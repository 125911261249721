import React from 'react';
import { useQuery } from 'react-apollo';

import {
  Company,
  MetricValuesListQuery,
  MetricValuesListQueryVariables,
  METRIC_VALUES_LIST_BY_CODE_QUERY,
} from 'src/graphql';
import { DashboardMetricGroups, MetricCode, MetricGroup, MetricPeriod } from 'src/types';
import { getDashboardMetricCodesByCompany, getDashboardMetricGroups } from 'src/utils';

type DashboardMetricsState = {
  data: Array<DashboardMetricGroups>;
  loading: boolean;
};

export const useDashboardMetrics = (
  company: Company,
  period: Array<string>,
): DashboardMetricsState => {
  const dashboardMetricCodes = getDashboardMetricCodesByCompany(company);

  const companyId = company?.id;

  const { data: metricListValues, loading: metricListValuesLoading } = useQuery<
    MetricValuesListQuery,
    MetricValuesListQueryVariables
  >(METRIC_VALUES_LIST_BY_CODE_QUERY, {
    variables: {
      filter: {
        company: {
          id: {
            equals: companyId,
          },
        },
        date: {
          in: period,
        },
        OR: [
          {
            period: {
              equals: MetricPeriod.Month,
            },
            metric: {
              code: {
                in: dashboardMetricCodes,
              },
            },
          },
          {
            AND: [
              {
                period: {
                  in: [MetricPeriod.Month],
                },
                metric: {
                  code: {
                    in: [MetricCode.SaaSScore],
                  },
                },
              },
            ],
          },
        ],
      },
    },
    skip: !companyId,
    fetchPolicy: 'no-cache',
  });

  const dashboardMetrics = React.useMemo(() => {
    const groups = metricListValues?.metricValuesList.groups || [];

    return getDashboardMetricGroups(groups as MetricGroup[]);
  }, [metricListValues]);

  return { data: dashboardMetrics, loading: metricListValuesLoading };
};
