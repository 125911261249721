import React from 'react';

import { MetricCellHeader, MetricCellSaaS, MetricCellValue } from 'src/components';
import { MetricValue } from 'src/graphql';
import { ScoreArrowType } from 'src/types';

interface Params {
  metricValue: MetricValue;
  colSpan: number;
  rowIndex: number;
  arrowType: ScoreArrowType | null;
}

export const renderValueColumn = ({
  metricValue,
  colSpan,
  rowIndex,
  arrowType,
}: Params): React.ReactNode => {
  const settings: Record<any, any> = {
    children: null,
    props: {},
  };

  switch (rowIndex) {
    case 0: {
      settings.children = <MetricCellSaaS arrowType={arrowType} metricValue={metricValue} />;
      settings.props.colSpan = colSpan;
      settings.props.align = 'center';
      break;
    }
    case 1: {
      settings.children = <MetricCellHeader type="value" />;
      settings.props.align = 'center';
      break;
    }
    default: {
      settings.children = <MetricCellValue metricValue={metricValue} />;
    }
  }

  return settings;
};
