import React from 'react';
import * as R from 'ramda';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import {
  Form,
  Field,
  TextField,
  NumberField,
  SelectField,
  FormSpy,
  useDataLoss,
  useCurrentUser,
} from 'src/app-builder';
import { useAllowed } from 'src/app-builder/providers';

import { useCompany, useNotification } from 'src/hooks';
import { COMPANIES_EDIT_MUTATION } from 'src/graphql';
import { SubmitButton } from 'src/components';
import {
  Permission,
  COUNTRIES_OPTIONS,
  STATE_OPTIONS_BY_COUNTRY,
  CAPITAL_SPEND_ON_OPTIONS,
  CAPITAL_TYPES_OPTIONS,
  FROM_WHOM_CAPITAL_RAISED_OPTIONS,
  FROM_WHOM_BORROWED,
  PRIMARY_BUSINESS_MODEL_OPTIONS,
  PRIMARY_CUSTOMER_BASE_OPTIONS,
} from 'src/constants';
import {
  baseNumberFormat,
  extractUserCompanyId,
  yearFormat,
  t,
  companyStoryLabel,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.spacing(2),
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface ClientPortalSaaSStoryPageProps {}

export const ClientPortalSaaSStoryPage: React.FC<ClientPortalSaaSStoryPageProps> = () => {
  const classes = useStyles();
  const notification = useNotification();
  const isAllowed = useAllowed();

  const { user, loading: userLoading } = useCurrentUser();
  const companyId = extractUserCompanyId(user);

  const { setWhen } = useDataLoss();

  const canChangeSaaSStory = isAllowed(Permission.ClientPortalSaaSStoryArea);

  const { data: company, loading: companyLoading } = useCompany(companyId as string);

  const initialValues = React.useMemo(() => (companyLoading ? {} : company || {}), [
    company,
    companyLoading,
  ]);

  const [updateCompany] = useMutation(COMPANIES_EDIT_MUTATION, {
    refetchQueries: [
      'UserCompany',
      'SortableLoanApplicationsList',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('company_update_success')),
    onError: () => notification.error(t('company_update_error')),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      await updateCompany({
        variables: { data },
      });

      setTimeout(form.reset);
    },
    [updateCompany],
  );

  const onCancel = React.useCallback(form => {
    setTimeout(form.reset);
  }, []);

  const loading = userLoading || companyLoading;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Paper className={classes.paper}>
        <Grid className={classes.header} item>
          <Typography variant="subtitle1">SaaSStory</Typography>
        </Grid>

        {loading ? (
          <LinearProgress />
        ) : (
          <Grid className={classes.content} item container direction="column">
            <Form
              tableSchemaName="Companies"
              type="UPDATE"
              onSubmit={onSubmit}
              subscription={{ submitting: true, pristine: true }}
              initialValues={initialValues as any}
              formatRelationToIds
            >
              {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1">I. Company Information</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="name"
                          label="Company Name"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="foundedYear"
                          label="Year Founded"
                          component={NumberField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          numberFormat={yearFormat}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="primaryBusinessModel"
                          label="Primary Business Model"
                          component={SelectField}
                          options={PRIMARY_BUSINESS_MODEL_OPTIONS}
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="primaryCustomerBase"
                          label="Primary Customer Base"
                          component={SelectField}
                          fullWidth
                          options={PRIMARY_CUSTOMER_BASE_OPTIONS}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={3}>
                        <Field
                          name="address.street1"
                          label="Street Address"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="address.city"
                          label="City"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="address.zip"
                          label="Zip Code"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="address.country"
                          label="Country"
                          component={SelectField}
                          disabled={true}
                          options={COUNTRIES_OPTIONS}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <FormSpy subscription={{ values: true }}>
                          {({ values }: any) => (
                            <Field
                              name="address.state"
                              label="State / Province"
                              component={SelectField}
                              disabled={submitting || !canChangeSaaSStory}
                              options={R.propOr(
                                [],
                                values?.address?.country,
                                STATE_OPTIONS_BY_COUNTRY,
                              )}
                              fullWidth
                            />
                          )}
                        </FormSpy>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="contactFirstName"
                          label="Contact First Name"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="contactLastName"
                          label="Contact Last Name"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="contactTitle"
                          label="Contact Title"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="contactEmail"
                          label="Contact Email"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">II. Company and Founder(s) Story</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="founderStory"
                          label="What are the Founder(s) story?"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                          rows={8}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormSpy subscription={{ values: true }}>
                          {({ values }) => (
                            <Field
                              name="companyStory"
                              label={companyStoryLabel(values.name)}
                              component={TextField}
                              fullWidth
                              disabled={submitting || !canChangeSaaSStory}
                              multiline
                              rows={8}
                            />
                          )}
                        </FormSpy>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">III. Product/Market</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="productDescription"
                          label="Product Description"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="marketDescription"
                          label="Market Description"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="customersStrategy"
                          label="How do you get customers?"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">IV. Capital</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          name="capital"
                          label="How much capital are you seeking?"
                          component={NumberField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="capitalType"
                            label="What type of capital are you seeking?"
                            component={SelectField}
                            options={CAPITAL_TYPES_OPTIONS}
                            disabled={submitting || !canChangeSaaSStory}
                            fullWidth
                            multiple
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="capitalStrategy"
                            label="What are you planning to spend it on?"
                            component={SelectField}
                            disabled={submitting || !canChangeSaaSStory}
                            options={CAPITAL_SPEND_ON_OPTIONS}
                            fullWidth
                            multiple
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="equityCapitalAmount"
                          label="How much equity (including convertible debt) have you raised?"
                          component={NumberField}
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="equityCapitalOwner"
                          label="From whom?"
                          component={SelectField}
                          disabled={submitting || !canChangeSaaSStory}
                          options={FROM_WHOM_CAPITAL_RAISED_OPTIONS}
                          multiple
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="monthsToNextRound"
                          label="How many months to your next equity round (ideally)?"
                          component={NumberField}
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item>
                          <Field
                            name="idealRaiseAmount"
                            label="Ideal raise amount"
                            component={NumberField}
                            disabled={submitting || !canChangeSaaSStory}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            numberFormat={baseNumberFormat}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          name="borrowedDebt"
                          label="How much have you borrowed (excluding convertible debt)?"
                          component={NumberField}
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="borrower"
                            label="From whom?"
                            component={SelectField}
                            disabled={submitting || !canChangeSaaSStory}
                            options={FROM_WHOM_BORROWED}
                            multiple
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="securedDebt"
                            label="How much of your debt is secured?"
                            component={NumberField}
                            disabled={submitting || !canChangeSaaSStory}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            numberFormat={baseNumberFormat}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item justify="flex-end" container spacing={2}>
                      <Grid item>
                        <Button
                          color="secondary"
                          onClick={onCancel.bind(null, form)}
                          disabled={submitting}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <SubmitButton
                          color="secondary"
                          variant="contained"
                          type="submit"
                          disableElevation
                          disabled={pristine}
                          loading={submitting}
                        >
                          Save Changes
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <FormSpy
                    subscription={{ pristine: true }}
                    onChange={({ pristine }) => {
                      setWhen(!pristine);
                    }}
                  />
                </form>
              )}
            </Form>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
