import React from 'react';
import { useHistory } from 'react-router-dom';
import { convertToQueryParams } from 'src/utils';

import { Filters } from './Filters';

interface FiltersContainerProps {
  selectedPeriod: number;
}

const FiltersContainer: React.FC<FiltersContainerProps> = React.memo(({ selectedPeriod }) => {
  const history = useHistory();

  const onChangeSelectedPeriod = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    _: React.ReactNode,
  ) => {
    history.replace({
      search: convertToQueryParams({ selectedPeriod: event.target.value }),
    });
  };

  return (
    <Filters selectedPeriod={selectedPeriod} onChangeSelectedPeriod={onChangeSelectedPeriod} />
  );
});

FiltersContainer.displayName = 'FiltersContainer';

export { FiltersContainer };
