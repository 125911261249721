import React from 'react';
import { Button, Theme, CircularProgress } from '@material-ui/core';
import { ButtonProps, ButtonClassKey } from '@material-ui/core/Button';
import * as icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { isFunction } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: ({ variant }: { variant: ButtonClassKey | undefined }) => {
    const mainSecondaryColor = theme.palette.secondary.main;
    const commonWhiteColor = theme.palette.common.white;
    const color = variant === 'outlined' ? mainSecondaryColor : commonWhiteColor;

    return {
      color,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    };
  },
}));

interface UploadButtonProps extends ButtonProps {
  loading?: boolean;
  acceptFile?: string;
  onSelectFile?: (file: File | null) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = props => {
  const { loading = false, acceptFile, onSelectFile, disabled, variant } = props;
  const classes = useStyles({ variant });

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    inputFileRef.current?.click();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!event.currentTarget?.files) {
      return;
    }

    const file = event.currentTarget?.files[0] || null;

    if (onSelectFile && isFunction(onSelectFile)) {
      return onSelectFile(file);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Button
        startIcon={<icons.CloudUpload />}
        loading={loading}
        onClick={onButtonClick}
        disabled={loading || disabled}
        {...props}
      >
        Upload CSV
      </Button>
      <input
        name="fileInput"
        type="file"
        ref={inputFileRef}
        style={{ display: 'none' }}
        accept={acceptFile || '.csv'}
        onChange={onChange}
      />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
