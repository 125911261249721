import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FiberManualRecordRounded } from '@material-ui/icons';
import { Theme, Typography } from '@material-ui/core';

interface StyleProps {
  color?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  legendLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  labelIcon: ({ color }) => ({
    fontSize: 12,
    color: color || theme.palette.secondary.main,
  }),
  text: {
    marginLeft: 10,
  },
}));

interface LegendLabelProps {
  label?: string;
  color?: string;
}

export const LegendLabel: React.FC<LegendLabelProps> = ({ label, color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.legendLabelContainer}>
      <div>
        <FiberManualRecordRounded className={classes.labelIcon} fontSize="small" />
      </div>
      <div className={classes.text}>
        <Typography variant="subtitle2">{label}</Typography>
      </div>
    </div>
  );
};
