import React from 'react';
import { Box, Paper, Theme, Typography } from '@material-ui/core';
import { getFilePreviewUrl } from 'src/app-builder';
import { makeStyles } from '@material-ui/core/styles';

import { File } from 'src/graphql';
import { downloadDataUrl } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  file: {
    padding: theme.spacing(2),
    position: 'relative',
    cursor: 'pointer',
  },
  picture: {
    height: 100,
    width: '100%',
    borderRadius: 4,
    backgroundImage: (props: { url: string }) => `url(${props?.url ?? ''})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  info: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(1),
    backgroundColor: '#FBFBFC',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  filePreview: {
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));

interface SingleAttachmentProps {
  file: File;
}

export const SingleAttachment: React.FC<SingleAttachmentProps> = props => {
  const { file } = props;

  const downloadUrl = file.downloadUrl as string;
  const filename = file.filename as string;

  const url = getFilePreviewUrl(downloadUrl, 150);
  const classes = useStyles({ url });

  const handleAttachmentClick = async (): Promise<any> => {
    await downloadDataUrl(filename, downloadUrl);
  };

  return (
    <Paper className={classes.file} onClick={handleAttachmentClick} elevation={1} square={true}>
      <Box className={classes.picture} />
      <Box className={classes.info}>
        {filename && (
          <Typography className={classes.filePreview} variant="caption">
            {filename}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};
