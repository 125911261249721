import * as R from 'ramda';

import { ChangedMetric, MetricCode, MetricPeriod, Source } from 'src/types';
import { generateChangedMetricValue } from 'src/utils';

interface GetToggledMetricInput {
  companyId: string;
  localChangedMetric: ChangedMetric | null;
  date: string;
  code: MetricCode;
  active: boolean;
  comment: string | null;
  adjustedValue: number | null;
  source: Source;
  period: MetricPeriod;
}

export const getToggledMetric = ({
  companyId,
  localChangedMetric,
  date,
  code,
  active,
  comment,
  adjustedValue,
  source,
  period,
}: GetToggledMetricInput): ChangedMetric => {
  if (!R.isNil(localChangedMetric)) {
    return {
      ...localChangedMetric,
      companyId,
      date,
      active,
    };
  }

  return generateChangedMetricValue(
    date,
    adjustedValue,
    comment,
    companyId,
    active,
    source,
    code,
    period,
  );
};
