import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, Typography } from '@material-ui/core';

import { ReactComponent as TierBadge } from 'src/assets/icons/tier-badge.svg';
import { TIER_TO_STRENGTH } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  progress: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  value: {
    color: theme.palette.common.black,
    lineHeight: 1,
    fontWeight: 300,
  },
  maxScore: {
    color: theme.palette.grey[700],
    lineHeight: 1.3,
  },
  currentTier: {
    color: theme.palette.grey[700],
    lineHeight: 1,
  },
  saasScore: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end',
  },
  badgeContainer: {
    maxWidth: 156,
    minWidth: 156,
    marginLeft: theme.spacing(3),
    position: 'relative',
  },
  badge: {
    fill: theme.palette.secondary.light,
  },
  badgeTier: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    lineHeight: 0,
  },
  progressContainer: {
    display: 'flex',
    position: 'relative',
  },
  strength: {
    position: 'absolute',
    top: 30,
    transform: 'translateX(-55%)',
    left: (props: { value: number }) => `${props.value}%`,

    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateY(-100%) translateX(-50%)',
      borderLeft: `10px solid transparent`,
      borderRight: `10px solid transparent`,
      borderBottom: `10px solid ${theme.palette.common.black}`,
    },
  },
}));

const SaaSScoreProgressValue = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 15,
      width: '100%',
      borderRadius: 10,
      position: 'relative',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 10,
      backgroundColor: theme.palette.secondary.light,
    },
  }),
)(LinearProgress);

interface SaasScoreProgressProps {
  saasScoreValue: number | null;
  tierValue: number | null;
}

export const SaasScoreProgress: React.FC<SaasScoreProgressProps> = ({
  saasScoreValue,
  tierValue,
}) => {
  const value = saasScoreValue ?? 0;
  const tier = tierValue ?? 0;

  const classes = useStyles({
    value,
  });

  if (value === 0) {
    return (
      <Typography style={{ marginBottom: '20px' }} variant="h5">
        SaaSScore is not available for your company!
      </Typography>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.progress}>
        <Box className={classes.saasScore}>
          <Typography variant="h1" className={classes.value}>
            {Math.round(value)}
          </Typography>
          <Typography variant="h4" className={classes.maxScore}>
            /100
          </Typography>
        </Box>

        <Box className={classes.progressContainer}>
          <SaaSScoreProgressValue variant="determinate" value={value} />
          <Box className={classes.strength}>
            <Typography variant="button">{TIER_TO_STRENGTH[tier]}</Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.badgeContainer}>
        <TierBadge className={classes.badge} />
        <Box className={classes.badgeTier}>
          <Typography variant="h2" className={classes.currentTier}>
            {tier}
          </Typography>
          <Typography variant="button" className={classes.currentTier}>
            tier
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
