import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router';
import { Grid, makeStyles } from '@material-ui/core';

import { SearchField, SubmitButton } from 'src/components';
import { MonthField } from 'src/components/MonthField';
import { convertToQueryParams, isDateGreaterOrEquals } from 'src/utils';

interface ToolbarProps {
  loading: boolean;
  searchValue: string;
  setSearchValue: (v: string) => void;
  selectedMonth: string | null;
  setSelectedMonth: (m: string | null) => void;
  maxMonth?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 64,
    padding: 16,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Toolbar: React.FC<ToolbarProps> = ({
  loading = false,
  setSearchValue,
  searchValue,
  setSelectedMonth,
  selectedMonth,
  maxMonth,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const onMonthSubmit = React.useCallback(() => {
    if (!R.isNil(selectedMonth)) {
      history.push({
        search: convertToQueryParams({ month: selectedMonth }),
      });
    }
  }, [history, selectedMonth]);

  const isGoButtonEnabled = Boolean(
    selectedMonth && (maxMonth ? isDateGreaterOrEquals(maxMonth, selectedMonth as string) : true),
  );

  return (
    <Grid container className={classes.root} alignItems="center" justify="space-between">
      <Grid item container alignItems="center" justify="flex-start" xs={7} spacing={2}>
        <Grid item>
          <MonthField
            label="Month"
            value={selectedMonth}
            onChange={setSelectedMonth}
            maxDate={maxMonth}
            maxDateMessage={`Must not exceed ${maxMonth}`}
          />
        </Grid>

        <Grid item>
          <SubmitButton
            size="large"
            color="secondary"
            variant="contained"
            loading={loading}
            disabled={!isGoButtonEnabled}
            onClick={onMonthSubmit}
          >
            Go
          </SubmitButton>
        </Grid>
      </Grid>
      <Grid container item xs={5} justify="flex-end">
        <Grid item>
          <SearchField
            onChange={e => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Search..."
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
