import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  ArgumentAxis,
  Chart,
  SplineSeries,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { getDateRange, getMonth } from 'src/utils';

const DEFAULT_PERIOD = 4;

const useStyles = makeStyles(() => ({
  chartContainer: {
    position: 'relative',
  },
  text: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface EmptyChartProps {
  height: number;
}

export const EmptyMetricChart: React.FC<EmptyChartProps> = ({ height }) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const fakeData = React.useMemo(() => {
    // example: ['2020-06-13', '2020-07-13']
    const dates = getDateRange(DEFAULT_PERIOD);

    const buildXAxis = (date: string) => ({
      date: getMonth(date, 'long'),
      value: 0,
    });

    return dates.map(buildXAxis);
  }, []);

  return (
    <Box className={classes.chartContainer}>
      <Typography className={classes.text} color="textSecondary" variant="body1" align="center">
        No metric data
      </Typography>
      <Chart data={fakeData} height={height}>
        <ArgumentAxis />
        <ValueAxis position="right" />

        <SplineSeries valueField="value" argumentField="date" color={palette.secondary.main} />
        <Animation />
      </Chart>
    </Box>
  );
};
