import * as R from 'ramda';

import { Facility } from 'src/graphql';
import { getTime } from 'src/utils';

type SortDirection = 'ASC' | 'DESC';

export const sortFacilities = (
  facilities: Facility[],
  sortDirection: SortDirection,
): Facility[] => {
  const sort = sortDirection === 'DESC' ? R.descend : R.ascend;

  const byClosingDate = sort((item: Facility): number => getTime(item.closingDate));
  const byCreatedAt = sort((item: Facility): number => getTime(item.createdAt));

  return R.sortWith([byClosingDate, byCreatedAt])(facilities);
};

export const getMostRecentFacility = (facilities: Facility[]): Facility | undefined => {
  const recentFacilities = sortFacilities(facilities, 'DESC');

  return recentFacilities[0];
};
