import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TableShared } from 'src/shared/table/TableShared';
import { ScoreMetricsTableDataStructureRow } from 'src/types';

interface TableProps {
  data: ScoreMetricsTableDataStructureRow[];
  dates: string[];
  columns: any[];
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '16px 16px 0',
    height: 'calc(100% - 164px)',
  },
  table: {
    height: '100%',
    '& tr': {
      '&:first-child, &:nth-child(2)': {
        height: 41,
      },
    },
    '& th, & tr, & td': {
      height: 37,
      padding: 0,
    },
    '& td': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td:first-child, & th:first-child': {
      padding: '0 16px',
    },
    '& td:first-child:after, & th:first-child:after': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    '& tr:nth-child(2), & tr:nth-child(3)': {
      '& td': {
        background: theme.palette.grey[50],
      },
    },
    '& .rc-table-container': {
      height: '100%',
    },
    '& .rc-table-body': {
      height: 'calc(100% - 41px)',
    },
  },
}));

const SCROLL_SETTINGS = {
  x: 'auto',
  y: 'auto',
};

const Table: React.FC<TableProps> = React.memo(({ data, columns }) => {
  const classes = useStyles();

  const getRowKey = React.useCallback((record, idx) => record.code + idx || idx, []);
  return (
    <div className={classes.root}>
      <TableShared
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        columns={columns}
        rowKey={getRowKey}
        data={data}
        scroll={SCROLL_SETTINGS}
        className={classes.table}
      />
    </div>
  );
});

Table.displayName = 'Table';

export { Table };
