import React from 'react';

export const useSelectedRows = (
  rows: any[],
): {
  selectedRows: string[];
  setSelectedRows: (rows: string[]) => void;
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectOne: (id: string) => void;
} => {
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);

  const handleSelectAll = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedRows(rows.map((row: any) => row.id));
      } else {
        setSelectedRows([]);
      }
    },
    [rows, setSelectedRows],
  );

  const handleSelectOne = React.useCallback(
    (id: string) => {
      const selectedIndex = selectedRows.indexOf(id);

      let newSelectedRows: string[] = [];

      if (selectedIndex === -1) {
        newSelectedRows = newSelectedRows.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedRows = newSelectedRows.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1),
        );
      }

      setSelectedRows(newSelectedRows);
    },
    [selectedRows, setSelectedRows],
  );

  return { selectedRows, setSelectedRows, handleSelectAll, handleSelectOne };
};
