import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { useNotification } from 'src/hooks';
import { LoanApplication } from 'src/graphql';
import { LOAN_APPLICATIONS_EDIT_MUTATION } from 'src/graphql';
import { ToggleSwitch } from 'src/components';
import { t } from 'src/utils';

export const StatusRenderer = (loan: LoanApplication): React.ReactNode => {
  const notification = useNotification();
  const [updateLoan, { loading }] = useMutation(LOAN_APPLICATIONS_EDIT_MUTATION, {
    refetchQueries: ['SortableLoanApplicationsList', 'LoanApplicationsMetrics'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('loan_application_status_update_success')),
    onError: () => notification.error(t('loan_application_status_update_error')),
  });

  const handleChange = (_: any, checked: boolean) => {
    updateLoan({
      variables: {
        filter: {
          id: loan.id,
        },
        data: {
          status: checked,
        },
      },
    });
  };

  return (
    <ToggleSwitch loading={loading} checked={loan.status as boolean} onChange={handleChange} />
  );
};
