import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Theme, LinearProgress, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import * as icons from '@material-ui/icons';

import { ModalsContext } from 'src/app-builder';
import { useAllowed } from 'src/app-builder';

import { usePortfolioCompany } from 'src/hooks';
import { FacilityAccordion, EmptyScreen } from 'src/components';
import {
  FacilitiesTableContentQuery,
  FacilitiesTableContentQueryVariables,
  SortOrder,
} from 'src/graphql';
import {
  FacilityCreateDialog,
  FacilityEditDialog,
  FacilityDeleteDialog,
  DrawCreateDialog,
  DrawEditDialog,
  DrawDeleteDialog,
  ConfirmationDialog,
  DIALOGS,
} from 'src/dialogs';
import { FACILITIES_TABLE_QUERY } from 'src/graphql/facilities';
import { Permission } from 'src/constants/permissions';
import { Covenants } from 'src/components/Covenants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'auto',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
  content: {
    padding: theme.spacing(2),
    overflow: 'auto',
    flex: 1,
  },
  tableCellLabel: {
    fontWeight: 500,
    height: theme.spacing(6),
  },
  tabs: {
    maxWidth: 500,
  },
}));

const DEFAULT_OPENED_TAB = '1';

export const PortfolioCompanyFacilityAndDrawsPage: React.FC<any> = () => {
  const { openModal } = React.useContext(ModalsContext);

  const classes = useStyles();

  const { id: portfolioCompanyId }: { id: string } = useParams();

  const isAllowed = useAllowed();

  const canAddFacility = isAllowed(Permission.PortfolioCompaniesAddFacility);

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const [openedTab, setOpenedTab] = React.useState(DEFAULT_OPENED_TAB);

  const companyId = portfolioCompany?.company?.id as string;

  const { data: facilitiesData, loading: facilitiesLoading } = useQuery<
    FacilitiesTableContentQuery,
    FacilitiesTableContentQueryVariables
  >(FACILITIES_TABLE_QUERY, {
    variables: {
      sort: [{ createdAt: SortOrder.Desc }],
      filter: {
        company: {
          portfolioCompany: {
            id: {
              equals: portfolioCompanyId,
            },
          },
        },
      },
    },
    skip: !Boolean(portfolioCompanyId),
  });

  const openCreateFacilityDialog = React.useCallback(() => {
    openModal(DIALOGS.FACILITY_CREATE_DIALOG, { companyId });
  }, [openModal, companyId]);

  const facilities = React.useMemo(() => facilitiesData?.facilitiesList?.items || [], [
    facilitiesData,
  ]);

  const onChangeTab = (e: React.ChangeEvent<Record<string, unknown>>, newValue: string) => {
    setOpenedTab(newValue);
  };

  const loading = facilitiesLoading || portfolioCompanyLoading;
  const facilitiesEmpty = facilities.length === 0;

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Facility &amp; Draws</Typography>

        {!loading && !facilitiesEmpty && (
          <Button
            disabled={!canAddFacility}
            color="secondary"
            variant="outlined"
            onClick={openCreateFacilityDialog}
          >
            Add Facility
          </Button>
        )}
      </Grid>

      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column" wrap="nowrap">
          <TabContext value={openedTab}>
            <TabList onChange={onChangeTab}>
              <Tab label="Facilities" value="1" />
              <Tab label="Covenants" value="2" />
            </TabList>
            <TabPanel value="1">
              {facilitiesEmpty && (
                <EmptyScreen
                  text="We couldn't find any facilites"
                  actionText="Add facilites"
                  icon={icons.Assignment}
                  onClick={openCreateFacilityDialog}
                />
              )}
              {!facilitiesEmpty &&
                facilities.map((facility: any, index: number) => (
                  <FacilityAccordion
                    key={facility?.id}
                    defaultExpanded={index === 0}
                    facility={facility}
                    companyId={companyId as string}
                    index={facilities.length - index}
                  />
                ))}
            </TabPanel>
            <TabPanel value="2">
              <Covenants />
            </TabPanel>
          </TabContext>
        </Grid>
      )}
      <FacilityCreateDialog />
      <FacilityEditDialog />
      <FacilityDeleteDialog />
      <DrawCreateDialog />
      <DrawEditDialog />
      <DrawDeleteDialog />
      <ConfirmationDialog />
    </Grid>
  );
};
