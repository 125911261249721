import * as R from 'ramda';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { REG_EXP } from 'src/constants';
import { MetricValuesListQueryVariables } from 'src/graphql';

const METRIC_VALUES_LIST_QUERY_REGEX = /metricValuesList/;

export const getMetricValuesListQueryVariables = (
  cache: InMemoryCache,
  companyId: string,
): Array<MetricValuesListQueryVariables> => {
  const rootQuery = cache.extract().ROOT_QUERY ?? {};
  const rootQueryKeys = Object.keys(rootQuery);

  const regexCompanyId = new RegExp(companyId);

  const metricValuesListQueries = rootQueryKeys.filter(
    key => METRIC_VALUES_LIST_QUERY_REGEX.test(key) && regexCompanyId.test(key),
  );

  const queryVariableSets = metricValuesListQueries.map(query => {
    const matchResult = query.match(REG_EXP.CURLY_BRACKETS) ?? [];
    const stringifiedVariables = matchResult[0] ?? null;

    return JSON.parse(stringifiedVariables);
  });

  return queryVariableSets.filter(varObject => !R.isNil(varObject));
};
