import React from 'react';

import { Header } from './';
import { TableExportType } from 'src/graphql';
import { useTableExport } from 'src/hooks';
import { ExportDataType } from 'src/types';

type HeaderContainerProps = {
  tableExportData: {
    columns: Array<ExportDataType>;
    rows: Array<any>;
  };
  exportDataLoading?: boolean;
};

const HeaderContainer: React.FC<HeaderContainerProps> = ({
  tableExportData,
  exportDataLoading = false,
}) => {
  const [onExport, { loading: isExporting }] = useTableExport(
    TableExportType.PortfolioOverview,
    tableExportData,
  );

  return (
    <Header onExport={onExport} isExporting={isExporting} exportDataLoading={exportDataLoading} />
  );
};

HeaderContainer.displayName = 'HeaderContainer';

export { HeaderContainer };
