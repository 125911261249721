import React from 'react';

import { SortTitleRenderer } from 'src/shared/table/renderers/SortTitleRenderer';
import { LinkRenderer } from 'src/shared/table/renderers/LinkRenderer';
import { PrimaryContactRenderer } from '../components/PrimaryContactRenderer';
import { SelectionColumnTitleRenderer } from 'src/shared/table/renderers/SelectionColumnTitleRenderer';
import { SelectionColumnRenderer } from 'src/shared/table/renderers/SelectionColumnRenderer';
import { ActionsTitleRenderer } from './ActionsTitleRenderer';

import { DateFormatPatterns, formatDate, formatMoney, formatWholeNumber } from 'src/utils';

import { StageRenderer, ActionsRenderer, AvatarRenderer, AgeRenderer } from './index';
import { SortableLoanApplication } from 'src/graphql';
import { CELL_TYPES } from 'src/types';
import { StatusRenderer } from './StatusRenderer';

/* eslint-disable react/display-name */
export const getColumns = (
  order: string[],
  classes: Record<string, string>,
  canDelete: boolean,
): any[] => {
  const columns: Record<string, any> = {
    'Applicant Name': {
      title: <SortTitleRenderer field="companyName">Applicant name</SortTitleRenderer>,
      exportData: { title: 'Applicant Name', type: CELL_TYPES.text, key: 'applicantName' },
      render: (r: SortableLoanApplication) =>
        r.status
          ? LinkRenderer(r.companyName as string, `/loan-applications/${r.id}/dashboard`)
          : r.companyName,
      align: 'left',
      style: {
        padding: 0,
      },
      width: 180,
    },
    Status: {
      title: <SortTitleRenderer field="status">Status</SortTitleRenderer>,
      exportData: { title: 'Status', type: CELL_TYPES.text, key: 'status' },
      key: 'active',
      render: StatusRenderer,
      align: 'center',
      width: 115,
    },
    'Contact Name': {
      title: <SortTitleRenderer field="ownerFirstName">Contact Name</SortTitleRenderer>,
      exportData: { title: 'Contact Name', type: CELL_TYPES.text, key: 'primaryContact' },
      key: 'active',
      render: PrimaryContactRenderer,
      align: 'left',
      width: 115,
    },
    'Account Creation Date': {
      title: <SortTitleRenderer field="createdAt">Account Creation Date</SortTitleRenderer>,
      exportData: {
        title: 'Account Creation Date',
        type: CELL_TYPES.date,
        key: 'accountCreationDate',
      },
      align: 'left',
      width: 170,
      render: (r: SortableLoanApplication) =>
        formatDate(r.createdAt, DateFormatPatterns.shortDateWithSlash),
    },
    Age: {
      title: <SortTitleRenderer field="age">Age</SortTitleRenderer>,
      exportData: { title: 'Age', type: CELL_TYPES.number, key: 'age' },
      render: AgeRenderer,
      align: 'left',
    },
    Stage: {
      title: <SortTitleRenderer field="stage">Stage</SortTitleRenderer>,
      exportData: { title: 'Stage', type: CELL_TYPES.text, key: 'stage' },
      render: (r: SortableLoanApplication) => <StageRenderer id={r.id} stage={r.stage} />,
      align: 'left',
      width: 176,
    },
    SaaSScore: {
      title: <SortTitleRenderer field="saasScore">SaaSScore</SortTitleRenderer>,
      exportData: { title: 'SaaSScore', type: CELL_TYPES.number, key: 'saasScore' },
      render: (r: SortableLoanApplication) => formatWholeNumber(r?.saasScore),
      align: 'left',
      width: 124,
    },
    'Amount Requested': {
      title: <SortTitleRenderer field="amountRequested">Amount Requested</SortTitleRenderer>,
      exportData: { title: 'Amount Requested', type: CELL_TYPES.number, key: 'amountRequested' },
      render: (r: SortableLoanApplication) => formatMoney(r?.amountRequested),
      align: 'left',
    },
    '$ Committed': {
      title: <SortTitleRenderer field="committed">$ Committed</SortTitleRenderer>,
      exportData: { title: '$ Committed', type: CELL_TYPES.number, key: 'amountCommitted' },
      render: (r: SortableLoanApplication) => formatMoney(r?.committed),
      align: 'left',
    },
    '1st Draw Proposed': {
      title: <SortTitleRenderer field="firstDrawProposed">1st Draw Proposed</SortTitleRenderer>,
      exportData: { title: '1st Draw Proposed', type: CELL_TYPES.number, key: 'firstDrawProposed' },
      render: (r: SortableLoanApplication) => formatMoney(r?.firstDrawProposed),
      align: 'left',
    },
    'Target Close Date': {
      title: <SortTitleRenderer field="targetCloseDate">Target Close Date</SortTitleRenderer>,
      exportData: { title: 'Target Close Date', type: CELL_TYPES.date, key: 'targetCloseDate' },
      render: (r: SortableLoanApplication) =>
        formatDate(
          r?.targetCloseDate,
          DateFormatPatterns.shortDateWithSlash,
          DateFormatPatterns.shortDateWithDash,
        ),
      align: 'left',
    },
    'Deal Team': {
      title: 'Deal Team',
      render: (r: SortableLoanApplication) => <AvatarRenderer id={r.id} />,
      align: 'left',
    },
  };

  const displayColumns: any = canDelete
    ? [
        {
          title: <SelectionColumnTitleRenderer />,
          key: 'select',
          width: 42,
          render: SelectionColumnRenderer,
          className: classes.selectionColumn,
          fixed: 'left',
          align: 'center',
        },
      ]
    : [];

  order.forEach(key => {
    displayColumns.push(columns[key]);
  });

  displayColumns.push({
    title: <ActionsTitleRenderer />,
    fixed: 'right',
    render: (r: SortableLoanApplication) => <ActionsRenderer id={r.id} />,
    key: 'actions',
    className: classes.actions,
    width: 88,
  });

  return displayColumns;
};
