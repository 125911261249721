import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Grid,
  Theme,
  Typography,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useInboxMessagesCount } from 'src/hooks';
import { MultipleAttachments } from './components';
import { DateFormatPatterns, formatDate } from 'src/utils';
import { InboxMessage, INBOX_MESSAGE_QUERY } from 'src/graphql';
import { InboxFolderTypes } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    color: theme.palette.grey[400],
    marginBottom: 20,
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(100% - ${theme.spacing(8)}px)`,
  },
  subject: {
    fontSize: 18,
    marginBottom: 11,
  },
  title: {
    color: theme.palette.text.primary,
  },
  card: {
    borderRadius: 8,
  },
  createdDate: {
    color: theme.palette.text.secondary,
    marginLeft: 11,
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "''",
      display: 'inline-block',
      width: 3,
      height: 3,
      background: '#6B6B6B',
      borderRadius: '50%',
      marginRight: 11,
    },
  },
  cardHeader: {
    padding: theme.spacing(4),
    paddingBottom: 0,
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  bodyText: {
    whiteSpace: 'pre-line',
  },
  repliesInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  repliesInfoNoReplies: {
    justifyContent: 'flex-end',
  },
  replies: {
    display: 'flex',
    maxWidth: '50%',
  },
  repliesList: {
    width: '100%',
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  reply: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  repliedOn: {
    maxWidth: '30%',
    display: 'flex',
  },
  repliedOnLink: {
    marginLeft: 5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  repliedOnLabel: {
    flexShrink: 0,
  },
}));

interface ActiveMessageProps {
  id: string;
  path: string;
  isVisible: boolean;
  folder: string;
  userId: string;
  createReplyInboxDialog: ({ messageId }: { messageId?: string }) => void;
}

export const ActiveMessage: React.FC<ActiveMessageProps> = props => {
  const { path, id, folder, isVisible, createReplyInboxDialog } = props;

  const { data, loading } = useQuery<{ inboxMessage: InboxMessage }>(INBOX_MESSAGE_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
    skip: !Boolean(id),
  });

  const message = data?.inboxMessage;

  const { markInboxMessageAsRead } = useInboxMessagesCount();

  React.useEffect(() => {
    if (Boolean(id) && !message?.isRead) {
      markInboxMessageAsRead(message);
    }
  }, [markInboxMessageAsRead, id, message]);

  const classes = useStyles();

  const createdAt = formatDate(message?.createdAt, DateFormatPatterns.smallDateWithLocalTime);
  const attachments = R.pathOr([], ['inboxMessage', 'attachments', 'items'], data);
  const replies = R.pathOr<InboxMessage[]>([], ['inboxMessage', 'replies', 'items'], data);
  const isRepliesEmpty = !Boolean(replies.length);
  const isNoRepliedOn = !Boolean(message?.repliedOn);

  const openCreateReplyInboxDialog = () => createReplyInboxDialog({ messageId: id });

  if (!isVisible) {
    return null;
  }

  if (loading) {
    return (
      <Box className={classes.loaderWrapper}>
        <CircularProgress className={classes.loader} />
        <Typography color="textSecondary" variant="body1" align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  const Body = () => {
    return (
      <Grid item sm={12}>
        <Typography variant="body1" component="p" className={classes.bodyText}>
          {message?.body}
        </Typography>
        {!R.isEmpty(attachments) && (
          <>
            <Grid item sm={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item sm={12}>
              <MultipleAttachments attachments={attachments} />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const Reply = ({ folder }: { folder: string }) => {
    if (folder === InboxFolderTypes.sent) {
      return null;
    }
    return (
      <Grid item sm={12}>
        <Divider className={classes.divider} />
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={openCreateReplyInboxDialog}>
            Reply Message
          </Button>
        </Box>
      </Grid>
    );
  };

  return (
    <Card className={classes.card} elevation={1}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Typography className={classes.subject} variant="subtitle1">
            {message?.subject}
          </Typography>
        }
        subheader={
          <Box display="flex" flexDirection="row">
            <Typography variant="body2" className={classes.createdDate}>
              {createdAt}
            </Typography>
          </Box>
        }
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Body />
          <div
            className={clsx(classes.repliesInfo, {
              [classes.repliesInfoNoReplies]: isRepliesEmpty,
            })}
          >
            {!isRepliesEmpty && (
              <Typography variant="body2" className={classes.replies}>
                replies:
                <div className={classes.repliesList}>
                  {replies.map(reply => (
                    <Link
                      className={classes.reply}
                      key={reply.id}
                      color="secondary"
                      component={RouterLink}
                      to={`${path}/${
                        folder === InboxFolderTypes.sent
                          ? InboxFolderTypes.received
                          : InboxFolderTypes.sent
                      }/${reply.id}`}
                    >
                      {reply.subject}
                    </Link>
                  ))}
                </div>
              </Typography>
            )}
            {!isNoRepliedOn && (
              <Typography variant="body2" className={classes.repliedOn}>
                <div className={classes.repliedOnLabel}>replied on</div>
                <Link
                  className={classes.repliedOnLink}
                  color="secondary"
                  component={RouterLink}
                  to={`${path}/${
                    folder === InboxFolderTypes.sent
                      ? InboxFolderTypes.received
                      : InboxFolderTypes.sent
                  }/${message?.repliedOn?.id}`}
                >
                  {message?.repliedOn?.subject}
                </Link>
              </Typography>
            )}
          </div>
          <Reply folder={folder} />
        </Grid>
      </CardContent>
    </Card>
  );
};
