import * as React from 'react';
import { Box, Paper, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    fontWeight: 400,
  },
}));

interface MetricCardProps {
  value?: number | string;
  name: string;
}

export const MetricCard: React.FC<MetricCardProps> = ({ value = 0, name }) => {
  const classes = useStyles();

  return (
    <Box component={Paper} padding={2} textAlign="center">
      <Typography className={classes.value} color="secondary" variant="h2">
        {value}
      </Typography>
      <Typography variant="body1" noWrap>
        {name}
      </Typography>
    </Box>
  );
};
