import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import * as R from 'ramda';
import { Button, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useNotification, useRouterQuery } from 'src/hooks';
import { Plaid } from 'src/routes/customer-home/customerHomeIntegrationsPage/components';
import {
  QUICKBOOKS_GET_AUTH_URL_MUTATION,
  STRIPE_GET_AUTH_URL_MUTATION,
  XERO_GET_AUTH_URL_MUTATION,
  QUICKBOOKS_DELETE_TOKEN_MUTATION,
  STRIPE_DELETE_TOKEN_MUTATION,
  XERO_DELETE_TOKEN_MUTATION,
  CompanyIntegrationsEntityQuery,
} from 'src/graphql';
import { Integration } from 'src/constants';
import {
  t,
  commonErrorResolver,
  checkIfPlaidIntegrationConnected,
  checkIfQuickBooksIntegrationConnected,
  checkIfStripeIntegrationConnected,
  checkIfXeroIntegrationConnected,
} from 'src/utils';

import { ReactComponent as XeroLogo } from 'src/assets/logos/xero.svg';
import { ReactComponent as StripeLogo } from 'src/assets/logos/stripe.svg';
import { ReactComponent as QuickbooksLogo } from 'src/assets/logos/quickbooks.svg';

const useStyles = makeStyles((theme: Theme) => ({
  integrationsContainer: {
    marginBottom: theme.spacing(4),
  },

  xeroWrapper: { height: 120, display: 'flex', marginBottom: theme.spacing(2) },
  quickbooksWrapper: { height: 120, display: 'flex', marginBottom: theme.spacing(2) },
  stripeWrapper: { height: 120, display: 'flex', marginBottom: theme.spacing(2) },

  xeroLogo: { width: '100%', maxHeight: 80 },
  quickbooksLogo: { width: '100%', maxHeight: 80 },
  stripeLogo: { width: '100%', maxHeight: 80 },

  cardHeader: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  cardWrapper: {
    minWidth: 400,
  },
}));

interface IntegrationsListProps {
  integrations?: CompanyIntegrationsEntityQuery;
  integrationsLoading: boolean;
  darkShadow?: boolean;
  refetchIntegrations: () => void;
}

// @todo delete if need after clarification of requirements
const SHOW_STRIPE_CARD = false;

export const IntegrationsList: React.FC<IntegrationsListProps> = ({
  integrations,
  integrationsLoading,
  refetchIntegrations,
}) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const query = useRouterQuery();

  const notification = useNotification();

  const company = R.pathOr(null, ['company'], integrations);
  const companyId = R.pathOr(null, ['company', 'id'], integrations);

  React.useEffect(() => {
    if (query.from) {
      if (query.from === Integration.QuickBooks) {
        if (query.error) {
          notification.error(query.error);
        } else {
          notification.success(t('integration_connect_quick_books_success'));
        }
      }

      if (query.from === Integration.Xero) {
        if (query.error) {
          notification.error(query.error);
        } else {
          notification.success(t('integration_connect_xero_success'));
        }
      }

      history.replace(location.pathname);
    }
  }, [query, location, history, notification]);

  const isPlaidIntegrationConnected = React.useMemo(
    () => checkIfPlaidIntegrationConnected(company),
    [company],
  );

  const isQuickBooksIntegrationConnected = React.useMemo(
    () => checkIfQuickBooksIntegrationConnected(company),
    [company],
  );

  const isStripeIntegrationConnected = React.useMemo(
    () => checkIfStripeIntegrationConnected(company),
    [company],
  );

  const isXeroIntegrationConnected = React.useMemo(
    () => checkIfXeroIntegrationConnected(company),
    [company],
  );

  const [getQuickBooksAuthUrl, { loading: quickBooksAuthUrlLoading }] = useMutation(
    QUICKBOOKS_GET_AUTH_URL_MUTATION,
    {
      onError: error => notification.error(commonErrorResolver(error)),
    },
  );

  const handleQuickBooksConnect = React.useCallback(async () => {
    const {
      data: {
        quickBooksGetAuthUrl: { url },
      },
    } = await getQuickBooksAuthUrl({
      variables: {
        companyId,
        redirectUrl: window.location.href,
      },
    });

    window.location.href = url;
  }, [getQuickBooksAuthUrl, companyId]);

  const [deleteQuickBooksToken, { loading: quickBooksTokenDeleting }] = useMutation(
    QUICKBOOKS_DELETE_TOKEN_MUTATION,
    {
      refetchQueries: ['CompanyIntegrationsEntity'],
      awaitRefetchQueries: true,
    },
  );

  const handleQuickBooksDisconnect = React.useCallback(async () => {
    const quickBooksIntegrationId = R.pathOr(
      null,
      ['company', 'integration', 'quickBooks', 'id'],
      integrations,
    );

    const response = await deleteQuickBooksToken({
      variables: {
        quickBooksIntegrationId,
      },
    });

    const success = R.pathOr(false, ['data', 'quickBooksDeleteToken', 'success'], response);

    if (success) {
      notification.success(t('integration_disconnect_quick_books_success'));
    } else {
      notification.error(t('integration_disconnect_quick_books_error'));
    }
  }, [integrations, deleteQuickBooksToken, notification]);

  const [getStripeAuthUrl, { loading: stripeAuthUrlLoading }] = useMutation(
    STRIPE_GET_AUTH_URL_MUTATION,
  );

  const handleStripeConnect = React.useCallback(async () => {
    const {
      data: {
        stripeGetAuthUrl: { url },
      },
    } = await getStripeAuthUrl({
      variables: {
        companyId,
        redirectUrl: window.location.href,
      },
    });

    window.location.href = url;
  }, [getStripeAuthUrl, companyId]);

  const [deleteStripeToken, { loading: stripeTokenDeleting }] = useMutation(
    STRIPE_DELETE_TOKEN_MUTATION,
    {
      refetchQueries: ['CompanyIntegrationsEntity'],
      awaitRefetchQueries: true,
    },
  );

  const handleStripeDisconnect = React.useCallback(async () => {
    const stripeIntegrationId = R.pathOr(
      null,
      ['company', 'integration', 'stripe', 'id'],
      integrations,
    );

    const response = await deleteStripeToken({
      variables: {
        stripeIntegrationId,
      },
    });

    const success = R.pathOr(false, ['data', 'stripeDeleteToken', 'success'], response);

    if (success) {
      notification.success('Stripe integration successfully disconnected');
    } else {
      notification.error(`Couldn't to disconnect Stripe integration`);
    }
  }, [integrations, deleteStripeToken, notification]);

  const [getXeroAuthUrl, { loading: xeroAuthUrlLoading }] = useMutation(
    XERO_GET_AUTH_URL_MUTATION,
    {
      onError: error => notification.error(commonErrorResolver(error)),
    },
  );

  const handleXeroConnect = React.useCallback(async () => {
    const {
      data: {
        xeroGetAuthUrl: { url },
      },
    } = await getXeroAuthUrl({
      variables: {
        companyId,
        redirectUrl: window.location.href,
      },
    });

    window.location.href = url;
  }, [getXeroAuthUrl, companyId]);

  const [deleteXeroToken, { loading: xeroTokenDeleting }] = useMutation(
    XERO_DELETE_TOKEN_MUTATION,
    {
      refetchQueries: ['CompanyIntegrationsEntity'],
      awaitRefetchQueries: true,
    },
  );

  const handleXeroDisconnect = React.useCallback(async () => {
    const xeroIntegrationId = R.pathOr(
      null,
      ['company', 'integration', 'xero', 'id'],
      integrations,
    );

    const response = await deleteXeroToken({
      variables: {
        xeroIntegrationId,
      },
    });

    const success = R.pathOr(false, ['data', 'xeroDeleteToken', 'success'], response);

    if (success) {
      notification.success(t('integration_disconnect_xero_success'));
    } else {
      notification.error(t('integration_disconnect_xero_error'));
    }
  }, [integrations, deleteXeroToken, notification]);

  if (!integrationsLoading && !company) {
    return <Typography>Couldn&apos;t load company integrations</Typography>;
  }

  return (
    <Grid className={classes.integrationsContainer} container justify="center" spacing={5}>
      <Grid className={classes.cardWrapper} item xs={12} lg={4}>
        <Grid component={Paper} container>
          <Grid className={classes.cardHeader} item xs={12}>
            <Typography variant="h6">Accounting Data</Typography>
          </Grid>
          <Grid container item xs={6} style={{ padding: 32, textAlign: 'center' }}>
            <Grid className={classes.xeroWrapper} item xs={12}>
              <XeroLogo className={classes.xeroLogo} />
            </Grid>
            <Grid item xs={12}>
              {isXeroIntegrationConnected ? (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleXeroDisconnect}
                  disabled={integrationsLoading || xeroTokenDeleting}
                >
                  {xeroTokenDeleting ? 'Disconnecting...' : 'Disconnect'}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleXeroConnect}
                  disabled={
                    integrationsLoading || xeroAuthUrlLoading || isQuickBooksIntegrationConnected
                  }
                >
                  Connect
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6} style={{ padding: 32, textAlign: 'center' }}>
            <Grid className={classes.quickbooksWrapper} item xs={12}>
              <QuickbooksLogo className={classes.quickbooksLogo} />
            </Grid>
            <Grid item xs={12}>
              {isQuickBooksIntegrationConnected ? (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleQuickBooksDisconnect}
                  disabled={integrationsLoading || quickBooksTokenDeleting}
                >
                  {quickBooksTokenDeleting ? 'Disconnecting...' : 'Disconnect'}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleQuickBooksConnect}
                  disabled={
                    integrationsLoading || quickBooksAuthUrlLoading || isXeroIntegrationConnected
                  }
                >
                  Connect
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} className={classes.cardWrapper}>
        <Grid>
          <Plaid
            integrationsLoading={integrationsLoading}
            isConnected={isPlaidIntegrationConnected}
            integrations={integrations}
            refetchIntegrations={refetchIntegrations}
          />
        </Grid>
      </Grid>
      {SHOW_STRIPE_CARD && (
        <Grid item xs={12} lg={4} className={classes.cardWrapper}>
          <Grid component={Paper} container>
            <Grid className={classes.cardHeader} item xs={12}>
              <Typography variant="h6">Revenue Data</Typography>
            </Grid>
            <Grid container item xs={12} style={{ padding: 32 }}>
              <Grid className={classes.stripeWrapper} item xs={12}>
                <StripeLogo className={classes.stripeLogo} />
              </Grid>
              <Grid item xs={12}>
                {isStripeIntegrationConnected ? (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleStripeDisconnect}
                    disabled={integrationsLoading || stripeTokenDeleting}
                  >
                    {stripeTokenDeleting ? 'Disconnecting...' : 'Disconnect'}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleStripeConnect}
                    disabled={integrationsLoading || stripeAuthUrlLoading}
                  >
                    Connect
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
