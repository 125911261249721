import { MetricCode } from 'src/types';
import { CashMonitoringColumn } from 'src/types/cashMonitoring';

export const CASH_MONITORING_COLUMNS_ORDER: Array<CashMonitoringColumn> = [
  CashMonitoringColumn.CompanyName,
  CashMonitoringColumn.CashPositionBanking,
  CashMonitoringColumn.CashPositionDate,
  CashMonitoringColumn.MinCashBalanceCovenant,
  CashMonitoringColumn.ChangeInCashLast2Weeks,
  CashMonitoringColumn.ChangeInCashLast4Weeks,
  CashMonitoringColumn.CashRunwayBanking,
  CashMonitoringColumn.MinRunwayCovenant,
  CashMonitoringColumn.ScoreMonth,
  CashMonitoringColumn.Tier,
  CashMonitoringColumn.Score,
  CashMonitoringColumn.ScoreChange,
  CashMonitoringColumn.ScoreTrendMoM,
  CashMonitoringColumn.Strengths,
  CashMonitoringColumn.Weaknesses,
  CashMonitoringColumn.Notes,
  CashMonitoringColumn.ActionItem,
];

export const BASE_SCORE_COMPONENTS: Partial<Record<MetricCode, boolean>> = {
  [MetricCode.TotalRevenue]: true,
  [MetricCode.GrossMargin]: true,
  [MetricCode.NetRevenueRetentionRate]: true,
  [MetricCode.MRRNetChurnRate]: true,
  [MetricCode.GrossCustomerChurnRate]: true,
  [MetricCode.CashPosition]: true,
  [MetricCode.CashRunwayAccounting]: true,
  [MetricCode.NetCashBurnOfGrossProfit]: true,
};

export const SMB_SCORE_COMPONENTS: Partial<Record<MetricCode, boolean>> = {
  ...BASE_SCORE_COMPONENTS,
  [MetricCode.RevenueGrowthRateMoM]: true,
  [MetricCode.CACPayback]: true,
  [MetricCode.YoYRevenueGrowth]: false,
};

export const ENTERPRISE_SCORE_COMPONENTS: Partial<Record<MetricCode, boolean>> = {
  ...BASE_SCORE_COMPONENTS,
  [MetricCode.YoYRevenueGrowth]: true,
  [MetricCode.RevenueGrowthRateMoM]: false,
  [MetricCode.CACPayback]: false,
};
