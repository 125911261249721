import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ACTIONS_TITLE } from 'src/constants';

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: '8px 16px',
  },
}));

export const ActionsTitleRenderer: React.FC = () => {
  const classes = useStyles();
  return <span className={classes.root}>{ACTIONS_TITLE}</span>;
};
