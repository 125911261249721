import React, { createContext, useState } from 'react';

interface DirtyContextValue {
  isDirty: boolean;
  setIsDirty: (v: boolean) => void;
}

export const dirtyContext = createContext<DirtyContextValue>({} as DirtyContextValue);

export const DirtyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  return <dirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</dirtyContext.Provider>;
};
