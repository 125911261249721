import React, { useContext } from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';

import { selectedRowsContext } from 'src/providers/SelectedRowsProvider';

const useStyles = makeStyles(theme => ({
  root: {
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
}));

export const SelectionColumnTitleRenderer: React.FC = () => {
  const selected = useContext(selectedRowsContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Checkbox
        indeterminate={selected.isNotAllRowsSelected && !selected.isAllRowsSelected}
        checked={selected.isAllRowsSelected}
        onChange={selected.toggleAll}
      />
    </div>
  );
};
