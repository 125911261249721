import React from 'react';
import * as R from 'ramda';

import { SORT_DIRECTIONS } from 'src/constants/sort';

export interface Sort {
  field: string;
  order: string;
  toggleSort: (field: string) => void;
}

const SortContext = React.createContext<Sort | null>(null);

const toSortVariables = (sort: Array<{ field: string; order: string }>): any =>
  sort.map(({ field, order }) => {
    if (field.includes('.')) {
      return R.assocPath(field.split('.'), order.toUpperCase(), {});
    }

    return { [field]: order.toUpperCase() };
  });

const useSort = (): Sort => {
  const [field, setField] = React.useState<string>('');
  const [order, setOrder] = React.useState<string>(SORT_DIRECTIONS.DESC);

  const toggleSort = (toggledField: string) => {
    if (field !== toggledField) {
      setField(toggledField);
    } else {
      switch (order) {
        case SORT_DIRECTIONS.DESC: {
          setOrder(SORT_DIRECTIONS.ASC);
          break;
        }
        case SORT_DIRECTIONS.ASC: {
          setOrder(SORT_DIRECTIONS.DESC);
          break;
        }
        default:
          setOrder(SORT_DIRECTIONS.DESC);
      }
    }
  };

  return { field, order, toggleSort };
};

const SortProvider = React.memo(({ children }) => {
  const ctx = useSort();
  return <SortContext.Provider value={ctx}>{children}</SortContext.Provider>;
});

SortProvider.displayName = 'Sort provider';

export { SortContext, toSortVariables, SortProvider };
