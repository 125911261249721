import React from 'react';
import { LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { ProgressStage } from 'src/graphql';
import { ProgressStageAccordion, ProgressStepProps } from 'src/components';
import { isFunction } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  progressStageListContainer: {
    padding: theme.spacing(2),
  },
  list: {
    marginTop: theme.spacing(2),
  },
  loaderStub: {
    minHeight: 4,
  },
}));

interface ProgressStageListProps {
  progressStages: Array<ProgressStage>;
  renderSteps: (stage: ProgressStage, index: number) => Array<ProgressStepProps>;
  upsertStepComments: (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => void;
  upsertStepVisibility?: (step: ProgressStepProps) => void;
  onSelectStep?: (step: ProgressStepProps) => void;
  onChangeStep?: (step: ProgressStepProps, name: string, comment: string) => void;
  initCompanyStepHandler?: (
    stageId: string | null | undefined,
  ) => ((name: string, code: string, comment: string) => void) | undefined;
  loading?: boolean;
}

export const ProgressStageList: React.FC<ProgressStageListProps> = props => {
  const {
    progressStages,
    renderSteps,
    onSelectStep,
    onChangeStep,
    initCompanyStepHandler,
    upsertStepVisibility,
    upsertStepComments,
    loading = false,
  } = props;
  const classes = useStyles();

  const loader = <div className={classes.loaderStub}>{loading ? <LinearProgress /> : null}</div>;

  if (loading && !isFunction(onSelectStep)) return <LinearProgress />;

  return (
    <>
      {loader}
      <div className={classes.progressStageListContainer}>
        <div className={classes.list}>
          {progressStages.map((stage: ProgressStage, index: number) => {
            const onChangeCompanyStep = initCompanyStepHandler
              ? initCompanyStepHandler(stage.id)
              : null;

            return (
              <ProgressStageAccordion
                key={stage.id}
                title={stage.name as string}
                steps={renderSteps(stage, index)}
                onSelectStep={onSelectStep}
                onChangeStep={onChangeStep}
                upsertStepVisibility={upsertStepVisibility}
                upsertStepComments={upsertStepComments}
                insertCompanyStep={onChangeCompanyStep}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
