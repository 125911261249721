import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Paper, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  SplineSeries,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { ScaleObject, Stack, Animation, ValueScale } from '@devexpress/dx-react-chart';

import { LegendLabel } from './LegendLabel';
import { EmptyMetricChart } from './EmptyMetricChart';
import { isFunction, isNegative } from 'src/utils';

const DEFAULT_HEIGHT_PAPER = 400;
const DEFAULT_HEIGHT_CHART = DEFAULT_HEIGHT_PAPER - 100;
const DEFAULT_HEIGHT_EMPTY_CHART = DEFAULT_HEIGHT_PAPER - 100;

const useStyles = makeStyles((theme: Theme) => ({
  chartPaper: ({
    height,
  }: {
    height: number | undefined;
  }): CreateCSSProperties<{ height: number | undefined }> => ({
    padding: theme.spacing(2),
    height: height ? height : DEFAULT_HEIGHT_PAPER,
  }),
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    color: theme.palette.secondary.main,
    marginLeft: '15px',
  },
  negative: {
    color: '#eb4336',
  },
  fakeChartContainer: {
    position: 'relative',
  },
  fakeDataText: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface yAxisProps {
  valueKey: string;
  position?: 'right' | 'left';
  formatValue?: (value: string) => string;
}

interface ChartProps {
  label?: string;
  valueKey: string;
  xAxisKey?: string;
}

interface LineProps extends ChartProps {
  lineColor?: string;
}

interface BarProps extends ChartProps {
  barColor?: string;
}

interface MetricMultiChartProps {
  chartName?: string;
  currentValue?: string | number | null;
  progressValue?: number | null;
  formatProgressValue?: (value?: number | null) => string | number | null;
  data: Array<any>;
  barCharts?: Array<BarProps>;
  lineCharts?: Array<LineProps>;
  yAxes: Array<yAxisProps>;
  height?: number;
  width?: number;
  withLegend?: boolean;
}

export const MultiMetricChart: React.FC<MetricMultiChartProps> = ({
  chartName,
  currentValue = null,
  progressValue,
  formatProgressValue,
  data,
  barCharts,
  lineCharts,
  withLegend,
  yAxes,
  height,
}) => {
  const classes = useStyles({ height });
  const chartHeight = height ? height - 50 : DEFAULT_HEIGHT_CHART;

  const { palette } = useTheme();

  const getUniqKeyComponent = (name: string, idx: number) => `${name}-${idx}`;

  const isEmptyChartData = R.isNil(data) || R.isEmpty(data);

  const chartNameRender = Boolean(chartName) && (
    <Typography variant="subtitle2">{chartName}</Typography>
  );

  const preparedRenderProgressValue = React.useMemo(() => {
    return formatProgressValue && isFunction(formatProgressValue)
      ? formatProgressValue(progressValue)
      : progressValue;
  }, [progressValue, formatProgressValue]);

  const progress = !R.isNil(progressValue) && (
    <Typography
      className={clsx({
        [classes.progress]: true,
        [classes.negative]: isNegative(progressValue),
      })}
      variant="subtitle2"
    >
      {preparedRenderProgressValue}
    </Typography>
  );

  const subtitle = !R.isNil(currentValue) ? currentValue : '-';
  const subtitleRender = (
    <Box className={classes.subtitleContainer}>
      <Typography variant="subtitle1">{subtitle}</Typography>
      {progress}
    </Box>
  );

  const valueScales = yAxes.map((yAxisProps, idx) => (
    <ValueScale key={getUniqKeyComponent(yAxisProps.valueKey, idx)} name={yAxisProps.valueKey} />
  ));

  const valueAxes = yAxes.map((yAxisProps, idx) => (
    <ValueAxis
      key={getUniqKeyComponent(yAxisProps.valueKey, idx)}
      position={yAxisProps.position}
      scaleName={yAxisProps.valueKey}
      tickFormat={(scale: ScaleObject) => (tick: string) => {
        const { formatValue } = yAxisProps;

        if (formatValue && isFunction(formatValue)) {
          return formatValue(tick);
        }

        return tick;
      }}
    />
  ));

  const renderLegend = () => {
    if (!withLegend || isEmptyChartData) {
      return null;
    }

    const barLabels =
      barCharts?.map((bar: BarProps) => (
        <LegendLabel key={bar.valueKey} label={bar.label} color={bar.barColor} />
      )) || [];

    const lineLabels =
      lineCharts?.map((line: LineProps) => (
        <LegendLabel key={line.valueKey} label={line.label} color={line.lineColor} />
      )) || [];

    return <div className={classes.legendContainer}>{[...barLabels, ...lineLabels]}</div>;
  };

  const customData = data.map(item => {
    lineCharts?.forEach((line: LineProps) => {
      const valueByLineKey = item[line.valueKey];
      item = {
        ...item,
        [line.valueKey]: valueByLineKey ?? 0,
      };
    });

    return item;
  });

  return (
    <Paper className={classes.chartPaper}>
      {chartNameRender}
      {subtitleRender}
      {isEmptyChartData ? (
        <EmptyMetricChart height={DEFAULT_HEIGHT_EMPTY_CHART} />
      ) : (
        <Chart data={customData} height={chartHeight}>
          <ArgumentAxis />

          {valueScales}

          {valueAxes}

          {barCharts?.map((bar: BarProps, idx: number) => (
            <BarSeries
              key={getUniqKeyComponent(bar.valueKey, idx)}
              name={bar.label}
              valueField={bar.valueKey}
              argumentField={bar.xAxisKey}
              color={bar.barColor || palette.secondary.main}
              scaleName={bar.valueKey}
            />
          ))}

          {lineCharts?.map((line: LineProps, idx: number) => (
            <SplineSeries
              key={getUniqKeyComponent(line.valueKey, idx)}
              name={line.label}
              valueField={line.valueKey}
              argumentField={line.xAxisKey}
              color={line.lineColor || palette.secondary.main}
              scaleName={line.valueKey}
            />
          ))}

          <Stack />
          <Animation />
        </Chart>
      )}

      {renderLegend()}
    </Paper>
  );
};
