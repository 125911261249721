import { gql } from '8base-react-sdk';

export const PLAID_GET_LINK_TOKEN_MUTATION = gql`
  mutation PlaidGetLinkToken($companyId: ID!) {
    plaidGetLinkToken(companyId: $companyId) {
      token
    }
  }
`;

export const PLAID_SAVE_TOKEN_MUTATION = gql`
  mutation PlaidSaveToken($companyId: ID!, $publicToken: String!, $accountIds: [String!]!) {
    plaidSaveToken(companyId: $companyId, publicToken: $publicToken, accountIds: $accountIds) {
      success
    }
  }
`;

export const PLAID_DELETE_TOKEN_MUTATION = gql`
  mutation PlaidDeleteToken($plaidIntegrationId: ID!) {
    plaidDeleteToken(plaidIntegrationId: $plaidIntegrationId) {
      success
    }
  }
`;

export const PLAID_UPDATE_TOKEN_MUTATION = gql`
  mutation PlaidUpdateToken(
    $plaidIntegrationId: ID!
    $companyId: ID!
    $publicToken: String!
    $accountIds: [String!]!
  ) {
    plaidUpdateToken(
      plaidIntegrationId: $plaidIntegrationId
      companyId: $companyId
      publicToken: $publicToken
      accountIds: $accountIds
    ) {
      success
    }
  }
`;

export const PLAID_REQUEST_METRICS_UPDATE_MUTATION = gql`
  mutation PlaidRequestMetricsUpdate($companyId: ID!) {
    plaidRequestMetricsUpdate(companyId: $companyId) {
      asyncTaskId
    }
  }
`;
