import React, { FC } from 'react';
import * as R from 'ramda';
import { Link, useParams } from 'react-router-dom';
import { Box, ListItem, ListItemText, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DateFormatPatterns, formatDate } from 'src/utils/date';

const useStyles = makeStyles((theme: Theme) => ({
  createdByTitle: {
    color: theme.palette.text.secondary,
  },
  createdDate: {
    color: theme.palette.text.secondary,
    marginLeft: 5,
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
    width: '100%',
  },
  listItem: {
    height: 'auto',
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: (props: { isActive: boolean }) =>
      props.isActive ? theme.customPalette.background.activeCard : theme.palette.background.paper,
  },
  title: {
    fontSize: 16,
    marginRight: 10,
  },
}));

type RouteParams = {
  id: string;
  noteId: string;
};

interface NoteProps {
  note: any;
  path: string;
}

export const Note: FC<NoteProps> = props => {
  const { note, path } = props;

  const params: RouteParams = useParams();

  const classes = useStyles({
    isActive: R.equals(params?.noteId, note?.id),
  });

  const createdAt = formatDate(note?.createdAt, DateFormatPatterns.smallDateWithLocalTime);

  const firstName = R.pathOr(null, ['createdBy', 'firstName'], note);
  const lastName = R.pathOr(null, ['createdBy', 'lastName'], note);

  const createdByTitle = firstName && lastName ? `by ${firstName} ${lastName}` : '';

  return (
    <ListItem className={classes.listItem}>
      <Link key={note.id} to={`${path}/notes/${note.id}`} className={classes.link}>
        <ListItemText
          primary={
            <Box
              component={'span'}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography className={classes.title} component={'span'} variant="subtitle2" noWrap>
                {note.title}
              </Typography>
              <Typography className={classes.createdDate} component={'span'} variant="body2">
                {createdAt}
              </Typography>
            </Box>
          }
          secondary={
            <Box component={'span'} display="flex" flexDirection="column">
              {!R.isEmpty(createdByTitle) && (
                <Typography component={'span'} variant="body2" className={classes.createdByTitle}>
                  {createdByTitle}
                </Typography>
              )}
              <Typography component={'span'} variant="body2" color="textPrimary" noWrap>
                {note.body}
              </Typography>
            </Box>
          }
        />
      </Link>
    </ListItem>
  );
};
