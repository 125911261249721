import * as yup from 'yup';

import { EventType } from 'src/graphql';
import { EmailRequiredSchema } from './common';
import {
  BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST,
  COMPANY_TEAMMATE_APP_ROLE_NAMES_LIST,
} from 'src/types';

export const BigfootEmployeeInvitationSendEventSchema = yup
  .object({
    type: yup
      .string()
      .oneOf([EventType.BigfootEmployeeInvitationSend])
      .required(),
    payload: yup
      .object({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: EmailRequiredSchema,
        appRoleName: yup
          .string()
          // TODO: replcae with .oneOf(BIGFOOT_EMPLOYEE_APP_BUILDER_ROLES_LIST as const)
          // this is the only one way to infer correct type using such code:
          // export type Event = yup.InferType<typeof eventSchema>
          .oneOf(BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST)
          .required('Role is required'),
      })
      .required(),
  })
  .required();

export const BigfootEmployeeEditEventSchema = yup
  .object({
    type: yup
      .string()
      .oneOf([EventType.BigfootEmployeeEdit])
      .required(),
    payload: yup
      .object({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: EmailRequiredSchema,
        appRoleName: yup
          .string()
          // TODO: replcae with .oneOf(BIGFOOT_EMPLOYEE_APP_BUILDER_ROLES_LIST as const)
          // this is the only one way to infer correct type using such code:
          // export type Event = yup.InferType<typeof eventSchema>
          .oneOf(BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST)
          .required('Role is required'),
      })
      .required(),
  })
  .required();

export const CompanyTeammateInvitationSendEventSchema = yup
  .object({
    type: yup
      .string()
      .oneOf([EventType.CompanyTeammateInvitationSend])
      .required(),
    payload: yup
      .object({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: EmailRequiredSchema,
        title: yup.string().nullable(),
        companyId: yup.string().required(),
        appRoleName: yup
          .string()
          // TODO: replcae with .oneOf(COMPANY_TEAMMATE_APP_BUILDER_ROLES_LIST as const)
          // this is the only one way to infer correct type using such code:
          // export type Event = yup.InferType<typeof eventSchema>
          .oneOf(COMPANY_TEAMMATE_APP_ROLE_NAMES_LIST)
          .required('Role is required'),
      })
      .required(),
  })
  .required();

export const CompanyTeammateEditEventSchema = yup
  .object({
    type: yup
      .string()
      .oneOf([EventType.CompanyTeammateEdit])
      .required(),
    payload: yup
      .object({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: EmailRequiredSchema,
        title: yup.string().nullable(),
        companyId: yup.string().required(),
        appRoleName: yup
          .string()
          // TODO: replcae with .oneOf(COMPANY_TEAMMATE_APP_BUILDER_ROLES_LIST as const)
          // this is the only one way to infer correct type using such code:
          // export type Event = yup.InferType<typeof eventSchema>
          .oneOf(COMPANY_TEAMMATE_APP_ROLE_NAMES_LIST)
          .required('Role is required'),
      })
      .required(),
  })
  .required();
