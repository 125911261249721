import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, Field, TextField, FileField } from 'src/app-builder';

import { SubmitButton } from 'src/components';
import { DIALOGS } from 'src/dialogs';
import { validateDataByYup } from 'src/utils';
import { ComposeMessageSchema } from 'src/utils/schemas';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface FormInboxDialogProps {
  title: string;
  onSubmit: (data: any, form: any) => Promise<void>;
  openModal: (id?: string, args?: any) => void;
  closeModal: (id?: string) => void;
  open: boolean;
}

export const FormInboxDialog: React.FC<FormInboxDialogProps> = props => {
  const { closeModal, openModal, onSubmit, open, title } = props;
  const classes = useStyles();

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  return (
    <Form
      type="CREATE"
      onSubmit={onSubmit}
      validate={values => validateDataByYup(ComposeMessageSchema, values)}
    >
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">{title}</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <Field
                  name="subject"
                  label="Subject"
                  component={TextField}
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="body"
                  label="Body"
                  component={TextField}
                  disabled={submitting}
                  rows={5}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="attachments"
                  label="Attachments"
                  component={FileField}
                  maxFiles={3}
                  fullWidth
                  disabled={submitting}
                  sessionCache
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              color="secondary"
              onClick={() => onClose(form, pristine)}
              disabled={submitting}
            >
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Send message
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
