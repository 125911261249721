import React from 'react';
import { useApolloClient } from 'react-apollo';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  CUSTOM_COVENANT_LIST_QUERY,
  CREATE_CUSTOM_COVENANT_VALUE_MUTATION,
  UPDATE_CUSTOM_COVENANT_VALUE_MUTATION,
  DELETE_CUSTOM_COVENANT_VALUE_MUTATION,
} from 'src/graphql';
import { useNotification, usePortfolioCompany } from '.';
import { t } from 'src/utils';

export type CustomCovenantData = {
  covenant?: string | number | null;
  actual?: string | number | null;
  difference?: string | number | null;
  inCompliance?: string | null;
};

enum CustomCovenantTypeName {
  CovenantValueListResponse = 'CovenantValueListResponse',
}

type CustomCovenantsHookResponse = {
  customCovenantValues: any;
  createCustomCovenant: (
    covenantId: string,
    date: string,
    covenantData: CustomCovenantData,
  ) => Promise<void>;
  updateCustomCovenant: (
    customCovenantId: string,
    date: string,
    covenantData: CustomCovenantData,
  ) => Promise<void>;
  upsertCustomCovenant: (
    covenantId: string,
    date: string,
    covenantData: CustomCovenantData,
  ) => Promise<void>;
  deleteCustomCovenant: (covenantId: string, date: string) => Promise<void>;
  loading: boolean;
};

export const useCustomCovenants = (): CustomCovenantsHookResponse => {
  const apolloClient = useApolloClient();

  const { data: portfolioCompany } = usePortfolioCompany();
  const companyId = portfolioCompany?.company?.id as string;

  const notification = useNotification();

  const customCovenantListQueryVariables = React.useMemo(() => {
    return {
      filter: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    };
  }, [companyId]);

  const { data: customCovenantsData } = useQuery(CUSTOM_COVENANT_LIST_QUERY, {
    variables: customCovenantListQueryVariables,
    skip: !companyId,
  });
  const customCovenantValues = React.useMemo(
    () => customCovenantsData?.customCovenantValuesList?.items || [],
    [customCovenantsData?.customCovenantValuesList?.items],
  );

  const getCustomCovenantsFromCache = React.useCallback(() => {
    try {
      const data = apolloClient.readQuery({
        query: CUSTOM_COVENANT_LIST_QUERY,
        variables: customCovenantListQueryVariables,
      });

      return data?.customCovenantValuesList?.items || [];
    } catch (error) {
      console.error({ error });
    }
  }, [apolloClient, customCovenantListQueryVariables]);

  const addCustomCovenantToCache = React.useCallback(
    (customCovenant: any) => {
      try {
        const customCovenantsFromCache = getCustomCovenantsFromCache();
        const updatedCustomCovenants = [...customCovenantsFromCache, customCovenant];

        apolloClient.writeQuery({
          query: CUSTOM_COVENANT_LIST_QUERY,
          variables: customCovenantListQueryVariables,
          data: {
            customCovenantValuesList: {
              items: updatedCustomCovenants,
              __typename: CustomCovenantTypeName.CovenantValueListResponse,
            },
          },
        });
      } catch (error) {
        console.error({ error });
      }
    },
    [apolloClient, customCovenantListQueryVariables, getCustomCovenantsFromCache],
  );

  const removeCustomCovenantFromCache = React.useCallback(
    (customCovenantId: any) => {
      try {
        const customCovenantsFromCache = getCustomCovenantsFromCache();
        const updatedCustomCovenants = customCovenantsFromCache.filter((covenant: any) => {
          return covenant.id !== customCovenantId;
        });

        apolloClient.writeQuery({
          query: CUSTOM_COVENANT_LIST_QUERY,
          variables: customCovenantListQueryVariables,
          data: {
            customCovenantValuesList: {
              items: updatedCustomCovenants,
              __typename: CustomCovenantTypeName.CovenantValueListResponse,
            },
          },
        });
      } catch (error) {
        console.error({ error });
      }
    },
    [apolloClient, customCovenantListQueryVariables, getCustomCovenantsFromCache],
  );

  const [createCustomCovenantMutation, { loading: creating }] = useMutation(
    CREATE_CUSTOM_COVENANT_VALUE_MUTATION,
    {
      update(apolloClient, { data: createdCustomCovenantResponse }) {
        const createdCustomCovenant =
          createdCustomCovenantResponse?.customCovenantValueCreate ?? null;

        if (Boolean(createdCustomCovenant)) {
          addCustomCovenantToCache(createdCustomCovenant);
        }
      },
    },
  );

  const [updateCustomCovenantMutation, { loading: updating }] = useMutation(
    UPDATE_CUSTOM_COVENANT_VALUE_MUTATION,
  );

  const [deleteCustomCovenantMutation, { loading: deleting }] = useMutation(
    DELETE_CUSTOM_COVENANT_VALUE_MUTATION,
  );

  const createCustomCovenant = React.useCallback(
    async (covenantId: string, date: string, covenantData: CustomCovenantData) => {
      try {
        await createCustomCovenantMutation({
          variables: {
            data: {
              covenant: { connect: { id: covenantId } },
              company: { connect: { id: companyId } },
              date,
              actual: covenantData.actual,
              difference: covenantData.difference,
              inCompliance: covenantData.inCompliance,
            },
          },
        });
        notification.success(t('custom_covenant_metric_create_success'));
      } catch (error) {
        notification.error(t('custom_covenant_metric_create_error'));
        throw error;
      }
    },
    [companyId, createCustomCovenantMutation, notification],
  );

  const updateCustomCovenant = React.useCallback(
    async (customCovenantId: string, date: string, covenantData: CustomCovenantData) => {
      try {
        await updateCustomCovenantMutation({
          variables: {
            data: {
              date,
              actual: covenantData.actual,
              difference: covenantData.difference,
              inCompliance: covenantData.inCompliance,
            },
            filter: {
              id: customCovenantId,
            },
          },
        });
        notification.success(t('custom_covenant_metric_update_success'));
      } catch (error) {
        notification.error(t('custom_covenant_metric_update_error'));
        throw error;
      }
    },
    [updateCustomCovenantMutation, notification],
  );

  const upsertCustomCovenant = React.useCallback(
    async (covenantId: string, date: string, covenantData: CustomCovenantData) => {
      const customCovenantValue = customCovenantValues.find((customCovenantValue: any) => {
        return customCovenantValue.date === date && customCovenantValue.covenant.id === covenantId;
      });

      const isExistCustomCovenantValue = Boolean(customCovenantValue);

      if (isExistCustomCovenantValue) {
        await updateCustomCovenant(customCovenantValue.id, date, covenantData);
      } else {
        await createCustomCovenant(covenantId, date, covenantData);
      }
    },
    [updateCustomCovenant, createCustomCovenant, customCovenantValues],
  );

  const deleteCustomCovenant = React.useCallback(
    async (covenantId: string, date: string) => {
      const customCovenantValue = customCovenantValues.find((customCovenantValue: any) => {
        return customCovenantValue.date === date && customCovenantValue.covenant.id === covenantId;
      });

      const customCovenantValueId = customCovenantValue?.id;

      if (!customCovenantValueId) {
        return;
      }

      try {
        const { data: response } = (await deleteCustomCovenantMutation({
          variables: {
            customCovenantValueId,
          },
        })) as any;

        const isDeleted = response?.customCovenantValueDelete ?? false;
        if (isDeleted) {
          removeCustomCovenantFromCache(customCovenantValueId);
        }

        notification.success(t('custom_covenant_metric_delete_success'));
      } catch (error) {
        notification.error(t('custom_covenant_metric_delete_error'));
        throw error;
      }
    },
    [
      customCovenantValues,
      deleteCustomCovenantMutation,
      notification,
      removeCustomCovenantFromCache,
    ],
  );

  return {
    customCovenantValues,
    createCustomCovenant,
    updateCustomCovenant,
    upsertCustomCovenant,
    deleteCustomCovenant,
    loading: creating || updating || deleting,
  };
};
