import React from 'react';
import { useQuery } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import { useRouterQuery } from 'src/hooks';
import * as R from 'ramda';
import { REPORT_METRIC_TYPES } from 'src/constants';
import { convertToQueryParams, isLoanApplicationCompany } from 'src/utils';
import { LOAN_APPLICATION_QUERY, PORTFOLIO_COMPANIES_QUERY } from 'src/graphql';
import { CompanyType, Report as MetricReport } from 'src/types';
import {
  LoanApplicationsEntityQuery,
  LoanApplicationsEntityQueryVariables,
  PortfolioCompaniesEntityQuery,
  PortfolioCompaniesEntityQueryVariables,
} from 'src/graphql';

type EntityQueryData = LoanApplicationsEntityQuery | PortfolioCompaniesEntityQuery;
type EntityQueryVariables =
  | LoanApplicationsEntityQueryVariables
  | PortfolioCompaniesEntityQueryVariables;

type ReportStatus = [
  (report: MetricReport, from: string | null, till: string | null) => void,
  {
    data: {
      companyId: string;
      activeReport?: MetricReport;
    };
    loading: boolean;
    filter: {
      from: string | null;
      till: string | null;
    };
  },
];

export const useUnderwritingReport = (companyType: CompanyType): ReportStatus => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const query = useRouterQuery();

  const from = R.pathOr(null, ['from'], query);
  const till = R.pathOr(null, ['till'], query);
  const reportName = R.pathOr('', ['reportName'], query);

  const activeReport = React.useMemo<MetricReport | undefined>(
    () => REPORT_METRIC_TYPES.find(report => report.label === reportName),
    [reportName],
  );

  const entityQuery = isLoanApplicationCompany(companyType)
    ? LOAN_APPLICATION_QUERY
    : PORTFOLIO_COMPANIES_QUERY;

  const { data: companyData, loading: companyLoading } = useQuery<
    EntityQueryData,
    EntityQueryVariables
  >(entityQuery, {
    variables: { id: params?.id },
    skip: !params.id,
  });

  const companyId = R.pathOr<string>('', [companyType, 'company', 'id'], companyData);

  React.useEffect(() => {
    if (R.isEmpty(reportName)) {
      history.push({
        search: convertToQueryParams({
          reportName: REPORT_METRIC_TYPES[0].label,
        }),
      });
    }
  }, [query, reportName, history]);

  const onChangeActiveReport = React.useCallback(
    (report: MetricReport, from: string | null, till: string | null) => {
      if (!R.isNil(report)) {
        history.push({
          search: convertToQueryParams({ reportName: report.label, from, till }),
        });
      }
    },
    [history],
  );

  return [
    onChangeActiveReport,
    {
      data: { companyId, activeReport },
      loading: companyLoading,
      filter: { from, till },
    },
  ];
};
