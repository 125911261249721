import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { useModal } from 'src/app-builder';

import { SubmitButton } from 'src/components';

import { DIALOGS } from './constants';
import { isFunction } from 'src/utils';

interface ConfirmationDialogProps {}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = () => {
  const [loading, setLoading] = React.useState(false);

  const { open, closeModal, args } = useModal(DIALOGS.CONFIRMATION_DIALOG);

  const onConfirm = React.useCallback(async () => {
    if (isFunction(args?.onConfirm)) {
      setLoading(true);

      try {
        await args.onConfirm();
      } catch (e) {}

      setLoading(false);
    }

    closeModal();
  }, [args, closeModal]);

  const onClose = React.useCallback(() => {
    if (isFunction(args?.onCancel)) {
      args.onCancel();
    }

    closeModal();
  }, [args, closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Confirmation</DialogTitle>

      <DialogContent>
        <DialogContentText>{args.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton color="secondary" variant="contained" loading={loading} onClick={onConfirm}>
          Confirm
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
