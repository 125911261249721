import React from 'react';
import * as R from 'ramda';
import { useMutation } from 'react-apollo';
import { usePlaidLink } from 'react-plaid-link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
  Link,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { useNotification } from 'src/hooks';
import {
  PLAID_GET_LINK_TOKEN_MUTATION,
  PLAID_SAVE_TOKEN_MUTATION,
  PlaidIntegration,
  PLAID_DELETE_TOKEN_MUTATION,
  PLAID_UPDATE_TOKEN_MUTATION,
} from 'src/graphql';
import { ACTIONS_TITLE } from 'src/constants';
import { commonErrorResolver, t } from 'src/utils';

import { ReactComponent as PlaidLogo } from 'src/assets/logos/plaid.svg';

interface PlaidProps {
  isConnected: boolean;
  integrationsLoading: boolean;
  integrations: any;
  refetchIntegrations: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    position: 'relative',
  },
  plaidWrapper: { height: 120, marginBottom: theme.spacing(2) },
  secureConnection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  plaidLogo: { width: 64 },
  plaidLogoHeader: {
    height: 36,
  },
  cardHeader: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  stickyRight: {
    width: 64,
    position: 'sticky',
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
  cardFooterButton: {
    minHeight: 64,
  },
  plaidAccountItem: {
    marginLeft: theme.spacing(0.5),
    display: 'inline',
    color: theme.palette.text.secondary,
  },
}));

interface PlaidMetadata {
  accounts: Array<{
    id: string;
  }>;
}

// @todo refactoring
export const Plaid: React.FC<PlaidProps> = ({
  isConnected,
  integrationsLoading,
  integrations,
  refetchIntegrations,
}) => {
  const classes = useStyles();
  const notification = useNotification();

  const companyId = R.pathOr(null, ['company', 'id'], integrations);

  const [plaidLinkToken, setPlaidLinkToken] = React.useState<string>('');

  const [tableRowMenuAnchorEl, setTableRowMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const tableRowMenuId = React.useRef<string | null | undefined>(null);

  const openTableRowMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string | null | undefined) => {
      setTableRowMenuAnchorEl(event.currentTarget);
      tableRowMenuId.current = id;
    },
    [setTableRowMenuAnchorEl],
  );

  const closeTableRowMenu = React.useCallback(() => {
    setTableRowMenuAnchorEl(null);
  }, [setTableRowMenuAnchorEl]);

  const [plaidGetLinkToken, { loading: plaidGetLinkTokenLoading }] = useMutation(
    PLAID_GET_LINK_TOKEN_MUTATION,
    {
      awaitRefetchQueries: true,
    },
  );

  const [plaidSaveToken, { loading: plaidTokenSaving }] = useMutation(PLAID_SAVE_TOKEN_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['CompanyIntegrationsEntity'],
  });

  const [plaidUpdateToken, { loading: plaidTokenUpdating }] = useMutation(
    PLAID_UPDATE_TOKEN_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['CompanyIntegrationsEntity'],
    },
  );

  const [deletePlaidToken] = useMutation(PLAID_DELETE_TOKEN_MUTATION, {
    refetchQueries: ['CompanyIntegrationsEntity'],
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await plaidGetLinkToken({
        variables: {
          companyId,
        },
      });

      const linkToken = R.pathOr('', ['data', 'plaidGetLinkToken', 'token'], response);
      setPlaidLinkToken(linkToken);
    };

    if (!R.isNil(companyId)) {
      fetchData();
    }
  }, [plaidGetLinkToken, companyId]);

  const handleSuccessConnect = React.useCallback(
    async (token: string, { accounts }: PlaidMetadata) => {
      try {
        const isTokenToUpdate = !R.isNil(tableRowMenuId.current);
        const commonMutationVariables = {
          publicToken: token,
          accountIds: accounts.map(account => account.id),
          companyId,
        };

        if (isTokenToUpdate) {
          const response = await plaidUpdateToken({
            variables: {
              plaidIntegrationId: tableRowMenuId.current,
              ...commonMutationVariables,
            },
          });

          const success = R.pathOr(false, ['data', 'plaidUpdateToken', 'success'], response);

          if (success) {
            notification.success(t('integration_update_plaid_success'));

            refetchIntegrations();
          } else {
            notification.error(t('integration_update_plaid_error'));
          }
        } else {
          const response = await plaidSaveToken({
            variables: {
              ...commonMutationVariables,
            },
          });

          const success = R.pathOr(false, ['data', 'plaidSaveToken', 'success'], response);

          if (success) {
            notification.success(t('integration_connect_plaid_success'));

            refetchIntegrations();
          } else {
            notification.error(t('integration_connect_plaid_error'));
          }
        }
      } catch (error) {
        notification.error(commonErrorResolver(error));
      } finally {
        tableRowMenuId.current = null;
      }
    },
    [plaidUpdateToken, plaidSaveToken, notification, refetchIntegrations, companyId],
  );

  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: handleSuccessConnect,
  });

  const plaidIntegrations: PlaidIntegration[] = React.useMemo(() => {
    return R.pathOr([], ['company', 'integration', 'plaid', 'items'], integrations);
  }, [integrations]);

  const handleDisconnect = React.useCallback(async () => {
    closeTableRowMenu();

    const response = await deletePlaidToken({
      variables: {
        plaidIntegrationId: tableRowMenuId.current,
      },
    });

    const success = R.pathOr(false, ['data', 'plaidDeleteToken', 'success'], response);

    if (success) {
      notification.success(t('integration_disconnect_plaid_success'));
    } else {
      notification.error(t('integration_disconnect_plaid_error'));
    }

    tableRowMenuId.current = null;
  }, [closeTableRowMenu, deletePlaidToken, notification]);

  const handleConnect = React.useCallback(() => {
    closeTableRowMenu();

    open();
  }, [closeTableRowMenu, open]);

  return (
    <React.Fragment>
      {!isConnected && (
        <Grid component={Paper} container direction="column" style={{ textAlign: 'center' }}>
          {/* header styles */}
          <Grid item className={classes.cardHeader}>
            <Typography variant="h6">Banking Data</Typography>
          </Grid>
          <Grid item container xs={12} style={{ padding: 32 }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.plaidWrapper} xs={12}>
                  <SvgIcon fontSize="large" color="secondary" component={icons.AccountBalance} />
                  <SvgIcon fontSize="large" color="disabled" component={icons.ArrowRightAlt} />
                  <SvgIcon fontSize="large" color="secondary" component={icons.AddToQueue} />
                  <Typography
                    className={classes.secureConnection}
                    color="textSecondary"
                    variant="body2"
                  >
                    Secure connection powered by&nbsp;
                    <PlaidLogo className={classes.plaidLogo} />
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {plaidGetLinkTokenLoading ||
                  plaidTokenSaving ||
                  plaidTokenUpdating ||
                  integrationsLoading ? (
                    <CircularProgress size={32} />
                  ) : (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={handleConnect}
                      disabled={!ready}
                    >
                      Connect
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {isConnected && (
        <Grid component={Paper} container direction="column">
          <Grid item className={classes.cardHeader}>
            <PlaidLogo className={classes.plaidLogoHeader} />
          </Grid>
          <Grid item container justify="space-between" direction="column" spacing={4}>
            <Grid item>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell className={classes.stickyRight}>{ACTIONS_TITLE}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {plaidIntegrations.map(plaidIntegration => (
                    <TableRow key={plaidIntegration.id}>
                      <TableCell>
                        <Typography variant="body1">
                          {plaidIntegration.institutionMetadata.name}
                        </Typography>
                        <Link href={plaidIntegration.institutionMetadata.url} color="secondary">
                          {plaidIntegration.institutionMetadata.url}
                        </Link>
                        <Grid container>
                          {plaidIntegration.accounts?.items?.map(integrationAccount => (
                            <Grid item key={integrationAccount.id}>
                              {integrationAccount.name}
                              <Typography variant="body2" className={classes.plaidAccountItem}>
                                {integrationAccount.mask}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.stickyRight}>
                        <IconButton onClick={event => openTableRowMenu(event, plaidIntegration.id)}>
                          <icons.MoreVert />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Menu
                anchorEl={tableRowMenuAnchorEl}
                keepMounted
                open={Boolean(tableRowMenuAnchorEl)}
                onClose={closeTableRowMenu}
              >
                <MenuItem onClick={handleDisconnect}>Delete</MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Grid item>
            <Button className={classes.cardFooterButton} fullWidth onClick={handleConnect}>
              Add institution
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
