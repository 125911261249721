import React from 'react';

import { MetricCell } from './index';
import { MetricCellHeader, MetricCellSaaS } from 'src/components';

import { MetricValue } from 'src/graphql';
import { MetricCode, ScoreArrowType } from 'src/types';

interface Params {
  metricValue: MetricValue;
  idx: number;
  prevMetricValue: React.MutableRefObject<any>;
  date: string;
  metricCode: MetricCode;
  metricName: string;
  arrowType: ScoreArrowType | null;
}

export const renderValueColumn = ({
  metricValue,
  idx,
  date,
  metricCode,
  metricName,
  arrowType,
}: Params): React.ReactNode => {
  const settings: Record<any, any> = {
    children: null,
    props: {},
  };

  switch (idx) {
    case 0: {
      settings.children = <MetricCellSaaS arrowType={arrowType} metricValue={metricValue} />;
      settings.props.colSpan = 2;
      settings.props.align = 'center';
      break;
    }
    case 1: {
      settings.children = <MetricCellHeader type="value" />;
      settings.props.align = 'center';
      break;
    }
    default: {
      settings.children = (
        <MetricCell
          value={metricValue}
          date={date}
          metricCode={metricCode}
          metricName={metricName}
          metricIndex={idx}
        />
      );
    }
  }

  return settings;
};
