import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AuthRoutes } from 'src/constants';

export const AuthPage: React.FC = () => {
  const history = useHistory();

  React.useEffect(() => {
    history.push(AuthRoutes.Login);
  }, [history]);

  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
};
