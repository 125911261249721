import * as yup from 'yup';

export const ComposeMessageSchema = yup
  .object({
    subject: yup
      .string()
      .trim()
      .required('Subject message is required'),
    body: yup
      .string()
      .trim()
      .required('Body message is required'),
  })
  .nullable()
  .required();
