import React from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Theme,
  ListItemIcon,
} from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo';
import * as icons from '@material-ui/icons';
import {
  ModalsContext,
  useSelectedRows,
  usePagination,
  useSort,
  toSortVariables,
  useSearch,
  useSelectedColumns,
  EntitiesTableCellValue,
  EntitiesTableActionsPopover,
  EntitiesTableToolbar,
  useAllowed,
} from 'src/app-builder';

import { TableHeader, EmptyScreen } from 'src/components';
import { useNotification } from 'src/hooks';
import {
  USERS_TABLE_QUERY,
  INVITATION_RESEND_MUTATION,
  INVITATION_CANCEL_MUTATION,
  Role,
} from 'src/graphql';
import { Permission, ACTIONS_TITLE, AREAS } from 'src/constants';
import {
  AppRoleName,
  APP_ROLE_NAMES_LIST,
  APP_ROLES,
  BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST,
} from 'src/types';
import {
  ConfirmationDialog,
  DIALOGS,
  UserDeleteDialog,
  UserEditDialog,
  UserInviteDialog,
} from 'src/dialogs';
import { bracketNotationToPath, getFullName, commonErrorResolver, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
  stickyLeft: {
    position: 'sticky',
    left: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      height: 1,
      background: theme.customPalette.border.table,
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
    },
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  stickyRight: {
    width: 80,
    position: 'sticky',
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      height: 1,
      background: theme.customPalette.border.table,
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
    },
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
}));

export const SettingsUsersPage: React.FC<any> = () => {
  const { openModal } = React.useContext(ModalsContext);

  const totalRows = React.useRef<number>(0);
  const { page, perPage, handlePageChange, handlePerPageChange, perPageOptions } = usePagination({
    initialCount: totalRows.current,
  });

  const { sort, createOnSort } = useSort();

  const { search, handleSearchChange } = useSearch();

  const notification = useNotification();

  const isAllowed = useAllowed();

  const [canInvite, canDelete, canEdit] = [
    isAllowed(Permission.UsersInvite),
    isAllowed(Permission.UsersDelete),
    isAllowed(Permission.UserEdit),
  ];

  const {
    selectedColumns,
    createHandleToggleColumn,
    moveColumn,
    showAll,
    hideAll,
    restoreDefaults,
  } = useSelectedColumns({
    areaCode: AREAS.USERS,
  });

  const variables = React.useMemo(
    () => ({
      skip: page * perPage,
      first: perPage,
      sort: toSortVariables(sort),
      filter: {
        AND: [
          {
            roles: {
              some: {
                name: {
                  in: BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST,
                },
              },
            },
          },
          {
            _fullText: search,
          },
        ],
      },
    }),
    [page, perPage, sort, search],
  );

  const { data, loading } = useQuery(USERS_TABLE_QUERY, {
    variables,
  });

  const [invitationResend] = useMutation(INVITATION_RESEND_MUTATION, {
    refetchQueries: ['UsersTableContent'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('invitation_resend_success')),
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const [invitationCancel] = useMutation(INVITATION_CANCEL_MUTATION, {
    refetchQueries: ['UsersTableContent'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('invitation_cancel_success')),
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const classes = useStyles({ loading });

  const [actions, setActions] = React.useState<{
    el: HTMLElement | null;
    id: string | null;
  }>({ el: null, id: null });

  const openActions = React.useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setActions({ el: event.currentTarget, id });
    },
    [setActions],
  );

  const closeActions = React.useCallback(() => {
    setActions({ el: null, id: null });
  }, [setActions]);

  const openUserInviteDialog = React.useCallback(() => {
    openModal(DIALOGS.USER_INVITE_CUSTOM_DIALOG);

    closeActions();
  }, [openModal, closeActions]);

  const openEditDialog = React.useCallback(
    id => {
      openModal(DIALOGS.USER_EDIT_DIALOG, {
        userId: id,
        onSuccess: () => notification.success(t('user_update_success')),
      });

      closeActions();
    },
    [openModal, closeActions, notification],
  );

  const resendInvitation = React.useCallback(
    async (id: string): Promise<void> => {
      await invitationResend({ variables: { id } });

      closeActions();
    },
    [invitationResend, closeActions],
  );

  const cancelInvitation = React.useCallback(
    async (id: string): Promise<void> => {
      closeActions();

      openModal(DIALOGS.CONFIRMATION_DIALOG, {
        message: 'Are you sure want to cancel invitation?',
        onConfirm: async () => {
          await invitationCancel({ variables: { id } });
        },
      });
    },
    [openModal, invitationCancel, closeActions],
  );

  const openDeleteRowDialog = React.useCallback(
    (args?: any) => {
      openModal(DIALOGS.USER_DELETE_DIALOG, args || { id: actions.id });

      closeActions();
    },
    [openModal, closeActions, actions],
  );

  const rows = React.useMemo(() => data?.tableData?.items || [], [data]);
  const count = data?.tableData?.count;
  totalRows.current = count;

  const { selectedRows, handleSelectAll, handleSelectOne, setSelectedRows } = useSelectedRows(rows);

  const entitiesTableToolbarProps = React.useMemo(() => {
    const deleteButtonProps = {
      openDeleteRowDialog,
      setSelectedRows,
    };

    const selectColumnsButtonProps = {
      areaCode: AREAS.USERS,
      selectedColumns,
      createHandleToggleColumn,
      moveColumn,
      showAll,
      hideAll,
      restoreDefaults,
    };

    const props = {
      selectedRows,
      deleteButtonProps,
      selectColumnsButtonProps,
    };

    return canDelete ? { ...props } : null;
  }, [
    canDelete,
    openDeleteRowDialog,
    setSelectedRows,
    selectedRows,
    selectedColumns,
    hideAll,
    createHandleToggleColumn,
    moveColumn,
    showAll,
    restoreDefaults,
  ]);

  const createActions = React.useCallback(
    (closeActions: () => void, id: string): React.ReactNode => {
      const row = rows.find((row: any) => row?.id === id);

      if (row.status !== 'invitationPending') {
        return [
          <MenuItem disabled={!canEdit} key="edit" onClick={() => openEditDialog(id)}>
            <ListItemIcon>
              <icons.Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem disabled={!canDelete} key="delete" onClick={() => openDeleteRowDialog()}>
            <ListItemIcon>
              <icons.Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,
        ];
      }

      return [
        <MenuItem key="resend" onClick={() => resendInvitation(row?.invitation?.id)}>
          <ListItemIcon>
            <icons.Send />
          </ListItemIcon>
          Resend Invitation
        </MenuItem>,
        <MenuItem key="cancel" onClick={() => cancelInvitation(row?.invitation?.id)}>
          <ListItemIcon>
            <icons.Cancel />
          </ListItemIcon>
          Cancel Invitation
        </MenuItem>,
      ];
    },
    [
      rows,
      canEdit,
      canDelete,
      openEditDialog,
      openDeleteRowDialog,
      resendInvitation,
      cancelInvitation,
    ],
  );

  const areEmptyUsers = !loading && data && R.isEmpty(rows) && !search;
  const areNotSearchedUsers = !loading && data && R.isEmpty(rows) && search;

  const emptyUsersScreen = React.useMemo(
    () => (
      <EmptyScreen
        text="We couldn't find any users"
        actionText="Invite User"
        icon={icons.Group}
        onClick={openUserInviteDialog}
      />
    ),
    [openUserInviteDialog],
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Users</Typography>

        {!R.isEmpty(rows) && (
          <Button
            disabled={!canInvite}
            color="secondary"
            variant="outlined"
            onClick={openUserInviteDialog}
          >
            Invite User
          </Button>
        )}
      </Grid>

      {loading && !data ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column">
          {areEmptyUsers ? (
            emptyUsersScreen
          ) : (
            <React.Fragment>
              <Grid item>
                <EntitiesTableToolbar
                  searchInputProps={{ search, handleSearchChange }}
                  {...entitiesTableToolbarProps}
                />
              </Grid>

              {areNotSearchedUsers ? (
                emptyUsersScreen
              ) : (
                <React.Fragment>
                  <Grid className={classes.tableWrapper} item>
                    <Table>
                      <TableHeader
                        selectedColumns={selectedColumns}
                        actionsProps={{
                          title: ACTIONS_TITLE,
                        }}
                        sortProps={{ sort, createOnSort }}
                        checkboxProps={
                          canDelete
                            ? {
                                checked: selectedRows.length === rows.length,
                                indeterminate:
                                  selectedRows.length > 0 && selectedRows.length < rows.length,
                                onChange: handleSelectAll,
                              }
                            : undefined
                        }
                      />

                      <TableBody>
                        {rows.map((item: any) => (
                          <TableRow hover key={item.id}>
                            {canDelete && (
                              <TableCell padding="checkbox" className={classes.stickyLeft}>
                                <Checkbox
                                  checked={selectedRows.indexOf(item.id) !== -1}
                                  color="primary"
                                  onChange={(): void => handleSelectOne(item.id)}
                                  value="true"
                                />
                              </TableCell>
                            )}

                            {selectedColumns.map(({ areaField, tableField, selected }) => {
                              if (!selected) {
                                return null;
                              }

                              const areaFieldPath = bracketNotationToPath(areaField.fieldName);

                              if (areaField.name === 'Name') {
                                return (
                                  <TableCell key={areaField.id}>{getFullName(item)}</TableCell>
                                );
                              }

                              if (areaField.fieldName === 'roles') {
                                const appRoles = (item.roles.items || []).filter(({ name }: Role) =>
                                  APP_ROLE_NAMES_LIST.some(appRoleName => appRoleName === name),
                                );

                                const roleTitles = appRoles
                                  .map(({ name }: Role) => APP_ROLES[name as AppRoleName].title)
                                  .join(', ');

                                return <TableCell key={areaField.id}>{roleTitles}</TableCell>;
                              }

                              return (
                                <TableCell key={areaField.id}>
                                  <EntitiesTableCellValue
                                    record={item}
                                    field={tableField}
                                    areaField={areaField}
                                    value={R.path(areaFieldPath, item)}
                                  />
                                </TableCell>
                              );
                            })}

                            <TableCell className={classes.stickyRight}>
                              <IconButton onClick={openActions(item.id)}>
                                <icons.MoreVert />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                      <EntitiesTableActionsPopover
                        anchorEl={actions.el}
                        open={Boolean(actions.el)}
                        onClose={closeActions}
                        activeId={actions.id}
                        createActions={createActions}
                      />
                    </Table>
                  </Grid>
                  <Grid item>
                    <TablePagination
                      component="div"
                      count={count}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerPageChange}
                      page={page}
                      rowsPerPage={perPage}
                      rowsPerPageOptions={perPageOptions}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Grid>
      )}

      <ConfirmationDialog />
      <UserDeleteDialog />
      <UserInviteDialog />
      <UserEditDialog />
    </Grid>
  );
};
