import React from 'react';

import { useLocalStorage, useScoreContext } from 'src/hooks';
import { ChangedMetric } from 'src/types';
import { LocalStorageKeys } from 'src/types/localStorage';

export interface ToggleMetricsContextType {
  areTogglesExpanded: boolean;
  setTogglesExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  toggleScoreComponent: (metric: ChangedMetric) => void;
}

interface ToggleMetricsProviderProps {}

export const ToggleMetricsContext = React.createContext<ToggleMetricsContextType>(
  {} as ToggleMetricsContextType,
);

export const ToggleMetricsProvider: React.FC<ToggleMetricsProviderProps> = ({ children }) => {
  const { updateMetric } = useScoreContext();

  const [areTogglesExpanded, setTogglesExpanded] = useLocalStorage<boolean>(
    LocalStorageKeys.SaaSScoreTableTogglesExpanded,
    false,
  );

  const toggleScoreComponent = React.useCallback(
    (metric: ChangedMetric) => {
      updateMetric(metric);
    },
    [updateMetric],
  );

  return (
    <ToggleMetricsContext.Provider
      value={{
        areTogglesExpanded: areTogglesExpanded as boolean,
        setTogglesExpanded: setTogglesExpanded as React.Dispatch<React.SetStateAction<boolean>>,
        toggleScoreComponent,
      }}
    >
      {children}
    </ToggleMetricsContext.Provider>
  );
};
