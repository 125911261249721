/* eslint-disable */
import React from 'react';
import { TableShared } from 'src/shared/table/TableShared';
import { makeStyles } from '@material-ui/core';
import { AlignType } from 'rc-table/es/interface';
import { ScaleByTierRenderer } from './ScaleByTierRenderer';
import { dirtyContext, scaleByTierContext } from './';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& td': {
      padding: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& thead td': {
      padding: '8px',
      height: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& tr': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        height: 41,
      },
    },
    '& .rc-table-container': {
      height: '100%',
    },
    '& .rc-table-body': {
      height: 'calc(100% - 41px)',
    },
  },
}));

const emptyData = [{}];

interface ScaleByTierTableProps {
  isEnterprise: boolean;
}

export const ScaleByTierTable: React.FC<ScaleByTierTableProps> = ({ isEnterprise }) => {
  const classes = useStyles();
  const { scaleByTierSettings, update } = React.useContext(scaleByTierContext);
  const { setIsDirty } = React.useContext(dirtyContext);

  const columns = [0, 1, 2, 3, 4].map(number => ({
    title: `Tier ${number + 1}`,
    align: 'center' as AlignType,
    width: '20%',
    render: () => <ScaleByTierRenderer
      idx={number}
      isEnterprise={isEnterprise}
      scaleByTierSettings={scaleByTierSettings}
      update={update}
      setIsDirty={setIsDirty}
    />,
  }));

  return <TableShared className={classes.table} columns={columns} data={emptyData} />;
};
