import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useModal, Form, Field, SelectField } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import {
  LOAN_APPLICATIONS_EDIT_MUTATION,
  UsersTableContentQuery,
  UsersTableContentQueryVariables,
  USERS_TABLE_QUERY,
} from 'src/graphql';
import { DIALOGS } from './constants';
import { getFullName, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface LoanApplicationDealTeamEditDialogProps {}

export const LoanApplicationDealTeamEditDialog: React.FC<LoanApplicationDealTeamEditDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal, openModal } = useModal(
    DIALOGS.LOAN_APPLICATION_DEAL_TEAM_EDIT_DIALOG,
  );

  const { data, loading } = useQuery<UsersTableContentQuery, UsersTableContentQueryVariables>(
    USERS_TABLE_QUERY,
    {
      variables: {
        filter: {
          roles: {
            some: {
              name: {
                equals: 'appAdministrator',
              },
            },
          },
        },
      },
    },
  );

  const [editRow] = useMutation(LOAN_APPLICATIONS_EDIT_MUTATION, {
    refetchQueries: ['UsersList'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('loan_application_deal_team_update_success')),
    onError: () => notification.error(t('loan_application_deal_team_update_error')),
  });

  const initialValues = React.useMemo(
    () => ({
      dealTeam: (args?.dealTeam || []).map(({ id }: any) => id),
    }),
    [args],
  );

  const dealTeamOptions = React.useMemo(
    () =>
      data?.tableData.items.map(item => ({
        value: item.id,
        label: `${getFullName(item)} (${item?.email})`,
      })),
    [data],
  );

  const onSubmit = React.useCallback(
    async (data, form) => {
      await editRow({
        variables: {
          data: {
            id: args.id,
            dealTeam: {
              reconnect: data.dealTeam.map((id: string) => ({ id })),
            },
          },
        },
      });

      closeModal();

      setTimeout(form.reset);
    },
    [closeModal, editRow, args],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  if (!open) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Deal Team</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Field
                  name="dealTeam"
                  label="Select Deal Team Members"
                  component={SelectField}
                  multiple
                  fullWidth
                  options={dealTeamOptions}
                  disabled={submitting || loading}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={() => onClose(form, pristine)} disabled={submitting}>
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Save Changes
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
