import React, { useContext } from 'react';
import * as icons from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

export const ActionsRenderer: React.FC<any> = ({ row, context }) => {
  const { openActions } = useContext(context);

  return (
    <IconButton onClick={openActions(row.id)}>
      <icons.MoreVert />
    </IconButton>
  );
};
