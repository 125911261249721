import React from 'react';
import { TextField as MUITextField } from '@material-ui/core';
import { FieldSchema } from '@8base/utils';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';

interface TextFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  fieldSchema: FieldSchema;
}

export const TextField: React.FC<TextFieldProps> = ({ input, meta, ...rest }) => {
  const { name, value, onBlur, onChange } = input;

  const handleChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  return (
    <MUITextField
      name={name}
      value={value}
      error={error}
      helperText={helperText}
      onChange={handleChange}
      onBlur={onBlur}
      variant="outlined"
      type={input.type}
      {...rest}
    />
  );
};
