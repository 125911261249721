import { MetricValue } from 'src/graphql';
import { ScoreMetricItem, ScoreArrowType } from 'src/types';
import { isUndefined } from 'src/utils';

const getNearestPrevValue = (
  values: Record<string, MetricValue | undefined>,
  dates: string[],
  idx: number,
): MetricValue | null => {
  if (idx === -1) return null;

  const value = values[dates[idx - 1]];
  if (value) return value;

  return getNearestPrevValue(values, dates, idx - 1);
};

export const detectArrowType = (
  dates: string[],
  scoreMetricItems: Array<ScoreMetricItem>,
  date: string,
  idx: number,
): ScoreArrowType | null => {
  if (isUndefined(scoreMetricItems)) {
    return null;
  }

  const [values] = scoreMetricItems;

  if (isUndefined(values)) {
    return null;
  }

  const metricValue = values[date];
  if (!Boolean(metricValue)) return null;

  const nearestPrevValue = getNearestPrevValue(values, dates, idx);

  const prevAmount = Math.round(nearestPrevValue?.value || 0);
  const amount = Math.round(metricValue?.value || 0);

  if (prevAmount < amount) return ScoreArrowType.up;
  if (prevAmount > amount) return ScoreArrowType.down;
  return ScoreArrowType.steady;
};
