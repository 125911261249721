import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import * as icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Button,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Typography,
  IconButton,
  MenuItem,
  Checkbox,
  TablePagination,
  Theme,
  ListItemIcon,
} from '@material-ui/core';

import {
  ModalsContext,
  useSelectedRows,
  usePagination,
  useSort,
  toSortVariables,
  useSearch,
  useSelectedColumns,
  EntitiesTableCellValue,
  EntitiesTableActionsPopover,
  EntitiesTableToolbar,
  SORT_DIRECTIONS,
} from 'src/app-builder';
import { useLoanApplication } from 'src/hooks';
import {
  DocumentCreateDialog,
  DocumentEditDialog,
  DocumentDeleteDialog,
  DIALOGS,
} from 'src/dialogs';

import { TableHeader, EmptyScreen } from 'src/components';
import { Document, DOCUMENTS_TABLE_QUERY } from 'src/graphql';
import { ACTIONS_TITLE, AREAS } from 'src/constants';
import { bracketNotationToPath, downloadDataUrl } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
  emptyScreen: {
    minHeight: 500,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
  table: {
    borderCollapse: 'separate',
  },
  stickyLeft: {
    width: theme.spacing(6),
    background: theme.palette.background.paper,
    position: 'sticky',
    left: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  stickyRight: {
    width: 80,
    background: theme.palette.background.paper,
    position: 'sticky',
    right: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
  massAction: {
    padding: '10px 0px 10px 4px',
  },
}));

export const LoanApplicationDocumentsPage: React.FC<any> = () => {
  const params: { id: string } = useParams();
  const history = useHistory();

  const { openModal } = React.useContext(ModalsContext);

  const totalRows = React.useRef<number>(0);
  const { page, perPage, handlePageChange, handlePerPageChange, perPageOptions } = usePagination({
    initialCount: totalRows.current,
  });

  const { sort, createOnSort } = useSort([{ by: 'updatedAt', dir: SORT_DIRECTIONS.DESC }]);

  const { search, handleSearchChange } = useSearch();

  const {
    selectedColumns,
    createHandleToggleColumn,
    moveColumn,
    showAll,
    hideAll,
    restoreDefaults,
  } = useSelectedColumns({
    areaCode: AREAS.DOCUMENTS,
  });

  const { data: loanApplication, loading: loanLoading } = useLoanApplication();

  const companyId = loanApplication?.company?.id;

  const sortField = React.useMemo(() => {
    const { by, dir } = sort[0];
    const isSortByFile = by === 'file';

    return isSortByFile ? { file: { filename: R.toUpper(dir) } } : toSortVariables(sort);
  }, [sort]);

  const variables = React.useMemo(
    () => ({
      skip: page * perPage,
      first: perPage,
      sort: sortField,
      filter: {
        file: {
          filename: {
            contains: search,
          },
        },
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    }),
    [page, perPage, sortField, search, companyId],
  );

  const { data, loading: documentsLoading } = useQuery(DOCUMENTS_TABLE_QUERY, {
    variables,
    skip: !Boolean(loanApplication?.company?.id),
  });

  const classes = useStyles({ loading: documentsLoading });

  const [actions, setActions] = React.useState<{
    el: HTMLElement | null;
    id: string | null;
  }>({ el: null, id: null });

  const openActions = React.useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setActions({ el: event.currentTarget, id });
    },
    [setActions],
  );

  const closeActions = React.useCallback(() => {
    setActions({ el: null, id: null });
  }, [setActions]);

  const openCreateRowDialog = React.useCallback(() => {
    openModal(DIALOGS.DOCUMENT_CREATE_DIALOG, {
      companyId,
      onSuccess: () => {
        history.push(`/loan-applications/${params.id}/documents`);
      },
    });

    closeActions();
  }, [openModal, closeActions, companyId, history, params]);

  const openEditRowDialog = React.useCallback(() => {
    openModal(DIALOGS.DOCUMENT_EDIT_DIALOG, { id: actions.id });

    closeActions();
  }, [openModal, closeActions, actions]);

  const openDeleteRowDialog = React.useCallback(
    (args?: any) => {
      openModal(DIALOGS.DOCUMENT_DELETE_DIALOG, args || { id: actions.id });

      closeActions();
    },
    [openModal, closeActions, actions],
  );

  const rows = React.useMemo(() => data?.tableData?.items || [], [data]);
  const count = data?.tableData?.count;
  totalRows.current = count;

  const { selectedRows, handleSelectAll, handleSelectOne, setSelectedRows } = useSelectedRows(rows);

  const isLoading = loanLoading || documentsLoading;

  const createActions = React.useCallback(
    (closeActions: () => void, id: string): React.ReactNode => [
      <MenuItem key="edit" onClick={openEditRowDialog}>
        <ListItemIcon>
          <icons.Edit />
        </ListItemIcon>
        Edit
      </MenuItem>,
      <MenuItem key="delete" onClick={() => openDeleteRowDialog()}>
        <ListItemIcon>
          <icons.Delete />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    [openDeleteRowDialog, openEditRowDialog],
  );

  const handleDownload = React.useCallback(async () => {
    await R.forEach(async (id: string) => {
      const document: Document | undefined = R.find(R.propEq('id', id))(rows);

      if (!R.isNil(document) && !R.isNil(document?.file)) {
        await downloadDataUrl(
          document?.file?.filename as string,
          document?.file?.downloadUrl as string,
        );
      }
    }, selectedRows);
  }, [rows, selectedRows]);

  const emptyScreen = React.useMemo(
    (): React.ReactNode => (
      <EmptyScreen
        text="We couldn't find any documents"
        actionText="Add documents"
        icon={icons.FileCopy}
        onClick={openCreateRowDialog}
      />
    ),
    [openCreateRowDialog],
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Documents</Typography>

        {!isLoading && !R.isEmpty(rows) && (
          <Button color="secondary" variant="outlined" onClick={openCreateRowDialog}>
            Add Document
          </Button>
        )}
      </Grid>

      {isLoading && <LinearProgress />}

      {!data ||
        (!loanLoading && (
          <Grid className={classes.content} item container direction="column">
            <Grid item container direction="row">
              {!R.isEmpty(rows) && (
                <Grid className={classes.massAction} item direction="row">
                  <Checkbox
                    checked={selectedRows.length === rows.length}
                    color="primary"
                    indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                    onChange={handleSelectAll}
                  />

                  <IconButton size="small" className={classes.massAction} onClick={handleDownload}>
                    <icons.GetApp />
                  </IconButton>
                </Grid>
              )}

              {(!R.isEmpty(rows) || search) && (
                <Grid item xs>
                  <EntitiesTableToolbar
                    selectedRows={selectedRows}
                    searchInputProps={{ search, handleSearchChange }}
                    deleteButtonProps={{
                      openDeleteRowDialog,
                      setSelectedRows,
                      disabled: R.isEmpty(selectedRows),
                    }}
                    selectColumnsButtonProps={{
                      areaCode: AREAS.DOCUMENTS,
                      selectedColumns,
                      createHandleToggleColumn,
                      moveColumn,
                      showAll,
                      hideAll,
                      restoreDefaults,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container direction="column">
              {R.isEmpty(rows) ? (
                <Grid item container alignItems="center" className={classes.emptyScreen}>
                  {emptyScreen}
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid className={classes.tableWrapper} item>
                    <Table className={classes.table}>
                      <TableHeader
                        selectedColumns={selectedColumns}
                        actionsProps={{
                          title: ACTIONS_TITLE,
                        }}
                        sortProps={{ sort, createOnSort }}
                        checkboxProps={{
                          hideCheckbox: true,
                        }}
                      />
                      <TableBody>
                        {rows.map((item: any) => (
                          <TableRow hover key={item.id}>
                            <TableCell padding="checkbox" className={classes.stickyLeft}>
                              <Checkbox
                                checked={selectedRows.indexOf(item.id) !== -1}
                                color="primary"
                                onChange={(): void => handleSelectOne(item.id)}
                                value="true"
                              />
                            </TableCell>

                            {selectedColumns.map(({ areaField, tableField, selected }) => {
                              if (!selected) {
                                return null;
                              }

                              const areaFieldPath = bracketNotationToPath(areaField.fieldName);

                              if (areaField.name === 'Provided By') {
                                return (
                                  <TableCell>
                                    {item?.createdBy?.firstName} {item?.createdBy?.lastName}
                                  </TableCell>
                                );
                              }

                              if (areaField.name === 'Private?') {
                                return <TableCell>{item?.private ? 'Yes' : 'No'}</TableCell>;
                              }

                              return (
                                <TableCell key={areaField.id}>
                                  <EntitiesTableCellValue
                                    record={item}
                                    field={tableField}
                                    areaField={areaField}
                                    value={R.path(areaFieldPath, item)}
                                  />
                                </TableCell>
                              );
                            })}

                            <TableCell className={classes.stickyRight}>
                              <IconButton onClick={openActions(item.id)}>
                                <icons.MoreVert />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <EntitiesTableActionsPopover
                        anchorEl={actions.el}
                        open={Boolean(actions.el)}
                        onClose={closeActions}
                        activeId={actions.id}
                        createActions={createActions}
                      />
                    </Table>
                  </Grid>
                  <Grid item>
                    <TablePagination
                      component="div"
                      count={count}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerPageChange}
                      page={page}
                      rowsPerPage={perPage}
                      rowsPerPageOptions={perPageOptions}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        ))}
      <DocumentCreateDialog />
      <DocumentEditDialog />
      <DocumentDeleteDialog />
    </Grid>
  );
};
