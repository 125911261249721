import { gql } from '8base-react-sdk';

export const INTEGRATIONS_QUERY = gql`
  query UserIntegrationsEntity($userId: ID!) {
    user(id: $userId) {
      id
      company {
        id
        integration {
          id
          xero {
            id
            status
          }
          quickBooks {
            id
            status
          }
          stripe {
            id
            status
          }
          plaid {
            items {
              id
              status
              institutionMetadata
              accounts {
                items {
                  id
                  name
                  mask
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const COMPANY_INTEGRATIONS_QUERY = gql`
  query CompanyIntegrationsEntity($companyId: ID!) {
    company(id: $companyId) {
      id
      integration {
        id
        xero {
          id
          status
        }
        quickBooks {
          id
          status
        }
        stripe {
          id
          status
        }
        plaid {
          items {
            id
            status
            institutionMetadata
            accounts {
              items {
                id
                name
                mask
              }
            }
          }
        }
      }
    }
  }
`;
