import React from 'react';
import {
  Avatar,
  CircularProgress,
  Grid,
  Paper,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import { ReactComponent as XeroLogo } from 'src/assets/logos/xero.svg';
import { ReactComponent as QuickbooksLogo } from 'src/assets/logos/quickbooks.svg';
import { ReactComponent as PlaidLogo } from 'src/assets/logos/plaid.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 15,
  },
  cardItem: {
    width: 300,
    height: 200,
    padding: 32,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  iconContainer: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: 'inherit',
    color: 'inherit',
  },
  status: ({ isConnected }: { isConnected: boolean }) => ({
    color: isConnected ? theme.palette.secondary.main : theme.palette.error.main,
    marginRight: 5,
  }),
  doneIcon: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: '50%',
    width: 14,
    height: 14,
  },
}));

interface IntegrationCardProps {
  isConnected: boolean;
  name: 'xeroQB' | 'plaid';
  title: string;
  loading?: boolean;
  isError?: boolean;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  name,
  title,
  loading,
  isConnected,
  isError,
}) => {
  const classes = useStyles({ isConnected });

  const renderIntegrationStatus = () => {
    if (loading) {
      return <CircularProgress style={{ width: 14, height: 14 }} />;
    }

    if (isError) {
      return (
        <Typography align="center" className={classes.status} variant="caption">
          Not loaded
        </Typography>
      );
    }

    if (isConnected) {
      return (
        <React.Fragment>
          <Typography align="center" className={classes.status} variant="caption">
            CONNECTED
          </Typography>
          <SvgIcon
            className={classes.doneIcon}
            fontSize="small"
            color="inherit"
            component={icons.DoneRounded}
          />
        </React.Fragment>
      );
    }

    if (!isConnected) {
      return (
        <Typography align="center" className={classes.status} variant="caption">
          NOT CONNECTED
        </Typography>
      );
    }
  };

  return (
    <Grid item className={classes.root}>
      <Paper className={classes.cardItem}>
        <Grid direction="column" alignItems="center" container spacing={4}>
          {name === 'xeroQB' ? (
            <Grid item justify="space-evenly" container spacing={3} alignItems="center">
              <Avatar className={classes.iconContainer}>
                <XeroLogo className={classes.icon} />
              </Avatar>
              <Avatar className={classes.iconContainer}>
                <QuickbooksLogo className={classes.icon} />
              </Avatar>
            </Grid>
          ) : (
            <Grid item justify="space-evenly" container spacing={3} alignItems="center">
              <Avatar style={{ marginRight: 5 }} className={classes.iconContainer}>
                <PlaidLogo className={classes.icon} />
              </Avatar>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1" align="center">
              {title}
            </Typography>
            <Grid container item alignItems="center" justify="center">
              {renderIntegrationStatus()}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
