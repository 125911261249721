import * as R from 'ramda';

export const isSafari = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    return ua.indexOf('chrome') <= -1;
  }

  return false;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (fn: unknown): fn is Function => typeof fn === 'function';

export const isUndefined = (obj: unknown): obj is undefined => typeof obj === 'undefined';

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export function delay(ms: number): Promise<void> {
  return new Promise(resolve => {
    return setTimeout(() => resolve(), ms);
  });
}

export function wrapWithBrackets(value: string | number | null): string | null {
  if (R.isNil(value)) {
    return null;
  }

  return `(${value})`;
}

export function insertAfter(
  arr: Array<any>,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any,
  comparator: (item: any) => boolean,
): Array<any> {
  const index = arr.findIndex(comparator);
  if (index === -1) {
    return arr;
  }

  return R.insert(index + 1, value, arr);
}

export function insertBefore(
  arr: Array<any>,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any,
  comparator: (item: any) => boolean,
): Array<any> {
  const index = arr.findIndex(comparator);
  if (index === -1) {
    return arr;
  }

  return R.insert(index, value, arr);
}

/**
 * (1) replace square brackets from string path:
 * "some.array[0].value" => "some.array.0.value"
 *
 * (2) then transform string into an array:
 * "some.array.0.value" => ["some", "array", "0", "value"]
 */
export function bracketNotationToPath(value: string): string[] {
  return value.replace(/\[([^\]]+)\]/g, '.$1').split('.');
}

export function getSumBy<T = any>(items: Array<T>, key: keyof T): number {
  const toNumber = (num: T[keyof T]) => Number(Number.isFinite(num) ? num : 0);

  const calculateSum = (sum: number, item: T) => sum + toNumber(item[key]);

  return items.reduce(calculateSum, 0);
}

export async function asyncMap<T = any, U = any>(
  array: Array<T>,
  asyncCallback: (item: T) => Promise<U>,
): Promise<Array<U>> {
  const promises = array.map(async (item: T) => await asyncCallback(item));

  return await Promise.all(promises);
}

export function replaceIfNil(value: string | number | null | undefined, replacement = '-') {
  if (R.isNil(value)) {
    return replacement;
  }

  return value;
}
