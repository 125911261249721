import { DurationUnit } from 'luxon';

import { MetricValue } from 'src/graphql';
import { Merge } from 'src/types/utils';
import { Format } from 'src/constants';

export enum MetricCode {
  ARPU = 'ARPU',
  ARPUTrend = 'ARPU_TREND',
  AccountsPayable = 'ACCOUNTS_PAYABLE',
  AccountsReceivable = 'ACCOUNTS_RECEIVABLE',
  AdjustedCurrentRatio = 'ADJUSTED_CURRENT_RATIO',
  AdjustedNetCashBurn = 'ADJUSTED_NET_CASH_BURN',
  AdjustedNetCashBurnL3M = 'ADJUSTED_NET_CASH_BURN_L3M',
  BeginningCustomers = 'BEGINNING_CUSTOMERS',
  BeginningMRR = 'BEGINNING_MRR',
  CAC = 'CAC',
  CACPayback = 'CAC_PAYBACK',
  CapitalizedDevelopmentSoftware = 'CAPITALIZED_DEVELOPMENT_SOFTWARE',
  CashFromFinancingActivities = 'CASH_FROM_FINANCING_ACTIVITIES',
  CashFromInvestingActivities = 'CASH_FROM_INVESTING_ACTIVITIES',
  CashFromOperatingActivities = 'CASH_FROM_OPERATING_ACTIVITIES',
  CashInflows = 'CASH_INFLOWS',
  CashOutflows = 'CASH_OUTFLOWS',
  CashPosition = 'CASH_POSITION',
  CashPositionPlaid = 'CASH_POSITION_PLAID',
  CashRunwayAccounting = 'CASH_RUNWAY_ACCOUNTING',
  CashRunwayBanking = 'CASH_RUNWAY_BANKING',
  CashRunwayBankingWeekly = 'CASH_RUNWAY_BANKING_WEEKLY',
  ChangeInCash = 'CHANGE_IN_CASH',
  ChangeInCashAccounting = 'CHANGE_IN_CASH_ACCOUNTING',
  ChangeInCashCurrentWeek = 'CHANGE_IN_CASH_CURRENT_WEEK',
  ChangeInCashFourWeeks = 'CHANGE_IN_CASH_FOUR_WEEKS',
  ChangeInCashTwoWeeks = 'CHANGE_IN_CASH_TWO_WEEKS',
  ChurnMRR = 'CHURN_MRR',
  ChurnedCustomers = 'CHURNED_CUSTOMERS',
  ContractionMRR = 'CONTRACTION_MRR',
  CostOfGoodsSold = 'COST_OF_GOODS_SOLD',
  CreditCards = 'CREDIT_CARDS',
  DebtInflows = 'DEBT_INFLOWS',
  DeferredRevenue = 'DEFERRED_REVENUE',
  EndingCustomers = 'ENDING_CUSTOMERS',
  EndingMRR = 'ENDING_MRR',
  EquityCapitalInflows = 'EQUITY_CAPITAL_INFLOWS',
  EquityDistributions = 'EQUITY_DISTRIBUTIONS',
  ExpansionMRR = 'EXPANSION_MRR',
  GeneralAdministrativeExpenses = 'GENERAL_AND_ADMINISTRATIVE_EXPENSES',
  GeneralAdministrativeExpensesOfRevenue = 'GENERAL_ADMINISTRATIVE_EXPENSES_OF_REVENUE',
  GrossCustomerChurnRate = 'GROSS_CUSTOMER_CHURN_RATE',
  GrossMargin = 'GROSS_MARGIN',
  GrossProfit = 'GROSS_PROFIT',
  LTV = 'LTV',
  LTVtoCAC = 'LTV_TO_CAC',
  LaborExpenses = 'LABOR_EXPENSES',
  LaborOfRevenue = 'LABOR_OF_REVENUE',
  LongTermDebt = 'LONG_TERM_DEBT',
  MRRExpansionRate = 'MRR_EXPANSION_RATE',
  MRRNetChurnRate = 'MRR_NET_CHURN_RATE',
  MRRNetGrowthRate = 'MRR_NET_GROWTH_RATE',
  NetCashBurnOfGrossProfit = 'NET_CASH_BURN_OF_GROSS_PROFIT',
  NetCashChange = 'NET_CASH_CHANGE',
  NetCustomersAdd = 'NET_CUSTOMERS_ADD',
  NetIncome = 'NET_INCOME',
  NetRevenueRetentionRate = 'NET_REVENUE_RETENTION_RATE',
  NewCustomers = 'NEW_CUSTOMERS',
  NewMRR = 'NEW_MRR',
  OtherIncomeAndExpenses = 'OTHER_INCOME_AND_EXPENSES',
  OtherOperatingExpenses = 'OTHER_OPERATING_EXPENSES',
  OtherOperatingExpensesOfRevenue = 'OTHER_OPERATING_EXPENSES_OF_REVENUE',
  PortionOfMRRFromMRRExpansion = 'PORTION_OF_MRR_FROM_MRR_EXPANSION',
  ResearchDevelopmentExpensesOfRevenue = 'RESEARCH_DEVELOPMENT_EXPENSES_OF_REVENUE',
  ResearchDevelopmentInfrastructureExpenses = 'RESEARCH_AND_DEVELOPMENT_INFRASTRUCTURE_EXPENSES',
  RevenueGrowthRateMoM = 'REVENUE_GROWTH_RATE_MOM',
  SaaSQuickRatio = 'SAAS_QUICK_RATIO',
  SaaSScore = 'SAAS_SCORE',
  SalesAndMarketingExpenses = 'SALES_AND_MARKETING_EXPENSES',
  SalesAndMarketingExpensesOfRevenue = 'SALES_AND_MARKETING_EXPENSES_OF_REVENUE',
  ShortTermDebt = 'SHORT_TERM_DEBT',
  TaxLiabilities = 'TAX_LIABILITIES',
  TotalAssets = 'TOTAL_ASSETS',
  TotalCurrentAssets = 'TOTAL_CURRENT_ASSETS',
  TotalCurrentLiabilities = 'TOTAL_CURRENT_LIABILITIES',
  TotalEquity = 'TOTAL_EQUITY',
  TotalLiabilities = 'TOTAL_LIABILITIES',
  TotalOperatingExpenses = 'TOTAL_OPERATING_EXPENSES',
  TotalRevenue = 'TOTAL_REVENUE',
  YoYRevenueGrowth = 'YOY_REVENUE_GROWTH',
}

export type MetricValuesGroup = {
  date: string;
  metrics: {
    items: MetricValue[];
  };
};

export type Report = {
  label: string;
  value: Array<MetricCode | string>;
  timeUnit: DurationUnit;
};

export type MetricGroup = Record<MetricCode | string, MetricValue>;

export type MergedMetricValue =
  | MetricValue
  | Merge<MetricValue, Record<string, number | undefined>>;

export type DashboardMetricGroups = {
  code: string;
  name: string;
  metrics: MetricValue[];
};

export type EmptyMetricValue = {
  id: string;
  period: MetricPeriod;
  date: string;
  metric: {
    code: MetricCode;
    name: string;
    format: Format;
  };
  value: null;
  adjustedValue: null;
  tier: null;
  comment: null;
};

export type ScoreDetailsMetric = {
  id: number;
  code: MetricCode;
  name: string;
  format: Format;
  tier: number | null;
  value: number | null;
  adjustedValue: number | null;
};

export enum MetricType {
  Plain = 'Plain',
  Calculated = 'Calculated',
}

export enum MetricPeriod {
  Week = 'week',
  Month = 'month',
  Third = 'third',
}

export enum TermType {
  TermLoan = 'TermLoan',
  RBF = 'RBF',
}

export enum LoanTerm {
  Months_24 = 24,
  Months_36 = 36,
}

export enum Source {
  Xero = 'Xero',
  Stripe = 'Stripe',
  Plaid = 'Plaid',
  QBO = 'QBO',
  Manual = 'Manual',
  Calculated = 'Calculated',
}

export type ChangedMetric = {
  adjustedValue: number | null;
  comment: string | null;
  companyId: string;
  date: string;
  active: boolean;
  source: Source;
  metricCode: MetricCode;
  period: MetricPeriod;
};

export type CompanyHeaderMetricCode =
  | MetricCode.SaaSScore
  | MetricCode.EndingMRR
  | MetricCode.AdjustedNetCashBurn
  | MetricCode.CashPosition
  | MetricCode.CashRunwayAccounting;
