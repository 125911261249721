import React from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';

import {
  CALCULATE_METRICS_MUTATION,
  CALCULATE_SAAS_SCORE_COMPONENTS_MUTATION,
  CALCULATE_SAAS_SCORE_MUTATION,
  METRIC_VALUES_LIST_QUERY,
} from 'src/graphql';
import { useNotification } from 'src/hooks';
import { isFunction, t } from 'src/utils';
import { CompaniesWithMetricChange } from 'src/types/localStorage';
import { removeMetricChange } from 'src/utils/localStorage/metrics';
import { getMetricValuesListQueryVariables } from 'src/utils/apolloCache';

type MetricsRecalculationState = [
  () => Promise<void>,
  {
    loading: boolean;
  },
];

export const useCalculateMetrics = (
  companyId: string,
  companiesWithChange?: CompaniesWithMetricChange | null,
  setCompaniesWithChange?: (newCompanies: CompaniesWithMetricChange) => void,
): MetricsRecalculationState => {
  const notification = useNotification();

  const [calculating, setCalculating] = React.useState(false);
  const apolloClient = useApolloClient();

  const metricValuesListRefetchQueries = getMetricValuesListQueryVariables(
    apolloClient.cache as InMemoryCache,
    companyId,
  ).map(variables => ({
    query: METRIC_VALUES_LIST_QUERY,
    variables,
  }));

  const [calculateMetrics] = useMutation(CALCULATE_METRICS_MUTATION, {
    onCompleted: () => notification.success(t('calculate_metrics_success')),
    onError: error => {
      notification.error(t('calculate_metrics_error'));

      throw error;
    },
  });

  const [calculateSaasScoreComponents] = useMutation(CALCULATE_SAAS_SCORE_COMPONENTS_MUTATION, {
    onCompleted: () => notification.success(t('calculate_saas_score_components_success')),
    onError: error => {
      notification.error(t('calculate_saas_score_components_error'));

      throw error;
    },
  });

  const [calculateSaasScore] = useMutation(CALCULATE_SAAS_SCORE_MUTATION, {
    refetchQueries: [...metricValuesListRefetchQueries, 'MetricsCompanyHeader'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('calculate_saas_score_success')),
    onError: error => {
      notification.error(t('calculate_saas_score_error'));

      throw error;
    },
  });

  const recalculateMetrics = React.useCallback(async () => {
    setCalculating(true);

    try {
      await calculateMetrics({
        variables: {
          companyId,
        },
      });

      await calculateSaasScoreComponents({
        variables: {
          companyId,
        },
      });

      await calculateSaasScore({
        variables: {
          companyId,
        },
      });

      if (isFunction(setCompaniesWithChange)) {
        const companies = removeMetricChange(
          companiesWithChange as CompaniesWithMetricChange,
          companyId,
        );
        setCompaniesWithChange(companies);
      }

      notification.success('All metrics were successfully calculated');
    } catch (error) {
      console.error(error);
    }

    setCalculating(false);
  }, [
    calculateMetrics,
    calculateSaasScore,
    calculateSaasScoreComponents,
    companiesWithChange,
    companyId,
    notification,
    setCompaniesWithChange,
  ]);

  return [
    recalculateMetrics,
    {
      loading: calculating,
    },
  ];
};
