import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Theme, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentUser, ModalsContext } from 'src/app-builder';

import { InboxCustomerReplyDialog, DIALOGS } from 'src/dialogs';
import {
  MessagesList,
  ActiveMessage,
  Switches,
  InboxEmptyScreen,
  LayoutInbox,
} from 'src/shared/inboxMessages';
import { Search } from 'src/components';
import {
  InboxMessagesListQuery,
  INBOX_MESSAGES_LIST_QUERY,
  InboxMessagesListQueryVariables,
  InboxMessage,
} from 'src/graphql';

import { extractUserCompanyId, createInboxListQueryVariables } from 'src/utils';
import { InboxFolderTypes } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  noteWrapper: {
    flex: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
    background: theme.customPalette.background.activeCard,
    height: '100%',
    overflow: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      height: theme.spacing(4),
      width: '100%',
    },
  },
  panel: {
    display: 'flex',
    flexFlow: 'column nowrap',
    background: '#FFF',
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

type Params = {
  folder: string;
  messageId: string;
};

export const Main: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user, loading: loadingUser } = useCurrentUser();
  const userId = R.pathOr('', ['id'], user);
  const [searchText, setSearchText] = React.useState<string>('');

  const { folder, messageId }: Params = useParams();

  const companyId = extractUserCompanyId(user);

  const { data, loading: loadingMessages } = useQuery<
    InboxMessagesListQuery,
    InboxMessagesListQueryVariables
  >(INBOX_MESSAGES_LIST_QUERY, {
    variables: createInboxListQueryVariables(companyId as string, searchText),
    skip: !companyId,
    fetchPolicy: 'no-cache',
  });

  const loading = loadingMessages || loadingUser;

  const messages = R.pathOr<InboxMessage[]>([], ['inboxMessagesList', 'items'], data);
  const receivedMessages = messages.filter(message => message.isByAdministrator);
  const sentMessages = messages.filter(message => !message.isByAdministrator);
  const messagesLength =
    folder === InboxFolderTypes.sent ? sentMessages.length : receivedMessages.length;

  const showActiveMessage = !loading && !R.isEmpty(messages) && !R.isNil(messageId);

  const { openModal } = React.useContext(ModalsContext);

  const createReplyInboxDialog = React.useCallback(
    ({ messageId }) => {
      openModal(DIALOGS.INBOX_CUSTOMER_REPLY_DIALOG, {
        companyId,
        repliedOnId: messageId,
        onSuccess: (resp: any) => {
          const messageId = R.pathOr(
            null,
            ['data', 'eventResolver', 'response', 'data', 'messageId'],
            resp,
          );
          if (messageId) history.push(`/client-portal/inbox/sent/${messageId}`);
          setSearchText('');
        },
      });
    },
    [openModal, companyId, history],
  );

  return (
    <LayoutInbox>
      <React.Fragment>
        <Grid item xs={5} sm={4} className={classes.panel}>
          <Search
            value={searchText}
            onChange={setSearchText}
            isHidden={R.isEmpty(messages) && R.isEmpty(searchText)}
          />
          {loading ? (
            <LinearProgress />
          ) : (
            <React.Fragment>
              <Switches path={`/client-portal/inbox`} />
              <MessagesList
                path={`/client-portal/inbox/${folder}`}
                messages={folder === InboxFolderTypes.sent ? sentMessages : receivedMessages}
              />
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={7} sm={8} className={classes.noteWrapper}>
          <ActiveMessage
            isVisible={showActiveMessage}
            path={`/client-portal/inbox`}
            id={messageId}
            folder={folder}
            userId={userId}
            createReplyInboxDialog={createReplyInboxDialog}
          />
          <InboxEmptyScreen
            folder={folder}
            loading={loading}
            quantity={messagesLength}
            isVisible={!showActiveMessage}
          />
        </Grid>
      </React.Fragment>
      <InboxCustomerReplyDialog />
    </LayoutInbox>
  );
};
