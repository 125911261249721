import React from 'react';

export interface SearchProviderValue {
  setSearch: ((v: string) => void) | null;
  search: string;
}

const SearchContext = React.createContext<SearchProviderValue | null>(null);

const SearchProvider = React.memo(({ children }) => {
  const [search, setSearch] = React.useState<string>('');

  return <SearchContext.Provider value={{ search, setSearch }}>{children}</SearchContext.Provider>;
});

SearchProvider.displayName = 'SearchProvider';

export { SearchContext, SearchProvider };
