import React from 'react';

import { Main } from './components';
import {
  SortProvider,
  SearchProvider,
  SelectedColumnsProvider,
  SelectedRowsProvider,
  PaginationProvider,
} from 'src/providers';
import { ALL_USERS_COLUMN_KEYS } from 'src/constants';

interface ClientPortalSettingsUsersPageProps {}

export const ClientPortalSettingsUsersPage: React.FC<ClientPortalSettingsUsersPageProps> = () => {
  return (
    <PaginationProvider>
      <SortProvider>
        <SearchProvider>
          <SelectedColumnsProvider
            allKeys={ALL_USERS_COLUMN_KEYS}
            initialOrder={ALL_USERS_COLUMN_KEYS}
          >
            <SelectedRowsProvider>
              <Main />
            </SelectedRowsProvider>
          </SelectedColumnsProvider>
        </SearchProvider>
      </SortProvider>
    </PaginationProvider>
  );
};
