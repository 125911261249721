import { CovenantTrackingPortfolioQueryVariables } from 'src/graphql';
import { SearchProviderValue } from 'src/providers';
import { MetricPeriod, MetricCode } from 'src/types';
import { getStartOfMonth } from 'src/utils';

export const getCovenantTrackingQueryVariables = (
  search: SearchProviderValue | null,
  date: string,
): CovenantTrackingPortfolioQueryVariables => {
  return {
    companyFilter: {
      company: {
        name: {
          contains: search?.search,
        },
      },
    },
    metricsFilter: {
      OR: [
        {
          period: {
            equals: MetricPeriod.Month,
          },
          metric: {
            code: {
              in: [
                MetricCode.AdjustedNetCashBurnL3M,
                MetricCode.CashPositionPlaid,
                MetricCode.CashInflows,
              ],
            },
          },
          date: {
            equals: date,
          },
        },
        {
          period: {
            equals: MetricPeriod.Week,
          },
          metric: {
            code: {
              in: [MetricCode.CashPositionPlaid],
            },
          },
          AND: [
            {
              date: {
                lte: date,
              },
            },
            {
              date: {
                gte: getStartOfMonth(date),
              },
            },
          ],
        },
      ],
    },

    customCovenantsFilter: {
      date: {
        equals: date,
      },
    },
  };
};
