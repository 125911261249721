import React from 'react';
import { Switch, Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import * as ChangeCase from 'change-case';

import { useAllowed, useRedirectPath } from './providers';
import { ProtectedRoute } from './shared';
import { MainLayout } from './layouts';
import { SettingsPage } from './routes/settings';
import { AccountPage } from './routes/account';
import { APP_SETTING } from 'src/constants/app';
import { AuthPages } from 'src/routes/auth';
import { Permission } from 'src/constants';

interface ApplicationProps {
  routes: Array<{
    path: string;
    exact: boolean;
    component: React.ComponentType<any>;
    location: 'main' | 'reports' | 'settings';
  }>;
}

interface RouteWithLayoutProps extends RouteProps {
  layout: React.ComponentType<any>;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  componentProps?: any;
}

const RouteWithLayout: React.FC<RouteWithLayoutProps> = props => {
  const { layout: Layout, component: Component, componentProps, ...rest } = props;

  return (
    <ProtectedRoute
      {...rest}
      render={(matchProps): React.ReactNode => (
        <Layout>
          <Component {...matchProps} {...(componentProps || {})} />
        </Layout>
      )}
    />
  );
};

export const Application: React.FC<ApplicationProps> = ({ routes }) => {
  const isAllowed = useAllowed();

  const redirectPath = useRedirectPath();

  const areas = APP_SETTING.areas.items
    .filter(
      ({ name, location }) =>
        location === 'main' && isAllowed(ChangeCase.constantCase(name + 'Area')),
    )
    .sort((a, b) => a.position - b.position);

  const allowedRoutes = React.useMemo(
    () =>
      routes.filter(
        route =>
          route.location === 'main' && areas.some(area => route.path.indexOf(area.path) === 0),
      ),
    [routes, areas],
  );

  return (
    <Switch>
      {redirectPath !== '/' && <Redirect exact from="/" to={redirectPath} />}

      <Route component={AuthPages} path="/auth" />

      {isAllowed(Permission.SettingsArea) && (
        <RouteWithLayout
          layout={MainLayout}
          component={SettingsPage}
          path="/settings"
          componentProps={{ routes: routes }}
        />
      )}

      <RouteWithLayout layout={MainLayout} component={AccountPage} path="/account" />

      {allowedRoutes.map(({ location, ...route }) => (
        <RouteWithLayout key={route.path} layout={MainLayout} {...route} />
      ))}

      {redirectPath && <Redirect to={redirectPath} />}
    </Switch>
  );
};
