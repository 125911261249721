import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, LinearProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ModalsContext, useCurrentUser } from 'src/app-builder';
import { DIALOGS, InboxComposeDialog } from 'src/dialogs';

import { usePortfolioCompany } from 'src/hooks';
import { Search } from 'src/components';
import {
  ActiveMessage,
  InboxEmptyScreen,
  LayoutInbox,
  MessagesList,
  Switches,
} from 'src/shared/inboxMessages';
import {
  INBOX_MESSAGES_LIST_QUERY,
  InboxMessage,
  InboxMessagesListQuery,
  InboxMessagesListQueryVariables,
} from 'src/graphql';
import { createInboxListQueryVariables } from 'src/utils';
import { InboxFolderTypes } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  noteWrapper: {
    flex: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
    background: theme.customPalette.background.activeCard,
    height: '100%',
    overflow: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      height: theme.spacing(4),
      width: '100%',
    },
  },
  panel: {
    display: 'flex',
    flexFlow: 'column nowrap',
    background: '#FFF',
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

type Params = {
  id: string;
  folder: string;
  messageId: string;
};

export const PortfolioCompaniesInboxPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user, loading: loadingUser } = useCurrentUser();
  const userId = R.pathOr('', ['id'], user);

  const [searchText, setSearchText] = React.useState<string>('');

  const { id: portfolioCompanyId, folder, messageId }: Params = useParams();

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const companyId = portfolioCompany?.company?.id;

  const { data, loading: loadingMessages } = useQuery<
    InboxMessagesListQuery,
    InboxMessagesListQueryVariables
  >(INBOX_MESSAGES_LIST_QUERY, {
    variables: createInboxListQueryVariables(companyId as string, searchText),
    skip: !companyId,
    fetchPolicy: 'no-cache',
  });

  const loading = portfolioCompanyLoading || loadingMessages || loadingUser;

  const messages = R.pathOr<InboxMessage[]>([], ['inboxMessagesList', 'items'], data);
  const sentMessages = messages.filter(message => message.isByAdministrator);
  const receivedMessages = messages.filter(message => !message.isByAdministrator);
  const messagesLength =
    folder === InboxFolderTypes.sent ? sentMessages.length : receivedMessages.length;

  const showActiveMessage = !loading && !R.isEmpty(messages) && !R.isNil(messageId);

  const { openModal } = React.useContext(ModalsContext);

  const onMessageSent = useCallback(
    (resp: any) => {
      const messageId = R.pathOr(
        null,
        ['data', 'eventResolver', 'response', 'data', 'messageId'],
        resp,
      );
      if (messageId)
        history.push(`/portfolio-companies/${portfolioCompanyId}/inbox/sent/${messageId}`);
      setSearchText('');
    },
    [history, portfolioCompanyId],
  );

  const openCreateInboxDialog = React.useCallback(() => {
    openModal(DIALOGS.INBOX_COMPOSE_DIALOG, {
      companyId,
      onSuccess: onMessageSent,
    });
  }, [openModal, companyId, onMessageSent]);

  const createReplyInboxDialog = React.useCallback(
    ({ messageId }: { messageId?: string }) => {
      openModal(DIALOGS.INBOX_COMPOSE_DIALOG, {
        companyId,
        repliedOnId: messageId,
        onSuccess: onMessageSent,
      });
    },
    [openModal, companyId, onMessageSent],
  );

  return (
    <LayoutInbox canCompose openCreateInboxDialog={openCreateInboxDialog}>
      <React.Fragment>
        <Grid item xs={5} sm={4} className={classes.panel}>
          <Search
            value={searchText}
            onChange={setSearchText}
            isHidden={R.isEmpty(messages) && R.isEmpty(searchText)}
          />
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <Switches path={`/portfolio-companies/${portfolioCompanyId}/inbox`} />
              <MessagesList
                path={`/portfolio-companies/${portfolioCompanyId}/inbox/${folder}`}
                messages={folder === InboxFolderTypes.sent ? sentMessages : receivedMessages}
              />
            </>
          )}
        </Grid>

        <Grid item xs={7} sm={8} className={classes.noteWrapper}>
          <ActiveMessage
            isVisible={showActiveMessage}
            path={`/portfolio-companies/${portfolioCompanyId}/inbox`}
            id={messageId}
            folder={folder}
            userId={userId}
            createReplyInboxDialog={createReplyInboxDialog}
          />
          <InboxEmptyScreen
            folder={folder}
            loading={loading}
            quantity={messagesLength}
            isVisible={!showActiveMessage}
          />
        </Grid>
      </React.Fragment>
      <InboxComposeDialog />
    </LayoutInbox>
  );
};
