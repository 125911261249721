import React from 'react';

import { MetricCellHeader, MetricCellToggle } from 'src/components';
import { MetricValue } from 'src/graphql';

export const renderToggleColumn = (metricValue: MetricValue, idx: number): Record<any, any> => {
  const settings: Record<any, any> = {
    children: metricValue,
    props: {},
  };

  switch (idx) {
    case 0: {
      settings.props.colSpan = 0;
      break;
    }
    case 1: {
      settings.children = <MetricCellHeader type="on?" />;
      settings.props.align = 'center';
      break;
    }
    default: {
      settings.children = <MetricCellToggle metricValue={metricValue} />;
    }
  }
  return settings;
};
