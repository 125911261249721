import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { ReactComponent as TierBadge } from 'src/assets/icons/tier-badge.svg';

interface DetailsProps {
  tier: number;
  title: string;
  description: string;
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  badgeContainer: {
    maxWidth: 80,
    minWidth: 80,
    marginRight: theme.spacing(2),
    position: 'relative',
  },
  badge: {
    fill: (props: { badgeColor: string }) => props.badgeColor,
  },
  badgeTier: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    lineHeight: 0,
  },
  info: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
}));

export const Detail: React.FC<DetailsProps> = props => {
  const { tier, color, title, description } = props;

  const classes = useStyles({
    badgeColor: color,
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.badgeContainer}>
        <TierBadge className={classes.badge} />
        <Typography variant="h4" align="left" className={classes.badgeTier}>
          {tier}
        </Typography>
      </Box>

      <Box className={classes.info}>
        <Typography variant="subtitle1" align="left">
          {title}
        </Typography>
        <Typography variant="body1" align="left">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
