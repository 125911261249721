import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Button, Grid } from '@material-ui/core';

import { ModalsContext } from '../../providers';
import { DIALOGS as APP_DIALOGS } from 'src/dialogs';

const useStyles = makeStyles((theme: Theme) => ({
  letsTalkButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const ButtonLetsTalk: React.FC = () => {
  const classes = useStyles();

  const { openModal } = React.useContext(ModalsContext);

  const openCalendlyWidget = () => {
    openModal(APP_DIALOGS.CALENDLY_WIDGET_DIALOG);
  };

  return (
    <Grid className={classes.letsTalkButton} container justify="flex-end">
      <Button onClick={openCalendlyWidget} color="secondary" variant="outlined" size="medium">
        Let&apos;s talk
      </Button>
    </Grid>
  );
};
