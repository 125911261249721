import * as yup from 'yup';

export const EmailSchema = yup
  .string()
  .email('Invalid email address')
  .nullable();

export const EmailRequiredSchema = yup
  .string()
  .email('Invalid email address')
  .nullable()
  .required('Email Address is required');
