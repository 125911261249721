import React from 'react';
import { EmailOutlined } from '@material-ui/icons';
import { FormControlLabel, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const ContactUs: React.FC = () => {
  const useStyles = makeStyles(() => ({
    formControl: {
      padding: '0 24px',
    },
  }));

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.formControl}
      control={
        <a href="mailto:support@bigfootcap.com">
          <IconButton color="primary">
            <EmailOutlined />
          </IconButton>
        </a>
      }
      label={'support@bigfootcap.com'}
      labelPlacement="end"
    />
  );
};
