import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
} from '@material-ui/core';

import { TIER_TO_STRENGTH } from 'src/constants';

interface ScoringChartProps {
  bounds: number[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  table: {},
  tableHeadRow: {
    '& .MuiTableCell-root': {
      background: `${theme.palette.common.black} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  tableRow: {
    '&:nth-of-type(even)': {
      background: theme.palette.grey[200],
    },

    '& .MuiTableCell-root': {
      borderBottom: 'none !important',
    },
  },
  tableCell: {
    padding: theme.spacing(3),
  },
}));

export const ScoringChart: React.FC<ScoringChartProps> = ({ bounds }) => {
  const classes = useStyles();

  const scorings = [100, ...bounds].map((bound, idx, array) => {
    const tier = idx + 1;
    let score = '';
    if (!array[idx + 1]) score = `< ${bound}`;
    else score = `${array[idx + 1]}-${bound}`;

    return {
      tier,
      score,
      strength: TIER_TO_STRENGTH[tier],
    };
  });

  return (
    <Paper elevation={1} className={classes.root}>
      <Typography variant="h5" className={classes.title} align="center">
        Scoring Chart
      </Typography>

      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell align="center">Tier</TableCell>
            <TableCell align="center">Score</TableCell>
            <TableCell align="center">Strength</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scorings.map(scoring => (
            <TableRow key={scoring.strength} className={classes.tableRow}>
              <TableCell align="center" className={classes.tableCell}>
                <Typography variant="body1">{scoring.tier}</Typography>
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                <Typography variant="body1">{scoring.score}</Typography>
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                <Typography variant="body1">{scoring.strength}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
