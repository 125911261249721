import React from 'react';
import * as R from 'ramda';
import { ModalsContext, usePopover, useAllowed } from 'src/app-builder';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  Switch,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { useNotification } from 'src/hooks';
import { DIALOGS } from 'src/dialogs';
import {
  commonErrorResolver,
  DateFormatPatterns,
  formatMoney,
  formatPercents,
  formatMultiplier,
  formatDate,
  t,
} from 'src/utils';
import { DrawItem } from './DrawItem';
import {
  Draw,
  Facility,
  FacilityUpdateMutation,
  FacilityUpdateMutationVariables,
  EventType,
  FACILITY_EDIT_MUTATION,
  EVENT_RESOLVER_MUTATION,
  RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION,
} from 'src/graphql';
import { Permission } from 'src/constants/permissions';

const useStyles = makeStyles((theme: Theme) => ({
  actionsButton: {
    position: 'absolute',
    right: theme.spacing(7),
    transform: 'translateY(-50%)',
    top: '50%',
  },
  tableCellLabel: {
    fontWeight: 500,
    lineHeight: `${theme.spacing(5)}px`,
    whiteSpace: 'nowrap',
  },
  tableCellComment: {
    wordBreak: 'break-all',
  },
  thumb: {
    '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
      border: '1px grey solid',
    },
  },
}));

interface FacilityAccordionProps {
  facility: Facility;
  companyId?: string;
  index: number;
  defaultExpanded?: boolean;
}

export const FacilityAccordion: React.FC<FacilityAccordionProps> = ({
  facility,
  companyId,
  index,
  defaultExpanded,
}) => {
  const { openModal } = React.useContext(ModalsContext);
  const notification = useNotification();

  const classes = useStyles();

  const actionsPopover = usePopover();

  const isAllowed = useAllowed();

  const [canAddDraw, canEditDraw, canDeleteDraw, canChangeFacilityActive, canChangeDrawActive] = [
    isAllowed(Permission.LoanApplicationsAddDraw),
    isAllowed(Permission.LoanApplicationsEditDraw),
    isAllowed(Permission.LoanApplicationsDeleteDraw),
    isAllowed(Permission.LoanApplicationsChangeFacilityActive),
    isAllowed(Permission.LoanApplicationsChangeDrawActive),
  ];

  const [recalculate] = useMutation(RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION, {
    refetchQueries: [
      'MetricsCompanyHeader',
      'FacilitiesTableContent',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
  });

  const [facilityUpdate] = useMutation<FacilityUpdateMutation, FacilityUpdateMutationVariables>(
    FACILITY_EDIT_MUTATION,
  );

  const [portfolioCompanyUpdateActive] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['FacilitiesTableContent'],
    awaitRefetchQueries: true,
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const openCreateDrawDialog = React.useCallback(() => {
    openModal(DIALOGS.DRAW_CREATE_DIALOG, { facilityId: facility?.id, companyId });
  }, [openModal, facility, companyId]);

  const openActions = React.useCallback(
    (event: any) => {
      event.stopPropagation();

      actionsPopover.openPopover(event);
    },
    [actionsPopover],
  );

  const openEditFacilityDialog = React.useCallback(
    (event: any) => {
      event.stopPropagation();

      openModal(DIALOGS.FACILITY_EDIT_DIALOG, { initialValues: facility });

      actionsPopover.closePopover();
    },
    [openModal, facility, actionsPopover],
  );

  const openDeleteFacilityDialog = React.useCallback(
    (event: any) => {
      event.stopPropagation();

      openModal(DIALOGS.FACILITY_DELETE_DIALOG, { facilityId: facility.id, companyId });

      actionsPopover.closePopover();
    },
    [openModal, facility, companyId, actionsPopover],
  );

  const onChangeActive = React.useCallback(() => {
    openModal(DIALOGS.CONFIRMATION_DIALOG, {
      message: facility.active ? DIALOGS.FACILITY_DISABLE_MESSAGE : DIALOGS.FACILITY_ENABLE_MESSAGE,
      onConfirm: async () => {
        try {
          await facilityUpdate({
            variables: { data: { id: facility.id, active: !facility.active } },
          });

          await recalculate({ variables: { facilityId: facility.id } });

          await portfolioCompanyUpdateActive({
            variables: {
              event: {
                type: EventType.PortfolioCompanyUpdateActive,
                payload: {
                  companyId,
                },
              },
            },
          });
        } catch (error) {
          console.error({ error });
          notification.error(t('facility_update_error'));
        }
      },
    });
  }, [
    openModal,
    facilityUpdate,
    portfolioCompanyUpdateActive,
    facility,
    companyId,
    notification,
    recalculate,
  ]);

  return (
    <Accordion key={facility?.id} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<icons.ExpandMore />} id={`facility-${index}`}>
        <Typography variant="button">Facility #{index}</Typography>
        {(canDeleteDraw || canEditDraw) && (
          <IconButton className={classes.actionsButton} onClick={openActions}>
            <icons.MoreVert />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Active?</TableCell>
                  <TableCell align="right">
                    <Switch
                      className={classes.thumb}
                      disabled={!canChangeFacilityActive}
                      checked={Boolean(facility?.active)}
                      onChange={onChangeActive}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Structure</TableCell>
                  <TableCell align="right">{facility?.structure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>$ Committed</TableCell>
                  <TableCell align="right">{formatMoney(facility?.amountCommitted)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Total $ Deployed</TableCell>
                  <TableCell align="right">{formatMoney(facility?.totalAmountDeployed)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Advance Rate</TableCell>
                  <TableCell align="right">{formatMultiplier(facility?.advanceRate)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Final Advance Rate</TableCell>
                  <TableCell align="right">
                    {formatDate(facility?.finalAdvanceRate, DateFormatPatterns.shortDateWithSlash)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid xs={6} item>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Closing Date</TableCell>
                  <TableCell align="right">
                    {formatDate(facility?.closingDate, DateFormatPatterns.shortDateWithSlash)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>
                    Closing Fee {facility?.closingFee ? `(${facility?.closingFee})` : null}
                  </TableCell>
                  <TableCell align="right">
                    {R.isNil(facility?.closingFeeValue) ? '' : `${facility?.closingFeeValue} %`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Success Fee</TableCell>
                  <TableCell align="right">{formatPercents(facility?.successFee)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Validity Guarantee</TableCell>
                  <TableCell align="right">{facility?.validityGuarantee}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Comments</TableCell>
                  <TableCell align="right" className={classes.tableCellComment}>
                    {facility?.comments}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellLabel}>Next Available Draw</TableCell>
                  <TableCell align="right">
                    {formatDate(facility?.nextAvailableDraw, DateFormatPatterns.shortDateWithSlash)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          {(facility?.draws?.items || []).map((item: Draw, index: number) => (
            <DrawItem
              key={item.id}
              facility={facility}
              draw={item}
              index={index}
              canEditDraw={canEditDraw}
              canDeleteDraw={canDeleteDraw}
              canChangeDrawActive={canChangeDrawActive}
            />
          ))}
          {canAddDraw && (
            <Grid xs={12} item container justify="flex-end">
              <Button color="secondary" variant="outlined" onClick={openCreateDrawDialog}>
                Add Draw to this Facility
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>

      <Popover
        disableRestoreFocus
        anchorEl={actionsPopover.el}
        open={Boolean(actionsPopover.el)}
        onClose={actionsPopover.closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          <MenuItem disabled={!canEditDraw} onClick={openEditFacilityDialog}>
            Edit
          </MenuItem>
          <MenuItem disabled={!canDeleteDraw} onClick={openDeleteFacilityDialog}>
            Delete
          </MenuItem>
        </MenuList>
      </Popover>
    </Accordion>
  );
};
