import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Theme,
  Box,
  Avatar,
  Divider,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';

import { useCurrentUser } from '../../hooks';
import { ModalsContext, useClientRoles } from '../../providers';
import { DIALOGS, SwitchRoleDialog } from '../../dialogs';
import { CalendlyWidgetDialog } from 'src/dialogs';
import { ButtonLetsTalk } from './ButtonLetsTalk';
import { Breadcrumbs } from './Breadcrumbs';
import { AppRole } from 'src/types';
import { getUserInitials } from '../../utils';
import { getCurrentUserRole, isSafari } from 'src/utils';
import { APP_SETTING } from 'src/constants/app';
import { useMainNavigation } from 'src/app-builder/providers/MainNavigationProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    gridArea: 'header',
    zIndex: 2,
  },
  userMenuToggle: {
    cursor: 'pointer',
  },
  spaced: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    fill: theme.palette.grey[400],
  },
}));

export const Header: React.FC = () => {
  const { isExpanded, toggleExpanded } = useMainNavigation();

  const classes = useStyles();

  const { authClient } = useAuth();

  const { user } = useCurrentUser();

  const { openModal } = React.useContext(ModalsContext);

  const { currentRole } = useClientRoles();

  const { isAppCustomer, isAppCustomerOwner } = getCurrentUserRole(currentRole as AppRole);

  const currentRoleName = currentRole?.title || currentRole?.name;

  const showLetsTalkButton = isAppCustomer || isAppCustomerOwner;

  const [userEl, setUserEl] = React.useState<HTMLElement | null>(null);

  const openUser = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setUserEl(event.currentTarget);
    },
    [setUserEl],
  );

  const closeUser = React.useCallback(() => {
    setUserEl(null);
  }, [setUserEl]);

  const openSwitchRoleDialog = React.useCallback(() => {
    openModal(DIALOGS.SWITCH_ROLE_DIALOG);

    closeUser();
  }, [openModal, closeUser]);

  const onSignOut = React.useCallback(async (): Promise<void> => {
    await authClient.logout();

    if (isSafari()) {
      await authClient.purgeState();
    }

    closeUser();
  }, [authClient, closeUser]);

  return (
    <Paper className={classes.root} square elevation={3}>
      <Toolbar>
        <IconButton onClick={toggleExpanded}>
          {isExpanded ? <icons.FormatIndentDecrease /> : <icons.FormatIndentIncrease />}
        </IconButton>

        <Breadcrumbs />

        {showLetsTalkButton && <ButtonLetsTalk />}

        <Divider className={classes.spaced} orientation="vertical" />

        <Box
          className={classes.userMenuToggle}
          display="flex"
          alignItems="center"
          onClick={openUser}
        >
          <Avatar src={user?.avatar?.downloadUrl || ''}>{getUserInitials(user)}</Avatar>
          <Box className={classes.spaced} display="flex" flexDirection="column">
            <Typography noWrap>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="caption">{currentRoleName}</Typography>
          </Box>
          <icons.KeyboardArrowDown className={classes.icon} />
        </Box>

        <Popover
          disableRestoreFocus
          anchorEl={userEl}
          open={Boolean(userEl)}
          onClose={closeUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List>
            <ListItem button component={Link} to="/account">
              <ListItemIcon>
                <icons.AccountCircle className={classes.icon} />
              </ListItemIcon>
              <ListItemText>My Account</ListItemText>
            </ListItem>
            <Divider />
            {APP_SETTING.roleSwitchEnabled && (
              <React.Fragment>
                <ListItem button onClick={openSwitchRoleDialog}>
                  <ListItemIcon>
                    <icons.CompareArrows className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText>Switch Role</ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>
            )}
            <ListItem button onClick={onSignOut}>
              <ListItemIcon>
                <icons.PowerSettingsNew className={classes.icon} />
              </ListItemIcon>
              <ListItemText>Sign Out</ListItemText>
            </ListItem>
          </List>
        </Popover>

        <SwitchRoleDialog />
        <CalendlyWidgetDialog />
      </Toolbar>
    </Paper>
  );
};
