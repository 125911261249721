import React from 'react';
import * as R from 'ramda';

import { ColumnsType } from 'rc-table/es/interface';
import { MetricArrow } from 'src/components';
import { Format, CASH_MONITORING_COLUMNS_ORDER } from 'src/constants';
import { CashMonitoringRecord } from 'src/graphql';
import { LinkRenderer } from 'src/shared/table/renderers/LinkRenderer';
import { SortTitleRenderer } from 'src/shared/table/renderers/SortTitleRenderer';
import {
  CashMonitoringTableRow,
  CashMonitoringRowKey,
  EditableField,
  CashMonitoringColumn,
  CELL_TYPES,
} from 'src/types';
import { getFormattedMetricValue, formatDate, DateFormatPatterns } from 'src/utils';
import { EditableCell } from './';

export const renderNumericCell = (
  r: CashMonitoringTableRow,
  key: CashMonitoringRowKey,
  format: Format = Format.Money,
): React.ReactNode | string => {
  const value = r[key];

  if (!R.isNil(value)) {
    const color = value < 0 ? '#eb4336' : 'rgba(0,0,0,0.87)';
    const formatted = getFormattedMetricValue(value as number, format);

    return <span style={{ color }}>{formatted}</span>;
  }

  return '-';
};

export const getColumns = (
  openEditForm: (
    event: React.MouseEvent<HTMLTableCellElement>,
    record: CashMonitoringRecord | null,
    field: EditableField,
  ) => void,
): ColumnsType<Record<string, any>> => {
  const columns: Record<string, any> = {
    [CashMonitoringColumn.CompanyName]: {
      title: (
        <SortTitleRenderer field="company.name">
          {CashMonitoringColumn.CompanyName}
        </SortTitleRenderer>
      ),
      exportData: {
        header: CashMonitoringColumn.CompanyName,
        type: CELL_TYPES.text,
        key: CashMonitoringRowKey.CompanyName,
        width: 30,
      },
      key: CashMonitoringRowKey.CompanyName,
      render: (r: CashMonitoringTableRow) =>
        LinkRenderer(r[CashMonitoringRowKey.CompanyName], `/portfolio-companies/${r.id}/dashboard`),
      align: 'left',
      fixed: 'left',
      className: 'fixed',
      style: {
        padding: 0,
      },
      width: 180,
    },

    [CashMonitoringColumn.CashPositionBanking]: {
      title: CashMonitoringColumn.CashPositionBanking,
      exportData: {
        header: CashMonitoringColumn.CashPositionBanking,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.CashPositionBanking,
        width: 20,
      },
      key: CashMonitoringRowKey.CashPositionBanking,
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.CashPositionBanking),
      align: 'center',
      width: 180,
    },

    [CashMonitoringColumn.CashPositionDate]: {
      title: CashMonitoringColumn.CashPositionDate,
      exportData: {
        header: CashMonitoringColumn.CashPositionDate,
        type: CELL_TYPES.date,
        key: CashMonitoringRowKey.CashPositionDate,
        width: 20,
      },
      key: CashMonitoringRowKey.CashPositionDate,
      align: 'center',
      width: 170,
      render: (r: CashMonitoringTableRow) =>
        formatDate(r[CashMonitoringRowKey.CashPositionDate], DateFormatPatterns.shortDateWithSlash),
    },

    [CashMonitoringColumn.MinCashBalanceCovenant]: {
      title: CashMonitoringColumn.MinCashBalanceCovenant,
      exportData: {
        header: CashMonitoringColumn.MinCashBalanceCovenant,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.MinCashBalanceCovenant,
        width: 20,
      },
      key: CashMonitoringRowKey.MinCashBalanceCovenant,
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.MinCashBalanceCovenant),
      align: 'center',
      width: 225,
    },

    [CashMonitoringColumn.ChangeInCashLast2Weeks]: {
      title: CashMonitoringColumn.ChangeInCashLast2Weeks,
      exportData: {
        header: CashMonitoringColumn.ChangeInCashLast2Weeks,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.ChangeInCashLast2Weeks,
        width: 20,
      },
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.ChangeInCashLast2Weeks),
      key: CashMonitoringRowKey.ChangeInCashLast2Weeks,
      align: 'center',
      width: 225,
    },

    [CashMonitoringColumn.ChangeInCashLast4Weeks]: {
      title: CashMonitoringColumn.ChangeInCashLast4Weeks,
      exportData: {
        header: CashMonitoringColumn.ChangeInCashLast4Weeks,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.ChangeInCashLast4Weeks,
        width: 20,
      },
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.ChangeInCashLast4Weeks),
      key: CashMonitoringRowKey.ChangeInCashLast4Weeks,
      align: 'center',
      width: 225,
    },

    [CashMonitoringColumn.CashRunwayBanking]: {
      title: CashMonitoringColumn.CashRunwayBanking,
      exportData: {
        header: CashMonitoringColumn.CashRunwayBanking,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.CashRunwayBanking,
        width: 20,
      },
      key: CashMonitoringRowKey.CashRunwayBanking,
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.CashRunwayBanking, Format.Month),
      align: 'center',
      width: 180,
    },

    [CashMonitoringColumn.MinRunwayCovenant]: {
      title: CashMonitoringColumn.MinRunwayCovenant,
      exportData: {
        header: CashMonitoringColumn.MinRunwayCovenant,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.MinRunwayCovenant,
        width: 20,
      },
      key: CashMonitoringRowKey.MinRunwayCovenant,
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.MinRunwayCovenant, Format.Month),
      align: 'center',
      width: 180,
    },

    [CashMonitoringColumn.ScoreMonth]: {
      title: CashMonitoringColumn.ScoreMonth,
      exportData: {
        header: CashMonitoringColumn.ScoreMonth,
        type: CELL_TYPES.text,
        key: CashMonitoringRowKey.ScoreMonth,
        width: 20,
      },
      key: CashMonitoringRowKey.ScoreMonth,
      render: (r: CashMonitoringTableRow) => r[CashMonitoringRowKey.ScoreMonth],
      align: 'center',
      width: 125,
    },

    [CashMonitoringColumn.Tier]: {
      title: CashMonitoringColumn.Tier,
      exportData: {
        header: CashMonitoringColumn.Tier,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.Tier,
        width: 20,
      },
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.Tier, Format.Count),
      key: CashMonitoringRowKey.Tier,
      align: 'center',
      width: 100,
    },

    [CashMonitoringColumn.Score]: {
      title: CashMonitoringColumn.Score,
      exportData: {
        header: CashMonitoringColumn.Score,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.Score,
        width: 20,
      },
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.Score, Format.Count),
      key: CashMonitoringRowKey.Score,
      align: 'center',
      width: 100,
    },

    [CashMonitoringColumn.ScoreChange]: {
      title: CashMonitoringColumn.ScoreChange,
      exportData: {
        header: CashMonitoringColumn.ScoreChange,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.ScoreChange,
        width: 20,
      },
      render: (r: CashMonitoringTableRow) =>
        renderNumericCell(r, CashMonitoringRowKey.ScoreChange, Format.Count),
      key: CashMonitoringRowKey.ScoreChange,
      align: 'center',
      width: 125,
    },

    [CashMonitoringColumn.ScoreTrendMoM]: {
      title: CashMonitoringColumn.ScoreTrendMoM,
      exportData: {
        header: CashMonitoringColumn.ScoreTrendMoM,
        type: CELL_TYPES.number,
        key: CashMonitoringRowKey.ScoreTrendMoM,
        width: 20,
      },
      // eslint-disable-next-line react/display-name
      render: (r: CashMonitoringTableRow) => (
        <MetricArrow arrowType={r[CashMonitoringRowKey.ScoreTrendMoM]} />
      ),
      key: CashMonitoringRowKey.ScoreTrendMoM,
      align: 'center',
      width: 150,
    },

    [CashMonitoringColumn.Strengths]: {
      title: CashMonitoringColumn.Strengths,
      exportData: {
        header: CashMonitoringColumn.Strengths,
        type: CELL_TYPES.text,
        key: CashMonitoringRowKey.Strengths,
        width: 40,
      },
      render: (r: CashMonitoringTableRow) => r[CashMonitoringRowKey.Strengths],
      key: CashMonitoringRowKey.Strengths,
      align: 'center',
      width: 300,
    },

    [CashMonitoringColumn.Weaknesses]: {
      title: CashMonitoringColumn.Weaknesses,
      exportData: {
        header: CashMonitoringColumn.Weaknesses,
        type: CELL_TYPES.text,
        key: CashMonitoringRowKey.Weaknesses,
        width: 40,
      },
      render: (r: CashMonitoringTableRow) => r[CashMonitoringRowKey.Weaknesses],
      key: CashMonitoringRowKey.Weaknesses,
      align: 'center',
      width: 300,
    },

    [CashMonitoringColumn.Notes]: {
      title: CashMonitoringColumn.Notes,
      exportData: {
        header: CashMonitoringColumn.Notes,
        type: CELL_TYPES.text,
        key: CashMonitoringRowKey.Notes,
        width: 40,
      },
      // eslint-disable-next-line react/display-name
      render: (r: CashMonitoringTableRow) => {
        const record = r[CashMonitoringRowKey.Notes];

        return <EditableCell value={record?.[EditableField.Note]} />;
      },
      key: CashMonitoringRowKey.Notes,
      className: 'editable',
      align: 'center',
      width: 300,
      onCell: (data: CashMonitoringTableRow) => ({
        onClick: (e: React.MouseEvent<HTMLTableCellElement>) =>
          openEditForm(e, data[CashMonitoringRowKey.Notes], EditableField.Note),
      }),
    },

    [CashMonitoringColumn.ActionItem]: {
      title: CashMonitoringColumn.ActionItem,
      exportData: {
        header: CashMonitoringColumn.ActionItem,
        type: CELL_TYPES.text,
        key: CashMonitoringRowKey.ActionItem,
        width: 40,
      },
      // eslint-disable-next-line react/display-name
      render: (r: CashMonitoringTableRow) => {
        const record = r[CashMonitoringRowKey.ActionItem];

        return <EditableCell value={record?.[EditableField.Action]} />;
      },
      key: CashMonitoringRowKey.ActionItem,
      className: 'editable',
      align: 'center',
      width: 300,
      onCell: (data: CashMonitoringTableRow) => ({
        onClick: (e: React.MouseEvent<HTMLTableCellElement>) =>
          openEditForm(e, data[CashMonitoringRowKey.ActionItem], EditableField.Action),
      }),
    },
  };

  return CASH_MONITORING_COLUMNS_ORDER.map(col => columns[col]);
};
