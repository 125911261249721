import React from 'react';
import * as R from 'ramda';
import { useQuery, useMutation } from 'react-apollo';

import { useNotification } from '.';
import {
  COVENANT_VALUE_LIST_QUERY,
  SET_COVENANT_VALUES,
  UPDATE_COVENANT_VALUE,
  COVENANT_LIST_QUERY,
  SET_COVENANT_VALUE,
  DELETE_COVENANT_VALUE,
  CovenantListQuery,
  CovenantListQueryVariables,
  CovenantValuesListQuery,
  CovenantValuesListQueryVariables,
} from 'src/graphql';
import { convertCovenantDataForForm, CovenantForFormType } from 'src/utils';
import { t } from 'src/utils';

type CovenantValuesData = {
  [key: string]: string;
};

interface CovenantTrackingHook {
  upsertCovenantValue: (data: CovenantValuesData) => Promise<void>;
  upsertCovenantStatus: (covenantName: string) => Promise<void>;
  upsertCovenantError: boolean;
  covenants: any;
  dataLoading: boolean;
  upsertLoading: boolean;
}

export const useCovenantTrackingValues = (
  companyId: string | null | undefined,
): CovenantTrackingHook => {
  const notification = useNotification();

  const [upsertCovenantError, setUpsertCovenantError] = React.useState(false);

  const { data: covenantsData, loading: covenantsDataLoading } = useQuery<
    CovenantListQuery,
    CovenantListQueryVariables
  >(COVENANT_LIST_QUERY);
  const { data: covenantValuesData, loading: covenantValuesLoading } = useQuery<
    CovenantValuesListQuery,
    CovenantValuesListQueryVariables
  >(COVENANT_VALUE_LIST_QUERY, {
    variables: {
      filter: {
        company: { id: { equals: companyId } },
      },
    },
    skip: !companyId,
  });

  const [createCovenantRecord, { loading: createCovenantLoading }] = useMutation(
    SET_COVENANT_VALUE,
    {
      refetchQueries: ['CovenantValuesList'],
      awaitRefetchQueries: true,
      onError: error => {
        notification.error(t('covenant_tracking_update_error'));
        throw error;
      },
    },
  );
  const [createManyCovenantRecord, { loading: createCovenantManyLoading }] = useMutation(
    SET_COVENANT_VALUES,
    {
      refetchQueries: ['CovenantValuesList'],
      awaitRefetchQueries: true,
      onError: error => {
        notification.error(t('covenant_tracking_update_error'));
        throw error;
      },
    },
  );
  const [updateCovenantRecord, { loading: updateCovenantLoading }] = useMutation(
    UPDATE_COVENANT_VALUE,
    {
      refetchQueries: ['CovenantValuesList'],
      awaitRefetchQueries: true,
      onError: error => {
        notification.error(t('covenant_tracking_update_error'));
        throw error;
      },
    },
  );
  const [deleteCovenantRecord, { loading: deleteCovenantLoading }] = useMutation(
    DELETE_COVENANT_VALUE,
    {
      refetchQueries: ['CovenantValuesList'],
      awaitRefetchQueries: true,
      onError: error => {
        notification.error(t('covenant_tracking_update_error'));
        throw error;
      },
    },
  );

  const covenants = React.useMemo(() => {
    if (!covenantValuesData) {
      return;
    }

    return convertCovenantDataForForm(covenantsData, covenantValuesData);
  }, [covenantsData, covenantValuesData]) as CovenantForFormType;

  const upsertCovenantValue = React.useCallback(
    async (data: CovenantValuesData) => {
      const changedCovenantNames = Object.keys(data);
      const covenantsForUpdate = changedCovenantNames.filter(covenantName => {
        return !R.isNil(covenants[covenantName]?.covenantValueId);
      });

      // TODO Refactor deletion logic
      const existingCovenants = Object.keys(covenants)?.filter(
        covenantName => covenants[covenantName]?.covenantValueId,
      );

      const covenantsForDelete = existingCovenants.filter(covenantNameFromData => {
        return !changedCovenantNames.includes(covenantNameFromData);
      });

      const covenantsToDelete = [...covenantsForDelete, ...covenantsForUpdate];

      const covenantDataToSend = changedCovenantNames.map(covenantName => {
        return {
          company: { connect: { id: companyId } },
          covenant: { connect: { id: covenants[covenantName]?.covenantId } },
          isEnabled: covenants[covenantName]?.isEnabled,
          value: data[covenantName],
        };
      });

      try {
        covenantsToDelete.forEach(async covenantName => {
          await deleteCovenantRecord({
            variables: {
              filter: {
                id: covenants[covenantName]?.covenantValueId,
              },
            },
          });
        });

        if (covenantDataToSend.length) {
          await createManyCovenantRecord({
            variables: {
              data: covenantDataToSend,
            },
          });
        }
        notification.success(t('covenant_tracking_update_success'));
      } catch (error) {
        console.error({ error });
        setUpsertCovenantError(true);
      }
    },
    [covenants, companyId, notification, deleteCovenantRecord, createManyCovenantRecord],
  );

  const upsertCovenantStatus = async (covenantName: string) => {
    try {
      if (!R.isNil(covenants[covenantName]?.covenantValueId)) {
        await updateCovenantRecord({
          variables: {
            data: {
              isEnabled: !covenants[covenantName]?.isEnabled,
            },
            filter: {
              id: covenants[covenantName]?.covenantValueId,
            },
          },
        });
      } else {
        await createCovenantRecord({
          variables: {
            data: {
              company: { connect: { id: companyId } },
              covenant: { connect: { id: covenants[covenantName]?.covenantId } },
              isEnabled: true,
            },
          },
        });
      }
    } catch (error) {
      console.error({ error });
      setUpsertCovenantError(true);
    }
  };

  const dataLoading = covenantsDataLoading || covenantValuesLoading;
  const upsertLoading =
    createCovenantLoading ||
    createCovenantManyLoading ||
    updateCovenantLoading ||
    deleteCovenantLoading;

  return {
    upsertCovenantValue,
    upsertCovenantStatus,
    upsertCovenantError,
    covenants,
    dataLoading,
    upsertLoading,
  };
};
