import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tiersContainer: { display: 'flex', justifyContent: 'space-between' },
  tier: { margin: '0 auto' },
}));

export const Tiers = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.tiersContainer}>
      <span className={classes.tier}>tier 2</span>
      <span className={classes.tier}>tier 3</span>
      <span className={classes.tier}>tier 4</span>
      <span className={classes.tier}>tier 5</span>
    </div>
  );
};
