import React from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SaasScoreResults } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  section: {
    width: '100%',
  },
}));

interface CustomerHomeSaaSScorePageProps {}

export const CustomerHomeSaaSScorePage: React.FC<CustomerHomeSaaSScorePageProps> = () => {
  const classes = useStyles();

  return (
    <Grid spacing={3} className={classes.root} container>
      <Grid item className={classes.section}>
        <SaasScoreResults />
      </Grid>
    </Grid>
  );
};
