import { gql } from '8base-react-sdk';

export const STRIPE_GET_AUTH_URL_MUTATION = gql`
  mutation StripeGetAuthUrl($companyId: ID!, $redirectUrl: String) {
    stripeGetAuthUrl(companyId: $companyId, redirectUrl: $redirectUrl) {
      url
    }
  }
`;

export const STRIPE_DELETE_TOKEN_MUTATION = gql`
  mutation StripeDeleteToken($stripeIntegrationId: ID!) {
    stripeDeleteToken(stripeIntegrationId: $stripeIntegrationId) {
      success
    }
  }
`;
