import React from 'react';
import * as R from 'ramda';
import { Grid, Typography, Theme, Box, makeStyles } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import { useLoanApplication, useProgressStages } from 'src/hooks';
import { ProgressStageList, ProgressStepProps } from 'src/components';
import { LoanApplicationStage, ProgressStepType } from 'src/constants';
import { ProgressCompanyStep, ProgressStage, ProgressStep, ProgressStepValue } from 'src/graphql';
import { ProgressStepCode } from 'src/types';
import {
  getCompanyStepStatus,
  getProgressValueStatus,
  isActiveProgressCompanyStep,
  isActiveProgressStepValue,
  isVisibleProgressStepValue,
  parseStepNameToCode,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(10),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
}));

export const LoanApplicationStatusPage: React.FC = () => {
  const classes = useStyles();

  const { data: loanApplication, loading: loanApplicationLoading } = useLoanApplication();

  const companyId = loanApplication?.company?.id as string;
  const loanApplicationStage = loanApplication?.stage as LoanApplicationStage;

  const {
    data: progressStages,
    loading: progressStagesLoading,
    upsertStepStatus,
    upsertStepName,
    insertCompanyStep,
    upsertStepVisibility,
    upsertStepComments,
    upsertStepLoading,
  } = useProgressStages(companyId, loanApplicationStage);

  const getStepsFromStage = (stage: ProgressStage) => {
    const convertStepToProps = (step: ProgressStep | ProgressCompanyStep) => {
      const { name: defaultName, code, comment: defaultComment } = step;
      const stepValue = (step as ProgressStep)?.stepValue;
      const progressCompanyValue = R.pathOr(null, ['items', 0], stepValue);
      const name = R.pathOr(defaultName, ['customStepName'], progressCompanyValue) as string;
      const comment = R.pathOr(
        defaultComment,
        ['customStepComment'],
        progressCompanyValue,
      ) as string;
      const status = stepValue
        ? getProgressValueStatus(progressCompanyValue)
        : getCompanyStepStatus(step as ProgressCompanyStep);
      const active = stepValue
        ? isActiveProgressStepValue(progressCompanyValue)
        : isActiveProgressCompanyStep(step as ProgressCompanyStep);
      const isVisible = stepValue
        ? isVisibleProgressStepValue(progressCompanyValue)
        : (step as ProgressCompanyStep).isVisible;
      const bigfootTeamComment = stepValue
        ? ((progressCompanyValue as unknown) as ProgressStepValue)?.bigfootTeamComment
        : (step as ProgressCompanyStep).bigfootTeamComment;
      const applicantComment = stepValue
        ? ((progressCompanyValue as unknown) as ProgressStepValue)?.applicantComment
        : (step as ProgressCompanyStep).applicantComment;

      return {
        id: step.id as string,
        code: code as ProgressStepCode,
        type: null,
        name,
        comment,
        isVisible: isVisible,
        bigfootTeamComment,
        applicantComment,
        status: status,
        active: active,
        disabled: progressStagesLoading || upsertStepLoading,
      };
    };

    const markCompanySteps = (step: ProgressStepProps) => {
      return { ...step, type: ProgressStepType.CompanyStep };
    };

    const steps = R.pipe(R.pathOr([], ['steps', 'items']), R.map(convertStepToProps))(stage);
    const companySteps = R.pipe(
      R.pathOr([], ['companySteps', 'items']),
      R.map(convertStepToProps),
      R.map(markCompanySteps),
    )(stage);
    const allSteps = [...steps, ...companySteps];
    return allSteps;
  };

  const onSelectStep = async (step: ProgressStepProps) => {
    upsertStepStatus(step);
  };

  const onChangeStep = async (step: ProgressStepProps, name: string, comment: string) => {
    const code = parseStepNameToCode(name);
    upsertStepName(step, name, code, comment);
  };

  const initCompanyStepHandler = (stageId: string | null | undefined) => {
    if (!stageId) {
      return;
    }

    return (name: string, code: string, comment: string) => {
      insertCompanyStep(stageId, name, code, comment);
    };
  };

  const loading = loanApplicationLoading || progressStagesLoading || upsertStepLoading;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Funding Progress</Typography>
        <Grid item container>
          <HelpOutline />
          <Typography>
            <Box fontStyle="italic">
              &nbsp;- Whenever you see a question mark, you can hover for detail on what we&apos;re
              asking you to provide.
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <ProgressStageList
        progressStages={progressStages}
        renderSteps={getStepsFromStage}
        upsertStepVisibility={upsertStepVisibility}
        onSelectStep={onSelectStep}
        onChangeStep={onChangeStep}
        initCompanyStepHandler={initCompanyStepHandler}
        upsertStepComments={upsertStepComments}
        loading={loading}
      />
    </Grid>
  );
};
