export enum CovenantType {
  MinCashPosition = 'MinCashPosition',
  MinCashRunway = 'MinCashRunway',
  CumulativeCashReceipts = 'CumulativeCashReceipts',
  MinCashAsPercentOfDrawsTaken = 'MinCashAsPercentOfDrawsTaken',
  CumulativeAdvanceRate = 'CumulativeAdvanceRate',
  ActualVsExpectedRevenue = 'ActualVsExpectedRevenue',
  AdditionalCovenant = 'AdditionalCovenant',
}
export enum CovenantTableType {
  AdjustedNetCashBurnL3MCovenant = 'AdjustedNetCashBurnL3MCovenant',
  MinCashPositionMonthly = 'MinCashPositionMonthly',
  MinCashRunwayMonthly = 'MinCashRunwayMonthly',
  MinCashPositionWeekly = 'MinCashPositionWeekly',
  MinCashRunwayWeekly = 'MinCashRunwayWeekly',
  CumulativeCashReceipts = 'CumulativeCashReceipts',
  MinCashAsPercentOfDrawsTaken = 'MinCashAsPercentOfDrawsTaken',
  CumulativeAdvanceRate = 'CumulativeAdvanceRate',
  ActualVsExpectedRevenue = 'ActualVsExpectedRevenue',
  AdditionalCovenant = 'AdditionalCovenant',
}

export enum CovenantRowType {
  Covenant = 'Covenant',
  Actual = 'Actual',
  Difference = 'Difference',
  InCompliance = 'In Compliance?',
}

export enum CovenantTitle {
  AdjustedNetCashBurnL3MCovenant = 'Adjusted Net Cash Burn $ L3M',
  MinCashPositionMonthly = 'Min. Cash Position (Monthly)',
  MinCashRunwayMonthly = 'Min. Cash Runway (Monthly)',
  MinCashPositionWeekly = 'Min. Cash Position (Weekly)',
  MinCashRunwayWeekly = 'Min. Cash Runway (Weekly)',
  CumulativeCashReceipts = 'Cumulative Cash Receipts',
  MinCashAsPercentOfDrawsTaken = 'Min. Cash as % of Draws Taken',
  CumulativeAdvanceRate = 'Cumulative Advance Rate',
  ActualVsExpectedRevenue = 'Actual vs Expected Revenue',
  AdditionalCovenant = 'Additional Covenant',
}

export type TotalDeployedMapByDate = Record<string, number | null>;
