import React from 'react';
import * as R from 'ramda';
import { AppProvider } from '8base-react-sdk';
import { Auth, AUTH_STRATEGIES, ISubscribableAuthClient } from '@8base/auth';
import { PaletteType, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';
import * as ChangeCase from 'change-case';
import { ErrorBoundary } from 'react-error-boundary';

import { DataLossDialog, DataLossProvider } from '../dialogs';
import { ApplicationProviderContent } from './ApplicationProviderContent';
import { ClientRolesProvider, useAllowed } from './ClientRolesProvider';
import { ModalsProvider } from './ModalsProvider';
import { ApplicationAreaActionsProvider } from './ApplicationAreaActionsProvider';
import { MainNavigationProvider } from './MainNavigationProvider';
import { useClientRoles } from './ClientRolesProvider';
import { APP_ROLES } from 'src/types';
import { createTheme } from 'src/theme';
import { APP_SETTING } from 'src/constants/app';
import { ErrorFallback } from 'src/components';
import { InboxMessagesProvider } from 'src/providers';

export const FIELD_FORMATS = {
  PLAIN: 'plain',
  CHIP: 'chip',
  LINK: 'link',
  DATE: 'date',
  AVATAR: 'avatar',
  MONEY: 'money',
};

type FieldFormat = typeof FIELD_FORMATS[keyof typeof FIELD_FORMATS];

type ApplicationAreaType = 'static' | 'dynamic';

export type ApplicationAreaAction = {
  id: string;
  link: string;
  name: string;
  type: 'link' | 'handler';
  key: string;
};

export type ApplicationAreaField = {
  id: string;
  fieldId: string;
  tableId?: string;
  fieldName: string;
  name: string;
  format: FieldFormat;
  formatOptions: any;
  showByDefault: boolean;
  sortEnabled: boolean;
};

export type ApplicationArea = {
  id: string;
  tableId: string;
  path: string;
  icon: string;
  type: ApplicationAreaType;
  areaType: 'table' | 'custom';
  location: 'main' | 'settings' | 'reports';
  position: number;
  name: string;
  title: string;
  isSystem: boolean;
  fields: {
    items: Array<ApplicationAreaField>;
  };
  fieldsOrder: string[];
  mainAction: ApplicationAreaAction;
  actions: {
    items: Array<ApplicationAreaAction>;
  };
  parentArea: {
    id: string;
  };
  code: string;
};

export type Application = {
  workspaceId: string;
  authClientId: string;
  authDomain: string;
  authProfileId?: string;
  id: string;
  mode: PaletteType;
  logo: {
    id: string;
    downloadUrl: string;
  };
  logoMark: {
    id: string;
    downloadUrl: string;
  };
  logoDark: {
    id: string;
    downloadUrl: string;
  };
  logoMarkDark: {
    id: string;
    downloadUrl: string;
  };
  theme: {
    primaryColor: string;
    secondaryColor: string;
  };
  areas: {
    items: ApplicationArea[];
  };
  helpEnabled: boolean;
  notificationsEnabled: boolean;
  inboxEnabled: boolean;
  themeSwitchEnabled: boolean;
  roleSwitchEnabled: boolean;
};

const ThemeModeContext = React.createContext<{
  themeMode: PaletteType;
  setThemeMode: (themeMode: PaletteType) => void;
}>({ themeMode: 'light', setThemeMode: () => {} });

export const useThemeMode = (): {
  themeMode: PaletteType;
  setThemeMode: (themeMode: PaletteType) => void;
} => {
  const { themeMode, setThemeMode } = React.useContext<{
    themeMode: PaletteType;
    setThemeMode: (themeMode: PaletteType) => void;
  }>(ThemeModeContext);

  return { themeMode, setThemeMode };
};

const customRedirectPathByRole = (role: string | undefined): string => {
  if (role === APP_ROLES.appAdministrator.name || role === APP_ROLES.appAnalyst.name) {
    return '/portfolio-companies';
  }

  return '';
};

export const useRedirectPath = (): string => {
  const isAllowed = useAllowed();

  const { currentRole } = useClientRoles();
  const customRedirectPath = customRedirectPathByRole(currentRole?.name);

  const areas = APP_SETTING.areas.items
    .filter(
      ({ name, location }) =>
        location === 'main' && isAllowed(ChangeCase.constantCase(name + 'Area')),
    )
    .sort((a, b) => a.position - b.position);

  if (!R.isEmpty(customRedirectPath)) {
    return customRedirectPath;
  }

  if (areas.length > 0) {
    return areas[0].path;
  }

  return '/account';
};

interface ApplicationProviderProps {
  uri: string;
  children: React.ReactNode;
  onRequestSuccess?: (request: { [key: string]: any }) => void;
  onRequestError?: (request: { [key: string]: any }) => void;
}

const authClient = Auth.createClient(
  {
    strategy: AUTH_STRATEGIES.WEB_AUTH0,
    subscribable: true,
  },
  {
    clientId: APP_SETTING.authClientId,
    domain: APP_SETTING.authDomain,
    redirectUri: `${window.location.origin}/auth/callback`,
    logoutRedirectUri: `${window.location.origin}/auth`,
  },
) as ISubscribableAuthClient;

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  uri,
  children,
  onRequestSuccess = (): void => {},
  onRequestError = (): void => {},
}) => {
  const [themeMode, setThemeMode] = React.useState<PaletteType>('light');

  const theme = createTheme();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <ThemeModeContext.Provider value={{ themeMode, setThemeMode }}>
          <ModalsProvider>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <DndProvider backend={HTML5Backend}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <AppProvider
                      uri={uri}
                      authClient={authClient}
                      onRequestSuccess={onRequestSuccess}
                      onRequestError={onRequestError}
                      withSubscriptions
                    >
                      {({ loading }): React.ReactNode => (
                        <ClientRolesProvider>
                          <ApplicationAreaActionsProvider>
                            <ApplicationProviderContent loading={loading}>
                              <MainNavigationProvider>
                                <DataLossProvider>
                                  <DataLossDialog />
                                  <InboxMessagesProvider>{children}</InboxMessagesProvider>
                                </DataLossProvider>
                              </MainNavigationProvider>
                            </ApplicationProviderContent>
                          </ApplicationAreaActionsProvider>
                        </ClientRolesProvider>
                      )}
                    </AppProvider>
                  </MuiPickersUtilsProvider>
                </DndProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </ModalsProvider>
        </ThemeModeContext.Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
