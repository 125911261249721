import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress, Paper, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

interface LayoutProps {
  table: React.ReactNode;
  head: React.ReactNode;
  pagination: React.ReactNode;
  toolbar: React.ReactNode;
  empty: React.ReactNode;
  isEmpty: boolean;
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.spacing(2),
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 101,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
}));

export const Layout: React.FC<LayoutProps> = ({
  table,
  head,
  toolbar,
  loading,
  empty,
  isEmpty,
  pagination,
}) => {
  const classes = useStyles({ loading });
  return (
    <Grid container direction="column" wrap="nowrap">
      <Paper className={classes.paper}>
        <Grid className={classes.header} item>
          {head}
        </Grid>
        {loading && <LinearProgress />}
        <Grid className={classes.content} item container direction="column">
          {toolbar}
          {isEmpty && empty}
          {!isEmpty && <div className={classes.tableWrapper}>{table}</div>}
          {!loading && !isEmpty && pagination}
        </Grid>
      </Paper>
    </Grid>
  );
};
