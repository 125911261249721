import * as R from 'ramda';
import { Company, PlaidIntegration } from 'src/graphql';
import { IntegrationStatus } from 'src/types';

export const checkIfPlaidIntegrationConnected = (company?: Company | null): boolean => {
  const connectedPlaidIntegrations = R.compose(
    R.filter((plaidIntegration: PlaidIntegration) => {
      return R.equals(IntegrationStatus.Connected, plaidIntegration.status);
    }),
    R.pathOr([], ['integration', 'plaid', 'items']),
  )(company);

  return connectedPlaidIntegrations.length > 0;
};

export const checkIfQuickBooksIntegrationConnected = (company?: Company | null): boolean =>
  R.compose(
    (status: IntegrationStatus) => R.equals(IntegrationStatus.Connected, status),
    R.pathOr(IntegrationStatus.Disconnected, ['integration', 'quickBooks', 'status']),
  )(company);

export const checkIfStripeIntegrationConnected = (company?: Company | null): boolean =>
  R.compose(
    (status: IntegrationStatus) => R.equals(IntegrationStatus.Connected, status),
    R.pathOr(IntegrationStatus.Disconnected, ['integration', 'stripe', 'status']),
  )(company);

export const checkIfXeroIntegrationConnected = (company?: Company | null): boolean =>
  R.compose(
    (status: IntegrationStatus) => R.equals(IntegrationStatus.Connected, status),
    R.pathOr(IntegrationStatus.Disconnected, ['integration', 'xero', 'status']),
  )(company);
