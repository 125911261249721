import React from 'react';
import { ActionsProvider } from 'src/providers/underwriting/ActionsProvider';
import { UnderwritingProvider } from 'src/providers';
import { FiltersContainer } from 'src/routes/portfolio-companies/portfolioCompanyUnderwritingPackagePage/components';
import { TableUnderwritingReport } from 'src/shared/metrics/TableUnderwritingReport';
import { Report as MetricReport } from 'src/types';
import { REPORT_METRIC_LABELS } from 'src/constants';
import { useTableControl } from 'src/hooks';

type ReportProps = {
  className?: string;
  activeReport: MetricReport | undefined;
  companyId: string;
  from: string | null;
  till: string | null;
  onChangeActiveReport: (report: MetricReport, from: string | null, till: string | null) => void;
  canEditMetrics: boolean;
  exportData: {
    rows: any;
    columns: any;
  };
  metrics: any;
  dates: Array<string>;
};

export const UnderwritingReport: React.FC<ReportProps> = ({
  from,
  till,
  companyId,
  activeReport,
  onChangeActiveReport,
  exportData: { columns },
  metrics,
  dates,
}) => {
  const isUnderwritingReport = Boolean(
    activeReport?.label === REPORT_METRIC_LABELS.UnderwritingPackage,
  );
  const activeColumns = isUnderwritingReport ? columns.underwritingColumns : columns.weeklyColumns;

  useTableControl();

  return (
    <UnderwritingProvider companyId={companyId}>
      <ActionsProvider companyId={companyId}>
        <FiltersContainer
          companyId={companyId}
          from={from}
          till={till}
          activeReport={activeReport}
          onChangeActiveReport={onChangeActiveReport}
        />
        <TableUnderwritingReport metrics={metrics} columns={activeColumns} dates={dates} />
      </ActionsProvider>
    </UnderwritingProvider>
  );
};
