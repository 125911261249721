import React from 'react';
import { InputAdornment, PopoverProps } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Event } from '@material-ui/icons';
import { DateTime } from 'luxon';

import { DateFormatPatterns } from 'src/utils';

interface MonthFieldProps {
  value: string | null;
  onChange: (v: string | null) => void;
  label: string;
  dateFormat?: string;
  minDate?: string;
  minDateMessage?: string;
  maxDate?: string;
  maxDateMessage?: string;
}

const POPOVER_PROPS: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const MonthField: React.FC<MonthFieldProps> = ({
  value,
  onChange,
  label,
  dateFormat = DateFormatPatterns.fullMonthAndYear,
  minDate,
  minDateMessage,
  maxDate,
  maxDateMessage,
  ...rest
}) => {
  const handleChange = React.useCallback(
    (date: DateTime | null) => {
      onChange(date ? date.endOf('month').toISODate() : date);
    },
    [onChange],
  );

  const props = {
    autoOk: true,
    format: dateFormat,
    value: value ? DateTime.fromISO(value) : null,
    onChange: handleChange,
    PopoverProps: POPOVER_PROPS,
    ...rest,
  };

  return (
    <DatePicker
      label={label}
      variant="inline"
      inputVariant="outlined"
      views={['month', 'year']}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Event color="action" />
          </InputAdornment>
        ),
      }}
      minDate={minDate && DateTime.fromISO(minDate as string)}
      minDateMessage={minDateMessage}
      maxDate={maxDate && DateTime.fromISO(maxDate as string)}
      maxDateMessage={maxDateMessage}
      {...props}
    />
  );
};
