import { canSort, getSortDirection, isActiveSort, SortDirection } from 'src/app-builder';
import { TableCell, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { AppAreaField } from 'src/constants/app';

type Sort = {
  by: string;
  dir: SortDirection;
};

export type TableHeaderCellWithSort = {
  withSort: true;
  areaField: AppAreaField;
  sort: Array<Sort>;
  createOnSort: (fieldName: string) => () => void;
};

export type TableHeaderCellWithoutSort = {
  withSort: false;
  areaField: AppAreaField;
};

type TableHeaderCellProps = TableHeaderCellWithSort | TableHeaderCellWithoutSort;

export const TableHeaderCell: React.FC<TableHeaderCellProps> = props => {
  const { areaField } = props;

  if (!props.withSort) {
    return <TableCell key={areaField.id}>{areaField.name}</TableCell>;
  } else {
    const { sort, createOnSort } = props;

    return (
      <TableCell sortDirection={getSortDirection(sort, areaField.fieldName)}>
        {canSort(areaField) ? (
          <TableSortLabel
            active={isActiveSort(sort, areaField.fieldName)}
            direction={getSortDirection(sort, areaField.fieldName)}
            onClick={createOnSort(areaField.fieldName)}
          >
            {areaField.name}
          </TableSortLabel>
        ) : (
          areaField.name
        )}
      </TableCell>
    );
  }
};
