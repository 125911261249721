/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AlignType } from 'rc-table/es/interface';

import { TableShared } from 'src/shared/table/TableShared';
import { loanMultipliersContext } from './LoanMultipliersProvider';
import { dirtyContext, MultiplierRenderer } from './';
import { LoanTerm, TermType } from 'src/types';

interface MultiplierTableProps {
  isRevenue: boolean;
}

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& td': {
      padding: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& thead td': {
      padding: '8px',
      height: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& tr': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        height: 41,
      },
    },
    '& .rc-table-container': {
      height: '100%',
    },
    '& .rc-table-body': {
      height: 'calc(100% - 41px)',
    },
  },
}));

const options = ['24 month Term Loan', '36 month Term Loan', '36 month RBF'];
const types = [TermType.TermLoan, TermType.TermLoan, TermType.RBF];
const terms = [LoanTerm.Months_24, LoanTerm.Months_36, LoanTerm.Months_36];
const emptyData = Array(3).fill({});

export const MultiplierTable: React.FC<MultiplierTableProps> = ({ isRevenue }) => {
  const classes = useStyles();
  const { loanMultiplierSettings, update } = React.useContext(loanMultipliersContext);
  const { setIsDirty } = React.useContext(dirtyContext);

  const firstColumn = {
    title: '',
    render: (_: any, __: any, idx: number) => (
      <div style={{ fontWeight: 'bold' }}>{options[idx]}</div>
    ),
  };

  const otherColumns: any = [0, 1, 2, 3, 4].map(number => ({
    title: `Tier ${number + 1}`,
    align: 'center' as AlignType,
    width: '14%',
    render: (_: any, __: any, idx: number) => (
      <MultiplierRenderer
        isRevenue={isRevenue}
        path={[types[idx], terms[idx], number]}
        loanMultiplierSettings={loanMultiplierSettings}
        setIsDirty={setIsDirty}
        update={update}
      />
    ),
  }));

  const columns = [firstColumn, ...otherColumns];

  return <TableShared className={classes.table} columns={columns} data={emptyData} />;
};
