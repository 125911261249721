import React, { FC, MouseEvent, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Delete, Edit, GetApp, MoreVert, Print } from '@material-ui/icons';
import { ModalsContext } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { DIALOGS } from 'src/dialogs';
import { DownloadPdfResponse, DownloadPdfType, DOWNLOAD_PDF, Maybe } from 'src/graphql';
import { downloadDataBase64, commonErrorResolver } from 'src/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.text.secondary,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    },
    iconButton: {
      position: 'relative',
    },
  }),
);

interface ActionsProps {
  note: any;
  path: string;
}

export const Actions: FC<ActionsProps> = props => {
  const { note, path } = props;

  const classes = useStyles();

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const history = useHistory();
  const notification = useNotification();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { openModal } = useContext(ModalsContext);

  const [downloadPdf, { loading }] = useMutation<{ downloadPdf?: Maybe<DownloadPdfResponse> }>(
    DOWNLOAD_PDF,
    {
      onError: error => notification.error(commonErrorResolver(error)),
    },
  );

  const getBase64Pdf = useCallback(
    async (noteId: string) => {
      const response = await downloadPdf({
        variables: {
          event: {
            type: DownloadPdfType.Note,
            payload: {
              noteId,
            },
          },
        },
      });

      return {
        fileName: response?.data?.downloadPdf?.response?.fileName,
        base64Pdf: response?.data?.downloadPdf?.response?.pdf,
      };
    },
    [downloadPdf],
  );

  const downloadNote = async (noteId: string) => {
    setDownloadLoading(true);
    const { fileName, base64Pdf } = await getBase64Pdf(noteId);

    setDownloadLoading(false);
    if (base64Pdf && fileName) {
      downloadDataBase64(fileName, base64Pdf);
    }
  };

  const isOpen = Boolean(anchorEl);

  const handleShowMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPdfViewDialog = useCallback(async () => {
    setPrintLoading(true);
    const { base64Pdf } = await getBase64Pdf(note.id);

    setPrintLoading(false);
    if (base64Pdf) {
      openModal(DIALOGS.PDF_VIEW_DIALOG, { title: 'Print Note', base64String: base64Pdf });
    }
  }, [getBase64Pdf, note.id, openModal]);

  const openEditNoteDialog = useCallback(() => {
    openModal(DIALOGS.NOTE_EDIT_DIALOG, { id: note?.id });
    handleClose();
  }, [openModal, note]);

  const openDeleteNoteDialog = useCallback(() => {
    openModal(DIALOGS.NOTE_DELETE_DIALOG, {
      id: note?.id,
      onSuccess: () => {
        history.push(`${path}/notes`);
      },
    });
    handleClose();
  }, [openModal, note, history, path]);

  return (
    <Box>
      <IconButton
        onClick={() => downloadNote(note.id)}
        className={classes.iconButton}
        disabled={loading}
      >
        <GetApp />
        {downloadLoading && <CircularProgress size={48} className={classes.progress} />}
      </IconButton>
      <IconButton onClick={openPdfViewDialog} className={classes.iconButton} disabled={loading}>
        <Print />
        {printLoading && <CircularProgress size={48} className={classes.progress} />}
      </IconButton>
      <IconButton onClick={handleShowMenu} disabled={loading}>
        <MoreVert />
      </IconButton>

      <Menu disableRestoreFocus anchorEl={anchorEl} open={isOpen} onClose={handleClose} keepMounted>
        <MenuItem key="edit" onClick={openEditNoteDialog}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem key="delete" onClick={openDeleteNoteDialog}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
