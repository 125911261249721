type DataFormatResult = {
  header: string;
  key: string | number;
  width: number;
};

export const getPortfolioMonitoringColumns = (dates: string[]): { columns: DataFormatResult[] } => {
  const companyNameColumn = {
    header: 'Name',
    key: 'name',
    name: 'Name',
    width: 30,
  };

  const formattedColumns = dates.map((date: string) => {
    return {
      name: date,
      header: date,
      key: date,
      width: 25,
    };
  });

  return { columns: [companyNameColumn, ...formattedColumns] };
};
