import * as yup from 'yup';
import { BUSINESS_LOCATION_VALUES } from 'src/constants';

export const PortfolioCompanyCreateSchema = yup
  .object({
    name: yup
      .string()
      .nullable()
      .required('Company Name is required'),
    contactFirstName: yup
      .string()
      .nullable()
      .required('Contact First Name is required'),
    contactLastName: yup
      .string()
      .nullable()
      .required('Contact Last Name is required'),
    contactEmail: yup
      .string()
      .nullable()
      .required('Contact Email is required'),
    primaryBusinessModel: yup
      .string()
      .nullable()
      .required('Primary Business Model is required'),
    primaryCustomerBase: yup
      .string()
      .nullable()
      .required('Primary Customer Base is required'),
    capital: yup
      .number()
      // Actually max amount equal to 2,147,483,647, but form more understandable view for customers we round down it to 2,000,000,000
      .max(2000000000, 'Amount of Capital Desired should be less than or equal to $2,000,000,000')
      .nullable(),
    address: yup.object({
      country: yup
        .string()
        .oneOf(BUSINESS_LOCATION_VALUES)
        .required('Country is required'),
    }),
  })
  .nullable()
  .required();
