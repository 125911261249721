import { gql } from '8base-react-sdk';

export const DOCUMENT_QUERY = gql`
  query DocumentsEntity($id: ID!) {
    document(id: $id) {
      id
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
        _description
      }
      file {
        id
        fileId
        filename
        downloadUrl
        shareUrl
        meta
      }
      private
      description
      type
      _description
    }
  }
`;

export const DOCUMENTS_EDIT_MUTATION = gql`
  mutation DocumentsUpdate($data: DocumentUpdateInput!, $filter: DocumentKeyFilter) {
    documentUpdate(data: $data, filter: $filter) {
      id
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
        _description
      }
      file {
        id
        fileId
        filename
        downloadUrl
        shareUrl
        meta
      }
      private
      description
      type
      _description
    }
  }
`;

export const DOCUMENTS_DELETE_MUTATION = gql`
  mutation DocumentsDelete($filter: DocumentKeyFilter!, $force: Boolean) {
    documentDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const DOCUMENTS_CREATE_MANY_MUTATION = gql`
  mutation DocumentsCreateMany($data: [DocumentCreateManyInput]!) {
    documentCreateMany(data: $data) {
      count
    }
  }
`;

export const DOCUMENTS_TABLE_QUERY = gql`
  query DocumentsTableContent(
    $filter: DocumentFilter
    $orderBy: [DocumentOrderBy]
    $sort: [DocumentSort!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
  ) {
    tableData: documentsList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
      skip: $skip
    ) {
      items {
        id
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
          roles {
            items {
              id
              name
            }
          }
          _description
        }
        file {
          id
          fileId
          filename
          downloadUrl
          shareUrl
          meta
        }
        private
        description
        _description
      }
      count
    }
  }
`;
