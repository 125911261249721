import { PRIMARY_COLOR } from 'src/constants/colors';

export const AREAS = {
  DOCUMENTS: 'DOCUMENTS',
  LOAN_APPLICATIONS: 'LOAN_APPLICATIONS',
  PORTFOLIO_COMPANIES: 'PORTFOLIO_COMPANIES',
  TEAM: 'TEAM',
  LOANS: 'LOANS',
  USERS: 'USERS',
};

export const LOGIN_CONNECTION = 'Username-Password-Authentication';

export const ACTIONS_TITLE = 'Actions';

export enum Delay {
  Default = 500,
}

export enum AuthRoutes {
  Auth = '/auth',
  Login = '/auth/login',
  SignUp = '/auth/signup',
  ChangePassword = '/auth/change-password',
  CallBack = '/auth/callback',
  Confirmation = '/auth/confirmation',
  InvitationDeprecated = '/auth/invitation-deprecated',
}

export enum Locale {
  EN = 'en-US',
}

export enum Format {
  Percent = 'Percent',
  Money = 'Money',
  Month = 'Month',
  Ratio = 'Ratio',
  Count = 'Count',
}

export enum InvitationRole {
  AccountOwner = 'Account Owner',
  Other = 'Other',
}

export const TABLE_DELIMITER = 'DELIMITER';

// MONITORING STATUS

export enum MonitoringStatus {
  OK = 'OK',
  IncompleteData = 'Incomplete data',
  WatchList = 'Watch list',
  Default = 'Default',
  Workout = 'Workout',
}

export const MONITORING_STATUS_COLORS = {
  [MonitoringStatus.OK]: '#009e8a',
  [MonitoringStatus.Default]: '#EB4336',
  [MonitoringStatus.Workout]: '#0A6DC4',
  [MonitoringStatus.WatchList]: '#F57C00',
  [MonitoringStatus.IncompleteData]: '#236B90',
};

// LOAN APPLICATION

export enum LoanApplicationStage {
  AccountCreated = 'Account Created',
  SaaSScored = 'SaaSScored',
  AppCompleted = 'App. Completed',
  StructurePresented = 'Structure Presented',
  TermSheet = 'Term Sheet',
  Underwriting = 'Underwriting',
  Closing = 'Closing',
  Funded = 'Funded',
  Lost = 'Lost',
  Rejected = 'Rejected',
}

export const LOAN_APPLICATION_STAGE_COLORS: Record<LoanApplicationStage, string> = {
  [LoanApplicationStage.AppCompleted]: '#F57C00',
  [LoanApplicationStage.Closing]: '#9D7DD5',
  [LoanApplicationStage.Funded]: '#4CAF50',
  [LoanApplicationStage.SaaSScored]: '#236B90',
  [LoanApplicationStage.Lost]: '#EB4336',
  [LoanApplicationStage.Rejected]: '#000000',
  [LoanApplicationStage.AccountCreated]: PRIMARY_COLOR,
  [LoanApplicationStage.StructurePresented]: '#7BC5EF',
  [LoanApplicationStage.TermSheet]: '#9ABBA0',
  [LoanApplicationStage.Underwriting]: '#0A6DC4',
};
