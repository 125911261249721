import * as R from 'ramda';
import { DateTime } from 'luxon';
import { MetricValueCreateManyInput } from 'src/graphql';
import { MetricPeriod, Source } from 'src/types';
import { Format, REG_EXP } from 'src/constants';
import { convertIfPercentages, DateFormatPatterns } from 'src/utils';

type MetricData = { [key in string]: string };
type DateData = { day: string; month: string; year: string };

export type CSVMetricRow = MetricData & DateData;

const convertCSVFormatToNumber = (strValue: string): number => {
  const format = /%/g.test(strValue) ? Format.Percent : null;
  const isNegativeStrValue = REG_EXP.BRACKETS.test(strValue);

  const strWithoutSpecialSymbols = strValue.replace(REG_EXP.DOLLAR_OR_PERCENT_OR_COMMA, '');
  const number = Number(strWithoutSpecialSymbols.replace(REG_EXP.BRACKETS, ''));
  const numberValue = convertIfPercentages(number, format) as number;

  return isNegativeStrValue ? -numberValue : numberValue;
};

export const generateMetricUpsertFromCSV = (
  companyId: string,
  jsonData: Array<CSVMetricRow>,
): Array<MetricValueCreateManyInput | null> => {
  const metricValueManyInputs = jsonData.map((csvMetricRow: CSVMetricRow) => {
    const dateKeys = Object.keys(csvMetricRow).filter(
      (keyCode: string) => keyCode !== 'metricsCode' && keyCode !== 'metricsName',
    );

    const metricsCode = R.prop('metricsCode', csvMetricRow);

    return dateKeys.map((dateKey: string): MetricValueCreateManyInput | null => {
      const strValue = csvMetricRow[dateKey];
      const hasValue = !R.isNil(strValue) && strValue !== '-';
      const shouldSkipValue = strValue === '';

      if (shouldSkipValue) {
        return null;
      }

      const value = hasValue ? convertCSVFormatToNumber(strValue) : null;

      const [month, year] = dateKey.split('/') as string[];

      const date = DateTime.fromObject({
        year: parseInt(year),
        month: parseInt(month),
      })
        .endOf(MetricPeriod.Month)
        .toFormat(DateFormatPatterns.shortDateWithDash);

      return {
        company: {
          connect: {
            id: companyId,
          },
        },
        metric: {
          connect: {
            code: metricsCode,
          },
        },
        period: MetricPeriod.Month,
        value,
        date,
        source: Source.Manual,
      };
    });
  });

  const flatted = R.flatten(metricValueManyInputs);
  return R.filter(row => !R.isNil(row), flatted);
};
