import React from 'react';
import { Box } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { actionsContext } from './ActionsProvider';

import { Chip } from 'src/components';
import { LOAN_APPLICATION_STAGE_COLORS, LoanApplicationStage } from 'src/constants';

interface StageRendererProps {
  id?: string | null;
  stage?: string | null;
}

export const StageRenderer: React.FC<StageRendererProps> = ({ id, stage }) => {
  const { onStatusesClick, canChangeStage } = React.useContext(actionsContext);
  if (!stage) return null;

  return (
    <Box
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      onClick={onStatusesClick(id as string)}
      style={{ cursor: 'pointer' }}
    >
      <Chip value={stage} color={LOAN_APPLICATION_STAGE_COLORS[stage as LoanApplicationStage]} />
      {canChangeStage && <icons.KeyboardArrowDown fontSize="small" />}
    </Box>
  );
};
