import React, { ChangeEvent, useContext } from 'react';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import * as R from 'ramda';

import { metricSettingsContext } from './MetricSettingsProvider';
import { isEmptyCell } from '../helpers';
import { EmptyCell } from './EmptyCell';
import { dirtyContext } from './DirtyProvider';

interface BoundsDirectionRenderProps {
  code: string;
  isEnterprise: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > *:first-child': {
      padding: 10,
      paddingRight: 32,
    },
  },
}));

export const BoundsDirectionRenderer: React.FC<BoundsDirectionRenderProps> = ({
  code,
  isEnterprise,
}) => {
  const classes = useStyles();
  const { settings, update } = useContext(metricSettingsContext);
  const { setIsDirty } = useContext(dirtyContext);

  const value = R.pathOr(
    '',
    [code, 'tierBands', isEnterprise ? 'enterprise' : 'smb', 'direction'],
    settings,
  );

  const onChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    update(code)(['tierBands', isEnterprise ? 'enterprise' : 'smb', 'direction'])(
      e.target.value as string,
    );
    setIsDirty(true);
  };

  if (isEmptyCell(isEnterprise, code)) return <EmptyCell />;

  return (
    <Select onChange={onChange} className={classes.root} variant="outlined" value={value}>
      <MenuItem value="ASC">Ascending</MenuItem>
      <MenuItem value="DESC">Descending</MenuItem>
    </Select>
  );
};
