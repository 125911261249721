import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

import { getFormattedMetricValue, isNegative } from 'src/utils';
import { Company } from 'src/graphql';
import { Format } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    cursor: 'pointer',
    position: 'relative',
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  activeCell: {
    background: theme.palette.grey[100],
  },
  lessThanZero: {
    color: '#eb4336',
  },
}));

interface MonitoringValueCellProps {
  date: string;
  valueName: string;
  monitoringValues: any;
  company: Company;
}

export const MonitoringValueCell: React.FC<MonitoringValueCellProps> = props => {
  const { monitoringValues, valueName } = props;
  const classes = useStyles();

  const value = React.useMemo(() => R.pathOr(null, [valueName], monitoringValues), [
    monitoringValues,
    valueName,
  ]);

  return (
    <TableCell
      className={clsx({
        [classes.cell]: true,
        [classes.lessThanZero]: isNegative(value),
      })}
    >
      {!R.isNil(value) ? getFormattedMetricValue(value, Format.Money) : 'N/A'}
    </TableCell>
  );
};
