import React from 'react';
import { CircularProgress, makeStyles, Switch } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'center',
  },

  switch: {
    '& .MuiButtonBase-root:not(.MuiSwitch-colorSecondary.Mui-checked) .MuiSwitch-thumb': {
      border: '1px solid gray',
    },
  },
}));

interface ToggleSwitchProps {
  checked: boolean;
  loading?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  loading = false,
  disabled = false,
  checked,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Switch
          className={classes.switch}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
      )}
    </div>
  );
};
