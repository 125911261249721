import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(120),
    padding: theme.spacing(2),
  },
  info: {
    marginBottom: theme.spacing(2),
  },
}));

export const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box className={classes.root}>
        <Box className={classes.info}>
          <Typography variant="h4" gutterBottom>
            Something went wrong
          </Typography>
          <Typography variant="body1">We already know about problem.</Typography>
        </Box>
        <Button variant="outlined" color="secondary" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </Box>
    </Box>
  );
};
