import React, { useMemo } from 'react';
import * as R from 'ramda';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { Box, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import { FORM_ERROR, useAuth } from 'src/app-builder';

import { useRouterQuery } from 'src/hooks';
import { authErrorResolver } from 'src/utils/errors';
import { SelfSignUpForm } from './SelfSignUpForm';
import { InvitationSignUpForm } from './InvitationSignUpForm';
import {
  EventResolverMutationMutationVariables,
  EventType,
  EVENT_RESOLVER_QUERY,
  Maybe,
} from 'src/graphql';
import { GetInvitationInfoResponse } from 'src/types';

import bfc from 'src/assets/logos/bfc.png';
import background from 'src/assets/bg.jpg';
import { AuthRoutes, OnboardingStep } from 'src/constants';
import { OnboardingStepper } from 'src/components';
import { prepareSignUpData } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    background: theme.palette.secondary.dark,
  },
  wrapper: {
    background: theme.palette.common.white,
    margin: 0,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  logo: {
    width: theme.spacing(22),
  },
}));

enum SignUpKind {
  InvitationSignUp = 'InvitationSignUp',
  SelfSignUp = 'SelfSignUp',
}

export const AuthSignUpPage: React.FC = () => {
  const { authClient, isAuthorized } = useAuth();

  const { id: inviteId } = useRouterQuery();

  const classes = useStyles();

  const { data, loading: invitationInfoLoading } = useQuery<
    Maybe<{ eventResolverQuery: GetInvitationInfoResponse }>,
    EventResolverMutationMutationVariables
  >(EVENT_RESOLVER_QUERY, {
    variables: {
      event: {
        type: EventType.GetInvitationInfo,
        payload: {
          id: inviteId,
        },
      },
    },
    skip: !inviteId,
  });

  // if we have invitation id (query.id) but we get the invitationExist field equals to false
  // it means eather invitation with this id isn't exist or the user
  // for whom was created this invitation already signed up in the app
  const invitationDeprecated = useMemo(
    () => inviteId && !data?.eventResolverQuery?.response?.invitationExist,
    [data, inviteId],
  );

  const signup = React.useCallback(
    async data =>
      await new Promise<void>(async (resolve, reject) => {
        const preparedData = prepareSignUpData(data);
        await authClient.auth0.signup(preparedData, (err: any) => {
          if (err) {
            reject(err);

            return;
          } else {
            authClient.auth0.login(
              {
                connection: preparedData.connection,
                email: preparedData.email,
                password: preparedData.password,
              },
              (err: any) => {
                if (err) {
                  reject(err);

                  return;
                } else {
                  resolve();

                  return;
                }
              },
            );
          }
        });
      }),
    [authClient],
  );

  const onSubmit = React.useCallback(
    async data => {
      try {
        await signup(data);
      } catch (e) {
        if (e.code === 'user_exists') {
          return {
            email: authErrorResolver(e),
          };
        }
        return { [FORM_ERROR as string]: authErrorResolver(e) };
      }
    },
    [signup],
  );

  const invitationRoles = R.pathOr([], ['eventResolverQuery', 'response', 'invitationRoles'], data);
  const hasRoles = !R.isEmpty(invitationRoles) && !invitationInfoLoading;

  const signUpKind = inviteId && hasRoles ? SignUpKind.InvitationSignUp : SignUpKind.SelfSignUp;

  const renderSignUpForm = (signUpKind: SignUpKind) => {
    switch (signUpKind) {
      case SignUpKind.SelfSignUp:
        return <SelfSignUpForm onSubmit={onSubmit} />;

      case SignUpKind.InvitationSignUp:
        return <InvitationSignUpForm onSubmit={onSubmit} />;

      default:
        return null;
    }
  };

  // if the user already logged in we redirect them to the root route
  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  if (invitationInfoLoading) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (invitationDeprecated) {
    return <Redirect to={AuthRoutes.InvitationDeprecated} />;
  }

  return (
    <Grid className={classes.root} container>
      <Grid
        className={classes.wrapper}
        xs={6}
        item
        container
        alignItems="center"
        justify="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <img className={classes.logo} src={bfc} alt="" />
        </Grid>
        <Grid item>
          <Typography variant="h3">Get Started</Typography>
        </Grid>
        <Grid item>
          <Typography>
            Already have an account?{' '}
            <Link color="secondary" component={RouterLink} to={AuthRoutes.Login}>
              Login
            </Link>
          </Typography>
        </Grid>

        {signUpKind === SignUpKind.SelfSignUp && (
          <Grid item>
            <OnboardingStepper step={OnboardingStep.First} />
          </Grid>
        )}

        {renderSignUpForm(signUpKind)}

        <Grid item>
          <Typography>
            By continuing, you agree to the Bigfoot Capital{' '}
            <Link
              color="secondary"
              target="_blank"
              href="https://www.iubenda.com/terms-and-conditions/14712955"
            >
              Terms and Conditions
            </Link>{' '}
            and{' '}
            <Link
              color="secondary"
              target="_blank"
              href="https://www.iubenda.com/privacy-policy/14712955"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={6}
        item
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
      />
    </Grid>
  );
};
