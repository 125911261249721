import _ from 'lodash';

import { MetricValue, PortfolioOverviewCompany } from 'src/graphql';
import { ExportDataType, MetricCode, Nullable } from 'src/types';
import {
  Format,
  PREPAYMENT_VALUES,
  PortfolioOverviewColumnKey,
  PortfolioOverviewMetricKey,
  PortfolioOverviewTableColumn,
} from 'src/constants';
import {
  roundNumber,
  convertToPercentage,
  getAdjustedOrOriginalMetricValue,
  getMetricValueByMetricCode,
  PORTFOLIO_OVERVIEW_METRIC_CODES,
  formatPercents,
} from 'src/utils';

export function getPortfolioOverviewExportColumns(): Array<ExportDataType> {
  const columnKeys = _.keys(PortfolioOverviewTableColumn);
  const exportColumns = columnKeys.map(colKey =>
    _.get(PortfolioOverviewTableColumn, [colKey, 'exportData']),
  );

  return exportColumns;
}

type PortfolioOverviewMetricValueMap = {
  [key in keyof typeof PORTFOLIO_OVERVIEW_METRIC_CODES]: Nullable<string | number>;
};

export function getMetricValues(metrics: Array<MetricValue>): PortfolioOverviewMetricValueMap {
  return _.mapValues(PORTFOLIO_OVERVIEW_METRIC_CODES, (metricCode: MetricCode) => {
    const metricValue = getMetricValueByMetricCode(metrics, metricCode);
    const format = metricValue?.metric?.format as Format | undefined;
    const value = getAdjustedOrOriginalMetricValue(metricValue);

    if (format === Format.Percent) {
      return convertToPercentage(value);
    }

    return roundNumber(value);
  });
}

export function getPortfolioOverviewExportRows(
  portfolioOverviewCompanies: Array<PortfolioOverviewCompany>,
) {
  return portfolioOverviewCompanies.map(portfolioOverviewCompany => {
    const { portfolioOverviewMetrics, companyName, metrics } = portfolioOverviewCompany;

    const closingFeeValue = portfolioOverviewMetrics[PortfolioOverviewMetricKey.ClosingFeeValue];
    const closingFee = portfolioOverviewMetrics[PortfolioOverviewMetricKey.ClosingFee];

    const formattedClosingFeeValue = formatPercents(closingFeeValue);
    const formattedClosingFee = closingFeeValue
      ? `${formattedClosingFeeValue} (${closingFee})`
      : null;

    const prepaymentMonths = portfolioOverviewMetrics[PortfolioOverviewMetricKey.PrepaymentMonths];
    const prepayment = portfolioOverviewMetrics[PortfolioOverviewMetricKey.Prepayment];

    const formattedPrepayment =
      prepayment === PREPAYMENT_VALUES.reducedCashOnCash ? prepayment : prepaymentMonths;

    return {
      [PortfolioOverviewColumnKey.CompanyName]: companyName,
      [PortfolioOverviewColumnKey.ClosingDate]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.ClosingDate],
      [PortfolioOverviewColumnKey.FundingDate]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.FundingDate],
      [PortfolioOverviewColumnKey.Structure]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.Structure],
      [PortfolioOverviewColumnKey.Committed]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.Committed],
      [PortfolioOverviewColumnKey.Deployed]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.Deployed],
      [PortfolioOverviewColumnKey.Term]: portfolioOverviewMetrics[PortfolioOverviewMetricKey.Term],
      [PortfolioOverviewColumnKey.IOPeriod]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.IOPeriod],
      [PortfolioOverviewColumnKey.Prepayment]: formattedPrepayment,
      [PortfolioOverviewColumnKey.AdvanceRate]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.AdvancedRate],
      [PortfolioOverviewColumnKey.InterestRate]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.InterestRate],
      [PortfolioOverviewColumnKey.ReturnCap]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.ReturnCap],
      [PortfolioOverviewColumnKey.ClosingFee]: formattedClosingFee,
      [PortfolioOverviewColumnKey.SuccessFee]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.SuccessFee],
      [PortfolioOverviewColumnKey.ExitFee]:
        portfolioOverviewMetrics[PortfolioOverviewMetricKey.ExitFee],
      [PortfolioOverviewColumnKey.IRR]: portfolioOverviewMetrics[PortfolioOverviewMetricKey.IRR],
      [PortfolioOverviewColumnKey.MOIC]: portfolioOverviewMetrics[PortfolioOverviewMetricKey.MOIC],
      [PortfolioOverviewColumnKey.Tier]: getMetricValueByMetricCode(metrics, MetricCode.SaaSScore)
        ?.tier,
      ...getMetricValues(metrics),
    };
  });
}
