import React from 'react';
import { useQuery } from 'react-apollo';

import {
  Layout,
  Table,
  LoanMultipliers,
  LoanMultipliersProvider,
  MetricSettingsProvider,
  SubmitBlock,
  ScaleByTier,
  ScaleByTierProvider,
  DirtyProvider,
} from './components';

import { METRICS_LIST_QUERY, MetricsListQuery } from 'src/graphql';
import {
  extractMetrics,
  getLoanMultipliersSettings,
  getScaleByTierSettings,
  moveSaaSScoreToTheEndOfAList,
} from './helpers';
import { MASTER_DATA_METRICS } from 'src/constants';

export const MasterDataPage: React.FC = () => {
  const { data, loading } = useQuery<MetricsListQuery>(METRICS_LIST_QUERY, {
    variables: {
      filter: {
        code: {
          in: MASTER_DATA_METRICS,
        },
      },
    },
  });

  const metrics = moveSaaSScoreToTheEndOfAList(extractMetrics(data));
  const scaleByTier = getScaleByTierSettings(metrics);
  const loanMultipliers = getLoanMultipliersSettings(extractMetrics(data));

  return (
    <DirtyProvider>
      <MetricSettingsProvider initialData={metrics}>
        <ScaleByTierProvider initialData={scaleByTier}>
          <LoanMultipliersProvider initialData={loanMultipliers}>
            <Layout
              loading={loading}
              scaleByTier={<ScaleByTier />}
              table={<Table data={metrics} />}
              submit={<SubmitBlock />}
              loanMultipliers={<LoanMultipliers />}
            />
          </LoanMultipliersProvider>
        </ScaleByTierProvider>
      </MetricSettingsProvider>
    </DirtyProvider>
  );
};
