import React from 'react';
import * as R from 'ramda';
import { useMutation } from 'react-apollo';
import { useModal } from 'src/app-builder';

import { useClientRoles } from 'src/app-builder/providers';
import { useNotification } from 'src/hooks';
import { FormInboxDialog } from './FormInboxDialog';
import { DIALOGS } from 'src/dialogs';
import { EVENT_RESOLVER_MUTATION, EventType } from 'src/graphql';
import { AppRole } from 'src/types';
import { getCurrentUserRole, isFunction, createPayload, commonErrorResolver, t } from 'src/utils';

interface InboxCustomerReplyDialogProps {}

export const InboxCustomerReplyDialog: React.FC<InboxCustomerReplyDialogProps> = () => {
  const notification = useNotification();
  const { currentRole } = useClientRoles();
  const { isAppAdministrator, isAppAnalyst } = getCurrentUserRole(currentRole as AppRole);

  const { open, closeModal, args, openModal } = useModal(DIALOGS.INBOX_CUSTOMER_REPLY_DIALOG);

  const [createInboxMessage] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['InboxMessagesList'],
    awaitRefetchQueries: true,
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      const attachments = form.getFieldState('attachments').value;

      const payload = createPayload({
        isByAdministrator: isAppAdministrator || isAppAnalyst,
        subject: data.subject,
        body: data.body,
        attachments,
        companyId: args.companyId,
        repliedOnId: args.repliedOnId,
      });

      try {
        const message = await createInboxMessage({
          variables: {
            event: {
              type: EventType.InboxMessageCreate,
              payload,
            },
          },
        });

        notification.success(t('inbox_message_sent_success'));

        closeModal();

        await form.reset;

        if (isFunction(args?.onSuccess)) {
          args.onSuccess(message);
        }
      } catch (error) {
        console.error(error);
        notification.error(commonErrorResolver(error));
      }
    },
    [closeModal, createInboxMessage, notification, args, isAppAdministrator, isAppAnalyst],
  );

  if (!open) {
    return null;
  }

  if (R.isEmpty(args?.recipientsList)) {
    notification.info('You cannot send a message because the list of recipients is empty');

    return null;
  }

  return (
    <FormInboxDialog
      title="Reply Message"
      onSubmit={onSubmit}
      openModal={openModal}
      closeModal={closeModal}
      open={open}
    />
  );
};
