import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { SelectedColumns } from 'src/app-builder';
import {
  TableHeaderCellWithSort,
  TableHeaderCellWithoutSort,
  TableHeaderCell,
} from '../TableHeaderCell';

export const useTableHeaderStyles = makeStyles((theme: Theme) => ({
  stickyLeft: {
    position: 'sticky',
    left: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      height: 1,
      background: theme.customPalette.border.table,
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
    },
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  stickyRight: {
    width: 80,
    position: 'sticky',
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      height: 1,
      background: theme.customPalette.border.table,
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
    },
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
}));

type CellPropsWithSort = Omit<TableHeaderCellWithSort, 'areaField'>;
type CellPropsWithoutSort = Omit<TableHeaderCellWithoutSort, 'areaField'>;
type CellProps = CellPropsWithSort | CellPropsWithoutSort;

type SortProps = Omit<TableHeaderCellWithSort, 'areaField' | 'withSort'>;

type ActionsProps = {
  title: string;
};

type TableHeaderProps = {
  selectedColumns: SelectedColumns;
  sortProps?: SortProps;
  actionsProps?: ActionsProps;
  checkboxProps?: CheckboxProps & {
    hideCheckbox?: boolean;
  };
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  sortProps,
  actionsProps,
  selectedColumns,
  checkboxProps,
}) => {
  const classes = useTableHeaderStyles();

  const cellProps: CellProps = sortProps ? { ...sortProps, withSort: true } : { withSort: false };

  return (
    <TableHead>
      <TableRow>
        {Boolean(checkboxProps) && (
          <TableCell padding="checkbox" className={classes.stickyLeft}>
            {Boolean(!checkboxProps?.hideCheckbox) && (
              <Checkbox color="secondary" {...checkboxProps} />
            )}
          </TableCell>
        )}

        {selectedColumns.map(({ areaField, selected }) => {
          if (!selected) {
            return null;
          }

          return <TableHeaderCell key={areaField.id} areaField={areaField} {...cellProps} />;
        })}

        {Boolean(actionsProps) && (
          <TableCell className={classes.stickyRight}>{actionsProps?.title}</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
