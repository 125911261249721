import React from 'react';

import { PaginationProvider, SearchProvider } from 'src/providers';
import { Main } from './components';

export const CovenantTrackingTablePage: React.FC = () => {
  return (
    <SearchProvider>
      <PaginationProvider>
        <Main />
      </PaginationProvider>
    </SearchProvider>
  );
};
