import React from 'react';
import { Route, Redirect, useLocation, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { isFunction } from 'src/utils';
import { AuthRoutes } from 'src/constants';

type RenderContentProps = RouteProps & {
  props: RouteComponentProps;
};

export const renderContent = ({
  component: Component,
  render,
  children,
  props,
}: RenderContentProps): React.ReactNode => {
  if (children) {
    return children;
  }

  if (Component) {
    return <Component {...props} />;
  }

  if (render && isFunction(render)) {
    return render(props);
  }

  return null;
};

export interface ProtectedRouteProps extends RouteProps {}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  children,
  render,
  ...rest
}) => {
  const auth = useAuth();
  const location = useLocation();

  const { isEmailVerified } = auth.authClient.getState();

  if (auth.isAuthorized) {
    if (!isEmailVerified) {
      return <Redirect to={{ pathname: '/auth/confirmation', state: { from: location } }} />;
    } else {
      return (
        <Route
          {...rest}
          render={(props): React.ReactNode => renderContent({ component, render, children, props })}
        />
      );
    }
  }

  return <Redirect to={{ pathname: AuthRoutes.Auth, state: { from: location } }} />;
};
