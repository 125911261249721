import React, { useCallback, useContext } from 'react';
import { Popover, MenuItem, MenuList, PopoverProps, ListItemIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ModalsContext } from 'src/app-builder';
import * as icons from '@material-ui/icons';

import { DIALOGS } from 'src/dialogs';

interface EntitiesTableActionsPopoverProps extends PopoverProps {
  createActions?: (closeActions: () => void, id: string) => React.ReactNode;
  areaId?: string;
  areaCode?: string;
  activeId: string | null;
  onClose: () => void;
}

export const CompaniesActionsPopover: React.FC<EntitiesTableActionsPopoverProps> = ({
  anchorEl,
  open,
  onClose,
  activeId,
}) => {
  const { openModal } = useContext(ModalsContext);
  const to = `/portfolio-companies/${activeId}/dashboard`;

  const openDeleteRowDialog = useCallback(
    (args?: any) => {
      onClose();

      openModal(
        DIALOGS.PORTFOLIO_COMPANY_DELETE_DIALOG,
        args || {
          id: activeId,
        },
      );
    },
    [onClose, openModal, activeId],
  );

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuList>
        <MenuItem key="view" component={Link} to={to}>
          <ListItemIcon>
            <icons.Edit />
          </ListItemIcon>
          View
        </MenuItem>
        <MenuItem key="delete" onClick={() => openDeleteRowDialog()}>
          <ListItemIcon>
            <icons.Delete />
          </ListItemIcon>
          Delete
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
