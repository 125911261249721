import React from 'react';
import * as R from 'ramda';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useLocalStorage } from 'src/hooks';
import { useAllowed, useCurrentUser, useModal } from 'src/app-builder';
import { SubmitButton } from 'src/components';
import { User } from 'src/graphql';
import { DIALOGS } from 'src/dialogs/constants';
import { Permission } from 'src/constants';
import { LocalStorageKeys, OnboardedUsers } from 'src/types/localStorage';
import { addOnboardedUser, hasOnboardedUser } from 'src/utils/localStorage/onboardedUsers';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 12,
    color: theme.palette.grey[500],
  },
}));

export const OnboardingClientUserDialog: React.FC = () => {
  const classes = useStyles();
  const { open, openModal, closeModal } = useModal(DIALOGS.NOVICE_USER_DIALOG);
  const { user } = useCurrentUser();
  const userId = R.prop('id', user as User) as string;
  const [onboardedUsers, setOnboardedUsers] = useLocalStorage<OnboardedUsers>(
    LocalStorageKeys.OnboardedUsers,
    [],
  );

  const isAllowed = useAllowed();

  const onClose = React.useCallback(() => {
    closeModal();
    const newOnboardedUsers = addOnboardedUser(userId, onboardedUsers);
    setOnboardedUsers(newOnboardedUsers);
  }, [closeModal, setOnboardedUsers, onboardedUsers, userId]);

  React.useEffect(() => {
    if (
      hasOnboardedUser(userId, onboardedUsers) &&
      isAllowed(Permission.ClientPortalDashboardArea)
    ) {
      openModal();
    }
  }, [openModal, onboardedUsers, isAllowed, userId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle disableTypography>
        <Typography variant="h6">Next Steps</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography component="p">
            Hi! Welcome to Bigfoot Capital&apos;s Perform platform.
          </Typography>
          <Typography component="p">
            We need you to do one more thing before we can present you with the best financial
            offer.
          </Typography>
          <Typography component="p">Please take a look below at next steps:</Typography>
          <br />
          <Typography component="p">
            1. Review <b>Pre Term Sheet Items</b> listed in the <b> Funding Progress </b> section.
          </Typography>
          <Typography component="p">
            2. Upload all requested files by clicking the <b> Upload </b> button.
          </Typography>
          <Typography component="p">
            3. Pat yourself on the back for getting <b>things</b> done.
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <SubmitButton color="secondary" variant="contained" type="submit" onClick={onClose}>
          OK
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
