import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import { Sidebar } from './Sidebar';
import { Header } from './Header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    gridTemplateAreas: '"sidebar header" "sidebar content"',
    gridTemplateRows: '64px 1fr',
    gridTemplateColumns: 'auto 1fr',
    color: theme.palette.text.primary,
  },
  content: {
    height: 'calc(100vh - 64px)',
    gridArea: 'content',
    overflow: 'auto',
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px 0`,

    '&::after': {
      display: 'block',
      content: '""',
      height: theme.spacing(4),
      width: '100%',
    },
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Header />
      <Sidebar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};
