import React, { createContext, useState } from 'react';
import { usePopover } from 'src/app-builder';

import { MonitoringStatusesPopover, CompaniesActionsPopover } from 'src/shared/popovers';

interface ActionsContextValue {
  openMonitoringPopover: (id: string, statuses: string[]) => (e: any) => void;
  openActionsPopover: (id: string) => (e: any) => void;
}

export const actionsContext = createContext<ActionsContextValue>({} as ActionsContextValue);

interface ActionsProviderProps {
  children: React.ReactNode;
}

export const ActionsProvider: React.FC<ActionsProviderProps> = ({ children }) => {
  const [companyId, setCompanyId] = useState<string>('');
  const [statuses, setStatuses] = useState<string[]>([]);
  const actionsPopover = usePopover();
  const monitoringStatusPopover = usePopover();

  const openMonitoringPopover = (id: string, statuses: string[]) => (e: any) => {
    setCompanyId(id);
    setStatuses(statuses);
    monitoringStatusPopover.openPopover(e);
  };

  const openActionsPopover = (id: string) => (e: any) => {
    setCompanyId(id);
    actionsPopover.openPopover(e);
  };

  return (
    <actionsContext.Provider
      value={{
        openMonitoringPopover,
        openActionsPopover,
      }}
    >
      {children}
      <MonitoringStatusesPopover
        anchorEl={monitoringStatusPopover.el}
        onClose={monitoringStatusPopover.closePopover}
        portfolioCompanyId={companyId}
        statuses={statuses}
        open={monitoringStatusPopover.isOpen}
      />
      <CompaniesActionsPopover
        anchorEl={actionsPopover.el}
        activeId={companyId}
        onClose={actionsPopover.closePopover}
        open={actionsPopover.isOpen}
      />
    </actionsContext.Provider>
  );
};
