import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { actionsContext } from 'src/providers/saaSScore/ActionsProvider';

import { getFormattedMetricValue, isFunction, isNegative } from 'src/utils';
import { MetricValue } from 'src/graphql';
import { Format } from 'src/constants';
import { MetricCode, EmptyMetricValue } from 'src/types';
import { PRIMARY_COLOR } from 'src/constants/colors';

interface ValueRendererProps {
  value: MetricValue | EmptyMetricValue;
  date?: string;
  metricCode?: MetricCode;
  metricName?: string;
  metricIndex?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 5,
  },
  lessThanZero: {
    color: '#eb4336',
  },
  pointer: {
    cursor: 'pointer',
  },
  activeCell: {
    background: theme.palette.grey[100],
  },
  adjustedValueMarker: {
    position: 'absolute',
    right: 0,
    top: 0,
    border: '5px solid transparent',
    borderTop: '5px solid #295F7B',
    borderRight: '5px solid #295F7B',
  },
  dot: ({ tier }: { tier?: number | null }): CreateCSSProperties<{ tier?: number | null }> => {
    let background = 'transparent';

    if (!tier) {
      return {};
    }

    if (tier < 3) {
      background = PRIMARY_COLOR;
    } else if (tier === 5) {
      background = '#EB4336';
    } else if (tier > 3) {
      background = '#FFCA27';
    }

    return {
      flexShrink: 0,
      background,
      width: 4,
      height: 4,
      borderRadius: 4,
    };
  },
}));

export const MetricCell: React.FC<ValueRendererProps> = ({ value }) => {
  const classes = useStyles({ tier: value?.tier });

  const metric = R.pathOr(null, ['metric'], value);

  const format = R.pathOr(Format.Count, ['format'], metric);
  const amount = value?.adjustedValue ?? value?.value ?? null;

  const { openActions, actions } = React.useContext(actionsContext);

  const isActiveCell = !R.isNil(actions?.activeCell) && R.equals(actions.activeCell?.id, value?.id);
  const hasTier = Number.isFinite(value?.tier);
  const hasAdjustedValue = !R.isNil(value?.adjustedValue) || !R.isNil(value?.comment);
  const formattedValue = !R.isNil(amount) ? getFormattedMetricValue(amount, format) : '–';

  const onCellClick = isFunction(openActions)
    ? openActions(value as MetricValue, value.date, metric)
    : undefined;

  return (
    <div
      onClick={onCellClick}
      className={clsx({
        [classes.root]: true,
        [classes.pointer]: false,
        [classes.activeCell]: isActiveCell,
        [classes.lessThanZero]: isNegative(amount),
      })}
    >
      {hasAdjustedValue && <Box className={classes.adjustedValueMarker} />}

      <div className={classes.value}>{formattedValue}</div>

      {hasTier && <span className={classes.dot} />}
    </div>
  );
};
