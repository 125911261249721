import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Metric } from 'src/graphql';
import { MetricCode } from 'src/types';

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '8px 16px',
    height: '37px',
  },
}));

interface MetricCellNameProps {
  metric: Metric;
  renderName?: (name: string, code: MetricCode) => string;
}

export const MetricCellName: React.FC<MetricCellNameProps> = ({
  metric,
  renderName = (name: string, metricCode: MetricCode) => name,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {metric.name && renderName(metric.name, metric.code as MetricCode)}
    </div>
  );
};

MetricCellName.displayName = 'MetricCellName';
