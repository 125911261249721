import React from 'react';
import clsx from 'clsx';

import { Grid, makeStyles, Theme } from '@material-ui/core';
import { EditCustomMetricPopover } from './EditCustomMetricPopover';
import {
  formatCovenantValue,
  getCovenantValueByType,
  getDataForCustomCovenant,
  isMatchingComplianceStatus,
  isNegative,
} from 'src/utils';
import { CovenantItemType } from 'src/hooks/useCovenantTracking';
import {
  COMPLIANCE_NO_COLOR,
  COMPLIANCE_YES_COLOR,
  Format,
  NUMBER_NEGATIVE_COLOR,
} from 'src/constants';
import { useCustomCovenants } from 'src/hooks/useCustomCovenants';

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    padding: '16px 24px',
    height: '100%',
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    userSelect: 'none',
    outline: 'none',
    outlineOffset: '-1px',
    boxSizing: 'border-box',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  isEditable: {
    cursor: 'pointer',
  },
  inCompliance: {
    backgroundColor: COMPLIANCE_YES_COLOR,
  },
  notInCompliance: {
    backgroundColor: COMPLIANCE_NO_COLOR,
  },
  negativeValue: {
    color: NUMBER_NEGATIVE_COLOR,
  },
}));

interface MetricCellProps {
  covenantItem: CovenantItemType;
  date: string;
}

export const CovenantMetricCell: React.FC<MetricCellProps> = ({ covenantItem, date }) => {
  const classes = useStyles();

  const [editValueAnchorEl, setEditValueAnchorEl] = React.useState<Element | null | undefined>(
    null,
  );

  const { upsertCustomCovenant, deleteCustomCovenant, loading } = useCustomCovenants();

  const onEdit = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!covenantItem?.isEditable) {
      return;
    }

    setEditValueAnchorEl(event.currentTarget);
  };

  const onCloseEdit = () => {
    setEditValueAnchorEl(null);
  };

  const onUpsertCustomCovenantMetricValue = async (value: string) => {
    if (!covenantItem?.isEditable && !covenantItem?.covenantId) {
      return;
    }

    try {
      const covenantData = getDataForCustomCovenant(covenantItem.name, value);

      await upsertCustomCovenant(covenantItem?.covenantId as string, date, covenantData);
      onCloseEdit();
    } catch (error) {
      console.error({ error });
    }
  };

  const onDeleteCustomCovenantMetricValue = async () => {
    try {
      await deleteCustomCovenant(covenantItem?.covenantId as string, date);
      onCloseEdit();
    } catch (error) {
      console.error({ error });
    }
  };

  const covenantValueByType = getCovenantValueByType(covenantItem?.name, covenantItem, date);

  const formattedCovenantValue = formatCovenantValue(
    covenantItem?.covenantTableType,
    covenantItem?.name,
    covenantValueByType,
    covenantItem?.format as Format,
  );
  const negative = isNegative(Number(covenantValueByType));

  const renderCell = () => {
    return (
      <div
        className={clsx({
          [classes.metric]: true,
          [classes.inCompliance]: isMatchingComplianceStatus(
            covenantItem?.name,
            true,
            covenantValueByType,
          ),
          [classes.notInCompliance]: isMatchingComplianceStatus(
            covenantItem?.name,
            false,
            covenantValueByType,
          ),
          [classes.negativeValue]: negative,
          [classes.isEditable]: covenantItem?.isEditable,
        })}
      >
        {formattedCovenantValue}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Grid onClick={onEdit}>{renderCell()}</Grid>

      <EditCustomMetricPopover
        open={Boolean(editValueAnchorEl)}
        anchorEl={editValueAnchorEl}
        onUpsertCustomCovenantMetricValue={onUpsertCustomCovenantMetricValue}
        onDeleteCustomCovenantMetricValue={onDeleteCustomCovenantMetricValue}
        onCloseEdit={onCloseEdit}
        value={covenantValueByType as string}
        loading={loading}
      />
    </React.Fragment>
  );
};
