import * as R from 'ramda';

import { Nullable } from 'src/types';
import { divideSafe, isFunction } from 'src/utils';

export function calculateActualValue(
  metricValue1: Nullable<number>,
  metricValue2: Nullable<number>,
  factionDigits = 0,
): number | null {
  const isValuesExist =
    !R.isNil(metricValue1) &&
    !R.isNil(metricValue2) &&
    !isNaN(metricValue1) &&
    !isNaN(metricValue2);

  if (!isValuesExist || metricValue2 === 0) {
    return null;
  }

  const actualValue = divideSafe(metricValue1, metricValue2);

  if (actualValue === null) {
    return null;
  }

  return Number(actualValue.toFixed(factionDigits));
}

export function calculateDifferenceValue(
  actualValue: Nullable<number>,
  covenantValue: Nullable<number>,
  customCalculate?: (
    actualValue: Nullable<number>,
    covenantValue: Nullable<number>,
  ) => number | null,
): number | null {
  const isValuesExist =
    !R.isNil(actualValue) &&
    !R.isNil(covenantValue) &&
    !isNaN(actualValue) &&
    !isNaN(covenantValue);

  if (!isValuesExist) {
    return null;
  }

  if (isFunction(customCalculate)) {
    return customCalculate(actualValue, covenantValue);
  }

  return Number(actualValue) - Number(covenantValue);
}

export function calculateInCompliance(
  greaterValue: number | null,
  lesserValue: number | null,
  customCalculate?: (greaterValue: number | null, lesserValue: number | null) => boolean | null,
): boolean | null {
  if (R.isNil(greaterValue) || R.isNil(lesserValue)) {
    return null;
  }

  if (isFunction(customCalculate)) {
    return customCalculate(greaterValue, lesserValue);
  }

  return !R.isNil(greaterValue) && !R.isNil(lesserValue) && R.gte(greaterValue, lesserValue);
}
