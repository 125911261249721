import React from 'react';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormGroup,
  FormControl,
  LinearProgress,
  Tooltip,
  Button,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';
import { HelpOutline } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useClientRoles } from 'src/app-builder/providers';
import { ProgressStep } from './ProgressStep';
import { AppRole, ProgressStepCode } from 'src/types';
import { DEFAULT_PROGRESS_COMPANY_STEP_NAME, STEP_GROUP, STEP_GROUP_COMMENT } from 'src/constants';
import {
  calculatePercentNumberOfNumber,
  isFunction,
  insertBefore,
  parseStepNameToCode,
  insertAfter,
  getCurrentUserRole,
} from 'src/utils';

const ProgressBar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      top: 10,
      height: 8,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.main,
    },
  }),
)(LinearProgress);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    details: {
      alignItems: 'center',
    },
    progressBar: {
      width: '30%',
      marginLeft: 40,
      marginRight: 40,
    },
    accordionDetails: {
      display: 'flex',
    },
    completeText: {
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.secondary.main,
    },
    stepSubtitle: {
      position: 'relative',
      left: 35,
      top: -10,
      fontSize: 14,
      color: 'grey',
    },
    groupTitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltip: {
      marginLeft: 20,
      maxWith: 500,
      fontSize: 16,
    },
    tooltipText: {
      fontSize: 14,
    },
  }),
);

export type ProgressStepProps = {
  id: string;
  name: string;
  code: ProgressStepCode;
  active: boolean;
  isVisible: boolean | null | undefined;
  status?: string;
  disabled?: boolean;
  comment?: string;
  bigfootTeamComment?: any;
  applicantComment?: any;
  helperText?: string;
  type?: string | null;
};

interface ProgressStageAccordionProps {
  title: string;
  steps: Array<ProgressStepProps>;
  upsertStepVisibility?: (step: ProgressStepProps) => void;
  insertCompanyStep: ((name: string, code: string, comment: string) => void) | null | undefined;
  upsertStepComments: (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => void;
  onChangeStep?: (step: ProgressStepProps, name: string, comment: string) => void;
  onSelectStep?: (step: ProgressStepProps) => void;
  disabled?: boolean;
}

export const ProgressStageAccordion: React.FC<ProgressStageAccordionProps> = props => {
  const {
    steps,
    title,
    onSelectStep,
    onChangeStep,
    insertCompanyStep,
    upsertStepComments,
    upsertStepVisibility,
  } = props;
  const classes = useStyles();

  const { currentRole } = useClientRoles();

  const { isBigfootTeam } = getCurrentUserRole(currentRole as AppRole);

  const onCompanyStepPopoverOpen = () => {
    if (insertCompanyStep) {
      const code = parseStepNameToCode(DEFAULT_PROGRESS_COMPANY_STEP_NAME);
      insertCompanyStep(DEFAULT_PROGRESS_COMPANY_STEP_NAME, code, '');
    }
  };

  const countCompletedSteps = steps.reduce((count, step: ProgressStepProps) => {
    if (step.active) {
      return (count += 1);
    }

    return count;
  }, 0);

  const visibleSteps = steps.filter(step => step.isVisible);

  const progressPercent = calculatePercentNumberOfNumber(countCompletedSteps, visibleSteps.length) || 0;

  const onChange = React.useCallback(
    (step: ProgressStepProps) => {
      if (onSelectStep && isFunction(onSelectStep)) {
        onSelectStep(step);
      }
    },
    [onSelectStep],
  );

  const renderStepGroupTitle = React.useCallback(
    (title: string) => {
      const titleComment = STEP_GROUP_COMMENT[title];
      if (Boolean(titleComment)) {
        return (
          <div className={classes.groupTitleContainer}>
            <Typography variant="subtitle1">{title}</Typography>
            <Tooltip
              className={classes.tooltip}
              title={<span className={classes.tooltipText}>{titleComment}</span>}
              placement="top"
            >
              <HelpOutline />
            </Tooltip>
          </div>
        );
      }

      return <Typography variant="subtitle1">{title}</Typography>;
    },
    [classes],
  );

  const groupSteps = React.useMemo(() => {
    let stepsByGroups = steps;

    steps?.forEach((step: ProgressStepProps) => {
      if (STEP_GROUP[step.code as string]) {
        stepsByGroups = insertBefore(
          stepsByGroups,
          renderStepGroupTitle(STEP_GROUP[step.code as string]),
          item => item.code === step.code,
        );
      }
      if (step.code === ProgressStepCode.TaxGuardCEO) {
        stepsByGroups = insertAfter(
          stepsByGroups,
          renderStepGroupTitle(STEP_GROUP.Other),
          item => item.code === ProgressStepCode.TaxGuardCEO,
        );
      }
    });

    return stepsByGroups.map(stepByGroup => {
      if (stepByGroup.code) {
        return (
          <ProgressStep
            key={stepByGroup.id}
            step={stepByGroup}
            onSelectStep={onChange}
            upsertStepVisibility={upsertStepVisibility}
            onChangeStep={onChangeStep}
            upsertStepComments={upsertStepComments}
          />
        );
      }

      return stepByGroup;
    });
  }, [
    steps,
    renderStepGroupTitle,
    onChange,
    upsertStepComments,
    onChangeStep,
    upsertStepVisibility,
  ]);

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography variant="subtitle1">{title}</Typography>
          </div>
          <div className={classes.progressBar}>
            <ProgressBar variant="determinate" value={progressPercent} />
          </div>
          <div className={classes.completeText}>
            <Typography variant="subtitle1">
              {countCompletedSteps} OF {visibleSteps.length}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.accordionDetails}>
            <FormControl>
              <FormGroup>{groupSteps}</FormGroup>
              {isBigfootTeam && (
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<icons.Add />}
                    onClick={onCompanyStepPopoverOpen}
                  >
                    Add item
                  </Button>
                </div>
              )}
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
