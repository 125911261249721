import { User } from 'src/graphql';

const distributor = (arr: User[], dict: Record<string, User[]>): Record<string, User[]> => {
  if (!arr.length) return dict;

  const el = arr.pop();
  const newDict = { ...dict };

  const loanApplicationId = el?.loanApplications?.id;

  if (loanApplicationId) {
    const dictValue = newDict[loanApplicationId];

    if (Array.isArray(dictValue)) {
      dictValue.push(el as User);
    } else {
      newDict[loanApplicationId] = [el as User];
    }
  }

  return distributor(arr, newDict);
};

export const structureUsers = (usersArray: User[]): Record<string, User[]> =>
  usersArray ? distributor(usersArray, {}) : {};
