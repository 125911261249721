import { File, InboxMessagesListQueryVariables, SortOrder } from 'src/graphql';
import * as R from 'ramda';

interface PayloadData {
  subject: string;
  body: string;
  isByAdministrator: boolean;
  repliedOnId?: string;
  attachments: File[];
  companyId: string;
}

interface CreatedPayload {
  data: {
    companyId: string;
    subject: string;
    isByAdministrator: boolean;
    body: string;
    repliedOnId: string | undefined;
    attachments: {
      create:
        | {
            file: { create: Omit<File, string> };
            company: { connect: { id: string } };
            type: string;
          }[]
        | undefined;
    };
  };
}

export const createPayload = ({
  subject,
  body,
  isByAdministrator,
  repliedOnId,
  attachments,
  companyId,
}: PayloadData): CreatedPayload => {
  const payload = {
    data: {
      subject,
      body,
      isByAdministrator,
      repliedOnId,
      companyId,
      attachments: {
        create: attachments?.map((file: File) => {
          const preparedFile = R.omit(['mimetype', 'downloadUrl'], file);
          return {
            file: { create: preparedFile },
            type: 'inbox',
            company: { connect: { id: companyId } },
          };
        }),
      },
    },
  };

  return payload;
};

export const createInboxListQueryVariables = (
  companyId: string,
  search?: string,
): InboxMessagesListQueryVariables => {
  const variables: InboxMessagesListQueryVariables = {
    filter: {
      company: {
        id: {
          equals: companyId,
        },
      },
    },
    sort: {
      createdAt: SortOrder.Desc,
    },
  };

  if (search) {
    variables.filter._fullText = search;
  }
  return variables;
};
