import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

interface MetricCellMarkProps {
  hasAdjustedValue?: boolean;
  hasDataChanged?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  adjustMarker: {
    position: 'absolute',
    right: 0,
    top: 0,
    border: '5px solid transparent',
    borderTop: '5px solid #295F7B',
    borderRight: '5px solid #295F7B',
  },
  dataChangeMarket: {
    position: 'absolute',
    left: 1,
    top: 0,
    border: '5px solid transparent',
    borderTop: `5px solid ${theme.palette.secondary.main}`,
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
  },
}));

export const MetricCellMark: React.FC<MetricCellMarkProps> = ({
  hasAdjustedValue,
  hasDataChanged,
}) => {
  const classes = useStyles();

  if (!hasAdjustedValue && !hasDataChanged) {
    return null;
  }

  return (
    <React.Fragment>
      {hasAdjustedValue && <Box className={classes.adjustMarker} />}
      {hasDataChanged && <Box className={classes.dataChangeMarket} />}
    </React.Fragment>
  );
};
