import React from 'react';
import * as R from 'ramda';
import * as icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress, Theme, Typography, Paper } from '@material-ui/core';

import { SubmitButton } from 'src/components';
import { Table, MetricColumnRenderer, renderValueColumn, renderTierColumn } from './index';

import { MetricValue, TableExportType } from 'src/graphql';
import { CELL_TYPES, ScoreMetricItem, ScoreMetricsTableDataStructureRow } from 'src/types';
import { DateFormatPatterns, detectArrowType, formatDate } from 'src/utils';
import { useTableExport } from 'src/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  form: {
    padding: theme.spacing(2),
  },
  right: {
    marginLeft: 'auto',
  },
}));

interface LayoutProps {
  filters: React.ReactNode;
  tableData: { data: any[]; dates: string[] };
  loading: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ filters, tableData, loading }) => {
  const classes = useStyles();
  const prevMetricValue = React.useRef<MetricValue | undefined>();

  const dates: string[] = tableData?.dates;
  const scoreMetricItems: ScoreMetricItem[] = R.pathOr([], ['data'], tableData);

  const arrowTypes = dates?.map((date, idx) => detectArrowType(dates, scoreMetricItems, date, idx));

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'name',
        exportData: { title: ' ', type: CELL_TYPES.text, key: 'name' },
        render: MetricColumnRenderer,
        width: 300,
        fixed: 'left',
      },
      ...dates
        .map((date, dateIdx) => [
          {
            title: formatDate(
              date,
              DateFormatPatterns.shortDateWithSlash,
              DateFormatPatterns.shortDateWithDash,
            ),
            exportData: { title: date, type: CELL_TYPES.text, key: date },
            colSpan: 2,
            width: 110,
            dataIndex: date,
            render: (
              metricValue: MetricValue,
              row: ScoreMetricsTableDataStructureRow,
              idx: number,
            ) => {
              return renderValueColumn({
                metricValue,
                idx,
                date,
                prevMetricValue,
                metricCode: (row as ScoreMetricItem).code,
                metricName: (row as ScoreMetricItem).name,
                arrowType: arrowTypes[dateIdx],
              });
            },
          },
          {
            colSpan: 0,
            width: 60,
            dataIndex: date,
            render: (value: MetricValue, row: ScoreMetricsTableDataStructureRow, idx: number) => {
              return renderTierColumn(value, row, idx);
            },
          },
        ])
        .flat(),
    ],
    [dates, arrowTypes],
  );

  const [onExport, { loading: isExporting }] = useTableExport(TableExportType.ScoreAndMetrics, {
    rows: scoreMetricItems,
    columns,
  });

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Paper>
        <Grid className={classes.header} item alignItems="center">
          <Typography variant="subtitle1">SaaSScore &amp; Key Metrics</Typography>

          <Grid className={classes.right} item>
            <SubmitButton
              onClick={onExport}
              color="secondary"
              variant="outlined"
              size="large"
              startIcon={<icons.GetApp />}
              loading={isExporting}
            >
              Export
            </SubmitButton>
          </Grid>
        </Grid>

        {loading ? (
          <LinearProgress />
        ) : (
          <>
            <Grid className={classes.form} item container alignItems="center" spacing={2}>
              <Grid item container alignItems="center" xs={8}>
                {filters}
              </Grid>
            </Grid>
            <Table data={scoreMetricItems} dates={dates} columns={columns} />
          </>
        )}
      </Paper>
    </Grid>
  );
};
