import React from 'react';
import { Typography } from '@material-ui/core';
import { gql, useTablesList } from '8base-react-sdk';
import { useMutation } from 'react-apollo';
import { tablesListSelectors, createTableRowUpdateTag } from '@8base/utils';

import { useNotification } from 'src/hooks';
import { useCurrentUser } from '../../hooks';
import { useClientRoles } from 'src/app-builder/providers';
import {
  AccountInformationForm,
  AccountInformationFormValues,
} from 'src/app-builder/routes/account/AccountInformationForm';
import { APP_ROLES } from 'src/types';
import { t } from 'src/utils';

interface DataForUpdate extends Omit<AccountInformationFormValues, 'email' | 'avatar'> {
  avatar?: {
    create?: {
      fileId: string;
      filename: string;
      public: boolean;
    };
    disconnect?: {
      fileId: string;
    };
  };
}

export const AccountInformationPage: React.FC<any> = () => {
  const { user } = useCurrentUser();
  const { currentRole } = useClientRoles();
  const notification = useNotification();

  const isEmployee =
    currentRole?.name === APP_ROLES.appAdministrator.name ||
    currentRole?.name === APP_ROLES.appAnalyst.name;

  const { tablesList } = useTablesList();

  const usersTable = tablesListSelectors.getTableByName(tablesList, 'Users');

  const [userUpdate, { loading }] = useMutation(
    gql`
      ${createTableRowUpdateTag(tablesList, usersTable.id, { withMeta: true })}
    `,
    {
      onCompleted: () => notification.success(t('account_information_update_success')),
      onError: () => notification.error(t('account_information_update_error')),
    },
  );

  const onSubmit = async (data: Omit<AccountInformationFormValues, 'email'>) => {
    const dataForUpdate: DataForUpdate = {
      firstName: data.firstName,
      lastName: data.lastName,
      title: data.title,
    };

    if (data.avatar?.fileId !== user?.avatar?.fileId) {
      if (data.avatar) {
        dataForUpdate.avatar = {
          create: {
            fileId: data.avatar?.fileId,
            filename: data.avatar?.filename,
            public: data.avatar?.public,
          },
        };
      } else if (!!user?.avatar?.fileId) {
        dataForUpdate.avatar = {
          disconnect: {
            fileId: user?.avatar.fileId,
          },
        };
      }
    }

    await userUpdate({
      variables: {
        data: {
          id: user?.id,
          ...dataForUpdate,
        },
      },
    });
  };

  if (!Boolean(user)) return null;

  return (
    <React.Fragment>
      <Typography style={{ marginBottom: 16 }} variant="h6">
        Account Information
      </Typography>
      <AccountInformationForm
        loading={loading}
        isEmployee={isEmployee}
        initialValues={user as any}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};
