import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

import { User } from 'src/graphql';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

const useStyles = makeStyles(theme => ({
  chip: ({ status }: { status?: string }): CreateCSSProperties<{ status?: string }> => {
    let color: PaletteColor | string = '';
    switch (status) {
      case 'active': {
        color = '#a1cf43';
        break;
      }
      case 'inactive': {
        color = theme.palette.error;
        break;
      }
      case 'invitationPending': {
        color = theme.palette.info.main;
        break;
      }
    }

    return {
      backgroundColor: color as string,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
    };
  },
}));

export const StatusRenderer = (user: User): JSX.Element => {
  const classes = useStyles({ status: user.status as string });

  return <Chip className={classes.chip} size="small" label={user?.status} />;
};
