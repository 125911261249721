export enum Country {
  USA = 'USA',
}

export const COUNTRIES_OPTIONS = [
  {
    label: Country.USA,
    value: Country.USA,
  },
];

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const CANADA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Northwest Territories',
  'Nunavut',
  'Yukon',
];

export const STATE_OPTIONS_BY_COUNTRY: Record<Country, Array<{ value: string; label: string }>> = {
  [Country.USA]: USA_STATES.map((value: string) => ({ value, label: value })),
};

export const BUSINESS_LOCATION_OPTIONS: Array<{ value: string; label: string }> = [
  ...COUNTRIES_OPTIONS,
  { label: 'Other', value: 'Other' },
];

export const BUSINESS_LOCATION_VALUES = [Country.USA, 'Other'];
