import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useModal } from 'src/app-builder';

import { DIALOGS } from 'src/dialogs/constants';

interface BusinessLocationCheckDialogProps {}

export const BusinessLocationCheckDialog: React.FC<BusinessLocationCheckDialogProps> = () => {
  const { open, closeModal } = useModal(DIALOGS.BUSINESS_LOCATION_CHECK_DIALOG);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle>Sorry we can&apos;t move forward</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Bigfoot Capital currently doesn&apos;t lend to companies outside of the US
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
