import React from 'react';
import * as R from 'ramda';
import { useTablesList } from '8base-react-sdk';
import { tableSelectors, tablesListSelectors, FieldSchema } from '@8base/utils';

import { AppAreaField, APP_SETTING } from 'src/constants/app';

export type SelectedColumn = {
  id: string;
  selected: boolean;
  areaField: AppAreaField;
  tableField: FieldSchema;
};

export type SelectedColumns = Array<SelectedColumn>;

export type SelectedColumnsHandler = (
  id: string,
) => (event: React.ChangeEvent<HTMLInputElement>) => void;

export const useSelectedColumns = ({
  areaCode,
}: {
  areaCode?: string;
}): {
  selectedColumns: SelectedColumns;
  setSelectedColumns: (value: SelectedColumns) => void;
  createHandleToggleColumn: SelectedColumnsHandler;
  moveColumn: (fromIndex: number, toIndex: number) => void;
  showAll: () => void;
  hideAll: () => void;
  restoreDefaults: () => void;
} => {
  const { tablesList } = useTablesList();

  const area = R.find(R.propEq('code', areaCode), APP_SETTING.areas.items || []);

  const table = tablesListSelectors.getTableById(tablesList, area?.tableId as string);

  const [selectedColumns, setSelectedColumns] = React.useState<SelectedColumns>(
    (area?.fieldsOrder || []).map(id => {
      const areaField = R.find(R.propEq('id', id), area?.fields?.items || []);

      if (!areaField) {
        throw new Error(`${id} undefined field`);
      }

      let tableField = tableSelectors.getFieldById(table, areaField?.fieldId);

      if (areaField.fieldName.indexOf('.') !== -1) {
        const relation = tableSelectors.getFieldByName(table, areaField.fieldName.split('.')[0]);

        if (relation) {
          if (relation.fieldType !== 'SMART') {
            const relationTable = tablesListSelectors.getTableById(
              tablesList,
              relation.relation.refTable.id,
            );

            tableField = tableSelectors.getFieldById(relationTable, areaField?.fieldId);
          }
        }
      }

      if (areaField.tableId) {
        const otherTable = tablesListSelectors.getTableById(
          tablesList,
          areaField?.tableId as string,
        );

        tableField = tableSelectors.getFieldById(otherTable, areaField?.fieldId);
      }

      if (!tableField) {
        throw new Error(`${id} undefined table field`);
      }

      return { id, selected: areaField.showByDefault, areaField, tableField };
    }),
  );

  const createHandleToggleColumn = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const index = R.findIndex(R.propEq('id', id), selectedColumns);

    setSelectedColumns(
      R.over(R.lensIndex(index), R.assoc('selected', event.target.checked), selectedColumns),
    );
  };

  const moveColumn = React.useCallback(
    (fromIndex: number, toIndex: number): void => {
      setSelectedColumns(R.move(fromIndex, toIndex, selectedColumns));
    },
    [setSelectedColumns, selectedColumns],
  );

  const showAll = React.useCallback((): void => {
    setSelectedColumns(
      selectedColumns.map(({ selected, ...rest }) => ({ selected: true, ...rest })),
    );
  }, [setSelectedColumns, selectedColumns]);

  const hideAll = React.useCallback((): void => {
    setSelectedColumns(
      selectedColumns.map(({ selected, ...rest }) => ({ selected: false, ...rest })),
    );
  }, [setSelectedColumns, selectedColumns]);

  const restoreDefaults = React.useCallback((): void => {
    setSelectedColumns(
      selectedColumns.map(({ selected, areaField, ...rest }) => ({
        selected: areaField.showByDefault,
        areaField,
        ...rest,
      })),
    );
  }, [setSelectedColumns, selectedColumns]);

  return {
    selectedColumns,
    setSelectedColumns,
    createHandleToggleColumn,
    moveColumn,
    showAll,
    hideAll,
    restoreDefaults,
  };
};
