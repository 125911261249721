import React, { createContext, useState, memo, useCallback } from 'react';

interface UseSelectedRowsResult {
  selectedRows: Record<string, boolean>;
  toggleRowSelection: (v: string) => void;
  toggleAll: () => void;
  initRows: (rows: any, key: string) => void;
  isAllRowsSelected: boolean;
  isNotAllRowsSelected: boolean;
  getSelectedArray: () => string[];
}

const selectedRowsContext = createContext<UseSelectedRowsResult>({} as UseSelectedRowsResult);

type SelectedRows = Record<string, boolean>;

const useSelectedRows = (): UseSelectedRowsResult => {
  const [selectedRows, setSelectedRows] = useState<SelectedRows>({});

  const toggleRowSelection = (rowId: string) => {
    setSelectedRows({ ...selectedRows, [rowId]: !selectedRows[rowId] });
  };

  const toggleAll = () => {
    const isAllSelectedNow = Object.values(selectedRows).every(v => v);

    const newSelectedRows: SelectedRows = {};
    Object.keys(selectedRows).forEach(key => {
      newSelectedRows[key] = !isAllSelectedNow;
    });
    setSelectedRows(newSelectedRows);
  };

  const initRows = useCallback((newRows: Record<string, any>[], key: string) => {
    const newSelectedRows: SelectedRows = {};

    newRows.forEach(row => {
      newSelectedRows[row[key]] = false;
    });

    setSelectedRows(newSelectedRows);
  }, []);

  const selectedRowsArray = Object.values(selectedRows);

  const isAllRowsSelected = selectedRowsArray.every(v => v) && Boolean(selectedRowsArray.length);
  const isNotAllRowsSelected = selectedRowsArray.some(v => v) && Boolean(selectedRowsArray.length);
  const getSelectedArray = () =>
    Object.entries(selectedRows)
      .filter(([, value]) => Boolean(value))
      .map(([key]) => key);

  return {
    selectedRows,
    toggleRowSelection,
    toggleAll,
    initRows,
    isAllRowsSelected,
    getSelectedArray,
    isNotAllRowsSelected,
  };
};

const SelectedRowsProvider = memo(({ children }: { children: React.ReactNode }) => {
  const ctx = useSelectedRows();
  return <selectedRowsContext.Provider value={ctx}>{children}</selectedRowsContext.Provider>;
});

SelectedRowsProvider.displayName = 'SelectedRowsProvider';

export { SelectedRowsProvider, selectedRowsContext };
