import React from 'react';
import * as R from 'ramda';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TableHead, TableRow, TableCell, TableBody, Table, Typography } from '@material-ui/core';

import {
  getAdjustedOrOriginalMetricValue,
  getFormattedMetricValue,
  sortScoreDetailsMetrics,
} from 'src/utils';
import { MetricCode, ScoreDetailsMetric } from 'src/types';

const useStyles = makeStyles((theme: Theme) => ({
  table: {},
  tableHeadRow: {
    '& .MuiTableCell-root': {
      background: `${theme.palette.common.black} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  tableRow: {
    '&:nth-of-type(even)': {
      background: theme.palette.grey[200],
    },

    '& .MuiTableCell-root': {
      borderBottom: 'none !important',
    },
  },
  tableCell: {
    padding: theme.spacing(3),
  },
}));

type SaasScoreMetricsTableProps = {
  scoreDetailsMetrics: Array<ScoreDetailsMetric>;
};

export const SaasScoreMetricsTable: React.FC<SaasScoreMetricsTableProps> = ({
  scoreDetailsMetrics,
}) => {
  const classes = useStyles();

  const formatMetricValue = (metric: any) => {
    const value = getAdjustedOrOriginalMetricValue(metric);
    return !R.isNil(value) ? getFormattedMetricValue(metric.value, metric.format) : '–';
  };

  const formatTier = (metric: any) => {
    return metric.tier ?? '-';
  };

  // Ignore score metric
  const filteredScoringDetails = scoreDetailsMetrics.filter((metric: ScoreDetailsMetric) => {
    return metric.code !== MetricCode.SaaSScore;
  });

  const sortedMetrics = sortScoreDetailsMetrics(filteredScoringDetails);

  return (
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <TableRow className={classes.tableHeadRow}>
          <TableCell align="left">Metric</TableCell>
          <TableCell align="center">Value</TableCell>
          <TableCell align="center">Tier</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedMetrics.map((metric: ScoreDetailsMetric) => (
          <TableRow key={metric.id} className={classes.tableRow}>
            <TableCell align="left" className={classes.tableCell}>
              <Typography variant="body1">{metric.name}</Typography>
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              <Typography variant="body1">{formatMetricValue(metric)}</Typography>
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              <Typography variant="body1">{formatTier(metric)}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
