/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import { TableShared } from 'src/shared/table/TableShared';
import { WeightRenderer, BoundsRenderer, BoundsDirectionRenderer, Tiers } from './';
import { SaaSScoreMetricSetting } from 'src/types';
import { detectIsEnterpriseRowByRowIdx } from '../helpers';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& td': {
      padding: '8px',
      height: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& tr': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        height: 41,
      },
    },
    '& .rc-table-container': {
      height: '100%',
    },
    '& .rc-table-body': {
      height: 'calc(100% - 41px)',
    },
  },
  nameColumn: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

interface TableProps {
  data: SaaSScoreMetricSetting[];
}

export const Table: React.FC<TableProps> = memo(({ data }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Metric Name',
      dataIndex: 'name',
      align: 'left',
      width: '20%',
      className: classes.nameColumn,
      render: (name: string, _: any, idx: number) => ({
        children: name,
        props: {
          rowSpan: detectIsEnterpriseRowByRowIdx(idx) ? 0 : 2,
        },
      }),
    },
    {
      align: 'left',
      width: '15%',
      render: (_: any, __: any, idx: number) =>
        detectIsEnterpriseRowByRowIdx(idx) ? 'Enterprise' : 'SMB',
    },
    {
      title: 'Bands Order',
      render: (row: SaaSScoreMetricSetting, _: any, idx: number) => (
        <BoundsDirectionRenderer
          isEnterprise={detectIsEnterpriseRowByRowIdx(idx)}
          code={row.code as string}
        />
      ),
      align: 'center',
      width: '18%',
    },
    {
      title: Tiers(),
      render: (row: SaaSScoreMetricSetting, _: any, idx: number) => (
        <BoundsRenderer
          format={row.format as string}
          isEnterprise={detectIsEnterpriseRowByRowIdx(idx)}
          code={row.code as string}
        />
      ),
      align: 'center',
      width: '32%',
    },
    {
      title: 'Metric Weight',
      render: (row: SaaSScoreMetricSetting, _: any, idx: number) => (
        <WeightRenderer
          isEnterprise={detectIsEnterpriseRowByRowIdx(idx)}
          code={row.code as string}
        />
      ),
      align: 'center',
      width: '15%',
    },
  ];

  // This is for TableUnderwritingReport structure
  const doubledData = data.map(item => [item, item]).flat();

  return <TableShared columns={columns as any} data={doubledData} className={classes.table} />;
});
