import React, { ChangeEvent } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { useMetricsUpdate, useScoreContext, useToggleMetricsContext } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { RecalculateMetricsButton } from 'src/shared/pageScoreAndMetrics';
import { SAASSCORE_PERIODS } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}));

interface FiltersProps {
  companyId: string;
  selectedPeriod: number;
  onChangeSelectedPeriod: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode,
  ) => void;
}

export const Filters: React.FC<FiltersProps> = ({
  companyId,
  selectedPeriod,
  onChangeSelectedPeriod,
}) => {
  const classes = useStyles();

  const {
    recalculateMetrics,
    companiesWithChange,
    hasMetricChanges,
    recalculating,
  } = useScoreContext();

  const { areTogglesExpanded, setTogglesExpanded } = useToggleMetricsContext();

  const [
    updateMetrics,
    { loading: isMetricsUpdating, disabled: isUpdateDisabled },
  ] = useMetricsUpdate(companyId, recalculateMetrics);

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Selected Period</InputLabel>
          <Select
            label="Selected Period"
            value={selectedPeriod}
            onChange={onChangeSelectedPeriod}
            fullWidth
          >
            {SAASSCORE_PERIODS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid className={classes.marginLeft} item>
        <RecalculateMetricsButton
          companyId={companyId}
          hasMetricChanges={hasMetricChanges}
          companiesWithChange={companiesWithChange}
          recalculating={recalculating}
          recalculateMetrics={recalculateMetrics}
        />
      </Grid>

      <Grid item>
        <SubmitButton
          className={classes.marginLeft}
          size="large"
          color="secondary"
          variant="contained"
          disabled={isUpdateDisabled}
          loading={isMetricsUpdating}
          onClick={updateMetrics}
        >
          Update metrics
        </SubmitButton>
      </Grid>

      <Grid item>
        <SubmitButton
          className={classes.marginLeft}
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => setTogglesExpanded(!areTogglesExpanded)}
        >
          Show All
        </SubmitButton>
      </Grid>
    </React.Fragment>
  );
};
