export enum ProgressStageName {
  PreTermSheetItems = 'Pre Term Sheet Items',
  PostTermSheetItems = 'Post Term Sheet Items',
  Closing = 'Closing / Funding Items',
}

export enum ProgressStepCode {
  IncomeStatement = 'INCOME_STATEMENT',
  BalanceSheet = 'BALANCE_SHEET',
  StatementOfCashFlows = 'STATEMENT_OF_CASH_FLOWS',
  MetricsReporting = 'METRICS_REPORTING',
  CustomerReporting = 'CUSTOMER_REPORTING',
  CashProjections = 'CASH_PROJECTIONS',
  DownloadCSV = 'DOWNLOAD_CSV',
  ArticlesOfOrganizationIncorporation = 'ARTICLES_OF_ORGANIZATION_INCORPORATION',
  OperatingAgreementCorporateBylaws = 'OPERATING_AGREEMENT_CORPORATE_BYLAWS',
  CertificateOfInsurance = 'CERTIFICATE_OF_INSURANCE',
  CapTable = 'CAP_TABLE',
  ListOfLitigationOngoingOrAnticipated = 'LIST_OF_LITIGATION_ONGOING_OR_ANTICIPATED',
  MonthlyIncomeStatementsForMostRecentTwoCompleteFiscalYears = 'MONTHLY_INCOME_STATEMENTS_FOR_MOST_RECENT_TWO_COMPLETE_FISCAL_YEARS',
  MonthlyIncomeStatementsForYearToDate = 'MONTHLY_INCOME_STATEMENTS_FOR_YEAR_TO_DATE',
  MonthlyBalanceSheetsForMostRecentTowCompleteFiscalYears = 'MONTHLY_BALANCE_SHEETS_FOR_MOST_RECENT_TOW_COMPLETE_FISCAL_YEARS',
  MonthlyBalanceSheetsForYearToDate = 'MONTHLY_BALANCE_SHEETS_FOR_YEAR_TO_DATE',
  MonthlyStatementOfCashFlowsForMostRecentTwoCompleteFiscalYears = 'MONTHLY_STATEMENT_OF_CASH_FLOWS_FOR_MOST_RECENT_TWO_COMPLETE_FISCAL_YEARS',
  MonthlyStatementOfCashFlowsYearToDate = 'MONTHLY_STATEMENT_OF_CASH_FLOWS_YEAR_TO_DATE',
  BankStatementsMonthlyLast12Months = 'BANK_STATEMENTS_MONTHLY_LAST_12_MONTHS',
  AOrRAgingAsOfLastClosedMonth = 'A_OR_R_AGING_AS_OF_LAST_CLOSED_MONTH',
  AOrPAgingASOfLastClosedMonth = 'A_OR_P_AGING_AS_OF_LAST_CLOSED_MONTH',
  BusinessTaxReturnsLastTwoMostRecentFiledYears = 'BUSINESS_TAX_RETURNS_LAST_TWO_MOST_RECENT_FILED_YEARS',
  LoanDocumentsAllDocumentsForOutstandingDebt = 'LOAN_DOCUMENTS_ALL_DOCUMENTS_FOR_OUTSTANDING_DEBT',
  TwoYearsOfCashProjections = 'TOW_YEARS_OF_CASH_PROJECTIONS',
  CustomerContractsSampleContractsThreeLargestCustomers = 'CUSTOMER_CONTRACTS_SAMPLE_CONTRACTS_3_LARGEST_CUSTOMERS',
  ListOfCustomersBreakingDownSigningDateOrMRROrContractRenewal = 'LIST_OF_CUSTOMERS_BREAKING_DOWN_SIGNING_DATE_MRR_CONTRACT_RENEWAL_OR_CURRENT_END_DATE',
  ReadonlyIntoCustomerOrMRRTracking = 'READ_ONLY_INTO_CUSTOMER_OR_MRR_TRACKING',
  CustomerConversations = 'CUSTOMER_CONVERSATIONS',
  InvestorConversations = 'INVESTOR_CONVERSATIONS',
  PricingStructure = 'PRICING_STRUCTURE',
  ProductDemo = 'PRODUCT_DEMO',
  IntellectualPropertyList = 'INTELLECTUAL_PROPERTY_LIST',
  CopyOfDriversLicense = 'COPY_OF_DRIVERS_LICENSE',
  PersonalTaxReturnsLastTwoMostRecentFiledYears = 'PERSONAL_TAX_RETURNS_LAST_TWO_MOST_RECENT_FILED_YEARS',
  BackgroundCheckCompany = 'BACKGROUND_CHECK_COMPANY',
  UCCSearchCompany = 'UCC_SEARCH_COMPANY',
  BackgroundCheckCEO = 'BACKGROUND_CHECK_CEO',
  CreditReportCEO = 'CREDIT_REPORT_CEO',
  TaxMonitoringCompany8821 = 'TAX_MONITORING_COMPANY_8821',
  TaxMonitoringCEO = 'TAX_MONITORING_CEO_8821',
  TaxGuard = 'TAX_GUARD_COMPANY',
  TaxGuardCEO = 'TAX_GUARD_CEO',
  LoanAndSecurityAgreement = 'LOAN_AND_SECURITY_ARGEEMENT',
  ComplianceCertificate = 'COMPLIANCE_CERTIFICATE',
  BigfootUCCFiled = 'BIGFOOT_UCC_FILED',
  ResolutionForLoanAgreement = 'RESOLUTION_FOR_LOAN_AGREEMENT',
  PromissoryNote = 'PROMISSORY_NOTE',
  RequestForAdvance = 'REQUEST_FOR_ADVANCE',
  SubordinationOrIntercreditorAgreement = 'SUBORDINATION_OR_INTERCREDITOR_ARGREEMENT',
  PayoffLetterObtained = 'PayoffLetterObtained',
  ValidityAgreement = 'VALIDITY_AGREEMENT',
  CorporateGuaranty = 'CORPORATE_GUARANTY',
  ResolutionForCorporateGuaranty = 'RESOLUTION_FOR_CORPORATE_GUARANTY',
}

export enum ProgressStepStatus {
  Active = 'Active',
  Pending = 'Pending',
}
