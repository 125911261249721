import React from 'react';
import * as R from 'ramda';

import { KeyCode } from 'src/constants/events';
import {
  getBottomCell,
  getClosestCell,
  getLeftCell,
  getRightCell,
  getTopCell,
  resetCell,
  selectCell,
} from 'src/utils/dom';

export const useTableControl = (): void => {
  const currentCellRef = React.useRef<HTMLDivElement | null>(null);

  const switchArrowSelect = (e: KeyboardEvent): HTMLDivElement | null => {
    if (currentCellRef.current) {
      switch (e.code) {
        case KeyCode.ArrowLeft:
          e.preventDefault();
          return getLeftCell(currentCellRef.current);
        case KeyCode.ArrowRight:
          e.preventDefault();
          return getRightCell(currentCellRef.current);
        case KeyCode.ArrowDown:
          e.preventDefault();
          return getBottomCell(currentCellRef.current);
        case KeyCode.ArrowUp:
          e.preventDefault();
          return getTopCell(currentCellRef.current);
      }
    }

    return null;
  };

  const handleArrowSelect = (e: KeyboardEvent) => {
    const newCurrentCell = switchArrowSelect(e);
    if (R.isNil(newCurrentCell)) {
      return;
    }
    if (currentCellRef.current !== newCurrentCell) {
      resetCell(currentCellRef.current);
      selectCell(newCurrentCell);
      currentCellRef.current = newCurrentCell;
    }
  };

  const handleCellClick = (e: MouseEvent) => {
    const target = e.target;
    if (target instanceof HTMLElement) {
      resetCell(currentCellRef.current);
      const newCurrentCell = getClosestCell(target);
      if (newCurrentCell) {
        selectCell(newCurrentCell);
        currentCellRef.current = newCurrentCell;
      }
    } else if (currentCellRef.current) {
      resetCell(currentCellRef.current);
    }
  };

  const handleKeyboardInput = (e: KeyboardEvent) => {
    handleArrowSelect(e);
    const numberCodeRegExp = /Digit[0-9]/i;
    const cmdMacKey = e.metaKey;

    if (
      !numberCodeRegExp.test(e.code) &&
      e.code !== KeyCode.Minus &&
      e.code !== KeyCode.Delete &&
      e.code !== KeyCode.Backspace &&
      e.code !== KeyCode.Period &&
      !cmdMacKey &&
      !e.ctrlKey &&
      !e.altKey
    ) {
      e.preventDefault();
    }
  };

  const addTableListeners = () => {
    document.addEventListener('click', handleCellClick);
    document.addEventListener('keydown', handleKeyboardInput);
  };

  const removeTableListeners = () => {
    document.removeEventListener('click', handleCellClick);
    document.removeEventListener('keydown', handleKeyboardInput);
  };

  React.useEffect(() => {
    addTableListeners();
    return removeTableListeners;
  }, []);
};
