import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

export const LinkRenderer = (text: string, to: string): React.ReactNode => {
  return (
    <Link color="secondary" component={RouterLink} to={to}>
      {text}
    </Link>
  );
};
