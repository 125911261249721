import gql from 'graphql-tag';

const COVENANT_TRACKING_PORTFOLIO_COMPANY_FRAGMENT = gql`
  fragment CovenantTrackingPortfolioCompanyFragment on PortfolioCompany {
    id
    company {
      id
      name
      metrics(filter: $metricsFilter) {
        items {
          id
          date
          period
          tier
          value
          adjustedValue
          metric {
            id
            name
            code
          }
        }
      }

      covenantValue {
        items {
          id
          covenant {
            id
            name
          }
          value
          isEnabled
        }
      }

      customCovenant(filter: $customCovenantsFilter) {
        items {
          id
          covenant {
            id
            name
          }
          date
          actual
          difference
          inCompliance
        }
      }
    }
  }
`;

export const COVENANT_TRACKING_PORTFOLIO_QUERY = gql`
  query CovenantTrackingPortfolio(
    $metricsFilter: MetricValueFilter!
    $companyFilter: PortfolioCompanyFilter!
    $customCovenantsFilter: CustomCovenantValueFilter!
  ) {
    portfolioCompaniesList(filter: $companyFilter) {
      items {
        ...CovenantTrackingPortfolioCompanyFragment
      }
      count
    }
  }
  ${COVENANT_TRACKING_PORTFOLIO_COMPANY_FRAGMENT}
`;

export const CUSTOM_COVENANT_VALUE_FRAGMENT = gql`
  fragment CustomCovenantValueFragment on CustomCovenantValue {
    id
    date
    company {
      id
    }
    covenant {
      id
      name
    }
    actual
    difference
    inCompliance
  }
`;

export const COVENANT_LIST_QUERY = gql`
  query CovenantList {
    covenantsList {
      items {
        id
        name
      }
    }
  }
`;

export const COVENANT_VALUE_LIST_QUERY = gql`
  query CovenantValuesList($filter: CovenantValueFilter) {
    covenantValuesList(filter: $filter) {
      items {
        id
        covenant {
          id
          name
        }
        company {
          id
        }
        value
        isEnabled
      }
    }
  }
`;

export const SET_COVENANT_VALUES = gql`
  mutation CovenantValueCreateMany($data: [CovenantValueCreateManyInput]!) {
    covenantValueCreateMany(data: $data) {
      items {
        id
        value
        isEnabled
      }
    }
  }
`;

export const SET_COVENANT_VALUE = gql`
  mutation CovenantValueCreate($data: CovenantValueCreateInput!) {
    covenantValueCreate(data: $data) {
      id
      isEnabled
    }
  }
`;

export const DELETE_COVENANT_VALUE = gql`
  mutation CovenantValueDelete($filter: CovenantValueKeyFilter) {
    covenantValueDelete(filter: $filter) {
      success
    }
  }
`;

export const UPDATE_COVENANT_VALUE = gql`
  mutation CovenantValueUpdate($data: CovenantValueUpdateInput!, $filter: CovenantValueKeyFilter) {
    covenantValueUpdate(data: $data, filter: $filter) {
      value
      isEnabled
    }
  }
`;

export const CUSTOM_COVENANT_LIST_QUERY = gql`
  query CustomCovenantValuesList($filter: CustomCovenantValueFilter) {
    customCovenantValuesList(filter: $filter) {
      items {
        ...CustomCovenantValueFragment
      }
    }
  }
  ${CUSTOM_COVENANT_VALUE_FRAGMENT}
`;

export const CREATE_CUSTOM_COVENANT_VALUE_MUTATION = gql`
  mutation CustomCovenantValueCreate($data: CustomCovenantValueCreateInput!) {
    customCovenantValueCreate(data: $data) {
      ...CustomCovenantValueFragment
    }
  }
  ${CUSTOM_COVENANT_VALUE_FRAGMENT}
`;

export const UPDATE_CUSTOM_COVENANT_VALUE_MUTATION = gql`
  mutation CustomCovenantValueUpdate(
    $data: CustomCovenantValueUpdateInput!
    $filter: CustomCovenantValueKeyFilter
  ) {
    customCovenantValueUpdate(data: $data, filter: $filter) {
      ...CustomCovenantValueFragment
    }
  }
  ${CUSTOM_COVENANT_VALUE_FRAGMENT}
`;

export const DELETE_CUSTOM_COVENANT_VALUE_MUTATION = gql`
  mutation CustomCovenantValueDelete($customCovenantValueId: ID!) {
    customCovenantValueDelete(filter: { id: $customCovenantValueId }) {
      success
    }
  }
`;
