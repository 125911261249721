import React from 'react';

import { useAllowed } from 'src/app-builder/providers';
import { useCalculateMetrics, useLocalStorage } from 'src/hooks';

import { CompaniesWithMetricChange, LocalStorageKeys } from 'src/types/localStorage';
import { Permission } from 'src/constants';
import {
  ChangedMetric,
  CompanyType,
  ScoreMetricItem,
  ScoreMetricsTableDataStructureRow,
} from 'src/types';
import { addMetricChange, hasMetricChange } from 'src/utils/localStorage/metrics';
import { isLoanApplicationCompany } from 'src/utils';

export interface ScoreContextData {
  metrics: ScoreMetricsTableDataStructureRow[];
  companiesWithChange: CompaniesWithMetricChange | null;
  hasMetricChanges: boolean;
  recalculateMetrics: () => Promise<void>;
  recalculating: boolean;
  updateMetric: (changedMetric: ChangedMetric) => void;
  canEditMetrics: boolean;
  companyId: string;
}

export const ScoreContext = React.createContext({} as ScoreContextData);

interface ScoreProviderProps {
  companyId: string;
  metrics: ScoreMetricItem[];
  children: React.ReactNode;
  companyType: CompanyType;
}

export const ScoreProvider: React.FC<ScoreProviderProps> = props => {
  const { companyId, metrics, children, companyType } = props;

  const isAllowed = useAllowed();
  const isLoanApplication = isLoanApplicationCompany(companyType);
  const editPermissionByCompanyType = isLoanApplication
    ? Permission.LoanApplicationsEditMetrics
    : Permission.PortfolioCompaniesEditMetrics;

  const canEditMetrics = isAllowed(editPermissionByCompanyType);

  const [companiesWithChange, setCompaniesWithChanges] = useLocalStorage<CompaniesWithMetricChange>(
    LocalStorageKeys.CompaniesWithChange,
    {},
  );

  const [recalculateMetrics, { loading: recalculating }] = useCalculateMetrics(
    companyId,
    companiesWithChange,
    setCompaniesWithChanges,
  );

  const hasMetricChanges = hasMetricChange(companiesWithChange, companyId);

  const updateMetric = (metric: ChangedMetric) => {
    const companies = addMetricChange(companiesWithChange, companyId, metric);
    setCompaniesWithChanges(companies);
  };

  return (
    <ScoreContext.Provider
      value={{
        updateMetric,
        recalculateMetrics,
        recalculating,
        hasMetricChanges,
        metrics,
        canEditMetrics,
        companiesWithChange,
        companyId,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};
