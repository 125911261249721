import React from 'react';
import * as R from 'ramda';
import pluralize from 'pluralize';
import { Box, CircularProgress, Typography, makeStyles, Theme } from '@material-ui/core';
import { Inbox, MoveToInbox } from '@material-ui/icons';

import { EmptyScreen } from 'src/components';
import { EmptyScreenOptions } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    color: theme.palette.grey[400],
    marginBottom: 20,
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(100% - ${theme.spacing(8)}px)`,
  },
}));

interface InboxEmptyScreenProps {
  folder: string;
  quantity: number;
  loading: boolean;
  isVisible: boolean;
}

export const InboxEmptyScreen: React.FC<InboxEmptyScreenProps> = props => {
  const { folder, quantity, loading, isVisible } = props;

  const classes = useStyles();

  const EmptyScreenText: Record<string, string> = React.useMemo(
    () => ({
      [EmptyScreenOptions.NoMessages]: "We couldn't find any messages",
      [EmptyScreenOptions.HaveMessages]: `You have ${quantity} ${folder} ${pluralize(
        'message',
        quantity,
      )}`,
    }),
    [quantity, folder],
  );

  const EmptyScreenIcon: Record<string, React.ElementType> = React.useMemo(
    () => ({
      [EmptyScreenOptions.NoMessages]: Inbox,
      [EmptyScreenOptions.HaveMessages]: MoveToInbox,
    }),
    [],
  );

  const EmptyScreenSelect = () => {
    if (R.isEmpty(quantity)) {
      return EmptyScreenOptions.NoMessages;
    }

    return EmptyScreenOptions.HaveMessages;
  };

  const EmptyScreenVariant = EmptyScreenSelect();

  if (!isVisible) {
    return null;
  }

  if (loading) {
    return (
      <Box className={classes.loaderWrapper}>
        <CircularProgress className={classes.loader} />
        <Typography color="textSecondary" variant="body1" align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <EmptyScreen
      text={EmptyScreenText[EmptyScreenVariant]}
      icon={EmptyScreenIcon[EmptyScreenVariant]}
    />
  );
};
