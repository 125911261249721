import React from 'react';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import * as icons from '@material-ui/icons';

interface GetUserPendingActionsProps {
  canResendInvite: boolean;
  canCancelInvite: boolean;
  resendInvitation: any;
  cancelInvitation: any;
}

export const getUserPendingActions = ({
  canResendInvite,
  canCancelInvite,
  resendInvitation,
  cancelInvitation,
}: GetUserPendingActionsProps): JSX.Element[] => {
  return [
    <MenuItem disabled={!canResendInvite} key="resend" onClick={resendInvitation}>
      <ListItemIcon>
        <icons.Send />
      </ListItemIcon>
      Resend Invitation
    </MenuItem>,
    <MenuItem disabled={!canCancelInvite} key="cancel" onClick={cancelInvitation}>
      <ListItemIcon>
        <icons.Cancel />
      </ListItemIcon>
      Cancel Invitation
    </MenuItem>,
  ];
};
