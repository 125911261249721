import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import * as R from 'ramda';

import { metricSettingsContext, EmptyCell, dirtyContext } from './index';
import { fixNumberAndTruncateZeros, getFractionCount } from 'src/utils';
import { MetricCode } from 'src/types';
import { isEmptyCell } from '../helpers';

interface WeightRendererProps {
  code: string;
  isEnterprise: boolean;
}

export const WeightRenderer: React.FC<WeightRendererProps> = ({ code, isEnterprise }) => {
  const [value, setValue] = useState<string>('');
  const { settings, update } = useContext(metricSettingsContext);
  const { setIsDirty } = useContext(dirtyContext);

  const path = [isEnterprise ? 'saaSScoreWeightEnterprise' : 'saaSScoreWeight'];

  const setWeight = update(code)(path);
  const weight = R.pathOr('', [code, ...path], settings);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.replace(/[^\d.-]/g, ''));
    setIsDirty(true);
  };

  const onBlur = () => {
    const newValue = parseFloat(value) || 0;

    if (newValue >= 1) setWeight(0.99);
    else if (newValue < 0) setWeight(0);
    else if (getFractionCount(newValue) > 4) setWeight(fixNumberAndTruncateZeros(newValue, 4));
    else setWeight(newValue);
  };

  useEffect(() => {
    setValue(weight);
  }, [weight]);

  if (isEmptyCell(isEnterprise, code) || code === MetricCode.SaaSScore) return <EmptyCell />;

  return (
    <TextField size="small" variant="outlined" value={value} onBlur={onBlur} onChange={onChange} />
  );
};
