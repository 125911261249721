import React from 'react';
import * as R from 'ramda';
import { Link, makeStyles, Theme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { REG_EXP } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface ProgressStepHelperTextProps {
  helperText?: string;
  link?: string;
  textReplacedByLink?: string;
}

export const ProgressStepHelperText: React.FC<ProgressStepHelperTextProps> = ({
  helperText,
  link,
  textReplacedByLink,
}) => {
  const classes = useStyles();

  const renderHelperText = () => {
    if (R.isNil(helperText)) {
      return null;
    }

    const insideBracketsStr = helperText.replace(REG_EXP.BRACKETS, '');

    const hasLink =
      !R.isNil(link) &&
      !R.isNil(textReplacedByLink) &&
      insideBracketsStr.includes(textReplacedByLink);

    if (hasLink) {
      const [textBeforeLink, textAfterLink] = helperText.split(textReplacedByLink as string);
      return (
        <span className={classes.helperText}>
          {textBeforeLink}
          <Link component={RouterLink} to={link as string} color={'secondary'}>
            {textReplacedByLink}
          </Link>
          {textAfterLink}
        </span>
      );
    } else {
      return <span className={classes.helperText}>{helperText}</span>;
    }
  };

  return renderHelperText();
};
