import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { actionsContext } from 'src/providers/saaSScore/ActionsProvider';

import { MetricCellMark } from 'src/components';

import {
  getDisplayedAdjustedValue,
  getFormattedMetricValue,
  isFunction,
  isNegative,
} from 'src/utils';
import { getMetricLocalValuesKey, getLocalChangedMetric } from 'src/utils/localStorage/metrics';

import { MetricValue } from 'src/graphql';
import { Format } from 'src/constants';
import { MetricCode, EmptyMetricValue } from 'src/types';
import { useScoreContext } from 'src/hooks';
import { PRIMARY_COLOR } from 'src/constants/colors';

interface MetricCellProps {
  value: MetricValue | EmptyMetricValue;
  date?: string;
  metricCode?: MetricCode;
  metricName?: string;
  metricIndex?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 5,
  },
  lessThanZero: {
    color: '#eb4336',
  },
  pointer: {
    cursor: 'pointer',
  },
  activeCell: {
    background: theme.palette.grey[100],
  },
  dot: ({ tier }: { tier?: number | null }): CreateCSSProperties<{ tier?: number | null }> => {
    let background = 'transparent';

    if (!tier) {
      return {};
    }

    if (tier < 3) {
      background = PRIMARY_COLOR;
    } else if (tier === 5) {
      background = '#EB4336';
    } else if (tier >= 3) {
      background = '#FFCA27';
    }

    return {
      flexShrink: 0,
      background,
      width: 4,
      height: 4,
      borderRadius: 4,
    };
  },
}));

export const MetricCell: React.FC<MetricCellProps> = ({ value, date }) => {
  const classes = useStyles({ tier: value?.tier });

  const { canEditMetrics, companiesWithChange, companyId } = useScoreContext();
  const { openActions, actions } = React.useContext(actionsContext);

  const metric = R.pathOr(null, ['metric'], value);
  const metricCode = R.pathOr('', ['metric', 'code'], value);
  const metricPeriod = R.pathOr('', ['period'], value);

  const metricLocalValueKey = getMetricLocalValuesKey(metricCode, metricPeriod);

  const localChangedMetric = getLocalChangedMetric(
    companiesWithChange,
    companyId,
    metricLocalValueKey,
    date as string,
  );

  const format = R.pathOr(Format.Count, ['format'], metric);
  const amount =
    getDisplayedAdjustedValue(value?.adjustedValue as number, localChangedMetric) ??
    value?.value ??
    null;

  const isActiveCell = !R.isNil(actions?.activeCell) && R.equals(actions.activeCell?.id, value?.id);

  const hasTier = Number.isFinite(value?.tier);
  const hasAdjustedValue = !R.isNil(value?.adjustedValue) || !R.isNil(value?.comment);

  const formattedValue = !R.isNil(amount) ? getFormattedMetricValue(amount, format) : '–';

  const hasLocalDataChanges = !R.isNil(localChangedMetric);

  const onCellClick =
    canEditMetrics && isFunction(openActions)
      ? openActions(value as MetricValue, value.date, metric)
      : () => null;

  return (
    <div
      onClick={onCellClick}
      className={clsx({
        [classes.root]: true,
        [classes.pointer]: canEditMetrics,
        [classes.activeCell]: isActiveCell,
        [classes.lessThanZero]: isNegative(amount),
      })}
    >
      <MetricCellMark hasAdjustedValue={hasAdjustedValue} hasDataChanged={hasLocalDataChanges} />

      <div className={classes.value}>{formattedValue}</div>

      {hasTier && <span className={classes.dot} />}
    </div>
  );
};
