import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ColumnsType } from 'rc-table/es/interface';

import { cashMonitoringActionsContext } from 'src/providers';
import { useSearch, useSort, usePagination } from 'src/hooks';
import {
  CashMonitoringQuery,
  CashMonitoringQueryVariables,
  CASH_MONITORING_QUERY,
  PortfolioCompany,
} from 'src/graphql';
import { CashMonitoringTableRow } from 'src/types';
import {
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  subtractDate,
  isDateGreaterThan,
  getCashMonitoringQueryVariables,
  calculateCashMonitoringTableRow,
} from 'src/utils';
import { getColumns } from 'src/routes/cash-monitoring/components/getColumns';

interface UseCashMonitoringOutput {
  loading: boolean;
  rows: Array<CashMonitoringTableRow>;
  paginatedRows: Array<CashMonitoringTableRow>;
  columns: ColumnsType<Record<string, any>>;
  count: number;
}

export const useCashMonitoring = (
  selectedDate: string,
  maxDate: string,
): UseCashMonitoringOutput => {
  const search = useSearch();
  const sort = useSort();
  const pagination = usePagination();

  const { openEditForm } = React.useContext(cashMonitoringActionsContext);

  const weeklyDate = getEndOfWeek(getStartOfMonth(selectedDate));
  const saasscoreStartDate = getEndOfMonth(subtractDate(selectedDate, 'month', 3) as string);
  const saasscoreEndDate = getEndOfMonth(subtractDate(selectedDate, 'month', 2) as string);

  const isInvalidDateSelected = isDateGreaterThan(selectedDate, maxDate);

  const variables = React.useMemo(
    () =>
      getCashMonitoringQueryVariables(
        search,
        pagination,
        sort,
        weeklyDate,
        saasscoreStartDate,
        saasscoreEndDate,
        selectedDate,
      ),
    [pagination, saasscoreEndDate, saasscoreStartDate, search, selectedDate, sort, weeklyDate],
  );

  const { data, loading } = useQuery<CashMonitoringQuery, CashMonitoringQueryVariables>(
    CASH_MONITORING_QUERY,
    { variables, fetchPolicy: 'no-cache', skip: isInvalidDateSelected },
  );

  const portfolioCompaniesList = React.useMemo(() => data?.portfolioCompaniesList?.items ?? [], [
    data,
  ]) as Array<PortfolioCompany>;

  const count = data?.portfolioCompaniesList?.count ?? 0;

  const rows = React.useMemo(
    () =>
      portfolioCompaniesList.map(pc =>
        calculateCashMonitoringTableRow(
          pc,
          weeklyDate,
          saasscoreStartDate,
          saasscoreEndDate,
          selectedDate,
        ),
      ),
    [portfolioCompaniesList, saasscoreEndDate, saasscoreStartDate, selectedDate, weeklyDate],
  );

  const paginatedRows = React.useMemo(() => {
    const from = pagination.page * pagination.perPage;
    const to = (pagination.page + 1) * pagination.perPage;

    if (rows.length < from) {
      pagination.setPage(0);
      return rows.slice(0, pagination.perPage);
    }

    return rows.slice(from, to);
  }, [pagination, rows]);

  const columns = React.useMemo(() => getColumns(openEditForm), [openEditForm]);

  return {
    loading,
    rows,
    paginatedRows,
    columns,
    count,
  };
};
