import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthPage } from './AuthPage';
import { AuthCallbackPage } from './AuthCallbackPage';
import { ConfirmationPage } from './ConfirmationPage';
import { AuthLoginPage } from './AuthLoginPage';
import { AuthSignUpPage } from './AuthSignUpPage';
import { InvitationDeprecatedPage } from './InvitationDeprecatedPage';
import { ChangePasswordPage } from './ChangePasswordPage';
import { AuthRoutes } from 'src/constants';

export const AuthPages: React.FC = () => {
  return (
    <Switch>
      <Route exact path={AuthRoutes.Auth} component={AuthPage} />
      <Route exact path={AuthRoutes.Login} component={AuthLoginPage} />
      <Route exact path={AuthRoutes.SignUp} component={AuthSignUpPage} />
      <Route exact path={AuthRoutes.ChangePassword} component={ChangePasswordPage} />
      <Route exact path={AuthRoutes.CallBack} component={AuthCallbackPage} />
      <Route exact path={AuthRoutes.Confirmation} component={ConfirmationPage} />
      <Route exact path={AuthRoutes.InvitationDeprecated} component={InvitationDeprecatedPage} />
      <Redirect to={AuthRoutes.Auth} />
    </Switch>
  );
};
