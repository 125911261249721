import React, { ChangeEvent, useRef, useEffect, useState } from 'react';
import * as R from 'ramda';
import { makeStyles, TextField } from '@material-ui/core';

import { fixNumberAndTruncateZeros, getFractionCount } from 'src/utils';
import { ScaleByTierSettings } from 'src/types';

const useStyles = makeStyles(() => ({
  input: {
    display: 'block',
    height: 33,
    '& > div': {
      height: '100%',
    },
  },
  valueContainer: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface ScaleByTierRendererProps {
  idx: number;
  isEnterprise: boolean;
  scaleByTierSettings: ScaleByTierSettings;
  update: (isEnterprise: boolean) => (idx: number) => (value: number) => void;
  setIsDirty: (isDirty: boolean) => void;
}

export const ScaleByTierRenderer: React.FC<ScaleByTierRendererProps> = ({
  idx,
  isEnterprise,
  scaleByTierSettings,
  update,
  setIsDirty,
}) => {
  const classes = useStyles();

  const [active, setActive] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!R.isNil(active) && !R.isNil(inputRef.current)) inputRef.current.focus();
  }, [active, inputRef]);

  useEffect(() => {
    const path = [isEnterprise ? 'enterprise' : 'smb', idx];
    setValue(R.pathOr(null, path, scaleByTierSettings));
  }, [scaleByTierSettings, idx, isEnterprise]);

  if (!scaleByTierSettings) return null;

  const typeValues = scaleByTierSettings[isEnterprise ? 'enterprise' : 'smb'];

  const handleClick = () => setActive(true);
  const handleBlur = () => {
    setActive(false);
    if (R.isNil(value)) return;

    const numberValue = parseFloat(value) || 0;
    const valueToSet =
      getFractionCount(numberValue) > 4 ? fixNumberAndTruncateZeros(numberValue, 4) : numberValue;

    update(isEnterprise)(idx)(valueToSet);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^\d.-]/g, '');
    setValue(newValue);
    setIsDirty(true);
  };

  if (!typeValues) return null;

  return active ? (
    <TextField
      value={value}
      size="small"
      variant="outlined"
      className={classes.input}
      inputRef={inputRef}
      onBlur={handleBlur}
      onChange={onChange}
    />
  ) : (
    <div className={classes.valueContainer} onClick={handleClick}>
      {typeValues[idx]}
    </div>
  );
};
