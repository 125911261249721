import React, { FC } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}));

interface CircularProgressWithLabelProps {
  value: number;
}

export const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = ({
  value,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={value} size={120} {...rest} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
