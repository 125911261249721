import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

import { MonitoringMetricCell } from './MonitoringMetricCell';
import {
  MonitoringCompanyData,
  MonitoringMetricData,
  MonitoringTableRows,
  MonitoringTotalCells,
} from 'src/types';
import { Format } from 'src/constants';
import { DateFormatPatterns, formatDate, getFormattedMetricValue, isNegative } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    marginBottom: theme.spacing(4),
    ...(loading ? { opacity: 0.3 } : {}),
  }),
  monitoringTable: {
    borderCollapse: 'initial',
  },
  companyNameCell: {
    backgroundColor: '#FFF',
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '20%',
  },
  cell: {
    position: 'relative',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  alignRight: {
    textAlign: 'right',
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  totalRow: {
    background: theme.palette.grey[200],
  },
  lessThanZero: {
    color: '#eb4336',
  },
  adjustedValueMarker: {
    position: 'absolute',
    right: 0,
    top: 0,
    border: '5px solid transparent',
    borderTop: '5px solid #295F7B',
    borderRight: '5px solid #295F7B',
  },
}));

interface TotalRowProps {
  dates: string[];
  format: Format;
  totals: MonitoringTotalCells;
}

interface MonitoringTableProps {
  tableName: string;
  dates: string[];
  metricCode: string;
  reportMetrics: Record<string, MonitoringMetricData>;
  reportCompanies: Record<string, MonitoringCompanyData>;
  reportValues: MonitoringTableRows;
  loading?: boolean;
}

const TotalRow: React.FC<TotalRowProps> = ({ dates, totals, format }) => {
  const classes = useStyles({ loading: false });

  return (
    <TableRow>
      <TableCell className={clsx(classes.totalRow, classes.companyNameCell, classes.stickyLeft)}>
        TOTAL
      </TableCell>
      {dates.map((date: string) => {
        const value = totals[date] ?? 0;

        return (
          <TableCell
            className={clsx({
              [classes.totalRow]: true,
              [classes.alignRight]: true,
              [classes.lessThanZero]: isNegative(value),
            })}
            key={date}
          >
            {getFormattedMetricValue(value, format)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export const MonitoringMetricTable: React.FC<MonitoringTableProps> = ({
  tableName,
  dates,
  metricCode,
  reportMetrics,
  reportCompanies,
  reportValues,
  loading = false,
}) => {
  const classes = useStyles({ loading });

  const companies = R.keys(reportCompanies);

  const metric = R.pathOr(null, [metricCode], reportMetrics);
  const format = R.pathOr(Format.Money, ['format'], metric);

  const hasTotalsRow = !R.isNil(reportValues?.totals);

  return (
    <React.Fragment>
      <Box marginBottom={1}>
        <Typography variant="subtitle1">{tableName}</Typography>
      </Box>
      <Grid className={classes.tableWrapper} item>
        <Table className={classes.monitoringTable}>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.companyNameCell, classes.stickyLeft)}>
                Company
              </TableCell>
              {dates.map((date: string) => (
                <TableCell key={date} style={{ textAlign: 'right' }}>
                  {formatDate(date, DateFormatPatterns.shortDateWithSlash)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {hasTotalsRow && (
              <TotalRow
                dates={dates}
                format={format}
                totals={reportValues.totals as MonitoringTotalCells}
              />
            )}
            {companies.map((id: string) => {
              const company = reportCompanies[id];
              const { companyId, companyName, portfolioCompanyId } = company;

              return (
                <TableRow key={companyId}>
                  <TableCell className={clsx(classes.companyNameCell, classes.stickyLeft)}>
                    <Link
                      color="secondary"
                      component={RouterLink}
                      to={`/portfolio-companies/${portfolioCompanyId}/underwriting-package`}
                    >
                      {companyName}
                    </Link>
                  </TableCell>
                  {dates.map((date: string, index: number) => (
                    <MonitoringMetricCell
                      key={index}
                      metricValue={R.pathOr(undefined, [companyId, date], reportValues)}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </React.Fragment>
  );
};
