import { Permission } from 'src/constants';

export const SYSTEM_ROLES = {
  // TODO: use a single register for all records
  Administrator: 'Administrator',
  appAdministrator: 'appAdministrator',
  appAnalyst: 'appAnalyst',
  appCustomer: 'appCustomer',
  appCustomerOwner: 'appCustomerOwner',
  Guest: 'Guest',
} as const;

export const BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST = [
  SYSTEM_ROLES.appAdministrator,
  SYSTEM_ROLES.appAnalyst,
];

export const COMPANY_TEAMMATE_APP_ROLE_NAMES_LIST = [
  SYSTEM_ROLES.appCustomer,
  SYSTEM_ROLES.appCustomerOwner,
];

export const APP_ROLE_NAMES_LIST = [
  SYSTEM_ROLES.appAdministrator,
  SYSTEM_ROLES.appAnalyst,
  SYSTEM_ROLES.appCustomer,
  SYSTEM_ROLES.appCustomerOwner,
];

export type BigfootEmployeeAppRoleName = typeof BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST[number];
export type CompanyTeammatesAppRoleName = typeof COMPANY_TEAMMATE_APP_ROLE_NAMES_LIST[number];
export type AppRoleName = typeof APP_ROLE_NAMES_LIST[number];

export type AppRole = {
  name: AppRoleName;
  title: string;
  permissions: Array<string>;
};

export const APP_ROLES: Record<AppRoleName, AppRole> = {
  [SYSTEM_ROLES.appAdministrator]: {
    name: SYSTEM_ROLES.appAdministrator,
    title: 'Administrator',
    permissions: [
      Permission.HomeArea,
      Permission.AdjustedValueEdit,
      Permission.AdjustedValueDelete,
      Permission.PortfolioCompaniesArea,
      Permission.PortfolioMonitoringArea,
      Permission.LoadApplicationsArea,
      Permission.SettingsArea,
      Permission.UsersArea,
      Permission.LoanApplicationsAsignTo,
      Permission.LoanApplicationsChangeStage,
      Permission.LoanApplicationDelete,
      Permission.LoanApplicationsEditInfo,
      Permission.LoanApplicationsEditSaaSStory,
      Permission.LoanApplicationsEditMetrics,
      Permission.LoanApplicationsAddFacility,
      Permission.LoanApplicationsAddDraw,
      Permission.LoanApplicationsEditDraw,
      Permission.LoanApplicationsDeleteDraw,
      Permission.LoanApplicationsChangeFacilityActive,
      Permission.LoanApplicationsChangeDrawActive,
      Permission.PortfolioCompaniesAddCompany,
      Permission.PortfolioCompaniesChangeMonitoringStatus,
      Permission.PortfolioCompaniesDelete,
      Permission.PortfolioCompaniesEditInfo,
      Permission.PortfolioCompaniesEditSaaSScore,
      Permission.PortfolioCompaniesEditMetrics,
      Permission.PortfolioCompaniesAddFacility,
      Permission.PortfolioOverviewArea,
      Permission.UsersInvite,
      Permission.UsersDelete,
      Permission.LoanApplicationsDelete,
      Permission.TeamInvite,
      Permission.TeamDelete,
      Permission.TeamResendInvite,
      Permission.TeamCancelInvite,
      Permission.UserEdit,
      Permission.TeammateEdit,
      Permission.MasterData,
      Permission.CashMonitoringArea,
      Permission.CovenantTrackingArea,
    ],
  },
  [SYSTEM_ROLES.appAnalyst]: {
    name: SYSTEM_ROLES.appAnalyst,
    title: 'Analyst',
    permissions: [
      Permission.LoanApplicationsAsignTo,
      Permission.LoanApplicationsChangeStage,
      Permission.LoanApplicationDelete,
      Permission.LoanApplicationsEditInfo,
      Permission.LoanApplicationsEditSaaSStory,
      Permission.LoanApplicationsEditMetrics,
      Permission.LoanApplicationsAddFacility,
      Permission.LoanApplicationsAddDraw,
      Permission.LoanApplicationsEditDraw,
      Permission.LoanApplicationsDeleteDraw,
      Permission.LoanApplicationsChangeFacilityActive,
      Permission.LoanApplicationsChangeDrawActive,
      Permission.LoadApplicationsArea,
      Permission.PortfolioMonitoringArea,
      Permission.PortfolioCompaniesArea,
      Permission.PortfolioCompaniesAddCompany,
      Permission.PortfolioCompaniesChangeMonitoringStatus,
      Permission.PortfolioCompaniesDelete,
      Permission.PortfolioCompaniesEditInfo,
      Permission.PortfolioCompaniesEditSaaSScore,
      Permission.PortfolioCompaniesEditMetrics,
      Permission.PortfolioCompaniesAddFacility,
      Permission.PortfolioOverviewArea,
      Permission.UsersInvite,
      Permission.UsersDelete,
      Permission.LoanApplicationsDelete,
      Permission.TeamInvite,
      Permission.TeamDelete,
      Permission.TeamResendInvite,
      Permission.TeamCancelInvite,
      Permission.UserEdit,
      Permission.TeammateEdit,
      Permission.MasterData,
      Permission.CashMonitoringArea,
      Permission.CovenantTrackingArea,
    ],
  },
  [SYSTEM_ROLES.appCustomer]: {
    name: SYSTEM_ROLES.appCustomer,
    title: 'Other',
    permissions: [
      Permission.ClientPortalDashboardArea,
      Permission.ClientPortalInboxArea,
      Permission.ClientPortalSaaSStoryArea,
      Permission.ClientPortalScoreAndMetricsArea,
      Permission.ClientPortalDocumentsArea,
      Permission.ClientPortalFacilityAndDrawsArea,
      Permission.SupportLink,
    ],
  },
  [SYSTEM_ROLES.appCustomerOwner]: {
    name: SYSTEM_ROLES.appCustomerOwner,
    title: 'Account Owner',
    permissions: [
      Permission.CustomerHomeArea,
      Permission.ClientPortalDashboardArea,
      Permission.ClientPortalInboxArea,
      Permission.ClientPortalSaaSStoryArea,
      Permission.ClientPortalScoreAndMetricsArea,
      Permission.ClientPortalDocumentsArea,
      Permission.ClientPortalIntegrationsArea,
      Permission.ClientPortalUsersArea,
      Permission.SettingsArea,
      Permission.ClientPortalFacilityAndDrawsArea,
      Permission.TeammateEdit,
      Permission.SupportLink,
    ],
  },
};
