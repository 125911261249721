import React from 'react';
import * as R from 'ramda';
import { Typography, Grid } from '@material-ui/core';

import { useLocation, matchPath, Link } from 'react-router-dom';
import { APP_SETTING } from 'src/constants/app';

const getPaths = (pathname: string): string[] =>
  pathname
    .replace(/\/$/, '')
    .split('/')
    .filter(Boolean)
    .reduce(
      (result: string[], path: string, index: number) => [
        ...result,
        index < 2 ? `/${path}` : `${result[result.length - 1]}/${path}`,
      ],
      [],
    );

interface BreadcrumbsPathProps {
  path: string;
  index: number;
}

const BreadcrumbsPath: React.FC<BreadcrumbsPathProps> = ({ path, index }) => {
  const area = R.find(
    (item: any) => {
      return Boolean(matchPath(path, { path: item.path, exact: true }));
    },
    [
      ...(APP_SETTING.areas.items || []).map(({ name, title, path }) => ({
        path,
        title: title || name,
      })),
      {
        path: '/:any/:id/dashboard',
        title: 'Dashboard',
      },
      {
        path: '/:any/:id/general',
        title: 'General',
      },
      {
        path: '/settings',
        title: 'Settings',
      },
    ],
  );

  if (!area) {
    return null;
  }

  return (
    <React.Fragment>
      {index > 0 && (
        <Grid key="/" item>
          <Typography color="textSecondary">/</Typography>
        </Grid>
      )}
      <Grid key="item" item>
        <Typography component={Link} to={area.path} color="textSecondary">
          {area.title}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const location = useLocation();

  const paths = getPaths(location.pathname);

  return (
    <Grid container spacing={1}>
      {paths.map((path, index) => (
        <React.Fragment key={path}>
          <BreadcrumbsPath path={path} index={index} />
        </React.Fragment>
      ))}
    </Grid>
  );
};
