import { gql } from '8base-react-sdk';

export const PROGRESS_STAGES_QUERY = gql`
  query ProgressStagesQuery(
    $progressStagesFilter: ProgressStageFilter!
    $progressStepValuesFilter: ProgressStepValueFilter!
    $progressCompanyStepFilter: ProgressCompanyStepFilter!
  ) {
    progressStagesList(filter: $progressStagesFilter) {
      items {
        id
        name
        steps(sort: { order: ASC }) {
          items {
            id
            name
            code
            comment
            helperText
            order
            stepValue(filter: $progressStepValuesFilter) {
              items {
                id
                status
                customStepName
                customStepComment
                isVisible
                bigfootTeamComment
                applicantComment
                updatedAt
              }
            }
          }
        }
        companySteps(filter: $progressCompanyStepFilter, sort: { createdAt: ASC }) {
          items {
            id
            name
            status
            code
            comment
            status
            isVisible
            bigfootTeamComment
            applicantComment
            updatedAt
          }
        }
      }
    }
  }
`;

export const PROGRESS_STEP_VALUE_CREATE_MUTATION = gql`
  mutation ProgressStepValueCreate($data: ProgressStepValueCreateInput!) {
    progressStepValueCreate(data: $data) {
      id
      status
    }
  }
`;

export const PROGRESS_COMPANY_STEP_CREATE_MUTATION = gql`
  mutation ProgressCompanyStepCreate($data: ProgressCompanyStepCreateInput!) {
    progressCompanyStepCreate(data: $data) {
      id
      name
      comment
      code
      status
    }
  }
`;

export const PROGRESS_STEP_VALUE_UPDATE_MUTATION = gql`
  mutation ProgressStepValueUpdate(
    $data: ProgressStepValueUpdateInput!
    $filter: ProgressStepValueKeyFilter!
  ) {
    progressStepValueUpdate(data: $data, filter: $filter) {
      id
      status
      customStepName
      customStepComment
      isVisible
      bigfootTeamComment
      applicantComment
    }
  }
`;

export const PROGRESS_COMPANY_STEP_UPDATE_MUTATION = gql`
  mutation ProgressCompanyStepUpdate(
    $data: ProgressCompanyStepUpdateInput!
    $filter: ProgressCompanyStepKeyFilter!
  ) {
    progressCompanyStepUpdate(data: $data, filter: $filter) {
      id
      status
      name
      comment
      isVisible
      bigfootTeamComment
      applicantComment
    }
  }
`;
