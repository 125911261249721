import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { MultiplierTable } from './';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
  },
}));

export const LoanMultipliers: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} lg={6}>
        <Typography gutterBottom variant="h6">
          SaaSLoan Revenue Multiple
        </Typography>
        <MultiplierTable isRevenue />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography gutterBottom variant="h6">
          SaaSLoan Repayment Multiple
        </Typography>
        <MultiplierTable isRevenue={false} />
      </Grid>
    </Grid>
  );
};
