import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Box, Grid, IconButton, Typography, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { MetricSourceLabel } from './MetricSourceLabel';
import { getFormattedMetricValue, isNegative } from 'src/utils';
import { Format } from 'src/constants';
import { MetricValue } from 'src/graphql';
import { Source } from 'src/types';

interface HeaderProps {
  cell: MetricValue | null;
  onClose: () => void;
  onDelete: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  lessThanZero: {
    color: '#eb4336',
  },
}));

export const Header: React.FC<HeaderProps> = ({ cell, onClose }): React.ReactElement => {
  const classes = useStyles();

  const value = R.pathOr(null, ['value'], cell);
  const format = R.pathOr(Format.Count, ['metric', 'format'], cell);
  const source = R.pathOr<Source | null>(null, ['source'], cell);

  return (
    <Grid container item spacing={2} justify="space-between">
      <Grid item>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1">
            Original value:
            <Typography
              component="span"
              variant="subtitle1"
              className={clsx({
                [classes.lessThanZero]: isNegative(value),
              })}
            >
              &nbsp;{!R.isNil(value) ? getFormattedMetricValue(value, format) : '–'}
            </Typography>
          </Typography>

          <MetricSourceLabel source={source} />
        </Box>
      </Grid>

      <Grid item>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  );
};
