import { gql } from '8base-react-sdk';

export const LOAN_APPLICATION_FRAGMENT = gql`
  fragment LoanApplicationFragment on LoanApplication {
    id
    createdAt
    updatedAt
    createdBy {
      id
      _description
    }
    company {
      id
      createdAt
      updatedAt
      createdBy {
        id
      }
      name
      active
      monitoringStatus
      primaryCustomerBase
      primaryBusinessModel
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      borrower
      borrowedDebt
      idealRaiseAmount
      owner {
        id
        firstName
        lastName
        title
        email
      }
      foundedYear
      companyStory
      founderStory
      productDescription
      marketDescription
      customersStrategy
      capital
      capitalType
      capitalStrategy
      equityCapitalAmount
      equityCapitalOwner
      monthsToNextRound
      securedDebt
      structure
      status
      contactTitle
      contactFirstName
      contactLastName
      contactEmail
      _description
    }
    dealTeam {
      items {
        id
        avatar {
          id
          downloadUrl
        }
        lastName
        firstName
      }
    }
    structure
    stage
    contactName
    applicantTitle
    firstDrawProposed
    targetCloseDate
    _description
  }
`;

export const LOAN_APPLICATIONS_METRICS_QUERY = gql`
  query LoanApplicationsMetrics(
    $accountCreatedFilter: LoanApplicationFilter
    $saasScoredFilter: LoanApplicationFilter
    $appCompletedFilter: LoanApplicationFilter
    $termSheetFilter: LoanApplicationFilter
    $structurePresentedFilter: LoanApplicationFilter
    $underwritingFilter: LoanApplicationFilter
    $closingFilter: LoanApplicationFilter
    $fundedFilter: LoanApplicationFilter
  ) {
    accountCreated: loanApplicationsList(filter: $accountCreatedFilter) {
      count
    }
    saaSScored: loanApplicationsList(filter: $saasScoredFilter) {
      count
    }
    appCompleted: loanApplicationsList(filter: $appCompletedFilter) {
      count
    }
    termSheet: loanApplicationsList(filter: $termSheetFilter) {
      count
    }
    structurePresented: loanApplicationsList(filter: $structurePresentedFilter) {
      count
    }
    underwriting: loanApplicationsList(filter: $underwritingFilter) {
      count
    }
    closing: loanApplicationsList(filter: $closingFilter) {
      count
    }
    funded: loanApplicationsList(filter: $fundedFilter) {
      count
    }
  }
`;

export const LOAN_APPLICATION_QUERY = gql`
  query LoanApplicationsEntity($id: ID!) {
    loanApplication(id: $id) {
      ...LoanApplicationFragment
    }
  }
  ${LOAN_APPLICATION_FRAGMENT}
`;

export const LOAN_APPLICATIONS_EDIT_MUTATION = gql`
  mutation LoanApplicationsUpdate(
    $data: LoanApplicationUpdateInput!
    $filter: LoanApplicationKeyFilter
  ) {
    loanApplicationUpdate(data: $data, filter: $filter) {
      ...LoanApplicationFragment
    }
  }
  ${LOAN_APPLICATION_FRAGMENT}
`;

export const LOAN_APPLICATIONS_DELETE_MUTATION = gql`
  mutation LoanApplicationsDelete($filter: LoanApplicationKeyFilter!, $force: Boolean) {
    loanApplicationDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const LOAN_APPLICATIONS_TABLE_QUERY = gql`
  query SortableLoanApplicationsList(
    $filter: SortableLoanApplicationFilter
    $orderBy: [SortableLoanApplicationOrderBy]
    $sort: [SortableLoanApplicationSort!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
  ) {
    tableData: sortableLoanApplicationsList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
      skip: $skip
    ) {
      items {
        id
        companyId
        createdAt
        age
        stage
        companyName
        ownerFirstName
        ownerLastName
        contactFirstName
        contactLastName
        ownerTitle
        amountRequested
        firstDrawProposed
        targetCloseDate
        committed
        saasScore
        status
      }
      count
    }
  }
`;

export const LOAN_APPLICATIONS_TABLE_USERS_QUERY = gql`
  query UsersList($filter: UserFilter) {
    tableData: usersList(filter: $filter) {
      items {
        firstName
        lastName
        loanApplications {
          id
        }
        avatar {
          downloadUrl
        }
      }
    }
  }
`;
