import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { useClientRoles } from 'src/app-builder/providers';

import { selectedRowsContext, selectedColumnsContext, toSortVariables } from 'src/providers';
import { usePagination, useSearch, useSort } from 'src/hooks';
import { ActionsProvider } from 'src/shared/team/ActionsProvider';
import { actionsContext } from 'src/shared/team/ActionsProvider';

import { Layout } from 'src/shared/team/Layout';
import { TeamTable } from 'src/shared/team/Table';
import {
  UserCompanyQuery,
  UsersTableContentQuery,
  USER_COMPANY_QUERY,
  USERS_TABLE_QUERY,
} from 'src/graphql';
import { getUserTableQueryVariables } from 'src/shared/team/getUserTableQueryVariables';
import { ToolbarContainer } from 'src/shared/table/ToolbarContainer';
import { PaginationContainer } from 'src/shared/table/PaginationContainer';
import EmptyScreenContainer from 'src/shared/team/EmptyScreenContainer';
import HeadContainer from 'src/shared/team/HeadContainer';
import { DIALOGS } from 'src/dialogs';
import { APP_ROLES } from 'src/types';

export const Main: React.FC = () => {
  const { data: userData } = useQuery<UserCompanyQuery>(USER_COMPANY_QUERY);
  const { currentRole } = useClientRoles();

  const pagination = usePagination();
  const search = useSearch();
  const sort = useSort();
  const selectedColumns = useContext(selectedColumnsContext);
  const { initRows } = useContext(selectedRowsContext);

  const companyId = userData?.user?.company?.id;
  const isOwner = currentRole?.name === APP_ROLES.appCustomerOwner.name;
  const doesCompanyHaveOwner = Boolean(userData?.user?.company?.owner);

  const variables = getUserTableQueryVariables({
    sort:
      sort?.field && sort?.order
        ? toSortVariables([{ field: sort.field, order: sort?.order }])
        : null,
    companyId,
    perPage: pagination.perPage,
    page: pagination.page,
    search: search?.search,
  });

  const { data, loading } = useQuery<UsersTableContentQuery>(USERS_TABLE_QUERY, {
    variables,
  });

  const count = data?.tableData?.count;

  const rows = useMemo(() => data?.tableData?.items || [], [data]);

  useEffect(() => {
    initRows(rows, 'id');
  }, [initRows, rows]);

  return (
    <ActionsProvider
      rows={rows}
      companyId={companyId}
      isClientPortal
      doesCompanyHaveOwner={doesCompanyHaveOwner}
      permissions={{
        canInvite: isOwner,
        canResendInvite: isOwner,
        canDelete: isOwner,
        canEdit: isOwner,
        canCancelInvite: isOwner,
      }}
    >
      <Layout
        isEmpty={!rows.length && !loading}
        loading={loading}
        toolbar={<ToolbarContainer dialog={DIALOGS.USER_DELETE_DIALOG} canDelete={isOwner} />}
        empty={<EmptyScreenContainer />}
        table={
          <TeamTable
            columnKeys={selectedColumns.keys}
            data={rows}
            actionsContext={actionsContext}
          />
        }
        head={<HeadContainer label="Users" />}
        pagination={<PaginationContainer count={count || 0} />}
      />
    </ActionsProvider>
  );
};
