import pluralize from 'pluralize';
import * as R from 'ramda';
import { SortableLoanApplication } from 'src/graphql';

export const AgeRenderer = (r: SortableLoanApplication): string => {
  if (R.isNil(r?.age)) {
    return '-';
  }

  return r.age === 0 ? 'New' : `${r.age} ${pluralize('day', r.age)}`;
};
