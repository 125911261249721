import * as yup from 'yup';
import { BUSINESS_LOCATION_VALUES, REG_EXP } from 'src/constants';

export const UserSelfSignUpSchema = yup
  .object({
    connection: yup
      .string()
      .nullable()
      .required(),
    email: yup
      .string()
      .email('Invalid Email')
      .required('Business Email is required'),
    password: yup
      .string()
      .nullable()
      .matches(
        REG_EXP.PASSWORD_VALIDATION,
        'Password must be at least 8 characters and contain at least one capital letter, one number and one special character',
      )
      .required('Password is required'),
    user_metadata: yup
      .object({
        first_name: yup
          .string()
          .nullable()
          .required('First Name is required'),
        last_name: yup
          .string()
          .nullable()
          .required('Last Name is required'),
        company_name: yup
          .string()
          .nullable()
          .required('Company Name is required'),
        business_location: yup
          .string()
          .oneOf(BUSINESS_LOCATION_VALUES)
          .required('Business Location is required'),
        primary_business_model: yup
          .string()
          .nullable()
          .required('Primary Customer Base is required'),
        primary_customer_base: yup
          .string()
          .nullable()
          .required('Primary Customer Base is required'),
        amount_of_capital_desired: yup
          .number()
          // Actually max amount equal to 2,147,483,647, but form more understandable view for customers we round down it to 2,000,000,000
          .max(
            2000000000,
            'Amount of Capital Desired should be less than or equal to $2,000,000,000',
          )
          .nullable()
          .required('Amount of Capital Desired is required'),
      })
      .nullable()
      .required(),
  })
  .nullable()
  .required();

export const UserInvitationSignUpSchema = yup
  .object({
    connection: yup
      .string()
      .nullable()
      .required(),
    email: yup
      .string()
      .email('Invalid Email')
      .required('Business Email is required'),
    password: yup
      .string()
      .nullable()
      .required('Password is required'),
    user_metadata: yup
      .object({
        invitation_id: yup
          .string()
          .nullable()
          .required(),
        first_name: yup
          .string()
          .nullable()
          .required('First Name is required'),
        last_name: yup
          .string()
          .nullable()
          .required('Last Name is required'),
      })
      .nullable()
      .required(),
  })
  .nullable()
  .required();

export const userAccountInformationSchema = yup.object({
  firstName: yup
    .string()
    .nullable()
    .required('First Name is required'),
  lastName: yup
    .string()
    .nullable()
    .required('Last Name is required'),
  title: yup
    .string()
    .nullable()
    .required('Title is required'),
});
