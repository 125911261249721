import React, { memo } from 'react';

import { TableExportType } from 'src/graphql';
import { useTableExport } from 'src/hooks';
import { CashMonitoringTableRow } from 'src/types';
import { Header } from './';

type HeaderContainerProps = {
  tableExportData: {
    columns: any;
    rows: Array<CashMonitoringTableRow>;
  };
};

const HeaderContainer = memo(({ tableExportData }: HeaderContainerProps) => {
  const [onExport, { loading: isExporting }] = useTableExport(
    TableExportType.CashMonitoring,
    tableExportData,
  );

  return <Header onExport={onExport} isExporting={isExporting} />;
});

HeaderContainer.displayName = 'HeaderContainer';

export { HeaderContainer };
