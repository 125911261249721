import React from 'react';
import { Box, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SaaSLoanOption } from 'src/graphql';
import { Locale } from 'src/constants';
import { TermType } from 'src/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'calc(100% / 3 - 20px)',
    flex: 1,
    '&:not(:last-child)': {
      marginRight: 20,
    },
  },
  chartGridItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  chartPaper: {
    padding: theme.spacing(2),
    height: theme.spacing(20),
  },
  cardHeader: {
    marginBottom: 20,
    minHeight: 64,
    width: 'calc(100% - 16px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 1.5,
  },
  container: {
    paddingBottom: 40,
  },
}));

const loanTermTypesToLabels = {
  [TermType.TermLoan]: 'Term Loan',
  [TermType.RBF]: 'RBF',
};

interface SaaSloanCardProps {
  option: SaaSLoanOption;
}

export const SaaSLoanCard: React.FC<SaaSloanCardProps> = ({ option }) => {
  const classes = useStyles();
  const { term, type } = option;

  return (
    <Box className={classes.root} component={Paper} textAlign="center">
      <Grid
        className={classes.container}
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item className={classes.cardHeader}>
          <Typography color="secondary" variant="subtitle1">
            {term} month {loanTermTypesToLabels[type as TermType]}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            ${option.capitalAvailable?.toLocaleString(Locale.EN)}
          </Typography>
          <Typography className={classes.label}>Capital Available</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">${option.totalRepayment?.toLocaleString(Locale.EN)}</Typography>
          <Typography className={classes.label}>Total Repayment</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">{option.repaymentMultiplier}x</Typography>
          <Typography className={classes.label}>Repayment Multiple</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">${option.monthlyPayment?.toLocaleString(Locale.EN)}</Typography>
          <Typography className={classes.label}>
            Estimated Average
            <br />
            Monthly Payment
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
