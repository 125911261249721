import React, { ChangeEvent, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { Filters } from './index';
import { convertToQueryParams } from 'src/utils';

interface FiltersContainerProps {
  companyId: string;
  selectedPeriod: number;
}

const FiltersContainer: React.FC<FiltersContainerProps> = memo(({ companyId, selectedPeriod }) => {
  const history = useHistory();

  const onChangeSelectedPeriod = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    _: React.ReactNode,
  ) => {
    history.replace({
      search: convertToQueryParams({ selectedPeriod: event.target.value }),
    });
  };

  return (
    <Filters
      companyId={companyId}
      selectedPeriod={selectedPeriod}
      onChangeSelectedPeriod={onChangeSelectedPeriod}
    />
  );
});

FiltersContainer.displayName = 'FiltersContainer';

export { FiltersContainer };
