import React, { FC, Fragment } from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NOTE_QUERY } from 'src/graphql';
import { DateFormatPatterns, formatDate } from 'src/utils/date';
import { Attachments } from './activeNote/Attachments';
import { Actions } from './activeNote/Actions';
import { getFullName } from 'src/utils';

const OFFSET_FROM_TOP_OF_THE_LAYOUT = '350px';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'auto',
    height: `calc(100vh - ${OFFSET_FROM_TOP_OF_THE_LAYOUT})`,
  },
  loader: {
    color: theme.palette.grey[400],
    marginBottom: 20,
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(100% - ${theme.spacing(8)}px)`,
  },
  title: {
    color: theme.palette.text.primary,
  },
  createdDate: {
    color: theme.palette.text.secondary,
    marginLeft: 5,
  },
  cardHeader: {
    padding: theme.spacing(4),
    paddingBottom: 0,
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  bodyText: {
    whiteSpace: 'pre-line',
  },
}));

interface ActiveNoteProps {
  id: string;
  path: string;
}

export const ActiveNote: FC<ActiveNoteProps> = props => {
  const { id, path } = props;

  const { data, loading } = useQuery(NOTE_QUERY, {
    variables: {
      id: id,
    },
    skip: !Boolean(id),
  });

  const classes = useStyles();

  if (loading) {
    return (
      <Box className={classes.loaderWrapper}>
        <CircularProgress className={classes.loader} />
        <Typography color="textSecondary" variant="body1" align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  const note = data?.note ?? {};
  const createdAt = formatDate(note?.createdAt, DateFormatPatterns.smallDateWithLocalTime);
  const attachments = note?.attachments?.items ?? [];

  const createdByFullName = getFullName(note?.createdBy);
  const createdByTitle = createdByFullName ? `by ${createdByFullName}` : '';

  return (
    <Card elevation={1} className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        action={<Actions path={path} note={note} />}
        title={<Typography variant="subtitle1">{note?.title}</Typography>}
        subheader={
          <Box display="flex" flexDirection="row">
            {!R.isEmpty(createdByTitle) && (
              <Typography variant="body2" className={classes.title}>
                {createdByTitle}
              </Typography>
            )}
            <Typography variant="body2" className={classes.createdDate}>
              {createdAt}
            </Typography>
          </Box>
        }
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="body1" component="p" className={classes.bodyText}>
              {note?.body}
            </Typography>
          </Grid>
          {!R.isEmpty(attachments) && (
            <Fragment>
              <Grid item sm={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item sm={12}>
                <Attachments attachments={attachments} />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
