import * as R from 'ramda';
import notificationMessages from 'src/constants/notificationMessages';
import { REG_EXP } from 'src/constants';

export const trimString = (value?: string | null): string | undefined | null =>
  !R.isNil(value) ? value.trim() : value;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function tryJSONParse(data: string, defaultData?: any): any {
  try {
    return JSON.parse(data);
  } catch (error) {
    return defaultData;
  }
}

export function templateParser(template: string, variables?: Record<string, string>): string {
  if (variables === undefined) {
    return template;
  }

  return template.replace(REG_EXP.TEMPLATE_PARSER_BRACKETS, (_, key: string) => {
    const value = variables[key];

    return value !== undefined ? value : '';
  });
}

export function t(key: string, options?: Record<string, string>): string {
  const message = notificationMessages[key];

  return templateParser(message, options);
}
