import React from 'react';
import {
  Box,
  TableCell,
  TableHead as MUITableHead,
  TableRow as MUITableRow,
} from '@material-ui/core';
import { ViewColumnOutlined } from '@material-ui/icons';
import { nanoid } from 'nanoid';

import { TableHeadProps } from '../interface';
import { getMaterialCellPropsFromCol } from '../utils';

function TableHead<TRecord>({ columns, hasRowActions }: TableHeadProps<TRecord>) {
  const renderTableTitles = (): React.ReactNode => {
    const titleCells = columns.map(col => {
      const cellProps = {
        ...getMaterialCellPropsFromCol<TRecord>(col),
        style: {
          width: col.width,
        },
      };

      return (
        <TableCell key={nanoid()} {...cellProps}>
          {col.title}
        </TableCell>
      );
    });

    // add actions col to the end
    if (hasRowActions) {
      const actionCell = (
        <TableCell key="rowActions" width={50}>
          <Box display="flex" justifyContent="center">
            <ViewColumnOutlined />
          </Box>
        </TableCell>
      );

      return [...titleCells, actionCell];
    }

    return titleCells;
  };

  return (
    <MUITableHead>
      <MUITableRow>{renderTableTitles()}</MUITableRow>
    </MUITableHead>
  );
}

export default TableHead;
