import csv from 'csvtojson';

class CSVReader {
  private readonly fileReader: FileReader;
  private readonly file: File;

  public constructor(file: File) {
    this.fileReader = new FileReader();
    this.file = file;
  }

  public getJSON(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.fileReader.readAsText(this.file);

      this.fileReader.onload = async (event: ProgressEvent<FileReader>) => {
        const csvString = event?.target?.result;
        const jsonData = await csv().fromString(csvString as string);

        resolve(jsonData);
      };

      this.fileReader.onerror = (event: ProgressEvent<FileReader>) => {
        console.error('Error read file');
        reject(event);
      };
    });
  }
}

export default CSVReader;
