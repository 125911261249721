import React from 'react';
import * as R from 'ramda';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { createTableRowUpdateTag, createTableRowQueryTag, TableSchema } from '@8base/utils';
import { gql, Form } from '8base-react-sdk';
import { SchemaNameGenerator } from '@8base/schema-name-generator';
import * as ChangeCase from 'change-case';
import { useSnackbar } from 'notistack';

import { useModal } from '../providers';
import { DIALOGS } from './constants';
import { EntityFormFields } from '../generic';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),

    '& .MuiFormControl-root:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface TableRowEditDialogProps {
  table: TableSchema;
}

export const TableRowEditDialog: React.FC<TableRowEditDialogProps> = ({ table }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { open, args, closeModal } = useModal(DIALOGS.TABLE_ROW_EDIT_DIALOG);

  const rowQuery = React.useMemo(
    () => gql`
      ${createTableRowQueryTag([table], table.id, { withMeta: true })}
    `,
    [table],
  );

  const updateMutation = React.useMemo(
    () => gql`
      ${createTableRowUpdateTag([table], table.id, { withMeta: true })}
    `,
    [table],
  );

  const { data, loading } = useQuery(rowQuery, {
    variables: {
      id: args?.id,
    },
    skip: !open || !Boolean(args?.id),
  });

  const initialValues: any = React.useMemo(
    () =>
      loading ? {} : R.propOr({}, SchemaNameGenerator.getTableItemFieldName(table.name), data),
    [data, loading, table],
  );

  const [updateRow] = useMutation(updateMutation);

  const onSubmit = React.useCallback(
    async (data, form) => {
      await updateRow({ variables: { data: { ...data, id: args?.id } } });

      enqueueSnackbar(`${pluralize(table.displayName, 1)} successfully updated`, {
        variant: 'success',
      });

      closeModal();

      setTimeout(form.reset);
    },
    [updateRow, args, enqueueSnackbar, table, closeModal],
  );

  const onClose = React.useCallback(
    form => {
      closeModal();

      setTimeout(form.reset);
    },
    [closeModal],
  );

  if (!open || loading) {
    return null;
  }

  const dialogName = ChangeCase.camelCase('edit ' + pluralize(table.displayName, 1));

  return (
    <Form
      tableSchemaName={table.name}
      type="UPDATE"
      onSubmit={onSubmit}
      initialValues={initialValues as any}
      formatRelationToIds
    >
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={onClose.bind(null, form)}
          PaperProps={{ className: classes.root, component: 'form' as any, onSubmit: handleSubmit }}
          maxWidth="md"
          data-e2e-id={`dialogs.${dialogName}`}
        >
          <DialogTitle>Edit {pluralize(table.displayName, 1)}</DialogTitle>

          <DialogContent>
            <EntityFormFields table={table} />
          </DialogContent>

          <DialogActions>
            <Button
              color="secondary"
              onClick={onClose.bind(null, form)}
              disabled={submitting}
              data-e2e-id={`dialogs.${dialogName}.cancelBtn`}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={submitting || pristine}
              data-e2e-id={`dialogs.${dialogName}.submitBtn`}
            >
              Edit {pluralize(table.displayName, 1)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
