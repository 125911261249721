import React, { useContext } from 'react';
import { AvatarGroup } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';

import { getFullName, getNameInitials } from 'src/utils';
import { actionsContext } from './ActionsProvider';

interface AvatarRendererProps {
  id?: string | null;
}

export const AvatarRenderer: React.FC<AvatarRendererProps> = ({ id }) => {
  const { users } = useContext(actionsContext);
  if (!users || !id) return null;

  return (
    <AvatarGroup>
      {(users[id] || []).map((user: any) => (
        <Avatar key={user.id} alt={getFullName(user)} src={user?.avatar?.downloadUrl}>
          {getNameInitials(user)}
        </Avatar>
      ))}
    </AvatarGroup>
  );
};
