import React from 'react';
import { Button, Grid, InputAdornment, LinearProgress, Theme, Typography } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { Permission } from 'src/constants';

import { useAllowed } from 'src/app-builder/providers';
import { useLoanApplication, useNotification } from 'src/hooks';
import { Form, Field, DateField, NumberField, FormSpy, useDataLoss } from 'src/app-builder';
import { SubmitButton } from 'src/components';
import { LOAN_APPLICATIONS_EDIT_MUTATION } from 'src/graphql';
import { baseNumberFormat, DateFormatPatterns, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
}));

export const LoanApplicationInformationPage: React.FC<any> = ({ tableName }) => {
  const classes = useStyles();

  const { setWhen } = useDataLoss();

  const notification = useNotification();

  const isAllowed = useAllowed();

  const canChangeInfo = isAllowed(Permission.LoanApplicationsEditInfo);

  const { data: loanApplication, loading } = useLoanApplication();

  const initialValues = React.useMemo(() => (loading ? {} : loanApplication || {}), [
    loanApplication,
    loading,
  ]);

  const [updateLoanApplication] = useMutation(LOAN_APPLICATIONS_EDIT_MUTATION, {
    onCompleted: () => notification.success(t('loan_application_update_success')),
    onError: () => notification.error(t('loan_application_update_error')),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      await updateLoanApplication({ variables: { data } });

      setTimeout(form.reset);
    },
    [updateLoanApplication],
  );

  const onCancel = React.useCallback(form => {
    setTimeout(form.reset);
  }, []);

  return (
    <Grid container direction="column">
      <Grid className={classes.header} item alignItems="center">
        <Typography variant="subtitle1">Loan Information</Typography>
      </Grid>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column">
          <Form
            tableSchemaName="LoanApplications"
            type="UPDATE"
            onSubmit={onSubmit}
            initialValues={initialValues as any}
            formatRelationToIds
          >
            {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
              <form onSubmit={handleSubmit}>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="firstDrawProposed"
                      label="1st Draw Proposed"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      fullWidth
                      disabled={submitting || !canChangeInfo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="targetCloseDate"
                      label="Target Close Date"
                      component={DateField}
                      dateFormat={DateFormatPatterns.shortDateWithSlash}
                      fullWidth
                      disabled={submitting || !canChangeInfo}
                    />
                  </Grid>

                  <Grid justify="flex-end" container spacing={2}>
                    <Grid item>
                      <Button
                        color="secondary"
                        onClick={onCancel.bind(null, form)}
                        disabled={submitting}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <SubmitButton
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={pristine}
                        loading={submitting}
                      >
                        Save Changes
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </Grid>

                <FormSpy
                  subscription={{ pristine: true }}
                  onChange={({ pristine }) => {
                    setWhen(!pristine);
                  }}
                />
              </form>
            )}
          </Form>
        </Grid>
      )}
    </Grid>
  );
};
