import React from 'react';

import { DateFormatPatterns, formatDate } from 'src/utils';

interface DateRendererProps {
  date: string;
}

export const MetricCellDate: React.FC<DateRendererProps> = ({ date }) => {
  return (
    <div style={{ padding: '0 16px', textAlign: 'right' }}>
      {formatDate(
        date,
        DateFormatPatterns.shortDateWithSlash,
        DateFormatPatterns.shortDateWithDash,
      )}
    </div>
  );
};
