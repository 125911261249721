import React from 'react';
import { Typography, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { SubmitButton, UploadButton } from 'src/components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  button: {
    padding: theme.spacing(1),

    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

interface UnderwritingReportHeaderProps {
  onUpload: (file: File) => Promise<void>;
  isUploading: boolean;
  onExport: () => Promise<void>;
  isExporting: boolean;
  isDataLoading: boolean;
}

export const UnderwritingReportHeader: React.FC<UnderwritingReportHeaderProps> = ({
  onUpload,
  isUploading,
  onExport,
  isExporting,
  isDataLoading,
}) => {
  const classes = useStyles();

  const onSelectFile = (file: File | null) => {
    if (!file) {
      return;
    }

    onUpload(file);
  };

  return (
    <Grid className={classes.root} item>
      <Typography variant="subtitle1">Underwriting Package</Typography>

      <Grid item className={classes.right}>
        <Grid item className={classes.button}>
          <UploadButton
            color="secondary"
            variant="outlined"
            size="large"
            onSelectFile={onSelectFile}
            disabled={isUploading}
            loading={isUploading}
          >
            Upload CSV
          </UploadButton>
        </Grid>

        <Grid item className={classes.button}>
          <SubmitButton
            onClick={onExport}
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<GetApp />}
            loading={isExporting}
            disabled={isDataLoading}
          >
            Export
          </SubmitButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
