import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useParams, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Box,
  Button,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Typography,
  IconButton,
  MenuItem,
  Checkbox,
  TablePagination,
  Theme,
  ListItemIcon,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';
import {
  ModalsContext,
  useAllowed,
  useSelectedRows,
  usePagination,
  useSort,
  toSortVariables,
  useSearch,
  useSelectedColumns,
  EntitiesTableCellValue,
  EntitiesTableActionsPopover,
  EntitiesTableToolbar,
} from 'src/app-builder';

import { useLoanApplication, useNotification } from 'src/hooks';
import { TableHeader, EmptyScreen } from 'src/components';
import {
  USERS_TABLE_QUERY,
  INVITATION_RESEND_MUTATION,
  INVITATION_CANCEL_MUTATION,
  Role,
} from 'src/graphql';
import {
  TeamInviteDialog,
  DIALOGS,
  ConfirmationDialog,
  UserDeleteDialog,
  TeamEditDialog,
} from 'src/dialogs';
import { Permission, ACTIONS_TITLE, AREAS } from 'src/constants';
import { getFullName } from 'src/utils/selectors';
import { AppRoleName, APP_ROLES, APP_ROLE_NAMES_LIST } from 'src/types';
import { bracketNotationToPath, commonErrorResolver, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 101,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
  stickyLeft: {
    position: 'sticky',
    left: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      height: 1,
      background: theme.customPalette.border.table,
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
    },
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  stickyRight: {
    width: 80,
    position: 'sticky',
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:before': {
      position: 'absolute',
      height: 1,
      background: theme.customPalette.border.table,
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
    },
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
}));

export const LoanApplicationTeamPage: React.FC<any> = () => {
  const { openModal } = React.useContext(ModalsContext);

  const { id: loanApplicationId }: { id: string } = useParams();
  const history = useHistory();

  const totalRows = React.useRef<number>(0);
  const { page, perPage, handlePageChange, handlePerPageChange, perPageOptions } = usePagination({
    initialCount: totalRows.current,
  });

  const { sort, createOnSort } = useSort();

  const { search, handleSearchChange } = useSearch();

  const notification = useNotification();

  const isAllowed = useAllowed();

  const [canInvite, canDelete, canEdit, canResendInvite, canCancelInvite] = [
    isAllowed(Permission.TeamInvite),
    isAllowed(Permission.TeamDelete),
    isAllowed(Permission.TeammateEdit),
    isAllowed(Permission.TeamResendInvite),
    isAllowed(Permission.TeamCancelInvite),
  ];

  const {
    selectedColumns,
    createHandleToggleColumn,
    moveColumn,
    showAll,
    hideAll,
    restoreDefaults,
  } = useSelectedColumns({
    areaCode: AREAS.TEAM,
  });

  const { data: loanApplication, loading: loanApplicationLoading } = useLoanApplication();

  const companyId = loanApplication?.company?.id as string;

  const currentCompanyHasOwner = Boolean(loanApplication?.company?.owner);

  const variables = React.useMemo(
    () => ({
      skip: page * perPage,
      first: perPage,
      sort: toSortVariables(sort),
      filter: {
        AND: [
          {
            _fullText: search,
          },
          {
            OR: [
              {
                company: {
                  loanApplication: {
                    id: {
                      equals: loanApplicationId,
                    },
                  },
                },
              },
              {
                employer: {
                  loanApplication: {
                    id: {
                      equals: loanApplicationId,
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    }),
    [page, perPage, sort, search, loanApplicationId],
  );

  const { data, loading } = useQuery(USERS_TABLE_QUERY, {
    variables,
  });

  const [invitationResend] = useMutation(INVITATION_RESEND_MUTATION, {
    refetchQueries: ['LoanApplicationsEntity'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('invitation_resend_success')),
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const [invitationCancel] = useMutation(INVITATION_CANCEL_MUTATION, {
    refetchQueries: ['LoanApplicationsEntity', 'UsersTableContent'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('invitation_cancel_success')),
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const classes = useStyles({ loading });

  const [actions, setActions] = React.useState<{
    el: HTMLElement | null;
    id: string | null;
  }>({ el: null, id: null });

  const openActions = React.useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setActions({ el: event.currentTarget, id });
    },
    [setActions],
  );

  const closeActions = React.useCallback(() => {
    setActions({ el: null, id: null });
  }, [setActions]);

  const openTeamInviteDialog = React.useCallback(() => {
    openModal(DIALOGS.TEAM_INVITE_DIALOG, {
      companyId,
      currentCompanyHasOwner,
      onSuccess: () => {
        history.push(`/loan-applications/${loanApplicationId}/team`);
      },
    });

    closeActions();
  }, [openModal, closeActions, companyId, history, loanApplicationId, currentCompanyHasOwner]);

  const openTeamEditDialog = React.useCallback(
    id => {
      openModal(DIALOGS.TEAM_EDIT_DIALOG, {
        companyId,
        userId: id,
        onSuccess: () => {
          history.push(`/loan-applications/${loanApplicationId}/team`);

          notification.success(t('teammate_update_success'));
        },
      });

      closeActions();
    },
    [closeActions, companyId, notification, history, openModal, loanApplicationId],
  );

  const resendInvitation = React.useCallback(
    async (id: string): Promise<void> => {
      await invitationResend({ variables: { id } });

      closeActions();
    },
    [invitationResend, closeActions],
  );

  const cancelInvitation = React.useCallback(
    async (id: string): Promise<void> => {
      closeActions();

      openModal(DIALOGS.CONFIRMATION_DIALOG, {
        message: 'Are you sure want to cancel invitation?',
        onConfirm: async () => {
          await invitationCancel({ variables: { id } });
        },
      });
    },
    [openModal, invitationCancel, closeActions],
  );

  const openDeleteRowDialog = React.useCallback(
    (args?: any) => {
      openModal(DIALOGS.USER_DELETE_DIALOG, args || { id: actions.id });

      closeActions();
    },
    [openModal, closeActions, actions],
  );

  const rows = React.useMemo(() => data?.tableData?.items || [], [data]);
  const count = data?.tableData?.count;
  totalRows.current = count;

  const { selectedRows, handleSelectAll, handleSelectOne, setSelectedRows } = useSelectedRows(rows);

  const areTeammatesEmpty = !loading && !loanApplicationLoading && R.isEmpty(rows) && !search;
  const areNotSearchedTeammates = !loading && !loanApplicationLoading && R.isEmpty(rows) && search;

  const entitiesTableToolbarProps = React.useMemo(() => {
    const deleteButtonProps = {
      openDeleteRowDialog,
      setSelectedRows,
    };

    const selectColumnsButtonProps = {
      areaCode: AREAS.TEAM,
      selectedColumns,
      createHandleToggleColumn,
      moveColumn,
      showAll,
      hideAll,
      restoreDefaults,
    };

    const props = {
      selectedRows,
      deleteButtonProps,
      selectColumnsButtonProps,
    };

    return canDelete ? { ...props } : null;
  }, [
    canDelete,
    openDeleteRowDialog,
    setSelectedRows,
    selectedRows,
    selectedColumns,
    hideAll,
    createHandleToggleColumn,
    moveColumn,
    showAll,
    restoreDefaults,
  ]);

  const createActions = React.useCallback(
    (closeActions: () => void, id: string): React.ReactNode => {
      const row = rows.find((row: any) => row?.id === id);

      if (row.status !== 'invitationPending') {
        return [
          <MenuItem disabled={!canEdit} key="delete" onClick={() => openTeamEditDialog(id)}>
            <ListItemIcon>
              <icons.Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem disabled={!canDelete} key="delete" onClick={() => openDeleteRowDialog()}>
            <ListItemIcon>
              <icons.Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,
        ];
      }

      return [
        <MenuItem
          disabled={!canResendInvite}
          key="resend"
          onClick={() => resendInvitation(row?.invitation?.id)}
        >
          <ListItemIcon>
            <icons.Send />
          </ListItemIcon>
          Resend Invitation
        </MenuItem>,
        <MenuItem
          disabled={!canCancelInvite}
          key="cancel"
          onClick={() => cancelInvitation(row?.invitation?.id)}
        >
          <ListItemIcon>
            <icons.Cancel />
          </ListItemIcon>
          Cancel Invitation
        </MenuItem>,
      ];
    },
    [
      rows,
      canResendInvite,
      canCancelInvite,
      canEdit,
      canDelete,
      openTeamEditDialog,
      openDeleteRowDialog,
      resendInvitation,
      cancelInvitation,
    ],
  );

  const emptyScreenProps = React.useMemo(() => {
    const actionText = 'Invite Teammate';
    const onClick = openTeamInviteDialog;

    return canInvite ? { actionText, onClick } : null;
  }, [canInvite, openTeamInviteDialog]);

  const emptyScreen = React.useMemo(
    (): React.ReactNode => (
      <Box margin={2}>
        <EmptyScreen
          text="We couldn't find any teammates"
          icon={icons.Group}
          {...emptyScreenProps}
        />
      </Box>
    ),
    [emptyScreenProps],
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Team</Typography>

        {!R.isEmpty(rows) && (
          <Button
            disabled={!canInvite}
            color="secondary"
            variant="outlined"
            onClick={openTeamInviteDialog}
          >
            Invite Teammate
          </Button>
        )}
      </Grid>

      {(loading || loanApplicationLoading) && <LinearProgress />}

      {!loanApplicationLoading && (
        <Grid className={classes.content} item container direction="column">
          <Grid item>
            {!areTeammatesEmpty && (
              <EntitiesTableToolbar
                searchInputProps={{ search, handleSearchChange }}
                {...entitiesTableToolbarProps}
              />
            )}
          </Grid>
          <Grid item container direction="column">
            {areTeammatesEmpty ? (
              emptyScreen
            ) : (
              <React.Fragment>
                {areNotSearchedTeammates ? (
                  emptyScreen
                ) : (
                  <React.Fragment>
                    <Grid className={classes.tableWrapper} item>
                      <Table>
                        <TableHeader
                          selectedColumns={selectedColumns}
                          actionsProps={{
                            title: ACTIONS_TITLE,
                          }}
                          sortProps={{ sort, createOnSort }}
                          checkboxProps={
                            canDelete
                              ? {
                                  checked: selectedRows.length === rows.length,
                                  indeterminate:
                                    selectedRows.length > 0 && selectedRows.length < rows.length,
                                  onChange: handleSelectAll,
                                }
                              : undefined
                          }
                        />

                        <TableBody>
                          {rows.map((item: any) => (
                            <TableRow hover key={item.id}>
                              {canDelete && (
                                <TableCell padding="checkbox" className={classes.stickyLeft}>
                                  <Checkbox
                                    checked={selectedRows.indexOf(item.id) !== -1}
                                    color="primary"
                                    onChange={(): void => handleSelectOne(item.id)}
                                    value="true"
                                  />
                                </TableCell>
                              )}

                              {selectedColumns.map(({ areaField, tableField, selected }) => {
                                if (!selected) {
                                  return null;
                                }

                                const areaFieldPath = bracketNotationToPath(areaField.fieldName);

                                if (areaField.name === 'Name') {
                                  return (
                                    <TableCell key={areaField.id}>{getFullName(item)}</TableCell>
                                  );
                                }

                                if (areaField.fieldName === 'roles') {
                                  const userRoles = item.roles.items || [];

                                  const appRoles = userRoles.filter(({ name }: Role) =>
                                    APP_ROLE_NAMES_LIST.some(appRoleName => name === appRoleName),
                                  ) as Array<Role & { name: AppRoleName }>;

                                  const roleTitles = appRoles
                                    .map(({ name }) => APP_ROLES[name].title)
                                    .join(', ');

                                  return <TableCell key={areaField.id}>{roleTitles}</TableCell>;
                                }

                                return (
                                  <TableCell key={areaField.id}>
                                    <EntitiesTableCellValue
                                      record={item}
                                      field={tableField}
                                      areaField={areaField}
                                      value={R.path(areaFieldPath, item)}
                                    />
                                  </TableCell>
                                );
                              })}

                              <TableCell className={classes.stickyRight}>
                                <IconButton onClick={openActions(item.id)}>
                                  <icons.MoreVert />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>

                        <EntitiesTableActionsPopover
                          anchorEl={actions.el}
                          open={Boolean(actions.el)}
                          onClose={closeActions}
                          activeId={actions.id}
                          createActions={createActions}
                        />
                      </Table>
                    </Grid>
                    {loading || (
                      <Grid item>
                        <TablePagination
                          component="div"
                          count={count}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerPageChange}
                          page={page}
                          rowsPerPage={perPage}
                          rowsPerPageOptions={perPageOptions}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      )}

      <TeamInviteDialog />
      <TeamEditDialog />
      <ConfirmationDialog />
      <UserDeleteDialog />
    </Grid>
  );
};
