import React, { createContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { set } from 'lodash';

import { SaaSScoreMetricSetting } from 'src/types';

interface MetricSettingsContextValue {
  settings: StructuredSaaSScoreMetricSettings;
  update: (code: string) => (path: string[]) => (value: number | string | number[]) => void;
  resetMetricSettingsTable: () => void;
}

interface ValuesProviderProps {
  children: React.ReactNode;
  initialData: SaaSScoreMetricSetting[];
}

export const metricSettingsContext = createContext<MetricSettingsContextValue>(
  {} as MetricSettingsContextValue,
);

export type StructuredSaaSScoreMetricSettings = Record<string, SaaSScoreMetricSetting>;

const structureData = (data: SaaSScoreMetricSetting[]): StructuredSaaSScoreMetricSettings =>
  data.reduce(
    (acc: StructuredSaaSScoreMetricSettings, item: SaaSScoreMetricSetting) => ({
      ...acc,
      [item.code as string]: {
        saaSScoreWeight: item.saaSScoreWeight,
        saaSScoreWeightEnterprise: item.saaSScoreWeightEnterprise,
        tierBands: JSON.parse(item.tierBands),
      },
    }),
    {},
  );

export const MetricSettingsProvider: React.FC<ValuesProviderProps> = ({
  children,
  initialData,
}) => {
  const [initialSettings, setInitialSettings] = useState<StructuredSaaSScoreMetricSettings>({});
  const [settings, setSettings] = useState<StructuredSaaSScoreMetricSettings>({});

  const update = (code: string) => (path: string[]) => (value: number | string | number[]) => {
    const newSettings = R.clone(settings) as StructuredSaaSScoreMetricSettings;
    set(newSettings, [code, ...path], value);

    setSettings(newSettings);
  };

  const resetMetricSettingsTable = () => setSettings(R.clone(initialSettings));

  useEffect(() => {
    const structuredData = structureData(initialData);

    setSettings(structuredData);
    setInitialSettings(structuredData);
  }, [initialData]);

  return (
    <metricSettingsContext.Provider
      value={{
        settings,
        update,
        resetMetricSettingsTable,
      }}
    >
      {children}
    </metricSettingsContext.Provider>
  );
};
