import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { SubmitButton } from 'src/components';

interface FooterProps {
  pristine: boolean;
  submitting: boolean;
  onCancel: () => void;
}

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { pristine, submitting, onCancel } = props;

  return (
    <Grid container item spacing={2} justify="flex-end">
      <Grid item>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          disabled={pristine}
          loading={submitting}
        >
          Save
        </SubmitButton>
      </Grid>
    </Grid>
  );
};
