import * as React from 'react';
import { Grid, Typography, Paper, LinearProgress, Button, makeStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { actionsContext } from './ActionsProvider';
import { SubmitButton } from 'src/components';

interface LayoutProps {
  onExport: () => Promise<void>;
  isExporting: boolean;
  metricsLoading: boolean;
  tableLoading: boolean;
  isOnlyActiveCompaniesCount: boolean;
  toggleActiveFilterAndRefetch: () => void;
  table: React.ReactNode;
  metrics: React.ReactNode;
  toolbar: React.ReactNode;
  pagination: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
  },
  right: {
    marginLeft: 'auto',
  },
  main: {
    width: '100%',
  },
  title: {
    marginBottom: 32,
  },
  tableWrapper: ({ loading }: { loading: boolean }) => ({
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
}));

export const Layout: React.FC<LayoutProps> = ({
  onExport,
  isExporting,
  metricsLoading,
  tableLoading,
  table,
  toolbar,
  metrics,
  pagination,
  isOnlyActiveCompaniesCount,
  toggleActiveFilterAndRefetch,
}) => {
  const classes = useStyles({ loading: tableLoading });
  const { openApplicantInviteDialog } = React.useContext(actionsContext);

  if (metricsLoading) return <LinearProgress />;

  return (
    <Grid className={classes.main}>
      <Grid
        className={classes.root}
        container
        justify="space-between"
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h4">Loan Applications</Typography>
        </Grid>

        <Grid item className={classes.right}>
          <SubmitButton
            onClick={toggleActiveFilterAndRefetch}
            color="secondary"
            variant={isOnlyActiveCompaniesCount ? 'contained' : 'outlined'}
            size="large"
            loading={metricsLoading}
          >
            Active Companies
          </SubmitButton>
        </Grid>
        <Grid item>
          <SubmitButton
            onClick={onExport}
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<GetApp />}
            loading={isExporting}
          >
            Export
          </SubmitButton>
        </Grid>

        <Grid item>
          <Button
            disabled={false}
            color="secondary"
            variant="contained"
            size="large"
            onClick={openApplicantInviteDialog}
          >
            Invite Applicant
          </Button>
        </Grid>
      </Grid>
      <Grid>
        {metrics}
        <Paper>
          {toolbar}
          <div className={classes.tableWrapper}>{table}</div>
          {pagination}
        </Paper>
      </Grid>
    </Grid>
  );
};
