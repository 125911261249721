import React from 'react';
import { useModal } from 'src/app-builder';
import { Dialog, DialogTitle, Grid, IconButton } from '@material-ui/core';
import * as icons from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { DIALOGS } from 'src/dialogs/constants';
import { isFunction } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden',
      height: 700,
    },
  },
}));

interface CalendlyWidgetDialogProps {}

export const CalendlyWidgetDialog: React.FC<CalendlyWidgetDialogProps> = () => {
  const { open, closeModal, args } = useModal(DIALOGS.CALENDLY_WIDGET_DIALOG);
  const classes = useStyles();

  React.useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('id', 'calendlyWidget');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    if (open) {
      head?.appendChild(script);
    }
    script?.parentNode?.removeChild(script);
  }, [open]);

  const onClose = React.useCallback(() => {
    if (isFunction(args?.onCancel)) {
      args.onCancel();
    }

    closeModal();
  }, [args, closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" className={classes.dialog}>
      <DialogTitle>Let&apos;s talk</DialogTitle>
      {Boolean(onClose) && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <icons.Close />
        </IconButton>
      )}
      <Grid alignContent="center" alignItems="center" justify="center">
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/bigfoot/intro-call-20-minutes-get-started"
          style={{ minWidth: 320, width: 1000, height: 600 }}
        />
      </Grid>
    </Dialog>
  );
};
