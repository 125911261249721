import React from 'react';
import { Typography, Button, Box } from '@material-ui/core';

interface HeadProps {
  label: string;
  onClick: () => void;
  canInvite: boolean;
}

export const Head: React.FC<HeadProps> = ({ onClick, canInvite, label }) => {
  return (
    <Box
      style={{ width: '100%' }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Button disabled={!canInvite} color="secondary" variant="outlined" onClick={onClick}>
        Invite Teammate
      </Button>
    </Box>
  );
};
