import React from 'react';

import { Badge } from '@material-ui/core';
import { MoveToInbox } from '@material-ui/icons';

interface InboxMessagesIconProps {
  messagesCount: number;
}

export const InboxMessagesIcon: React.FC<InboxMessagesIconProps> = ({ messagesCount }) => {
  if (messagesCount > 0) {
    return (
      <Badge badgeContent={messagesCount} color="error">
        <MoveToInbox />
      </Badge>
    );
  }

  return <MoveToInbox />;
};
