import React from 'react';

import { useLoanApplication, useSaaSLoanOptions } from 'src/hooks';
import { SaaSLoanCard } from 'src/components';
import { SaaSLoanPageLayout } from './components/SaaSLoanPageLayout';

export const LoanApplicationSaaSLoanPage: React.FC = () => {
  const { data: loanApplication, loading: loanLoading } = useLoanApplication();

  const companyId = loanApplication?.company?.id as string;

  const [
    calculateSaaSLoanOptions,
    { data: optionsData, loading: optionsLoading },
  ] = useSaaSLoanOptions(companyId);

  React.useEffect(() => {
    if (companyId) {
      calculateSaaSLoanOptions();
    }
  }, [calculateSaaSLoanOptions, companyId]);

  if (!companyId) return null;

  const isLoading = loanLoading || optionsLoading;

  return (
    <SaaSLoanPageLayout loading={isLoading}>
      {optionsData.map(option => (
        <SaaSLoanCard key={option.term} option={option} />
      ))}
    </SaaSLoanPageLayout>
  );
};
