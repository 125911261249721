import React from 'react';

import {
  SelectedRowsProvider,
  SortProvider,
  SearchProvider,
  PaginationProvider,
  SelectedColumnsProvider,
} from 'src/providers';

import { Main } from './components';
import { ALL_LOAN_APPLICATION_COLUMN_KEYS } from 'src/constants';

export const LoanApplicationsTablePage: React.FC = () => {
  return (
    <SelectedRowsProvider>
      <SearchProvider>
        <SortProvider>
          <PaginationProvider>
            <SelectedColumnsProvider
              allKeys={ALL_LOAN_APPLICATION_COLUMN_KEYS}
              initialOrder={ALL_LOAN_APPLICATION_COLUMN_KEYS}
            >
              <Main />
            </SelectedColumnsProvider>
          </PaginationProvider>
        </SortProvider>
      </SearchProvider>
    </SelectedRowsProvider>
  );
};
