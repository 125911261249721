import React from 'react';
import * as R from 'ramda';
import { tryJSONParse } from 'src/utils/parsers';
import { isFunction, isString } from 'src/utils';

export function useLocalStorage<TValue>(
  key: string,
  initialValue: TValue,
): [TValue, (newValue: TValue) => void] {
  const [state, setState] = React.useState<TValue>(() => {
    const value = localStorage.getItem(key);
    return R.isNil(value) ? initialValue : tryJSONParse(value, initialValue);
  });

  const setValue = React.useCallback(
    (value: any): void => {
      try {
        const resultValue = isFunction(value) ? value(state) : value;

        setState(resultValue);

        const strValue = isString(resultValue) ? resultValue : JSON.stringify(resultValue);
        window.localStorage.setItem(key, strValue);
      } catch (error) {
        console.error(error);
      }
    },
    [key, state],
  );

  return [state, setValue];
}
