import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { OutlinedInputProps, TextField } from '@material-ui/core';
import { FieldSchema } from '@8base/utils';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';

interface NumberFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  fieldSchema: FieldSchema;
  className?: string;
  InputProps?: Partial<OutlinedInputProps>;
  /** Documentation: https://github.com/s-yadav/react-number-format/blob/master/README.md#props */
  numberFormat?: NumberFormatProps;
}

interface NumberFormatFieldProps {
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  numberFormat?: NumberFormatProps;
}

function NumberFormatField(props: NumberFormatFieldProps): React.ReactNode {
  const { name, inputRef, onChange, numberFormat, ...other } = props;

  const handleChange = React.useCallback(
    values => {
      onChange({
        target: {
          name,
          value: values.value,
        },
      });
    },
    [name, onChange],
  );

  return (
    <NumberFormat
      {...other}
      {...numberFormat}
      getInputRef={inputRef}
      onValueChange={handleChange}
    />
  );
}

export const NumberField: React.FC<NumberFieldProps> = ({
  input,
  InputProps,
  className,
  numberFormat,
  meta,
  ...rest
}) => {
  const { name, value, onBlur, onChange } = input;

  const handleChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  return (
    <TextField
      name={name}
      value={value}
      error={error}
      className={className}
      helperText={helperText}
      onChange={handleChange}
      onBlur={onBlur}
      variant="outlined"
      InputProps={{
        ...InputProps,
        inputComponent: NumberFormatField as any,
        inputProps: {
          ...InputProps?.inputProps,
          ...numberFormat,
        } as any,
      }}
      {...rest}
    />
  );
};
