export const formatMoney = (money?: number | undefined | null): string | null =>
  Number.isFinite(money) ? `$${money?.toLocaleString()}` : null;

export const formatPercents = (percents?: number | undefined | null): string | null =>
  Number.isFinite(percents) ? `${percents}%` : null;

export const formatMultiplier = (multiplier?: number | undefined | null): string | null =>
  Number.isFinite(multiplier) ? `${multiplier}x` : null;

export const formatWholeNumber = (value?: number | null): number | null =>
  Number.isFinite(value) ? Math.floor(value as number) : null;

export const baseNumberFormat = {
  thousandSeparator: true,
};

export const moneyFormat = {
  prefix: '$',
  thousandSeparator: true,
};

export const percentFormat = {
  suffix: '%',
  thousandSeparator: true,
};

export const yearFormat = {
  format: '####',
};

export const countFormat = {
  thousandSeparator: true,
};

export const ratioFormat = {
  thousandSeparator: true,
  suffix: 'x',
};

export const monthFormat = {
  thousandSeparator: true,
  suffix: ' months',
};

export const getWorkspaceInfo = (
  endpoint: string,
): {
  id: string;
  env?: string;
} => {
  const [id, env] = (endpoint.match(/[^/]+$/) || [''])[0].split('_');

  return {
    id,
    env,
  };
};
