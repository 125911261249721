import { UserFilter } from 'src/graphql';

export const providedByString = (createdBy: UserFilter | null): string => {
  if (!Boolean(createdBy)) {
    return 'Bigfoot team';
  }

  const firstName = createdBy?.firstName;
  const lastName = createdBy?.lastName;

  if (!Boolean(firstName) && !Boolean(lastName)) {
    return '-';
  }

  if (!Boolean(lastName) && Boolean(firstName)) {
    return firstName as string;
  }

  if (!Boolean(firstName) && Boolean(lastName)) {
    return lastName as string;
  }

  return `${firstName} ${lastName}`;
};
