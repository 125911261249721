import { PORTFOLIO_OVERVIEW_METRICS, PortfolioOverviewMetricKey } from 'src/constants';
import { UnionTypesOfArray } from 'src/types';

const PORTFOLIO_OVERVIEW_METRICS_PATH = 'portfolioOverviewMetrics';

export function getPortfolioMetricPath(portfolioMetricKey: PortfolioOverviewMetricKey): string {
  return `${PORTFOLIO_OVERVIEW_METRICS_PATH}.${portfolioMetricKey}`;
}

type PortfolioOverviewMetricCodes = UnionTypesOfArray<typeof PORTFOLIO_OVERVIEW_METRICS>;

function getPortfolioOverviewMetricCodes(): Record<
  PortfolioOverviewMetricCodes,
  PortfolioOverviewMetricCodes
> {
  const portfolioOverviewMetricKeys = {} as Record<
    PortfolioOverviewMetricCodes,
    PortfolioOverviewMetricCodes
  >;

  PORTFOLIO_OVERVIEW_METRICS.forEach(metricCode => {
    portfolioOverviewMetricKeys[metricCode] = metricCode;
  });

  return portfolioOverviewMetricKeys;
}

export const PORTFOLIO_OVERVIEW_METRIC_CODES = getPortfolioOverviewMetricCodes();
