import { MetricCode, MetricPeriod } from 'src/types';
import { MetricValuesListQueryVariables } from 'src/graphql';
import { SAASSCORE_SHARED_METRICS } from 'src/constants';

export const getMetricValuesListVariables = (
  companyId: string,
  dates: string[],
  metricCodes: Array<MetricCode>,
): MetricValuesListQueryVariables => ({
  filter: {
    company: {
      id: {
        equals: companyId,
      },
    },
    date: {
      in: dates,
    },
    OR: [
      {
        period: {
          equals: MetricPeriod.Third,
        },
        metric: {
          code: {
            in: metricCodes,
          },
        },
      },
      {
        AND: [
          {
            period: {
              in: [MetricPeriod.Month, MetricPeriod.Week],
            },
            metric: {
              code: {
                in: [...SAASSCORE_SHARED_METRICS, MetricCode.SaaSScore],
              },
            },
          },
        ],
      },
    ],
  },
});
