import React from 'react';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';

import {
  PORTFOLIO_COMPANIES_QUERY,
  PortfolioCompaniesEntityQuery,
  PortfolioCompaniesEntityQueryVariables,
  PortfolioCompany,
} from 'src/graphql';

export interface PortfolioCompanyProviderProps {
  portfolioCompanyId: string;
  children: React.ReactNode;
}

export interface PortfolioCompanyContextData {
  data?: PortfolioCompany;
  loading: boolean;
}

export const PortfolioCompanyContext = React.createContext<PortfolioCompanyContextData>(
  {} as PortfolioCompanyContextData,
);

export const PortfolioCompanyProvider: React.FC<PortfolioCompanyProviderProps> = ({
  children,
  portfolioCompanyId,
}) => {
  const { data, loading } = useQuery<
    PortfolioCompaniesEntityQuery,
    PortfolioCompaniesEntityQueryVariables
  >(PORTFOLIO_COMPANIES_QUERY, {
    variables: {
      id: portfolioCompanyId,
    },
    skip: !portfolioCompanyId,
  });

  const portfolioCompany = R.pathOr<PortfolioCompany | undefined>(
    undefined,
    ['portfolioCompany'],
    data,
  );

  return (
    <PortfolioCompanyContext.Provider
      value={{
        data: portfolioCompany,
        loading,
      }}
    >
      {children}
    </PortfolioCompanyContext.Provider>
  );
};
