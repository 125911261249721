import React from 'react';

import { useLocalStorage, useCalculateMetrics } from 'src/hooks';
import { ChangedMetric } from 'src/types';
import { CompaniesWithMetricChange, LocalStorageKeys } from 'src/types/localStorage';
import { hasMetricChange, addMetricChange } from 'src/utils/localStorage/metrics';

export interface UnderwritingContextType {
  hasMetricChanges: boolean;
  companiesWithChange: CompaniesWithMetricChange | null;
  recalculateMetrics: () => Promise<void>;
  recalculating: boolean;
  updateMetric: (changedMetric: ChangedMetric) => void;
  companyId: string;
}

export const UnderwritingContext = React.createContext({} as UnderwritingContextType);

interface UnderwritingProvider {
  companyId: string;
  children: React.ReactNode;
}

export const UnderwritingProvider: React.FC<UnderwritingProvider> = ({ companyId, children }) => {
  const [companiesWithChange, setCompaniesWithChanges] = useLocalStorage<CompaniesWithMetricChange>(
    LocalStorageKeys.CompaniesWithChange,
    {},
  );

  const [recalculateMetrics, { loading: recalculating }] = useCalculateMetrics(
    companyId,
    companiesWithChange,
    setCompaniesWithChanges,
  );

  const hasMetricChanges = hasMetricChange(companiesWithChange, companyId);
  const updateMetric = (metric: ChangedMetric) => {
    const companies = addMetricChange(companiesWithChange, companyId, metric);
    setCompaniesWithChanges(companies);
  };

  return (
    <UnderwritingContext.Provider
      value={{
        recalculateMetrics,
        recalculating,
        updateMetric,
        companiesWithChange,
        hasMetricChanges,
        companyId,
      }}
    >
      {children}
    </UnderwritingContext.Provider>
  );
};
