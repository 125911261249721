import React from 'react';
import { useMutation } from 'react-apollo';

import { EntitiesTableActionsPopover, ModalsContext } from 'src/app-builder';
import { useNotification } from 'src/hooks';
import {
  ConfirmationDialog,
  DIALOGS,
  TeamEditDialog,
  TeamInviteDialog,
  UserDeleteDialog,
} from 'src/dialogs';
import {
  INVITATION_CANCEL_MUTATION,
  INVITATION_RESEND_MUTATION,
  Maybe,
  UserFragmentFragment,
} from 'src/graphql';
import { getUserPendingActions } from 'src/shared/team/getUserPendingActions';
import { getUserActions } from 'src/shared/team/getUserActions';
import { t } from 'src/utils';

interface Permissions {
  canDelete: boolean;
  canEdit: boolean;
  canResendInvite: boolean;
  canCancelInvite: boolean;
  canInvite: boolean;
}

interface ActionsContext {
  openTeamEditDialog: () => void;
  openDeleteRowDialog: () => void;
  openTeamInviteDialog: () => void;
  resendInvitation: () => void;
  openActions: (id: string) => void;
  permissions: Permissions;
}

export const actionsContext = React.createContext<ActionsContext>({} as ActionsContext);

interface ActionsProvider {
  children: React.ReactNode;
  companyId: Maybe<string | undefined>;
  permissions: Permissions;
  rows: UserFragmentFragment[];
  doesCompanyHaveOwner?: boolean;
  isClientPortal?: boolean;
}

export const ActionsProvider: React.FC<ActionsProvider> = ({
  children,
  companyId,
  permissions,
  rows,
  doesCompanyHaveOwner,
  isClientPortal,
}) => {
  const { openModal } = React.useContext(ModalsContext);
  const notification = useNotification();

  const [invitationResend] = useMutation(INVITATION_RESEND_MUTATION, {
    refetchQueries: ['UsersTableContent', 'PortfolioCompaniesEntity'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('invitation_resend_success')),
    onError: () => notification.error(t('invitation_resend_error')),
  });

  const [invitationCancel] = useMutation(INVITATION_CANCEL_MUTATION, {
    refetchQueries: ['UsersTableContent', 'PortfolioCompaniesEntity'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('invitation_cancel_success')),
    onError: () => notification.error(t('invitation_cancel_error')),
  });

  const [actions, setActions] = React.useState<{
    el: HTMLElement | null;
    id: string | null;
  }>({ el: null, id: null });

  const openActions = (id: string) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setActions({ el: event.currentTarget, id });
  };

  const closeActions = React.useCallback(() => {
    setActions({ el: null, id: null });
  }, [setActions]);

  const openTeamEditDialog = () => {
    openModal(DIALOGS.TEAM_EDIT_DIALOG, {
      companyId,
      userId: actions.id,
      doesCompanyHaveOwner,
      onSuccess: () => notification.success('Teammate successfully updated'),
    });

    closeActions();
  };

  const openDeleteRowDialog = () => {
    openModal(DIALOGS.USER_DELETE_DIALOG, { id: actions.id });

    closeActions();
  };

  const resendInvitation = async (): Promise<void> => {
    closeActions();
    const row = rows.find((r: UserFragmentFragment) => r.id === actions.id);

    await invitationResend({ variables: { id: row?.invitation?.id } });
  };

  const cancelInvitation = async (): Promise<void> => {
    closeActions();
    const row = rows.find((r: UserFragmentFragment) => r.id === actions.id);

    openModal(DIALOGS.CONFIRMATION_DIALOG, {
      message: 'Are you sure want to cancel invitation?',
      onConfirm: async () => {
        await invitationCancel({ variables: { id: row?.invitation?.id } });
      },
    });
  };

  const openTeamInviteDialog = React.useCallback(() => {
    openModal(DIALOGS.TEAM_INVITE_DIALOG, {
      companyId,
      doesCompanyHaveOwner,
    });

    closeActions();
  }, [openModal, closeActions, companyId, doesCompanyHaveOwner]);

  const createActions = (_: any, rowId: string) => {
    const row = rows.find((r: UserFragmentFragment) => r.id === rowId);

    return row?.status !== 'invitationPending'
      ? getUserActions({
          canDelete: permissions.canDelete,
          canEdit: permissions.canEdit,
          openDeleteRowDialog,
          openTeamEditDialog,
        })
      : getUserPendingActions({
          canResendInvite: permissions.canResendInvite,
          canCancelInvite: permissions.canCancelInvite,
          cancelInvitation,
          resendInvitation,
        });
  };

  return (
    <actionsContext.Provider
      value={{
        openTeamEditDialog,
        openDeleteRowDialog,
        openTeamInviteDialog,
        resendInvitation,
        openActions,
        permissions,
      }}
    >
      {children}
      <EntitiesTableActionsPopover
        anchorEl={actions.el}
        open={Boolean(actions.el)}
        onClose={closeActions}
        activeId={actions.id}
        createActions={createActions}
      />
      <TeamInviteDialog />
      <TeamEditDialog />
      <ConfirmationDialog />
      <UserDeleteDialog isClientPortal={isClientPortal} />
    </actionsContext.Provider>
  );
};
