import React from 'react';
import { TableSortLabel } from '@material-ui/core';

import { useSort } from 'src/hooks';
import { SORT_DIRECTIONS } from 'src/constants/sort';

interface SortTitleRenderer {
  children: React.ReactNode;
  field: string;
}

export const SortTitleRenderer: React.FC<SortTitleRenderer> = ({ children, field }) => {
  const sort = useSort();
  if (!sort) return null;

  return (
    <TableSortLabel
      active={sort.field === field && !!sort.order}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      direction={sort.order || SORT_DIRECTIONS.ASC}
      onClick={() => sort.toggleSort(field)}
    >
      {children}
    </TableSortLabel>
  );
};
