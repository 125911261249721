import React from 'react';
import * as R from 'ramda';
import { Grid, makeStyles, Theme, Typography, LinearProgress } from '@material-ui/core';
import { FormatListBulleted } from '@material-ui/icons';
import { EmptyScreen } from 'src/components';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    height: '100%',
    background: theme.palette.background.default,
    overflow: 'auto',
  },
  options: {
    padding: theme.spacing(4),
  },
  emptyScreen: {
    background: theme.palette.background.paper,
  },
}));

interface SaaSLoanPageLayoutProps {
  children: React.ReactNode;
  loading: boolean;
}

export const SaaSLoanPageLayout: React.FC<SaaSLoanPageLayoutProps> = ({ children, loading }) => {
  const classes = useStyles();
  const areOptionsAvailable = !R.isEmpty(children);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid className={classes.header} item alignItems="center">
        <Typography variant="subtitle1">SaaSLoan</Typography>
      </Grid>

      {loading ? (
        <LinearProgress />
      ) : (
        <Grid item container className={classes.content} xs={12} direction="row">
          {areOptionsAvailable ? (
            <Grid item container className={classes.options} xs={12} direction="row">
              {children}
            </Grid>
          ) : (
            <Grid item className={classes.emptyScreen} xs={12}>
              <EmptyScreen
                text="SaaSLoan options are not available for this company yet"
                icon={FormatListBulleted}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
