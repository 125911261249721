import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';

import { useRouterQuery, useLoanApplication } from 'src/hooks';
import { ScoreProvider, MetricCellProvider, ToggleMetricsProvider } from 'src/providers';
import { Layout, FiltersContainer } from './components';

import {
  Company,
  MetricsListQuery,
  MetricsListQueryVariables,
  MetricValuesListQuery,
  MetricValuesListQueryVariables,
  METRIC_VALUES_LIST_QUERY,
  METRICS_LIST_QUERY,
} from 'src/graphql';
import { MetricCode, CompanyType } from 'src/types';
import {
  getDateRange,
  getScoreMetricCodesByCompany,
  getMetricValuesListVariables,
  structureTableData,
} from 'src/utils';

export const LoanApplicationScoreAndMetricsPage: React.FC = () => {
  const query = useRouterQuery();
  const selectedPeriod = parseInt(R.pathOr('12', ['selectedPeriod'], query));
  const dates = getDateRange(selectedPeriod);

  const { data: loanApplication, loading: loanAppLoading } = useLoanApplication();

  const company = loanApplication?.company;
  const companyId = company?.id as string;

  const saasScoreMetricCodes = getScoreMetricCodesByCompany(company as Company);

  const { data: metricsList, loading: metricsListLoading } = useQuery<
    MetricsListQuery,
    MetricsListQueryVariables
  >(METRICS_LIST_QUERY);

  const { data: metricListValues, loading: metricListValuesLoading } = useQuery<
    MetricValuesListQuery,
    MetricValuesListQueryVariables
  >(METRIC_VALUES_LIST_QUERY, {
    variables: getMetricValuesListVariables(companyId, dates, saasScoreMetricCodes),
  });

  const metricCodes = Object.values(saasScoreMetricCodes).map(v => v);

  const scoreMetrics = React.useMemo(
    () =>
      structureTableData({
        metricCodes: metricCodes as MetricCode[],
        metrics: metricsList,
        values: metricListValues,
        dates,
      }),
    [metricCodes, metricListValues, metricsList, dates],
  );

  const loading = metricListValuesLoading || metricsListLoading || loanAppLoading;

  const tableData = { data: scoreMetrics, dates };

  return (
    <ScoreProvider
      companyId={companyId as string}
      companyType={CompanyType.LoanApplication}
      metrics={scoreMetrics}
    >
      <MetricCellProvider companyId={companyId}>
        <ToggleMetricsProvider>
          <Layout
            filters={<FiltersContainer companyId={companyId} selectedPeriod={selectedPeriod} />}
            loading={loading}
            tableData={tableData}
          />
        </ToggleMetricsProvider>
      </MetricCellProvider>
    </ScoreProvider>
  );
};
