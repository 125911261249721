import React from 'react';
import * as R from 'ramda';
import { useMutation } from 'react-apollo';
import { useSnackbar } from 'notistack';
import { gql } from '8base-react-sdk';

import { DIALOGS, PermissionUpdateDialog } from '../dialogs';
import { ApplicationAreaAction } from './ApplicationProvider';
import { useModal } from './ModalsProvider';

type ApplicationAreaActionsContextType = {
  handlers: Array<{
    key: string;
    handler: (...args: any) => void;
  }>;
};

const ApplicationAreaActionsContext = React.createContext<ApplicationAreaActionsContextType>({
  handlers: [],
});

export const useApplicationAreaAction = (): ((...args: any) => void) => {
  const { handlers } = React.useContext<ApplicationAreaActionsContextType>(
    ApplicationAreaActionsContext,
  );

  return React.useCallback(
    (action: ApplicationAreaAction, args?: any) => {
      if (action.type === 'handler') {
        const handler = R.find(R.propEq('key', action.key), handlers);

        if (handler) {
          handler.handler(args);
        } else {
          throw new Error('action not found ' + JSON.stringify(action));
        }
      }
    },
    [handlers],
  );
};

const INVITATION_RESEND_MUTATION = gql`
  mutation InvitationResend($id: ID!) {
    invitations {
      invitationResend(id: $id) {
        success
      }
    }
  }
`;

const INVITATION_CANCEL_MUTATION = gql`
  mutation InvitationCancel($id: ID!) {
    invitations {
      invitationCancel(id: $id) {
        success
      }
    }
  }
`;

interface ApplicationAreaActionsProviderProps {
  children: React.ReactNode;
}

export const ApplicationAreaActionsProvider: React.FC<ApplicationAreaActionsProviderProps> = ({
  children,
}) => {
  const { openModal } = useModal();

  const [invitationResend] = useMutation(INVITATION_RESEND_MUTATION, {
    refetchQueries: ['UsersTableContent'],
    awaitRefetchQueries: true,
  });

  const [invitationCancel] = useMutation(INVITATION_CANCEL_MUTATION, {
    refetchQueries: ['UsersTableContent'],
    awaitRefetchQueries: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handlers = React.useMemo(
    () => [
      {
        key: 'OPEN_PERMISSION_UPDATE_DIALOG',
        handler: async (id: string): Promise<void> => {
          openModal(DIALOGS.PERMISSION_UPDATE_DIALOG, { id });
        },
      },
      {
        key: 'RESEND_INVITATION',
        handler: async (id: string): Promise<void> => {
          await invitationResend({ variables: { id } });

          enqueueSnackbar('Invitation successfully resent', { variant: 'success' });
        },
      },
      {
        key: 'CANCEL_INVITATION',
        handler: async (id: string): Promise<void> => {
          await invitationCancel({ variables: { id } });

          enqueueSnackbar('Invitation successfully canceled', { variant: 'success' });
        },
      },
    ],
    [enqueueSnackbar, invitationCancel, invitationResend, openModal],
  );

  return (
    <ApplicationAreaActionsContext.Provider value={{ handlers }}>
      {children}
      <PermissionUpdateDialog />
    </ApplicationAreaActionsContext.Provider>
  );
};
