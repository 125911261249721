import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Typography,
} from '@material-ui/core';
import { Prompt, useHistory } from 'react-router-dom';

import { DIALOGS } from './constants';

import { useModal } from '../providers';
import { isFunction } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

type DataLossContextType = {
  when: boolean;
  setWhen: (when: boolean) => void;
};

export const DataLossContext = React.createContext<DataLossContextType>({
  when: false,
  setWhen: () => {},
});

interface DataLossProviderProps {
  children: React.ReactNode;
}

export const DataLossProvider: React.FC<DataLossProviderProps> = ({ children }) => {
  const [when, setWhen] = React.useState<boolean>(false);

  return <DataLossContext.Provider value={{ when, setWhen }}>{children}</DataLossContext.Provider>;
};

export const useDataLoss = (): DataLossContextType => {
  const { when, setWhen } = React.useContext<DataLossContextType>(DataLossContext);

  return {
    when,
    setWhen,
  };
};

interface DataLossDialogProps {}

export const DataLossDialog: React.FC<DataLossDialogProps> = () => {
  const classes = useStyles();

  const { when, setWhen } = useDataLoss();

  const history = useHistory();

  const { open, closeModal, openModal, args } = useModal(DIALOGS.DATA_LOSS_DIALOG);

  const [lastLocation, setLastLocation] = React.useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const handleBlockedNavigation = React.useCallback(
    (nextLocation: any) => {
      if (!confirmedNavigation) {
        openModal();

        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, openModal, setLastLocation],
  );

  const onCancel = React.useCallback(() => {
    closeModal();

    if (isFunction(args?.onCancel)) {
      args.onCancel();
    }
  }, [closeModal, args]);

  const onConfirm = React.useCallback(() => {
    closeModal();
    setConfirmedNavigation(true);

    setWhen(false);

    if (isFunction(args?.onConfirm)) {
      args.onConfirm();
    }
  }, [closeModal, setWhen, args]);

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);

      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {open && (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={onCancel}
          PaperProps={{
            className: classes.root,
          }}
          maxWidth="sm"
        >
          <DialogTitle>Save Changes</DialogTitle>

          <DialogContent>
            <Typography>You have unsaved changes. Keep editing or leave without saving.</Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={onConfirm}>Leave</Button>
            <Button color="secondary" variant="contained" onClick={onCancel}>
              Keep Editing
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
