import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';

import { hasFieldError, getFieldError } from './utils';
import { PopoverProps } from '@material-ui/core';

interface DatetimeFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
}

const POPOVER_PROPS: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const DatetimeField: React.FC<DatetimeFieldProps> = ({ input, meta, ...rest }) => {
  const { name, value, onChange } = input;

  const handleChange = React.useCallback(
    (date: DateTime | null) => {
      onChange(date ? date.toISO() : date);
    },
    [onChange],
  );

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  const props = {
    autoOk: true,
    name: name,
    error,
    helperText,
    value: value ? DateTime.fromISO(value) : null,
    onChange: handleChange,
    PopoverProps: POPOVER_PROPS,
    ...rest,
  };

  return (
    <KeyboardDateTimePicker
      variant="inline"
      inputVariant="outlined"
      format="dd/MM/yyyy HH:mm"
      {...props}
    />
  );
};
