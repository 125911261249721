import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';

import { useRouterQuery, usePortfolioCompany } from 'src/hooks';
import { ScoreProvider, ToggleMetricsProvider, ActionsProvider } from 'src/providers';
import {
  Company,
  MetricsListQuery,
  MetricsListQueryVariables,
  MetricValuesListQuery,
  MetricValuesListQueryVariables,
  METRIC_VALUES_LIST_QUERY,
  METRICS_LIST_QUERY,
} from 'src/graphql';
import {
  getDateRange,
  getMetricValuesListVariables,
  getScoreMetricCodesByCompany,
  structureTableData,
} from 'src/utils';
import { MetricCode, ScoreMetricItem, CompanyType } from 'src/types';
import { Layout, FiltersContainer } from './components';

export const PortfolioCompanyScoreAndMetricsPage: React.FC = () => {
  const query = useRouterQuery();
  const selectedPeriod = parseInt(R.pathOr('12', ['selectedPeriod'], query));
  const dates = getDateRange(selectedPeriod);

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const company = portfolioCompany?.company;
  const saasScoreMetricCodes = getScoreMetricCodesByCompany(company as Company);

  const companyId = company?.id as string;

  const { data: metricsList, loading: metricsListLoading } = useQuery<
    MetricsListQuery,
    MetricsListQueryVariables
  >(METRICS_LIST_QUERY, {
    variables: {
      filter: {
        code: {
          in: saasScoreMetricCodes,
        },
      },
    },
  });

  const { data: metricListValues, loading: metricListValuesLoading } = useQuery<
    MetricValuesListQuery,
    MetricValuesListQueryVariables
  >(METRIC_VALUES_LIST_QUERY, {
    variables: getMetricValuesListVariables(companyId, dates, saasScoreMetricCodes),
  });

  const metricCodes = Object.values(saasScoreMetricCodes).map(v => v);

  const scoreMetrics: ScoreMetricItem[] = React.useMemo(
    () =>
      structureTableData({
        metricCodes: metricCodes as MetricCode[],
        metrics: metricsList,
        values: metricListValues,
        dates,
      }),
    [metricCodes, metricListValues, metricsList, dates],
  );

  const loading = metricListValuesLoading || metricsListLoading || portfolioCompanyLoading;

  const tableData = { data: scoreMetrics, dates };

  return (
    <ScoreProvider
      companyId={companyId as string}
      metrics={scoreMetrics}
      companyType={CompanyType.PortfolioCompany}
    >
      <ActionsProvider companyId={companyId}>
        <ToggleMetricsProvider>
          <Layout
            filters={<FiltersContainer companyId={companyId} selectedPeriod={selectedPeriod} />}
            tableData={tableData}
            loading={loading}
          />
        </ToggleMetricsProvider>
      </ActionsProvider>
    </ScoreProvider>
  );
};
