import React, { createContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { set } from 'lodash';

import { LoanMultiplierSettings } from 'src/types';

interface LoanMultipliersContextValue {
  loanMultiplierSettings: LoanMultiplierSettings;
  update: (isRevenue: boolean) => (path: Array<string | number>) => (value: number) => void;
  resetLoanMultiplierSettings: () => void;
}

interface LoanMultipliersProviderProps {
  initialData: LoanMultiplierSettings;
  children: React.ReactNode;
}

export const loanMultipliersContext = createContext<LoanMultipliersContextValue>(
  {} as LoanMultipliersContextValue,
);

export const LoanMultipliersProvider: React.FC<LoanMultipliersProviderProps> = ({
  initialData,
  children,
}) => {
  const [loanSettings, setLoanSettings] = useState<LoanMultiplierSettings>(null);

  const update = (isRevenue: boolean) => (path: Array<string | number>) => (value: number) => {
    if (!loanSettings) return;

    const newSettings = R.clone(loanSettings);
    set(newSettings, [isRevenue ? 'revenue' : 'repayment', ...path], value);
    setLoanSettings(newSettings);
  };

  const resetLoanMultiplierSettings = () => setLoanSettings(initialData);

  useEffect(() => {
    setLoanSettings(initialData);
  }, [initialData]);

  return (
    <loanMultipliersContext.Provider
      value={{
        loanMultiplierSettings: loanSettings,
        update,
        resetLoanMultiplierSettings,
      }}
    >
      {children}
    </loanMultipliersContext.Provider>
  );
};
