import React from 'react';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
  Typography,
} from '@material-ui/core';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { METRIC_VALUE_UPSERT } from 'src/graphql';
import { DIALOGS } from './constants';
import { isFunction, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface MetricDeleteDialogProps {}

export const MetricDeleteDialog: React.FC<MetricDeleteDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal } = useModal(DIALOGS.METRIC_DELETE_DIALOG);

  const [updateRow, { loading }] = useMutation(METRIC_VALUE_UPSERT, {
    refetchQueries: ['MetricValuesList'],
    awaitRefetchQueries: true,
    onCompleted: () =>
      notification.success(
        t('metric_delete_success', {
          metricName: args?.metric?.name,
        }),
      ),
    onError: () =>
      notification.error(
        t('metric_delete_error', {
          metricName: args?.metric?.name,
        }),
      ),
  });

  const onDelete = React.useCallback(async () => {
    await updateRow({
      variables: {
        date: args?.date,
        companyId: args?.companyId,
        metricCode: args?.metric?.code,
        adjustedValue: null,
        comment: null,
        value: null,
        period: args?.cell.period,
      },
    });

    if (isFunction(args?.onSuccess)) {
      args?.onSuccess();
    }

    closeModal();
  }, [closeModal, args, updateRow]);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ className: classes.root }}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Delete modal</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure want to delete this &nbsp; &rdquo;{args?.metric?.name}&rdquo;?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          onClick={onDelete}
          loading={loading}
        >
          Delete metric
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
