import React from 'react';
import { useMutation } from 'react-apollo';
import { useNotification } from 'src/hooks';
import { TableExportType, TABLE_EXPORT_MUTATION } from 'src/graphql';
import { commonErrorResolver, downloadXlsx, getExportParams } from 'src/utils';
import { ExportParams } from 'src/types';

type TableExportState = [
  () => Promise<void>,
  {
    loading: boolean;
  },
];

export const useTableExport = (type: TableExportType, params: ExportParams): TableExportState => {
  const notification = useNotification();

  const [exportTable, { loading }] = useMutation(TABLE_EXPORT_MUTATION, {
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const onExport = React.useCallback(async () => {
    const exportParams = getExportParams(type, params);

    const variables = {
      type,
      exportParams,
    };

    const exportResult = await exportTable({
      variables: {
        event: variables,
      },
    });

    const exportResultData = exportResult?.data;

    if (Boolean(exportResultData)) {
      downloadXlsx(exportResultData, type);
    }
  }, [type, params, exportTable]);

  return [onExport, { loading }];
};
