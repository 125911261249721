import React from 'react';
import { ApolloError } from 'apollo-client';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';

import { COMPANY_INTEGRATIONS_QUERY, Integration } from 'src/graphql';
import {
  checkIfPlaidIntegrationConnected,
  checkIfQuickBooksIntegrationConnected,
  checkIfXeroIntegrationConnected,
  isFunction,
} from 'src/utils';

type IntegrationsState = {
  data?: Integration;
  loading: boolean;
  error?: ApolloError;
  isPlaidIntegrationConnected: boolean;
  isQuickBooksIntegrationConnected: boolean;
  isXeroIntegrationConnected: boolean;
};

export const useIntegrations = (
  companyId: string,
  options?: {
    onCompleted?: () => void;
    onError?: (error: ApolloError) => void;
  },
): IntegrationsState => {
  const { data: integrations, loading, error } = useQuery(COMPANY_INTEGRATIONS_QUERY, {
    variables: {
      companyId,
    },
    onError: (error: ApolloError) => {
      if (options?.onError && isFunction(options?.onError)) {
        options?.onError(error);
        throw error;
      }
    },
    onCompleted: () => {
      if (options?.onCompleted && isFunction(options?.onError)) {
        options?.onCompleted();
      }
    },
    errorPolicy: 'all',
    skip: !companyId,
  });

  const companyWithIntegrations = R.pathOr(null, ['company'], integrations);

  const isPlaidIntegrationConnected = React.useMemo(() => {
    return checkIfPlaidIntegrationConnected(companyWithIntegrations);
  }, [companyWithIntegrations]);

  const isQuickBooksIntegrationConnected = React.useMemo(() => {
    return checkIfQuickBooksIntegrationConnected(companyWithIntegrations);
  }, [companyWithIntegrations]);

  const isXeroIntegrationConnected = React.useMemo(() => {
    return checkIfXeroIntegrationConnected(companyWithIntegrations);
  }, [companyWithIntegrations]);

  const data = R.pathOr<Integration | undefined>(
    undefined,
    ['company', 'integration'],
    integrations,
  );

  return {
    data,
    loading,
    error,
    isPlaidIntegrationConnected,
    isQuickBooksIntegrationConnected,
    isXeroIntegrationConnected,
  };
};
