import React from 'react';
import { Typography, Paper, Grid, List, Theme, Box, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SecondaryNavItem } from '../../shared';
import { useCurrentUser } from '../../hooks';
import { useClientRoles } from '../../providers';
import { getUserInitials } from '../../utils';

import { AccountInformationPage } from './AccountInformationPage';
import { AccountSecurityPage } from './AccountSecurityPage';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'auto',
    flex: 1,
  },
  content: {
    width: '100%',
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  avatarWrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(1),
  },
}));

export const AccountPage: React.FC<any> = () => {
  const classes = useStyles();

  const { user } = useCurrentUser();

  const { currentRole } = useClientRoles();
  const currentRoleName = currentRole?.title || currentRole?.name;

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">My Account</Typography>
      </Grid>

      <Grid container item>
        <Paper className={classes.paper}>
          <Grid container direction="row" wrap="nowrap">
            <Grid item className={classes.sidebar}>
              <Box
                className={classes.avatarWrapper}
                padding={4}
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <Avatar src={user?.avatar?.downloadUrl || ''} className={classes.avatar}>
                  {getUserInitials(user)}
                </Avatar>
                <Typography>
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography variant="caption">{currentRoleName}</Typography>
              </Box>
              <List>
                <SecondaryNavItem
                  to="/account/information"
                  icon="Person"
                  title="Account Information"
                />
                <SecondaryNavItem
                  to="/account/security"
                  icon="Lock"
                  title="Sign-in &amp; Security"
                />
              </List>
            </Grid>

            <Grid item className={classes.contentWrapper}>
              <Box padding={4} className={classes.content}>
                <Switch>
                  <Route path="/account/information" component={AccountInformationPage} />
                  <Route
                    path="/account/security"
                    render={() => <AccountSecurityPage email={user?.email as string} />}
                  />
                  <Redirect to="/account/information" />
                </Switch>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
