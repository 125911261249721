import React from 'react';
import {
  EntitiesTableActionsPopover,
  ModalsContext,
  useAllowed,
  usePopover,
} from 'src/app-builder';

import { StagePopover } from 'src/shared';
import {
  DIALOGS,
  ApplicantInviteDialog,
  LoanApplicationDealTeamEditDialog,
  LoanApplicationDeleteDialog,
} from 'src/dialogs';
import { Permission } from 'src/constants';
import { getActions } from './getActions';
import { User } from 'src/graphql';

interface ActionsProviderProps {
  children: React.ReactNode;
  users: any;
  rows: any;
}

interface ActionsContextValue {
  onStatusesClick: (id: string) => ((event: React.MouseEvent<any, MouseEvent>) => void) | undefined;
  openActions: (id: string) => (event: React.MouseEvent<any, MouseEvent>) => void;
  openDealTeamDialog: () => void;
  openDeleteRowDialog: () => void;
  openApplicantInviteDialog: () => void;
  canChangeStage: boolean;
  canDelete: boolean;
  canAsign: boolean;
  users: Record<string, User[]>;
}

export const actionsContext = React.createContext<ActionsContextValue>({} as ActionsContextValue);

export const ActionsProvider: React.FC<ActionsProviderProps> = ({ children, rows, users }) => {
  const isAllowed = useAllowed();
  const { openModal } = React.useContext(ModalsContext);
  const [activeId, setActiveId] = React.useState<string | null>(null);

  const stagePopover = usePopover();
  const actionsPopover = usePopover();

  const [canChangeStage, canDelete, canAsign] = [
    isAllowed(Permission.LoanApplicationsChangeStage),
    isAllowed(Permission.LoanApplicationsDelete),
    isAllowed(Permission.LoanApplicationsAsignTo),
  ];

  const openStatuses = React.useCallback(
    (id: string) => (event: React.MouseEvent<any, MouseEvent>): void => {
      stagePopover.openPopover(event);

      setActiveId(id);
    },
    [stagePopover, setActiveId],
  );

  const openActions = React.useCallback(
    (id: string) => (event: React.MouseEvent<any, MouseEvent>): void => {
      actionsPopover.openPopover(event);

      setActiveId(id);
    },
    [actionsPopover, setActiveId],
  );

  const closeActions = React.useCallback(() => {
    actionsPopover.closePopover();

    setActiveId(null);
  }, [actionsPopover, setActiveId]);

  const onStatusesClick = React.useCallback(
    (id: string) => {
      return canChangeStage ? openStatuses(id) : undefined;
    },
    [canChangeStage, openStatuses],
  );

  const closeStatuses = React.useCallback(() => {
    stagePopover.closePopover();

    setActiveId(null);
  }, [stagePopover, setActiveId]);

  const activeStage = React.useMemo(() => rows.find(({ id }: any) => id === activeId)?.stage, [
    rows,
    activeId,
  ]);

  const openDealTeamDialog = React.useCallback(() => {
    const dealTeam = rows.find(({ id }: any) => id === activeId)?.dealTeam?.items;

    openModal(DIALOGS.LOAN_APPLICATION_DEAL_TEAM_EDIT_DIALOG, {
      id: activeId,
      dealTeam,
    });

    closeActions();
  }, [openModal, closeActions, rows, activeId]);

  const openDeleteRowDialog = React.useCallback(
    (args?: any) => {
      openModal(DIALOGS.LOAN_APPLICATION_DELETE_DIALOG, args || { id: activeId });

      closeActions();
    },
    [openModal, activeId, closeActions],
  );

  const openApplicantInviteDialog = React.useCallback(
    (args?: any) => {
      openModal(DIALOGS.APPLICANT_INVITE_DIALOG, args);

      closeActions();
    },
    [openModal, closeActions],
  );

  return (
    <actionsContext.Provider
      value={{
        onStatusesClick,
        openDealTeamDialog,
        openDeleteRowDialog,
        openApplicantInviteDialog,
        canChangeStage,
        canDelete,
        canAsign,
        openActions,
        users,
      }}
    >
      {children}
      {canChangeStage && (
        <StagePopover
          anchorEl={stagePopover.el}
          open={Boolean(stagePopover.el)}
          onClose={closeStatuses}
          loanApplicationId={activeId}
          stage={activeStage}
        />
      )}
      <EntitiesTableActionsPopover
        anchorEl={actionsPopover.el}
        open={Boolean(actionsPopover.el)}
        onClose={closeActions}
        activeId={activeId}
        createActions={getActions(activeId)}
      />
      <ApplicantInviteDialog />
      <LoanApplicationDeleteDialog />
      <LoanApplicationDealTeamEditDialog />
    </actionsContext.Provider>
  );
};
