import { PRIMARY_COLOR } from 'src/constants/colors';

const makeArrayFromNodeList = (nodeList: NodeList): HTMLElement[] => {
  return Array.prototype.slice.call(nodeList);
};

const isTextEditable = (cellText: HTMLSpanElement | null) => {
  return cellText?.dataset?.contentCell === 'true';
};

const getNewCurrentCell = (
  oldCurrentCell: HTMLDivElement | null,
  newCurrentCell: ChildNode | null,
  repeatMoveCallback?: (oldCurrentCell: HTMLDivElement) => HTMLDivElement | null,
): HTMLDivElement | null => {
  if (newCurrentCell instanceof HTMLDivElement) {
    if (typeof repeatMoveCallback === 'function' && newCurrentCell.dataset?.emptyCell === 'true') {
      return repeatMoveCallback(newCurrentCell);
    }

    const cellText = newCurrentCell?.firstChild;
    if (cellText instanceof HTMLSpanElement && isTextEditable(cellText)) {
      return newCurrentCell;
    }
  }

  return oldCurrentCell;
};

const outlineCell = (cell: HTMLDivElement | null) => {
  if (cell) {
    cell.style.outline = `1px solid ${PRIMARY_COLOR}`;
  }
};

const getRowOfCell = (cell: HTMLDivElement): HTMLTableRowElement | null => {
  const rowOfCell = cell.parentElement?.parentElement;
  if (rowOfCell instanceof HTMLTableRowElement) {
    return rowOfCell;
  }

  return null;
};

const getTdElementOfCell = (cell: HTMLDivElement): HTMLTableCellElement | null => {
  const tdElement = cell.parentElement;
  if (tdElement instanceof HTMLTableCellElement) {
    return tdElement;
  }

  return null;
};

const getCellHorizontalPosition = (row: HTMLTableRowElement, tdElement: HTMLTableCellElement) => {
  const rowElementList = makeArrayFromNodeList(row.childNodes);

  return rowElementList.indexOf(tdElement);
};

export const selectText = (cell: HTMLSpanElement | null): void => {
  if (cell) {
    const range = document.createRange();
    const sel = window.getSelection();
    const textNode = cell.childNodes[0];
    const text = cell.innerText;

    if (text.length > 0) {
      range.setStart(textNode, 0);
      range.setEnd(textNode, text.length);
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  }
};

export const getClosestCell = (cell: HTMLElement | null): HTMLDivElement | null => {
  if (!cell) {
    return null;
  }

  const closestCell = cell.closest('td')?.firstChild || null;
  const cellText = closestCell?.firstChild;

  if (
    closestCell instanceof HTMLDivElement &&
    cellText instanceof HTMLSpanElement &&
    isTextEditable(cellText)
  ) {
    return closestCell;
  }

  return null;
};

export const getRightCell = (oldCurrentCell: HTMLDivElement): HTMLDivElement | null => {
  const rightCell = oldCurrentCell?.parentElement?.nextSibling?.firstChild || null;

  if (rightCell instanceof HTMLDivElement) {
    return getNewCurrentCell(oldCurrentCell, rightCell);
  }

  return null;
};

export const getLeftCell = (oldCurrentCell: HTMLDivElement): HTMLDivElement | null => {
  const leftCell = oldCurrentCell?.parentElement?.previousSibling?.firstChild || null;

  if (leftCell instanceof HTMLDivElement) {
    return getNewCurrentCell(oldCurrentCell, leftCell);
  }

  return null;
};

export const getTopCell = (oldCurrentCell: HTMLDivElement): HTMLDivElement | null => {
  const oldCurrentRow = getRowOfCell(oldCurrentCell);
  const tdElement = getTdElementOfCell(oldCurrentCell);
  const newCurrentRow = oldCurrentRow?.previousSibling;

  if (oldCurrentRow && tdElement && newCurrentRow) {
    const cellPositionX = getCellHorizontalPosition(oldCurrentRow, tdElement);
    const newCurrentRowElementList = makeArrayFromNodeList(newCurrentRow.childNodes);
    const topCell = newCurrentRowElementList[cellPositionX].firstChild;

    return getNewCurrentCell(oldCurrentCell, topCell, getTopCell);
  }

  return null;
};

export const getBottomCell = (oldCurrentCell: HTMLDivElement): HTMLDivElement | null => {
  const oldCurrentRow = getRowOfCell(oldCurrentCell);
  const tdElement = getTdElementOfCell(oldCurrentCell);
  const newCurrentRow = oldCurrentRow?.nextSibling;

  if (oldCurrentRow && tdElement && newCurrentRow) {
    const cellPositionX = getCellHorizontalPosition(oldCurrentRow, tdElement);
    const newCurrentRowElementList = makeArrayFromNodeList(newCurrentRow.childNodes);
    const bottomCell = newCurrentRowElementList[cellPositionX].firstChild;

    return getNewCurrentCell(oldCurrentCell, bottomCell, getBottomCell);
  }

  return null;
};

export const resetCell = (cell: HTMLDivElement | null): void => {
  if (cell) {
    cell.style.outline = 'none';
    cell.contentEditable = 'false';
  }
};

export const selectCell = (cell: HTMLDivElement | null): void => {
  const cellText = cell?.firstChild;

  if (cell) {
    if (cellText instanceof HTMLSpanElement) {
      cellText.contentEditable = 'true';
      cellText.focus();
      selectText(cellText);
    }
    outlineCell(cell);
  }
};
