import * as R from 'ramda';

import { StructuredSaaSScoreMetricSettings } from './components/MetricSettingsProvider';
import { MetricsListQuery } from 'src/graphql';
import {
  MetricCode,
  LoanMultiplierSettings,
  ScaleByTierSettings,
  SaaSScoreMetricSetting,
} from 'src/types';

import { Format } from 'src/constants';
import {
  convertIfPercentages,
  getFormattedMetricValue,
  leaveFourDigitsInFraction,
} from 'src/utils';

export const extractMetrics = (
  response: MetricsListQuery | undefined,
): Array<SaaSScoreMetricSetting> => R.pathOr([], ['metricsList', 'items'], response);

export const moveSaaSScoreToTheEndOfAList = (
  metrics: Array<SaaSScoreMetricSetting>,
): Array<SaaSScoreMetricSetting> => {
  const saaSScoreIdx = metrics.findIndex(metric => metric.code === MetricCode.SaaSScore);
  const [saaSScoreMetric] = metrics.splice(saaSScoreIdx, 1);
  if (saaSScoreMetric) metrics.push(saaSScoreMetric);
  return metrics;
};

export const detectIsEnterpriseRowByRowIdx = (idx: number): boolean => idx % 2 === 1;

export const isEmptyCell = (isEnterprise: boolean, code: string): boolean =>
  (!isEnterprise && code === MetricCode.YoYRevenueGrowth) ||
  (isEnterprise && code === MetricCode.RevenueGrowthRateMoM) ||
  (isEnterprise && code === MetricCode.CACPayback);

export const getScaleByTierSettings = (
  metrics: Array<SaaSScoreMetricSetting>,
): ScaleByTierSettings => {
  const saasScoreMetric = metrics.find(metric => metric.code === MetricCode.SaaSScore);
  if (!saasScoreMetric) return null;

  const metadata = JSON.parse(saasScoreMetric.metadata) || {};
  return {
    smb: metadata?.scaleByTier,
    enterprise: metadata?.scaleByTierEnterprise,
  };
};

export const getLoanMultipliersSettings = (
  metrics: Array<SaaSScoreMetricSetting>,
): LoanMultiplierSettings => {
  const revenueMetric = metrics.find(metric => metric.code === MetricCode.TotalRevenue);
  if (!revenueMetric) return null;
  const metadata = JSON.parse(revenueMetric.metadata) || {};
  return {
    revenue: metadata.revenue,
    repayment: metadata.repayment,
  };
};

export const mapValuesToUpdate = (
  metricSettings: StructuredSaaSScoreMetricSettings,
  scaleByTier: ScaleByTierSettings,
  loanOptionMultiplierSettings: LoanMultiplierSettings,
): SaaSScoreMetricSetting[] =>
  Object.entries(metricSettings).map(([code, setting]) => {
    const dataToInsert = { code, ...setting };
    if (code === MetricCode.SaaSScore)
      dataToInsert.metadata = JSON.stringify({
        scaleByTier: scaleByTier?.smb,
        scaleByTierEnterprise: scaleByTier?.enterprise,
      });
    if (code === MetricCode.TotalRevenue)
      dataToInsert.metadata = JSON.stringify(loanOptionMultiplierSettings);
    return dataToInsert;
  });

export const formatBound = (format: Format) => (value: number): string | number | null => {
  if (R.isNil(value)) {
    return '';
  }

  return format === Format.Percent ? `${value}%` : getFormattedMetricValue(value, format as Format);
};

export const boundNumbersToStrings = (format: Format) => (values: number[]): string[] =>
  values.map(v => {
    if (R.isNil(v)) return '';

    const formatted = format === Format.Percent ? v * 100 : v;
    return leaveFourDigitsInFraction(formatted).toString();
  });

export const boundStringsToNumbers = (format: Format) => (values: string[]): number[] =>
  values.map(v => {
    const number = parseFloat(v) || 0;
    const converted = convertIfPercentages(number, format) as number;
    return leaveFourDigitsInFraction(converted);
  });
