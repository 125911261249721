import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useModal, Form, Field, TextField, FileField } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { NOTES_CREATE_MUTATION, NOTES_LIST_QUERY, SortOrder } from 'src/graphql';
import { DIALOGS } from 'src/dialogs';
import { isFunction, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface NoteCreateDialogProps {}

export const NoteCreateDialog: React.FC<NoteCreateDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, closeModal, args, openModal } = useModal(DIALOGS.NOTE_CREATE_DIALOG);

  const [createNote] = useMutation(NOTES_CREATE_MUTATION, {
    refetchQueries: [
      {
        query: NOTES_LIST_QUERY,
        variables: {
          sort: [{ updatedAt: SortOrder.Desc }],
          filter: {
            AND: [
              {
                company: { id: { equals: args?.companyId } },
              },
              {
                OR: [
                  {
                    title: { contains: '' },
                  },
                  {
                    body: { contains: '' },
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('note_create_success')),
    onError: () => notification.error(t('note_create_error')),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      const note: any = await createNote({
        variables: {
          data: {
            ...data,
            company: { connect: { id: args.companyId } },
          },
        },
      });

      closeModal();

      await form.reset;

      if (isFunction(args?.onSuccess)) {
        args.onSuccess(note);
      }
    },
    [closeModal, createNote, args],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  if (!open) {
    return null;
  }

  return (
    <Form tableSchemaName="Notes" type="CREATE" onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Create Note</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <Field
                  name="title"
                  label="Title"
                  component={TextField}
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="body"
                  label="Body"
                  component={TextField}
                  disabled={submitting}
                  rows={5}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="attachments"
                  label="Attachments"
                  component={FileField}
                  maxFiles={3}
                  fullWidth
                  disabled={submitting}
                  sessionCache
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              color="secondary"
              onClick={() => onClose(form, pristine)}
              disabled={submitting}
            >
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Create note
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
