import React from 'react';
import { Box } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { EmptyScreen } from 'src/components';

export const EmptyCompaniesScreen: React.FC = () => {
  return (
    <Box paddingTop={16} paddingBottom={16}>
      <EmptyScreen text="We couldn't find any companies" icon={icons.Group} />
    </Box>
  );
};
