import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from '@material-ui/core';
import { FieldSchema } from '@8base/utils';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';

interface RadioGroupFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  fieldSchema: FieldSchema;
  label?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  options: Array<{ label: string; value: string }>;
}

export const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  input,
  meta,
  fullWidth,
  label,
  options,
  disabled,
  ...rest
}) => {
  const { name, value, onBlur, onChange } = input;

  const valuesMap = React.useMemo(
    () => options.reduce((result, { value }) => ({ ...result, [String(value)]: value }), {}),
    [options],
  );

  const handleChange = React.useCallback(
    e => {
      // eslint-disable-next-line
      // @ts-ignore
      onChange(valuesMap[e.target.value]);
    },
    [onChange, valuesMap],
  );

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  return (
    <FormControl variant="outlined" component="fieldset" fullWidth={fullWidth} error={error}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={handleChange} onBlur={onBlur} {...rest}>
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            control={<Radio />}
            value={value}
            label={label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
