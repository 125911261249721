import React from 'react';
import { isFunction, isString } from 'src/utils';

export const useLocalStorage = (key: string): [any, (value: any) => void] => {
  const [state, setState] = React.useState<any>(localStorage.getItem(key));

  const setValue = React.useCallback(
    (value: any): void => {
      try {
        const resultValue = isFunction(value) ? value(state) : value;
        const strValue = isString(resultValue) ? resultValue : JSON.stringify(resultValue);

        window.localStorage.setItem(key, strValue);
        setState(resultValue);
      } catch (error) {
        console.error(error);
      }
    },
    [key, state],
  );

  return [state, setValue];
};
