import { gql } from '8base-react-sdk';

export const FINISHED_ASYNC_TASKS_SUBSCRIPTION = gql`
  subscription FinishedAsyncTasks {
    AsyncTasks(filter: { node: { status: { in: ["Finished", "Failed"] } }, mutation_in: update }) {
      mutation
      node {
        id
        name
        status
        result
      }
    }
  }
`;

export const FINISHED_ASYNC_TASKS_SUBSCRIPTION_FOR_EXPORT = gql`
  subscription FinishedAsyncTasksForExport {
    AsyncTasks(filter: { node: { status: { in: ["Finished", "Failed"] } }, mutation_in: update }) {
      mutation
      node {
        id
        name
        result
        status
      }
    }
  }
`;

export const GET_ASYNC_TASK_RESULT = gql`
  query AsyncTaskResult($id: ID!) {
    asyncTask(id: $id) {
      id
      result
    }
  }
`;
