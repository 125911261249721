import { gql } from '8base-react-sdk';

export const XERO_GET_AUTH_URL_MUTATION = gql`
  mutation XeroGetAuthUrl($companyId: ID!, $redirectUrl: String) {
    xeroGetAuthUrl(companyId: $companyId, redirectUrl: $redirectUrl) {
      url
    }
  }
`;

export const XERO_DELETE_TOKEN_MUTATION = gql`
  mutation XeroDeleteToken($xeroIntegrationId: ID!) {
    xeroDeleteToken(xeroIntegrationId: $xeroIntegrationId) {
      success
    }
  }
`;

export const XERO_REQUEST_METRICS_UPDATE_MUTATION = gql`
  mutation XeroRequestMetricsUpdate($companyId: ID!) {
    xeroRequestMetricsUpdate(companyId: $companyId) {
      asyncTaskId
    }
  }
`;
