import React from 'react';
import * as R from 'ramda';
import _ from 'lodash';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useNotification, usePortfolioOverview, useRouterQuery } from 'src/hooks';
import { TableColumn, TableData } from 'src/components';
import { LinkRenderer } from 'src/shared/table/renderers/LinkRenderer';
import { PaginationContainer } from 'src/shared/table/PaginationContainer';
import {
  PortfolioOverviewColumnKey,
  PortfolioOverviewColumnTitle,
  PortfolioOverviewMetricKey,
  PortfolioOverviewTableColumn,
  PREPAYMENT_VALUES,
} from 'src/constants';
import { HeaderContainer, Layout, MetricValueCell, ToolbarContainer } from './';
import { MetricValue, PortfolioOverviewCompany } from 'src/graphql';
import {
  DateFormatPatterns,
  formatDate,
  formatMoney,
  formatMonths,
  formatMultiplier,
  formatPercents,
  formatSaaSScore,
  getEndOfMonth,
  getMetricValueByMetricCode,
  getNowDate,
  getPortfolioMetricPath,
  getPortfolioOverviewExportColumns,
  getPortfolioOverviewExportRows,
  replaceIfNil,
  subtractDate,
  t,
} from 'src/utils';
import { MetricCode, Nullable } from 'src/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  tableContainer: {
    overflowX: 'scroll',
    overflowY: 'auto',

    maxHeight: 'calc(100vh - 400px)',
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  colName: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#FFF',
  },
  row: {
    height: 65,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type PortfolioOverviewTableColumn = TableColumn<PortfolioOverviewCompany>;

const PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH = 'metrics';

export const Main: React.FC = () => {
  const classes = useStyles();

  const notification = useNotification();

  const query = useRouterQuery();
  const selectedMonth = query?.month ?? null;
  const endOfPreviousMonth = getEndOfMonth(subtractDate(getNowDate()) as string);
  const selectedDate = selectedMonth ?? endOfPreviousMonth;

  const {
    data,
    exportData,
    loading,
    exportDataLoading,
    exportFetchError,
    dataFetchError,
  } = usePortfolioOverview({
    date: selectedDate,
  });

  const COLUMNS: Array<PortfolioOverviewTableColumn> = React.useMemo(
    () => [
      {
        title: PortfolioOverviewColumnTitle.CompanyName,
        key: PortfolioOverviewColumnKey.CompanyName,
        align: 'left',
        width: 200,
        className: clsx(classes.colName, classes.stickyLeft),
        render: (_: unknown, portfolioOverviewCompany) => {
          const companyName = portfolioOverviewCompany.companyName;
          const portfolioCompanyId = portfolioOverviewCompany.portfolioCompanyId;

          return LinkRenderer(companyName, `/portfolio-companies/${portfolioCompanyId}/dashboard`);
        },
      },
      {
        title: PortfolioOverviewColumnTitle.ClosingDate,
        key: PortfolioOverviewColumnKey.ClosingDate,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.ClosingDate),
        render: (closingDate: Nullable<string>) => {
          return formatDate(closingDate, DateFormatPatterns.shortDateWithSlash) || '-';
        },
      },
      {
        title: PortfolioOverviewColumnTitle.FundingDate,
        key: PortfolioOverviewColumnKey.FundingDate,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.FundingDate),
        render: (fundingDate: Nullable<string>) => {
          return formatDate(fundingDate, DateFormatPatterns.shortDateWithSlash) || '-';
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Committed,
        key: PortfolioOverviewColumnKey.Committed,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.Committed),
        render: (committed: Nullable<number>) => {
          const formattedCommitted = formatMoney(committed);

          return replaceIfNil(formattedCommitted, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Deployed,
        key: PortfolioOverviewColumnKey.Deployed,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.Deployed),
        render: (deployed: Nullable<number>) => {
          const formattedDeployed = formatMoney(deployed);

          return replaceIfNil(formattedDeployed, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Structure,
        key: PortfolioOverviewColumnKey.Structure,
        align: 'right',
        width: 180,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.Structure),
        render: (structure: Nullable<string>) => {
          return replaceIfNil(structure, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Term,
        key: PortfolioOverviewColumnKey.Term,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.Term),
        render: (term: Nullable<number>) => {
          return replaceIfNil(term, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.IOPeriod,
        key: PortfolioOverviewColumnKey.IOPeriod,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.IOPeriod),
        render: (iOPeriod: Nullable<number>) => {
          const formattedIOPeriod = formatMonths(iOPeriod);

          return replaceIfNil(formattedIOPeriod, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Prepayment,
        key: PortfolioOverviewColumnKey.Prepayment,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.Prepayment),
        render: (prepayment: Nullable<string>, portfolioCompany) => {
          const prepaymentMonthsPath = getPortfolioMetricPath(
            PortfolioOverviewMetricKey.PrepaymentMonths,
          );
          const prepaymentMonths = _.get(portfolioCompany, prepaymentMonthsPath, null);

          if (R.isNil(prepayment)) {
            return '-';
          }

          const formattedPrepayment =
            prepayment === PREPAYMENT_VALUES.reducedCashOnCash
              ? prepayment
              : formatMonths(prepaymentMonths);

          return formattedPrepayment;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.AdvanceRate,
        key: PortfolioOverviewColumnKey.AdvanceRate,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.AdvancedRate),
        render: (advanceRate: Nullable<number>) => {
          const formattedAdvanceRate = formatMultiplier(advanceRate);

          return replaceIfNil(formattedAdvanceRate, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.InterestRate,
        key: PortfolioOverviewColumnKey.InterestRate,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.InterestRate),
        render: (interestRate: Nullable<number>) => {
          const formattedInterestRate = formatPercents(interestRate);

          return replaceIfNil(formattedInterestRate, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.ReturnCap,
        key: PortfolioOverviewColumnKey.ReturnCap,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.ReturnCap),
        render: (returnCap: Nullable<number>) => {
          const formattedReturnCap = formatMultiplier(returnCap);

          return replaceIfNil(formattedReturnCap, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.ClosingFee,
        key: PortfolioOverviewColumnKey.ClosingFee,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.ClosingFee),
        render: (closingFee: Nullable<string>, portfolioCompany) => {
          const closingFeeValuePath = getPortfolioMetricPath(
            PortfolioOverviewMetricKey.ClosingFeeValue,
          );
          const closingFeeValue = _.get(portfolioCompany, closingFeeValuePath, null);

          const formattedClosingFeeValue = formatPercents(closingFeeValue);
          const formattedClosingFee = closingFeeValue
            ? `${formattedClosingFeeValue} (${closingFee})`
            : null;

          return replaceIfNil(formattedClosingFee, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.SuccessFee,
        key: PortfolioOverviewColumnKey.SuccessFee,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.SuccessFee),
        render: (successFee: Nullable<number>) => {
          const formattedSuccessFee = formatPercents(successFee);

          return replaceIfNil(formattedSuccessFee, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.ExitFee,
        key: PortfolioOverviewColumnKey.ExitFee,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.ExitFee),
        render: (exitFee: Nullable<number>) => {
          const formattedExitFee = formatPercents(exitFee);

          return replaceIfNil(formattedExitFee, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.IRR,
        key: PortfolioOverviewColumnKey.IRR,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.IRR),
        render: (iRR: Nullable<number>) => {
          const formattedIRR = formatPercents(iRR);

          return replaceIfNil(formattedIRR, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.MOIC,
        key: PortfolioOverviewColumnKey.MOIC,
        align: 'right',
        width: 80,
        dataPath: getPortfolioMetricPath(PortfolioOverviewMetricKey.MOIC),
        render: (mOIC: Nullable<number>) => {
          const formattedMOIC = formatMultiplier(mOIC);

          return replaceIfNil(formattedMOIC, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Revenue,
        key: PortfolioOverviewColumnKey.TOTAL_REVENUE,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.TotalRevenue);

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.RevenueGrows,
        key: PortfolioOverviewColumnKey.REVENUE_GROWTH_RATE_MOM,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(
            metricValues,
            MetricCode.RevenueGrowthRateMoM,
          );

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.MRR,
        key: PortfolioOverviewColumnKey.ENDING_MRR,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.EndingMRR);

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.MRRGrowth,
        key: PortfolioOverviewColumnKey.MRR_NET_GROWTH_RATE,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.MRRNetGrowthRate);

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.GrossMargin,
        key: PortfolioOverviewColumnKey.GROSS_MARGIN,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.GrossMargin);

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.NetCashBurn,
        key: PortfolioOverviewColumnKey.ADJUSTED_NET_CASH_BURN,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(
            metricValues,
            MetricCode.AdjustedNetCashBurn,
          );

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.CashBalance,
        key: PortfolioOverviewColumnKey.CASH_POSITION,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.CashPosition);

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.CashRunway,
        key: PortfolioOverviewColumnKey.CASH_RUNWAY_ACCOUNTING,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(
            metricValues,
            MetricCode.CashRunwayAccounting,
          );

          return <MetricValueCell metricValue={metricValue} />;
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Tier,
        key: PortfolioOverviewColumnKey.Tier,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.SaaSScore);

          return replaceIfNil(metricValue?.tier, '-');
        },
      },
      {
        title: PortfolioOverviewColumnTitle.Score,
        key: PortfolioOverviewColumnKey.SAAS_SCORE,
        align: 'right',
        dataPath: PORTFOLIO_OVERVIEW_METRIC_VALUES_PATH,
        render: (metricValues: Array<MetricValue>) => {
          const metricValue = getMetricValueByMetricCode(metricValues, MetricCode.SaaSScore);
          const score = metricValue?.value;
          const formattedScore = formatSaaSScore(score);

          return replaceIfNil(formattedScore, '-');
        },
      },
    ],
    [classes.colName, classes.stickyLeft],
  );

  const isFirstLoading = !data && loading;
  const hasCompanies = !isFirstLoading && !R.isEmpty(data.companies);

  const columns = React.useMemo(() => {
    return getPortfolioOverviewExportColumns();
  }, []);

  const rows = React.useMemo(() => {
    return getPortfolioOverviewExportRows(exportData.companies);
  }, [exportData.companies]);

  if (exportFetchError) {
    notification.error(t('portfolio_overview_export_data_fetch_error'));
  }

  if (dataFetchError) {
    notification.error(t('portfolio_overview_data_fetch_error'));
  }

  return (
    <Layout
      loading={loading}
      isFirstLoading={isFirstLoading}
      hasCompanies={hasCompanies}
      renderHeader={
        <HeaderContainer
          tableExportData={{ columns, rows }}
          exportDataLoading={exportDataLoading || loading}
        />
      }
      renderToolbar={<ToolbarContainer initialSelectedDate={selectedDate} />}
      renderTable={
        <TableData
          dataSource={data.companies}
          columns={React.useMemo(() => COLUMNS, [COLUMNS])}
          tableContainerProps={{ className: classes.tableContainer }}
        />
      }
      renderPagination={<PaginationContainer count={data.totalCount} />}
    />
  );
};
