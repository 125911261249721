import React from 'react';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  Typography,
} from '@material-ui/core';

import { useModal, Form, Field, TextField } from 'src/app-builder';
import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { EVENT_RESOLVER_MUTATION, EventType } from 'src/graphql';
import { ApplicantInviteSchema } from 'src/utils/schemas';
import { validateDataByYup, commonErrorResolver, t } from 'src/utils';

import { DIALOGS } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface ApplicantInviteDialogProps {}

// eslint-disable-next-line react/display-name
export const ApplicantInviteDialog: React.FC<ApplicantInviteDialogProps> = React.memo(() => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, closeModal } = useModal(DIALOGS.APPLICANT_INVITE_DIALOG);

  const [applicantInvitationSend] = useMutation(EVENT_RESOLVER_MUTATION, {
    onCompleted: () => {
      notification.success(t('applicant_invite_sent_success'));
      closeModal();
    },
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const onSubmit = React.useCallback(
    async data => {
      await applicantInvitationSend({
        variables: {
          event: data,
        },
      });
    },
    [applicantInvitationSend],
  );

  const onClose = React.useCallback(
    (form: any) => {
      closeModal();

      setTimeout(form.reset);
    },
    [closeModal],
  );

  const initialValues = {
    type: EventType.ApplicantInvitationSend,
  };

  if (!open) {
    return null;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={values => validateDataByYup(ApplicantInviteSchema, values)}
    >
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => {
        return (
          <Dialog
            disableRestoreFocus
            open={open}
            onClose={() => onClose(form)}
            PaperProps={{
              className: classes.root,
              component: 'form',
              onSubmit: handleSubmit,
            }}
            maxWidth="md"
          >
            <DialogTitle disableTypography>
              <Typography variant="h6">Invite Applicant</Typography>
            </DialogTitle>

            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field
                    component={TextField}
                    name="payload.firstName"
                    label="First Name*"
                    disabled={submitting}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Field
                    component={TextField}
                    name="payload.lastName"
                    label="Last Name*"
                    disabled={submitting}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Field
                    component={TextField}
                    name="payload.email"
                    label="Email*"
                    disabled={submitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={() => onClose(form)} disabled={submitting}>
                Cancel
              </Button>
              <SubmitButton
                color="secondary"
                variant="contained"
                type="submit"
                loading={submitting}
                disabled={pristine}
              >
                Invite
              </SubmitButton>
            </DialogActions>
          </Dialog>
        );
      }}
    </Form>
  );
});
