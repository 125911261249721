import React from 'react';
import { TableShared } from 'src/shared/table/TableShared';
import { SortableLoanApplication } from 'src/graphql';
import { ColumnsType } from 'rc-table/es/interface';

interface TableProps {
  data: SortableLoanApplication[];
  columns: ColumnsType<any>;
  classes: Record<any, any>;
}

export const Table: React.FC<TableProps> = ({ data, columns, classes }) => {
  return (
    <TableShared
      rowKey={(row: SortableLoanApplication) => row.id as string}
      data={data}
      rowClassName={classes.row}
      className={classes.table}
      columns={columns}
      scroll={{ x: 'auto' }}
    />
  );
};
