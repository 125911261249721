import * as R from 'ramda';
import React from 'react';
import * as icons from '@material-ui/icons';

import { SortDirection, SORT_DIRECTIONS } from '../hooks';
import { FieldSchema, isListField } from '@8base/utils';
import { AppAreaField } from 'src/constants/app';
import { Maybe } from 'src/graphql';

export const getFilePreviewUrl = (url: string, height: number): string =>
  url.replace(
    '/security=',
    `/output=format:jpg,page:1,secure:true/resize=height:${height.toString()}/security=`,
  );

export const toggleSortDirection = (direction: SortDirection): SortDirection =>
  (direction === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC) as SortDirection;

export const isActiveSort = (
  sort: Array<{ by: string; dir: SortDirection }>,
  fieldName: string,
): boolean => {
  return R.findIndex(R.propEq('by', fieldName), sort) !== -1;
};

export const canSort = (areaField: AppAreaField, tableField?: FieldSchema): boolean => {
  if (
    !areaField.sortEnabled ||
    areaField.fieldName.indexOf('items') !== -1 ||
    (tableField && isListField(tableField))
  ) {
    return false;
  }

  return true;
};

export const getSortDirection = (
  sort: Array<{ by: string; dir: SortDirection }>,
  fieldName: string,
): SortDirection => R.find(R.propEq('by', fieldName), sort)?.dir as SortDirection;

export const toSortVariables = (sort: Array<{ by: string; dir: SortDirection }>): any =>
  sort.map(({ by, dir }) => {
    if (by.indexOf('.') !== -1) {
      return R.assocPath(by.split('.'), dir.toUpperCase(), {});
    }

    return { [by]: dir.toUpperCase() };
  });

export const renderIcon = (icon: string | JSX.Element): React.ReactNode => {
  if (typeof icon === 'string') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return R.has(icon, icons) ? React.createElement(icons[icon as string]) : null;
  }

  return icon;
};

export const getUserInitials = (
  user?: { firstName?: Maybe<string>; lastName?: Maybe<string> } | null,
): string =>
  (user?.firstName?.slice(0, 1).toLocaleUpperCase() || '') +
  (user?.lastName?.slice(0, 1).toLocaleUpperCase() || '');
