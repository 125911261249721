import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { Button, makeStyles } from '@material-ui/core';

import { useNotification } from 'src/hooks';
import { metricSettingsContext, scaleByTierContext, dirtyContext } from './index';
import { EVENT_RESOLVER_MUTATION, EventType, UPDATE_METRIC_SETTINGS } from 'src/graphql';
import { mapValuesToUpdate } from '../helpers';
import { SubmitButton } from 'src/components';
import { loanMultipliersContext } from 'src/routes/settings/MasterDataPage/components/LoanMultipliersProvider';
import { t } from 'src/utils';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  resetByDefaultButton: {
    width: 180,
  },
  resetButton: {
    marginRight: 16,
  },
  button: {
    width: 120,
  },
}));

export const SubmitBlock: React.FC = () => {
  const classes = useStyles();
  const notification = useNotification();

  const [resetProcess, setResetProcess] = React.useState(false);
  const { settings, resetMetricSettingsTable } = useContext(metricSettingsContext);
  const { scaleByTierSettings, resetScaleByTierTables } = useContext(scaleByTierContext);
  const { loanMultiplierSettings, resetLoanMultiplierSettings } = useContext(
    loanMultipliersContext,
  );
  const { isDirty: isButtonActive, setIsDirty } = useContext(dirtyContext);

  const [updateRequest, { loading }] = useMutation(UPDATE_METRIC_SETTINGS, {
    refetchQueries: ['MetricsList'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      notification.success(t('update_metric_settings_success'));
      setIsDirty(false);
    },
    onError: () => notification.error(t('update_metric_settings_error')),
  });

  const handleSave = () => {
    updateRequest({
      variables: {
        settings: mapValuesToUpdate(settings, scaleByTierSettings, loanMultiplierSettings),
      },
    });
  };

  const handleReset = () => {
    resetMetricSettingsTable();
    resetScaleByTierTables();
    resetLoanMultiplierSettings();
    setIsDirty(false);
  };

  const [resetMasterData] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['MetricsList'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      notification.success(t('reset_metric_settings_by_default_success'));
      setResetProcess(false);
    },
    onError: () => notification.error(t('reset_metric_settings_by_default_error')),
  });

  const handleResetByDefault = async () => {
    setResetProcess(() => true);
    await resetMasterData({
      variables: {
        event: {
          type: EventType.ResetMasterDataByDefault,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <SubmitButton
        className={`${classes.resetButton} ${classes.resetByDefaultButton}`}
        onClick={handleResetByDefault}
        variant="contained"
        size="large"
        loading={resetProcess}
        disabled={resetProcess}
      >
        Reset by default
      </SubmitButton>
      <Button
        className={`${classes.resetButton} ${classes.button}`}
        onClick={handleReset}
        variant="contained"
        size="large"
        disabled={!isButtonActive || loading}
      >
        Reset
      </Button>
      <SubmitButton
        loading={loading}
        className={classes.button}
        onClick={handleSave}
        color="secondary"
        variant="contained"
        size="large"
        disabled={!isButtonActive}
      >
        Save
      </SubmitButton>
    </div>
  );
};
