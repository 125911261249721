import { ChangedMetric } from 'src/types';

export interface ILocalStorage {
  storageKey: string;
  get(key: string): any;
  set(key: string, value: any): void;
  remove(key: string): boolean;
  has(key: string): boolean;
  keys(): string[];
}

export enum LocalStorageKeys {
  Storage = 'BFC',
  CompaniesWithChange = 'CompaniesWithChange',
  SaaSScoreTableTogglesExpanded = 'SaaSScoreTableTogglesExpanded',
  OnboardedUsers = 'OnboardedUsers',
}

export type CompaniesWithMetricChange = Record<string, Record<string, ChangedMetric>>;

export type OnboardedUsers = string[];
