import { gql } from '8base-react-sdk';

export const COMPANY_FRAGMENT = gql`
  fragment CompanyFragment on Company {
    id
    createdAt
    updatedAt
    createdBy {
      id
    }
    loanApplication {
      id
      stage
    }
    portfolioCompany {
      id
    }
    facilities(first: 1, sort: { fundingDate: DESC }) {
      items {
        id
        structure
        fundingDate
        amountDeployed
      }
    }
    name
    active
    monitoringStatus
    primaryCustomerBase
    primaryBusinessModel
    address {
      country
      street1
      street2
      zip
      city
      state
    }
    team {
      items {
        firstName
        lastName
        id
        email
      }
    }
    owner {
      firstName
      lastName
      id
      email
    }
    borrower
    borrowedDebt
    idealRaiseAmount
    foundedYear
    companyStory
    founderStory
    productDescription
    marketDescription
    customersStrategy
    capital
    capitalType
    capitalStrategy
    equityCapitalAmount
    equityCapitalOwner
    monthsToNextRound
    securedDebt
    structure
    status
    contactTitle
    contactFirstName
    contactLastName
    contactEmail
    _description
  }
`;

export const COMPANIES_QUERY = gql`
  query CompaniesEntity($id: ID!) {
    company(id: $id) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const COMPANIES_CREATE_MUTATION = gql`
  mutation CompaniesCreate($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const COMPANIES_DELETE_MUTATION = gql`
  mutation CompaniesDelete($filter: CompanyKeyFilter!, $force: Boolean) {
    companyDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const COMPANIES_EDIT_MUTATION = gql`
  mutation CompaniesUpdate($data: CompanyUpdateInput!, $filter: CompanyKeyFilter) {
    companyUpdate(data: $data, filter: $filter) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const FUNDING_DATE_BY_COMPANY_QUERY = gql`
  query FundingDateByCompany($companyId: ID!) {
    getFundingDate(companyId: $companyId) {
      fundingDate
    }
  }
`;

export const AMOUNT_COMMITTED_BY_COMPANY_QUERY = gql`
  query AmountCommitted($companyId: ID!) {
    getAmountCommitted(companyId: $companyId) {
      amountCommitted
    }
  }
`;

export const TOTAL_AMOUNT_DEPLOYED_BY_COMPANY_QUERY = gql`
  query TotalAmountDeployed($companyId: ID!) {
    getTotalAmountDeployed(companyId: $companyId) {
      totalAmountDeployed
    }
  }
`;
