import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Theme, LinearProgress } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { useAllowed } from 'src/app-builder/providers';
import { useLoanApplication } from 'src/hooks';
import { ModalsContext } from 'src/app-builder';

import { FacilityAccordion, EmptyScreen } from 'src/components';
import {
  FacilityCreateDialog,
  FacilityEditDialog,
  FacilityDeleteDialog,
  DrawCreateDialog,
  DrawEditDialog,
  DrawDeleteDialog,
  ConfirmationDialog,
  DIALOGS,
} from 'src/dialogs';
import {
  FacilitiesTableContentQuery,
  FacilitiesTableContentQueryVariables,
  SortOrder,
  FACILITIES_TABLE_QUERY,
} from 'src/graphql';
import { Permission } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
  content: {
    padding: theme.spacing(2),
    overflow: 'auto',
    flex: 1,
  },
  tableCellLabel: {
    fontWeight: 500,
    height: theme.spacing(6),
  },
}));

type Params = { id: string };

export const LoanApplicationFacilityAndDrawsPage: React.FC<any> = () => {
  const { openModal } = React.useContext(ModalsContext);

  const classes = useStyles();

  const { id: loanApplicationId }: Params = useParams();

  const { data: loanApplication, loading: loanApplicationLoading } = useLoanApplication();
  const companyId = loanApplication?.company?.id as string;

  const isAllowed = useAllowed();
  const canAddFacility = isAllowed(Permission.LoanApplicationsAddFacility);

  const { data: facilitiesData, loading: facilitiesLoading } = useQuery<
    FacilitiesTableContentQuery,
    FacilitiesTableContentQueryVariables
  >(FACILITIES_TABLE_QUERY, {
    variables: {
      sort: [{ createdAt: SortOrder.Desc }],
      filter: {
        company: {
          loanApplication: {
            id: {
              equals: loanApplicationId,
            },
          },
        },
      },
    },
    skip: !Boolean(loanApplicationId),
  });

  const openCreateFacilityDialog = React.useCallback(() => {
    openModal(DIALOGS.FACILITY_CREATE_DIALOG, { companyId });
  }, [openModal, companyId]);

  const facilities = React.useMemo(() => facilitiesData?.facilitiesList?.items || [], [
    facilitiesData,
  ]);

  const loading = facilitiesLoading || loanApplicationLoading;
  const facilitiesEmpty = facilities.length === 0;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Facility &amp; Draws</Typography>

        {!loading && !facilitiesEmpty && (
          <Button
            disabled={!canAddFacility}
            color="secondary"
            variant="outlined"
            onClick={openCreateFacilityDialog}
          >
            Add Facility
          </Button>
        )}
      </Grid>

      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column" wrap="nowrap">
          {facilitiesEmpty && (
            <EmptyScreen
              text="We couldn't find any facilites"
              actionText="Add facilites"
              icon={icons.Assignment}
              onClick={openCreateFacilityDialog}
            />
          )}

          {!facilitiesEmpty &&
            facilities.map((facility: any, index: number) => (
              <FacilityAccordion
                key={facility?.id}
                defaultExpanded={index === 0}
                facility={facility}
                companyId={companyId}
                index={facilities.length - index}
              />
            ))}
        </Grid>
      )}
      <FacilityCreateDialog />
      <FacilityEditDialog />
      <FacilityDeleteDialog />
      <DrawCreateDialog />
      <DrawEditDialog />
      <DrawDeleteDialog />
      <ConfirmationDialog />
    </Grid>
  );
};
