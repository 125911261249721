import React from 'react';
import clsx from 'clsx';
import * as R from 'ramda';

import { getFormattedMetricValue, isNegative } from 'src/utils';
import { Format } from 'src/constants';

import classes from './StatementsCell.module.css';

interface StatementsCellProps {
  value: any | null;
}

export const StatementsCell: React.FC<StatementsCellProps> = ({ value }) => {
  const formattedValue = !R.isNil(value) ? getFormattedMetricValue(value, Format.Money) : '–';

  return (
    <div
      className={clsx(classes.cell, {
        [classes.lessThanZero]: isNegative(Number(value)),
      })}
    >
      {formattedValue}
    </div>
  );
};
