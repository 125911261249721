import React from 'react';
import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { useAuth } from '8base-react-sdk';

import { useCurrentUser, UserStatuses } from '../hooks';

interface ApplicationProviderContentProps {
  loading: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => {
  const { fontFamily } = theme.typography;
  return {
    suspendedContainer: {
      left: '50%',
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      fontSize: '16px',
      fontFamily,
    },
    logoutButton: {
      cursor: 'pointer',
      textDecoration: 'underline',
      background: 'none',
      border: 'none',
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontFamily,
    },
  };
});

const InactiveUserPlaceholder: React.FC = () => {
  const classes = useStyles();
  const { authClient } = useAuth();

  const onLogout = React.useCallback(async () => {
    await authClient.logout();

    await authClient.purgeState();
  }, [authClient]);

  return (
    <div className={classes.suspendedContainer}>
      Your account has been suspended
      <br />
      <button type="button" className={classes.logoutButton} onClick={onLogout}>
        Try login to another account
      </button>
    </div>
  );
};

export const ApplicationProviderContent: React.FC<ApplicationProviderContentProps> = ({
  loading,
  children,
}) => {
  const { user, loading: currentUserLoading } = useCurrentUser();

  if (loading || currentUserLoading) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (user?.status === UserStatuses.INACTIVE) {
    return <InactiveUserPlaceholder />;
  }

  return <>{children}</>;
};
