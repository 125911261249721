import { gql } from '8base-react-sdk';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    createdAt
    updatedAt
    avatar {
      id
      downloadUrl
    }
    firstName
    lastName
    email
    title
    status
    invitation {
      id
    }
    roles {
      items {
        id
        name
      }
    }
    _description
  }
`;

export const USER_COMPANY_QUERY = gql`
  query UserCompany {
    user {
      id
      company {
        id
        primaryCustomerBase
        applicationFinalized
        owner {
          id
        }
      }
      employer {
        id
        primaryCustomerBase
        applicationFinalized
      }
    }
  }
`;

export const USER_DELETE_MUTATION = gql`
  mutation UserDelete($filter: UserKeyFilter, $force: Boolean) {
    userDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const TEAM_EDIT_QUERY = gql`
  query TeammateQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      title
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

export const USER_EDIT_QUERY = gql`
  query EmployeeQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

export const USERS_TABLE_QUERY = gql`
  query UsersTableContent(
    $filter: UserFilter
    $orderBy: [UserOrderBy]
    $sort: [UserSort!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
  ) {
    tableData: usersList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
      skip: $skip
    ) {
      items {
        ...UserFragment
      }
      count
    }
  }
  ${USER_FRAGMENT}
`;
