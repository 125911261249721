import { MetricsListQuery, MetricValue, MetricValuesListQuery } from 'src/graphql';
import { MetricCode, MetricPeriod, ScoreMetricItem } from 'src/types';
import { SAASSCORE_SHARED_METRICS_WITH_OPTIONS } from 'src/constants';
import {
  generateEmptyMetricValue,
  getMetricName,
  getMetricValue,
  getMetricValueBy,
  isEqualMetricCode,
  isEqualMetricPeriod,
  isUndefined,
} from 'src/utils';

interface Params {
  metricCodes: MetricCode[];
  metrics?: MetricsListQuery;
  values?: MetricValuesListQuery;
  dates: string[];
}

const compareMetric = (
  metricValue: MetricValue,
  metricCode: MetricCode,
  period: MetricPeriod,
): boolean => {
  return isEqualMetricCode(metricValue, metricCode) && isEqualMetricPeriod(metricValue, period);
};

export const structureTableData = ({
  metricCodes,
  metrics,
  values,
  dates,
}: Params): ScoreMetricItem[] => {
  if (!metrics || !values) return [];

  const metricsMap = Object.fromEntries(
    metrics?.metricsList.items.map(metric => [metric.code, metric]),
  );

  const firstRow: any = {
    name: 'Score & Tier',
    code: '',
  };

  dates.forEach(date => {
    firstRow[date] = getMetricValue(values, date, MetricCode.SaaSScore) as MetricValue;
  });

  const secondRow = {
    name: 'Metric',
    code: '',
  };

  const lastIndexOfMainPartScoreMetrics = metricCodes.lastIndexOf(
    MetricCode.NetCashBurnOfGrossProfit,
  );

  const otherRows = metricCodes.map((metricCode: MetricCode, metricIdx: number) => {
    const isMainPart = metricIdx <= lastIndexOfMainPartScoreMetrics;

    const metric = metricsMap[metricCode];
    const metricFormat = metric?.format;
    const metricName = getMetricName(metric) || metricCode;

    const row: any = {
      name: metricName,
      code: metricCode,
      format: metricFormat,
    };

    if (isMainPart) {
      // Rows by main score metrics (Metric Period = 4 months)
      const getMetricByThirdPeriod = (metricValue: MetricValue) => {
        return compareMetric(metricValue, metricCode, MetricPeriod.Third);
      };

      dates.forEach(date => {
        const metricValue = getMetricValueBy(values, date, getMetricByThirdPeriod) as MetricValue;

        if (isUndefined(metricValue)) {
          row[date] = generateEmptyMetricValue(
            date,
            metricCode,
            metricName,
            MetricPeriod.Third,
            metricIdx,
            metricFormat,
          );
          return;
        }

        row[date] = metricValue;
      });
    } else {
      // Rows by shared score metrics (Metric Period = Shared Score Metric Option)
      const period = SAASSCORE_SHARED_METRICS_WITH_OPTIONS[metricCode]?.period;
      const getMetricBySharedPeriod = (metricValue: MetricValue) => {
        return compareMetric(metricValue, metricCode, period);
      };

      dates.forEach(date => {
        const metricValue = getMetricValueBy(values, date, getMetricBySharedPeriod) as MetricValue;

        if (isUndefined(metricValue)) {
          row[date] = generateEmptyMetricValue(
            date,
            metricCode,
            metricName,
            period,
            metricIdx,
            metricFormat,
          );
          return;
        }

        row[date] = metricValue;
      });
    }

    return row;
  });

  return [firstRow, secondRow, ...otherRows];
};
