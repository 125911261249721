import React from 'react';
import { useMutation } from 'react-apollo';
import { FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { useModal, Form, Field, TextField, NumberField, SelectField } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { EVENT_RESOLVER_MUTATION, EventType } from 'src/graphql';
import {
  STATE_OPTIONS_BY_COUNTRY,
  CAPITAL_SPEND_ON_OPTIONS,
  CAPITAL_TYPES_OPTIONS,
  FROM_WHOM_CAPITAL_RAISED_OPTIONS,
  FROM_WHOM_BORROWED,
  PRIMARY_BUSINESS_MODEL_OPTIONS,
  PRIMARY_CUSTOMER_BASE_OPTIONS,
  BUSINESS_LOCATION_OPTIONS,
} from 'src/constants';
import { DIALOGS } from './constants';
import { PortfolioCompanyCreateSchema } from 'src/utils/schemas';
import {
  baseNumberFormat,
  validateDataByYup,
  yearFormat,
  trimString,
  parseStringToFloat,
  commonErrorResolver,
  t,
  companyStoryLabel,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface PortfolioCompanyCreateDialogProps {}

// eslint-disable-next-line react/display-name
export const PortfolioCompanyCreateDialog: React.FC<PortfolioCompanyCreateDialogProps> = React.memo(
  () => {
    const classes = useStyles();

    const notification = useNotification();

    const { open, closeModal, openModal } = useModal(DIALOGS.PORTFOLIO_COMPANY_CREATE_DIALOG);

    const [portfolioCompanyCreate] = useMutation(EVENT_RESOLVER_MUTATION, {
      refetchQueries: ['SortablePortfolioCompaniesList'],
      awaitRefetchQueries: true,
    });

    const onSubmit = React.useCallback(
      async (companyData, form) => {
        try {
          await portfolioCompanyCreate({
            variables: {
              event: {
                type: EventType.PortfolioCompanyCreate,
                payload: {
                  ...companyData,
                },
              },
            },
          });
          notification.success(t('portfolio_company_create_success'));

          closeModal();

          setTimeout(form.reset);
        } catch (error) {
          console.error(error);
          notification.error(commonErrorResolver(error));
        }
      },
      [closeModal, portfolioCompanyCreate, notification],
    );

    const onClose = React.useCallback(
      (form: any, pristine: boolean) => {
        if (!pristine) {
          openModal(DIALOGS.DATA_LOSS_DIALOG, {
            onConfirm: () => {
              closeModal();

              setTimeout(form.reset);
            },
          });
        } else {
          closeModal();

          setTimeout(form.reset);
        }
      },
      [closeModal, openModal],
    );

    if (!open) {
      return null;
    }

    return (
      <Form
        type="CREATE"
        subscription={{ submitting: true, pristine: true }}
        onSubmit={onSubmit}
        validate={values => validateDataByYup(PortfolioCompanyCreateSchema, values)}
      >
        {({ handleSubmit, form, submitting, pristine }): React.ReactNode => {
          return (
            <Dialog
              disableRestoreFocus
              open={open}
              onClose={() => onClose(form, pristine)}
              PaperProps={{
                className: classes.root,
                component: 'form',
                onSubmit: handleSubmit,
              }}
              maxWidth="md"
            >
              <DialogTitle disableTypography>
                <Typography variant="h6">Add New Company</Typography>
              </DialogTitle>

              <DialogContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="subtitle1">I. Company Information</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="name"
                        label="Company Name"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                        format={trimString}
                        formatOnBlur={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="foundedYear"
                        label="Year Founded"
                        component={NumberField}
                        numberFormat={yearFormat}
                        fullWidth
                        disabled={submitting}
                        parse={parseStringToFloat}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="contactFirstName"
                        label="Contact First Name"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="contactLastName"
                        label="Contact Last Name"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="contactTitle"
                        label="Contact Title"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="contactEmail"
                        label="Contact Email"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="primaryBusinessModel"
                        label="Primary Business Model"
                        component={SelectField}
                        options={PRIMARY_BUSINESS_MODEL_OPTIONS}
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="primaryCustomerBase"
                        label="Primary Customer Base"
                        component={SelectField}
                        fullWidth
                        options={PRIMARY_CUSTOMER_BASE_OPTIONS}
                        disabled={submitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item md={6}>
                      <Field
                        name="address.country"
                        label="Country"
                        component={SelectField}
                        fullWidth
                        options={BUSINESS_LOCATION_OPTIONS}
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }: any) => (
                          <Field
                            name="address.state"
                            label="State / Province"
                            component={SelectField}
                            options={R.propOr(
                              [],
                              values?.address?.country,
                              STATE_OPTIONS_BY_COUNTRY,
                            )}
                            fullWidth
                            disabled={submitting}
                          />
                        )}
                      </FormSpy>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item md={6}>
                      <Field
                        name="address.city"
                        label="City"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Field
                        name="address.zip"
                        label="Zip Code"
                        component={TextField}
                        fullWidth
                        disabled={submitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Field
                      name="address.street1"
                      label="Street Address"
                      component={TextField}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">II. Company and Founder(s) Story</Typography>
                  </Grid>
                  <Grid item>
                    <Field
                      name="founderStory"
                      label="What is the Founder(s) story?"
                      component={TextField}
                      fullWidth
                      multiline
                      rows={3}
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <FormSpy subscription={{ values: true }}>
                      {({ values }: any) => (
                        <Field
                          name="companyStory"
                          label={companyStoryLabel(values.name)}
                          component={TextField}
                          fullWidth
                          multiline
                          rows={3}
                          disabled={submitting}
                        />
                      )}
                    </FormSpy>
                  </Grid>
                  {/**/}
                  <Grid item>
                    <Typography variant="subtitle1">III. Product/Market</Typography>
                  </Grid>
                  <Grid item>
                    <Field
                      name="productDescription"
                      label="Product Description"
                      component={TextField}
                      fullWidth
                      multiline
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="marketDescription"
                      label="Market Description"
                      component={TextField}
                      fullWidth
                      multiline
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="customersStrategy"
                      label="How do you get customers?"
                      component={TextField}
                      fullWidth
                      multiline
                      disabled={submitting}
                    />
                  </Grid>
                  {/**/}
                  <Grid item>
                    <Typography variant="subtitle1">IV. Capital</Typography>
                  </Grid>
                  <Grid item>
                    <Field
                      name="capital"
                      label="How much capital are you seeking?"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      parse={parseStringToFloat}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="capitalType"
                      label="What type of capital are you seeking?"
                      component={SelectField}
                      options={CAPITAL_TYPES_OPTIONS}
                      fullWidth
                      multiple
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="capitalStrategy"
                      label="What are you planning to spend it on?"
                      component={SelectField}
                      options={CAPITAL_SPEND_ON_OPTIONS}
                      fullWidth
                      multiple
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="equityCapitalAmount"
                      label="How much equity (including convertible debt) have you raised?"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      parse={parseStringToFloat}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="equityCapitalOwner"
                      label="From whom?"
                      component={SelectField}
                      options={FROM_WHOM_CAPITAL_RAISED_OPTIONS}
                      multiple
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="monthsToNextRound"
                      label="How many months to your next equity round (ideally)?"
                      component={NumberField}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      parse={parseStringToFloat}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="idealRaiseAmount"
                      label="Ideal raise amount"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      parse={parseStringToFloat}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="borrowedDebt"
                      label="How much have you borrowed (excluding convertible debt)?"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      parse={parseStringToFloat}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="borrower"
                      label="From whom?"
                      component={SelectField}
                      options={FROM_WHOM_BORROWED}
                      multiple
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="securedDebt"
                      label="How much of your debt is secured?"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={baseNumberFormat}
                      parse={parseStringToFloat}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => onClose(form, pristine)}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <SubmitButton
                  color="secondary"
                  variant="contained"
                  type="submit"
                  loading={submitting}
                  disabled={pristine}
                >
                  Add Company
                </SubmitButton>
              </DialogActions>
            </Dialog>
          );
        }}
      </Form>
    );
  },
);
