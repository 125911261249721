import { AsyncTaskStatus } from 'src/types';
import { AsyncTask } from 'src/graphql';

export const anyAsyncTaskFailed = (asyncTasks: Record<string, AsyncTask>): boolean =>
  Object.values(asyncTasks).some(item => {
    return item.status === AsyncTaskStatus.Failed;
  });

export const anyAsyncTaskSucceded = (asyncTasks: Record<string, AsyncTask>): boolean =>
  Object.values(asyncTasks).some(item => {
    return item.status === AsyncTaskStatus.Finished;
  });

export const allAsyncTaskFinished = (asyncTasks: Record<string, AsyncTask>): boolean =>
  Object.values(asyncTasks).every(item => {
    return item.status !== AsyncTaskStatus.Pending;
  });
