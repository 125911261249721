import React from 'react';
import * as R from 'ramda';
import { useMutation, useSubscription } from 'react-apollo';

import { useNotification, useIntegrations } from 'src/hooks';
import {
  AsyncTask,
  FINISHED_ASYNC_TASKS_SUBSCRIPTION,
  PLAID_REQUEST_METRICS_UPDATE_MUTATION,
  QUICKBOOKS_REQUEST_METRICS_UPDATE_MUTATION,
  XERO_REQUEST_METRICS_UPDATE_MUTATION,
} from 'src/graphql';
import { isFunction, t } from 'src/utils';
import { Integration } from 'src/constants';
import { AsyncTaskStatus } from 'src/types';
import { allAsyncTaskFinished, anyAsyncTaskSucceded } from 'src/utils/asyncTasks';

type AsyncTaskExtended = AsyncTask & {
  from?: Integration;
};

export const useMetricsUpdate = (
  companyId: string,
  recalculateMetrics: () => void,
  options: {
    onCompleted?: () => void;
    onError?: () => void;
  } = {},
): [() => void, { loading: boolean; error: boolean; disabled: boolean }] => {
  const notification = useNotification();

  const {
    loading: integrationsLoading,
    isPlaidIntegrationConnected,
    isQuickBooksIntegrationConnected,
    isXeroIntegrationConnected,
  } = useIntegrations(companyId);

  const hasConnectedIntegrations = React.useMemo(
    () =>
      isPlaidIntegrationConnected || isQuickBooksIntegrationConnected || isXeroIntegrationConnected,
    [isPlaidIntegrationConnected, isQuickBooksIntegrationConnected, isXeroIntegrationConnected],
  );

  const [plaidRequestMetricsUpdate] = useMutation(PLAID_REQUEST_METRICS_UPDATE_MUTATION);
  const [quickBooksRequestMetricsUpdate] = useMutation(QUICKBOOKS_REQUEST_METRICS_UPDATE_MUTATION);
  const [xeroRequestMetricsUpdate] = useMutation(XERO_REQUEST_METRICS_UPDATE_MUTATION);

  const showNotificationSuccessOnUpdateMetrics = (from: Integration): void => {
    switch (from) {
      case Integration.Plaid: {
        notification.success(t('update_metrics_from_plaid_success'));
        break;
      }
      case Integration.QuickBooks: {
        notification.success(t('update_metrics_from_quick_books_success'));
        break;
      }
      case Integration.Xero: {
        notification.success(t('update_metrics_from_xero_success'));
        break;
      }
    }
  };

  const showNotificationErrorOnUpdateMetrics = (from: Integration): void => {
    switch (from) {
      case Integration.Plaid: {
        notification.error(t('update_metrics_from_plaid_error'));
        break;
      }
      case Integration.QuickBooks: {
        notification.error(t('update_metrics_from_quick_books_error'));
        break;
      }
      case Integration.Xero: {
        notification.error(t('update_metrics_from_xero_error'));
        break;
      }
    }
  };

  // Use ref for statuses to avoid stale data in subscription callback
  const asyncTasksRef = React.useRef<Record<string, AsyncTaskExtended>>({});

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const updateMetrics = React.useCallback(async () => {
    if (!integrationsLoading && companyId && hasConnectedIntegrations && !isLoading) {
      asyncTasksRef.current = {};

      setIsLoading(true);
      setIsError(false);

      const promises = [];

      if (isPlaidIntegrationConnected) {
        promises.push(
          plaidRequestMetricsUpdate({
            variables: {
              companyId,
            },
          }).then(response => {
            const id = R.pathOr('', ['data', 'plaidRequestMetricsUpdate', 'asyncTaskId'], response);

            asyncTasksRef.current[id] = {
              id,
              from: Integration.Plaid,
              status: AsyncTaskStatus.Pending,
            };
          }),
        );
      }

      if (isQuickBooksIntegrationConnected) {
        promises.push(
          quickBooksRequestMetricsUpdate({
            variables: {
              companyId,
            },
          }).then(response => {
            const id = R.pathOr(
              '',
              ['data', 'quickBooksRequestMetricsUpdate', 'asyncTaskId'],
              response,
            );

            asyncTasksRef.current[id] = {
              id,
              from: Integration.QuickBooks,
              status: AsyncTaskStatus.Pending,
            };
          }),
        );
      }

      if (isXeroIntegrationConnected) {
        promises.push(
          xeroRequestMetricsUpdate({
            variables: {
              companyId,
            },
          }).then(response => {
            const id = R.pathOr('', ['data', 'xeroRequestMetricsUpdate', 'asyncTaskId'], response);

            asyncTasksRef.current[id] = {
              id,
              from: Integration.Xero,
              status: AsyncTaskStatus.Pending,
            };
          }),
        );
      }

      if (promises.length > 0) {
        await Promise.all(promises);
      } else {
        setIsLoading(false);
      }
    }
  }, [
    integrationsLoading,
    companyId,
    hasConnectedIntegrations,
    isLoading,
    isPlaidIntegrationConnected,
    isQuickBooksIntegrationConnected,
    isXeroIntegrationConnected,
    plaidRequestMetricsUpdate,
    quickBooksRequestMetricsUpdate,
    xeroRequestMetricsUpdate,
  ]);

  useSubscription(FINISHED_ASYNC_TASKS_SUBSCRIPTION, {
    onSubscriptionData: async ({ subscriptionData }) => {
      const node = R.pathOr(
        null,
        ['data', 'AsyncTasks', 'node'],
        subscriptionData,
      ) as AsyncTask | null;

      if (node) {
        const nodeId = node.id as string;

        // Double call protection: multiple messages can be sent for a single update event
        // If status !== "Pending" -> current async task has already been processed
        const shouldHandleStatusUpdate =
          nodeId in asyncTasksRef.current &&
          asyncTasksRef.current[nodeId].status === AsyncTaskStatus.Pending;

        if (shouldHandleStatusUpdate) {
          asyncTasksRef.current[nodeId].status = node.status;
          const from = asyncTasksRef.current[nodeId].from;

          if (node.status === AsyncTaskStatus.Failed) {
            showNotificationErrorOnUpdateMetrics(from as Integration);

            if (options.onError && isFunction(options?.onError)) {
              try {
                await options.onError();
              } catch (e) {
                console.error(e);
              }
            }

            setIsError(true);
          } else {
            showNotificationSuccessOnUpdateMetrics(from as Integration);
          }

          // check that all of the current async tasks are completed (not pending)
          const tasksFinished = allAsyncTaskFinished(asyncTasksRef.current);

          // check if any successful fetches have been made from integrations
          const hasSuccededTasks = anyAsyncTaskSucceded(asyncTasksRef.current);

          const hasUpdatedMetrics = tasksFinished && hasSuccededTasks;

          // All async tasks completed
          // + metrics from at least one integration were updated
          // -> we can run metrics calculation
          if (!integrationsLoading && companyId && hasUpdatedMetrics) {
            try {
              await recalculateMetrics();
            } catch (error) {
              console.error(error);
            }

            if (options.onCompleted && isFunction(options.onCompleted)) {
              try {
                await options.onCompleted();
              } catch (e) {
                console.error(e);
              }
            }

            setIsLoading(false);
          }
        }
      }
    },
  });

  return [
    updateMetrics,
    {
      loading: isLoading,
      error: isError,
      disabled: integrationsLoading || !hasConnectedIntegrations || isLoading,
    },
  ];
};
