interface TableDataInput {
  columns: any[];
}

type FormatExportDataInput = {
  title?: string;
  key?: string | number;
};

type ColumnProps = {
  exportData: FormatExportDataInput;
  width?: number | string | undefined;
};

type DataFormatResult = {
  header: string;
  key: string | number;
  width: number;
};

export const formatExportData = (tableData: TableDataInput): { columns: DataFormatResult[] } => {
  const { columns } = tableData;

  const formattedColumns = columns
    .map(
      (column: ColumnProps, index: number): DataFormatResult => {
        const exportData = column?.exportData;

        const columnWidth = typeof column?.width === 'number' ? column?.width / 6 : 20;

        return {
          header: exportData?.title || '',
          key: exportData?.key || index,
          width: columnWidth,
        };
      },
    )
    .filter(el => el.header !== '');

  return { columns: formattedColumns };
};
