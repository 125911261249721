import React from 'react';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as icons from '@material-ui/icons';
import { actionsContext } from './ActionsProvider';

// eslint-disable-next-line react/display-name
export const getActions = (id?: string | null) => (): JSX.Element => {
  const { openDealTeamDialog, openDeleteRowDialog, canAsign, canDelete } = React.useContext(
    actionsContext,
  );

  return (
    <React.Fragment>
      <MenuItem key="view" component={Link} to={`/loan-applications/${id}`}>
        <ListItemIcon>
          <icons.Edit />
        </ListItemIcon>
        Loan Information
      </MenuItem>

      <MenuItem disabled={!canAsign} key="asignTo" onClick={() => openDealTeamDialog()}>
        <ListItemIcon>
          <icons.AssignmentInd />
        </ListItemIcon>
        Assign To
      </MenuItem>

      <MenuItem disabled={!canDelete} key="delete" onClick={() => openDeleteRowDialog()}>
        <ListItemIcon>
          <icons.Delete />
        </ListItemIcon>
        Delete
      </MenuItem>
    </React.Fragment>
  );
};
