import * as React from 'react';
import { ListItemIcon, ListItemText, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { renderIcon } from '../utils';

import { ListNavLinkItem } from './ListNavLinkItem';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: 40,
    marginBottom: 8,
    paddingLeft: theme.spacing(4),

    '& .MuiSvgIcon-root': {
      fill: theme.palette.grey[400],
    },

    '&.Mui-selected': {
      '& .MuiSvgIcon-root': {
        fill: theme.palette.secondary.main,
      },
    },
  },

  itemSelected: {
    backgroundColor: `#F8F8F8 !important`,

    '&:before': {
      content: "''",
      zIndex: 1,
      position: 'absolute',
      width: 4,
      height: '100%',
      left: 0,
      top: 0,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  itemIcon: {
    minWidth: 24,
    justifyContent: 'center',
  },
}));

interface SecondaryNavItemProps {
  to: string;
  icon?: string | JSX.Element;
  title: string;
}

export const SecondaryNavItem: React.FC<SecondaryNavItemProps> = ({ to, icon, title }) => {
  const classes = useStyles();

  return (
    <ListNavLinkItem
      className={classes.item}
      classes={{ root: classes.item, selected: classes.itemSelected }}
      to={to}
    >
      {icon && <ListItemIcon className={classes.itemIcon}>{renderIcon(icon)}</ListItemIcon>}

      <ListItemText>{title}</ListItemText>
    </ListNavLinkItem>
  );
};
