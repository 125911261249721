import React from 'react';
import { useMutation } from 'react-apollo';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { FormState, FormApi } from 'final-form';
import {
  useModal,
  Form,
  FormSpy,
  Field,
  TextField,
  DateField,
  NumberField,
  SelectField,
} from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { DRAW_EDIT_MUTATION, RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION } from 'src/graphql';
import { PREPAYMENT_OPTIONS, PREPAYMENT_VALUES } from 'src/constants';
import { DIALOGS } from './constants';
import { baseNumberFormat, calculateAmortizing, DateFormatPatterns, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface DrawEditDialogProps {}

const validateRecord = (values: any) => {
  const errors: any = {};

  if (values.prepayment === PREPAYMENT_VALUES.months && R.isNil(values.prepaymentMonths))
    errors.prepaymentMonths = 'field is required';

  return errors;
};

export const DrawEditDialog: React.FC<DrawEditDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal, openModal } = useModal(DIALOGS.DRAW_EDIT_DIALOG);

  const [isAmortizing, setIsAmortizing] = React.useState<boolean>(true);

  const initialValues = args?.initialValues;

  const [recalculate] = useMutation(RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION, {
    refetchQueries: [
      'MetricsCompanyHeader',
      'FacilitiesTableContent',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
  });
  const [editRow] = useMutation(DRAW_EDIT_MUTATION);

  const onSubmit = React.useCallback(
    async (data, form) => {
      try {
        await editRow({
          variables: {
            data:
              data.prepayment === PREPAYMENT_VALUES.reducedCashOnCash
                ? R.assoc('prepaymentMonths', null, data)
                : data,
          },
        });
        await recalculate({
          variables: {
            facilityId: args?.facilityId,
          },
        });

        notification.success(t('draw_update_success'));
        closeModal();
        setTimeout(form.reset);
      } catch (error) {
        console.error(error);
        notification.error(t('draw_update_error'));
      }
    },
    [closeModal, editRow, notification, args, recalculate],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  const calculateIOPeriodAmortizationPeriod = (
    { values }: FormState<Record<string, any>, Partial<Record<string, any>>>,
    form: FormApi<any, Partial<any>>,
  ): void => {
    const fundedDate = values?.fundedDate;
    const iOPeriod: number | undefined = values?.iOPeriod;

    if (R.isNil(iOPeriod) || R.isNil(fundedDate)) {
      if (values.iOPeriodAmortizationPeriod) form.change('iOPeriodAmortizationPeriod', undefined);
      return undefined;
    }

    const { isAmortizing, value } = calculateAmortizing(fundedDate, iOPeriod);
    form.change('iOPeriodAmortizationPeriod', value);

    setIsAmortizing(isAmortizing);
  };

  if (!open) {
    return null;
  }

  return (
    <Form
      tableSchemaName="Draws"
      type="UPDATE"
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateRecord}
    >
      {({ handleSubmit, form, submitting, pristine, values }): React.ReactNode => {
        const isMonthsInputShown = values.prepayment === PREPAYMENT_VALUES.months;

        return (
          <Dialog
            disableRestoreFocus
            open={open}
            onClose={() => onClose(form, pristine)}
            PaperProps={{
              className: classes.root,
              component: 'form',
              onSubmit: handleSubmit,
            }}
            maxWidth="md"
          >
            <DialogTitle disableTypography>
              <Typography variant="h6">Edit Draw</Typography>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Field
                    name="fundedDate"
                    label="Funded Date"
                    component={DateField}
                    dateFormat={DateFormatPatterns.shortDateWithSlash}
                    fullWidth
                    disabled={submitting}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    name="maturityDate"
                    label="Maturity Date"
                    component={DateField}
                    dateFormat={DateFormatPatterns.shortDateWithSlash}
                    fullWidth
                    disabled={submitting}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    name="amountDeployed"
                    label="Deployed"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="interestRate"
                    label="Interest Rate"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="cashShare"
                    label="Cash Share"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="returnCap"
                    label="Return Cap"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">x</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="term"
                    label="Term"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="iOPeriod"
                    label="IO Period"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="amortizationPeriod"
                    label="Amortization Period"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={values => calculateIOPeriodAmortizationPeriod(values, form)}
                  />
                  <Field
                    name="iOPeriodAmortizationPeriod"
                    label="IO Period / Amortizing"
                    component={NumberField}
                    numberFormat={baseNumberFormat}
                    fullWidth
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {!R.isNil(values.iOPeriodAmortizationPeriod)
                            ? isAmortizing
                              ? 'Amortizing Months'
                              : 'IO Period Months'
                            : ''}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="exitFee"
                    label="Exit Fee"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={isMonthsInputShown ? 3 : 6} item>
                  <Field
                    name="prepayment"
                    label="Prepayment"
                    component={SelectField}
                    options={PREPAYMENT_OPTIONS}
                    fullWidth
                    disabled={submitting}
                  />
                </Grid>
                {isMonthsInputShown && (
                  <Grid xs={3} item>
                    <Field
                      name="prepaymentMonths"
                      label="# of months"
                      component={NumberField}
                      fullWidth
                      disabled={submitting}
                    />
                  </Grid>
                )}
                <Grid xs={6} item>
                  <Field
                    name="iRR"
                    label="IRR"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name="mOIC"
                    label="MOIC"
                    component={NumberField}
                    fullWidth
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">x</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    name="comments"
                    label="Comments"
                    component={TextField}
                    fullWidth
                    disabled={submitting}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                color="secondary"
                onClick={() => onClose(form, pristine)}
                disabled={submitting}
              >
                Cancel
              </Button>
              <SubmitButton
                color="secondary"
                variant="contained"
                type="submit"
                disabled={pristine}
                loading={submitting}
              >
                Save Changes
              </SubmitButton>
            </DialogActions>
          </Dialog>
        );
      }}
    </Form>
  );
};
