import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { SubmitButton } from 'src/components';

interface HeaderProps {
  onExport?: () => void;
  isExporting?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
  },
  right: {
    marginLeft: 'auto',
  },
}));

export const Header: React.FC<HeaderProps> = ({ onExport, isExporting = false }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      direction="row"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <Typography variant="h4">Cash Monitoring</Typography>
      </Grid>
      <Grid item className={classes.right}>
        <SubmitButton
          onClick={onExport}
          color="secondary"
          variant="outlined"
          size="large"
          startIcon={<icons.GetApp />}
          loading={isExporting}
        >
          Export
        </SubmitButton>
      </Grid>
    </Grid>
  );
};
