import { MetricCode, MetricPeriod } from 'src/types';

export const DEFAULT_COUNT_PERIOD = 3;
export const DEFAULT_COUNT_SELECTED_COMPANIES = 5;

export const METRICS_WITHOUT_TOTALS: MetricCode[] = [
  MetricCode.CashRunwayBanking,
  MetricCode.CashRunwayBankingWeekly,
];

export const MONITORING_REPORT_OPTIONS: Array<{ value: string; label: string }> = [
  { value: MetricPeriod.Month, label: 'Monthly' },
  { value: MetricPeriod.Week, label: 'Weekly' },
];
