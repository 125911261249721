import React from 'react';
import * as R from 'ramda';
import { Form } from 'src/app-builder';
import { Box } from '@material-ui/core';

import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';

import {
  convertFromFormData,
  convertToFormData,
  getDisplayedAdjustedValue,
  isFunction,
} from 'src/utils';
import { getMetricLocalValuesKey, getLocalChangedMetric } from 'src/utils/localStorage/metrics';
import { ChangedMetric, Source } from 'src/types';
import { Metric, MetricValue } from 'src/graphql';
import { Format } from 'src/constants';
import { CompaniesWithMetricChange } from 'src/types/localStorage';

type EditFormProps = {
  cell: MetricValue | null;
  metric: Metric | null;
  companyId: string | undefined | null;
  date: string | null;
  onSubmit?: (metric: ChangedMetric) => void;
  onClose: () => void;
  onDelete: () => void;
  companiesWithChange: CompaniesWithMetricChange | null;
};

export const EditForm: React.FC<EditFormProps> = props => {
  const {
    cell,
    onClose,
    companyId,
    date,
    onSubmit: onSubmitProp,
    onDelete,
    companiesWithChange,
  } = props;

  const format = R.pathOr(Format.Count, ['metric', 'format'], cell);
  const metricCode = R.pathOr('', ['metric', 'code'], cell);
  const metricPeriod = R.pathOr('', ['period'], cell);
  const source = R.pathOr(Source.Manual, ['source'], cell);

  const metricLocalValueKey = getMetricLocalValuesKey(metricCode, metricPeriod);

  const changedMetric = getLocalChangedMetric(
    companiesWithChange,
    companyId as string,
    metricLocalValueKey,
    date as string,
    format,
  );

  const active = changedMetric?.active ?? cell?.active ?? true;

  const buildMetricVariable = React.useCallback(
    (comment: string, adjustedValue: any) => {
      const { adjustedValue: convertedAdjustedValue } = convertFromFormData(
        {
          adjustedValue,
        },
        format,
      );

      return {
        date,
        source,
        active,
        comment,
        companyId,
        metricCode,
        period: metricPeriod,
        adjustedValue: convertedAdjustedValue,
      };
    },
    [format, date, source, active, companyId, metricCode, metricPeriod],
  );

  const onSubmit = React.useCallback(
    async (data, form) => {
      const { adjustedValue, comment } = data;
      const metricVariable = buildMetricVariable(comment, adjustedValue);

      if (onSubmitProp && isFunction(onSubmitProp)) {
        onSubmitProp(metricVariable as ChangedMetric);
      }

      onClose();

      setTimeout(form.reset);
    },
    [onSubmitProp, onClose, buildMetricVariable],
  );

  const initialValues = React.useMemo(() => {
    const comment = R.pathOr(null, ['comment'], cell);

    const { value: originalValue, adjustedValue } = convertToFormData(
      {
        value: R.pathOr(null, ['value'], cell),
        adjustedValue: getDisplayedAdjustedValue(
          R.pathOr(null, ['adjustedValue'], cell),
          changedMetric,
        ),
      },
      format,
    );

    const localComment = R.pathOr(null, ['comment'], changedMetric);

    return {
      comment: localComment ?? comment,
      adjustedValue,
      originalValue,
    };
  }, [cell, format, changedMetric]);

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine, values }): React.ReactNode => (
        <Box component="form" onSubmit={handleSubmit}>
          <Header cell={cell} onClose={onClose} onDelete={onDelete} />
          <Body
            values={values}
            cell={cell}
            format={format}
            submitting={submitting}
            onClose={onClose}
          />
          <Footer pristine={pristine} submitting={submitting} onCancel={onClose} />
        </Box>
      )}
    </Form>
  );
};
