import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, Theme } from '@material-ui/core';

import { MonitoringTotalCells } from 'src/types';
import { getFormattedMetricValue, isNegative } from 'src/utils';
import { Format } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  companyNameCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '15%',
  },
  alignRight: {
    textAlign: 'right',
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  totalRow: {
    background: theme.palette.grey[200],
  },
  lessThanZero: {
    color: '#eb4336',
  },
}));

interface TotalRowProps {
  dates: string[];
  metricFormat: Format;
  totals: MonitoringTotalCells;
}

export const TotalRow: React.FC<TotalRowProps> = ({ totals, dates, metricFormat }) => {
  const classes = useStyles({ loading: false });

  return (
    <TableRow>
      <TableCell className={clsx(classes.totalRow, classes.companyNameCell, classes.stickyLeft)}>
        TOTAL
      </TableCell>
      {dates.map((date: string) => {
        const totalMonitoringValue = totals[date] ?? 0;

        return (
          <TableCell
            className={clsx({
              [classes.totalRow]: true,
              [classes.alignRight]: true,
              [classes.lessThanZero]: isNegative(totalMonitoringValue),
            })}
            key={date}
          >
            {getFormattedMetricValue(totalMonitoringValue, metricFormat)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
