import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { PopoverProps } from '@material-ui/core';
import { DateTime } from 'luxon';

import { hasFieldError, getFieldError } from './utils';

interface DateFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  dateFormat?: string;
}

const POPOVER_PROPS: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const DateField: React.FC<DateFieldProps> = ({ input, meta, dateFormat, ...rest }) => {
  const { name, value, onChange } = input;

  const handleChange = React.useCallback(
    (date: DateTime | null) => {
      onChange(date ? date.toISO()?.slice(0, 10) : date);
    },
    [onChange],
  );

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  const props = {
    autoOk: true,
    name: name,
    error,
    helperText,
    format: dateFormat ? dateFormat : 'dd/MM/yyyy',
    value: value ? DateTime.fromISO(value) : null,
    onChange: handleChange,
    PopoverProps: POPOVER_PROPS,
    ...rest,
  };

  return <KeyboardDatePicker variant="inline" inputVariant="outlined" {...props} />;
};
