import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import { Grid, LinearProgress, Paper, Theme, Typography } from '@material-ui/core';
import { EmptyScreen } from 'src/components';

interface LayoutProps {
  loading: boolean;
  isEmpty: boolean;
  renderFacilities: () => React.ReactNode | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.spacing(2),
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  box: {
    width: '100%',
  },
  reportWrapper: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
}));

export const Layout: React.FC<LayoutProps> = ({ loading, children, isEmpty, renderFacilities }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap">
      <Paper className={classes.paper}>
        <Grid className={classes.header} item>
          <Typography variant="subtitle1">Facility &amp; Draws</Typography>
        </Grid>

        {loading ? (
          <LinearProgress />
        ) : (
          <Grid className={classes.content} item container direction="column">
            {isEmpty ? (
              <EmptyScreen
                text="We couldn't find any facilites"
                actionText="Add facilites"
                icon={icons.Assignment}
              />
            ) : (
              renderFacilities()
            )}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
