import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { MetricCellMark } from 'src/components';
import { useMetricCell, useScoreContext } from 'src/hooks';
import { EmptyMetricValue, MetricCode } from 'src/types';
import { Metric, MetricValue } from 'src/graphql';
import { getDisplayedAdjustedValue, getFormattedMetricValue, isNegative } from 'src/utils';
import { getLocalChangedMetric, getMetricLocalValuesKey } from 'src/utils/localStorage/metrics';
import { Format } from 'src/constants';
import { PRIMARY_COLOR } from 'src/constants/colors';

type DotProperties = { tier?: number | null };
type CSSProperties = CreateCSSProperties<DotProperties>;

const createDotCSSProperties = (tier?: number | null): CSSProperties => {
  if (!tier) {
    return {};
  }

  let background = 'transparent';

  if (tier < 3) {
    background = PRIMARY_COLOR;
  } else if (tier === 5) {
    background = '#EB4336';
  } else if (tier >= 3) {
    background = '#FFCA27';
  }

  return {
    flexShrink: 0,
    width: 4,
    height: 4,
    borderRadius: 4,
    background,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 5,
  },
  lessThanZero: {
    color: '#eb4336',
  },
  pointer: {
    cursor: 'pointer',
  },
  activeCell: {
    background: theme.palette.grey[100],
  },
  dot: ({ tier }: DotProperties): CSSProperties => {
    return createDotCSSProperties(tier);
  },
}));

export interface MetricCellValueProps {
  metricValue: MetricValue | EmptyMetricValue;
}

export const MetricCellValue: React.FC<MetricCellValueProps> = ({ metricValue }) => {
  const classes = useStyles({ tier: metricValue?.tier });

  const { canEditMetrics, companiesWithChange, companyId } = useScoreContext();
  const { openEditMetricForm, getActiveMetricCell } = useMetricCell();

  const activeMetricCell = React.useMemo(() => getActiveMetricCell(), [getActiveMetricCell]);
  const isActive = activeMetricCell?.id === metricValue?.id;

  const metric = R.pathOr<Metric | null>(null, ['metric'], metricValue);
  const period = R.pathOr('', ['period'], metricValue);
  const format = R.pathOr<Format>(Format.Count, ['format'], metric);
  const code = R.pathOr('', ['code'], metric) as MetricCode;

  const metricLocalKey = getMetricLocalValuesKey(code, period);

  const localChangedMetric = getLocalChangedMetric(
    companiesWithChange,
    companyId,
    metricLocalKey,
    metricValue.date,
  );

  const value =
    getDisplayedAdjustedValue(metricValue?.adjustedValue as number, localChangedMetric) ??
    metricValue?.value ??
    null;

  const hasTier = Number.isFinite(metricValue?.tier);
  const hasAdjustedValue = !R.isNil(metricValue?.adjustedValue) || !R.isNil(metricValue?.comment);
  const formattedValue = !R.isNil(value) ? getFormattedMetricValue(value, format) : '–';

  const hasLocalDataChanges = !R.isNil(localChangedMetric);

  const onCellClick: React.MouseEventHandler = (event: React.MouseEvent<HTMLTableCellElement>) => {
    if (canEditMetrics) {
      openEditMetricForm(event, metricValue, metricValue.date, metric);
    }
  };

  return (
    <div
      onClick={onCellClick}
      className={clsx({
        [classes.root]: true,
        [classes.pointer]: canEditMetrics,
        [classes.activeCell]: isActive,
        [classes.lessThanZero]: isNegative(value),
      })}
    >
      <MetricCellMark hasAdjustedValue={hasAdjustedValue} hasDataChanged={hasLocalDataChanges} />

      <div className={classes.value}>{formattedValue}</div>

      {hasTier && <span className={classes.dot} />}
    </div>
  );
};
