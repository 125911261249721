import React from 'react';

import { PaginationProvider } from 'src/providers/PaginationProvider';
import { SortProvider } from 'src/providers/SortProvider';
import { SearchProvider } from 'src/providers/SearchProvider';
import { SelectedColumnsProvider } from 'src/providers/SelectedColumnsProvider';
import { SelectedRowsProvider } from 'src/providers/SelectedRowsProvider';

import { Main } from './components';
import { ALL_USERS_COLUMN_KEYS } from 'src/constants';

export const PortfolioCompanyTeamPage: React.FC = () => {
  return (
    <PaginationProvider>
      <SortProvider>
        <SearchProvider>
          <SelectedColumnsProvider
            allKeys={ALL_USERS_COLUMN_KEYS}
            initialOrder={ALL_USERS_COLUMN_KEYS}
          >
            <SelectedRowsProvider>
              <Main />
            </SelectedRowsProvider>
          </SelectedColumnsProvider>
        </SearchProvider>
      </SortProvider>
    </PaginationProvider>
  );
};
