import { Maybe } from 'src/graphql';

interface Params {
  page: number;
  perPage: number;
  search?: string;
  sort: Array<{ field: string; order: string }>;
  companyId: Maybe<string> | undefined;
}

export const getUserTableQueryVariables = ({
  page,
  perPage,
  sort,
  search,
  companyId,
}: Params): any => ({
  skip: page * perPage,
  first: perPage,
  sort,
  filter: {
    AND: [
      {
        _fullText: search,
      },
      {
        OR: [
          {
            company: {
              id: {
                equals: companyId,
              },
            },
          },
          {
            employer: {
              id: {
                equals: companyId,
              },
            },
          },
        ],
      },
    ],
  },
});
