import React from 'react';
import * as ejs from 'ejs';
import { FieldSchema } from '@8base/utils';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { AppAreaField } from 'src/constants/app';

interface FieldLinkProps {
  field: FieldSchema;
  value: any;
  areaField: AppAreaField;
  record: Record<string, any>;
}

export const FieldLink: React.FC<FieldLinkProps> = ({ value, areaField, record }) => {
  const text = areaField.formatOptions.text
    ? ejs.render(areaField.formatOptions.text, { record })
    : String(value);
  const to = areaField.formatOptions.to ? ejs.render(areaField.formatOptions.to, { record }) : '/';

  return (
    <Link component={RouterLink} to={to} color="secondary">
      {text}
    </Link>
  );
};
