import React from 'react';
import * as R from 'ramda';
import { useLazyQuery } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-client';

import { useNotification } from 'src/hooks';
import { commonErrorResolver, t } from 'src/utils';
import {
  SAAS_LOAN_OPTIONS_QUERY,
  CalculateSaaSLoanOptionsQuery,
  CalculateSaaSLoanOptionsQueryVariables,
  SaaSLoanOption,
  Exact,
} from 'src/graphql';

type CalculatedSaaSLoanOptions = {
  data: SaaSLoanOption[];
  loading: boolean;
  refetch: (
    variables?: Exact<{
      companyId: string;
    }>,
  ) => Promise<ApolloQueryResult<CalculateSaaSLoanOptionsQuery>>;
};

type SaaSLoanOptionsQuery = [() => void, CalculatedSaaSLoanOptions];

export const useSaaSLoanOptions = (companyId: string): SaaSLoanOptionsQuery => {
  const notification = useNotification();

  const [calculateSaaSLoanOptions, { data: optionsResponse, loading, refetch }] = useLazyQuery<
    CalculateSaaSLoanOptionsQuery,
    CalculateSaaSLoanOptionsQueryVariables
  >(SAAS_LOAN_OPTIONS_QUERY, {
    variables: {
      companyId: companyId as string,
    },
    onCompleted: () => notification.success(t('calculate_saas_loan_options_success')),
    onError: error =>
      notification.error(
        t('calculate_saas_loan_options_error', {
          errorMessage: commonErrorResolver(error),
        }),
      ),
  });

  const data = React.useMemo(
    () => R.pathOr([], ['calculateSaaSLoanOptions', 'items'], optionsResponse),
    [optionsResponse],
  );

  return [calculateSaaSLoanOptions, { data, loading, refetch }];
};
