import React from 'react';
import * as R from 'ramda';
import { Field, Form } from 'react-final-form';
import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { TextField } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { CashMonitoringRecord } from 'src/graphql';
import { EditableField, RecordFormData } from 'src/types';
import { t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(40),
    padding: theme.spacing(2),
  },
}));

interface RecordEditFormProps {
  field: EditableField;
  record: CashMonitoringRecord | null;
  loading: boolean;
  onSubmitForm: (formData: RecordFormData) => Promise<void>;
}

export const RecordEditForm: React.FC<RecordEditFormProps> = ({
  field,
  record,
  onSubmitForm,
  loading = false,
}) => {
  const classes = useStyles();

  const notification = useNotification();

  const onSubmit = async (formData: RecordFormData) => {
    try {
      await onSubmitForm(formData);

      notification.success(t('cash_monitoring_record_update_success'));
    } catch (e) {
      console.error(e);
      notification.error(t('cash_monitoring_record_update_error'));
    }
  };

  const canDisplay = !R.isNil(record) && !R.isNil(field);
  const initialValue = React.useMemo(() => ({ [field]: record?.[field] }), [record, field]);

  if (!canDisplay) {
    return null;
  }

  return (
    <Paper elevation={1} className={classes.root}>
      <Form initialValues={initialValue} onSubmit={onSubmit}>
        {({ pristine, handleSubmit }) => (
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Leave a comment</Typography>
              </Grid>

              <Grid item xs={12}>
                <Field name={field} component={TextField} multiline fullWidth />
              </Grid>

              <Grid item container xs={12} justify="flex-start">
                <Grid item>
                  <SubmitButton
                    color="secondary"
                    variant="contained"
                    loading={loading}
                    disabled={pristine}
                    onClick={handleSubmit}
                  >
                    Save
                  </SubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Paper>
  );
};
