import React from 'react';
import { useMutation } from 'react-apollo';

import { useNotification, useCalculateMetrics } from 'src/hooks';
import {
  MetricValueUpsertManyMutation,
  MetricValueUpsertManyMutationVariables,
  METRIC_VALUE_UPSERT_MANY_MUTATION,
} from 'src/graphql';
import { CSVReader, generateMetricUpsertFromCSV, t } from 'src/utils';

type CSVUploadState = [
  (file: File) => Promise<void>,
  {
    loading: boolean;
  },
];

export const useCSVUpload = (companyId: string): CSVUploadState => {
  const notification = useNotification();
  const [recalculateMetrics] = useCalculateMetrics(companyId);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const [metricValueUpsert] = useMutation<
    MetricValueUpsertManyMutation,
    MetricValueUpsertManyMutationVariables
  >(METRIC_VALUE_UPSERT_MANY_MUTATION, {
    refetchQueries: ['MetricValuesList', 'MetricsCompanyHeader'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('upload_metrics_from_csv_success')),
    onError: error => {
      notification.error(t('upload_metrics_from_csv_error'));

      throw error;
    },
  });

  const onUpload = React.useCallback(
    async (file: File) => {
      setIsUploading(true);

      let upsertData = null;

      try {
        const json = await new CSVReader(file).getJSON();
        upsertData = generateMetricUpsertFromCSV(companyId, json);
        notification.success(t('parse_csv_success'));
      } catch (error) {
        notification.error(t('parse_csv_error'));
        setIsUploading(false);
        return;
      }

      try {
        await metricValueUpsert({
          variables: {
            data: upsertData,
          },
        });

        await recalculateMetrics();
      } catch (error) {
        console.error(error);
      }

      setIsUploading(false);
    },
    [companyId, metricValueUpsert, notification, recalculateMetrics],
  );

  return [onUpload, { loading: isUploading }];
};
