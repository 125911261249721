import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  Button,
  FormLabel,
  Grid,
  Typography,
  Theme,
} from '@material-ui/core';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';
import { CloudUpload } from '@material-ui/icons';
import { FileInput } from '8base-react-sdk';

interface FileFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  label?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  maxFiles?: number;
  sessionCache?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
}));

const DEFAULT_MAX_COUNT_FILES = 10;

export const FileField: React.FC<FileFieldProps> = ({
  input,
  meta,
  fullWidth,
  label,
  disabled,
  sessionCache,
}) => {
  const classes = useStyles();

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  const uploadOptions = {
    fromSources: ['local_file_system', 'googledrive'],
  };

  return (
    <FormControl variant="outlined" error={error} fullWidth={fullWidth}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <FileInput
        onChange={input.onChange}
        value={input.value}
        maxFiles={DEFAULT_MAX_COUNT_FILES}
        sessionCache={sessionCache}
      >
        {({ pick, value }): React.ReactNode => (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                color="default"
                startIcon={<CloudUpload />}
                onClick={(): void => {
                  pick(uploadOptions);
                }}
                disabled={disabled}
              >
                Upload file
              </Button>
            </Grid>
            <Grid item>
              <Typography>
                {value
                  ? Array.isArray(value)
                    ? `${value.length} files selected`
                    : value.filename
                  : 'No file selected'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </FileInput>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
