import * as R from 'ramda';

import { PaginationProviderValue, SearchProviderValue, Sort, toSortVariables } from 'src/providers';
import {
  CashMonitoringQueryVariables,
  CovenantValue,
  MetricValue,
  PortfolioCompany,
} from 'src/graphql';
import {
  MetricCode,
  MetricPeriod,
  CashMonitoringStrengthsWeaknesses,
  CashMonitoringTableRow,
  CashMonitoringRowKey,
  ScoreArrowType,
  CovenantType,
  CashMonitoringCovenant,
} from 'src/types';
import {
  DateFormatPatterns,
  formatDate,
  getAdjustedOrOriginalMetricValue,
  isDateEquals,
} from 'src/utils';
import {
  PrimaryCustomerBase,
  SMB_SCORE_COMPONENTS,
  ENTERPRISE_SCORE_COMPONENTS,
} from 'src/constants';

export const getCashMonitoringQueryVariables = (
  search: SearchProviderValue | null,
  pagination: PaginationProviderValue,
  sort: Sort | null,
  weeklyDate: string,
  saasscoreStartDate: string,
  saasscoreEndDate: string,
  recordDate: string,
): CashMonitoringQueryVariables => ({
  sort:
    sort?.field && sort?.order
      ? toSortVariables([{ field: sort?.field, order: sort?.order }])
      : null,
  companyFilter: {
    company: {
      name: {
        contains: search?.search,
      },
    },
  },
  metricsFilter: {
    OR: [
      {
        date: {
          equals: weeklyDate,
        },
        metric: {
          code: {
            in: [
              MetricCode.ChangeInCashTwoWeeks,
              MetricCode.ChangeInCashFourWeeks,
              MetricCode.CashPositionPlaid,
              MetricCode.CashRunwayBankingWeekly,
            ],
          },
        },
        period: {
          equals: MetricPeriod.Week,
        },
      },
      {
        AND: [
          {
            date: {
              gte: saasscoreStartDate,
            },
          },
          {
            date: {
              lte: saasscoreEndDate,
            },
          },
        ],
        metric: {
          code: {
            equals: MetricCode.SaaSScore,
          },
        },
      },
      {
        date: {
          equals: saasscoreEndDate,
        },
        period: {
          equals: MetricPeriod.Third,
        },
      },
    ],
  },

  recordFilter: {
    date: {
      equals: recordDate,
    },
  },
});

type StrengthsWeaknessesAndRemainingMetrics = [
  CashMonitoringStrengthsWeaknesses,
  Array<MetricValue>,
];

const getScoreComponents = (primaryCustomerBase: string) => {
  return primaryCustomerBase === PrimaryCustomerBase.SMB
    ? SMB_SCORE_COMPONENTS
    : ENTERPRISE_SCORE_COMPONENTS;
};

const extractStrengthWeaknessesFromMetrics = (
  primaryCustomerBase: string | null,
  metrics: MetricValue[],
): StrengthsWeaknessesAndRemainingMetrics => {
  const strengthsWeaknesses = {
    strengths: [],
    weaknesses: [],
  } as CashMonitoringStrengthsWeaknesses;

  const remainingMetrics = metrics.filter(metricValue => {
    const period = metricValue?.period;
    const code = metricValue?.metric?.code;

    const components = getScoreComponents(primaryCustomerBase as string);

    const isSaaSScoreMetric = period === MetricPeriod.Third;
    const isStrengthOrWeakness = isSaaSScoreMetric && components[code as MetricCode];

    if (isStrengthOrWeakness) {
      const tier = metricValue?.tier ?? -1;
      const name = metricValue?.metric?.name ?? 'Unrecognized Metric';

      if (tier >= 1 && tier < 3) {
        strengthsWeaknesses.strengths.push(name);
      } else if (tier === 5) {
        strengthsWeaknesses.weaknesses.push(name);
      }
    }

    return !isSaaSScoreMetric;
  });

  return [strengthsWeaknesses, remainingMetrics];
};

const getCompanyMetricsByMetricCode = (
  metrics: Array<MetricValue>,
): Partial<Record<MetricCode, MetricValue>> => {
  return R.groupBy<MetricValue>(metricValue => metricValue?.metric?.code as string, metrics);
};

const getCompanyCovenants = (
  covenantValues: Array<CovenantValue>,
): Partial<Record<CovenantType, CashMonitoringCovenant>> => {
  const covenants = {} as Record<CovenantType, CashMonitoringCovenant>;

  covenantValues.forEach(cov => {
    const type = cov?.covenant?.name;
    const value = cov?.value ? Number(cov?.value) : null;

    if (type) {
      covenants[type as CovenantType] = {
        value,
        isEnabled: cov?.isEnabled ?? false,
      };
    }
  });

  return covenants;
};

const calculateCovenant = (
  covenants: Partial<Record<CovenantType, CashMonitoringCovenant>>,
  type: CovenantType,
): number | null => {
  const covenant = covenants?.[type];

  if (covenant?.isEnabled) {
    return covenant?.value;
  }

  return null;
};

const calculateWeeklyMetric = (
  metricValues: Array<MetricValue>,
  weeklyDate: string,
): number | null => {
  const weeklyValue = isDateEquals(metricValues?.[0]?.date, weeklyDate) ? metricValues[0] : null;

  return getAdjustedOrOriginalMetricValue(weeklyValue);
};

type SaaSScoreMonitoringMetrics = {
  month: string | null;
  tier: number | null;
  score: number | null;
  scoreChange: number | null;
  scoreTrendMoM: ScoreArrowType;
};

const calculateSaaSScoreMetrics = (
  metricValues: Array<MetricValue>,
  startDate: string,
  endDate: string,
): SaaSScoreMonitoringMetrics => {
  const score2MonthsAgo = metricValues.find(score => isDateEquals(score?.date, endDate));
  const score3MonthsAgo = metricValues.find(score => isDateEquals(score?.date, startDate));

  const month = formatDate(endDate, DateFormatPatterns.fullMonth);
  const tier = score2MonthsAgo?.tier ?? null;

  const score = !R.isNil(score2MonthsAgo?.value)
    ? Number((score2MonthsAgo?.value as number).toFixed())
    : null;
  const prevScore = !R.isNil(score3MonthsAgo?.value)
    ? Number((score3MonthsAgo?.value as number).toFixed())
    : null;

  const hasScore = !R.isNil(score) || !R.isNil(prevScore);
  const scoreChange = hasScore ? (score ?? 0) - (prevScore ?? 0) : null;

  const scoreTrendMoM =
    R.isNil(scoreChange) || scoreChange === 0
      ? ScoreArrowType.steady
      : scoreChange > 0
      ? ScoreArrowType.up
      : ScoreArrowType.down;

  return {
    month,
    tier,
    score,
    scoreChange,
    scoreTrendMoM,
  };
};

export const calculateCashMonitoringTableRow = (
  portfolioCompany: PortfolioCompany,
  weeklyDate: string,
  saasscoreStartDate: string,
  saasscoreEndDate: string,
  recordDate: string,
): CashMonitoringTableRow => {
  const portfolioCompanyId = portfolioCompany?.id ?? '';
  const company = portfolioCompany?.company;
  const companyName = company?.name ?? '';
  const primaryCustomerBase = company?.primaryCustomerBase ?? null;

  const initialMetricValues = company?.metrics?.items ?? [];
  const initialCovenants = company?.covenantValue?.items ?? [];

  const cashMonitoringRecord = portfolioCompany?.cashMonitoringRecords?.items?.[0] ?? {
    portfolioCompany: {
      id: portfolioCompanyId,
    },
    date: recordDate,
  };

  const [strengthsWeaknesses, remainingMetrics] = extractStrengthWeaknessesFromMetrics(
    primaryCustomerBase,
    initialMetricValues,
  );
  const metrics = getCompanyMetricsByMetricCode(remainingMetrics);
  const covenants = getCompanyCovenants(initialCovenants);

  const saasscoreMetrics = calculateSaaSScoreMetrics(
    R.pathOr([], [MetricCode.SaaSScore], metrics),
    saasscoreStartDate,
    saasscoreEndDate,
  );

  const row = {} as CashMonitoringTableRow;

  row.id = portfolioCompanyId;
  row[CashMonitoringRowKey.CompanyName] = companyName;

  row[CashMonitoringRowKey.CashPositionBanking] = calculateWeeklyMetric(
    R.pathOr([], [MetricCode.CashPositionPlaid], metrics),
    weeklyDate,
  );

  row[CashMonitoringRowKey.CashPositionDate] = weeklyDate;

  row[CashMonitoringRowKey.MinCashBalanceCovenant] = calculateCovenant(
    covenants,
    CovenantType.MinCashPosition,
  );

  row[CashMonitoringRowKey.ChangeInCashLast2Weeks] = calculateWeeklyMetric(
    R.pathOr([], [MetricCode.ChangeInCashTwoWeeks], metrics),
    weeklyDate,
  );

  row[CashMonitoringRowKey.ChangeInCashLast4Weeks] = calculateWeeklyMetric(
    R.pathOr([], [MetricCode.ChangeInCashFourWeeks], metrics),
    weeklyDate,
  );

  const cashRunwayBanking = calculateWeeklyMetric(
    R.pathOr([], [MetricCode.CashRunwayBankingWeekly], metrics),
    weeklyDate,
  );
  row[CashMonitoringRowKey.CashRunwayBanking] = cashRunwayBanking
    ? Number(cashRunwayBanking.toFixed())
    : null;

  row[CashMonitoringRowKey.MinRunwayCovenant] = calculateCovenant(
    covenants,
    CovenantType.MinCashRunway,
  );

  row[CashMonitoringRowKey.ScoreMonth] = saasscoreMetrics.month;
  row[CashMonitoringRowKey.Tier] = saasscoreMetrics.tier;
  row[CashMonitoringRowKey.Score] = saasscoreMetrics.score;
  row[CashMonitoringRowKey.ScoreChange] = saasscoreMetrics.scoreChange;
  row[CashMonitoringRowKey.ScoreTrendMoM] = saasscoreMetrics.scoreTrendMoM;

  row[CashMonitoringRowKey.Strengths] = strengthsWeaknesses.strengths?.join(', ');
  row[CashMonitoringRowKey.Weaknesses] = strengthsWeaknesses.weaknesses?.join(', ');

  row[CashMonitoringRowKey.Notes] = cashMonitoringRecord;
  row[CashMonitoringRowKey.ActionItem] = cashMonitoringRecord;

  return row;
};
