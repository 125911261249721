import { useRef, useEffect } from 'react';

export function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef<() => void | undefined>();

  // Because useRef don't support lazy initial value
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback?.current) {
        savedCallback?.current();
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}
