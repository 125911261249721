import React, { FC, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
  Typography,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { NOTES_DELETE_MUTATION } from 'src/graphql';
import { DIALOGS } from 'src/dialogs';
import { isFunction, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface NoteDeleteDialogProps {}

export const NoteDeleteDialog: FC<NoteDeleteDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal } = useModal(DIALOGS.NOTE_DELETE_DIALOG);

  const [deleteNote, { loading }] = useMutation(NOTES_DELETE_MUTATION, {
    onCompleted: () => notification.success(t('note_delete_success')),
    onError: () => notification.error(t('note_delete_error')),
  });

  const onDelete = useCallback(async () => {
    await deleteNote({
      variables: {
        filter: { id: args?.id },
        force: true,
      },
      refetchQueries: ['NotesList'],
      awaitRefetchQueries: true,
    });

    if (isFunction(args?.onSuccess)) {
      args.onSuccess();
    }

    closeModal();
  }, [closeModal, args, deleteNote]);

  const onClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ className: classes.root }}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Delete Note</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>Are you sure want to delete this note?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          onClick={onDelete}
          loading={loading}
        >
          Delete note
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
