import React from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Grid, TextField } from '@material-ui/core';

import { ImageField } from 'src/app-builder';
import { validateDataByYup } from 'src/utils/validators';
import { userAccountInformationSchema } from 'src/utils/schemas';

export interface AccountInformationFormValues {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  avatar?: {
    fileId: string;
    filename: string;
    public: boolean;
  };
}

interface AccountInformationFormProps {
  initialValues: AccountInformationFormValues;
  isEmployee: boolean;
  loading: boolean;
  onSubmit: (data: any) => void;
}

export const AccountInformationForm: React.FC<AccountInformationFormProps> = ({
  initialValues,
  onSubmit,
  isEmployee,
  loading,
}) => {
  return (
    <Form
      onSubmit={values => {
        const data: Omit<AccountInformationFormValues, 'email'> = {
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
          avatar: values.avatar,
        };
        onSubmit(data);
      }}
      validate={values => validateDataByYup(userAccountInformationSchema, values)}
      initialValues={initialValues}
    >
      {({ pristine, handleSubmit, form, values }) => {
        return (
          <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
            <Grid item xs={12}>
              <Field
                name="email"
                render={({ input, meta }) => {
                  return (
                    <TextField
                      disabled
                      placeholder="Email"
                      label="Email"
                      fullWidth
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      {...input}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="firstName"
                render={({ input, meta }) => {
                  return (
                    <TextField
                      placeholder="First Name"
                      label="First Name"
                      fullWidth
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      {...input}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="lastName"
                render={({ input, meta }) => {
                  return (
                    <TextField
                      placeholder="Last Name"
                      label="Last Name"
                      fullWidth
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      {...input}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="title"
                render={({ input, meta }) => {
                  return (
                    <TextField
                      placeholder="Title"
                      label="Title"
                      fullWidth
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      {...input}
                    />
                  );
                }}
              />
            </Grid>

            {isEmployee && (
              <Grid item>
                <Field
                  name="avatar"
                  render={({ input, meta }) => <ImageField input={input} meta={meta} />}
                />
              </Grid>
            )}

            <Grid item container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => form.reset()}
                  disabled={pristine || loading}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={pristine || loading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};
