// @todo: find normal solution for this
import { DIALOGS as ENGINE_DIALOGS } from 'src/app-builder/dialogs';

export const DIALOGS = {
  ...ENGINE_DIALOGS,

  PORTFOLIO_COMPANY_CREATE_DIALOG: 'PORTFOLIO_COMPANY_CREATE_DIALOG',
  PORTFOLIO_COMPANY_DELETE_DIALOG: 'PORTFOLIO_COMPANY_DELETE_DIALOG',

  APPLICANT_INVITE_DIALOG: 'APPLICANT_INVITE_DIALOG',
  LOAN_APPLICATION_DELETE_DIALOG: 'LOAN_APPLICATION_DELETE_DIALOG',
  LOAN_APPLICATION_DEAL_TEAM_EDIT_DIALOG: 'LOAN_APPLICATION_DEAL_TEAM_EDIT_DIALOG',

  DOCUMENT_CREATE_DIALOG: 'DOCUMENT_CREATE_DIALOG',
  DOCUMENT_EDIT_DIALOG: 'DOCUMENT_EDIT_DIALOG',
  DOCUMENT_DELETE_DIALOG: 'DOCUMENT_DELETE_DIALOG',

  NOTE_CREATE_DIALOG: 'NOTE_CREATE_DIALOG',
  NOTE_EDIT_DIALOG: 'NOTE_EDIT_DIALOG',
  NOTE_DELETE_DIALOG: 'NOTE_DELETE_DIALOG',

  INBOX_COMPOSE_DIALOG: 'INBOX_COMPOSE_DIALOG',
  INBOX_CUSTOMER_REPLY_DIALOG: 'INBOX_CUSTOMER_REPLY_DIALOG',

  FACILITY_CREATE_DIALOG: 'FACILITY_CREATE_DIALOG',
  FACILITY_EDIT_DIALOG: 'FACILITY_EDIT_DIALOG',
  FACILITY_DELETE_DIALOG: 'FACILITY_DELETE_DIALOG',

  FACILITY_ENABLE_MESSAGE:
    'Are you sure want to enable this facility? All related draws will enable too.',
  FACILITY_DISABLE_MESSAGE:
    'Are you sure want to disable this facility? All related draws will disable too.',

  DRAW_CREATE_DIALOG: 'DRAW_CREATE_DIALOG',
  DRAW_EDIT_DIALOG: 'DRAW_EDIT_DIALOG',
  DRAW_DELETE_DIALOG: 'DRAW_DELETE_DIALOG',

  DRAW_ENABLE_MESSAGE: 'Are you sure want to enable this draw?',
  DRAW_DISABLE_MESSAGE: 'Are you sure want to disable this draw?',

  USER_INVITE_CUSTOM_DIALOG: 'USER_INVITE_CUSTOM_DIALOG',
  USER_DELETE_DIALOG: 'USER_DELETE_DIALOG',
  USER_EDIT_DIALOG: 'USER_EDIT_DIALOG',

  TEAM_INVITE_DIALOG: 'TEAM_INVITE_DIALOG',
  TEAM_EDIT_DIALOG: 'TEAM_EDIT_DIALOG',

  CONFIRMATION_DIALOG: 'CONFIRMATION_DIALOG',

  METRIC_DELETE_DIALOG: 'DELETE_METRIC_DIALOG',

  BUSINESS_LOCATION_CHECK_DIALOG: 'BUSINESS_LOCATION_CHECK_DIALOG',

  CALENDLY_WIDGET_DIALOG: 'CALENDLY_WIDGET_DIALOG',

  NOVICE_USER_DIALOG: 'NOVICE_USER_DIALOG',

  PDF_VIEW_DIALOG: 'PDF_VIEW_DIALOG',
};
