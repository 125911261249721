import React, { memo } from 'react';
import { ModalsContext, useAllowed } from 'src/app-builder';
import { Header } from './Header';
import { TableExportType } from 'src/graphql';
import { Permission } from 'src/constants/permissions';
import { DIALOGS } from 'src/dialogs';
import { DocumentNode } from 'graphql';
import { useTableExport } from 'src/hooks';

type HeaderContainerProps = {
  tableExportData: {
    columns: any;
    dataEntry: { query: DocumentNode };
  };
};

const HeaderContainer = memo(({ tableExportData }: HeaderContainerProps) => {
  const isAllowed = useAllowed();
  const { openModal } = React.useContext(ModalsContext);

  const [onExport, { loading: isExporting }] = useTableExport(
    TableExportType.PortfolioCompanies,
    tableExportData,
  );
  const canAddNewCompany = isAllowed(Permission.PortfolioCompaniesAddCompany);

  const openCreateRowDialog = () => openModal(DIALOGS.PORTFOLIO_COMPANY_CREATE_DIALOG);

  return (
    <Header
      onExport={onExport}
      canAddNewCompany={canAddNewCompany}
      openCreateRowDialog={openCreateRowDialog}
      isExporting={isExporting}
    />
  );
});

HeaderContainer.displayName = 'HeaderContainer';

export { HeaderContainer };
