import { gql } from '8base-react-sdk';

export const INBOX_MESSAGE_FRAGMENT = gql`
  fragment InboxMessageFragment on InboxMessage {
    id
    subject
    body
    createdAt
    isByAdministrator
    isRead
    repliedOn {
      id
      subject
    }
    replies {
      items {
        id
        subject
      }
    }
    attachments {
      items {
        file {
          filename
          id
          downloadUrl
          previewUrl
          meta
        }
      }
    }
  }
`;

export const INBOX_MESSAGES_COUNT_NOT_READ_QUERY = gql`
  query InboxMessagesCount($filter: InboxMessageFilter!) {
    inboxMessagesList(filter: $filter) {
      count
    }
  }
`;

export const INBOX_MESSAGE_UPDATE_MUTATION = gql`
  mutation InboxMessageMarkAsRead($messageId: ID!, $data: InboxMessageUpdateInput!) {
    inboxMessageUpdate(filter: { id: $messageId }, data: $data) {
      ...InboxMessageFragment
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
`;

export const INBOX_MESSAGE_QUERY = gql`
  query InboxMessageQuery($id: ID!) {
    inboxMessage(id: $id) {
      ...InboxMessageFragment
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
`;

export const INBOX_MESSAGES_LIST_QUERY = gql`
  query InboxMessagesList($filter: InboxMessageFilter!, $sort: [InboxMessageSort!]) {
    inboxMessagesList(filter: $filter, sort: $sort) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
`;

export const INBOX_MESSAGES_ON_CREATE_SUBSCRIPTION = gql`
  subscription InboxMessageOnCreateSubscription($companyId: ID!) {
    InboxMessages(
      filter: {
        mutation_in: create
        node: { company: { id: { equals: $companyId } }, isRead: { equals: false } }
      }
    ) {
      mutation
      node {
        ...InboxMessageFragment
      }
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
`;
