import * as R from 'ramda';

import { Company, User } from 'src/graphql';
import {
  DASHBOARD_METRICS,
  ENTERPRISE_DASHBOARD_METRICS,
  ENTERPRISE_SAASSCORE_METRICS,
  PrimaryCustomerBase,
  SAASSCORE_METRICS,
} from 'src/constants';
import { CompanyType, MetricCode } from 'src/types';

export const isEnterpriseCompany = (company: Company | null | undefined): boolean => {
  const primaryCustomerBase = company?.primaryCustomerBase;

  return primaryCustomerBase === PrimaryCustomerBase.Enterprise;
};

export function getScoreMetricCodesByCompany(company: Company | null | undefined): MetricCode[] {
  return isEnterpriseCompany(company) ? ENTERPRISE_SAASSCORE_METRICS : SAASSCORE_METRICS;
}

export function getDashboardMetricCodesByCompany(
  company: Company | null | undefined,
): MetricCode[] {
  return isEnterpriseCompany(company) ? ENTERPRISE_DASHBOARD_METRICS : DASHBOARD_METRICS;
}

export const extractUserCompanyId = (user: User | null): string | undefined | null => {
  if (R.isNil(user)) {
    return null;
  }

  if (Boolean(user?.company?.id)) {
    return user?.company?.id;
  }

  return user?.employer?.id;
};

export const isLoanApplicationCompany = (companyType: CompanyType): boolean => {
  return companyType === CompanyType.LoanApplication;
};

export const companyStoryLabel = (companyName: string | undefined): string => {
  return !R.isNil(companyName) ? `What is ${companyName}'s story?` : 'What is your company story?';
};
