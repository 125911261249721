import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { ModalsContext } from 'src/app-builder';
import { Grid, Theme, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isNil } from 'ramda';

import { usePortfolioCompany } from 'src/hooks';
import { NOTES_LIST_QUERY, NotesListQuery, NotesListQueryVariables, SortOrder } from 'src/graphql';
import { ReactComponent as ContentPasteIcon } from 'src/assets/icons/content-paste.svg';
import {
  DIALOGS,
  NoteCreateDialog,
  NoteDeleteDialog,
  NoteEditDialog,
  PdfViewDialog,
} from 'src/dialogs';
import { ActiveNote, NotesList, PageHeader } from 'src/shared/notes';
import { EmptyScreen, Search } from 'src/components';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: 1,
    flex: '1 0 auto',
    overflow: 'hidden',
  },
  noteWrapper: {
    flex: 1,
    padding: theme.spacing(4),
    background: theme.customPalette.background.activeCard,
  },
  panel: {
    display: 'flex',
    flexFlow: 'column nowrap',
    background: '#FFF',
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
}));

type Params = {
  id: string;
  noteId: string;
};

export const PortfolioCompanyNotesPage: React.FC<any> = () => {
  const classes = useStyles();

  const [searchText, setSearchText] = React.useState<string>('');

  const history = useHistory();

  const { openModal } = React.useContext(ModalsContext);

  const { id, noteId }: Params = useParams();

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const companyId = portfolioCompany?.company?.id as string;

  const variables: NotesListQueryVariables = React.useMemo(
    () => ({
      sort: [{ updatedAt: SortOrder.Desc }],
      filter: {
        AND: [
          {
            company: { id: { equals: companyId } },
          },
          {
            OR: [
              {
                title: { contains: searchText },
              },
              {
                body: { contains: searchText },
              },
            ],
          },
        ],
      },
    }),
    [searchText, companyId],
  );

  const { data, loading: notesListLoading } = useQuery<NotesListQuery, NotesListQueryVariables>(
    NOTES_LIST_QUERY,
    {
      variables,
      skip: !Boolean(companyId),
    },
  );

  const notes = data?.notesList?.items ?? [];

  const firstNoteId = !isEmpty(notes) ? notes[0]?.id ?? null : null;

  const loading = portfolioCompanyLoading || notesListLoading;

  const isActiveNoteLoading = (loading || !isEmpty(notes)) && Boolean(noteId);

  const openCreateNoteDialog = React.useCallback(() => {
    openModal(DIALOGS.NOTE_CREATE_DIALOG, {
      companyId,
      onSuccess: () => {
        setSearchText('');
        history.push(`/portfolio-companies/${id}/notes`);
      },
    });
  }, [openModal, companyId, history, id]);

  const emptyScreen = React.useMemo(
    (): React.ReactNode => (
      <EmptyScreen
        text="We couldn't find any notes"
        actionText="Add notes"
        icon={ContentPasteIcon}
        onClick={openCreateNoteDialog}
      />
    ),
    [openCreateNoteDialog],
  );

  return (
    <Grid container direction="column">
      <PageHeader
        companyId={companyId}
        hasNotes={!loading && !isEmpty(notes)}
        openCreateNoteDialog={openCreateNoteDialog}
      />

      <Grid className={classes.content} direction="row" item container>
        <React.Fragment>
          <Grid item xs={5} sm={4} className={classes.panel}>
            <Search
              value={searchText}
              onChange={setSearchText}
              isHidden={isEmpty(notes) && isEmpty(searchText)}
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <React.Fragment>
                {isEmpty(notes) && emptyScreen}

                {!isEmpty(notes) && <NotesList path={`/portfolio-companies/${id}`} notes={notes} />}
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={7} sm={8} className={classes.noteWrapper}>
            {isActiveNoteLoading && noteId && (
              <ActiveNote path={`/portfolio-companies/${id}`} id={noteId} />
            )}
          </Grid>
        </React.Fragment>
      </Grid>

      <NoteCreateDialog />
      <NoteEditDialog />
      <NoteDeleteDialog />
      <PdfViewDialog />

      {!noteId && !isNil(firstNoteId) && (
        <Redirect to={`/portfolio-companies/${id}/notes/${firstNoteId}`} />
      )}
    </Grid>
  );
};
