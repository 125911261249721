import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { Grid, makeStyles } from '@material-ui/core';
import { Form, Field } from 'react-final-form';

import { DateRangeField, SubmitButton } from 'src/components';
import {
  DateFormatPatterns,
  getEndOfMonth,
  getNowDate,
  isDateGreaterOrEquals,
  isDateGreaterThan,
  subtractDate,
} from 'src/utils';

const useStyles = makeStyles(() => ({
  filters: {
    marginBottom: '16px',
  },
}));

const validateDateRange = ([from, till]: any) => {
  const isFromValid = DateTime.fromISO(from).isValid;
  const isTillValid = DateTime.fromISO(till).isValid;

  if (!isFromValid || !isTillValid) {
    return 'Both dates must be set';
  }

  if (isDateGreaterOrEquals(from, till)) {
    return 'From date must be greater';
  }

  const prevMonth = getEndOfMonth(subtractDate(getNowDate()) as string);

  if (isDateGreaterThan(till, prevMonth)) {
    return `Selected date must not exceed ${prevMonth}`;
  }
};

interface FiltersProps {
  selectedPeriod: string[];
  onChangeSelectedPeriod: (values: any) => Promise<void>;
}

export const Filters: React.FC<FiltersProps> = ({ selectedPeriod, onChangeSelectedPeriod }) => {
  const classes = useStyles();

  const INITIAL_PERIOD = React.useMemo(
    () => ({
      period: selectedPeriod,
    }),
    [selectedPeriod],
  );

  const previousMonth = DateTime.local().minus({ months: 1 });

  return (
    <Grid className={classes.filters} item xs={12}>
      <Form onSubmit={onChangeSelectedPeriod} initialValues={INITIAL_PERIOD}>
        {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
          <Grid
            item
            container
            component="form"
            onSubmit={handleSubmit}
            alignItems={hasValidationErrors ? 'flex-start' : 'center'}
            spacing={2}
            xs={12}
          >
            <Grid item xs={3}>
              <Field
                name="period"
                label="Period"
                component={DateRangeField}
                isEqual={R.equals}
                dateFormat={DateFormatPatterns.shortDateWithSlash}
                relativeTo={previousMonth}
                validate={validateDateRange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <SubmitButton
                size="large"
                color="secondary"
                variant="contained"
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Go
              </SubmitButton>
            </Grid>
          </Grid>
        )}
      </Form>
    </Grid>
  );
};
