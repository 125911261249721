import React from 'react';
import { Grid, Theme, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAllowed } from 'src/app-builder';
import {
  useCSVUpload,
  useTableExport,
  useUnderwritingReport,
  useUnderwritingTable,
} from 'src/hooks';
import { UnderwritingReport, UnderwritingReportHeader } from 'src/shared';
import { Permission } from 'src/constants';
import { CompanyType } from 'src/types';
import { TableExportType } from 'src/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(2),
  },
  reportTable: {
    marginBottom: 20,
  },
}));

interface LoanApplicationUnderwritingPackagePageProps {}

export const LoanApplicationUnderwritingPackagePage: React.FC<LoanApplicationUnderwritingPackagePageProps> = props => {
  const classes = useStyles();
  const isAllowed = useAllowed();

  const canEditMetrics = isAllowed(Permission.LoanApplicationsEditMetrics);

  const [
    onChangeActiveReport,
    {
      data: { companyId, activeReport },
      loading: companyLoading,
      filter: { from, till },
    },
  ] = useUnderwritingReport(CompanyType.LoanApplication);

  const {
    data: { metrics, dates, exportData },
    loading: metricsLoading,
  } = useUnderwritingTable(companyId, from, till, canEditMetrics, activeReport);

  const [onExport, { loading: isExporting }] = useTableExport(
    TableExportType.UnderwritingPackage,
    exportData,
  );
  const [onUpload, { loading: isUploading }] = useCSVUpload(companyId);

  const isLoading = companyLoading || metricsLoading;

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <UnderwritingReportHeader
        onUpload={onUpload}
        isUploading={isUploading}
        onExport={onExport}
        isExporting={isExporting}
        isDataLoading={isLoading}
      />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item>
          <UnderwritingReport
            companyId={companyId}
            from={from}
            till={till}
            activeReport={activeReport}
            className={classes.reportTable}
            onChangeActiveReport={onChangeActiveReport}
            canEditMetrics={canEditMetrics}
            metrics={metrics}
            dates={dates}
            exportData={exportData}
          />
        </Grid>
      )}
    </Grid>
  );
};
