import React from 'react';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

import { COMPANIES_EDIT_MUTATION } from 'src/graphql';

interface HeaderProps {
  isReadyToFinalize: boolean;
  companyId: string;
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export const Header: React.FC<HeaderProps> = ({ isReadyToFinalize, companyId }) => {
  const classes = useStyles();

  const [updateCompany, { loading: companyUpdating }] = useMutation(COMPANIES_EDIT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'UserCompany',
      'SortableLoanApplicationsList',
      'SortablePortfolioCompaniesList',
    ],
  });

  const finalizeApplication = async () => {
    if (companyId) {
      await updateCompany({
        variables: {
          data: {
            id: companyId,
            applicationFinalized: true,
          },
        },
      });

      window.location.href = '/client-portal/dashboard';
    }
  };

  return (
    <Grid
      container
      direction="row"
      wrap="wrap"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid xs={12} md={6} item>
        <Typography variant="h3" align="left">
          Your SaaSScore Results
        </Typography>
      </Grid>

      <Grid xs={12} md={6} item container spacing={3} justify="flex-end">
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={finalizeApplication}
            disabled={!isReadyToFinalize || companyUpdating}
          >
            Finalize Application
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
