import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { Attachment } from './attachments/Attachment';

export const Attachments: FC<any> = props => {
  const { attachments } = props;

  return (
    <Grid container spacing={3}>
      {attachments.map((file: any) => (
        <Grid key={file.id} md={6} lg={4} xl={3} item>
          <Attachment file={file} />
        </Grid>
      ))}
    </Grid>
  );
};
