import React from 'react';
import { makeStyles } from '@material-ui/core';

interface MetricNameColumnTitleProps {
  title?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '8px 16px',
  },
}));

export const MetricNameColumnTitle: React.FC<MetricNameColumnTitleProps> = ({ title }) => {
  const classes = useStyles();
  return <div className={classes.root}>{title}</div>;
};
