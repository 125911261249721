import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { gql, Form, Field, FieldArray } from '8base-react-sdk';
import { useSnackbar } from 'notistack';

import { useModal } from '../providers';
import { CheckboxField } from '../forms';

import { DIALOGS } from './constants';

const PERMISSION_UPDATE_MUTATION = gql`
  mutation PermissionUpdate($data: AppPermissionUpdateInput!) {
    appPermissionUpdate(data: $data) {
      id
      name
      appRolePermissions {
        items {
          id
          state
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const PERMISSION_QUERY = gql`
  query Permission($id: ID!) {
    appPermission(id: $id) {
      id
      name
      appRolePermissions {
        items {
          id
          state
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface PermissionUpdateDialogProps {}

export const PermissionUpdateDialog: React.FC<PermissionUpdateDialogProps> = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { open, closeModal, args } = useModal(DIALOGS.PERMISSION_UPDATE_DIALOG);

  const { data, loading } = useQuery(PERMISSION_QUERY, {
    variables: { id: args?.id },
    skip: !args?.id,
  });

  const [permissionUpdate] = useMutation(PERMISSION_UPDATE_MUTATION);

  const onSubmit = React.useCallback(
    async (data, form) => {
      await permissionUpdate({
        variables: {
          data: {
            id: args?.id,
            appRolePermissions: {
              update: data.permissions.map(({ id, state }: { id: string; state: boolean }) => ({
                data: { id, state },
              })),
            },
          },
        },
      });

      enqueueSnackbar(`Permission successfully updated`, { variant: 'success' });

      closeModal();

      setTimeout(form.reset);
    },
    [args, closeModal, enqueueSnackbar, permissionUpdate],
  );

  const onClose = React.useCallback(
    form => {
      closeModal();

      setTimeout(form.reset);
    },
    [closeModal],
  );

  const initialValues = React.useMemo(
    () =>
      loading
        ? {}
        : {
            permissions: (data?.appPermission?.appRolePermissions.items || []).map(
              ({ id, state }: any) => ({
                id,
                state,
              }),
            ),
          },
    [data, loading],
  );

  if (!open || loading) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={onClose.bind(null, form)}
          PaperProps={{
            className: classes.root,
            component: 'form' as any,
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle>Edit Permission</DialogTitle>

          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Role</TableCell>
                    <TableCell>Allowed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="permissions">
                    {({ fields }): React.ReactNode => {
                      return fields.map((name, index) => {
                        const roleName =
                          data?.appPermission?.appRolePermissions?.items[index]?.role?.name;

                        return (
                          <TableRow key={name}>
                            <TableCell>{roleName}</TableCell>
                            <TableCell style={{ width: '1%' }}>
                              <Field name={`${name}.state`} component={CheckboxField} />
                            </TableCell>
                          </TableRow>
                        );
                      });
                    }}
                  </FieldArray>
                </TableBody>
              </Table>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" disabled={submitting} onClick={onClose.bind(null, form)}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={submitting || pristine}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
