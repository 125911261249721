import React from 'react';
import * as R from 'ramda';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { Field, Form, SelectField } from 'src/app-builder';
import { DateRangeField, SubmitButton } from 'src/components';

import { DateFormatPatterns } from 'src/utils';
import { Source } from 'src/types';
import { ALLOWED_STATEMENT_REPORTS, STATEMENTS_REPORT_TYPES } from 'src/constants';
import { StatementsReportType } from 'src/graphql';

interface FiltersProps {
  initialValues: { selectedReport: StatementsReportType; period: (string | null)[] };
  onSubmit: (values: any) => void;
  onRefetch: () => void;
  integrationType: Source.Xero | Source.QBO | null;
  isPlaidIntegrationConnected: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  right: {
    marginLeft: 'auto',
  },
}));

export const Filters: React.FC<FiltersProps> = props => {
  const {
    onSubmit,
    onRefetch,
    initialValues,
    integrationType,
    isPlaidIntegrationConnected,
  } = props;
  const classes = useStyles();

  if (integrationType === null) {
    return null;
  }

  const ALLOWED_REPORTS = ALLOWED_STATEMENT_REPORTS[integrationType].map(reportType => {
    return {
      value: reportType,
      label: STATEMENTS_REPORT_TYPES[reportType],
    };
  });

  const REPORT_OPTIONS = isPlaidIntegrationConnected
    ? [
        ...ALLOWED_REPORTS,
        {
          value: StatementsReportType.Banking,
          label: STATEMENTS_REPORT_TYPES[StatementsReportType.Banking],
        },
      ]
    : ALLOWED_REPORTS;

  return (
    <Box className={classes.root}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, pristine, submitting }) => (
          <Grid
            component="form"
            onSubmit={handleSubmit}
            alignItems="center"
            spacing={2}
            container
            item
          >
            <Grid item xs={3}>
              <Field
                label="Report"
                name="selectedReport"
                component={SelectField}
                options={REPORT_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="period"
                label="Period"
                isEqual={R.equals}
                component={DateRangeField}
                dateFormat={DateFormatPatterns.shortDateWithSlash}
                fullWidth
              />
            </Grid>
            <Grid item>
              <SubmitButton
                size="large"
                color="secondary"
                variant="contained"
                type="submit"
                disabled={pristine}
                loading={submitting}
              >
                Go
              </SubmitButton>
            </Grid>
            <Grid item>
              <SubmitButton
                size="large"
                color="secondary"
                variant="contained"
                onClick={onRefetch}
                disabled={submitting}
                loading={submitting}
              >
                Refetch
              </SubmitButton>
            </Grid>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
