import React from 'react';
import * as R from 'ramda';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { createTableRowCreateTag, TableSchema } from '@8base/utils';
import { gql, Form } from '8base-react-sdk';
import * as ChangeCase from 'change-case';
import { useSnackbar } from 'notistack';

import { useModal } from '../providers';
import { DIALOGS } from './constants';
import { EntityFormFields } from '../generic';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface TableRowCreateDialogProps {
  table: TableSchema;
}

export const TableRowCreateDialog: React.FC<TableRowCreateDialogProps> = ({ table }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { open, closeModal } = useModal(DIALOGS.TABLE_ROW_CREATE_DIALOG);

  const createMutation = React.useMemo(
    () => gql`
      ${createTableRowCreateTag([table], table.id, { withMeta: true })}
    `,
    [table],
  );

  const [createRow] = useMutation(createMutation, {
    refetchQueries: [R.toUpper(R.head(table.name)) + R.tail(table.name) + 'TableContent'],
    awaitRefetchQueries: true,
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      await createRow({ variables: { data } });

      enqueueSnackbar(`${pluralize(table.displayName, 1)} successfully created`, {
        variant: 'success',
      });

      closeModal();

      setTimeout(form.reset);
    },
    [closeModal, createRow, enqueueSnackbar, table],
  );

  const onClose = React.useCallback(
    form => {
      closeModal();

      setTimeout(form.reset);
    },
    [closeModal],
  );

  if (!open) {
    return null;
  }

  const dialogName = ChangeCase.camelCase('create ' + pluralize(table.displayName, 1));

  return (
    <Form tableSchemaName={table.name} type="CREATE" onSubmit={onSubmit} formatRelationToIds>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={onClose.bind(null, form)}
          PaperProps={{
            className: classes.root,
            component: 'form' as any,
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
          data-e2e-id={`dialogs.${dialogName}`}
        >
          <DialogTitle>Create {pluralize(table.displayName, 1)}</DialogTitle>

          <DialogContent>
            <EntityFormFields table={table} />
          </DialogContent>

          <DialogActions>
            <Button
              color="secondary"
              onClick={onClose.bind(null, form)}
              disabled={submitting}
              data-e2e-id={`dialogs.${dialogName}.cancelBtn`}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={submitting || pristine}
              data-e2e-id={`dialogs.${dialogName}.submitBtn`}
            >
              Create {pluralize(table.displayName, 1)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
