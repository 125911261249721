import { gql } from '8base-react-sdk';

export const FILE_CREATE_MUTATION = gql`
  mutation FilesCreate($data: FileCreateInput!) {
    fileCreate(data: $data) {
      id
      fileId
      filename
      downloadUrl
      shareUrl
      meta
    }
  }
`;
