import { gql } from '8base-react-sdk';

export const NOTE_QUERY = gql`
  query NotesEntity($id: ID!) {
    note(id: $id) {
      id
      title
      body
      createdAt
      createdBy {
        firstName
        lastName
        id
        email
      }
      attachments {
        items {
          filename
          id
          downloadUrl
          previewUrl
          meta
        }
      }
    }
  }
`;

export const NOTES_LIST_QUERY = gql`
  query NotesList($filter: NoteFilter!, $sort: [NoteSort!]) {
    notesList(filter: $filter, sort: $sort) {
      items {
        id
        title
        body
        createdAt
        createdBy {
          firstName
          lastName
          id
          email
        }
        attachments {
          items {
            filename
            id
            downloadUrl
            previewUrl
            meta
          }
        }
      }
    }
  }
`;

export const NOTES_CREATE_MUTATION = gql`
  mutation NotesCreate($data: NoteCreateInput!) {
    noteCreate(data: $data) {
      id
      title
      body
      createdAt
      createdBy {
        firstName
        lastName
        id
        email
      }
      attachments {
        items {
          filename
          id
          downloadUrl
          previewUrl
          meta
        }
      }
    }
  }
`;

export const NOTES_UPDATE_MUTATION = gql`
  mutation NoteUpdate($data: NoteUpdateInput!, $filter: NoteKeyFilter) {
    noteUpdate(data: $data, filter: $filter) {
      id
      title
      body
      createdAt
      createdBy {
        firstName
        lastName
        id
        email
      }
      attachments {
        items {
          filename
          id
          downloadUrl
          previewUrl
          meta
        }
      }
    }
  }
`;

export const NOTES_DELETE_MUTATION = gql`
  mutation NotesDelete($filter: NoteKeyFilter!, $force: Boolean) {
    noteDelete(filter: $filter, force: $force) {
      success
    }
  }
`;
