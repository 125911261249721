import { gql } from '8base-react-sdk';

export const PORTFOLIO_COMPANY_FRAGMENT = gql`
  fragment PortfolioCompanyFragment on PortfolioCompany {
    id
    active
    monitoringStatus
    company {
      id
      createdAt
      updatedAt
      createdBy {
        id
      }
      name
      active
      monitoringStatus
      primaryCustomerBase
      primaryBusinessModel
      address {
        country
        street1
        street2
        zip
        city
        state
      }
      borrower
      borrowedDebt
      idealRaiseAmount
      owner {
        id
        firstName
        lastName
        title
        email
      }
      foundedYear
      companyStory
      founderStory
      productDescription
      marketDescription
      customersStrategy
      capital
      capitalType
      capitalStrategy
      equityCapitalAmount
      equityCapitalOwner
      monthsToNextRound
      securedDebt
      structure
      status
      contactTitle
      contactFirstName
      contactLastName
      contactEmail
      _description
    }
    _description
  }
`;

export const PORTFOLIO_COMPANY_MAIN_INFO_QUERY = gql`
  query PortfolioCompanyMainInfo($id: ID!) {
    portfolioCompany(id: $id) {
      id
      active
      monitoringStatus
      company {
        id
        name
        contactEmail
        contactFirstName
        contactLastName
        primaryCustomerBase
        status
        structure
      }
    }
  }
`;

export const PORTFOLIO_COMPANIES_QUERY = gql`
  query PortfolioCompaniesEntity($id: ID!) {
    portfolioCompany(id: $id) {
      ...PortfolioCompanyFragment
    }
  }
  ${PORTFOLIO_COMPANY_FRAGMENT}
`;

export const PORTFOLIO_COMPANIES_DELETE_MUTATION = gql`
  mutation PortfolioCompaniesDelete($filter: PortfolioCompanyKeyFilter!, $force: Boolean) {
    portfolioCompanyDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const PORTFOLIO_COMPANIES_EDIT_MUTATION = gql`
  mutation PortfolioCompaniesUpdate(
    $data: PortfolioCompanyUpdateInput!
    $filter: PortfolioCompanyKeyFilter
  ) {
    portfolioCompanyUpdate(data: $data, filter: $filter) {
      ...PortfolioCompanyFragment
    }
  }
  ${PORTFOLIO_COMPANY_FRAGMENT}
`;

export const PORTFOLIO_COMPANIES_TABLE_QUERY = gql`
  query SortablePortfolioCompaniesList(
    $filter: SortablePortfolioCompanyFilter
    $orderBy: [SortablePortfolioCompanyOrderBy]
    $sort: [SortablePortfolioCompanySort!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
  ) {
    tableData: sortablePortfolioCompaniesList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
      skip: $skip
    ) {
      items {
        id
        name
        structure
        active
        monitoringStatus
        fundedDate
        deployed
        committed
        term
        finalAdvanceRate
        nextAvailableDraw
        contactFirstName
        contactLastName
      }
      count
    }
  }
`;

export const TABLE_EXPORT_MUTATION = gql`
  mutation export($event: TableExportInput!) {
    tableExport(event: $event) {
      base64data
    }
  }
`;

export const PORTFOLIO_COMPANIES_FACILITY_LIST = gql`
  query portfolioCompaniesFacilityList {
    companiesList(filter: { portfolioCompany: { id: { is_not_empty: true } } }) {
      items {
        portfolioCompany {
          id
        }
        facilities {
          items {
            closingDate
            draws {
              items {
                fundedDate
                term
                amountDeployed
              }
            }
            amountCommitted
            amountDeployed
            totalAmountDeployed
            finalAdvanceRate
            structure
            nextAvailableDraw
            createdAt
          }
        }
      }
    }
  }
`;
