import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Box, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  ArgumentAxis,
  Chart,
  SplineSeries,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, BarSeries, ScaleObject, ValueScale } from '@devexpress/dx-react-chart';

import { EmptyMetricChart } from './EmptyMetricChart';
import { isNegative, isFunction } from 'src/utils';

const DEFAULT_HEIGHT_PAPER = 400;
const DEFAULT_HEIGHT_CHART = DEFAULT_HEIGHT_PAPER - 100;

interface StyleProps {
  height?: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  chartPaper: ({ height }) => ({
    padding: theme.spacing(2),
    height: height ?? DEFAULT_HEIGHT_PAPER,
  }),
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    color: theme.palette.secondary.main,
    marginLeft: '15px',
  },
  negative: {
    color: '#eb4336',
  },
  fakeChartContainer: {
    position: 'relative',
  },
  fakeDataText: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
  },
}));

interface MetricChartProps {
  chartName: string;
  currentValue?: string | number | null;
  progressValue?: number | null;
  formatProgressValue?: (value?: number | null) => string | number | null;
  type: 'line' | 'bar';
  data: Array<any>;
  valueKey: string;
  valueInterval?: [number, number];
  xAxisKey: string;
  color?: string;
  formatValue?: (value: string) => string;
  height?: number;
  width?: number;
}

export const MetricChart: React.FC<MetricChartProps> = ({
  chartName,
  currentValue = null,
  progressValue,
  formatProgressValue,
  type,
  data,
  valueKey,
  formatValue,
  xAxisKey,
  valueInterval,
  color,
  height,
}) => {
  const classes = useStyles({ height });

  const { palette } = useTheme();

  const chartComponent = React.useMemo(() => {
    const isLine = type === 'line';

    const chartProps = {
      valueField: valueKey,
      argumentField: xAxisKey,
      color: color || palette.secondary.main,
    };

    return isLine ? <SplineSeries {...chartProps} /> : <BarSeries {...chartProps} />;
  }, [type, color, xAxisKey, valueKey, palette]);

  const subtitle = !R.isNil(currentValue) ? currentValue : '-';

  const preparedRenderProgressValue = React.useMemo(() => {
    return formatProgressValue && isFunction(formatProgressValue)
      ? formatProgressValue(progressValue)
      : progressValue;
  }, [progressValue, formatProgressValue]);

  const progress = !R.isNil(progressValue) && (
    <Typography
      className={clsx({
        [classes.progress]: true,
        [classes.negative]: isNegative(progressValue),
      })}
      variant="subtitle2"
    >
      {preparedRenderProgressValue}
    </Typography>
  );

  const isValidValueInterval = !R.isNil(valueInterval) && !R.isEmpty(valueInterval);

  const valueScale = isValidValueInterval && (
    <ValueScale modifyDomain={() => valueInterval || []} />
  );

  const chartHeight = height ? height - 50 : DEFAULT_HEIGHT_CHART;

  const isEmptyChartData = R.isNil(data) || R.isEmpty(data);

  const customData = data.map(item => {
    const valueByKey = item[valueKey];
    return {
      ...item,
      [valueKey]: valueByKey ?? 0,
    };
  });

  return (
    <Paper className={classes.chartPaper}>
      <Typography variant="subtitle2">{chartName}</Typography>
      <Box className={classes.subtitleContainer}>
        <Typography variant="subtitle1">{subtitle}</Typography>
        {progress}
      </Box>
      {isEmptyChartData ? (
        <EmptyMetricChart height={chartHeight} />
      ) : (
        <Chart data={customData} height={chartHeight}>
          <ArgumentAxis />
          {valueScale}
          <ValueAxis
            position="right"
            tickFormat={(scale: ScaleObject) => (tick: string) => {
              if (formatValue && isFunction(formatValue)) {
                return formatValue(tick);
              }

              return tick;
            }}
          />
          {chartComponent}

          <Animation />
        </Chart>
      )}
    </Paper>
  );
};
