import React, { createContext, useEffect, useState } from 'react';

import { ScaleByTier, ScaleByTierSettings } from 'src/types';

interface ScaleByTierContextValue {
  scaleByTierSettings: ScaleByTierSettings;
  update: (isEnterprise: boolean) => (idx: number) => (value: number) => void;
  resetScaleByTierTables: () => void;
}

interface ScaleByTierProviderProps {
  initialData: ScaleByTierSettings;
  children: React.ReactNode;
}

export const scaleByTierContext = createContext<ScaleByTierContextValue>(
  {} as ScaleByTierContextValue,
);

export const ScaleByTierProvider: React.FC<ScaleByTierProviderProps> = ({
  initialData,
  children,
}) => {
  const [scaleByTierSettings, setScaleByTierSettings] = useState<ScaleByTierSettings>(null);

  const update = (isEnterprise: boolean) => (idx: number) => (value: number) => {
    if (!scaleByTierSettings) return;

    const scaleByTier = isEnterprise ? scaleByTierSettings.enterprise : scaleByTierSettings.smb;
    const newScaleByTier = [...scaleByTier];
    newScaleByTier.splice(idx, 1, value);

    const newScaleByTierSettings = {
      ...scaleByTierSettings,
      [isEnterprise ? 'enterprise' : 'smb']: newScaleByTier as ScaleByTier,
    };

    setScaleByTierSettings(newScaleByTierSettings);
  };

  const resetScaleByTierTables = () => setScaleByTierSettings(initialData);

  useEffect(() => {
    setScaleByTierSettings(initialData);
  }, [initialData]);

  return (
    <scaleByTierContext.Provider
      value={{
        scaleByTierSettings,
        update,
        resetScaleByTierTables,
      }}
    >
      {children}
    </scaleByTierContext.Provider>
  );
};
