import React, { FC, useState, useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Theme, Button, CircularProgress } from '@material-ui/core';

import { useNotification } from 'src/hooks';
import { DownloadPdfResponse, DownloadPdfType, DOWNLOAD_PDF, Maybe } from 'src/graphql';
import { downloadDataBase64, commonErrorResolver } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    height: theme.spacing(8),
    flexShrink: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  progress: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 1,
  },
}));

interface PageHeaderProps {
  hasNotes: boolean;
  companyId: string;
  openCreateNoteDialog: () => void;
}

export const PageHeader: FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const { hasNotes, companyId, openCreateNoteDialog } = props;

  const classes = useStyles();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const notification = useNotification();

  const [downloadPdf] = useMutation<{
    downloadPdf?: Maybe<DownloadPdfResponse>;
  }>(DOWNLOAD_PDF, {
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const getBase64Pdf = useCallback(
    async (companyId: string) => {
      const response = await downloadPdf({
        variables: {
          event: {
            type: DownloadPdfType.AllNotes,
            payload: {
              companyId,
            },
          },
        },
      });

      return {
        fileName: response?.data?.downloadPdf?.response?.fileName,
        base64Pdf: response?.data?.downloadPdf?.response?.pdf,
      };
    },
    [downloadPdf],
  );

  const downloadNotes = async (companyId: string) => {
    setDownloadLoading(true);
    const { fileName, base64Pdf } = await getBase64Pdf(companyId);

    setDownloadLoading(false);
    if (base64Pdf && fileName) {
      downloadDataBase64(fileName, base64Pdf);
    }
  };

  return (
    <Grid className={classes.header} item>
      <Typography component={'span'} variant="subtitle1">
        Notes
      </Typography>

      {hasNotes && (
        <Grid>
          <Button
            variant="outlined"
            style={{ marginRight: 15 }}
            color="secondary"
            disabled={downloadLoading}
            onClick={() => downloadNotes(companyId)}
          >
            Download all notes
            {downloadLoading && <CircularProgress size={28} className={classes.progress} />}
          </Button>
          <Button variant="outlined" color="secondary" onClick={openCreateNoteDialog}>
            Create note
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
