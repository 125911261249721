import * as React from 'react';

import { useQueryParams } from './useQueryParams';

export const PER_PAGE_OPTIONS = [5, 10, 25];

export const useSearch = (): {
  search: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} => {
  const [{ search }, { setQueryParams }] = useQueryParams<{ search: string }>({
    initialValues: { search: '' },
  });

  const handleSearchChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({ search: e.currentTarget.value });
    },
    [setQueryParams],
  );

  return {
    search,
    handleSearchChange,
  };
};
