import React from 'react';
import * as R from 'ramda';
import { Theme, Typography, TypographyClassKey } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNegative } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  lessThanZero: {
    color: '#eb4336',
  },
}));

interface MetricValueTextProps {
  value: number | null | undefined;
  formatter?: (value: number | null | undefined) => string | null;
  variant?: TypographyClassKey;
}

export const MetricValueText: React.FC<MetricValueTextProps> = ({
  value,
  formatter = val => val,
  variant = 'subtitle1',
  ...rest
}) => {
  const classes = useStyles();

  if (R.isNil(value)) {
    return <Typography {...rest}>-</Typography>;
  }

  const negative = isNegative(value);

  const abs = Math.abs(Math.round(value));

  const formattedValue = formatter(abs);

  if (negative) {
    return (
      <Typography className={classes.lessThanZero} {...rest}>
        ({formattedValue})
      </Typography>
    );
  }

  return <Typography {...rest}>{formattedValue}</Typography>;
};
