import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import * as icons from '@material-ui/icons';
import { SubmitButton } from 'src/components';

interface HeaderProps {
  canAddNewCompany: boolean;
  openCreateRowDialog: () => void;
  onExport: () => void;
  isExporting: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
  },
  right: {
    marginLeft: 'auto',
  },
}));

export const Header: React.FC<HeaderProps> = ({
  canAddNewCompany,
  openCreateRowDialog,
  onExport,
  isExporting,
}: HeaderProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      direction="row"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <Typography variant="h4">Portfolio Companies</Typography>
      </Grid>
      <Grid item className={classes.right}>
        <SubmitButton
          onClick={onExport}
          color="secondary"
          variant="outlined"
          size="large"
          startIcon={<icons.GetApp />}
          loading={isExporting}
        >
          Export
        </SubmitButton>
      </Grid>
      <Grid item>
        <Button
          disabled={!canAddNewCompany}
          color="secondary"
          variant="contained"
          size="large"
          onClick={openCreateRowDialog}
        >
          Add New Company
        </Button>
      </Grid>
    </Grid>
  );
};
