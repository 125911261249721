import * as R from 'ramda';

import { Format } from 'src/constants';
import { DashboardMetricGroups, MergedMetricValue, MetricCode, MetricGroup } from 'src/types';
import { MetricValue } from 'src/graphql';
import {
  getAdjustedOrOriginalMetricValue,
  getMetricsInGroupByCode,
  mergeMetricValues,
  sortingMetricsByDate,
} from 'src/utils/metric';
import {
  calculatePercentNumberOfNumber,
  convertIfPercentages,
  convertToPercentage,
  formatDecimalFractionToPercentage,
  isNegative,
} from 'src/utils/number';
import { getMonth } from 'src/utils/date';
import { wrapWithBrackets } from 'src/utils/helpers';

export const getDashboardMetricGroups = (groups: MetricGroup[]): DashboardMetricGroups[] => {
  const prepareMetric = (metric: MetricValue) => ({
    ...metric,
    value: getAdjustedOrOriginalMetricValue(metric),
    date: getMonth(metric.date, 'long'),
  });

  return groups.map((group: MetricGroup) => {
    const metrics: MetricValue[] = R.pipe(
      R.pathOr([], ['metrics', 'items']),
      R.sort(sortingMetricsByDate),
      R.map(prepareMetric),
    )(group);

    const [metric] = metrics;
    const name = R.pathOr('', ['metric', 'name'], metric);
    const code = R.pathOr('', ['code'], group);

    return { code, name, metrics };
  });
};

export const getSMBChartMetrics = (
  dashboardMetricGroups: DashboardMetricGroups[],
): Record<string, MetricValue[] | MergedMetricValue[]> => {
  // SaaS Score
  const SaaSScoreMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.SaaSScore);

  // Revenue Performance
  const RevenueMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.TotalRevenue);
  const RevenueGrowthRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.RevenueGrowthRateMoM,
  );

  const RevenueAndGrowthMergeMetrics = mergeMetricValues(
    RevenueMetrics,
    RevenueGrowthRateMetrics,
    'RevenueMetricValue',
    'RevenueGrowthMetricValue',
    'date',
  );

  const GrossMarginMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.GrossMargin);
  const NetRevenueRetentionRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.NetRevenueRetentionRate,
  );
  const MRRChurnRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.MRRNetChurnRate,
  );
  const GrossCustomerChurnRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.GrossCustomerChurnRate,
  );

  // SaaS Efficiency
  const CACPaybackMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.CACPayback);

  // Capital Resources
  const CashPositionMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.CashPosition,
  );
  const CashRunwayAccountingMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.CashRunwayAccounting,
  );
  const NetCashBurnOfGrossProfitMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.NetCashBurnOfGrossProfit,
  );

  return {
    [MetricCode.SaaSScore]: SaaSScoreMetrics,

    [MetricCode.TotalRevenue]: RevenueMetrics,
    [MetricCode.RevenueGrowthRateMoM]: RevenueGrowthRateMetrics,
    RevenueAndGrowthMergeMetrics,
    [MetricCode.GrossMargin]: GrossMarginMetrics,
    [MetricCode.NetRevenueRetentionRate]: NetRevenueRetentionRateMetrics,
    [MetricCode.MRRNetChurnRate]: MRRChurnRateMetrics,
    [MetricCode.GrossCustomerChurnRate]: GrossCustomerChurnRateMetrics,

    [MetricCode.CACPayback]: CACPaybackMetrics,

    [MetricCode.CashPosition]: CashPositionMetrics,
    [MetricCode.CashRunwayAccounting]: CashRunwayAccountingMetrics,
    [MetricCode.NetCashBurnOfGrossProfit]: NetCashBurnOfGrossProfitMetrics,
  };
};

export const getEnterpriseChartMetrics = (
  dashboardMetricGroups: DashboardMetricGroups[],
): Record<string, MetricValue[] | MergedMetricValue[]> => {
  // SaaS Score
  const SaaSScoreMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.SaaSScore);

  // Revenue Performance
  const RevenueMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.TotalRevenue);
  const RevenueGrowthRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.RevenueGrowthRateMoM,
  );

  const RevenueAndGrowthMergeMetrics = mergeMetricValues(
    RevenueMetrics,
    RevenueGrowthRateMetrics,
    'RevenueMetricValue',
    'RevenueGrowthMetricValue',
    'date',
  );

  const GrossMarginMetrics = getMetricsInGroupByCode(dashboardMetricGroups, MetricCode.GrossMargin);
  const NetRevenueRetentionRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.NetRevenueRetentionRate,
  );
  const MRRChurnRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.MRRNetChurnRate,
  );
  const GrossCustomerChurnRateMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.GrossCustomerChurnRate,
  );

  // Capital Resources
  const CashPositionMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.CashPosition,
  );
  const CashRunwayAccountingMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.CashRunwayAccounting,
  );
  const NetCashBurnOfGrossProfitMetrics = getMetricsInGroupByCode(
    dashboardMetricGroups,
    MetricCode.NetCashBurnOfGrossProfit,
  );

  return {
    [MetricCode.SaaSScore]: SaaSScoreMetrics,

    [MetricCode.TotalRevenue]: RevenueMetrics,
    [MetricCode.RevenueGrowthRateMoM]: RevenueGrowthRateMetrics,
    RevenueAndGrowthMergeMetrics,
    [MetricCode.GrossMargin]: GrossMarginMetrics,
    [MetricCode.NetRevenueRetentionRate]: NetRevenueRetentionRateMetrics,
    [MetricCode.MRRNetChurnRate]: MRRChurnRateMetrics,
    [MetricCode.GrossCustomerChurnRate]: GrossCustomerChurnRateMetrics,

    [MetricCode.CashPosition]: CashPositionMetrics,
    [MetricCode.CashRunwayAccounting]: CashRunwayAccountingMetrics,
    [MetricCode.NetCashBurnOfGrossProfit]: NetCashBurnOfGrossProfitMetrics,
  };
};

export const calculateProgress = (
  lastValue: number | null,
  prevValue: number | null,
  format?: Format,
): number | null => {
  if (!Number.isFinite(lastValue) || !Number.isFinite(prevValue)) {
    return null;
  }

  const diffValue = (lastValue as number) - (prevValue as number);

  if (format === Format.Percent) {
    return convertToPercentage(diffValue as number);
  }

  if (format === Format.Count) {
    return diffValue;
  }

  if (lastValue === 0 && prevValue !== 0) {
    if (Number(prevValue) > 0) {
      return -100;
    }

    return 100;
  }

  return calculatePercentNumberOfNumber(diffValue, prevValue);
};

export function formatProgressValue(
  progressValue?: number | null,
  format = Format.Percent,
): string | number | null {
  if (R.isNil(progressValue)) {
    return null;
  }

  const valueFormatter = (value: number): string | number => {
    if (format === Format.Percent) {
      return formatDecimalFractionToPercentage(Math.abs(value));
    }

    return Math.abs(value);
  };

  const value = convertIfPercentages(progressValue, format) as number;

  // example: +25%
  if (!isNegative(value)) {
    return '+'.concat(valueFormatter(value) as string);
  }

  return wrapWithBrackets(valueFormatter(value));
}
