import React from 'react';

import { PaginationProvider, SearchProvider, SortProvider } from 'src/providers';
import { CashMonitoringActionsProvider } from 'src/providers';
import { Main } from './components';

export const CashMonitoringTablePage: React.FC = () => {
  return (
    <SearchProvider>
      <SortProvider>
        <PaginationProvider>
          <CashMonitoringActionsProvider>
            <Main />
          </CashMonitoringActionsProvider>
        </PaginationProvider>
      </SortProvider>
    </SearchProvider>
  );
};
