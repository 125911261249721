import React, { ChangeEvent, SetStateAction, Dispatch } from 'react';

import { InputAdornment, Box, Theme, TextField, debounce } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchOutlined } from '@material-ui/icons';

import { Delay } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  input: {
    width: '100%',
  },
}));

interface SearchProps {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  isHidden?: boolean;
}

const inputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <SearchOutlined color="disabled" />
    </InputAdornment>
  ),
};

export const Search: React.FC<SearchProps> = props => {
  const { value, onChange, isHidden = false } = props;
  const [text, setText] = React.useState<string>(value);
  const classes = useStyles();

  React.useEffect(() => {
    if (!value) {
      setText('');
    }
  }, [value]);

  const debouncedChange = React.useMemo(() => {
    return debounce((val: string) => onChange(val), Delay.Default);
  }, [onChange]);

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event?.target?.value ?? '';
    setText(query);
    debouncedChange(query);
  };

  if (isHidden) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <TextField
        label="Search"
        value={text}
        size="small"
        onChange={handleTextChange}
        variant="outlined"
        InputProps={inputProps}
        fullWidth
        className={classes.input}
      />
    </Box>
  );
};
