import React from 'react';
import * as R from 'ramda';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import {
  Popover,
  Checkbox,
  List,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  PopoverProps,
  Divider,
  Link,
  Typography,
  Grid,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import { SelectedColumns, SelectedColumnsHandler } from '../hooks';
import { APP_SETTING } from 'src/constants/app';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '8px 16px',
    paddingRight: 40,
    position: 'relative',
  },
  selectedColumns: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  fieldRow: {
    display: 'flex',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actions: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  action: {
    cursor: 'pointer',
  },
}));

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const SelectedColumnsRow: React.FC<any> = ({
  id,
  name,
  selected,
  createHandleToggleColumn,
  areaField,
  moveColumn,
  index,
}) => {
  const classes = useStyles();

  const ref = React.useRef<HTMLLIElement>(null);

  const [, drop] = useDrop({
    accept: 'COLUMN',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset?.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveColumn(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'COLUMN', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li
      style={{ visibility: isDragging ? 'hidden' : 'visible' }}
      className={classes.root}
      key={areaField.name}
      ref={ref}
    >
      <label className={classes.label}>
        <ListItemIcon>
          <Checkbox onClick={createHandleToggleColumn(name)} checked={selected} color="primary" />
        </ListItemIcon>
        <ListItemText>{areaField.name}</ListItemText>
        <ListItemSecondaryAction className={classes.fieldRow}>
          <icons.DragIndicator />
        </ListItemSecondaryAction>
      </label>
    </li>
  );
};

interface SelectedColumnsPopoverProps extends PopoverProps {
  selectedColumns: SelectedColumns;
  areaCode?: string;
  createHandleToggleColumn: SelectedColumnsHandler;
  moveColumn: (fromIndex: number, toIndex: number) => void;
  showAll: () => void;
  hideAll: () => void;
  restoreDefaults: () => void;
}

export const SelectedColumnsPopover: React.FC<SelectedColumnsPopoverProps> = ({
  anchorEl,
  open,
  onClose,
  areaCode,
  createHandleToggleColumn,
  selectedColumns,
  moveColumn,
  showAll,
  hideAll,
  restoreDefaults,
}) => {
  const classes = useStyles();

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List className={classes.selectedColumns}>
        {selectedColumns.map(({ id, selected }, index) => {
          const areaField = R.find(R.propEq('id', id), APP_SETTING.areas.items);

          if (!areaField) {
            return null;
          }

          return (
            <SelectedColumnsRow
              key={areaField.id}
              id={areaField.id}
              selected={selected}
              name={id}
              areaField={areaField}
              createHandleToggleColumn={createHandleToggleColumn}
              index={index}
              moveColumn={moveColumn}
            />
          );
        })}
        <Divider />
        <Grid container justify="center" className={classes.actions}>
          <Grid item>
            <Link onClick={showAll} className={classes.action}>
              <Typography>Show All</Typography>
            </Link>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Link onClick={restoreDefaults} className={classes.action}>
              <Typography>Restore Defaults</Typography>
            </Link>
          </Grid>
        </Grid>
      </List>
    </Popover>
  );
};
