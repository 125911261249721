import React from 'react';
import {
  IconButton,
  Grid,
  Toolbar,
  Tooltip,
  Theme,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import { usePopover, SelectedColumnsHandler, SelectedColumns } from '../hooks';
import { SelectedColumnsPopover } from '../popovers';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    justifyContent: 'space-between',
  },
  massActions: {
    width: 'auto',
  },
}));

interface SearchInputProps {
  search: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
}

interface DeleteButtonProps {
  openDeleteRowDialog: (args: any) => void;
  setSelectedRows: (rows: string[]) => void;
  disabled?: boolean;
}

interface SelectColumnsButtonProps {
  areaId?: string;
  areaCode?: string;
  selectedColumns: SelectedColumns;
  createHandleToggleColumn: SelectedColumnsHandler;
  moveColumn: (fromIndex: number, toIndex: number) => void;
  showAll: () => void;
  hideAll: () => void;
  restoreDefaults: () => void;
  disabled?: boolean;
}

interface EntitiesTableToolbarProps {
  selectedRows?: string[];

  searchInputProps?: SearchInputProps;
  deleteButtonProps?: DeleteButtonProps;
  selectColumnsButtonProps?: SelectColumnsButtonProps;
}

export const EntitiesTableToolbar: React.FC<EntitiesTableToolbarProps> = ({
  selectedRows = [],
  searchInputProps,
  deleteButtonProps,
  selectColumnsButtonProps,
}) => {
  const classes = useStyles();

  const selectedColumnsPopover = usePopover();

  const openDeleteSelectedRowsDialog = React.useCallback(() => {
    deleteButtonProps &&
      deleteButtonProps.openDeleteRowDialog({
        id: selectedRows,
        onSuccess: () => {
          deleteButtonProps.setSelectedRows([]);
        },
      });
  }, [deleteButtonProps, selectedRows]);

  return (
    <Toolbar className={classes.toolbar}>
      {selectedRows.length === 0 ? (
        <div />
      ) : (
        <Typography color="inherit" variant="subtitle1" component="div">
          {selectedRows.length} selected
        </Typography>
      )}

      <Grid container alignItems="center" className={classes.massActions}>
        {searchInputProps && (
          <Grid item>
            <TextField
              value={searchInputProps.search}
              onChange={searchInputProps.handleSearchChange}
              variant="outlined"
              size="small"
              placeholder="Search..."
              disabled={searchInputProps.disabled || false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <icons.Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {selectColumnsButtonProps && (
          <Grid item>
            <Tooltip title="Select Columns">
              <IconButton
                aria-label="select-columns"
                onClick={selectedColumnsPopover.openPopover}
                disabled={selectColumnsButtonProps.disabled || false}
              >
                <icons.ViewColumn />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {deleteButtonProps && (
          <Grid item>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={openDeleteSelectedRowsDialog}
                disabled={deleteButtonProps.disabled || false}
              >
                <icons.Delete />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      {selectColumnsButtonProps && (
        <SelectedColumnsPopover
          anchorEl={selectedColumnsPopover.el}
          open={selectedColumnsPopover.isOpen}
          onClose={selectedColumnsPopover.closePopover}
          selectedColumns={selectColumnsButtonProps.selectedColumns}
          createHandleToggleColumn={selectColumnsButtonProps.createHandleToggleColumn}
          moveColumn={selectColumnsButtonProps.moveColumn}
          showAll={selectColumnsButtonProps.showAll}
          hideAll={selectColumnsButtonProps.hideAll}
          restoreDefaults={selectColumnsButtonProps.restoreDefaults}
          areaCode={selectColumnsButtonProps.areaCode}
        />
      )}
    </Toolbar>
  );
};
