import React from 'react';
import * as R from 'ramda';

import { useCashMonitoring, useRouterQuery } from 'src/hooks';
import { getEndOfMonth, getNowDate, subtractDate } from 'src/utils';
import { PaginationContainer } from 'src/shared/table/PaginationContainer';
import { Layout, Table, HeaderContainer, ToolbarContainer } from './';

export const Main: React.FC = () => {
  const query = useRouterQuery();
  const selectedMonth = query?.month ?? null;
  const endOfPreviousMonth = getEndOfMonth(subtractDate(getNowDate()) as string);
  const selectedDate = selectedMonth ?? endOfPreviousMonth;

  const { loading, rows, paginatedRows, columns, count } = useCashMonitoring(
    selectedDate,
    endOfPreviousMonth,
  );

  const tableExportData = React.useMemo(
    () => ({
      rows,
      columns,
    }),
    [rows, columns],
  );

  const hasCompanies = !R.isEmpty(rows);
  const isFirstLoading = loading && !hasCompanies;

  return (
    <Layout
      loading={loading}
      isFirstLoading={isFirstLoading}
      hasCompanies={hasCompanies}
      renderHeader={<HeaderContainer tableExportData={tableExportData} />}
      renderToolbar={
        <ToolbarContainer
          loading={loading}
          maxMonth={endOfPreviousMonth}
          initialSelectedDate={selectedDate}
        />
      }
      renderTable={<Table rows={paginatedRows} columns={columns} />}
      renderPagination={<PaginationContainer count={count} />}
    />
  );
};
