import * as R from 'ramda';

import { ProgressCompanyStep, ProgressStage, ProgressStep, ProgressStepValue } from 'src/graphql';
import { REG_EXP } from 'src/constants';
import { ProgressStepStatus } from 'src/types';
import { DateFormatPatterns, formatDate } from 'src/utils';

export const getProgressStepById = (
  progressStages: Array<ProgressStage>,
  progressStepId: string,
): ProgressStepValue | ProgressCompanyStep | null | undefined => {
  let progressStep: ProgressStepValue | ProgressCompanyStep | null | undefined = null;
  const isEqualId = R.pathEq(['id'], progressStepId);

  R.forEach<ProgressStage>(stage => {
    if (progressStep !== null) {
      return;
    }

    const steps = R.pathOr([], ['steps', 'items'], stage);
    const companySteps = R.pathOr([], ['companySteps', 'items'], stage);
    const step = R.find<ProgressStep>(isEqualId, steps);
    const companyStep = R.find<ProgressCompanyStep>(isEqualId, companySteps);

    if (Boolean(step)) {
      progressStep = R.pathOr(null, ['stepValue', 'items', 0], step);
      return;
    }
    if (Boolean(companyStep)) {
      progressStep = companyStep;
    }
  }, progressStages);

  return progressStep;
};

export const isActiveProgressStepValue = (
  progressStepValue: ProgressStepValue | null | undefined,
): boolean => {
  if (R.isNil(progressStepValue)) {
    return false;
  }

  return progressStepValue.status === ProgressStepStatus.Active;
};

export const isVisibleProgressStepValue = (
  progressStepValue: ProgressStepValue | null | undefined,
): boolean | null | undefined => {
  if (R.isNil(progressStepValue)) {
    return true;
  }

  return progressStepValue.isVisible;
};

export const isActiveProgressCompanyStep = (
  progressCompanyStep: ProgressCompanyStep | null | undefined,
): boolean => {
  if (R.isNil(progressCompanyStep)) {
    return false;
  }

  return progressCompanyStep.status === ProgressStepStatus.Active;
};

export const getProgressValueStatus = (
  progressStepValue: ProgressStepValue | null | undefined,
): string => {
  if (
    R.isNil(progressStepValue) ||
    R.equals(progressStepValue.status, ProgressStepStatus.Pending)
  ) {
    return ProgressStepStatus.Pending;
  }

  return formatDate(progressStepValue.updatedAt, DateFormatPatterns.smallDateWithLocalTime) || '';
};

export const getCompanyStepStatus = (
  progressCompanyStep: ProgressCompanyStep | null | undefined,
): string => {
  if (
    R.isNil(progressCompanyStep) ||
    R.equals(progressCompanyStep.status, ProgressStepStatus.Pending)
  ) {
    return ProgressStepStatus.Pending;
  }

  return formatDate(progressCompanyStep.updatedAt, DateFormatPatterns.smallDateWithLocalTime) || '';
};

export const toggleProgressStepStatus = (isActiveStep: boolean): ProgressStepStatus => {
  return isActiveStep ? ProgressStepStatus.Pending : ProgressStepStatus.Active;
};

export const parseStepNameToCode = (name: string): string => {
  return name.replace(REG_EXP.TEMPLATE_PARSER_FROM_TITLE_TO_CODE, '_').toUpperCase();
};

export const toggleVisibilityStatus = (isVisible: boolean | null | undefined): boolean => {
  return !isVisible;
};
