import { gql } from '8base-react-sdk';

export const PORTFOLIO_MONITORING_COMPANIES_LIST = gql`
  query PortfolioMonitoringCompaniesList {
    portfolioCompaniesList {
      items {
        company {
          id
          name
          structure
          portfolioCompany {
            id
          }
        }
      }
    }
  }
`;

export const PORTFOLIO_MONITORING_VALUES = gql`
  query CalculatePortfolioMonitoring($companiesList: [ID!]!, $dates: [String!]!, $period: String!) {
    calculatePortfolioMonitoring(companiesList: $companiesList, dates: $dates, period: $period) {
      monitoringData {
        companyId
        companyName
        portfolioCompanyId
        monitoringValues {
          date
          committed
          deployed
          advanceRate
        }
        monitoringMetrics {
          id
          value
          adjustedValue
          date
          period
          comment
          metric {
            code
            format
            id
            name
          }
        }
      }
    }
  }
`;
