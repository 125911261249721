import _ from 'lodash';
import { TableColumn, TableColumnCellProps } from './interface';
import { MATERIAL_TABLE_CELL_PROPS_KEYS } from './interface';

type EmptyObject = Record<any, never>;

/**
 * Get cell props from column
 *
 * @param {Array<TableColumn>} columns
 * @example
 *  returns { align: 'center' }
 *  getCellPropsFromCol({
 *    title: 'name',
 *    key: 'name',
 *    dataPath: 'name'
 *    align: 'center'
 *  })
 * @returns {TableColumnCellProps}
 */
export function getMaterialCellPropsFromCol<TRecord>(
  column: TableColumn<TRecord> | null | undefined,
): TableColumnCellProps | EmptyObject {
  if (_.isNil(column)) {
    return {};
  }

  return _.pick(column, MATERIAL_TABLE_CELL_PROPS_KEYS);
}

/**
 * Get table keys from table columns
 *
 * @param {Array<TableColumn>} columns
 * @returns {Array<string>}
 */
export function getKeys<TRecord>(columns: Array<TableColumn<TRecord>>): Array<string> {
  return columns.map<string>((col: TableColumn<TRecord>) => col.key);
}

/**
 * Generate uniq ReactKey by args
 *
 * @param {TRecord} record
 * @returns {string}
 */
export function generateReactKeyByRecord<TRecord>(record: TRecord): string {
  return _.get(record, 'id') || JSON.stringify(record).substr(0, 10);
}
