import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Paper, Typography } from '@material-ui/core';

import { CircularProgressWithLabel } from 'src/components';
import { SaasScoreProgress, SaasScoreMetricsTable } from './index';
import { ScoreDetailsMetric } from 'src/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(1),
    textAlign: 'left',
  },
  title: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: {
    marginBottom: theme.spacing(5),
  },
}));

interface ScoringDetailsProps {
  isError: boolean;
  isPending: boolean;
  progress: number;
  saasScoreValue: number | null;
  tierValue: number | null;
  scoreDetailsMetrics: ScoreDetailsMetric[];
  handleRetry: () => void;
}

export const ScoringDetails: React.FC<ScoringDetailsProps> = ({
  isError,
  isPending,
  progress,
  saasScoreValue,
  tierValue,
  scoreDetailsMetrics,
  handleRetry,
}) => {
  const classes = useStyles();

  if (isPending) {
    return (
      <Paper elevation={1} className={classes.container}>
        <Typography variant="h5" className={classes.text}>
          Please wait, while metrics are being calculated...
        </Typography>
        <CircularProgressWithLabel value={progress} />
      </Paper>
    );
  }

  if (isError) {
    return (
      <Paper elevation={1} className={classes.container}>
        <Typography variant="h5" className={classes.text}>
          Couldn&apos;t calculate metrics. Try again.
        </Typography>
        <Button onClick={handleRetry} color="secondary" variant="outlined" size="large">
          Retry
        </Button>
      </Paper>
    );
  }

  const isReadyScoreMetrics = !isPending && !isError;

  return (
    <Paper elevation={1} className={classes.root}>
      <SaasScoreProgress saasScoreValue={saasScoreValue} tierValue={tierValue} />
      <Typography variant="h5" className={classes.title}>
        Scoring Details
      </Typography>

      {isReadyScoreMetrics && <SaasScoreMetricsTable scoreDetailsMetrics={scoreDetailsMetrics} />}
    </Paper>
  );
};
