export const prepareSignUpData = (data: any) => {
  return {
    connection: data.connection,
    email: data.email,
    password: data.password,
    user_metadata: {
      ...data.user_metadata,
      type_of_desired_capital: JSON.stringify(data.user_metadata.type_of_desired_capital),
    },
  };
};
