import React from 'react';
import * as R from 'ramda';
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import { Company } from 'src/graphql';
import { DateFormatPatterns } from 'src/utils';
import { MONITORING_REPORT_OPTIONS } from 'src/constants';

interface MonitoringFiltersProps {
  selectedCompanies: string[];
  view: string;
  companyList: Company[];
  from: string | null;
  till: string | null;
  onSelectCompanies: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string | unknown;
    }>,
  ) => void;
  onChangePeriod: (date: string | null | undefined, periodName: string, limitDate: string) => void;
  onChangeView: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string | unknown;
    }>,
  ) => void;
  setInvalidFilters: (hasInvalidFilters: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: theme.spacing(2),
  },
}));

export const MonitoringFilters: React.FC<MonitoringFiltersProps> = ({
  selectedCompanies,
  view,
  companyList,
  from,
  till,
  onSelectCompanies,
  onChangePeriod,
  onChangeView,
  setInvalidFilters,
}) => {
  const classes = useStyles();

  const maxDate: string | undefined = till ?? undefined;
  const minDate: string | undefined = from ?? undefined;

  const setError = (error: React.ReactNode) => setInvalidFilters(Boolean(error));

  const renderSelectedValue = React.useCallback(
    (selectedCompanies): React.ReactNode => {
      const findById = (selectedCompanyId: string) =>
        R.find(company => company?.portfolioCompany?.id === selectedCompanyId, companyList);

      const getName = R.pathOr('', ['name']);

      return R.map(R.pipe(findById, getName), selectedCompanies)
        .filter(companyName => !R.isEmpty(companyName))
        .join(', ');
    },
    [companyList],
  );

  return (
    <Grid className={classes.form} item container alignItems="flex-start" spacing={2}>
      <Grid item xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Companies</InputLabel>
          <Select
            multiple
            label="Companies"
            fullWidth
            value={selectedCompanies}
            onChange={onSelectCompanies}
            renderValue={renderSelectedValue}
          >
            {companyList.map((company: any) => {
              const foundCompany = R.find(
                item => item === company.portfolioCompany.id,
                selectedCompanies,
              );
              const isCheckedCompany = Boolean(foundCompany);

              return (
                <MenuItem key={company.id} value={company.portfolioCompany.id}>
                  <Checkbox checked={isCheckedCompany} />
                  <ListItemText primary={company.name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl fullWidth>
          <KeyboardDatePicker
            label="Start Date"
            format={DateFormatPatterns.shortDateWithSlash}
            value={from}
            onChange={(event, date) => {
              setInvalidFilters(false);
              onChangePeriod(date, 'from', maxDate as string);
            }}
            inputVariant="outlined"
            onError={setError}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            maxDate={maxDate}
          />
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl fullWidth>
          <KeyboardDatePicker
            label="End Date"
            format={DateFormatPatterns.shortDateWithSlash}
            value={till}
            onChange={(event, date) => {
              setInvalidFilters(false);
              onChangePeriod(date, 'till', minDate as string);
            }}
            inputVariant="outlined"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minDate={minDate}
          />
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Report</InputLabel>
          <Select label="Report" value={view} onChange={onChangeView} fullWidth>
            {MONITORING_REPORT_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
