import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as R from 'ramda';
import { makeStyles, TextField } from '@material-ui/core';

import { metricSettingsContext, EmptyCell, dirtyContext } from './index';
import { Format } from 'src/constants';
import { formatBound, isEmptyCell, boundNumbersToStrings, boundStringsToNumbers } from '../helpers';

interface BoundsRendererProps {
  code: string;
  isEnterprise: boolean;
  format: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    '& td': {
      border: `1px solid ${theme.palette.divider}`,
      padding: '0!important',
    },
  },
  inputTd: {
    padding: 0,
  },
  valueContainer: {
    padding: 6,
  },
  input: {
    display: 'block',
    height: 33,
    '& > div': {
      height: '100%',
    },
  },
}));

export const BoundsRenderer: React.FC<BoundsRendererProps> = ({ code, isEnterprise, format }) => {
  const classes = useStyles();
  const [values, setValues] = useState<string[]>([]);

  const [active, setActive] = useState<null | number>();
  const { settings, update } = useContext(metricSettingsContext);
  const { setIsDirty } = useContext(dirtyContext);

  const inputRef = useRef<HTMLInputElement>();

  const path = ['tierBands', isEnterprise ? 'enterprise' : 'smb', 'bounds'];

  const onChange = (idx: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^\d.-]/g, '');
    const newValues = [...values];

    newValues.splice(idx, 1, newValue);

    setValues(newValues);
    setIsDirty(true);
  };

  const onBlur = () => {
    setActive(null);
    update(code)(path)(boundStringsToNumbers(format as Format)(values));
  };

  useEffect(() => {
    if (!R.isNil(active) && !R.isNil(inputRef.current)) inputRef.current.focus();
  }, [active]);

  useLayoutEffect(() => {
    const bounds = R.pathOr(
      null,
      [code, 'tierBands', isEnterprise ? 'enterprise' : 'smb', 'bounds'],
      settings,
    );
    if (!bounds) return;

    const values = settings[code]['tierBands'][isEnterprise ? 'enterprise' : 'smb']['bounds'];
    setValues(boundNumbersToStrings(format as Format)(values));
  }, [settings, code, isEnterprise, format]);

  const renderValue = (v: string, idx: number) =>
    idx === active ? (
      <td className={classes.inputTd}>
        <TextField
          onBlur={onBlur}
          onChange={onChange(idx)}
          inputRef={inputRef}
          value={v}
          className={classes.input}
          size="small"
          variant="outlined"
        />
      </td>
    ) : (
      <td>
        <div className={classes.valueContainer} onClick={() => setActive(idx)} key={v}>
          {formatBound(format as Format)(parseFloat(v))}
        </div>
      </td>
    );

  if (isEmptyCell(isEnterprise, code)) return <EmptyCell />;

  return (
    <table className={classes.root}>
      <tbody>
        <tr>{values.map((v, idx) => renderValue(v, idx))}</tr>
      </tbody>
    </table>
  );
};
