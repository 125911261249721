/* eslint-disable react/display-name */
import React from 'react';

import { SelectionColumnTitleRenderer } from 'src/shared/table/renderers/SelectionColumnTitleRenderer';
import { SelectionColumnRenderer } from 'src/shared/table/renderers/SelectionColumnRenderer';
import { SortTitleRenderer } from 'src/shared/table/renderers/SortTitleRenderer';
import { PrimaryContactRenderer } from 'src/shared/table/renderers/PrimaryContactRenderer';
import { LinkRenderer } from 'src/shared/table/renderers/LinkRenderer';

import {
  ActiveColumnRenderer,
  ActionsRenderer,
  ActionsTitleRenderer,
  MonitoringStatusRenderer,
} from './index';
import { formatMoney, formatDate, DateFormatPatterns, formatNumberToMonth } from 'src/utils';
import { CELL_TYPES } from 'src/types/export';

export const getColumns = (
  order: string[],
  classes: Record<any, any>,
  canDelete: boolean,
): any[] => {
  const columns: Record<string, any> = {
    'Company Name': {
      title: <SortTitleRenderer field="name">Company name</SortTitleRenderer>,
      exportData: { title: 'Company name', type: CELL_TYPES.text, key: 'companyName' },
      key: 'companyName',
      render: (r: any) => LinkRenderer(r.name, `/portfolio-companies/${r.id}/dashboard`),
      align: 'left',
      style: {
        padding: 0,
      },
      width: 180,
    },
    'Active?': {
      title: <SortTitleRenderer field="active">Active?</SortTitleRenderer>,
      exportData: { title: 'Active?', type: CELL_TYPES.boolean, key: 'active' },
      key: 'active',
      render: ActiveColumnRenderer,
      align: 'left',
      width: 115,
    },
    'Monitoring Status': {
      title: 'Monitoring status',
      exportData: { title: 'Monitoring Status', type: CELL_TYPES.status, key: 'monitoringStatus' },
      align: 'left',
      width: 170,
      render: MonitoringStatusRenderer,
    },
    'Primary Contact': {
      title: <SortTitleRenderer field="contactFirstName">Primary contact</SortTitleRenderer>,
      exportData: { title: 'Primary Contact', type: CELL_TYPES.text, key: 'primaryContact' },
      render: PrimaryContactRenderer,
      align: 'left',
    },
    'Funded Date': {
      title: <SortTitleRenderer field="fundedDate">Funded date</SortTitleRenderer>,
      exportData: { title: 'Funded Date', type: CELL_TYPES.date, key: 'fundedDate' },
      render: (r: any) =>
        formatDate(
          r?.fundedDate,
          DateFormatPatterns.shortDateWithSlash,
          DateFormatPatterns.shortDateWithDash,
        ),
      key: 'fundingDate',
      align: 'left',
      width: 176,
    },
    Structure: {
      title: <SortTitleRenderer field="structure">Structure</SortTitleRenderer>,
      exportData: { title: 'Structure', type: CELL_TYPES.text, key: 'structure' },
      render: (r: any) => <div className={classes.structureColumn}>{r?.structure}</div>,
      align: 'left',
      width: 200,
    },
    '$ Deployed': {
      title: <SortTitleRenderer field="deployed">$ DEPLOYED</SortTitleRenderer>,
      exportData: { title: '$ Deployed', type: CELL_TYPES.currency, key: 'amountDeployed' },
      render: (r: any) => formatMoney(r?.deployed),
      align: 'left',
    },
    '$ Committed': {
      title: <SortTitleRenderer field="committed">$ COMMITTED</SortTitleRenderer>,
      exportData: { title: '$ Committed', type: CELL_TYPES.currency, key: 'amountCommitted' },
      render: (r: any) => formatMoney(r?.committed),
      align: 'left',
    },
    Term: {
      title: <SortTitleRenderer field="term">TERM</SortTitleRenderer>,
      exportData: { title: 'Term', type: CELL_TYPES.text, key: 'term' },
      render: (r: any) => formatNumberToMonth(r?.term),
      align: 'left',
    },
    'Final Advance Date': {
      title: <SortTitleRenderer field="finalAdvanceRate">FINAL ADVANCE DATE</SortTitleRenderer>,
      exportData: { title: 'Final Advance Date', type: CELL_TYPES.text, key: 'finalAdvanceRate' },
      render: (r: any) =>
        formatDate(
          r?.finalAdvanceRate,
          DateFormatPatterns.shortDateWithSlash,
          DateFormatPatterns.shortDateWithDash,
        ),
      key: 'finalAdvanceRate',
      align: 'left',
    },
    'Next Available Draw': {
      title: <SortTitleRenderer field="nextAvailableDraw">NEXT AVAILABLE DRAW</SortTitleRenderer>,
      exportData: { title: 'Next Available Draw', type: CELL_TYPES.text, key: 'nextAvailableDraw' },
      render: (r: any) =>
        formatDate(
          r?.nextAvailableDraw,
          DateFormatPatterns.shortDateWithSlash,
          DateFormatPatterns.shortDateWithDash,
        ),
      key: 'nextAvailableDraw',
      align: 'left',
    },
  };

  const displayColumns: any = canDelete
    ? [
        {
          title: <SelectionColumnTitleRenderer />,
          key: 'select',
          width: 42,
          render: SelectionColumnRenderer,
          className: classes.selectionColumn,
          fixed: 'left',
          align: 'center',
        },
      ]
    : [];

  order.forEach(key => {
    displayColumns.push(columns[key]);
  });

  displayColumns.push({
    title: <ActionsTitleRenderer />,
    fixed: 'right',
    render: ActionsRenderer,
    key: 'actions',
    width: 88,
  });

  return displayColumns;
};
