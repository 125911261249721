import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { ModalsContext } from 'src/app-builder';
import { Grid, Theme, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isNil } from 'ramda';

import { useLoanApplication } from 'src/hooks';
import { EmptyScreen } from 'src/components';
import { NOTES_LIST_QUERY, NotesListQuery, NotesListQueryVariables, SortOrder } from 'src/graphql';
import {
  DIALOGS,
  NoteCreateDialog,
  NoteDeleteDialog,
  NoteEditDialog,
  PdfViewDialog,
} from 'src/dialogs';
import { ActiveNote, NotesList, PageHeader } from 'src/shared/notes';
import { Search } from 'src/components';

import { ReactComponent as ContentPasteIcon } from 'src/assets/icons/content-paste.svg';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: 1,
    flex: '1 0 auto',
    overflow: 'hidden',
  },
  noteWrapper: {
    flex: 1,
    padding: theme.spacing(4),
    background: theme.customPalette.background.activeCard,
  },
  panel: {
    display: 'flex',
    flexFlow: 'column nowrap',
    background: '#FFF',
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
  plugText: {
    padding: theme.spacing(2),
  },
  emptyState: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Params = {
  id: string;
  noteId: string;
};

export const LoanApplicationNotesPage: React.FC = () => {
  const classes = useStyles();

  const [searchText, setSearchText] = React.useState<string>('');

  const history = useHistory();

  const { openModal } = React.useContext(ModalsContext);

  const params: Params = useParams();

  const { data: loanApplication, loading: loanApplicationLoading } = useLoanApplication();

  const companyId = loanApplication?.company?.id as string;

  const variables: NotesListQueryVariables = React.useMemo(
    () => ({
      sort: [{ updatedAt: SortOrder.Desc }],
      filter: {
        AND: [
          {
            company: { id: { equals: companyId } },
          },
          {
            OR: [
              {
                title: { contains: searchText },
              },
              {
                body: { contains: searchText },
              },
            ],
          },
        ],
      },
    }),
    [searchText, companyId],
  );

  const { data, loading: notesListLoading } = useQuery<NotesListQuery, NotesListQueryVariables>(
    NOTES_LIST_QUERY,
    {
      variables,
      skip: !companyId,
    },
  );

  const notes = data?.notesList?.items ?? [];

  const firstNoteId = !isEmpty(notes) ? notes[0]?.id ?? null : null;

  const loading = loanApplicationLoading || notesListLoading;

  const isActiveNoteLoading = (loading || !isEmpty(notes)) && Boolean(params?.noteId);

  const openCreateNoteDialog = React.useCallback(() => {
    openModal(DIALOGS.NOTE_CREATE_DIALOG, {
      companyId,
      onSuccess: () => {
        setSearchText('');
        history.push(`/loan-applications/${params.id}/notes`);
      },
    });
  }, [openModal, companyId, history, params, setSearchText]);

  const emptyScreen = React.useMemo(
    (): React.ReactNode => (
      <EmptyScreen
        text="We couldn't find any notes"
        actionText="Add notes"
        icon={ContentPasteIcon}
        onClick={openCreateNoteDialog}
      />
    ),
    [openCreateNoteDialog],
  );

  return (
    <Grid container direction="column">
      <PageHeader
        companyId={companyId}
        hasNotes={!loading && !isEmpty(notes)}
        openCreateNoteDialog={openCreateNoteDialog}
      />

      <Grid className={classes.content} direction="row" item container>
        <React.Fragment>
          <Grid item xs={5} sm={4} className={classes.panel}>
            <Search
              value={searchText}
              onChange={setSearchText}
              isHidden={isEmpty(notes) && isEmpty(searchText)}
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <React.Fragment>
                {isEmpty(notes) && emptyScreen}

                {!isEmpty(notes) && (
                  <NotesList path={`/loan-applications/${params.id}`} notes={notes} />
                )}
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={7} sm={8} className={classes.noteWrapper}>
            {isActiveNoteLoading && (
              <ActiveNote path={`/loan-applications/${params.id}`} id={params.noteId} />
            )}
          </Grid>
        </React.Fragment>
      </Grid>

      <NoteCreateDialog />
      <NoteEditDialog />
      <NoteDeleteDialog />
      <PdfViewDialog />

      {!params?.noteId && !isNil(firstNoteId) && (
        <Redirect to={`/loan-applications/${params.id}/notes/${firstNoteId}`} />
      )}
    </Grid>
  );
};
