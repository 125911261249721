import { CashMonitoringRecord } from 'src/graphql';

export enum CashMonitoringColumn {
  CompanyName = 'Company Name',
  CashPositionBanking = 'Cash Position (Banking)',
  CashPositionDate = 'Cash Position Date',
  MinCashBalanceCovenant = 'Min. Cash Balance Covenant',
  ChangeInCashLast2Weeks = 'Change in Cash Last 2 Weeks',
  ChangeInCashLast4Weeks = 'Change in Cash Last 4 Weeks',
  CashRunwayBanking = 'Cash Runway (Banking)',
  MinRunwayCovenant = 'Min. Runway Covenant',
  ScoreMonth = 'Score Month',
  Tier = 'Tier',
  Score = 'Score',
  ScoreChange = 'Score Change',
  ScoreTrendMoM = 'Score Trend MoM',
  Strengths = 'Strengths',
  Weaknesses = 'Weaknesses',
  Notes = 'Notes',
  ActionItem = 'Action Item',
}

export enum CashMonitoringRowKey {
  CompanyName = 'companyName',
  CashPositionBanking = 'cashPositionBanking',
  CashPositionDate = 'cashPositionDate',
  MinCashBalanceCovenant = 'minCashBalanceCovenant',
  ChangeInCashLast2Weeks = 'cashTrend2Weeks',
  ChangeInCashLast4Weeks = 'cashTrend4Weeks',
  CashRunwayBanking = 'cashRunwayBanking',
  MinRunwayCovenant = 'minRunwayCovenant',
  ScoreMonth = 'scoreMonth',
  Tier = 'tier',
  Score = 'score',
  ScoreChange = 'scoreChange',
  ScoreTrendMoM = 'scoreTrendMoM',
  Strengths = 'strengths',
  Weaknesses = 'weaknesses',
  Notes = 'notes',
  ActionItem = 'actionItem',
}

export enum EditableField {
  Note = 'note',
  Action = 'action',
}

export interface CashMonitoringStrengthsWeaknesses {
  strengths: Array<string>;
  weaknesses: Array<string>;
}

export interface CashMonitoringTableRow {
  id: string;
  [CashMonitoringRowKey.CompanyName]: string;
  [CashMonitoringRowKey.CashPositionBanking]: number | null;
  [CashMonitoringRowKey.CashPositionDate]: string | null;
  [CashMonitoringRowKey.MinCashBalanceCovenant]: number | null;
  [CashMonitoringRowKey.ChangeInCashLast2Weeks]: number | null;
  [CashMonitoringRowKey.ChangeInCashLast4Weeks]: number | null;
  [CashMonitoringRowKey.CashRunwayBanking]: number | null;
  [CashMonitoringRowKey.MinRunwayCovenant]: number | null;
  [CashMonitoringRowKey.ScoreMonth]: string | null;
  [CashMonitoringRowKey.Tier]: number | null;
  [CashMonitoringRowKey.Score]: number | null;
  [CashMonitoringRowKey.ScoreChange]: number | null;
  [CashMonitoringRowKey.ScoreTrendMoM]: number | null;
  [CashMonitoringRowKey.Strengths]: string;
  [CashMonitoringRowKey.Weaknesses]: string;
  [CashMonitoringRowKey.Notes]: CashMonitoringRecord | null;
  [CashMonitoringRowKey.ActionItem]: CashMonitoringRecord | null;
}

export type RecordFormData = Partial<Record<EditableField, string>>;

export type CashMonitoringCovenant = {
  isEnabled: boolean;
  value: number | null;
};
