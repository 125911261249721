import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { useAuth } from 'src/app-builder';
import { AuthRoutes } from 'src/constants';

const { REACT_APP_WORKSPACE_ID = '', REACT_APP_WORKSPACE_ENV = '' } = process.env;

type AuthData = {
  isEmailVerified: boolean;
  email: string;
  idToken: string;
};

const useStyles = makeStyles((theme: Theme) => {
  const { fontFamily } = theme.typography;
  return {
    errorContainer: {
      left: '50%',
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      fontSize: '16px',
      fontFamily,
    },
    logoutButton: {
      cursor: 'pointer',
      textDecoration: 'underline',
      background: 'none',
      border: 'none',
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontFamily,
    },
  };
});

export const AuthCallbackPage: React.FC = () => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = React.useState(null);

  const { authClient } = useAuth();
  const history = useHistory();

  const authenticate = React.useCallback(async () => {
    try {
      const authData: AuthData = await authClient.getAuthorizedData();

      const { isEmailVerified, email, idToken } = authData;

      authClient.setState({
        token: idToken,
        email,
        isEmailVerified,
        workspaceId: REACT_APP_WORKSPACE_ID,
        environmentName: REACT_APP_WORKSPACE_ENV,
      });

      if (isEmailVerified === undefined || idToken === undefined) {
        history.replace(AuthRoutes.Auth);
      } else if (isEmailVerified) {
        history.replace('/');
      } else if (!isEmailVerified) {
        history.replace(AuthRoutes.Confirmation);
      }
    } catch (e) {
      setErrorMessage(e.errorDescription);
    }
  }, [authClient, history]);

  React.useEffect(() => {
    authenticate();
  }, [authenticate]);

  const onLogout = React.useCallback(async () => {
    await authClient.logout();

    await authClient.purgeState();
  }, [authClient]);

  if (errorMessage) {
    return (
      <div className={classes.errorContainer}>
        {errorMessage}
        <br />
        <button type="button" className={classes.logoutButton} onClick={onLogout}>
          Try login to another account
        </button>
      </div>
    );
  }

  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
};
