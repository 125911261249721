import { DocumentNode } from 'graphql';
import { ColumnProps } from 'rc-table/es/sugar/Column';

import { MetricCode, MetricPeriod } from 'src/types';

export type ColumnDataTypes = 'text' | 'currency' | 'number' | 'boolean' | 'status' | 'date';

export type ExportDataType = {
  header: string;
  type: ColumnDataTypes;
  key: string;
  width?: number;
};

export type ExportParams = {
  columns?: any;
  rows?: any;
  metrics?: {
    underwritingMetricsList: Array<MetricCode[]>;
    weeklyMetricsList: Array<MetricCode[]>;
  };
  reportType?: MetricPeriod;
  dataEntry?: {
    query: DocumentNode;
  };
};

export interface TableColumnType extends ColumnProps<any> {
  exportData: ExportDataType;
}

export type ExportResponse = {
  base64data: string;
};

export const CELL_TYPES: Record<ColumnDataTypes, ColumnDataTypes> = {
  text: 'text',
  currency: 'currency',
  number: 'number',
  boolean: 'boolean',
  status: 'status',
  date: 'date',
};
