import React from 'react';
import { Button, Grid, makeStyles, Paper, Popover, TextField, Theme } from '@material-ui/core';

import { ProgressStepProps } from 'src/components';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '15px',
  },
  textField: {
    margin: '5px 0 5px 0',
  },
  submitButton: {
    margin: '10px 0 0 0',
  },
}));

interface ProgressStepCustomizePopoverProps {
  step: ProgressStepProps;
  open: boolean;
  anchorEl: Element | null | undefined;
  handleContextMenuClose: () => void;
  onChangeStep?: (step: ProgressStepProps, name: string, comment: string) => void;
}

export const ProgressStepCustomizePopover: React.FC<ProgressStepCustomizePopoverProps> = ({
  step,
  open,
  anchorEl,
  handleContextMenuClose,
  onChangeStep,
}) => {
  const classes = useStyles();

  const [title, setTitle] = React.useState<string>(step.name);
  const [comment, setComment] = React.useState<string | undefined>(step.comment);

  const onChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setComment(event.target.value);
  };
  const onSubmit = () => {
    if (onChangeStep) {
      onChangeStep(step, title, comment as string);
      handleContextMenuClose();
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper>
        <Grid className={classes.wrapper} container>
          <Grid item container>
            <TextField
              className={classes.textField}
              label="Title"
              variant="outlined"
              value={title}
              onChange={onChangeName}
              fullWidth
            />
            <TextField
              className={classes.textField}
              label="Comment"
              variant="outlined"
              value={comment}
              onChange={onChangeComment}
              fullWidth
            />
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              className={classes.submitButton}
              color="secondary"
              variant="contained"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  );
};
