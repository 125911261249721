import React from 'react';

import { MetricCellTier, MetricCellHeader } from 'src/components';
import { MetricValue } from 'src/graphql';

type RowSettings = Record<any, any>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const renderTierColumn = (row: MetricValue, _: any, idx: number): RowSettings => {
  const settings: RowSettings = {
    children: row,
    props: {},
  };
  switch (idx) {
    case 0: {
      settings.props.colSpan = 0;
      break;
    }
    case 1: {
      settings.children = <MetricCellHeader type="tier" />;
      settings.props.align = 'center';
      break;
    }
    default: {
      settings.children = <MetricCellTier value={row} />;
    }
  }
  return settings;
};
