import React from 'react';

import { useCurrentUser, useLocalStorage } from '../hooks';
import { Maybe, Role } from 'src/graphql';
import { APP_ROLES, APP_ROLE_NAMES_LIST, AppRoleName, AppRole } from 'src/types';
import { Permission } from 'src/constants';

interface ClientRolesProviderProps {
  children: React.ReactNode;
}

type ClientsRoles = {
  roles: Array<Role & { name: AppRoleName }>;
  currentRole?: Maybe<AppRole>;
  setRoleName: (name?: Maybe<AppRoleName>) => void;
};

export const ClientRolesContext = React.createContext<ClientsRoles>({
  roles: [],
  setRoleName: () => {},
});

export const useClientRoles = (): ClientsRoles => React.useContext(ClientRolesContext);

export const useAllowed = (): ((key: string) => boolean) => {
  const { user } = useCurrentUser();

  const { currentRole } = useClientRoles();

  const applicationFinalized = Boolean(
    user?.company?.applicationFinalized || user?.employer?.applicationFinalized,
  );

  const handler = React.useCallback(
    (key: string) => {
      const clientPortalRoles = [APP_ROLES.appCustomerOwner.name, APP_ROLES.appCustomer.name];

      if (currentRole && clientPortalRoles.includes(currentRole.name)) {
        if (applicationFinalized && key === Permission.CustomerHomeArea) {
          return false;
        }

        // for non finalized application we allow only customer home route
        if (!applicationFinalized && key !== Permission.CustomerHomeArea) {
          return false;
        }
      }

      const hasPermission = (currentRole?.permissions || []).some(
        permissionKey => permissionKey === key,
      );

      return hasPermission;
    },
    [currentRole, applicationFinalized],
  );

  return handler;
};

export const ClientRolesProvider: React.FC<ClientRolesProviderProps> = ({ children }) => {
  const { loading, user } = useCurrentUser();

  const [roleName, setRoleName]: [
    AppRoleName | undefined | null,
    (value?: Maybe<AppRoleName>) => void,
  ] = useLocalStorage('roleName');

  const roles = React.useMemo(() => user?.roles?.items || [], [user]);

  const appRoles = React.useMemo(
    () => roles.filter(({ name }) => APP_ROLE_NAMES_LIST.some(appRoleName => appRoleName === name)),
    [roles],
  ) as Array<Role & { name: AppRoleName }>;

  React.useEffect(() => {
    if (!loading && user) {
      const firstRoleName = appRoles[0]?.name as Maybe<AppRoleName>;

      const hasCurrentRole = appRoles.some(({ name }) => name === roleName);

      if (!roleName || !hasCurrentRole) {
        setRoleName(firstRoleName);
      }
    }
  }, [appRoles, loading, roleName, setRoleName, user]);

  const currentRole = roleName && APP_ROLES[roleName];

  return (
    <ClientRolesContext.Provider value={{ roles: appRoles, currentRole, setRoleName }}>
      {children}
    </ClientRolesContext.Provider>
  );
};
