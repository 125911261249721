import {
  CovenantTrackingPortfolioColumn,
  CovenantTrackingRowKey,
  CovenantTrackingRowType,
} from 'src/types';

export const COVENANT_OFF_MESSAGE = 'OFF';

export const COMPLIANCE_YES = 'Yes';
export const COMPLIANCE_NO = 'No';

export const COMPLIANCE_NO_COLOR = 'rgba(235, 67, 54, 0.2)';
export const COMPLIANCE_YES_COLOR = 'rgba(24, 201, 98, 0.2)';

export const NUMBER_NEGATIVE_COLOR = '#eb4336';
export const NUMBER_POSITIVE_COLOR = 'rgba(0,0,0,0.87)';

export const COVENANT_TRACKING_PORTFOLIO_COLUMNS_ORDER: Array<CovenantTrackingPortfolioColumn> = [
  CovenantTrackingPortfolioColumn.Name,
  CovenantTrackingPortfolioColumn.AdjustedNetCashBurnL3M,
  CovenantTrackingPortfolioColumn.MinCashPositionMonthly,
  CovenantTrackingPortfolioColumn.MinCashRunwayMonthly,
  CovenantTrackingPortfolioColumn.MinCashPositionWeekly,
  CovenantTrackingPortfolioColumn.MinCashRunwayWeekly,
  CovenantTrackingPortfolioColumn.CumulativeCashReceipts,
  CovenantTrackingPortfolioColumn.MinCashOfDrawsTaken,
  CovenantTrackingPortfolioColumn.CumulativeAdvanceRate,
  CovenantTrackingPortfolioColumn.ActualVSExpectedRevenue,
  CovenantTrackingPortfolioColumn.AdditionalCovenant,
];

export const COVENANT_TRACKING_ROW_ORDER = [
  CovenantTrackingRowType.CompanyName,
  CovenantTrackingRowType.Covenant,
  CovenantTrackingRowType.Actual,
  CovenantTrackingRowType.Difference,
  CovenantTrackingRowType.InCompliance,
];

export const COVENANT_TRACKING_COL_TO_ROW_KEY = {
  [CovenantTrackingPortfolioColumn.Name]: CovenantTrackingRowKey.Name,
  [CovenantTrackingPortfolioColumn.AdjustedNetCashBurnL3M]:
    CovenantTrackingRowKey.AdjustedNetCashBurnL3M,
  [CovenantTrackingPortfolioColumn.MinCashPositionMonthly]:
    CovenantTrackingRowKey.MinCashPositionMonthly,
  [CovenantTrackingPortfolioColumn.MinCashRunwayMonthly]:
    CovenantTrackingRowKey.MinCashRunwayMonthly,
  [CovenantTrackingPortfolioColumn.MinCashPositionWeekly]:
    CovenantTrackingRowKey.MinCashPositionWeekly,
  [CovenantTrackingPortfolioColumn.MinCashRunwayWeekly]: CovenantTrackingRowKey.MinCashRunwayWeekly,
  [CovenantTrackingPortfolioColumn.CumulativeCashReceipts]:
    CovenantTrackingRowKey.CumulativeCashReceipts,
  [CovenantTrackingPortfolioColumn.MinCashOfDrawsTaken]: CovenantTrackingRowKey.MinCashOfDrawsTaken,
  [CovenantTrackingPortfolioColumn.CumulativeAdvanceRate]:
    CovenantTrackingRowKey.CumulativeAdvanceRate,
  [CovenantTrackingPortfolioColumn.ActualVSExpectedRevenue]:
    CovenantTrackingRowKey.ActualVSExpectedRevenue,
  [CovenantTrackingPortfolioColumn.AdditionalCovenant]: CovenantTrackingRowKey.AdditionalCovenant,
};
