import React from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export const SearchField: React.FC<TextFieldProps> = ({ ...rest }) => {
  return (
    <TextField
      {...rest}
      size="small"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
