import { MetricValue } from 'src/graphql';
import { MetricCode } from 'src/types';

export type TableDelimiter = string;

interface MetricValueItem<T = MetricValue> {
  [key: string]: T;
}

export type ScoreMetricItem<T = MetricValue> = MetricValueItem<T> & {
  name: string;
  code: MetricCode;
};

export type ScoreMetricsTableDataStructureRow = ScoreMetricItem | TableDelimiter;

export enum ScoreArrowType {
  up,
  down,
  steady,
}
