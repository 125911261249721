import React, { memo } from 'react';
import RCTable from 'rc-table';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import { TableProps } from 'rc-table/es/Table';
import './styles.css';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& table': {
      fontFamily: '"Barlow", sans-serif',
      fontSize: 14,
      color: theme.palette.text.primary,
    },
    '& th': {
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      background: theme.palette.type === 'dark' ? '#616161 !important' : '#f1f1f1 !important',
      fontWeight: 500,
      lineHeight: '1.5rem',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td': {
      background:
        theme.palette.type === 'dark' ? '#212121 !important' : theme.palette.background.paper,
    },
  },
}));

export const TableShared = memo(({ columns, className, ...rest }: TableProps<any>) => {
  const classes = useStyles();
  const tableClassNames = `${classes.table} ${className}`;
  const DEFAULT_INDENT_SIZE = 40;

  return (
    <RCTable
      className={tableClassNames}
      columns={columns}
      {...rest}
      indentSize={DEFAULT_INDENT_SIZE}
    />
  );
});

TableShared.displayName = 'SharedTable';
