import React, { memo } from 'react';
import { Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { EmptyCompaniesScreen } from './index';

interface LayoutProps {
  isFirstLoading: boolean;
  loading: boolean;
  areCompaniesEmpty: boolean;
  areNotSearchedCompanies: boolean;
  renderHeader: React.ReactNode;
  renderTable: React.ReactNode;
  renderPagination: React.ReactNode;
  renderToolbar: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
  },
  root: {
    padding: 16,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
}));

const Layout = memo(
  ({
    isFirstLoading,
    loading,
    areCompaniesEmpty,
    areNotSearchedCompanies,
    renderHeader,
    renderTable,
    renderPagination,
    renderToolbar,
  }: LayoutProps) => {
    const classes = useStyles({ loading });
    if (isFirstLoading) return <LinearProgress />;

    return (
      <Grid className={classes.root} container spacing={4}>
        {renderHeader}
        <Grid className={classes.main}>
          <Paper>
            {areCompaniesEmpty && <EmptyCompaniesScreen />}
            {!areCompaniesEmpty && renderToolbar}
            {areNotSearchedCompanies && <EmptyCompaniesScreen />}
            <div className={classes.tableWrapper}>
              {!areCompaniesEmpty && !areNotSearchedCompanies && renderTable}
            </div>
            {!areCompaniesEmpty && !areNotSearchedCompanies && renderPagination}
          </Paper>
        </Grid>
      </Grid>
    );
  },
);

Layout.displayName = 'Layout';

export { Layout };
