import React from 'react';
import * as R from 'ramda';
import { Popover, PopoverActions } from '@material-ui/core';

import { RecordEditForm } from 'src/routes/cash-monitoring/components/RecordEditForm';
import { CashMonitoringRecord } from 'src/graphql';
import { delay } from 'src/utils';
import { EditableField, RecordFormData } from 'src/types';
import { useCashMonitoringRecords } from 'src/hooks';

export type ActiveRecord = {
  el: HTMLElement | null;
  record: CashMonitoringRecord | null;
  field: EditableField | null;
};

export interface CashMonitoringActionsContext {
  openEditForm: (
    event: React.MouseEvent<HTMLTableCellElement>,
    record: CashMonitoringRecord | null,
    field: EditableField,
  ) => void;
  closeEditForm: () => void;
  getActiveRecord: () => CashMonitoringRecord | null;
  activeRecord: ActiveRecord;
}

export const cashMonitoringActionsContext = React.createContext({
  openEditForm: () => {},
  closeEditForm: () => {},
  getActiveRecord: () => null,
  activeRecord: {
    el: null,
    record: null,
    field: null,
  },
} as CashMonitoringActionsContext);

export const CashMonitoringActionsProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popoverAction, setPopoverAction] = React.useState<PopoverActions>();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [activeRecord, setActiveRecord] = React.useState<ActiveRecord>({
    el: null,
    record: null,
    field: null,
  });

  const { upsertRecord, loading } = useCashMonitoringRecords();

  const openEditForm = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    record: CashMonitoringRecord | null,
    field: EditableField,
  ): void => {
    setIsPopoverOpen(true);

    setActiveRecord({
      el: event.currentTarget,
      record,
      field,
    });
  };

  const closeEditForm = React.useCallback(() => {
    setIsPopoverOpen(false);

    delay(300).then(() =>
      setActiveRecord({
        el: null,
        record: null,
        field: null,
      }),
    );
  }, []);

  const onSubmitForm = React.useCallback(
    async (formData: RecordFormData) => {
      const hasActiveRecord = !R.isNil(activeRecord?.record) && !R.isNil(activeRecord?.field);

      if (hasActiveRecord) {
        await upsertRecord(
          formData,
          activeRecord?.record as CashMonitoringRecord,
          activeRecord?.field as EditableField,
        );

        closeEditForm();
      }
    },
    [activeRecord, closeEditForm, upsertRecord],
  );

  const getActiveRecord = React.useCallback(() => {
    return activeRecord?.record ?? null;
  }, [activeRecord]);

  return (
    <cashMonitoringActionsContext.Provider
      value={{
        openEditForm,
        closeEditForm,
        getActiveRecord,
        activeRecord,
      }}
    >
      {children}
      <Popover
        disableRestoreFocus
        open={isPopoverOpen}
        onClose={closeEditForm}
        anchorEl={activeRecord?.el}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        action={setPopoverAction as (instance: PopoverActions | null) => void}
      >
        <RecordEditForm
          record={activeRecord?.record}
          field={activeRecord?.field as EditableField}
          loading={loading}
          onSubmitForm={onSubmitForm}
        />
      </Popover>
    </cashMonitoringActionsContext.Provider>
  );
};
