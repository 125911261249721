import React from 'react';
import { Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import { ColumnsType } from 'rc-table/es/interface';

import { TableShared } from 'src/shared/table/TableShared';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    padding: theme.spacing(2),
  },
  table: {
    '& .rc-table-body': {
      maxHeight: 'calc(100vh - 448px)',
    },

    '& th, & td': {
      padding: '8px 16px',
    },
    '& th:first-child': {
      zIndex: 101,
    },
    '& th': {
      height: '1px',
      zIndex: 100,
    },
    '& td': {
      borderBottom: `1px solid ${theme.palette.divider}`,

      '&.fixed': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },

      '&.editable': {
        '& > *': {
          height: '100%',
          display: 'inline-block',
        },

        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'rgba(225,225,225,0.15)',
        },
      },
    },
  },
  row: {
    height: 50,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface TableProps {
  rows: any;
  columns: ColumnsType<any>;
}

const Table = React.memo(({ rows, columns }: TableProps) => {
  const classes = useStyles();

  return (
    <Grid container item className={classes.tableContainer}>
      <Paper elevation={0} style={{ width: '100%' }}>
        <TableShared
          rowKey={(row: any) => row.id}
          className={classes.table}
          rowClassName={classes.row}
          scroll={{ x: 2500, y: 'auto' }}
          columns={columns}
          data={rows}
        />
      </Paper>
    </Grid>
  );
});

Table.displayName = 'Table';

export { Table };
