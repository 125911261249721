import React from 'react';
import * as R from 'ramda';
import { useMutation } from 'react-apollo';
import * as icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Theme,
  Divider,
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  Switch,
} from '@material-ui/core';
import { ModalsContext, usePopover } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { DIALOGS } from 'src/dialogs';
import {
  Facility,
  Draw,
  DrawUpdateMutation,
  DrawUpdateMutationVariables,
  DRAW_EDIT_MUTATION,
  RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION,
} from 'src/graphql';
import {
  formatMoney,
  formatPercents,
  formatMonths,
  formatMultiplier,
  formatDate,
  DateFormatPatterns,
  calculateAmortizing,
  t,
  getPrepayment,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  tableCellLabel: {
    fontWeight: 500,
    lineHeight: `${theme.spacing(5)}px`,
    whiteSpace: 'nowrap',
  },
  tableCellComment: {
    wordBreak: 'break-all',
  },
  thumb: {
    '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
      border: '1px grey solid',
    },
  },
}));

interface DrawItemProps {
  facility: Facility;
  draw: Draw;
  index: number;
  canEditDraw: boolean;
  canDeleteDraw: boolean;
  canChangeDrawActive: boolean;
}

export const DrawItem: React.FC<DrawItemProps> = props => {
  const { facility, draw, index, canEditDraw, canDeleteDraw, canChangeDrawActive } = props;
  const classes = useStyles();

  const notification = useNotification();

  const { openModal } = React.useContext(ModalsContext);

  const [recalculate] = useMutation(RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION, {
    refetchQueries: [
      'MetricsCompanyHeader',
      'FacilitiesTableContent',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
  });
  const [drawEdit] = useMutation<DrawUpdateMutation, DrawUpdateMutationVariables>(
    DRAW_EDIT_MUTATION,
    {
      onError: () => notification.error(t('draw_update_error')),
    },
  );

  const actionsPopover = usePopover();

  const openEditDrawDialog = React.useCallback(() => {
    openModal(DIALOGS.DRAW_EDIT_DIALOG, { initialValues: draw, facilityId: facility.id });

    actionsPopover.closePopover();
  }, [openModal, facility, draw, actionsPopover]);

  const openDeleteDrawDialog = React.useCallback(() => {
    openModal(DIALOGS.DRAW_DELETE_DIALOG, { id: draw.id, facilityId: facility.id });

    actionsPopover.closePopover();
  }, [openModal, facility, draw, actionsPopover]);

  const onChangeActive = React.useCallback(() => {
    openModal(DIALOGS.CONFIRMATION_DIALOG, {
      message: draw.active ? DIALOGS.DRAW_DISABLE_MESSAGE : DIALOGS.DRAW_ENABLE_MESSAGE,
      onConfirm: async () => {
        await drawEdit({ variables: { data: { id: draw.id, active: !draw.active } } });
        await recalculate({ variables: { facilityId: facility.id } });
      },
    });
  }, [openModal, drawEdit, facility.id, draw, recalculate]);

  const amortizingOrIOPeriodText = React.useMemo(() => {
    if (!Number.isFinite(draw?.iOPeriodAmortizationPeriod)) return null;

    const { isAmortizing } = calculateAmortizing(draw.fundedDate, draw.iOPeriod as number);
    const text = isAmortizing ? 'Amortizing' : 'IO Period';

    return `${text} (${draw.iOPeriodAmortizationPeriod} months)`;
  }, [draw]);

  const isPrepaymentShown = !R.isNil(draw?.prepayment);
  const prepayment = getPrepayment(draw);

  return (
    <React.Fragment>
      <Grid xs={12} item container alignItems="center" justify="space-between">
        <Typography variant="button">Draw #{index + 1}</Typography>
        {canEditDraw && canDeleteDraw && (
          <IconButton onClick={actionsPopover.openPopover}>
            <icons.MoreVert />
          </IconButton>
        )}
      </Grid>
      <Grid xs={12} item>
        <Divider />
      </Grid>
      <Grid xs={6} item>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Active?</TableCell>
              <TableCell align="right">
                <Switch
                  className={classes.thumb}
                  disabled={!canChangeDrawActive}
                  checked={Boolean(draw?.active)}
                  onChange={onChangeActive}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Funded Date</TableCell>
              <TableCell align="right">
                {formatDate(draw?.fundedDate, DateFormatPatterns.shortDateWithSlash)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Maturity Date</TableCell>
              <TableCell align="right">
                {formatDate(draw?.maturityDate, DateFormatPatterns.shortDateWithSlash)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>$ Deployed</TableCell>
              <TableCell align="right">{formatMoney(draw?.amountDeployed)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Interest Rate</TableCell>
              <TableCell align="right">{formatPercents(draw?.interestRate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Cash Share</TableCell>
              <TableCell align="right">{formatPercents(draw?.cashShare)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Return Cap</TableCell>
              <TableCell align="right">{formatMultiplier(draw?.returnCap)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Term</TableCell>
              <TableCell align="right">{formatMonths(draw?.term)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid xs={6} item>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>IO Period</TableCell>
              <TableCell align="right">{formatMonths(draw?.iOPeriod)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Amortization Period</TableCell>
              <TableCell align="right">{formatMonths(draw?.amortizationPeriod)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>IO Period / Amortizing</TableCell>
              <TableCell align="right">{amortizingOrIOPeriodText}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Exit Fee</TableCell>
              <TableCell align="right">{formatPercents(draw?.exitFee)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Prepayment</TableCell>
              <TableCell align="right">{isPrepaymentShown && prepayment}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>IRR</TableCell>
              <TableCell align="right">{formatPercents(draw?.iRR)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>MOIC</TableCell>
              <TableCell align="right">{formatMultiplier(draw?.mOIC)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCellLabel}>Comments</TableCell>
              <TableCell align="right" className={classes.tableCellComment}>
                {draw?.comments}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Popover
        disableRestoreFocus
        anchorEl={actionsPopover.el}
        open={Boolean(actionsPopover.el)}
        onClose={actionsPopover.closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          <MenuItem disabled={!canEditDraw} onClick={openEditDrawDialog}>
            Edit
          </MenuItem>
          <MenuItem disabled={!canDeleteDraw} onClick={openDeleteDrawDialog}>
            Delete
          </MenuItem>
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};
