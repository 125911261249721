import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { ResetAdjustmentButton, SubmitButton } from 'src/components';
import * as R from 'ramda';
import { MetricValue } from 'src/graphql';

interface FooterProps {
  pristine: boolean;
  submitting: boolean;
  onCancel: () => void;
  cell: MetricValue | null;
  values: {
    originalValue: number;
    adjustedValue: number;
    adjustmentValue: number;
    comment: string;
  };
  onClose: () => void;
}

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const {
    pristine,
    submitting,
    onCancel,
    cell,
    values: { adjustedValue },
    onClose,
  } = props;

  const hasAdjustedValue =
    (!R.isNil(cell?.adjustedValue) && !R.isNil(adjustedValue)) || !R.isNil(adjustedValue);

  return (
    <Grid container item spacing={2} justify="flex-end" direction={'row'}>
      <Grid item>
        <ResetAdjustmentButton
          submitting={submitting}
          hasAdjustedValue={hasAdjustedValue}
          onClose={onClose}
        />
      </Grid>
      <Grid item>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          disabled={pristine}
          loading={submitting}
        >
          Save
        </SubmitButton>
      </Grid>
    </Grid>
  );
};
