import React from 'react';
import { TablePagination } from '@material-ui/core';

import { usePagination } from 'src/hooks';

interface PaginationContainerProps {
  count: number;
}

export const PaginationContainer: React.FC<PaginationContainerProps> = ({ count }) => {
  const pagination = usePagination();

  return (
    <TablePagination
      component="div"
      count={count}
      onChangePage={pagination.handlePageChange}
      onChangeRowsPerPage={pagination.handlePerPageChange}
      page={pagination.page}
      rowsPerPage={pagination.perPage}
      rowsPerPageOptions={pagination.perPageOptions}
    />
  );
};
