import React from 'react';

type MainNavigation = {
  isExpanded: boolean;
  toggleExpanded: () => void;
};

const MainNavigationContext = React.createContext<MainNavigation>({
  isExpanded: false,
  toggleExpanded: () => {},
});

export const useMainNavigation = (): MainNavigation => React.useContext(MainNavigationContext);

export const MainNavigationProvider = React.memo(({ children }) => {
  const [isExpanded, setExpanded] = React.useState<boolean>(false);

  const toggleExpanded = React.useCallback(() => {
    setExpanded(isExpanded => !isExpanded);
  }, []);

  return (
    <MainNavigationContext.Provider value={{ isExpanded, toggleExpanded }}>
      {children}
    </MainNavigationContext.Provider>
  );
});

MainNavigationProvider.displayName = 'MainNavigationProvider';
