import React, { ElementType, FC } from 'react';
import { Box, Button, SvgIcon, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: `calc(100% - ${theme.spacing(8)}px)`,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  picture: {
    height: 186,
    width: 186,
    borderRadius: '50%',
    background: theme.palette.grey[100],
  },
  icon: {
    width: '100%',
    height: '100%',
    padding: 35,
    fill: theme.palette.grey[300],
  },
  text: {
    padding: theme.spacing(2),
  },
}));

interface EmptyScreenProps {
  icon: ElementType;
  text?: string;
  actionText?: string;
  onClick?: () => void;
}

export const EmptyScreen: FC<EmptyScreenProps> = props => {
  const { onClick, text, actionText, icon } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {Boolean(icon) && (
        <Box className={classes.picture}>
          <SvgIcon component={icon} className={classes.icon} />
        </Box>
      )}
      {Boolean(text) && (
        <Typography className={classes.text} color="textSecondary" variant="body1" align="center">
          {text}
        </Typography>
      )}
      {Boolean(onClick) && (
        <Button variant="contained" color="secondary" onClick={onClick}>
          {actionText}
        </Button>
      )}
    </Box>
  );
};
