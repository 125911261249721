import React from 'react';
import { useMutation } from 'react-apollo';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
  Typography,
} from '@material-ui/core';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { PORTFOLIO_COMPANIES_DELETE_MUTATION } from 'src/graphql';

import { DIALOGS } from './constants';
import { isFunction, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface PortfolioCompanyDeleteDialogProps {}

export const PortfolioCompanyDeleteDialog: React.FC<PortfolioCompanyDeleteDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal } = useModal(DIALOGS.PORTFOLIO_COMPANY_DELETE_DIALOG);

  const portfolioCompaniesCount = Array.isArray(args?.id) ? args?.id?.length : 1;
  const formattedPortfolioCompanies = pluralize('Portfolio Companies', portfolioCompaniesCount);

  const [deleteRow, { loading }] = useMutation(PORTFOLIO_COMPANIES_DELETE_MUTATION, {
    onError: () =>
      notification.error(
        t('portfolio_company_delete_error', {
          formattedPortfolioCompanies,
        }),
      ),
  });

  const onDelete = React.useCallback(async () => {
    if (Array.isArray(args?.id)) {
      await Promise.all(
        args?.id.slice(1).map((id: string) =>
          deleteRow({
            variables: { filter: { id }, force: true },
          }),
        ),
      );

      await deleteRow({
        variables: { filter: { id: args?.id[0] }, force: true },
        refetchQueries: ['SortablePortfolioCompaniesList'],
        awaitRefetchQueries: true,
      });
    } else {
      await deleteRow({
        variables: { filter: { id: args?.id }, force: true },
        refetchQueries: ['SortablePortfolioCompaniesList'],
        awaitRefetchQueries: true,
      });
    }

    notification.success(
      t('portfolio_company_delete_success', {
        formattedPortfolioCompanies,
      }),
    );

    if (isFunction(args?.onSuccess)) {
      args.onSuccess();
    }

    closeModal();
  }, [closeModal, args, deleteRow, notification, formattedPortfolioCompanies]);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ className: classes.root }}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Delete {formattedPortfolioCompanies}</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure want to delete this {formattedPortfolioCompanies.toLowerCase()}?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          onClick={onDelete}
          loading={loading}
        >
          Delete {formattedPortfolioCompanies}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
