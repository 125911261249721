import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';

import { useRouterQuery } from 'src/hooks';
import {
  COVENANT_TRACKING_FROM_UNDERWITING_PACKAGE,
  COVENANT_TRACKING_FROM_WEEKLY_BANK_REPORT,
  DEFAULT_COVENANT_REPORT_RANGE,
  Format,
} from 'src/constants';
import {
  CovenantValuesListQuery,
  CovenantValuesListQueryVariables,
  MetricValuesListQuery,
  MetricValuesListQueryVariables,
  COVENANT_VALUE_LIST_QUERY,
  METRIC_VALUES_LIST_QUERY,
  PortfolioCompany,
  CalculatePortfolioMonitoringQuery,
  CalculatePortfolioMonitoringQueryVariables,
  PORTFOLIO_MONITORING_VALUES,
} from 'src/graphql';
import { CovenantMetricCell } from 'src/routes/portfolio-companies/portfolioCompaniesCovenantPage/components/CovenantMetricCell';
import {
  CELL_TYPES,
  CovenantRowType,
  CovenantTableType,
  MetricPeriod,
  MonitoringCompany,
} from 'src/types';
import {
  DateFormatPatterns,
  formatDate,
  getCovenantRow,
  getEndOfMonth,
  getExactDateRangeBetweenTwoDates,
  getNowDate,
  getStartOfMonth,
  mapTotalDeployedByDate,
  subtractDate,
} from 'src/utils';
import { MetricHeaderCell } from 'src/routes/portfolio-companies/portfolioCompaniesCovenantPage/components/MetricHeaderCell';

import { useCustomCovenants } from './useCustomCovenants';

const buildDateFilter = ([from, till]: Array<string | null>) => {
  return {
    AND: [
      {
        date: {
          gte: from,
        },
      },
      {
        date: {
          lte: till,
        },
      },
    ],
  };
};

export type CovenantItemType = {
  covenantTableType: CovenantTableType;
  name: CovenantRowType;
  value?: string | number;
  format?: Format;
  covenantId?: string;
  isEditable?: boolean | undefined;
};

export type UseCovenantTrackingOutput = {
  loading: boolean;
  selectedPeriod: string[];
  metrics: Record<string, any>[];
  metricValues: any;
  rows: any[];
};

export const useCovenantTracking = (
  portfolioCompany: PortfolioCompany | null | undefined,
): UseCovenantTrackingOutput => {
  const query = useRouterQuery();

  const portfolioCompanyId = portfolioCompany?.id as string;
  const companyId = portfolioCompany?.company?.id as string;

  const defaultTill = getEndOfMonth(subtractDate(getNowDate()) as string);
  const defaultFrom = getStartOfMonth(
    subtractDate(defaultTill, 'months', DEFAULT_COVENANT_REPORT_RANGE - 1) as string,
  );

  const from = R.pathOr(defaultFrom, ['from'], query) as string;
  const till = R.pathOr(defaultTill, ['till'], query) as string;

  const selectedPeriod = React.useMemo(() => [from, till], [from, till]);
  const selectedDates = React.useMemo(() => getExactDateRangeBetweenTwoDates(from, till, 'month'), [
    from,
    till,
  ]);

  const dateFilter = React.useMemo(() => buildDateFilter(selectedPeriod), [selectedPeriod]);

  const { customCovenantValues } = useCustomCovenants();

  const {
    data: underwritingMetricValuesResponse,
    loading: underwritingMetricValuesLoading,
  } = useQuery<MetricValuesListQuery, MetricValuesListQueryVariables>(METRIC_VALUES_LIST_QUERY, {
    variables: {
      filter: {
        metric: { code: { in: COVENANT_TRACKING_FROM_UNDERWITING_PACKAGE } },
        company: { id: { equals: companyId } },
        period: { equals: MetricPeriod.Month },
        ...dateFilter,
      },
    },
    skip: !companyId,
  });

  const { data: weeklyMetricData, loading: weeklyMetricValuesLoading } = useQuery<
    MetricValuesListQuery,
    MetricValuesListQueryVariables
  >(METRIC_VALUES_LIST_QUERY, {
    variables: {
      filter: {
        metric: { code: { in: COVENANT_TRACKING_FROM_WEEKLY_BANK_REPORT } },
        company: { id: { equals: companyId } },
        period: { equals: MetricPeriod.Week },
        ...dateFilter,
      },
    },
    skip: !companyId,
  });

  const { data: covenantValuesData, loading: covenantValuesLoading } = useQuery<
    CovenantValuesListQuery,
    CovenantValuesListQueryVariables
  >(COVENANT_VALUE_LIST_QUERY, {
    variables: {
      filter: {
        company: { id: { equals: companyId } },
      },
    },
    skip: !companyId,
  });

  const { data: portfolioMonitoring, loading: portfolioMonitoringLoading } = useQuery<
    CalculatePortfolioMonitoringQuery,
    CalculatePortfolioMonitoringQueryVariables
  >(PORTFOLIO_MONITORING_VALUES, {
    variables: {
      companiesList: portfolioCompanyId,
      dates: selectedDates,
      period: MetricPeriod.Month,
    },
    skip: !portfolioCompanyId,
  });

  const totalDeployedMap = React.useMemo(() => {
    const monitoringData = (portfolioMonitoring?.calculatePortfolioMonitoring?.monitoringData ??
      []) as MonitoringCompany[];

    const companyData = monitoringData.find(company => company?.companyId === companyId);

    return mapTotalDeployedByDate(companyData?.monitoringValues ?? []);
  }, [companyId, portfolioMonitoring?.calculatePortfolioMonitoring?.monitoringData]);

  const weeklyMetricValues = React.useMemo(() => weeklyMetricData?.metricValuesList?.groups || [], [
    weeklyMetricData,
  ]);

  const underwritingMetricValues = React.useMemo(() => {
    return underwritingMetricValuesResponse?.metricValuesList?.groups || [];
  }, [underwritingMetricValuesResponse]);

  const covenantValues = React.useMemo(
    () =>
      covenantValuesData?.covenantValuesList.items?.filter(
        covenantValue => covenantValue.isEnabled,
      ) || [],
    [covenantValuesData],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Covenant',
        width: 150,
        fixed: 'left',
        align: 'left',
        exportData: {
          header: 'Covenant',
          type: CELL_TYPES.text,
          key: 'covenant',
          width: 35,
        },
        render: (element: CovenantItemType) => {
          return <MetricHeaderCell value={element.name} />;
        },
      },
      ...(selectedDates?.map((date: string) => {
        const formattedDate = formatDate(
          date,
          DateFormatPatterns.shortDateWithSlash,
          DateFormatPatterns.shortDateWithDash,
        );

        return {
          title: formattedDate,
          align: 'center',
          width: 100,
          exportData: {
            header: formattedDate,
            type: CELL_TYPES.text,
            key: date,
            width: 30,
          },
          render: (covenantItem: CovenantItemType) => {
            return <CovenantMetricCell covenantItem={covenantItem} date={date} />;
          },
        };
      }) || []),
    ];
  }, [selectedDates]);

  const rows = React.useMemo(() => {
    return [
      ...getCovenantRow(
        CovenantTableType.AdjustedNetCashBurnL3MCovenant,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.MinCashPositionMonthly,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.MinCashRunwayMonthly,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.MinCashPositionWeekly,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.MinCashRunwayWeekly,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.CumulativeCashReceipts,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.MinCashAsPercentOfDrawsTaken,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
        totalDeployedMap,
      ),
      ...getCovenantRow(
        CovenantTableType.CumulativeAdvanceRate,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.ActualVsExpectedRevenue,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
      ...getCovenantRow(
        CovenantTableType.AdditionalCovenant,
        covenantValues,
        underwritingMetricValues,
        weeklyMetricValues,
        customCovenantValues,
      ),
    ];
  }, [
    covenantValues,
    underwritingMetricValues,
    weeklyMetricValues,
    customCovenantValues,
    totalDeployedMap,
  ]);

  const loading =
    underwritingMetricValuesLoading ||
    covenantValuesLoading ||
    weeklyMetricValuesLoading ||
    portfolioMonitoringLoading;

  return {
    metrics: columns,
    rows,
    metricValues: underwritingMetricValues,
    loading,
    selectedPeriod,
  };
};
