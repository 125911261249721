export const ALL_USERS_COLUMN_KEYS = ['Name', 'Title', 'Email', 'Status', 'Roles'];

export const ALL_LOAN_APPLICATION_COLUMN_KEYS = [
  'Applicant Name',
  'Status',
  'Contact Name',
  'Account Creation Date',
  'Age',
  'Stage',
  'SaaSScore',
  'Amount Requested',
  '$ Committed',
  '1st Draw Proposed',
  'Target Close Date',
  'Deal Team',
];

export const ALL_PORTFOLIO_COMPANIES_COLUMN_KEYS = [
  'Company Name',
  'Active?',
  'Monitoring Status',
  'Primary Contact',
  'Funded Date',
  'Structure',
  '$ Deployed',
  '$ Committed',
  'Term',
  'Final Advance Date',
  'Next Available Draw',
];
