import React from 'react';
import {
  Popover,
  PopoverProps,
  Box,
  Grid,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import { Form, FieldArray, Field, CheckboxField, FORM_ERROR } from 'src/app-builder';
import { useMutation } from 'react-apollo';

import { SubmitButton } from 'src/components';
import { PORTFOLIO_COMPANIES_EDIT_MUTATION } from 'src/graphql';
import { MonitoringStatus } from 'src/constants';

const StatusField: React.FC<any> = ({ input, meta }) => {
  return (
    <Grid item>
      <FormControlLabel
        control={<Field name={`${input.name}.checked`} component={CheckboxField} />}
        label={input.value.value}
      />
    </Grid>
  );
};

interface MonitoringStatusesPopoverProps extends PopoverProps {
  onClose: () => void;
  portfolioCompanyId: string | null;
  statuses: string[];
}

export const MonitoringStatusesPopover: React.FC<MonitoringStatusesPopoverProps> = ({
  anchorEl,
  open,
  onClose,
  portfolioCompanyId,
  statuses,
}) => {
  const [portfolioCompanyUpdate] = useMutation(PORTFOLIO_COMPANIES_EDIT_MUTATION, {
    refetchQueries: ['SortablePortfolioCompaniesList'],
  });

  const onSubmit = React.useCallback(
    async data => {
      const statuses = data.statuses.reduce(
        (result: string[], { value, checked }: { value: string; checked: boolean }) =>
          checked ? [...result, value] : result,
        [],
      );

      if (statuses.length === 0) {
        return {
          [FORM_ERROR]: 'Please select one or more statuses',
        };
      }

      await portfolioCompanyUpdate({
        variables: {
          data: {
            id: portfolioCompanyId,
            monitoringStatus: statuses,
          },
        },
      });

      onClose();
    },
    [portfolioCompanyUpdate, portfolioCompanyId, onClose],
  );

  const initialValues = React.useMemo(
    () => ({
      statuses: Object.values(MonitoringStatus).map(value => ({
        value,
        checked: statuses.indexOf(value) !== -1,
      })),
    }),
    [statuses],
  );

  if (!portfolioCompanyId) {
    return null;
  }

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine, submitError }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            padding={2}
            paddingTop={1}
            paddingBottom={1}
          >
            <Grid container direction="column">
              <FieldArray name="statuses">
                {({ fields }) =>
                  fields.map(name => <Field key={name} name={name} component={StatusField} />)
                }
              </FieldArray>
            </Grid>
            {submitError && <FormHelperText error>{submitError}</FormHelperText>}
            <Grid container justify="flex-end">
              <Grid item>
                <SubmitButton
                  type="submit"
                  color="secondary"
                  disabled={pristine}
                  loading={submitting}
                >
                  Apply
                </SubmitButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Form>
    </Popover>
  );
};
