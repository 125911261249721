import React, { useContext } from 'react';

import { Head } from 'src/shared/team/Head';
import { actionsContext } from 'src/shared/team/ActionsProvider';

interface HeadContainerProps {
  label: string;
}

export const HeadContainer: React.FC<HeadContainerProps> = ({ label }) => {
  const { openTeamInviteDialog, permissions } = useContext(actionsContext);
  return <Head label={label} canInvite={permissions.canInvite} onClick={openTeamInviteDialog} />;
};

export default HeadContainer;
