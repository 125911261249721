import React from 'react';

import { Filters } from './Filters';
import { StatementsReportType } from 'src/graphql';
import { Source } from 'src/types';

interface FiltersContainerProps {
  activeReport: StatementsReportType;
  onChangeActiveReport: (reportType: StatementsReportType, period: string[]) => void;
  onRefetch: () => void;
  from: string | null;
  till: string | null;
  integrationType: Source.Xero | Source.QBO | null;
  isPlaidIntegrationConnected: boolean;
}

export const FiltersContainer: React.FC<FiltersContainerProps> = props => {
  const {
    activeReport,
    from,
    till,
    onChangeActiveReport,
    onRefetch,
    integrationType,
    isPlaidIntegrationConnected,
  } = props;

  const onSubmit = async (values: any) => {
    const { selectedReport, period } = values;

    onChangeActiveReport(selectedReport, period);
  };

  const initialValues = React.useMemo(
    () => ({
      selectedReport: activeReport,
      period: [from, till],
    }),
    [activeReport, from, till],
  );

  return (
    <React.Fragment>
      <Filters
        initialValues={initialValues}
        integrationType={integrationType}
        onSubmit={onSubmit}
        onRefetch={onRefetch}
        isPlaidIntegrationConnected={isPlaidIntegrationConnected}
      />
    </React.Fragment>
  );
};
