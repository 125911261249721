import React from 'react';
import { FieldSchema } from '@8base/utils';

import { AppAreaField } from 'src/constants/app';

interface FieldLinkProps {
  field: FieldSchema;
  value: any;
  areaField: AppAreaField;
  record: Record<string, any>;
}

export const FieldMoney: React.FC<FieldLinkProps> = ({ value, areaField }) => {
  const currency = areaField.formatOptions.currency || 'USD';
  const locale = areaField.formatOptions.locale || 'en-US';
  const minimumFractionDigits = areaField.formatOptions.minimumFractionDigits || 0;
  const parsedValue = parseInt(value, 10);

  return Number.isFinite(value) ? (
    <React.Fragment>{`${parsedValue.toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
    })}`}</React.Fragment>
  ) : null;
};
