import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  GridSize,
  Paper,
  Theme,
  Typography,
  LinearProgress,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';
import { ModalsContext, useCurrentUser } from 'src/app-builder';

import { useProgressStages, useCompany } from 'src/hooks';

import { ProgressStageList, Dashboard, ProgressStepProps } from 'src/components';
import { OnboardingClientUserDialog } from 'src/dialogs/OnboardingClientUserDialog';

import {
  ProgressStage,
  ProgressStep,
  Company,
  ProgressCompanyStep,
  ProgressStepValue,
} from 'src/graphql';
import { ProgressStepCode } from 'src/types';

import { LoanApplicationStage, DEFAULT_COUNT_MONTHS, ProgressStepType } from 'src/constants';
import {
  extractUserCompanyId,
  getCompanyStepStatus,
  getDateRange,
  getProgressValueStatus,
  isActiveProgressCompanyStep,
  isActiveProgressStepValue,
  isVisibleProgressStepValue,
} from 'src/utils';
import { DIALOGS, DocumentCreateDialog } from 'src/dialogs';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  header: {
    height: theme.spacing(11),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface ClientPortalDashboardPageProps {}
type DashboardGridProps = { xs: GridSize };

export const ClientPortalDashboardPage: React.FC<ClientPortalDashboardPageProps> = () => {
  const classes = useStyles();
  const { openModal } = React.useContext(ModalsContext);
  const history = useHistory();

  const { user, loading: userLoading } = useCurrentUser();
  const companyId = extractUserCompanyId(user) as string;

  const period = getDateRange(DEFAULT_COUNT_MONTHS);

  const { data: company, loading: companyLoading } = useCompany(companyId);

  const loanApplicationStage = company?.loanApplication?.stage as LoanApplicationStage;
  const isPortfolioCompany = Boolean(company?.portfolioCompany);

  const {
    data: progressStages,
    loading: progressStagesLoading,
    upsertStepComments,
  } = useProgressStages(companyId, loanApplicationStage, {
    skip: !companyId || isPortfolioCompany,
  });

  const openCreateRowDialog = () => {
    openModal(DIALOGS.DOCUMENT_CREATE_DIALOG, {
      companyId,
      onSuccess: () => {
        history.push('/client-portal/documents');
      },
    });
  };

  const getStepsFromStage = (stage: ProgressStage) => {
    const convertStepToProps = (step: ProgressStep | ProgressCompanyStep) => {
      const { name, code, comment } = step;
      const stepValue = (step as ProgressStep)?.stepValue;
      const helperText = (step as ProgressStep)?.helperText;
      const progressCompanyValue = R.pathOr(null, ['items', 0], stepValue);
      const status = stepValue
        ? getProgressValueStatus(progressCompanyValue)
        : getCompanyStepStatus(step as ProgressCompanyStep);
      const active = stepValue
        ? isActiveProgressStepValue(progressCompanyValue)
        : isActiveProgressCompanyStep(step as ProgressCompanyStep);
      const isVisible = stepValue
        ? isVisibleProgressStepValue(progressCompanyValue)
        : (step as ProgressCompanyStep).isVisible;
      const bigfootTeamComment = stepValue
        ? ((progressCompanyValue as unknown) as ProgressStepValue)?.bigfootTeamComment
        : (step as ProgressCompanyStep).bigfootTeamComment;
      const applicantComment = stepValue
        ? ((progressCompanyValue as unknown) as ProgressStepValue)?.applicantComment
        : (step as ProgressCompanyStep).applicantComment;

      return {
        id: step.id as string,
        name: R.pathOr(name, ['customStepName'], progressCompanyValue) as string,
        comment: R.pathOr(comment, ['customStepComment'], progressCompanyValue) as string,
        isVisible: isVisible,
        helperText: helperText as string,
        code: code as ProgressStepCode,
        bigfootTeamComment: bigfootTeamComment,
        applicantComment: applicantComment,
        status: status,
        active: active,
      };
    };

    const markCompanySteps = (step: ProgressStepProps) => {
      return { ...step, type: ProgressStepType.CompanyStep };
    };

    const filterHiddenSteps = (step: ProgressStepProps) => {
      return !!step.isVisible;
    };

    const steps = R.pipe(
      R.pathOr([], ['steps', 'items']),
      R.map(convertStepToProps),
      R.filter(filterHiddenSteps),
    )(stage);
    const companySteps = R.pipe(
      R.pathOr([], ['companySteps', 'items']),
      R.map(convertStepToProps),
      R.filter(filterHiddenSteps),
      R.map(markCompanySteps),
    )(stage);
    const allSteps = [...steps, ...companySteps];
    return allSteps;
  };

  const loading = userLoading || companyLoading || progressStagesLoading;
  const isApplicationProgressVisible = !isPortfolioCompany && !companyLoading;

  const justifyContent = isApplicationProgressVisible ? 'space-between' : 'center';
  const dashboardContainerGridProps: DashboardGridProps = {
    xs: isApplicationProgressVisible ? 5 : 12,
  };

  if (companyLoading) {
    return <LinearProgress />;
  }

  return (
    <Grid container direction="row" justify={justifyContent} spacing={1}>
      <Grid container item direction="column" wrap="nowrap" {...dashboardContainerGridProps}>
        <Paper className={classes.paper}>
          <Grid className={classes.header} item>
            <Typography variant="subtitle1">Dashboard</Typography>
          </Grid>

          <Dashboard
            company={company as Company}
            period={period}
            oneColumn={isApplicationProgressVisible}
            loading={loading}
          />
        </Paper>
      </Grid>

      {isApplicationProgressVisible && (
        <Grid item container direction="column" xs={7}>
          <Paper className={classes.paper}>
            <Grid className={classes.header} item container>
              <Typography variant="subtitle1">Funding Progress</Typography>
              <Button variant="outlined" color="secondary" onClick={openCreateRowDialog}>
                Upload
              </Button>
              <Grid item container>
                <HelpOutline />
                <Typography>
                  <Box fontStyle="italic">
                    &nbsp;- Whenever you see a question mark, you can hover for detail on what
                    we&apos;re asking you to provide.
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <ProgressStageList
              progressStages={progressStages}
              renderSteps={getStepsFromStage}
              loading={progressStagesLoading}
              upsertStepComments={upsertStepComments}
            />
          </Paper>
          <DocumentCreateDialog />
          <OnboardingClientUserDialog />
        </Grid>
      )}
    </Grid>
  );
};
