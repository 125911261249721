import React from 'react';
import * as R from 'ramda';
import { Grid, GridSize, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MetricChart, MultiMetricChart } from 'src/components';
import { DashboardMetricGroups, MetricCode } from 'src/types';
import { Format } from 'src/constants';
import {
  calculateProgress,
  convertToPercentage,
  getCurrentMetricValues,
  getEnterpriseChartMetrics,
  getFormattedMetricValue,
  getPrevMetricValues,
  formatProgressValue,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  chartCategoryTitle: {
    padding: theme.spacing(2),
  },
  chartGridItem: {
    flex: 1,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

interface EnterpriseDashboardProps {
  dashboardMetrics: DashboardMetricGroups[];
  oneColumn?: boolean;
}

type Value = string | number | null;
type ChartGridProps = { xs: GridSize };

export const EnterpriseDashboard: React.FC<EnterpriseDashboardProps> = props => {
  const { dashboardMetrics, oneColumn = false } = props;
  const classes = useStyles();

  const chartMetrics = React.useMemo(() => getEnterpriseChartMetrics(dashboardMetrics), [
    dashboardMetrics,
  ]);

  const currentMetricValues = React.useMemo(() => getCurrentMetricValues(chartMetrics), [
    chartMetrics,
  ]);

  const prevMetricValues = React.useMemo(() => getPrevMetricValues(chartMetrics), [chartMetrics]);

  const getCurrentValue = (metricCode: MetricCode, format: Format): string | number | null => {
    if (metricCode === MetricCode.SaaSScore) {
      const scoreValue = currentMetricValues[metricCode];

      return !R.isNil(scoreValue) ? scoreValue.toFixed(0) : null;
    }

    return getFormattedMetricValue(currentMetricValues[metricCode], format);
  };

  const getProgressValue = (metricCode: MetricCode, format?: Format): number | null => {
    if (metricCode === MetricCode.RevenueGrowthRateMoM) {
      const value = currentMetricValues[metricCode];

      return convertToPercentage(value);
    }

    return calculateProgress(currentMetricValues[metricCode], prevMetricValues[metricCode], format);
  };

  const chartGridProps: ChartGridProps = { xs: oneColumn ? 12 : 6 };
  const gridContainerProps = oneColumn ? {} : { container: true, item: true };

  return (
    <React.Fragment>
      <Grid {...gridContainerProps}>
        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="SaaSScore"
            currentValue={getCurrentValue(MetricCode.SaaSScore, Format.Count)}
            progressValue={getProgressValue(MetricCode.SaaSScore)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="line"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.SaaSScore]}
          />
        </Grid>

        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MultiMetricChart
            chartName="Revenue &amp; Revenue Growth"
            currentValue={getCurrentValue(MetricCode.TotalRevenue, Format.Money)}
            progressValue={getProgressValue(MetricCode.RevenueGrowthRateMoM, Format.Percent)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            data={chartMetrics.RevenueAndGrowthMergeMetrics}
            withLegend
            barCharts={[
              {
                valueKey: 'RevenueMetricValue',
                xAxisKey: 'date',
                label: 'Revenue',
              },
            ]}
            lineCharts={[
              {
                valueKey: 'RevenueGrowthMetricValue',
                xAxisKey: 'date',
                lineColor: 'orange',
                label: 'Revenue Growth',
              },
            ]}
            yAxes={[
              {
                valueKey: 'RevenueMetricValue',
                position: 'right',
                formatValue: (value: Value) =>
                  getFormattedMetricValue(value, Format.Money) as string,
              },
              {
                valueKey: 'RevenueGrowthMetricValue',
                position: 'left',
                formatValue: (value: Value) =>
                  getFormattedMetricValue(value, Format.Percent) as string,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid {...gridContainerProps}>
        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="Gross Margin"
            currentValue={getCurrentValue(MetricCode.GrossMargin, Format.Percent)}
            progressValue={getProgressValue(MetricCode.GrossMargin, Format.Percent)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="line"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.GrossMargin]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Percent) as string}
          />
        </Grid>

        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="Net Revenue Retention Rate"
            currentValue={getCurrentValue(MetricCode.NetRevenueRetentionRate, Format.Percent)}
            progressValue={getProgressValue(MetricCode.NetRevenueRetentionRate, Format.Percent)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="line"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.NetRevenueRetentionRate]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Percent) as string}
          />
        </Grid>
      </Grid>

      <Grid {...gridContainerProps}>
        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="MRR Net Churn Rate"
            currentValue={getCurrentValue(MetricCode.MRRNetChurnRate, Format.Percent)}
            progressValue={getProgressValue(MetricCode.MRRNetChurnRate, Format.Percent)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="line"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.MRRNetChurnRate]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Percent) as string}
          />
        </Grid>

        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="Gross Customer Churn Rate"
            currentValue={getCurrentValue(MetricCode.GrossCustomerChurnRate, Format.Percent)}
            progressValue={getProgressValue(MetricCode.GrossCustomerChurnRate, Format.Percent)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="line"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.GrossCustomerChurnRate]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Percent) as string}
          />
        </Grid>
      </Grid>

      <Grid {...gridContainerProps}>
        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="Cash Position"
            currentValue={getCurrentValue(MetricCode.CashPosition, Format.Money)}
            progressValue={getProgressValue(MetricCode.CashPosition)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="bar"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.CashPosition]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Money) as string}
          />
        </Grid>

        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="Cash Runway"
            currentValue={getCurrentValue(MetricCode.CashRunwayAccounting, Format.Month)}
            progressValue={getProgressValue(MetricCode.CashRunwayAccounting)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="bar"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.CashRunwayAccounting]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Month) as string}
          />
        </Grid>
      </Grid>

      <Grid {...gridContainerProps}>
        <Grid className={classes.chartGridItem} item {...chartGridProps}>
          <MetricChart
            chartName="Net Cash Burn % as a % of Gross Profit"
            currentValue={getCurrentValue(MetricCode.NetCashBurnOfGrossProfit, Format.Percent)}
            progressValue={getProgressValue(MetricCode.NetCashBurnOfGrossProfit, Format.Percent)}
            formatProgressValue={progressValue => formatProgressValue(progressValue)}
            type="line"
            valueKey="value"
            xAxisKey="date"
            data={chartMetrics[MetricCode.NetCashBurnOfGrossProfit]}
            formatValue={(value: Value) => getFormattedMetricValue(value, Format.Percent) as string}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
