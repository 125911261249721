import React from 'react';
import { Chip as MuiChip, Grid, GridProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme: Theme) => ({
  chip: ({ color }: { color: string }): CreateCSSProperties<{ color: string }> => {
    return {
      backgroundColor: color,
      color: '#fff',
    };
  },
}));

interface ChipProps {
  value: string;
  color: string;
}

export const Chip: React.FC<ChipProps> = ({ value, color }) => {
  const classes = useStyles({ color });

  return (
    <Grid item>
      <MuiChip className={classes.chip} size="small" label={value} />
    </Grid>
  );
};

interface ChipsProps extends GridProps {
  values: string[];
  colors: Record<string, string>;
}

export const Chips: React.FC<ChipsProps> = ({ values, colors, ...rest }) => {
  return (
    <Grid container spacing={1} {...rest}>
      {values.map(item => (
        <Chip key={item} value={item} color={colors[item]} />
      ))}
    </Grid>
  );
};
