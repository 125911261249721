import React from 'react';
import { Grid, makeStyles, Paper, Popover, TextField, Theme } from '@material-ui/core';

import { SubmitButton } from 'src/components';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '15px',
    width: 300,
  },
  textField: {
    margin: '5px 0 5px 0',
  },
  submitButton: {
    margin: '10px 0 0 0',
  },
}));

interface EditCustomMetricPopoverProps {
  open: boolean;
  anchorEl: Element | null | undefined;
  onUpsertCustomCovenantMetricValue: (value: string) => void;
  onDeleteCustomCovenantMetricValue: () => void;
  value: string;
  loading: boolean;
  onCloseEdit: () => void;
}

export const EditCustomMetricPopover: React.FC<EditCustomMetricPopoverProps> = ({
  open,
  anchorEl,
  onUpsertCustomCovenantMetricValue,
  onDeleteCustomCovenantMetricValue,
  onCloseEdit,
  value,
  loading,
}) => {
  const classes = useStyles();

  const [customCovenantValue, setCustomCovenantValue] = React.useState<string>(value);

  const onChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCustomCovenantValue(event.target.value);
  };

  const onSubmit = () => {
    onUpsertCustomCovenantMetricValue(customCovenantValue);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onCloseEdit}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper>
        <Grid className={classes.wrapper} container>
          <Grid item container>
            <TextField
              className={classes.textField}
              label="Custom Covenant"
              variant="outlined"
              value={customCovenantValue}
              onChange={onChangeValue}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item container justify="flex-end">
            <SubmitButton
              className={classes.submitButton}
              style={{ marginRight: 10 }}
              onClick={onDeleteCustomCovenantMetricValue}
              variant="contained"
              disabled={loading}
            >
              Reset
            </SubmitButton>
            <SubmitButton
              className={classes.submitButton}
              color="secondary"
              variant="contained"
              disabled={loading}
              onClick={onSubmit}
              loading={loading}
            >
              Submit
            </SubmitButton>
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  );
};
