import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { SubmitButton } from 'src/components';

interface HeaderProps {
  onExport?: () => void;
  isExporting?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  button: {
    padding: theme.spacing(1),

    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

export const Header: React.FC<HeaderProps> = ({ onExport, isExporting = false }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} item>
      <Typography variant="subtitle1">Covenant Tracking</Typography>

      <Grid item className={classes.right}>
        <Grid item className={classes.button}>
          <SubmitButton
            onClick={onExport}
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<icons.GetApp />}
            loading={isExporting}
          >
            Export
          </SubmitButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
