import React from 'react';
import { useSubscription, useMutation } from 'react-apollo';
import * as R from 'ramda';

import {
  AsyncTask,
  FINISHED_ASYNC_TASKS_SUBSCRIPTION_FOR_EXPORT,
  STATEMENTS_EXPORT_MUTATION,
  TableExportType,
} from 'src/graphql';
import { downloadXlsx, isFunction } from 'src/utils';
import { AsyncTaskStatus, Source } from 'src/types';
import { ASYNC_TASK_TYPES } from 'src/constants/asyncTask';
import { anyAsyncTaskFailed, anyAsyncTaskSucceded } from 'src/utils/asyncTasks';

export const useStatementsExport = (
  params: {
    companyId: string;
    period: (string | null)[];
    integrationType: Source.Xero | Source.QBO | null;
    isPlaidIntegrationConnected: boolean;
  },
  options: {
    onCompleted?: () => void;
    onError?: () => void;
  } = {},
): [() => void, { loading: boolean; error: boolean; disabled: boolean }] => {
  const { companyId } = params;
  // Use ref for statuses to avoid stale data in subscription callback
  const asyncTasksRef = React.useRef<Record<string, AsyncTask>>({});

  const [isExporting, setIsExporting] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const [statementsExport] = useMutation(STATEMENTS_EXPORT_MUTATION);

  useSubscription(FINISHED_ASYNC_TASKS_SUBSCRIPTION_FOR_EXPORT, {
    onSubscriptionData: async ({ subscriptionData }) => {
      const node = R.pathOr(
        null,
        ['data', 'AsyncTasks', 'node'],
        subscriptionData,
      ) as AsyncTask | null;

      if (node) {
        const nodeId = node.id as string;
        const nodeResult = node?.result;
        const nodeName = node?.name;

        if (nodeId in asyncTasksRef.current) {
          asyncTasksRef.current[nodeId].status = node.status;

          // If any task failed - skip other results and display error state
          const tasksFailed = anyAsyncTaskFailed(asyncTasksRef.current);

          if (tasksFailed) {
            if (options.onError && isFunction(options?.onError)) {
              await options.onError();
            }

            setIsExporting(false);
            setIsError(true);
          }

          const tasksFinished = anyAsyncTaskSucceded(asyncTasksRef.current);

          // If all tasks finished - we can run metrics calculation
          if (tasksFinished) {
            if (Boolean(nodeResult) && nodeName === ASYNC_TASK_TYPES.statementsExport) {
              const data = { tableExport: { base64data: node.result } };
              downloadXlsx(data, TableExportType.Statements);
              setIsExporting(false);
            }

            if (options.onCompleted && isFunction(options.onCompleted)) {
              await options.onCompleted();
            }

            setIsExporting(false);
          }
        }
      }
    },
    skip: isError || !companyId,
  });

  const onExport = React.useCallback(async () => {
    if (!isExporting && !R.isNil(params.integrationType)) {
      asyncTasksRef.current = {};

      setIsExporting(true);
      setIsError(false);

      try {
        const response = await statementsExport({
          variables: {
            event: {
              type: TableExportType.Statements,
              exportParams: params,
            },
          },
        });

        const id = R.pathOr('', ['data', 'tableExport', 'asyncTaskId'], response);

        asyncTasksRef.current[id] = {
          id,
          status: AsyncTaskStatus.Pending,
        };
      } catch (e) {
        if (options.onError && isFunction(options?.onError)) {
          await options.onError();
        }
        setIsError(true);
        console.error({ e });
      }
    }
  }, [isExporting, params, statementsExport, options]);

  return [
    onExport,
    {
      loading: isExporting,
      error: isError,
      disabled: isExporting,
    },
  ];
};
