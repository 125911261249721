import React from 'react';
import * as R from 'ramda';

import { RenderExpandIconProps } from 'rc-table/es/interface';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

export const ExpandIcon: React.FC<RenderExpandIconProps<any>> = ({
  record,
  expanded,
  onExpand,
}) => {
  const cellHasNoNestedRows = R.isEmpty(record?.children);

  if (cellHasNoNestedRows) return null;

  return (
    <div
      className="expand-row-icon"
      onClick={e => onExpand(record, e)}
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {expanded ? <ExpandLess /> : <ExpandMore />}
    </div>
  );
};
