import React, { useContext } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useAllowed } from 'src/app-builder';

import { Permission } from 'src/constants/permissions';
import { actionsContext } from './ActionsProvider';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
}));

export const ActionsRenderer = (row: Record<any, any>): React.ReactNode => {
  const actions = useContext(actionsContext);
  const isAllowed = useAllowed();
  const canDelete = isAllowed(Permission.PortfolioCompaniesDelete);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton disabled={!canDelete} onClick={actions.openActionsPopover(row.id)}>
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};
