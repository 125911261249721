import React from 'react';

import { SelectionColumnTitleRenderer } from 'src/shared/table/renderers/SelectionColumnTitleRenderer';
import { SelectionColumnRenderer } from 'src/shared/table/renderers/SelectionColumnRenderer';
import { SortTitleRenderer } from 'src/shared/table/renderers/SortTitleRenderer';
import { PrimaryContactRenderer } from 'src/shared/table/renderers/PrimaryContactRenderer';
import { ColumnProps } from 'rc-table/es/sugar/Column';
import { getFullName } from 'src/utils';
import { User } from 'src/graphql';
import { RolesRenderer } from 'src/shared/team/RolesRenderer';
import { StatusRenderer } from 'src/shared/team/StatusRenderer';
import { ActionsRenderer } from 'src/shared/team/getActionsRenderer';
import { CELL_TYPES, ColumnDataTypes } from 'src/types';

type ExportDataType = {
  title: string;
  type: ColumnDataTypes;
  key: string;
};

export interface TableColumnType extends ColumnProps<any> {
  exportData: ExportDataType;
}

export const getColumns = (
  order: string[],
  classes: Record<any, any>,
  canDelete: boolean,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  actionsContext: any,
): any[] => {
  const columns: Record<string, any> = {
    Name: {
      title: <SortTitleRenderer field="firstName">Name</SortTitleRenderer>,
      key: 'name',
      align: 'left',
      render: (user: User) => getFullName(user),
      width: '20%',
    },
    Title: {
      title: <SortTitleRenderer field="title">Title</SortTitleRenderer>,
      key: 'title',
      dataIndex: 'title',
      align: 'left',
    },
    Email: {
      title: <SortTitleRenderer field="email">Email</SortTitleRenderer>,
      align: 'left',
      dataIndex: 'email',
    },
    Status: {
      title: 'Status',
      align: 'left',
      render: StatusRenderer,
    },
    'Primary Contact': {
      title: <SortTitleRenderer field="company.owner.lastName">Primary contact</SortTitleRenderer>,
      exportData: { title: 'Primary Contact', type: CELL_TYPES.text, key: 'primaryContact' },
      render: PrimaryContactRenderer,
      align: 'left',
    },
    Roles: {
      title: 'Roles',
      key: 'roles',
      render: RolesRenderer,
      align: 'left',
    },
  };

  const displayColumns: any = canDelete
    ? [
        {
          title: <SelectionColumnTitleRenderer />,
          key: 'select',
          width: 42,
          render: SelectionColumnRenderer,
          className: classes.selectionColumn,
          align: 'center',
        },
      ]
    : [];

  order.forEach(key => {
    displayColumns.push(columns[key]);
  });

  const renderActions = (row: any) => <ActionsRenderer row={row} context={actionsContext} />;

  displayColumns.push({
    align: 'center',
    key: 'actions',
    title: 'Actions',
    render: renderActions,
    width: 88,
  });

  return displayColumns;
};
