import * as yup from 'yup';
import { EventType } from 'src/graphql';
import { EmailRequiredSchema } from 'src/utils/schemas/common';

export const ApplicantInviteSchema = yup
  .object({
    type: yup
      .string()
      .oneOf([EventType.ApplicantInvitationSend])
      .required(),
    payload: yup
      .object({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: EmailRequiredSchema,
      })
      .required(),
  })
  .required();
