import React, { ChangeEvent } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid } from '@material-ui/core';

import { SAASSCORE_PERIODS } from 'src/constants';

interface FiltersProps {
  selectedPeriod: number;
  onChangeSelectedPeriod: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode,
  ) => void;
}

export const Filters: React.FC<FiltersProps> = ({ selectedPeriod, onChangeSelectedPeriod }) => {
  return (
    <React.Fragment>
      <Grid item xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Selected Period</InputLabel>
          <Select
            label="Selected Period"
            value={selectedPeriod}
            onChange={onChangeSelectedPeriod}
            fullWidth
          >
            {SAASSCORE_PERIODS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};
