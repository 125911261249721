import { gql } from '8base-react-sdk';

export const QUICKBOOKS_GET_AUTH_URL_MUTATION = gql`
  mutation QuickBooksGetAuthUrl($companyId: ID!, $redirectUrl: String) {
    quickBooksGetAuthUrl(companyId: $companyId, redirectUrl: $redirectUrl) {
      url
    }
  }
`;

export const QUICKBOOKS_DELETE_TOKEN_MUTATION = gql`
  mutation QuickBooksDeleteToken($quickBooksIntegrationId: ID!) {
    quickBooksDeleteToken(quickBooksIntegrationId: $quickBooksIntegrationId) {
      success
    }
  }
`;

export const QUICKBOOKS_REQUEST_METRICS_UPDATE_MUTATION = gql`
  mutation QuickBooksRequestMetricsUpdate($companyId: ID!) {
    quickBooksRequestMetricsUpdate(companyId: $companyId) {
      asyncTaskId
    }
  }
`;
