import React from 'react';
import * as R from 'ramda';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  DialogContentText,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { createTableRowDeleteTag, TableSchema } from '@8base/utils';
import { gql } from '8base-react-sdk';
import * as ChangeCase from 'change-case';
import { useSnackbar } from 'notistack';

import { useModal } from '../providers';
import { DIALOGS } from './constants';
import { isFunction } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface TableRowDeleteDialogProps {
  table: TableSchema;
}

export const TableRowDeleteDialog: React.FC<TableRowDeleteDialogProps> = ({ table }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { open, args, closeModal } = useModal(DIALOGS.TABLE_ROW_DELETE_DIALOG);

  const deleteMutation = React.useMemo(
    () => gql`
      ${createTableRowDeleteTag([table], table.id)}
    `,
    [table],
  );

  const [deleteRow, { loading }] = useMutation(deleteMutation);

  const onDelete = React.useCallback(async () => {
    if (Array.isArray(args?.id)) {
      await Promise.all(
        args?.id.slice(1).map((id: string) =>
          deleteRow({
            variables: { filter: { id }, force: true },
          }),
        ),
      );

      await deleteRow({
        variables: { filter: { id: args?.id[0] }, force: true },
        refetchQueries: [R.toUpper(R.head(table.name)) + R.tail(table.name) + 'TableContent'],
        awaitRefetchQueries: true,
      });
    } else {
      await deleteRow({
        variables: { filter: { id: args?.id }, force: true },
        refetchQueries: [R.toUpper(R.head(table.name)) + R.tail(table.name) + 'TableContent'],
        awaitRefetchQueries: true,
      });
    }

    enqueueSnackbar(
      `${pluralize(
        table.displayName,
        Array.isArray(args?.id) ? args?.id?.length : 1,
      )} successfully deleted`,
      { variant: 'success' },
    );

    if (isFunction(args?.onSuccess)) {
      args?.onSuccess();
    }

    closeModal();
  }, [args, enqueueSnackbar, table, closeModal, deleteRow]);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  const dialogName = ChangeCase.camelCase('delete ' + pluralize(table.displayName, 1));

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.root }}
      maxWidth="md"
      data-e2e-id={`dialogs.${dialogName}`}
    >
      <DialogTitle>
        Delete {pluralize(table.displayName, Array.isArray(args?.id) ? args?.id?.length : 1)}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure want to delete this{' '}
          {pluralize(
            table.displayName,
            Array.isArray(args?.id) ? args?.id?.length : 1,
          ).toLowerCase()}
          ?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose} data-e2e-id={`dialogs.${dialogName}.cancelBtn`}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onDelete}
          disabled={loading}
          data-e2e-id={`dialogs.${dialogName}.submitBtn`}
        >
          Delete {pluralize(table.displayName, Array.isArray(args?.id) ? args?.id?.length : 1)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
