import React from 'react';

import {
  SelectedRowsProvider,
  SelectedColumnsProvider,
  SortProvider,
  SearchProvider,
  PaginationProvider,
} from 'src/providers';
import { Main, ActionsProvider } from './components';
import { PortfolioCompanyCreateDialog, PortfolioCompanyDeleteDialog } from 'src/dialogs';
import { ALL_PORTFOLIO_COMPANIES_COLUMN_KEYS } from 'src/constants';

export const PortfolioCompaniesTablePage: React.FC = () => {
  return (
    <SelectedRowsProvider>
      <SearchProvider>
        <SortProvider>
          <PaginationProvider>
            <SelectedColumnsProvider
              allKeys={ALL_PORTFOLIO_COMPANIES_COLUMN_KEYS}
              initialOrder={ALL_PORTFOLIO_COMPANIES_COLUMN_KEYS}
            >
              <ActionsProvider>
                <Main />
                <PortfolioCompanyDeleteDialog />
                <PortfolioCompanyCreateDialog />
              </ActionsProvider>
            </SelectedColumnsProvider>
          </PaginationProvider>
        </SortProvider>
      </SearchProvider>
    </SelectedRowsProvider>
  );
};
