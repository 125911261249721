import React from 'react';
import * as R from 'ramda';
import { Filters } from './index';
import { Report } from 'src/types';
import { REPORT_METRIC_TYPES } from 'src/constants';

interface FiltersContainerProps {
  companyId: string;
  activeReport: Report | undefined;
  onChangeActiveReport: (report: Report, from: string | null, till: string | null) => void;
  from: string | null;
  till: string | null;
}

export const FiltersContainer: React.FC<FiltersContainerProps> = ({
  companyId,
  activeReport,
  from,
  till,
  onChangeActiveReport,
}) => {
  const onSubmit = async (values: any) => {
    const { selectedReport, period } = values;
    const activeReport = R.find(
      (report: Report) => R.equals(report.label, selectedReport),
      REPORT_METRIC_TYPES,
    );
    if (!R.isNil(activeReport)) {
      onChangeActiveReport(activeReport, period[0], period[1]);
    }
  };

  const initialValues = React.useMemo(
    () => ({
      selectedReport: R.pathOr(null, ['label'], activeReport),
      period: [from, till],
    }),
    [activeReport, from, till],
  );

  return <Filters companyId={companyId} initialValues={initialValues} onSubmit={onSubmit} />;
};
