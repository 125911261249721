import React, { useCallback, useContext, useMemo } from 'react';
import { FormState } from 'final-form';
import { Grid, Typography, FormHelperText, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useRouterQuery } from 'src/hooks';
import {
  NumberField,
  SelectField,
  TextField,
  Field,
  Form,
  FormSpy,
  ModalsContext,
} from 'src/app-builder';

import { SubmitButton } from 'src/components';
import { baseNumberFormat, getWorkspaceInfo, validateDataByYup } from 'src/utils';
import { trimString } from 'src/utils/parsers';
import { UserSelfSignUpSchema } from 'src/utils/schemas';

import { BusinessLocationCheckDialog, DIALOGS } from 'src/dialogs';

import {
  Country,
  BUSINESS_LOCATION_OPTIONS,
  LOGIN_CONNECTION,
  CAPITAL_TYPES_OPTIONS,
  PRIMARY_BUSINESS_MODEL_OPTIONS,
  PRIMARY_CUSTOMER_BASE_OPTIONS,
} from 'src/constants';

const { REACT_APP_ENDPOINT = '' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: theme.spacing(80),
  },
}));

type SelfSignUpProps = {
  onSubmit: (
    data: any,
  ) => Promise<
    | {
        [key: string]: string;
      }
    | undefined
  >;
};

export const SelfSignUpForm: React.FC<SelfSignUpProps> = ({ onSubmit }) => {
  const { firstName, lastName, email } = useRouterQuery();
  const { env } = useMemo(() => {
    return getWorkspaceInfo(REACT_APP_ENDPOINT);
  }, []);

  const initialValues = useMemo(
    () => ({
      connection: LOGIN_CONNECTION,
      email,
      user_metadata: {
        workspace_env: env,
        first_name: firstName,
        last_name: lastName,
      },
    }),
    [env, email, firstName, lastName],
  );
  const { openModal } = useContext(ModalsContext);

  const classes = useStyles();

  const openBusinessLocationCheckDialog = useCallback(() => {
    openModal(DIALOGS.BUSINESS_LOCATION_CHECK_DIALOG);
  }, [openModal]);

  const checkBusinessLocation = (
    { values }: FormState<Record<string, any>, Partial<Record<string, any>>>,
    form: any,
  ) => {
    const businessLocation = values?.user_metadata?.business_location;
    if (!!businessLocation && businessLocation !== Country.USA) {
      openBusinessLocationCheckDialog();
      form.change('user_metadata.business_location', undefined);
    }
  };

  return (
    <Form
      subscription={{ submitting: true, pristine: true }}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => validateDataByYup(UserSelfSignUpSchema, values)}
    >
      {({ handleSubmit, submitError, submitting, form }) => (
        <Grid
          className={classes.form}
          component="form"
          onSubmit={handleSubmit}
          container
          item
          spacing={2}
        >
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.company_name"
              label="Company Name"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.business_location"
              label="Business Location"
              component={SelectField}
              options={BUSINESS_LOCATION_OPTIONS}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.primary_business_model"
              label="Primary Business Model"
              component={SelectField}
              options={PRIMARY_BUSINESS_MODEL_OPTIONS}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.primary_customer_base"
              label="Primary Customer Base"
              component={SelectField}
              options={PRIMARY_CUSTOMER_BASE_OPTIONS}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.amount_of_capital_desired"
              label="Amount of Capital Desired"
              component={NumberField}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              numberFormat={baseNumberFormat}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.type_of_desired_capital"
              label="Type of Capital Desired"
              component={SelectField}
              options={CAPITAL_TYPES_OPTIONS}
              multiple
            />
          </Grid>

          <Grid xs={12} item>
            <Typography variant="subtitle1">Login Information</Typography>
          </Grid>

          <Grid xs={6} item>
            <Field
              fullWidth
              name="email"
              label="Business Email"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="password"
              label="Password"
              type="password"
              component={TextField}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.first_name"
              label="First Name"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.last_name"
              label="Last Name"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
            />
          </Grid>

          {submitError && (
            <Grid xs={12} item>
              <FormHelperText error>{submitError}</FormHelperText>
            </Grid>
          )}
          <Grid xs={12} item>
            <SubmitButton
              fullWidth
              size="large"
              type="submit"
              color="secondary"
              variant="contained"
              loading={submitting}
            >
              Create Account
            </SubmitButton>
          </Grid>
          <FormSpy
            subscription={{ values: true }}
            onChange={values => checkBusinessLocation(values, form)}
          />
          <BusinessLocationCheckDialog />
        </Grid>
      )}
    </Form>
  );
};
