import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';

interface ItemProps {
  label: string;
  index: number;
  moveCard: (oldIndex: number, newIndex: number) => void;
  selected: boolean;
  toggle: (idx: number) => void;
  commitMoveChange: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 16px',
    paddingRight: 40,
    position: 'relative',
  },
  anchor: {
    cursor: 'grab',
    display: 'flex',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

export const Item: React.FC<ItemProps> = ({
  label,
  index,
  moveCard,
  selected,
  toggle,
  commitMoveChange,
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const classes = useStyles();
  const [, drop] = useDrop<any, any, any>({
    accept: 'COLUMN',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset?.y || 0) - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop() {
      commitMoveChange();
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'COLUMN', id: label, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <li style={{ opacity: isDragging ? 0 : 1 }} className={classes.root} key={label} ref={ref}>
      <label className={classes.label}>
        <ListItemIcon>
          <Checkbox onChange={() => toggle(index)} checked={selected} color="primary" />
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>
        <ListItemSecondaryAction
          style={{ opacity: isDragging ? 0 : 1 }}
          className={classes.anchor}
          ref={drag}
        >
          <icons.DragIndicator />
        </ListItemSecondaryAction>
      </label>
    </li>
  );
};
