import * as React from 'react';

import { useQueryParams } from './useQueryParams';

export const PER_PAGE_OPTIONS = [10, 25, 50, 100];

interface PropsInitial {
  initialCount?: number;
  initialPerPage?: number;
}

const defaultValues = {
  initialCount: 0,
  initialPerPage: 10,
};

export const usePagination = ({
  initialCount = defaultValues.initialCount,
  initialPerPage = defaultValues.initialPerPage,
}: PropsInitial = defaultValues): {
  count: number;
  setCount: (count: number) => void;
  perPage: number;
  page: number;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  perPageOptions: typeof PER_PAGE_OPTIONS;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  handlePerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
} => {
  const [{ perPage, page }, { setQueryParams }] = useQueryParams<{ perPage: string; page: string }>(
    {
      initialValues: { perPage: initialPerPage.toString(), page: '0' },
    },
  );

  const [count, setCount] = React.useState(initialCount);

  const setPerPage = React.useCallback(
    (perPage: number) => {
      if (perPage && count !== undefined && Number.parseInt(page) * Number(perPage) > count) {
        const remainder: number = count / Number(perPage);
        const maxCountPages: number = Math.floor(remainder);

        setQueryParams({
          perPage: perPage ? String(perPage) : undefined,
          page: String(maxCountPages),
        });
      } else {
        setQueryParams({ perPage: perPage ? String(perPage) : undefined });
      }
    },
    [count, page, setQueryParams],
  );

  const setPage = React.useCallback(
    (page: number) => {
      setQueryParams({ page: page || page === 0 ? String(page) : undefined });
    },
    [setQueryParams],
  );

  const handlePageChange = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page) => {
      setPage(page);
    },
    [setPage],
  );

  const handlePerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPerPage(Number.parseInt(event.target.value));
    },
    [setPerPage],
  );

  return {
    count,
    setCount,
    perPage: Number.parseInt(perPage),
    page: Number.parseInt(page) || 0,
    setPerPage,
    setPage,
    perPageOptions: PER_PAGE_OPTIONS,
    handlePageChange,
    handlePerPageChange,
  };
};
