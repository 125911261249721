import React from 'react';
import { useParams } from 'react-router-dom';
import { useAllowed, usePopover } from 'src/app-builder';
import { Typography, Paper, Grid, Theme, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import * as icons from '@material-ui/icons';
import { usePortfolioCompany, useCompanyHeaderMetrics } from 'src/hooks';
import { MonitoringStatusesPopover } from 'src/shared';
import { Chips, Tier, MetricValueText } from 'src/components';
import { Permission, MONITORING_STATUS_COLORS } from 'src/constants';
import { MetricCode } from 'src/types';
import {
  DateFormatPatterns,
  formatDate,
  formatMoney,
  formatMonths,
  formatSaaSScore,
  getFullName,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  header: {
    height: 80,
    flexWrap: 'nowrap',
  },
  name: {
    display: 'grid',
    minWidth: 300,
    maxWidth: 350,
    alignItems: 'center',
    gridTemplate: '"name name" "owner title"',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    flexBasis: '25%',
  },
  metrics: {
    height: '100%',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridGap: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  metric: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  score: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',

    display: 'grid',
    alignItems: 'center',
    gridTemplate: '"score tier" "status tier"',
  },
  scoreValue: {
    color: theme.palette.secondary.main,
  },
  tier: {
    gridArea: 'tier',
    paddingLeft: theme.spacing(2),
  },
}));

interface PortfolioCompanyHeaderProps {}

export const PortfolioCompanyHeader: React.FC<PortfolioCompanyHeaderProps> = () => {
  const classes = useStyles();

  const { id: portfolioCompanyId }: { id: string } = useParams();

  const isAllowed = useAllowed();

  const canChangeMonitoringStatus = isAllowed(Permission.PortfolioCompaniesChangeMonitoringStatus);

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const companyId = portfolioCompany?.company?.id as string;
  const {
    metricsData: companyHeaderMetrics,
    facilityData,
    loading: metricsLoading,
  } = useCompanyHeaderMetrics(companyId);

  const monitoringStatusPopover = usePopover();

  const companyName = portfolioCompany?.company?.name;
  const contactTitle = portfolioCompany?.company?.contactTitle;
  const contactFirstName = portfolioCompany?.company?.contactFirstName;
  const contactLastName = portfolioCompany?.company?.contactLastName;

  const { fundingDate, amountCommitted, totalAmountDeployed } = facilityData;

  const { value: saasScore, tier: saasScoreTier } = companyHeaderMetrics[MetricCode.SaaSScore];
  const { value: endingMRR } = companyHeaderMetrics[MetricCode.EndingMRR];
  const { value: netCashBurn } = companyHeaderMetrics[MetricCode.AdjustedNetCashBurn];
  const { value: cashBalance } = companyHeaderMetrics[MetricCode.CashPosition];
  const { value: cashRunway } = companyHeaderMetrics[MetricCode.CashRunwayAccounting];
  const monitoringStatus = (portfolioCompany?.monitoringStatus || []) as string[];

  const onStagesClick = canChangeMonitoringStatus ? monitoringStatusPopover.openPopover : undefined;

  if (portfolioCompanyLoading || metricsLoading) {
    return <Skeleton width="100%" style={{ height: 100 }} />;
  }

  return (
    <Paper className={classes.paper}>
      <Grid className={classes.header} container alignItems="center" justify="space-between">
        <Grid item className={classes.name}>
          <Typography variant="h5" noWrap style={{ gridArea: 'name' }}>
            {companyName}
          </Typography>
          <Typography variant="subtitle1">
            {getFullName({ firstName: contactFirstName, lastName: contactLastName })}
          </Typography>
          <Typography variant="button" color="textSecondary" display="inline">
            {contactTitle}
          </Typography>
        </Grid>
        <Grid item container className={classes.metrics}>
          <Grid item className={classes.metric}>
            <Typography variant="caption">Funded Date</Typography>
            <Typography variant="subtitle1">
              {formatDate(fundingDate, DateFormatPatterns.fullDateStartsFromMonth) || '-'}
            </Typography>
          </Grid>
          <Grid item className={classes.metric}>
            <Typography variant="caption">$ Committed</Typography>
            <MetricValueText value={amountCommitted} formatter={formatMoney} />
          </Grid>
          <Grid item className={classes.metric}>
            <Typography variant="caption">Total $ Deployed</Typography>
            <MetricValueText value={totalAmountDeployed} formatter={formatMoney} />
          </Grid>
          <Grid item className={classes.metric}>
            <Typography variant="caption">MRR</Typography>
            <MetricValueText value={endingMRR} formatter={formatMoney} />
          </Grid>
          <Grid item className={classes.metric}>
            <Typography variant="caption">Net Cash Burn</Typography>
            <MetricValueText value={netCashBurn} formatter={formatMoney} />
          </Grid>
          <Grid item className={classes.metric}>
            <Typography variant="caption">Cash Balance</Typography>
            <MetricValueText value={cashBalance} formatter={formatMoney} />
          </Grid>
          <Grid item className={classes.metric}>
            <Typography variant="caption">Cash Runway</Typography>
            <MetricValueText value={cashRunway} formatter={formatMonths} />
          </Grid>
        </Grid>
        <Grid item className={classes.score}>
          <Typography variant="h5">
            SaaSScore: <span className={classes.scoreValue}>{formatSaaSScore(saasScore)}/100</span>
          </Typography>
          <div style={{ gridArea: 'status' }}>
            <Box
              display="inline-flex"
              flexDirection="row"
              alignItems="center"
              onClick={onStagesClick}
              style={{ cursor: 'pointer' }}
            >
              <Chips values={monitoringStatus} colors={MONITORING_STATUS_COLORS} wrap="nowrap" />
              {canChangeMonitoringStatus && <icons.KeyboardArrowDown fontSize="small" />}
            </Box>

            <MonitoringStatusesPopover
              anchorEl={monitoringStatusPopover.el}
              open={Boolean(monitoringStatusPopover.el)}
              onClose={monitoringStatusPopover.closePopover}
              portfolioCompanyId={portfolioCompanyId}
              statuses={monitoringStatus}
            />
          </div>
          <div className={classes.tier}>
            <Tier value={saasScoreTier || '-'} />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
