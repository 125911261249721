import React from 'react';
import { Grid, Link, Typography, FormHelperText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom';

import { TextField, useAuth, Field, Form, FORM_ERROR } from 'src/app-builder';
import { SubmitButton } from 'src/components';
import { useNotification } from 'src/hooks';
import { LOGIN_CONNECTION, AuthRoutes } from 'src/constants';
import { required, email, composeValidators } from 'src/utils/validators';
import { delay } from 'src/utils';

import bfc from 'src/assets/logos/bfc.png';
import background from 'src/assets/bg.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    background: theme.palette.secondary.dark,
  },
  wrapper: {
    background: theme.palette.common.white,
    margin: 0,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  form: {
    maxWidth: theme.spacing(80),
    marginTop: 45,
  },
  logo: {
    width: theme.spacing(22),
    marginBottom: 45,
  },
  backLogin: {
    marginTop: 15,
  },
}));

export const ChangePasswordPage: React.FC = () => {
  const { authClient, isAuthorized } = useAuth();

  const classes = useStyles();
  const notification = useNotification();
  const history = useHistory();

  const changePassword = React.useCallback(
    data =>
      new Promise<string>((resolve, reject) => {
        authClient.auth0.changePassword(data, (err: any, message: string) => {
          if (err) {
            return reject(err);
          }

          resolve(message);
        });
      }),
    [authClient],
  );

  const onSubmit = React.useCallback(
    async data => {
      try {
        const successMessage = await changePassword({
          connection: LOGIN_CONNECTION,
          email: data.email,
        });

        notification.success(successMessage);
        await delay(2000);
        history.push(AuthRoutes.Login);
      } catch (e) {
        return { [FORM_ERROR]: e?.description || 'Something went wrong during change password' };
      }
    },
    [changePassword, notification, history],
  );

  // if the user already logged in we redirect them to the root route
  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <Grid className={classes.root} container>
      <Grid
        className={classes.wrapper}
        xs={6}
        item
        container
        alignItems="center"
        justify="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <img className={classes.logo} src={bfc} alt="" />
        </Grid>
        <Grid item>
          <Typography variant="h3">Forgot your password?</Typography>
        </Grid>

        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitError, submitting }) => (
            <Grid
              className={classes.form}
              component="form"
              onSubmit={handleSubmit}
              container
              item
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Field
                  fullWidth
                  name="email"
                  label="Business email"
                  component={TextField}
                  validate={composeValidators([required, email])}
                />
              </Grid>

              {submitError && (
                <Grid item>
                  <FormHelperText error>{submitError}</FormHelperText>
                </Grid>
              )}
              <Grid item>
                <SubmitButton
                  fullWidth
                  size="large"
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={submitting}
                >
                  Reset Password
                </SubmitButton>
              </Grid>
            </Grid>
          )}
        </Form>
        <Grid item>
          <Typography className={classes.backLogin}>
            <Link color="secondary" component={RouterLink} to={AuthRoutes.Login}>
              Back to Login
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={6}
        item
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
      />
    </Grid>
  );
};
