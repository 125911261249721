import React from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import { Grid, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AllInbox, Send } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),

    '&:first-child': {
      paddingTop: theme.spacing(3),
    },
  },
  button: {
    borderRadius: 8,
    textTransform: 'none',
    fontSize: 16,
    border: 0,
    marginRight: 5,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  activeButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

interface SwitchesProps {
  path: string;
}

export const Switches: React.FC<SwitchesProps> = ({ path }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Button
        component={RouterLink}
        to={`${path}/received`}
        className={classes.button}
        activeClassName={classes.activeButton}
        startIcon={<AllInbox />}
      >
        Received
      </Button>
      <Button
        component={RouterLink}
        to={`${path}/sent`}
        className={classes.button}
        activeClassName={classes.activeButton}
        startIcon={<Send />}
      >
        Sent
      </Button>
    </Grid>
  );
};
