import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  Button,
  FormLabel,
  Grid,
  Typography,
  Theme,
  Box,
} from '@material-ui/core';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';
import { CloudUpload, Clear } from '@material-ui/icons';
import { FileInput } from '8base-react-sdk';

interface ImageFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  label?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  preview: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  previewImage: {
    maxWidth: theme.spacing(32),
    maxHeight: theme.spacing(32),
  },
  previewClear: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.common.white,
  },
}));

export const ImageField: React.FC<ImageFieldProps> = ({
  input,
  meta,
  fullWidth,
  label,
  disabled,
}) => {
  const classes = useStyles();

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  const onClear = React.useCallback(() => {
    input.onChange(null);
  }, [input]);

  return (
    <FormControl variant="outlined" error={error} fullWidth={fullWidth}>
      {label && <FormLabel className={classes.label}>{label}</FormLabel>}
      {input.value && (
        <Box className={classes.preview}>
          <img className={classes.previewImage} src={input.value?.downloadUrl} alt="" />
          <Clear className={classes.previewClear} onClick={onClear} />
        </Box>
      )}
      <FileInput onChange={input.onChange} value={input.value}>
        {({ pick, value }): React.ReactNode => (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                color="default"
                startIcon={<CloudUpload />}
                onClick={(): void => {
                  pick({});
                }}
                disabled={disabled}
              >
                Upload file
              </Button>
            </Grid>
            <Grid item>
              <Typography>
                {value
                  ? Array.isArray(value)
                    ? `${value.length} images selected`
                    : value.filename
                  : 'No image selected'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </FileInput>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
