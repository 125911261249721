import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';

import { Layout } from 'src/shared/team/Layout';
import { TeamTable } from 'src/shared/team/Table';
import {
  PORTFOLIO_COMPANIES_QUERY,
  PortfolioCompany,
  USERS_TABLE_QUERY,
  UsersTableContentQuery,
} from 'src/graphql';
import { getUserTableQueryVariables } from 'src/shared/team/getUserTableQueryVariables';
import { toSortVariables } from 'src/providers/SortProvider';
import { selectedColumnsContext } from 'src/providers/SelectedColumnsProvider';
import { ToolbarContainer } from 'src/shared/table/ToolbarContainer';
import { PaginationContainer } from 'src/shared/table/PaginationContainer';
import { ActionsProvider } from 'src/shared/team/ActionsProvider';
import { actionsContext } from 'src/shared/team/ActionsProvider';
import { usePagination, useSearch, useSort } from 'src/hooks';
import { selectedRowsContext } from 'src/providers/SelectedRowsProvider';
import { useAllowed } from 'src/app-builder/providers';
import EmptyScreenContainer from 'src/shared/team/EmptyScreenContainer';
import HeadContainer from 'src/shared/team/HeadContainer';
import { DIALOGS } from 'src/dialogs';
import { Permission } from 'src/constants/permissions';

export const Main: React.FC = () => {
  const params: { id: string } = useParams();
  const isAllowed = useAllowed();

  const pagination = usePagination();
  const search = useSearch();
  const sort = useSort();
  const selectedColumns = useContext(selectedColumnsContext);
  const { initRows } = useContext(selectedRowsContext);

  const { data: portfolioCompanyData, loading: portfolioCompanyLoading } = useQuery<{
    portfolioCompany: PortfolioCompany;
  }>(PORTFOLIO_COMPANIES_QUERY, {
    variables: {
      id: params?.id,
    },
    skip: !params.id,
  });

  const [canInvite, canDelete, canEdit, canResendInvite, canCancelInvite] = [
    isAllowed(Permission.TeamInvite),
    isAllowed(Permission.TeamDelete),
    isAllowed(Permission.TeammateEdit),
    isAllowed(Permission.TeamResendInvite),
    isAllowed(Permission.TeamCancelInvite),
  ];

  const companyId = portfolioCompanyData?.portfolioCompany?.company?.id;
  const doesCompanyHaveOwner = Boolean(portfolioCompanyData?.portfolioCompany?.company?.owner);

  const variables = getUserTableQueryVariables({
    sort:
      sort?.field && sort?.order
        ? toSortVariables([{ field: sort.field, order: sort?.order }])
        : null,
    companyId,
    perPage: pagination.perPage,
    page: pagination.page,
    search: search?.search,
  });

  const { data, loading } = useQuery<UsersTableContentQuery>(USERS_TABLE_QUERY, {
    variables,
  });

  const count = data?.tableData?.count;

  const rows = useMemo(() => data?.tableData?.items || [], [data]);

  useEffect(() => {
    initRows(rows, 'id');
  }, [initRows, rows]);

  return (
    <ActionsProvider
      rows={rows}
      companyId={companyId}
      permissions={{ canEdit, canDelete, canResendInvite, canCancelInvite, canInvite }}
      doesCompanyHaveOwner={doesCompanyHaveOwner}
    >
      <Layout
        isEmpty={!rows.length && !loading}
        loading={loading || portfolioCompanyLoading}
        toolbar={<ToolbarContainer dialog={DIALOGS.USER_DELETE_DIALOG} canDelete={canDelete} />}
        empty={<EmptyScreenContainer />}
        table={
          <TeamTable
            columnKeys={selectedColumns.keys}
            data={rows}
            actionsContext={actionsContext}
          />
        }
        head={<HeadContainer label="Team" />}
        pagination={<PaginationContainer count={count || 0} />}
      />
    </ActionsProvider>
  );
};
