import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, LinearProgress, Typography, Theme, InputAdornment } from '@material-ui/core';

import {
  Form,
  Field,
  TextField,
  NumberField,
  SelectField,
  FormSpy,
  useDataLoss,
  useAllowed,
} from 'src/app-builder';
import { SubmitButton } from 'src/components';
import { useNotification } from 'src/hooks';
import { PORTFOLIO_COMPANIES_QUERY, PORTFOLIO_COMPANIES_EDIT_MUTATION } from 'src/graphql';
import { baseNumberFormat, yearFormat, t, companyStoryLabel } from 'src/utils';
import {
  Permission,
  COUNTRIES_OPTIONS,
  STATE_OPTIONS_BY_COUNTRY,
  CAPITAL_SPEND_ON_OPTIONS,
  CAPITAL_TYPES_OPTIONS,
  FROM_WHOM_CAPITAL_RAISED_OPTIONS,
  FROM_WHOM_BORROWED,
  PRIMARY_BUSINESS_MODEL_OPTIONS,
  PRIMARY_CUSTOMER_BASE_OPTIONS,
} from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'auto',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(2),
    overflow: 'auto',
    flex: 1,
  },
}));

export const PortfolioCompanySaaSStoryPage: React.FC<any> = () => {
  const classes = useStyles();

  const params: { id: string } = useParams();

  const { setWhen } = useDataLoss();

  const notification = useNotification();

  const isAllowed = useAllowed();

  const canEditInfo = isAllowed(Permission.PortfolioCompaniesEditInfo);

  const { data, loading } = useQuery(PORTFOLIO_COMPANIES_QUERY, {
    variables: {
      id: params?.id,
    },
    skip: !params.id,
  });

  const initialValues = React.useMemo(() => {
    if (loading) {
      return {};
    }

    // we don't need the owner in the mutation data
    const { owner, ...rest } = data?.portfolioCompany?.company || {};
    return rest;
  }, [data, loading]);

  const [updateCompany] = useMutation(PORTFOLIO_COMPANIES_EDIT_MUTATION, {
    onCompleted: () => notification.success(t('company_update_success')),
    onError: () => notification.error(t('company_update_error')),
  });

  const onSubmit = React.useCallback(
    async ({ id, ...data }, form) => {
      const dataToUpdate = R.omit(['owner', 'facilities', 'team'], data);
      await updateCompany({
        variables: { data: { id: params.id, company: { update: { ...dataToUpdate } } } },
      });

      setTimeout(form.reset);
    },
    [updateCompany, params],
  );

  const onCancel = React.useCallback(form => {
    setTimeout(form.reset);
  }, []);

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid className={classes.header} item alignItems="center">
        <Typography variant="subtitle1">SaaSStory</Typography>
      </Grid>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column">
          <Form
            subscription={{ submitting: true, pristine: true }}
            tableSchemaName="Companies"
            type="UPDATE"
            onSubmit={onSubmit}
            initialValues={initialValues as any}
            formatRelationToIds
          >
            {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="subtitle1">I. Company Information</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="name"
                        label="Company Name"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="foundedYear"
                        label="Year Founded"
                        component={NumberField}
                        fullWidth
                        numberFormat={yearFormat}
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="primaryBusinessModel"
                        label="Primary Business Model"
                        component={SelectField}
                        options={PRIMARY_BUSINESS_MODEL_OPTIONS}
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="primaryCustomerBase"
                        label="Primary Customer Base"
                        component={SelectField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                        options={PRIMARY_CUSTOMER_BASE_OPTIONS}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={3}>
                      <Field
                        name="address.street1"
                        label="Street Address"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="address.city"
                        label="City"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="address.zip"
                        label="Zip Code"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="address.country"
                        label="Country"
                        component={SelectField}
                        options={COUNTRIES_OPTIONS}
                        fullWidth
                      />
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <FormSpy subscription={{ values: true }}>
                          {({ values }: any) => (
                            <Field
                              name="address.state"
                              label="State / Province"
                              component={SelectField}
                              options={R.propOr(
                                [],
                                values?.address?.country,
                                STATE_OPTIONS_BY_COUNTRY,
                              )}
                              fullWidth
                              disabled={
                                submitting || !canEditInfo || !Boolean(values?.address?.country)
                              }
                            />
                          )}
                        </FormSpy>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="contactFirstName"
                        label="Contact First Name"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="contactLastName"
                        label="Contact Last Name"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="contactTitle"
                        label="Contact Title"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="contactEmail"
                        label="Contact Email"
                        component={TextField}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">II. Company and Founder(s) Story</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="founderStory"
                        label="What is the Founder(s) story?"
                        component={TextField}
                        fullWidth
                        multiline
                        rows={8}
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }: any) => (
                          <Field
                            name="companyStory"
                            label={companyStoryLabel(values.name)}
                            component={TextField}
                            fullWidth
                            multiline
                            rows={8}
                            disabled={submitting || !canEditInfo}
                          />
                        )}
                      </FormSpy>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">III. Product/Market</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="productDescription"
                        label="Product Description"
                        component={TextField}
                        fullWidth
                        multiline
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="marketDescription"
                        label="Market Description"
                        component={TextField}
                        fullWidth
                        multiline
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="customersStrategy"
                        label="How do you get customers?"
                        component={TextField}
                        fullWidth
                        multiline
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item style={{ flex: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">IV. Capital</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="capital"
                        label="How much capital are you seeking?"
                        component={NumberField}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        numberFormat={baseNumberFormat}
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item>
                        <Field
                          name="capitalType"
                          label="What type of capital are you seeking?"
                          component={SelectField}
                          options={CAPITAL_TYPES_OPTIONS}
                          fullWidth
                          multiple
                          disabled={submitting || !canEditInfo}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item>
                        <Field
                          name="capitalStrategy"
                          label="What are you planning to spend it on?"
                          component={SelectField}
                          options={CAPITAL_SPEND_ON_OPTIONS}
                          fullWidth
                          multiple
                          disabled={submitting || !canEditInfo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="equityCapitalAmount"
                        label="How much equity (including convertible debt) have you raised?"
                        component={NumberField}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        numberFormat={baseNumberFormat}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="equityCapitalOwner"
                        label="From whom?"
                        component={SelectField}
                        options={FROM_WHOM_CAPITAL_RAISED_OPTIONS}
                        multiple
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="monthsToNextRound"
                        label="How many months to your next equity round (ideally)?"
                        component={NumberField}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                        }}
                        numberFormat={baseNumberFormat}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item>
                        <Field
                          name="idealRaiseAmount"
                          label="Ideal raise amount"
                          component={NumberField}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                          disabled={submitting || !canEditInfo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="borrowedDebt"
                        label="How much have you borrowed (excluding convertible debt)?"
                        component={NumberField}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        numberFormat={baseNumberFormat}
                        fullWidth
                        disabled={submitting || !canEditInfo}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item>
                        <Field
                          name="borrower"
                          label="From whom?"
                          component={SelectField}
                          options={FROM_WHOM_BORROWED}
                          multiple
                          fullWidth
                          disabled={submitting || !canEditInfo}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item>
                        <Field
                          name="securedDebt"
                          label="How much of your debt is secured?"
                          component={NumberField}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                          disabled={submitting || !canEditInfo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item justify="flex-end" container spacing={2}>
                    <Grid item>
                      <Button
                        color="secondary"
                        onClick={onCancel.bind(null, form)}
                        disabled={submitting}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <SubmitButton
                        color="secondary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        disabled={pristine}
                        loading={submitting}
                      >
                        Save Changes
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </Grid>

                <FormSpy
                  subscription={{ pristine: true }}
                  onChange={({ pristine }) => {
                    setWhen(!pristine);
                  }}
                />
              </form>
            )}
          </Form>
        </Grid>
      )}
    </Grid>
  );
};
