import React from 'react';
import { useApolloClient, useQuery, useMutation, useSubscription } from 'react-apollo';
import * as R from 'ramda';

import {
  InboxMessage,
  InboxMessagePayload,
  InboxMessagesListQuery,
  InboxMessagesListQueryVariables,
  INBOX_MESSAGES_ON_CREATE_SUBSCRIPTION,
  INBOX_MESSAGES_COUNT_NOT_READ_QUERY,
  INBOX_MESSAGE_UPDATE_MUTATION,
} from 'src/graphql';

export enum InboxMessagesTypeName {
  InboxMessageList = 'InboxMessageListResponse',
}

export type InboxMessagesHook = {
  messagesCount: number;
  markInboxMessageAsRead: (message: InboxMessage | undefined) => void;
};

const uniqMessageIds = new Set<string>();

export const useInboxMessagesSubscribe = (
  companyId: string,
  isBigfootTeam: boolean,
): InboxMessagesHook => {
  const apolloClient = useApolloClient();

  const inboxMessagesCountQueryVariables = React.useMemo<InboxMessagesListQueryVariables>(() => {
    return {
      filter: {
        company: {
          id: {
            equals: companyId,
          },
        },
        isRead: {
          equals: false,
        },
        isByAdministrator: {
          equals: !isBigfootTeam,
        },
      },
    };
  }, [companyId, isBigfootTeam]);

  useSubscription<InboxMessagePayload, { companyId: string }>(
    INBOX_MESSAGES_ON_CREATE_SUBSCRIPTION,
    {
      variables: {
        companyId,
      },
      skip: !companyId,
      onSubscriptionData({ subscriptionData }) {
        const newInboxMessage = R.pathOr<InboxMessage | null>(
          null,
          ['data', 'InboxMessages', 'node'],
          subscriptionData,
        );
        const currentInboxMessageCount = getInboxMessagesCount();
        const shouldReadMessage = newInboxMessage?.isByAdministrator !== isBigfootTeam;
        const isUniqMessageId = !uniqMessageIds.has(newInboxMessage?.id as string);

        if (shouldReadMessage && isUniqMessageId) {
          updateInboxMessagesCount(currentInboxMessageCount + 1);
          uniqMessageIds.add(newInboxMessage?.id as string);
        }
      },
    },
  );

  const { data } = useQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>(
    INBOX_MESSAGES_COUNT_NOT_READ_QUERY,
    {
      variables: inboxMessagesCountQueryVariables,
      skip: !companyId,
    },
  );

  const messagesCount = R.pathOr(0, ['inboxMessagesList', 'count'], data);

  const [updateInboxMessage] = useMutation(INBOX_MESSAGE_UPDATE_MUTATION, {
    update(apolloClient, { data: updateInboxMessageAfterMutation }) {
      const countFromCache = getInboxMessagesCount();

      const isReadInboxMessageAfterMutation = Boolean(
        updateInboxMessageAfterMutation?.inboxMessageUpdate?.isRead,
      );

      if (isReadInboxMessageAfterMutation) {
        updateInboxMessagesCount(countFromCache - 1);
      }
    },
  });

  const markInboxMessageAsRead = React.useCallback(
    async (message?: InboxMessage) => {
      if (!message) {
        return;
      }

      const isFromAdminMessage = message?.isByAdministrator;

      if ((isFromAdminMessage && isBigfootTeam) || (!isFromAdminMessage && !isBigfootTeam)) {
        return;
      }

      try {
        await updateInboxMessage({
          variables: {
            messageId: message?.id as string,
            data: {
              isRead: true,
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [updateInboxMessage, isBigfootTeam],
  );

  function getInboxMessagesCount(): number {
    try {
      const data = apolloClient.readQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>({
        query: INBOX_MESSAGES_COUNT_NOT_READ_QUERY,
        variables: inboxMessagesCountQueryVariables,
      });

      return R.pathOr(0, ['inboxMessagesList', 'count'], data);
    } catch (error) {
      console.error(error);
    }

    return 0;
  }

  function updateInboxMessagesCount(count: number): void {
    try {
      apolloClient.writeQuery({
        query: INBOX_MESSAGES_COUNT_NOT_READ_QUERY,
        variables: inboxMessagesCountQueryVariables,
        data: {
          inboxMessagesList: {
            count,
            __typename: InboxMessagesTypeName.InboxMessageList,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  return {
    messagesCount,
    markInboxMessageAsRead,
  };
};
