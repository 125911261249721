import React from 'react';

import { Main } from './components';
import { DrawCreateDialog, DrawDeleteDialog, DrawEditDialog } from 'src/dialogs';

export const ClientPortalFacilityAndDrawsPage: React.FC = () => {
  return (
    <>
      <Main />
      <DrawCreateDialog />
      <DrawDeleteDialog />
      <DrawEditDialog />
    </>
  );
};
