import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import * as icons from '@material-ui/icons';

import {
  EntitiesTableActionsPopover,
  EntitiesTableCellValue,
  EntitiesTableToolbar,
  ModalsContext,
  SORT_DIRECTIONS,
  toSortVariables,
  useCurrentUser,
  usePagination,
  useSearch,
  useSelectedColumns,
  useSelectedRows,
  useSort,
} from 'src/app-builder';
import { TableHeader, EmptyScreen } from 'src/components';
import { useClientRoles } from 'src/app-builder/providers';
import {
  DIALOGS,
  DocumentCreateDialog,
  DocumentDeleteDialog,
  DocumentEditDialog,
} from 'src/dialogs';
import { ACTIONS_TITLE, AREAS } from 'src/constants';
import { Document, DOCUMENTS_TABLE_QUERY } from 'src/graphql';
import { AppRole } from 'src/types';
import {
  downloadDataUrl,
  bracketNotationToPath,
  extractUserCompanyId,
  getCurrentUserRole,
  providedByString,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
  emptyScreen: {
    minHeight: 500,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
  table: {
    borderCollapse: 'separate',
  },
  stickyLeft: {
    width: theme.spacing(6),
    background: theme.palette.background.paper,
    position: 'sticky',
    left: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  stickyRight: {
    width: 80,
    background: theme.palette.background.paper,
    position: 'sticky',
    right: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
  massAction: {
    padding: '10px 0px 10px 4px',
  },
}));

interface ClientPortalDocumentsPageProps {}

export const ClientPortalDocumentsPage: React.FC<ClientPortalDocumentsPageProps> = () => {
  const history = useHistory();
  const { currentRole } = useClientRoles();

  const { openModal } = React.useContext(ModalsContext);

  const [actions, setActions] = React.useState<{
    el: HTMLElement | null;
    id: string | null;
  }>({ el: null, id: null });

  const { user, loading: userLoading } = useCurrentUser();

  const totalRows = React.useRef<number>(0);
  const { page, perPage, handlePageChange, handlePerPageChange, perPageOptions } = usePagination({
    initialCount: totalRows.current,
  });

  const { sort, createOnSort } = useSort([{ by: 'updatedAt', dir: SORT_DIRECTIONS.DESC }]);

  const { search, handleSearchChange } = useSearch();

  const { selectedColumns } = useSelectedColumns({
    areaCode: AREAS.DOCUMENTS,
  });

  const selectedColumnsWithoutPrivate = selectedColumns.filter(
    filed => filed?.areaField?.fieldName !== 'private',
  );

  const { isAppCustomer, isAppCustomerOwner } = getCurrentUserRole(currentRole as AppRole);
  const isCustomer = isAppCustomer || isAppCustomerOwner;

  const companyId = extractUserCompanyId(user);

  const sortField = React.useMemo(() => {
    const { by, dir } = sort[0];
    const isSortByFile = by === 'file';

    return isSortByFile ? { file: { filename: R.toUpper(dir) } } : toSortVariables(sort);
  }, [sort]);

  const variables = React.useMemo(
    () => ({
      skip: page * perPage,
      first: perPage,
      sort: sortField,
      filter: {
        file: {
          filename: {
            contains: search,
          },
        },
        company: {
          id: {
            equals: companyId,
          },
        },
        private: {
          not_equals: true,
        },
      },
    }),
    [page, perPage, sortField, search, companyId],
  );

  const { data, loading: documentsLoading } = useQuery(DOCUMENTS_TABLE_QUERY, {
    variables,
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const classes = useStyles({ loading: documentsLoading });

  const rows = React.useMemo(() => data?.tableData?.items || [], [data]);
  const count = data?.tableData?.count;
  totalRows.current = count;

  const openActions = React.useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setActions({ el: event.currentTarget, id });
    },
    [setActions],
  );

  const closeActions = React.useCallback(() => {
    setActions({ el: null, id: null });
  }, [setActions]);

  const openCreateRowDialog = React.useCallback(() => {
    openModal(DIALOGS.DOCUMENT_CREATE_DIALOG, {
      companyId,
      onSuccess: () => {
        history.push('/client-portal/documents');
      },
    });

    closeActions();
  }, [openModal, closeActions, companyId, history]);

  const openEditRowDialog = React.useCallback(() => {
    openModal(DIALOGS.DOCUMENT_EDIT_DIALOG, { id: actions.id });

    closeActions();
  }, [openModal, closeActions, actions]);

  const openDeleteRowDialog = React.useCallback(
    (args?: any) => {
      openModal(DIALOGS.DOCUMENT_DELETE_DIALOG, args || { id: actions.id });

      closeActions();
    },
    [openModal, closeActions, actions],
  );

  const { selectedRows, handleSelectAll, handleSelectOne, setSelectedRows } = useSelectedRows(rows);

  const isLoading = userLoading || documentsLoading;

  const createActions = React.useCallback(
    (): React.ReactNode => [
      <MenuItem key="edit" onClick={openEditRowDialog}>
        <ListItemIcon>
          <icons.Edit />
        </ListItemIcon>
        Edit
      </MenuItem>,
      <MenuItem key="delete" onClick={() => openDeleteRowDialog()}>
        <ListItemIcon>
          <icons.Delete />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    [openDeleteRowDialog, openEditRowDialog],
  );

  const handleDownload = React.useCallback(async () => {
    await R.forEach(async (id: string) => {
      const document: Document | undefined = R.find(R.propEq('id', id))(rows);

      if (!R.isNil(document) && !R.isNil(document?.file)) {
        await downloadDataUrl(
          document?.file?.filename as string,
          document?.file?.downloadUrl as string,
        );
      }
    }, selectedRows);
  }, [rows, selectedRows]);

  const emptyScreen = React.useMemo(
    (): React.ReactNode => (
      <EmptyScreen
        text="We couldn't find any documents"
        actionText="Add documents"
        icon={icons.FileCopy}
        onClick={openCreateRowDialog}
      />
    ),
    [openCreateRowDialog],
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Paper className={classes.paper}>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            className={classes.header}
            item
            container
            alignItems="center"
            justify="space-between"
            direction="row"
            spacing={0}
          >
            <Typography variant="subtitle1">Documents</Typography>

            {!isLoading && !R.isEmpty(rows) && (
              <Button color="secondary" variant="outlined" onClick={openCreateRowDialog}>
                Add Document
              </Button>
            )}
          </Grid>

          {isLoading && <LinearProgress />}

          {!data ||
            (!userLoading && (
              <Grid className={classes.content} item container direction="column">
                <Grid item container direction="row">
                  {!R.isEmpty(rows) && (
                    <Grid className={classes.massAction} item direction="row">
                      <Checkbox
                        checked={selectedRows.length === rows.length}
                        color="primary"
                        indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                        onChange={handleSelectAll}
                      />

                      <IconButton
                        size="small"
                        className={classes.massAction}
                        onClick={handleDownload}
                      >
                        <icons.GetApp />
                      </IconButton>
                    </Grid>
                  )}

                  {(!R.isEmpty(rows) || search) && (
                    <Grid item xs>
                      <EntitiesTableToolbar
                        selectedRows={selectedRows}
                        searchInputProps={{ search, handleSearchChange }}
                        deleteButtonProps={{
                          openDeleteRowDialog,
                          setSelectedRows,
                          disabled: R.isEmpty(selectedRows),
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item container direction="column">
                  {R.isEmpty(rows) ? (
                    <Grid item container alignItems="center" className={classes.emptyScreen}>
                      {emptyScreen}
                    </Grid>
                  ) : (
                    <React.Fragment>
                      <Grid className={classes.tableWrapper} item>
                        <Table className={classes.table}>
                          <TableHeader
                            selectedColumns={selectedColumnsWithoutPrivate}
                            actionsProps={{
                              title: ACTIONS_TITLE,
                            }}
                            sortProps={{ sort, createOnSort }}
                            checkboxProps={{
                              hideCheckbox: true,
                            }}
                          />
                          <TableBody>
                            {rows.map((item: any) => (
                              <TableRow hover key={item.id}>
                                <TableCell padding="checkbox" className={classes.stickyLeft}>
                                  <Checkbox
                                    checked={selectedRows.includes(item.id)}
                                    color="primary"
                                    onChange={() => handleSelectOne(item.id)}
                                    value="true"
                                  />
                                </TableCell>

                                {selectedColumnsWithoutPrivate.map(
                                  ({ areaField, tableField, selected }) => {
                                    if (!selected) {
                                      return null;
                                    }

                                    const areaFieldPath = bracketNotationToPath(
                                      areaField.fieldName,
                                    );

                                    if (areaField.name === 'Provided By') {
                                      return (
                                        <TableCell>{providedByString(item?.createdBy)}</TableCell>
                                      );
                                    }

                                    return (
                                      <TableCell key={areaField.id}>
                                        <EntitiesTableCellValue
                                          record={item}
                                          field={tableField}
                                          areaField={areaField}
                                          value={R.path(areaFieldPath, item)}
                                        />
                                      </TableCell>
                                    );
                                  },
                                )}
                                <TableCell className={classes.stickyRight}>
                                  {isCustomer && (
                                    <IconButton onClick={openActions(item.id)}>
                                      <icons.MoreVert />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <EntitiesTableActionsPopover
                            anchorEl={actions.el}
                            open={Boolean(actions.el)}
                            onClose={closeActions}
                            activeId={actions.id}
                            createActions={createActions}
                          />
                        </Table>
                      </Grid>
                      <Grid item>
                        <TablePagination
                          component="div"
                          count={count}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerPageChange}
                          page={page}
                          rowsPerPage={perPage}
                          rowsPerPageOptions={perPageOptions}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            ))}
          <DocumentCreateDialog />
          <DocumentEditDialog />
          <DocumentDeleteDialog />
        </Grid>
      </Paper>
    </Grid>
  );
};
