import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { Main } from './components';

export const ClientPortalInboxPage: React.FC = () => {
  return (
    <Switch>
      <Route
        path={[`/client-portal/inbox/:folder/:messageId`, `/client-portal/inbox/:folder`]}
        component={Main}
      />
      <Redirect to={`/client-portal/inbox/received`} />
    </Switch>
  );
};
