import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, SelectField, TextField, useModal } from 'src/app-builder';
import { SubmitButton } from 'src/components';

import { useNotification } from 'src/hooks';
import { EVENT_RESOLVER_MUTATION, EventType, USER_EDIT_QUERY, User } from 'src/graphql';
import { DIALOGS } from 'src/dialogs/constants';
import { BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST, APP_ROLES } from 'src/types';
import { validateDataByYup, commonErrorResolver, t } from 'src/utils';
import { BigfootEmployeeEditEventSchema } from 'src/utils/schemas/event';

interface UserEditDialog {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

export const UserEditDialog: React.FC<UserEditDialog> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, closeModal, args, openModal } = useModal(DIALOGS.USER_EDIT_DIALOG);

  const { data: userData, loading: userLoading } = useQuery<{ user: User }>(USER_EDIT_QUERY, {
    variables: {
      id: args?.userId,
    },
    skip: !open || !Boolean(args?.userId),
  });

  const roleOptions = React.useMemo(
    () =>
      BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST.map(name => {
        return { label: APP_ROLES[name].title, value: name };
      }).filter(Boolean),
    [],
  );

  const [eventResolver] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['UsersTableContent', 'PortfolioCompaniesEntity', 'LoanApplicationsEntity'],
    awaitRefetchQueries: true,
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      try {
        await eventResolver({
          variables: {
            event: data,
          },
        });
        closeModal();
        setTimeout(form.reset);
        notification.success(t('user_update_success'));
      } catch (error) {
        notification.error(commonErrorResolver(error));
      }
    },
    [closeModal, eventResolver, notification],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  const user = userData?.user;

  const userRole = React.useMemo(
    () =>
      user?.roles?.items?.filter(({ name }) =>
        BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST.some(systemRoleName => systemRoleName === name),
      ) || [],
    [user],
  );

  const initialValues = React.useMemo(
    () =>
      userLoading
        ? {}
        : {
            type: EventType.BigfootEmployeeEdit,
            payload: {
              appRoleName: userRole[0]?.name,
              ...user,
            },
          },
    [user, userLoading, userRole],
  );

  if (!open || userLoading) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => validateDataByYup(BigfootEmployeeEditEventSchema, values)}
    >
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle>Edit User</DialogTitle>

          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Field
                  component={TextField}
                  name="payload.firstName"
                  label="First Name*"
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="payload.lastName"
                  label="Last Name*"
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="payload.email"
                  label="Email*"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={SelectField}
                  name="payload.appRoleName"
                  label="Role*"
                  disabled={submitting}
                  options={roleOptions}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" disabled={submitting} onClick={() => onClose(form, pristine)}>
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Save Changes
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
