import React, { memo } from 'react';
import { createContext, useState } from 'react';

interface SortContextValue {
  keys: string[];
  allKeys: string[];
  initialOrder: string[];
  setKeys: (keys: string[]) => void;
}

const selectedColumnsContext = createContext<SortContextValue>({} as SortContextValue);

const useSelectedColumns = (allKeys: string[], initialOrder: string[]): SortContextValue => {
  const [keys, setKeys] = useState(initialOrder);

  return {
    keys,
    allKeys,
    initialOrder,
    setKeys,
  };
};

interface UseSelectedColumnsProvider {
  children: React.ReactNode;
  allKeys: string[];
  initialOrder: string[];
}

const SelectedColumnsProvider = memo(
  ({ children, allKeys, initialOrder }: UseSelectedColumnsProvider) => {
    const ctx = useSelectedColumns(allKeys, initialOrder);
    return (
      <selectedColumnsContext.Provider value={ctx}>{children}</selectedColumnsContext.Provider>
    );
  },
);

SelectedColumnsProvider.displayName = 'SelectedColumnsProvider';

export { selectedColumnsContext, SelectedColumnsProvider };
