import React from 'react';
import { ButtonProps, TableCellProps, TableContainerProps, TableProps } from '@material-ui/core';

export type DefaultDataRow = Record<string, any>;
export type TableTitle = React.ReactNode;

export enum TableCellPropKey {
  align = 'align',
  width = 'width',
  className = 'className',
}
export const MATERIAL_TABLE_CELL_PROPS_KEYS = [TableCellPropKey.align, TableCellPropKey.className];

export type TableColumnCellProps = {
  [TableCellPropKey.align]?: TableCellProps['align'];
  [TableCellPropKey.className]?: string;
  [TableCellPropKey.width]?: string | number;
};

export type TableRowAction<TRecord = DefaultDataRow> = {
  actionName: string;
  onAction: (record: TRecord, rowIndex: number) => void;
  disabled?: boolean;
};

export type TableActionState<TRecord = DefaultDataRow> = {
  dataRow: TRecord;
  rowIndex: number;
} | null;

export type TableColumn<TRecord = DefaultDataRow> = {
  title: TableTitle;
  key: string;
  dataPath?: string;
  render?: (
    valueByDataPath: any,
    row: TRecord,
    rowIndex: number,
    dataSourceArr: TRecord[],
  ) => React.ReactNode;
} & TableColumnCellProps;

export interface TableDataProps<TRecord> {
  dataSource: Array<TRecord>;
  columns: Array<TableColumn<TRecord>>;
  rowActions?: Array<TableRowAction<TRecord>>;
  loading?: boolean;
  hideTitle?: boolean;
  emptyMessage?: string;
  disableEmptyMessage?: boolean;
  tableProps?: TableProps;
  tableContainerProps?: TableContainerProps;
}

type BaseButtonProps = Omit<ButtonProps, 'startIcon' | 'endIcon' | 'color'>;
export interface BottomActionButtonProps extends BaseButtonProps {
  icon: React.ReactNode;
}

export interface TableHeadProps<TRecord> {
  columns: Array<TableColumn<TRecord>>;
  hasRowActions: boolean;
}
