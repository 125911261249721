import React from 'react';
import * as R from 'ramda';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'ramda';

export type SetQueryParamsOptions = {
  shouldReplace?: boolean;
};

export type SetQueryParamsHandler<T> = (
  queryParams: Partial<T>,
  options?: SetQueryParamsOptions,
) => void;

export const useQueryParams = <T = Record<string, string>>(
  options: {
    initialValues?: T;
  } = {},
): [
  T,
  {
    setQueryParams: SetQueryParamsHandler<T>;
  },
] => {
  const location = useLocation();
  const history = useHistory();

  const params = React.useMemo(
    () =>
      R.merge(
        options.initialValues || {},
        qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, any>,
      ) as T,
    [location.search, options.initialValues],
  );

  React.useEffect(() => {
    if (isEmpty(params) && !isEmpty(options.initialValues) && !!options.initialValues) {
      history.replace({
        search: qs.stringify(options.initialValues),
      });
    }
  }, [params, history, options.initialValues]);

  const setQueryParams = React.useCallback(
    (value: Partial<T>, { shouldReplace = false }: SetQueryParamsOptions = {}) => {
      history.replace({
        search: qs.stringify(
          shouldReplace
            ? value
            : {
                ...params,
                ...value,
              },
          { arrayFormat: 'brackets' },
        ),
      });
    },
    [history, params],
  );

  return [params, { setQueryParams }];
};
