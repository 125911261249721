import React from 'react';
import { FormControl, FormHelperText, Checkbox, FormLabel } from '@material-ui/core';
import { FieldSchema } from '@8base/utils';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';

interface CheckboxFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  fieldSchema: FieldSchema;
  label?: React.ReactNode;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ label, input, meta, ...rest }) => {
  const { name, value, onBlur, onChange } = input;

  const handleChange = React.useCallback(
    e => {
      onChange(e.target.checked);
    },
    [onChange],
  );

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  return (
    <FormControl variant="outlined" component="fieldset" error={error}>
      <FormLabel component="legend">{label}</FormLabel>
      <Checkbox name={name} checked={value} onChange={handleChange} onBlur={onBlur} {...rest} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
