import React, { useContext, useState } from 'react';
import { useDebounce } from 'react-use';
import { ModalsContext } from 'src/app-builder';

import ToolBar from 'src/shared/table/ToolBar';
import { useSearch } from 'src/hooks';
import { selectedRowsContext } from 'src/providers/SelectedRowsProvider';

interface ToolbarContainerProps {
  canDelete: boolean;
  dialog: string;
}

const ToolbarContainer: React.FC<ToolbarContainerProps> = React.memo(({ canDelete, dialog }) => {
  const { openModal } = React.useContext(ModalsContext);
  const { getSelectedArray } = useContext(selectedRowsContext);
  const selected = useContext(selectedRowsContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const search = useSearch();

  useDebounce(
    () => {
      if (search?.setSearch) search?.setSearch(searchValue);
    },
    500,
    [searchValue],
  );

  const selectedRowsCount = Object.values(selected?.selectedRows || {}).filter(v => v).length;

  const openDeleteRowDialog = React.useCallback(() => {
    openModal(dialog, {
      id: getSelectedArray(),
    });
  }, [openModal, dialog, getSelectedArray]);

  return (
    <ToolBar
      canDelete={canDelete}
      selected={selectedRowsCount}
      setSearchValue={setSearchValue}
      value={searchValue}
      onDeleteButtonClick={openDeleteRowDialog}
    />
  );
});

ToolbarContainer.displayName = 'ToolbarContainer';

export { ToolbarContainer };
