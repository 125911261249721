import { ProgressStepCode } from 'src/types';

enum StepGroupTitle {
  OrganizationInformation = 'I. Organization Information',
  FinancialInformation = 'II. Financial Information',
  CustomersOrContractsInformation = 'III. Customers / Contracts Information',
  ProductInformation = 'IV. Product Information',
  CEOOrGuarantorInformation = 'V. CEO/Guarantor Information',
  ThreeRDPartyReports = 'VI. 3rd Party Reports',
  Other = 'VII. Other',
}

export enum ProgressStepType {
  CompanyStep = 'companyStep',
}

export const STEP_GROUP: Record<string, string> = {
  [ProgressStepCode.ArticlesOfOrganizationIncorporation]: StepGroupTitle.OrganizationInformation,
  [ProgressStepCode.MonthlyIncomeStatementsForMostRecentTwoCompleteFiscalYears]:
    StepGroupTitle.FinancialInformation,
  [ProgressStepCode.CustomerContractsSampleContractsThreeLargestCustomers]:
    StepGroupTitle.CustomersOrContractsInformation,
  [ProgressStepCode.ProductDemo]: StepGroupTitle.ProductInformation,
  [ProgressStepCode.CopyOfDriversLicense]: StepGroupTitle.CEOOrGuarantorInformation,
  [ProgressStepCode.BackgroundCheckCompany]: StepGroupTitle.ThreeRDPartyReports,
  Other: StepGroupTitle.Other,
};

export const STEP_GROUP_COMMENT: Record<string, string> = {
  [StepGroupTitle.FinancialInformation]:
    'We can pull financials ourselves if you provide read-only access to your accounting system.',
  [StepGroupTitle.ThreeRDPartyReports]: 'Bigfoot to pull below reports.',
};

export const DEFAULT_PROGRESS_COMPANY_STEP_NAME = 'Item';
