import React from 'react';
import { useMutation } from 'react-apollo';
import { Popover, PopoverProps, Box, Grid, FormHelperText } from '@material-ui/core';
import { Form, Field, RadioGroupField } from 'src/app-builder';

import { SubmitButton } from 'src/components';
import { useNotification } from 'src/hooks';
import { LOAN_APPLICATIONS_EDIT_MUTATION } from 'src/graphql';
import { LoanApplicationStage } from 'src/constants';
import { t } from 'src/utils';

interface StagePopoverProps extends PopoverProps {
  onClose: () => void;
  loanApplicationId: string | null;
  stage?: string;
  awaitRefetchQueries?: boolean;
}

export const StagePopover: React.FC<StagePopoverProps> = ({
  anchorEl,
  open,
  onClose,
  loanApplicationId,
  stage,
  awaitRefetchQueries = true,
}) => {
  const notification = useNotification();

  const [loanApplicationUpdate] = useMutation(LOAN_APPLICATIONS_EDIT_MUTATION, {
    refetchQueries: ['LoanApplicationsMetrics', 'SortableLoanApplicationsList'],
    awaitRefetchQueries,
    onError: () => notification.error(t('loan_application_stage_update_error')),
  });

  const onSubmit = React.useCallback(
    async data => {
      await loanApplicationUpdate({
        variables: {
          data: {
            ...data,
            id: loanApplicationId,
          },
        },
      });

      onClose();
    },
    [loanApplicationUpdate, loanApplicationId, onClose],
  );

  const initialValues = React.useMemo(
    () => ({
      stage,
    }),
    [stage],
  );

  if (!loanApplicationId) {
    return null;
  }

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine, submitError }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            padding={2}
            paddingTop={1}
            paddingBottom={1}
          >
            <Grid container direction="column" style={{ overflow: 'auto', maxHeight: 300 }}>
              <Field
                name="stage"
                component={RadioGroupField}
                options={Object.values(LoanApplicationStage).map(value => ({
                  value,
                  label: value,
                }))}
              />
            </Grid>
            {submitError && <FormHelperText error>{submitError}</FormHelperText>}
            <Grid container justify="flex-end">
              <Grid item>
                <SubmitButton
                  type="submit"
                  color="secondary"
                  disabled={pristine}
                  loading={submitting}
                >
                  Apply
                </SubmitButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Form>
    </Popover>
  );
};
