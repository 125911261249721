import React from 'react';
import { Grid, Typography, Stepper, Step, StepLabel } from '@material-ui/core';

import { OnboardingStep } from 'src/constants';

interface OnboardingProgressBarProps {
  step: number;
}

const ONBOARDING_STEPS = ['Create Account', 'Share Data', 'Receive SaaSScore and SaaSLoan Offer'];

export const OnboardingStepper: React.FC<OnboardingProgressBarProps> = ({ step }) => {
  const activeStep = step <= ONBOARDING_STEPS.length ? step : OnboardingStep.First;

  return (
    <Grid item container justify="center" xs={12}>
      <Grid item xs={12} container justify="center">
        <Typography style={{ textTransform: 'uppercase' }}>5 minute financing offer</Typography>
      </Grid>

      <Grid item container xs={12} justify="center">
        <Stepper alternativeLabel activeStep={activeStep}>
          {ONBOARDING_STEPS.map(stepLabel => (
            <Step key={stepLabel}>
              <StepLabel>{stepLabel}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};
