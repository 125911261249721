import { gql } from '8base-react-sdk';
import { MetricCode, MetricPeriod } from 'src/types';

export const METRICS_LIST_QUERY = gql`
  query MetricsList($filter: MetricFilter) {
    metricsList(filter: $filter) {
      items {
        id
        name
        code
        type
        format
        saaSScoreWeight
        saaSScoreWeightEnterprise
        tierBands
        metadata
      }
    }
  }
`;

export const METRIC_VALUES_LIST_QUERY = gql`
  query MetricValuesList($filter: MetricValueFilter!) {
    metricValuesList(
      groupBy: {
        query: { date: { as: "date" }, _group: { as: "metrics" } }
        sort: { alias: "date", direction: ASC }
      }
      filter: $filter
    ) {
      groups {
        date: Date
        metrics: MetricValueGroup {
          items {
            id
            value
            comment
            adjustedValue
            source
            date
            tier
            period
            active
            company {
              id
            }
            metric {
              id
              name
              format
              code
            }
            createdBy {
              title
              id
              avatar {
                id
                previewUrl
                downloadUrl
              }
              lastName
              firstName
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const METRIC_VALUES_LIST_BY_CODE_QUERY = gql`
  query MetricValuesListByCode($filter: MetricValueFilter!) {
    metricValuesList(
      groupBy: { query: { metric: { code: { as: "code" } }, _group: { as: "metrics" } } }
      filter: $filter
    ) {
      groups {
        code: String
        metrics: MetricValueGroup {
          items {
            id
            value
            adjustedValue
            tier
            date
            metric {
              id
              name
              format
              code
            }
          }
        }
      }
    }
  }
`;

export const METRICS_COMPANY_HEADER_QUERY = gql`
  query MetricsCompanyHeader($companyId: ID!, $date: Date!) {
    company(id: $companyId) {
      id
      name
      metrics(
        groupBy: {
          query: { metric: { code: { as: "code" } }, _group: { as: "metrics" } }
          having: {
            alias: "code"
            string: {
              in: [
                "${MetricCode.SaaSScore}",
                "${MetricCode.EndingMRR}",
                "${MetricCode.CashPosition}",
                "${MetricCode.CashRunwayAccounting}",
                "${MetricCode.AdjustedNetCashBurn}"
              ]
            }
          }
        }
      ) {
        groups {
          code: String
          metrics: MetricValueGroup(filter: { period: { equals: "${MetricPeriod.Month}" }, date: { equals: $date } }) {
            items {
              id
              value
              adjustedValue
              date
              tier
            }
          }
        }
      }
    }
    getFundingDate(companyId: $companyId) {
      fundingDate
    }
    getAmountCommitted(companyId: $companyId) {
      amountCommitted
    }
    getTotalAmountDeployed(companyId: $companyId) {
      totalAmountDeployed
    }
  }
`;

export const CALCULATE_METRICS_MUTATION = gql`
  mutation CalculateMetric($companyId: ID!) {
    calculateMetrics(companyId: $companyId) {
      success
    }
  }
`;

export const CALCULATE_SAAS_SCORE_COMPONENTS_MUTATION = gql`
  mutation CalculateSaaSScoreComponents($companyId: ID!) {
    calculateSaaSScoreComponents(companyId: $companyId) {
      success
    }
  }
`;

export const CALCULATE_SAAS_SCORE_MUTATION = gql`
  mutation CalculateSaaSScore($companyId: ID!) {
    calculateSaaSScore(companyId: $companyId) {
      success
    }
  }
`;

export const METRIC_VALUE_UPSERT_MANY_MUTATION = gql`
  mutation MetricValueUpsertMany($data: [MetricValueCreateManyInput]!) {
    metricValueUpsertMany(data: $data) {
      success
    }
  }
`;

export const METRIC_VALUE_UPSERT = gql`
  mutation MetricValueUpsert(
    $companyId: ID!
    $date: Date!
    $metricCode: String!
    $value: Float
    $adjustedValue: Float
    $comment: String
    $period: String!
  ) {
    metricValueUpsert(
      companyId: $companyId
      date: $date
      metricCode: $metricCode
      value: $value
      adjustedValue: $adjustedValue
      comment: $comment
      period: $period
    ) {
      id
    }
  }
`;

export const UPDATE_METRIC_SETTINGS = gql`
  mutation UpdateMetricSettings($settings: [MetricSettingsInput]) {
    updateMetricSettings(settings: $settings) {
      success
    }
  }
`;

export const SAAS_LOAN_OPTIONS_QUERY = gql`
  query calculateSaaSLoanOptions($companyId: ID!) {
    calculateSaaSLoanOptions(companyId: $companyId) {
      items {
        term
        type
        revenueMultiplier
        repaymentMultiplier
        capitalAvailable
        totalRepayment
        monthlyPayment
      }
    }
  }
`;
