import React from 'react';
import { LinearProgress } from '@material-ui/core';

import { useDashboardMetrics } from 'src/hooks';
import { SMBDashboard, EnterpriseDashboard } from 'src/components';
import { Company } from 'src/graphql';
import { isEnterpriseCompany } from 'src/utils';

interface DashboardProps {
  company: Company;
  loading: boolean;
  period: string[];
  oneColumn?: boolean;
}

export const Dashboard: React.FC<DashboardProps> = props => {
  const { company, period, oneColumn = false, loading } = props;

  const isEnterprise = isEnterpriseCompany(company);

  const { data: dashboardMetrics, loading: dashboardMetricsLoading } = useDashboardMetrics(
    company,
    period,
  );

  const isLoading = loading || dashboardMetricsLoading;

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isEnterprise) {
    return <EnterpriseDashboard dashboardMetrics={dashboardMetrics} oneColumn={oneColumn} />;
  }

  return <SMBDashboard dashboardMetrics={dashboardMetrics} oneColumn={oneColumn} />;
};
