import { useQuery } from 'react-apollo';
import { Company, COMPANIES_QUERY } from 'src/graphql';

type CompanyHook = {
  data: Company | undefined;
  loading: boolean;
};

type CompanyFilterById = { id: string };

export const useCompany = (companyId: string): CompanyHook => {
  const { data: companyData, loading } = useQuery<Company, CompanyFilterById>(COMPANIES_QUERY, {
    variables: {
      id: companyId,
    },
    skip: !companyId,
  });
  const company = companyData?.company as Company | undefined;

  return { data: company, loading };
};
