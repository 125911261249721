import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Paper, Theme, Typography } from '@material-ui/core';

interface LayoutProps {
  canCompose?: boolean;
  openCreateInboxDialog?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    height: '100%',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: -theme.spacing(8),
    background: theme.palette.background.paper,
    zIndex: 101,
  },
  content: {
    flex: '1 0 auto',
    height: `calc(100% - ${theme.spacing(8)}px)`,
  },
  fullHeight: {
    height: '100%',
  },
}));

export const LayoutInbox: React.FC<LayoutProps> = props => {
  const { canCompose, openCreateInboxDialog, children } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.fullHeight} container direction="column" wrap="nowrap">
      <Paper className={classes.paper}>
        <Grid className={classes.header} item>
          <Typography variant="subtitle1">Inbox</Typography>
          {canCompose && (
            <Button variant="outlined" color="secondary" onClick={openCreateInboxDialog}>
              Compose
            </Button>
          )}
        </Grid>

        <Grid className={classes.content} direction="row" item container>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};
