import React from 'react';
import * as R from 'ramda';
import { tablesListSelectors, createTableFilterGraphqlTag } from '@8base/utils';
import { useTablesList, gql } from '8base-react-sdk';
import { useQuery } from 'react-apollo';

import { SelectField, SelectFieldProps } from '../forms';

interface EntitySelectFieldProps extends Omit<SelectFieldProps, 'options'> {
  tableName: string;
}

export const EntitySelectField: React.FC<EntitySelectFieldProps> = ({
  tableName,
  disabled,
  ...rest
}) => {
  const { tablesList } = useTablesList();

  const table = tablesListSelectors.getTableByName(tablesList, tableName);

  const listQuery = React.useMemo(
    () => gql`
      ${createTableFilterGraphqlTag([table], table.id, {
        tableContentName: 'tableData',
      })}
    `,
    [table],
  );

  const { data, loading } = useQuery(listQuery);

  const options = React.useMemo(
    () =>
      loading
        ? []
        : R.pipe(
            R.pathOr([], ['tableData', 'items']),
            R.map(({ id, _description }) => ({ value: id, label: _description })),
          )(data),
    [data, loading],
  );

  return <SelectField options={options} disabled={disabled || loading} {...rest} />;
};
