import React from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress, Theme, Typography } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { useTableExport, useToggleMetricsContext } from 'src/hooks';

import { SubmitButton } from 'src/components';
import { MetricValue, TableExportType } from 'src/graphql';
import {
  DateFormatPatterns,
  insertDelimitersAfter,
  detectArrowType,
  formatDate,
  detectSharedMetric,
} from 'src/utils';
import {
  CELL_TYPES,
  MetricCode,
  ScoreMetricItem,
  ScoreMetricsTableDataStructureRow,
} from 'src/types';

import { TABLE_DELIMITER } from 'src/constants';
import { Table } from './Table';
import { MetricColumnRenderer, renderValueColumn, renderTierColumn, renderToggleColumn } from './';

import cellRenderClasses from 'src/shared/metrics/TableUnderwritingReport/CellRenderer.module.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  form: {
    padding: theme.spacing(2),
  },
  right: {
    marginLeft: 'auto',
  },
  flex: {
    display: 'flex',
  },
}));

interface LayoutProps {
  filters: React.ReactNode;
  tableData: any;
  loading: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ filters, tableData, loading }) => {
  const classes = useStyles();

  const { areTogglesExpanded } = useToggleMetricsContext();

  const dates: string[] = tableData?.dates;
  const scoreMetricItems: ScoreMetricItem[] = R.pathOr([], ['data'], tableData);

  const arrowTypes = React.useMemo(() => {
    return dates?.map((date, idx) => detectArrowType(dates, scoreMetricItems, date, idx));
  }, [dates, scoreMetricItems]);

  const metricsWithDelimiters = React.useMemo(() => {
    const delimiterAfterCodes = [MetricCode.NetCashBurnOfGrossProfit];

    const compare = (metricItem: ScoreMetricItem, metricCode: MetricCode) => {
      return R.propEq('code', metricCode)(metricItem);
    };

    return insertDelimitersAfter<ScoreMetricItem>(
      delimiterAfterCodes,
      scoreMetricItems as ScoreMetricItem[],
      compare,
    );
  }, [scoreMetricItems]);

  const colSpan = areTogglesExpanded ? 3 : 2;

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'name',
        exportData: { title: ' ', type: CELL_TYPES.text, key: 'name' },
        render: MetricColumnRenderer,
        width: 300,
        fixed: 'left',
      },
      ...dates
        .map((date, dateIdx) => [
          {
            title: formatDate(
              date,
              DateFormatPatterns.shortDateWithSlash,
              DateFormatPatterns.shortDateWithDash,
            ),
            exportData: { title: date, type: CELL_TYPES.text, key: date },
            colSpan,
            width: 110,
            dataIndex: date,
            render: (
              metricValue: MetricValue,
              row: ScoreMetricsTableDataStructureRow,
              rowIndex: number,
            ) => {
              if (row === TABLE_DELIMITER) {
                return <div className={cellRenderClasses.emptyCell} />;
              }

              return renderValueColumn({
                metricValue,
                colSpan,
                rowIndex,
                arrowType: arrowTypes[dateIdx],
              });
            },
          },
          {
            colSpan: 0,
            width: 35,
            dataIndex: date,
            render: (value: MetricValue, row: ScoreMetricsTableDataStructureRow, idx: number) => {
              const isSharedMetric = detectSharedMetric(row);

              if (row === TABLE_DELIMITER || isSharedMetric) {
                return <div className={cellRenderClasses.emptyCell} />;
              }

              return renderTierColumn(value, row, idx);
            },
          },
          ...(areTogglesExpanded
            ? [
                {
                  colSpan: 0,
                  width: 75,
                  dataIndex: date,
                  render: (
                    metricValue: MetricValue,
                    row: ScoreMetricsTableDataStructureRow,
                    idx: number,
                  ) => {
                    const isSharedMetric = detectSharedMetric(row);
                    const isEmptyCell = row === TABLE_DELIMITER || isSharedMetric;

                    if (isEmptyCell) {
                      return <div className={cellRenderClasses.emptyCell} />;
                    }

                    return renderToggleColumn(metricValue, idx);
                  },
                },
              ]
            : []),
        ])
        .flat(),
    ],
    [dates, colSpan, areTogglesExpanded, arrowTypes],
  );

  const [onExport, { loading: isExporting }] = useTableExport(TableExportType.ScoreAndMetrics, {
    rows: scoreMetricItems,
    columns,
  });

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid className={classes.header} item>
        <Typography variant="subtitle1">SaaSScore &amp; Key Metrics</Typography>

        <Grid className={classes.right} item>
          <SubmitButton
            onClick={onExport}
            color="secondary"
            variant="outlined"
            size="large"
            startIcon={<icons.GetApp />}
            loading={isExporting}
          >
            Export
          </SubmitButton>
        </Grid>
      </Grid>

      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <Grid className={classes.form} item container alignItems="center" spacing={2}>
            <Grid className={classes.flex} item alignItems="center" xs={12}>
              {filters}
            </Grid>
          </Grid>
          <Table data={metricsWithDelimiters} dates={dates} columns={columns} />
        </>
      )}
    </Grid>
  );
};
