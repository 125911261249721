import React from 'react';
import { usePagination } from 'src/app-builder';

export interface PaginationProviderValue extends ReturnType<typeof usePagination> {
  count: number;
}

const PaginationContext = React.createContext<PaginationProviderValue>(
  {} as PaginationProviderValue,
);

const PaginationProvider = React.memo(({ children }: any) => {
  const totalRows = React.useRef<number>(0);
  const pagination = usePagination({ initialCount: totalRows.current });

  return (
    <PaginationContext.Provider value={{ ...pagination, count: totalRows.current }}>
      {children}
    </PaginationContext.Provider>
  );
});

PaginationProvider.displayName = 'PaginationProvider';

export { PaginationContext, PaginationProvider };
