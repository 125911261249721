import React from 'react';
import { useSubscription, useMutation } from 'react-apollo';
import * as R from 'ramda';

import {
  AsyncTask,
  FINISHED_ASYNC_TASKS_SUBSCRIPTION,
  GET_STATEMENTS_METRICS_MUTATION,
  StatementsReportType,
} from 'src/graphql';
import { isFunction } from 'src/utils';
import { AsyncTaskStatus, Source, StatementsReportObject } from 'src/types';
import { ASYNC_TASK_TYPES } from 'src/constants/asyncTask';
import { anyAsyncTaskFailed, anyAsyncTaskSucceded } from 'src/utils/asyncTasks';

type ReportStatus = [
  (reportType: StatementsReportType, period: string[]) => void,
  () => void,
  {
    data: {
      statementsReports: StatementsReportObject[];
      reportType: StatementsReportType;
    };
    loading: boolean;
    error: boolean;
    disabled: boolean;
    filter: {
      from: string | null;
      till: string | null;
    };
  },
];

export const useStatementsReport = (
  params: {
    companyId: string | undefined;
    integrationType: Source.Xero | Source.QBO | null;
    integrationsLoading: boolean;
  },
  options: {
    onCompleted?: () => void;
    onError?: () => void;
  } = {},
): ReportStatus => {
  const { companyId, integrationType, integrationsLoading } = params;

  const [reportType, setReportType] = React.useState<StatementsReportType>(
    StatementsReportType.BalanceSheet,
  );
  const [period, setPeriod] = React.useState<string[] | null>(null);

  const [statementsReports, setStatementsReports] = React.useState<StatementsReportObject[] | null>(
    null,
  );

  const from = !R.isNil(period) ? period[0] : null;
  const till = !R.isNil(period) ? period[1] : null;

  const onChangeActiveReport = (reportType: StatementsReportType, period: string[]) => {
    setReportType(reportType);
    setPeriod(period);
    setStatementsReports([]);

    onGetStatements(integrationType, companyId, reportType, period);
  };

  const onRefetch = () => {
    onGetStatements(integrationType, companyId, reportType, period);
  };

  // Use ref for statuses to avoid stale data in subscription callback
  const asyncTasksRef = React.useRef<Record<string, AsyncTask>>({});

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const [getStatements] = useMutation(GET_STATEMENTS_METRICS_MUTATION);

  useSubscription(FINISHED_ASYNC_TASKS_SUBSCRIPTION, {
    onSubscriptionData: async ({ subscriptionData }) => {
      const node = R.pathOr(
        null,
        ['data', 'AsyncTasks', 'node'],
        subscriptionData,
      ) as AsyncTask | null;

      if (node) {
        const nodeId = node.id as string;
        const nodeName = node?.name;
        const nodeResult = node?.result;

        if (nodeId in asyncTasksRef.current) {
          asyncTasksRef.current[nodeId].status = node.status;

          // If any task failed - skip other results and display error state
          const tasksFailed = anyAsyncTaskFailed(asyncTasksRef.current);

          if (tasksFailed) {
            if (options.onError && isFunction(options?.onError)) {
              await options.onError();
            }

            setStatementsReports([]);
            setIsLoading(false);
            setIsError(true);
          }

          const tasksFinished = anyAsyncTaskSucceded(asyncTasksRef.current);

          // If all tasks finished - we can run metrics calculation
          if (tasksFinished) {
            if (nodeName === ASYNC_TASK_TYPES.getStatementsTask && Boolean(nodeResult)) {
              try {
                const response = await fetch(nodeResult);
                const reportsData: any[] = await response.json();

                setStatementsReports(reportsData[0]);
                setIsLoading(false);
              } catch (e) {
                setIsError(true);
                setStatementsReports([]);

                if (options.onError && isFunction(options?.onError)) {
                  await options.onError();
                }

                console.error({ e });
              }
            }

            if (options.onCompleted && isFunction(options.onCompleted)) {
              await options.onCompleted();
            }

            setIsLoading(false);
          }
        }
      }
    },
    skip: isError || !companyId,
  });

  const onGetStatements = React.useCallback(
    async (integrationType, companyId, reportType, period) => {
      if (!integrationsLoading && !isLoading && !R.isNil(integrationType)) {
        setIsLoading(true);
        setIsError(false);
        asyncTasksRef.current = {};

        await getStatements({
          variables: {
            event: {
              integrationType,
              companyId,
              reportType,
              period,
            },
          },
        }).then(response => {
          const id = R.pathOr('', ['data', 'getStatementsMetricsMutation', 'taskId'], response);

          asyncTasksRef.current[id] = {
            id,
            status: AsyncTaskStatus.Pending,
          };
        });
      }
    },
    [integrationsLoading, getStatements, isLoading],
  );

  React.useEffect(() => {
    if (statementsReports === null && !isLoading && !integrationsLoading) {
      onGetStatements(integrationType, companyId, reportType, period);
    }
  }, [
    isLoading,
    statementsReports,
    integrationsLoading,
    onGetStatements,
    integrationType,
    companyId,
    reportType,
    period,
  ]);

  return [
    onChangeActiveReport,
    onRefetch,
    {
      data: { statementsReports: statementsReports || [], reportType },
      loading: isLoading,
      error: isError,
      disabled: isLoading,
      filter: { from, till },
    },
  ];
};
