import React from 'react';
import * as R from 'ramda';
import { SortDirection as MuiSortDirection } from '@material-ui/core';

import { toggleSortDirection } from '../utils';

export type SortDirection = Exclude<MuiSortDirection, false>;

export const SORT_DIRECTIONS: Record<string, SortDirection> = {
  ASC: 'asc',
  DESC: 'desc',
};

type Sort = {
  by: string;
  dir: SortDirection;
};

export const useSort = (
  initialSort: Array<Sort> = [],
): {
  sort: Array<Sort>;
  createOnSort: (fieldName: string) => () => void;
} => {
  const [sort, setSort] = React.useState<Array<Sort>>(initialSort);

  const createOnSort = React.useCallback(
    (areaFieldName: string) => (): void => {
      const index = R.findIndex(R.propEq('by', areaFieldName), sort);

      setSort(
        index === -1
          ? [{ by: areaFieldName, dir: 'asc' }]
          : R.over(R.lensIndex(index), R.assoc('dir', toggleSortDirection(sort[index].dir)), sort),
      );
    },
    [setSort, sort],
  );

  return {
    sort,
    createOnSort,
  };
};
