import React from 'react';
import { ListItemText, Theme, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: 40,
    marginBottom: 8,
    paddingLeft: theme.spacing(4),
  },
}));

interface BackNavItemProps {
  to: string;
}

export const BackNavItem: React.FC<BackNavItemProps> = ({ to }) => {
  const classes = useStyles();

  return (
    <ListItem component={Link} to={to} button className={classes.item}>
      <ListItemText>
        <Typography variant="button" color="textSecondary">
          ← Go Back
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
