import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { usePortfolioCompany, useNotification } from 'src/hooks';
import { IntegrationsList } from 'src/shared';
import { useQuery } from 'react-apollo';
import {
  COMPANY_INTEGRATIONS_QUERY,
  CompanyIntegrationsEntityQuery,
  CompanyIntegrationsEntityQueryVariables,
} from 'src/graphql';
import { t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 101,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
  cardItem: {
    padding: 32,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export const PortfolioCompaniesIntegrationsPage: React.FC = () => {
  const classes = useStyles();
  const notification = useNotification();

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const companyId = portfolioCompany?.company?.id as string;

  const {
    data: integrations,
    loading: integrationsLoading,
    refetch: refetchIntegrations,
  } = useQuery<CompanyIntegrationsEntityQuery, CompanyIntegrationsEntityQueryVariables>(
    COMPANY_INTEGRATIONS_QUERY,
    {
      variables: { companyId },
      onError: () => notification.error(t('integrations_load_error')),
      skip: portfolioCompanyLoading,
    },
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Integrations</Typography>
      </Grid>
      <Grid className={classes.content} justify="flex-start" item container>
        <IntegrationsList
          integrations={integrations}
          integrationsLoading={portfolioCompanyLoading || integrationsLoading}
          refetchIntegrations={refetchIntegrations}
          darkShadow
        />
      </Grid>
    </Grid>
  );
};
