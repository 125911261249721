import React from 'react';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import * as icons from '@material-ui/icons';

interface GetUserActionsProps {
  canEdit: boolean;
  canDelete: boolean;
  openTeamEditDialog: any;
  openDeleteRowDialog: any;
}

export const getUserActions = ({
  canDelete,
  canEdit,
  openDeleteRowDialog,
  openTeamEditDialog,
}: GetUserActionsProps): JSX.Element[] => {
  return [
    <MenuItem disabled={!canEdit} key="edit" onClick={openTeamEditDialog}>
      <ListItemIcon>
        <icons.Edit />
      </ListItemIcon>
      Edit
    </MenuItem>,
    <MenuItem disabled={!canDelete} key="delete" onClick={openDeleteRowDialog}>
      <ListItemIcon>
        <icons.Delete />
      </ListItemIcon>
      Delete
    </MenuItem>,
  ];
};
