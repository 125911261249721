export const AUTH_ERROR_MESSAGES: Record<string, string> = {
  user_exists: 'User with such Email already exist',
  invalid_password:
    'Password should be at least 8 characters in length. ' +
    'Contain at least 3 of the following 4 types of characters: ' +
    '(1) lower case letters (a-z), ' +
    '(2) upper case letters (A-Z), ' +
    '(3) numbers (i.e. 0-9), ' +
    '(4) special characters (e.g. !@#$%^&*)',
};

export const DEFAULT_AUTH_ERROR_MESSAGE =
  'Something went wrong. Authentication internal error. Please, contact the administrator to resolve it.';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
