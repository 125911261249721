import * as React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';

import { useUnderwritingContext } from 'src/hooks';
import { actionsContext } from 'src/providers/underwriting/ActionsProvider';

import { MetricCellMark } from 'src/components';

import {
  convertFromFormData,
  getDisplayedAdjustedValue,
  getFormattedMetricValue,
  isNegative,
} from 'src/utils';
import { getLocalChangedMetric, getMetricLocalValuesKey } from 'src/utils/localStorage/metrics';

import { Metric, MetricValue } from 'src/graphql';
import { Format } from 'src/constants';

import classes from './CellRenderer.module.css';
import { ChangedMetric, MetricPeriod, Source } from 'src/types';

export const CellRenderer = (
  metric: Metric | string,
  data: Record<string, Record<string, MetricValue>> | null,
  date: string,
  editable: boolean,
): React.ReactNode => {
  const cellRef = React.useRef<HTMLDivElement>(null);
  const [isNeedToSave, setIsNeedToSave] = React.useState(false);
  const { actions, openActions } = React.useContext(actionsContext);
  const { companiesWithChange, companyId, updateMetric } = useUnderwritingContext();

  if (typeof metric === 'string' || !metric.code || !data)
    return <div className={classes.emptyCell} data-empty-cell={true} />;

  const cellMetric = data[date][metric.code];

  const format = R.pathOr(Format.Count, ['metric', 'format'], cellMetric) as Format;
  const metricCode = R.pathOr<string>('', ['metric', 'code'], cellMetric);
  const source = R.pathOr(Source.Manual, ['source'], cellMetric);
  const metricPeriod = R.pathOr('', ['period'], cellMetric);

  const metricLocalValueKey = getMetricLocalValuesKey(metric.code, cellMetric.period as string);

  const changedMetric = getLocalChangedMetric(
    companiesWithChange,
    companyId,
    metricLocalValueKey,
    date,
    format,
  );

  const active = changedMetric?.active ?? cellMetric?.active ?? true;
  const hasLocalDataChanges = !R.isNil(changedMetric);

  const value =
    getDisplayedAdjustedValue(cellMetric?.adjustedValue as number, changedMetric) ??
    cellMetric?.value ??
    null;

  const isActiveCell = !R.isNil(actions.activeCell) && actions.activeCell?.id === cellMetric?.id;
  const hasAdjustedValue = !R.isNil(cellMetric?.adjustedValue) || Boolean(cellMetric?.comment);
  const formattedValue = !R.isNil(value) ? getFormattedMetricValue(value, format) : '–';

  const openPopover = (e: React.MouseEvent<HTMLTableCellElement>) => {
    e.preventDefault();
    openActions(cellMetric, date, metric)(e);
  };

  const saveCellValue = () => {
    if (cellRef?.current) {
      const cellText = cellRef.current.innerText.length > 0 ? cellRef.current?.innerText : '0';

      if (isNeedToSave) {
        const { adjustedValue: convertedAdjustedValue } = convertFromFormData(
          {
            adjustedValue: cellText,
          },
          format,
        );

        updateMetric({
          date,
          source,
          active,
          comment: null,
          companyId,
          metricCode: metricCode,
          period: metricPeriod as MetricPeriod,
          adjustedValue: convertedAdjustedValue,
        } as ChangedMetric);

        setIsNeedToSave(false);
      }
    }
  };

  const needToSave = () => {
    setIsNeedToSave(true);
  };

  return (
    <div
      ref={cellRef}
      className={clsx(classes.cell, {
        [classes.editable]: editable,
        [classes.activeCell]: isActiveCell,
        [classes.lessThanZero]: isNegative(value),
      })}
      onContextMenu={openPopover}
      onBlur={saveCellValue}
      onInput={needToSave}
    >
      <span
        data-content-cell={true}
        className={classes.unselectable}
        style={{ outline: 'none', display: 'block' }}
      >
        {formattedValue}
      </span>
      <MetricCellMark hasAdjustedValue={hasAdjustedValue} hasDataChanged={hasLocalDataChanges} />
    </div>
  );
};
