import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Checkbox, Typography, FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import { HelpOutline, Visibility, VisibilityOff, Forum } from '@material-ui/icons';

import { ProgressStepProps } from 'src/components';
import { ProgressStepHelperText } from './ProgressStepHelperText';
import { useClientRoles } from 'src/app-builder/providers';
import { ProgressStepCustomizePopover } from './ProgressStepCustomizePopover';
import { ProgressStepCommentsPopover } from './ProgressStepCommentsPopover';
import { AppRole } from 'src/types';
import { getCurrentUserRole } from 'src/utils';

const useStyles = makeStyles(() =>
  createStyles({
    checkboxWrapper: {
      margin: '0 0 23px 0',
    },
    status: {
      position: 'absolute',
      fontSize: 14,
      margin: '-6px 0 0 0',
      color: 'grey',
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltip: {
      marginLeft: 20,
      maxWidth: 500,
      fontSize: 16,
    },
    tooltipText: {
      fontSize: 14,
    },
  }),
);

interface ProgressStep {
  step: ProgressStepProps;
  upsertStepVisibility?: (step: ProgressStepProps) => void;
  onSelectStep: (step: ProgressStepProps) => void;
  upsertStepComments: (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => void;
  onChangeStep?: (step: ProgressStepProps, name: string, comment: string) => void;
}

export const ProgressStep: React.FC<ProgressStep> = ({
  step,
  onSelectStep,
  onChangeStep,
  upsertStepVisibility,
  upsertStepComments,
}) => {
  const classes = useStyles();

  const { currentRole } = useClientRoles();

  const [editStepAnchorEl, setEditStepAnchorEl] = React.useState<Element | null | undefined>(null);
  const [editCommentAnchorEl, setEditCommentAnchorEl] = React.useState<Element | null | undefined>(
    null,
  );

  const { isBigfootTeam } = getCurrentUserRole(currentRole as AppRole);

  const isChecked = !step.isVisible;

  const handleContextMenuOpen = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!isBigfootTeam) {
      return;
    }
    event.preventDefault();

    setEditStepAnchorEl(event.currentTarget);
  };

  const onChangeStepComments = (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => {
    upsertStepComments(step, bigfootTeamComment, applicantComment);
  };

  const onCommentPopoverOpen = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setEditCommentAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setEditStepAnchorEl(null);
  };

  const onCloseEditComment = () => {
    setEditCommentAnchorEl(null);
  };

  const onVisibilityChange = () => {
    if (upsertStepVisibility) {
      upsertStepVisibility(step);
    }
  };

  return (
    <div key={step.id} className={classes.checkboxWrapper}>
      {isBigfootTeam && (
        <Checkbox
          name={step.code}
          icon={<Visibility />}
          checkedIcon={<VisibilityOff />}
          checked={isChecked}
          onChange={onVisibilityChange}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            name={step.code}
            checked={step.active}
            onChange={() => onSelectStep(step)}
            disabled={step.disabled}
          />
        }
        label={
          <div>
            <div className={classes.labelContainer}>
              <Typography onContextMenu={handleContextMenuOpen}>{step.name}&nbsp;</Typography>
              <ProgressStepHelperText
                helperText={step.helperText}
                link="/settings/client-portal/integrations"
                textReplacedByLink="here"
              />
              <IconButton onClick={onCommentPopoverOpen}>
                <Forum />
              </IconButton>
              {Boolean(step.comment) && (
                <Tooltip
                  className={classes.tooltip}
                  title={<span className={classes.tooltipText}>{String(step.comment)}</span>}
                  placement="top"
                >
                  <HelpOutline />
                </Tooltip>
              )}
            </div>
            {step.status && (
              <Typography className={classes.status} variant="caption" display="block">
                {step.status}
              </Typography>
            )}
          </div>
        }
      />
      {isBigfootTeam && (
        <ProgressStepCustomizePopover
          step={step}
          open={Boolean(editStepAnchorEl)}
          handleContextMenuClose={handleClose}
          onChangeStep={onChangeStep}
          anchorEl={editStepAnchorEl}
        />
      )}
      <ProgressStepCommentsPopover
        step={step}
        open={Boolean(editCommentAnchorEl)}
        isBigfootTeam={isBigfootTeam}
        handleContextMenuClose={onCloseEditComment}
        onChangeCommentStep={onChangeStepComments}
        anchorEl={editCommentAnchorEl}
      />
    </div>
  );
};
