import React from 'react';

interface MetricCellProps {
  value: string | number;
}

export const MetricHeaderCell: React.FC<MetricCellProps> = ({ value }) => {
  return (
    <React.Fragment>
      <div>{value}</div>
    </React.Fragment>
  );
};
