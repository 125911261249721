import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { EventType, FACILITY_DELETE_MUTATION, EVENT_RESOLVER_MUTATION } from 'src/graphql';
import { DIALOGS } from './constants';
import { t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface FacilityDeleteDialogProps {}

export const FacilityDeleteDialog: React.FC<FacilityDeleteDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal } = useModal(DIALOGS.FACILITY_DELETE_DIALOG);

  const [deleteFacility, { loading: deleting }] = useMutation(FACILITY_DELETE_MUTATION);

  const [portfolioCompanyUpdateActive, { loading: updatingActive }] = useMutation(
    EVENT_RESOLVER_MUTATION,
    {
      refetchQueries: [
        'FacilitiesTableContent',
        'PortfolioMonitoringCompaniesList',
        'MetricsCompanyHeader',
        'PortfolioCompaniesEntity',
        'SortablePortfolioCompaniesList',
      ],
      awaitRefetchQueries: true,
    },
  );

  const onDelete = React.useCallback(async () => {
    try {
      await deleteFacility({
        variables: {
          filter: {
            id: args?.facilityId,
          },
          force: true,
        },
      });

      await portfolioCompanyUpdateActive({
        variables: {
          event: {
            type: EventType.PortfolioCompanyUpdateActive,
            payload: {
              companyId: args?.companyId,
            },
          },
        },
      });

      notification.success(t('facility_delete_success'));
      closeModal();
    } catch (error) {
      console.error(error);
      notification.error(t('facility_delete_error'));
    }
  }, [closeModal, args, deleteFacility, portfolioCompanyUpdateActive, notification]);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ className: classes.root }}>
      <DialogTitle>Delete Facility</DialogTitle>

      <DialogContent>
        <DialogContentText>Are you sure want to delete this facility?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          onClick={onDelete}
          loading={deleting || updatingActive}
        >
          Delete Facility
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
