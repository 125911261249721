import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import {
  useModal,
  Form,
  Field,
  TextField,
  DateField,
  NumberField,
  SelectField,
} from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { EventType, FACILITY_CREATE_MUTATION, EVENT_RESOLVER_MUTATION } from 'src/graphql';
import { DIALOGS } from './constants';
import { STRUCTURE_OPTIONS, CLOSING_FEE_OPTIONS } from 'src/constants';
import { baseNumberFormat, DateFormatPatterns, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface FacilityCreateDialogProps {}

export const FacilityCreateDialog: React.FC<FacilityCreateDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal, openModal } = useModal(DIALOGS.FACILITY_CREATE_DIALOG);

  const [createFacility] = useMutation(FACILITY_CREATE_MUTATION);

  const [portfolioCompanyUpdateActive] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: [
      'FacilitiesTableContent',
      'PortfolioMonitoringCompaniesList',
      'MetricsCompanyHeader',
      'PortfolioCompaniesEntity',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      try {
        await createFacility({
          variables: {
            data: {
              ...data,
              company: { connect: { id: args?.companyId } },
            },
          },
        });

        await portfolioCompanyUpdateActive({
          variables: {
            event: {
              type: EventType.PortfolioCompanyUpdateActive,
              payload: {
                companyId: args?.companyId,
              },
            },
          },
        });

        notification.success(t('facility_create_success'));

        closeModal();
        setTimeout(form.reset);
      } catch (error) {
        console.error(error);
        notification.error(t('facility_create_error'));
      }
    },
    [closeModal, createFacility, portfolioCompanyUpdateActive, notification, args],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  if (!open) {
    return null;
  }

  return (
    <Form tableSchemaName="Facilities" type="CREATE" onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Add Facility</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Field
                  name="structure"
                  label="Structure"
                  component={SelectField}
                  fullWidth
                  options={STRUCTURE_OPTIONS}
                  disabled={submitting}
                />
              </Grid>
              <Grid xs={12} item>
                <Field
                  name="amountCommitted"
                  label="Committed"
                  component={NumberField}
                  fullWidth
                  disabled={submitting}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  numberFormat={baseNumberFormat}
                />
              </Grid>
              <Grid xs={12} item>
                <Field
                  name="totalAmountDeployed"
                  label="Total Deployed"
                  component={NumberField}
                  fullWidth
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  numberFormat={baseNumberFormat}
                />
              </Grid>
              <Grid xs={6} item>
                <Field
                  name="advanceRate"
                  label="Advance Rate"
                  component={NumberField}
                  fullWidth
                  disabled={submitting}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">x</InputAdornment>,
                  }}
                  numberFormat={baseNumberFormat}
                />
              </Grid>
              <Grid xs={6} item>
                <Field
                  name="finalAdvanceRate"
                  label="Final Advance Date"
                  component={DateField}
                  dateFormat={DateFormatPatterns.shortDateWithSlash}
                  fullWidth
                  disabled={submitting}
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  name="closingFee"
                  label="Closing Fee Type"
                  component={SelectField}
                  fullWidth
                  disabled={submitting}
                  options={CLOSING_FEE_OPTIONS}
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  name="closingDate"
                  label="Closing Date"
                  component={DateField}
                  dateFormat={DateFormatPatterns.shortDateWithSlash}
                  fullWidth
                  disabled={submitting}
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  name="closingFeeValue"
                  label="Closing Fee Value"
                  component={NumberField}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  fullWidth
                  disabled={submitting || !values.closingFee}
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  name="nextAvailableDraw"
                  label="Next Available Draw"
                  component={DateField}
                  dateFormat={DateFormatPatterns.shortDateWithSlash}
                  fullWidth
                  disabled={submitting}
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  name="successFee"
                  label="Success Fee"
                  component={NumberField}
                  fullWidth
                  disabled={submitting}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  numberFormat={baseNumberFormat}
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  name="validityGuarantee"
                  label="Validity Guarantee"
                  component={TextField}
                  fullWidth
                  disabled={submitting}
                />
              </Grid>

              <Grid xs={12} item>
                <Field
                  name="comments"
                  label="Comments"
                  component={TextField}
                  fullWidth
                  disabled={submitting}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={() => onClose(form, pristine)} disabled={submitting}>
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Add Facility
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
