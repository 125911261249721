import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { ColumnsType } from 'rc-table/es/interface';

import { useSearch, usePagination } from 'src/hooks';
import {
  CovenantTrackingPortfolioQuery,
  CovenantTrackingPortfolioQueryVariables,
  COVENANT_TRACKING_PORTFOLIO_QUERY,
  PortfolioCompany,
  CalculatePortfolioMonitoringQuery,
  CalculatePortfolioMonitoringQueryVariables,
  PORTFOLIO_MONITORING_VALUES,
  MonitoringCompany,
} from 'src/graphql';
import { getColumns } from 'src/routes/covenant-tracking/components/getColumns';
import { CovenantTrackingTableRow, MetricPeriod } from 'src/types';
import {
  isDateGreaterThan,
  getCovenantTrackingQueryVariables,
  getCovenantTrackingRows,
} from 'src/utils';

interface UseCovenantTrackingPortfolioOutput {
  hasData: boolean;
  loading: boolean;
  rows: Array<CovenantTrackingTableRow>;
  paginatedRows: Array<CovenantTrackingTableRow>;
  columns: ColumnsType<Record<string, any>>;
  count: number;
}

const SINGLE_COMPANY_ROW_COUNT = 5;

export const useCovenantTrackingPortfolio = (
  selectedDate: string,
  maxDate: string,
): UseCovenantTrackingPortfolioOutput => {
  const search = useSearch();
  const pagination = usePagination();

  const isInvalidDateSelected = isDateGreaterThan(selectedDate, maxDate);

  const variables = React.useMemo(() => getCovenantTrackingQueryVariables(search, selectedDate), [
    search,
    selectedDate,
  ]);

  const { data, loading: companiesLoading } = useQuery<
    CovenantTrackingPortfolioQuery,
    CovenantTrackingPortfolioQueryVariables
  >(COVENANT_TRACKING_PORTFOLIO_QUERY, { variables, skip: isInvalidDateSelected });

  const count = data?.portfolioCompaniesList?.count ?? 0;

  const portfolioCompaniesList = React.useMemo(() => data?.portfolioCompaniesList?.items ?? [], [
    data,
  ]) as PortfolioCompany[];

  const portfolioCompanyIds = React.useMemo(
    () => portfolioCompaniesList.map(pc => pc?.id).filter(id => !R.isNil(id)) as string[],
    [portfolioCompaniesList],
  );

  const { data: portfolioMonitoring, loading: portfolioMonitoringLoading } = useQuery<
    CalculatePortfolioMonitoringQuery,
    CalculatePortfolioMonitoringQueryVariables
  >(PORTFOLIO_MONITORING_VALUES, {
    variables: {
      companiesList: portfolioCompanyIds,
      dates: [selectedDate],
      period: MetricPeriod.Month,
    },
    skip: !portfolioCompanyIds.length || isInvalidDateSelected,
  });

  const monitoringData = React.useMemo(
    () =>
      (portfolioMonitoring?.calculatePortfolioMonitoring?.monitoringData ?? []) as Array<
        MonitoringCompany
      >,
    [portfolioMonitoring],
  );

  const rows = React.useMemo(
    () => getCovenantTrackingRows(portfolioCompaniesList, monitoringData, selectedDate),
    [monitoringData, portfolioCompaniesList, selectedDate],
  );

  const paginatedRows = React.useMemo(() => {
    const from = pagination.page * pagination.perPage * SINGLE_COMPANY_ROW_COUNT;
    const to = (pagination.page + 1) * pagination.perPage * SINGLE_COMPANY_ROW_COUNT;

    if (rows.length < from) {
      pagination.setPage(0);
      return rows.slice(0, pagination.perPage * SINGLE_COMPANY_ROW_COUNT);
    }

    return rows.slice(from, to);
  }, [pagination, rows]);

  const columns = React.useMemo(() => getColumns(), []);

  const hasData = Boolean(data && portfolioMonitoring);
  const loading = companiesLoading || portfolioMonitoringLoading;

  return {
    hasData,
    loading,
    rows,
    paginatedRows,
    columns,
    count,
  };
};
