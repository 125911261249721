import * as R from 'ramda';
import _ from 'lodash';

import {
  Company,
  CustomCovenantValue,
  MetricValue,
  MonitoringCompany,
  PortfolioCompany,
} from 'src/graphql';
import {
  COVENANT_TRACKING_COL_TO_ROW_KEY,
  COVENANT_TRACKING_PORTFOLIO_COLUMNS_ORDER,
} from 'src/constants';
import {
  CovenantTrackingMetricsByPeriod,
  MetricPeriod,
  CovenantTrackingRowType,
  CovenantTrackingTableRow,
  CovenantTrackingRowIndex,
  CovenantName,
  CovenantValuesMap,
  CustomCovenantActualValuesMap,
  CalculationCtx,
} from 'src/types';
import { getAdjustedOrOriginalMetricValue, isDateEquals, isFunction } from 'src/utils';
import { calculateCells } from './';

const getCovenantValues = (company: Company): CovenantValuesMap => {
  const covenantValuesMap = {} as CovenantValuesMap;
  const covenantsFromCompany = company?.covenantValue?.items ?? [];

  covenantsFromCompany.forEach(cov => {
    const covenantName = cov?.covenant?.name as CovenantName;

    _.set(covenantValuesMap, [covenantName], {
      value: cov?.value ?? null,
      isEnabled: cov?.isEnabled ?? false,
    });
  });

  return covenantValuesMap;
};

const getMetricsByPeriod = (metricValues: Array<MetricValue>): CovenantTrackingMetricsByPeriod => {
  const metricsByPeriod = {
    [MetricPeriod.Month]: {},
    [MetricPeriod.Week]: {},
  } as CovenantTrackingMetricsByPeriod;

  metricValues.forEach(metricValue => {
    const code = metricValue?.metric?.code ?? '';
    const period = metricValue?.period ?? null;

    switch (period) {
      case MetricPeriod.Month: {
        metricsByPeriod[MetricPeriod.Month][code] = getAdjustedOrOriginalMetricValue(metricValue);
        break;
      }
      case MetricPeriod.Week: {
        const existingValue = metricsByPeriod[MetricPeriod.Week][code];
        const incomingValue = getAdjustedOrOriginalMetricValue(metricValue);

        if (!R.isNil(existingValue) && !R.isNil(incomingValue)) {
          metricsByPeriod[MetricPeriod.Week][code] = Math.min(existingValue, incomingValue);
        } else {
          metricsByPeriod[MetricPeriod.Week][code] = !R.isNil(incomingValue)
            ? incomingValue
            : existingValue;
        }
      }
    }
  });

  return metricsByPeriod;
};

const getTotalDeployedMap = (
  monitoringData: Array<MonitoringCompany>,
  date: string,
): Record<string, number | null> => {
  const totalDeployedMap = {} as Record<string, number | null>;

  monitoringData?.forEach(company => {
    const companyId = company?.companyId ?? '';
    const firstDeployed = company?.monitoringValues?.[0];
    const totalDeployed =
      !R.isNil(firstDeployed) && isDateEquals(firstDeployed.date, date)
        ? firstDeployed.deployed ?? null
        : 0;

    totalDeployedMap[companyId] = totalDeployed;
  });

  return totalDeployedMap;
};

const getCustomCovenantsActualValues = (
  customCovenantsValues: CustomCovenantValue[] = [],
): CustomCovenantActualValuesMap => {
  const customCovenantActualValuesMap = {} as CustomCovenantActualValuesMap;

  customCovenantsValues.forEach(customCovenantValue => {
    const covenantName = customCovenantValue?.covenant?.name as CovenantName;
    const date = customCovenantValue?.date;
    const actualValue = customCovenantValue?.actual;
    const difference = customCovenantValue?.difference;
    const inCompliance = customCovenantValue?.inCompliance;

    _.set(customCovenantActualValuesMap, [covenantName], {
      date,
      covenantName,
      actualValue,
      difference,
      inCompliance,
    });
  });

  return customCovenantActualValuesMap;
};

const getCovenantTrackingRowGroup = (ctx: CalculationCtx): Array<CovenantTrackingTableRow> => {
  const id = ctx.portfolioCompanyId;

  const rows = [
    {
      id,
      rowType: CovenantTrackingRowType.CompanyName,
    },
    {
      id: `${id}-${CovenantTrackingRowIndex.Covenant}`,
      rowType: CovenantTrackingRowType.Covenant,
    },
    {
      id: `${id}-${CovenantTrackingRowIndex.Actual}`,
      rowType: CovenantTrackingRowType.Actual,
    },
    {
      id: `${id}-${CovenantTrackingRowIndex.Difference}`,
      rowType: CovenantTrackingRowType.Difference,
    },
    {
      id: `${id}-${CovenantTrackingRowIndex.InCompliance}`,
      rowType: CovenantTrackingRowType.InCompliance,
    },
  ] as Array<CovenantTrackingTableRow>;

  COVENANT_TRACKING_PORTFOLIO_COLUMNS_ORDER.forEach(colName => {
    const key = COVENANT_TRACKING_COL_TO_ROW_KEY[colName];

    const calcFn = calculateCells[key];

    const cellGroup = isFunction(calcFn) ? calcFn(ctx) : [null, null, null, null, null];

    _.set(
      rows,
      [CovenantTrackingRowIndex.CompanyName, key],
      cellGroup[CovenantTrackingRowIndex.CompanyName],
    );

    _.set(
      rows,
      [CovenantTrackingRowIndex.Covenant, key],
      cellGroup[CovenantTrackingRowIndex.Covenant],
    );

    _.set(rows, [CovenantTrackingRowIndex.Actual, key], cellGroup[CovenantTrackingRowIndex.Actual]);

    _.set(
      rows,
      [CovenantTrackingRowIndex.Difference, key],
      cellGroup[CovenantTrackingRowIndex.Difference],
    );

    _.set(
      rows,
      [CovenantTrackingRowIndex.InCompliance, key],
      cellGroup[CovenantTrackingRowIndex.InCompliance],
    );
  });

  return rows;
};

export const getCovenantTrackingRows = (
  portfolioCompanies: Array<PortfolioCompany>,
  monitoringData: Array<MonitoringCompany>,
  date: string,
): Array<CovenantTrackingTableRow> => {
  const totalDeployedMap = getTotalDeployedMap(monitoringData, date);

  const tableRows = portfolioCompanies
    .map(pc => {
      const portfolioCompanyId = pc?.id;
      const company = pc?.company;
      const companyId = company?.id;
      const metrics = getMetricsByPeriod(company?.metrics?.items ?? []);
      const covenants = getCovenantValues(company as Company);
      const customCovenantValues = getCustomCovenantsActualValues(
        company?.customCovenant?.items ?? [],
      );

      if (company && companyId && portfolioCompanyId) {
        const calculationCtx: CalculationCtx = {
          portfolioCompanyId,
          company,
          totalDeployed: totalDeployedMap[companyId],
          metrics,
          covenants,
          customCovenantValues,
        };

        return getCovenantTrackingRowGroup(calculationCtx);
      }

      return null;
    })
    .filter(r => !R.isNil(r));

  return R.flatten(tableRows) as Array<CovenantTrackingTableRow>;
};
