import React from 'react';
import { useMutation } from 'react-apollo';
import pluralize from 'pluralize';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
} from '@material-ui/core';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { EventType, EVENT_RESOLVER_MUTATION } from 'src/graphql';
import { DIALOGS } from './constants';
import { commonErrorResolver, isFunction, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface UserDeleteDialogProps {
  isClientPortal?: boolean;
}

export const UserDeleteDialog: React.FC<UserDeleteDialogProps> = ({ isClientPortal }) => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal } = useModal(DIALOGS.USER_DELETE_DIALOG);

  const usersCount = Array.isArray(args?.id) ? args?.id?.length : 1;
  const formattedUsers = pluralize('Users', usersCount);

  const [eventResolver, { loading }] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['UsersTableContent'],
    awaitRefetchQueries: true,
    onError: error => notification.error(commonErrorResolver(error)),
    onCompleted: () => {
      notification.success(
        t('user_delete_success', {
          formattedUsers,
        }),
      );

      if (isFunction(args?.onSuccess)) {
        args.onSuccess();
      }

      closeModal();
    },
  });

  const onDelete = React.useCallback(async () => {
    if (args?.id) {
      if (Array.isArray(args.id)) {
        await eventResolver({
          variables: {
            event: {
              type: isClientPortal
                ? EventType.CompanyTeammateDelete
                : EventType.BigfootEmployeesDelete,
              payload: {
                userIdsList: args.id,
              },
            },
          },
        });
      } else {
        await eventResolver({
          variables: {
            event: {
              type: isClientPortal
                ? EventType.CompanyTeammateDelete
                : EventType.BigfootEmployeesDelete,
              payload: {
                userIdsList: [args.id],
              },
            },
          },
        });
      }
    }
  }, [args, eventResolver, isClientPortal]);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ className: classes.root }}>
      <DialogTitle>Delete {formattedUsers}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure want to delete this
          {formattedUsers.toLowerCase()}?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          onClick={onDelete}
          loading={loading}
        >
          Delete {formattedUsers}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
