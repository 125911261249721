import * as R from 'ramda';

import {
  CellCalculator,
  CellGroup,
  CovenantName,
  CovenantTrackingRowKey,
  CovenantTrackingRowType,
  CovenantValue,
  MetricCode,
  MetricPeriod,
} from 'src/types';
import { COMPLIANCE_YES, COVENANT_OFF_MESSAGE } from 'src/constants';
import { subtractNumbers, divideNumbers, convertFromPercentage } from 'src/utils';
import { calculateInCompliance } from '../covenants';

const COVENANT_OFF_COL = [null, COVENANT_OFF_MESSAGE, null, null, null] as CellGroup;

const calculateName: CellCalculator = ctx => {
  const companyName = ctx.company?.name ?? '';

  return [
    companyName,
    CovenantTrackingRowType.Covenant,
    CovenantTrackingRowType.Actual,
    CovenantTrackingRowType.Difference,
    CovenantTrackingRowType.InCompliance,
  ];
};

const calculateAdjustedNetCashBurnL3M: CellCalculator = ctx => {
  const metricValue = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.AdjustedNetCashBurnL3M],
    ctx.metrics,
  );

  return [null, null, metricValue, null, null];
};

const calculateMinCashPositionMonthly: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.MinCashPosition],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? Number(value) : null;

  const metricValue = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.CashPositionPlaid],
    ctx.metrics,
  );

  const difference = subtractNumbers(metricValue, covenantValue);

  const inCompliance = calculateInCompliance(metricValue, covenantValue);

  return [null, covenantValue, metricValue, difference, inCompliance];
};

const calculateMinCashRunwayMonthly: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.MinCashRunway],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? Number(value) : null;

  const adjustedNetCashBurnL3M = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.AdjustedNetCashBurnL3M],
    ctx.metrics,
  );

  const cashPosition = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.CashPositionPlaid],
    ctx.metrics,
  );
  const cashPositionNegated = !R.isNil(cashPosition) ? -cashPosition : cashPosition;

  const metricValue = divideNumbers(cashPositionNegated, adjustedNetCashBurnL3M, 0);

  const difference = subtractNumbers(metricValue, covenantValue);

  let inCompliance = null;

  if (!R.isNil(metricValue) && !R.isNil(covenantValue)) {
    inCompliance = R.gte(metricValue, covenantValue) || metricValue < 0;
  }

  return [null, covenantValue, metricValue, difference, inCompliance];
};

const calculateMinCashPositionWeekly: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.MinCashPosition],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? Number(value) : null;

  const metricValue = R.pathOr<number | null>(
    null,
    [MetricPeriod.Week, MetricCode.CashPositionPlaid],
    ctx.metrics,
  );

  const difference = subtractNumbers(metricValue, covenantValue);

  const inCompliance = calculateInCompliance(metricValue, covenantValue);

  return [null, covenantValue, metricValue, difference, inCompliance];
};

const calculateMinCashRunwayWeekly: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.MinCashRunway],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? Number(value) : null;

  const adjustedNetCashBurnL3M = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.AdjustedNetCashBurnL3M],
    ctx.metrics,
  );

  const cashPosition = R.pathOr<number | null>(
    null,
    [MetricPeriod.Week, MetricCode.CashPositionPlaid],
    ctx.metrics,
  );
  const cashPositionNegated = !R.isNil(cashPosition) ? -cashPosition : cashPosition;

  const metricValue = divideNumbers(cashPositionNegated, adjustedNetCashBurnL3M, 0);

  const difference = subtractNumbers(metricValue, covenantValue);

  let inCompliance = null;

  if (!R.isNil(metricValue) && !R.isNil(covenantValue)) {
    inCompliance = R.gte(metricValue, covenantValue) || metricValue < 0;
  }

  return [null, covenantValue, metricValue, difference, inCompliance];
};

const calculateCumulativeCashReceipts: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.CumulativeCashReceipts],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? Number(value) : null;

  const metricValue = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.CashInflows],
    ctx.metrics,
  );

  const difference = subtractNumbers(metricValue, covenantValue);

  const inCompliance = calculateInCompliance(metricValue, covenantValue);

  return [null, covenantValue, metricValue, difference, inCompliance];
};

const calculateMinCashOfDrawsTaken: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.MinCashAsPercentOfDrawsTaken],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? convertFromPercentage(value) : null;

  const cashPosition = R.pathOr<number | null>(
    null,
    [MetricPeriod.Month, MetricCode.CashPositionPlaid],
    ctx.metrics,
  );
  const metricValue = divideNumbers(cashPosition, ctx.totalDeployed, 3);

  const difference = subtractNumbers(metricValue, covenantValue, 3);

  const inCompliance = calculateInCompliance(metricValue, covenantValue);

  return [null, covenantValue, metricValue, difference, inCompliance];
};

const calculateCumulativeAdvanceRate: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.CumulativeAdvanceRate],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? Number(value) : null;

  const cumulativeAdvanceRateValue =
    ctx?.customCovenantValues?.[CovenantName.CumulativeAdvanceRate];

  const actualValue = !R.isNil(cumulativeAdvanceRateValue?.actualValue)
    ? Number.parseFloat(cumulativeAdvanceRateValue?.actualValue as string)
    : null;

  const difference = subtractNumbers(covenantValue, actualValue);

  const inCompliance = calculateInCompliance(covenantValue, actualValue);

  return [null, covenantValue, actualValue, difference, inCompliance];
};

const calculateActualVSExpectedRevenue: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.ActualVsExpectedRevenue],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const value = covenant?.value;
  const covenantValue = !R.isNil(value) ? convertFromPercentage(value) : null;

  const actualVSExpectedRevenueValue =
    ctx?.customCovenantValues?.[CovenantName.ActualVsExpectedRevenue];

  const actualValue = !R.isNil(actualVSExpectedRevenueValue?.actualValue)
    ? convertFromPercentage(actualVSExpectedRevenueValue?.actualValue as string)
    : null;

  const difference = subtractNumbers(actualValue, covenantValue, 3);

  const inCompliance = calculateInCompliance(actualValue, covenantValue);

  return [null, covenantValue, actualValue, difference, inCompliance];
};

const calculateAdditionalCovenant: CellCalculator = ctx => {
  const covenant = R.pathOr<CovenantValue | null>(
    null,
    [CovenantName.AdditionalCovenant],
    ctx.covenants,
  );

  if (!covenant?.isEnabled) {
    return COVENANT_OFF_COL;
  }

  const covenantValue = covenant?.value ?? null;

  const additionalCovenantValue = ctx?.customCovenantValues?.[CovenantName.AdditionalCovenant];

  const actualValue = additionalCovenantValue?.actualValue ?? null;

  const difference = additionalCovenantValue?.difference ?? null;

  const inComplianceText = additionalCovenantValue?.inCompliance;
  const inCompliance = !R.isNil(inComplianceText) ? inComplianceText === COMPLIANCE_YES : null;

  return [null, covenantValue, actualValue, difference, inCompliance];
};

export const calculateCells: Partial<Record<CovenantTrackingRowKey, CellCalculator>> = {
  [CovenantTrackingRowKey.Name]: calculateName,
  [CovenantTrackingRowKey.AdjustedNetCashBurnL3M]: calculateAdjustedNetCashBurnL3M,
  [CovenantTrackingRowKey.MinCashPositionMonthly]: calculateMinCashPositionMonthly,
  [CovenantTrackingRowKey.MinCashRunwayMonthly]: calculateMinCashRunwayMonthly,
  [CovenantTrackingRowKey.MinCashPositionWeekly]: calculateMinCashPositionWeekly,
  [CovenantTrackingRowKey.MinCashRunwayWeekly]: calculateMinCashRunwayWeekly,
  [CovenantTrackingRowKey.CumulativeCashReceipts]: calculateCumulativeCashReceipts,
  [CovenantTrackingRowKey.MinCashOfDrawsTaken]: calculateMinCashOfDrawsTaken,
  [CovenantTrackingRowKey.CumulativeAdvanceRate]: calculateCumulativeAdvanceRate,
  [CovenantTrackingRowKey.ActualVSExpectedRevenue]: calculateActualVSExpectedRevenue,
  [CovenantTrackingRowKey.AdditionalCovenant]: calculateAdditionalCovenant,
};
