import { DateTime } from 'luxon';
import { TableExportType } from 'src/graphql';
import { DateFormatPatterns } from 'src/utils/date';

interface DownloadXlsxInput {
  tableExport: {
    base64data: string;
  };
}

const convertStringToArrayBuffer = (str: string): ArrayBuffer => {
  const buffer = new ArrayBuffer(str.length);
  const view = new Uint8Array(buffer);

  // Filling data view with initial string chars converted to 8bit ints
  for (let i = 0; i < str.length; i++) {
    // & 0xff is used to make sure that char code from string does not exceed 8 bits length (255)
    view[i] = str.charCodeAt(i) & 0xff;
  }

  return buffer;
};

export const downloadXlsx = (
  data: DownloadXlsxInput,
  type: TableExportType,
): { success: boolean } => {
  const downLoadDate = DateTime.local().toFormat(DateFormatPatterns.fullDateWithTime);
  const base64 = data.tableExport.base64data;
  const fileName = `${TableExportType[type]}_${downLoadDate}`;

  const binaryStrFromBase64 = atob(base64);
  const buffer = convertStringToArrayBuffer(binaryStrFromBase64);
  const xlsxBlob = new Blob([buffer], {
    type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(xlsxBlob);
  downloadLink.download = `${fileName}.xlsx`;
  downloadLink.click();

  return { success: true };
};
