import React from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import { Box, Typography, Link } from '@material-ui/core';
import { useAuth } from 'src/app-builder';
import { AuthRoutes } from 'src/constants';

export const InvitationDeprecatedPage: React.FC = () => {
  const { isAuthorized } = useAuth();

  // if the user already logged in we redirect them to the root route
  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <Box
      display="flex"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography component="p">This reference had become outdated or never existed.</Typography>
      <Typography>
        Already have an account?{' '}
        <Link color="secondary" component={RouterLink} to={AuthRoutes.Login}>
          Login
        </Link>
      </Typography>
    </Box>
  );
};
