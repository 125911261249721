import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { DRAW_DELETE_MUTATION, RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION } from 'src/graphql';
import { DIALOGS } from './constants';
import { t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface DrawDeleteDialogProps {}

export const DrawDeleteDialog: React.FC<DrawDeleteDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal } = useModal(DIALOGS.DRAW_DELETE_DIALOG);

  const [recalculate] = useMutation(RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION, {
    refetchQueries: [
      'FacilitiesTableContent',
      'MetricsCompanyHeader',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
  });
  const [deleteDraw, { loading }] = useMutation(DRAW_DELETE_MUTATION);

  const onDelete = React.useCallback(async () => {
    try {
      await deleteDraw({
        variables: { filter: { id: args?.id }, force: true },
      });
      await recalculate({
        variables: {
          facilityId: args?.facilityId,
        },
      });

      notification.success(t('draw_delete_success'));
      closeModal();
    } catch (error) {
      console.error(error);
      notification.error(t('draw_delete_error'));
    }
  }, [closeModal, args, deleteDraw, notification, recalculate]);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ className: classes.root }}>
      <DialogTitle>Delete Draw</DialogTitle>

      <DialogContent>
        <DialogContentText>Are you sure want to delete this draw?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton
          color="secondary"
          variant="contained"
          type="submit"
          onClick={onDelete}
          loading={loading}
        >
          Delete Draw
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
