import { gql } from '8base-react-sdk';

const METRIC_FRAGMENT = gql`
  fragment MetricFragment on Metric {
    id
    code
    name
    format
    metadata
    saaSScoreWeight
    saaSScoreWeightEnterprise
    tierBands
    type
  }
`;

export const PORTFOLIO_OVERVIEW_QUERY = gql`
  query PortfolioOverview($date: String!, $first: Int, $skip: Int, $search: String) {
    getPortfolioOverviewReport(date: $date, first: $first, skip: $skip, search: $search) {
      count
      companies {
        companyId
        companyName
        portfolioCompanyId
        portfolioOverviewMetrics {
          closingDate
          fundingDate
          committed
          deployed
          advancedRate
          closingFee
          closingFeeValue
          exitFee
          iOPeriod
          iRR
          interestRate
          mOIC
          prepayment
          prepaymentMonths
          returnCap
          structure
          successFee
          term
        }
        metrics {
          id
          value
          adjustedValue
          date
          period
          active
          comment
          source
          tier
          metric {
            ...MetricFragment
          }
        }
      }
    }
  }
  ${METRIC_FRAGMENT}
`;
