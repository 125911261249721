import React from 'react';
import { ModalsContext } from 'src/app-builder';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DIALOGS } from 'src/dialogs';
import { MetricDeleteDialog } from 'src/dialogs/MetricDeleteDialog';

import { EditForm } from './EditForm';
import { Metric, MetricValue } from 'src/graphql';
import { ChangedMetric } from 'src/types';
import { CompaniesWithMetricChange } from 'src/types/localStorage';

type MetricCellEditFormProps = {
  cell: MetricValue | null;
  metric: Metric | null;
  companyId: string | undefined | null;
  date: string | null;
  onSubmit?: (metric: ChangedMetric) => void;
  onClose: () => void;
  companiesWithChange: CompaniesWithMetricChange | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(40),
    padding: theme.spacing(2),
    position: 'relative',
  },
}));

export const MetricCellEditForm: React.FC<MetricCellEditFormProps> = props => {
  const { cell, metric, companyId, date, onClose, companiesWithChange, onSubmit } = props;

  const classes = useStyles();

  const { openModal } = React.useContext(ModalsContext);

  const handleDelete = React.useCallback(() => {
    openModal(DIALOGS.METRIC_DELETE_DIALOG, {
      metric,
      cell,
      date,
      companyId,
      onSuccess: () => {
        onClose();
      },
    });
  }, [openModal, onClose, date, companyId, metric, cell]);

  return (
    <Paper elevation={1} className={classes.root}>
      <EditForm
        cell={cell}
        metric={metric}
        companyId={companyId}
        date={date}
        onSubmit={onSubmit}
        onClose={onClose}
        onDelete={handleDelete}
        companiesWithChange={companiesWithChange}
      />
      <MetricDeleteDialog />
    </Paper>
  );
};
