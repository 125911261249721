import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Grid, Theme, TextField as MUITextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, NumberField, TextField } from 'src/app-builder';
import { ResetAdjustmentButton } from 'src/components';
import { MetricValue, User as UserType } from 'src/graphql';

import { UserComment } from './UserComment';
import {
  countFormat,
  metricFormats,
  isNegative,
  subtractNumbers,
  getFormattedMetricValue,
  convertFromFormData,
} from 'src/utils';
import { Format } from 'src/constants';

interface BodyProps {
  format: Format;
  cell: MetricValue | null;
  values: {
    originalValue: number;
    adjustedValue: number;
    adjustmentValue: number;
    comment: string;
  };
  submitting: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  lessThanZero: {
    '& .MuiInputBase-input': {
      color: '#eb4336',
    },
  },
}));

export const Body: React.FC<BodyProps> = ({
  cell,
  format,
  values: { originalValue, adjustedValue },
  submitting,
  onClose,
}) => {
  const classes = useStyles();

  const numberFormat = R.pathOr(countFormat, [format], metricFormats);
  const user: UserType = R.pathOr({}, ['createdBy'], cell);

  const comment = R.pathOr('', ['comment'], cell);

  const adjustment = subtractNumbers(adjustedValue, originalValue);

  const {
    originalValue: convertedOriginalValue,
    adjustment: convertedAdjustmentValue,
  } = convertFromFormData({ originalValue, adjustment }, format);

  const hasAdjustedValue =
    (!R.isNil(cell?.adjustedValue) && !R.isNil(adjustedValue)) || !R.isNil(adjustedValue);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <MUITextField
          className={clsx({
            [classes.lessThanZero]: isNegative(originalValue),
          })}
          label="Original value"
          value={getFormattedMetricValue(convertedOriginalValue, format)}
          size="small"
          variant="outlined"
          disabled
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <MUITextField
          className={clsx({
            [classes.lessThanZero]: isNegative(adjustment),
          })}
          label="Adjustment"
          value={getFormattedMetricValue(convertedAdjustmentValue || 0, format)}
          defaultValue={0}
          size="small"
          variant="outlined"
          disabled
          fullWidth
        />
      </Grid>

      <Grid item>
        <Typography variant="subtitle1">Adjust value</Typography>
      </Grid>

      <Grid item container xs={12} direction="row">
        <Grid item xs={9}>
          <Field
            component={NumberField}
            name="adjustedValue"
            label="Adjusted value"
            size="small"
            disabled={submitting}
            numberFormat={numberFormat}
            fullWidth
          />
        </Grid>

        <Grid item container xs={3} justify="flex-end">
          <ResetAdjustmentButton
            submitting={submitting}
            hasAdjustedValue={hasAdjustedValue}
            onClose={onClose}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Field
          component={TextField}
          name="comment"
          label="Add comment"
          disabled={submitting}
          size="small"
          fullWidth
        />
      </Grid>

      <UserComment user={user} comment={comment} />
    </Grid>
  );
};
