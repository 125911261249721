import React from 'react';
import {
  FIELD_TYPE,
  FieldSchema,
  DATE_FORMATS,
  FILE_FORMATS,
  SWITCH_FORMATS,
  FieldType,
} from '@8base/utils';
import { Field } from '8base-react-sdk';

import {
  TextField,
  NumberField,
  DateField,
  DatetimeField,
  FileField,
  ImageField,
  SelectField,
  RadioGroupField,
} from '../forms';
import { EntitySelectField } from './EntitySelectField';

const SUPPORTED_FIELD_TYPES: FieldType[] = [
  FIELD_TYPE.TEXT,
  FIELD_TYPE.NUMBER,
  FIELD_TYPE.DATE,
  FIELD_TYPE.SWITCH,
  FIELD_TYPE.FILE,
  FIELD_TYPE.RELATION,
];

const SWITCH_VALUES = {
  ON_OFF: {
    On: true,
    Off: false,
  },
  YES_NO: {
    Yes: true,
    No: false,
  },
  TRUE_FALSE: {
    True: true,
    False: false,
  },
  ACTIVE_INACTIVE: {
    Active: true,
    Inactive: false,
  },
  HIGH_LOW: {
    High: true,
    Low: false,
  },
};

export const isSupportedField = (field: FieldSchema): boolean =>
  !field.isMeta && !field.isList && SUPPORTED_FIELD_TYPES.indexOf(field.fieldType) !== -1;

interface EntityFormFieldProps {
  field: FieldSchema;
}

export const EntityFormField: React.FC<EntityFormFieldProps> = ({ field, ...rest }) => {
  if (!isSupportedField(field)) {
    return null;
  }

  if (field.fieldType === FIELD_TYPE.TEXT) {
    return (
      <Field
        component={TextField}
        name={field.name}
        label={field.displayName}
        fullWidth
        multiline={field?.fieldTypeAttributes?.fieldSize > 255}
        {...rest}
      />
    );
  } else if (field.fieldType === FIELD_TYPE.RELATION) {
    return (
      <Field
        component={EntitySelectField}
        tableName={field?.relation?.refTable?.name}
        name={field.name}
        label={field.displayName}
        fullWidth
        {...rest}
      />
    );
  } else if (field.fieldType === FIELD_TYPE.NUMBER) {
    return (
      <Field
        component={NumberField}
        name={field.name}
        label={field.displayName}
        fullWidth
        {...rest}
      />
    );
  } else if (field.fieldType === FIELD_TYPE.DATE) {
    if (field.fieldTypeAttributes.format === DATE_FORMATS.DATETIME) {
      return (
        <Field
          component={DatetimeField}
          name={field.name}
          label={field.displayName}
          fullWidth
          {...rest}
        />
      );
    } else {
      return (
        <Field
          component={DateField}
          name={field.name}
          label={field.displayName}
          fullWidth
          {...rest}
        />
      );
    }
  } else if (field.fieldType === FIELD_TYPE.FILE) {
    if (field.fieldTypeAttributes.format === FILE_FORMATS.IMAGE) {
      return (
        <Field
          component={ImageField}
          name={field.name}
          label={field.displayName}
          fullWidth
          {...rest}
        />
      );
    } else {
      return (
        <Field
          component={FileField}
          name={field.name}
          label={field.displayName}
          fullWidth
          {...rest}
        />
      );
    }
  } else if (field.fieldType === FIELD_TYPE.SWITCH) {
    if (field.fieldTypeAttributes.format === SWITCH_FORMATS.CUSTOM) {
      const options = (field.fieldTypeAttributes?.listOptions || []).map((value: string) => ({
        value,
        label: value,
      }));

      return (
        <Field
          component={SelectField}
          name={field.name}
          label={field.displayName}
          options={options}
          fullWidth
          {...rest}
        />
      );
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      const labels = SWITCH_VALUES[field.fieldTypeAttributes?.format];

      const options = Object.keys(labels).map(label => ({ label, value: labels[label] }));

      return (
        <Field
          component={RadioGroupField}
          name={field.name}
          label={field.displayName}
          options={options}
          fullWidth
          {...rest}
        />
      );
    }
  }

  return null;
};
