import React from 'react';
import { Grid, IconButton, makeStyles, Popover, Typography } from '@material-ui/core';
import { Delete as DeleteIcon, ViewColumn as ViewColumnIcon } from '@material-ui/icons';
import { usePopover } from 'src/app-builder';
import { SelectedColumnsPopover } from 'src/shared/popovers/SelectedColumnsPopover/SelectedColumnsPopover';
import { SearchField } from 'src/components';

interface ToolBarProps {
  selected: number;
  setSearchValue: (v: string) => void;
  value: string;
  onDeleteButtonClick: () => void;
  canDelete: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 64,
    paddingRight: 8,
    paddingLeft: 16,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ToolBar: React.FC<ToolBarProps> = ({
  selected,
  setSearchValue,
  value,
  onDeleteButtonClick,
  canDelete,
}) => {
  const classes = useStyles();
  const selectedColumnsPopover = usePopover();

  return (
    <Grid className={classes.root} container alignItems="center" justify="space-between">
      <Grid>
        <Typography color="inherit" variant="subtitle1" component="div">
          {Boolean(selected) && `${selected} selected`}
        </Typography>
      </Grid>
      <Grid className={classes.right}>
        <SearchField
          onChange={e => setSearchValue(e.target.value)}
          value={value}
          placeholder="Search..."
          variant="outlined"
        />
        <IconButton onClick={selectedColumnsPopover.openPopover}>
          <ViewColumnIcon />
        </IconButton>
        <IconButton disabled={!canDelete || selected === 0} onClick={onDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
        <Popover
          disableRestoreFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={selectedColumnsPopover.closePopover}
          anchorEl={selectedColumnsPopover.el}
          open={selectedColumnsPopover.isOpen}
        >
          <SelectedColumnsPopover />
        </Popover>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
