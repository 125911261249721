import React from 'react';
import { Popover, PopoverActions } from '@material-ui/core';

import { useUnderwritingContext } from 'src/hooks';
import { MetricCellEditFormUnderwriting } from 'src/components';
import { ChangedMetric } from 'src/types';
import { Metric, MetricValue } from 'src/graphql';
import { delay } from 'src/utils';

type Actions = {
  el: HTMLElement | null;
  activeCell: MetricValue | null;
  activeDate: string | null;
  activeMetric: Metric | null;
};

interface ActionsContext {
  openActions: (
    cell: MetricValue | null,
    date: string | null,
    metric: Metric | null,
  ) => (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  actions: Actions;
  closeActions: () => void;
}

export const actionsContext = React.createContext({
  openActions: () => (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {},
  actions: {
    el: null,
    activeCell: null,
    activeDate: null,
    activeMetric: null,
  },
  closeActions: () => {},
} as ActionsContext);

interface ActionsProviderProps {
  children: React.ReactNode;
  companyId: string | null | undefined;
  afterUpdateMetric?: () => Promise<void>;
}

export const ActionsProvider: React.FC<ActionsProviderProps> = ({ companyId, children }) => {
  const { updateMetric, companiesWithChange } = useUnderwritingContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popoverAction, setPopoverAction] = React.useState<PopoverActions>();
  const [isOpen, toggleIsOpen] = React.useState<boolean>(false);
  const [actions, setActions] = React.useState<Actions>({
    el: null,
    activeCell: null,
    activeDate: null,
    activeMetric: null,
  });

  const openActions = (cell: MetricValue | null, date: string | null, metric: Metric | null) => (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  ): void => {
    setActions({
      el: event.currentTarget,
      activeCell: cell,
      activeDate: date,
      activeMetric: metric,
    });

    toggleIsOpen(true);
  };

  const closeActions = (): void => {
    toggleIsOpen(false);

    delay(300).then(() => {
      setActions({
        el: null,
        activeCell: null,
        activeDate: null,
        activeMetric: null,
      });
    });
  };

  const preventPopoverContextMenuOpen = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const onSubmit = (metric: ChangedMetric) => {
    updateMetric(metric);
  };

  return (
    <actionsContext.Provider
      value={{
        actions,
        openActions,
        closeActions,
      }}
    >
      {children}
      <Popover
        disableRestoreFocus
        open={isOpen}
        onClose={closeActions}
        anchorEl={actions.el}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        action={setPopoverAction as (instance: PopoverActions | null) => void}
        onContextMenu={preventPopoverContextMenuOpen}
      >
        <MetricCellEditFormUnderwriting
          companyId={companyId}
          cell={actions.activeCell}
          metric={actions.activeMetric}
          date={actions.activeDate}
          onClose={closeActions}
          onSubmit={onSubmit}
          companiesWithChange={companiesWithChange}
        />
      </Popover>
    </actionsContext.Provider>
  );
};
