import { CELL_TYPES, MetricCode, UnionTypesOfArray } from 'src/types';

type PortfolioOverviewMetricCodes = UnionTypesOfArray<typeof PORTFOLIO_OVERVIEW_METRICS>;

export const PORTFOLIO_OVERVIEW_METRICS = [
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.EndingMRR,
  MetricCode.MRRNetGrowthRate,
  MetricCode.GrossMargin,
  MetricCode.AdjustedNetCashBurn,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.SaaSScore,
] as const;

export enum PortfolioOverviewMetricKey {
  ClosingDate = 'closingDate',
  FundingDate = 'fundingDate',
  Committed = 'committed',
  Deployed = 'deployed',
  Structure = 'structure',
  Term = 'term',
  IOPeriod = 'iOPeriod',
  Prepayment = 'prepayment',
  PrepaymentMonths = 'prepaymentMonths',
  AdvancedRate = 'advancedRate',
  InterestRate = 'interestRate',
  ReturnCap = 'returnCap',
  ClosingFee = 'closingFee',
  ClosingFeeValue = 'closingFeeValue',
  SuccessFee = 'successFee',
  ExitFee = 'exitFee',
  IRR = 'iRR',
  MOIC = 'mOIC',
}

function getPortfolioOverviewMetricCodes(): Record<
  PortfolioOverviewMetricCodes,
  PortfolioOverviewMetricCodes
> {
  const portfolioOverviewMetricKeys = {} as Record<
    PortfolioOverviewMetricCodes,
    PortfolioOverviewMetricCodes
  >;

  PORTFOLIO_OVERVIEW_METRICS.forEach(metricCode => {
    portfolioOverviewMetricKeys[metricCode] = metricCode;
  });

  return portfolioOverviewMetricKeys;
}

export const PORTFOLIO_OVERVIEW_METRIC_CODES = getPortfolioOverviewMetricCodes();

export const PortfolioOverviewColumnKey = {
  CompanyName: 'companyName',
  ClosingDate: PortfolioOverviewMetricKey.ClosingDate,
  FundingDate: PortfolioOverviewMetricKey.FundingDate,
  Structure: PortfolioOverviewMetricKey.Structure,
  Committed: PortfolioOverviewMetricKey.Committed,
  Deployed: PortfolioOverviewMetricKey.Deployed,
  Term: PortfolioOverviewMetricKey.Term,
  IOPeriod: PortfolioOverviewMetricKey.IOPeriod,
  Prepayment: PortfolioOverviewMetricKey.Prepayment,
  AdvanceRate: PortfolioOverviewMetricKey.AdvancedRate,
  InterestRate: PortfolioOverviewMetricKey.InterestRate,
  ReturnCap: PortfolioOverviewMetricKey.ReturnCap,
  ClosingFee: PortfolioOverviewMetricKey.ClosingFee,
  SuccessFee: PortfolioOverviewMetricKey.SuccessFee,
  ExitFee: PortfolioOverviewMetricKey.ExitFee,
  IRR: PortfolioOverviewMetricKey.IRR,
  MOIC: PortfolioOverviewMetricKey.MOIC,
  ...PORTFOLIO_OVERVIEW_METRIC_CODES,
  Tier: 'tier',
};

export enum PortfolioOverviewColumnTitle {
  CompanyName = 'Company Name',
  ClosingDate = 'Closing Date',
  FundingDate = 'Funding Date',
  Structure = 'Structure',
  Committed = 'Committed',
  Deployed = 'Deployed',
  Term = 'Term',
  IOPeriod = 'IO Period',
  Prepayment = 'Prepayment',
  AdvanceRate = 'Advance Rate',
  InterestRate = 'Interest Rate',
  ReturnCap = 'Return Cap',
  ClosingFee = 'Closing Fee',
  SuccessFee = 'Success Fee',
  ExitFee = 'Exit Fee',
  IRR = 'IRR',
  MOIC = 'MOIC',
  Revenue = 'Revenue',
  RevenueGrows = 'Revenue Growth',
  MRR = 'MRR',
  MRRGrowth = 'MRR Growth',
  GrossMargin = 'Gross Margin',
  NetCashBurn = 'Net Cash Burn',
  CashBalance = 'Cash Balance',
  CashRunway = 'Cash Runway',
  Tier = 'Tier',
  Score = 'Score',
}

export const PortfolioOverviewTableColumn = {
  [PortfolioOverviewColumnKey.CompanyName]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.CompanyName,
      type: CELL_TYPES.text,
      key: PortfolioOverviewColumnKey.CompanyName,
      width: 30,
    },
  },
  [PortfolioOverviewColumnKey.ClosingDate]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.ClosingDate,
      type: CELL_TYPES.date,
      key: PortfolioOverviewColumnKey.ClosingDate,
      width: 20,
    },
  },
  [PortfolioOverviewColumnKey.FundingDate]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.FundingDate,
      type: CELL_TYPES.date,
      key: PortfolioOverviewColumnKey.FundingDate,
      width: 20,
    },
  },
  [PortfolioOverviewColumnKey.Committed]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Committed,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.Committed,
      width: 20,
    },
  },
  [PortfolioOverviewColumnKey.Deployed]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Deployed,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.Deployed,
      width: 20,
    },
  },
  [PortfolioOverviewColumnKey.Structure]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Structure,
      type: CELL_TYPES.text,
      key: PortfolioOverviewColumnKey.Structure,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.Term]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Term,
      type: CELL_TYPES.text,
      key: PortfolioOverviewColumnKey.Term,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.IOPeriod]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.IOPeriod,
      type: CELL_TYPES.text,
      key: PortfolioOverviewColumnKey.IOPeriod,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.Prepayment]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Prepayment,
      type: CELL_TYPES.text,
      key: PortfolioOverviewColumnKey.Prepayment,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.AdvanceRate]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.AdvanceRate,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.AdvanceRate,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.InterestRate]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.InterestRate,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.InterestRate,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.ReturnCap]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.ReturnCap,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.ReturnCap,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.ClosingFee]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.ClosingFee,
      type: CELL_TYPES.text,
      key: PortfolioOverviewColumnKey.ClosingFee,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.SuccessFee]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.SuccessFee,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.SuccessFee,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.ExitFee]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.ExitFee,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.ExitFee,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.IRR]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.IRR,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.IRR,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.MOIC]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.MOIC,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.MOIC,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.TOTAL_REVENUE]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Revenue,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.TOTAL_REVENUE,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.REVENUE_GROWTH_RATE_MOM]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.RevenueGrows,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.REVENUE_GROWTH_RATE_MOM,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.ENDING_MRR]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.MRR,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.ENDING_MRR,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.MRR_NET_GROWTH_RATE]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.MRRGrowth,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.MRR_NET_GROWTH_RATE,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.GROSS_MARGIN]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.GrossMargin,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.GROSS_MARGIN,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.ADJUSTED_NET_CASH_BURN]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.NetCashBurn,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.ADJUSTED_NET_CASH_BURN,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.CASH_RUNWAY_ACCOUNTING]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.CashRunway,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.CASH_RUNWAY_ACCOUNTING,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.CASH_POSITION]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.CashBalance,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.CASH_POSITION,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.Tier]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Tier,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.Tier,
      width: 40,
    },
  },
  [PortfolioOverviewColumnKey.SAAS_SCORE]: {
    exportData: {
      header: PortfolioOverviewColumnTitle.Score,
      type: CELL_TYPES.number,
      key: PortfolioOverviewColumnKey.SAAS_SCORE,
      width: 40,
    },
  },
};
