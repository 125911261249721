import React from 'react';
import * as R from 'ramda';

import { MetricArrow } from 'src/components';
import { MetricValue } from 'src/graphql';
import { ScoreArrowType } from 'src/types';

interface MetricCellSaaSProps {
  metricValue: MetricValue | undefined | null;
  arrowType: ScoreArrowType | null;
}

const MetricCellSaaS: React.FC<MetricCellSaaSProps> = React.memo(({ metricValue, arrowType }) => {
  if (R.isNil(metricValue)) {
    return <React.Fragment>-</React.Fragment>;
  }

  const value = metricValue?.value;
  const tier = metricValue?.tier || '-';
  const formattedValue = Number.isFinite(value) ? value?.toFixed(0) : '-';

  return (
    <b style={{ marginLeft: 8 }}>
      {formattedValue}/100 - TIER:{tier}
      &nbsp;
      <MetricArrow arrowType={arrowType} />
    </b>
  );
});

MetricCellSaaS.displayName = 'MetricCellSaaS';

export { MetricCellSaaS };
