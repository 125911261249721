import React from 'react';
import { Box } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { useAllowed } from 'src/app-builder';
import { Chips } from 'src/components';
import { MONITORING_STATUS_COLORS } from 'src/constants';
import { actionsContext } from './ActionsProvider';
import { Permission } from 'src/constants/permissions';

export const MonitoringStatusRenderer = (row: Record<any, any>): React.ReactNode => {
  const actions = React.useContext(actionsContext);
  const isAllowed = useAllowed();
  const canChangeMonitoringStatus = isAllowed(Permission.PortfolioCompaniesChangeMonitoringStatus);

  return (
    <Box
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      onClick={
        canChangeMonitoringStatus
          ? actions.openMonitoringPopover(row.id, row.monitoringStatus || [])
          : () => {}
      }
      style={{ cursor: 'pointer' }}
    >
      <Chips values={row.monitoringStatus || []} colors={MONITORING_STATUS_COLORS} />
      {canChangeMonitoringStatus && <icons.KeyboardArrowDown fontSize="small" />}
    </Box>
  );
};
