import React from 'react';
import { useQuery } from 'react-apollo';

import { Layout } from './Layout';
import { FacilityAccordion } from 'src/components';
import {
  FACILITIES_TABLE_QUERY,
  FacilitiesTableContentQuery,
  FacilitiesTableContentQueryVariables,
  SortOrder,
} from 'src/graphql';
import { useCurrentUser } from 'src/app-builder';
import { extractUserCompanyId } from 'src/utils';

export const Main: React.FC = () => {
  const { user, loading: userLoading } = useCurrentUser();

  const companyId = extractUserCompanyId(user);

  const { data: facilitiesData, loading: facilitiesLoading } = useQuery<
    FacilitiesTableContentQuery,
    FacilitiesTableContentQueryVariables
  >(FACILITIES_TABLE_QUERY, {
    variables: {
      sort: [{ createdAt: SortOrder.Desc }],
      filter: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
    skip: !companyId,
  });

  const facilities = React.useMemo(() => facilitiesData?.facilitiesList?.items || [], [
    facilitiesData,
  ]);

  const facilitiesEmpty = !facilitiesLoading && !userLoading && facilities.length === 0;

  return (
    <Layout
      loading={facilitiesLoading || userLoading}
      isEmpty={facilitiesEmpty}
      renderFacilities={() =>
        facilities.length > 0
          ? facilities.map((facility: any, index: number) => (
              <FacilityAccordion
                key={facility?.id}
                defaultExpanded={index === 0}
                facility={facility}
                index={facilities.length - index}
              />
            ))
          : null
      }
    />
  );
};
