import React from 'react';
import { useMutation, useQuery, QueryFunctionOptions } from 'react-apollo';
import * as R from 'ramda';

import { useNotification } from 'src/hooks/useNotification';
import {
  PROGRESS_STAGES_QUERY,
  PROGRESS_STEP_VALUE_CREATE_MUTATION,
  PROGRESS_STEP_VALUE_UPDATE_MUTATION,
  PROGRESS_COMPANY_STEP_CREATE_MUTATION,
  PROGRESS_COMPANY_STEP_UPDATE_MUTATION,
  ProgressStage,
} from 'src/graphql';
import { LoanApplicationStage, ProgressStepType } from 'src/constants';
import { ProgressStageName, ProgressStepStatus } from 'src/types';
import { ProgressStepProps } from 'src/components';
import {
  getProgressStepById,
  toggleProgressStepStatus,
  t,
  toggleVisibilityStatus,
} from 'src/utils';

type ProgressStagesResult = {
  data: Array<ProgressStage>;
  loading: boolean;
  upsertStepStatus: (step: ProgressStepProps) => void;
  upsertStepName: (step: ProgressStepProps, name?: string, code?: string, comment?: string) => void;
  upsertStepComments: (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => void;
  insertCompanyStep: (stageId: string, name: string, code: string, comment?: string) => void;
  upsertStepVisibility: (step: ProgressStepProps) => void;
  upsertStepLoading: boolean;
  upsertStepError: boolean;
};

const buildFilterByLoanStage = (loanApplicationStage: LoanApplicationStage) => {
  switch (loanApplicationStage) {
    case LoanApplicationStage.AccountCreated: {
      return {
        name: {
          equals: ProgressStageName.PreTermSheetItems,
        },
      };
    }
    case LoanApplicationStage.Underwriting: {
      return {
        name: {
          not_equals: ProgressStageName.Closing,
        },
      };
    }
    case LoanApplicationStage.Closing: {
      return {};
    }
    default: {
      return {
        name: {
          equals: ProgressStageName.PreTermSheetItems,
        },
      };
    }
  }
};

export const useProgressStages = (
  companyId: string,
  loanApplicationStage: LoanApplicationStage,
  queryOptions?: QueryFunctionOptions,
): ProgressStagesResult => {
  const notification = useNotification();

  const { data, loading, refetch: refetchProgressStages } = useQuery(PROGRESS_STAGES_QUERY, {
    variables: {
      progressStagesFilter: {
        ...buildFilterByLoanStage(loanApplicationStage),
      },
      progressStepValuesFilter: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
      progressCompanyStepFilter: {
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
    skip: !companyId,
    fetchPolicy: 'no-cache',
    ...queryOptions,
  });

  const progressStages = R.pathOr([], ['progressStagesList', 'items'], data);

  const [upsertStepLoading, setUpsertStepLoading] = React.useState<boolean>(false);
  const [upsertStepError, setUpsertStepError] = React.useState<boolean>(false);

  const [createStepValueMutation] = useMutation(PROGRESS_STEP_VALUE_CREATE_MUTATION, {
    onError: () => notification.error(t('progress_step_update_status_error')),
  });
  const [updateStepValueMutation] = useMutation(PROGRESS_STEP_VALUE_UPDATE_MUTATION, {
    onError: () => notification.error(t('progress_step_update_status_error')),
  });
  const [createCompanyStepMutation] = useMutation(PROGRESS_COMPANY_STEP_CREATE_MUTATION, {
    onError: () => notification.error(t('progress_company_step_create_error')),
  });
  const [updateCompanyStepMutation] = useMutation(PROGRESS_COMPANY_STEP_UPDATE_MUTATION, {
    onError: () => notification.error(t('progress_company_step_update_error')),
  });

  const createCompanyStep = React.useCallback(
    (stageId: string, name: string, code: string, comment?: string) => {
      return createCompanyStepMutation({
        variables: {
          data: {
            company: {
              connect: {
                id: companyId,
              },
            },
            stage: {
              connect: {
                id: stageId,
              },
            },
            name: name,
            code: code,
            comment: comment,
            status: ProgressStepStatus.Pending,
          },
        },
      });
    },
    [companyId, createCompanyStepMutation],
  );

  const createStepValueWithStatus = React.useCallback(
    (stepId: string, status?: ProgressStepStatus) => {
      return createStepValueMutation({
        variables: {
          data: {
            company: {
              connect: {
                id: companyId,
              },
            },
            step: {
              connect: {
                id: stepId,
              },
            },
            status: status || ProgressStepStatus.Active,
          },
        },
      });
    },
    [companyId, createStepValueMutation],
  );

  const createStepValueWithName = React.useCallback(
    (stepId: string, name?: string, comment?: string) => {
      return createStepValueMutation({
        variables: {
          data: {
            company: {
              connect: {
                id: companyId,
              },
            },
            step: {
              connect: {
                id: stepId,
              },
            },
            customStepName: name || null,
            customStepComment: comment || null,
          },
        },
      });
    },
    [companyId, createStepValueMutation],
  );

  const createStepValueWithComment = React.useCallback(
    (stepId: string, bigfootTeamComment?: string, applicantComment?: string) => {
      return createStepValueMutation({
        variables: {
          data: {
            company: {
              connect: {
                id: companyId,
              },
            },
            step: {
              connect: {
                id: stepId,
              },
            },
            bigfootTeamComment,
            applicantComment,
          },
        },
      });
    },
    [createStepValueMutation, companyId],
  );

  const updateStepValueStatus = React.useCallback(
    (stepValueId: string, status?: ProgressStepStatus) => {
      return updateStepValueMutation({
        variables: {
          data: {
            status: status || ProgressStepStatus.Active,
          },
          filter: {
            id: stepValueId,
          },
        },
      });
    },
    [updateStepValueMutation],
  );

  const updateStepValueVisibility = React.useCallback(
    (stepValueId: string, isVisible: boolean) => {
      return updateStepValueMutation({
        variables: {
          data: {
            isVisible: isVisible,
          },
          filter: {
            id: stepValueId,
          },
        },
      });
    },
    [updateStepValueMutation],
  );

  const createStepValueWithVisibility = React.useCallback(
    (stepId: string, isVisible: boolean) => {
      return createStepValueMutation({
        variables: {
          data: {
            company: {
              connect: {
                id: companyId,
              },
            },
            step: {
              connect: {
                id: stepId,
              },
            },
            isVisible: isVisible,
          },
        },
      });
    },
    [companyId, createStepValueMutation],
  );

  const updateStepValueName = React.useCallback(
    (stepValueId: string, name?: string, comment?: string) => {
      return updateStepValueMutation({
        variables: {
          data: {
            customStepName: name || null,
            customStepComment: comment || null,
          },
          filter: {
            id: stepValueId,
          },
        },
      });
    },
    [updateStepValueMutation],
  );

  const updateStepValueComment = React.useCallback(
    (stepValueId: string, bigfootTeamComment: string, applicantComment: string) => {
      return updateStepValueMutation({
        variables: {
          data: {
            bigfootTeamComment,
            applicantComment,
          },
          filter: {
            id: stepValueId,
          },
        },
      });
    },
    [updateStepValueMutation],
  );

  const updateCompanyStep = React.useCallback(
    (stepId: string, name: string, code: string, comment: string) => {
      return updateCompanyStepMutation({
        variables: {
          data: {
            name: name,
            code: code,
            comment: comment,
          },
          filter: {
            id: stepId,
          },
        },
      });
    },
    [updateCompanyStepMutation],
  );

  const updateCompanyStepComments = React.useCallback(
    (stepId: string, bigfootTeamComment: string, applicantComment: string) => {
      return updateCompanyStepMutation({
        variables: {
          data: {
            bigfootTeamComment: bigfootTeamComment,
            applicantComment: applicantComment,
          },
          filter: {
            id: stepId,
          },
        },
      });
    },
    [updateCompanyStepMutation],
  );

  const updateCompanyStepStatus = React.useCallback(
    (stepId: string, status: ProgressStepStatus) => {
      return updateCompanyStepMutation({
        variables: {
          data: {
            status: status,
          },
          filter: {
            id: stepId,
          },
        },
      });
    },
    [updateCompanyStepMutation],
  );

  const updateCompanyStepVisability = React.useCallback(
    (stepId: string, isVisible: boolean) => {
      return updateCompanyStepMutation({
        variables: {
          data: {
            isVisible: isVisible,
          },
          filter: {
            id: stepId,
          },
        },
      });
    },
    [updateCompanyStepMutation],
  );

  const upsertStepStatus = async (step: ProgressStepProps) => {
    const { active } = step;

    const progressCompanyValue = getProgressStepById(progressStages, step.id);
    const isExistProgressStepValue = Boolean(progressCompanyValue);

    setUpsertStepLoading(true);

    try {
      if (isExistProgressStepValue) {
        const status = toggleProgressStepStatus(active);
        if (step.type === ProgressStepType.CompanyStep) {
          await updateCompanyStepStatus(progressCompanyValue?.id as string, status);
        } else {
          await updateStepValueStatus(progressCompanyValue?.id as string, status);
        }
      } else {
        await createStepValueWithStatus(step.id);
      }

      await refetchProgressStages();
    } catch (error) {
      console.error({ error });
      setUpsertStepError(true);
    }

    setUpsertStepLoading(false);
  };

  const upsertStepName = async (
    step: ProgressStepProps,
    name?: string,
    code?: string,
    comment?: string,
  ) => {
    const progressCompanyValue = getProgressStepById(progressStages, step.id);
    const isExistProgressStepValue = Boolean(progressCompanyValue);

    setUpsertStepLoading(true);
    try {
      if (isExistProgressStepValue) {
        if (step.type === ProgressStepType.CompanyStep) {
          await updateCompanyStep(
            progressCompanyValue?.id as string,
            name as string,
            code as string,
            comment as string,
          );
        } else {
          await updateStepValueName(progressCompanyValue?.id as string, name, comment);
        }
      } else {
        await createStepValueWithName(step.id, name as string, comment as string);
      }
      await refetchProgressStages();
    } catch (error) {
      console.error({ error });
      setUpsertStepError(true);
    }
    setUpsertStepLoading(false);
  };

  const upsertStepVisibility = async (step: ProgressStepProps) => {
    const progressCompanyValue = getProgressStepById(progressStages, step.id);
    const isExistProgressStepValue = Boolean(progressCompanyValue);
    const isVisible = toggleVisibilityStatus(step.isVisible);

    setUpsertStepLoading(true);
    try {
      if (step.type === ProgressStepType.CompanyStep) {
        await updateCompanyStepVisability(step.id, isVisible);
      } else {
        if (isExistProgressStepValue) {
          await updateStepValueVisibility(progressCompanyValue?.id as string, isVisible);
        } else {
          await createStepValueWithVisibility(step.id, isVisible);
        }
      }
      await refetchProgressStages();
    } catch (error) {
      console.error({ error });
      setUpsertStepError(true);
    }
    setUpsertStepLoading(false);
  };

  const upsertStepComments = async (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => {
    const progressCompanyValue = getProgressStepById(progressStages, step.id);
    const isExistProgressStepValue = Boolean(progressCompanyValue);

    setUpsertStepLoading(true);
    try {
      if (isExistProgressStepValue) {
        if (step.type === ProgressStepType.CompanyStep) {
          await updateCompanyStepComments(step?.id as string, bigfootTeamComment, applicantComment);
        } else {
          await updateStepValueComment(
            progressCompanyValue?.id as string,
            bigfootTeamComment,
            applicantComment,
          );
        }
      } else {
        await createStepValueWithComment(step.id, bigfootTeamComment, applicantComment);
      }
      await refetchProgressStages();
    } catch (error) {
      console.error({ error });
      setUpsertStepError(true);
    }
    setUpsertStepLoading(false);
  };

  const insertCompanyStep = async (
    stageId: string,
    name: string,
    code: string,
    comment?: string,
  ) => {
    setUpsertStepLoading(true);

    try {
      await createCompanyStep(stageId, name, code, comment);
      await refetchProgressStages();
    } catch (error) {
      console.error({ error });
      setUpsertStepError(true);
    }

    setUpsertStepLoading(false);
  };

  return {
    data: progressStages,
    loading,
    upsertStepStatus,
    upsertStepName,
    insertCompanyStep,
    upsertStepVisibility,
    upsertStepComments,
    upsertStepLoading,
    upsertStepError,
  };
};
