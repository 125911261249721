import React from 'react';

import { SubmitButton } from 'src/components';
import { useForm } from 'react-final-form';

interface ResetAdjustmentButtonProps {
  hasAdjustedValue: boolean;
  submitting: boolean;
  onClose: () => void;
}

export const ResetAdjustmentButton: React.FC<ResetAdjustmentButtonProps> = ({
  submitting,
  hasAdjustedValue,
  onClose,
}) => {
  const { change, submit } = useForm();

  const onClick = React.useCallback(() => {
    change('adjustedValue', null);
    submit();
    onClose();
  }, [change, onClose, submit]);

  return (
    <SubmitButton color="secondary" disabled={!hasAdjustedValue || submitting} onClick={onClick}>
      Reset
    </SubmitButton>
  );
};
