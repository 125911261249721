import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Source } from 'src/types';
import { getMetricSourceLabel } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  accounting: {
    color: theme.palette.grey[500],
  },
}));

interface MetricSourceLabelProps {
  source: Source | null;
}

export const MetricSourceLabel: React.FC<MetricSourceLabelProps> = ({ source }) => {
  const classes = useStyles();

  return (
    <Typography variant="overline" className={classes.accounting}>
      {getMetricSourceLabel(source)}
    </Typography>
  );
};
