import React from 'react';
import * as R from 'ramda';

type ModalState = {
  open: boolean;
  args: any;
};

type ModalsState = Record<string, ModalState>;

type ModalsContextType = {
  state: ModalsState;
  openModal: (id: string, args?: any) => void;
  closeModal: (id: string) => void;
};

export const ModalsContext = React.createContext<ModalsContextType>({
  state: {},
  openModal: () => null,
  closeModal: () => null,
});

export const useModal = (
  id?: string,
): {
  open: boolean;
  args: any;
  openModal: (id?: string, args?: any) => void;
  closeModal: (id?: string) => void;
} => {
  const { openModal, closeModal, state } = React.useContext<ModalsContextType>(ModalsContext);

  const modalState: ModalState = R.propOr({ open: false }, id as string, state);

  const openCurrentModal = React.useCallback(
    (customId?: string, customArgs?: any) => {
      if (customId) {
        openModal(customId, customArgs);
      } else if (id) {
        openModal(id);
      }
    },
    [id, openModal],
  );

  const closeCurrentModal = React.useCallback(
    (customId?: string) => closeModal(id || (customId as string)),
    [closeModal, id],
  );

  return { ...modalState, openModal: openCurrentModal, closeModal: closeCurrentModal };
};

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalsProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [state, setState] = React.useState<ModalsState>({});

  const openModal = React.useCallback((id: string, args?: any): void => {
    setState(R.assoc(id, { open: true, args }));
  }, []);

  const closeModal = React.useCallback((id: string): void => {
    setState(R.assoc(id, { open: false }));
  }, []);

  return (
    <ModalsContext.Provider value={{ state, openModal, closeModal }}>
      {children}
    </ModalsContext.Provider>
  );
};
