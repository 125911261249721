import React from 'react';

import { useClientRoles } from 'src/app-builder/providers';
import { useCurrentUser } from 'src/app-builder';
import { useInboxMessagesSubscribe } from 'src/hooks';
import { InboxMessage } from '../graphql';
import { extractUserCompanyId, getCurrentUserRole } from 'src/utils';
import { AppRole } from 'src/types';

export interface InboxMessageContextData {
  messagesCount: number;
  markInboxMessageAsRead: (message: InboxMessage | undefined) => void;
  setCompanyId: (companyId: string) => void;
}

export interface InboxMessageProviderProps {
  children: React.ReactNode;
}

export const InboxMessagesContext = React.createContext({} as InboxMessageContextData);

export const InboxMessagesProvider: React.FC<InboxMessageProviderProps> = ({ children }) => {
  const { currentRole } = useClientRoles();
  const { user } = useCurrentUser();
  const { isBigfootTeam } = getCurrentUserRole(currentRole as AppRole);

  const [companyId, setCompanyId] = React.useState<string>('');
  const { messagesCount, markInboxMessageAsRead } = useInboxMessagesSubscribe(
    companyId,
    isBigfootTeam,
  );

  React.useEffect(() => {
    if (!isBigfootTeam) {
      const companyId = extractUserCompanyId(user) as string;
      setCompanyId(companyId);
    }
  }, [isBigfootTeam, setCompanyId, user]);

  return (
    <InboxMessagesContext.Provider
      value={{
        messagesCount,
        markInboxMessageAsRead,
        setCompanyId,
      }}
    >
      {children}
    </InboxMessagesContext.Provider>
  );
};
