import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { getFormattedMetricValue, isNegative } from 'src/utils';
import { Format } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    textAlign: 'right',
  },
  lessThanZero: {
    color: '#eb4336',
  },
}));

interface FacilityValueCellProps {
  value: number | null | undefined;
  metricFormat?: Format;
}

export const FacilityValueCell: React.FC<FacilityValueCellProps> = ({
  value,
  metricFormat = Format.Money,
}) => {
  const classes = useStyles();
  const formattedValue = !R.isNil(value) ? getFormattedMetricValue(value, metricFormat) : 'N/A';

  return (
    <TableCell
      className={clsx({
        [classes.cell]: true,
        [classes.lessThanZero]: isNegative(value),
      })}
    >
      {formattedValue}
    </TableCell>
  );
};
