import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';

import { MetricCellDate } from 'src/components';
import { MetricNameColumnTitle } from 'src/shared/metrics/TableUnderwritingReport/MetricNameColumnTitle';
import { StatementsCell } from 'src/shared/statements/StatementsCell';
import { StatementsMetricNameRenderer } from 'src/shared/statements/StatementsMetricNameRenderer';

interface StatementsData {
  metrics: any;
  dates: Array<string>;
  exportData: {
    columns: any;
    rows: any;
  };
}

const getDates = (report: Array<Record<string, any>>): string[] => {
  if (R.isEmpty(report)) {
    return [];
  }

  return Object.keys(report[0])?.filter(
    transaction =>
      transaction !== 'metricName' && transaction !== 'children' && transaction !== 'key',
  );
};

export const useStatementsTable = (
  statementsReports: Array<Record<string, any>>,
): StatementsData => {
  const columnTitle = 'Metric Name';

  const getDatesOrder = (dates: string[]): string[] => {
    const firstDate = DateTime.fromISO(dates[0]);
    const lastDate = DateTime.fromISO(dates[dates.length - 1]);

    if (firstDate > lastDate) {
      return dates.reverse();
    }
    return dates;
  };

  const dates = getDates(statementsReports);
  const datesInOrder = getDatesOrder(dates);

  const columns = React.useMemo<Record<any, any>[]>(
    () => [
      {
        title: <MetricNameColumnTitle title={columnTitle} />,
        width: 380,
        key: 'metricName',
        exportData: {
          title: columnTitle,
          key: 'name',
        },
        fixed: 'left',
        align: 'left',
        dataIndex: 'metricName',
        render: StatementsMetricNameRenderer,
        ellipsis: true,
      },
      ...datesInOrder.map((date: string) => {
        return {
          title: <MetricCellDate date={date} />,
          exportData: { title: date, key: date },
          key: date,
          // eslint-disable-next-line react/display-name
          render: (value: any) => {
            return <StatementsCell value={value} />;
          },
          dataIndex: date,
          align: 'right',
          width: 130,
        };
      }),
    ],
    [columnTitle, datesInOrder],
  );

  return {
    metrics: statementsReports,
    dates: datesInOrder,
    exportData: {
      columns,
      rows: statementsReports,
    },
  };
};
