import React, { useContext, useEffect, useState } from 'react';
import { Item } from 'src/shared/popovers/SelectedColumnsPopover/Item';
import { selectedColumnsContext } from 'src/providers/SelectedColumnsProvider';
import { Divider, Grid, Link, List, makeStyles, Typography } from '@material-ui/core';

interface Column {
  selected: boolean;
  label: string;
}

const useStyles = makeStyles(theme => ({
  selectedColumns: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  actions: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
  },
  divider: {
    padding: '0 6px',
  },
  action: {
    cursor: 'pointer',
    '& p': {
      padding: '0 4px',
    },
  },
}));

const initColumns = (allKeys: string[], initialOrder: string[]) => {
  const columns: Column[] = [];
  allKeys.forEach(key => columns.push({ selected: initialOrder.includes(key), label: key }));
  return columns;
};

export const SelectedColumnsPopover: React.FC = () => {
  const { allKeys, initialOrder, keys, setKeys } = useContext(selectedColumnsContext);
  const [columns, setColumns] = useState<Column[]>([]);
  const classes = useStyles();

  const move = (oldIdx: number, newIdx: number) => {
    const newColumns = [...columns];
    const value = newColumns[oldIdx];
    newColumns.splice(oldIdx, 1);
    newColumns.splice(newIdx, 0, value);
    setColumns(newColumns);
  };

  const toggle = (idx: number) => {
    const newColumns = [...columns];
    columns[idx].selected = !columns[idx].selected;
    setColumns(newColumns);
    setKeys(newColumns.filter(column => column.selected).map(column => column.label));
  };

  const showAll = () => {
    setColumns(allKeys.map(key => ({ selected: true, label: key })));
    setKeys(allKeys);
  };

  const restoreDefaults = () => {
    setColumns(initialOrder.map(key => ({ selected: true, label: key })));
    setKeys(initialOrder);
  };

  const commitMoveChange = () => {
    const newKeys = columns.filter(column => column.selected).map(column => column.label);
    setKeys(newKeys);
  };

  useEffect(() => {
    setColumns(initColumns(allKeys, keys));
  }, [allKeys, keys]);

  const renderCard = (column: Column, idx: number) => {
    return (
      <Item
        key={column.label}
        label={column.label}
        index={idx}
        selected={column.selected}
        moveCard={move}
        toggle={toggle}
        commitMoveChange={commitMoveChange}
      />
    );
  };

  return (
    <List className={classes.selectedColumns}>
      {columns.map((column, i) => renderCard(column, i))}
      <Divider />
      <Grid container justify="center" className={classes.actions}>
        <Grid item>
          <Link onClick={showAll} className={classes.action}>
            <Typography>Show All</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.divider}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Link onClick={restoreDefaults} className={classes.action}>
            <Typography>Restore Defaults</Typography>
          </Link>
        </Grid>
      </Grid>
    </List>
  );
};
