import { gql } from '8base-react-sdk';

export const DRAW_FRAGMENT = gql`
  fragment DrawFragment on Draw {
    id
    createdAt
    updatedAt
    active
    fundedDate
    maturityDate
    amountDeployed
    interestRate
    cashShare
    returnCap
    term
    iOPeriod
    amortizationPeriod
    iOPeriodAmortizationPeriod
    exitFee
    prepayment
    prepaymentMonths
    iRR
    mOIC
    comments
    _description
  }
`;

export const DRAW_QUERY = gql`
  query DrawEntity($id: ID!) {
    draw(id: $id) {
      ...DrawFragment
    }
  }
  ${DRAW_FRAGMENT}
`;

export const DRAW_EDIT_MUTATION = gql`
  mutation DrawUpdate($data: DrawUpdateInput!, $filter: DrawKeyFilter) {
    drawUpdate(data: $data, filter: $filter) {
      ...DrawFragment
    }
  }
  ${DRAW_FRAGMENT}
`;

export const DRAW_CREATE_MUTATION = gql`
  mutation DrawCreate($data: DrawCreateInput!) {
    drawCreate(data: $data) {
      ...DrawFragment
    }
  }
  ${DRAW_FRAGMENT}
`;

export const DRAW_DELETE_MUTATION = gql`
  mutation DrawDelete($filter: DrawKeyFilter!, $force: Boolean) {
    drawDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const DRAWS_TABLE_QUERY = gql`
  query DrawsTableContent(
    $filter: DrawFilter
    $orderBy: [DrawOrderBy]
    $sort: [DrawSort!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
  ) {
    drawsList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
      skip: $skip
    ) {
      items {
        ...DrawFragment
      }
      count
    }
  }
  ${DRAW_FRAGMENT}
`;
