import React from 'react';
import * as R from 'ramda';
import { useMutation } from 'react-apollo';
import { useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { FormInboxDialog } from './FormInboxDialog';
import { DIALOGS } from 'src/dialogs';
import { EVENT_RESOLVER_MUTATION, EventType } from 'src/graphql';
import { useClientRoles } from 'src/app-builder/providers';
import { AppRole } from 'src/types';
import { createPayload, commonErrorResolver, getCurrentUserRole, isFunction, t } from 'src/utils';

interface InboxComposeDialogProps {}

export const InboxComposeDialog: React.FC<InboxComposeDialogProps> = () => {
  const notification = useNotification();

  const { currentRole } = useClientRoles();
  const { isAppAdministrator, isAppAnalyst } = getCurrentUserRole(currentRole as AppRole);

  const { open, closeModal, args, openModal } = useModal(DIALOGS.INBOX_COMPOSE_DIALOG);

  const [createInboxMessage] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['InboxMessagesList'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('inbox_message_sent_success')),
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      const attachments = form.getFieldState('attachments').value;

      const payload = createPayload({
        isByAdministrator: isAppAdministrator || isAppAnalyst,
        subject: data.subject,
        body: data.body,
        attachments,
        companyId: args.companyId,
        repliedOnId: args.repliedOnId,
      });

      try {
        const message = await createInboxMessage({
          variables: {
            event: {
              type: EventType.InboxMessageCreate,
              payload,
            },
          },
        });

        closeModal();

        await form.reset;

        if (isFunction(args?.onSuccess)) {
          args.onSuccess(message);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [closeModal, createInboxMessage, args, isAppAdministrator, isAppAnalyst],
  );

  if (!open) {
    return null;
  }

  if (R.isEmpty(args?.recipientsList)) {
    notification.info('You cannot send a message because the list of recipients is empty');

    return null;
  }

  return (
    <FormInboxDialog
      title="Compose Message"
      onSubmit={onSubmit}
      openModal={openModal}
      closeModal={closeModal}
      open={open}
    />
  );
};
