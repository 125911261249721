import { AUTH_ERROR_MESSAGES, DEFAULT_ERROR_MESSAGE } from 'src/constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const authErrorResolver = (error: any): string => {
  const errorCode = error?.code;
  if (typeof errorCode === 'string') {
    const errorMessage = AUTH_ERROR_MESSAGES[errorCode];

    if (errorMessage) {
      return errorMessage;
    }
  }

  const errorMessage = error?.message;
  if (typeof errorMessage === 'string') {
    return errorMessage;
  }

  const errorDescription = error?.description;
  if (typeof errorDescription === 'string') {
    return errorDescription;
  }

  return 'Something went wrong during sign up';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const commonErrorResolver = (error: any): string => {
  if (error?.graphQLErrors[0]?.message) {
    return error.graphQLErrors[0].message;
  } else if (error?.message) {
    return error.message;
  } else if (error?.description) {
    return error.description;
  }

  return DEFAULT_ERROR_MESSAGE;
};
