import * as React from 'react';
import clsx from 'clsx';
import { SvgIcon } from '@material-ui/core';
import * as icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ScoreArrowType } from 'src/types';
import { PRIMARY_COLOR } from 'src/constants/colors';

const useStyles = makeStyles(() => ({
  arrow: {
    fontWeight: 400,
    verticalAlign: 'middle',
  },
  red: {
    color: '#EB4336',
  },
  green: {
    color: PRIMARY_COLOR,
  },
}));

interface MetricArrowProps {
  arrowType: ScoreArrowType | null;
}
export const MetricArrow: React.FC<MetricArrowProps> = ({ arrowType }) => {
  const classes = useStyles();

  switch (arrowType) {
    case ScoreArrowType.up:
      return (
        <SvgIcon className={clsx(classes.arrow, classes.green)} component={icons.TrendingUp} />
      );

    case ScoreArrowType.steady:
      return <SvgIcon className={classes.arrow} component={icons.TrendingFlat} />;

    default:
      return (
        <SvgIcon className={clsx(classes.arrow, classes.red)} component={icons.TrendingDown} />
      );
  }
};
