import * as R from 'ramda';
import * as _ from 'lodash';

import {
  MetricCode,
  MonitoringCompany,
  MonitoringDataKey,
  MonitoringValueName,
  PortfolioMonitoringTableData,
} from 'src/types';
import { getAdjustedOrOriginalMetricValue } from 'src/utils';
import { METRICS_WITHOUT_TOTALS } from 'src/constants';

const TOTALS_KEY = 'totals';

export const getPortfolioMonitoringTableData = (
  monitoringData: Array<MonitoringCompany> | null,
): PortfolioMonitoringTableData | null => {
  if (R.isNil(monitoringData)) {
    return null;
  }

  try {
    const tableData = {
      companies: {},
      metrics: {},
      values: {},
    } as PortfolioMonitoringTableData;

    for (const company of monitoringData) {
      const {
        companyId,
        companyName,
        portfolioCompanyId,
        monitoringValues,
        monitoringMetrics,
      } = company;

      _.set(tableData, [MonitoringDataKey.Companies, companyId], {
        companyId,
        companyName,
        portfolioCompanyId,
      });

      for (const monitoringValue of monitoringValues) {
        const date = monitoringValue.date;
        const committed = R.pathOr(null, [MonitoringValueName.Committed], monitoringValue);
        const deployed = R.pathOr(null, [MonitoringValueName.Deployed], monitoringValue);
        const advanceRate = R.pathOr(null, [MonitoringValueName.AdvanceRate], monitoringValue);

        _.set(
          tableData,
          [MonitoringDataKey.Values, MonitoringValueName.Committed, companyId, date],
          committed,
        );

        const committedTotals = R.pathOr(
          0,
          [MonitoringDataKey.Values, MonitoringValueName.Committed, TOTALS_KEY, date],
          tableData,
        );

        _.set(
          tableData,
          [MonitoringDataKey.Values, MonitoringValueName.Committed, TOTALS_KEY, date],
          R.sum([committedTotals, committed ?? 0]),
        );

        _.set(
          tableData,
          [MonitoringDataKey.Values, MonitoringValueName.Deployed, companyId, date],
          deployed,
        );

        const deployedTotals = R.pathOr(
          0,
          [MonitoringDataKey.Values, MonitoringValueName.Deployed, TOTALS_KEY, date],
          tableData,
        );

        _.set(
          tableData,
          [MonitoringDataKey.Values, MonitoringValueName.Deployed, TOTALS_KEY, date],
          R.sum([deployedTotals, deployed ?? 0]),
        );

        _.set(
          tableData,
          [MonitoringDataKey.Values, MonitoringValueName.AdvanceRate, companyId, date],
          advanceRate,
        );

        const advanceRateTotals = R.pathOr(
          0,
          [MonitoringDataKey.Values, MonitoringValueName.AdvanceRate, TOTALS_KEY, date],
          tableData,
        );

        _.set(
          tableData,
          [MonitoringDataKey.Values, MonitoringValueName.AdvanceRate, TOTALS_KEY, date],
          R.sum([advanceRateTotals, advanceRate ?? 0]),
        );
      }

      for (const monitoringMetric of monitoringMetrics) {
        const date = monitoringMetric?.date;
        const code = monitoringMetric?.metric?.code;

        _.set(tableData, [MonitoringDataKey.Values, code, companyId, date], monitoringMetric);

        const hasMetricInfo = !R.isNil(
          R.pathOr(null, [MonitoringDataKey.Metrics, code as string], tableData),
        );

        if (!hasMetricInfo) {
          _.set(tableData, [MonitoringDataKey.Metrics, code as string], {
            code,
            name: monitoringMetric?.metric?.name,
            format: monitoringMetric?.metric?.format,
          });
        }

        if (!METRICS_WITHOUT_TOTALS.includes(code as MetricCode)) {
          const value = getAdjustedOrOriginalMetricValue(monitoringMetric) ?? 0;
          const totalsByDate = R.pathOr(
            0,
            [MonitoringDataKey.Values, code, TOTALS_KEY, date],
            tableData,
          );

          _.set(
            tableData,
            [MonitoringDataKey.Values, code, TOTALS_KEY, date],
            R.sum([totalsByDate, value]),
          );
        }
      }
    }

    return tableData;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getTableNameByMetricCode = (code: MetricCode): string => {
  switch (code) {
    case MetricCode.CashPositionPlaid: {
      return 'Cash Balance';
    }

    case MetricCode.ChangeInCash:
    case MetricCode.ChangeInCashCurrentWeek: {
      return 'Change In Cash';
    }

    case MetricCode.CashRunwayBanking:
    case MetricCode.CashRunwayBankingWeekly: {
      return 'Cash Runway';
    }

    default: {
      return 'Unknown';
    }
  }
};

export const getMonitoringValueCellKey = (companyId: string, date: string): string => {
  return `${companyId}-${date}`;
};
