import { gql } from '8base-react-sdk';
import { DRAW_FRAGMENT } from './draws';

export const FACILITY_FRAGMENT = gql`
  fragment FacilityFragment on Facility {
    id
    createdAt
    updatedAt
    structure
    amountCommitted
    totalAmountDeployed
    advanceRate
    finalAdvanceRate
    nextAvailableDraw
    closingFee
    closingFeeValue
    closingDate
    successFee
    minCashCovenant
    minCashRunawayCovenant
    validityGuarantee
    comments
    active
    _description
  }
`;

export const FACILITY_QUERY = gql`
  query FacilityEntity($id: ID!) {
    facility(id: $id) {
      ...FacilityFragment
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const FACILITY_EDIT_MUTATION = gql`
  mutation FacilityUpdate($data: FacilityUpdateInput!, $filter: FacilityKeyFilter) {
    facilityUpdate(data: $data, filter: $filter) {
      ...FacilityFragment
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const FACILITY_CREATE_MUTATION = gql`
  mutation FacilityCreate($data: FacilityCreateInput!) {
    facilityCreate(data: $data) {
      ...FacilityFragment
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const FACILITY_DELETE_MUTATION = gql`
  mutation FacilityDelete($filter: FacilityKeyFilter!, $force: Boolean) {
    facilityDelete(filter: $filter, force: $force) {
      success
    }
  }
`;

export const FACILITIES_TABLE_QUERY = gql`
  query FacilitiesTableContent(
    $filter: FacilityFilter
    $orderBy: [FacilityOrderBy]
    $sort: [FacilitySort!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $skip: Int
  ) {
    facilitiesList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
      skip: $skip
    ) {
      items {
        ...FacilityFragment
        draws {
          items {
            ...DrawFragment
          }
        }
      }
      count
    }
  }
  ${FACILITY_FRAGMENT}
  ${DRAW_FRAGMENT}
`;

export const RECALCULATE_TOTAL_AMOUNT_DEPLOYED_MUTATION = gql`
  mutation RecalculateTotalAmountDeployed($facilityId: ID!) {
    recalculateTotalAmountDeployedFacility(facilityId: $facilityId) {
      totalAmountDeployed
    }
  }
`;
