import React from 'react';
import { Typography } from '@material-ui/core';
import { getFullName } from 'src/utils';

export const PrimaryContactRenderer = (row: Record<any, any>): React.ReactNode => {
  return (
    <React.Fragment>
      {getFullName({ firstName: row.contactFirstName, lastName: row.contactLastName })}
      <br />
      <Typography variant="overline" color="textSecondary">
        {row?.company?.owner?.title ?? ''}
      </Typography>
    </React.Fragment>
  );
};
