import React from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { DateTime } from 'luxon';
import { FIELD_TYPE, FieldSchema, DATE_FORMATS } from '@8base/utils';
import { Theme, Typography, Link, Avatar } from '@material-ui/core';

import { getFilePreviewUrl } from '../utils';

import { FieldChip, FieldLink, FieldMoney } from './formats';
import { AppAreaField, FIELD_FORMATS } from 'src/constants/app';

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    display: 'flex',
    alignItems: 'center',
  },
  previewImage: ({ url }: { url: string }): CreateCSSProperties<{ url: string }> => ({
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: theme.palette.divider,
    backgroundImage: `url(${url})`,
    width: theme.spacing(6),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  }),
}));

interface FilePreviewProps {
  url: string;
  filename: string;
}

const FilePreview: React.FC<FilePreviewProps> = ({ url, filename }) => {
  const classes = useStyles({ url: getFilePreviewUrl(url, 32) });

  return (
    <Link
      className={classes.preview}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      color="textPrimary"
    >
      <div className={classes.previewImage} />
      {filename}
    </Link>
  );
};

interface EntitiesTableCellValueProps {
  field: FieldSchema;
  value: any;
  areaField: AppAreaField;
  record: Record<string, any>;
}

export const EntitiesTableCellValue: React.FC<EntitiesTableCellValueProps> = ({
  field,
  value,
  areaField,
  record,
}) => {
  if (R.isNil(value)) {
    return null;
  }

  switch (areaField.format) {
    case FIELD_FORMATS.PLAIN: {
      switch (field.fieldType) {
        case FIELD_TYPE.ID:
        case FIELD_TYPE.TEXT:
        case FIELD_TYPE.SWITCH: {
          return value;
        }

        case FIELD_TYPE.RELATION: {
          if (field.isList) {
            return value.items.map(R.prop('_description')).join(', ');
          }
          return value._description;
        }

        case FIELD_TYPE.NUMBER: {
          return Number.isFinite(value) ? value.toLocaleString() : null;
        }

        case FIELD_TYPE.DATE: {
          if (field.fieldTypeAttributes.format === DATE_FORMATS.DATE) {
            return DateTime.fromISO(value).toFormat('MM/dd/yyyy');
          } else {
            return (
              <React.Fragment>
                {DateTime.fromISO(value).toFormat('MM/dd/yyyy')}
                <Typography variant="overline" color="textSecondary" display="block">
                  {DateTime.fromISO(value).toFormat('h:mm a')}
                </Typography>
              </React.Fragment>
            );
          }
        }

        case FIELD_TYPE.FILE: {
          return <FilePreview url={value.downloadUrl} filename={value.filename} />;
        }
      }
      break;
    }

    case FIELD_FORMATS.CHIP: {
      return <FieldChip field={field} value={value} areaField={areaField} />;
    }

    case FIELD_FORMATS.LINK: {
      return <FieldLink field={field} value={value} areaField={areaField} record={record} />;
    }

    case FIELD_FORMATS.MONEY: {
      return <FieldMoney field={field} value={value} areaField={areaField} record={record} />;
    }

    case FIELD_FORMATS.AVATAR: {
      return <Avatar src={value.downloadUrl} />;
    }

    default: {
      return 'Not supported';
    }
  }
};
