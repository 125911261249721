import React from 'react';
import * as R from 'ramda';
import { useMutation } from '@apollo/react-hooks';

import {
  CashMonitoringRecord,
  CashMonitoringRecordCreateInput,
  CashMonitoringRecordUpdateInput,
  CASH_MONITORING_RECORD_CREATE_MUTATION,
  CASH_MONITORING_RECORD_UPDATE_MUTATION,
} from 'src/graphql';
import { EditableField } from 'src/types';

interface UseCashMonitoringRecordsOutput {
  loading: boolean;
  upsertRecord: (
    newRecordInput: CashMonitoringRecordUpdateInput,
    existingRecord: CashMonitoringRecord,
    field: EditableField,
  ) => Promise<void>;
}

export const useCashMonitoringRecords = (): UseCashMonitoringRecordsOutput => {
  const [createRecord, { loading: creating }] = useMutation(
    CASH_MONITORING_RECORD_CREATE_MUTATION,
    {
      refetchQueries: ['CashMonitoring'],
      awaitRefetchQueries: true,
    },
  );

  const [updateRecord, { loading: updating }] = useMutation(
    CASH_MONITORING_RECORD_UPDATE_MUTATION,
    {
      refetchQueries: ['CashMonitoring'],
      awaitRefetchQueries: true,
    },
  );

  const loading = creating || updating;

  const upsertRecord = React.useCallback(
    async (
      newRecordInput: CashMonitoringRecordUpdateInput,
      existingRecord: CashMonitoringRecord,
      field: EditableField,
    ) => {
      const portfolioCompanyId = existingRecord?.portfolioCompany?.id;
      const existingRecordId = existingRecord?.id;
      const date = existingRecord?.date;

      const upsertInput = !R.isEmpty(newRecordInput) ? newRecordInput : { [field]: null };

      const hasExistingRecord = !R.isNil(existingRecordId);

      if (R.isNil(portfolioCompanyId) || R.isNil(date)) {
        return;
      }

      if (!hasExistingRecord) {
        const recordCreateInput = {
          portfolioCompany: {
            connect: {
              id: portfolioCompanyId,
            },
          },
          date,
          ...upsertInput,
        } as CashMonitoringRecordCreateInput;

        await createRecord({
          variables: {
            data: recordCreateInput,
          },
        });
      } else {
        const filter = {
          id: existingRecordId,
        };

        await updateRecord({
          variables: {
            filter,
            data: upsertInput as CashMonitoringRecordUpdateInput,
          },
        });
      }
    },
    [createRecord, updateRecord],
  );

  return { loading, upsertRecord };
};
