import { Company } from 'src/graphql';
import { MetricPeriod } from 'src/types';

export enum CovenantTrackingPortfolioColumn {
  Name = '',
  AdjustedNetCashBurnL3M = 'Adjusted Net Cash Burn $ (L3M)',
  MinCashPositionMonthly = 'Min. Cash Position (monthly)',
  MinCashRunwayMonthly = 'Min. Cash Runway (monthly)',
  MinCashPositionWeekly = 'Min. Cash Position (weekly)',
  MinCashRunwayWeekly = 'Min. Cash Runway (weekly)',
  CumulativeCashReceipts = 'Cumulative Cash Receipts',
  MinCashOfDrawsTaken = 'Min. Cash as a % of Draws Taken',
  CumulativeAdvanceRate = 'Cumulative Advance Rate',
  ActualVSExpectedRevenue = 'Actual vs. Expected Revenue',
  AdditionalCovenant = 'Additional Covenant',
}

export enum CovenantTrackingRowKey {
  RowType = 'rowType',
  Name = 'name',
  AdjustedNetCashBurnL3M = 'adjustedNetCashBurnL3M',
  MinCashPositionMonthly = 'minCashPositionMonthly',
  MinCashRunwayMonthly = 'minCashRunwayMonthly',
  MinCashPositionWeekly = 'minCashPositionWeekly',
  MinCashRunwayWeekly = 'minCashRunwayWeekly',
  CumulativeCashReceipts = 'cumulativeCashReceipts',
  MinCashOfDrawsTaken = 'minCashOfDrawsTaken',
  CumulativeAdvanceRate = 'cumulativeAdvanceRate',
  ActualVSExpectedRevenue = 'actualVSExpectedRevenue',
  AdditionalCovenant = 'additionalCovenant',
}

export enum CovenantName {
  MinCashPosition = 'MinCashPosition',
  MinCashRunway = 'MinCashRunway',
  CumulativeCashReceipts = 'CumulativeCashReceipts',
  MinCashAsPercentOfDrawsTaken = 'MinCashAsPercentOfDrawsTaken',
  CumulativeAdvanceRate = 'CumulativeAdvanceRate',
  ActualVsExpectedRevenue = 'ActualVsExpectedRevenue',
  AdditionalCovenant = 'AdditionalCovenant',
}

export type CovenantValue = {
  value: number | string | null;
  isEnabled: boolean;
};

export type CustomCovenantActualValue = {
  covenantName: CovenantName;
  date: string;
  actualValue: number | string | null;
  difference: number | string | null;
  inCompliance: string | null;
};

export type CustomCovenantActualValuesMap = Record<CovenantName, CustomCovenantActualValue>;

export type CovenantValuesMap = Partial<Record<CovenantName, CovenantValue>>;

export enum CovenantTrackingRowType {
  CompanyName = 'Company Name',
  Covenant = 'Covenant',
  Actual = 'Actual',
  Difference = 'Difference',
  InCompliance = 'In Compliance?',
}

export enum CovenantTrackingRowIndex {
  CompanyName,
  Covenant,
  Actual,
  Difference,
  InCompliance,
}

export type RCTableRowProps = { id: string; key: string };

export type CovenantTrackingTableRow = RCTableRowProps &
  (
    | {
        [CovenantTrackingRowKey.RowType]: CovenantTrackingRowType.CompanyName;
        [CovenantTrackingRowKey.Name]: string;
        [CovenantTrackingRowKey.AdjustedNetCashBurnL3M]?: null;
        [CovenantTrackingRowKey.MinCashPositionMonthly]?: null;
        [CovenantTrackingRowKey.MinCashRunwayMonthly]?: null;
        [CovenantTrackingRowKey.MinCashPositionWeekly]?: null;
        [CovenantTrackingRowKey.MinCashRunwayWeekly]?: null;
        [CovenantTrackingRowKey.CumulativeCashReceipts]?: null;
        [CovenantTrackingRowKey.MinCashOfDrawsTaken]?: null;
        [CovenantTrackingRowKey.CumulativeAdvanceRate]?: null;
        [CovenantTrackingRowKey.ActualVSExpectedRevenue]?: null;
        [CovenantTrackingRowKey.AdditionalCovenant]?: null;
      }
    | {
        [CovenantTrackingRowKey.RowType]: CovenantTrackingRowType.Covenant;
        [CovenantTrackingRowKey.Name]: CovenantTrackingRowType.Covenant;
        [CovenantTrackingRowKey.AdjustedNetCashBurnL3M]: number | string | null;
        [CovenantTrackingRowKey.MinCashPositionMonthly]: number | string | null;
        [CovenantTrackingRowKey.MinCashRunwayMonthly]: number | string | null;
        [CovenantTrackingRowKey.MinCashPositionWeekly]: number | string | null;
        [CovenantTrackingRowKey.MinCashRunwayWeekly]: number | string | null;
        [CovenantTrackingRowKey.CumulativeCashReceipts]: number | string | null;
        [CovenantTrackingRowKey.MinCashOfDrawsTaken]: number | string | null;
        [CovenantTrackingRowKey.CumulativeAdvanceRate]: number | string | null;
        [CovenantTrackingRowKey.ActualVSExpectedRevenue]: number | string | null;
        [CovenantTrackingRowKey.AdditionalCovenant]: number | string | null;
      }
    | {
        [CovenantTrackingRowKey.RowType]:
          | CovenantTrackingRowType.Actual
          | CovenantTrackingRowType.Difference;
        [CovenantTrackingRowKey.Name]:
          | CovenantTrackingRowType.Actual
          | CovenantTrackingRowType.Difference;
        [CovenantTrackingRowKey.AdjustedNetCashBurnL3M]: number | null;
        [CovenantTrackingRowKey.MinCashPositionMonthly]: number | null;
        [CovenantTrackingRowKey.MinCashRunwayMonthly]: number | null;
        [CovenantTrackingRowKey.MinCashPositionWeekly]: number | null;
        [CovenantTrackingRowKey.MinCashRunwayWeekly]: number | null;
        [CovenantTrackingRowKey.CumulativeCashReceipts]: number | null;
        [CovenantTrackingRowKey.MinCashOfDrawsTaken]: number | null;
        [CovenantTrackingRowKey.CumulativeAdvanceRate]: number | null;
        [CovenantTrackingRowKey.ActualVSExpectedRevenue]: number | null;
        [CovenantTrackingRowKey.AdditionalCovenant]: number | null;
      }
    | {
        [CovenantTrackingRowKey.RowType]: CovenantTrackingRowType.InCompliance;
        [CovenantTrackingRowKey.Name]: CovenantTrackingRowType.InCompliance;
        [CovenantTrackingRowKey.AdjustedNetCashBurnL3M]: boolean;
        [CovenantTrackingRowKey.MinCashPositionMonthly]: boolean;
        [CovenantTrackingRowKey.MinCashRunwayMonthly]: boolean;
        [CovenantTrackingRowKey.MinCashPositionWeekly]: boolean;
        [CovenantTrackingRowKey.MinCashRunwayWeekly]: boolean;
        [CovenantTrackingRowKey.CumulativeCashReceipts]: boolean;
        [CovenantTrackingRowKey.MinCashOfDrawsTaken]: boolean;
        [CovenantTrackingRowKey.CumulativeAdvanceRate]: boolean;
        [CovenantTrackingRowKey.ActualVSExpectedRevenue]: boolean;
        [CovenantTrackingRowKey.AdditionalCovenant]: boolean;
      }
  );

export type CovenantTrackingMetricsByPeriod = {
  [MetricPeriod.Month]: Record<string, number | null>;
  [MetricPeriod.Week]: Record<string, number | null>;
};

export type CalculationCtx = {
  portfolioCompanyId: string;
  company: Company;
  totalDeployed: number | null;
  metrics: CovenantTrackingMetricsByPeriod;
  covenants: Record<string, any>;
  customCovenantValues: CustomCovenantActualValuesMap;
};

export type CellValue = string | number | boolean | null;

export type CellGroup = [CellValue, CellValue, CellValue, CellValue, CellValue];

export type CellCalculator = (ctx: CalculationCtx) => CellGroup;
