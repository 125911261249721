import React from 'react';
import * as R from 'ramda';

import { ToggleSwitch } from 'src/components';
import { useScoreContext, useToggleMetricsContext } from 'src/hooks';
import { MetricCode, MetricPeriod, Source } from 'src/types';
import { MetricValue } from 'src/graphql';
import { getToggledMetric } from 'src/utils';
import { getLocalChangedMetric, getMetricLocalValuesKey } from 'src/utils/localStorage/metrics';
import { Format } from 'src/constants';

interface MetricCellToggleProps {
  metricValue: MetricValue;
}

export const MetricCellToggle: React.FC<MetricCellToggleProps> = ({ metricValue }) => {
  const { companyId, companiesWithChange } = useScoreContext();
  const { toggleScoreComponent } = useToggleMetricsContext();

  const date = R.pathOr('', ['date'], metricValue);
  const adjustedValue = R.pathOr(null, ['adjustedValue'], metricValue);
  const comment = R.pathOr(null, ['comment'], metricValue);
  const format = R.pathOr(Format.Count, ['metric', 'format'], metricValue) as Format;
  const code = R.pathOr('', ['metric', 'code'], metricValue) as MetricCode;
  const period = MetricPeriod.Third;
  const source = R.pathOr(Source.Manual, ['source'], metricValue);

  const metricLocalValueKey = getMetricLocalValuesKey(code, period);
  const localChangedMetric = getLocalChangedMetric(
    companiesWithChange,
    companyId,
    metricLocalValueKey,
    date,
    format,
  );

  const active = localChangedMetric?.active ?? metricValue.active ?? true;
  const hasMetricValue =
    !R.isNil(localChangedMetric?.adjustedValue) ||
    !R.isNil(adjustedValue) ||
    !R.isNil(metricValue?.value);

  const handleChange = () => {
    const toggledMetric = getToggledMetric({
      companyId,
      localChangedMetric,
      date,
      code,
      active: !active,
      comment,
      adjustedValue,
      source,
      period,
    });
    toggleScoreComponent(toggledMetric);

    return !active;
  };

  return hasMetricValue ? <ToggleSwitch checked={active} onChange={handleChange} /> : null;
};
