import React from 'react';

import { ApplicationProvider } from './providers';
import { Application as App } from './Application';

export interface EngineProps {
  uri: string;
  routes: Array<{
    path: string;
    exact: boolean;
    component: React.ComponentType<any>;
    location: 'main' | 'reports' | 'settings';
  }>;
  onRequestSuccess?: (request: { [key: string]: any }) => void;
  onRequestError?: (request: { [key: string]: any }) => void;
}

export const Engine: React.FC<EngineProps> = ({
  uri,
  routes,
  onRequestSuccess,
  onRequestError,
}) => {
  return (
    <ApplicationProvider
      uri={uri}
      onRequestSuccess={onRequestSuccess}
      onRequestError={onRequestError}
    >
      <App routes={routes} />
    </ApplicationProvider>
  );
};
