import React from 'react';
import { Grid, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { CovenantTrackingReport } from './CovenantTrackingReport';
import { usePortfolioCompany, useCovenantTracking } from 'src/hooks';

import { HeaderContainer, FiltersContainer } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: '16px',
  },
}));

export const Main: React.FC = () => {
  const classes = useStyles();

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const { metrics, rows, loading: covenantsTrackingLoading, selectedPeriod } = useCovenantTracking(
    portfolioCompany,
  );

  const exportData = React.useMemo(
    () => ({
      rows,
      columns: metrics,
    }),
    [metrics, rows],
  );

  const loading = portfolioCompanyLoading || covenantsTrackingLoading;

  return (
    <Grid container direction="column" wrap="nowrap">
      <HeaderContainer tableExportData={exportData} />
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.table}>
          <FiltersContainer selectedPeriod={selectedPeriod} />
          <CovenantTrackingReport metrics={metrics} rows={rows} />
        </Grid>
      )}
    </Grid>
  );
};
