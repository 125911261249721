import React from 'react';
import { useMutation } from 'react-apollo';

import { SubmitButton } from 'src/components';
import { useNotification } from 'src/hooks';
import {
  MetricValueUpsertManyMutation,
  MetricValueUpsertManyMutationVariables,
  METRIC_VALUE_UPSERT_MANY_MUTATION,
} from 'src/graphql';
import {
  getAllMetricsByCompanyId,
  generateLocalMetricsAsQueryData,
} from 'src/utils/localStorage/metrics';
import { t } from 'src/utils';
import { CompaniesWithMetricChange } from 'src/types/localStorage';

interface RecalculateMetricsButtonProps {
  companyId: string;
  hasMetricChanges: boolean;
  companiesWithChange: CompaniesWithMetricChange | null;
  recalculating: boolean;
  recalculateMetrics: () => void;
}

export const RecalculateMetricsButton: React.FC<RecalculateMetricsButtonProps> = ({
  companyId,
  hasMetricChanges,
  companiesWithChange,
  recalculating,
  recalculateMetrics,
}) => {
  const notification = useNotification();

  const [isSavingLocalMetrics, setIsSavingLocalMetrics] = React.useState(false);

  const [metricValueUpsert] = useMutation<
    MetricValueUpsertManyMutation,
    MetricValueUpsertManyMutationVariables
  >(METRIC_VALUE_UPSERT_MANY_MUTATION, {
    refetchQueries: ['MetricValuesList', 'MetricsCompanyHeader', 'PortfolioCompaniesEntity'],
    awaitRefetchQueries: true,
  });

  const beforeRecalculateMetrics = async () => {
    if (!hasMetricChanges) {
      recalculateMetrics();
      return;
    }

    setIsSavingLocalMetrics(true);

    const localMetrics = getAllMetricsByCompanyId(companiesWithChange, companyId);
    const data = generateLocalMetricsAsQueryData(localMetrics, companyId);

    try {
      await metricValueUpsert({
        variables: {
          data,
        },
      });

      notification.success(t('upload_metrics_success'));
      recalculateMetrics();
    } catch (error) {
      console.error(error);
      notification.error(t('upload_metrics_error'));
    }

    setIsSavingLocalMetrics(false);
  };
  return (
    <SubmitButton
      size="large"
      color="secondary"
      variant="contained"
      disabled={!hasMetricChanges}
      loading={isSavingLocalMetrics || recalculating}
      onClick={beforeRecalculateMetrics}
    >
      Calculate
    </SubmitButton>
  );
};
