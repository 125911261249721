import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';

import { useRouterQuery } from 'src/hooks';
import { Layout, FiltersContainer } from './index';
import {
  MetricsListQuery,
  MetricsListQueryVariables,
  MetricValuesListQuery,
  MetricValuesListQueryVariables,
  METRIC_VALUES_LIST_QUERY,
  METRICS_LIST_QUERY,
} from 'src/graphql';
import { getDateRange, structureTableData, extractUserCompanyId } from 'src/utils';
import { MetricCode, MetricPeriod, ScoreMetricItem } from 'src/types';
import { CLIENT_SAASSCORE_METRICS } from 'src/constants';
import { useCurrentUser } from 'src/app-builder';

interface ClientPortalScoreAndMetricsPageProps {}

export const Main: React.FC<ClientPortalScoreAndMetricsPageProps> = () => {
  const query = useRouterQuery();
  const { user } = useCurrentUser();

  const selectedPeriod = parseInt(R.pathOr('12', ['selectedPeriod'], query));
  const dates = getDateRange(selectedPeriod);

  const companyId = extractUserCompanyId(user);

  const { data: metricList, loading: metricListLoading } = useQuery<
    MetricsListQuery,
    MetricsListQueryVariables
  >(METRICS_LIST_QUERY, {
    variables: {
      filter: {
        code: {
          in: CLIENT_SAASSCORE_METRICS,
        },
      },
    },
  });

  const { data: metricValuesList, loading: metricValuesListLoading } = useQuery<
    MetricValuesListQuery,
    MetricValuesListQueryVariables
  >(METRIC_VALUES_LIST_QUERY, {
    variables: {
      filter: {
        company: {
          id: {
            equals: companyId,
          },
        },
        date: {
          in: dates,
        },
        OR: [
          {
            period: {
              equals: MetricPeriod.Third,
            },
          },
          {
            AND: [
              {
                period: {
                  equals: MetricPeriod.Month,
                },
                metric: {
                  code: {
                    in: [MetricCode.SaaSScore],
                  },
                },
              },
            ],
          },
        ],
      },
    },
  });

  const scoreMetrics: ScoreMetricItem[] = React.useMemo(
    () =>
      structureTableData({
        metricCodes: CLIENT_SAASSCORE_METRICS as MetricCode[],
        metrics: metricList,
        values: metricValuesList,
        dates,
      }),
    [metricList, metricValuesList, dates],
  );

  const loading = metricListLoading || metricValuesListLoading;

  const tableData = { data: scoreMetrics, dates };

  return (
    <Layout
      filters={<FiltersContainer selectedPeriod={selectedPeriod} />}
      tableData={tableData}
      loading={loading}
    />
  );
};
