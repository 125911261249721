import { assocPath } from 'ramda';
import { ObjectSchema, ValidationError } from 'yup';
import { AssertsShape, TypeOfShape } from 'yup/lib/object';
import { bracketNotationToPath } from './helpers';
import { REG_EXP } from 'src/constants';

type Validator = (value: any) => false | string;

export const composeValidators = (validators: Validator[]): Validator => value => {
  for (let index = 0; index < validators.length; index++) {
    const validator = validators[index];

    const check = validator(value);

    if (check) {
      return check;
    }
  }

  return false;
};

export const required: Validator = value => (!value ? 'Required' : false);

export const email: Validator = value =>
  REG_EXP.EMAIL.test(String(value)) ? false : 'Invalid email address';

type ValidationObject = {
  [field: string]: string;
};

export const validateDataByYup = async (
  yupSchema: ObjectSchema<
    any,
    Record<string, any>,
    TypeOfShape<any> | null,
    AssertsShape<any> | null
  >,
  entityData?: Record<string, any> | null,
): Promise<ValidationObject> => {
  return await yupSchema
    .validate(entityData, {
      abortEarly: false,
    })
    .then(() => [])
    .catch(({ inner }) => {
      return inner.reduce((accum: ValidationObject, error: ValidationError) => {
        // (1) transform string in the array:
        // "some.array[0].value" => ["some", "array", "0", "value"]
        const errorPath = bracketNotationToPath(error?.path || '');

        // (2) mapping splitted values to create array instead of object in assocPath:
        // ["some", "array", "0", "value"] => ["some", "array", 0, "value"]
        const errorPathWithNumbers = errorPath.map(value =>
          Number.isInteger(Number(value)) ? Number(value) : value,
        );

        return assocPath(errorPathWithNumbers, error.errors[0], accum);
      }, {});
    });
};
