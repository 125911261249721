import React from 'react';
import * as R from 'ramda';

import { ColumnsType } from 'rc-table/es/interface';
import {
  Format,
  COVENANT_TRACKING_PORTFOLIO_COLUMNS_ORDER,
  COVENANT_OFF_MESSAGE,
  COMPLIANCE_NO_COLOR,
  COMPLIANCE_YES_COLOR,
  NUMBER_NEGATIVE_COLOR,
  NUMBER_POSITIVE_COLOR,
  COMPLIANCE_YES,
  COMPLIANCE_NO,
} from 'src/constants';
import { LinkRenderer } from 'src/shared/table/renderers/LinkRenderer';
import {
  CovenantTrackingRowKey,
  CovenantTrackingTableRow,
  CovenantTrackingPortfolioColumn,
  CELL_TYPES,
  CovenantTrackingRowType,
} from 'src/types';
import { getFormattedMetricValue } from 'src/utils';

const renderNumericCell = (
  r: CovenantTrackingTableRow,
  key: CovenantTrackingRowKey,
  format: Format = Format.Money,
): React.ReactNode | string => {
  const value = r[key];

  if (!R.isNil(value)) {
    const color = value < 0 ? NUMBER_NEGATIVE_COLOR : NUMBER_POSITIVE_COLOR;
    const formatted = getFormattedMetricValue(value as number, format);

    return <span style={{ color }}>{formatted}</span>;
  }

  return '-';
};

const renderBooleanCell = (value: boolean | null): React.ReactNode => {
  if (!R.isNil(value)) {
    const text = value ? COMPLIANCE_YES : COMPLIANCE_NO;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: value ? COMPLIANCE_YES_COLOR : COMPLIANCE_NO_COLOR,
          height: 32,
        }}
      >
        {text}
      </div>
    );
  }

  return '-';
};

const renderCell = (
  r: CovenantTrackingTableRow,
  key: CovenantTrackingRowKey,
  format: Format = Format.Money,
) => {
  const value = r[key];

  switch (r.rowType) {
    case CovenantTrackingRowType.CompanyName: {
      return null;
    }

    case CovenantTrackingRowType.InCompliance: {
      return renderBooleanCell(value as boolean);
    }

    default: {
      if (r.rowType === CovenantTrackingRowType.Covenant && value === COVENANT_OFF_MESSAGE) {
        return COVENANT_OFF_MESSAGE;
      }

      return renderNumericCell(r, key, format);
    }
  }
};

const renderTextCell = (r: CovenantTrackingTableRow, key: CovenantTrackingRowKey) => {
  const value = r[key];

  switch (r.rowType) {
    case CovenantTrackingRowType.CompanyName: {
      return null;
    }

    case CovenantTrackingRowType.InCompliance: {
      return renderBooleanCell(value as boolean);
    }

    default: {
      if (r.rowType === CovenantTrackingRowType.Covenant && value === COVENANT_OFF_MESSAGE) {
        return COVENANT_OFF_MESSAGE;
      }

      return <div>{value ?? '-'}</div>;
    }
  }
};

export const getColumns = (): ColumnsType<Record<string, any>> => {
  const columns: Record<string, any> = {
    [CovenantTrackingPortfolioColumn.Name]: {
      title: CovenantTrackingPortfolioColumn.Name,
      exportData: {
        header: CovenantTrackingPortfolioColumn.Name,
        type: CELL_TYPES.text,
        key: CovenantTrackingRowKey.Name,
        width: 30,
      },
      key: CovenantTrackingRowKey.Name,
      render: (r: CovenantTrackingTableRow) => {
        switch (r.rowType) {
          case CovenantTrackingRowType.CompanyName: {
            return (
              <div style={{ fontSize: 18, padding: '16px 0' }}>
                {LinkRenderer(
                  r[CovenantTrackingRowKey.Name],
                  `/portfolio-companies/${r.id}/dashboard`,
                )}
              </div>
            );
          }

          default: {
            return r[CovenantTrackingRowKey.Name];
          }
        }
      },
      align: 'left',
      fixed: 'left',
      className: 'fixed',
      style: {
        padding: 0,
      },
      width: 200,
    },

    [CovenantTrackingPortfolioColumn.AdjustedNetCashBurnL3M]: {
      title: CovenantTrackingPortfolioColumn.AdjustedNetCashBurnL3M,
      exportData: {
        header: CovenantTrackingPortfolioColumn.AdjustedNetCashBurnL3M,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.AdjustedNetCashBurnL3M,
        width: 20,
      },
      key: CovenantTrackingRowKey.AdjustedNetCashBurnL3M,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.AdjustedNetCashBurnL3M),
      align: 'center',
      width: 250,
    },

    [CovenantTrackingPortfolioColumn.MinCashPositionMonthly]: {
      title: CovenantTrackingPortfolioColumn.MinCashPositionMonthly,
      exportData: {
        header: CovenantTrackingPortfolioColumn.MinCashPositionMonthly,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.MinCashPositionMonthly,
        width: 20,
      },
      key: CovenantTrackingRowKey.MinCashPositionMonthly,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.MinCashPositionMonthly),
    },

    [CovenantTrackingPortfolioColumn.MinCashRunwayMonthly]: {
      title: CovenantTrackingPortfolioColumn.MinCashRunwayMonthly,
      exportData: {
        header: CovenantTrackingPortfolioColumn.MinCashRunwayMonthly,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.MinCashRunwayMonthly,
        width: 20,
      },
      key: CovenantTrackingRowKey.MinCashRunwayMonthly,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.MinCashRunwayMonthly, Format.Month),
    },

    [CovenantTrackingPortfolioColumn.MinCashPositionWeekly]: {
      title: CovenantTrackingPortfolioColumn.MinCashPositionWeekly,
      exportData: {
        header: CovenantTrackingPortfolioColumn.MinCashPositionWeekly,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.MinCashPositionWeekly,
        width: 20,
      },
      key: CovenantTrackingRowKey.MinCashPositionWeekly,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.MinCashPositionWeekly),
    },

    [CovenantTrackingPortfolioColumn.MinCashRunwayWeekly]: {
      title: CovenantTrackingPortfolioColumn.MinCashRunwayWeekly,
      exportData: {
        header: CovenantTrackingPortfolioColumn.MinCashRunwayWeekly,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.MinCashRunwayWeekly,
        width: 20,
      },
      key: CovenantTrackingRowKey.MinCashRunwayWeekly,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.MinCashRunwayWeekly, Format.Month),
    },

    [CovenantTrackingPortfolioColumn.CumulativeCashReceipts]: {
      title: CovenantTrackingPortfolioColumn.CumulativeCashReceipts,
      exportData: {
        header: CovenantTrackingPortfolioColumn.CumulativeCashReceipts,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.CumulativeCashReceipts,
        width: 20,
      },
      key: CovenantTrackingRowKey.CumulativeCashReceipts,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.CumulativeCashReceipts),
    },

    [CovenantTrackingPortfolioColumn.MinCashOfDrawsTaken]: {
      title: CovenantTrackingPortfolioColumn.MinCashOfDrawsTaken,
      exportData: {
        header: CovenantTrackingPortfolioColumn.MinCashOfDrawsTaken,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.MinCashOfDrawsTaken,
        width: 20,
      },
      key: CovenantTrackingRowKey.MinCashOfDrawsTaken,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.MinCashOfDrawsTaken, Format.Percent),
    },

    [CovenantTrackingPortfolioColumn.CumulativeAdvanceRate]: {
      title: CovenantTrackingPortfolioColumn.CumulativeAdvanceRate,
      exportData: {
        header: CovenantTrackingPortfolioColumn.CumulativeAdvanceRate,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.CumulativeAdvanceRate,
        width: 20,
      },
      key: CovenantTrackingRowKey.CumulativeAdvanceRate,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.CumulativeAdvanceRate, Format.Ratio),
    },

    [CovenantTrackingPortfolioColumn.ActualVSExpectedRevenue]: {
      title: CovenantTrackingPortfolioColumn.ActualVSExpectedRevenue,
      exportData: {
        header: CovenantTrackingPortfolioColumn.ActualVSExpectedRevenue,
        type: CELL_TYPES.number,
        key: CovenantTrackingRowKey.ActualVSExpectedRevenue,
        width: 20,
      },
      key: CovenantTrackingRowKey.ActualVSExpectedRevenue,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderCell(r, CovenantTrackingRowKey.ActualVSExpectedRevenue, Format.Percent),
    },

    [CovenantTrackingPortfolioColumn.AdditionalCovenant]: {
      title: CovenantTrackingPortfolioColumn.AdditionalCovenant,
      exportData: {
        header: CovenantTrackingPortfolioColumn.AdditionalCovenant,
        type: CELL_TYPES.text,
        key: CovenantTrackingRowKey.AdditionalCovenant,
        width: 20,
      },
      key: CovenantTrackingRowKey.AdditionalCovenant,
      align: 'center',
      width: 250,
      render: (r: CovenantTrackingTableRow) =>
        renderTextCell(r, CovenantTrackingRowKey.AdditionalCovenant),
    },
  };

  return COVENANT_TRACKING_PORTFOLIO_COLUMNS_ORDER.map(col => columns[col]);
};
