import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Box, TableCell, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getFormattedMetricValue, isNegative } from 'src/utils';
import { Format } from 'src/constants';
import { MetricValue } from 'src/graphql';

interface MonitoringMetricCellProps {
  metricValue: MetricValue | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    position: 'relative',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  alignRight: {
    textAlign: 'right',
  },
  lessThanZero: {
    color: '#eb4336',
  },
  adjustedValueMarker: {
    position: 'absolute',
    right: 0,
    top: 0,
    border: '5px solid transparent',
    borderTop: '5px solid #295F7B',
    borderRight: '5px solid #295F7B',
  },
}));

export const MonitoringMetricCell: React.FC<MonitoringMetricCellProps> = ({ metricValue }) => {
  const classes = useStyles();

  const format = R.pathOr(Format.Money, ['metric', 'format'], metricValue);

  const adjustedValue = metricValue?.adjustedValue;
  const comment = metricValue?.comment;

  const value = adjustedValue ?? metricValue?.value ?? null;
  const formattedValue = getFormattedMetricValue(value, format);

  const visibleAdjustedMarker = !R.isNil(adjustedValue) || !R.isNil(comment);

  return (
    <TableCell
      className={clsx({ [classes.cell]: true, [classes.lessThanZero]: isNegative(value) })}
    >
      {visibleAdjustedMarker && <Box className={classes.adjustedValueMarker} />}
      {!R.isNil(value) ? formattedValue : 'N/A'}
    </TableCell>
  );
};
