import gql from 'graphql-tag';

const CASH_MONITORING_PORTFOLIO_COMPANY_FRAGMENT = gql`
  fragment CashMonitoringPortfolioCompanyFragment on PortfolioCompany {
    id
    company {
      id
      name
      primaryCustomerBase
      metrics(filter: $metricsFilter) {
        items {
          id
          date
          period
          tier
          value
          adjustedValue
          metric {
            id
            name
            code
          }
        }
      }

      covenantValue {
        items {
          id
          covenant {
            id
            name
          }
          value
          isEnabled
        }
      }
    }

    cashMonitoringRecords(filter: $recordFilter) {
      items {
        id
        date
        note
        action
        portfolioCompany {
          id
        }
      }
    }
  }
`;

export const CASH_MONITORING_QUERY = gql`
  query CashMonitoring(
    $metricsFilter: MetricValueFilter!
    $companyFilter: PortfolioCompanyFilter
    $recordFilter: CashMonitoringRecordFilter
    $sort: [PortfolioCompanySort!]
  ) {
    portfolioCompaniesList(sort: $sort, filter: $companyFilter) {
      items {
        ...CashMonitoringPortfolioCompanyFragment
      }
      count
    }
  }
  ${CASH_MONITORING_PORTFOLIO_COMPANY_FRAGMENT}
`;

export const CASH_MONITORING_RECORD_CREATE_MUTATION = gql`
  mutation CashMonitoringRecordCreate($data: CashMonitoringRecordCreateInput!) {
    cashMonitoringRecordCreate(data: $data) {
      id
    }
  }
`;

export const CASH_MONITORING_RECORD_UPDATE_MUTATION = gql`
  mutation CashMonitoringRecordUpdate(
    $filter: CashMonitoringRecordKeyFilter!
    $data: CashMonitoringRecordUpdateInput!
  ) {
    cashMonitoringRecordUpdate(filter: $filter, data: $data) {
      id
    }
  }
`;
