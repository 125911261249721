import React from 'react';
import { Select, FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import { FieldSchema } from '@8base/utils';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { hasFieldError, getFieldError } from './utils';

export interface SelectFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  fieldSchema: FieldSchema;
  label?: React.ReactNode;
  fullWidth?: boolean;
  options: Array<{ label: string; value: string }>;
  disabled?: boolean;
  multiple?: boolean;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  input,
  meta,
  fullWidth,
  label,
  options,
  multiple,
  disabled,
  ...rest
}) => {
  const { name, value, onBlur, onChange } = input;

  const handleChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const id = React.useMemo(() => `select-${name}`, [name]);

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  return (
    <FormControl variant="outlined" error={error} fullWidth={fullWidth}>
      <InputLabel id={id} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        name={name}
        value={multiple ? value || [] : value}
        onChange={handleChange}
        onBlur={onBlur}
        label={label}
        multiple={multiple}
        disabled={disabled}
        {...rest}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
