import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useModal, Form, Field, TextField, FileField } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { NOTE_QUERY, NOTES_UPDATE_MUTATION } from 'src/graphql';
import { DIALOGS } from 'src/dialogs';
import { t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface NoteEditDialogProps {}

export const NoteEditDialog: React.FC<NoteEditDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, closeModal, args, openModal } = useModal(DIALOGS.NOTE_EDIT_DIALOG);

  const { data, loading } = useQuery(NOTE_QUERY, {
    variables: {
      id: args?.id,
    },
    skip: !open || !Boolean(args?.id),
  });

  const initialValues = React.useMemo(() => (loading ? {} : data?.note ?? {}), [data, loading]);

  const [updateNote] = useMutation(NOTES_UPDATE_MUTATION, {
    refetchQueries: ['NotesList'],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('note_update_success')),
    onError: () => notification.error(t('note_update_error')),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      await updateNote({ variables: { data } });

      closeModal();

      await form.reset;
    },
    [closeModal, updateNote],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  if (!open) {
    return null;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Form tableSchemaName="Notes" type="UPDATE" onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Edit Note</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <Field
                  name="title"
                  label="Title"
                  component={TextField}
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="body"
                  label="Body"
                  component={TextField}
                  disabled={submitting}
                  rows={5}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="attachments"
                  label="Attachments"
                  component={FileField}
                  maxFiles={3}
                  fullWidth
                  disabled={submitting}
                  sessionCache
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={() => onClose(form, pristine)} disabled={submitting}>
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Save changes
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
