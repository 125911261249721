import React from 'react';
import * as R from 'ramda';
import { Grid } from '@material-ui/core';
import { TableSchema, FieldSchema } from '@8base/utils';

import { EntityFormField, isSupportedField } from './EntityFormField';

type EntityFormFieldsProps = {
  table: TableSchema;
  children?: React.ReactNode;
  filterFields?: (field: FieldSchema) => boolean;
};

export const EntityFormFields: React.FC<EntityFormFieldsProps> = ({
  table,
  filterFields,
  children,
}) => {
  const fields: FieldSchema[] = R.pipe<FieldSchema[], FieldSchema[]>(
    R.filter(R.allPass([isSupportedField, filterFields || R.T])),
  )(table.fields);

  return (
    <Grid container direction="column" spacing={2}>
      {fields.map((field: FieldSchema) => (
        <Grid key={field.id} item>
          <EntityFormField field={field} />
        </Grid>
      ))}

      {children}
    </Grid>
  );
};
