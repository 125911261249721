import { createMuiTheme, colors, Theme } from '@material-ui/core';
import { APP_SETTING } from 'src/constants/app';
import { isSafari } from 'src/utils';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    customPalette: {
      border: {
        form: React.CSSProperties['color'];
        table: React.CSSProperties['color'];
      };
      background: {
        activeCard: React.CSSProperties['color'];
      };
    };
  }
  interface ThemeOptions {
    customPalette: {
      border: {
        form: React.CSSProperties['color'];
        table: React.CSSProperties['color'];
      };
      background: {
        activeCard: React.CSSProperties['color'];
      };
    };
  }
}

export const createTheme = (): Theme => {
  const palette = {
    primary: {
      main: APP_SETTING.theme.primaryColor,
    },
    secondary: {
      main: APP_SETTING.theme.secondaryColor,
      contrastText: '#fff',
    },
    background: {
      default: '#f6f6f6',
    },
    divider: '#E7E7E7',
  };

  const customPalette = {
    border: {
      form: '#D2D2D2',
      table: '#E0E0E0',
    },
    background: {
      activeCard: colors.grey[50],
    },
  };

  const theme = createMuiTheme({
    typography: {
      fontFamily: '"Barlow", sans-serif',
      h1: { fontSize: 100, fontWeight: 100 },
      h2: { fontSize: 63, fontWeight: 100 },
      h3: { fontSize: 50, fontWeight: 400 },
      h4: { fontSize: 36, fontWeight: 400, lineHeight: '43px' },
      h5: { fontSize: 25, fontWeight: 400 },
      h6: { fontSize: 18, fontWeight: 500 },
      subtitle1: { fontSize: 16, fontWeight: 500 },
      subtitle2: { fontSize: 14, fontWeight: 500 },
      body1: { fontSize: 16, fontWeight: 400 },
      body2: { fontSize: 14, fontWeight: 400, lineHeight: '20px' },
      button: { fontSize: 14, fontWeight: 500, textTransform: 'uppercase' },
      caption: { fontSize: 12, fontWeight: 400 },
      overline: { fontSize: 11, fontWeight: 400, lineHeight: '13px' },
    },

    palette,

    customPalette,

    zIndex: {
      appBar: 1200,
    },

    shadows: [
      'none',
      '0 7px 21px 0 rgba(171,171,171,0.17)',
      '0 7px 21px 0 rgba(171,171,171,0.17)',
      '0 5px 15px 0 rgba(171,171,171,0.17)',
      '5px 5px 20px 0 rgba(171,171,171,0.17)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
      '-5px 5px 30px 0px rgba(171,171,171,0.25)',
      '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
      '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
      '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
      '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
      '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
      '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
      '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
      '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    ],
    overrides: {
      MuiTableHead: {
        root: {
          whiteSpace: 'nowrap',
          textTransform: 'uppercase',

          '& .MuiTableCell-head': {
            backgroundColor: '#fafafa !important',
          },
        },
      },

      MuiTableCell: {
        root: {
          borderColor: `${customPalette.border.table} !important`,
          padding: '8px 16px',
          wordBreak: 'break-word',
          maxHeight: 52,
        },
      },

      MuiTableRow: {
        root: {
          '&.MuiTableRow-hover:hover': {
            boxShadow: '0 3px 15px 0 #EBEBEB',
            backgroundColor: 'transparent',
          },
        },
      },

      MuiList: {
        root: {
          zIndex: 1,
        },
      },

      MuiListItemIcon: {
        root: {
          minWidth: 32,

          '& + .MuiListItemText-root': {
            marginLeft: 8,
          },
        },
      },

      MuiButton: {
        root: {
          '&.MuiButton-sizeLarge': {
            height: 45,
          },
        },
        containedSecondary: {
          color: isSafari() ? '#fff !important' : '#fff',
        },
      },

      MuiInputBase: {
        root: {},
      },

      MuiOutlinedInput: {
        notchedOutline: {
          color: colors.grey[700],
          borderColor: customPalette.border.form,
        },
      },

      MuiDialogTitle: {
        root: {
          padding: '20px 24px',
          borderBottom: `1px solid ${colors.grey[300]}`,
        },
      },

      MuiDialogContent: {
        root: {
          padding: 24,
        },
      },

      MuiAccordionSummary: {
        root: {
          backgroundColor: '#fafafa !important',
        },
      },

      MuiDialogActions: {
        root: {
          padding: '20px 24px',
        },
        spacing: {
          '& > :not(:first-child)': {
            marginLeft: 20,
          },
        },
      },

      MuiStep: {
        root: {
          '& .MuiStepIcon-text': {
            fill: '#fff',
          },
        },
      },

      MuiStepper: {
        root: {
          background: 'transparent',
        },
      },

      MuiChip: {
        sizeSmall: {
          height: 20,
        },
      },
    },
  });

  return theme;
};
