import gql from 'graphql-tag';

export const GET_STATEMENTS_METRICS_MUTATION = gql`
  mutation GetStatementsMetricsMutation($event: StatementsMetricsInput!) {
    getStatementsMetricsMutation(event: $event) {
      taskId
    }
  }
`;

export const STATEMENTS_EXPORT_MUTATION = gql`
  mutation StatementsExportMutation($event: TableExportInput!) {
    tableExport(event: $event) {
      asyncTaskId
    }
  }
`;
