import React from 'react';
import * as R from 'ramda';
import { List, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ShortViewMessage } from './components/ShortViewMessage';
import { InboxMessage } from 'src/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    overflow: 'auto',
    padding: 0,
  },
}));

interface NotesListProps {
  path: string;
  messages: Array<InboxMessage>;
}

export const MessagesList: React.FC<NotesListProps> = ({ messages, path }) => {
  const classes = useStyles();

  if (R.isEmpty(messages)) {
    return null;
  }

  return (
    <List className={classes.list}>
      {messages.map((message: InboxMessage) => (
        <ShortViewMessage key={message.id} path={path} message={message} />
      ))}
    </List>
  );
};
