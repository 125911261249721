import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useModal } from 'src/app-builder';

import { DIALOGS } from 'src/dialogs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    overflowY: 'hidden',
    height: 700,
    width: 1200,
  },
  iframe: { border: 'none !important' },
  dialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
      overflow: 'hidden',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 12,
    color: theme.palette.grey[500],
  },
}));

interface PdfViewDialogProps {}

export const PdfViewDialog: React.FC<PdfViewDialogProps> = () => {
  const classes = useStyles();

  const { open, args, closeModal } = useModal(DIALOGS.PDF_VIEW_DIALOG);

  const onClose = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ className: classes.root }}
      className={classes.dialog}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">{args?.title}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <iframe
          title={args?.title}
          className={classes.iframe}
          width="100%"
          height="630px"
          src={args?.base64String}
        />
      </DialogContent>
    </Dialog>
  );
};
