import React from 'react';
import { Button, Grid, makeStyles, Paper, Popover, TextField, Theme } from '@material-ui/core';

import { ProgressStepProps } from 'src/components';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '15px',
  },
  textField: {
    margin: '5px 0 5px 0',
  },
  submitButton: {
    margin: '10px 0 0 0',
  },
}));

interface ProgressStepCommentsPopoverProps {
  step: ProgressStepProps;
  open: boolean;
  isBigfootTeam: boolean;
  anchorEl: Element | null | undefined;
  handleContextMenuClose: () => void;
  onChangeCommentStep?: (
    step: ProgressStepProps,
    bigfootTeamComment: string,
    applicantComment: string,
  ) => void;
}

export const ProgressStepCommentsPopover: React.FC<ProgressStepCommentsPopoverProps> = ({
  step,
  open,
  anchorEl,
  isBigfootTeam,
  handleContextMenuClose,
  onChangeCommentStep,
}) => {
  const classes = useStyles();

  const [bigfootTeamComment, setBigfootTeamComment] = React.useState<string>(
    step.bigfootTeamComment,
  );
  const [applicantComment, setApplicantComment] = React.useState<string | undefined>(
    step.applicantComment,
  );

  const onChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setBigfootTeamComment(event.target.value);
  };
  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setApplicantComment(event.target.value);
  };
  const onSubmit = () => {
    if (onChangeCommentStep) {
      onChangeCommentStep(step, bigfootTeamComment, applicantComment as string);
      handleContextMenuClose();
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper>
        <Grid className={classes.wrapper} container>
          <Grid item container>
            <TextField
              className={classes.textField}
              label="Bigfoot team comment"
              variant="outlined"
              value={bigfootTeamComment}
              onChange={onChangeName}
              fullWidth
              disabled={!isBigfootTeam}
            />
            <TextField
              className={classes.textField}
              label="Applicant comment"
              variant="outlined"
              value={applicantComment}
              onChange={onChangeComment}
              fullWidth
              disabled={isBigfootTeam}
            />
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              className={classes.submitButton}
              color="secondary"
              variant="contained"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Popover>
  );
};
