import React from 'react';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';

import {
  LOAN_APPLICATION_QUERY,
  LoanApplication,
  LoanApplicationsEntityQuery,
  LoanApplicationsEntityQueryVariables,
} from 'src/graphql';

export interface LoanApplicationProviderProps {
  loanApplicationId: string;
  children: React.ReactNode;
}

export interface LoanApplicationContextData {
  data?: LoanApplication;
  loading: boolean;
}

export const LoanApplicationContext = React.createContext<LoanApplicationContextData>(
  {} as LoanApplicationContextData,
);

export const LoanApplicationProvider: React.FC<LoanApplicationProviderProps> = ({
  children,
  loanApplicationId,
}) => {
  const { data, loading } = useQuery<
    LoanApplicationsEntityQuery,
    LoanApplicationsEntityQueryVariables
  >(LOAN_APPLICATION_QUERY, {
    variables: {
      id: loanApplicationId,
    },
    skip: !loanApplicationId,
  });

  const loanApplication = R.pathOr<LoanApplication | undefined>(
    undefined,
    ['loanApplication'],
    data,
  );

  return (
    <LoanApplicationContext.Provider
      value={{
        data: loanApplication,
        loading,
      }}
    >
      {children}
    </LoanApplicationContext.Provider>
  );
};
