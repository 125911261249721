import * as R from 'ramda';
import { nanoid } from 'nanoid';
import { DateTime, DurationUnit } from 'luxon';

import {
  Format,
  MetricDuration,
  SCORE_RESULTS_ORDERED_METRICS,
  SOURCE_TO_LABEL,
  TABLE_DELIMITER,
} from 'src/constants';
import { Metric, MetricValue, MetricValuesListQuery } from 'src/graphql';
import {
  convertFromPercentage,
  convertToPercentage,
  isNegative,
  numberFormatter,
} from 'src/utils/number';
import { insertAfter, replaceIfNil, wrapWithBrackets } from 'src/utils/helpers';
import {
  ChangedMetric,
  DashboardMetricGroups,
  EmptyMetricValue,
  Merge,
  MergedMetricValue,
  MetricCode,
  MetricGroup,
  MetricPeriod,
  MetricValuesGroup,
  ScoreDetailsMetric,
  Source,
  TableDelimiter,
} from 'src/types';
import { getDateTimeEndOf, getDateTimeStartOf, getDifferenceBetweenTwoDates } from 'src/utils/date';
import { countFormat, moneyFormat, monthFormat, percentFormat, ratioFormat } from 'src/utils/other';

export const metricFormats = {
  [Format.Money]: moneyFormat,
  [Format.Percent]: percentFormat,
  [Format.Count]: countFormat,
  [Format.Ratio]: ratioFormat,
  [Format.Month]: monthFormat,
};

export const DEFAULT_PERIOD = 6;

export function convertFromFormData(
  values: Record<string, any>,
  format: Format,
): Record<string, number | null> {
  return R.compose(
    R.reduce((acc: Record<string, number | null>, key: string) => {
      if (R.isNil(values[key]) || Number.isNaN(Number(values[key]))) {
        return { ...acc, [key]: null };
      }

      if (R.equals(format, Format.Percent)) {
        return {
          ...acc,
          [key]: convertFromPercentage(values[key]),
        };
      }

      return {
        ...acc,
        [key]: parseFloat(values[key]),
      };
    }, {}),
    R.keys,
  )(values);
}

export function convertToFormData(
  values: Record<string, any>,
  format: Format,
): Record<string, number | null> {
  return R.compose(
    R.reduce((acc: Record<string, number | null>, key: string) => {
      if (R.isNil(values[key]) || Number.isNaN(Number(values[key]))) {
        return { ...acc, [key]: null };
      }

      if (R.equals(format, Format.Percent)) {
        return {
          ...acc,
          [key]: convertToPercentage(values[key]),
        };
      }

      return {
        ...acc,
        [key]: parseFloat(values[key]),
      };
    }, {}),
    R.keys,
  )(values);
}

export function getFormattedMetricValue(
  value: null | number | string,
  format: Format,
): string | number | null {
  if (R.isNil(value)) {
    return null;
  }

  const formatted = numberFormatter(Math.abs(Number(value)), format);

  return isNegative(Number(value)) ? wrapWithBrackets(formatted) : formatted;
}

export function convertToListWithDelimiters(
  reports: Array<MetricCode[]>,
): Array<MetricCode | string> {
  return R.reduce((a: Array<MetricCode | string>, b: MetricCode[]) => {
    if (R.equals(R.last(reports), b)) {
      return [...a, ...b];
    }

    return [...a, ...b, TABLE_DELIMITER];
  }, [])(reports);
}

export function insertDelimitersAfter<T>(
  metricCodes: Array<MetricCode>,
  arr: Array<T>,
  comparator: (item: T, metricCode: MetricCode, index: number) => boolean,
): Array<TableDelimiter | T> {
  let result = arr;

  metricCodes.forEach((metricCode: MetricCode, index: number) => {
    result = insertAfter(result, TABLE_DELIMITER, (item: T) => comparator(item, metricCode, index));
  });

  return result;
}

export function sortMetricsByFilter(
  metrics: Merge<Metric, { code: string; name: string }>[],
  filter: string[],
): Array<Merge<Metric, { code: string; name: string }> | string> {
  const data = [];

  for (const code of filter) {
    if (R.equals(code, TABLE_DELIMITER)) {
      data.push(TABLE_DELIMITER);
    }

    for (const metric of metrics) {
      if (R.equals(code, metric.code)) {
        data.push(metric);
      }
    }
  }

  return data;
}

function createEmptyMetricValue(date: string, metric: Metric, period: MetricPeriod): MetricValue {
  return {
    id: nanoid(),
    comment: null,
    adjustedValue: null,
    value: null,
    metric,
    date,
    period,
  };
}

function makeMetricValues(values: MetricValuesGroup[]): Record<string, MetricGroup> {
  const result: Record<string, MetricGroup> = {};

  for (const value of values) {
    result[value.date] = {};

    for (const item of value.metrics.items) {
      if (item?.metric?.code) {
        result[value.date][item.metric.code] = item;
      } else {
        result[value.date] = {};
      }
    }
  }

  return result;
}

function sortMetricList(
  list: Record<string, MetricValuesGroup>,
): Record<string, MetricGroup> | null {
  if (R.isEmpty(list) || R.isEmpty(list)) {
    return null;
  }

  return R.compose(
    R.reduce<any, any>((a: Record<string, MetricGroup>, b: string) => ({ ...a, [b]: list[b] }), {}),
    R.sort(
      (a: string, b: string) => DateTime.fromISO(a).toSeconds() - DateTime.fromISO(b).toSeconds(),
    ),
    R.keys,
  )(list);
}

function generate(
  date: DateTime,
  values: MetricValuesGroup[],
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>,
  timeUnit: DurationUnit,
  periods: number,
): Record<string, MetricValuesGroup> {
  const result: any = {};
  const data = makeMetricValues(values);
  const period = timeUnit === MetricDuration.Weeks ? MetricPeriod.Week : MetricPeriod.Month;

  for (let i = 0; i < periods; i++) {
    const currentDate = date
      .plus({ [timeUnit]: i })
      .endOf(timeUnit)
      .toISODate();
    result[currentDate] = {};

    for (const metric of metrics) {
      if (typeof metric !== 'string') {
        if (R.isNil(data[currentDate]) || R.isNil(data[currentDate][metric.code])) {
          result[currentDate][metric.code] = createEmptyMetricValue(currentDate, metric, period);
        } else {
          result[currentDate][metric.code] = data[currentDate][metric.code];
        }
      }
    }
  }

  return result;
}

/**
 * Generates empty metrics if no start and end is specified.
 *
 * @param metrics List of metrics meta data.
 * @param values List of metrics values.
 * @param timeUnit Time unit.
 * @param defaultPeriod Default period in current time unit.
 */
function generateMetricsByPeriod(
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>,
  values: MetricValuesGroup[],
  timeUnit: DurationUnit,
  defaultPeriod: number,
): Record<string, MetricValuesGroup> {
  const till = DateTime.local();
  const from = till.minus({ [MetricDuration.Months]: defaultPeriod });
  const dateFromEndOf = getDateTimeEndOf(timeUnit, from.toISODate());

  const fromISODate = from.toISODate();
  const tillISODate = till.toISODate();

  const periods = getPeriodsByTimeUnit(fromISODate, tillISODate, timeUnit);

  return generate(dateFromEndOf, values, metrics, timeUnit, periods);
}

/**
 * Generates empty metrics from the start of the period.
 *
 * @param metrics List of metrics meta data.
 * @param values List of metrics values.
 * @param timeUnit Time unit.
 * @param from Date from in ISO format.
 */
function generateMetricsFrom(
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>,
  values: MetricValuesGroup[],
  timeUnit: DurationUnit,
  from: string,
): Record<string, MetricValuesGroup> {
  const till = DateTime.local().toISODate();
  const dateFromEndOf = getDateTimeEndOf(timeUnit, from);

  const periods = getPeriodsByTimeUnit(from, till, timeUnit);

  return generate(dateFromEndOf, values, metrics, timeUnit, periods);
}

function getPeriodsByTimeUnit(from: string, till: string, timeUnit: DurationUnit): number {
  if (timeUnit === MetricDuration.Weeks) {
    const dateTill = getDateTimeStartOf(MetricDuration.Months, till).toISODate();
    const diffByWeek = getDifferenceBetweenTwoDates(from, dateTill, timeUnit);

    return Math.floor(R.pathOr(0, [timeUnit], diffByWeek));
  }

  const diffByMonth = getDifferenceBetweenTwoDates(from, till, MetricDuration.Months);
  return Math.floor(R.pathOr(0, [MetricDuration.Months], diffByMonth));
}

/**
 * Generates empty metrics from the end of the period.
 *
 * @param metrics List of metrics meta data.
 * @param values List of metrics values.
 * @param timeUnit Time unit.
 * @param till Date till in ISO format.
 * @param defaultPeriod Default period in current time unit.
 */
function generateMetricsTill(
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>,
  values: MetricValuesGroup[],
  timeUnit: DurationUnit,
  till: string,
  defaultPeriod: number,
): Record<string, MetricValuesGroup> {
  const dateTillEndOf = getDateTimeEndOf(timeUnit, till);
  const from = dateTillEndOf.minus({ [timeUnit]: defaultPeriod }).toISODate();
  const dateFromEndOf = getDateTimeEndOf(timeUnit, from);

  return generate(dateFromEndOf, values, metrics, timeUnit, defaultPeriod);
}

/**
 * Generates empty metrics in the period from and till.
 *
 * @param metrics List of metrics meta data.
 * @param values List of metrics values.
 * @param timeUnit Time unit.
 * @param from Date from in ISO format.
 * @param till Date till in ISO format.
 */
function generateMetricsFromAndTill(
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>,
  values: MetricValuesGroup[],
  timeUnit: DurationUnit,
  from: string,
  till: string,
): Record<string, MetricValuesGroup> {
  const dateFromEndOf = getDateTimeEndOf(timeUnit, from);
  const diff = getDifferenceBetweenTwoDates(from, till, timeUnit);
  const periods = Math.floor(R.pathOr(0, [timeUnit], diff));

  return generate(dateFromEndOf, values, metrics, timeUnit, periods);
}

/**
 * Generate remaining values in the period.
 *
 * @param metrics List of metrics meta data.
 * @param values List of metrics values.
 * @param from Date from in ISO format.
 * @param till Date till in ISO format.
 * @param timeUnit Time unit.
 * @param defaultPeriod Default period for generate empty cells.
 */
export function generateMetrics(
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>,
  values: MetricValuesGroup[],
  from: string | null,
  till: string | null,
  timeUnit: DurationUnit = MetricDuration.Months,
  defaultPeriod = DEFAULT_PERIOD,
): Record<string, MetricGroup> | null {
  switch (true) {
    case !R.isNil(from) && !R.isNil(till): {
      return sortMetricList(
        generateMetricsFromAndTill(metrics, values, timeUnit, from as string, till as string),
      );
    }

    case R.isNil(from) && !R.isNil(till): {
      return sortMetricList(
        generateMetricsTill(metrics, values, timeUnit, till as string, defaultPeriod),
      );
    }

    case !R.isNil(from) && R.isNil(till): {
      return sortMetricList(generateMetricsFrom(metrics, values, timeUnit, from as string));
    }

    case R.isNil(from) && R.isNil(till): {
      return sortMetricList(generateMetricsByPeriod(metrics, values, timeUnit, defaultPeriod));
    }

    default: {
      return null;
    }
  }
}

export const getAdjustedOrOriginalMetricValue = (
  metricValue: MetricValue | undefined | null,
): number | null => {
  if (Number.isFinite(metricValue?.adjustedValue)) {
    return metricValue?.adjustedValue as number;
  }

  if (Number.isFinite(metricValue?.value)) {
    return metricValue?.value as number;
  }

  return null;
};

export const getMetricValue = (
  data: MetricValuesListQuery | undefined,
  date: string,
  code: MetricCode,
): MetricValue | undefined =>
  R.pipe(
    R.pathOr([], ['metricValuesList', 'groups']),
    R.find(R.propEq('date', date)),
    R.pathOr([], ['metrics', 'items']),
    R.find(R.pathEq(['metric', 'code'], code)),
  )(data);

export const getMetricValueBy = (
  data: MetricValuesListQuery | undefined,
  date: string,
  comparator: (metric: MetricValue) => boolean,
): MetricValue | undefined =>
  R.pipe(
    R.pathOr([], ['metricValuesList', 'groups']),
    R.find(R.propEq('date', date)),
    R.pathOr([], ['metrics', 'items']),
    R.find(comparator),
  )(data);

export const generateEmptyMetricValue = (
  date: string,
  metricCode: MetricCode,
  metricName: string,
  metricPeriod: MetricPeriod,
  metricIndex: number,
  metricFormat = Format.Count,
): EmptyMetricValue => {
  return {
    id: `${date}-${metricCode}-${metricIndex}`,
    period: metricPeriod,
    date,
    metric: {
      code: metricCode,
      name: metricName,
      format: metricFormat,
    },
    value: null,
    adjustedValue: null,
    tier: null,
    comment: null,
  };
};

export const generateChangedMetricValue = (
  date: string,
  adjustedValue: number | null,
  comment: string | null,
  companyId: string,
  active: boolean,
  source: Source,
  metricCode: MetricCode,
  period: MetricPeriod,
): ChangedMetric => {
  return {
    date,
    adjustedValue,
    comment,
    companyId,
    active,
    source,
    metricCode,
    period,
  };
};

export function isEqualMetricCode(metricValue: MetricValue, metricCode: MetricCode): boolean {
  return metricValue.metric?.code === metricCode;
}

export function isEqualMetricPeriod(metricValue: MetricValue, period: MetricPeriod): boolean {
  return metricValue.period === period;
}

export function getMetricValueByMetricCode(
  metricValues: Array<MetricValue>,
  metricCode: MetricCode,
  metricPeriod?: MetricPeriod,
): MetricValue | null {
  const metricValue = metricValues.find(metricValue => {
    if (!metricPeriod) {
      return isEqualMetricCode(metricValue, metricCode);
    }

    return (
      isEqualMetricCode(metricValue, metricCode) && isEqualMetricPeriod(metricValue, metricPeriod)
    );
  });

  return metricValue || null;
}

export const getMetricsInGroupByCode = (
  metricGroups: Array<MetricGroup | DashboardMetricGroups>,
  metricCode: MetricCode,
): MetricValue[] => {
  const isEqualCodes = (metricGroup: MetricGroup | DashboardMetricGroups) =>
    metricGroup.code === metricCode;
  const metricGroup = R.find(isEqualCodes, metricGroups);

  return R.pathOr([], ['metrics'], metricGroup);
};

export function sortingMetricsByDate(a: MetricValue, b: MetricValue): number {
  const dateA = DateTime.fromISO(a.date).toSeconds();
  const dateB = DateTime.fromISO(b.date).toSeconds();

  return dateA - dateB;
}

// TODO: need refactor
export const mergeMetricValues = (
  array1: Array<MetricValue>,
  array2: Array<MetricValue>,
  key1: string,
  key2: string,
  mergeBy: keyof MetricValue,
): any[] => {
  const isFirstMain = array1.length >= array2.length;

  let mainArray: Array<MetricValue> = [];
  let minorArray: Array<MetricValue> = [];

  let mainKey = '';
  let minorKey = '';

  if (isFirstMain) {
    mainArray = array1;
    minorArray = array2;

    mainKey = key1;
    minorKey = key2;
  } else {
    mainArray = array2;
    minorArray = array1;

    mainKey = key2;
    minorKey = key1;
  }

  return mainArray.map(mainItem => {
    const findMetric = (itemMetric: MetricValue) => mainItem[mergeBy] === itemMetric[mergeBy];
    const fundedItem = R.find(findMetric, minorArray);
    const isFunded = Boolean(fundedItem);

    if (isFunded) {
      return {
        ...mainItem,
        [mainKey]: mainItem.value,
        [minorKey]: fundedItem?.value,
      };
    } else {
      return {
        ...mainItem,
        [mainKey]: mainItem.value,
      };
    }
  });
};

export function getLastMetricValue(metricValues: MetricValue[]): number | null {
  const lastMetricValues = R.last(metricValues);

  return lastMetricValues?.value ?? null;
}

export function getCurrentMetricValues(
  chartMetrics: Record<string, MetricValue[] | MergedMetricValue[]>,
): Record<string, number | null> {
  return R.mapObjIndexed(getLastMetricValue, chartMetrics);
}

export const getPrevMetricValue = (metricValues: MetricValue[]): number | null => {
  const lastIndex = metricValues.length - 1;
  const prevLastIndex = lastIndex - 1;
  const prevMetricValue = metricValues[prevLastIndex];

  return prevMetricValue?.value ?? null;
};

export const getPrevMetricValues = (
  chartMetrics: Record<string, MetricValue[] | MergedMetricValue[]>,
): Record<string, number | null> => R.mapObjIndexed(getPrevMetricValue, chartMetrics);

export const getMetricSourceLabel = (source: Source | null): string => {
  return source ? SOURCE_TO_LABEL[source] : 'Not Available';
};

export const sortScoreDetailsMetrics = (metrics: ScoreDetailsMetric[]): ScoreDetailsMetric[] => {
  const sortedMetrics = [] as ScoreDetailsMetric[];

  SCORE_RESULTS_ORDERED_METRICS.forEach(code => {
    const scoreDetailsMetric = metrics.find(metric => metric.code === code);

    if (!R.isNil(scoreDetailsMetric)) {
      sortedMetrics.push(scoreDetailsMetric);
    }
  });

  return sortedMetrics;
};

export const getDisplayedAdjustedValue = (
  adjustedValue: number | null,
  localChangedMetric: ChangedMetric | null,
): number | null => {
  if (!R.isNil(localChangedMetric)) {
    return localChangedMetric.adjustedValue;
  }

  return adjustedValue;
};

export function formatSaaSScore(saasScore: number | null | undefined) {
  return replaceIfNil(saasScore?.toFixed(0), '-');
}
