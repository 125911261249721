import { Format } from 'src/constants';
import { MetricValue } from '../graphql';

export enum MonitoringValueName {
  Committed = 'committed',
  Deployed = 'deployed',
  AdvanceRate = 'advanceRate',
}

export enum MonitoringDataKey {
  Companies = 'companies',
  Metrics = 'metrics',
  Values = 'values',
}

export type MonitoringValues = {
  date: string;
  [MonitoringValueName.Committed]: number | null;
  [MonitoringValueName.Deployed]: number | null;
  [MonitoringValueName.AdvanceRate]: number | null;
};

export type MonitoringCompany = {
  companyId: string;
  companyName: string;
  portfolioCompanyId: string;

  monitoringValues: Array<MonitoringValues>;
  monitoringMetrics: Array<MetricValue>;
};

export type PortfolioMonitoringData = {
  monitoringData: Array<MonitoringCompany>;
};

export type MonitoringMetricData = {
  code: string;
  name: string;
  format: Format;
};

export type MonitoringCompanyData = {
  companyId: string;
  companyName: string;
  portfolioCompanyId: string;
};

export type MonitoringTotalCells = Record<string, number>;

export type MonitoringTableCells = Record<string, MetricValue | number | null>;

export type MonitoringTableRows = Record<string, MonitoringTableCells> & {
  totals?: MonitoringTotalCells;
};

export type PortfolioMonitoringTableData = {
  [MonitoringDataKey.Companies]: Record<string, MonitoringCompanyData>;
  [MonitoringDataKey.Metrics]: Record<string, MonitoringMetricData>;
  [MonitoringDataKey.Values]: Record<string, MonitoringTableRows>;
};
