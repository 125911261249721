import React from 'react';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from '@material-ui/core';
import { Field, Form, SelectField, TextField, useModal } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { DIALOGS } from './constants';
import { EVENT_RESOLVER_MUTATION, EventType } from 'src/graphql';
import { BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST, APP_ROLES } from 'src/types';
import { BigfootEmployeeInvitationSendEventSchema } from 'src/utils/schemas/event';
import { validateDataByYup, commonErrorResolver, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(60),
  },
}));

interface UserInviteDialog {}

export const UserInviteDialog: React.FC<UserInviteDialog> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, closeModal, openModal } = useModal(DIALOGS.USER_INVITE_CUSTOM_DIALOG);

  const roleOptions = React.useMemo(
    () =>
      BIGFOOT_EMPLOYEE_APP_ROLE_NAMES_LIST.map(name => ({
        label: APP_ROLES[name].title,
        value: name,
      })),
    [],
  );

  const [eventResolver] = useMutation(EVENT_RESOLVER_MUTATION, {
    refetchQueries: ['UsersTableContent'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      notification.success(t('teammate_invite_success'));
      closeModal();
    },
    onError: error => notification.error(commonErrorResolver(error)),
  });

  const onSubmit = React.useCallback(
    async data => {
      await eventResolver({
        variables: {
          event: data,
        },
      });
    },
    [eventResolver],
  );

  const onClose = React.useCallback(
    (pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();
          },
        });
      } else {
        closeModal();
      }
    },
    [closeModal, openModal],
  );

  const initialValues = React.useMemo(
    () => ({
      type: EventType.BigfootEmployeeInvitationSend,
    }),
    [],
  );

  // Instead of using setTimeout(form.rest) everywhere
  // we can just return null if the dialog is not open
  if (!open) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => validateDataByYup(BigfootEmployeeInvitationSendEventSchema, values)}
    >
      {({ handleSubmit, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle>Invite User</DialogTitle>

          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Field
                  component={TextField}
                  name="payload.firstName"
                  label="First Name*"
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="payload.lastName"
                  label="Last Name*"
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="payload.email"
                  label="Email*"
                  disabled={submitting}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={SelectField}
                  name="payload.appRoleName"
                  label="Role*"
                  disabled={submitting}
                  options={roleOptions}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" disabled={submitting} onClick={() => onClose(pristine)}>
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Invite
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
