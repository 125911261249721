import React from 'react';
import * as R from 'ramda';
import { Grid } from '@material-ui/core';

import { SingleAttachment } from './SingleAttachment';
import { Document, File } from 'src/graphql';

interface MultipleAttachmentsProps {
  attachments: Document[];
}

export const MultipleAttachments: React.FC<MultipleAttachmentsProps> = props => {
  const { attachments } = props;

  return (
    <Grid container spacing={3}>
      {attachments.map((attach: Document) => {
        const file = R.pathOr({}, ['file'], attach);
        const fileId = R.pathOr('', ['id'], file);

        return (
          <Grid key={fileId} md={6} lg={4} xl={3} item>
            <SingleAttachment file={file as File} />
          </Grid>
        );
      })}
    </Grid>
  );
};
