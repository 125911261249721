import * as R from 'ramda';
import { useQuery } from 'react-apollo';

import { METRICS_COMPANY_HEADER_QUERY, MetricValue } from 'src/graphql';
import { COMPANY_HEADER_METRICS } from 'src/constants';
import { CompanyHeaderMetricCode } from 'src/types';
import { getAdjustedOrOriginalMetricValue, getEndOfPreviousMonth, getNowDate } from 'src/utils';

type MetricHeaderValue = {
  value: number | null | undefined;
  tier: number | null | undefined;
};

type FacilityHeaderValue = {
  fundingDate: string | null | undefined;
  amountCommitted: number | null | undefined;
  totalAmountDeployed: number | null | undefined;
};

type CompanyHeaderMetrics = {
  metricsData: Record<CompanyHeaderMetricCode, MetricHeaderValue>;
  facilityData: FacilityHeaderValue;
  loading: boolean;
};

type CompanyHeaderMetricsGroup = {
  code: CompanyHeaderMetricCode;
  metrics: {
    items: Array<MetricValue>;
  };
};

const DEFAULT_UNDEFINED_METRIC = { value: undefined, tier: undefined };

const getLastMetricValueByMetricCode = (
  companyHeaderMetricCode: CompanyHeaderMetricCode,
  metricGroups: Array<CompanyHeaderMetricsGroup>,
): MetricHeaderValue => {
  const metricGroupItem = metricGroups.find(metric => metric.code === companyHeaderMetricCode);

  if (Boolean(metricGroupItem) && !R.isEmpty(metricGroupItem?.metrics.items)) {
    const lastMetric = metricGroupItem?.metrics.items[0];
    const value = getAdjustedOrOriginalMetricValue(lastMetric);
    const tier = lastMetric?.tier;

    return {
      value,
      tier,
    };
  }

  return DEFAULT_UNDEFINED_METRIC;
};

export const useCompanyHeaderMetrics = (companyId: string): CompanyHeaderMetrics => {
  const previousMonth = getEndOfPreviousMonth(getNowDate());

  const { data: metricsHeader, loading: metricsLoading } = useQuery(METRICS_COMPANY_HEADER_QUERY, {
    variables: {
      companyId,
      date: previousMonth,
    },
    skip: !Boolean(companyId),
  });

  const metrics = R.pathOr([], ['company', 'metrics', 'groups'], metricsHeader);
  const fundingDate = R.pathOr(null, ['getFundingDate', 'fundingDate'], metricsHeader);
  const amountCommitted = R.pathOr(null, ['getAmountCommitted', 'amountCommitted'], metricsHeader);
  const totalAmountDeployed = R.pathOr(
    null,
    ['getTotalAmountDeployed', 'totalAmountDeployed'],
    metricsHeader,
  );

  const facilityData = {
    fundingDate,
    amountCommitted,
    totalAmountDeployed,
  };

  const metricsData = Object.fromEntries(
    COMPANY_HEADER_METRICS.map((metricCode: CompanyHeaderMetricCode) => [
      metricCode,
      getLastMetricValueByMetricCode(metricCode, metrics),
    ]),
  );

  return {
    metricsData,
    facilityData,
    loading: metricsLoading,
  } as CompanyHeaderMetrics;
};
