import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { useMutation } from 'react-apollo';

import { useAuth, useCurrentUser } from 'src/app-builder';
import { AuthRoutes } from 'src/constants';
import { EventType, EVENT_RESOLVER_MUTATION } from 'src/graphql';
import { EventResolverResponse } from 'src/types';
import { authErrorResolver } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => {
  const { fontFamily } = theme.typography;
  return {
    logoutButton: {
      cursor: 'pointer',
      textDecoration: 'underline',
      background: 'none',
      border: 'none',
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontFamily,
    },
  };
});

enum VerifyingStage {
  Checking = 'CHECKING',
  EmailIsNotConfirmed = 'EMAIL_IS_NOT_CONFIRMED',
  EmailIsConfirmed = 'EMAIL_IS_CONFIRMED',
  Error = 'ERROR',
}

export const ConfirmationPage: React.FC = () => {
  const classes = useStyles();

  const { authClient } = useAuth();
  const { isEmailVerified, email } = authClient.getState();

  const { user } = useCurrentUser();

  // It's necessary to memoize email value to avoid
  // behavior when after click on the Logout button
  // user sees that his email value disappears for a second.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentUserEmail = useMemo(() => email, []);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [verifyingStage, setVerifyingStage] = useState<VerifyingStage>(() =>
    isEmailVerified ? VerifyingStage.EmailIsConfirmed : VerifyingStage.Checking,
  );

  const [eventResolver] =
    useMutation<{ eventResolver: EventResolverResponse }>(EVENT_RESOLVER_MUTATION);

  const onLogout = React.useCallback(async () => {
    await authClient.logout();

    await authClient.purgeState();
  }, [authClient]);

  const verifyEmail = React.useCallback(async () => {
    if (user) {
      try {
        // 1. Check if the user came from invitation or not
        // user should have relation with some invitation
        const hasUserInvitation = Boolean(user?.invitation);

        if (hasUserInvitation) {
          // 2. If it's true, so update user manually in the auht0 and set email_verified to true
          const { data } = await eventResolver({
            variables: {
              event: {
                type: EventType.UserEmailVerify,
                payload: {
                  email,
                },
              },
            },
          });

          if (data?.eventResolver?.type === EventType.UserEmailVerify) {
            if (data?.eventResolver?.response?.emailVerified) {
              setVerifyingStage(VerifyingStage.EmailIsConfirmed);
            } else {
              setVerifyingStage(VerifyingStage.EmailIsNotConfirmed);
            }
          }
        } else {
          // 3. If it isn't true so send him a verification email
          // eventResolver (type: UserVerificationEmailSend)
          const { data } = await eventResolver({
            variables: {
              event: {
                type: EventType.UserVerificationEmailSend,
                payload: {
                  email,
                },
              },
            },
          });

          if (data?.eventResolver?.type === EventType.UserVerificationEmailSend) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (data?.eventResolver?.response?.emailVerified) {
              setVerifyingStage(VerifyingStage.EmailIsConfirmed);
            } else {
              setVerifyingStage(VerifyingStage.EmailIsNotConfirmed);
            }
          }
        }
      } catch (e) {
        setVerifyingStage(VerifyingStage.Error);
        setErrorMessage(authErrorResolver(e));
      }
    }
  }, [email, eventResolver, user]);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  // Handle users who follow the link in the email and previously
  // logged out or follow the link from different browser
  if (!user) {
    return <Redirect to={AuthRoutes.Login} />;
  }

  switch (verifyingStage) {
    case VerifyingStage.EmailIsConfirmed: {
      authClient.setState({ isEmailVerified: true });
      return <Redirect to={'/'} />;
    }

    case VerifyingStage.EmailIsNotConfirmed: {
      return (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography component="p">We sent a confirmation email to</Typography>
          <Typography component="p">{currentUserEmail}</Typography>
          <Typography component="p">Click on the link inside to confirm your account!</Typography>
          <Box>
            <button type="button" className={classes.logoutButton} onClick={onLogout}>
              Logout
            </button>
          </Box>
        </Box>
      );
    }

    case VerifyingStage.Error: {
      return (
        <Box
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography component="p">{errorMessage}</Typography>

          <Box>
            <button type="button" className={classes.logoutButton} onClick={onLogout}>
              Logout
            </button>
          </Box>
        </Box>
      );
    }

    default: {
      return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      );
    }
  }
};
