import * as R from 'ramda';
import { Draw, Facility } from 'src/graphql';

import {
  getDifferenceBetweenTwoDates,
  getDateTimeStartOf,
  DateFormatPatterns,
  getDateTimeEndOf,
  formatMonths,
  sortFacilities,
  getTime,
} from 'src/utils';
import { PREPAYMENT_VALUES } from 'src/constants';

type AmortizingOrIOPeriodResult = {
  isAmortizing: boolean;
  value: number;
};

export function calculateAmortizing(
  fundedDate: string,
  iOPeriod: number,
): AmortizingOrIOPeriodResult {
  const todayEndOfMonth: string = getDateTimeEndOf('month').toISO();
  const fundedStartOfMonth: string = getDateTimeStartOf('month', fundedDate).toISO();
  const fundedEndOfMonth: string = getDateTimeEndOf('month', fundedDate).toFormat(
    DateFormatPatterns.shortDateWithDash,
  );

  const { months } = getDifferenceBetweenTwoDates(fundedStartOfMonth, todayEndOfMonth, 'month');
  const countMonths = Math.ceil(months || 0);

  const isEndOfMonthFundedDate = fundedDate === fundedEndOfMonth;

  const diffOfMonths: number = isEndOfMonthFundedDate ? countMonths - 1 : countMonths;
  const isAmortizing = diffOfMonths > iOPeriod;

  const value: number = isAmortizing ? diffOfMonths - iOPeriod : diffOfMonths;

  return { isAmortizing, value };
}

export function getPrepayment(draw: Draw | null | undefined): string | null {
  if (R.isNil(draw)) {
    return null;
  }

  const formattedPrepayment =
    draw?.prepayment === PREPAYMENT_VALUES.reducedCashOnCash
      ? draw?.prepayment
      : formatMonths(draw?.prepaymentMonths);

  return formattedPrepayment;
}

export function getMostRecentDrawFromFacilities(facilities: Facility[]): Draw | undefined {
  const recentFacilities = sortFacilities(facilities, 'DESC');
  const recentFacility = recentFacilities[0];

  const draws = R.pathOr([], ['draws', 'items'], recentFacility);

  if (draws.length > 0) {
    const byFundedDate = R.descend((draw: Draw): number => getTime(draw.fundedDate));
    const sortedDraws = R.sort(byFundedDate, draws);

    return sortedDraws[0];
  }

  return undefined;
}

export function getOldestDrawDateOfOldestFacility(facilities: Facility[]): string | undefined {
  const sortedFacilities = sortFacilities(facilities, 'ASC');

  const oldestFacility = sortedFacilities[0];

  if (oldestFacility?.draws?.items) {
    const byFundedDate = R.ascend((draw: Draw): number => getTime(draw.fundedDate));

    const oldestDraw = R.sort(byFundedDate, oldestFacility.draws.items)[0];
    return oldestDraw?.fundedDate;
  }

  return undefined;
}
