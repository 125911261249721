import * as React from 'react';
import { Button, Theme, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface SubmitButtonProps extends ButtonProps {
  loading?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  loading = false,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button type="submit" disabled={loading || disabled} {...rest} />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
