import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { MetricValue } from 'src/graphql';
import { Format } from 'src/constants';
import { Nullable } from 'src/types';
import {
  getAdjustedOrOriginalMetricValue,
  getFormattedMetricValue,
  replaceIfNil,
  isNegative,
} from 'src/utils';

const useStyles = makeStyles(() => ({
  negativeMetric: {
    color: '#eb4336',
  },
}));

interface MetricValueCellProps {
  metricValue: Nullable<MetricValue>;
}

export const MetricValueCell: React.FC<MetricValueCellProps> = ({ metricValue }) => {
  const classes = useStyles();

  const value = getAdjustedOrOriginalMetricValue(metricValue);

  if (R.isNil(value)) {
    return <React.Fragment>-</React.Fragment>;
  }

  const metricFormat = (metricValue?.metric?.format as Format) || Format.Count;

  const formattedValue = getFormattedMetricValue(value, metricFormat);

  return (
    <div
      className={clsx({
        [classes.negativeMetric]: isNegative(value),
      })}
    >
      {replaceIfNil(formattedValue, '-')}
    </div>
  );
};
