import React from 'react';
import { Grid, LinearProgress, makeStyles, Paper, Theme } from '@material-ui/core';

import { EmptyCompaniesScreen } from './';

interface LayoutProps {
  loading: boolean;
  isFirstLoading: boolean;
  hasCompanies: boolean;
  renderHeader: React.ReactNode;
  renderTable: React.ReactNode;
  renderPagination: React.ReactNode;
  renderToolbar: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
  },
  root: {
    padding: 16,
  },
  tableWrapper: {
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    padding: 16,
    marginBottom: theme.spacing(4),
  },
}));

const Layout: React.FC<LayoutProps> = ({
  loading,
  isFirstLoading,
  hasCompanies,
  renderHeader,
  renderTable,
  renderPagination,
  renderToolbar,
}) => {
  const classes = useStyles({ loading });

  const renderContentTable = () => {
    if (loading) {
      return <LinearProgress />;
    }

    if (!isFirstLoading && !hasCompanies) {
      return <EmptyCompaniesScreen />;
    }

    return (
      <Grid className={classes.tableWrapper} item>
        {renderTable}

        {renderPagination}
      </Grid>
    );
  };

  return (
    <Grid className={classes.root} container spacing={4}>
      {renderHeader}
      <Grid className={classes.main}>
        <Paper>
          {renderToolbar}

          {renderContentTable()}
        </Paper>
      </Grid>
    </Grid>
  );
};

Layout.displayName = 'Layout';

export { Layout };
