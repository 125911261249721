import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { useAuth, FORM_ERROR } from 'src/app-builder';

import { LOGIN_CONNECTION } from 'src/constants';

interface AccountSecurityPageInterface {
  email: string;
}

export const AccountSecurityPage: React.FC<AccountSecurityPageInterface> = props => {
  const { email } = props;

  const [message, setMessage] = React.useState('');

  const { authClient } = useAuth();

  const changePassword = React.useCallback(
    data =>
      new Promise<string>((resolve, reject) => {
        authClient.auth0.changePassword(data, (err: any, message: string) => {
          if (err) {
            return reject(err);
          }
          return resolve(message);
        });
      }),
    [authClient],
  );

  const onSendLink = React.useCallback(async () => {
    try {
      const message = await changePassword({
        connection: LOGIN_CONNECTION,
        email,
      });

      setMessage(message);
    } catch (e) {
      return { [FORM_ERROR]: e?.description || 'Something went wrong during change password' };
    }
  }, [changePassword, email]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h6">Sign-in &amp; Security</Typography>
      </Grid>

      <Grid item>
        <Typography style={{ marginBottom: 15 }}>
          Send link on <b style={{ fontWeight: 500 }}>{email}</b> for change password
        </Typography>

        {message ? (
          <Typography>{message}</Typography>
        ) : (
          <Button color="secondary" variant="contained" type="submit" onClick={onSendLink}>
            Send link
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
