import React from 'react';
import { Grid, Theme, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.border.table}`,
  },
  content: {
    padding: theme.spacing(2),
    flex: 1,
  },
}));

interface LayoutProps {
  loading: boolean;
  table: React.ReactNode;
  submit: React.ReactNode;
  scaleByTier: React.ReactNode;
  loanMultipliers: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({
  table,
  submit,
  scaleByTier,
  loading,
  loanMultipliers,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.header}
        item
        container
        alignItems="center"
        justify="space-between"
        direction="row"
        spacing={0}
      >
        <Typography variant="subtitle1">Master Data</Typography>
      </Grid>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column">
          {table}
          {scaleByTier}
          {loanMultipliers}
          {submit}
        </Grid>
      )}
    </>
  );
};
