import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Theme, Avatar } from '@material-ui/core';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { FileInput } from '8base-react-sdk';

interface AvatarFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarWrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.spacing(20),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  avatarButton: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(4),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));

export const AvatarField: React.FC<AvatarFieldProps> = ({ input }) => {
  const classes = useStyles();

  return (
    <FileInput onChange={input.onChange} value={input.value}>
      {({ pick }): React.ReactNode => (
        <div className={classes.avatarWrapper}>
          <Avatar className={classes.avatar} src={input.value?.downloadUrl} />
          <div
            className={classes.avatarButton}
            onClick={(): void => {
              pick({});
            }}
          >
            <Typography variant="caption">Change</Typography>
          </div>
        </div>
      )}
    </FileInput>
  );
};
