import * as React from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { ListItem, ListItemProps } from '@material-ui/core';

interface ListNavLinkItemProps extends Omit<ListItemProps<'a'>, 'component'> {
  to: string;
}

export const ListNavLinkItem: React.FC<ListNavLinkItemProps> = React.forwardRef(
  function ListNavLinkItem({ to, children, className, classes, ...rest }, ref) {
    const location = useLocation();

    const selected: boolean = React.useMemo(
      () => !!matchPath(location.pathname, { path: to }) as boolean,
      [location.pathname, to],
    );

    return (
      <ListItem
        component={RouterLink}
        to={to}
        selected={selected}
        className={className}
        classes={classes}
        {...rest}
        button
        ref={ref}
      >
        {children}
      </ListItem>
    );
  },
);
