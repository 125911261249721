import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import {
  Metric,
  METRICS_LIST_QUERY,
  MetricsListQuery,
  MetricsListQueryVariables,
  METRIC_VALUES_LIST_QUERY,
  MetricValuesListQuery,
  MetricValuesListQueryVariables,
} from 'src/graphql';
import {
  MetricDuration,
  REPORT_METRIC_LABELS,
  REPORT_METRIC_TYPES,
  TABLE_DELIMITER,
} from 'src/constants';
import { Merge, Report as MetricReport, MetricPeriod, MetricValuesGroup } from 'src/types';
import {
  DEFAULT_PERIOD,
  generateMetrics,
  sortMetricsByFilter,
  subtractDate,
  getNowDate,
} from 'src/utils';
import { MetricCellName, MetricCellDate } from 'src/components';
import { MetricNameColumnTitle } from 'src/shared/metrics/TableUnderwritingReport/MetricNameColumnTitle';
import { CellRenderer } from 'src/shared/metrics/TableUnderwritingReport/CellRenderer';

export type SortedMetrics = (
  | string
  | Merge<
      Metric,
      {
        code: string;
        name: string;
      }
    >
)[];

interface ExportData {
  rows: any;
  columns: any;
}

interface UnderwritingTable {
  data: {
    metrics: SortedMetrics;
    dates: Array<string>;
    exportData: ExportData;
  };
  loading: boolean;
  refetch: () => void;
}

const buildDateFilter = (from: string | null | undefined, till?: string | null) => {
  if (!R.isNil(from) && R.isNil(till)) {
    return {
      date: {
        gte: from,
      },
    };
  } else {
    return {
      AND: [
        {
          date: {
            gte: from,
          },
        },
        {
          date: {
            lte: till,
          },
        },
      ],
    };
  }
};

export const useUnderwritingTable = (
  companyId: string,
  from: string | null,
  till: string | null,
  canEditMetrics: boolean,
  activeReport?: MetricReport,
): UnderwritingTable => {
  const columnTitle = 'Metric Name';

  const underwritingMetricsKeys =
    REPORT_METRIC_TYPES.find(
      metricType => metricType.label === REPORT_METRIC_LABELS.UnderwritingPackage,
    )?.value || [];

  const weeklyMetricsKeys =
    REPORT_METRIC_TYPES.find(
      metricType => metricType.label === REPORT_METRIC_LABELS.WeeklyBankActivity,
    )?.value || [];

  const isUnderwritingReport = Boolean(
    activeReport?.label === REPORT_METRIC_LABELS.UnderwritingPackage,
  );

  const dateFilter = React.useMemo(() => {
    const hasFromDate = !R.isNil(from);
    const hasTillDate = !R.isNil(till);

    if (hasFromDate && hasTillDate) {
      return buildDateFilter(from, till);
    }

    if (hasFromDate && !hasTillDate) {
      return buildDateFilter(from);
    }

    if (!hasFromDate && hasTillDate) {
      const dateFromDefaultPeriodAgo = subtractDate(till as string, 'months', DEFAULT_PERIOD);
      return buildDateFilter(dateFromDefaultPeriodAgo, till);
    }

    const currentDate = getNowDate();
    const dateFrom = subtractDate(currentDate, 'months', DEFAULT_PERIOD);

    return buildDateFilter(dateFrom, currentDate);
  }, [from, till]);

  const { data: underwritingMetricsList, loading: underwritingMetricsListLoading } = useQuery<
    MetricsListQuery,
    MetricsListQueryVariables
  >(METRICS_LIST_QUERY, {
    variables: {
      filter: { code: { in: underwritingMetricsKeys } },
    },
  });

  const { data: weeklyMetricsList, loading: weeklyMetricsListLoading } = useQuery<
    MetricsListQuery,
    MetricsListQueryVariables
  >(METRICS_LIST_QUERY, {
    variables: {
      filter: { code: { in: weeklyMetricsKeys } },
    },
  });

  const {
    data: underwritingMetricValues,
    loading: underwritingMetricValuesLoading,
    refetch: refetchUnderwritingMetricValues,
  } = useQuery<MetricValuesListQuery, MetricValuesListQueryVariables>(METRIC_VALUES_LIST_QUERY, {
    variables: {
      filter: {
        metric: { code: { in: underwritingMetricsKeys } },
        company: { id: { equals: companyId } },
        ...dateFilter,
        period: { equals: MetricPeriod.Month },
      },
    },
  });

  const {
    data: weeklyMetricValues,
    loading: weeklyMetricValuesLoading,
    refetch: refetchWeeklyMetricValues,
  } = useQuery<MetricValuesListQuery, MetricValuesListQueryVariables>(METRIC_VALUES_LIST_QUERY, {
    variables: {
      filter: {
        metric: { code: { in: weeklyMetricsKeys } },
        company: { id: { equals: companyId } },
        ...dateFilter,
        period: { equals: MetricPeriod.Week },
      },
    },
  });

  const metricsLoading =
    underwritingMetricsListLoading ||
    weeklyMetricsListLoading ||
    underwritingMetricValuesLoading ||
    weeklyMetricValuesLoading;

  const underwritingMetrics = R.compose(
    (items: Merge<Metric, { code: string; name: string }>[]) =>
      sortMetricsByFilter(items, underwritingMetricsKeys),
    R.pathOr([], ['metricsList', 'items']),
  )(underwritingMetricsList);

  const weeklyMetrics = R.compose(
    (items: Merge<Metric, { code: string; name: string }>[]) =>
      sortMetricsByFilter(items, weeklyMetricsKeys),
    R.pathOr([], ['metricsList', 'items']),
  )(weeklyMetricsList);

  const metrics = isUnderwritingReport ? underwritingMetrics : weeklyMetrics;

  const underwritingRows = React.useMemo(
    () =>
      R.compose(
        (values: MetricValuesGroup[]) =>
          generateMetrics(underwritingMetrics, values, from, till, MetricDuration.Months),
        R.pathOr([], ['metricValuesList', 'groups']),
      )(underwritingMetricValues),
    [underwritingMetricValues, underwritingMetrics, from, till],
  );

  const weeklyRows = React.useMemo(
    () =>
      R.compose(
        (values: MetricValuesGroup[]) =>
          generateMetrics(weeklyMetrics, values, from, till, MetricDuration.Weeks),
        R.pathOr([], ['metricValuesList', 'groups']),
      )(weeklyMetricValues),
    [weeklyMetricValues, weeklyMetrics, from, till],
  );

  const rows = isUnderwritingReport ? underwritingRows : weeklyRows;

  const underwritingDates = R.keys(underwritingRows);
  const weeklyDates = R.keys(weeklyRows);

  const dates = isUnderwritingReport ? underwritingDates : weeklyDates;

  const renderMetricName = (name: string) => {
    switch (name) {
      case 'MRR':
        return 'Ending ' + name;
      case 'Cash Runway (Banking) Weekly':
        return 'Cash Runway (Banking)';
      case TABLE_DELIMITER:
        return '';
      default:
        return name;
    }
  };

  const getColumns = (reportType: 'Underwriting' | 'Weekly'): Record<any, any>[] => {
    return [
      {
        title: <MetricNameColumnTitle title={columnTitle} />,
        width: 340,
        exportData: {
          title: columnTitle,
          key: 'name',
        },
        fixed: 'left',
        align: 'left',
        // eslint-disable-next-line react/display-name
        render: (metric: Metric) => (
          <MetricCellName key={metric.id} metric={metric} renderName={renderMetricName} />
        ),
        ellipsis: true,
      },
      ...(reportType === 'Underwriting' ? underwritingDates : weeklyDates).map(date => {
        return {
          title: <MetricCellDate date={date} />,
          exportData: { title: date, key: date },
          render: (row: Metric) => CellRenderer(row, rows, date, canEditMetrics),
          align: 'left',
          width: 106,
        };
      }),
    ];
  };

  const refetch = React.useCallback(() => {
    refetchUnderwritingMetricValues();
    refetchWeeklyMetricValues();
  }, [refetchUnderwritingMetricValues, refetchWeeklyMetricValues]);

  const underwritingColumns = getColumns('Underwriting');

  const weeklyColumns = getColumns('Weekly');

  const exportData = {
    columns: { underwritingColumns, weeklyColumns },
    rows: { underwritingRows, weeklyRows },
  };

  return {
    data: {
      metrics,
      dates,
      exportData,
    },
    loading: metricsLoading,
    refetch,
  };
};
