import * as React from 'react';
import { ListItemIcon, ListItemText, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { renderIcon } from '../utils';
import { ListNavLinkItem } from './ListNavLinkItem';

const useStyles = makeStyles((theme: Theme) => ({
  item: ({
    isExpanded,
  }: {
    isExpanded: boolean;
  }): CreateCSSProperties<{ isExpanded: boolean }> => ({
    height: 60,
    marginBottom: theme.spacing(1),

    '& .MuiSvgIcon-root': {
      width: 30,
      height: 30,
      fill: theme.palette.grey[400],
    },

    '&.Mui-selected': {
      '& .MuiSvgIcon-root': {
        fill: '#fff',
      },
    },

    ...(isExpanded
      ? { padding: '0 24px' }
      : {
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px',
        }),
  }),
  itemSelected: {
    color: '#fff',
    backgroundColor: 'transparent !important',

    '&:before': {
      content: "''",
      zIndex: -1,
      position: 'absolute',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 8px)',
      left: 8,
      top: 4,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 5,
    },
  },
  itemIcon: ({
    isExpanded,
  }: {
    isExpanded: boolean;
  }): CreateCSSProperties<{ isExpanded: boolean }> => ({
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    minWidth: 32,
    minHeight: 32,

    '& + .MuiListItemText-root': {
      marginLeft: isExpanded ? 8 : 0,
    },
  }),

  itemText: ({
    isExpanded,
  }: {
    isExpanded: boolean;
  }): CreateCSSProperties<{ isExpanded: boolean }> => ({
    whiteSpace: 'nowrap',
    opacity: isExpanded ? 1 : 0,
    flex: isExpanded ? 1 : 0,
  }),
}));

interface MainNavItemProps {
  to: string;
  icon: string | JSX.Element;
  title: string;
  isExpanded: boolean;
}

export const MainNavItem: React.FC<MainNavItemProps> = ({ to, icon, title, isExpanded }) => {
  const classes = useStyles({ isExpanded });

  const content = (
    <ListNavLinkItem classes={{ root: classes.item, selected: classes.itemSelected }} to={to}>
      <ListItemIcon className={classes.itemIcon}>{renderIcon(icon)}</ListItemIcon>

      {isExpanded && <ListItemText className={classes.itemText}>{title}</ListItemText>}
    </ListNavLinkItem>
  );

  if (isExpanded) return content;

  return (
    <Tooltip placement="right" title={title}>
      {content}
    </Tooltip>
  );
};
