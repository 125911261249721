import React from 'react';
import { useDebounce } from 'react-use';

import { Toolbar } from './';
import { useSearch } from 'src/hooks';

interface ToolbarContainerProps {
  initialSelectedDate: string;
  loading?: boolean;
  maxMonth?: string;
}

export const ToolbarContainer: React.FC<ToolbarContainerProps> = ({
  initialSelectedDate,
  loading = false,
  maxMonth,
}) => {
  const search = useSearch();

  const [searchValue, setSearchValue] = React.useState<string>('');

  const [selectedMonth, setSelectedMonth] = React.useState<string | null>(
    () => initialSelectedDate,
  );

  useDebounce(
    () => {
      if (search?.setSearch) search?.setSearch(searchValue);
    },
    500,
    [searchValue],
  );

  return (
    <Toolbar
      loading={loading}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      setSelectedMonth={setSelectedMonth}
      selectedMonth={selectedMonth}
      maxMonth={maxMonth}
    />
  );
};
