import React from 'react';
import { ApolloError } from 'apollo-client';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';

import { usePagination, useSearch } from 'src/hooks';
import {
  PORTFOLIO_OVERVIEW_QUERY,
  PortfolioOverviewQueryVariables,
  PortfolioOverviewQuery,
  PortfolioOverviewCompany,
} from 'src/graphql';

type PortfolioOverviewData = {
  totalCount: number;
  companies: Array<PortfolioOverviewCompany>;
};

type PortfolioOverviewHook = {
  data: PortfolioOverviewData;
  exportData: PortfolioOverviewData;
  exportDataLoading: boolean;
  dataFetchError: ApolloError | undefined;
  exportFetchError: ApolloError | undefined;
  loading: boolean;
};

type PortfolioOverviewParams = {
  date: string;
};

function getPortfolioOverviewDataFromQuery(
  data: PortfolioOverviewQuery | undefined,
): PortfolioOverviewData {
  const companies = R.pathOr([], ['getPortfolioOverviewReport', 'companies'], data);
  const totalCount = R.pathOr(0, ['getPortfolioOverviewReport', 'count'], data);

  return { companies, totalCount };
}

export const usePortfolioOverview = (params: PortfolioOverviewParams): PortfolioOverviewHook => {
  const { date } = params;

  const pagination = usePagination();
  const search = useSearch();

  const { data, loading, error: dataFetchError } = useQuery<
    PortfolioOverviewQuery,
    PortfolioOverviewQueryVariables
  >(PORTFOLIO_OVERVIEW_QUERY, {
    variables: {
      date,
      first: pagination.perPage,
      skip: pagination.page * pagination.perPage,
      search: search?.search,
    },
    fetchPolicy: 'no-cache',
  });

  const portfolioOverviewData = React.useMemo(() => {
    return getPortfolioOverviewDataFromQuery(data);
  }, [data]);

  const hasCompanies = Boolean(portfolioOverviewData?.companies?.length);

  const { data: exportData, loading: exportDataLoading, error: exportFetchError } = useQuery<
    PortfolioOverviewQuery,
    PortfolioOverviewQueryVariables
  >(PORTFOLIO_OVERVIEW_QUERY, {
    variables: {
      date,
    },
    fetchPolicy: 'no-cache',
    skip: !hasCompanies,
  });

  const exportPortfolioOverviewData = React.useMemo(() => {
    return getPortfolioOverviewDataFromQuery(exportData);
  }, [exportData]);

  return {
    data: portfolioOverviewData,
    exportData: exportPortfolioOverviewData,
    exportDataLoading,
    loading,
    dataFetchError,
    exportFetchError,
  };
};
