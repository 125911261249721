export enum PrimaryCustomerBase {
  SMB = 'SMB',
  Enterprise = 'Enterprise',
}

export enum Structure {
  Single = 'Term Loan (single draw)',
  Multi = 'Term Loan (multi draw)',
  RBF = 'RBF',
}

export const PRIMARY_CUSTOMER_BASE_OPTIONS = [
  {
    value: PrimaryCustomerBase.Enterprise,
    label: PrimaryCustomerBase.Enterprise,
  },
  {
    value: PrimaryCustomerBase.SMB,
    label: PrimaryCustomerBase.SMB,
  },
];

export const CAPITAL_SPEND_ON_OPTIONS = [
  'Customer Acquisition',
  'Hiring',
  'Product Development',
  'Make Acquisition',
].map((value: string) => ({ value, label: value }));

export const CAPITAL_TYPES_OPTIONS = [
  'Term Loan',
  'RBF',
  'Line of Credit',
  'Equity',
].map((value: string) => ({ value, label: value }));

export const FROM_WHOM_CAPITAL_RAISED_OPTIONS = [
  'Venture Capital',
  'Angel Capital',
  'Friends & Family',
].map((value: string) => ({ value, label: value }));

export const FROM_WHOM_BORROWED = [
  'Bank Lender',
  'Non-bank Lender',
  'Friends & Family',
].map((value: string) => ({ value, label: value }));

export const PRIMARY_BUSINESS_MODEL_OPTIONS = [
  'B2B SaaS',
  'B2B Marketplace/Transactional',
  'B2B Tech-Enabled Services',
  'B2C',
  'Other',
  'Hybrid',
].map((value: string) => ({ value, label: value }));

export const PREPAYMENT_VALUES = {
  reducedCashOnCash: 'Reduced cash on cash',
  months: '# of months',
};

export const PREPAYMENT_OPTIONS = Object.values(PREPAYMENT_VALUES).map((value: string) => ({
  value,
  label: value,
}));

export const STRUCTURE_OPTIONS = Object.values(Structure).map((value: string) => ({
  label: value,
  value,
}));

export const CLOSING_FEE_OPTIONS = [
  {
    label: 'Closing Fee % (draw)',
    value: 'draw',
  },
  {
    label: 'Closing Fee % (facility)',
    value: 'facility',
  },
];

export const DEFAULT_COUNT_MONTHS = 6;
