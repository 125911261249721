export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as `2007-12-25T16:15:30Z`, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A time string at UTC, such as `16:15:30Z`, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** A date string, such as `2007-12-25`, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `BigInt` scalar type represents whole numeric values. */
  BigInt: any;
};

/** LoginResponse */
export type LoginResponse = {
  __typename?: 'LoginResponse';
  success?: Maybe<Scalars['Boolean']>;
  auth?: Maybe<Auth>;
  workspaces?: Maybe<Array<WorkspaceInfo>>;
};

/** LoginResponseWorkspace name and id */
export type WorkspaceInfo = {
  __typename?: 'WorkspaceInfo';
  workspace?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auth response */
export type Auth = {
  __typename?: 'Auth';
  refreshToken?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
};

/** Async Session */
export type AsyncSession = {
  __typename?: 'AsyncSession';
  sessionId: Scalars['String'];
};

/** ImportedTable */
export type ImportedTable = {
  __typename?: 'ImportedTable';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** MissingRelation */
export type MissingRelation = {
  __typename?: 'MissingRelation';
  table: Scalars['String'];
};

/** FunctionInfo */
export type FunctionInfo = {
  name: Scalars['String'];
  functionType: FunctionType;
  description?: Maybe<Scalars['String']>;
  application?: Maybe<Application>;
};

/** FunctionType */
export enum FunctionType {
  Resolver = 'resolver',
  Trigger = 'trigger',
  Webhook = 'webhook',
  Task = 'task',
  Schedule = 'schedule',
}

/** Application */
export type Application = {
  __typename?: 'Application';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  appType: Scalars['String'];
  status: ApplicationStatusEnum;
};

/** Application Status Enum */
export enum ApplicationStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/** FunctionWebhookInfo */
export type FunctionWebhookInfo = FunctionInfo & {
  __typename?: 'FunctionWebhookInfo';
  name: Scalars['String'];
  functionType: FunctionType;
  description?: Maybe<Scalars['String']>;
  httpMethod: Scalars['String'];
  workspaceRelativePath: Scalars['String'];
  workspaceFullPath: Scalars['String'];
  application?: Maybe<Application>;
};

/** FunctionTriggerInfo */
export type FunctionTriggerInfo = FunctionInfo & {
  __typename?: 'FunctionTriggerInfo';
  name: Scalars['String'];
  functionType: FunctionType;
  description?: Maybe<Scalars['String']>;
  operation: Scalars['String'];
  tableName: Scalars['String'];
  type: Scalars['String'];
  application?: Maybe<Application>;
};

/** FunctionResolverInfo */
export type FunctionResolverInfo = FunctionInfo & {
  __typename?: 'FunctionResolverInfo';
  name: Scalars['String'];
  functionType: FunctionType;
  description?: Maybe<Scalars['String']>;
  gqlType: Scalars['String'];
  application?: Maybe<Application>;
};

/** FunctionTaskInfo */
export type FunctionTaskInfo = FunctionInfo & {
  __typename?: 'FunctionTaskInfo';
  name: Scalars['String'];
  functionType: FunctionType;
  description?: Maybe<Scalars['String']>;
  scheduleExpression?: Maybe<Scalars['String']>;
  application?: Maybe<Application>;
};

/** FunctionInfoFilter */
export type FunctionInfoFilter = {
  name?: Maybe<Scalars['String']>;
  functionType?: Maybe<FunctionType>;
  description?: Maybe<Scalars['String']>;
};

/** FunctionInfoOrderBy */
export enum FunctionInfoOrderBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  FunctionTypeAsc = 'functionType_ASC',
  FunctionTypeDesc = 'functionType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
}

/** Schema Origin */
export type SchemaOrigin = {
  __typename?: 'SchemaOrigin';
  type: SchemaOriginType;
  provider?: Maybe<Scalars['String']>;
};

/** Schema Origin Type Enum */
export enum SchemaOriginType {
  Remote = 'REMOTE',
  Local = 'LOCAL',
  View = 'VIEW',
}

/** Table Schema Create Features */
export type TableSchemaCreateFeatures = {
  __typename?: 'TableSchemaCreateFeatures';
  ID: Scalars['Boolean'];
  UUID: Scalars['Boolean'];
  JSON: Scalars['Boolean'];
  NUMBER: Scalars['Boolean'];
  TEXT: Scalars['Boolean'];
  DATE: Scalars['Boolean'];
  SWITCH: Scalars['Boolean'];
  RELATION: Scalars['Boolean'];
  FILE: Scalars['Boolean'];
  SMART: Scalars['Boolean'];
  GEO: Scalars['Boolean'];
  ONE_WAY_RELATION: Scalars['Boolean'];
  MISSING_RELATION: Scalars['Boolean'];
};

/** Table Schema Meta Field Features */
export type TableSchemaMetaFieldFeatures = {
  __typename?: 'TableSchemaMetaFieldFeatures';
  name: Scalars['Boolean'];
  displayName: Scalars['Boolean'];
};

/** Table Schema Features */
export type TableSchemaFeatures = {
  __typename?: 'TableSchemaFeatures';
  create: TableSchemaCreateFeatures;
  update?: Maybe<TableSchemaMetaFieldFeatures>;
  computedFields: Scalars['Boolean'];
};

/** Field Schema Features */
export type FieldSchemaFeatures = {
  __typename?: 'FieldSchemaFeatures';
  update: Scalars['Boolean'];
  delete: Scalars['Boolean'];
};

/** Field Data Features */
export type FieldDataFeatures = {
  __typename?: 'FieldDataFeatures';
  create: Scalars['Boolean'];
  update: Scalars['Boolean'];
  sort: Scalars['Boolean'];
};

/** Table Data Features */
export type TableDataFeatures = {
  __typename?: 'TableDataFeatures';
  create: Scalars['Boolean'];
  update: Scalars['Boolean'];
  delete: Scalars['Boolean'];
};

/** Date Type Format Enum */
export enum DateTypeFormatEnum {
  Date = 'DATE',
  Datetime = 'DATETIME',
}

/** File Type Format Enum */
export enum FileTypeFormatEnum {
  File = 'FILE',
  Image = 'IMAGE',
}

/** Number Type Format Enum */
export enum NumberTypeFormatEnum {
  Number = 'NUMBER',
  Currency = 'CURRENCY',
  Percentage = 'PERCENTAGE',
  Fraction = 'FRACTION',
  Scientific = 'SCIENTIFIC',
}

/** Smart Type Format Enum */
export enum SmartTypeFormatEnum {
  Address = 'ADDRESS',
  Phone = 'PHONE',
}

/** Switch Type Format Enum */
export enum SwitchTypeFormatEnum {
  OnOff = 'ON_OFF',
  YesNo = 'YES_NO',
  TrueFalse = 'TRUE_FALSE',
  ActiveInactive = 'ACTIVE_INACTIVE',
  HighLow = 'HIGH_LOW',
  Custom = 'CUSTOM',
}

/** Text Type Format Enum */
export enum TextTypeFormatEnum {
  Unformatted = 'UNFORMATTED',
  Name = 'NAME',
  Ein = 'EIN',
  Email = 'EMAIL',
  Markdown = 'MARKDOWN',
  Html = 'HTML',
}

/** Field Type Attributes */
export type FieldTypeAttributes =
  | DateFieldTypeAttributes
  | FileFieldTypeAttributes
  | MissingRelationFieldTypeAttributes
  | NumberFieldTypeAttributes
  | SmartFieldTypeAttributes
  | SwitchFieldTypeAttributes
  | TextFieldTypeAttributes
  | UuidFieldTypeAttributes
  | GeoFieldTypeAttributes;

/** Date Field Attributes */
export type DateFieldTypeAttributes = {
  __typename?: 'DateFieldTypeAttributes';
  format: Scalars['String'];
};

/** File Field Attributes */
export type FileFieldTypeAttributes = {
  __typename?: 'FileFieldTypeAttributes';
  format: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  /** @deprecated Field is deprecated */
  showTitle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated */
  showUrl?: Maybe<Scalars['Boolean']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
  expiration?: Maybe<Scalars['Int']>;
};

/** MissingRelation Field Attributes */
export type MissingRelationFieldTypeAttributes = {
  __typename?: 'MissingRelationFieldTypeAttributes';
  missingTable: Scalars['String'];
};

/** Number Field Attributes */
export type NumberFieldTypeAttributes = {
  __typename?: 'NumberFieldTypeAttributes';
  format: Scalars['String'];
  precision?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
  isBigInt?: Maybe<Scalars['Boolean']>;
  autoIncrement?: Maybe<Scalars['Boolean']>;
};

/** Smart Field Attributes */
export type SmartFieldTypeAttributes = {
  __typename?: 'SmartFieldTypeAttributes';
  format: Scalars['String'];
  innerFields?: Maybe<Array<Maybe<CustomTableField>>>;
};

/** Custom Table Field Type */
export type CustomTableField = {
  __typename?: 'CustomTableField';
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldType>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<ComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributes>;
};

/** Switch Field Attributes */
export type SwitchFieldTypeAttributes = {
  __typename?: 'SwitchFieldTypeAttributes';
  format: Scalars['String'];
  listOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Text Field Attributes */
export type TextFieldTypeAttributes = {
  __typename?: 'TextFieldTypeAttributes';
  format: Scalars['String'];
  fieldSize?: Maybe<Scalars['Int']>;
};

/** UUID Field Attributes */
export type UuidFieldTypeAttributes = {
  __typename?: 'UUIDFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
};

/** Geo Field Attributes */
export type GeoFieldTypeAttributes = {
  __typename?: 'GeoFieldTypeAttributes';
  format: Scalars['String'];
  srid?: Maybe<Scalars['Int']>;
};

/** Table Attributes */
export type TableAttributes = ViewAttributes;

/** View Attributes */
export type ViewAttributes = {
  __typename?: 'ViewAttributes';
  query?: Maybe<Scalars['String']>;
};

/** Field types */
export enum FieldType {
  Id = 'ID',
  Uuid = 'UUID',
  Json = 'JSON',
  Number = 'NUMBER',
  Text = 'TEXT',
  Date = 'DATE',
  Switch = 'SWITCH',
  Relation = 'RELATION',
  File = 'FILE',
  Smart = 'SMART',
  Geo = 'GEO',
  OneWayRelation = 'ONE_WAY_RELATION',
  MissingRelation = 'MISSING_RELATION',
}

/** Computed field mode */
export enum ComputedFieldMode {
  Virtual = 'VIRTUAL',
  Stored = 'STORED',
}

/** Table List Filter */
export type TableListFilter = {
  applications?: Maybe<Array<Maybe<TableListApplicationFilter>>>;
  onlyUserTables?: Maybe<Scalars['Boolean']>;
  tableNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Table List Application Filter */
export type TableListApplicationFilter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Table Create Input */
export type TableCreateInput = {
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
};

/** Table Delete Input */
export type TableDeleteInput = {
  id: Scalars['ID'];
};

/** Table Update Input */
export type TableUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** View Create Input */
export type ViewCreateInput = {
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  query: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

/** View Update Input */
export type ViewUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Table Field Create Input */
export type TableFieldCreateInput = {
  tableId: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<ComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributesInput>;
  relation?: Maybe<RelationCreateInput>;
};

/** Field Type Attributes Input */
export type FieldTypeAttributesInput = {
  format?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
  isBigInt?: Maybe<Scalars['Boolean']>;
  autoIncrement?: Maybe<Scalars['Boolean']>;
  fieldSize?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['Int']>;
  listOptions?: Maybe<Array<Scalars['String']>>;
  maxSize?: Maybe<Scalars['Int']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
  srid?: Maybe<Scalars['Int']>;
};

/** Relation Create Input */
export type RelationCreateInput = {
  refTableId: Scalars['ID'];
  refFieldName?: Maybe<Scalars['String']>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList: Scalars['Boolean'];
  refFieldIsRequired: Scalars['Boolean'];
};

/** Table Field Delete Input */
export type TableFieldDeleteInput = {
  id: Scalars['ID'];
};

/** Table Field Update Input */
export type TableFieldUpdateInput = {
  id: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldType>;
  isList?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<ComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributesInput>;
  relation?: Maybe<RelationUpdateInput>;
};

/** Relation Update Input */
export type RelationUpdateInput = {
  refTableId?: Maybe<Scalars['ID']>;
  refFieldName?: Maybe<Scalars['String']>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
};

/** Table Field Position Update Input */
export type TableFieldPositionUpdateInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

/** Table Create Index Input */
export type IndexCreateInput = {
  tableId: Scalars['ID'];
  type: TableIndexType;
  columns: Array<TableIndexColumnInput>;
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export enum TableIndexType {
  Index = 'INDEX',
  Unique = 'UNIQUE',
}

/** Table Index Column Input */
export type TableIndexColumnInput = {
  name: Scalars['String'];
};

/** Table Update Index Input */
export type IndexUpdateInput = {
  id: Scalars['ID'];
  type?: Maybe<TableIndexType>;
  columns?: Maybe<Array<TableIndexColumnInput>>;
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Table Delete Index Input */
export type IndexDeleteInput = {
  id: Scalars['ID'];
};

/** Table */
export type Table = {
  __typename?: 'Table';
  id: Scalars['ID'];
  application?: Maybe<Application>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<TableField>>;
  indexes?: Maybe<Array<TableIndex>>;
  origin: SchemaOrigin;
  schemaFeatures: TableSchemaFeatures;
  dataFeatures: TableDataFeatures;
  attributes?: Maybe<TableAttributes>;
  description?: Maybe<Scalars['String']>;
};

/** TableField */
export type TableField = {
  __typename?: 'TableField';
  id: Scalars['ID'];
  table: Table;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  origin: SchemaOrigin;
  schemaFeatures: FieldSchemaFeatures;
  dataFeatures: FieldDataFeatures;
  isMeta: Scalars['Boolean'];
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<ComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributes>;
  relation?: Maybe<Relation>;
};

/** Table Index */
export type TableIndex = {
  __typename?: 'TableIndex';
  id: Scalars['ID'];
  table: Table;
  type: Scalars['String'];
  columns?: Maybe<Array<TableIndexColumn>>;
  name?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
};

/** Table Index Column */
export type TableIndexColumn = {
  __typename?: 'TableIndexColumn';
  name: Scalars['String'];
};

/** Relation */
export type Relation = {
  __typename?: 'Relation';
  refTable: Table;
  refField?: Maybe<TableField>;
  relationTableName?: Maybe<Scalars['String']>;
  relationFieldName?: Maybe<Scalars['String']>;
  refFieldName?: Maybe<Scalars['String']>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
};

/** Table List Response */
export type TableListResponse = {
  __typename?: 'TableListResponse';
  items?: Maybe<Array<Maybe<Table>>>;
  count?: Maybe<Scalars['Int']>;
};

/** FunctionInfo */
export type SystemFunctionInfo = {
  name: Scalars['String'];
  functionType: SystemFunctionType;
  description?: Maybe<Scalars['String']>;
  application?: Maybe<SystemApplication>;
};

/** FunctionType */
export enum SystemFunctionType {
  Resolver = 'resolver',
  Trigger = 'trigger',
  Webhook = 'webhook',
  Task = 'task',
  Schedule = 'schedule',
}

/** Application */
export type SystemApplication = {
  __typename?: 'SystemApplication';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  appType: Scalars['String'];
  status: SystemApplicationStatusEnum;
};

/** Application Status Enum */
export enum SystemApplicationStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/** FunctionWebhookInfo */
export type SystemFunctionWebhookInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionWebhookInfo';
  name: Scalars['String'];
  functionType: SystemFunctionType;
  description?: Maybe<Scalars['String']>;
  httpMethod: Scalars['String'];
  workspaceRelativePath: Scalars['String'];
  workspaceFullPath: Scalars['String'];
  application?: Maybe<SystemApplication>;
};

/** FunctionTriggerInfo */
export type SystemFunctionTriggerInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionTriggerInfo';
  name: Scalars['String'];
  functionType: SystemFunctionType;
  description?: Maybe<Scalars['String']>;
  operation: Scalars['String'];
  tableName: Scalars['String'];
  type: Scalars['String'];
  application?: Maybe<SystemApplication>;
};

/** FunctionResolverInfo */
export type SystemFunctionResolverInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionResolverInfo';
  name: Scalars['String'];
  functionType: SystemFunctionType;
  description?: Maybe<Scalars['String']>;
  gqlType: Scalars['String'];
  application?: Maybe<SystemApplication>;
};

/** FunctionTaskInfo */
export type SystemFunctionTaskInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionTaskInfo';
  name: Scalars['String'];
  functionType: SystemFunctionType;
  description?: Maybe<Scalars['String']>;
  scheduleExpression?: Maybe<Scalars['String']>;
  application?: Maybe<SystemApplication>;
};

/** FunctionInfoFilter */
export type SystemFunctionInfoFilter = {
  name?: Maybe<Scalars['String']>;
  functionType?: Maybe<SystemFunctionType>;
  description?: Maybe<Scalars['String']>;
};

/** FunctionInfoOrderBy */
export enum SystemFunctionInfoOrderBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  FunctionTypeAsc = 'functionType_ASC',
  FunctionTypeDesc = 'functionType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
}

/** FunctionLogEntry */
export type SystemFunctionLogEntry = {
  __typename?: 'SystemFunctionLogEntry';
  timestamp?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
};

/** Schema Origin */
export type SystemSchemaOrigin = {
  __typename?: 'SystemSchemaOrigin';
  type: SystemSchemaOriginType;
  provider?: Maybe<Scalars['String']>;
};

/** Schema Origin Type Enum */
export enum SystemSchemaOriginType {
  Remote = 'REMOTE',
  Local = 'LOCAL',
  View = 'VIEW',
}

/** Table Schema Create Features */
export type SystemTableSchemaCreateFeatures = {
  __typename?: 'SystemTableSchemaCreateFeatures';
  ID: Scalars['Boolean'];
  UUID: Scalars['Boolean'];
  JSON: Scalars['Boolean'];
  NUMBER: Scalars['Boolean'];
  TEXT: Scalars['Boolean'];
  DATE: Scalars['Boolean'];
  SWITCH: Scalars['Boolean'];
  RELATION: Scalars['Boolean'];
  FILE: Scalars['Boolean'];
  SMART: Scalars['Boolean'];
  GEO: Scalars['Boolean'];
  ONE_WAY_RELATION: Scalars['Boolean'];
  MISSING_RELATION: Scalars['Boolean'];
};

/** Table Schema Meta Field Features */
export type SystemTableSchemaMetaFieldFeatures = {
  __typename?: 'SystemTableSchemaMetaFieldFeatures';
  name: Scalars['Boolean'];
  displayName: Scalars['Boolean'];
};

/** Table Schema Features */
export type SystemTableSchemaFeatures = {
  __typename?: 'SystemTableSchemaFeatures';
  create: SystemTableSchemaCreateFeatures;
  update?: Maybe<SystemTableSchemaMetaFieldFeatures>;
  computedFields: Scalars['Boolean'];
};

/** Field Schema Features */
export type SystemFieldSchemaFeatures = {
  __typename?: 'SystemFieldSchemaFeatures';
  update: Scalars['Boolean'];
  delete: Scalars['Boolean'];
};

/** Field Data Features */
export type SystemFieldDataFeatures = {
  __typename?: 'SystemFieldDataFeatures';
  create: Scalars['Boolean'];
  update: Scalars['Boolean'];
  sort: Scalars['Boolean'];
};

/** Table Data Features */
export type SystemTableDataFeatures = {
  __typename?: 'SystemTableDataFeatures';
  create: Scalars['Boolean'];
  update: Scalars['Boolean'];
  delete: Scalars['Boolean'];
};

/** Date Type Format Enum */
export enum SystemDateTypeFormatEnum {
  Date = 'DATE',
  Datetime = 'DATETIME',
}

/** File Type Format Enum */
export enum SystemFileTypeFormatEnum {
  File = 'FILE',
  Image = 'IMAGE',
}

/** Number Type Format Enum */
export enum SystemNumberTypeFormatEnum {
  Number = 'NUMBER',
  Currency = 'CURRENCY',
  Percentage = 'PERCENTAGE',
  Fraction = 'FRACTION',
  Scientific = 'SCIENTIFIC',
}

/** Smart Type Format Enum */
export enum SystemSmartTypeFormatEnum {
  Address = 'ADDRESS',
  Phone = 'PHONE',
}

/** Switch Type Format Enum */
export enum SystemSwitchTypeFormatEnum {
  OnOff = 'ON_OFF',
  YesNo = 'YES_NO',
  TrueFalse = 'TRUE_FALSE',
  ActiveInactive = 'ACTIVE_INACTIVE',
  HighLow = 'HIGH_LOW',
  Custom = 'CUSTOM',
}

/** Text Type Format Enum */
export enum SystemTextTypeFormatEnum {
  Unformatted = 'UNFORMATTED',
  Name = 'NAME',
  Ein = 'EIN',
  Email = 'EMAIL',
  Markdown = 'MARKDOWN',
  Html = 'HTML',
}

/** Field Type Attributes */
export type SystemFieldTypeAttributes =
  | SystemDateFieldTypeAttributes
  | SystemFileFieldTypeAttributes
  | SystemMissingRelationFieldTypeAttributes
  | SystemNumberFieldTypeAttributes
  | SystemSmartFieldTypeAttributes
  | SystemSwitchFieldTypeAttributes
  | SystemTextFieldTypeAttributes
  | SystemUuidFieldTypeAttributes
  | SystemGeoFieldTypeAttributes;

/** Date Field Attributes */
export type SystemDateFieldTypeAttributes = {
  __typename?: 'SystemDateFieldTypeAttributes';
  format: Scalars['String'];
};

/** File Field Attributes */
export type SystemFileFieldTypeAttributes = {
  __typename?: 'SystemFileFieldTypeAttributes';
  format: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  /** @deprecated Field is deprecated */
  showTitle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated */
  showUrl?: Maybe<Scalars['Boolean']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
  expiration?: Maybe<Scalars['Int']>;
};

/** MissingRelation Field Attributes */
export type SystemMissingRelationFieldTypeAttributes = {
  __typename?: 'SystemMissingRelationFieldTypeAttributes';
  missingTable: Scalars['String'];
};

/** Number Field Attributes */
export type SystemNumberFieldTypeAttributes = {
  __typename?: 'SystemNumberFieldTypeAttributes';
  format: Scalars['String'];
  precision?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
  isBigInt?: Maybe<Scalars['Boolean']>;
  autoIncrement?: Maybe<Scalars['Boolean']>;
};

/** Smart Field Attributes */
export type SystemSmartFieldTypeAttributes = {
  __typename?: 'SystemSmartFieldTypeAttributes';
  format: Scalars['String'];
  innerFields?: Maybe<Array<Maybe<SystemCustomTableField>>>;
};

/** Custom Table Field Type */
export type SystemCustomTableField = {
  __typename?: 'SystemCustomTableField';
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType?: Maybe<SystemFieldType>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<SystemComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributes>;
};

/** Switch Field Attributes */
export type SystemSwitchFieldTypeAttributes = {
  __typename?: 'SystemSwitchFieldTypeAttributes';
  format: Scalars['String'];
  listOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Text Field Attributes */
export type SystemTextFieldTypeAttributes = {
  __typename?: 'SystemTextFieldTypeAttributes';
  format: Scalars['String'];
  fieldSize?: Maybe<Scalars['Int']>;
};

/** UUID Field Attributes */
export type SystemUuidFieldTypeAttributes = {
  __typename?: 'SystemUUIDFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
};

/** Geo Field Attributes */
export type SystemGeoFieldTypeAttributes = {
  __typename?: 'SystemGeoFieldTypeAttributes';
  format: Scalars['String'];
  srid?: Maybe<Scalars['Int']>;
};

/** Table Attributes */
export type SystemTableAttributes = SystemViewAttributes;

/** View Attributes */
export type SystemViewAttributes = {
  __typename?: 'SystemViewAttributes';
  query?: Maybe<Scalars['String']>;
};

/** Field types */
export enum SystemFieldType {
  Id = 'ID',
  Uuid = 'UUID',
  Json = 'JSON',
  Number = 'NUMBER',
  Text = 'TEXT',
  Date = 'DATE',
  Switch = 'SWITCH',
  Relation = 'RELATION',
  File = 'FILE',
  Smart = 'SMART',
  Geo = 'GEO',
  OneWayRelation = 'ONE_WAY_RELATION',
  MissingRelation = 'MISSING_RELATION',
}

/** Computed field mode */
export enum SystemComputedFieldMode {
  Virtual = 'VIRTUAL',
  Stored = 'STORED',
}

/** Table List Filter */
export type SystemTableListFilter = {
  applications?: Maybe<Array<Maybe<SystemTableListApplicationFilter>>>;
  onlyUserTables?: Maybe<Scalars['Boolean']>;
  tableNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Table List Application Filter */
export type SystemTableListApplicationFilter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Table Create Input */
export type SystemTableCreateInput = {
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
};

/** Table Delete Input */
export type SystemTableDeleteInput = {
  id: Scalars['ID'];
};

/** Table Update Input */
export type SystemTableUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** View Create Input */
export type SystemViewCreateInput = {
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  query: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

/** View Update Input */
export type SystemViewUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Table Field Create Input */
export type SystemTableFieldCreateInput = {
  tableId: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType: SystemFieldType;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<SystemComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributesInput>;
  relation?: Maybe<SystemRelationCreateInput>;
};

/** Field Type Attributes Input */
export type SystemFieldTypeAttributesInput = {
  format?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
  isBigInt?: Maybe<Scalars['Boolean']>;
  autoIncrement?: Maybe<Scalars['Boolean']>;
  fieldSize?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['Int']>;
  listOptions?: Maybe<Array<Scalars['String']>>;
  maxSize?: Maybe<Scalars['Int']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
  srid?: Maybe<Scalars['Int']>;
};

/** Relation Create Input */
export type SystemRelationCreateInput = {
  refTableId: Scalars['ID'];
  refFieldName?: Maybe<Scalars['String']>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList: Scalars['Boolean'];
  refFieldIsRequired: Scalars['Boolean'];
};

/** Table Field Delete Input */
export type SystemTableFieldDeleteInput = {
  id: Scalars['ID'];
};

/** Table Field Update Input */
export type SystemTableFieldUpdateInput = {
  id: Scalars['ID'];
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType?: Maybe<SystemFieldType>;
  isList?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<SystemComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributesInput>;
  relation?: Maybe<SystemRelationUpdateInput>;
};

/** Relation Update Input */
export type SystemRelationUpdateInput = {
  refTableId?: Maybe<Scalars['ID']>;
  refFieldName?: Maybe<Scalars['String']>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
};

/** Table Field Position Update Input */
export type SystemTableFieldPositionUpdateInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

/** Table Create Index Input */
export type SystemIndexCreateInput = {
  tableId: Scalars['ID'];
  type: TableIndexType;
  columns: Array<SystemTableIndexColumnInput>;
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Table Index Column Input */
export type SystemTableIndexColumnInput = {
  name: Scalars['String'];
};

/** Table Update Index Input */
export type SystemIndexUpdateInput = {
  id: Scalars['ID'];
  type?: Maybe<TableIndexType>;
  columns?: Maybe<Array<SystemTableIndexColumnInput>>;
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Table Delete Index Input */
export type SystemIndexDeleteInput = {
  id: Scalars['ID'];
};

/** Table */
export type SystemTable = {
  __typename?: 'SystemTable';
  id: Scalars['ID'];
  application?: Maybe<SystemApplication>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<SystemTableField>>;
  indexes?: Maybe<Array<SystemTableIndex>>;
  origin: SystemSchemaOrigin;
  schemaFeatures: SystemTableSchemaFeatures;
  dataFeatures: SystemTableDataFeatures;
  attributes?: Maybe<SystemTableAttributes>;
  description?: Maybe<Scalars['String']>;
};

/** TableField */
export type SystemTableField = {
  __typename?: 'SystemTableField';
  id: Scalars['ID'];
  table: SystemTable;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldType: SystemFieldType;
  origin: SystemSchemaOrigin;
  schemaFeatures: SystemFieldSchemaFeatures;
  dataFeatures: SystemFieldDataFeatures;
  isMeta: Scalars['Boolean'];
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  computedMode?: Maybe<SystemComputedFieldMode>;
  expression?: Maybe<Scalars['String']>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributes>;
  relation?: Maybe<SystemRelation>;
};

/** Table Index */
export type SystemTableIndex = {
  __typename?: 'SystemTableIndex';
  id: Scalars['ID'];
  table: SystemTable;
  type: Scalars['String'];
  columns?: Maybe<Array<SystemTableIndexColumn>>;
  name?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
};

/** Table Index Column */
export type SystemTableIndexColumn = {
  __typename?: 'SystemTableIndexColumn';
  name: Scalars['String'];
};

/** Relation */
export type SystemRelation = {
  __typename?: 'SystemRelation';
  refTable: SystemTable;
  refField?: Maybe<SystemTableField>;
  relationTableName?: Maybe<Scalars['String']>;
  relationFieldName?: Maybe<Scalars['String']>;
  refFieldName?: Maybe<Scalars['String']>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
};

/** Table List Response */
export type SystemTableListResponse = {
  __typename?: 'SystemTableListResponse';
  items?: Maybe<Array<Maybe<SystemTable>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  apiToken?: Maybe<ApiToken>;
  apiTokensList: ApiTokenListResponse;
  /** @deprecated No longer supported. Use `system.application` instead. */
  application?: Maybe<Application>;
  /** @deprecated No longer supported. Use `system.applicationsList` instead. */
  applicationsList?: Maybe<ApplicationListResponse>;
  asyncSessionStatus?: Maybe<AsyncSessionStatusResponse>;
  asyncTask?: Maybe<AsyncTask>;
  asyncTasksList: AsyncTaskListResponse;
  authenticationProfile?: Maybe<AuthenticationProfile>;
  authenticationProfilesList: AuthenticationProfileListResponse;
  authenticationSettings?: Maybe<AuthenticationSetting>;
  /** @deprecated No longer supported. Use `system.billingCurrentPlan` instead. */
  billingCurrentPlan?: Maybe<BillingCurrentPlanResponse>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetails, system.organizationPaymentDetails or system.workspacePaymentDetails` instead. */
  billingDetails?: Maybe<BillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.memberBillingHistory, system.organizationBillingHistory or system.workspaceBillingHistory` instead. */
  billingInvoicesList: BillingInvoicesListResponse;
  /** @deprecated No longer supported. Use `system.billingMetricUsageQuotasList` instead. */
  billingMetricUsageQuotasList: BillingMetricUsageQuotasListResponse;
  /** @deprecated No longer supported. Use `system.billingMetricUsagesList` instead. */
  billingMetricUsagesList: BillingMetricUsagesListResponse;
  cashMonitoringRecord?: Maybe<CashMonitoringRecord>;
  cashMonitoringRecordsList: CashMonitoringRecordListResponse;
  ciCdMigration?: Maybe<CiCdMigration>;
  ciCdMigrationsList: CiCdMigrationListResponse;
  companiesList: CompanyListResponse;
  company?: Maybe<Company>;
  companyMonitoring?: Maybe<CompanyMonitoring>;
  companyMonitoringsList: CompanyMonitoringListResponse;
  companyName?: Maybe<Scalars['String']>;
  covenant?: Maybe<Covenant>;
  covenantValue?: Maybe<CovenantValue>;
  covenantValuesList: CovenantValueListResponse;
  covenantsList: CovenantListResponse;
  customCovenantValue?: Maybe<CustomCovenantValue>;
  customCovenantValuesList: CustomCovenantValueListResponse;
  /** @deprecated No longer supported. Use `system.deployStatus` instead. */
  deployStatus: DeployStatusResult;
  document?: Maybe<Document>;
  documentsList: DocumentListResponse;
  draw?: Maybe<Draw>;
  drawHistoriesList: DrawHistoryListResponse;
  drawHistory?: Maybe<DrawHistory>;
  drawHistoryValue?: Maybe<DrawHistoryValue>;
  drawHistoryValuesList: DrawHistoryValueListResponse;
  drawsList: DrawListResponse;
  environmentVariable?: Maybe<EnvironmentVariable>;
  environmentVariablesList: EnvironmentVariableListResponse;
  facilitiesList: FacilityListResponse;
  facility?: Maybe<Facility>;
  facilityHistoriesList: FacilityHistoryListResponse;
  facilityHistory?: Maybe<FacilityHistory>;
  facilityHistoryValue?: Maybe<FacilityHistoryValue>;
  facilityHistoryValuesList: FacilityHistoryValueListResponse;
  file?: Maybe<File>;
  fileUploadInfo?: Maybe<FileUploadInfoResponse>;
  filesList: FileListResponse;
  /** @deprecated No longer supported. Use `system.functionsList` instead. */
  functionsList?: Maybe<FunctionListResponse>;
  getWorkspaceTransferInfo?: Maybe<WorkspaceTransferItem>;
  inboxMessage?: Maybe<InboxMessage>;
  inboxMessagesList: InboxMessageListResponse;
  integration?: Maybe<Integration>;
  integrationsList: IntegrationListResponse;
  invitation?: Maybe<Invitation>;
  invitationsList: InvitationListResponse;
  loanApplication?: Maybe<LoanApplication>;
  loanApplicationsList: LoanApplicationListResponse;
  loanMonitoring?: Maybe<LoanMonitoring>;
  loanMonitoringsList: LoanMonitoringListResponse;
  /** @deprecated No longer supported. Use `system.logsList` instead. */
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  metric?: Maybe<Metric>;
  metricValue?: Maybe<MetricValue>;
  metricValuesList: MetricValueListResponse;
  metricsList: MetricListResponse;
  note?: Maybe<Note>;
  notesList: NoteListResponse;
  plaidIntegration?: Maybe<PlaidIntegration>;
  plaidIntegrationAccount?: Maybe<PlaidIntegrationAccount>;
  plaidIntegrationAccountsList: PlaidIntegrationAccountListResponse;
  plaidIntegrationsList: PlaidIntegrationListResponse;
  portfolioCompaniesList: PortfolioCompanyListResponse;
  portfolioCompany?: Maybe<PortfolioCompany>;
  progressCompanyStep?: Maybe<ProgressCompanyStep>;
  progressCompanyStepsList: ProgressCompanyStepListResponse;
  progressStage?: Maybe<ProgressStage>;
  progressStagesList: ProgressStageListResponse;
  progressStep?: Maybe<ProgressStep>;
  progressStepValue?: Maybe<ProgressStepValue>;
  progressStepValuesList: ProgressStepValueListResponse;
  progressStepsList: ProgressStepListResponse;
  quickBooksIntegration?: Maybe<QuickBooksIntegration>;
  quickBooksIntegrationsList: QuickBooksIntegrationListResponse;
  role?: Maybe<Role>;
  rolesList: RoleListResponse;
  settings?: Maybe<Setting>;
  sortableLoanApplication?: Maybe<SortableLoanApplication>;
  sortableLoanApplicationsList: SortableLoanApplicationListResponse;
  sortablePortfolioCompaniesList: SortablePortfolioCompanyListResponse;
  sortablePortfolioCompany?: Maybe<SortablePortfolioCompany>;
  stripeIntegration?: Maybe<StripeIntegration>;
  stripeIntegrationsList: StripeIntegrationListResponse;
  system?: Maybe<SystemQuery>;
  /** @deprecated No longer supported. Use `system.table` instead. */
  table?: Maybe<Table>;
  /** @deprecated No longer supported. Use `system.tableField` instead. */
  tableField?: Maybe<TableField>;
  /** @deprecated No longer supported. Use `system.tablesList` instead. */
  tablesList: TableListResponse;
  teamMember?: Maybe<TeamMember>;
  teamMembersList: TeamMemberListResponse;
  user?: Maybe<User>;
  userBillingConfiguration: UserBillingConfigurationResponse;
  /** @deprecated No longer supported. Use `system.userInvitationsList` instead. */
  userInvitationsList?: Maybe<UserInvitationList>;
  usersList: UserListResponse;
  /** @deprecated No longer supported. Use `system.workspacesList` instead. */
  workspacesList?: Maybe<WorkspaceListResponse>;
  xeroIntegration?: Maybe<XeroIntegration>;
  xeroIntegrationsList: XeroIntegrationListResponse;
  eventResolverQuery?: Maybe<EventResolverResponse>;
  calculateSaaSLoanOptions?: Maybe<SaaSLoanOptionsResult>;
  calculatePortfolioMonitoring?: Maybe<PortfolioMonitoringData>;
  getClosingDate?: Maybe<ClosingDate>;
  getFundingDate?: Maybe<FundingDate>;
  getTotalAmountDeployed?: Maybe<TotalAmountDeployed>;
  getAmountCommitted?: Maybe<AmountCommitted>;
  getPortfolioOverviewReport?: Maybe<PortfolioOverviewData>;
  getStatementsMetricsQuery?: Maybe<StatementsMetricsQueryResponse>;
};

export type QueryApiTokenArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryApiTokensListArgs = {
  filter?: Maybe<ApiTokenFilter>;
  orderBy?: Maybe<Array<Maybe<ApiTokenOrderBy>>>;
  sort?: Maybe<Array<ApiTokenSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ApiTokenGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryApplicationArgs = {
  id: Scalars['String'];
};

export type QueryAsyncSessionStatusArgs = {
  sessionId: Scalars['String'];
};

export type QueryAsyncTaskArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAsyncTasksListArgs = {
  filter?: Maybe<AsyncTaskFilter>;
  orderBy?: Maybe<Array<Maybe<AsyncTaskOrderBy>>>;
  sort?: Maybe<Array<AsyncTaskSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AsyncTaskGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAuthenticationProfileArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAuthenticationProfilesListArgs = {
  filter?: Maybe<AuthenticationProfileFilter>;
  orderBy?: Maybe<Array<Maybe<AuthenticationProfileOrderBy>>>;
  sort?: Maybe<Array<AuthenticationProfileSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AuthenticationProfileGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryBillingInvoicesListArgs = {
  by?: Maybe<BillingInvoicesListFilterType>;
  limit?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type QueryBillingMetricUsagesListArgs = {
  filter?: Maybe<BillingMetricUsagesListFilter>;
};

export type QueryCashMonitoringRecordArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCashMonitoringRecordsListArgs = {
  filter?: Maybe<CashMonitoringRecordFilter>;
  orderBy?: Maybe<Array<Maybe<CashMonitoringRecordOrderBy>>>;
  sort?: Maybe<Array<CashMonitoringRecordSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CashMonitoringRecordGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCiCdMigrationArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCiCdMigrationsListArgs = {
  filter?: Maybe<CiCdMigrationFilter>;
  orderBy?: Maybe<Array<Maybe<CiCdMigrationOrderBy>>>;
  sort?: Maybe<Array<CiCdMigrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CiCdMigrationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCompaniesListArgs = {
  filter?: Maybe<CompanyFilter>;
  orderBy?: Maybe<Array<Maybe<CompanyOrderBy>>>;
  sort?: Maybe<Array<CompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CompanyGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCompanyMonitoringArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCompanyMonitoringsListArgs = {
  filter?: Maybe<CompanyMonitoringFilter>;
  orderBy?: Maybe<Array<Maybe<CompanyMonitoringOrderBy>>>;
  sort?: Maybe<Array<CompanyMonitoringSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CompanyMonitoringGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCovenantArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCovenantValueArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCovenantValuesListArgs = {
  filter?: Maybe<CovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CovenantValueOrderBy>>>;
  sort?: Maybe<Array<CovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CovenantValueGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCovenantsListArgs = {
  filter?: Maybe<CovenantFilter>;
  orderBy?: Maybe<Array<Maybe<CovenantOrderBy>>>;
  sort?: Maybe<Array<CovenantSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CovenantGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomCovenantValueArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomCovenantValuesListArgs = {
  filter?: Maybe<CustomCovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CustomCovenantValueOrderBy>>>;
  sort?: Maybe<Array<CustomCovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomCovenantValueGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDeployStatusArgs = {
  buildName: Scalars['String'];
};

export type QueryDocumentArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDocumentsListArgs = {
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>>>;
  sort?: Maybe<Array<DocumentSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDrawArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDrawHistoriesListArgs = {
  filter?: Maybe<DrawHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryOrderBy>>>;
  sort?: Maybe<Array<DrawHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDrawHistoryArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDrawHistoryValueArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDrawHistoryValuesListArgs = {
  filter?: Maybe<DrawHistoryValueFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryValueOrderBy>>>;
  sort?: Maybe<Array<DrawHistoryValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryValueGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDrawsListArgs = {
  filter?: Maybe<DrawFilter>;
  orderBy?: Maybe<Array<Maybe<DrawOrderBy>>>;
  sort?: Maybe<Array<DrawSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEnvironmentVariableArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEnvironmentVariablesListArgs = {
  filter?: Maybe<EnvironmentVariableFilter>;
  orderBy?: Maybe<Array<Maybe<EnvironmentVariableOrderBy>>>;
  sort?: Maybe<Array<EnvironmentVariableSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EnvironmentVariableGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFacilitiesListArgs = {
  filter?: Maybe<FacilityFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityOrderBy>>>;
  sort?: Maybe<Array<FacilitySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFacilityArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFacilityHistoriesListArgs = {
  filter?: Maybe<FacilityHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityHistoryOrderBy>>>;
  sort?: Maybe<Array<FacilityHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityHistoryGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFacilityHistoryArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFacilityHistoryValueArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFacilityHistoryValuesListArgs = {
  filter?: Maybe<FacilityHistoryValueFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityHistoryValueOrderBy>>>;
  sort?: Maybe<Array<FacilityHistoryValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityHistoryValueGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFileArgs = {
  id?: Maybe<Scalars['ID']>;
  fileId?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFilesListArgs = {
  filter?: Maybe<FileFilter>;
  orderBy?: Maybe<Array<Maybe<FileOrderBy>>>;
  sort?: Maybe<Array<FileSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FileGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFunctionsListArgs = {
  applicationId?: Maybe<Scalars['String']>;
  filter?: Maybe<FunctionInfoFilter>;
  orderBy?: Maybe<Array<Maybe<FunctionInfoOrderBy>>>;
};

export type QueryGetWorkspaceTransferInfoArgs = {
  workspaceId: Scalars['String'];
};

export type QueryInboxMessageArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryInboxMessagesListArgs = {
  filter?: Maybe<InboxMessageFilter>;
  orderBy?: Maybe<Array<Maybe<InboxMessageOrderBy>>>;
  sort?: Maybe<Array<InboxMessageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InboxMessageGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryIntegrationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryIntegrationsListArgs = {
  filter?: Maybe<IntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<IntegrationOrderBy>>>;
  sort?: Maybe<Array<IntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<IntegrationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryInvitationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryInvitationsListArgs = {
  filter?: Maybe<InvitationFilter>;
  orderBy?: Maybe<Array<Maybe<InvitationOrderBy>>>;
  sort?: Maybe<Array<InvitationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InvitationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryLoanApplicationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryLoanApplicationsListArgs = {
  filter?: Maybe<LoanApplicationFilter>;
  orderBy?: Maybe<Array<Maybe<LoanApplicationOrderBy>>>;
  sort?: Maybe<Array<LoanApplicationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<LoanApplicationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryLoanMonitoringArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryLoanMonitoringsListArgs = {
  filter?: Maybe<LoanMonitoringFilter>;
  orderBy?: Maybe<Array<Maybe<LoanMonitoringOrderBy>>>;
  sort?: Maybe<Array<LoanMonitoringSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<LoanMonitoringGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryLogsArgs = {
  functionName: Scalars['String'];
  applicationId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type QueryMetricArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryMetricValueArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryMetricValuesListArgs = {
  filter?: Maybe<MetricValueFilter>;
  orderBy?: Maybe<Array<Maybe<MetricValueOrderBy>>>;
  sort?: Maybe<Array<MetricValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MetricValueGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryMetricsListArgs = {
  filter?: Maybe<MetricFilter>;
  orderBy?: Maybe<Array<Maybe<MetricOrderBy>>>;
  sort?: Maybe<Array<MetricSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MetricGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNoteArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotesListArgs = {
  filter?: Maybe<NoteFilter>;
  orderBy?: Maybe<Array<Maybe<NoteOrderBy>>>;
  sort?: Maybe<Array<NoteSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NoteGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPlaidIntegrationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPlaidIntegrationAccountArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPlaidIntegrationAccountsListArgs = {
  filter?: Maybe<PlaidIntegrationAccountFilter>;
  orderBy?: Maybe<Array<Maybe<PlaidIntegrationAccountOrderBy>>>;
  sort?: Maybe<Array<PlaidIntegrationAccountSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PlaidIntegrationAccountGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPlaidIntegrationsListArgs = {
  filter?: Maybe<PlaidIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<PlaidIntegrationOrderBy>>>;
  sort?: Maybe<Array<PlaidIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PlaidIntegrationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPortfolioCompaniesListArgs = {
  filter?: Maybe<PortfolioCompanyFilter>;
  orderBy?: Maybe<Array<Maybe<PortfolioCompanyOrderBy>>>;
  sort?: Maybe<Array<PortfolioCompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PortfolioCompanyGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryPortfolioCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressCompanyStepArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressCompanyStepsListArgs = {
  filter?: Maybe<ProgressCompanyStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressCompanyStepOrderBy>>>;
  sort?: Maybe<Array<ProgressCompanyStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressCompanyStepGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressStageArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressStagesListArgs = {
  filter?: Maybe<ProgressStageFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStageOrderBy>>>;
  sort?: Maybe<Array<ProgressStageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStageGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressStepArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressStepValueArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressStepValuesListArgs = {
  filter?: Maybe<ProgressStepValueFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepValueOrderBy>>>;
  sort?: Maybe<Array<ProgressStepValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepValueGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryProgressStepsListArgs = {
  filter?: Maybe<ProgressStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepOrderBy>>>;
  sort?: Maybe<Array<ProgressStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryQuickBooksIntegrationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryQuickBooksIntegrationsListArgs = {
  filter?: Maybe<QuickBooksIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<QuickBooksIntegrationOrderBy>>>;
  sort?: Maybe<Array<QuickBooksIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<QuickBooksIntegrationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryRoleArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryRolesListArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QuerySortableLoanApplicationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QuerySortableLoanApplicationsListArgs = {
  filter?: Maybe<SortableLoanApplicationFilter>;
  orderBy?: Maybe<Array<Maybe<SortableLoanApplicationOrderBy>>>;
  sort?: Maybe<Array<SortableLoanApplicationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SortableLoanApplicationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QuerySortablePortfolioCompaniesListArgs = {
  filter?: Maybe<SortablePortfolioCompanyFilter>;
  orderBy?: Maybe<Array<Maybe<SortablePortfolioCompanyOrderBy>>>;
  sort?: Maybe<Array<SortablePortfolioCompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SortablePortfolioCompanyGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QuerySortablePortfolioCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryStripeIntegrationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryStripeIntegrationsListArgs = {
  filter?: Maybe<StripeIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<StripeIntegrationOrderBy>>>;
  sort?: Maybe<Array<StripeIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<StripeIntegrationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryTableArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryTableFieldArgs = {
  id: Scalars['ID'];
};

export type QueryTablesListArgs = {
  filter?: Maybe<TableListFilter>;
};

export type QueryTeamMemberArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryTeamMembersListArgs = {
  filter?: Maybe<TeamMemberFilter>;
  orderBy?: Maybe<Array<Maybe<TeamMemberOrderBy>>>;
  sort?: Maybe<Array<TeamMemberSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<TeamMemberGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryUsersListArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryXeroIntegrationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryXeroIntegrationsListArgs = {
  filter?: Maybe<XeroIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<XeroIntegrationOrderBy>>>;
  sort?: Maybe<Array<XeroIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<XeroIntegrationGroupBy>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEventResolverQueryArgs = {
  event: EventResolverInput;
};

export type QueryCalculateSaaSLoanOptionsArgs = {
  companyId: Scalars['ID'];
};

export type QueryCalculatePortfolioMonitoringArgs = {
  companiesList: Array<Scalars['ID']>;
  dates: Array<Scalars['String']>;
  period: Scalars['String'];
};

export type QueryGetClosingDateArgs = {
  companyId: Scalars['ID'];
  dateTo?: Maybe<Scalars['String']>;
};

export type QueryGetFundingDateArgs = {
  companyId: Scalars['ID'];
  dateTo?: Maybe<Scalars['String']>;
};

export type QueryGetTotalAmountDeployedArgs = {
  companyId: Scalars['ID'];
};

export type QueryGetAmountCommittedArgs = {
  companyId: Scalars['ID'];
};

export type QueryGetPortfolioOverviewReportArgs = {
  date: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type QueryGetStatementsMetricsQueryArgs = {
  event: StatementsMetricsInput;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type ApiTokenRolesArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  is8base?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  roles?: Maybe<RoleListResponse>;
  invitation?: Maybe<Invitation>;
  company?: Maybe<Company>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<Company>;
  loanApplications?: Maybe<LoanApplication>;
  portfolioCompanies?: Maybe<PortfolioCompanyListResponse>;
  permissions?: Maybe<UserPermissionList>;
  _description?: Maybe<Scalars['String']>;
};

export type UserRolesArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
};

export type UserPortfolioCompaniesArgs = {
  filter?: Maybe<PortfolioCompanyFilter>;
  orderBy?: Maybe<Array<Maybe<PortfolioCompanyOrderBy>>>;
  sort?: Maybe<Array<PortfolioCompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PortfolioCompanyGroupBy>;
};

export type UserPermissionsArgs = {
  filter?: Maybe<PermissionInputFilter>;
};

export type File = {
  __typename?: 'File';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  fileId?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  shareUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  settings_menuBarLogo?: Maybe<SettingListResponse>;
  settings_landingPageImage?: Maybe<SettingListResponse>;
  users_avatar?: Maybe<UserListResponse>;
  teamMembers_avatar?: Maybe<TeamMemberListResponse>;
  portfolioCompanies_logo?: Maybe<CompanyListResponse>;
  documents_file?: Maybe<DocumentListResponse>;
  notes_attachments?: Maybe<NoteListResponse>;
  previewUrl?: Maybe<Scalars['String']>;
  downloadStorageUrl?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type FileSettings_MenuBarLogoArgs = {
  filter?: Maybe<SettingFilter>;
  orderBy?: Maybe<Array<Maybe<SettingOrderBy>>>;
  sort?: Maybe<Array<SettingSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SettingGroupBy>;
};

export type FileSettings_LandingPageImageArgs = {
  filter?: Maybe<SettingFilter>;
  orderBy?: Maybe<Array<Maybe<SettingOrderBy>>>;
  sort?: Maybe<Array<SettingSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SettingGroupBy>;
};

export type FileUsers_AvatarArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
};

export type FileTeamMembers_AvatarArgs = {
  filter?: Maybe<TeamMemberFilter>;
  orderBy?: Maybe<Array<Maybe<TeamMemberOrderBy>>>;
  sort?: Maybe<Array<TeamMemberSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<TeamMemberGroupBy>;
};

export type FilePortfolioCompanies_LogoArgs = {
  filter?: Maybe<CompanyFilter>;
  orderBy?: Maybe<Array<Maybe<CompanyOrderBy>>>;
  sort?: Maybe<Array<CompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CompanyGroupBy>;
};

export type FileDocuments_FileArgs = {
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>>>;
  sort?: Maybe<Array<DocumentSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentGroupBy>;
};

export type FileNotes_AttachmentsArgs = {
  filter?: Maybe<NoteFilter>;
  orderBy?: Maybe<Array<Maybe<NoteOrderBy>>>;
  sort?: Maybe<Array<NoteSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NoteGroupBy>;
};

/** SettingListResponse output */
export type SettingListResponse = {
  __typename?: 'SettingListResponse';
  /** List items */
  items: Array<Setting>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Setting = {
  __typename?: 'Setting';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequireNumbers?: Maybe<Scalars['Boolean']>;
  passwordRequireSpecial?: Maybe<Scalars['Boolean']>;
  passwordRequireUppercase?: Maybe<Scalars['Boolean']>;
  passwordRequireLowercase?: Maybe<Scalars['Boolean']>;
  passwordUpdateInterval?: Maybe<Scalars['Int']>;
  rememberDevice?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  menuBarLogo?: Maybe<File>;
  landingPageImage?: Maybe<File>;
  vanityUrl?: Maybe<Scalars['String']>;
  buttonLinkColor?: Maybe<Scalars['String']>;
  userInterfaceStyle?: Maybe<Scalars['String']>;
  menuBarBGColor?: Maybe<Scalars['String']>;
  menuBarIconsColor?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  containerColor?: Maybe<Scalars['String']>;
  leftNavColor?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type GroupByResponse = {
  __typename?: 'GroupByResponse';
  ID?: Maybe<Scalars['ID']>;
  String?: Maybe<Scalars['String']>;
  Int?: Maybe<Scalars['Int']>;
  BigInt?: Maybe<Scalars['BigInt']>;
  Float?: Maybe<Scalars['Float']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Date?: Maybe<Scalars['Date']>;
  Boolean?: Maybe<Scalars['Boolean']>;
  JSON?: Maybe<Scalars['JSON']>;
  GroupIds?: Maybe<Array<Scalars['ID']>>;
  UserGroup: UserListResponse;
  FileGroup: FileListResponse;
  RoleGroup: RoleListResponse;
  ApiTokenGroup: ApiTokenListResponse;
  EnvironmentVariableGroup: EnvironmentVariableListResponse;
  AuthenticationProfileGroup: AuthenticationProfileListResponse;
  TeamMemberGroup: TeamMemberListResponse;
  CiCdMigrationGroup: CiCdMigrationListResponse;
  LoanApplicationGroup: LoanApplicationListResponse;
  InvitationGroup: InvitationListResponse;
  LoanMonitoringGroup: LoanMonitoringListResponse;
  CompanyGroup: CompanyListResponse;
  FacilityGroup: FacilityListResponse;
  DocumentGroup: DocumentListResponse;
  NoteGroup: NoteListResponse;
  IntegrationGroup: IntegrationListResponse;
  XeroIntegrationGroup: XeroIntegrationListResponse;
  PortfolioCompanyGroup: PortfolioCompanyListResponse;
  MetricGroup: MetricListResponse;
  MetricValueGroup: MetricValueListResponse;
  DrawGroup: DrawListResponse;
  QuickBooksIntegrationGroup: QuickBooksIntegrationListResponse;
  StripeIntegrationGroup: StripeIntegrationListResponse;
  CompanyMonitoringGroup: CompanyMonitoringListResponse;
  PlaidIntegrationGroup: PlaidIntegrationListResponse;
  AsyncTaskGroup: AsyncTaskListResponse;
  InboxMessageGroup: InboxMessageListResponse;
  ProgressStageGroup: ProgressStageListResponse;
  ProgressStepValueGroup: ProgressStepValueListResponse;
  ProgressStepGroup: ProgressStepListResponse;
  PlaidIntegrationAccountGroup: PlaidIntegrationAccountListResponse;
  ProgressCompanyStepGroup: ProgressCompanyStepListResponse;
  SortablePortfolioCompanyGroup: SortablePortfolioCompanyListResponse;
  SortableLoanApplicationGroup: SortableLoanApplicationListResponse;
  CashMonitoringRecordGroup: CashMonitoringRecordListResponse;
  CovenantGroup: CovenantListResponse;
  CovenantValueGroup: CovenantValueListResponse;
  FacilityHistoryGroup: FacilityHistoryListResponse;
  FacilityHistoryValueGroup: FacilityHistoryValueListResponse;
  DrawHistoryGroup: DrawHistoryListResponse;
  DrawHistoryValueGroup: DrawHistoryValueListResponse;
  CustomCovenantValueGroup: CustomCovenantValueListResponse;
};

export type GroupByResponseUserGroupArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
};

export type GroupByResponseFileGroupArgs = {
  filter?: Maybe<FileFilter>;
  orderBy?: Maybe<Array<Maybe<FileOrderBy>>>;
  sort?: Maybe<Array<FileSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FileGroupBy>;
};

export type GroupByResponseRoleGroupArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
};

export type GroupByResponseApiTokenGroupArgs = {
  filter?: Maybe<ApiTokenFilter>;
  orderBy?: Maybe<Array<Maybe<ApiTokenOrderBy>>>;
  sort?: Maybe<Array<ApiTokenSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ApiTokenGroupBy>;
};

export type GroupByResponseEnvironmentVariableGroupArgs = {
  filter?: Maybe<EnvironmentVariableFilter>;
  orderBy?: Maybe<Array<Maybe<EnvironmentVariableOrderBy>>>;
  sort?: Maybe<Array<EnvironmentVariableSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EnvironmentVariableGroupBy>;
};

export type GroupByResponseAuthenticationProfileGroupArgs = {
  filter?: Maybe<AuthenticationProfileFilter>;
  orderBy?: Maybe<Array<Maybe<AuthenticationProfileOrderBy>>>;
  sort?: Maybe<Array<AuthenticationProfileSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AuthenticationProfileGroupBy>;
};

export type GroupByResponseTeamMemberGroupArgs = {
  filter?: Maybe<TeamMemberFilter>;
  orderBy?: Maybe<Array<Maybe<TeamMemberOrderBy>>>;
  sort?: Maybe<Array<TeamMemberSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<TeamMemberGroupBy>;
};

export type GroupByResponseCiCdMigrationGroupArgs = {
  filter?: Maybe<CiCdMigrationFilter>;
  orderBy?: Maybe<Array<Maybe<CiCdMigrationOrderBy>>>;
  sort?: Maybe<Array<CiCdMigrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CiCdMigrationGroupBy>;
};

export type GroupByResponseLoanApplicationGroupArgs = {
  filter?: Maybe<LoanApplicationFilter>;
  orderBy?: Maybe<Array<Maybe<LoanApplicationOrderBy>>>;
  sort?: Maybe<Array<LoanApplicationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<LoanApplicationGroupBy>;
};

export type GroupByResponseInvitationGroupArgs = {
  filter?: Maybe<InvitationFilter>;
  orderBy?: Maybe<Array<Maybe<InvitationOrderBy>>>;
  sort?: Maybe<Array<InvitationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InvitationGroupBy>;
};

export type GroupByResponseLoanMonitoringGroupArgs = {
  filter?: Maybe<LoanMonitoringFilter>;
  orderBy?: Maybe<Array<Maybe<LoanMonitoringOrderBy>>>;
  sort?: Maybe<Array<LoanMonitoringSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<LoanMonitoringGroupBy>;
};

export type GroupByResponseCompanyGroupArgs = {
  filter?: Maybe<CompanyFilter>;
  orderBy?: Maybe<Array<Maybe<CompanyOrderBy>>>;
  sort?: Maybe<Array<CompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CompanyGroupBy>;
};

export type GroupByResponseFacilityGroupArgs = {
  filter?: Maybe<FacilityFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityOrderBy>>>;
  sort?: Maybe<Array<FacilitySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityGroupBy>;
};

export type GroupByResponseDocumentGroupArgs = {
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>>>;
  sort?: Maybe<Array<DocumentSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentGroupBy>;
};

export type GroupByResponseNoteGroupArgs = {
  filter?: Maybe<NoteFilter>;
  orderBy?: Maybe<Array<Maybe<NoteOrderBy>>>;
  sort?: Maybe<Array<NoteSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NoteGroupBy>;
};

export type GroupByResponseIntegrationGroupArgs = {
  filter?: Maybe<IntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<IntegrationOrderBy>>>;
  sort?: Maybe<Array<IntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<IntegrationGroupBy>;
};

export type GroupByResponseXeroIntegrationGroupArgs = {
  filter?: Maybe<XeroIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<XeroIntegrationOrderBy>>>;
  sort?: Maybe<Array<XeroIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<XeroIntegrationGroupBy>;
};

export type GroupByResponsePortfolioCompanyGroupArgs = {
  filter?: Maybe<PortfolioCompanyFilter>;
  orderBy?: Maybe<Array<Maybe<PortfolioCompanyOrderBy>>>;
  sort?: Maybe<Array<PortfolioCompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PortfolioCompanyGroupBy>;
};

export type GroupByResponseMetricGroupArgs = {
  filter?: Maybe<MetricFilter>;
  orderBy?: Maybe<Array<Maybe<MetricOrderBy>>>;
  sort?: Maybe<Array<MetricSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MetricGroupBy>;
};

export type GroupByResponseMetricValueGroupArgs = {
  filter?: Maybe<MetricValueFilter>;
  orderBy?: Maybe<Array<Maybe<MetricValueOrderBy>>>;
  sort?: Maybe<Array<MetricValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MetricValueGroupBy>;
};

export type GroupByResponseDrawGroupArgs = {
  filter?: Maybe<DrawFilter>;
  orderBy?: Maybe<Array<Maybe<DrawOrderBy>>>;
  sort?: Maybe<Array<DrawSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawGroupBy>;
};

export type GroupByResponseQuickBooksIntegrationGroupArgs = {
  filter?: Maybe<QuickBooksIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<QuickBooksIntegrationOrderBy>>>;
  sort?: Maybe<Array<QuickBooksIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<QuickBooksIntegrationGroupBy>;
};

export type GroupByResponseStripeIntegrationGroupArgs = {
  filter?: Maybe<StripeIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<StripeIntegrationOrderBy>>>;
  sort?: Maybe<Array<StripeIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<StripeIntegrationGroupBy>;
};

export type GroupByResponseCompanyMonitoringGroupArgs = {
  filter?: Maybe<CompanyMonitoringFilter>;
  orderBy?: Maybe<Array<Maybe<CompanyMonitoringOrderBy>>>;
  sort?: Maybe<Array<CompanyMonitoringSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CompanyMonitoringGroupBy>;
};

export type GroupByResponsePlaidIntegrationGroupArgs = {
  filter?: Maybe<PlaidIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<PlaidIntegrationOrderBy>>>;
  sort?: Maybe<Array<PlaidIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PlaidIntegrationGroupBy>;
};

export type GroupByResponseAsyncTaskGroupArgs = {
  filter?: Maybe<AsyncTaskFilter>;
  orderBy?: Maybe<Array<Maybe<AsyncTaskOrderBy>>>;
  sort?: Maybe<Array<AsyncTaskSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AsyncTaskGroupBy>;
};

export type GroupByResponseInboxMessageGroupArgs = {
  filter?: Maybe<InboxMessageFilter>;
  orderBy?: Maybe<Array<Maybe<InboxMessageOrderBy>>>;
  sort?: Maybe<Array<InboxMessageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InboxMessageGroupBy>;
};

export type GroupByResponseProgressStageGroupArgs = {
  filter?: Maybe<ProgressStageFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStageOrderBy>>>;
  sort?: Maybe<Array<ProgressStageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStageGroupBy>;
};

export type GroupByResponseProgressStepValueGroupArgs = {
  filter?: Maybe<ProgressStepValueFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepValueOrderBy>>>;
  sort?: Maybe<Array<ProgressStepValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepValueGroupBy>;
};

export type GroupByResponseProgressStepGroupArgs = {
  filter?: Maybe<ProgressStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepOrderBy>>>;
  sort?: Maybe<Array<ProgressStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepGroupBy>;
};

export type GroupByResponsePlaidIntegrationAccountGroupArgs = {
  filter?: Maybe<PlaidIntegrationAccountFilter>;
  orderBy?: Maybe<Array<Maybe<PlaidIntegrationAccountOrderBy>>>;
  sort?: Maybe<Array<PlaidIntegrationAccountSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PlaidIntegrationAccountGroupBy>;
};

export type GroupByResponseProgressCompanyStepGroupArgs = {
  filter?: Maybe<ProgressCompanyStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressCompanyStepOrderBy>>>;
  sort?: Maybe<Array<ProgressCompanyStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressCompanyStepGroupBy>;
};

export type GroupByResponseSortablePortfolioCompanyGroupArgs = {
  filter?: Maybe<SortablePortfolioCompanyFilter>;
  orderBy?: Maybe<Array<Maybe<SortablePortfolioCompanyOrderBy>>>;
  sort?: Maybe<Array<SortablePortfolioCompanySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SortablePortfolioCompanyGroupBy>;
};

export type GroupByResponseSortableLoanApplicationGroupArgs = {
  filter?: Maybe<SortableLoanApplicationFilter>;
  orderBy?: Maybe<Array<Maybe<SortableLoanApplicationOrderBy>>>;
  sort?: Maybe<Array<SortableLoanApplicationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SortableLoanApplicationGroupBy>;
};

export type GroupByResponseCashMonitoringRecordGroupArgs = {
  filter?: Maybe<CashMonitoringRecordFilter>;
  orderBy?: Maybe<Array<Maybe<CashMonitoringRecordOrderBy>>>;
  sort?: Maybe<Array<CashMonitoringRecordSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CashMonitoringRecordGroupBy>;
};

export type GroupByResponseCovenantGroupArgs = {
  filter?: Maybe<CovenantFilter>;
  orderBy?: Maybe<Array<Maybe<CovenantOrderBy>>>;
  sort?: Maybe<Array<CovenantSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CovenantGroupBy>;
};

export type GroupByResponseCovenantValueGroupArgs = {
  filter?: Maybe<CovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CovenantValueOrderBy>>>;
  sort?: Maybe<Array<CovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CovenantValueGroupBy>;
};

export type GroupByResponseFacilityHistoryGroupArgs = {
  filter?: Maybe<FacilityHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityHistoryOrderBy>>>;
  sort?: Maybe<Array<FacilityHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityHistoryGroupBy>;
};

export type GroupByResponseFacilityHistoryValueGroupArgs = {
  filter?: Maybe<FacilityHistoryValueFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityHistoryValueOrderBy>>>;
  sort?: Maybe<Array<FacilityHistoryValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityHistoryValueGroupBy>;
};

export type GroupByResponseDrawHistoryGroupArgs = {
  filter?: Maybe<DrawHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryOrderBy>>>;
  sort?: Maybe<Array<DrawHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryGroupBy>;
};

export type GroupByResponseDrawHistoryValueGroupArgs = {
  filter?: Maybe<DrawHistoryValueFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryValueOrderBy>>>;
  sort?: Maybe<Array<DrawHistoryValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryValueGroupBy>;
};

export type GroupByResponseCustomCovenantValueGroupArgs = {
  filter?: Maybe<CustomCovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CustomCovenantValueOrderBy>>>;
  sort?: Maybe<Array<CustomCovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomCovenantValueGroupBy>;
};

/** UserListResponse output */
export type UserListResponse = {
  __typename?: 'UserListResponse';
  /** List items */
  items: Array<User>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type UserFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  email?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  origin?: Maybe<StringPredicate>;
  is8base?: Maybe<BoolPredicate>;
  firstName?: Maybe<StringPredicate>;
  lastName?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  title?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  is_self?: Maybe<Scalars['Boolean']>;
  not_self?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserFilter>;
  avatar?: Maybe<FileFilter>;
  roles?: Maybe<RoleRelationFilter>;
  invitation?: Maybe<InvitationFilter>;
  company?: Maybe<CompanyFilter>;
  employer?: Maybe<CompanyFilter>;
  loanApplications?: Maybe<LoanApplicationFilter>;
  portfolioCompanies?: Maybe<PortfolioCompanyRelationFilter>;
  AND?: Maybe<Array<UserFilter>>;
  OR?: Maybe<Array<UserFilter>>;
};

export type IdPredicate = {
  equals?: Maybe<Scalars['ID']>;
  not_equals?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  not_in?: Maybe<Array<Scalars['ID']>>;
  contains?: Maybe<Scalars['ID']>;
  not_contains?: Maybe<Scalars['ID']>;
  starts_with?: Maybe<Scalars['ID']>;
  not_starts_with?: Maybe<Scalars['ID']>;
  ends_with?: Maybe<Scalars['ID']>;
  not_ends_with?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
};

export type DateTimePredicate = {
  equals?: Maybe<Scalars['DateTime']>;
  not_equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  not_in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  relative?: Maybe<DateRelativePredicates>;
};

export type DateRelativePredicates = {
  lt?: Maybe<DateRelativePredicateType>;
  lte?: Maybe<DateRelativePredicateType>;
  gt?: Maybe<DateRelativePredicateType>;
  gte?: Maybe<DateRelativePredicateType>;
};

export type DateRelativePredicateType = {
  op?: Maybe<RelativePredicateOpEnum>;
  unit?: Maybe<RelativePredicateUnitEnum>;
  value: Scalars['String'];
};

/** Relative Date Predicate Operation Enum */
export enum RelativePredicateOpEnum {
  Add = 'ADD',
  Sub = 'SUB',
}

/** Relative Date Predicate Unit Enum */
export enum RelativePredicateUnitEnum {
  Microsecond = 'MICROSECOND',
  Second = 'SECOND',
  Minute = 'MINUTE',
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
  SecondMicrosecond = 'SECOND_MICROSECOND',
  MinuteMicrosecond = 'MINUTE_MICROSECOND',
  MinuteSecond = 'MINUTE_SECOND',
  HourMicrosecond = 'HOUR_MICROSECOND',
  HourSecond = 'HOUR_SECOND',
  HourMinute = 'HOUR_MINUTE',
  DayMicrosecond = 'DAY_MICROSECOND',
  DaySecond = 'DAY_SECOND',
  DayMinute = 'DAY_MINUTE',
  DayHour = 'DAY_HOUR',
  YearMonth = 'YEAR_MONTH',
}

export type IntPredicate = {
  equals?: Maybe<Scalars['Int']>;
  not_equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  not_in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
};

export type StringPredicate = {
  equals?: Maybe<Scalars['String']>;
  not_equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  not_in?: Maybe<Array<Scalars['String']>>;
  contains?: Maybe<Scalars['String']>;
  not_contains?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  not_starts_with?: Maybe<Scalars['String']>;
  ends_with?: Maybe<Scalars['String']>;
  not_ends_with?: Maybe<Scalars['String']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
};

export type BoolPredicate = {
  equals?: Maybe<Scalars['Boolean']>;
  not_equals?: Maybe<Scalars['Boolean']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
};

export type FileFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  fileId?: Maybe<StringPredicate>;
  downloadUrl?: Maybe<StringPredicate>;
  shareUrl?: Maybe<StringPredicate>;
  provider?: Maybe<StringPredicate>;
  public?: Maybe<BoolPredicate>;
  uploaded?: Maybe<BoolPredicate>;
  filename?: Maybe<StringPredicate>;
  uploadUrl?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  settings_menuBarLogo?: Maybe<SettingRelationFilter>;
  settings_landingPageImage?: Maybe<SettingRelationFilter>;
  users_avatar?: Maybe<UserRelationFilter>;
  teamMembers_avatar?: Maybe<TeamMemberRelationFilter>;
  portfolioCompanies_logo?: Maybe<CompanyRelationFilter>;
  documents_file?: Maybe<DocumentRelationFilter>;
  notes_attachments?: Maybe<NoteRelationFilter>;
  AND?: Maybe<Array<FileFilter>>;
  OR?: Maybe<Array<FileFilter>>;
};

export type SettingRelationFilter = {
  some?: Maybe<SettingFilter>;
  every?: Maybe<SettingFilter>;
  none?: Maybe<SettingFilter>;
};

export type SettingFilter = {
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  passwordMinLength?: Maybe<IntPredicate>;
  passwordRequireNumbers?: Maybe<BoolPredicate>;
  passwordRequireSpecial?: Maybe<BoolPredicate>;
  passwordRequireUppercase?: Maybe<BoolPredicate>;
  passwordRequireLowercase?: Maybe<BoolPredicate>;
  passwordUpdateInterval?: Maybe<IntPredicate>;
  rememberDevice?: Maybe<StringPredicate>;
  language?: Maybe<StringPredicate>;
  dateFormat?: Maybe<StringPredicate>;
  currency?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  vanityUrl?: Maybe<StringPredicate>;
  buttonLinkColor?: Maybe<StringPredicate>;
  userInterfaceStyle?: Maybe<StringPredicate>;
  menuBarBGColor?: Maybe<StringPredicate>;
  menuBarIconsColor?: Maybe<StringPredicate>;
  bgColor?: Maybe<StringPredicate>;
  containerColor?: Maybe<StringPredicate>;
  leftNavColor?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  menuBarLogo?: Maybe<FileFilter>;
  landingPageImage?: Maybe<FileFilter>;
  AND?: Maybe<Array<SettingFilter>>;
  OR?: Maybe<Array<SettingFilter>>;
};

export type UserRelationFilter = {
  some?: Maybe<UserFilter>;
  every?: Maybe<UserFilter>;
  none?: Maybe<UserFilter>;
};

export type TeamMemberRelationFilter = {
  some?: Maybe<TeamMemberFilter>;
  every?: Maybe<TeamMemberFilter>;
  none?: Maybe<TeamMemberFilter>;
};

export type TeamMemberFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  isOwner?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  user?: Maybe<UserFilter>;
  avatar?: Maybe<FileFilter>;
  roles?: Maybe<RoleRelationFilter>;
  receivedTeamInvitations?: Maybe<TeamInvitationRelationFilter>;
  sentTeamInvitations?: Maybe<TeamInvitationRelationFilter>;
  AND?: Maybe<Array<TeamMemberFilter>>;
  OR?: Maybe<Array<TeamMemberFilter>>;
};

export type RoleRelationFilter = {
  some?: Maybe<RoleFilter>;
  every?: Maybe<RoleFilter>;
  none?: Maybe<RoleFilter>;
};

export type RoleFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  description?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  users?: Maybe<UserRelationFilter>;
  permissions?: Maybe<PermissionRelationFilter>;
  apiTokens?: Maybe<ApiTokenRelationFilter>;
  authenticationProfiles?: Maybe<AuthenticationProfileRelationFilter>;
  teamMembers?: Maybe<TeamMemberRelationFilter>;
  AND?: Maybe<Array<RoleFilter>>;
  OR?: Maybe<Array<RoleFilter>>;
};

export type PermissionRelationFilter = {
  some?: Maybe<PermissionFilter>;
  every?: Maybe<PermissionFilter>;
  none?: Maybe<PermissionFilter>;
};

export type PermissionFilter = {
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  appId?: Maybe<StringPredicate>;
  resourceType?: Maybe<StringPredicate>;
  resource?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  role?: Maybe<RoleFilter>;
  AND?: Maybe<Array<PermissionFilter>>;
  OR?: Maybe<Array<PermissionFilter>>;
};

export type ApiTokenRelationFilter = {
  some?: Maybe<ApiTokenFilter>;
  every?: Maybe<ApiTokenFilter>;
  none?: Maybe<ApiTokenFilter>;
};

export type ApiTokenFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  token?: Maybe<StringPredicate>;
  createdBy?: Maybe<UserFilter>;
  roles?: Maybe<RoleRelationFilter>;
  AND?: Maybe<Array<ApiTokenFilter>>;
  OR?: Maybe<Array<ApiTokenFilter>>;
};

export type AuthenticationProfileRelationFilter = {
  some?: Maybe<AuthenticationProfileFilter>;
  every?: Maybe<AuthenticationProfileFilter>;
  none?: Maybe<AuthenticationProfileFilter>;
};

export type AuthenticationProfileFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  secret?: Maybe<StringPredicate>;
  managementDomain?: Maybe<StringPredicate>;
  clientId?: Maybe<StringPredicate>;
  databaseName?: Maybe<StringPredicate>;
  domain?: Maybe<StringPredicate>;
  selfSignUpEnabled?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  roles?: Maybe<RoleRelationFilter>;
  AND?: Maybe<Array<AuthenticationProfileFilter>>;
  OR?: Maybe<Array<AuthenticationProfileFilter>>;
};

export type TeamInvitationRelationFilter = {
  some?: Maybe<TeamInvitationFilter>;
  every?: Maybe<TeamInvitationFilter>;
  none?: Maybe<TeamInvitationFilter>;
};

export type TeamInvitationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  uuid?: Maybe<StringPredicate>;
  email?: Maybe<StringPredicate>;
  firstName?: Maybe<StringPredicate>;
  lastName?: Maybe<StringPredicate>;
  resentOn?: Maybe<DateTimePredicate>;
  accepted?: Maybe<BoolPredicate>;
  acceptedOn?: Maybe<DateTimePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  invitee?: Maybe<TeamMemberFilter>;
  invitedBy?: Maybe<TeamMemberFilter>;
  AND?: Maybe<Array<TeamInvitationFilter>>;
  OR?: Maybe<Array<TeamInvitationFilter>>;
};

export type CompanyRelationFilter = {
  some?: Maybe<CompanyFilter>;
  every?: Maybe<CompanyFilter>;
  none?: Maybe<CompanyFilter>;
};

export type CompanyFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  address?: Maybe<SmartAddressPredicate>;
  companyStory?: Maybe<StringPredicate>;
  founderStory?: Maybe<StringPredicate>;
  productDescription?: Maybe<StringPredicate>;
  marketDescription?: Maybe<StringPredicate>;
  customersStrategy?: Maybe<StringPredicate>;
  capital?: Maybe<IntPredicate>;
  equityCapitalAmount?: Maybe<IntPredicate>;
  monthsToNextRound?: Maybe<IntPredicate>;
  securedDebt?: Maybe<IntPredicate>;
  foundedYear?: Maybe<IntPredicate>;
  structure?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  contactFirstName?: Maybe<StringPredicate>;
  contactTitle?: Maybe<StringPredicate>;
  contactEmail?: Maybe<StringPredicate>;
  active?: Maybe<BoolPredicate>;
  primaryCustomerBase?: Maybe<StringPredicate>;
  idealRaiseAmount?: Maybe<IntPredicate>;
  borrowedDebt?: Maybe<IntPredicate>;
  saaSScore?: Maybe<IntPredicate>;
  primaryBusinessModel?: Maybe<StringPredicate>;
  applicationFinalized?: Maybe<BoolPredicate>;
  contactLastName?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  logo?: Maybe<FileFilter>;
  notes?: Maybe<NoteRelationFilter>;
  loanApplication?: Maybe<LoanApplicationFilter>;
  documents?: Maybe<DocumentRelationFilter>;
  facilities?: Maybe<FacilityRelationFilter>;
  owner?: Maybe<UserFilter>;
  integration?: Maybe<IntegrationFilter>;
  portfolioCompany?: Maybe<PortfolioCompanyFilter>;
  team?: Maybe<UserRelationFilter>;
  metrics?: Maybe<MetricValueRelationFilter>;
  monitoringValues?: Maybe<CompanyMonitoringRelationFilter>;
  company?: Maybe<ProgressStepValueRelationFilter>;
  inboxMessages?: Maybe<InboxMessageRelationFilter>;
  companyStep?: Maybe<ProgressCompanyStepRelationFilter>;
  covenantValue?: Maybe<CovenantValueRelationFilter>;
  customCovenant?: Maybe<CustomCovenantValueRelationFilter>;
  AND?: Maybe<Array<CompanyFilter>>;
  OR?: Maybe<Array<CompanyFilter>>;
};

export type SmartAddressPredicate = {
  country?: Maybe<StringPredicate>;
  street1?: Maybe<StringPredicate>;
  street2?: Maybe<StringPredicate>;
  zip?: Maybe<StringPredicate>;
  city?: Maybe<StringPredicate>;
  state?: Maybe<StringPredicate>;
};

export type NoteRelationFilter = {
  some?: Maybe<NoteFilter>;
  every?: Maybe<NoteFilter>;
  none?: Maybe<NoteFilter>;
};

export type NoteFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  title?: Maybe<StringPredicate>;
  body?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  attachments?: Maybe<FileRelationFilter>;
  company?: Maybe<CompanyFilter>;
  AND?: Maybe<Array<NoteFilter>>;
  OR?: Maybe<Array<NoteFilter>>;
};

export type FileRelationFilter = {
  some?: Maybe<FileFilter>;
  every?: Maybe<FileFilter>;
  none?: Maybe<FileFilter>;
};

export type LoanApplicationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  stage?: Maybe<StringPredicate>;
  contactName?: Maybe<StringPredicate>;
  applicantTitle?: Maybe<StringPredicate>;
  amountRequested?: Maybe<IntPredicate>;
  amountCommitted?: Maybe<IntPredicate>;
  firstDrawProposed?: Maybe<IntPredicate>;
  targetCloseDate?: Maybe<DatePredicate>;
  structure?: Maybe<StringPredicate>;
  status?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  company?: Maybe<CompanyFilter>;
  documents?: Maybe<DocumentRelationFilter>;
  dealTeam?: Maybe<UserRelationFilter>;
  AND?: Maybe<Array<LoanApplicationFilter>>;
  OR?: Maybe<Array<LoanApplicationFilter>>;
};

export type DatePredicate = {
  equals?: Maybe<Scalars['Date']>;
  not_equals?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  not_in?: Maybe<Array<Scalars['Date']>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  relative?: Maybe<DateRelativePredicates>;
};

export type DocumentRelationFilter = {
  some?: Maybe<DocumentFilter>;
  every?: Maybe<DocumentFilter>;
  none?: Maybe<DocumentFilter>;
};

export type DocumentFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  source?: Maybe<StringPredicate>;
  private?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  file?: Maybe<FileFilter>;
  company?: Maybe<CompanyFilter>;
  loanApplication?: Maybe<LoanApplicationFilter>;
  inboxMessage?: Maybe<InboxMessageRelationFilter>;
  AND?: Maybe<Array<DocumentFilter>>;
  OR?: Maybe<Array<DocumentFilter>>;
};

export type InboxMessageRelationFilter = {
  some?: Maybe<InboxMessageFilter>;
  every?: Maybe<InboxMessageFilter>;
  none?: Maybe<InboxMessageFilter>;
};

export type InboxMessageFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  subject?: Maybe<StringPredicate>;
  body?: Maybe<StringPredicate>;
  isRead?: Maybe<BoolPredicate>;
  isByAdministrator?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  attachments?: Maybe<DocumentRelationFilter>;
  company?: Maybe<CompanyFilter>;
  repliedOn?: Maybe<InboxMessageFilter>;
  replies?: Maybe<InboxMessageRelationFilter>;
  AND?: Maybe<Array<InboxMessageFilter>>;
  OR?: Maybe<Array<InboxMessageFilter>>;
};

export type FacilityRelationFilter = {
  some?: Maybe<FacilityFilter>;
  every?: Maybe<FacilityFilter>;
  none?: Maybe<FacilityFilter>;
};

export type FacilityFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  structure?: Maybe<StringPredicate>;
  amountCommitted?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  comments?: Maybe<StringPredicate>;
  totalAmountDeployed?: Maybe<IntPredicate>;
  nextAvailableDraw?: Maybe<DatePredicate>;
  closingFee?: Maybe<StringPredicate>;
  successFee?: Maybe<FloatPredicate>;
  minCashCovenant?: Maybe<IntPredicate>;
  minCashRunawayCovenant?: Maybe<IntPredicate>;
  validityGuarantee?: Maybe<StringPredicate>;
  advanceRate?: Maybe<FloatPredicate>;
  amountDeployed?: Maybe<IntPredicate>;
  fundingDate?: Maybe<DatePredicate>;
  closingFeeValue?: Maybe<FloatPredicate>;
  closingDate?: Maybe<DatePredicate>;
  finalAdvanceRate?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  company?: Maybe<CompanyFilter>;
  draws?: Maybe<DrawRelationFilter>;
  facilityHistory?: Maybe<FacilityHistoryRelationFilter>;
  drawHistory?: Maybe<DrawHistoryRelationFilter>;
  AND?: Maybe<Array<FacilityFilter>>;
  OR?: Maybe<Array<FacilityFilter>>;
};

export type FloatPredicate = {
  equals?: Maybe<Scalars['Float']>;
  not_equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  not_in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
};

export type DrawRelationFilter = {
  some?: Maybe<DrawFilter>;
  every?: Maybe<DrawFilter>;
  none?: Maybe<DrawFilter>;
};

export type DrawFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  fundedDate?: Maybe<DatePredicate>;
  maturityDate?: Maybe<DatePredicate>;
  amountDeployed?: Maybe<IntPredicate>;
  interestRate?: Maybe<FloatPredicate>;
  cashShare?: Maybe<FloatPredicate>;
  returnCap?: Maybe<FloatPredicate>;
  term?: Maybe<IntPredicate>;
  iOPeriod?: Maybe<IntPredicate>;
  amortizationPeriod?: Maybe<IntPredicate>;
  iOPeriodAmortizationPeriod?: Maybe<IntPredicate>;
  exitFee?: Maybe<FloatPredicate>;
  prepayment?: Maybe<StringPredicate>;
  iRR?: Maybe<FloatPredicate>;
  mOIC?: Maybe<FloatPredicate>;
  comments?: Maybe<StringPredicate>;
  prepaymentMonths?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  facility?: Maybe<FacilityFilter>;
  drawHistory?: Maybe<DrawHistoryRelationFilter>;
  AND?: Maybe<Array<DrawFilter>>;
  OR?: Maybe<Array<DrawFilter>>;
};

export type DrawHistoryRelationFilter = {
  some?: Maybe<DrawHistoryFilter>;
  every?: Maybe<DrawHistoryFilter>;
  none?: Maybe<DrawHistoryFilter>;
};

export type DrawHistoryFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  draw?: Maybe<DrawFilter>;
  facility?: Maybe<FacilityFilter>;
  drawHistoryValue?: Maybe<DrawHistoryValueRelationFilter>;
  AND?: Maybe<Array<DrawHistoryFilter>>;
  OR?: Maybe<Array<DrawHistoryFilter>>;
};

export type DrawHistoryValueRelationFilter = {
  some?: Maybe<DrawHistoryValueFilter>;
  every?: Maybe<DrawHistoryValueFilter>;
  none?: Maybe<DrawHistoryValueFilter>;
};

export type DrawHistoryValueFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  date?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  drawHistory?: Maybe<DrawHistoryFilter>;
  AND?: Maybe<Array<DrawHistoryValueFilter>>;
  OR?: Maybe<Array<DrawHistoryValueFilter>>;
};

export type FacilityHistoryRelationFilter = {
  some?: Maybe<FacilityHistoryFilter>;
  every?: Maybe<FacilityHistoryFilter>;
  none?: Maybe<FacilityHistoryFilter>;
};

export type FacilityHistoryFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  facility?: Maybe<FacilityFilter>;
  facilityHistoryValue?: Maybe<FacilityHistoryValueRelationFilter>;
  AND?: Maybe<Array<FacilityHistoryFilter>>;
  OR?: Maybe<Array<FacilityHistoryFilter>>;
};

export type FacilityHistoryValueRelationFilter = {
  some?: Maybe<FacilityHistoryValueFilter>;
  every?: Maybe<FacilityHistoryValueFilter>;
  none?: Maybe<FacilityHistoryValueFilter>;
};

export type FacilityHistoryValueFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  date?: Maybe<DatePredicate>;
  advanceRate?: Maybe<FloatPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  facilityHistory?: Maybe<FacilityHistoryFilter>;
  AND?: Maybe<Array<FacilityHistoryValueFilter>>;
  OR?: Maybe<Array<FacilityHistoryValueFilter>>;
};

export type IntegrationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  company?: Maybe<CompanyFilter>;
  xero?: Maybe<XeroIntegrationFilter>;
  quickBooks?: Maybe<QuickBooksIntegrationFilter>;
  stripe?: Maybe<StripeIntegrationFilter>;
  plaid?: Maybe<PlaidIntegrationRelationFilter>;
  AND?: Maybe<Array<IntegrationFilter>>;
  OR?: Maybe<Array<IntegrationFilter>>;
};

export type XeroIntegrationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  refreshToken?: Maybe<StringPredicate>;
  expiresAt?: Maybe<IntPredicate>;
  tenantId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  integrations?: Maybe<IntegrationFilter>;
  AND?: Maybe<Array<XeroIntegrationFilter>>;
  OR?: Maybe<Array<XeroIntegrationFilter>>;
};

export type QuickBooksIntegrationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  refreshToken?: Maybe<StringPredicate>;
  expiresAt?: Maybe<IntPredicate>;
  realmId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  integrations?: Maybe<IntegrationFilter>;
  AND?: Maybe<Array<QuickBooksIntegrationFilter>>;
  OR?: Maybe<Array<QuickBooksIntegrationFilter>>;
};

export type StripeIntegrationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  refreshToken?: Maybe<StringPredicate>;
  userId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  integrations?: Maybe<IntegrationFilter>;
  AND?: Maybe<Array<StripeIntegrationFilter>>;
  OR?: Maybe<Array<StripeIntegrationFilter>>;
};

export type PlaidIntegrationRelationFilter = {
  some?: Maybe<PlaidIntegrationFilter>;
  every?: Maybe<PlaidIntegrationFilter>;
  none?: Maybe<PlaidIntegrationFilter>;
};

export type PlaidIntegrationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  institutionId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  integrations?: Maybe<IntegrationFilter>;
  accounts?: Maybe<PlaidIntegrationAccountRelationFilter>;
  AND?: Maybe<Array<PlaidIntegrationFilter>>;
  OR?: Maybe<Array<PlaidIntegrationFilter>>;
};

export type PlaidIntegrationAccountRelationFilter = {
  some?: Maybe<PlaidIntegrationAccountFilter>;
  every?: Maybe<PlaidIntegrationAccountFilter>;
  none?: Maybe<PlaidIntegrationAccountFilter>;
};

export type PlaidIntegrationAccountFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  accountId?: Maybe<StringPredicate>;
  name?: Maybe<StringPredicate>;
  mask?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  plaidIntegration?: Maybe<PlaidIntegrationFilter>;
  AND?: Maybe<Array<PlaidIntegrationAccountFilter>>;
  OR?: Maybe<Array<PlaidIntegrationAccountFilter>>;
};

export type PortfolioCompanyFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  company?: Maybe<CompanyFilter>;
  dealTeam?: Maybe<UserRelationFilter>;
  cashMonitoringRecords?: Maybe<CashMonitoringRecordRelationFilter>;
  AND?: Maybe<Array<PortfolioCompanyFilter>>;
  OR?: Maybe<Array<PortfolioCompanyFilter>>;
};

export type CashMonitoringRecordRelationFilter = {
  some?: Maybe<CashMonitoringRecordFilter>;
  every?: Maybe<CashMonitoringRecordFilter>;
  none?: Maybe<CashMonitoringRecordFilter>;
};

export type CashMonitoringRecordFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  note?: Maybe<StringPredicate>;
  action?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  portfolioCompany?: Maybe<PortfolioCompanyFilter>;
  AND?: Maybe<Array<CashMonitoringRecordFilter>>;
  OR?: Maybe<Array<CashMonitoringRecordFilter>>;
};

export type MetricValueRelationFilter = {
  some?: Maybe<MetricValueFilter>;
  every?: Maybe<MetricValueFilter>;
  none?: Maybe<MetricValueFilter>;
};

export type MetricValueFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  source?: Maybe<StringPredicate>;
  value?: Maybe<FloatPredicate>;
  adjustedValue?: Maybe<FloatPredicate>;
  comment?: Maybe<StringPredicate>;
  tier?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  period?: Maybe<StringPredicate>;
  active?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  metric?: Maybe<MetricFilter>;
  company?: Maybe<CompanyFilter>;
  AND?: Maybe<Array<MetricValueFilter>>;
  OR?: Maybe<Array<MetricValueFilter>>;
};

export type MetricFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  description?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  format?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  saaSScoreWeight?: Maybe<FloatPredicate>;
  saaSScoreWeightEnterprise?: Maybe<FloatPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  values?: Maybe<MetricValueRelationFilter>;
  AND?: Maybe<Array<MetricFilter>>;
  OR?: Maybe<Array<MetricFilter>>;
};

export type CompanyMonitoringRelationFilter = {
  some?: Maybe<CompanyMonitoringFilter>;
  every?: Maybe<CompanyMonitoringFilter>;
  none?: Maybe<CompanyMonitoringFilter>;
};

export type CompanyMonitoringFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  committed?: Maybe<IntPredicate>;
  deployed?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  companies?: Maybe<CompanyFilter>;
  AND?: Maybe<Array<CompanyMonitoringFilter>>;
  OR?: Maybe<Array<CompanyMonitoringFilter>>;
};

export type ProgressStepValueRelationFilter = {
  some?: Maybe<ProgressStepValueFilter>;
  every?: Maybe<ProgressStepValueFilter>;
  none?: Maybe<ProgressStepValueFilter>;
};

export type ProgressStepValueFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  customStepName?: Maybe<StringPredicate>;
  customStepComment?: Maybe<StringPredicate>;
  bigfootTeamComment?: Maybe<StringPredicate>;
  applicantComment?: Maybe<StringPredicate>;
  isVisible?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  company?: Maybe<CompanyFilter>;
  step?: Maybe<ProgressStepFilter>;
  AND?: Maybe<Array<ProgressStepValueFilter>>;
  OR?: Maybe<Array<ProgressStepValueFilter>>;
};

export type ProgressStepFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  order?: Maybe<IntPredicate>;
  comment?: Maybe<StringPredicate>;
  helperText?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  stage?: Maybe<ProgressStageFilter>;
  stepValue?: Maybe<ProgressStepValueRelationFilter>;
  AND?: Maybe<Array<ProgressStepFilter>>;
  OR?: Maybe<Array<ProgressStepFilter>>;
};

export type ProgressStageFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  steps?: Maybe<ProgressStepRelationFilter>;
  companySteps?: Maybe<ProgressCompanyStepRelationFilter>;
  AND?: Maybe<Array<ProgressStageFilter>>;
  OR?: Maybe<Array<ProgressStageFilter>>;
};

export type ProgressStepRelationFilter = {
  some?: Maybe<ProgressStepFilter>;
  every?: Maybe<ProgressStepFilter>;
  none?: Maybe<ProgressStepFilter>;
};

export type ProgressCompanyStepRelationFilter = {
  some?: Maybe<ProgressCompanyStepFilter>;
  every?: Maybe<ProgressCompanyStepFilter>;
  none?: Maybe<ProgressCompanyStepFilter>;
};

export type ProgressCompanyStepFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  comment?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  bigfootTeamComment?: Maybe<StringPredicate>;
  applicantComment?: Maybe<StringPredicate>;
  isVisible?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  stage?: Maybe<ProgressStageFilter>;
  company?: Maybe<CompanyFilter>;
  AND?: Maybe<Array<ProgressCompanyStepFilter>>;
  OR?: Maybe<Array<ProgressCompanyStepFilter>>;
};

export type CovenantValueRelationFilter = {
  some?: Maybe<CovenantValueFilter>;
  every?: Maybe<CovenantValueFilter>;
  none?: Maybe<CovenantValueFilter>;
};

export type CovenantValueFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  value?: Maybe<StringPredicate>;
  isEnabled?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  covenant?: Maybe<CovenantFilter>;
  company?: Maybe<CompanyFilter>;
  AND?: Maybe<Array<CovenantValueFilter>>;
  OR?: Maybe<Array<CovenantValueFilter>>;
};

export type CovenantFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  covenantValue?: Maybe<CovenantValueRelationFilter>;
  customCovenantValue?: Maybe<CustomCovenantValueRelationFilter>;
  AND?: Maybe<Array<CovenantFilter>>;
  OR?: Maybe<Array<CovenantFilter>>;
};

export type CustomCovenantValueRelationFilter = {
  some?: Maybe<CustomCovenantValueFilter>;
  every?: Maybe<CustomCovenantValueFilter>;
  none?: Maybe<CustomCovenantValueFilter>;
};

export type CustomCovenantValueFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  actual?: Maybe<StringPredicate>;
  difference?: Maybe<StringPredicate>;
  inCompliance?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  covenant?: Maybe<CovenantFilter>;
  company?: Maybe<CompanyFilter>;
  AND?: Maybe<Array<CustomCovenantValueFilter>>;
  OR?: Maybe<Array<CustomCovenantValueFilter>>;
};

export type InvitationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  invitedUser?: Maybe<UserFilter>;
  AND?: Maybe<Array<InvitationFilter>>;
  OR?: Maybe<Array<InvitationFilter>>;
};

export type PortfolioCompanyRelationFilter = {
  some?: Maybe<PortfolioCompanyFilter>;
  every?: Maybe<PortfolioCompanyFilter>;
  none?: Maybe<PortfolioCompanyFilter>;
};

/** No longer supported. Use `sort` instead. */
export enum UserOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  OriginAsc = 'origin_ASC',
  OriginDesc = 'origin_DESC',
  IsOwnerAsc = 'isOwner_ASC',
  IsOwnerDesc = 'isOwner_DESC',
  Is8baseAsc = 'is8base_ASC',
  Is8baseDesc = 'is8base_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type UserSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  origin?: Maybe<SortOrder>;
  is8base?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  avatar?: Maybe<FileSort>;
  invitation?: Maybe<InvitationSort>;
  company?: Maybe<CompanySort>;
  employer?: Maybe<CompanySort>;
  loanApplications?: Maybe<LoanApplicationSort>;
};

/** SortOrder */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type FileSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  fileId?: Maybe<SortOrder>;
  downloadUrl?: Maybe<SortOrder>;
  shareUrl?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  public?: Maybe<SortOrder>;
  uploaded?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  uploadUrl?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type InvitationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  invitedUser?: Maybe<UserSort>;
};

export type CompanySort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  address?: Maybe<SmartAddressSort>;
  companyStory?: Maybe<SortOrder>;
  founderStory?: Maybe<SortOrder>;
  productDescription?: Maybe<SortOrder>;
  marketDescription?: Maybe<SortOrder>;
  customersStrategy?: Maybe<SortOrder>;
  capital?: Maybe<SortOrder>;
  equityCapitalAmount?: Maybe<SortOrder>;
  monthsToNextRound?: Maybe<SortOrder>;
  securedDebt?: Maybe<SortOrder>;
  foundedYear?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  contactFirstName?: Maybe<SortOrder>;
  contactTitle?: Maybe<SortOrder>;
  contactEmail?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  primaryCustomerBase?: Maybe<SortOrder>;
  idealRaiseAmount?: Maybe<SortOrder>;
  borrowedDebt?: Maybe<SortOrder>;
  saaSScore?: Maybe<SortOrder>;
  primaryBusinessModel?: Maybe<SortOrder>;
  applicationFinalized?: Maybe<SortOrder>;
  contactLastName?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  logo?: Maybe<FileSort>;
  loanApplication?: Maybe<LoanApplicationSort>;
  owner?: Maybe<UserSort>;
  integration?: Maybe<IntegrationSort>;
  portfolioCompany?: Maybe<PortfolioCompanySort>;
};

export type SmartAddressSort = {
  country?: Maybe<SortOrder>;
  street1?: Maybe<SortOrder>;
  street2?: Maybe<SortOrder>;
  zip?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type LoanApplicationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  contactName?: Maybe<SortOrder>;
  applicantTitle?: Maybe<SortOrder>;
  amountRequested?: Maybe<SortOrder>;
  amountCommitted?: Maybe<SortOrder>;
  firstDrawProposed?: Maybe<SortOrder>;
  targetCloseDate?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
};

export type IntegrationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
  xero?: Maybe<XeroIntegrationSort>;
  quickBooks?: Maybe<QuickBooksIntegrationSort>;
  stripe?: Maybe<StripeIntegrationSort>;
};

export type XeroIntegrationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  accessToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  tenantId?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  integrations?: Maybe<IntegrationSort>;
};

export type QuickBooksIntegrationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  accessToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  realmId?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  integrations?: Maybe<IntegrationSort>;
};

export type StripeIntegrationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  accessToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  integrations?: Maybe<IntegrationSort>;
};

export type PortfolioCompanySort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
};

export type UserGroupBy = {
  query: UserGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type UserGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  email?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  origin?: Maybe<Array<GroupByField>>;
  is8base?: Maybe<Array<GroupByField>>;
  firstName?: Maybe<Array<GroupByField>>;
  lastName?: Maybe<Array<GroupByField>>;
  timezone?: Maybe<Array<GroupByField>>;
  title?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  avatar?: Maybe<FileGroupByQuery>;
  roles?: Maybe<RoleGroupByQuery>;
  invitation?: Maybe<InvitationGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  employer?: Maybe<CompanyGroupByQuery>;
  loanApplications?: Maybe<LoanApplicationGroupByQuery>;
  portfolioCompanies?: Maybe<PortfolioCompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type GroupByField = {
  as?: Maybe<Scalars['String']>;
  fn?: Maybe<Array<Maybe<GroupByFieldFunction>>>;
};

export type GroupByFieldFunction = {
  aggregate?: Maybe<AggregationFunctionType>;
  distinct?: Maybe<Scalars['Boolean']>;
  datePart?: Maybe<DatePartFunctionType>;
  abs?: Maybe<Scalars['Boolean']>;
  ceil?: Maybe<Scalars['Boolean']>;
  crc32?: Maybe<Scalars['Boolean']>;
  floor?: Maybe<Scalars['Boolean']>;
  mod?: Maybe<Scalars['Int']>;
  round?: Maybe<Scalars['Int']>;
  sign?: Maybe<Scalars['Boolean']>;
  truncate?: Maybe<Scalars['Int']>;
  left?: Maybe<Scalars['Int']>;
  right?: Maybe<Scalars['Int']>;
  locate?: Maybe<LocateFunctionArguments>;
  substring?: Maybe<SubstringFunctionArguments>;
  replace?: Maybe<ReplaceFunctionArguments>;
  reverse?: Maybe<Scalars['Boolean']>;
  trim?: Maybe<TrimFunctionArguments>;
  ltrim?: Maybe<Scalars['Boolean']>;
  rtrim?: Maybe<Scalars['Boolean']>;
  lower?: Maybe<Scalars['Boolean']>;
  upper?: Maybe<Scalars['Boolean']>;
  ascii?: Maybe<Scalars['Boolean']>;
  hex?: Maybe<Scalars['Boolean']>;
  bitLength?: Maybe<Scalars['Boolean']>;
  charLength?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Boolean']>;
  like?: Maybe<PatternFunctionArguments>;
  notLike?: Maybe<PatternFunctionArguments>;
  lpad?: Maybe<StringPadFunctionArguments>;
  rpad?: Maybe<StringPadFunctionArguments>;
  ifNull?: Maybe<Scalars['String']>;
  isNull?: Maybe<Scalars['Boolean']>;
  nullIf?: Maybe<Scalars['String']>;
};

export enum AggregationFunctionType {
  Avg = 'AVG',
  Sum = 'SUM',
  Count = 'COUNT',
  Min = 'MIN',
  Max = 'MAX',
  GroupConcat = 'GROUP_CONCAT',
  AnyValue = 'ANY_VALUE',
  StddevPop = 'STDDEV_POP',
  StddevSamp = 'STDDEV_SAMP',
  VarPop = 'VAR_POP',
  VarSamp = 'VAR_SAMP',
}

export enum DatePartFunctionType {
  Date = 'DATE',
  Time = 'TIME',
  Week = 'WEEK',
  WeekDay = 'WEEK_DAY',
  WeekOfYear = 'WEEK_OF_YEAR',
  Year = 'YEAR',
  YearWeek = 'YEAR_WEEK',
  DayName = 'DAY_NAME',
  DayOfMonth = 'DAY_OF_MONTH',
  DayOfWeek = 'DAY_OF_WEEK',
  DayOfYear = 'DAY_OF_YEAR',
  Quarter = 'QUARTER',
  Day = 'DAY',
  Month = 'MONTH',
  MonthName = 'MONTH_NAME',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Second = 'SECOND',
  Microsecond = 'MICROSECOND',
  LastDay = 'LAST_DAY',
}

export type LocateFunctionArguments = {
  str: Scalars['String'];
  pos?: Maybe<Scalars['Int']>;
};

export type SubstringFunctionArguments = {
  pos: Scalars['Int'];
  len?: Maybe<Scalars['Int']>;
};

export type ReplaceFunctionArguments = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type TrimFunctionArguments = {
  str: Scalars['String'];
  mode?: Maybe<StringTrimMode>;
};

export enum StringTrimMode {
  Both = 'BOTH',
  Leading = 'LEADING',
  Trailing = 'TRAILING',
}

export type PatternFunctionArguments = {
  pattern: Scalars['String'];
  escape?: Maybe<Scalars['String']>;
};

export type StringPadFunctionArguments = {
  len: Scalars['Int'];
  str: Scalars['String'];
};

export type FileGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  fileId?: Maybe<Array<GroupByField>>;
  downloadUrl?: Maybe<Array<GroupByField>>;
  shareUrl?: Maybe<Array<GroupByField>>;
  provider?: Maybe<Array<GroupByField>>;
  public?: Maybe<Array<GroupByField>>;
  uploaded?: Maybe<Array<GroupByField>>;
  filename?: Maybe<Array<GroupByField>>;
  uploadUrl?: Maybe<Array<GroupByField>>;
  fields?: Maybe<Array<GroupByField>>;
  meta?: Maybe<Array<GroupByField>>;
  mods?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  settings_menuBarLogo?: Maybe<SettingGroupByQuery>;
  settings_landingPageImage?: Maybe<SettingGroupByQuery>;
  users_avatar?: Maybe<UserGroupByQuery>;
  teamMembers_avatar?: Maybe<TeamMemberGroupByQuery>;
  portfolioCompanies_logo?: Maybe<CompanyGroupByQuery>;
  documents_file?: Maybe<DocumentGroupByQuery>;
  notes_attachments?: Maybe<NoteGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type SettingGroupByQuery = {
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  passwordMinLength?: Maybe<Array<GroupByField>>;
  passwordRequireNumbers?: Maybe<Array<GroupByField>>;
  passwordRequireSpecial?: Maybe<Array<GroupByField>>;
  passwordRequireUppercase?: Maybe<Array<GroupByField>>;
  passwordRequireLowercase?: Maybe<Array<GroupByField>>;
  passwordUpdateInterval?: Maybe<Array<GroupByField>>;
  rememberDevice?: Maybe<Array<GroupByField>>;
  language?: Maybe<Array<GroupByField>>;
  dateFormat?: Maybe<Array<GroupByField>>;
  currency?: Maybe<Array<GroupByField>>;
  timezone?: Maybe<Array<GroupByField>>;
  vanityUrl?: Maybe<Array<GroupByField>>;
  buttonLinkColor?: Maybe<Array<GroupByField>>;
  userInterfaceStyle?: Maybe<Array<GroupByField>>;
  menuBarBGColor?: Maybe<Array<GroupByField>>;
  menuBarIconsColor?: Maybe<Array<GroupByField>>;
  bgColor?: Maybe<Array<GroupByField>>;
  containerColor?: Maybe<Array<GroupByField>>;
  leftNavColor?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  menuBarLogo?: Maybe<FileGroupByQuery>;
  landingPageImage?: Maybe<FileGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type GroupIdentifiersGroupByField = {
  as: Scalars['String'];
};

export type TeamMemberGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  isOwner?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  user?: Maybe<UserGroupByQuery>;
  avatar?: Maybe<FileGroupByQuery>;
  roles?: Maybe<RoleGroupByQuery>;
  receivedTeamInvitations?: Maybe<TeamInvitationGroupByQuery>;
  sentTeamInvitations?: Maybe<TeamInvitationGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type RoleGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  description?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  users?: Maybe<UserGroupByQuery>;
  permissions?: Maybe<PermissionGroupByQuery>;
  apiTokens?: Maybe<ApiTokenGroupByQuery>;
  authenticationProfiles?: Maybe<AuthenticationProfileGroupByQuery>;
  teamMembers?: Maybe<TeamMemberGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type PermissionGroupByQuery = {
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  appId?: Maybe<Array<GroupByField>>;
  resourceType?: Maybe<Array<GroupByField>>;
  resource?: Maybe<Array<GroupByField>>;
  permission?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  role?: Maybe<RoleGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type ApiTokenGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  roles?: Maybe<RoleGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type AuthenticationProfileGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  type?: Maybe<Array<GroupByField>>;
  secret?: Maybe<Array<GroupByField>>;
  managementDomain?: Maybe<Array<GroupByField>>;
  clientId?: Maybe<Array<GroupByField>>;
  databaseName?: Maybe<Array<GroupByField>>;
  domain?: Maybe<Array<GroupByField>>;
  selfSignUpEnabled?: Maybe<Array<GroupByField>>;
  selfSignUpEmailDomains?: Maybe<Array<GroupByField>>;
  audiences?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  roles?: Maybe<RoleGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type TeamInvitationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  uuid?: Maybe<Array<GroupByField>>;
  email?: Maybe<Array<GroupByField>>;
  firstName?: Maybe<Array<GroupByField>>;
  lastName?: Maybe<Array<GroupByField>>;
  resentOn?: Maybe<Array<GroupByField>>;
  accepted?: Maybe<Array<GroupByField>>;
  acceptedOn?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  invitee?: Maybe<TeamMemberGroupByQuery>;
  invitedBy?: Maybe<TeamMemberGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type CompanyGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  address?: Maybe<SmartAddressGroupByQuery>;
  companyStory?: Maybe<Array<GroupByField>>;
  founderStory?: Maybe<Array<GroupByField>>;
  productDescription?: Maybe<Array<GroupByField>>;
  marketDescription?: Maybe<Array<GroupByField>>;
  customersStrategy?: Maybe<Array<GroupByField>>;
  capital?: Maybe<Array<GroupByField>>;
  capitalType?: Maybe<Array<GroupByField>>;
  capitalStrategy?: Maybe<Array<GroupByField>>;
  equityCapitalAmount?: Maybe<Array<GroupByField>>;
  equityCapitalOwner?: Maybe<Array<GroupByField>>;
  monthsToNextRound?: Maybe<Array<GroupByField>>;
  securedDebt?: Maybe<Array<GroupByField>>;
  foundedYear?: Maybe<Array<GroupByField>>;
  structure?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  contactFirstName?: Maybe<Array<GroupByField>>;
  contactTitle?: Maybe<Array<GroupByField>>;
  contactEmail?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  monitoringStatus?: Maybe<Array<GroupByField>>;
  primaryCustomerBase?: Maybe<Array<GroupByField>>;
  idealRaiseAmount?: Maybe<Array<GroupByField>>;
  borrowedDebt?: Maybe<Array<GroupByField>>;
  borrower?: Maybe<Array<GroupByField>>;
  saaSScore?: Maybe<Array<GroupByField>>;
  primaryBusinessModel?: Maybe<Array<GroupByField>>;
  applicationFinalized?: Maybe<Array<GroupByField>>;
  contactLastName?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  logo?: Maybe<FileGroupByQuery>;
  notes?: Maybe<NoteGroupByQuery>;
  loanApplication?: Maybe<LoanApplicationGroupByQuery>;
  documents?: Maybe<DocumentGroupByQuery>;
  facilities?: Maybe<FacilityGroupByQuery>;
  owner?: Maybe<UserGroupByQuery>;
  integration?: Maybe<IntegrationGroupByQuery>;
  portfolioCompany?: Maybe<PortfolioCompanyGroupByQuery>;
  team?: Maybe<UserGroupByQuery>;
  metrics?: Maybe<MetricValueGroupByQuery>;
  monitoringValues?: Maybe<CompanyMonitoringGroupByQuery>;
  company?: Maybe<ProgressStepValueGroupByQuery>;
  inboxMessages?: Maybe<InboxMessageGroupByQuery>;
  companyStep?: Maybe<ProgressCompanyStepGroupByQuery>;
  covenantValue?: Maybe<CovenantValueGroupByQuery>;
  customCovenant?: Maybe<CustomCovenantValueGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type SmartAddressGroupByQuery = {
  country?: Maybe<Array<GroupByField>>;
  street1?: Maybe<Array<GroupByField>>;
  street2?: Maybe<Array<GroupByField>>;
  zip?: Maybe<Array<GroupByField>>;
  city?: Maybe<Array<GroupByField>>;
  state?: Maybe<Array<GroupByField>>;
};

export type NoteGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  title?: Maybe<Array<GroupByField>>;
  body?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  attachments?: Maybe<FileGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type LoanApplicationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  stage?: Maybe<Array<GroupByField>>;
  contactName?: Maybe<Array<GroupByField>>;
  applicantTitle?: Maybe<Array<GroupByField>>;
  amountRequested?: Maybe<Array<GroupByField>>;
  amountCommitted?: Maybe<Array<GroupByField>>;
  firstDrawProposed?: Maybe<Array<GroupByField>>;
  targetCloseDate?: Maybe<Array<GroupByField>>;
  structure?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  documents?: Maybe<DocumentGroupByQuery>;
  dealTeam?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type DocumentGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  description?: Maybe<Array<GroupByField>>;
  type?: Maybe<Array<GroupByField>>;
  source?: Maybe<Array<GroupByField>>;
  private?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  file?: Maybe<FileGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  loanApplication?: Maybe<LoanApplicationGroupByQuery>;
  inboxMessage?: Maybe<InboxMessageGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type InboxMessageGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  subject?: Maybe<Array<GroupByField>>;
  body?: Maybe<Array<GroupByField>>;
  isRead?: Maybe<Array<GroupByField>>;
  isByAdministrator?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  attachments?: Maybe<DocumentGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  repliedOn?: Maybe<InboxMessageGroupByQuery>;
  replies?: Maybe<InboxMessageGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type FacilityGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  structure?: Maybe<Array<GroupByField>>;
  amountCommitted?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  comments?: Maybe<Array<GroupByField>>;
  totalAmountDeployed?: Maybe<Array<GroupByField>>;
  nextAvailableDraw?: Maybe<Array<GroupByField>>;
  closingFee?: Maybe<Array<GroupByField>>;
  successFee?: Maybe<Array<GroupByField>>;
  minCashCovenant?: Maybe<Array<GroupByField>>;
  minCashRunawayCovenant?: Maybe<Array<GroupByField>>;
  validityGuarantee?: Maybe<Array<GroupByField>>;
  advanceRate?: Maybe<Array<GroupByField>>;
  amountDeployed?: Maybe<Array<GroupByField>>;
  fundingDate?: Maybe<Array<GroupByField>>;
  closingFeeValue?: Maybe<Array<GroupByField>>;
  closingDate?: Maybe<Array<GroupByField>>;
  finalAdvanceRate?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  draws?: Maybe<DrawGroupByQuery>;
  facilityHistory?: Maybe<FacilityHistoryGroupByQuery>;
  drawHistory?: Maybe<DrawHistoryGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type DrawGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  fundedDate?: Maybe<Array<GroupByField>>;
  maturityDate?: Maybe<Array<GroupByField>>;
  amountDeployed?: Maybe<Array<GroupByField>>;
  interestRate?: Maybe<Array<GroupByField>>;
  cashShare?: Maybe<Array<GroupByField>>;
  returnCap?: Maybe<Array<GroupByField>>;
  term?: Maybe<Array<GroupByField>>;
  iOPeriod?: Maybe<Array<GroupByField>>;
  amortizationPeriod?: Maybe<Array<GroupByField>>;
  iOPeriodAmortizationPeriod?: Maybe<Array<GroupByField>>;
  exitFee?: Maybe<Array<GroupByField>>;
  prepayment?: Maybe<Array<GroupByField>>;
  iRR?: Maybe<Array<GroupByField>>;
  mOIC?: Maybe<Array<GroupByField>>;
  comments?: Maybe<Array<GroupByField>>;
  prepaymentMonths?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  facility?: Maybe<FacilityGroupByQuery>;
  drawHistory?: Maybe<DrawHistoryGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type DrawHistoryGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  draw?: Maybe<DrawGroupByQuery>;
  facility?: Maybe<FacilityGroupByQuery>;
  drawHistoryValue?: Maybe<DrawHistoryValueGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type DrawHistoryValueGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  date?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  drawHistory?: Maybe<DrawHistoryGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type FacilityHistoryGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  facility?: Maybe<FacilityGroupByQuery>;
  facilityHistoryValue?: Maybe<FacilityHistoryValueGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type FacilityHistoryValueGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  date?: Maybe<Array<GroupByField>>;
  advanceRate?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  facilityHistory?: Maybe<FacilityHistoryGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type IntegrationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  xero?: Maybe<XeroIntegrationGroupByQuery>;
  quickBooks?: Maybe<QuickBooksIntegrationGroupByQuery>;
  stripe?: Maybe<StripeIntegrationGroupByQuery>;
  plaid?: Maybe<PlaidIntegrationGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type XeroIntegrationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  accessToken?: Maybe<Array<GroupByField>>;
  refreshToken?: Maybe<Array<GroupByField>>;
  expiresAt?: Maybe<Array<GroupByField>>;
  tenantId?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  integrations?: Maybe<IntegrationGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type QuickBooksIntegrationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  accessToken?: Maybe<Array<GroupByField>>;
  refreshToken?: Maybe<Array<GroupByField>>;
  expiresAt?: Maybe<Array<GroupByField>>;
  realmId?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  integrations?: Maybe<IntegrationGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type StripeIntegrationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  accessToken?: Maybe<Array<GroupByField>>;
  refreshToken?: Maybe<Array<GroupByField>>;
  userId?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  integrations?: Maybe<IntegrationGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type PlaidIntegrationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  accessToken?: Maybe<Array<GroupByField>>;
  institutionMetadata?: Maybe<Array<GroupByField>>;
  institutionId?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  integrations?: Maybe<IntegrationGroupByQuery>;
  accounts?: Maybe<PlaidIntegrationAccountGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type PlaidIntegrationAccountGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  accountId?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  mask?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  plaidIntegration?: Maybe<PlaidIntegrationGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type PortfolioCompanyGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  monitoringStatus?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  dealTeam?: Maybe<UserGroupByQuery>;
  cashMonitoringRecords?: Maybe<CashMonitoringRecordGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type CashMonitoringRecordGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  date?: Maybe<Array<GroupByField>>;
  note?: Maybe<Array<GroupByField>>;
  action?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  portfolioCompany?: Maybe<PortfolioCompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type MetricValueGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  source?: Maybe<Array<GroupByField>>;
  value?: Maybe<Array<GroupByField>>;
  adjustedValue?: Maybe<Array<GroupByField>>;
  comment?: Maybe<Array<GroupByField>>;
  tier?: Maybe<Array<GroupByField>>;
  date?: Maybe<Array<GroupByField>>;
  period?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  metric?: Maybe<MetricGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type MetricGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  description?: Maybe<Array<GroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  format?: Maybe<Array<GroupByField>>;
  type?: Maybe<Array<GroupByField>>;
  saaSScoreWeight?: Maybe<Array<GroupByField>>;
  saaSScoreWeightEnterprise?: Maybe<Array<GroupByField>>;
  tierBands?: Maybe<Array<GroupByField>>;
  metadata?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  values?: Maybe<MetricValueGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type CompanyMonitoringGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  committed?: Maybe<Array<GroupByField>>;
  deployed?: Maybe<Array<GroupByField>>;
  date?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  companies?: Maybe<CompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type ProgressStepValueGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  customStepName?: Maybe<Array<GroupByField>>;
  customStepComment?: Maybe<Array<GroupByField>>;
  bigfootTeamComment?: Maybe<Array<GroupByField>>;
  applicantComment?: Maybe<Array<GroupByField>>;
  isVisible?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  step?: Maybe<ProgressStepGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type ProgressStepGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  order?: Maybe<Array<GroupByField>>;
  comment?: Maybe<Array<GroupByField>>;
  helperText?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  stage?: Maybe<ProgressStageGroupByQuery>;
  stepValue?: Maybe<ProgressStepValueGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type ProgressStageGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  steps?: Maybe<ProgressStepGroupByQuery>;
  companySteps?: Maybe<ProgressCompanyStepGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type ProgressCompanyStepGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  comment?: Maybe<Array<GroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  bigfootTeamComment?: Maybe<Array<GroupByField>>;
  applicantComment?: Maybe<Array<GroupByField>>;
  isVisible?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  stage?: Maybe<ProgressStageGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type CovenantValueGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  value?: Maybe<Array<GroupByField>>;
  isEnabled?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  covenant?: Maybe<CovenantGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type CovenantGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  covenantValue?: Maybe<CovenantValueGroupByQuery>;
  customCovenantValue?: Maybe<CustomCovenantValueGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type CustomCovenantValueGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  date?: Maybe<Array<GroupByField>>;
  actual?: Maybe<Array<GroupByField>>;
  difference?: Maybe<Array<GroupByField>>;
  inCompliance?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  covenant?: Maybe<CovenantGroupByQuery>;
  company?: Maybe<CompanyGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type InvitationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  meta?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  invitedUser?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

export type GroupBySort = {
  alias: Scalars['String'];
  direction: SortOrder;
};

export type Having = {
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<IdPredicateHaving>;
  string?: Maybe<StringPredicateHaving>;
  int?: Maybe<IntPredicateHaving>;
  bigint?: Maybe<BigIntPredicateHaving>;
  float?: Maybe<FloatPredicateHaving>;
  bool?: Maybe<BoolPredicateHaving>;
  date?: Maybe<DatePredicateHaving>;
  datetime?: Maybe<DateTimePredicateHaving>;
  AND?: Maybe<Array<Having>>;
  OR?: Maybe<Array<Having>>;
};

export type IdPredicateHaving = {
  equals?: Maybe<Scalars['ID']>;
  not_equals?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  not_in?: Maybe<Array<Scalars['ID']>>;
  contains?: Maybe<Scalars['ID']>;
  not_contains?: Maybe<Scalars['ID']>;
  starts_with?: Maybe<Scalars['ID']>;
  not_starts_with?: Maybe<Scalars['ID']>;
  ends_with?: Maybe<Scalars['ID']>;
  not_ends_with?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<IdPredicateHaving>>;
  OR?: Maybe<Array<IdPredicateHaving>>;
};

export type StringPredicateHaving = {
  equals?: Maybe<Scalars['String']>;
  not_equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  not_in?: Maybe<Array<Scalars['String']>>;
  contains?: Maybe<Scalars['String']>;
  not_contains?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  not_starts_with?: Maybe<Scalars['String']>;
  ends_with?: Maybe<Scalars['String']>;
  not_ends_with?: Maybe<Scalars['String']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<StringPredicateHaving>>;
  OR?: Maybe<Array<StringPredicateHaving>>;
};

export type IntPredicateHaving = {
  equals?: Maybe<Scalars['Int']>;
  not_equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  not_in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<IntPredicateHaving>>;
  OR?: Maybe<Array<IntPredicateHaving>>;
};

export type BigIntPredicateHaving = {
  equals?: Maybe<Scalars['BigInt']>;
  not_equals?: Maybe<Scalars['BigInt']>;
  in?: Maybe<Array<Scalars['BigInt']>>;
  not_in?: Maybe<Array<Scalars['BigInt']>>;
  lt?: Maybe<Scalars['BigInt']>;
  lte?: Maybe<Scalars['BigInt']>;
  gt?: Maybe<Scalars['BigInt']>;
  gte?: Maybe<Scalars['BigInt']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<BigIntPredicateHaving>>;
  OR?: Maybe<Array<BigIntPredicateHaving>>;
};

export type FloatPredicateHaving = {
  equals?: Maybe<Scalars['Float']>;
  not_equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  not_in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<FloatPredicateHaving>>;
  OR?: Maybe<Array<FloatPredicateHaving>>;
};

export type BoolPredicateHaving = {
  equals?: Maybe<Scalars['Boolean']>;
  not_equals?: Maybe<Scalars['Boolean']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<BoolPredicateHaving>>;
  OR?: Maybe<Array<BoolPredicateHaving>>;
};

export type DatePredicateHaving = {
  equals?: Maybe<Scalars['Date']>;
  not_equals?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  not_in?: Maybe<Array<Scalars['Date']>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DatePredicateHaving>>;
  OR?: Maybe<Array<DatePredicateHaving>>;
};

export type DateTimePredicateHaving = {
  equals?: Maybe<Scalars['DateTime']>;
  not_equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  not_in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DateTimePredicateHaving>>;
  OR?: Maybe<Array<DateTimePredicateHaving>>;
};

/** FileListResponse output */
export type FileListResponse = {
  __typename?: 'FileListResponse';
  /** List items */
  items: Array<File>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum FileOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  FileIdAsc = 'fileId_ASC',
  FileIdDesc = 'fileId_DESC',
  DownloadUrlAsc = 'downloadUrl_ASC',
  DownloadUrlDesc = 'downloadUrl_DESC',
  ShareUrlAsc = 'shareUrl_ASC',
  ShareUrlDesc = 'shareUrl_DESC',
  ProviderAsc = 'provider_ASC',
  ProviderDesc = 'provider_DESC',
  PublicAsc = 'public_ASC',
  PublicDesc = 'public_DESC',
  UploadedAsc = 'uploaded_ASC',
  UploadedDesc = 'uploaded_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  UploadUrlAsc = 'uploadUrl_ASC',
  UploadUrlDesc = 'uploadUrl_DESC',
  FieldsAsc = 'fields_ASC',
  FieldsDesc = 'fields_DESC',
  MetaAsc = 'meta_ASC',
  MetaDesc = 'meta_DESC',
  ModsAsc = 'mods_ASC',
  ModsDesc = 'mods_DESC',
}

export type FileGroupBy = {
  query: FileGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** RoleListResponse output */
export type RoleListResponse = {
  __typename?: 'RoleListResponse';
  /** List items */
  items: Array<Role>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Role = {
  __typename?: 'Role';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  users?: Maybe<UserListResponse>;
  apiTokens?: Maybe<ApiTokenListResponse>;
  authenticationProfiles?: Maybe<AuthenticationProfileListResponse>;
  teamMembers?: Maybe<TeamMemberListResponse>;
  permissions?: Maybe<PermissionListResponse>;
  _description?: Maybe<Scalars['String']>;
  membersCount: Scalars['Int'];
};

export type RoleUsersArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
};

export type RoleApiTokensArgs = {
  filter?: Maybe<ApiTokenFilter>;
  orderBy?: Maybe<Array<Maybe<ApiTokenOrderBy>>>;
  sort?: Maybe<Array<ApiTokenSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ApiTokenGroupBy>;
};

export type RoleAuthenticationProfilesArgs = {
  filter?: Maybe<AuthenticationProfileFilter>;
  orderBy?: Maybe<Array<Maybe<AuthenticationProfileOrderBy>>>;
  sort?: Maybe<Array<AuthenticationProfileSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AuthenticationProfileGroupBy>;
};

export type RoleTeamMembersArgs = {
  filter?: Maybe<TeamMemberFilter>;
  orderBy?: Maybe<Array<Maybe<TeamMemberOrderBy>>>;
  sort?: Maybe<Array<TeamMemberSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<TeamMemberGroupBy>;
};

export type RolePermissionsArgs = {
  filter?: Maybe<PermissionInputFilter>;
};

/** ApiTokenListResponse output */
export type ApiTokenListResponse = {
  __typename?: 'ApiTokenListResponse';
  /** List items */
  items: Array<ApiToken>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum ApiTokenOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TokenAsc = 'token_ASC',
  TokenDesc = 'token_DESC',
}

export type ApiTokenSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type ApiTokenGroupBy = {
  query: ApiTokenGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** AuthenticationProfileListResponse output */
export type AuthenticationProfileListResponse = {
  __typename?: 'AuthenticationProfileListResponse';
  /** List items */
  items: Array<AuthenticationProfile>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type AuthenticationProfile = {
  __typename?: 'AuthenticationProfile';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<RoleListResponse>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  attributes?: Maybe<AuthenticationProfileAttributes>;
  _description?: Maybe<Scalars['String']>;
};

export type AuthenticationProfileRolesArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
};

/** No longer supported. Use `sort` instead. */
export enum RoleOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SystemTypeAsc = 'systemType_ASC',
  SystemTypeDesc = 'systemType_DESC',
}

export type RoleSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type RoleGroupBy = {
  query: RoleGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** Authentication Profile Attributes */
export type AuthenticationProfileAttributes = CognitoAuthProfileAttributes;

/** Authentication Profile Attributes for Cognito */
export type CognitoAuthProfileAttributes = {
  __typename?: 'CognitoAuthProfileAttributes';
  clientAuthDomain?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum AuthenticationProfileOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SecretAsc = 'secret_ASC',
  SecretDesc = 'secret_DESC',
  ManagementDomainAsc = 'managementDomain_ASC',
  ManagementDomainDesc = 'managementDomain_DESC',
  ClientIdAsc = 'clientId_ASC',
  ClientIdDesc = 'clientId_DESC',
  DatabaseNameAsc = 'databaseName_ASC',
  DatabaseNameDesc = 'databaseName_DESC',
  DomainAsc = 'domain_ASC',
  DomainDesc = 'domain_DESC',
  SelfSignUpEnabledAsc = 'selfSignUpEnabled_ASC',
  SelfSignUpEnabledDesc = 'selfSignUpEnabled_DESC',
}

export type AuthenticationProfileSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  secret?: Maybe<SortOrder>;
  managementDomain?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  databaseName?: Maybe<SortOrder>;
  domain?: Maybe<SortOrder>;
  selfSignUpEnabled?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type AuthenticationProfileGroupBy = {
  query: AuthenticationProfileGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** TeamMemberListResponse output */
export type TeamMemberListResponse = {
  __typename?: 'TeamMemberListResponse';
  /** List items */
  items: Array<TeamMember>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  user?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<File>;
  roles?: Maybe<RoleListResponse>;
  receivedTeamInvitations?: Maybe<TeamInvitationListResponse>;
  sentTeamInvitations?: Maybe<TeamInvitationListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type TeamMemberRolesArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
};

export type TeamMemberReceivedTeamInvitationsArgs = {
  filter?: Maybe<TeamInvitationFilter>;
  orderBy?: Maybe<Array<Maybe<TeamInvitationOrderBy>>>;
  sort?: Maybe<Array<TeamInvitationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<TeamInvitationGroupBy>;
};

export type TeamMemberSentTeamInvitationsArgs = {
  filter?: Maybe<TeamInvitationFilter>;
  orderBy?: Maybe<Array<Maybe<TeamInvitationOrderBy>>>;
  sort?: Maybe<Array<TeamInvitationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<TeamInvitationGroupBy>;
};

/** TeamInvitationListResponse output */
export type TeamInvitationListResponse = {
  __typename?: 'TeamInvitationListResponse';
  /** List items */
  items: Array<TeamInvitation>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  uuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  invitee?: Maybe<TeamMember>;
  invitedBy?: Maybe<TeamMember>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum TeamInvitationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UuidAsc = 'uuid_ASC',
  UuidDesc = 'uuid_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  ResentOnAsc = 'resentOn_ASC',
  ResentOnDesc = 'resentOn_DESC',
  AcceptedAsc = 'accepted_ASC',
  AcceptedDesc = 'accepted_DESC',
  AcceptedOnAsc = 'acceptedOn_ASC',
  AcceptedOnDesc = 'acceptedOn_DESC',
}

export type TeamInvitationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  uuid?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  resentOn?: Maybe<SortOrder>;
  accepted?: Maybe<SortOrder>;
  acceptedOn?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  invitee?: Maybe<TeamMemberSort>;
  invitedBy?: Maybe<TeamMemberSort>;
};

export type TeamMemberSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  isOwner?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  user?: Maybe<UserSort>;
  avatar?: Maybe<FileSort>;
};

export type TeamInvitationGroupBy = {
  query: TeamInvitationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum TeamMemberOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IsOwnerAsc = 'isOwner_ASC',
  IsOwnerDesc = 'isOwner_DESC',
}

export type TeamMemberGroupBy = {
  query: TeamMemberGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** PermissionListResponse output */
export type PermissionListResponse = {
  __typename?: 'PermissionListResponse';
  /** List items */
  items: Array<Permission>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Permission = {
  __typename?: 'Permission';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  appId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['JSON']>;
  role?: Maybe<Role>;
  _description?: Maybe<Scalars['String']>;
};

/** Permission Filter */
export type PermissionInputFilter = {
  resourceType?: Maybe<PermissionResourceTypeEnum>;
  action?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
};

export enum PermissionResourceTypeEnum {
  Data = 'data',
  Custom = 'custom',
}

/** EnvironmentVariableListResponse output */
export type EnvironmentVariableListResponse = {
  __typename?: 'EnvironmentVariableListResponse';
  /** List items */
  items: Array<EnvironmentVariable>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type EnvironmentVariableFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  value?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<EnvironmentVariableFilter>>;
  OR?: Maybe<Array<EnvironmentVariableFilter>>;
};

/** No longer supported. Use `sort` instead. */
export enum EnvironmentVariableOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type EnvironmentVariableSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type EnvironmentVariableGroupBy = {
  query: EnvironmentVariableGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type EnvironmentVariableGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  value?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

/** CiCdMigrationListResponse output */
export type CiCdMigrationListResponse = {
  __typename?: 'CiCdMigrationListResponse';
  /** List items */
  items: Array<CiCdMigration>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type CiCdMigration = {
  __typename?: 'CiCdMigration';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type CiCdMigrationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<CiCdMigrationFilter>>;
  OR?: Maybe<Array<CiCdMigrationFilter>>;
};

/** No longer supported. Use `sort` instead. */
export enum CiCdMigrationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
}

export type CiCdMigrationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type CiCdMigrationGroupBy = {
  query: CiCdMigrationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type CiCdMigrationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

/** LoanApplicationListResponse output */
export type LoanApplicationListResponse = {
  __typename?: 'LoanApplicationListResponse';
  /** List items */
  items: Array<LoanApplication>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type LoanApplication = {
  __typename?: 'LoanApplication';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  company?: Maybe<Company>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<DocumentListResponse>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<UserListResponse>;
  status?: Maybe<Scalars['Boolean']>;
  _description?: Maybe<Scalars['String']>;
};

export type LoanApplicationDocumentsArgs = {
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>>>;
  sort?: Maybe<Array<DocumentSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentGroupBy>;
};

export type LoanApplicationDealTeamArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
};

export type Company = {
  __typename?: 'Company';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddress>;
  logo?: Maybe<File>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<NoteListResponse>;
  loanApplication?: Maybe<LoanApplication>;
  documents?: Maybe<DocumentListResponse>;
  facilities?: Maybe<FacilityListResponse>;
  owner?: Maybe<User>;
  integration?: Maybe<Integration>;
  portfolioCompany?: Maybe<PortfolioCompany>;
  team?: Maybe<UserListResponse>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<MetricValueListResponse>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompanyMonitoringListResponse>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<ProgressStepValueListResponse>;
  inboxMessages?: Maybe<InboxMessageListResponse>;
  companyStep?: Maybe<ProgressCompanyStepListResponse>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CovenantValueListResponse>;
  customCovenant?: Maybe<CustomCovenantValueListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type CompanyNotesArgs = {
  filter?: Maybe<NoteFilter>;
  orderBy?: Maybe<Array<Maybe<NoteOrderBy>>>;
  sort?: Maybe<Array<NoteSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NoteGroupBy>;
};

export type CompanyDocumentsArgs = {
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>>>;
  sort?: Maybe<Array<DocumentSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentGroupBy>;
};

export type CompanyFacilitiesArgs = {
  filter?: Maybe<FacilityFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityOrderBy>>>;
  sort?: Maybe<Array<FacilitySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityGroupBy>;
};

export type CompanyTeamArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
};

export type CompanyMetricsArgs = {
  filter?: Maybe<MetricValueFilter>;
  orderBy?: Maybe<Array<Maybe<MetricValueOrderBy>>>;
  sort?: Maybe<Array<MetricValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MetricValueGroupBy>;
};

export type CompanyMonitoringValuesArgs = {
  filter?: Maybe<CompanyMonitoringFilter>;
  orderBy?: Maybe<Array<Maybe<CompanyMonitoringOrderBy>>>;
  sort?: Maybe<Array<CompanyMonitoringSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CompanyMonitoringGroupBy>;
};

export type CompanyCompanyArgs = {
  filter?: Maybe<ProgressStepValueFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepValueOrderBy>>>;
  sort?: Maybe<Array<ProgressStepValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepValueGroupBy>;
};

export type CompanyInboxMessagesArgs = {
  filter?: Maybe<InboxMessageFilter>;
  orderBy?: Maybe<Array<Maybe<InboxMessageOrderBy>>>;
  sort?: Maybe<Array<InboxMessageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InboxMessageGroupBy>;
};

export type CompanyCompanyStepArgs = {
  filter?: Maybe<ProgressCompanyStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressCompanyStepOrderBy>>>;
  sort?: Maybe<Array<ProgressCompanyStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressCompanyStepGroupBy>;
};

export type CompanyCovenantValueArgs = {
  filter?: Maybe<CovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CovenantValueOrderBy>>>;
  sort?: Maybe<Array<CovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CovenantValueGroupBy>;
};

export type CompanyCustomCovenantArgs = {
  filter?: Maybe<CustomCovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CustomCovenantValueOrderBy>>>;
  sort?: Maybe<Array<CustomCovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomCovenantValueGroupBy>;
};

export type SmartAddress = {
  __typename?: 'SmartAddress';
  country?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** NoteListResponse output */
export type NoteListResponse = {
  __typename?: 'NoteListResponse';
  /** List items */
  items: Array<Note>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Note = {
  __typename?: 'Note';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<FileListResponse>;
  company?: Maybe<Company>;
  _description?: Maybe<Scalars['String']>;
};

export type NoteAttachmentsArgs = {
  filter?: Maybe<FileFilter>;
  orderBy?: Maybe<Array<Maybe<FileOrderBy>>>;
  sort?: Maybe<Array<FileSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FileGroupBy>;
};

/** No longer supported. Use `sort` instead. */
export enum NoteOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
}

export type NoteSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
};

export type NoteGroupBy = {
  query: NoteGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** DocumentListResponse output */
export type DocumentListResponse = {
  __typename?: 'DocumentListResponse';
  /** List items */
  items: Array<Document>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Document = {
  __typename?: 'Document';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  file?: Maybe<File>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  loanApplication?: Maybe<LoanApplication>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<InboxMessageListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type DocumentInboxMessageArgs = {
  filter?: Maybe<InboxMessageFilter>;
  orderBy?: Maybe<Array<Maybe<InboxMessageOrderBy>>>;
  sort?: Maybe<Array<InboxMessageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InboxMessageGroupBy>;
};

/** InboxMessageListResponse output */
export type InboxMessageListResponse = {
  __typename?: 'InboxMessageListResponse';
  /** List items */
  items: Array<InboxMessage>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type InboxMessage = {
  __typename?: 'InboxMessage';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<DocumentListResponse>;
  company?: Maybe<Company>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessage>;
  replies?: Maybe<InboxMessageListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type InboxMessageAttachmentsArgs = {
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>>>;
  sort?: Maybe<Array<DocumentSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentGroupBy>;
};

export type InboxMessageRepliesArgs = {
  filter?: Maybe<InboxMessageFilter>;
  orderBy?: Maybe<Array<Maybe<InboxMessageOrderBy>>>;
  sort?: Maybe<Array<InboxMessageSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<InboxMessageGroupBy>;
};

/** No longer supported. Use `sort` instead. */
export enum DocumentOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  PrivateAsc = 'private_ASC',
  PrivateDesc = 'private_DESC',
}

export type DocumentSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  private?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  file?: Maybe<FileSort>;
  company?: Maybe<CompanySort>;
  loanApplication?: Maybe<LoanApplicationSort>;
};

export type DocumentGroupBy = {
  query: DocumentGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum InboxMessageOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  SubjectAsc = 'subject_ASC',
  SubjectDesc = 'subject_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  IsReadAsc = 'isRead_ASC',
  IsReadDesc = 'isRead_DESC',
  IsByAdministratorAsc = 'isByAdministrator_ASC',
  IsByAdministratorDesc = 'isByAdministrator_DESC',
}

export type InboxMessageSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  isByAdministrator?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
  repliedOn?: Maybe<InboxMessageSort>;
};

export type InboxMessageGroupBy = {
  query: InboxMessageGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** FacilityListResponse output */
export type FacilityListResponse = {
  __typename?: 'FacilityListResponse';
  /** List items */
  items: Array<Facility>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** Table of individual loans for portfolio companies. Each portfolio company can have multiple loans. */
export type Facility = {
  __typename?: 'Facility';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  structure?: Maybe<Scalars['String']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<DrawListResponse>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilityHistoryListResponse>;
  drawHistory?: Maybe<DrawHistoryListResponse>;
  _description?: Maybe<Scalars['String']>;
};

/** Table of individual loans for portfolio companies. Each portfolio company can have multiple loans. */
export type FacilityDrawsArgs = {
  filter?: Maybe<DrawFilter>;
  orderBy?: Maybe<Array<Maybe<DrawOrderBy>>>;
  sort?: Maybe<Array<DrawSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawGroupBy>;
};

/** Table of individual loans for portfolio companies. Each portfolio company can have multiple loans. */
export type FacilityFacilityHistoryArgs = {
  filter?: Maybe<FacilityHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityHistoryOrderBy>>>;
  sort?: Maybe<Array<FacilityHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityHistoryGroupBy>;
};

/** Table of individual loans for portfolio companies. Each portfolio company can have multiple loans. */
export type FacilityDrawHistoryArgs = {
  filter?: Maybe<DrawHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryOrderBy>>>;
  sort?: Maybe<Array<DrawHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryGroupBy>;
};

/** DrawListResponse output */
export type DrawListResponse = {
  __typename?: 'DrawListResponse';
  /** List items */
  items: Array<Draw>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Draw = {
  __typename?: 'Draw';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  facility?: Maybe<Facility>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawHistoryListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type DrawDrawHistoryArgs = {
  filter?: Maybe<DrawHistoryFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryOrderBy>>>;
  sort?: Maybe<Array<DrawHistorySort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryGroupBy>;
};

/** DrawHistoryListResponse output */
export type DrawHistoryListResponse = {
  __typename?: 'DrawHistoryListResponse';
  /** List items */
  items: Array<DrawHistory>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type DrawHistory = {
  __typename?: 'DrawHistory';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  draw?: Maybe<Draw>;
  facility?: Maybe<Facility>;
  drawHistoryValue?: Maybe<DrawHistoryValueListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type DrawHistoryDrawHistoryValueArgs = {
  filter?: Maybe<DrawHistoryValueFilter>;
  orderBy?: Maybe<Array<Maybe<DrawHistoryValueOrderBy>>>;
  sort?: Maybe<Array<DrawHistoryValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DrawHistoryValueGroupBy>;
};

/** DrawHistoryValueListResponse output */
export type DrawHistoryValueListResponse = {
  __typename?: 'DrawHistoryValueListResponse';
  /** List items */
  items: Array<DrawHistoryValue>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type DrawHistoryValue = {
  __typename?: 'DrawHistoryValue';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  drawHistory?: Maybe<DrawHistory>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum DrawHistoryValueOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
}

export type DrawHistoryValueSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  drawHistory?: Maybe<DrawHistorySort>;
};

export type DrawHistorySort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  draw?: Maybe<DrawSort>;
  facility?: Maybe<FacilitySort>;
};

export type DrawSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  fundedDate?: Maybe<SortOrder>;
  maturityDate?: Maybe<SortOrder>;
  amountDeployed?: Maybe<SortOrder>;
  interestRate?: Maybe<SortOrder>;
  cashShare?: Maybe<SortOrder>;
  returnCap?: Maybe<SortOrder>;
  term?: Maybe<SortOrder>;
  iOPeriod?: Maybe<SortOrder>;
  amortizationPeriod?: Maybe<SortOrder>;
  iOPeriodAmortizationPeriod?: Maybe<SortOrder>;
  exitFee?: Maybe<SortOrder>;
  prepayment?: Maybe<SortOrder>;
  iRR?: Maybe<SortOrder>;
  mOIC?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  prepaymentMonths?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  facility?: Maybe<FacilitySort>;
};

export type FacilitySort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  amountCommitted?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  totalAmountDeployed?: Maybe<SortOrder>;
  nextAvailableDraw?: Maybe<SortOrder>;
  closingFee?: Maybe<SortOrder>;
  successFee?: Maybe<SortOrder>;
  minCashCovenant?: Maybe<SortOrder>;
  minCashRunawayCovenant?: Maybe<SortOrder>;
  validityGuarantee?: Maybe<SortOrder>;
  advanceRate?: Maybe<SortOrder>;
  amountDeployed?: Maybe<SortOrder>;
  fundingDate?: Maybe<SortOrder>;
  closingFeeValue?: Maybe<SortOrder>;
  closingDate?: Maybe<SortOrder>;
  finalAdvanceRate?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
};

export type DrawHistoryValueGroupBy = {
  query: DrawHistoryValueGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum DrawHistoryOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
}

export type DrawHistoryGroupBy = {
  query: DrawHistoryGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum DrawOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  FundedDateAsc = 'fundedDate_ASC',
  FundedDateDesc = 'fundedDate_DESC',
  MaturityDateAsc = 'maturityDate_ASC',
  MaturityDateDesc = 'maturityDate_DESC',
  AmountDeployedAsc = 'amountDeployed_ASC',
  AmountDeployedDesc = 'amountDeployed_DESC',
  InterestRateAsc = 'interestRate_ASC',
  InterestRateDesc = 'interestRate_DESC',
  CashShareAsc = 'cashShare_ASC',
  CashShareDesc = 'cashShare_DESC',
  ReturnCapAsc = 'returnCap_ASC',
  ReturnCapDesc = 'returnCap_DESC',
  TermAsc = 'term_ASC',
  TermDesc = 'term_DESC',
  IOPeriodAsc = 'iOPeriod_ASC',
  IOPeriodDesc = 'iOPeriod_DESC',
  AmortizationPeriodAsc = 'amortizationPeriod_ASC',
  AmortizationPeriodDesc = 'amortizationPeriod_DESC',
  IOPeriodAmortizationPeriodAsc = 'iOPeriodAmortizationPeriod_ASC',
  IOPeriodAmortizationPeriodDesc = 'iOPeriodAmortizationPeriod_DESC',
  ExitFeeAsc = 'exitFee_ASC',
  ExitFeeDesc = 'exitFee_DESC',
  PrepaymentAsc = 'prepayment_ASC',
  PrepaymentDesc = 'prepayment_DESC',
  IRrAsc = 'iRR_ASC',
  IRrDesc = 'iRR_DESC',
  MOicAsc = 'mOIC_ASC',
  MOicDesc = 'mOIC_DESC',
  CommentsAsc = 'comments_ASC',
  CommentsDesc = 'comments_DESC',
  PrepaymentMonthsAsc = 'prepaymentMonths_ASC',
  PrepaymentMonthsDesc = 'prepaymentMonths_DESC',
}

export type DrawGroupBy = {
  query: DrawGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** FacilityHistoryListResponse output */
export type FacilityHistoryListResponse = {
  __typename?: 'FacilityHistoryListResponse';
  /** List items */
  items: Array<FacilityHistory>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type FacilityHistory = {
  __typename?: 'FacilityHistory';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  facility?: Maybe<Facility>;
  facilityHistoryValue?: Maybe<FacilityHistoryValueListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type FacilityHistoryFacilityHistoryValueArgs = {
  filter?: Maybe<FacilityHistoryValueFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityHistoryValueOrderBy>>>;
  sort?: Maybe<Array<FacilityHistoryValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FacilityHistoryValueGroupBy>;
};

/** FacilityHistoryValueListResponse output */
export type FacilityHistoryValueListResponse = {
  __typename?: 'FacilityHistoryValueListResponse';
  /** List items */
  items: Array<FacilityHistoryValue>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type FacilityHistoryValue = {
  __typename?: 'FacilityHistoryValue';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  facilityHistory?: Maybe<FacilityHistory>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  advanceRate?: Maybe<Scalars['Float']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum FacilityHistoryValueOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  AdvanceRateAsc = 'advanceRate_ASC',
  AdvanceRateDesc = 'advanceRate_DESC',
}

export type FacilityHistoryValueSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  advanceRate?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  facilityHistory?: Maybe<FacilityHistorySort>;
};

export type FacilityHistorySort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  facility?: Maybe<FacilitySort>;
};

export type FacilityHistoryValueGroupBy = {
  query: FacilityHistoryValueGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum FacilityHistoryOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
}

export type FacilityHistoryGroupBy = {
  query: FacilityHistoryGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum FacilityOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StructureAsc = 'structure_ASC',
  StructureDesc = 'structure_DESC',
  AmountCommittedAsc = 'amountCommitted_ASC',
  AmountCommittedDesc = 'amountCommitted_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CommentsAsc = 'comments_ASC',
  CommentsDesc = 'comments_DESC',
  TotalAmountDeployedAsc = 'totalAmountDeployed_ASC',
  TotalAmountDeployedDesc = 'totalAmountDeployed_DESC',
  NextAvailableDrawAsc = 'nextAvailableDraw_ASC',
  NextAvailableDrawDesc = 'nextAvailableDraw_DESC',
  ClosingFeeAsc = 'closingFee_ASC',
  ClosingFeeDesc = 'closingFee_DESC',
  SuccessFeeAsc = 'successFee_ASC',
  SuccessFeeDesc = 'successFee_DESC',
  MinCashCovenantAsc = 'minCashCovenant_ASC',
  MinCashCovenantDesc = 'minCashCovenant_DESC',
  MinCashRunawayCovenantAsc = 'minCashRunawayCovenant_ASC',
  MinCashRunawayCovenantDesc = 'minCashRunawayCovenant_DESC',
  ValidityGuaranteeAsc = 'validityGuarantee_ASC',
  ValidityGuaranteeDesc = 'validityGuarantee_DESC',
  AdvanceRateAsc = 'advanceRate_ASC',
  AdvanceRateDesc = 'advanceRate_DESC',
  AmountDeployedAsc = 'amountDeployed_ASC',
  AmountDeployedDesc = 'amountDeployed_DESC',
  FundingDateAsc = 'fundingDate_ASC',
  FundingDateDesc = 'fundingDate_DESC',
  ClosingFeeValueAsc = 'closingFeeValue_ASC',
  ClosingFeeValueDesc = 'closingFeeValue_DESC',
  ClosingDateAsc = 'closingDate_ASC',
  ClosingDateDesc = 'closingDate_DESC',
  FinalAdvanceRateAsc = 'finalAdvanceRate_ASC',
  FinalAdvanceRateDesc = 'finalAdvanceRate_DESC',
}

export type FacilityGroupBy = {
  query: FacilityGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type Integration = {
  __typename?: 'Integration';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  company?: Maybe<Company>;
  xero?: Maybe<XeroIntegration>;
  quickBooks?: Maybe<QuickBooksIntegration>;
  stripe?: Maybe<StripeIntegration>;
  plaid?: Maybe<PlaidIntegrationListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type IntegrationPlaidArgs = {
  filter?: Maybe<PlaidIntegrationFilter>;
  orderBy?: Maybe<Array<Maybe<PlaidIntegrationOrderBy>>>;
  sort?: Maybe<Array<PlaidIntegrationSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PlaidIntegrationGroupBy>;
};

export type XeroIntegration = {
  __typename?: 'XeroIntegration';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<Integration>;
  tenantId?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type QuickBooksIntegration = {
  __typename?: 'QuickBooksIntegration';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<Integration>;
  realmId?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type StripeIntegration = {
  __typename?: 'StripeIntegration';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<Integration>;
  userId?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

/** PlaidIntegrationListResponse output */
export type PlaidIntegrationListResponse = {
  __typename?: 'PlaidIntegrationListResponse';
  /** List items */
  items: Array<PlaidIntegration>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type PlaidIntegration = {
  __typename?: 'PlaidIntegration';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<Integration>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountListResponse>;
  institutionId?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type PlaidIntegrationAccountsArgs = {
  filter?: Maybe<PlaidIntegrationAccountFilter>;
  orderBy?: Maybe<Array<Maybe<PlaidIntegrationAccountOrderBy>>>;
  sort?: Maybe<Array<PlaidIntegrationAccountSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<PlaidIntegrationAccountGroupBy>;
};

/** PlaidIntegrationAccountListResponse output */
export type PlaidIntegrationAccountListResponse = {
  __typename?: 'PlaidIntegrationAccountListResponse';
  /** List items */
  items: Array<PlaidIntegrationAccount>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type PlaidIntegrationAccount = {
  __typename?: 'PlaidIntegrationAccount';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  plaidIntegration?: Maybe<PlaidIntegration>;
  accountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum PlaidIntegrationAccountOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  AccountIdAsc = 'accountId_ASC',
  AccountIdDesc = 'accountId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  MaskAsc = 'mask_ASC',
  MaskDesc = 'mask_DESC',
}

export type PlaidIntegrationAccountSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  accountId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  mask?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  plaidIntegration?: Maybe<PlaidIntegrationSort>;
};

export type PlaidIntegrationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  accessToken?: Maybe<SortOrder>;
  institutionId?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  integrations?: Maybe<IntegrationSort>;
};

export type PlaidIntegrationAccountGroupBy = {
  query: PlaidIntegrationAccountGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum PlaidIntegrationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  AccessTokenAsc = 'accessToken_ASC',
  AccessTokenDesc = 'accessToken_DESC',
  InstitutionMetadataAsc = 'institutionMetadata_ASC',
  InstitutionMetadataDesc = 'institutionMetadata_DESC',
  InstitutionIdAsc = 'institutionId_ASC',
  InstitutionIdDesc = 'institutionId_DESC',
}

export type PlaidIntegrationGroupBy = {
  query: PlaidIntegrationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PortfolioCompany = {
  __typename?: 'PortfolioCompany';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<UserListResponse>;
  cashMonitoringRecords?: Maybe<CashMonitoringRecordListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type PortfolioCompanyDealTeamArgs = {
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  sort?: Maybe<Array<UserSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
};

export type PortfolioCompanyCashMonitoringRecordsArgs = {
  filter?: Maybe<CashMonitoringRecordFilter>;
  orderBy?: Maybe<Array<Maybe<CashMonitoringRecordOrderBy>>>;
  sort?: Maybe<Array<CashMonitoringRecordSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CashMonitoringRecordGroupBy>;
};

/** CashMonitoringRecordListResponse output */
export type CashMonitoringRecordListResponse = {
  __typename?: 'CashMonitoringRecordListResponse';
  /** List items */
  items: Array<CashMonitoringRecord>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type CashMonitoringRecord = {
  __typename?: 'CashMonitoringRecord';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  portfolioCompany?: Maybe<PortfolioCompany>;
  date?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum CashMonitoringRecordOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  NoteAsc = 'note_ASC',
  NoteDesc = 'note_DESC',
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
}

export type CashMonitoringRecordSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  action?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  portfolioCompany?: Maybe<PortfolioCompanySort>;
};

export type CashMonitoringRecordGroupBy = {
  query: CashMonitoringRecordGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** MetricValueListResponse output */
export type MetricValueListResponse = {
  __typename?: 'MetricValueListResponse';
  /** List items */
  items: Array<MetricValue>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type MetricValue = {
  __typename?: 'MetricValue';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  metric?: Maybe<Metric>;
  company?: Maybe<Company>;
  source?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  period?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  _description?: Maybe<Scalars['String']>;
};

export type Metric = {
  __typename?: 'Metric';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  values?: Maybe<MetricValueListResponse>;
  format?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  _description?: Maybe<Scalars['String']>;
};

export type MetricValuesArgs = {
  filter?: Maybe<MetricValueFilter>;
  orderBy?: Maybe<Array<Maybe<MetricValueOrderBy>>>;
  sort?: Maybe<Array<MetricValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MetricValueGroupBy>;
};

/** No longer supported. Use `sort` instead. */
export enum MetricValueOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  AdjustedValueAsc = 'adjustedValue_ASC',
  AdjustedValueDesc = 'adjustedValue_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
}

export type MetricValueSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  adjustedValue?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  tier?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  period?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  metric?: Maybe<MetricSort>;
  company?: Maybe<CompanySort>;
};

export type MetricSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  saaSScoreWeight?: Maybe<SortOrder>;
  saaSScoreWeightEnterprise?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type MetricValueGroupBy = {
  query: MetricValueGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** CompanyMonitoringListResponse output */
export type CompanyMonitoringListResponse = {
  __typename?: 'CompanyMonitoringListResponse';
  /** List items */
  items: Array<CompanyMonitoring>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type CompanyMonitoring = {
  __typename?: 'CompanyMonitoring';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  committed?: Maybe<Scalars['Int']>;
  deployed?: Maybe<Scalars['Int']>;
  companies?: Maybe<Company>;
  date?: Maybe<Scalars['Date']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum CompanyMonitoringOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  CommittedAsc = 'committed_ASC',
  CommittedDesc = 'committed_DESC',
  DeployedAsc = 'deployed_ASC',
  DeployedDesc = 'deployed_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
}

export type CompanyMonitoringSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  committed?: Maybe<SortOrder>;
  deployed?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  companies?: Maybe<CompanySort>;
};

export type CompanyMonitoringGroupBy = {
  query: CompanyMonitoringGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** ProgressStepValueListResponse output */
export type ProgressStepValueListResponse = {
  __typename?: 'ProgressStepValueListResponse';
  /** List items */
  items: Array<ProgressStepValue>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type ProgressStepValue = {
  __typename?: 'ProgressStepValue';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  company?: Maybe<Company>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<ProgressStep>;
  customStepName?: Maybe<Scalars['String']>;
  customStepComment?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  _description?: Maybe<Scalars['String']>;
};

export type ProgressStep = {
  __typename?: 'ProgressStep';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  stage?: Maybe<ProgressStage>;
  stepValue?: Maybe<ProgressStepValueListResponse>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type ProgressStepStepValueArgs = {
  filter?: Maybe<ProgressStepValueFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepValueOrderBy>>>;
  sort?: Maybe<Array<ProgressStepValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepValueGroupBy>;
};

export type ProgressStage = {
  __typename?: 'ProgressStage';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  steps?: Maybe<ProgressStepListResponse>;
  companySteps?: Maybe<ProgressCompanyStepListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type ProgressStageStepsArgs = {
  filter?: Maybe<ProgressStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressStepOrderBy>>>;
  sort?: Maybe<Array<ProgressStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressStepGroupBy>;
};

export type ProgressStageCompanyStepsArgs = {
  filter?: Maybe<ProgressCompanyStepFilter>;
  orderBy?: Maybe<Array<Maybe<ProgressCompanyStepOrderBy>>>;
  sort?: Maybe<Array<ProgressCompanyStepSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ProgressCompanyStepGroupBy>;
};

/** ProgressStepListResponse output */
export type ProgressStepListResponse = {
  __typename?: 'ProgressStepListResponse';
  /** List items */
  items: Array<ProgressStep>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum ProgressStepOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  HelperTextAsc = 'helperText_ASC',
  HelperTextDesc = 'helperText_DESC',
}

export type ProgressStepSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  helperText?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  stage?: Maybe<ProgressStageSort>;
};

export type ProgressStageSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type ProgressStepGroupBy = {
  query: ProgressStepGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** ProgressCompanyStepListResponse output */
export type ProgressCompanyStepListResponse = {
  __typename?: 'ProgressCompanyStepListResponse';
  /** List items */
  items: Array<ProgressCompanyStep>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type ProgressCompanyStep = {
  __typename?: 'ProgressCompanyStep';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  stage?: Maybe<ProgressStage>;
  company?: Maybe<Company>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum ProgressCompanyStepOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  BigfootTeamCommentAsc = 'bigfootTeamComment_ASC',
  BigfootTeamCommentDesc = 'bigfootTeamComment_DESC',
  ApplicantCommentAsc = 'applicantComment_ASC',
  ApplicantCommentDesc = 'applicantComment_DESC',
  IsVisibleAsc = 'isVisible_ASC',
  IsVisibleDesc = 'isVisible_DESC',
}

export type ProgressCompanyStepSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  bigfootTeamComment?: Maybe<SortOrder>;
  applicantComment?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  stage?: Maybe<ProgressStageSort>;
  company?: Maybe<CompanySort>;
};

export type ProgressCompanyStepGroupBy = {
  query: ProgressCompanyStepGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum ProgressStepValueOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  CustomStepNameAsc = 'customStepName_ASC',
  CustomStepNameDesc = 'customStepName_DESC',
  CustomStepCommentAsc = 'customStepComment_ASC',
  CustomStepCommentDesc = 'customStepComment_DESC',
  BigfootTeamCommentAsc = 'bigfootTeamComment_ASC',
  BigfootTeamCommentDesc = 'bigfootTeamComment_DESC',
  ApplicantCommentAsc = 'applicantComment_ASC',
  ApplicantCommentDesc = 'applicantComment_DESC',
  IsVisibleAsc = 'isVisible_ASC',
  IsVisibleDesc = 'isVisible_DESC',
}

export type ProgressStepValueSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  customStepName?: Maybe<SortOrder>;
  customStepComment?: Maybe<SortOrder>;
  bigfootTeamComment?: Maybe<SortOrder>;
  applicantComment?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  company?: Maybe<CompanySort>;
  step?: Maybe<ProgressStepSort>;
};

export type ProgressStepValueGroupBy = {
  query: ProgressStepValueGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** CovenantValueListResponse output */
export type CovenantValueListResponse = {
  __typename?: 'CovenantValueListResponse';
  /** List items */
  items: Array<CovenantValue>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type CovenantValue = {
  __typename?: 'CovenantValue';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  covenant?: Maybe<Covenant>;
  company?: Maybe<Company>;
  value?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  _description?: Maybe<Scalars['String']>;
};

export type Covenant = {
  __typename?: 'Covenant';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  covenantValue?: Maybe<CovenantValueListResponse>;
  name?: Maybe<Scalars['String']>;
  customCovenantValue?: Maybe<CustomCovenantValueListResponse>;
  _description?: Maybe<Scalars['String']>;
};

export type CovenantCovenantValueArgs = {
  filter?: Maybe<CovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CovenantValueOrderBy>>>;
  sort?: Maybe<Array<CovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CovenantValueGroupBy>;
};

export type CovenantCustomCovenantValueArgs = {
  filter?: Maybe<CustomCovenantValueFilter>;
  orderBy?: Maybe<Array<Maybe<CustomCovenantValueOrderBy>>>;
  sort?: Maybe<Array<CustomCovenantValueSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomCovenantValueGroupBy>;
};

/** No longer supported. Use `sort` instead. */
export enum CovenantValueOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
}

export type CovenantValueSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  covenant?: Maybe<CovenantSort>;
  company?: Maybe<CompanySort>;
};

export type CovenantSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type CovenantValueGroupBy = {
  query: CovenantValueGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** CustomCovenantValueListResponse output */
export type CustomCovenantValueListResponse = {
  __typename?: 'CustomCovenantValueListResponse';
  /** List items */
  items: Array<CustomCovenantValue>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type CustomCovenantValue = {
  __typename?: 'CustomCovenantValue';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  covenant?: Maybe<Covenant>;
  company?: Maybe<Company>;
  date?: Maybe<Scalars['Date']>;
  actual?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
  inCompliance?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum CustomCovenantValueOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  ActualAsc = 'actual_ASC',
  ActualDesc = 'actual_DESC',
  DifferenceAsc = 'difference_ASC',
  DifferenceDesc = 'difference_DESC',
  InComplianceAsc = 'inCompliance_ASC',
  InComplianceDesc = 'inCompliance_DESC',
}

export type CustomCovenantValueSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  actual?: Maybe<SortOrder>;
  difference?: Maybe<SortOrder>;
  inCompliance?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  covenant?: Maybe<CovenantSort>;
  company?: Maybe<CompanySort>;
};

export type CustomCovenantValueGroupBy = {
  query: CustomCovenantValueGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum LoanApplicationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StageAsc = 'stage_ASC',
  StageDesc = 'stage_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ApplicantTitleAsc = 'applicantTitle_ASC',
  ApplicantTitleDesc = 'applicantTitle_DESC',
  AmountRequestedAsc = 'amountRequested_ASC',
  AmountRequestedDesc = 'amountRequested_DESC',
  AmountCommittedAsc = 'amountCommitted_ASC',
  AmountCommittedDesc = 'amountCommitted_DESC',
  FirstDrawProposedAsc = 'firstDrawProposed_ASC',
  FirstDrawProposedDesc = 'firstDrawProposed_DESC',
  TargetCloseDateAsc = 'targetCloseDate_ASC',
  TargetCloseDateDesc = 'targetCloseDate_DESC',
  StructureAsc = 'structure_ASC',
  StructureDesc = 'structure_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
}

export type LoanApplicationGroupBy = {
  query: LoanApplicationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** InvitationListResponse output */
export type InvitationListResponse = {
  __typename?: 'InvitationListResponse';
  /** List items */
  items: Array<Invitation>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type Invitation = {
  __typename?: 'Invitation';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  invitedUser?: Maybe<User>;
  meta?: Maybe<Scalars['JSON']>;
  _description?: Maybe<Scalars['String']>;
};

/** No longer supported. Use `sort` instead. */
export enum InvitationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MetaAsc = 'meta_ASC',
  MetaDesc = 'meta_DESC',
}

export type InvitationGroupBy = {
  query: InvitationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** LoanMonitoringListResponse output */
export type LoanMonitoringListResponse = {
  __typename?: 'LoanMonitoringListResponse';
  /** List items */
  items: Array<LoanMonitoring>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type LoanMonitoring = {
  __typename?: 'LoanMonitoring';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  merchantDate?: Maybe<Scalars['Date']>;
  companyName?: Maybe<Scalars['String']>;
  loanDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  monitoringStatus?: Maybe<Scalars['String']>;
  bankBalance?: Maybe<Scalars['Int']>;
  bankAccountDate?: Maybe<Scalars['Date']>;
  _description?: Maybe<Scalars['String']>;
};

export type LoanMonitoringFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  merchantDate?: Maybe<DatePredicate>;
  companyName?: Maybe<StringPredicate>;
  loanDate?: Maybe<DatePredicate>;
  accountingDate?: Maybe<DatePredicate>;
  monitoringStatus?: Maybe<StringPredicate>;
  bankBalance?: Maybe<IntPredicate>;
  bankAccountDate?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<LoanMonitoringFilter>>;
  OR?: Maybe<Array<LoanMonitoringFilter>>;
};

/** No longer supported. Use `sort` instead. */
export enum LoanMonitoringOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MerchantDateAsc = 'merchantDate_ASC',
  MerchantDateDesc = 'merchantDate_DESC',
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  LoanDateAsc = 'loanDate_ASC',
  LoanDateDesc = 'loanDate_DESC',
  AccountingDateAsc = 'accountingDate_ASC',
  AccountingDateDesc = 'accountingDate_DESC',
  MonitoringStatusAsc = 'monitoringStatus_ASC',
  MonitoringStatusDesc = 'monitoringStatus_DESC',
  BankBalanceAsc = 'bankBalance_ASC',
  BankBalanceDesc = 'bankBalance_DESC',
  BankAccountDateAsc = 'bankAccountDate_ASC',
  BankAccountDateDesc = 'bankAccountDate_DESC',
}

export type LoanMonitoringSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  merchantDate?: Maybe<SortOrder>;
  companyName?: Maybe<SortOrder>;
  loanDate?: Maybe<SortOrder>;
  accountingDate?: Maybe<SortOrder>;
  monitoringStatus?: Maybe<SortOrder>;
  bankBalance?: Maybe<SortOrder>;
  bankAccountDate?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type LoanMonitoringGroupBy = {
  query: LoanMonitoringGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type LoanMonitoringGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  merchantDate?: Maybe<Array<GroupByField>>;
  companyName?: Maybe<Array<GroupByField>>;
  loanDate?: Maybe<Array<GroupByField>>;
  accountingDate?: Maybe<Array<GroupByField>>;
  monitoringStatus?: Maybe<Array<GroupByField>>;
  bankBalance?: Maybe<Array<GroupByField>>;
  bankAccountDate?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

/** CompanyListResponse output */
export type CompanyListResponse = {
  __typename?: 'CompanyListResponse';
  /** List items */
  items: Array<Company>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum CompanyOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  AddressCountryAsc = 'address_country_ASC',
  AddressCountryDesc = 'address_country_DESC',
  AddressStreet1Asc = 'address_street1_ASC',
  AddressStreet1Desc = 'address_street1_DESC',
  AddressStreet2Asc = 'address_street2_ASC',
  AddressStreet2Desc = 'address_street2_DESC',
  AddressZipAsc = 'address_zip_ASC',
  AddressZipDesc = 'address_zip_DESC',
  AddressCityAsc = 'address_city_ASC',
  AddressCityDesc = 'address_city_DESC',
  AddressStateAsc = 'address_state_ASC',
  AddressStateDesc = 'address_state_DESC',
  CompanyStoryAsc = 'companyStory_ASC',
  CompanyStoryDesc = 'companyStory_DESC',
  FounderStoryAsc = 'founderStory_ASC',
  FounderStoryDesc = 'founderStory_DESC',
  ProductDescriptionAsc = 'productDescription_ASC',
  ProductDescriptionDesc = 'productDescription_DESC',
  MarketDescriptionAsc = 'marketDescription_ASC',
  MarketDescriptionDesc = 'marketDescription_DESC',
  CustomersStrategyAsc = 'customersStrategy_ASC',
  CustomersStrategyDesc = 'customersStrategy_DESC',
  CapitalAsc = 'capital_ASC',
  CapitalDesc = 'capital_DESC',
  EquityCapitalAmountAsc = 'equityCapitalAmount_ASC',
  EquityCapitalAmountDesc = 'equityCapitalAmount_DESC',
  MonthsToNextRoundAsc = 'monthsToNextRound_ASC',
  MonthsToNextRoundDesc = 'monthsToNextRound_DESC',
  SecuredDebtAsc = 'securedDebt_ASC',
  SecuredDebtDesc = 'securedDebt_DESC',
  FoundedYearAsc = 'foundedYear_ASC',
  FoundedYearDesc = 'foundedYear_DESC',
  StructureAsc = 'structure_ASC',
  StructureDesc = 'structure_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ContactFirstNameAsc = 'contactFirstName_ASC',
  ContactFirstNameDesc = 'contactFirstName_DESC',
  ContactTitleAsc = 'contactTitle_ASC',
  ContactTitleDesc = 'contactTitle_DESC',
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  PrimaryCustomerBaseAsc = 'primaryCustomerBase_ASC',
  PrimaryCustomerBaseDesc = 'primaryCustomerBase_DESC',
  IdealRaiseAmountAsc = 'idealRaiseAmount_ASC',
  IdealRaiseAmountDesc = 'idealRaiseAmount_DESC',
  BorrowedDebtAsc = 'borrowedDebt_ASC',
  BorrowedDebtDesc = 'borrowedDebt_DESC',
  SaaSScoreAsc = 'saaSScore_ASC',
  SaaSScoreDesc = 'saaSScore_DESC',
  PrimaryBusinessModelAsc = 'primaryBusinessModel_ASC',
  PrimaryBusinessModelDesc = 'primaryBusinessModel_DESC',
  ApplicationFinalizedAsc = 'applicationFinalized_ASC',
  ApplicationFinalizedDesc = 'applicationFinalized_DESC',
  ContactLastNameAsc = 'contactLastName_ASC',
  ContactLastNameDesc = 'contactLastName_DESC',
}

export type CompanyGroupBy = {
  query: CompanyGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** IntegrationListResponse output */
export type IntegrationListResponse = {
  __typename?: 'IntegrationListResponse';
  /** List items */
  items: Array<Integration>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum IntegrationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
}

export type IntegrationGroupBy = {
  query: IntegrationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** XeroIntegrationListResponse output */
export type XeroIntegrationListResponse = {
  __typename?: 'XeroIntegrationListResponse';
  /** List items */
  items: Array<XeroIntegration>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum XeroIntegrationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  AccessTokenAsc = 'accessToken_ASC',
  AccessTokenDesc = 'accessToken_DESC',
  RefreshTokenAsc = 'refreshToken_ASC',
  RefreshTokenDesc = 'refreshToken_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  TenantIdAsc = 'tenantId_ASC',
  TenantIdDesc = 'tenantId_DESC',
}

export type XeroIntegrationGroupBy = {
  query: XeroIntegrationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** PortfolioCompanyListResponse output */
export type PortfolioCompanyListResponse = {
  __typename?: 'PortfolioCompanyListResponse';
  /** List items */
  items: Array<PortfolioCompany>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum PortfolioCompanyOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
}

export type PortfolioCompanyGroupBy = {
  query: PortfolioCompanyGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** MetricListResponse output */
export type MetricListResponse = {
  __typename?: 'MetricListResponse';
  /** List items */
  items: Array<Metric>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum MetricOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SaaSScoreWeightAsc = 'saaSScoreWeight_ASC',
  SaaSScoreWeightDesc = 'saaSScoreWeight_DESC',
  SaaSScoreWeightEnterpriseAsc = 'saaSScoreWeightEnterprise_ASC',
  SaaSScoreWeightEnterpriseDesc = 'saaSScoreWeightEnterprise_DESC',
  TierBandsAsc = 'tierBands_ASC',
  TierBandsDesc = 'tierBands_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
}

export type MetricGroupBy = {
  query: MetricGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** QuickBooksIntegrationListResponse output */
export type QuickBooksIntegrationListResponse = {
  __typename?: 'QuickBooksIntegrationListResponse';
  /** List items */
  items: Array<QuickBooksIntegration>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum QuickBooksIntegrationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  AccessTokenAsc = 'accessToken_ASC',
  AccessTokenDesc = 'accessToken_DESC',
  RefreshTokenAsc = 'refreshToken_ASC',
  RefreshTokenDesc = 'refreshToken_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  RealmIdAsc = 'realmId_ASC',
  RealmIdDesc = 'realmId_DESC',
}

export type QuickBooksIntegrationGroupBy = {
  query: QuickBooksIntegrationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** StripeIntegrationListResponse output */
export type StripeIntegrationListResponse = {
  __typename?: 'StripeIntegrationListResponse';
  /** List items */
  items: Array<StripeIntegration>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum StripeIntegrationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  AccessTokenAsc = 'accessToken_ASC',
  AccessTokenDesc = 'accessToken_DESC',
  RefreshTokenAsc = 'refreshToken_ASC',
  RefreshTokenDesc = 'refreshToken_DESC',
  UserIdAsc = 'userId_ASC',
  UserIdDesc = 'userId_DESC',
}

export type StripeIntegrationGroupBy = {
  query: StripeIntegrationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** AsyncTaskListResponse output */
export type AsyncTaskListResponse = {
  __typename?: 'AsyncTaskListResponse';
  /** List items */
  items: Array<AsyncTask>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type AsyncTask = {
  __typename?: 'AsyncTask';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  args?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['JSON']>;
  argsHash?: Maybe<Scalars['String']>;
  _description?: Maybe<Scalars['String']>;
};

export type AsyncTaskFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  argsHash?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<AsyncTaskFilter>>;
  OR?: Maybe<Array<AsyncTaskFilter>>;
};

/** No longer supported. Use `sort` instead. */
export enum AsyncTaskOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ArgsAsc = 'args_ASC',
  ArgsDesc = 'args_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ResultAsc = 'result_ASC',
  ResultDesc = 'result_DESC',
  ArgsHashAsc = 'argsHash_ASC',
  ArgsHashDesc = 'argsHash_DESC',
}

export type AsyncTaskSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  argsHash?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type AsyncTaskGroupBy = {
  query: AsyncTaskGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AsyncTaskGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  args?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  result?: Maybe<Array<GroupByField>>;
  argsHash?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

/** ProgressStageListResponse output */
export type ProgressStageListResponse = {
  __typename?: 'ProgressStageListResponse';
  /** List items */
  items: Array<ProgressStage>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum ProgressStageOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type ProgressStageGroupBy = {
  query: ProgressStageGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** SortablePortfolioCompanyListResponse output */
export type SortablePortfolioCompanyListResponse = {
  __typename?: 'SortablePortfolioCompanyListResponse';
  /** List items */
  items: Array<SortablePortfolioCompany>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type SortablePortfolioCompany = {
  __typename?: 'SortablePortfolioCompany';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  fundedDate?: Maybe<Scalars['Date']>;
  structure?: Maybe<Scalars['String']>;
  deployed?: Maybe<Scalars['Int']>;
  committed?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['Int']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  _description?: Maybe<Scalars['String']>;
};

export type SortablePortfolioCompanyFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  name?: Maybe<StringPredicate>;
  contactFirstName?: Maybe<StringPredicate>;
  contactLastName?: Maybe<StringPredicate>;
  fundedDate?: Maybe<DatePredicate>;
  structure?: Maybe<StringPredicate>;
  deployed?: Maybe<IntPredicate>;
  committed?: Maybe<IntPredicate>;
  term?: Maybe<IntPredicate>;
  finalAdvanceRate?: Maybe<DatePredicate>;
  nextAvailableDraw?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<SortablePortfolioCompanyFilter>>;
  OR?: Maybe<Array<SortablePortfolioCompanyFilter>>;
};

/** No longer supported. Use `sort` instead. */
export enum SortablePortfolioCompanyOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  MonitoringStatusAsc = 'monitoringStatus_ASC',
  MonitoringStatusDesc = 'monitoringStatus_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ContactFirstNameAsc = 'contactFirstName_ASC',
  ContactFirstNameDesc = 'contactFirstName_DESC',
  ContactLastNameAsc = 'contactLastName_ASC',
  ContactLastNameDesc = 'contactLastName_DESC',
  FundedDateAsc = 'fundedDate_ASC',
  FundedDateDesc = 'fundedDate_DESC',
  StructureAsc = 'structure_ASC',
  StructureDesc = 'structure_DESC',
  DeployedAsc = 'deployed_ASC',
  DeployedDesc = 'deployed_DESC',
  CommittedAsc = 'committed_ASC',
  CommittedDesc = 'committed_DESC',
  TermAsc = 'term_ASC',
  TermDesc = 'term_DESC',
  FinalAdvanceRateAsc = 'finalAdvanceRate_ASC',
  FinalAdvanceRateDesc = 'finalAdvanceRate_DESC',
  NextAvailableDrawAsc = 'nextAvailableDraw_ASC',
  NextAvailableDrawDesc = 'nextAvailableDraw_DESC',
}

export type SortablePortfolioCompanySort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  contactFirstName?: Maybe<SortOrder>;
  contactLastName?: Maybe<SortOrder>;
  fundedDate?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  deployed?: Maybe<SortOrder>;
  committed?: Maybe<SortOrder>;
  term?: Maybe<SortOrder>;
  finalAdvanceRate?: Maybe<SortOrder>;
  nextAvailableDraw?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type SortablePortfolioCompanyGroupBy = {
  query: SortablePortfolioCompanyGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SortablePortfolioCompanyGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  active?: Maybe<Array<GroupByField>>;
  monitoringStatus?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  contactFirstName?: Maybe<Array<GroupByField>>;
  contactLastName?: Maybe<Array<GroupByField>>;
  fundedDate?: Maybe<Array<GroupByField>>;
  structure?: Maybe<Array<GroupByField>>;
  deployed?: Maybe<Array<GroupByField>>;
  committed?: Maybe<Array<GroupByField>>;
  term?: Maybe<Array<GroupByField>>;
  finalAdvanceRate?: Maybe<Array<GroupByField>>;
  nextAvailableDraw?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

/** SortableLoanApplicationListResponse output */
export type SortableLoanApplicationListResponse = {
  __typename?: 'SortableLoanApplicationListResponse';
  /** List items */
  items: Array<SortableLoanApplication>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

export type SortableLoanApplication = {
  __typename?: 'SortableLoanApplication';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  ownerFirstName?: Maybe<Scalars['String']>;
  ownerLastName?: Maybe<Scalars['String']>;
  ownerTitle?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  committed?: Maybe<Scalars['Int']>;
  saasScore?: Maybe<Scalars['Float']>;
  _description?: Maybe<Scalars['String']>;
};

export type SortableLoanApplicationFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  stage?: Maybe<StringPredicate>;
  contactName?: Maybe<StringPredicate>;
  applicantTitle?: Maybe<StringPredicate>;
  targetCloseDate?: Maybe<DatePredicate>;
  amountRequested?: Maybe<IntPredicate>;
  amountCommitted?: Maybe<IntPredicate>;
  firstDrawProposed?: Maybe<IntPredicate>;
  structure?: Maybe<StringPredicate>;
  status?: Maybe<BoolPredicate>;
  companyId?: Maybe<StringPredicate>;
  companyName?: Maybe<StringPredicate>;
  contactFirstName?: Maybe<StringPredicate>;
  contactLastName?: Maybe<StringPredicate>;
  ownerFirstName?: Maybe<StringPredicate>;
  ownerLastName?: Maybe<StringPredicate>;
  ownerTitle?: Maybe<StringPredicate>;
  ownerId?: Maybe<StringPredicate>;
  age?: Maybe<IntPredicate>;
  committed?: Maybe<IntPredicate>;
  saasScore?: Maybe<FloatPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<SortableLoanApplicationFilter>>;
  OR?: Maybe<Array<SortableLoanApplicationFilter>>;
};

/** No longer supported. Use `sort` instead. */
export enum SortableLoanApplicationOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StageAsc = 'stage_ASC',
  StageDesc = 'stage_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ApplicantTitleAsc = 'applicantTitle_ASC',
  ApplicantTitleDesc = 'applicantTitle_DESC',
  TargetCloseDateAsc = 'targetCloseDate_ASC',
  TargetCloseDateDesc = 'targetCloseDate_DESC',
  AmountRequestedAsc = 'amountRequested_ASC',
  AmountRequestedDesc = 'amountRequested_DESC',
  AmountCommittedAsc = 'amountCommitted_ASC',
  AmountCommittedDesc = 'amountCommitted_DESC',
  FirstDrawProposedAsc = 'firstDrawProposed_ASC',
  FirstDrawProposedDesc = 'firstDrawProposed_DESC',
  StructureAsc = 'structure_ASC',
  StructureDesc = 'structure_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  CompanyIdAsc = 'companyId_ASC',
  CompanyIdDesc = 'companyId_DESC',
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  ContactFirstNameAsc = 'contactFirstName_ASC',
  ContactFirstNameDesc = 'contactFirstName_DESC',
  ContactLastNameAsc = 'contactLastName_ASC',
  ContactLastNameDesc = 'contactLastName_DESC',
  OwnerFirstNameAsc = 'ownerFirstName_ASC',
  OwnerFirstNameDesc = 'ownerFirstName_DESC',
  OwnerLastNameAsc = 'ownerLastName_ASC',
  OwnerLastNameDesc = 'ownerLastName_DESC',
  OwnerTitleAsc = 'ownerTitle_ASC',
  OwnerTitleDesc = 'ownerTitle_DESC',
  OwnerIdAsc = 'ownerId_ASC',
  OwnerIdDesc = 'ownerId_DESC',
  AgeAsc = 'age_ASC',
  AgeDesc = 'age_DESC',
  CommittedAsc = 'committed_ASC',
  CommittedDesc = 'committed_DESC',
  SaasScoreAsc = 'saasScore_ASC',
  SaasScoreDesc = 'saasScore_DESC',
}

export type SortableLoanApplicationSort = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  contactName?: Maybe<SortOrder>;
  applicantTitle?: Maybe<SortOrder>;
  targetCloseDate?: Maybe<SortOrder>;
  amountRequested?: Maybe<SortOrder>;
  amountCommitted?: Maybe<SortOrder>;
  firstDrawProposed?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  companyName?: Maybe<SortOrder>;
  contactFirstName?: Maybe<SortOrder>;
  contactLastName?: Maybe<SortOrder>;
  ownerFirstName?: Maybe<SortOrder>;
  ownerLastName?: Maybe<SortOrder>;
  ownerTitle?: Maybe<SortOrder>;
  ownerId?: Maybe<SortOrder>;
  age?: Maybe<SortOrder>;
  committed?: Maybe<SortOrder>;
  saasScore?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
};

export type SortableLoanApplicationGroupBy = {
  query: SortableLoanApplicationGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SortableLoanApplicationGroupByQuery = {
  id?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  stage?: Maybe<Array<GroupByField>>;
  contactName?: Maybe<Array<GroupByField>>;
  applicantTitle?: Maybe<Array<GroupByField>>;
  targetCloseDate?: Maybe<Array<GroupByField>>;
  amountRequested?: Maybe<Array<GroupByField>>;
  amountCommitted?: Maybe<Array<GroupByField>>;
  firstDrawProposed?: Maybe<Array<GroupByField>>;
  structure?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  companyId?: Maybe<Array<GroupByField>>;
  companyName?: Maybe<Array<GroupByField>>;
  contactFirstName?: Maybe<Array<GroupByField>>;
  contactLastName?: Maybe<Array<GroupByField>>;
  ownerFirstName?: Maybe<Array<GroupByField>>;
  ownerLastName?: Maybe<Array<GroupByField>>;
  ownerTitle?: Maybe<Array<GroupByField>>;
  ownerId?: Maybe<Array<GroupByField>>;
  age?: Maybe<Array<GroupByField>>;
  committed?: Maybe<Array<GroupByField>>;
  saasScore?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
};

/** CovenantListResponse output */
export type CovenantListResponse = {
  __typename?: 'CovenantListResponse';
  /** List items */
  items: Array<Covenant>;
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
};

/** No longer supported. Use `sort` instead. */
export enum CovenantOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type CovenantGroupBy = {
  query: CovenantGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** No longer supported. Use `sort` instead. */
export enum SettingOrderBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PasswordMinLengthAsc = 'passwordMinLength_ASC',
  PasswordMinLengthDesc = 'passwordMinLength_DESC',
  PasswordRequireNumbersAsc = 'passwordRequireNumbers_ASC',
  PasswordRequireNumbersDesc = 'passwordRequireNumbers_DESC',
  PasswordRequireSpecialAsc = 'passwordRequireSpecial_ASC',
  PasswordRequireSpecialDesc = 'passwordRequireSpecial_DESC',
  PasswordRequireUppercaseAsc = 'passwordRequireUppercase_ASC',
  PasswordRequireUppercaseDesc = 'passwordRequireUppercase_DESC',
  PasswordRequireLowercaseAsc = 'passwordRequireLowercase_ASC',
  PasswordRequireLowercaseDesc = 'passwordRequireLowercase_DESC',
  PasswordUpdateIntervalAsc = 'passwordUpdateInterval_ASC',
  PasswordUpdateIntervalDesc = 'passwordUpdateInterval_DESC',
  RememberDeviceAsc = 'rememberDevice_ASC',
  RememberDeviceDesc = 'rememberDevice_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  DateFormatAsc = 'dateFormat_ASC',
  DateFormatDesc = 'dateFormat_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  VanityUrlAsc = 'vanityUrl_ASC',
  VanityUrlDesc = 'vanityUrl_DESC',
  ButtonLinkColorAsc = 'buttonLinkColor_ASC',
  ButtonLinkColorDesc = 'buttonLinkColor_DESC',
  UserInterfaceStyleAsc = 'userInterfaceStyle_ASC',
  UserInterfaceStyleDesc = 'userInterfaceStyle_DESC',
  MenuBarBgColorAsc = 'menuBarBGColor_ASC',
  MenuBarBgColorDesc = 'menuBarBGColor_DESC',
  MenuBarIconsColorAsc = 'menuBarIconsColor_ASC',
  MenuBarIconsColorDesc = 'menuBarIconsColor_DESC',
  BgColorAsc = 'bgColor_ASC',
  BgColorDesc = 'bgColor_DESC',
  ContainerColorAsc = 'containerColor_ASC',
  ContainerColorDesc = 'containerColor_DESC',
  LeftNavColorAsc = 'leftNavColor_ASC',
  LeftNavColorDesc = 'leftNavColor_DESC',
}

export type SettingSort = {
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  passwordMinLength?: Maybe<SortOrder>;
  passwordRequireNumbers?: Maybe<SortOrder>;
  passwordRequireSpecial?: Maybe<SortOrder>;
  passwordRequireUppercase?: Maybe<SortOrder>;
  passwordRequireLowercase?: Maybe<SortOrder>;
  passwordUpdateInterval?: Maybe<SortOrder>;
  rememberDevice?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  dateFormat?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  vanityUrl?: Maybe<SortOrder>;
  buttonLinkColor?: Maybe<SortOrder>;
  userInterfaceStyle?: Maybe<SortOrder>;
  menuBarBGColor?: Maybe<SortOrder>;
  menuBarIconsColor?: Maybe<SortOrder>;
  bgColor?: Maybe<SortOrder>;
  containerColor?: Maybe<SortOrder>;
  leftNavColor?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  menuBarLogo?: Maybe<FileSort>;
  landingPageImage?: Maybe<FileSort>;
};

export type SettingGroupBy = {
  query: SettingGroupByQuery;
  sort?: Maybe<Array<GroupBySort>>;
  having?: Maybe<Having>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** User Permission List */
export type UserPermissionList = {
  __typename?: 'UserPermissionList';
  items?: Maybe<Array<UserPermission>>;
  count: Scalars['Int'];
};

/** User Permission */
export type UserPermission = {
  __typename?: 'UserPermission';
  resourceType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['JSON']>;
};

/** ApplicationListResponse output */
export type ApplicationListResponse = {
  __typename?: 'ApplicationListResponse';
  /** List items */
  items: Array<Application>;
  /** List items count */
  count: Scalars['Int'];
};

export type AsyncSessionStatusResponse = {
  __typename?: 'AsyncSessionStatusResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type AuthenticationSetting = {
  __typename?: 'AuthenticationSetting';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  allowedCallbacks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedWebOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedLogouts?: Maybe<Array<Maybe<Scalars['String']>>>;
  connections?: Maybe<AuthenticationProfileConnectionOptions>;
  _description?: Maybe<Scalars['String']>;
};

/** Authentication profile connection options */
export type AuthenticationProfileConnectionOptions = {
  __typename?: 'AuthenticationProfileConnectionOptions';
  google?: Maybe<GoogleOptions>;
  facebook?: Maybe<FacebookOptions>;
  github?: Maybe<GithubOptions>;
};

/** Google connection params */
export type GoogleOptions = {
  __typename?: 'GoogleOptions';
  enabled: Scalars['Boolean'];
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
};

/** Facebook connection params */
export type FacebookOptions = {
  __typename?: 'FacebookOptions';
  enabled: Scalars['Boolean'];
  app_id: Scalars['String'];
  app_secret: Scalars['String'];
};

/** Github connection params */
export type GithubOptions = {
  __typename?: 'GithubOptions';
  enabled: Scalars['Boolean'];
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
};

export type BillingCurrentPlanResponse = {
  __typename?: 'BillingCurrentPlanResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
  status?: Maybe<WorkspaceStatus>;
  nextPlan?: Maybe<BillingNextPlanResponse>;
};

export enum WorkspaceStatus {
  Active = 'active',
  Blocked = 'blocked',
  Canceled = 'canceled',
  Suspended = 'suspended',
  Canceling = 'canceling',
  Pending = 'pending',
}

export type BillingNextPlanResponse = {
  __typename?: 'BillingNextPlanResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
};

export type BillingDetailsResponse = {
  __typename?: 'BillingDetailsResponse';
  last4?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
};

/** BillingInvoicesListResponse output */
export type BillingInvoicesListResponse = {
  __typename?: 'BillingInvoicesListResponse';
  /** List items */
  items: Array<BillingInvoiceItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type BillingInvoiceItem = {
  __typename?: 'BillingInvoiceItem';
  id: Scalars['ID'];
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  paid?: Maybe<Scalars['Boolean']>;
  invoicePdf?: Maybe<Scalars['String']>;
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountRemaining?: Maybe<Scalars['Float']>;
  endingBalance?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  plan?: Maybe<BillingInvoiceItemPlanInfo>;
  workspace?: Maybe<BillingInvoiceItemWorkspaceInfo>;
  organization?: Maybe<BillingInvoiceItemOrganizationInfo>;
};

export type BillingInvoiceItemPlanInfo = {
  __typename?: 'BillingInvoiceItemPlanInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type BillingInvoiceItemWorkspaceInfo = {
  __typename?: 'BillingInvoiceItemWorkspaceInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingInvoiceItemOrganizationInfo = {
  __typename?: 'BillingInvoiceItemOrganizationInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum BillingInvoicesListFilterType {
  Workspace = 'WORKSPACE',
  Customer = 'CUSTOMER',
}

/** BillingMetricUsageQuotasListResponse output */
export type BillingMetricUsageQuotasListResponse = {
  __typename?: 'BillingMetricUsageQuotasListResponse';
  /** List items */
  items: Array<BillingMetricUsageQuotaItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type BillingMetricUsageQuotaItem = {
  __typename?: 'BillingMetricUsageQuotaItem';
  limitMetric?: Maybe<BillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

export type BillingLimitMetricItem = {
  __typename?: 'BillingLimitMetricItem';
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  showPriority?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

/** BillingMetricUsagesListResponse output */
export type BillingMetricUsagesListResponse = {
  __typename?: 'BillingMetricUsagesListResponse';
  /** List items */
  items: Array<BillingMetricUsageItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type BillingMetricUsageItem = {
  __typename?: 'BillingMetricUsageItem';
  limitMetric?: Maybe<BillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

export type BillingMetricUsagesListFilter = {
  entryDate: DateTimePredicate;
};

/** DeployStatusResult */
export type DeployStatusResult = {
  __typename?: 'DeployStatusResult';
  status: DeployStatusEnum;
  message?: Maybe<Scalars['String']>;
};

export enum DeployStatusEnum {
  Deploying = 'deploying',
  CompleteError = 'complete_error',
  CompleteSuccess = 'complete_success',
  Compiling = 'compiling',
  Preparing = 'preparing',
  Initialize = 'initialize',
}

export type FileUploadInfoResponse = {
  __typename?: 'FileUploadInfoResponse';
  policy: Scalars['String'];
  signature: Scalars['String'];
  apiKey: Scalars['String'];
  path: Scalars['String'];
};

/** FunctionListResponse output */
export type FunctionListResponse = {
  __typename?: 'FunctionListResponse';
  /** List items */
  items: Array<FunctionInfo>;
  /** List items count */
  count: Scalars['Int'];
};

export type WorkspaceTransferItem = {
  __typename?: 'WorkspaceTransferItem';
  workspaceId: Scalars['String'];
  workspaceName: Scalars['String'];
  ownerEmail: Scalars['String'];
  ownerFirstName?: Maybe<Scalars['String']>;
  ownerLastName?: Maybe<Scalars['String']>;
  newOwnerEmail: Scalars['String'];
  newOwnerFirstName?: Maybe<Scalars['String']>;
  newOwnerLastName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type SystemQuery = {
  __typename?: 'SystemQuery';
  application?: Maybe<SystemApplication>;
  applicationsList?: Maybe<SystemApplicationListResponse>;
  billingCurrentPlan?: Maybe<SystemBillingCurrentPlanResponse>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetails, system.organizationPaymentDetails or system.workspacePaymentDetails` instead. */
  billingDetails?: Maybe<SystemBillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.memberBillingHistory, system.organizationBillingHistory or system.workspaceBillingHistory` instead. */
  billingInvoicesList: SystemBillingInvoicesListResponse;
  billingMetricUsageQuotasList: SystemBillingMetricUsageQuotasListResponse;
  billingMetricUsagesList: SystemBillingMetricUsagesListResponse;
  ciGenerate?: Maybe<SystemGenerateEnvironmentOutput>;
  /** @deprecated No longer supported. Use `ciGenerate` instead. */
  ciPlan?: Maybe<SystemPlanEnvironmentOutput>;
  ciStatus?: Maybe<SystemCiStatusOutput>;
  deployStatus: SystemDeployStatusResult;
  environmentBackupsList?: Maybe<SystemEnvironmentBackupListResponse>;
  environmentMember?: Maybe<SystemEnvironmentMember>;
  environmentMembersList?: Maybe<SystemEnvironmentMembersListResponse>;
  environmentSettings?: Maybe<SystemEnvironmentSettings>;
  environmentsList?: Maybe<SystemEnvironmentsListResponse>;
  functionsList?: Maybe<SystemFunctionListResponse>;
  getEnvironmentRoles?: Maybe<Array<Maybe<SystemEnvironmentRoleList>>>;
  getFrontendPlanId: SystemFrontendPlanIdItem;
  getWorkspaceTransferInfo?: Maybe<WorkspaceTransferItem>;
  inboxEventsList?: Maybe<SystemInboxEventsListResponse>;
  introspection?: Maybe<IntrospectionQueryResponse>;
  /** @deprecated No longer supported. Use `system.logsList` instead. */
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  logsList?: Maybe<Array<Maybe<SystemFunctionLogEntry>>>;
  memberAccount?: Maybe<SystemMemberAccountInfo>;
  memberBillingHistory: SystemBillingInvoicesListResponse;
  memberInvitation?: Maybe<SystemMemberInvitation>;
  memberInvitationsList?: Maybe<SystemMemberInvitationsList>;
  memberPaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  organizationBillingHistory: SystemBillingInvoicesListResponse;
  organizationById?: Maybe<SystemOrganizationItem>;
  organizationInvitationById?: Maybe<SystemOrganizationInvitation>;
  organizationPaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  organizationWorkspaceAddSession?: Maybe<SystemOrganizationWorkspaceAddSession>;
  organizationsListAll?: Maybe<SystemOrganizationsListAllResponse>;
  organizationsListByUser?: Maybe<SystemOrganizationsListResponse>;
  table?: Maybe<SystemTable>;
  tableField?: Maybe<SystemTableField>;
  tablesList: SystemTableListResponse;
  userBillingConfiguration: SystemUserBillingConfigurationResponse;
  userInvitationsList?: Maybe<SystemUserInvitationList>;
  workspaceBillingHistory: SystemBillingInvoicesListResponse;
  workspacePaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  workspacesFrontendList?: Maybe<SystemWorkspaceListResponse>;
  workspacesList?: Maybe<SystemWorkspaceListResponse>;
};

export type SystemQueryApplicationArgs = {
  id: Scalars['String'];
};

export type SystemQueryBillingInvoicesListArgs = {
  by?: Maybe<SystemBillingInvoicesListFilterType>;
  limit?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type SystemQueryBillingMetricUsagesListArgs = {
  filter?: Maybe<SystemBillingMetricUsagesListFilter>;
};

export type SystemQueryCiGenerateArgs = {
  tables?: Maybe<Array<Scalars['String']>>;
  sourceEnvironmentId?: Maybe<Scalars['String']>;
  targetEnvironmentId?: Maybe<Scalars['String']>;
};

export type SystemQueryCiPlanArgs = {
  tables?: Maybe<Array<Scalars['String']>>;
  sourceEnvironmentId?: Maybe<Scalars['String']>;
  targetEnvironmentId?: Maybe<Scalars['String']>;
};

export type SystemQueryDeployStatusArgs = {
  buildName: Scalars['String'];
};

export type SystemQueryEnvironmentBackupsListArgs = {
  environmentName?: Maybe<Scalars['String']>;
};

export type SystemQueryEnvironmentMemberArgs = {
  filter?: Maybe<SystemEnvironmentMemberFilter>;
};

export type SystemQueryEnvironmentMembersListArgs = {
  filter?: Maybe<SystemEnvironmentMembersListFilter>;
  sort?: Maybe<Array<SystemEnvironmentMembersListSort>>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SystemQueryFunctionsListArgs = {
  applicationId?: Maybe<Scalars['String']>;
  filter?: Maybe<SystemFunctionInfoFilter>;
  orderBy?: Maybe<Array<Maybe<SystemFunctionInfoOrderBy>>>;
};

export type SystemQueryGetEnvironmentRolesArgs = {
  workspaceId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type SystemQueryGetWorkspaceTransferInfoArgs = {
  workspaceId: Scalars['String'];
};

export type SystemQueryInboxEventsListArgs = {
  filter?: Maybe<InboxEventsListFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SystemQueryLogsArgs = {
  functionName: Scalars['String'];
  applicationId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type SystemQueryLogsListArgs = {
  functionName: Scalars['String'];
  applicationId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type SystemQueryMemberBillingHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  hideOrganizationInvoices?: Maybe<Scalars['Boolean']>;
};

export type SystemQueryMemberInvitationArgs = {
  id: Scalars['String'];
};

export type SystemQueryOrganizationBillingHistoryArgs = {
  organizationId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  hideWorkspaceInvoices?: Maybe<Scalars['Boolean']>;
};

export type SystemQueryOrganizationByIdArgs = {
  organizationId: Scalars['String'];
};

export type SystemQueryOrganizationInvitationByIdArgs = {
  invitationId: Scalars['String'];
};

export type SystemQueryOrganizationPaymentDetailsArgs = {
  organizationId: Scalars['ID'];
};

export type SystemQueryOrganizationWorkspaceAddSessionArgs = {
  sessionId: Scalars['String'];
};

export type SystemQueryTableArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemQueryTableFieldArgs = {
  id: Scalars['ID'];
};

export type SystemQueryTablesListArgs = {
  filter?: Maybe<SystemTableListFilter>;
};

export type SystemQueryWorkspaceBillingHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** SystemApplicationListResponse output */
export type SystemApplicationListResponse = {
  __typename?: 'SystemApplicationListResponse';
  /** List items */
  items: Array<SystemApplication>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemBillingCurrentPlanResponse = {
  __typename?: 'SystemBillingCurrentPlanResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SystemWorkspaceStatus>;
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
};

export enum SystemWorkspaceStatus {
  Active = 'active',
  Blocked = 'blocked',
  Canceled = 'canceled',
  Suspended = 'suspended',
  Canceling = 'canceling',
  Pending = 'pending',
}

export type SystemBillingNextPlanResponse = {
  __typename?: 'SystemBillingNextPlanResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
};

export type SystemBillingDetailsResponse = {
  __typename?: 'SystemBillingDetailsResponse';
  last4?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
};

/** SystemBillingInvoicesListResponse output */
export type SystemBillingInvoicesListResponse = {
  __typename?: 'SystemBillingInvoicesListResponse';
  /** List items */
  items: Array<SystemBillingInvoiceItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemBillingInvoiceItem = {
  __typename?: 'SystemBillingInvoiceItem';
  id: Scalars['ID'];
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  paid?: Maybe<Scalars['Boolean']>;
  invoicePdf?: Maybe<Scalars['String']>;
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountRemaining?: Maybe<Scalars['Float']>;
  endingBalance?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  plan?: Maybe<SystemBillingInvoiceItemPlanInfo>;
  workspace?: Maybe<SystemBillingInvoiceItemWorkspaceInfo>;
  organization?: Maybe<SystemBillingInvoiceItemOrganizationInfo>;
};

export type SystemBillingInvoiceItemPlanInfo = {
  __typename?: 'SystemBillingInvoiceItemPlanInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoiceItemWorkspaceInfo = {
  __typename?: 'SystemBillingInvoiceItemWorkspaceInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoiceItemOrganizationInfo = {
  __typename?: 'SystemBillingInvoiceItemOrganizationInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum SystemBillingInvoicesListFilterType {
  Workspace = 'WORKSPACE',
  Customer = 'CUSTOMER',
}

/** SystemBillingMetricUsageQuotasListResponse output */
export type SystemBillingMetricUsageQuotasListResponse = {
  __typename?: 'SystemBillingMetricUsageQuotasListResponse';
  /** List items */
  items: Array<SystemBillingMetricUsageQuotaItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemBillingMetricUsageQuotaItem = {
  __typename?: 'SystemBillingMetricUsageQuotaItem';
  limitMetric?: Maybe<SystemBillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

export type SystemBillingLimitMetricItem = {
  __typename?: 'SystemBillingLimitMetricItem';
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  showPriority?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

/** SystemBillingMetricUsagesListResponse output */
export type SystemBillingMetricUsagesListResponse = {
  __typename?: 'SystemBillingMetricUsagesListResponse';
  /** List items */
  items: Array<SystemBillingMetricUsageItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemBillingMetricUsageItem = {
  __typename?: 'SystemBillingMetricUsageItem';
  limitMetric?: Maybe<SystemBillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

export type SystemBillingMetricUsagesListFilter = {
  entryDate: DateTimePredicate;
};

/** Diff Environment Input */
export type SystemGenerateEnvironmentOutput = {
  __typename?: 'SystemGenerateEnvironmentOutput';
  url?: Maybe<Scalars['String']>;
};

/** Diff Environment Input */
export type SystemPlanEnvironmentOutput = {
  __typename?: 'SystemPlanEnvironmentOutput';
  url?: Maybe<Scalars['String']>;
};

/** Ci Status */
export type SystemCiStatusOutput = {
  __typename?: 'SystemCiStatusOutput';
  status: Scalars['String'];
  migrations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** SystemDeployStatusResult */
export type SystemDeployStatusResult = {
  __typename?: 'SystemDeployStatusResult';
  status: SystemDeployStatusEnum;
  message?: Maybe<Scalars['String']>;
};

export enum SystemDeployStatusEnum {
  Deploying = 'deploying',
  CompleteError = 'complete_error',
  CompleteSuccess = 'complete_success',
  Compiling = 'compiling',
  Preparing = 'preparing',
  Initialize = 'initialize',
}

/** SystemEnvironmentBackupListResponse output */
export type SystemEnvironmentBackupListResponse = {
  __typename?: 'SystemEnvironmentBackupListResponse';
  /** List items */
  items: Array<EnvironmentBackupItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type EnvironmentBackupItem = {
  __typename?: 'EnvironmentBackupItem';
  name: Scalars['String'];
  size: Scalars['Float'];
};

export type SystemEnvironmentMember = {
  __typename?: 'SystemEnvironmentMember';
  email: Scalars['String'];
  status: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  avatar?: Maybe<SystemEnvironmentMemberAvatar>;
  permissions?: Maybe<SystemEnvironmentMemberPermissionsList>;
  roles?: Maybe<SystemEnvironmentMemberRolesList>;
};

export type SystemEnvironmentMemberAvatar = {
  __typename?: 'SystemEnvironmentMemberAvatar';
  id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberPermissionsList = {
  __typename?: 'SystemEnvironmentMemberPermissionsList';
  items?: Maybe<Array<SystemEnvironmentMemberPermission>>;
  count: Scalars['Int'];
};

export type SystemEnvironmentMemberPermission = {
  __typename?: 'SystemEnvironmentMemberPermission';
  resourceType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['JSON']>;
};

export type SystemEnvironmentMemberRolesList = {
  __typename?: 'SystemEnvironmentMemberRolesList';
  items?: Maybe<Array<SystemEnvironmentMemberRole>>;
  count: Scalars['Int'];
};

export type SystemEnvironmentMemberRole = {
  __typename?: 'SystemEnvironmentMemberRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SystemEnvironmentMemberFilter = {
  email: Scalars['String'];
};

/** SystemEnvironmentMembersListResponse output */
export type SystemEnvironmentMembersListResponse = {
  __typename?: 'SystemEnvironmentMembersListResponse';
  /** List items */
  items: Array<SystemEnvironmentMember>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemEnvironmentMembersListFilter = {
  roleId?: Maybe<SystemEnvironmentMemberRoleIdFilterPredicate>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberRoleIdFilterPredicate = {
  equals?: Maybe<Scalars['ID']>;
  not_equals?: Maybe<Scalars['ID']>;
};

export type SystemEnvironmentMembersListSort = {
  isOwner?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
};

export type SystemEnvironmentSettings = {
  __typename?: 'SystemEnvironmentSettings';
  deleteLock?: Maybe<Scalars['Boolean']>;
  fileManagementProvider?: Maybe<Scalars['String']>;
};

/** SystemEnvironmentsListResponse output */
export type SystemEnvironmentsListResponse = {
  __typename?: 'SystemEnvironmentsListResponse';
  /** List items */
  items: Array<EnvironmentItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type EnvironmentItem = {
  __typename?: 'EnvironmentItem';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** SystemFunctionListResponse output */
export type SystemFunctionListResponse = {
  __typename?: 'SystemFunctionListResponse';
  /** List items */
  items: Array<SystemFunctionInfo>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemEnvironmentRoleList = {
  __typename?: 'SystemEnvironmentRoleList';
  environmentId: Scalars['String'];
  environmentName: Scalars['String'];
  roles?: Maybe<Array<Maybe<SystemEnvironmentRoleBaseInfo>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type SystemEnvironmentRoleBaseInfo = {
  __typename?: 'SystemEnvironmentRoleBaseInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SystemFrontendPlanIdItem = {
  __typename?: 'SystemFrontendPlanIdItem';
  id?: Maybe<Scalars['ID']>;
};

/** SystemInboxEventsListResponse output */
export type SystemInboxEventsListResponse = {
  __typename?: 'SystemInboxEventsListResponse';
  /** List items */
  items: Array<SystemInboxEventItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemInboxEventItem = {
  __typename?: 'SystemInboxEventItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  type?: Maybe<SystemInboxEventTypeEnum>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  details?: Maybe<SystemInboxEventDetailsUnion>;
};

export enum SystemInboxEventTypeEnum {
  OrganizationInvitation = 'OrganizationInvitation',
  EnvironmentInvitation = 'EnvironmentInvitation',
  Notification = 'Notification',
}

export type SystemInboxEventDetailsUnion =
  | SystemInboxEventEnvironmentInvitationDetails
  | SystemInboxEventOrganizationInvitationDetails
  | SystemInboxEventNotificationDetailsType;

export type SystemInboxEventEnvironmentInvitationDetails = {
  __typename?: 'SystemInboxEventEnvironmentInvitationDetails';
  uuid?: Maybe<Scalars['String']>;
  status?: Maybe<SystemInboxEventStatusEnum>;
  invitedBy?: Maybe<SystemInboxEventInvitedBy>;
  workspace?: Maybe<SystemInboxEventWorkspace>;
  environmentName?: Maybe<Scalars['String']>;
};

export enum SystemInboxEventStatusEnum {
  Sent = 'sent',
  Accepted = 'accepted',
  Declined = 'declined',
}

export type SystemInboxEventInvitedBy = {
  __typename?: 'SystemInboxEventInvitedBy';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
};

/** GraphQLFileItemResponse */
export type GraphQlFileItemResponse = {
  __typename?: 'GraphQLFileItemResponse';
  id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type SystemInboxEventWorkspace = {
  __typename?: 'SystemInboxEventWorkspace';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  apiHost?: Maybe<Scalars['String']>;
};

export type SystemInboxEventOrganizationInvitationDetails = {
  __typename?: 'SystemInboxEventOrganizationInvitationDetails';
  uuid?: Maybe<Scalars['String']>;
  status?: Maybe<SystemInboxEventStatusEnum>;
  invitedBy?: Maybe<SystemInboxEventInvitedBy>;
  organization?: Maybe<SystemInboxEventOrganization>;
};

export type SystemInboxEventOrganization = {
  __typename?: 'SystemInboxEventOrganization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
};

export type SystemInboxEventNotificationDetailsType = {
  __typename?: 'SystemInboxEventNotificationDetailsType';
  details?: Maybe<Scalars['JSON']>;
  kind?: Maybe<Scalars['String']>;
};

/** Inbox Events List Filter */
export type InboxEventsListFilter = {
  isCompleted?: Maybe<IntPredicate>;
};

export type IntrospectionQueryResponse = {
  __typename?: 'IntrospectionQueryResponse';
  url: Scalars['String'];
};

export type SystemMemberAccountInfo = {
  __typename?: 'SystemMemberAccountInfo';
  createdAt?: Maybe<Scalars['DateTime']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  learningMode?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  isDeveloper?: Maybe<Scalars['Boolean']>;
  aboutMe?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  linkedInUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  type?: Maybe<SystemUserType>;
  companyName?: Maybe<Scalars['String']>;
  projectDescription?: Maybe<Scalars['String']>;
};

/** User Type */
export enum SystemUserType {
  MyOwn = 'MyOwn',
  Agency = 'Agency',
  Team = 'Team',
}

/** Member Invitation */
export type SystemMemberInvitation = {
  __typename?: 'SystemMemberInvitation';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  resentOn?: Maybe<Scalars['DateTime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
};

/** Invited By Name */
export type SystemInvitedByName = {
  __typename?: 'SystemInvitedByName';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  workspaceName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SystemMemberInvitationsList = {
  __typename?: 'SystemMemberInvitationsList';
  items?: Maybe<Array<Maybe<SystemMemberInvitation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type SystemPaymentDetailsResponse = {
  __typename?: 'SystemPaymentDetailsResponse';
  last4?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
};

export type SystemOrganizationItem = {
  __typename?: 'SystemOrganizationItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  predefinedPlans?: Maybe<Array<Maybe<SystemBillingPlanBaseScalarInfo>>>;
  type?: Maybe<SystemOrganizationTypeEnum>;
  users?: Maybe<Array<Maybe<SystemOrganizationUserInfo>>>;
  image?: Maybe<SystemOrganizationItemImage>;
};

export type SystemBillingPlanBaseScalarInfo = {
  __typename?: 'SystemBillingPlanBaseScalarInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export enum SystemOrganizationTypeEnum {
  Agency = 'agency',
  Company = 'company',
  Community = 'community',
}

export type SystemOrganizationUserInfo = {
  __typename?: 'SystemOrganizationUserInfo';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  status: Scalars['String'];
  avatar?: Maybe<GraphQlFileItemResponse>;
  workspaces?: Maybe<Array<Maybe<SystemOrganizationWorkspaceItem>>>;
};

export type SystemOrganizationWorkspaceItem = {
  __typename?: 'SystemOrganizationWorkspaceItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<SystemOrganizationBaseItem>;
};

export type SystemOrganizationBaseItem = {
  __typename?: 'SystemOrganizationBaseItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<SystemOrganizationTypeEnum>;
  description?: Maybe<Scalars['String']>;
};

export type SystemOrganizationItemImage = {
  __typename?: 'SystemOrganizationItemImage';
  id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type SystemOrganizationInvitation = {
  __typename?: 'SystemOrganizationInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailFrom: Scalars['String'];
  firstNameFrom?: Maybe<Scalars['String']>;
  lastNameFrom?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  accepted?: Maybe<Scalars['Boolean']>;
  organization: SystemOrganizationBaseItem;
};

export type SystemOrganizationWorkspaceAddSession = {
  __typename?: 'SystemOrganizationWorkspaceAddSession';
  user?: Maybe<SystemOrganizationUserBaseInfo>;
  workspace: SystemOrganizationWorkspaceItem;
  organization: SystemOrganizationBaseItem;
  status?: Maybe<Scalars['String']>;
};

export type SystemOrganizationUserBaseInfo = {
  __typename?: 'SystemOrganizationUserBaseInfo';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  status: Scalars['String'];
};

/** SystemOrganizationsListAllResponse output */
export type SystemOrganizationsListAllResponse = {
  __typename?: 'SystemOrganizationsListAllResponse';
  /** List items */
  items: Array<SystemOrganizationItem>;
  /** List items count */
  count: Scalars['Int'];
};

/** SystemOrganizationsListResponse output */
export type SystemOrganizationsListResponse = {
  __typename?: 'SystemOrganizationsListResponse';
  /** List items */
  items: Array<SystemOrganizationItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemUserBillingConfigurationResponse = {
  __typename?: 'SystemUserBillingConfigurationResponse';
  isFreePlanAvailable: Scalars['Boolean'];
  isCancelSubscriptionAvailable: Scalars['Boolean'];
  availablePlans: Array<SystemBillingPlanBaseInfo>;
};

export type SystemBillingPlanBaseInfo = {
  __typename?: 'SystemBillingPlanBaseInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  limitMetrics?: Maybe<Array<Maybe<SystemBillingPlanLimitMetricItem>>>;
};

export type SystemBillingPlanLimitMetricItem = {
  __typename?: 'SystemBillingPlanLimitMetricItem';
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['Int']>;
  softLimit?: Maybe<Scalars['Float']>;
  hardLimit?: Maybe<Scalars['Float']>;
};

/** User Invitation List */
export type SystemUserInvitationList = {
  __typename?: 'SystemUserInvitationList';
  items?: Maybe<Array<Maybe<SystemUserInvitationDetails>>>;
  count?: Maybe<Scalars['Int']>;
};

/** User Invitation Details */
export type SystemUserInvitationDetails = {
  __typename?: 'SystemUserInvitationDetails';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  resentOn?: Maybe<Scalars['DateTime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
};

/** SystemWorkspaceListResponse output */
export type SystemWorkspaceListResponse = {
  __typename?: 'SystemWorkspaceListResponse';
  /** List items */
  items: Array<SystemWorkspaceItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type SystemWorkspaceItem = {
  __typename?: 'SystemWorkspaceItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  isOwner: Scalars['Boolean'];
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  teamMemberCount?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  owner?: Maybe<SystemMemberAccountInfo>;
  image?: Maybe<SystemWorkspaceImage>;
  isCiCdEnabled?: Maybe<Scalars['Boolean']>;
  apiHost?: Maybe<Scalars['String']>;
  webSocket?: Maybe<Scalars['String']>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type SystemWorkspaceImage = {
  __typename?: 'SystemWorkspaceImage';
  id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type UserBillingConfigurationResponse = {
  __typename?: 'UserBillingConfigurationResponse';
  isFreePlanAvailable: Scalars['Boolean'];
  isCancelSubscriptionAvailable: Scalars['Boolean'];
  availablePlans: Array<BillingPlanBaseInfo>;
};

export type BillingPlanBaseInfo = {
  __typename?: 'BillingPlanBaseInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  limitMetrics?: Maybe<Array<Maybe<BillingPlanLimitMetricItem>>>;
};

export type BillingPlanLimitMetricItem = {
  __typename?: 'BillingPlanLimitMetricItem';
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['Int']>;
  softLimit?: Maybe<Scalars['Float']>;
  hardLimit?: Maybe<Scalars['Float']>;
};

/** User Invitation List */
export type UserInvitationList = {
  __typename?: 'UserInvitationList';
  items?: Maybe<Array<Maybe<UserInvitationDetails>>>;
  count?: Maybe<Scalars['Int']>;
};

/** User Invitation Details */
export type UserInvitationDetails = {
  __typename?: 'UserInvitationDetails';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  resentOn?: Maybe<Scalars['DateTime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<InvitedByName>;
};

/** Invited By Name */
export type InvitedByName = {
  __typename?: 'InvitedByName';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  workspaceName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** WorkspaceListResponse output */
export type WorkspaceListResponse = {
  __typename?: 'WorkspaceListResponse';
  /** List items */
  items: Array<WorkspaceItem>;
  /** List items count */
  count: Scalars['Int'];
};

export type WorkspaceItem = {
  __typename?: 'WorkspaceItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  isOwner: Scalars['Boolean'];
  plan?: Maybe<BillingCurrentPlanResponse>;
  nextPlan?: Maybe<BillingNextPlanResponse>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  teamMemberCount?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  owner?: Maybe<SystemMemberAccountInfo>;
  image?: Maybe<WorkspaceImage>;
  isCiCdEnabled?: Maybe<Scalars['Boolean']>;
  apiHost?: Maybe<Scalars['String']>;
  webSocket?: Maybe<Scalars['String']>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WorkspaceImage = {
  __typename?: 'WorkspaceImage';
  id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  apiTokenCreate: ApiTokenResponse;
  apiTokenDelete?: Maybe<SuccessResponse>;
  apiTokenDeleteByFilter?: Maybe<SuccessResponse>;
  apiTokenDestroy?: Maybe<SuccessResponse>;
  apiTokenDestroyByFilter?: Maybe<SuccessResponse>;
  apiTokenRestore: ApiToken;
  apiTokenUpdate: ApiToken;
  apiTokenUpdateByFilter: ApiTokenManyResponse;
  /** @deprecated No longer supported. Use `system.applicationDelete` instead. */
  applicationDelete?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.applicationInstall` instead. */
  applicationInstall?: Maybe<Application>;
  /** @deprecated No longer supported. Use `system.applicationUpdate` instead. */
  applicationUpdate?: Maybe<Application>;
  asyncTaskCreate: AsyncTask;
  asyncTaskCreateMany: AsyncTaskManyResponse;
  asyncTaskDelete?: Maybe<SuccessResponse>;
  asyncTaskDeleteByFilter?: Maybe<SuccessResponse>;
  asyncTaskDestroy?: Maybe<SuccessResponse>;
  asyncTaskDestroyByFilter?: Maybe<SuccessResponse>;
  asyncTaskRestore: AsyncTask;
  asyncTaskUpdate: AsyncTask;
  asyncTaskUpdateByFilter: AsyncTaskManyResponse;
  authenticationProfileCreate: AuthenticationProfile;
  authenticationProfileCreateMany: AuthenticationProfileManyResponse;
  authenticationProfileDelete?: Maybe<SuccessResponse>;
  authenticationProfileDeleteByFilter?: Maybe<SuccessResponse>;
  authenticationProfileDestroy?: Maybe<SuccessResponse>;
  authenticationProfileDestroyByFilter?: Maybe<SuccessResponse>;
  authenticationProfileRestore: AuthenticationProfile;
  authenticationProfileUpdate: AuthenticationProfile;
  authenticationProfileUpdateByFilter: AuthenticationProfileManyResponse;
  authenticationSettingsUpdate: AuthenticationSetting;
  /** @deprecated No longer supported. Use `system.memberPaymentDetailsUpdate, system.organizationPaymentDetailsUpdate or system.workspacePaymentDetailsUpdate` instead. */
  billingDetailsUpdate?: Maybe<BillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.billingPlanUpdate` instead. */
  billingPlanUpdate?: Maybe<BillingCurrentPlanResponse>;
  cashMonitoringRecordCreate: CashMonitoringRecord;
  cashMonitoringRecordCreateMany: CashMonitoringRecordManyResponse;
  cashMonitoringRecordDelete?: Maybe<SuccessResponse>;
  cashMonitoringRecordDeleteByFilter?: Maybe<SuccessResponse>;
  cashMonitoringRecordDestroy?: Maybe<SuccessResponse>;
  cashMonitoringRecordDestroyByFilter?: Maybe<SuccessResponse>;
  cashMonitoringRecordRestore: CashMonitoringRecord;
  cashMonitoringRecordUpdate: CashMonitoringRecord;
  cashMonitoringRecordUpdateByFilter: CashMonitoringRecordManyResponse;
  ciCdMigrationCreate: CiCdMigration;
  ciCdMigrationCreateMany: CiCdMigrationManyResponse;
  ciCdMigrationDelete?: Maybe<SuccessResponse>;
  ciCdMigrationDeleteByFilter?: Maybe<SuccessResponse>;
  ciCdMigrationDestroy?: Maybe<SuccessResponse>;
  ciCdMigrationDestroyByFilter?: Maybe<SuccessResponse>;
  ciCdMigrationRestore: CiCdMigration;
  ciCdMigrationUpdate: CiCdMigration;
  ciCdMigrationUpdateByFilter: CiCdMigrationManyResponse;
  companyCreate: Company;
  companyCreateMany: CompanyManyResponse;
  companyDelete?: Maybe<SuccessResponse>;
  companyDeleteByFilter?: Maybe<SuccessResponse>;
  companyDestroy?: Maybe<SuccessResponse>;
  companyDestroyByFilter?: Maybe<SuccessResponse>;
  companyMonitoringCreate: CompanyMonitoring;
  companyMonitoringCreateMany: CompanyMonitoringManyResponse;
  companyMonitoringDelete?: Maybe<SuccessResponse>;
  companyMonitoringDeleteByFilter?: Maybe<SuccessResponse>;
  companyMonitoringDestroy?: Maybe<SuccessResponse>;
  companyMonitoringDestroyByFilter?: Maybe<SuccessResponse>;
  companyMonitoringRestore: CompanyMonitoring;
  companyMonitoringUpdate: CompanyMonitoring;
  companyMonitoringUpdateByFilter: CompanyMonitoringManyResponse;
  companyRestore: Company;
  companyUpdate: Company;
  companyUpdateByFilter: CompanyManyResponse;
  covenantCreate: Covenant;
  covenantCreateMany: CovenantManyResponse;
  covenantDelete?: Maybe<SuccessResponse>;
  covenantDeleteByFilter?: Maybe<SuccessResponse>;
  covenantDestroy?: Maybe<SuccessResponse>;
  covenantDestroyByFilter?: Maybe<SuccessResponse>;
  covenantRestore: Covenant;
  covenantUpdate: Covenant;
  covenantUpdateByFilter: CovenantManyResponse;
  covenantValueCreate: CovenantValue;
  covenantValueCreateMany: CovenantValueManyResponse;
  covenantValueDelete?: Maybe<SuccessResponse>;
  covenantValueDeleteByFilter?: Maybe<SuccessResponse>;
  covenantValueDestroy?: Maybe<SuccessResponse>;
  covenantValueDestroyByFilter?: Maybe<SuccessResponse>;
  covenantValueRestore: CovenantValue;
  covenantValueUpdate: CovenantValue;
  covenantValueUpdateByFilter: CovenantValueManyResponse;
  customCovenantValueCreate: CustomCovenantValue;
  customCovenantValueCreateMany: CustomCovenantValueManyResponse;
  customCovenantValueDelete?: Maybe<SuccessResponse>;
  customCovenantValueDeleteByFilter?: Maybe<SuccessResponse>;
  customCovenantValueDestroy?: Maybe<SuccessResponse>;
  customCovenantValueDestroyByFilter?: Maybe<SuccessResponse>;
  customCovenantValueRestore: CustomCovenantValue;
  customCovenantValueUpdate: CustomCovenantValue;
  customCovenantValueUpdateByFilter: CustomCovenantValueManyResponse;
  /** @deprecated No longer supported. Use `system.deploy` instead. */
  deploy?: Maybe<Scalars['Boolean']>;
  documentCreate: Document;
  documentCreateMany: DocumentManyResponse;
  documentDelete?: Maybe<SuccessResponse>;
  documentDeleteByFilter?: Maybe<SuccessResponse>;
  documentDestroy?: Maybe<SuccessResponse>;
  documentDestroyByFilter?: Maybe<SuccessResponse>;
  documentRestore: Document;
  documentUpdate: Document;
  documentUpdateByFilter: DocumentManyResponse;
  drawCreate: Draw;
  drawCreateMany: DrawManyResponse;
  drawDelete?: Maybe<SuccessResponse>;
  drawDeleteByFilter?: Maybe<SuccessResponse>;
  drawDestroy?: Maybe<SuccessResponse>;
  drawDestroyByFilter?: Maybe<SuccessResponse>;
  drawHistoryCreate: DrawHistory;
  drawHistoryCreateMany: DrawHistoryManyResponse;
  drawHistoryDelete?: Maybe<SuccessResponse>;
  drawHistoryDeleteByFilter?: Maybe<SuccessResponse>;
  drawHistoryDestroy?: Maybe<SuccessResponse>;
  drawHistoryDestroyByFilter?: Maybe<SuccessResponse>;
  drawHistoryRestore: DrawHistory;
  drawHistoryUpdate: DrawHistory;
  drawHistoryValueCreate: DrawHistoryValue;
  drawHistoryValueCreateMany: DrawHistoryValueManyResponse;
  drawHistoryValueDelete?: Maybe<SuccessResponse>;
  drawHistoryValueDeleteByFilter?: Maybe<SuccessResponse>;
  drawHistoryValueDestroy?: Maybe<SuccessResponse>;
  drawHistoryValueDestroyByFilter?: Maybe<SuccessResponse>;
  drawHistoryValueRestore: DrawHistoryValue;
  drawHistoryValueUpdate: DrawHistoryValue;
  drawHistoryValueUpdateByFilter: DrawHistoryValueManyResponse;
  drawRestore: Draw;
  drawUpdate: Draw;
  drawUpdateByFilter: DrawManyResponse;
  environmentVariableCreate: EnvironmentVariable;
  environmentVariableCreateMany: EnvironmentVariableManyResponse;
  environmentVariableDelete?: Maybe<SuccessResponse>;
  environmentVariableDeleteByFilter?: Maybe<SuccessResponse>;
  environmentVariableDestroy?: Maybe<SuccessResponse>;
  environmentVariableDestroyByFilter?: Maybe<SuccessResponse>;
  environmentVariableRestore: EnvironmentVariable;
  environmentVariableUpdate: EnvironmentVariable;
  environmentVariableUpdateByFilter: EnvironmentVariableManyResponse;
  facilityCreate: Facility;
  facilityCreateMany: FacilityManyResponse;
  facilityDelete?: Maybe<SuccessResponse>;
  facilityDeleteByFilter?: Maybe<SuccessResponse>;
  facilityDestroy?: Maybe<SuccessResponse>;
  facilityDestroyByFilter?: Maybe<SuccessResponse>;
  facilityHistoryCreate: FacilityHistory;
  facilityHistoryCreateMany: FacilityHistoryManyResponse;
  facilityHistoryDelete?: Maybe<SuccessResponse>;
  facilityHistoryDeleteByFilter?: Maybe<SuccessResponse>;
  facilityHistoryDestroy?: Maybe<SuccessResponse>;
  facilityHistoryDestroyByFilter?: Maybe<SuccessResponse>;
  facilityHistoryRestore: FacilityHistory;
  facilityHistoryUpdate: FacilityHistory;
  facilityHistoryValueCreate: FacilityHistoryValue;
  facilityHistoryValueCreateMany: FacilityHistoryValueManyResponse;
  facilityHistoryValueDelete?: Maybe<SuccessResponse>;
  facilityHistoryValueDeleteByFilter?: Maybe<SuccessResponse>;
  facilityHistoryValueDestroy?: Maybe<SuccessResponse>;
  facilityHistoryValueDestroyByFilter?: Maybe<SuccessResponse>;
  facilityHistoryValueRestore: FacilityHistoryValue;
  facilityHistoryValueUpdate: FacilityHistoryValue;
  facilityHistoryValueUpdateByFilter: FacilityHistoryValueManyResponse;
  facilityRestore: Facility;
  facilityUpdate: Facility;
  facilityUpdateByFilter: FacilityManyResponse;
  /** @deprecated No longer supported. Use `system.fieldCreate` instead. */
  fieldCreate: TableField;
  /** @deprecated No longer supported. Use `system.fieldDelete` instead. */
  fieldDelete: SuccessResponse;
  /** @deprecated No longer supported. Use `system.fieldUpdate` instead. */
  fieldUpdate: TableField;
  /** @deprecated No longer supported. Use `system.fieldUpdatePosition` instead. */
  fieldUpdatePosition: SuccessResponse;
  fileCreate: File;
  fileCreateMany: FileManyResponse;
  fileDelete?: Maybe<SuccessResponse>;
  fileDeleteByFilter?: Maybe<SuccessResponse>;
  fileDestroy?: Maybe<SuccessResponse>;
  fileDestroyByFilter?: Maybe<SuccessResponse>;
  fileRestore: File;
  fileUpdate: File;
  fileUpdateByFilter: FileManyResponse;
  inboxMessageCreate: InboxMessage;
  inboxMessageCreateMany: InboxMessageManyResponse;
  inboxMessageDelete?: Maybe<SuccessResponse>;
  inboxMessageDeleteByFilter?: Maybe<SuccessResponse>;
  inboxMessageDestroy?: Maybe<SuccessResponse>;
  inboxMessageDestroyByFilter?: Maybe<SuccessResponse>;
  inboxMessageRestore: InboxMessage;
  inboxMessageUpdate: InboxMessage;
  inboxMessageUpdateByFilter: InboxMessageManyResponse;
  indexCreate: TableIndex;
  indexDelete?: Maybe<SuccessResponse>;
  indexUpdate: TableIndex;
  integrationCreate: Integration;
  integrationCreateMany: IntegrationManyResponse;
  integrationDelete?: Maybe<SuccessResponse>;
  integrationDeleteByFilter?: Maybe<SuccessResponse>;
  integrationDestroy?: Maybe<SuccessResponse>;
  integrationDestroyByFilter?: Maybe<SuccessResponse>;
  integrationRestore: Integration;
  integrationUpdate: Integration;
  invitationCreate: Invitation;
  invitationCreateMany: InvitationManyResponse;
  invitationDelete?: Maybe<SuccessResponse>;
  invitationDeleteByFilter?: Maybe<SuccessResponse>;
  invitationDestroy?: Maybe<SuccessResponse>;
  invitationDestroyByFilter?: Maybe<SuccessResponse>;
  invitationRestore: Invitation;
  invitationUpdate: Invitation;
  invitationUpdateByFilter: InvitationManyResponse;
  invitations?: Maybe<InvitationsMutation>;
  /** @deprecated No longer supported. Use `system.invoke` instead. */
  invoke?: Maybe<InvokeFunctionResponse>;
  loanApplicationCreate: LoanApplication;
  loanApplicationCreateMany: LoanApplicationManyResponse;
  loanApplicationDelete?: Maybe<SuccessResponse>;
  loanApplicationDeleteByFilter?: Maybe<SuccessResponse>;
  loanApplicationDestroy?: Maybe<SuccessResponse>;
  loanApplicationDestroyByFilter?: Maybe<SuccessResponse>;
  loanApplicationRestore: LoanApplication;
  loanApplicationUpdate: LoanApplication;
  loanApplicationUpdateByFilter: LoanApplicationManyResponse;
  loanMonitoringCreate: LoanMonitoring;
  loanMonitoringCreateMany: LoanMonitoringManyResponse;
  loanMonitoringDelete?: Maybe<SuccessResponse>;
  loanMonitoringDeleteByFilter?: Maybe<SuccessResponse>;
  loanMonitoringDestroy?: Maybe<SuccessResponse>;
  loanMonitoringDestroyByFilter?: Maybe<SuccessResponse>;
  loanMonitoringRestore: LoanMonitoring;
  loanMonitoringUpdate: LoanMonitoring;
  loanMonitoringUpdateByFilter: LoanMonitoringManyResponse;
  metricCreate: Metric;
  metricCreateMany: MetricManyResponse;
  metricDelete?: Maybe<SuccessResponse>;
  metricDeleteByFilter?: Maybe<SuccessResponse>;
  metricDestroy?: Maybe<SuccessResponse>;
  metricDestroyByFilter?: Maybe<SuccessResponse>;
  metricRestore: Metric;
  metricUpdate: Metric;
  metricUpdateByFilter: MetricManyResponse;
  metricValueCreate: MetricValue;
  metricValueCreateMany: MetricValueManyResponse;
  metricValueDelete?: Maybe<SuccessResponse>;
  metricValueDeleteByFilter?: Maybe<SuccessResponse>;
  metricValueDestroy?: Maybe<SuccessResponse>;
  metricValueDestroyByFilter?: Maybe<SuccessResponse>;
  metricValueRestore: MetricValue;
  metricValueUpdate: MetricValue;
  metricValueUpdateByFilter: MetricValueManyResponse;
  noteCreate: Note;
  noteCreateMany: NoteManyResponse;
  noteDelete?: Maybe<SuccessResponse>;
  noteDeleteByFilter?: Maybe<SuccessResponse>;
  noteDestroy?: Maybe<SuccessResponse>;
  noteDestroyByFilter?: Maybe<SuccessResponse>;
  noteRestore: Note;
  noteUpdate: Note;
  noteUpdateByFilter: NoteManyResponse;
  plaidIntegrationAccountCreate: PlaidIntegrationAccount;
  plaidIntegrationAccountCreateMany: PlaidIntegrationAccountManyResponse;
  plaidIntegrationAccountDelete?: Maybe<SuccessResponse>;
  plaidIntegrationAccountDeleteByFilter?: Maybe<SuccessResponse>;
  plaidIntegrationAccountDestroy?: Maybe<SuccessResponse>;
  plaidIntegrationAccountDestroyByFilter?: Maybe<SuccessResponse>;
  plaidIntegrationAccountRestore: PlaidIntegrationAccount;
  plaidIntegrationAccountUpdate: PlaidIntegrationAccount;
  plaidIntegrationAccountUpdateByFilter: PlaidIntegrationAccountManyResponse;
  plaidIntegrationCreate: PlaidIntegration;
  plaidIntegrationCreateMany: PlaidIntegrationManyResponse;
  plaidIntegrationDelete?: Maybe<SuccessResponse>;
  plaidIntegrationDeleteByFilter?: Maybe<SuccessResponse>;
  plaidIntegrationDestroy?: Maybe<SuccessResponse>;
  plaidIntegrationDestroyByFilter?: Maybe<SuccessResponse>;
  plaidIntegrationRestore: PlaidIntegration;
  plaidIntegrationUpdate: PlaidIntegration;
  plaidIntegrationUpdateByFilter: PlaidIntegrationManyResponse;
  portfolioCompanyCreate: PortfolioCompany;
  portfolioCompanyCreateMany: PortfolioCompanyManyResponse;
  portfolioCompanyDelete?: Maybe<SuccessResponse>;
  portfolioCompanyDeleteByFilter?: Maybe<SuccessResponse>;
  portfolioCompanyDestroy?: Maybe<SuccessResponse>;
  portfolioCompanyDestroyByFilter?: Maybe<SuccessResponse>;
  portfolioCompanyRestore: PortfolioCompany;
  portfolioCompanyUpdate: PortfolioCompany;
  portfolioCompanyUpdateByFilter: PortfolioCompanyManyResponse;
  /** @deprecated No longer supported. Use `system.prepareDeploy` instead. */
  prepareDeploy: DeployDataResponse;
  progressCompanyStepCreate: ProgressCompanyStep;
  progressCompanyStepCreateMany: ProgressCompanyStepManyResponse;
  progressCompanyStepDelete?: Maybe<SuccessResponse>;
  progressCompanyStepDeleteByFilter?: Maybe<SuccessResponse>;
  progressCompanyStepDestroy?: Maybe<SuccessResponse>;
  progressCompanyStepDestroyByFilter?: Maybe<SuccessResponse>;
  progressCompanyStepRestore: ProgressCompanyStep;
  progressCompanyStepUpdate: ProgressCompanyStep;
  progressCompanyStepUpdateByFilter: ProgressCompanyStepManyResponse;
  progressStageCreate: ProgressStage;
  progressStageCreateMany: ProgressStageManyResponse;
  progressStageDelete?: Maybe<SuccessResponse>;
  progressStageDeleteByFilter?: Maybe<SuccessResponse>;
  progressStageDestroy?: Maybe<SuccessResponse>;
  progressStageDestroyByFilter?: Maybe<SuccessResponse>;
  progressStageRestore: ProgressStage;
  progressStageUpdate: ProgressStage;
  progressStageUpdateByFilter: ProgressStageManyResponse;
  progressStepCreate: ProgressStep;
  progressStepCreateMany: ProgressStepManyResponse;
  progressStepDelete?: Maybe<SuccessResponse>;
  progressStepDeleteByFilter?: Maybe<SuccessResponse>;
  progressStepDestroy?: Maybe<SuccessResponse>;
  progressStepDestroyByFilter?: Maybe<SuccessResponse>;
  progressStepRestore: ProgressStep;
  progressStepUpdate: ProgressStep;
  progressStepUpdateByFilter: ProgressStepManyResponse;
  progressStepValueCreate: ProgressStepValue;
  progressStepValueCreateMany: ProgressStepValueManyResponse;
  progressStepValueDelete?: Maybe<SuccessResponse>;
  progressStepValueDeleteByFilter?: Maybe<SuccessResponse>;
  progressStepValueDestroy?: Maybe<SuccessResponse>;
  progressStepValueDestroyByFilter?: Maybe<SuccessResponse>;
  progressStepValueRestore: ProgressStepValue;
  progressStepValueUpdate: ProgressStepValue;
  progressStepValueUpdateByFilter: ProgressStepValueManyResponse;
  quickBooksIntegrationCreate: QuickBooksIntegration;
  quickBooksIntegrationCreateMany: QuickBooksIntegrationManyResponse;
  quickBooksIntegrationDelete?: Maybe<SuccessResponse>;
  quickBooksIntegrationDeleteByFilter?: Maybe<SuccessResponse>;
  quickBooksIntegrationDestroy?: Maybe<SuccessResponse>;
  quickBooksIntegrationDestroyByFilter?: Maybe<SuccessResponse>;
  quickBooksIntegrationRestore: QuickBooksIntegration;
  quickBooksIntegrationUpdate: QuickBooksIntegration;
  quickBooksIntegrationUpdateByFilter: QuickBooksIntegrationManyResponse;
  roleCreate: Role;
  roleCreateMany: RoleManyResponse;
  roleDelete?: Maybe<SuccessResponse>;
  roleDeleteByFilter?: Maybe<SuccessResponse>;
  roleDestroy?: Maybe<SuccessResponse>;
  roleDestroyByFilter?: Maybe<SuccessResponse>;
  roleRestore: Role;
  roleUpdate: Role;
  roleUpdateByFilter: RoleManyResponse;
  sendInvitationTo8base?: Maybe<SuccessResponse>;
  settingsUpdate: Setting;
  sortableLoanApplicationUpdateByFilter: SortableLoanApplicationManyResponse;
  sortablePortfolioCompanyUpdateByFilter: SortablePortfolioCompanyManyResponse;
  stripeIntegrationCreate: StripeIntegration;
  stripeIntegrationCreateMany: StripeIntegrationManyResponse;
  stripeIntegrationDelete?: Maybe<SuccessResponse>;
  stripeIntegrationDeleteByFilter?: Maybe<SuccessResponse>;
  stripeIntegrationDestroy?: Maybe<SuccessResponse>;
  stripeIntegrationDestroyByFilter?: Maybe<SuccessResponse>;
  stripeIntegrationRestore: StripeIntegration;
  stripeIntegrationUpdate: StripeIntegration;
  stripeIntegrationUpdateByFilter: StripeIntegrationManyResponse;
  system?: Maybe<SystemMutation>;
  /** @deprecated No longer supported. Use `system.tableCreate` instead. */
  tableCreate: Table;
  /** @deprecated No longer supported. Use `system.tableDelete` instead. */
  tableDelete: SuccessResponse;
  /** @deprecated No longer supported. Use `system.tableUpdate` instead. */
  tableUpdate: Table;
  teamInvitationDelete?: Maybe<SuccessResponse>;
  teamInvitationDeleteByFilter?: Maybe<SuccessResponse>;
  teamInvitationDestroy?: Maybe<SuccessResponse>;
  teamInvitationDestroyByFilter?: Maybe<SuccessResponse>;
  teamInvitationRestore: TeamInvitation;
  teamMemberDelete?: Maybe<SuccessResponse>;
  teamMemberDeleteByFilter?: Maybe<SuccessResponse>;
  teamMemberDestroy?: Maybe<SuccessResponse>;
  teamMemberDestroyByFilter?: Maybe<SuccessResponse>;
  teamMemberRestore: TeamMember;
  teamMemberUpdate: TeamMember;
  teamMemberUpdateByFilter: TeamMemberManyResponse;
  userChangePassword?: Maybe<SuccessResponse>;
  userCreate: User;
  userCreateMany: UserManyResponse;
  userDelete?: Maybe<SuccessResponse>;
  userDeleteByFilter?: Maybe<SuccessResponse>;
  userDestroy?: Maybe<SuccessResponse>;
  userDestroyByFilter?: Maybe<SuccessResponse>;
  userLogin?: Maybe<LoginResponse>;
  userPasswordForgot?: Maybe<SuccessResponse>;
  userPasswordForgotConfirm?: Maybe<SuccessResponse>;
  userRefreshToken?: Maybe<Auth>;
  userRestore: User;
  /** @deprecated No longer supported. Use `userSignUpWithToken` instead. */
  userSignUp: User;
  /** @deprecated No longer supported. Use `userVerificationEmailResend` instead. */
  userSignUpResend?: Maybe<SuccessResponse>;
  userSignUpWithPassword: User;
  userSignUpWithToken: User;
  userUpdate: User;
  userUpdateByFilter: UserManyResponse;
  userVerificationEmailResend?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.viewCreate` instead. */
  viewCreate: Table;
  viewUpdate: Table;
  /** @deprecated No longer supported. Use `system.workspaceCreate` instead. */
  workspaceCreate?: Maybe<WorkspaceCreateResponse>;
  /** @deprecated No longer supported. Use `system.workspaceCreateAsync` instead. */
  workspaceCreateAsync?: Maybe<WorkspaceCreateResponse>;
  /** @deprecated No longer supported. Use `system.workspaceDelete` instead. */
  workspaceDelete?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.workspaceLeave` instead. */
  workspaceLeave?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.workspaceUpdate` instead. */
  workspaceUpdate?: Maybe<WorkspaceUpdateResponse>;
  xeroIntegrationCreate: XeroIntegration;
  xeroIntegrationCreateMany: XeroIntegrationManyResponse;
  xeroIntegrationDelete?: Maybe<SuccessResponse>;
  xeroIntegrationDeleteByFilter?: Maybe<SuccessResponse>;
  xeroIntegrationDestroy?: Maybe<SuccessResponse>;
  xeroIntegrationDestroyByFilter?: Maybe<SuccessResponse>;
  xeroIntegrationRestore: XeroIntegration;
  xeroIntegrationUpdate: XeroIntegration;
  xeroIntegrationUpdateByFilter: XeroIntegrationManyResponse;
  eventResolver?: Maybe<EventResolverResponse>;
  downloadPdf?: Maybe<DownloadPdfResponse>;
  calculateMetrics?: Maybe<SuccessResponse>;
  calculateSaaSScoreComponents?: Maybe<SuccessResponse>;
  calculateSaaSScore?: Maybe<SuccessResponse>;
  metricValueUpsert?: Maybe<MetricValueUpsert>;
  metricValueUpsertMany?: Maybe<SuccessResponse>;
  plaidGetLinkToken?: Maybe<PlaidGetLinkTokenResult>;
  plaidSaveToken?: Maybe<SuccessResponse>;
  plaidDeleteToken?: Maybe<SuccessResponse>;
  plaidUpdateToken?: Maybe<SuccessResponse>;
  plaidRequestMetricsUpdate?: Maybe<PlaidRequestMetricsUpdateResult>;
  stripeGetAuthUrl?: Maybe<StripeGetAuthUrlResult>;
  stripeDeleteToken?: Maybe<SuccessResponse>;
  quickBooksGetAuthUrl?: Maybe<QuickBooksGetAuthUrlResult>;
  quickBooksDeleteToken?: Maybe<SuccessResponse>;
  quickBooksRequestMetricsUpdate?: Maybe<QuickBooksRequestMetricsUpdateResult>;
  recalculateTotalAmountDeployedFacility?: Maybe<TotalAmountDeployedResult>;
  xeroGetAuthUrl?: Maybe<XeroGetAuthUrlResult>;
  xeroDeleteToken?: Maybe<SuccessResponse>;
  xeroRequestMetricsUpdate?: Maybe<XeroRequestMetricsUpdateResult>;
  tableExport?: Maybe<TableExportResponse>;
  getStatementsMetricsMutation?: Maybe<StatementsMetricsResponse>;
  updateMetricSettings?: Maybe<SuccessResponse>;
  createGreetingInboxMessage?: Maybe<SuccessResponse>;
};

export type MutationApiTokenCreateArgs = {
  data: ApiTokenCreateInput;
};

export type MutationApiTokenDeleteArgs = {
  data?: Maybe<ApiTokenDeleteInput>;
  filter?: Maybe<ApiTokenKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenDeleteByFilterArgs = {
  filter: ApiTokenFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenDestroyArgs = {
  filter?: Maybe<ApiTokenKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenDestroyByFilterArgs = {
  filter: ApiTokenFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenRestoreArgs = {
  id: Scalars['String'];
};

export type MutationApiTokenUpdateArgs = {
  data: ApiTokenUpdateInput;
  filter?: Maybe<ApiTokenKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenUpdateByFilterArgs = {
  data: ApiTokenUpdateByFilterInput;
  filter?: Maybe<ApiTokenFilter>;
};

export type MutationApplicationDeleteArgs = {
  data: ApplicationDeleteMutationInput;
};

export type MutationApplicationInstallArgs = {
  data: ApplicationInstallInput;
};

export type MutationApplicationUpdateArgs = {
  data: ApplicationUpdateInput;
};

export type MutationAsyncTaskCreateArgs = {
  data: AsyncTaskCreateInput;
};

export type MutationAsyncTaskCreateManyArgs = {
  data: Array<Maybe<AsyncTaskCreateManyInput>>;
};

export type MutationAsyncTaskDeleteArgs = {
  data?: Maybe<AsyncTaskDeleteInput>;
  filter?: Maybe<AsyncTaskKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAsyncTaskDeleteByFilterArgs = {
  filter: AsyncTaskFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAsyncTaskDestroyArgs = {
  filter?: Maybe<AsyncTaskKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAsyncTaskDestroyByFilterArgs = {
  filter: AsyncTaskFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAsyncTaskRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAsyncTaskUpdateArgs = {
  data: AsyncTaskUpdateInput;
  filter?: Maybe<AsyncTaskKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationAsyncTaskUpdateByFilterArgs = {
  data: AsyncTaskUpdateByFilterInput;
  filter?: Maybe<AsyncTaskFilter>;
};

export type MutationAuthenticationProfileCreateArgs = {
  data: AuthenticationProfileCreateInput;
};

export type MutationAuthenticationProfileCreateManyArgs = {
  data: Array<Maybe<AuthenticationProfileCreateManyInput>>;
};

export type MutationAuthenticationProfileDeleteArgs = {
  data?: Maybe<AuthenticationProfileDeleteInput>;
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDeleteByFilterArgs = {
  filter: AuthenticationProfileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDestroyArgs = {
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDestroyByFilterArgs = {
  filter: AuthenticationProfileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAuthenticationProfileUpdateArgs = {
  data: AuthenticationProfileUpdateInput;
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileUpdateByFilterArgs = {
  data: AuthenticationProfileUpdateByFilterInput;
  filter?: Maybe<AuthenticationProfileFilter>;
};

export type MutationAuthenticationSettingsUpdateArgs = {
  data: AuthenticationSettingUpdateInput;
};

export type MutationBillingDetailsUpdateArgs = {
  data: BillingDetailsUpdateMutationInput;
};

export type MutationBillingPlanUpdateArgs = {
  data: BillingPlanUpdateMutationInput;
};

export type MutationCashMonitoringRecordCreateArgs = {
  data: CashMonitoringRecordCreateInput;
};

export type MutationCashMonitoringRecordCreateManyArgs = {
  data: Array<Maybe<CashMonitoringRecordCreateManyInput>>;
};

export type MutationCashMonitoringRecordDeleteArgs = {
  data?: Maybe<CashMonitoringRecordDeleteInput>;
  filter?: Maybe<CashMonitoringRecordKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCashMonitoringRecordDeleteByFilterArgs = {
  filter: CashMonitoringRecordFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCashMonitoringRecordDestroyArgs = {
  filter?: Maybe<CashMonitoringRecordKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCashMonitoringRecordDestroyByFilterArgs = {
  filter: CashMonitoringRecordFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCashMonitoringRecordRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCashMonitoringRecordUpdateArgs = {
  data: CashMonitoringRecordUpdateInput;
  filter?: Maybe<CashMonitoringRecordKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCashMonitoringRecordUpdateByFilterArgs = {
  data: CashMonitoringRecordUpdateByFilterInput;
  filter?: Maybe<CashMonitoringRecordFilter>;
};

export type MutationCiCdMigrationCreateArgs = {
  data: CiCdMigrationCreateInput;
};

export type MutationCiCdMigrationCreateManyArgs = {
  data: Array<Maybe<CiCdMigrationCreateManyInput>>;
};

export type MutationCiCdMigrationDeleteArgs = {
  data?: Maybe<CiCdMigrationDeleteInput>;
  filter?: Maybe<CiCdMigrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDeleteByFilterArgs = {
  filter: CiCdMigrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDestroyArgs = {
  filter?: Maybe<CiCdMigrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDestroyByFilterArgs = {
  filter: CiCdMigrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCiCdMigrationUpdateArgs = {
  data: CiCdMigrationUpdateInput;
  filter?: Maybe<CiCdMigrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationUpdateByFilterArgs = {
  data: CiCdMigrationUpdateByFilterInput;
  filter?: Maybe<CiCdMigrationFilter>;
};

export type MutationCompanyCreateArgs = {
  data: CompanyCreateInput;
};

export type MutationCompanyCreateManyArgs = {
  data: Array<Maybe<CompanyCreateManyInput>>;
};

export type MutationCompanyDeleteArgs = {
  data?: Maybe<CompanyDeleteInput>;
  filter?: Maybe<CompanyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyDeleteByFilterArgs = {
  filter: CompanyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyDestroyArgs = {
  filter?: Maybe<CompanyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyDestroyByFilterArgs = {
  filter: CompanyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyMonitoringCreateArgs = {
  data: CompanyMonitoringCreateInput;
};

export type MutationCompanyMonitoringCreateManyArgs = {
  data: Array<Maybe<CompanyMonitoringCreateManyInput>>;
};

export type MutationCompanyMonitoringDeleteArgs = {
  data?: Maybe<CompanyMonitoringDeleteInput>;
  filter?: Maybe<CompanyMonitoringKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyMonitoringDeleteByFilterArgs = {
  filter: CompanyMonitoringFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyMonitoringDestroyArgs = {
  filter?: Maybe<CompanyMonitoringKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyMonitoringDestroyByFilterArgs = {
  filter: CompanyMonitoringFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyMonitoringRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCompanyMonitoringUpdateArgs = {
  data: CompanyMonitoringUpdateInput;
  filter?: Maybe<CompanyMonitoringKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyMonitoringUpdateByFilterArgs = {
  data: CompanyMonitoringUpdateByFilterInput;
  filter?: Maybe<CompanyMonitoringFilter>;
};

export type MutationCompanyRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCompanyUpdateArgs = {
  data: CompanyUpdateInput;
  filter?: Maybe<CompanyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCompanyUpdateByFilterArgs = {
  data: CompanyUpdateByFilterInput;
  filter?: Maybe<CompanyFilter>;
};

export type MutationCovenantCreateArgs = {
  data: CovenantCreateInput;
};

export type MutationCovenantCreateManyArgs = {
  data: Array<Maybe<CovenantCreateManyInput>>;
};

export type MutationCovenantDeleteArgs = {
  data?: Maybe<CovenantDeleteInput>;
  filter?: Maybe<CovenantKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantDeleteByFilterArgs = {
  filter: CovenantFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantDestroyArgs = {
  filter?: Maybe<CovenantKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantDestroyByFilterArgs = {
  filter: CovenantFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCovenantUpdateArgs = {
  data: CovenantUpdateInput;
  filter?: Maybe<CovenantKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantUpdateByFilterArgs = {
  data: CovenantUpdateByFilterInput;
  filter?: Maybe<CovenantFilter>;
};

export type MutationCovenantValueCreateArgs = {
  data: CovenantValueCreateInput;
};

export type MutationCovenantValueCreateManyArgs = {
  data: Array<Maybe<CovenantValueCreateManyInput>>;
};

export type MutationCovenantValueDeleteArgs = {
  data?: Maybe<CovenantValueDeleteInput>;
  filter?: Maybe<CovenantValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantValueDeleteByFilterArgs = {
  filter: CovenantValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantValueDestroyArgs = {
  filter?: Maybe<CovenantValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantValueDestroyByFilterArgs = {
  filter: CovenantValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantValueRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCovenantValueUpdateArgs = {
  data: CovenantValueUpdateInput;
  filter?: Maybe<CovenantValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCovenantValueUpdateByFilterArgs = {
  data: CovenantValueUpdateByFilterInput;
  filter?: Maybe<CovenantValueFilter>;
};

export type MutationCustomCovenantValueCreateArgs = {
  data: CustomCovenantValueCreateInput;
};

export type MutationCustomCovenantValueCreateManyArgs = {
  data: Array<Maybe<CustomCovenantValueCreateManyInput>>;
};

export type MutationCustomCovenantValueDeleteArgs = {
  data?: Maybe<CustomCovenantValueDeleteInput>;
  filter?: Maybe<CustomCovenantValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomCovenantValueDeleteByFilterArgs = {
  filter: CustomCovenantValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomCovenantValueDestroyArgs = {
  filter?: Maybe<CustomCovenantValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomCovenantValueDestroyByFilterArgs = {
  filter: CustomCovenantValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomCovenantValueRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCustomCovenantValueUpdateArgs = {
  data: CustomCovenantValueUpdateInput;
  filter?: Maybe<CustomCovenantValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomCovenantValueUpdateByFilterArgs = {
  data: CustomCovenantValueUpdateByFilterInput;
  filter?: Maybe<CustomCovenantValueFilter>;
};

export type MutationDeployArgs = {
  data?: Maybe<DeployingBuildInput>;
};

export type MutationDocumentCreateArgs = {
  data: DocumentCreateInput;
};

export type MutationDocumentCreateManyArgs = {
  data: Array<Maybe<DocumentCreateManyInput>>;
};

export type MutationDocumentDeleteArgs = {
  data?: Maybe<DocumentDeleteInput>;
  filter?: Maybe<DocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDeleteByFilterArgs = {
  filter: DocumentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDestroyArgs = {
  filter?: Maybe<DocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDestroyByFilterArgs = {
  filter: DocumentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDocumentUpdateArgs = {
  data: DocumentUpdateInput;
  filter?: Maybe<DocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentUpdateByFilterArgs = {
  data: DocumentUpdateByFilterInput;
  filter?: Maybe<DocumentFilter>;
};

export type MutationDrawCreateArgs = {
  data: DrawCreateInput;
};

export type MutationDrawCreateManyArgs = {
  data: Array<Maybe<DrawCreateManyInput>>;
};

export type MutationDrawDeleteArgs = {
  data?: Maybe<DrawDeleteInput>;
  filter?: Maybe<DrawKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawDeleteByFilterArgs = {
  filter: DrawFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawDestroyArgs = {
  filter?: Maybe<DrawKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawDestroyByFilterArgs = {
  filter: DrawFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryCreateArgs = {
  data: DrawHistoryCreateInput;
};

export type MutationDrawHistoryCreateManyArgs = {
  data: Array<Maybe<DrawHistoryCreateManyInput>>;
};

export type MutationDrawHistoryDeleteArgs = {
  data?: Maybe<DrawHistoryDeleteInput>;
  filter?: Maybe<DrawHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryDeleteByFilterArgs = {
  filter: DrawHistoryFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryDestroyArgs = {
  filter?: Maybe<DrawHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryDestroyByFilterArgs = {
  filter: DrawHistoryFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDrawHistoryUpdateArgs = {
  data: DrawHistoryUpdateInput;
  filter?: Maybe<DrawHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryValueCreateArgs = {
  data: DrawHistoryValueCreateInput;
};

export type MutationDrawHistoryValueCreateManyArgs = {
  data: Array<Maybe<DrawHistoryValueCreateManyInput>>;
};

export type MutationDrawHistoryValueDeleteArgs = {
  data?: Maybe<DrawHistoryValueDeleteInput>;
  filter?: Maybe<DrawHistoryValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryValueDeleteByFilterArgs = {
  filter: DrawHistoryValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryValueDestroyArgs = {
  filter?: Maybe<DrawHistoryValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryValueDestroyByFilterArgs = {
  filter: DrawHistoryValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryValueRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDrawHistoryValueUpdateArgs = {
  data: DrawHistoryValueUpdateInput;
  filter?: Maybe<DrawHistoryValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawHistoryValueUpdateByFilterArgs = {
  data: DrawHistoryValueUpdateByFilterInput;
  filter?: Maybe<DrawHistoryValueFilter>;
};

export type MutationDrawRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDrawUpdateArgs = {
  data: DrawUpdateInput;
  filter?: Maybe<DrawKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationDrawUpdateByFilterArgs = {
  data: DrawUpdateByFilterInput;
  filter?: Maybe<DrawFilter>;
};

export type MutationEnvironmentVariableCreateArgs = {
  data: EnvironmentVariableCreateInput;
};

export type MutationEnvironmentVariableCreateManyArgs = {
  data: Array<Maybe<EnvironmentVariableCreateManyInput>>;
};

export type MutationEnvironmentVariableDeleteArgs = {
  data?: Maybe<EnvironmentVariableDeleteInput>;
  filter?: Maybe<EnvironmentVariableKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDeleteByFilterArgs = {
  filter: EnvironmentVariableFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDestroyArgs = {
  filter?: Maybe<EnvironmentVariableKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDestroyByFilterArgs = {
  filter: EnvironmentVariableFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEnvironmentVariableUpdateArgs = {
  data: EnvironmentVariableUpdateInput;
  filter?: Maybe<EnvironmentVariableKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableUpdateByFilterArgs = {
  data: EnvironmentVariableUpdateByFilterInput;
  filter?: Maybe<EnvironmentVariableFilter>;
};

export type MutationFacilityCreateArgs = {
  data: FacilityCreateInput;
};

export type MutationFacilityCreateManyArgs = {
  data: Array<Maybe<FacilityCreateManyInput>>;
};

export type MutationFacilityDeleteArgs = {
  data?: Maybe<FacilityDeleteInput>;
  filter?: Maybe<FacilityKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityDeleteByFilterArgs = {
  filter: FacilityFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityDestroyArgs = {
  filter?: Maybe<FacilityKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityDestroyByFilterArgs = {
  filter: FacilityFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryCreateArgs = {
  data: FacilityHistoryCreateInput;
};

export type MutationFacilityHistoryCreateManyArgs = {
  data: Array<Maybe<FacilityHistoryCreateManyInput>>;
};

export type MutationFacilityHistoryDeleteArgs = {
  data?: Maybe<FacilityHistoryDeleteInput>;
  filter?: Maybe<FacilityHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryDeleteByFilterArgs = {
  filter: FacilityHistoryFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryDestroyArgs = {
  filter?: Maybe<FacilityHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryDestroyByFilterArgs = {
  filter: FacilityHistoryFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFacilityHistoryUpdateArgs = {
  data: FacilityHistoryUpdateInput;
  filter?: Maybe<FacilityHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryValueCreateArgs = {
  data: FacilityHistoryValueCreateInput;
};

export type MutationFacilityHistoryValueCreateManyArgs = {
  data: Array<Maybe<FacilityHistoryValueCreateManyInput>>;
};

export type MutationFacilityHistoryValueDeleteArgs = {
  data?: Maybe<FacilityHistoryValueDeleteInput>;
  filter?: Maybe<FacilityHistoryValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryValueDeleteByFilterArgs = {
  filter: FacilityHistoryValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryValueDestroyArgs = {
  filter?: Maybe<FacilityHistoryValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryValueDestroyByFilterArgs = {
  filter: FacilityHistoryValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryValueRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFacilityHistoryValueUpdateArgs = {
  data: FacilityHistoryValueUpdateInput;
  filter?: Maybe<FacilityHistoryValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityHistoryValueUpdateByFilterArgs = {
  data: FacilityHistoryValueUpdateByFilterInput;
  filter?: Maybe<FacilityHistoryValueFilter>;
};

export type MutationFacilityRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFacilityUpdateArgs = {
  data: FacilityUpdateInput;
  filter?: Maybe<FacilityKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationFacilityUpdateByFilterArgs = {
  data: FacilityUpdateByFilterInput;
  filter?: Maybe<FacilityFilter>;
};

export type MutationFieldCreateArgs = {
  data: TableFieldCreateInput;
};

export type MutationFieldDeleteArgs = {
  data: TableFieldDeleteInput;
};

export type MutationFieldUpdateArgs = {
  data: TableFieldUpdateInput;
};

export type MutationFieldUpdatePositionArgs = {
  data: TableFieldPositionUpdateInput;
};

export type MutationFileCreateArgs = {
  data: FileCreateInput;
};

export type MutationFileCreateManyArgs = {
  data: Array<Maybe<FileCreateManyInput>>;
};

export type MutationFileDeleteArgs = {
  data?: Maybe<FileDeleteInput>;
  filter?: Maybe<FileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileDeleteByFilterArgs = {
  filter: FileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileDestroyArgs = {
  filter?: Maybe<FileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileDestroyByFilterArgs = {
  filter: FileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFileUpdateArgs = {
  data: FileUpdateInput;
  filter?: Maybe<FileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationFileUpdateByFilterArgs = {
  data: FileUpdateByFilterInput;
  filter?: Maybe<FileFilter>;
};

export type MutationInboxMessageCreateArgs = {
  data: InboxMessageCreateInput;
};

export type MutationInboxMessageCreateManyArgs = {
  data: Array<Maybe<InboxMessageCreateManyInput>>;
};

export type MutationInboxMessageDeleteArgs = {
  data?: Maybe<InboxMessageDeleteInput>;
  filter?: Maybe<InboxMessageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInboxMessageDeleteByFilterArgs = {
  filter: InboxMessageFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInboxMessageDestroyArgs = {
  filter?: Maybe<InboxMessageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInboxMessageDestroyByFilterArgs = {
  filter: InboxMessageFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInboxMessageRestoreArgs = {
  id: Scalars['String'];
};

export type MutationInboxMessageUpdateArgs = {
  data: InboxMessageUpdateInput;
  filter?: Maybe<InboxMessageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationInboxMessageUpdateByFilterArgs = {
  data: InboxMessageUpdateByFilterInput;
  filter?: Maybe<InboxMessageFilter>;
};

export type MutationIndexCreateArgs = {
  data: IndexCreateInput;
};

export type MutationIndexDeleteArgs = {
  data: IndexDeleteInput;
};

export type MutationIndexUpdateArgs = {
  data: IndexUpdateInput;
};

export type MutationIntegrationCreateArgs = {
  data: IntegrationCreateInput;
};

export type MutationIntegrationCreateManyArgs = {
  data: Array<Maybe<IntegrationCreateManyInput>>;
};

export type MutationIntegrationDeleteArgs = {
  data?: Maybe<IntegrationDeleteInput>;
  filter?: Maybe<IntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationIntegrationDeleteByFilterArgs = {
  filter: IntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationIntegrationDestroyArgs = {
  filter?: Maybe<IntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationIntegrationDestroyByFilterArgs = {
  filter: IntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationIntegrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationIntegrationUpdateArgs = {
  data: IntegrationUpdateInput;
  filter?: Maybe<IntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationInvitationCreateArgs = {
  data: InvitationCreateInput;
};

export type MutationInvitationCreateManyArgs = {
  data: Array<Maybe<InvitationCreateManyInput>>;
};

export type MutationInvitationDeleteArgs = {
  data?: Maybe<InvitationDeleteInput>;
  filter?: Maybe<InvitationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInvitationDeleteByFilterArgs = {
  filter: InvitationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInvitationDestroyArgs = {
  filter?: Maybe<InvitationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInvitationDestroyByFilterArgs = {
  filter: InvitationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationInvitationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationInvitationUpdateArgs = {
  data: InvitationUpdateInput;
  filter?: Maybe<InvitationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationInvitationUpdateByFilterArgs = {
  data: InvitationUpdateByFilterInput;
  filter?: Maybe<InvitationFilter>;
};

export type MutationInvokeArgs = {
  data?: Maybe<InvokeData>;
};

export type MutationLoanApplicationCreateArgs = {
  data: LoanApplicationCreateInput;
};

export type MutationLoanApplicationCreateManyArgs = {
  data: Array<Maybe<LoanApplicationCreateManyInput>>;
};

export type MutationLoanApplicationDeleteArgs = {
  data?: Maybe<LoanApplicationDeleteInput>;
  filter?: Maybe<LoanApplicationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanApplicationDeleteByFilterArgs = {
  filter: LoanApplicationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanApplicationDestroyArgs = {
  filter?: Maybe<LoanApplicationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanApplicationDestroyByFilterArgs = {
  filter: LoanApplicationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanApplicationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationLoanApplicationUpdateArgs = {
  data: LoanApplicationUpdateInput;
  filter?: Maybe<LoanApplicationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanApplicationUpdateByFilterArgs = {
  data: LoanApplicationUpdateByFilterInput;
  filter?: Maybe<LoanApplicationFilter>;
};

export type MutationLoanMonitoringCreateArgs = {
  data: LoanMonitoringCreateInput;
};

export type MutationLoanMonitoringCreateManyArgs = {
  data: Array<Maybe<LoanMonitoringCreateManyInput>>;
};

export type MutationLoanMonitoringDeleteArgs = {
  data?: Maybe<LoanMonitoringDeleteInput>;
  filter?: Maybe<LoanMonitoringKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanMonitoringDeleteByFilterArgs = {
  filter: LoanMonitoringFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanMonitoringDestroyArgs = {
  filter?: Maybe<LoanMonitoringKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanMonitoringDestroyByFilterArgs = {
  filter: LoanMonitoringFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanMonitoringRestoreArgs = {
  id: Scalars['String'];
};

export type MutationLoanMonitoringUpdateArgs = {
  data: LoanMonitoringUpdateInput;
  filter?: Maybe<LoanMonitoringKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationLoanMonitoringUpdateByFilterArgs = {
  data: LoanMonitoringUpdateByFilterInput;
  filter?: Maybe<LoanMonitoringFilter>;
};

export type MutationMetricCreateArgs = {
  data: MetricCreateInput;
};

export type MutationMetricCreateManyArgs = {
  data: Array<Maybe<MetricCreateManyInput>>;
};

export type MutationMetricDeleteArgs = {
  data?: Maybe<MetricDeleteInput>;
  filter?: Maybe<MetricKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricDeleteByFilterArgs = {
  filter: MetricFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricDestroyArgs = {
  filter?: Maybe<MetricKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricDestroyByFilterArgs = {
  filter: MetricFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricRestoreArgs = {
  id: Scalars['String'];
};

export type MutationMetricUpdateArgs = {
  data: MetricUpdateInput;
  filter?: Maybe<MetricKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricUpdateByFilterArgs = {
  data: MetricUpdateByFilterInput;
  filter?: Maybe<MetricFilter>;
};

export type MutationMetricValueCreateArgs = {
  data: MetricValueCreateInput;
};

export type MutationMetricValueCreateManyArgs = {
  data: Array<Maybe<MetricValueCreateManyInput>>;
};

export type MutationMetricValueDeleteArgs = {
  data?: Maybe<MetricValueDeleteInput>;
  filter?: Maybe<MetricValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricValueDeleteByFilterArgs = {
  filter: MetricValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricValueDestroyArgs = {
  filter?: Maybe<MetricValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricValueDestroyByFilterArgs = {
  filter: MetricValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricValueRestoreArgs = {
  id: Scalars['String'];
};

export type MutationMetricValueUpdateArgs = {
  data: MetricValueUpdateInput;
  filter?: Maybe<MetricValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationMetricValueUpdateByFilterArgs = {
  data: MetricValueUpdateByFilterInput;
  filter?: Maybe<MetricValueFilter>;
};

export type MutationNoteCreateArgs = {
  data: NoteCreateInput;
};

export type MutationNoteCreateManyArgs = {
  data: Array<Maybe<NoteCreateManyInput>>;
};

export type MutationNoteDeleteArgs = {
  data?: Maybe<NoteDeleteInput>;
  filter?: Maybe<NoteKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNoteDeleteByFilterArgs = {
  filter: NoteFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNoteDestroyArgs = {
  filter?: Maybe<NoteKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNoteDestroyByFilterArgs = {
  filter: NoteFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNoteRestoreArgs = {
  id: Scalars['String'];
};

export type MutationNoteUpdateArgs = {
  data: NoteUpdateInput;
  filter?: Maybe<NoteKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationNoteUpdateByFilterArgs = {
  data: NoteUpdateByFilterInput;
  filter?: Maybe<NoteFilter>;
};

export type MutationPlaidIntegrationAccountCreateArgs = {
  data: PlaidIntegrationAccountCreateInput;
};

export type MutationPlaidIntegrationAccountCreateManyArgs = {
  data: Array<Maybe<PlaidIntegrationAccountCreateManyInput>>;
};

export type MutationPlaidIntegrationAccountDeleteArgs = {
  data?: Maybe<PlaidIntegrationAccountDeleteInput>;
  filter?: Maybe<PlaidIntegrationAccountKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationAccountDeleteByFilterArgs = {
  filter: PlaidIntegrationAccountFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationAccountDestroyArgs = {
  filter?: Maybe<PlaidIntegrationAccountKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationAccountDestroyByFilterArgs = {
  filter: PlaidIntegrationAccountFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationAccountRestoreArgs = {
  id: Scalars['String'];
};

export type MutationPlaidIntegrationAccountUpdateArgs = {
  data: PlaidIntegrationAccountUpdateInput;
  filter?: Maybe<PlaidIntegrationAccountKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationAccountUpdateByFilterArgs = {
  data: PlaidIntegrationAccountUpdateByFilterInput;
  filter?: Maybe<PlaidIntegrationAccountFilter>;
};

export type MutationPlaidIntegrationCreateArgs = {
  data: PlaidIntegrationCreateInput;
};

export type MutationPlaidIntegrationCreateManyArgs = {
  data: Array<Maybe<PlaidIntegrationCreateManyInput>>;
};

export type MutationPlaidIntegrationDeleteArgs = {
  data?: Maybe<PlaidIntegrationDeleteInput>;
  filter?: Maybe<PlaidIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationDeleteByFilterArgs = {
  filter: PlaidIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationDestroyArgs = {
  filter?: Maybe<PlaidIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationDestroyByFilterArgs = {
  filter: PlaidIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationPlaidIntegrationUpdateArgs = {
  data: PlaidIntegrationUpdateInput;
  filter?: Maybe<PlaidIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationPlaidIntegrationUpdateByFilterArgs = {
  data: PlaidIntegrationUpdateByFilterInput;
  filter?: Maybe<PlaidIntegrationFilter>;
};

export type MutationPortfolioCompanyCreateArgs = {
  data: PortfolioCompanyCreateInput;
};

export type MutationPortfolioCompanyCreateManyArgs = {
  data: Array<Maybe<PortfolioCompanyCreateManyInput>>;
};

export type MutationPortfolioCompanyDeleteArgs = {
  data?: Maybe<PortfolioCompanyDeleteInput>;
  filter?: Maybe<PortfolioCompanyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPortfolioCompanyDeleteByFilterArgs = {
  filter: PortfolioCompanyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPortfolioCompanyDestroyArgs = {
  filter?: Maybe<PortfolioCompanyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPortfolioCompanyDestroyByFilterArgs = {
  filter: PortfolioCompanyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationPortfolioCompanyRestoreArgs = {
  id: Scalars['String'];
};

export type MutationPortfolioCompanyUpdateArgs = {
  data: PortfolioCompanyUpdateInput;
  filter?: Maybe<PortfolioCompanyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationPortfolioCompanyUpdateByFilterArgs = {
  data: PortfolioCompanyUpdateByFilterInput;
  filter?: Maybe<PortfolioCompanyFilter>;
};

export type MutationProgressCompanyStepCreateArgs = {
  data: ProgressCompanyStepCreateInput;
};

export type MutationProgressCompanyStepCreateManyArgs = {
  data: Array<Maybe<ProgressCompanyStepCreateManyInput>>;
};

export type MutationProgressCompanyStepDeleteArgs = {
  data?: Maybe<ProgressCompanyStepDeleteInput>;
  filter?: Maybe<ProgressCompanyStepKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressCompanyStepDeleteByFilterArgs = {
  filter: ProgressCompanyStepFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressCompanyStepDestroyArgs = {
  filter?: Maybe<ProgressCompanyStepKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressCompanyStepDestroyByFilterArgs = {
  filter: ProgressCompanyStepFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressCompanyStepRestoreArgs = {
  id: Scalars['String'];
};

export type MutationProgressCompanyStepUpdateArgs = {
  data: ProgressCompanyStepUpdateInput;
  filter?: Maybe<ProgressCompanyStepKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressCompanyStepUpdateByFilterArgs = {
  data: ProgressCompanyStepUpdateByFilterInput;
  filter?: Maybe<ProgressCompanyStepFilter>;
};

export type MutationProgressStageCreateArgs = {
  data: ProgressStageCreateInput;
};

export type MutationProgressStageCreateManyArgs = {
  data: Array<Maybe<ProgressStageCreateManyInput>>;
};

export type MutationProgressStageDeleteArgs = {
  data?: Maybe<ProgressStageDeleteInput>;
  filter?: Maybe<ProgressStageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStageDeleteByFilterArgs = {
  filter: ProgressStageFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStageDestroyArgs = {
  filter?: Maybe<ProgressStageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStageDestroyByFilterArgs = {
  filter: ProgressStageFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStageRestoreArgs = {
  id: Scalars['String'];
};

export type MutationProgressStageUpdateArgs = {
  data: ProgressStageUpdateInput;
  filter?: Maybe<ProgressStageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStageUpdateByFilterArgs = {
  data: ProgressStageUpdateByFilterInput;
  filter?: Maybe<ProgressStageFilter>;
};

export type MutationProgressStepCreateArgs = {
  data: ProgressStepCreateInput;
};

export type MutationProgressStepCreateManyArgs = {
  data: Array<Maybe<ProgressStepCreateManyInput>>;
};

export type MutationProgressStepDeleteArgs = {
  data?: Maybe<ProgressStepDeleteInput>;
  filter?: Maybe<ProgressStepKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepDeleteByFilterArgs = {
  filter: ProgressStepFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepDestroyArgs = {
  filter?: Maybe<ProgressStepKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepDestroyByFilterArgs = {
  filter: ProgressStepFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepRestoreArgs = {
  id: Scalars['String'];
};

export type MutationProgressStepUpdateArgs = {
  data: ProgressStepUpdateInput;
  filter?: Maybe<ProgressStepKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepUpdateByFilterArgs = {
  data: ProgressStepUpdateByFilterInput;
  filter?: Maybe<ProgressStepFilter>;
};

export type MutationProgressStepValueCreateArgs = {
  data: ProgressStepValueCreateInput;
};

export type MutationProgressStepValueCreateManyArgs = {
  data: Array<Maybe<ProgressStepValueCreateManyInput>>;
};

export type MutationProgressStepValueDeleteArgs = {
  data?: Maybe<ProgressStepValueDeleteInput>;
  filter?: Maybe<ProgressStepValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepValueDeleteByFilterArgs = {
  filter: ProgressStepValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepValueDestroyArgs = {
  filter?: Maybe<ProgressStepValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepValueDestroyByFilterArgs = {
  filter: ProgressStepValueFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepValueRestoreArgs = {
  id: Scalars['String'];
};

export type MutationProgressStepValueUpdateArgs = {
  data: ProgressStepValueUpdateInput;
  filter?: Maybe<ProgressStepValueKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationProgressStepValueUpdateByFilterArgs = {
  data: ProgressStepValueUpdateByFilterInput;
  filter?: Maybe<ProgressStepValueFilter>;
};

export type MutationQuickBooksIntegrationCreateArgs = {
  data: QuickBooksIntegrationCreateInput;
};

export type MutationQuickBooksIntegrationCreateManyArgs = {
  data: Array<Maybe<QuickBooksIntegrationCreateManyInput>>;
};

export type MutationQuickBooksIntegrationDeleteArgs = {
  data?: Maybe<QuickBooksIntegrationDeleteInput>;
  filter?: Maybe<QuickBooksIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationQuickBooksIntegrationDeleteByFilterArgs = {
  filter: QuickBooksIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationQuickBooksIntegrationDestroyArgs = {
  filter?: Maybe<QuickBooksIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationQuickBooksIntegrationDestroyByFilterArgs = {
  filter: QuickBooksIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationQuickBooksIntegrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationQuickBooksIntegrationUpdateArgs = {
  data: QuickBooksIntegrationUpdateInput;
  filter?: Maybe<QuickBooksIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationQuickBooksIntegrationUpdateByFilterArgs = {
  data: QuickBooksIntegrationUpdateByFilterInput;
  filter?: Maybe<QuickBooksIntegrationFilter>;
};

export type MutationRoleCreateArgs = {
  data: RoleCreateInput;
};

export type MutationRoleCreateManyArgs = {
  data: Array<Maybe<RoleCreateManyInput>>;
};

export type MutationRoleDeleteArgs = {
  data?: Maybe<RoleDeleteInput>;
  filter?: Maybe<RoleKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleDeleteByFilterArgs = {
  filter: RoleFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleDestroyArgs = {
  filter?: Maybe<RoleKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleDestroyByFilterArgs = {
  filter: RoleFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleRestoreArgs = {
  id: Scalars['String'];
};

export type MutationRoleUpdateArgs = {
  data: RoleUpdateInput;
  filter?: Maybe<RoleKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleUpdateByFilterArgs = {
  data: RoleUpdateByFilterInput;
  filter?: Maybe<RoleFilter>;
};

export type MutationSendInvitationTo8baseArgs = {
  inviteEmail: Scalars['String'];
};

export type MutationSettingsUpdateArgs = {
  data: SettingUpdateInput;
};

export type MutationSortableLoanApplicationUpdateByFilterArgs = {
  data: SortableLoanApplicationUpdateByFilterInput;
  filter?: Maybe<SortableLoanApplicationFilter>;
};

export type MutationSortablePortfolioCompanyUpdateByFilterArgs = {
  data: SortablePortfolioCompanyUpdateByFilterInput;
  filter?: Maybe<SortablePortfolioCompanyFilter>;
};

export type MutationStripeIntegrationCreateArgs = {
  data: StripeIntegrationCreateInput;
};

export type MutationStripeIntegrationCreateManyArgs = {
  data: Array<Maybe<StripeIntegrationCreateManyInput>>;
};

export type MutationStripeIntegrationDeleteArgs = {
  data?: Maybe<StripeIntegrationDeleteInput>;
  filter?: Maybe<StripeIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationStripeIntegrationDeleteByFilterArgs = {
  filter: StripeIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationStripeIntegrationDestroyArgs = {
  filter?: Maybe<StripeIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationStripeIntegrationDestroyByFilterArgs = {
  filter: StripeIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationStripeIntegrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationStripeIntegrationUpdateArgs = {
  data: StripeIntegrationUpdateInput;
  filter?: Maybe<StripeIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationStripeIntegrationUpdateByFilterArgs = {
  data: StripeIntegrationUpdateByFilterInput;
  filter?: Maybe<StripeIntegrationFilter>;
};

export type MutationTableCreateArgs = {
  data: TableCreateInput;
};

export type MutationTableDeleteArgs = {
  data: TableDeleteInput;
};

export type MutationTableUpdateArgs = {
  data: TableUpdateInput;
};

export type MutationTeamInvitationDeleteArgs = {
  data?: Maybe<TeamInvitationDeleteInput>;
  filter?: Maybe<TeamInvitationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationDeleteByFilterArgs = {
  filter: TeamInvitationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationDestroyArgs = {
  filter?: Maybe<TeamInvitationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationDestroyByFilterArgs = {
  filter: TeamInvitationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamInvitationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationTeamMemberDeleteArgs = {
  data?: Maybe<TeamMemberDeleteInput>;
  filter?: Maybe<TeamMemberKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamMemberDeleteByFilterArgs = {
  filter: TeamMemberFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamMemberDestroyArgs = {
  filter?: Maybe<TeamMemberKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamMemberDestroyByFilterArgs = {
  filter: TeamMemberFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamMemberRestoreArgs = {
  id: Scalars['String'];
};

export type MutationTeamMemberUpdateArgs = {
  data: TeamMemberUpdateInput;
  filter?: Maybe<TeamMemberKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationTeamMemberUpdateByFilterArgs = {
  data: TeamMemberUpdateByFilterInput;
  filter?: Maybe<TeamMemberFilter>;
};

export type MutationUserChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationUserCreateArgs = {
  data: UserCreateInput;
};

export type MutationUserCreateManyArgs = {
  data: Array<Maybe<UserCreateManyInput>>;
};

export type MutationUserDeleteArgs = {
  data?: Maybe<UserDeleteInput>;
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserDeleteByFilterArgs = {
  filter: UserFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserDestroyArgs = {
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserDestroyByFilterArgs = {
  filter: UserFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserLoginArgs = {
  data: UserLoginInput;
};

export type MutationUserPasswordForgotArgs = {
  data: PasswordForgotInput;
};

export type MutationUserPasswordForgotConfirmArgs = {
  data: PasswordForgotConfirmInput;
};

export type MutationUserRefreshTokenArgs = {
  data: RefreshTokenInput;
};

export type MutationUserRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserSignUpArgs = {
  user: UserCreateInput;
  authProfileId?: Maybe<Scalars['ID']>;
};

export type MutationUserSignUpResendArgs = {
  data: SignUpResendInput;
};

export type MutationUserSignUpWithPasswordArgs = {
  user: UserCreateInput;
  password: Scalars['String'];
  authProfileId?: Maybe<Scalars['ID']>;
};

export type MutationUserSignUpWithTokenArgs = {
  user: UserCreateInput;
  authProfileId?: Maybe<Scalars['ID']>;
};

export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationUserUpdateByFilterArgs = {
  data: UserUpdateByFilterInput;
  filter?: Maybe<UserFilter>;
};

export type MutationUserVerificationEmailResendArgs = {
  data: VerificationEmailResendInput;
  authProfileId?: Maybe<Scalars['ID']>;
};

export type MutationViewCreateArgs = {
  data: ViewCreateInput;
};

export type MutationViewUpdateArgs = {
  data: ViewUpdateInput;
};

export type MutationWorkspaceCreateArgs = {
  data: WorkspaceCreateMutationInput;
};

export type MutationWorkspaceCreateAsyncArgs = {
  data: WorkspaceCreateMutationInput;
};

export type MutationWorkspaceDeleteArgs = {
  data: WorkspaceDeleteMutationInput;
};

export type MutationWorkspaceLeaveArgs = {
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationWorkspaceUpdateArgs = {
  data: WorkspaceUpdateMutationInput;
};

export type MutationXeroIntegrationCreateArgs = {
  data: XeroIntegrationCreateInput;
};

export type MutationXeroIntegrationCreateManyArgs = {
  data: Array<Maybe<XeroIntegrationCreateManyInput>>;
};

export type MutationXeroIntegrationDeleteArgs = {
  data?: Maybe<XeroIntegrationDeleteInput>;
  filter?: Maybe<XeroIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationXeroIntegrationDeleteByFilterArgs = {
  filter: XeroIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationXeroIntegrationDestroyArgs = {
  filter?: Maybe<XeroIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationXeroIntegrationDestroyByFilterArgs = {
  filter: XeroIntegrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationXeroIntegrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationXeroIntegrationUpdateArgs = {
  data: XeroIntegrationUpdateInput;
  filter?: Maybe<XeroIntegrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
  destroyDetached?: Maybe<Scalars['Boolean']>;
};

export type MutationXeroIntegrationUpdateByFilterArgs = {
  data: XeroIntegrationUpdateByFilterInput;
  filter?: Maybe<XeroIntegrationFilter>;
};

export type MutationEventResolverArgs = {
  event: EventResolverInput;
};

export type MutationDownloadPdfArgs = {
  event: DownloadPdfInput;
};

export type MutationCalculateMetricsArgs = {
  companyId: Scalars['ID'];
};

export type MutationCalculateSaaSScoreComponentsArgs = {
  companyId: Scalars['ID'];
};

export type MutationCalculateSaaSScoreArgs = {
  companyId: Scalars['ID'];
};

export type MutationMetricValueUpsertArgs = {
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  date: Scalars['Date'];
  metricCode: Scalars['String'];
  period: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type MutationMetricValueUpsertManyArgs = {
  data: Array<Maybe<MetricValueCreateManyInput>>;
};

export type MutationPlaidGetLinkTokenArgs = {
  companyId: Scalars['ID'];
};

export type MutationPlaidSaveTokenArgs = {
  companyId: Scalars['ID'];
  publicToken: Scalars['String'];
  accountIds: Array<Scalars['String']>;
};

export type MutationPlaidDeleteTokenArgs = {
  plaidIntegrationId: Scalars['ID'];
};

export type MutationPlaidUpdateTokenArgs = {
  plaidIntegrationId: Scalars['ID'];
  companyId: Scalars['ID'];
  publicToken: Scalars['String'];
  accountIds: Array<Scalars['String']>;
};

export type MutationPlaidRequestMetricsUpdateArgs = {
  companyId: Scalars['ID'];
};

export type MutationStripeGetAuthUrlArgs = {
  companyId: Scalars['ID'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type MutationStripeDeleteTokenArgs = {
  stripeIntegrationId: Scalars['ID'];
};

export type MutationQuickBooksGetAuthUrlArgs = {
  companyId: Scalars['ID'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type MutationQuickBooksDeleteTokenArgs = {
  quickBooksIntegrationId: Scalars['ID'];
};

export type MutationQuickBooksRequestMetricsUpdateArgs = {
  companyId: Scalars['ID'];
};

export type MutationRecalculateTotalAmountDeployedFacilityArgs = {
  facilityId: Scalars['ID'];
};

export type MutationXeroGetAuthUrlArgs = {
  companyId: Scalars['ID'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type MutationXeroDeleteTokenArgs = {
  xeroIntegrationId: Scalars['ID'];
};

export type MutationXeroRequestMetricsUpdateArgs = {
  companyId: Scalars['ID'];
};

export type MutationTableExportArgs = {
  event: TableExportInput;
};

export type MutationGetStatementsMetricsMutationArgs = {
  event: StatementsMetricsInput;
};

export type MutationUpdateMetricSettingsArgs = {
  settings?: Maybe<Array<Maybe<MetricSettingsInput>>>;
};

export type MutationCreateGreetingInboxMessageArgs = {
  companyId: Scalars['ID'];
};

/** API Token Response */
export type ApiTokenResponse = {
  __typename?: 'ApiTokenResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  token: Scalars['String'];
};

/** API Token Response */
export type ApiTokenResponseRolesArgs = {
  filter?: Maybe<RoleFilter>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  sort?: Maybe<Array<RoleSort>>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
};

/** ApiTokens create input */
export type ApiTokenCreateInput = {
  name: Scalars['String'];
  roles?: Maybe<ApiTokensRolesRelationInput>;
};

/** ApiTokens relation input */
export type ApiTokensRolesRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<ApiTokens_RoleCreateInput>>>;
};

export type RoleKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** Roles create input from apiTokens */
export type ApiTokens_RoleCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersRelationInput>;
  permissions?: Maybe<PermissionsInput>;
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersRelationInput>;
};

/** Roles relation input */
export type RolesUsersRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_UserCreateInput>>>;
};

export type UserKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

/** Users create input from roles */
export type Roles_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** Users relation input */
export type UsersAvatarRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<Users_Avatar_FileCreateInput>;
};

export type FileKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  fileId?: Maybe<Scalars['String']>;
};

/** Files create input from users_avatar */
export type Users_Avatar_FileCreateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsRelationInput>;
};

/** Files relation input */
export type FilesUsers_AvatarRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Avatar_UserCreateInput>>>;
};

/** Users create input from avatar */
export type Avatar_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** Users relation input */
export type UsersRolesRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<Users_RoleCreateInput>>>;
};

/** Roles create input from users */
export type Users_RoleCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersRelationInput>;
  permissions?: Maybe<PermissionsInput>;
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersRelationInput>;
};

/** PermissionsInput create input */
export type PermissionsInput = {
  data?: Maybe<PermissionsData>;
  custom?: Maybe<PermissionsCustom>;
};

/** Schema tables permissions input */
export type PermissionsData = {
  Users?: Maybe<PermissionsDataUsers>;
  Files?: Maybe<PermissionsDataFiles>;
  Roles?: Maybe<PermissionsDataRoles>;
  TeamInvitations?: Maybe<PermissionsDataTeamInvitations>;
  TeamMembers?: Maybe<PermissionsDataTeamMembers>;
  CiCdMigrations?: Maybe<PermissionsDataCiCdMigrations>;
  LoanApplications?: Maybe<PermissionsDataLoanApplications>;
  Invitations?: Maybe<PermissionsDataInvitations>;
  LoanMonitorings?: Maybe<PermissionsDataLoanMonitorings>;
  Companies?: Maybe<PermissionsDataCompanies>;
  Facilities?: Maybe<PermissionsDataFacilities>;
  Documents?: Maybe<PermissionsDataDocuments>;
  Notes?: Maybe<PermissionsDataNotes>;
  Integrations?: Maybe<PermissionsDataIntegrations>;
  XeroIntegrations?: Maybe<PermissionsDataXeroIntegrations>;
  PortfolioCompanies?: Maybe<PermissionsDataPortfolioCompanies>;
  Metrics?: Maybe<PermissionsDataMetrics>;
  MetricValues?: Maybe<PermissionsDataMetricValues>;
  Draws?: Maybe<PermissionsDataDraws>;
  QuickBooksIntegration?: Maybe<PermissionsDataQuickBooksIntegration>;
  StripeIntegration?: Maybe<PermissionsDataStripeIntegration>;
  CompanyMonitoring?: Maybe<PermissionsDataCompanyMonitoring>;
  PlaidIntegration?: Maybe<PermissionsDataPlaidIntegration>;
  AsyncTasks?: Maybe<PermissionsDataAsyncTasks>;
  InboxMessages?: Maybe<PermissionsDataInboxMessages>;
  ProgressStages?: Maybe<PermissionsDataProgressStages>;
  ProgressStepValues?: Maybe<PermissionsDataProgressStepValues>;
  ProgressSteps?: Maybe<PermissionsDataProgressSteps>;
  PlaidIntegrationAccounts?: Maybe<PermissionsDataPlaidIntegrationAccounts>;
  ProgressCompanySteps?: Maybe<PermissionsDataProgressCompanySteps>;
  SortablePortfolioCompanies?: Maybe<PermissionsDataSortablePortfolioCompanies>;
  SortableLoanApplications?: Maybe<PermissionsDataSortableLoanApplications>;
  CashMonitoringRecords?: Maybe<PermissionsDataCashMonitoringRecords>;
  Covenants?: Maybe<PermissionsDataCovenants>;
  CovenantValues?: Maybe<PermissionsDataCovenantValues>;
  FacilityHistories?: Maybe<PermissionsDataFacilityHistories>;
  FacilityHistoryValues?: Maybe<PermissionsDataFacilityHistoryValues>;
  DrawHistories?: Maybe<PermissionsDataDrawHistories>;
  DrawHistoryValues?: Maybe<PermissionsDataDrawHistoryValues>;
  CustomCovenantValues?: Maybe<PermissionsDataCustomCovenantValues>;
};

export type PermissionsDataUsers = {
  create?: Maybe<PermissionsDataUsersCreate>;
  read?: Maybe<PermissionsDataUsersRead>;
  update?: Maybe<PermissionsDataUsersUpdate>;
  delete?: Maybe<PermissionsDataUsersDelete>;
  destroy?: Maybe<PermissionsDataUsersDestroy>;
};

export type PermissionsDataUsersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<User_PermissionFilter>;
  fields?: Maybe<UserFieldsPermissions>;
};

export type User_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  email?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  origin?: Maybe<StringPredicate>;
  is8base?: Maybe<BoolPredicate>;
  firstName?: Maybe<StringPredicate>;
  lastName?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  title?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  is_self?: Maybe<Scalars['Boolean']>;
  not_self?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User_PermissionFilter>;
  avatar?: Maybe<File_PermissionFilter>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  invitation?: Maybe<Invitation_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  employer?: Maybe<Company_PermissionFilter>;
  loanApplications?: Maybe<LoanApplication_PermissionFilter>;
  portfolioCompanies?: Maybe<PortfolioCompany_PermissionRelationFilter>;
  AND?: Maybe<Array<User_PermissionFilter>>;
  OR?: Maybe<Array<User_PermissionFilter>>;
};

export type File_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  fileId?: Maybe<StringPredicate>;
  downloadUrl?: Maybe<StringPredicate>;
  shareUrl?: Maybe<StringPredicate>;
  provider?: Maybe<StringPredicate>;
  public?: Maybe<BoolPredicate>;
  uploaded?: Maybe<BoolPredicate>;
  filename?: Maybe<StringPredicate>;
  uploadUrl?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  settings_menuBarLogo?: Maybe<Setting_PermissionRelationFilter>;
  settings_landingPageImage?: Maybe<Setting_PermissionRelationFilter>;
  users_avatar?: Maybe<User_PermissionRelationFilter>;
  teamMembers_avatar?: Maybe<TeamMember_PermissionRelationFilter>;
  portfolioCompanies_logo?: Maybe<Company_PermissionRelationFilter>;
  documents_file?: Maybe<Document_PermissionRelationFilter>;
  notes_attachments?: Maybe<Note_PermissionRelationFilter>;
  AND?: Maybe<Array<File_PermissionFilter>>;
  OR?: Maybe<Array<File_PermissionFilter>>;
};

export type Setting_PermissionRelationFilter = {
  some?: Maybe<Setting_PermissionFilter>;
  every?: Maybe<Setting_PermissionFilter>;
  none?: Maybe<Setting_PermissionFilter>;
};

export type Setting_PermissionFilter = {
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  passwordMinLength?: Maybe<IntPredicate>;
  passwordRequireNumbers?: Maybe<BoolPredicate>;
  passwordRequireSpecial?: Maybe<BoolPredicate>;
  passwordRequireUppercase?: Maybe<BoolPredicate>;
  passwordRequireLowercase?: Maybe<BoolPredicate>;
  passwordUpdateInterval?: Maybe<IntPredicate>;
  rememberDevice?: Maybe<StringPredicate>;
  language?: Maybe<StringPredicate>;
  dateFormat?: Maybe<StringPredicate>;
  currency?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  vanityUrl?: Maybe<StringPredicate>;
  buttonLinkColor?: Maybe<StringPredicate>;
  userInterfaceStyle?: Maybe<StringPredicate>;
  menuBarBGColor?: Maybe<StringPredicate>;
  menuBarIconsColor?: Maybe<StringPredicate>;
  bgColor?: Maybe<StringPredicate>;
  containerColor?: Maybe<StringPredicate>;
  leftNavColor?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  menuBarLogo?: Maybe<File_PermissionFilter>;
  landingPageImage?: Maybe<File_PermissionFilter>;
  AND?: Maybe<Array<Setting_PermissionFilter>>;
  OR?: Maybe<Array<Setting_PermissionFilter>>;
};

export type User_PermissionRelationFilter = {
  some?: Maybe<User_PermissionFilter>;
  every?: Maybe<User_PermissionFilter>;
  none?: Maybe<User_PermissionFilter>;
};

export type TeamMember_PermissionRelationFilter = {
  some?: Maybe<TeamMember_PermissionFilter>;
  every?: Maybe<TeamMember_PermissionFilter>;
  none?: Maybe<TeamMember_PermissionFilter>;
};

export type TeamMember_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  isOwner?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  user?: Maybe<User_PermissionFilter>;
  avatar?: Maybe<File_PermissionFilter>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  receivedTeamInvitations?: Maybe<TeamInvitation_PermissionRelationFilter>;
  sentTeamInvitations?: Maybe<TeamInvitation_PermissionRelationFilter>;
  AND?: Maybe<Array<TeamMember_PermissionFilter>>;
  OR?: Maybe<Array<TeamMember_PermissionFilter>>;
};

export type Role_PermissionRelationFilter = {
  some?: Maybe<Role_PermissionFilter>;
  every?: Maybe<Role_PermissionFilter>;
  none?: Maybe<Role_PermissionFilter>;
};

export type Role_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  description?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  users?: Maybe<User_PermissionRelationFilter>;
  permissions?: Maybe<Permission_PermissionRelationFilter>;
  apiTokens?: Maybe<ApiToken_PermissionRelationFilter>;
  authenticationProfiles?: Maybe<AuthenticationProfile_PermissionRelationFilter>;
  teamMembers?: Maybe<TeamMember_PermissionRelationFilter>;
  AND?: Maybe<Array<Role_PermissionFilter>>;
  OR?: Maybe<Array<Role_PermissionFilter>>;
};

export type Permission_PermissionRelationFilter = {
  some?: Maybe<Permission_PermissionFilter>;
  every?: Maybe<Permission_PermissionFilter>;
  none?: Maybe<Permission_PermissionFilter>;
};

export type Permission_PermissionFilter = {
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  appId?: Maybe<StringPredicate>;
  resourceType?: Maybe<StringPredicate>;
  resource?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  role?: Maybe<Role_PermissionFilter>;
  AND?: Maybe<Array<Permission_PermissionFilter>>;
  OR?: Maybe<Array<Permission_PermissionFilter>>;
};

export type ApiToken_PermissionRelationFilter = {
  some?: Maybe<ApiToken_PermissionFilter>;
  every?: Maybe<ApiToken_PermissionFilter>;
  none?: Maybe<ApiToken_PermissionFilter>;
};

export type ApiToken_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  token?: Maybe<StringPredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  AND?: Maybe<Array<ApiToken_PermissionFilter>>;
  OR?: Maybe<Array<ApiToken_PermissionFilter>>;
};

export type AuthenticationProfile_PermissionRelationFilter = {
  some?: Maybe<AuthenticationProfile_PermissionFilter>;
  every?: Maybe<AuthenticationProfile_PermissionFilter>;
  none?: Maybe<AuthenticationProfile_PermissionFilter>;
};

export type AuthenticationProfile_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  secret?: Maybe<StringPredicate>;
  managementDomain?: Maybe<StringPredicate>;
  clientId?: Maybe<StringPredicate>;
  databaseName?: Maybe<StringPredicate>;
  domain?: Maybe<StringPredicate>;
  selfSignUpEnabled?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  AND?: Maybe<Array<AuthenticationProfile_PermissionFilter>>;
  OR?: Maybe<Array<AuthenticationProfile_PermissionFilter>>;
};

export type TeamInvitation_PermissionRelationFilter = {
  some?: Maybe<TeamInvitation_PermissionFilter>;
  every?: Maybe<TeamInvitation_PermissionFilter>;
  none?: Maybe<TeamInvitation_PermissionFilter>;
};

export type TeamInvitation_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  uuid?: Maybe<StringPredicate>;
  email?: Maybe<StringPredicate>;
  firstName?: Maybe<StringPredicate>;
  lastName?: Maybe<StringPredicate>;
  resentOn?: Maybe<DateTimePredicate>;
  accepted?: Maybe<BoolPredicate>;
  acceptedOn?: Maybe<DateTimePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  invitee?: Maybe<TeamMember_PermissionFilter>;
  invitedBy?: Maybe<TeamMember_PermissionFilter>;
  AND?: Maybe<Array<TeamInvitation_PermissionFilter>>;
  OR?: Maybe<Array<TeamInvitation_PermissionFilter>>;
};

export type Company_PermissionRelationFilter = {
  some?: Maybe<Company_PermissionFilter>;
  every?: Maybe<Company_PermissionFilter>;
  none?: Maybe<Company_PermissionFilter>;
};

export type Company_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  address?: Maybe<SmartAddressPredicate>;
  companyStory?: Maybe<StringPredicate>;
  founderStory?: Maybe<StringPredicate>;
  productDescription?: Maybe<StringPredicate>;
  marketDescription?: Maybe<StringPredicate>;
  customersStrategy?: Maybe<StringPredicate>;
  capital?: Maybe<IntPredicate>;
  equityCapitalAmount?: Maybe<IntPredicate>;
  monthsToNextRound?: Maybe<IntPredicate>;
  securedDebt?: Maybe<IntPredicate>;
  foundedYear?: Maybe<IntPredicate>;
  structure?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  contactFirstName?: Maybe<StringPredicate>;
  contactTitle?: Maybe<StringPredicate>;
  contactEmail?: Maybe<StringPredicate>;
  active?: Maybe<BoolPredicate>;
  primaryCustomerBase?: Maybe<StringPredicate>;
  idealRaiseAmount?: Maybe<IntPredicate>;
  borrowedDebt?: Maybe<IntPredicate>;
  saaSScore?: Maybe<IntPredicate>;
  primaryBusinessModel?: Maybe<StringPredicate>;
  applicationFinalized?: Maybe<BoolPredicate>;
  contactLastName?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  logo?: Maybe<File_PermissionFilter>;
  notes?: Maybe<Note_PermissionRelationFilter>;
  loanApplication?: Maybe<LoanApplication_PermissionFilter>;
  documents?: Maybe<Document_PermissionRelationFilter>;
  facilities?: Maybe<Facility_PermissionRelationFilter>;
  owner?: Maybe<User_PermissionFilter>;
  integration?: Maybe<Integration_PermissionFilter>;
  portfolioCompany?: Maybe<PortfolioCompany_PermissionFilter>;
  team?: Maybe<User_PermissionRelationFilter>;
  metrics?: Maybe<MetricValue_PermissionRelationFilter>;
  monitoringValues?: Maybe<CompanyMonitoring_PermissionRelationFilter>;
  company?: Maybe<ProgressStepValue_PermissionRelationFilter>;
  inboxMessages?: Maybe<InboxMessage_PermissionRelationFilter>;
  companyStep?: Maybe<ProgressCompanyStep_PermissionRelationFilter>;
  covenantValue?: Maybe<CovenantValue_PermissionRelationFilter>;
  customCovenant?: Maybe<CustomCovenantValue_PermissionRelationFilter>;
  AND?: Maybe<Array<Company_PermissionFilter>>;
  OR?: Maybe<Array<Company_PermissionFilter>>;
};

export type Note_PermissionRelationFilter = {
  some?: Maybe<Note_PermissionFilter>;
  every?: Maybe<Note_PermissionFilter>;
  none?: Maybe<Note_PermissionFilter>;
};

export type Note_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  title?: Maybe<StringPredicate>;
  body?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  attachments?: Maybe<File_PermissionRelationFilter>;
  company?: Maybe<Company_PermissionFilter>;
  AND?: Maybe<Array<Note_PermissionFilter>>;
  OR?: Maybe<Array<Note_PermissionFilter>>;
};

export type File_PermissionRelationFilter = {
  some?: Maybe<File_PermissionFilter>;
  every?: Maybe<File_PermissionFilter>;
  none?: Maybe<File_PermissionFilter>;
};

export type LoanApplication_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  stage?: Maybe<StringPredicate>;
  contactName?: Maybe<StringPredicate>;
  applicantTitle?: Maybe<StringPredicate>;
  amountRequested?: Maybe<IntPredicate>;
  amountCommitted?: Maybe<IntPredicate>;
  firstDrawProposed?: Maybe<IntPredicate>;
  targetCloseDate?: Maybe<DatePredicate>;
  structure?: Maybe<StringPredicate>;
  status?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  documents?: Maybe<Document_PermissionRelationFilter>;
  dealTeam?: Maybe<User_PermissionRelationFilter>;
  AND?: Maybe<Array<LoanApplication_PermissionFilter>>;
  OR?: Maybe<Array<LoanApplication_PermissionFilter>>;
};

export type Document_PermissionRelationFilter = {
  some?: Maybe<Document_PermissionFilter>;
  every?: Maybe<Document_PermissionFilter>;
  none?: Maybe<Document_PermissionFilter>;
};

export type Document_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  source?: Maybe<StringPredicate>;
  private?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  file?: Maybe<File_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  loanApplication?: Maybe<LoanApplication_PermissionFilter>;
  inboxMessage?: Maybe<InboxMessage_PermissionRelationFilter>;
  AND?: Maybe<Array<Document_PermissionFilter>>;
  OR?: Maybe<Array<Document_PermissionFilter>>;
};

export type InboxMessage_PermissionRelationFilter = {
  some?: Maybe<InboxMessage_PermissionFilter>;
  every?: Maybe<InboxMessage_PermissionFilter>;
  none?: Maybe<InboxMessage_PermissionFilter>;
};

export type InboxMessage_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  subject?: Maybe<StringPredicate>;
  body?: Maybe<StringPredicate>;
  isRead?: Maybe<BoolPredicate>;
  isByAdministrator?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  attachments?: Maybe<Document_PermissionRelationFilter>;
  company?: Maybe<Company_PermissionFilter>;
  repliedOn?: Maybe<InboxMessage_PermissionFilter>;
  replies?: Maybe<InboxMessage_PermissionRelationFilter>;
  AND?: Maybe<Array<InboxMessage_PermissionFilter>>;
  OR?: Maybe<Array<InboxMessage_PermissionFilter>>;
};

export type Facility_PermissionRelationFilter = {
  some?: Maybe<Facility_PermissionFilter>;
  every?: Maybe<Facility_PermissionFilter>;
  none?: Maybe<Facility_PermissionFilter>;
};

export type Facility_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  structure?: Maybe<StringPredicate>;
  amountCommitted?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  comments?: Maybe<StringPredicate>;
  totalAmountDeployed?: Maybe<IntPredicate>;
  nextAvailableDraw?: Maybe<DatePredicate>;
  closingFee?: Maybe<StringPredicate>;
  successFee?: Maybe<FloatPredicate>;
  minCashCovenant?: Maybe<IntPredicate>;
  minCashRunawayCovenant?: Maybe<IntPredicate>;
  validityGuarantee?: Maybe<StringPredicate>;
  advanceRate?: Maybe<FloatPredicate>;
  amountDeployed?: Maybe<IntPredicate>;
  fundingDate?: Maybe<DatePredicate>;
  closingFeeValue?: Maybe<FloatPredicate>;
  closingDate?: Maybe<DatePredicate>;
  finalAdvanceRate?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  draws?: Maybe<Draw_PermissionRelationFilter>;
  facilityHistory?: Maybe<FacilityHistory_PermissionRelationFilter>;
  drawHistory?: Maybe<DrawHistory_PermissionRelationFilter>;
  AND?: Maybe<Array<Facility_PermissionFilter>>;
  OR?: Maybe<Array<Facility_PermissionFilter>>;
};

export type Draw_PermissionRelationFilter = {
  some?: Maybe<Draw_PermissionFilter>;
  every?: Maybe<Draw_PermissionFilter>;
  none?: Maybe<Draw_PermissionFilter>;
};

export type Draw_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  fundedDate?: Maybe<DatePredicate>;
  maturityDate?: Maybe<DatePredicate>;
  amountDeployed?: Maybe<IntPredicate>;
  interestRate?: Maybe<FloatPredicate>;
  cashShare?: Maybe<FloatPredicate>;
  returnCap?: Maybe<FloatPredicate>;
  term?: Maybe<IntPredicate>;
  iOPeriod?: Maybe<IntPredicate>;
  amortizationPeriod?: Maybe<IntPredicate>;
  iOPeriodAmortizationPeriod?: Maybe<IntPredicate>;
  exitFee?: Maybe<FloatPredicate>;
  prepayment?: Maybe<StringPredicate>;
  iRR?: Maybe<FloatPredicate>;
  mOIC?: Maybe<FloatPredicate>;
  comments?: Maybe<StringPredicate>;
  prepaymentMonths?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  facility?: Maybe<Facility_PermissionFilter>;
  drawHistory?: Maybe<DrawHistory_PermissionRelationFilter>;
  AND?: Maybe<Array<Draw_PermissionFilter>>;
  OR?: Maybe<Array<Draw_PermissionFilter>>;
};

export type DrawHistory_PermissionRelationFilter = {
  some?: Maybe<DrawHistory_PermissionFilter>;
  every?: Maybe<DrawHistory_PermissionFilter>;
  none?: Maybe<DrawHistory_PermissionFilter>;
};

export type DrawHistory_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  draw?: Maybe<Draw_PermissionFilter>;
  facility?: Maybe<Facility_PermissionFilter>;
  drawHistoryValue?: Maybe<DrawHistoryValue_PermissionRelationFilter>;
  AND?: Maybe<Array<DrawHistory_PermissionFilter>>;
  OR?: Maybe<Array<DrawHistory_PermissionFilter>>;
};

export type DrawHistoryValue_PermissionRelationFilter = {
  some?: Maybe<DrawHistoryValue_PermissionFilter>;
  every?: Maybe<DrawHistoryValue_PermissionFilter>;
  none?: Maybe<DrawHistoryValue_PermissionFilter>;
};

export type DrawHistoryValue_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  date?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  drawHistory?: Maybe<DrawHistory_PermissionFilter>;
  AND?: Maybe<Array<DrawHistoryValue_PermissionFilter>>;
  OR?: Maybe<Array<DrawHistoryValue_PermissionFilter>>;
};

export type FacilityHistory_PermissionRelationFilter = {
  some?: Maybe<FacilityHistory_PermissionFilter>;
  every?: Maybe<FacilityHistory_PermissionFilter>;
  none?: Maybe<FacilityHistory_PermissionFilter>;
};

export type FacilityHistory_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  facility?: Maybe<Facility_PermissionFilter>;
  facilityHistoryValue?: Maybe<FacilityHistoryValue_PermissionRelationFilter>;
  AND?: Maybe<Array<FacilityHistory_PermissionFilter>>;
  OR?: Maybe<Array<FacilityHistory_PermissionFilter>>;
};

export type FacilityHistoryValue_PermissionRelationFilter = {
  some?: Maybe<FacilityHistoryValue_PermissionFilter>;
  every?: Maybe<FacilityHistoryValue_PermissionFilter>;
  none?: Maybe<FacilityHistoryValue_PermissionFilter>;
};

export type FacilityHistoryValue_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  date?: Maybe<DatePredicate>;
  advanceRate?: Maybe<FloatPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  facilityHistory?: Maybe<FacilityHistory_PermissionFilter>;
  AND?: Maybe<Array<FacilityHistoryValue_PermissionFilter>>;
  OR?: Maybe<Array<FacilityHistoryValue_PermissionFilter>>;
};

export type Integration_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  xero?: Maybe<XeroIntegration_PermissionFilter>;
  quickBooks?: Maybe<QuickBooksIntegration_PermissionFilter>;
  stripe?: Maybe<StripeIntegration_PermissionFilter>;
  plaid?: Maybe<PlaidIntegration_PermissionRelationFilter>;
  AND?: Maybe<Array<Integration_PermissionFilter>>;
  OR?: Maybe<Array<Integration_PermissionFilter>>;
};

export type XeroIntegration_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  refreshToken?: Maybe<StringPredicate>;
  expiresAt?: Maybe<IntPredicate>;
  tenantId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  integrations?: Maybe<Integration_PermissionFilter>;
  AND?: Maybe<Array<XeroIntegration_PermissionFilter>>;
  OR?: Maybe<Array<XeroIntegration_PermissionFilter>>;
};

export type QuickBooksIntegration_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  refreshToken?: Maybe<StringPredicate>;
  expiresAt?: Maybe<IntPredicate>;
  realmId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  integrations?: Maybe<Integration_PermissionFilter>;
  AND?: Maybe<Array<QuickBooksIntegration_PermissionFilter>>;
  OR?: Maybe<Array<QuickBooksIntegration_PermissionFilter>>;
};

export type StripeIntegration_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  refreshToken?: Maybe<StringPredicate>;
  userId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  integrations?: Maybe<Integration_PermissionFilter>;
  AND?: Maybe<Array<StripeIntegration_PermissionFilter>>;
  OR?: Maybe<Array<StripeIntegration_PermissionFilter>>;
};

export type PlaidIntegration_PermissionRelationFilter = {
  some?: Maybe<PlaidIntegration_PermissionFilter>;
  every?: Maybe<PlaidIntegration_PermissionFilter>;
  none?: Maybe<PlaidIntegration_PermissionFilter>;
};

export type PlaidIntegration_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  accessToken?: Maybe<StringPredicate>;
  institutionId?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  integrations?: Maybe<Integration_PermissionFilter>;
  accounts?: Maybe<PlaidIntegrationAccount_PermissionRelationFilter>;
  AND?: Maybe<Array<PlaidIntegration_PermissionFilter>>;
  OR?: Maybe<Array<PlaidIntegration_PermissionFilter>>;
};

export type PlaidIntegrationAccount_PermissionRelationFilter = {
  some?: Maybe<PlaidIntegrationAccount_PermissionFilter>;
  every?: Maybe<PlaidIntegrationAccount_PermissionFilter>;
  none?: Maybe<PlaidIntegrationAccount_PermissionFilter>;
};

export type PlaidIntegrationAccount_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  accountId?: Maybe<StringPredicate>;
  name?: Maybe<StringPredicate>;
  mask?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  plaidIntegration?: Maybe<PlaidIntegration_PermissionFilter>;
  AND?: Maybe<Array<PlaidIntegrationAccount_PermissionFilter>>;
  OR?: Maybe<Array<PlaidIntegrationAccount_PermissionFilter>>;
};

export type PortfolioCompany_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  dealTeam?: Maybe<User_PermissionRelationFilter>;
  cashMonitoringRecords?: Maybe<CashMonitoringRecord_PermissionRelationFilter>;
  AND?: Maybe<Array<PortfolioCompany_PermissionFilter>>;
  OR?: Maybe<Array<PortfolioCompany_PermissionFilter>>;
};

export type CashMonitoringRecord_PermissionRelationFilter = {
  some?: Maybe<CashMonitoringRecord_PermissionFilter>;
  every?: Maybe<CashMonitoringRecord_PermissionFilter>;
  none?: Maybe<CashMonitoringRecord_PermissionFilter>;
};

export type CashMonitoringRecord_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  note?: Maybe<StringPredicate>;
  action?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  portfolioCompany?: Maybe<PortfolioCompany_PermissionFilter>;
  AND?: Maybe<Array<CashMonitoringRecord_PermissionFilter>>;
  OR?: Maybe<Array<CashMonitoringRecord_PermissionFilter>>;
};

export type MetricValue_PermissionRelationFilter = {
  some?: Maybe<MetricValue_PermissionFilter>;
  every?: Maybe<MetricValue_PermissionFilter>;
  none?: Maybe<MetricValue_PermissionFilter>;
};

export type MetricValue_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  source?: Maybe<StringPredicate>;
  value?: Maybe<FloatPredicate>;
  adjustedValue?: Maybe<FloatPredicate>;
  comment?: Maybe<StringPredicate>;
  tier?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  period?: Maybe<StringPredicate>;
  active?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  metric?: Maybe<Metric_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  AND?: Maybe<Array<MetricValue_PermissionFilter>>;
  OR?: Maybe<Array<MetricValue_PermissionFilter>>;
};

export type Metric_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  description?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  format?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  saaSScoreWeight?: Maybe<FloatPredicate>;
  saaSScoreWeightEnterprise?: Maybe<FloatPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  values?: Maybe<MetricValue_PermissionRelationFilter>;
  AND?: Maybe<Array<Metric_PermissionFilter>>;
  OR?: Maybe<Array<Metric_PermissionFilter>>;
};

export type CompanyMonitoring_PermissionRelationFilter = {
  some?: Maybe<CompanyMonitoring_PermissionFilter>;
  every?: Maybe<CompanyMonitoring_PermissionFilter>;
  none?: Maybe<CompanyMonitoring_PermissionFilter>;
};

export type CompanyMonitoring_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  committed?: Maybe<IntPredicate>;
  deployed?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  companies?: Maybe<Company_PermissionFilter>;
  AND?: Maybe<Array<CompanyMonitoring_PermissionFilter>>;
  OR?: Maybe<Array<CompanyMonitoring_PermissionFilter>>;
};

export type ProgressStepValue_PermissionRelationFilter = {
  some?: Maybe<ProgressStepValue_PermissionFilter>;
  every?: Maybe<ProgressStepValue_PermissionFilter>;
  none?: Maybe<ProgressStepValue_PermissionFilter>;
};

export type ProgressStepValue_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  customStepName?: Maybe<StringPredicate>;
  customStepComment?: Maybe<StringPredicate>;
  bigfootTeamComment?: Maybe<StringPredicate>;
  applicantComment?: Maybe<StringPredicate>;
  isVisible?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  step?: Maybe<ProgressStep_PermissionFilter>;
  AND?: Maybe<Array<ProgressStepValue_PermissionFilter>>;
  OR?: Maybe<Array<ProgressStepValue_PermissionFilter>>;
};

export type ProgressStep_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  order?: Maybe<IntPredicate>;
  comment?: Maybe<StringPredicate>;
  helperText?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  stage?: Maybe<ProgressStage_PermissionFilter>;
  stepValue?: Maybe<ProgressStepValue_PermissionRelationFilter>;
  AND?: Maybe<Array<ProgressStep_PermissionFilter>>;
  OR?: Maybe<Array<ProgressStep_PermissionFilter>>;
};

export type ProgressStage_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  steps?: Maybe<ProgressStep_PermissionRelationFilter>;
  companySteps?: Maybe<ProgressCompanyStep_PermissionRelationFilter>;
  AND?: Maybe<Array<ProgressStage_PermissionFilter>>;
  OR?: Maybe<Array<ProgressStage_PermissionFilter>>;
};

export type ProgressStep_PermissionRelationFilter = {
  some?: Maybe<ProgressStep_PermissionFilter>;
  every?: Maybe<ProgressStep_PermissionFilter>;
  none?: Maybe<ProgressStep_PermissionFilter>;
};

export type ProgressCompanyStep_PermissionRelationFilter = {
  some?: Maybe<ProgressCompanyStep_PermissionFilter>;
  every?: Maybe<ProgressCompanyStep_PermissionFilter>;
  none?: Maybe<ProgressCompanyStep_PermissionFilter>;
};

export type ProgressCompanyStep_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  comment?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  bigfootTeamComment?: Maybe<StringPredicate>;
  applicantComment?: Maybe<StringPredicate>;
  isVisible?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  stage?: Maybe<ProgressStage_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  AND?: Maybe<Array<ProgressCompanyStep_PermissionFilter>>;
  OR?: Maybe<Array<ProgressCompanyStep_PermissionFilter>>;
};

export type CovenantValue_PermissionRelationFilter = {
  some?: Maybe<CovenantValue_PermissionFilter>;
  every?: Maybe<CovenantValue_PermissionFilter>;
  none?: Maybe<CovenantValue_PermissionFilter>;
};

export type CovenantValue_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  value?: Maybe<StringPredicate>;
  isEnabled?: Maybe<BoolPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  covenant?: Maybe<Covenant_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  AND?: Maybe<Array<CovenantValue_PermissionFilter>>;
  OR?: Maybe<Array<CovenantValue_PermissionFilter>>;
};

export type Covenant_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  covenantValue?: Maybe<CovenantValue_PermissionRelationFilter>;
  customCovenantValue?: Maybe<CustomCovenantValue_PermissionRelationFilter>;
  AND?: Maybe<Array<Covenant_PermissionFilter>>;
  OR?: Maybe<Array<Covenant_PermissionFilter>>;
};

export type CustomCovenantValue_PermissionRelationFilter = {
  some?: Maybe<CustomCovenantValue_PermissionFilter>;
  every?: Maybe<CustomCovenantValue_PermissionFilter>;
  none?: Maybe<CustomCovenantValue_PermissionFilter>;
};

export type CustomCovenantValue_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  date?: Maybe<DatePredicate>;
  actual?: Maybe<StringPredicate>;
  difference?: Maybe<StringPredicate>;
  inCompliance?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  covenant?: Maybe<Covenant_PermissionFilter>;
  company?: Maybe<Company_PermissionFilter>;
  AND?: Maybe<Array<CustomCovenantValue_PermissionFilter>>;
  OR?: Maybe<Array<CustomCovenantValue_PermissionFilter>>;
};

export type Invitation_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  invitedUser?: Maybe<User_PermissionFilter>;
  AND?: Maybe<Array<Invitation_PermissionFilter>>;
  OR?: Maybe<Array<Invitation_PermissionFilter>>;
};

export type PortfolioCompany_PermissionRelationFilter = {
  some?: Maybe<PortfolioCompany_PermissionFilter>;
  every?: Maybe<PortfolioCompany_PermissionFilter>;
  none?: Maybe<PortfolioCompany_PermissionFilter>;
};

/** UserFieldsPermissions create input */
export type UserFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  origin?: Maybe<Scalars['Boolean']>;
  is8base?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataUsersUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<User_PermissionFilter>;
  fields?: Maybe<UserFieldsPermissions>;
};

export type PermissionsDataUsersDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataUsersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFiles = {
  create?: Maybe<PermissionsDataFilesCreate>;
  read?: Maybe<PermissionsDataFilesRead>;
  update?: Maybe<PermissionsDataFilesUpdate>;
  delete?: Maybe<PermissionsDataFilesDelete>;
  destroy?: Maybe<PermissionsDataFilesDestroy>;
};

export type PermissionsDataFilesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<File_PermissionFilter>;
  fields?: Maybe<FileFieldsPermissions>;
};

/** FileFieldsPermissions create input */
export type FileFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  fileId?: Maybe<Scalars['Boolean']>;
  downloadUrl?: Maybe<Scalars['Boolean']>;
  shareUrl?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<Scalars['Boolean']>;
  public?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['Boolean']>;
  uploadUrl?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['Boolean']>;
  mods?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFilesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<File_PermissionFilter>;
  fields?: Maybe<FileFieldsPermissions>;
};

export type PermissionsDataFilesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFilesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRoles = {
  create?: Maybe<PermissionsDataRolesCreate>;
  read?: Maybe<PermissionsDataRolesRead>;
  update?: Maybe<PermissionsDataRolesUpdate>;
  delete?: Maybe<PermissionsDataRolesDelete>;
  destroy?: Maybe<PermissionsDataRolesDestroy>;
};

export type PermissionsDataRolesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRolesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Role_PermissionFilter>;
  fields?: Maybe<RoleFieldsPermissions>;
};

/** RoleFieldsPermissions create input */
export type RoleFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataRolesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Role_PermissionFilter>;
  fields?: Maybe<RoleFieldsPermissions>;
};

export type PermissionsDataRolesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataRolesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamInvitations = {
  create?: Maybe<PermissionsDataTeamInvitationsCreate>;
  read?: Maybe<PermissionsDataTeamInvitationsRead>;
  update?: Maybe<PermissionsDataTeamInvitationsUpdate>;
  delete?: Maybe<PermissionsDataTeamInvitationsDelete>;
  destroy?: Maybe<PermissionsDataTeamInvitationsDestroy>;
};

export type PermissionsDataTeamInvitationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamInvitationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<TeamInvitation_PermissionFilter>;
  fields?: Maybe<TeamInvitationFieldsPermissions>;
};

/** TeamInvitationFieldsPermissions create input */
export type TeamInvitationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['Boolean']>;
  resentOn?: Maybe<Scalars['Boolean']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataTeamInvitationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<TeamInvitation_PermissionFilter>;
  fields?: Maybe<TeamInvitationFieldsPermissions>;
};

export type PermissionsDataTeamInvitationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataTeamInvitationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamMembers = {
  create?: Maybe<PermissionsDataTeamMembersCreate>;
  read?: Maybe<PermissionsDataTeamMembersRead>;
  update?: Maybe<PermissionsDataTeamMembersUpdate>;
  delete?: Maybe<PermissionsDataTeamMembersDelete>;
  destroy?: Maybe<PermissionsDataTeamMembersDestroy>;
};

export type PermissionsDataTeamMembersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataTeamMembersRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<TeamMember_PermissionFilter>;
  fields?: Maybe<TeamMemberFieldsPermissions>;
};

/** TeamMemberFieldsPermissions create input */
export type TeamMemberFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataTeamMembersUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<TeamMember_PermissionFilter>;
  fields?: Maybe<TeamMemberFieldsPermissions>;
};

export type PermissionsDataTeamMembersDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataTeamMembersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCiCdMigrations = {
  create?: Maybe<PermissionsDataCiCdMigrationsCreate>;
  read?: Maybe<PermissionsDataCiCdMigrationsRead>;
  update?: Maybe<PermissionsDataCiCdMigrationsUpdate>;
  delete?: Maybe<PermissionsDataCiCdMigrationsDelete>;
  destroy?: Maybe<PermissionsDataCiCdMigrationsDestroy>;
};

export type PermissionsDataCiCdMigrationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCiCdMigrationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CiCdMigration_PermissionFilter>;
  fields?: Maybe<CiCdMigrationFieldsPermissions>;
};

export type CiCdMigration_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  code?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  AND?: Maybe<Array<CiCdMigration_PermissionFilter>>;
  OR?: Maybe<Array<CiCdMigration_PermissionFilter>>;
};

/** CiCdMigrationFieldsPermissions create input */
export type CiCdMigrationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCiCdMigrationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CiCdMigration_PermissionFilter>;
  fields?: Maybe<CiCdMigrationFieldsPermissions>;
};

export type PermissionsDataCiCdMigrationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCiCdMigrationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLoanApplications = {
  create?: Maybe<PermissionsDataLoanApplicationsCreate>;
  read?: Maybe<PermissionsDataLoanApplicationsRead>;
  update?: Maybe<PermissionsDataLoanApplicationsUpdate>;
  delete?: Maybe<PermissionsDataLoanApplicationsDelete>;
  destroy?: Maybe<PermissionsDataLoanApplicationsDestroy>;
};

export type PermissionsDataLoanApplicationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLoanApplicationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<LoanApplication_PermissionFilter>;
  fields?: Maybe<LoanApplicationFieldsPermissions>;
};

/** LoanApplicationFieldsPermissions create input */
export type LoanApplicationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Scalars['Boolean']>;
  contactName?: Maybe<Scalars['Boolean']>;
  applicantTitle?: Maybe<Scalars['Boolean']>;
  amountRequested?: Maybe<Scalars['Boolean']>;
  amountCommitted?: Maybe<Scalars['Boolean']>;
  firstDrawProposed?: Maybe<Scalars['Boolean']>;
  targetCloseDate?: Maybe<Scalars['Boolean']>;
  structure?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataLoanApplicationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<LoanApplication_PermissionFilter>;
  fields?: Maybe<LoanApplicationFieldsPermissions>;
};

export type PermissionsDataLoanApplicationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataLoanApplicationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInvitations = {
  create?: Maybe<PermissionsDataInvitationsCreate>;
  read?: Maybe<PermissionsDataInvitationsRead>;
  update?: Maybe<PermissionsDataInvitationsUpdate>;
  delete?: Maybe<PermissionsDataInvitationsDelete>;
  destroy?: Maybe<PermissionsDataInvitationsDestroy>;
};

export type PermissionsDataInvitationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInvitationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Invitation_PermissionFilter>;
  fields?: Maybe<InvitationFieldsPermissions>;
};

/** InvitationFieldsPermissions create input */
export type InvitationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataInvitationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Invitation_PermissionFilter>;
  fields?: Maybe<InvitationFieldsPermissions>;
};

export type PermissionsDataInvitationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataInvitationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLoanMonitorings = {
  create?: Maybe<PermissionsDataLoanMonitoringsCreate>;
  read?: Maybe<PermissionsDataLoanMonitoringsRead>;
  update?: Maybe<PermissionsDataLoanMonitoringsUpdate>;
  delete?: Maybe<PermissionsDataLoanMonitoringsDelete>;
  destroy?: Maybe<PermissionsDataLoanMonitoringsDestroy>;
};

export type PermissionsDataLoanMonitoringsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataLoanMonitoringsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<LoanMonitoring_PermissionFilter>;
  fields?: Maybe<LoanMonitoringFieldsPermissions>;
};

export type LoanMonitoring_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  merchantDate?: Maybe<DatePredicate>;
  companyName?: Maybe<StringPredicate>;
  loanDate?: Maybe<DatePredicate>;
  accountingDate?: Maybe<DatePredicate>;
  monitoringStatus?: Maybe<StringPredicate>;
  bankBalance?: Maybe<IntPredicate>;
  bankAccountDate?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  AND?: Maybe<Array<LoanMonitoring_PermissionFilter>>;
  OR?: Maybe<Array<LoanMonitoring_PermissionFilter>>;
};

/** LoanMonitoringFieldsPermissions create input */
export type LoanMonitoringFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  merchantDate?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['Boolean']>;
  loanDate?: Maybe<Scalars['Boolean']>;
  accountingDate?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Scalars['Boolean']>;
  bankBalance?: Maybe<Scalars['Boolean']>;
  bankAccountDate?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataLoanMonitoringsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<LoanMonitoring_PermissionFilter>;
  fields?: Maybe<LoanMonitoringFieldsPermissions>;
};

export type PermissionsDataLoanMonitoringsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataLoanMonitoringsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCompanies = {
  create?: Maybe<PermissionsDataCompaniesCreate>;
  read?: Maybe<PermissionsDataCompaniesRead>;
  update?: Maybe<PermissionsDataCompaniesUpdate>;
  delete?: Maybe<PermissionsDataCompaniesDelete>;
  destroy?: Maybe<PermissionsDataCompaniesDestroy>;
};

export type PermissionsDataCompaniesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCompaniesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Company_PermissionFilter>;
  fields?: Maybe<CompanyFieldsPermissions>;
};

/** CompanyFieldsPermissions create input */
export type CompanyFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['Boolean']>;
  companyStory?: Maybe<Scalars['Boolean']>;
  founderStory?: Maybe<Scalars['Boolean']>;
  productDescription?: Maybe<Scalars['Boolean']>;
  marketDescription?: Maybe<Scalars['Boolean']>;
  customersStrategy?: Maybe<Scalars['Boolean']>;
  capital?: Maybe<Scalars['Boolean']>;
  capitalType?: Maybe<Scalars['Boolean']>;
  capitalStrategy?: Maybe<Scalars['Boolean']>;
  equityCapitalAmount?: Maybe<Scalars['Boolean']>;
  equityCapitalOwner?: Maybe<Scalars['Boolean']>;
  monthsToNextRound?: Maybe<Scalars['Boolean']>;
  securedDebt?: Maybe<Scalars['Boolean']>;
  foundedYear?: Maybe<Scalars['Boolean']>;
  structure?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  contactFirstName?: Maybe<Scalars['Boolean']>;
  contactTitle?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Scalars['Boolean']>;
  primaryCustomerBase?: Maybe<Scalars['Boolean']>;
  idealRaiseAmount?: Maybe<Scalars['Boolean']>;
  borrowedDebt?: Maybe<Scalars['Boolean']>;
  borrower?: Maybe<Scalars['Boolean']>;
  saaSScore?: Maybe<Scalars['Boolean']>;
  primaryBusinessModel?: Maybe<Scalars['Boolean']>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  contactLastName?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCompaniesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Company_PermissionFilter>;
  fields?: Maybe<CompanyFieldsPermissions>;
};

export type PermissionsDataCompaniesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCompaniesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFacilities = {
  create?: Maybe<PermissionsDataFacilitiesCreate>;
  read?: Maybe<PermissionsDataFacilitiesRead>;
  update?: Maybe<PermissionsDataFacilitiesUpdate>;
  delete?: Maybe<PermissionsDataFacilitiesDelete>;
  destroy?: Maybe<PermissionsDataFacilitiesDestroy>;
};

export type PermissionsDataFacilitiesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFacilitiesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Facility_PermissionFilter>;
  fields?: Maybe<FacilityFieldsPermissions>;
};

/** FacilityFieldsPermissions create input */
export type FacilityFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  structure?: Maybe<Scalars['Boolean']>;
  amountCommitted?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['Boolean']>;
  totalAmountDeployed?: Maybe<Scalars['Boolean']>;
  nextAvailableDraw?: Maybe<Scalars['Boolean']>;
  closingFee?: Maybe<Scalars['Boolean']>;
  successFee?: Maybe<Scalars['Boolean']>;
  minCashCovenant?: Maybe<Scalars['Boolean']>;
  minCashRunawayCovenant?: Maybe<Scalars['Boolean']>;
  validityGuarantee?: Maybe<Scalars['Boolean']>;
  advanceRate?: Maybe<Scalars['Boolean']>;
  amountDeployed?: Maybe<Scalars['Boolean']>;
  fundingDate?: Maybe<Scalars['Boolean']>;
  closingFeeValue?: Maybe<Scalars['Boolean']>;
  closingDate?: Maybe<Scalars['Boolean']>;
  finalAdvanceRate?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFacilitiesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Facility_PermissionFilter>;
  fields?: Maybe<FacilityFieldsPermissions>;
};

export type PermissionsDataFacilitiesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFacilitiesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDocuments = {
  create?: Maybe<PermissionsDataDocumentsCreate>;
  read?: Maybe<PermissionsDataDocumentsRead>;
  update?: Maybe<PermissionsDataDocumentsUpdate>;
  delete?: Maybe<PermissionsDataDocumentsDelete>;
  destroy?: Maybe<PermissionsDataDocumentsDestroy>;
};

export type PermissionsDataDocumentsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDocumentsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Document_PermissionFilter>;
  fields?: Maybe<DocumentFieldsPermissions>;
};

/** DocumentFieldsPermissions create input */
export type DocumentFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDocumentsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Document_PermissionFilter>;
  fields?: Maybe<DocumentFieldsPermissions>;
};

export type PermissionsDataDocumentsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDocumentsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotes = {
  create?: Maybe<PermissionsDataNotesCreate>;
  read?: Maybe<PermissionsDataNotesRead>;
  update?: Maybe<PermissionsDataNotesUpdate>;
  delete?: Maybe<PermissionsDataNotesDelete>;
  destroy?: Maybe<PermissionsDataNotesDestroy>;
};

export type PermissionsDataNotesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Note_PermissionFilter>;
  fields?: Maybe<NoteFieldsPermissions>;
};

/** NoteFieldsPermissions create input */
export type NoteFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataNotesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Note_PermissionFilter>;
  fields?: Maybe<NoteFieldsPermissions>;
};

export type PermissionsDataNotesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataNotesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataIntegrations = {
  create?: Maybe<PermissionsDataIntegrationsCreate>;
  read?: Maybe<PermissionsDataIntegrationsRead>;
  update?: Maybe<PermissionsDataIntegrationsUpdate>;
  delete?: Maybe<PermissionsDataIntegrationsDelete>;
  destroy?: Maybe<PermissionsDataIntegrationsDestroy>;
};

export type PermissionsDataIntegrationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataIntegrationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Integration_PermissionFilter>;
  fields?: Maybe<IntegrationFieldsPermissions>;
};

/** IntegrationFieldsPermissions create input */
export type IntegrationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataIntegrationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Integration_PermissionFilter>;
  fields?: Maybe<IntegrationFieldsPermissions>;
};

export type PermissionsDataIntegrationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataIntegrationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataXeroIntegrations = {
  create?: Maybe<PermissionsDataXeroIntegrationsCreate>;
  read?: Maybe<PermissionsDataXeroIntegrationsRead>;
  update?: Maybe<PermissionsDataXeroIntegrationsUpdate>;
  delete?: Maybe<PermissionsDataXeroIntegrationsDelete>;
  destroy?: Maybe<PermissionsDataXeroIntegrationsDestroy>;
};

export type PermissionsDataXeroIntegrationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataXeroIntegrationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<XeroIntegration_PermissionFilter>;
  fields?: Maybe<XeroIntegrationFieldsPermissions>;
};

/** XeroIntegrationFieldsPermissions create input */
export type XeroIntegrationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  accessToken?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['Boolean']>;
  expiresAt?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataXeroIntegrationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<XeroIntegration_PermissionFilter>;
  fields?: Maybe<XeroIntegrationFieldsPermissions>;
};

export type PermissionsDataXeroIntegrationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataXeroIntegrationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPortfolioCompanies = {
  create?: Maybe<PermissionsDataPortfolioCompaniesCreate>;
  read?: Maybe<PermissionsDataPortfolioCompaniesRead>;
  update?: Maybe<PermissionsDataPortfolioCompaniesUpdate>;
  delete?: Maybe<PermissionsDataPortfolioCompaniesDelete>;
  destroy?: Maybe<PermissionsDataPortfolioCompaniesDestroy>;
};

export type PermissionsDataPortfolioCompaniesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPortfolioCompaniesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<PortfolioCompany_PermissionFilter>;
  fields?: Maybe<PortfolioCompanyFieldsPermissions>;
};

/** PortfolioCompanyFieldsPermissions create input */
export type PortfolioCompanyFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataPortfolioCompaniesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<PortfolioCompany_PermissionFilter>;
  fields?: Maybe<PortfolioCompanyFieldsPermissions>;
};

export type PermissionsDataPortfolioCompaniesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataPortfolioCompaniesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataMetrics = {
  create?: Maybe<PermissionsDataMetricsCreate>;
  read?: Maybe<PermissionsDataMetricsRead>;
  update?: Maybe<PermissionsDataMetricsUpdate>;
  delete?: Maybe<PermissionsDataMetricsDelete>;
  destroy?: Maybe<PermissionsDataMetricsDestroy>;
};

export type PermissionsDataMetricsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataMetricsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Metric_PermissionFilter>;
  fields?: Maybe<MetricFieldsPermissions>;
};

/** MetricFieldsPermissions create input */
export type MetricFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['Boolean']>;
  format?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
  saaSScoreWeight?: Maybe<Scalars['Boolean']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Boolean']>;
  tierBands?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataMetricsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Metric_PermissionFilter>;
  fields?: Maybe<MetricFieldsPermissions>;
};

export type PermissionsDataMetricsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataMetricsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataMetricValues = {
  create?: Maybe<PermissionsDataMetricValuesCreate>;
  read?: Maybe<PermissionsDataMetricValuesRead>;
  update?: Maybe<PermissionsDataMetricValuesUpdate>;
  delete?: Maybe<PermissionsDataMetricValuesDelete>;
  destroy?: Maybe<PermissionsDataMetricValuesDestroy>;
};

export type PermissionsDataMetricValuesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataMetricValuesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<MetricValue_PermissionFilter>;
  fields?: Maybe<MetricValueFieldsPermissions>;
};

/** MetricValueFieldsPermissions create input */
export type MetricValueFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Boolean']>;
  adjustedValue?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['Boolean']>;
  tier?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Boolean']>;
  period?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataMetricValuesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<MetricValue_PermissionFilter>;
  fields?: Maybe<MetricValueFieldsPermissions>;
};

export type PermissionsDataMetricValuesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataMetricValuesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDraws = {
  create?: Maybe<PermissionsDataDrawsCreate>;
  read?: Maybe<PermissionsDataDrawsRead>;
  update?: Maybe<PermissionsDataDrawsUpdate>;
  delete?: Maybe<PermissionsDataDrawsDelete>;
  destroy?: Maybe<PermissionsDataDrawsDestroy>;
};

export type PermissionsDataDrawsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDrawsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Draw_PermissionFilter>;
  fields?: Maybe<DrawFieldsPermissions>;
};

/** DrawFieldsPermissions create input */
export type DrawFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Boolean']>;
  maturityDate?: Maybe<Scalars['Boolean']>;
  amountDeployed?: Maybe<Scalars['Boolean']>;
  interestRate?: Maybe<Scalars['Boolean']>;
  cashShare?: Maybe<Scalars['Boolean']>;
  returnCap?: Maybe<Scalars['Boolean']>;
  term?: Maybe<Scalars['Boolean']>;
  iOPeriod?: Maybe<Scalars['Boolean']>;
  amortizationPeriod?: Maybe<Scalars['Boolean']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Boolean']>;
  exitFee?: Maybe<Scalars['Boolean']>;
  prepayment?: Maybe<Scalars['Boolean']>;
  iRR?: Maybe<Scalars['Boolean']>;
  mOIC?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['Boolean']>;
  prepaymentMonths?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDrawsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Draw_PermissionFilter>;
  fields?: Maybe<DrawFieldsPermissions>;
};

export type PermissionsDataDrawsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDrawsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataQuickBooksIntegration = {
  create?: Maybe<PermissionsDataQuickBooksIntegrationCreate>;
  read?: Maybe<PermissionsDataQuickBooksIntegrationRead>;
  update?: Maybe<PermissionsDataQuickBooksIntegrationUpdate>;
  delete?: Maybe<PermissionsDataQuickBooksIntegrationDelete>;
  destroy?: Maybe<PermissionsDataQuickBooksIntegrationDestroy>;
};

export type PermissionsDataQuickBooksIntegrationCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataQuickBooksIntegrationRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<QuickBooksIntegration_PermissionFilter>;
  fields?: Maybe<QuickBooksIntegrationFieldsPermissions>;
};

/** QuickBooksIntegrationFieldsPermissions create input */
export type QuickBooksIntegrationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  accessToken?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['Boolean']>;
  expiresAt?: Maybe<Scalars['Boolean']>;
  realmId?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataQuickBooksIntegrationUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<QuickBooksIntegration_PermissionFilter>;
  fields?: Maybe<QuickBooksIntegrationFieldsPermissions>;
};

export type PermissionsDataQuickBooksIntegrationDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataQuickBooksIntegrationDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataStripeIntegration = {
  create?: Maybe<PermissionsDataStripeIntegrationCreate>;
  read?: Maybe<PermissionsDataStripeIntegrationRead>;
  update?: Maybe<PermissionsDataStripeIntegrationUpdate>;
  delete?: Maybe<PermissionsDataStripeIntegrationDelete>;
  destroy?: Maybe<PermissionsDataStripeIntegrationDestroy>;
};

export type PermissionsDataStripeIntegrationCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataStripeIntegrationRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<StripeIntegration_PermissionFilter>;
  fields?: Maybe<StripeIntegrationFieldsPermissions>;
};

/** StripeIntegrationFieldsPermissions create input */
export type StripeIntegrationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  accessToken?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataStripeIntegrationUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<StripeIntegration_PermissionFilter>;
  fields?: Maybe<StripeIntegrationFieldsPermissions>;
};

export type PermissionsDataStripeIntegrationDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataStripeIntegrationDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCompanyMonitoring = {
  create?: Maybe<PermissionsDataCompanyMonitoringCreate>;
  read?: Maybe<PermissionsDataCompanyMonitoringRead>;
  update?: Maybe<PermissionsDataCompanyMonitoringUpdate>;
  delete?: Maybe<PermissionsDataCompanyMonitoringDelete>;
  destroy?: Maybe<PermissionsDataCompanyMonitoringDestroy>;
};

export type PermissionsDataCompanyMonitoringCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCompanyMonitoringRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CompanyMonitoring_PermissionFilter>;
  fields?: Maybe<CompanyMonitoringFieldsPermissions>;
};

/** CompanyMonitoringFieldsPermissions create input */
export type CompanyMonitoringFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  committed?: Maybe<Scalars['Boolean']>;
  deployed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCompanyMonitoringUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CompanyMonitoring_PermissionFilter>;
  fields?: Maybe<CompanyMonitoringFieldsPermissions>;
};

export type PermissionsDataCompanyMonitoringDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCompanyMonitoringDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPlaidIntegration = {
  create?: Maybe<PermissionsDataPlaidIntegrationCreate>;
  read?: Maybe<PermissionsDataPlaidIntegrationRead>;
  update?: Maybe<PermissionsDataPlaidIntegrationUpdate>;
  delete?: Maybe<PermissionsDataPlaidIntegrationDelete>;
  destroy?: Maybe<PermissionsDataPlaidIntegrationDestroy>;
};

export type PermissionsDataPlaidIntegrationCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPlaidIntegrationRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<PlaidIntegration_PermissionFilter>;
  fields?: Maybe<PlaidIntegrationFieldsPermissions>;
};

/** PlaidIntegrationFieldsPermissions create input */
export type PlaidIntegrationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  accessToken?: Maybe<Scalars['Boolean']>;
  institutionMetadata?: Maybe<Scalars['Boolean']>;
  institutionId?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataPlaidIntegrationUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<PlaidIntegration_PermissionFilter>;
  fields?: Maybe<PlaidIntegrationFieldsPermissions>;
};

export type PermissionsDataPlaidIntegrationDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataPlaidIntegrationDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAsyncTasks = {
  create?: Maybe<PermissionsDataAsyncTasksCreate>;
  read?: Maybe<PermissionsDataAsyncTasksRead>;
  update?: Maybe<PermissionsDataAsyncTasksUpdate>;
  delete?: Maybe<PermissionsDataAsyncTasksDelete>;
  destroy?: Maybe<PermissionsDataAsyncTasksDestroy>;
};

export type PermissionsDataAsyncTasksCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAsyncTasksRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<AsyncTask_PermissionFilter>;
  fields?: Maybe<AsyncTaskFieldsPermissions>;
};

export type AsyncTask_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  name?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  argsHash?: Maybe<StringPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  AND?: Maybe<Array<AsyncTask_PermissionFilter>>;
  OR?: Maybe<Array<AsyncTask_PermissionFilter>>;
};

/** AsyncTaskFieldsPermissions create input */
export type AsyncTaskFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  args?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  result?: Maybe<Scalars['Boolean']>;
  argsHash?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataAsyncTasksUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<AsyncTask_PermissionFilter>;
  fields?: Maybe<AsyncTaskFieldsPermissions>;
};

export type PermissionsDataAsyncTasksDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataAsyncTasksDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessages = {
  create?: Maybe<PermissionsDataInboxMessagesCreate>;
  read?: Maybe<PermissionsDataInboxMessagesRead>;
  update?: Maybe<PermissionsDataInboxMessagesUpdate>;
  delete?: Maybe<PermissionsDataInboxMessagesDelete>;
  destroy?: Maybe<PermissionsDataInboxMessagesDestroy>;
};

export type PermissionsDataInboxMessagesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataInboxMessagesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<InboxMessage_PermissionFilter>;
  fields?: Maybe<InboxMessageFieldsPermissions>;
};

/** InboxMessageFieldsPermissions create input */
export type InboxMessageFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['Boolean']>;
  isRead?: Maybe<Scalars['Boolean']>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataInboxMessagesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<InboxMessage_PermissionFilter>;
  fields?: Maybe<InboxMessageFieldsPermissions>;
};

export type PermissionsDataInboxMessagesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataInboxMessagesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressStages = {
  create?: Maybe<PermissionsDataProgressStagesCreate>;
  read?: Maybe<PermissionsDataProgressStagesRead>;
  update?: Maybe<PermissionsDataProgressStagesUpdate>;
  delete?: Maybe<PermissionsDataProgressStagesDelete>;
  destroy?: Maybe<PermissionsDataProgressStagesDestroy>;
};

export type PermissionsDataProgressStagesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressStagesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressStage_PermissionFilter>;
  fields?: Maybe<ProgressStageFieldsPermissions>;
};

/** ProgressStageFieldsPermissions create input */
export type ProgressStageFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressStagesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressStage_PermissionFilter>;
  fields?: Maybe<ProgressStageFieldsPermissions>;
};

export type PermissionsDataProgressStagesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressStagesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressStepValues = {
  create?: Maybe<PermissionsDataProgressStepValuesCreate>;
  read?: Maybe<PermissionsDataProgressStepValuesRead>;
  update?: Maybe<PermissionsDataProgressStepValuesUpdate>;
  delete?: Maybe<PermissionsDataProgressStepValuesDelete>;
  destroy?: Maybe<PermissionsDataProgressStepValuesDestroy>;
};

export type PermissionsDataProgressStepValuesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressStepValuesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressStepValue_PermissionFilter>;
  fields?: Maybe<ProgressStepValueFieldsPermissions>;
};

/** ProgressStepValueFieldsPermissions create input */
export type ProgressStepValueFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  customStepName?: Maybe<Scalars['Boolean']>;
  customStepComment?: Maybe<Scalars['Boolean']>;
  bigfootTeamComment?: Maybe<Scalars['Boolean']>;
  applicantComment?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressStepValuesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressStepValue_PermissionFilter>;
  fields?: Maybe<ProgressStepValueFieldsPermissions>;
};

export type PermissionsDataProgressStepValuesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressStepValuesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressSteps = {
  create?: Maybe<PermissionsDataProgressStepsCreate>;
  read?: Maybe<PermissionsDataProgressStepsRead>;
  update?: Maybe<PermissionsDataProgressStepsUpdate>;
  delete?: Maybe<PermissionsDataProgressStepsDelete>;
  destroy?: Maybe<PermissionsDataProgressStepsDestroy>;
};

export type PermissionsDataProgressStepsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressStepsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressStep_PermissionFilter>;
  fields?: Maybe<ProgressStepFieldsPermissions>;
};

/** ProgressStepFieldsPermissions create input */
export type ProgressStepFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['Boolean']>;
  helperText?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressStepsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressStep_PermissionFilter>;
  fields?: Maybe<ProgressStepFieldsPermissions>;
};

export type PermissionsDataProgressStepsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressStepsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPlaidIntegrationAccounts = {
  create?: Maybe<PermissionsDataPlaidIntegrationAccountsCreate>;
  read?: Maybe<PermissionsDataPlaidIntegrationAccountsRead>;
  update?: Maybe<PermissionsDataPlaidIntegrationAccountsUpdate>;
  delete?: Maybe<PermissionsDataPlaidIntegrationAccountsDelete>;
  destroy?: Maybe<PermissionsDataPlaidIntegrationAccountsDestroy>;
};

export type PermissionsDataPlaidIntegrationAccountsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataPlaidIntegrationAccountsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<PlaidIntegrationAccount_PermissionFilter>;
  fields?: Maybe<PlaidIntegrationAccountFieldsPermissions>;
};

/** PlaidIntegrationAccountFieldsPermissions create input */
export type PlaidIntegrationAccountFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  accountId?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  mask?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataPlaidIntegrationAccountsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<PlaidIntegrationAccount_PermissionFilter>;
  fields?: Maybe<PlaidIntegrationAccountFieldsPermissions>;
};

export type PermissionsDataPlaidIntegrationAccountsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataPlaidIntegrationAccountsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressCompanySteps = {
  create?: Maybe<PermissionsDataProgressCompanyStepsCreate>;
  read?: Maybe<PermissionsDataProgressCompanyStepsRead>;
  update?: Maybe<PermissionsDataProgressCompanyStepsUpdate>;
  delete?: Maybe<PermissionsDataProgressCompanyStepsDelete>;
  destroy?: Maybe<PermissionsDataProgressCompanyStepsDestroy>;
};

export type PermissionsDataProgressCompanyStepsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataProgressCompanyStepsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressCompanyStep_PermissionFilter>;
  fields?: Maybe<ProgressCompanyStepFieldsPermissions>;
};

/** ProgressCompanyStepFieldsPermissions create input */
export type ProgressCompanyStepFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  bigfootTeamComment?: Maybe<Scalars['Boolean']>;
  applicantComment?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressCompanyStepsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<ProgressCompanyStep_PermissionFilter>;
  fields?: Maybe<ProgressCompanyStepFieldsPermissions>;
};

export type PermissionsDataProgressCompanyStepsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataProgressCompanyStepsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSortablePortfolioCompanies = {
  create?: Maybe<PermissionsDataSortablePortfolioCompaniesCreate>;
  read?: Maybe<PermissionsDataSortablePortfolioCompaniesRead>;
  update?: Maybe<PermissionsDataSortablePortfolioCompaniesUpdate>;
  delete?: Maybe<PermissionsDataSortablePortfolioCompaniesDelete>;
  destroy?: Maybe<PermissionsDataSortablePortfolioCompaniesDestroy>;
};

export type PermissionsDataSortablePortfolioCompaniesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSortablePortfolioCompaniesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<SortablePortfolioCompany_PermissionFilter>;
  fields?: Maybe<SortablePortfolioCompanyFieldsPermissions>;
};

export type SortablePortfolioCompany_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  active?: Maybe<BoolPredicate>;
  name?: Maybe<StringPredicate>;
  contactFirstName?: Maybe<StringPredicate>;
  contactLastName?: Maybe<StringPredicate>;
  fundedDate?: Maybe<DatePredicate>;
  structure?: Maybe<StringPredicate>;
  deployed?: Maybe<IntPredicate>;
  committed?: Maybe<IntPredicate>;
  term?: Maybe<IntPredicate>;
  finalAdvanceRate?: Maybe<DatePredicate>;
  nextAvailableDraw?: Maybe<DatePredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  AND?: Maybe<Array<SortablePortfolioCompany_PermissionFilter>>;
  OR?: Maybe<Array<SortablePortfolioCompany_PermissionFilter>>;
};

/** SortablePortfolioCompanyFieldsPermissions create input */
export type SortablePortfolioCompanyFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  contactFirstName?: Maybe<Scalars['Boolean']>;
  contactLastName?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Boolean']>;
  structure?: Maybe<Scalars['Boolean']>;
  deployed?: Maybe<Scalars['Boolean']>;
  committed?: Maybe<Scalars['Boolean']>;
  term?: Maybe<Scalars['Boolean']>;
  finalAdvanceRate?: Maybe<Scalars['Boolean']>;
  nextAvailableDraw?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataSortablePortfolioCompaniesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<SortablePortfolioCompany_PermissionFilter>;
  fields?: Maybe<SortablePortfolioCompanyFieldsPermissions>;
};

export type PermissionsDataSortablePortfolioCompaniesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataSortablePortfolioCompaniesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSortableLoanApplications = {
  create?: Maybe<PermissionsDataSortableLoanApplicationsCreate>;
  read?: Maybe<PermissionsDataSortableLoanApplicationsRead>;
  update?: Maybe<PermissionsDataSortableLoanApplicationsUpdate>;
  delete?: Maybe<PermissionsDataSortableLoanApplicationsDelete>;
  destroy?: Maybe<PermissionsDataSortableLoanApplicationsDestroy>;
};

export type PermissionsDataSortableLoanApplicationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataSortableLoanApplicationsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<SortableLoanApplication_PermissionFilter>;
  fields?: Maybe<SortableLoanApplicationFieldsPermissions>;
};

export type SortableLoanApplication_PermissionFilter = {
  id?: Maybe<IdPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  stage?: Maybe<StringPredicate>;
  contactName?: Maybe<StringPredicate>;
  applicantTitle?: Maybe<StringPredicate>;
  targetCloseDate?: Maybe<DatePredicate>;
  amountRequested?: Maybe<IntPredicate>;
  amountCommitted?: Maybe<IntPredicate>;
  firstDrawProposed?: Maybe<IntPredicate>;
  structure?: Maybe<StringPredicate>;
  status?: Maybe<BoolPredicate>;
  companyId?: Maybe<StringPredicate>;
  companyName?: Maybe<StringPredicate>;
  contactFirstName?: Maybe<StringPredicate>;
  contactLastName?: Maybe<StringPredicate>;
  ownerFirstName?: Maybe<StringPredicate>;
  ownerLastName?: Maybe<StringPredicate>;
  ownerTitle?: Maybe<StringPredicate>;
  ownerId?: Maybe<StringPredicate>;
  age?: Maybe<IntPredicate>;
  committed?: Maybe<IntPredicate>;
  saasScore?: Maybe<FloatPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User_PermissionFilter>;
  AND?: Maybe<Array<SortableLoanApplication_PermissionFilter>>;
  OR?: Maybe<Array<SortableLoanApplication_PermissionFilter>>;
};

/** SortableLoanApplicationFieldsPermissions create input */
export type SortableLoanApplicationFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Scalars['Boolean']>;
  contactName?: Maybe<Scalars['Boolean']>;
  applicantTitle?: Maybe<Scalars['Boolean']>;
  targetCloseDate?: Maybe<Scalars['Boolean']>;
  amountRequested?: Maybe<Scalars['Boolean']>;
  amountCommitted?: Maybe<Scalars['Boolean']>;
  firstDrawProposed?: Maybe<Scalars['Boolean']>;
  structure?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['Boolean']>;
  contactFirstName?: Maybe<Scalars['Boolean']>;
  contactLastName?: Maybe<Scalars['Boolean']>;
  ownerFirstName?: Maybe<Scalars['Boolean']>;
  ownerLastName?: Maybe<Scalars['Boolean']>;
  ownerTitle?: Maybe<Scalars['Boolean']>;
  ownerId?: Maybe<Scalars['Boolean']>;
  age?: Maybe<Scalars['Boolean']>;
  committed?: Maybe<Scalars['Boolean']>;
  saasScore?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataSortableLoanApplicationsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<SortableLoanApplication_PermissionFilter>;
  fields?: Maybe<SortableLoanApplicationFieldsPermissions>;
};

export type PermissionsDataSortableLoanApplicationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataSortableLoanApplicationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCashMonitoringRecords = {
  create?: Maybe<PermissionsDataCashMonitoringRecordsCreate>;
  read?: Maybe<PermissionsDataCashMonitoringRecordsRead>;
  update?: Maybe<PermissionsDataCashMonitoringRecordsUpdate>;
  delete?: Maybe<PermissionsDataCashMonitoringRecordsDelete>;
  destroy?: Maybe<PermissionsDataCashMonitoringRecordsDestroy>;
};

export type PermissionsDataCashMonitoringRecordsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCashMonitoringRecordsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CashMonitoringRecord_PermissionFilter>;
  fields?: Maybe<CashMonitoringRecordFieldsPermissions>;
};

/** CashMonitoringRecordFieldsPermissions create input */
export type CashMonitoringRecordFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCashMonitoringRecordsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CashMonitoringRecord_PermissionFilter>;
  fields?: Maybe<CashMonitoringRecordFieldsPermissions>;
};

export type PermissionsDataCashMonitoringRecordsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCashMonitoringRecordsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCovenants = {
  create?: Maybe<PermissionsDataCovenantsCreate>;
  read?: Maybe<PermissionsDataCovenantsRead>;
  update?: Maybe<PermissionsDataCovenantsUpdate>;
  delete?: Maybe<PermissionsDataCovenantsDelete>;
  destroy?: Maybe<PermissionsDataCovenantsDestroy>;
};

export type PermissionsDataCovenantsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCovenantsRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Covenant_PermissionFilter>;
  fields?: Maybe<CovenantFieldsPermissions>;
};

/** CovenantFieldsPermissions create input */
export type CovenantFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCovenantsUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<Covenant_PermissionFilter>;
  fields?: Maybe<CovenantFieldsPermissions>;
};

export type PermissionsDataCovenantsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCovenantsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCovenantValues = {
  create?: Maybe<PermissionsDataCovenantValuesCreate>;
  read?: Maybe<PermissionsDataCovenantValuesRead>;
  update?: Maybe<PermissionsDataCovenantValuesUpdate>;
  delete?: Maybe<PermissionsDataCovenantValuesDelete>;
  destroy?: Maybe<PermissionsDataCovenantValuesDestroy>;
};

export type PermissionsDataCovenantValuesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCovenantValuesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CovenantValue_PermissionFilter>;
  fields?: Maybe<CovenantValueFieldsPermissions>;
};

/** CovenantValueFieldsPermissions create input */
export type CovenantValueFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCovenantValuesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CovenantValue_PermissionFilter>;
  fields?: Maybe<CovenantValueFieldsPermissions>;
};

export type PermissionsDataCovenantValuesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCovenantValuesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFacilityHistories = {
  create?: Maybe<PermissionsDataFacilityHistoriesCreate>;
  read?: Maybe<PermissionsDataFacilityHistoriesRead>;
  update?: Maybe<PermissionsDataFacilityHistoriesUpdate>;
  delete?: Maybe<PermissionsDataFacilityHistoriesDelete>;
  destroy?: Maybe<PermissionsDataFacilityHistoriesDestroy>;
};

export type PermissionsDataFacilityHistoriesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFacilityHistoriesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<FacilityHistory_PermissionFilter>;
  fields?: Maybe<FacilityHistoryFieldsPermissions>;
};

/** FacilityHistoryFieldsPermissions create input */
export type FacilityHistoryFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFacilityHistoriesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<FacilityHistory_PermissionFilter>;
  fields?: Maybe<FacilityHistoryFieldsPermissions>;
};

export type PermissionsDataFacilityHistoriesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFacilityHistoriesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFacilityHistoryValues = {
  create?: Maybe<PermissionsDataFacilityHistoryValuesCreate>;
  read?: Maybe<PermissionsDataFacilityHistoryValuesRead>;
  update?: Maybe<PermissionsDataFacilityHistoryValuesUpdate>;
  delete?: Maybe<PermissionsDataFacilityHistoryValuesDelete>;
  destroy?: Maybe<PermissionsDataFacilityHistoryValuesDestroy>;
};

export type PermissionsDataFacilityHistoryValuesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFacilityHistoryValuesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<FacilityHistoryValue_PermissionFilter>;
  fields?: Maybe<FacilityHistoryValueFieldsPermissions>;
};

/** FacilityHistoryValueFieldsPermissions create input */
export type FacilityHistoryValueFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Boolean']>;
  advanceRate?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFacilityHistoryValuesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<FacilityHistoryValue_PermissionFilter>;
  fields?: Maybe<FacilityHistoryValueFieldsPermissions>;
};

export type PermissionsDataFacilityHistoryValuesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFacilityHistoryValuesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDrawHistories = {
  create?: Maybe<PermissionsDataDrawHistoriesCreate>;
  read?: Maybe<PermissionsDataDrawHistoriesRead>;
  update?: Maybe<PermissionsDataDrawHistoriesUpdate>;
  delete?: Maybe<PermissionsDataDrawHistoriesDelete>;
  destroy?: Maybe<PermissionsDataDrawHistoriesDestroy>;
};

export type PermissionsDataDrawHistoriesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDrawHistoriesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<DrawHistory_PermissionFilter>;
  fields?: Maybe<DrawHistoryFieldsPermissions>;
};

/** DrawHistoryFieldsPermissions create input */
export type DrawHistoryFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDrawHistoriesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<DrawHistory_PermissionFilter>;
  fields?: Maybe<DrawHistoryFieldsPermissions>;
};

export type PermissionsDataDrawHistoriesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDrawHistoriesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDrawHistoryValues = {
  create?: Maybe<PermissionsDataDrawHistoryValuesCreate>;
  read?: Maybe<PermissionsDataDrawHistoryValuesRead>;
  update?: Maybe<PermissionsDataDrawHistoryValuesUpdate>;
  delete?: Maybe<PermissionsDataDrawHistoryValuesDelete>;
  destroy?: Maybe<PermissionsDataDrawHistoryValuesDestroy>;
};

export type PermissionsDataDrawHistoryValuesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDrawHistoryValuesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<DrawHistoryValue_PermissionFilter>;
  fields?: Maybe<DrawHistoryValueFieldsPermissions>;
};

/** DrawHistoryValueFieldsPermissions create input */
export type DrawHistoryValueFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDrawHistoryValuesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<DrawHistoryValue_PermissionFilter>;
  fields?: Maybe<DrawHistoryValueFieldsPermissions>;
};

export type PermissionsDataDrawHistoryValuesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDrawHistoryValuesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomCovenantValues = {
  create?: Maybe<PermissionsDataCustomCovenantValuesCreate>;
  read?: Maybe<PermissionsDataCustomCovenantValuesRead>;
  update?: Maybe<PermissionsDataCustomCovenantValuesUpdate>;
  delete?: Maybe<PermissionsDataCustomCovenantValuesDelete>;
  destroy?: Maybe<PermissionsDataCustomCovenantValuesDestroy>;
};

export type PermissionsDataCustomCovenantValuesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomCovenantValuesRead = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CustomCovenantValue_PermissionFilter>;
  fields?: Maybe<CustomCovenantValueFieldsPermissions>;
};

/** CustomCovenantValueFieldsPermissions create input */
export type CustomCovenantValueFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
  difference?: Maybe<Scalars['Boolean']>;
  inCompliance?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCustomCovenantValuesUpdate = {
  allow: Scalars['Boolean'];
  filter?: Maybe<CustomCovenantValue_PermissionFilter>;
  fields?: Maybe<CustomCovenantValueFieldsPermissions>;
};

export type PermissionsDataCustomCovenantValuesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCustomCovenantValuesDestroy = {
  allow: Scalars['Boolean'];
};

/** custom permissions input */
export type PermissionsCustom = {
  data?: Maybe<PermissionsCustomData>;
  logic?: Maybe<PermissionsCustomLogic>;
  api?: Maybe<PermissionsCustomApi>;
  users?: Maybe<PermissionsCustomUsers>;
  settings?: Maybe<PermissionsCustomSettings>;
};

export type PermissionsCustomData = {
  schemaManagement?: Maybe<PermissionsCustomDataSchemaManagement>;
  viewerAccess?: Maybe<PermissionsCustomDataViewerAccess>;
};

export type PermissionsCustomDataSchemaManagement = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomDataViewerAccess = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogic = {
  view?: Maybe<PermissionsCustomLogicView>;
  deploy?: Maybe<PermissionsCustomLogicDeploy>;
  invoke?: Maybe<PermissionsCustomLogicInvoke>;
  logs?: Maybe<PermissionsCustomLogicLogs>;
};

export type PermissionsCustomLogicView = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicDeploy = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicInvoke = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicLogs = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomApi = {
  eventResolver?: Maybe<PermissionsCustomApiResolver>;
  eventResolverQuery?: Maybe<PermissionsCustomApiResolver>;
  downloadPdf?: Maybe<PermissionsCustomApiResolver>;
  calculateSaaSLoanOptions?: Maybe<PermissionsCustomApiResolver>;
  calculateMetrics?: Maybe<PermissionsCustomApiResolver>;
  calculateSaaSScoreComponents?: Maybe<PermissionsCustomApiResolver>;
  calculateSaaSScore?: Maybe<PermissionsCustomApiResolver>;
  calculatePortfolioMonitoring?: Maybe<PermissionsCustomApiResolver>;
  getClosingDate?: Maybe<PermissionsCustomApiResolver>;
  getFundingDate?: Maybe<PermissionsCustomApiResolver>;
  getTotalAmountDeployed?: Maybe<PermissionsCustomApiResolver>;
  getAmountCommitted?: Maybe<PermissionsCustomApiResolver>;
  getPortfolioOverviewReport?: Maybe<PermissionsCustomApiResolver>;
  metricValueUpsert?: Maybe<PermissionsCustomApiResolver>;
  metricValueUpsertMany?: Maybe<PermissionsCustomApiResolver>;
  plaidGetLinkToken?: Maybe<PermissionsCustomApiResolver>;
  plaidSaveToken?: Maybe<PermissionsCustomApiResolver>;
  plaidDeleteToken?: Maybe<PermissionsCustomApiResolver>;
  plaidUpdateToken?: Maybe<PermissionsCustomApiResolver>;
  plaidRequestMetricsUpdate?: Maybe<PermissionsCustomApiResolver>;
  stripeGetAuthUrl?: Maybe<PermissionsCustomApiResolver>;
  stripeDeleteToken?: Maybe<PermissionsCustomApiResolver>;
  quickBooksGetAuthUrl?: Maybe<PermissionsCustomApiResolver>;
  quickBooksDeleteToken?: Maybe<PermissionsCustomApiResolver>;
  quickBooksRequestMetricsUpdate?: Maybe<PermissionsCustomApiResolver>;
  recalculateTotalAmountDeployedFacility?: Maybe<PermissionsCustomApiResolver>;
  xeroGetAuthUrl?: Maybe<PermissionsCustomApiResolver>;
  xeroDeleteToken?: Maybe<PermissionsCustomApiResolver>;
  xeroRequestMetricsUpdate?: Maybe<PermissionsCustomApiResolver>;
  tableExport?: Maybe<PermissionsCustomApiResolver>;
  getStatementsMetricsMutation?: Maybe<PermissionsCustomApiResolver>;
  getStatementsMetricsQuery?: Maybe<PermissionsCustomApiResolver>;
  updateMetricSettings?: Maybe<PermissionsCustomApiResolver>;
  createGreetingInboxMessage?: Maybe<PermissionsCustomApiResolver>;
  invitationSend?: Maybe<PermissionsCustomApiResolver>;
  invitationResend?: Maybe<PermissionsCustomApiResolver>;
  invitationCancel?: Maybe<PermissionsCustomApiResolver>;
};

export type PermissionsCustomApiResolver = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomUsers = {
  teamAdministration?: Maybe<PermissionsCustomUsersTeamAdministration>;
};

export type PermissionsCustomUsersTeamAdministration = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomSettings = {
  workspaceAdministration?: Maybe<PermissionsCustomSettingsWorkspaceAdministration>;
};

export type PermissionsCustomSettingsWorkspaceAdministration = {
  allow: Scalars['Boolean'];
};

/** Roles relation input */
export type RolesApiTokensRelationInput = {
  connect?: Maybe<Array<ApiTokenKeyFilter>>;
};

export type ApiTokenKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesRelationInput = {
  connect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_AuthenticationProfileCreateInput>>>;
};

export type AuthenticationProfileKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** AuthenticationProfiles create input from roles */
export type Roles_AuthenticationProfileCreateInput = {
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<AuthenticationProfilesRolesRelationInput>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<AuthenticationProfiles_RoleCreateInput>>>;
};

/** Roles create input from authenticationProfiles */
export type AuthenticationProfiles_RoleCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersRelationInput>;
  permissions?: Maybe<PermissionsInput>;
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersRelationInput>;
};

/** Roles relation input */
export type RolesTeamMembersRelationInput = {
  connect?: Maybe<Array<TeamMemberKeyFilter>>;
};

export type TeamMemberKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Users relation input */
export type UsersInvitationRelationInput = {
  connect?: Maybe<InvitationKeyFilter>;
  create?: Maybe<InvitedUser_InvitationCreateInput>;
};

export type InvitationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Invitations create input from invitedUser */
export type InvitedUser_InvitationCreateInput = {
  invitedUser?: Maybe<InvitationsInvitedUserRelationInput>;
  meta?: Maybe<Scalars['JSON']>;
};

/** Invitations relation input */
export type InvitationsInvitedUserRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<Invitation_UserCreateInput>;
};

/** Users create input from invitation */
export type Invitation_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** Users relation input */
export type UsersCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Owner_CompanyCreateInput>;
};

export type CompanyKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Companies create input from owner */
export type Owner_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** SmartAddressInput create input */
export type SmartAddressInput = {
  country?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** Companies relation input */
export type CompaniesLogoRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<PortfolioCompanies_Logo_FileCreateInput>;
};

/** Files create input from portfolioCompanies_logo */
export type PortfolioCompanies_Logo_FileCreateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsRelationInput>;
};

/** Files relation input */
export type FilesTeamMembers_AvatarRelationInput = {
  connect?: Maybe<Array<TeamMemberKeyFilter>>;
};

/** Files relation input */
export type FilesPortfolioCompanies_LogoRelationInput = {
  connect?: Maybe<Array<CompanyKeyFilter>>;
  create?: Maybe<Array<Maybe<Logo_CompanyCreateInput>>>;
};

/** Companies create input from logo */
export type Logo_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesNotesRelationInput = {
  connect?: Maybe<Array<NoteKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_NoteCreateInput>>>;
};

export type NoteKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Notes create input from company */
export type Company_NoteCreateInput = {
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<NotesAttachmentsRelationInput>;
  company?: Maybe<NotesCompanyRelationInput>;
};

/** Notes relation input */
export type NotesAttachmentsRelationInput = {
  connect?: Maybe<Array<FileKeyFilter>>;
  create?: Maybe<Array<Maybe<Notes_Attachments_FileCreateInput>>>;
};

/** Files create input from notes_attachments */
export type Notes_Attachments_FileCreateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsRelationInput>;
};

/** Files relation input */
export type FilesDocuments_FileRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<File_DocumentCreateInput>>>;
};

export type DocumentKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Documents create input from file */
export type File_DocumentCreateInput = {
  file?: Maybe<DocumentsFileRelationInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageRelationInput>;
};

/** Documents relation input */
export type DocumentsFileRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<Documents_File_FileCreateInput>;
};

/** Files create input from documents_file */
export type Documents_File_FileCreateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsRelationInput>;
};

/** Files relation input */
export type FilesNotes_AttachmentsRelationInput = {
  connect?: Maybe<Array<NoteKeyFilter>>;
  create?: Maybe<Array<Maybe<Attachments_NoteCreateInput>>>;
};

/** Notes create input from attachments */
export type Attachments_NoteCreateInput = {
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<NotesAttachmentsRelationInput>;
  company?: Maybe<NotesCompanyRelationInput>;
};

/** Notes relation input */
export type NotesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Notes_CompanyCreateInput>;
};

/** Companies create input from notes */
export type Notes_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesLoanApplicationRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
  create?: Maybe<Company_LoanApplicationCreateInput>;
};

export type LoanApplicationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** LoanApplications create input from company */
export type Company_LoanApplicationCreateInput = {
  company?: Maybe<LoanApplicationsCompanyRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications relation input */
export type LoanApplicationsCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<LoanApplication_CompanyCreateInput>;
};

/** Companies create input from loanApplication */
export type LoanApplication_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesDocumentsRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_DocumentCreateInput>>>;
};

/** Documents create input from company */
export type Company_DocumentCreateInput = {
  file?: Maybe<DocumentsFileRelationInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageRelationInput>;
};

/** Documents relation input */
export type DocumentsCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Documents_CompanyCreateInput>;
};

/** Companies create input from documents */
export type Documents_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesFacilitiesRelationInput = {
  connect?: Maybe<Array<FacilityKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_FacilityCreateInput>>>;
};

export type FacilityKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Facilities create input from company */
export type Company_FacilityCreateInput = {
  structure: Scalars['String'];
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryRelationInput>;
};

/** Facilities relation input */
export type FacilitiesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Facilities_CompanyCreateInput>;
};

/** Companies create input from facilities */
export type Facilities_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesOwnerRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<Company_UserCreateInput>;
};

/** Users create input from company */
export type Company_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** Users relation input */
export type UsersEmployerRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Team_CompanyCreateInput>;
};

/** Companies create input from team */
export type Team_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesIntegrationRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Company_IntegrationCreateInput>;
};

export type IntegrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Integrations create input from company */
export type Company_IntegrationCreateInput = {
  company?: Maybe<IntegrationsCompanyRelationInput>;
  xero?: Maybe<IntegrationsXeroRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksRelationInput>;
  stripe?: Maybe<IntegrationsStripeRelationInput>;
  plaid?: Maybe<IntegrationsPlaidRelationInput>;
};

/** Integrations relation input */
export type IntegrationsCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Integration_CompanyCreateInput>;
};

/** Companies create input from integration */
export type Integration_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesPortfolioCompanyRelationInput = {
  connect?: Maybe<PortfolioCompanyKeyFilter>;
  create?: Maybe<Company_PortfolioCompanyCreateInput>;
};

export type PortfolioCompanyKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** PortfolioCompanies create input from company */
export type Company_PortfolioCompanyCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsRelationInput>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<PortfolioCompany_CompanyCreateInput>;
};

/** Companies create input from portfolioCompany */
export type PortfolioCompany_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesTeamRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Employer_UserCreateInput>>>;
};

/** Users create input from employer */
export type Employer_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** Users relation input */
export type UsersLoanApplicationsRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
  create?: Maybe<DealTeam_LoanApplicationCreateInput>;
};

/** LoanApplications create input from dealTeam */
export type DealTeam_LoanApplicationCreateInput = {
  company?: Maybe<LoanApplicationsCompanyRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications relation input */
export type LoanApplicationsDocumentsRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<LoanApplication_DocumentCreateInput>>>;
};

/** Documents create input from loanApplication */
export type LoanApplication_DocumentCreateInput = {
  file?: Maybe<DocumentsFileRelationInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageRelationInput>;
};

/** Documents relation input */
export type DocumentsLoanApplicationRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
  create?: Maybe<Documents_LoanApplicationCreateInput>;
};

/** LoanApplications create input from documents */
export type Documents_LoanApplicationCreateInput = {
  company?: Maybe<LoanApplicationsCompanyRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications relation input */
export type LoanApplicationsDealTeamRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<LoanApplications_UserCreateInput>>>;
};

/** Users create input from loanApplications */
export type LoanApplications_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** Users relation input */
export type UsersPortfolioCompaniesRelationInput = {
  connect?: Maybe<Array<PortfolioCompanyKeyFilter>>;
  create?: Maybe<Array<Maybe<DealTeam_PortfolioCompanyCreateInput>>>;
};

/** PortfolioCompanies create input from dealTeam */
export type DealTeam_PortfolioCompanyCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsRelationInput>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesDealTeamRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<PortfolioCompanies_UserCreateInput>>>;
};

/** Users create input from portfolioCompanies */
export type PortfolioCompanies_UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesCashMonitoringRecordsRelationInput = {
  connect?: Maybe<Array<CashMonitoringRecordKeyFilter>>;
  create?: Maybe<Array<Maybe<PortfolioCompany_CashMonitoringRecordCreateInput>>>;
};

export type CashMonitoringRecordKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CashMonitoringRecords create input from portfolioCompany */
export type PortfolioCompany_CashMonitoringRecordCreateInput = {
  portfolioCompany?: Maybe<CashMonitoringRecordsPortfolioCompanyRelationInput>;
  date: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

/** CashMonitoringRecords relation input */
export type CashMonitoringRecordsPortfolioCompanyRelationInput = {
  connect?: Maybe<PortfolioCompanyKeyFilter>;
  create?: Maybe<CashMonitoringRecords_PortfolioCompanyCreateInput>;
};

/** PortfolioCompanies create input from cashMonitoringRecords */
export type CashMonitoringRecords_PortfolioCompanyCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsRelationInput>;
};

/** Documents relation input */
export type DocumentsInboxMessageRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
  create?: Maybe<Array<Maybe<Attachments_InboxMessageCreateInput>>>;
};

export type InboxMessageKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** InboxMessages create input from attachments */
export type Attachments_InboxMessageCreateInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsRelationInput>;
  company?: Maybe<InboxMessagesCompanyRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnRelationInput>;
  replies?: Maybe<InboxMessagesRepliesRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesAttachmentsRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<InboxMessage_DocumentCreateInput>>>;
};

/** Documents create input from inboxMessage */
export type InboxMessage_DocumentCreateInput = {
  file?: Maybe<DocumentsFileRelationInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<InboxMessages_CompanyCreateInput>;
};

/** Companies create input from inboxMessages */
export type InboxMessages_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesMetricsRelationInput = {
  connect?: Maybe<Array<MetricValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_MetricValueCreateInput>>>;
};

export type MetricValueKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** MetricValues create input from company */
export type Company_MetricValueCreateInput = {
  metric: MetricValuesMetricRelationInput;
  company?: Maybe<MetricValuesCompanyRelationInput>;
  source: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  period?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

/** MetricValues relation input */
export type MetricValuesMetricRelationInput = {
  connect?: Maybe<MetricKeyFilter>;
  create?: Maybe<Values_MetricCreateInput>;
};

export type MetricKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

/** Metrics create input from values */
export type Values_MetricCreateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  values?: Maybe<MetricsValuesRelationInput>;
  format?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
};

/** Metrics relation input */
export type MetricsValuesRelationInput = {
  connect?: Maybe<Array<MetricValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Metric_MetricValueCreateInput>>>;
};

/** MetricValues create input from metric */
export type Metric_MetricValueCreateInput = {
  metric?: Maybe<MetricValuesMetricRelationInput>;
  company?: Maybe<MetricValuesCompanyRelationInput>;
  source: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  period?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

/** MetricValues relation input */
export type MetricValuesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Metrics_CompanyCreateInput>;
};

/** Companies create input from metrics */
export type Metrics_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesMonitoringValuesRelationInput = {
  connect?: Maybe<Array<CompanyMonitoringKeyFilter>>;
  create?: Maybe<Array<Maybe<Companies_CompanyMonitoringCreateInput>>>;
};

export type CompanyMonitoringKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CompanyMonitoring create input from companies */
export type Companies_CompanyMonitoringCreateInput = {
  committed?: Maybe<Scalars['Int']>;
  deployed?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanyMonitoringCompaniesRelationInput>;
  date?: Maybe<Scalars['Date']>;
};

/** CompanyMonitoring relation input */
export type CompanyMonitoringCompaniesRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<MonitoringValues_CompanyCreateInput>;
};

/** Companies create input from monitoringValues */
export type MonitoringValues_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesCompanyRelationInput = {
  connect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_ProgressStepValueCreateInput>>>;
};

export type ProgressStepValueKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** ProgressStepValues create input from company */
export type Company_ProgressStepValueCreateInput = {
  company?: Maybe<ProgressStepValuesCompanyRelationInput>;
  status?: Maybe<Scalars['String']>;
  step: ProgressStepValuesStepRelationInput;
  customStepName?: Maybe<Scalars['String']>;
  customStepComment?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressStepValues relation input */
export type ProgressStepValuesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Company_CompanyCreateInput>;
};

/** Companies create input from company */
export type Company_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesInboxMessagesRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_InboxMessageCreateInput>>>;
};

/** InboxMessages create input from company */
export type Company_InboxMessageCreateInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsRelationInput>;
  company?: Maybe<InboxMessagesCompanyRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnRelationInput>;
  replies?: Maybe<InboxMessagesRepliesRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesRepliedOnRelationInput = {
  connect?: Maybe<InboxMessageKeyFilter>;
  create?: Maybe<Replies_InboxMessageCreateInput>;
};

/** InboxMessages create input from replies */
export type Replies_InboxMessageCreateInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsRelationInput>;
  company?: Maybe<InboxMessagesCompanyRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnRelationInput>;
  replies?: Maybe<InboxMessagesRepliesRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesRepliesRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
  create?: Maybe<Array<Maybe<RepliedOn_InboxMessageCreateInput>>>;
};

/** InboxMessages create input from repliedOn */
export type RepliedOn_InboxMessageCreateInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsRelationInput>;
  company?: Maybe<InboxMessagesCompanyRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnRelationInput>;
  replies?: Maybe<InboxMessagesRepliesRelationInput>;
};

/** Companies relation input */
export type CompaniesCompanyStepRelationInput = {
  connect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_ProgressCompanyStepCreateInput>>>;
};

export type ProgressCompanyStepKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** ProgressCompanySteps create input from company */
export type Company_ProgressCompanyStepCreateInput = {
  stage: ProgressCompanyStepsStageRelationInput;
  company?: Maybe<ProgressCompanyStepsCompanyRelationInput>;
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressCompanySteps relation input */
export type ProgressCompanyStepsStageRelationInput = {
  connect?: Maybe<ProgressStageKeyFilter>;
  create?: Maybe<CompanySteps_ProgressStageCreateInput>;
};

export type ProgressStageKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** ProgressStages create input from companySteps */
export type CompanySteps_ProgressStageCreateInput = {
  name: Scalars['String'];
  steps?: Maybe<ProgressStagesStepsRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsRelationInput>;
};

/** ProgressStages relation input */
export type ProgressStagesStepsRelationInput = {
  connect?: Maybe<Array<ProgressStepKeyFilter>>;
  create?: Maybe<Array<Maybe<Stage_ProgressStepCreateInput>>>;
};

export type ProgressStepKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

/** ProgressSteps create input from stage */
export type Stage_ProgressStepCreateInput = {
  stage?: Maybe<ProgressStepsStageRelationInput>;
  stepValue?: Maybe<ProgressStepsStepValueRelationInput>;
  name: Scalars['String'];
  code: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
};

/** ProgressSteps relation input */
export type ProgressStepsStageRelationInput = {
  connect?: Maybe<ProgressStageKeyFilter>;
  create?: Maybe<Steps_ProgressStageCreateInput>;
};

/** ProgressStages create input from steps */
export type Steps_ProgressStageCreateInput = {
  name: Scalars['String'];
  steps?: Maybe<ProgressStagesStepsRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsRelationInput>;
};

/** ProgressStages relation input */
export type ProgressStagesCompanyStepsRelationInput = {
  connect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  create?: Maybe<Array<Maybe<Stage_ProgressCompanyStepCreateInput>>>;
};

/** ProgressCompanySteps create input from stage */
export type Stage_ProgressCompanyStepCreateInput = {
  stage?: Maybe<ProgressCompanyStepsStageRelationInput>;
  company?: Maybe<ProgressCompanyStepsCompanyRelationInput>;
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressCompanySteps relation input */
export type ProgressCompanyStepsCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<CompanyStep_CompanyCreateInput>;
};

/** Companies create input from companyStep */
export type CompanyStep_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesCovenantValueRelationInput = {
  connect?: Maybe<Array<CovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_CovenantValueCreateInput>>>;
};

export type CovenantValueKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CovenantValues create input from company */
export type Company_CovenantValueCreateInput = {
  covenant?: Maybe<CovenantValuesCovenantRelationInput>;
  company?: Maybe<CovenantValuesCompanyRelationInput>;
  value?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

/** CovenantValues relation input */
export type CovenantValuesCovenantRelationInput = {
  connect?: Maybe<CovenantKeyFilter>;
  create?: Maybe<CovenantValue_CovenantCreateInput>;
};

export type CovenantKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Covenants create input from covenantValue */
export type CovenantValue_CovenantCreateInput = {
  covenantValue?: Maybe<CovenantsCovenantValueRelationInput>;
  name: Scalars['String'];
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueRelationInput>;
};

/** Covenants relation input */
export type CovenantsCovenantValueRelationInput = {
  connect?: Maybe<Array<CovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Covenant_CovenantValueCreateInput>>>;
};

/** CovenantValues create input from covenant */
export type Covenant_CovenantValueCreateInput = {
  covenant?: Maybe<CovenantValuesCovenantRelationInput>;
  company?: Maybe<CovenantValuesCompanyRelationInput>;
  value?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

/** CovenantValues relation input */
export type CovenantValuesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<CovenantValue_CompanyCreateInput>;
};

/** Companies create input from covenantValue */
export type CovenantValue_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** Companies relation input */
export type CompaniesCustomCovenantRelationInput = {
  connect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_CustomCovenantValueCreateInput>>>;
};

export type CustomCovenantValueKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CustomCovenantValues create input from company */
export type Company_CustomCovenantValueCreateInput = {
  covenant: CustomCovenantValuesCovenantRelationInput;
  company?: Maybe<CustomCovenantValuesCompanyRelationInput>;
  date: Scalars['Date'];
  actual?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
  inCompliance?: Maybe<Scalars['String']>;
};

/** CustomCovenantValues relation input */
export type CustomCovenantValuesCovenantRelationInput = {
  connect?: Maybe<CovenantKeyFilter>;
  create?: Maybe<CustomCovenantValue_CovenantCreateInput>;
};

/** Covenants create input from customCovenantValue */
export type CustomCovenantValue_CovenantCreateInput = {
  covenantValue?: Maybe<CovenantsCovenantValueRelationInput>;
  name: Scalars['String'];
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueRelationInput>;
};

/** Covenants relation input */
export type CovenantsCustomCovenantValueRelationInput = {
  connect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Covenant_CustomCovenantValueCreateInput>>>;
};

/** CustomCovenantValues create input from covenant */
export type Covenant_CustomCovenantValueCreateInput = {
  covenant?: Maybe<CustomCovenantValuesCovenantRelationInput>;
  company?: Maybe<CustomCovenantValuesCompanyRelationInput>;
  date: Scalars['Date'];
  actual?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
  inCompliance?: Maybe<Scalars['String']>;
};

/** CustomCovenantValues relation input */
export type CustomCovenantValuesCompanyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<CustomCovenant_CompanyCreateInput>;
};

/** Companies create input from customCovenant */
export type CustomCovenant_CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** ProgressSteps relation input */
export type ProgressStepsStepValueRelationInput = {
  connect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Step_ProgressStepValueCreateInput>>>;
};

/** ProgressStepValues create input from step */
export type Step_ProgressStepValueCreateInput = {
  company?: Maybe<ProgressStepValuesCompanyRelationInput>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<ProgressStepValuesStepRelationInput>;
  customStepName?: Maybe<Scalars['String']>;
  customStepComment?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressStepValues relation input */
export type ProgressStepValuesStepRelationInput = {
  connect?: Maybe<ProgressStepKeyFilter>;
  create?: Maybe<StepValue_ProgressStepCreateInput>;
};

/** ProgressSteps create input from stepValue */
export type StepValue_ProgressStepCreateInput = {
  stage?: Maybe<ProgressStepsStageRelationInput>;
  stepValue?: Maybe<ProgressStepsStepValueRelationInput>;
  name: Scalars['String'];
  code: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
};

/** Integrations relation input */
export type IntegrationsXeroRelationInput = {
  connect?: Maybe<XeroIntegrationKeyFilter>;
  create?: Maybe<Integrations_XeroIntegrationCreateInput>;
};

export type XeroIntegrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** XeroIntegrations create input from integrations */
export type Integrations_XeroIntegrationCreateInput = {
  status: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<XeroIntegrationsIntegrationsRelationInput>;
  tenantId?: Maybe<Scalars['String']>;
};

/** XeroIntegrations relation input */
export type XeroIntegrationsIntegrationsRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Xero_IntegrationCreateInput>;
};

/** Integrations create input from xero */
export type Xero_IntegrationCreateInput = {
  company?: Maybe<IntegrationsCompanyRelationInput>;
  xero?: Maybe<IntegrationsXeroRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksRelationInput>;
  stripe?: Maybe<IntegrationsStripeRelationInput>;
  plaid?: Maybe<IntegrationsPlaidRelationInput>;
};

/** Integrations relation input */
export type IntegrationsQuickBooksRelationInput = {
  connect?: Maybe<QuickBooksIntegrationKeyFilter>;
  create?: Maybe<Integrations_QuickBooksIntegrationCreateInput>;
};

export type QuickBooksIntegrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** QuickBooksIntegration create input from integrations */
export type Integrations_QuickBooksIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<QuickBooksIntegrationIntegrationsRelationInput>;
  realmId?: Maybe<Scalars['String']>;
};

/** QuickBooksIntegration relation input */
export type QuickBooksIntegrationIntegrationsRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<QuickBooks_IntegrationCreateInput>;
};

/** Integrations create input from quickBooks */
export type QuickBooks_IntegrationCreateInput = {
  company?: Maybe<IntegrationsCompanyRelationInput>;
  xero?: Maybe<IntegrationsXeroRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksRelationInput>;
  stripe?: Maybe<IntegrationsStripeRelationInput>;
  plaid?: Maybe<IntegrationsPlaidRelationInput>;
};

/** Integrations relation input */
export type IntegrationsStripeRelationInput = {
  connect?: Maybe<StripeIntegrationKeyFilter>;
  create?: Maybe<Integrations_StripeIntegrationCreateInput>;
};

export type StripeIntegrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** StripeIntegration create input from integrations */
export type Integrations_StripeIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<StripeIntegrationIntegrationsRelationInput>;
  userId?: Maybe<Scalars['String']>;
};

/** StripeIntegration relation input */
export type StripeIntegrationIntegrationsRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Stripe_IntegrationCreateInput>;
};

/** Integrations create input from stripe */
export type Stripe_IntegrationCreateInput = {
  company?: Maybe<IntegrationsCompanyRelationInput>;
  xero?: Maybe<IntegrationsXeroRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksRelationInput>;
  stripe?: Maybe<IntegrationsStripeRelationInput>;
  plaid?: Maybe<IntegrationsPlaidRelationInput>;
};

/** Integrations relation input */
export type IntegrationsPlaidRelationInput = {
  connect?: Maybe<Array<PlaidIntegrationKeyFilter>>;
  create?: Maybe<Array<Maybe<Integrations_PlaidIntegrationCreateInput>>>;
};

export type PlaidIntegrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** PlaidIntegration create input from integrations */
export type Integrations_PlaidIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<PlaidIntegrationIntegrationsRelationInput>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountsRelationInput>;
  institutionId?: Maybe<Scalars['String']>;
};

/** PlaidIntegration relation input */
export type PlaidIntegrationIntegrationsRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Plaid_IntegrationCreateInput>;
};

/** Integrations create input from plaid */
export type Plaid_IntegrationCreateInput = {
  company?: Maybe<IntegrationsCompanyRelationInput>;
  xero?: Maybe<IntegrationsXeroRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksRelationInput>;
  stripe?: Maybe<IntegrationsStripeRelationInput>;
  plaid?: Maybe<IntegrationsPlaidRelationInput>;
};

/** PlaidIntegration relation input */
export type PlaidIntegrationAccountsRelationInput = {
  connect?: Maybe<Array<PlaidIntegrationAccountKeyFilter>>;
  create?: Maybe<Array<Maybe<PlaidIntegration_PlaidIntegrationAccountCreateInput>>>;
};

export type PlaidIntegrationAccountKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** PlaidIntegrationAccounts create input from plaidIntegration */
export type PlaidIntegration_PlaidIntegrationAccountCreateInput = {
  plaidIntegration?: Maybe<PlaidIntegrationAccountsPlaidIntegrationRelationInput>;
  accountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
};

/** PlaidIntegrationAccounts relation input */
export type PlaidIntegrationAccountsPlaidIntegrationRelationInput = {
  connect?: Maybe<PlaidIntegrationKeyFilter>;
  create?: Maybe<Accounts_PlaidIntegrationCreateInput>;
};

/** PlaidIntegration create input from accounts */
export type Accounts_PlaidIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<PlaidIntegrationIntegrationsRelationInput>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountsRelationInput>;
  institutionId?: Maybe<Scalars['String']>;
};

/** Facilities relation input */
export type FacilitiesDrawsRelationInput = {
  connect?: Maybe<Array<DrawKeyFilter>>;
  create?: Maybe<Array<Maybe<Facility_DrawCreateInput>>>;
};

export type DrawKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** Draws create input from facility */
export type Facility_DrawCreateInput = {
  facility?: Maybe<DrawsFacilityRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawsDrawHistoryRelationInput>;
};

/** Draws relation input */
export type DrawsFacilityRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
  create?: Maybe<Draws_FacilityCreateInput>;
};

/** Facilities create input from draws */
export type Draws_FacilityCreateInput = {
  structure: Scalars['String'];
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryRelationInput>;
};

/** Facilities relation input */
export type FacilitiesFacilityHistoryRelationInput = {
  connect?: Maybe<Array<FacilityHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<Facility_FacilityHistoryCreateInput>>>;
};

export type FacilityHistoryKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** FacilityHistories create input from facility */
export type Facility_FacilityHistoryCreateInput = {
  facility?: Maybe<FacilityHistoriesFacilityRelationInput>;
  facilityHistoryValue?: Maybe<FacilityHistoriesFacilityHistoryValueRelationInput>;
};

/** FacilityHistories relation input */
export type FacilityHistoriesFacilityRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
  create?: Maybe<FacilityHistory_FacilityCreateInput>;
};

/** Facilities create input from facilityHistory */
export type FacilityHistory_FacilityCreateInput = {
  structure: Scalars['String'];
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryRelationInput>;
};

/** Facilities relation input */
export type FacilitiesDrawHistoryRelationInput = {
  connect?: Maybe<Array<DrawHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<Facility_DrawHistoryCreateInput>>>;
};

export type DrawHistoryKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** DrawHistories create input from facility */
export type Facility_DrawHistoryCreateInput = {
  draw: DrawHistoriesDrawRelationInput;
  facility?: Maybe<DrawHistoriesFacilityRelationInput>;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueRelationInput>;
};

/** DrawHistories relation input */
export type DrawHistoriesDrawRelationInput = {
  connect?: Maybe<DrawKeyFilter>;
  create?: Maybe<DrawHistory_DrawCreateInput>;
};

/** Draws create input from drawHistory */
export type DrawHistory_DrawCreateInput = {
  facility?: Maybe<DrawsFacilityRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawsDrawHistoryRelationInput>;
};

/** Draws relation input */
export type DrawsDrawHistoryRelationInput = {
  connect?: Maybe<Array<DrawHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<Draw_DrawHistoryCreateInput>>>;
};

/** DrawHistories create input from draw */
export type Draw_DrawHistoryCreateInput = {
  draw?: Maybe<DrawHistoriesDrawRelationInput>;
  facility?: Maybe<DrawHistoriesFacilityRelationInput>;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueRelationInput>;
};

/** DrawHistories relation input */
export type DrawHistoriesFacilityRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
  create?: Maybe<DrawHistory_FacilityCreateInput>;
};

/** Facilities create input from drawHistory */
export type DrawHistory_FacilityCreateInput = {
  structure: Scalars['String'];
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryRelationInput>;
};

/** DrawHistories relation input */
export type DrawHistoriesDrawHistoryValueRelationInput = {
  connect?: Maybe<Array<DrawHistoryValueKeyFilter>>;
  create?: Maybe<Array<Maybe<DrawHistory_DrawHistoryValueCreateInput>>>;
};

export type DrawHistoryValueKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** DrawHistoryValues create input from drawHistory */
export type DrawHistory_DrawHistoryValueCreateInput = {
  drawHistory?: Maybe<DrawHistoryValuesDrawHistoryRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
};

/** DrawHistoryValues relation input */
export type DrawHistoryValuesDrawHistoryRelationInput = {
  connect?: Maybe<DrawHistoryKeyFilter>;
  create?: Maybe<DrawHistoryValue_DrawHistoryCreateInput>;
};

/** DrawHistories create input from drawHistoryValue */
export type DrawHistoryValue_DrawHistoryCreateInput = {
  draw?: Maybe<DrawHistoriesDrawRelationInput>;
  facility?: Maybe<DrawHistoriesFacilityRelationInput>;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueRelationInput>;
};

/** FacilityHistories relation input */
export type FacilityHistoriesFacilityHistoryValueRelationInput = {
  connect?: Maybe<Array<FacilityHistoryValueKeyFilter>>;
  create?: Maybe<Array<Maybe<FacilityHistory_FacilityHistoryValueCreateInput>>>;
};

export type FacilityHistoryValueKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** FacilityHistoryValues create input from facilityHistory */
export type FacilityHistory_FacilityHistoryValueCreateInput = {
  facilityHistory?: Maybe<FacilityHistoryValuesFacilityHistoryRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  date: Scalars['Date'];
  advanceRate?: Maybe<Scalars['Float']>;
};

/** FacilityHistoryValues relation input */
export type FacilityHistoryValuesFacilityHistoryRelationInput = {
  connect?: Maybe<FacilityHistoryKeyFilter>;
  create?: Maybe<FacilityHistoryValue_FacilityHistoryCreateInput>;
};

/** FacilityHistories create input from facilityHistoryValue */
export type FacilityHistoryValue_FacilityHistoryCreateInput = {
  facility?: Maybe<FacilityHistoriesFacilityRelationInput>;
  facilityHistoryValue?: Maybe<FacilityHistoriesFacilityHistoryValueRelationInput>;
};

/** ApiTokens delete input */
export type ApiTokenDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** ApiTokens update input */
export type ApiTokenUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<ApiTokensRolesUpdateRelationInput>;
};

/** ApiTokens relation input */
export type ApiTokensRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<ApiTokens_RoleCreateInput>>>;
  update?: Maybe<Array<Maybe<ApiTokens_RoleUpdateInput>>>;
};

/** Roles update input from apiTokens */
export type ApiTokens_RoleUpdateInput = {
  filter?: Maybe<RoleKeyFilter>;
  data: RoleUpdateInput;
};

/** Roles update input */
export type RoleUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersUpdateRelationInput>;
  permissions?: Maybe<PermissionsInput>;
  apiTokens?: Maybe<RolesApiTokensUpdateRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesUpdateRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersUpdateRelationInput>;
};

/** Roles relation input */
export type RolesUsersUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_UserCreateInput>>>;
  update?: Maybe<Array<Maybe<Roles_UserUpdateInput>>>;
};

/** Users update input from roles */
export type Roles_UserUpdateInput = {
  filter?: Maybe<UserKeyFilter>;
  data: UserUpdateInput;
};

/** Users update input */
export type UserUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  invitation?: Maybe<UsersInvitationUpdateRelationInput>;
  company?: Maybe<UsersCompanyUpdateRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerUpdateRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsUpdateRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesUpdateRelationInput>;
};

/** Users relation input */
export type UsersAvatarUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  create?: Maybe<Users_Avatar_FileCreateInput>;
  update?: Maybe<Users_Avatar_FileUpdateInput>;
};

/** Files update input from users_avatar */
export type Users_Avatar_FileUpdateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoUpdateRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileUpdateRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsUpdateRelationInput>;
};

/** Files relation input */
export type FilesUsers_AvatarUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Avatar_UserCreateInput>>>;
  update?: Maybe<Array<Maybe<Avatar_UserUpdateInput>>>;
};

/** Users update input from avatar */
export type Avatar_UserUpdateInput = {
  filter?: Maybe<UserKeyFilter>;
  data: UserUpdateInput;
};

/** Files relation input */
export type FilesTeamMembers_AvatarUpdateRelationInput = {
  connect?: Maybe<Array<TeamMemberKeyFilter>>;
  disconnect?: Maybe<Array<TeamMemberKeyFilter>>;
  reconnect?: Maybe<Array<TeamMemberKeyFilter>>;
  update?: Maybe<Array<Maybe<Avatar_TeamMemberUpdateInput>>>;
};

/** TeamMembers update input from avatar */
export type Avatar_TeamMemberUpdateInput = {
  filter?: Maybe<TeamMemberKeyFilter>;
  data: TeamMemberUpdateInput;
};

/** TeamMembers update input */
export type TeamMemberUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  avatar?: Maybe<TeamMembersAvatarUpdateRelationInput>;
  roles?: Maybe<TeamMembersRolesUpdateRelationInput>;
  receivedTeamInvitations?: Maybe<TeamMembersReceivedTeamInvitationsUpdateRelationInput>;
  sentTeamInvitations?: Maybe<TeamMembersSentTeamInvitationsUpdateRelationInput>;
};

/** TeamMembers relation input */
export type TeamMembersAvatarUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  create?: Maybe<TeamMembers_Avatar_FileCreateInput>;
  update?: Maybe<TeamMembers_Avatar_FileUpdateInput>;
};

/** Files create input from teamMembers_avatar */
export type TeamMembers_Avatar_FileCreateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsRelationInput>;
};

/** Files update input from teamMembers_avatar */
export type TeamMembers_Avatar_FileUpdateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoUpdateRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileUpdateRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsUpdateRelationInput>;
};

/** Files relation input */
export type FilesPortfolioCompanies_LogoUpdateRelationInput = {
  connect?: Maybe<Array<CompanyKeyFilter>>;
  disconnect?: Maybe<Array<CompanyKeyFilter>>;
  reconnect?: Maybe<Array<CompanyKeyFilter>>;
  create?: Maybe<Array<Maybe<Logo_CompanyCreateInput>>>;
  update?: Maybe<Array<Maybe<Logo_CompanyUpdateInput>>>;
};

/** Companies update input from logo */
export type Logo_CompanyUpdateInput = {
  filter?: Maybe<CompanyKeyFilter>;
  data: CompanyUpdateInput;
};

/** Companies update input */
export type CompanyUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesLogoUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  create?: Maybe<PortfolioCompanies_Logo_FileCreateInput>;
  update?: Maybe<PortfolioCompanies_Logo_FileUpdateInput>;
};

/** Files update input from portfolioCompanies_logo */
export type PortfolioCompanies_Logo_FileUpdateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoUpdateRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileUpdateRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsUpdateRelationInput>;
};

/** Files relation input */
export type FilesDocuments_FileUpdateRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  disconnect?: Maybe<Array<DocumentKeyFilter>>;
  reconnect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<File_DocumentCreateInput>>>;
  update?: Maybe<Array<Maybe<File_DocumentUpdateInput>>>;
};

/** Documents update input from file */
export type File_DocumentUpdateInput = {
  filter?: Maybe<DocumentKeyFilter>;
  data: DocumentUpdateInput;
};

/** Documents update input */
export type DocumentUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  file?: Maybe<DocumentsFileUpdateRelationInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyUpdateRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationUpdateRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageUpdateRelationInput>;
};

/** Documents relation input */
export type DocumentsFileUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  create?: Maybe<Documents_File_FileCreateInput>;
  update?: Maybe<Documents_File_FileUpdateInput>;
};

/** Files update input from documents_file */
export type Documents_File_FileUpdateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoUpdateRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileUpdateRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsUpdateRelationInput>;
};

/** Files relation input */
export type FilesNotes_AttachmentsUpdateRelationInput = {
  connect?: Maybe<Array<NoteKeyFilter>>;
  disconnect?: Maybe<Array<NoteKeyFilter>>;
  reconnect?: Maybe<Array<NoteKeyFilter>>;
  create?: Maybe<Array<Maybe<Attachments_NoteCreateInput>>>;
  update?: Maybe<Array<Maybe<Attachments_NoteUpdateInput>>>;
};

/** Notes update input from attachments */
export type Attachments_NoteUpdateInput = {
  filter?: Maybe<NoteKeyFilter>;
  data: NoteUpdateInput;
};

/** Notes update input */
export type NoteUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<NotesAttachmentsUpdateRelationInput>;
  company?: Maybe<NotesCompanyUpdateRelationInput>;
};

/** Notes relation input */
export type NotesAttachmentsUpdateRelationInput = {
  connect?: Maybe<Array<FileKeyFilter>>;
  disconnect?: Maybe<Array<FileKeyFilter>>;
  reconnect?: Maybe<Array<FileKeyFilter>>;
  create?: Maybe<Array<Maybe<Notes_Attachments_FileCreateInput>>>;
  update?: Maybe<Array<Maybe<Notes_Attachments_FileUpdateInput>>>;
};

/** Files update input from notes_attachments */
export type Notes_Attachments_FileUpdateInput = {
  filter?: Maybe<FileKeyFilter>;
  data: FileUpdateInput;
};

/** Files update input */
export type FileUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarUpdateRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoUpdateRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileUpdateRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsUpdateRelationInput>;
};

/** Notes relation input */
export type NotesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Notes_CompanyCreateInput>;
  update?: Maybe<Notes_CompanyUpdateInput>;
};

/** Companies update input from notes */
export type Notes_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesNotesUpdateRelationInput = {
  connect?: Maybe<Array<NoteKeyFilter>>;
  disconnect?: Maybe<Array<NoteKeyFilter>>;
  reconnect?: Maybe<Array<NoteKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_NoteCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_NoteUpdateInput>>>;
};

/** Notes update input from company */
export type Company_NoteUpdateInput = {
  filter?: Maybe<NoteKeyFilter>;
  data: NoteUpdateInput;
};

/** Companies relation input */
export type CompaniesLoanApplicationUpdateRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
  disconnect?: Maybe<LoanApplicationKeyFilter>;
  reconnect?: Maybe<LoanApplicationKeyFilter>;
  create?: Maybe<Company_LoanApplicationCreateInput>;
  update?: Maybe<Company_LoanApplicationUpdateInput>;
};

/** LoanApplications update input from company */
export type Company_LoanApplicationUpdateInput = {
  company?: Maybe<LoanApplicationsCompanyUpdateRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsUpdateRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamUpdateRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications relation input */
export type LoanApplicationsCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<LoanApplication_CompanyCreateInput>;
  update?: Maybe<LoanApplication_CompanyUpdateInput>;
};

/** Companies update input from loanApplication */
export type LoanApplication_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesDocumentsUpdateRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  disconnect?: Maybe<Array<DocumentKeyFilter>>;
  reconnect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_DocumentCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_DocumentUpdateInput>>>;
};

/** Documents update input from company */
export type Company_DocumentUpdateInput = {
  filter?: Maybe<DocumentKeyFilter>;
  data: DocumentUpdateInput;
};

/** Companies relation input */
export type CompaniesFacilitiesUpdateRelationInput = {
  connect?: Maybe<Array<FacilityKeyFilter>>;
  disconnect?: Maybe<Array<FacilityKeyFilter>>;
  reconnect?: Maybe<Array<FacilityKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_FacilityCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_FacilityUpdateInput>>>;
};

/** Facilities update input from company */
export type Company_FacilityUpdateInput = {
  filter?: Maybe<FacilityKeyFilter>;
  data: FacilityUpdateInput;
};

/** Facilities update input */
export type FacilityUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  structure?: Maybe<Scalars['String']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsUpdateRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryUpdateRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryUpdateRelationInput>;
};

/** Facilities relation input */
export type FacilitiesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Facilities_CompanyCreateInput>;
  update?: Maybe<Facilities_CompanyUpdateInput>;
};

/** Companies update input from facilities */
export type Facilities_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesOwnerUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  create?: Maybe<Company_UserCreateInput>;
  update?: Maybe<Company_UserUpdateInput>;
};

/** Users update input from company */
export type Company_UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  invitation?: Maybe<UsersInvitationUpdateRelationInput>;
  company?: Maybe<UsersCompanyUpdateRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerUpdateRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsUpdateRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesUpdateRelationInput>;
};

/** Users relation input */
export type UsersRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<Users_RoleCreateInput>>>;
  update?: Maybe<Array<Maybe<Users_RoleUpdateInput>>>;
};

/** Roles update input from users */
export type Users_RoleUpdateInput = {
  filter?: Maybe<RoleKeyFilter>;
  data: RoleUpdateInput;
};

/** Users relation input */
export type UsersInvitationUpdateRelationInput = {
  connect?: Maybe<InvitationKeyFilter>;
  disconnect?: Maybe<InvitationKeyFilter>;
  reconnect?: Maybe<InvitationKeyFilter>;
  create?: Maybe<InvitedUser_InvitationCreateInput>;
  update?: Maybe<InvitedUser_InvitationUpdateInput>;
};

/** Invitations update input from invitedUser */
export type InvitedUser_InvitationUpdateInput = {
  invitedUser?: Maybe<InvitationsInvitedUserUpdateRelationInput>;
  meta?: Maybe<Scalars['JSON']>;
};

/** Invitations relation input */
export type InvitationsInvitedUserUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  create?: Maybe<Invitation_UserCreateInput>;
  update?: Maybe<Invitation_UserUpdateInput>;
};

/** Users update input from invitation */
export type Invitation_UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  invitation?: Maybe<UsersInvitationUpdateRelationInput>;
  company?: Maybe<UsersCompanyUpdateRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerUpdateRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsUpdateRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesUpdateRelationInput>;
};

/** Users relation input */
export type UsersCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Owner_CompanyCreateInput>;
  update?: Maybe<Owner_CompanyUpdateInput>;
};

/** Companies update input from owner */
export type Owner_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesIntegrationUpdateRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  disconnect?: Maybe<IntegrationKeyFilter>;
  reconnect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Company_IntegrationCreateInput>;
  update?: Maybe<Company_IntegrationUpdateInput>;
};

/** Integrations update input from company */
export type Company_IntegrationUpdateInput = {
  company?: Maybe<IntegrationsCompanyUpdateRelationInput>;
  xero?: Maybe<IntegrationsXeroUpdateRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksUpdateRelationInput>;
  stripe?: Maybe<IntegrationsStripeUpdateRelationInput>;
  plaid?: Maybe<IntegrationsPlaidUpdateRelationInput>;
};

/** Integrations relation input */
export type IntegrationsCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Integration_CompanyCreateInput>;
  update?: Maybe<Integration_CompanyUpdateInput>;
};

/** Companies update input from integration */
export type Integration_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesPortfolioCompanyUpdateRelationInput = {
  connect?: Maybe<PortfolioCompanyKeyFilter>;
  disconnect?: Maybe<PortfolioCompanyKeyFilter>;
  reconnect?: Maybe<PortfolioCompanyKeyFilter>;
  create?: Maybe<Company_PortfolioCompanyCreateInput>;
  update?: Maybe<Company_PortfolioCompanyUpdateInput>;
};

/** PortfolioCompanies update input from company */
export type Company_PortfolioCompanyUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyUpdateRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamUpdateRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsUpdateRelationInput>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<PortfolioCompany_CompanyCreateInput>;
  update?: Maybe<PortfolioCompany_CompanyUpdateInput>;
};

/** Companies update input from portfolioCompany */
export type PortfolioCompany_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesTeamUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Employer_UserCreateInput>>>;
  update?: Maybe<Array<Maybe<Employer_UserUpdateInput>>>;
};

/** Users update input from employer */
export type Employer_UserUpdateInput = {
  filter?: Maybe<UserKeyFilter>;
  data: UserUpdateInput;
};

/** Companies relation input */
export type CompaniesMetricsUpdateRelationInput = {
  connect?: Maybe<Array<MetricValueKeyFilter>>;
  disconnect?: Maybe<Array<MetricValueKeyFilter>>;
  reconnect?: Maybe<Array<MetricValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_MetricValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_MetricValueUpdateInput>>>;
};

/** MetricValues update input from company */
export type Company_MetricValueUpdateInput = {
  filter?: Maybe<MetricValueKeyFilter>;
  data: MetricValueUpdateInput;
};

/** MetricValues update input */
export type MetricValueUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  metric?: Maybe<MetricValuesMetricUpdateRelationInput>;
  company?: Maybe<MetricValuesCompanyUpdateRelationInput>;
  source?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  period?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

/** MetricValues relation input */
export type MetricValuesMetricUpdateRelationInput = {
  connect?: Maybe<MetricKeyFilter>;
  disconnect?: Maybe<MetricKeyFilter>;
  reconnect?: Maybe<MetricKeyFilter>;
  create?: Maybe<Values_MetricCreateInput>;
  update?: Maybe<Values_MetricUpdateInput>;
};

/** Metrics update input from values */
export type Values_MetricUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  values?: Maybe<MetricsValuesUpdateRelationInput>;
  format?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
};

/** Metrics relation input */
export type MetricsValuesUpdateRelationInput = {
  connect?: Maybe<Array<MetricValueKeyFilter>>;
  disconnect?: Maybe<Array<MetricValueKeyFilter>>;
  reconnect?: Maybe<Array<MetricValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Metric_MetricValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Metric_MetricValueUpdateInput>>>;
};

/** MetricValues update input from metric */
export type Metric_MetricValueUpdateInput = {
  filter?: Maybe<MetricValueKeyFilter>;
  data: MetricValueUpdateInput;
};

/** MetricValues relation input */
export type MetricValuesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Metrics_CompanyCreateInput>;
  update?: Maybe<Metrics_CompanyUpdateInput>;
};

/** Companies update input from metrics */
export type Metrics_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesMonitoringValuesUpdateRelationInput = {
  connect?: Maybe<Array<CompanyMonitoringKeyFilter>>;
  disconnect?: Maybe<Array<CompanyMonitoringKeyFilter>>;
  reconnect?: Maybe<Array<CompanyMonitoringKeyFilter>>;
  create?: Maybe<Array<Maybe<Companies_CompanyMonitoringCreateInput>>>;
  update?: Maybe<Array<Maybe<Companies_CompanyMonitoringUpdateInput>>>;
};

/** CompanyMonitoring update input from companies */
export type Companies_CompanyMonitoringUpdateInput = {
  filter?: Maybe<CompanyMonitoringKeyFilter>;
  data: CompanyMonitoringUpdateInput;
};

/** CompanyMonitoring update input */
export type CompanyMonitoringUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  committed?: Maybe<Scalars['Int']>;
  deployed?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanyMonitoringCompaniesUpdateRelationInput>;
  date?: Maybe<Scalars['Date']>;
};

/** CompanyMonitoring relation input */
export type CompanyMonitoringCompaniesUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<MonitoringValues_CompanyCreateInput>;
  update?: Maybe<MonitoringValues_CompanyUpdateInput>;
};

/** Companies update input from monitoringValues */
export type MonitoringValues_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesCompanyUpdateRelationInput = {
  connect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  disconnect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  reconnect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_ProgressStepValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_ProgressStepValueUpdateInput>>>;
};

/** ProgressStepValues update input from company */
export type Company_ProgressStepValueUpdateInput = {
  filter?: Maybe<ProgressStepValueKeyFilter>;
  data: ProgressStepValueUpdateInput;
};

/** ProgressStepValues update input */
export type ProgressStepValueUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  company?: Maybe<ProgressStepValuesCompanyUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<ProgressStepValuesStepUpdateRelationInput>;
  customStepName?: Maybe<Scalars['String']>;
  customStepComment?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressStepValues relation input */
export type ProgressStepValuesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Company_CompanyCreateInput>;
  update?: Maybe<Company_CompanyUpdateInput>;
};

/** Companies update input from company */
export type Company_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesInboxMessagesUpdateRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
  disconnect?: Maybe<Array<InboxMessageKeyFilter>>;
  reconnect?: Maybe<Array<InboxMessageKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_InboxMessageCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_InboxMessageUpdateInput>>>;
};

/** InboxMessages update input from company */
export type Company_InboxMessageUpdateInput = {
  filter?: Maybe<InboxMessageKeyFilter>;
  data: InboxMessageUpdateInput;
};

/** InboxMessages update input */
export type InboxMessageUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsUpdateRelationInput>;
  company?: Maybe<InboxMessagesCompanyUpdateRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnUpdateRelationInput>;
  replies?: Maybe<InboxMessagesRepliesUpdateRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesAttachmentsUpdateRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  disconnect?: Maybe<Array<DocumentKeyFilter>>;
  reconnect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<InboxMessage_DocumentCreateInput>>>;
  update?: Maybe<Array<Maybe<InboxMessage_DocumentUpdateInput>>>;
};

/** Documents update input from inboxMessage */
export type InboxMessage_DocumentUpdateInput = {
  filter?: Maybe<DocumentKeyFilter>;
  data: DocumentUpdateInput;
};

/** InboxMessages relation input */
export type InboxMessagesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<InboxMessages_CompanyCreateInput>;
  update?: Maybe<InboxMessages_CompanyUpdateInput>;
};

/** Companies update input from inboxMessages */
export type InboxMessages_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesCompanyStepUpdateRelationInput = {
  connect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  disconnect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  reconnect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_ProgressCompanyStepCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_ProgressCompanyStepUpdateInput>>>;
};

/** ProgressCompanySteps update input from company */
export type Company_ProgressCompanyStepUpdateInput = {
  filter?: Maybe<ProgressCompanyStepKeyFilter>;
  data: ProgressCompanyStepUpdateInput;
};

/** ProgressCompanySteps update input */
export type ProgressCompanyStepUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  stage?: Maybe<ProgressCompanyStepsStageUpdateRelationInput>;
  company?: Maybe<ProgressCompanyStepsCompanyUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressCompanySteps relation input */
export type ProgressCompanyStepsStageUpdateRelationInput = {
  connect?: Maybe<ProgressStageKeyFilter>;
  disconnect?: Maybe<ProgressStageKeyFilter>;
  reconnect?: Maybe<ProgressStageKeyFilter>;
  create?: Maybe<CompanySteps_ProgressStageCreateInput>;
  update?: Maybe<CompanySteps_ProgressStageUpdateInput>;
};

/** ProgressStages update input from companySteps */
export type CompanySteps_ProgressStageUpdateInput = {
  name?: Maybe<Scalars['String']>;
  steps?: Maybe<ProgressStagesStepsUpdateRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsUpdateRelationInput>;
};

/** ProgressStages relation input */
export type ProgressStagesStepsUpdateRelationInput = {
  connect?: Maybe<Array<ProgressStepKeyFilter>>;
  disconnect?: Maybe<Array<ProgressStepKeyFilter>>;
  reconnect?: Maybe<Array<ProgressStepKeyFilter>>;
  create?: Maybe<Array<Maybe<Stage_ProgressStepCreateInput>>>;
  update?: Maybe<Array<Maybe<Stage_ProgressStepUpdateInput>>>;
};

/** ProgressSteps update input from stage */
export type Stage_ProgressStepUpdateInput = {
  filter?: Maybe<ProgressStepKeyFilter>;
  data: ProgressStepUpdateInput;
};

/** ProgressSteps update input */
export type ProgressStepUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  stage?: Maybe<ProgressStepsStageUpdateRelationInput>;
  stepValue?: Maybe<ProgressStepsStepValueUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
};

/** ProgressSteps relation input */
export type ProgressStepsStageUpdateRelationInput = {
  connect?: Maybe<ProgressStageKeyFilter>;
  disconnect?: Maybe<ProgressStageKeyFilter>;
  reconnect?: Maybe<ProgressStageKeyFilter>;
  create?: Maybe<Steps_ProgressStageCreateInput>;
  update?: Maybe<Steps_ProgressStageUpdateInput>;
};

/** ProgressStages update input from steps */
export type Steps_ProgressStageUpdateInput = {
  name?: Maybe<Scalars['String']>;
  steps?: Maybe<ProgressStagesStepsUpdateRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsUpdateRelationInput>;
};

/** ProgressStages relation input */
export type ProgressStagesCompanyStepsUpdateRelationInput = {
  connect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  disconnect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  reconnect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
  create?: Maybe<Array<Maybe<Stage_ProgressCompanyStepCreateInput>>>;
  update?: Maybe<Array<Maybe<Stage_ProgressCompanyStepUpdateInput>>>;
};

/** ProgressCompanySteps update input from stage */
export type Stage_ProgressCompanyStepUpdateInput = {
  filter?: Maybe<ProgressCompanyStepKeyFilter>;
  data: ProgressCompanyStepUpdateInput;
};

/** ProgressSteps relation input */
export type ProgressStepsStepValueUpdateRelationInput = {
  connect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  disconnect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  reconnect?: Maybe<Array<ProgressStepValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Step_ProgressStepValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Step_ProgressStepValueUpdateInput>>>;
};

/** ProgressStepValues update input from step */
export type Step_ProgressStepValueUpdateInput = {
  filter?: Maybe<ProgressStepValueKeyFilter>;
  data: ProgressStepValueUpdateInput;
};

/** ProgressCompanySteps relation input */
export type ProgressCompanyStepsCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<CompanyStep_CompanyCreateInput>;
  update?: Maybe<CompanyStep_CompanyUpdateInput>;
};

/** Companies update input from companyStep */
export type CompanyStep_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesCovenantValueUpdateRelationInput = {
  connect?: Maybe<Array<CovenantValueKeyFilter>>;
  disconnect?: Maybe<Array<CovenantValueKeyFilter>>;
  reconnect?: Maybe<Array<CovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_CovenantValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_CovenantValueUpdateInput>>>;
};

/** CovenantValues update input from company */
export type Company_CovenantValueUpdateInput = {
  filter?: Maybe<CovenantValueKeyFilter>;
  data: CovenantValueUpdateInput;
};

/** CovenantValues update input */
export type CovenantValueUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  covenant?: Maybe<CovenantValuesCovenantUpdateRelationInput>;
  company?: Maybe<CovenantValuesCompanyUpdateRelationInput>;
  value?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

/** CovenantValues relation input */
export type CovenantValuesCovenantUpdateRelationInput = {
  connect?: Maybe<CovenantKeyFilter>;
  disconnect?: Maybe<CovenantKeyFilter>;
  reconnect?: Maybe<CovenantKeyFilter>;
  create?: Maybe<CovenantValue_CovenantCreateInput>;
  update?: Maybe<CovenantValue_CovenantUpdateInput>;
};

/** Covenants update input from covenantValue */
export type CovenantValue_CovenantUpdateInput = {
  covenantValue?: Maybe<CovenantsCovenantValueUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueUpdateRelationInput>;
};

/** Covenants relation input */
export type CovenantsCovenantValueUpdateRelationInput = {
  connect?: Maybe<Array<CovenantValueKeyFilter>>;
  disconnect?: Maybe<Array<CovenantValueKeyFilter>>;
  reconnect?: Maybe<Array<CovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Covenant_CovenantValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Covenant_CovenantValueUpdateInput>>>;
};

/** CovenantValues update input from covenant */
export type Covenant_CovenantValueUpdateInput = {
  filter?: Maybe<CovenantValueKeyFilter>;
  data: CovenantValueUpdateInput;
};

/** Covenants relation input */
export type CovenantsCustomCovenantValueUpdateRelationInput = {
  connect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  disconnect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  reconnect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Covenant_CustomCovenantValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Covenant_CustomCovenantValueUpdateInput>>>;
};

/** CustomCovenantValues update input from covenant */
export type Covenant_CustomCovenantValueUpdateInput = {
  filter?: Maybe<CustomCovenantValueKeyFilter>;
  data: CustomCovenantValueUpdateInput;
};

/** CustomCovenantValues update input */
export type CustomCovenantValueUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  covenant?: Maybe<CustomCovenantValuesCovenantUpdateRelationInput>;
  company?: Maybe<CustomCovenantValuesCompanyUpdateRelationInput>;
  date?: Maybe<Scalars['Date']>;
  actual?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
  inCompliance?: Maybe<Scalars['String']>;
};

/** CustomCovenantValues relation input */
export type CustomCovenantValuesCovenantUpdateRelationInput = {
  connect?: Maybe<CovenantKeyFilter>;
  disconnect?: Maybe<CovenantKeyFilter>;
  reconnect?: Maybe<CovenantKeyFilter>;
  create?: Maybe<CustomCovenantValue_CovenantCreateInput>;
  update?: Maybe<CustomCovenantValue_CovenantUpdateInput>;
};

/** Covenants update input from customCovenantValue */
export type CustomCovenantValue_CovenantUpdateInput = {
  covenantValue?: Maybe<CovenantsCovenantValueUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueUpdateRelationInput>;
};

/** CustomCovenantValues relation input */
export type CustomCovenantValuesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<CustomCovenant_CompanyCreateInput>;
  update?: Maybe<CustomCovenant_CompanyUpdateInput>;
};

/** Companies update input from customCovenant */
export type CustomCovenant_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Companies relation input */
export type CompaniesCustomCovenantUpdateRelationInput = {
  connect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  disconnect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  reconnect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
  create?: Maybe<Array<Maybe<Company_CustomCovenantValueCreateInput>>>;
  update?: Maybe<Array<Maybe<Company_CustomCovenantValueUpdateInput>>>;
};

/** CustomCovenantValues update input from company */
export type Company_CustomCovenantValueUpdateInput = {
  filter?: Maybe<CustomCovenantValueKeyFilter>;
  data: CustomCovenantValueUpdateInput;
};

/** CovenantValues relation input */
export type CovenantValuesCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<CovenantValue_CompanyCreateInput>;
  update?: Maybe<CovenantValue_CompanyUpdateInput>;
};

/** Companies update input from covenantValue */
export type CovenantValue_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesRepliedOnUpdateRelationInput = {
  connect?: Maybe<InboxMessageKeyFilter>;
  disconnect?: Maybe<InboxMessageKeyFilter>;
  reconnect?: Maybe<InboxMessageKeyFilter>;
  create?: Maybe<Replies_InboxMessageCreateInput>;
  update?: Maybe<Replies_InboxMessageUpdateInput>;
};

/** InboxMessages update input from replies */
export type Replies_InboxMessageUpdateInput = {
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsUpdateRelationInput>;
  company?: Maybe<InboxMessagesCompanyUpdateRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnUpdateRelationInput>;
  replies?: Maybe<InboxMessagesRepliesUpdateRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesRepliesUpdateRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
  disconnect?: Maybe<Array<InboxMessageKeyFilter>>;
  reconnect?: Maybe<Array<InboxMessageKeyFilter>>;
  create?: Maybe<Array<Maybe<RepliedOn_InboxMessageCreateInput>>>;
  update?: Maybe<Array<Maybe<RepliedOn_InboxMessageUpdateInput>>>;
};

/** InboxMessages update input from repliedOn */
export type RepliedOn_InboxMessageUpdateInput = {
  filter?: Maybe<InboxMessageKeyFilter>;
  data: InboxMessageUpdateInput;
};

/** ProgressStepValues relation input */
export type ProgressStepValuesStepUpdateRelationInput = {
  connect?: Maybe<ProgressStepKeyFilter>;
  disconnect?: Maybe<ProgressStepKeyFilter>;
  reconnect?: Maybe<ProgressStepKeyFilter>;
  create?: Maybe<StepValue_ProgressStepCreateInput>;
  update?: Maybe<StepValue_ProgressStepUpdateInput>;
};

/** ProgressSteps update input from stepValue */
export type StepValue_ProgressStepUpdateInput = {
  stage?: Maybe<ProgressStepsStageUpdateRelationInput>;
  stepValue?: Maybe<ProgressStepsStepValueUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesDealTeamUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<PortfolioCompanies_UserCreateInput>>>;
  update?: Maybe<Array<Maybe<PortfolioCompanies_UserUpdateInput>>>;
};

/** Users update input from portfolioCompanies */
export type PortfolioCompanies_UserUpdateInput = {
  filter?: Maybe<UserKeyFilter>;
  data: UserUpdateInput;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesCashMonitoringRecordsUpdateRelationInput = {
  connect?: Maybe<Array<CashMonitoringRecordKeyFilter>>;
  disconnect?: Maybe<Array<CashMonitoringRecordKeyFilter>>;
  reconnect?: Maybe<Array<CashMonitoringRecordKeyFilter>>;
  create?: Maybe<Array<Maybe<PortfolioCompany_CashMonitoringRecordCreateInput>>>;
  update?: Maybe<Array<Maybe<PortfolioCompany_CashMonitoringRecordUpdateInput>>>;
};

/** CashMonitoringRecords update input from portfolioCompany */
export type PortfolioCompany_CashMonitoringRecordUpdateInput = {
  filter?: Maybe<CashMonitoringRecordKeyFilter>;
  data: CashMonitoringRecordUpdateInput;
};

/** CashMonitoringRecords update input */
export type CashMonitoringRecordUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  portfolioCompany?: Maybe<CashMonitoringRecordsPortfolioCompanyUpdateRelationInput>;
  date?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

/** CashMonitoringRecords relation input */
export type CashMonitoringRecordsPortfolioCompanyUpdateRelationInput = {
  connect?: Maybe<PortfolioCompanyKeyFilter>;
  disconnect?: Maybe<PortfolioCompanyKeyFilter>;
  reconnect?: Maybe<PortfolioCompanyKeyFilter>;
  create?: Maybe<CashMonitoringRecords_PortfolioCompanyCreateInput>;
  update?: Maybe<CashMonitoringRecords_PortfolioCompanyUpdateInput>;
};

/** PortfolioCompanies update input from cashMonitoringRecords */
export type CashMonitoringRecords_PortfolioCompanyUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyUpdateRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamUpdateRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsUpdateRelationInput>;
};

/** Integrations relation input */
export type IntegrationsXeroUpdateRelationInput = {
  connect?: Maybe<XeroIntegrationKeyFilter>;
  disconnect?: Maybe<XeroIntegrationKeyFilter>;
  reconnect?: Maybe<XeroIntegrationKeyFilter>;
  create?: Maybe<Integrations_XeroIntegrationCreateInput>;
  update?: Maybe<Integrations_XeroIntegrationUpdateInput>;
};

/** XeroIntegrations update input from integrations */
export type Integrations_XeroIntegrationUpdateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<XeroIntegrationsIntegrationsUpdateRelationInput>;
  tenantId?: Maybe<Scalars['String']>;
};

/** XeroIntegrations relation input */
export type XeroIntegrationsIntegrationsUpdateRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  disconnect?: Maybe<IntegrationKeyFilter>;
  reconnect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Xero_IntegrationCreateInput>;
  update?: Maybe<Xero_IntegrationUpdateInput>;
};

/** Integrations update input from xero */
export type Xero_IntegrationUpdateInput = {
  company?: Maybe<IntegrationsCompanyUpdateRelationInput>;
  xero?: Maybe<IntegrationsXeroUpdateRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksUpdateRelationInput>;
  stripe?: Maybe<IntegrationsStripeUpdateRelationInput>;
  plaid?: Maybe<IntegrationsPlaidUpdateRelationInput>;
};

/** Integrations relation input */
export type IntegrationsQuickBooksUpdateRelationInput = {
  connect?: Maybe<QuickBooksIntegrationKeyFilter>;
  disconnect?: Maybe<QuickBooksIntegrationKeyFilter>;
  reconnect?: Maybe<QuickBooksIntegrationKeyFilter>;
  create?: Maybe<Integrations_QuickBooksIntegrationCreateInput>;
  update?: Maybe<Integrations_QuickBooksIntegrationUpdateInput>;
};

/** QuickBooksIntegration update input from integrations */
export type Integrations_QuickBooksIntegrationUpdateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<QuickBooksIntegrationIntegrationsUpdateRelationInput>;
  realmId?: Maybe<Scalars['String']>;
};

/** QuickBooksIntegration relation input */
export type QuickBooksIntegrationIntegrationsUpdateRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  disconnect?: Maybe<IntegrationKeyFilter>;
  reconnect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<QuickBooks_IntegrationCreateInput>;
  update?: Maybe<QuickBooks_IntegrationUpdateInput>;
};

/** Integrations update input from quickBooks */
export type QuickBooks_IntegrationUpdateInput = {
  company?: Maybe<IntegrationsCompanyUpdateRelationInput>;
  xero?: Maybe<IntegrationsXeroUpdateRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksUpdateRelationInput>;
  stripe?: Maybe<IntegrationsStripeUpdateRelationInput>;
  plaid?: Maybe<IntegrationsPlaidUpdateRelationInput>;
};

/** Integrations relation input */
export type IntegrationsStripeUpdateRelationInput = {
  connect?: Maybe<StripeIntegrationKeyFilter>;
  disconnect?: Maybe<StripeIntegrationKeyFilter>;
  reconnect?: Maybe<StripeIntegrationKeyFilter>;
  create?: Maybe<Integrations_StripeIntegrationCreateInput>;
  update?: Maybe<Integrations_StripeIntegrationUpdateInput>;
};

/** StripeIntegration update input from integrations */
export type Integrations_StripeIntegrationUpdateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<StripeIntegrationIntegrationsUpdateRelationInput>;
  userId?: Maybe<Scalars['String']>;
};

/** StripeIntegration relation input */
export type StripeIntegrationIntegrationsUpdateRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  disconnect?: Maybe<IntegrationKeyFilter>;
  reconnect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Stripe_IntegrationCreateInput>;
  update?: Maybe<Stripe_IntegrationUpdateInput>;
};

/** Integrations update input from stripe */
export type Stripe_IntegrationUpdateInput = {
  company?: Maybe<IntegrationsCompanyUpdateRelationInput>;
  xero?: Maybe<IntegrationsXeroUpdateRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksUpdateRelationInput>;
  stripe?: Maybe<IntegrationsStripeUpdateRelationInput>;
  plaid?: Maybe<IntegrationsPlaidUpdateRelationInput>;
};

/** Integrations relation input */
export type IntegrationsPlaidUpdateRelationInput = {
  connect?: Maybe<Array<PlaidIntegrationKeyFilter>>;
  disconnect?: Maybe<Array<PlaidIntegrationKeyFilter>>;
  reconnect?: Maybe<Array<PlaidIntegrationKeyFilter>>;
  create?: Maybe<Array<Maybe<Integrations_PlaidIntegrationCreateInput>>>;
  update?: Maybe<Array<Maybe<Integrations_PlaidIntegrationUpdateInput>>>;
};

/** PlaidIntegration update input from integrations */
export type Integrations_PlaidIntegrationUpdateInput = {
  filter?: Maybe<PlaidIntegrationKeyFilter>;
  data: PlaidIntegrationUpdateInput;
};

/** PlaidIntegration update input */
export type PlaidIntegrationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<PlaidIntegrationIntegrationsUpdateRelationInput>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountsUpdateRelationInput>;
  institutionId?: Maybe<Scalars['String']>;
};

/** PlaidIntegration relation input */
export type PlaidIntegrationIntegrationsUpdateRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
  disconnect?: Maybe<IntegrationKeyFilter>;
  reconnect?: Maybe<IntegrationKeyFilter>;
  create?: Maybe<Plaid_IntegrationCreateInput>;
  update?: Maybe<Plaid_IntegrationUpdateInput>;
};

/** Integrations update input from plaid */
export type Plaid_IntegrationUpdateInput = {
  company?: Maybe<IntegrationsCompanyUpdateRelationInput>;
  xero?: Maybe<IntegrationsXeroUpdateRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksUpdateRelationInput>;
  stripe?: Maybe<IntegrationsStripeUpdateRelationInput>;
  plaid?: Maybe<IntegrationsPlaidUpdateRelationInput>;
};

/** PlaidIntegration relation input */
export type PlaidIntegrationAccountsUpdateRelationInput = {
  connect?: Maybe<Array<PlaidIntegrationAccountKeyFilter>>;
  disconnect?: Maybe<Array<PlaidIntegrationAccountKeyFilter>>;
  reconnect?: Maybe<Array<PlaidIntegrationAccountKeyFilter>>;
  create?: Maybe<Array<Maybe<PlaidIntegration_PlaidIntegrationAccountCreateInput>>>;
  update?: Maybe<Array<Maybe<PlaidIntegration_PlaidIntegrationAccountUpdateInput>>>;
};

/** PlaidIntegrationAccounts update input from plaidIntegration */
export type PlaidIntegration_PlaidIntegrationAccountUpdateInput = {
  filter?: Maybe<PlaidIntegrationAccountKeyFilter>;
  data: PlaidIntegrationAccountUpdateInput;
};

/** PlaidIntegrationAccounts update input */
export type PlaidIntegrationAccountUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  plaidIntegration?: Maybe<PlaidIntegrationAccountsPlaidIntegrationUpdateRelationInput>;
  accountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
};

/** PlaidIntegrationAccounts relation input */
export type PlaidIntegrationAccountsPlaidIntegrationUpdateRelationInput = {
  connect?: Maybe<PlaidIntegrationKeyFilter>;
  disconnect?: Maybe<PlaidIntegrationKeyFilter>;
  reconnect?: Maybe<PlaidIntegrationKeyFilter>;
  create?: Maybe<Accounts_PlaidIntegrationCreateInput>;
  update?: Maybe<Accounts_PlaidIntegrationUpdateInput>;
};

/** PlaidIntegration update input from accounts */
export type Accounts_PlaidIntegrationUpdateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<PlaidIntegrationIntegrationsUpdateRelationInput>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountsUpdateRelationInput>;
  institutionId?: Maybe<Scalars['String']>;
};

/** Users relation input */
export type UsersEmployerUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Team_CompanyCreateInput>;
  update?: Maybe<Team_CompanyUpdateInput>;
};

/** Companies update input from team */
export type Team_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Users relation input */
export type UsersLoanApplicationsUpdateRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
  disconnect?: Maybe<LoanApplicationKeyFilter>;
  reconnect?: Maybe<LoanApplicationKeyFilter>;
  create?: Maybe<DealTeam_LoanApplicationCreateInput>;
  update?: Maybe<DealTeam_LoanApplicationUpdateInput>;
};

/** LoanApplications update input from dealTeam */
export type DealTeam_LoanApplicationUpdateInput = {
  company?: Maybe<LoanApplicationsCompanyUpdateRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsUpdateRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamUpdateRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications relation input */
export type LoanApplicationsDocumentsUpdateRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
  disconnect?: Maybe<Array<DocumentKeyFilter>>;
  reconnect?: Maybe<Array<DocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<LoanApplication_DocumentCreateInput>>>;
  update?: Maybe<Array<Maybe<LoanApplication_DocumentUpdateInput>>>;
};

/** Documents update input from loanApplication */
export type LoanApplication_DocumentUpdateInput = {
  filter?: Maybe<DocumentKeyFilter>;
  data: DocumentUpdateInput;
};

/** LoanApplications relation input */
export type LoanApplicationsDealTeamUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<LoanApplications_UserCreateInput>>>;
  update?: Maybe<Array<Maybe<LoanApplications_UserUpdateInput>>>;
};

/** Users update input from loanApplications */
export type LoanApplications_UserUpdateInput = {
  filter?: Maybe<UserKeyFilter>;
  data: UserUpdateInput;
};

/** Users relation input */
export type UsersPortfolioCompaniesUpdateRelationInput = {
  connect?: Maybe<Array<PortfolioCompanyKeyFilter>>;
  disconnect?: Maybe<Array<PortfolioCompanyKeyFilter>>;
  reconnect?: Maybe<Array<PortfolioCompanyKeyFilter>>;
  create?: Maybe<Array<Maybe<DealTeam_PortfolioCompanyCreateInput>>>;
  update?: Maybe<Array<Maybe<DealTeam_PortfolioCompanyUpdateInput>>>;
};

/** PortfolioCompanies update input from dealTeam */
export type DealTeam_PortfolioCompanyUpdateInput = {
  filter?: Maybe<PortfolioCompanyKeyFilter>;
  data: PortfolioCompanyUpdateInput;
};

/** PortfolioCompanies update input */
export type PortfolioCompanyUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyUpdateRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamUpdateRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsUpdateRelationInput>;
};

/** Facilities relation input */
export type FacilitiesDrawsUpdateRelationInput = {
  connect?: Maybe<Array<DrawKeyFilter>>;
  disconnect?: Maybe<Array<DrawKeyFilter>>;
  reconnect?: Maybe<Array<DrawKeyFilter>>;
  create?: Maybe<Array<Maybe<Facility_DrawCreateInput>>>;
  update?: Maybe<Array<Maybe<Facility_DrawUpdateInput>>>;
};

/** Draws update input from facility */
export type Facility_DrawUpdateInput = {
  filter?: Maybe<DrawKeyFilter>;
  data: DrawUpdateInput;
};

/** Draws update input */
export type DrawUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  facility?: Maybe<DrawsFacilityUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawsDrawHistoryUpdateRelationInput>;
};

/** Draws relation input */
export type DrawsFacilityUpdateRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
  disconnect?: Maybe<FacilityKeyFilter>;
  reconnect?: Maybe<FacilityKeyFilter>;
  create?: Maybe<Draws_FacilityCreateInput>;
  update?: Maybe<Draws_FacilityUpdateInput>;
};

/** Facilities update input from draws */
export type Draws_FacilityUpdateInput = {
  structure?: Maybe<Scalars['String']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsUpdateRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryUpdateRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryUpdateRelationInput>;
};

/** Facilities relation input */
export type FacilitiesFacilityHistoryUpdateRelationInput = {
  connect?: Maybe<Array<FacilityHistoryKeyFilter>>;
  disconnect?: Maybe<Array<FacilityHistoryKeyFilter>>;
  reconnect?: Maybe<Array<FacilityHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<Facility_FacilityHistoryCreateInput>>>;
  update?: Maybe<Array<Maybe<Facility_FacilityHistoryUpdateInput>>>;
};

/** FacilityHistories update input from facility */
export type Facility_FacilityHistoryUpdateInput = {
  filter?: Maybe<FacilityHistoryKeyFilter>;
  data: FacilityHistoryUpdateInput;
};

/** FacilityHistories update input */
export type FacilityHistoryUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  facility?: Maybe<FacilityHistoriesFacilityUpdateRelationInput>;
  facilityHistoryValue?: Maybe<FacilityHistoriesFacilityHistoryValueUpdateRelationInput>;
};

/** FacilityHistories relation input */
export type FacilityHistoriesFacilityUpdateRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
  disconnect?: Maybe<FacilityKeyFilter>;
  reconnect?: Maybe<FacilityKeyFilter>;
  create?: Maybe<FacilityHistory_FacilityCreateInput>;
  update?: Maybe<FacilityHistory_FacilityUpdateInput>;
};

/** Facilities update input from facilityHistory */
export type FacilityHistory_FacilityUpdateInput = {
  structure?: Maybe<Scalars['String']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsUpdateRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryUpdateRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryUpdateRelationInput>;
};

/** Facilities relation input */
export type FacilitiesDrawHistoryUpdateRelationInput = {
  connect?: Maybe<Array<DrawHistoryKeyFilter>>;
  disconnect?: Maybe<Array<DrawHistoryKeyFilter>>;
  reconnect?: Maybe<Array<DrawHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<Facility_DrawHistoryCreateInput>>>;
  update?: Maybe<Array<Maybe<Facility_DrawHistoryUpdateInput>>>;
};

/** DrawHistories update input from facility */
export type Facility_DrawHistoryUpdateInput = {
  filter?: Maybe<DrawHistoryKeyFilter>;
  data: DrawHistoryUpdateInput;
};

/** DrawHistories update input */
export type DrawHistoryUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  draw?: Maybe<DrawHistoriesDrawUpdateRelationInput>;
  facility?: Maybe<DrawHistoriesFacilityUpdateRelationInput>;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueUpdateRelationInput>;
};

/** DrawHistories relation input */
export type DrawHistoriesDrawUpdateRelationInput = {
  connect?: Maybe<DrawKeyFilter>;
  disconnect?: Maybe<DrawKeyFilter>;
  reconnect?: Maybe<DrawKeyFilter>;
  create?: Maybe<DrawHistory_DrawCreateInput>;
  update?: Maybe<DrawHistory_DrawUpdateInput>;
};

/** Draws update input from drawHistory */
export type DrawHistory_DrawUpdateInput = {
  facility?: Maybe<DrawsFacilityUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawsDrawHistoryUpdateRelationInput>;
};

/** Draws relation input */
export type DrawsDrawHistoryUpdateRelationInput = {
  connect?: Maybe<Array<DrawHistoryKeyFilter>>;
  disconnect?: Maybe<Array<DrawHistoryKeyFilter>>;
  reconnect?: Maybe<Array<DrawHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<Draw_DrawHistoryCreateInput>>>;
  update?: Maybe<Array<Maybe<Draw_DrawHistoryUpdateInput>>>;
};

/** DrawHistories update input from draw */
export type Draw_DrawHistoryUpdateInput = {
  filter?: Maybe<DrawHistoryKeyFilter>;
  data: DrawHistoryUpdateInput;
};

/** DrawHistories relation input */
export type DrawHistoriesFacilityUpdateRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
  disconnect?: Maybe<FacilityKeyFilter>;
  reconnect?: Maybe<FacilityKeyFilter>;
  create?: Maybe<DrawHistory_FacilityCreateInput>;
  update?: Maybe<DrawHistory_FacilityUpdateInput>;
};

/** Facilities update input from drawHistory */
export type DrawHistory_FacilityUpdateInput = {
  structure?: Maybe<Scalars['String']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsUpdateRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryUpdateRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryUpdateRelationInput>;
};

/** DrawHistories relation input */
export type DrawHistoriesDrawHistoryValueUpdateRelationInput = {
  connect?: Maybe<Array<DrawHistoryValueKeyFilter>>;
  disconnect?: Maybe<Array<DrawHistoryValueKeyFilter>>;
  reconnect?: Maybe<Array<DrawHistoryValueKeyFilter>>;
  create?: Maybe<Array<Maybe<DrawHistory_DrawHistoryValueCreateInput>>>;
  update?: Maybe<Array<Maybe<DrawHistory_DrawHistoryValueUpdateInput>>>;
};

/** DrawHistoryValues update input from drawHistory */
export type DrawHistory_DrawHistoryValueUpdateInput = {
  filter?: Maybe<DrawHistoryValueKeyFilter>;
  data: DrawHistoryValueUpdateInput;
};

/** DrawHistoryValues update input */
export type DrawHistoryValueUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  drawHistory?: Maybe<DrawHistoryValuesDrawHistoryUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
};

/** DrawHistoryValues relation input */
export type DrawHistoryValuesDrawHistoryUpdateRelationInput = {
  connect?: Maybe<DrawHistoryKeyFilter>;
  disconnect?: Maybe<DrawHistoryKeyFilter>;
  reconnect?: Maybe<DrawHistoryKeyFilter>;
  create?: Maybe<DrawHistoryValue_DrawHistoryCreateInput>;
  update?: Maybe<DrawHistoryValue_DrawHistoryUpdateInput>;
};

/** DrawHistories update input from drawHistoryValue */
export type DrawHistoryValue_DrawHistoryUpdateInput = {
  draw?: Maybe<DrawHistoriesDrawUpdateRelationInput>;
  facility?: Maybe<DrawHistoriesFacilityUpdateRelationInput>;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueUpdateRelationInput>;
};

/** FacilityHistories relation input */
export type FacilityHistoriesFacilityHistoryValueUpdateRelationInput = {
  connect?: Maybe<Array<FacilityHistoryValueKeyFilter>>;
  disconnect?: Maybe<Array<FacilityHistoryValueKeyFilter>>;
  reconnect?: Maybe<Array<FacilityHistoryValueKeyFilter>>;
  create?: Maybe<Array<Maybe<FacilityHistory_FacilityHistoryValueCreateInput>>>;
  update?: Maybe<Array<Maybe<FacilityHistory_FacilityHistoryValueUpdateInput>>>;
};

/** FacilityHistoryValues update input from facilityHistory */
export type FacilityHistory_FacilityHistoryValueUpdateInput = {
  filter?: Maybe<FacilityHistoryValueKeyFilter>;
  data: FacilityHistoryValueUpdateInput;
};

/** FacilityHistoryValues update input */
export type FacilityHistoryValueUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  facilityHistory?: Maybe<FacilityHistoryValuesFacilityHistoryUpdateRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  advanceRate?: Maybe<Scalars['Float']>;
};

/** FacilityHistoryValues relation input */
export type FacilityHistoryValuesFacilityHistoryUpdateRelationInput = {
  connect?: Maybe<FacilityHistoryKeyFilter>;
  disconnect?: Maybe<FacilityHistoryKeyFilter>;
  reconnect?: Maybe<FacilityHistoryKeyFilter>;
  create?: Maybe<FacilityHistoryValue_FacilityHistoryCreateInput>;
  update?: Maybe<FacilityHistoryValue_FacilityHistoryUpdateInput>;
};

/** FacilityHistories update input from facilityHistoryValue */
export type FacilityHistoryValue_FacilityHistoryUpdateInput = {
  facility?: Maybe<FacilityHistoriesFacilityUpdateRelationInput>;
  facilityHistoryValue?: Maybe<FacilityHistoriesFacilityHistoryValueUpdateRelationInput>;
};

/** Documents relation input */
export type DocumentsCompanyUpdateRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
  disconnect?: Maybe<CompanyKeyFilter>;
  reconnect?: Maybe<CompanyKeyFilter>;
  create?: Maybe<Documents_CompanyCreateInput>;
  update?: Maybe<Documents_CompanyUpdateInput>;
};

/** Companies update input from documents */
export type Documents_CompanyUpdateInput = {
  /** Company name */
  name?: Maybe<Scalars['String']>;
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoUpdateRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesUpdateRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationUpdateRelationInput>;
  documents?: Maybe<CompaniesDocumentsUpdateRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesUpdateRelationInput>;
  owner?: Maybe<CompaniesOwnerUpdateRelationInput>;
  integration?: Maybe<CompaniesIntegrationUpdateRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyUpdateRelationInput>;
  team?: Maybe<CompaniesTeamUpdateRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsUpdateRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesUpdateRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyUpdateRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesUpdateRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepUpdateRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueUpdateRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantUpdateRelationInput>;
};

/** Documents relation input */
export type DocumentsLoanApplicationUpdateRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
  disconnect?: Maybe<LoanApplicationKeyFilter>;
  reconnect?: Maybe<LoanApplicationKeyFilter>;
  create?: Maybe<Documents_LoanApplicationCreateInput>;
  update?: Maybe<Documents_LoanApplicationUpdateInput>;
};

/** LoanApplications update input from documents */
export type Documents_LoanApplicationUpdateInput = {
  company?: Maybe<LoanApplicationsCompanyUpdateRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsUpdateRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamUpdateRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** Documents relation input */
export type DocumentsInboxMessageUpdateRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
  disconnect?: Maybe<Array<InboxMessageKeyFilter>>;
  reconnect?: Maybe<Array<InboxMessageKeyFilter>>;
  create?: Maybe<Array<Maybe<Attachments_InboxMessageCreateInput>>>;
  update?: Maybe<Array<Maybe<Attachments_InboxMessageUpdateInput>>>;
};

/** InboxMessages update input from attachments */
export type Attachments_InboxMessageUpdateInput = {
  filter?: Maybe<InboxMessageKeyFilter>;
  data: InboxMessageUpdateInput;
};

/** TeamMembers relation input */
export type TeamMembersRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<TeamMembers_RoleCreateInput>>>;
  update?: Maybe<Array<Maybe<TeamMembers_RoleUpdateInput>>>;
};

/** Roles create input from teamMembers */
export type TeamMembers_RoleCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersRelationInput>;
  permissions?: Maybe<PermissionsInput>;
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersRelationInput>;
};

/** Roles update input from teamMembers */
export type TeamMembers_RoleUpdateInput = {
  filter?: Maybe<RoleKeyFilter>;
  data: RoleUpdateInput;
};

/** TeamMembers relation input */
export type TeamMembersReceivedTeamInvitationsUpdateRelationInput = {
  connect?: Maybe<Array<TeamInvitationKeyFilter>>;
  disconnect?: Maybe<Array<TeamInvitationKeyFilter>>;
  reconnect?: Maybe<Array<TeamInvitationKeyFilter>>;
};

export type TeamInvitationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['String']>;
};

/** TeamMembers relation input */
export type TeamMembersSentTeamInvitationsUpdateRelationInput = {
  connect?: Maybe<Array<TeamInvitationKeyFilter>>;
  disconnect?: Maybe<Array<TeamInvitationKeyFilter>>;
  reconnect?: Maybe<Array<TeamInvitationKeyFilter>>;
};

/** Roles relation input */
export type RolesApiTokensUpdateRelationInput = {
  connect?: Maybe<Array<ApiTokenKeyFilter>>;
  disconnect?: Maybe<Array<ApiTokenKeyFilter>>;
  reconnect?: Maybe<Array<ApiTokenKeyFilter>>;
  update?: Maybe<Array<Maybe<Roles_ApiTokenUpdateInput>>>;
};

/** ApiTokens update input from roles */
export type Roles_ApiTokenUpdateInput = {
  filter?: Maybe<ApiTokenKeyFilter>;
  data: ApiTokenUpdateInput;
};

/** Roles relation input */
export type RolesAuthenticationProfilesUpdateRelationInput = {
  connect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  disconnect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  reconnect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_AuthenticationProfileCreateInput>>>;
  update?: Maybe<Array<Maybe<Roles_AuthenticationProfileUpdateInput>>>;
};

/** AuthenticationProfiles update input from roles */
export type Roles_AuthenticationProfileUpdateInput = {
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  data: AuthenticationProfileUpdateInput;
};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<AuthenticationProfilesRolesUpdateRelationInput>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<AuthenticationProfiles_RoleCreateInput>>>;
  update?: Maybe<Array<Maybe<AuthenticationProfiles_RoleUpdateInput>>>;
};

/** Roles update input from authenticationProfiles */
export type AuthenticationProfiles_RoleUpdateInput = {
  filter?: Maybe<RoleKeyFilter>;
  data: RoleUpdateInput;
};

/** Roles relation input */
export type RolesTeamMembersUpdateRelationInput = {
  connect?: Maybe<Array<TeamMemberKeyFilter>>;
  disconnect?: Maybe<Array<TeamMemberKeyFilter>>;
  reconnect?: Maybe<Array<TeamMemberKeyFilter>>;
  update?: Maybe<Array<Maybe<Roles_TeamMemberUpdateInput>>>;
};

/** TeamMembers update input from roles */
export type Roles_TeamMemberUpdateInput = {
  filter?: Maybe<TeamMemberKeyFilter>;
  data: TeamMemberUpdateInput;
};

/** ApiTokenManyResponse output */
export type ApiTokenManyResponse = {
  __typename?: 'ApiTokenManyResponse';
  /** List items */
  items: Array<ApiToken>;
  /** List items count */
  count: Scalars['Int'];
};

/** ApiTokens update input */
export type ApiTokenUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  token?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

export type UpdateByFilterStringInput = {
  prefix?: Maybe<Scalars['String']>;
  postfix?: Maybe<Scalars['String']>;
  set?: Maybe<Scalars['String']>;
};

/** ApplicationDeleteMutationInput */
export type ApplicationDeleteMutationInput = {
  id: Scalars['String'];
  force?: Maybe<Scalars['Boolean']>;
};

/** Application install input */
export type ApplicationInstallInput = {
  appType: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<ApplicationStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Application update input */
export type ApplicationUpdateInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<ApplicationStatusEnum>;
};

/** AsyncTasks create input */
export type AsyncTaskCreateInput = {
  name?: Maybe<Scalars['String']>;
  args?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['JSON']>;
  argsHash?: Maybe<Scalars['String']>;
};

/** AsyncTaskManyResponse output */
export type AsyncTaskManyResponse = {
  __typename?: 'AsyncTaskManyResponse';
  /** List items */
  items: Array<AsyncTask>;
  /** List items count */
  count: Scalars['Int'];
};

/** AsyncTasks create many input */
export type AsyncTaskCreateManyInput = {
  name?: Maybe<Scalars['String']>;
  args?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['JSON']>;
  argsHash?: Maybe<Scalars['String']>;
};

/** AsyncTasks delete input */
export type AsyncTaskDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

export type AsyncTaskKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** AsyncTasks update input */
export type AsyncTaskUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  args?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['JSON']>;
  argsHash?: Maybe<Scalars['String']>;
};

/** AsyncTasks update input */
export type AsyncTaskUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  args?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  result?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  argsHash?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

export type UpdateByFilterJsonInput = {
  set?: Maybe<Scalars['JSON']>;
};

export type UpdateByFilterStringSwitchInput = {
  set?: Maybe<Scalars['String']>;
};

/** AuthenticationProfiles create input */
export type AuthenticationProfileCreateInput = {
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<AuthenticationProfilesRolesRelationInput>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AuthenticationProfileManyResponse output */
export type AuthenticationProfileManyResponse = {
  __typename?: 'AuthenticationProfileManyResponse';
  /** List items */
  items: Array<AuthenticationProfile>;
  /** List items count */
  count: Scalars['Int'];
};

/** AuthenticationProfiles create many input */
export type AuthenticationProfileCreateManyInput = {
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<AuthenticationProfilesRolesManyRelationInput>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesManyRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
};

/** AuthenticationProfiles delete input */
export type AuthenticationProfileDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  type?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  secret?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  managementDomain?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  clientId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  databaseName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  domain?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  selfSignUpEnabled?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  audiences?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
};

export type UpdateByFilterBooleanSwitchInput = {
  set?: Maybe<Scalars['Boolean']>;
  invert?: Maybe<Scalars['Boolean']>;
};

export type UpdateByFilterListStringInput = {
  set?: Maybe<Array<Maybe<Scalars['String']>>>;
  push?: Maybe<Array<Maybe<Scalars['String']>>>;
  unshift?: Maybe<Array<Maybe<Scalars['String']>>>;
  insert?: Maybe<UpdateByFilterListStringInsertOperationInput>;
  remove?: Maybe<Array<Maybe<Scalars['Int']>>>;
  removeValue?: Maybe<Scalars['String']>;
  swap?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UpdateByFilterListStringInsertOperationInput = {
  start: Scalars['Int'];
  values: Array<Scalars['String']>;
};

/** AuthenticationSettings update input */
export type AuthenticationSettingUpdateInput = {
  allowedCallbacks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedWebOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedLogouts?: Maybe<Array<Maybe<Scalars['String']>>>;
  connections?: Maybe<AuthenticationProfileConnectionsOptionsInput>;
};

/** Authentication profile connection options input */
export type AuthenticationProfileConnectionsOptionsInput = {
  google?: Maybe<GoogleOptionsInput>;
  facebook?: Maybe<FacebookOptionsInput>;
  github?: Maybe<GithubOptionsInput>;
};

/** Google connection params input */
export type GoogleOptionsInput = {
  enabled: Scalars['Boolean'];
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
};

/** Facebook connection params input */
export type FacebookOptionsInput = {
  enabled: Scalars['Boolean'];
  app_id: Scalars['String'];
  app_secret: Scalars['String'];
};

/** Github connection params input */
export type GithubOptionsInput = {
  enabled: Scalars['Boolean'];
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
};

/** BillingDetailsUpdateMutationInput */
export type BillingDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
};

/** BillingPlanUpdateMutationInput */
export type BillingPlanUpdateMutationInput = {
  planId: Scalars['ID'];
};

/** CashMonitoringRecords create input */
export type CashMonitoringRecordCreateInput = {
  portfolioCompany?: Maybe<CashMonitoringRecordsPortfolioCompanyRelationInput>;
  date: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

/** CashMonitoringRecordManyResponse output */
export type CashMonitoringRecordManyResponse = {
  __typename?: 'CashMonitoringRecordManyResponse';
  /** List items */
  items: Array<CashMonitoringRecord>;
  /** List items count */
  count: Scalars['Int'];
};

/** CashMonitoringRecords create many input */
export type CashMonitoringRecordCreateManyInput = {
  portfolioCompany: CashMonitoringRecordsPortfolioCompanyManyRelationInput;
  date: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

/** CashMonitoringRecords relation input */
export type CashMonitoringRecordsPortfolioCompanyManyRelationInput = {
  connect?: Maybe<PortfolioCompanyKeyFilter>;
};

/** CashMonitoringRecords delete input */
export type CashMonitoringRecordDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** CashMonitoringRecords update input */
export type CashMonitoringRecordUpdateByFilterInput = {
  date?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  note?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  action?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

export type UpdateByFilterDateInput = {
  set?: Maybe<Scalars['String']>;
  add?: Maybe<UpdateByFilterDatePartsInput>;
  sub?: Maybe<UpdateByFilterDatePartsInput>;
};

export type UpdateByFilterDatePartsInput = {
  years?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
};

/** CiCdMigrations create input */
export type CiCdMigrationCreateInput = {
  name: Scalars['String'];
  code: Scalars['String'];
};

/** CiCdMigrationManyResponse output */
export type CiCdMigrationManyResponse = {
  __typename?: 'CiCdMigrationManyResponse';
  /** List items */
  items: Array<CiCdMigration>;
  /** List items count */
  count: Scalars['Int'];
};

/** CiCdMigrations create many input */
export type CiCdMigrationCreateManyInput = {
  name: Scalars['String'];
  code: Scalars['String'];
};

/** CiCdMigrations delete input */
export type CiCdMigrationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

export type CiCdMigrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** CiCdMigrations update input */
export type CiCdMigrationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

/** CiCdMigrations update input */
export type CiCdMigrationUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Companies create input */
export type CompanyCreateInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationRelationInput>;
  documents?: Maybe<CompaniesDocumentsRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesRelationInput>;
  owner?: Maybe<CompaniesOwnerRelationInput>;
  integration?: Maybe<CompaniesIntegrationRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyRelationInput>;
  team?: Maybe<CompaniesTeamRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantRelationInput>;
};

/** CompanyManyResponse output */
export type CompanyManyResponse = {
  __typename?: 'CompanyManyResponse';
  /** List items */
  items: Array<Company>;
  /** List items count */
  count: Scalars['Int'];
};

/** Companies create many input */
export type CompanyCreateManyInput = {
  /** Company name */
  name: Scalars['String'];
  address?: Maybe<SmartAddressInput>;
  logo?: Maybe<CompaniesLogoManyRelationInput>;
  companyStory?: Maybe<Scalars['String']>;
  founderStory?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  customersStrategy?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Int']>;
  capitalType?: Maybe<Array<Maybe<Scalars['String']>>>;
  capitalStrategy?: Maybe<Array<Maybe<Scalars['String']>>>;
  equityCapitalAmount?: Maybe<Scalars['Int']>;
  equityCapitalOwner?: Maybe<Array<Maybe<Scalars['String']>>>;
  monthsToNextRound?: Maybe<Scalars['Int']>;
  securedDebt?: Maybe<Scalars['Int']>;
  foundedYear?: Maybe<Scalars['Int']>;
  structure?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactTitle?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryCustomerBase?: Maybe<Scalars['String']>;
  notes?: Maybe<CompaniesNotesManyRelationInput>;
  loanApplication?: Maybe<CompaniesLoanApplicationManyRelationInput>;
  documents?: Maybe<CompaniesDocumentsManyRelationInput>;
  facilities?: Maybe<CompaniesFacilitiesManyRelationInput>;
  owner?: Maybe<CompaniesOwnerManyRelationInput>;
  integration?: Maybe<CompaniesIntegrationManyRelationInput>;
  portfolioCompany?: Maybe<CompaniesPortfolioCompanyManyRelationInput>;
  team?: Maybe<CompaniesTeamManyRelationInput>;
  idealRaiseAmount?: Maybe<Scalars['Int']>;
  borrowedDebt?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<CompaniesMetricsManyRelationInput>;
  saaSScore?: Maybe<Scalars['Int']>;
  primaryBusinessModel?: Maybe<Scalars['String']>;
  monitoringValues?: Maybe<CompaniesMonitoringValuesManyRelationInput>;
  applicationFinalized?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompaniesCompanyManyRelationInput>;
  inboxMessages?: Maybe<CompaniesInboxMessagesManyRelationInput>;
  companyStep?: Maybe<CompaniesCompanyStepManyRelationInput>;
  contactLastName?: Maybe<Scalars['String']>;
  covenantValue?: Maybe<CompaniesCovenantValueManyRelationInput>;
  customCovenant?: Maybe<CompaniesCustomCovenantManyRelationInput>;
};

/** Companies relation input */
export type CompaniesLogoManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** Companies relation input */
export type CompaniesNotesManyRelationInput = {
  connect?: Maybe<Array<NoteKeyFilter>>;
};

/** Companies relation input */
export type CompaniesLoanApplicationManyRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
};

/** Companies relation input */
export type CompaniesDocumentsManyRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
};

/** Companies relation input */
export type CompaniesFacilitiesManyRelationInput = {
  connect?: Maybe<Array<FacilityKeyFilter>>;
};

/** Companies relation input */
export type CompaniesOwnerManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** Companies relation input */
export type CompaniesIntegrationManyRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
};

/** Companies relation input */
export type CompaniesPortfolioCompanyManyRelationInput = {
  connect?: Maybe<PortfolioCompanyKeyFilter>;
};

/** Companies relation input */
export type CompaniesTeamManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** Companies relation input */
export type CompaniesMetricsManyRelationInput = {
  connect?: Maybe<Array<MetricValueKeyFilter>>;
};

/** Companies relation input */
export type CompaniesMonitoringValuesManyRelationInput = {
  connect?: Maybe<Array<CompanyMonitoringKeyFilter>>;
};

/** Companies relation input */
export type CompaniesCompanyManyRelationInput = {
  connect?: Maybe<Array<ProgressStepValueKeyFilter>>;
};

/** Companies relation input */
export type CompaniesInboxMessagesManyRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
};

/** Companies relation input */
export type CompaniesCompanyStepManyRelationInput = {
  connect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
};

/** Companies relation input */
export type CompaniesCovenantValueManyRelationInput = {
  connect?: Maybe<Array<CovenantValueKeyFilter>>;
};

/** Companies relation input */
export type CompaniesCustomCovenantManyRelationInput = {
  connect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
};

/** Companies delete input */
export type CompanyDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** CompanyMonitoring create input */
export type CompanyMonitoringCreateInput = {
  committed?: Maybe<Scalars['Int']>;
  deployed?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanyMonitoringCompaniesRelationInput>;
  date?: Maybe<Scalars['Date']>;
};

/** CompanyMonitoringManyResponse output */
export type CompanyMonitoringManyResponse = {
  __typename?: 'CompanyMonitoringManyResponse';
  /** List items */
  items: Array<CompanyMonitoring>;
  /** List items count */
  count: Scalars['Int'];
};

/** CompanyMonitoring create many input */
export type CompanyMonitoringCreateManyInput = {
  committed?: Maybe<Scalars['Int']>;
  deployed?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanyMonitoringCompaniesManyRelationInput>;
  date?: Maybe<Scalars['Date']>;
};

/** CompanyMonitoring relation input */
export type CompanyMonitoringCompaniesManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** CompanyMonitoring delete input */
export type CompanyMonitoringDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** CompanyMonitoring update input */
export type CompanyMonitoringUpdateByFilterInput = {
  committed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  deployed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  date?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
};

export type UpdateByFilterIntInput = {
  add?: Maybe<Scalars['Int']>;
  sub?: Maybe<Scalars['Int']>;
  mult?: Maybe<Scalars['Int']>;
  div?: Maybe<Scalars['Int']>;
  mod?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
  pow?: Maybe<Scalars['Int']>;
  sqrt?: Maybe<Scalars['Boolean']>;
  prec?: Maybe<Scalars['Int']>;
};

/** Companies update input */
export type CompanyUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  companyStory?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  founderStory?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  productDescription?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  marketDescription?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  customersStrategy?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  capital?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  capitalType?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  capitalStrategy?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  equityCapitalAmount?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  equityCapitalOwner?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  monthsToNextRound?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  securedDebt?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  foundedYear?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  structure?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  contactFirstName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactTitle?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactEmail?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  monitoringStatus?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  primaryCustomerBase?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  idealRaiseAmount?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  borrowedDebt?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  borrower?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  saaSScore?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  primaryBusinessModel?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  applicationFinalized?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  contactLastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Covenants create input */
export type CovenantCreateInput = {
  covenantValue?: Maybe<CovenantsCovenantValueRelationInput>;
  name: Scalars['String'];
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueRelationInput>;
};

/** CovenantManyResponse output */
export type CovenantManyResponse = {
  __typename?: 'CovenantManyResponse';
  /** List items */
  items: Array<Covenant>;
  /** List items count */
  count: Scalars['Int'];
};

/** Covenants create many input */
export type CovenantCreateManyInput = {
  covenantValue?: Maybe<CovenantsCovenantValueManyRelationInput>;
  name: Scalars['String'];
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueManyRelationInput>;
};

/** Covenants relation input */
export type CovenantsCovenantValueManyRelationInput = {
  connect?: Maybe<Array<CovenantValueKeyFilter>>;
};

/** Covenants relation input */
export type CovenantsCustomCovenantValueManyRelationInput = {
  connect?: Maybe<Array<CustomCovenantValueKeyFilter>>;
};

/** Covenants delete input */
export type CovenantDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Covenants update input */
export type CovenantUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  covenantValue?: Maybe<CovenantsCovenantValueUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  customCovenantValue?: Maybe<CovenantsCustomCovenantValueUpdateRelationInput>;
};

/** Covenants update input */
export type CovenantUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** CovenantValues create input */
export type CovenantValueCreateInput = {
  covenant?: Maybe<CovenantValuesCovenantRelationInput>;
  company?: Maybe<CovenantValuesCompanyRelationInput>;
  value?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

/** CovenantValueManyResponse output */
export type CovenantValueManyResponse = {
  __typename?: 'CovenantValueManyResponse';
  /** List items */
  items: Array<CovenantValue>;
  /** List items count */
  count: Scalars['Int'];
};

/** CovenantValues create many input */
export type CovenantValueCreateManyInput = {
  covenant?: Maybe<CovenantValuesCovenantManyRelationInput>;
  company: CovenantValuesCompanyManyRelationInput;
  value?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

/** CovenantValues relation input */
export type CovenantValuesCovenantManyRelationInput = {
  connect?: Maybe<CovenantKeyFilter>;
};

/** CovenantValues relation input */
export type CovenantValuesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** CovenantValues delete input */
export type CovenantValueDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** CovenantValues update input */
export type CovenantValueUpdateByFilterInput = {
  value?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  isEnabled?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** CustomCovenantValues create input */
export type CustomCovenantValueCreateInput = {
  covenant?: Maybe<CustomCovenantValuesCovenantRelationInput>;
  company?: Maybe<CustomCovenantValuesCompanyRelationInput>;
  date: Scalars['Date'];
  actual?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
  inCompliance?: Maybe<Scalars['String']>;
};

/** CustomCovenantValueManyResponse output */
export type CustomCovenantValueManyResponse = {
  __typename?: 'CustomCovenantValueManyResponse';
  /** List items */
  items: Array<CustomCovenantValue>;
  /** List items count */
  count: Scalars['Int'];
};

/** CustomCovenantValues create many input */
export type CustomCovenantValueCreateManyInput = {
  covenant: CustomCovenantValuesCovenantManyRelationInput;
  company: CustomCovenantValuesCompanyManyRelationInput;
  date: Scalars['Date'];
  actual?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
  inCompliance?: Maybe<Scalars['String']>;
};

/** CustomCovenantValues relation input */
export type CustomCovenantValuesCovenantManyRelationInput = {
  connect?: Maybe<CovenantKeyFilter>;
};

/** CustomCovenantValues relation input */
export type CustomCovenantValuesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** CustomCovenantValues delete input */
export type CustomCovenantValueDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** CustomCovenantValues update input */
export type CustomCovenantValueUpdateByFilterInput = {
  date?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  actual?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  difference?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  inCompliance?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** DeployingBuildInput */
export type DeployingBuildInput = {
  buildName: Scalars['String'];
  options?: Maybe<DeployOptions>;
};

/** DeployOptions */
export type DeployOptions = {
  mode?: Maybe<DeployModeEnum>;
  pluginNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  extensionNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum DeployModeEnum {
  Full = 'FULL',
  OnlyPlugins = 'ONLY_PLUGINS',
  OnlyProject = 'ONLY_PROJECT',
  Functions = 'FUNCTIONS',
  Migrations = 'MIGRATIONS',
}

/** Documents create input */
export type DocumentCreateInput = {
  file?: Maybe<DocumentsFileRelationInput>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageRelationInput>;
};

/** DocumentManyResponse output */
export type DocumentManyResponse = {
  __typename?: 'DocumentManyResponse';
  /** List items */
  items: Array<Document>;
  /** List items count */
  count: Scalars['Int'];
};

/** Documents create many input */
export type DocumentCreateManyInput = {
  file: DocumentsFileManyRelationInput;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  company?: Maybe<DocumentsCompanyManyRelationInput>;
  loanApplication?: Maybe<DocumentsLoanApplicationManyRelationInput>;
  private?: Maybe<Scalars['Boolean']>;
  inboxMessage?: Maybe<DocumentsInboxMessageManyRelationInput>;
};

/** Documents relation input */
export type DocumentsFileManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** Documents relation input */
export type DocumentsCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** Documents relation input */
export type DocumentsLoanApplicationManyRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
};

/** Documents relation input */
export type DocumentsInboxMessageManyRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
};

/** Documents delete input */
export type DocumentDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Documents update input */
export type DocumentUpdateByFilterInput = {
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  type?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  source?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  private?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** Draws create input */
export type DrawCreateInput = {
  facility?: Maybe<DrawsFacilityRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawsDrawHistoryRelationInput>;
};

/** DrawManyResponse output */
export type DrawManyResponse = {
  __typename?: 'DrawManyResponse';
  /** List items */
  items: Array<Draw>;
  /** List items count */
  count: Scalars['Int'];
};

/** Draws create many input */
export type DrawCreateManyInput = {
  facility: DrawsFacilityManyRelationInput;
  active?: Maybe<Scalars['Boolean']>;
  fundedDate?: Maybe<Scalars['Date']>;
  maturityDate?: Maybe<Scalars['Date']>;
  amountDeployed?: Maybe<Scalars['Int']>;
  interestRate?: Maybe<Scalars['Float']>;
  cashShare?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  amortizationPeriod?: Maybe<Scalars['Int']>;
  iOPeriodAmortizationPeriod?: Maybe<Scalars['Int']>;
  exitFee?: Maybe<Scalars['Float']>;
  prepayment?: Maybe<Scalars['String']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  drawHistory?: Maybe<DrawsDrawHistoryManyRelationInput>;
};

/** Draws relation input */
export type DrawsFacilityManyRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
};

/** Draws relation input */
export type DrawsDrawHistoryManyRelationInput = {
  connect?: Maybe<Array<DrawHistoryKeyFilter>>;
};

/** Draws delete input */
export type DrawDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** DrawHistories create input */
export type DrawHistoryCreateInput = {
  draw?: Maybe<DrawHistoriesDrawRelationInput>;
  facility?: Maybe<DrawHistoriesFacilityRelationInput>;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueRelationInput>;
};

/** DrawHistoryManyResponse output */
export type DrawHistoryManyResponse = {
  __typename?: 'DrawHistoryManyResponse';
  /** List items */
  items: Array<DrawHistory>;
  /** List items count */
  count: Scalars['Int'];
};

/** DrawHistories create many input */
export type DrawHistoryCreateManyInput = {
  draw: DrawHistoriesDrawManyRelationInput;
  facility: DrawHistoriesFacilityManyRelationInput;
  drawHistoryValue?: Maybe<DrawHistoriesDrawHistoryValueManyRelationInput>;
};

/** DrawHistories relation input */
export type DrawHistoriesDrawManyRelationInput = {
  connect?: Maybe<DrawKeyFilter>;
};

/** DrawHistories relation input */
export type DrawHistoriesFacilityManyRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
};

/** DrawHistories relation input */
export type DrawHistoriesDrawHistoryValueManyRelationInput = {
  connect?: Maybe<Array<DrawHistoryValueKeyFilter>>;
};

/** DrawHistories delete input */
export type DrawHistoryDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** DrawHistoryValues create input */
export type DrawHistoryValueCreateInput = {
  drawHistory?: Maybe<DrawHistoryValuesDrawHistoryRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
};

/** DrawHistoryValueManyResponse output */
export type DrawHistoryValueManyResponse = {
  __typename?: 'DrawHistoryValueManyResponse';
  /** List items */
  items: Array<DrawHistoryValue>;
  /** List items count */
  count: Scalars['Int'];
};

/** DrawHistoryValues create many input */
export type DrawHistoryValueCreateManyInput = {
  drawHistory: DrawHistoryValuesDrawHistoryManyRelationInput;
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
};

/** DrawHistoryValues relation input */
export type DrawHistoryValuesDrawHistoryManyRelationInput = {
  connect?: Maybe<DrawHistoryKeyFilter>;
};

/** DrawHistoryValues delete input */
export type DrawHistoryValueDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** DrawHistoryValues update input */
export type DrawHistoryValueUpdateByFilterInput = {
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  date?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
};

/** Draws update input */
export type DrawUpdateByFilterInput = {
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  fundedDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  maturityDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  amountDeployed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  interestRate?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  cashShare?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  returnCap?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  term?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  iOPeriod?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  amortizationPeriod?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  iOPeriodAmortizationPeriod?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  exitFee?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  prepayment?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  iRR?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  mOIC?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  comments?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  prepaymentMonths?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
};

export type UpdateByFilterFloatInput = {
  add?: Maybe<Scalars['Float']>;
  sub?: Maybe<Scalars['Float']>;
  mult?: Maybe<Scalars['Float']>;
  div?: Maybe<Scalars['Float']>;
  mod?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
  pow?: Maybe<Scalars['Float']>;
  sqrt?: Maybe<Scalars['Boolean']>;
  prec?: Maybe<Scalars['Int']>;
};

/** EnvironmentVariables create input */
export type EnvironmentVariableCreateInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** EnvironmentVariableManyResponse output */
export type EnvironmentVariableManyResponse = {
  __typename?: 'EnvironmentVariableManyResponse';
  /** List items */
  items: Array<EnvironmentVariable>;
  /** List items count */
  count: Scalars['Int'];
};

/** EnvironmentVariables create many input */
export type EnvironmentVariableCreateManyInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** EnvironmentVariables delete input */
export type EnvironmentVariableDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

export type EnvironmentVariableKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  value?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Facilities create input */
export type FacilityCreateInput = {
  structure: Scalars['String'];
  amountCommitted?: Maybe<Scalars['Int']>;
  company?: Maybe<FacilitiesCompanyRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryRelationInput>;
};

/** FacilityManyResponse output */
export type FacilityManyResponse = {
  __typename?: 'FacilityManyResponse';
  /** List items */
  items: Array<Facility>;
  /** List items count */
  count: Scalars['Int'];
};

/** Facilities create many input */
export type FacilityCreateManyInput = {
  structure: Scalars['String'];
  amountCommitted?: Maybe<Scalars['Int']>;
  company: FacilitiesCompanyManyRelationInput;
  active?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  totalAmountDeployed?: Maybe<Scalars['Int']>;
  nextAvailableDraw?: Maybe<Scalars['Date']>;
  /**
   * Closing Fee % (draw) 1.5%
   * Closing Fee % (facility) 1.5%
   */
  closingFee?: Maybe<Scalars['String']>;
  successFee?: Maybe<Scalars['Float']>;
  minCashCovenant?: Maybe<Scalars['Int']>;
  minCashRunawayCovenant?: Maybe<Scalars['Int']>;
  validityGuarantee?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  draws?: Maybe<FacilitiesDrawsManyRelationInput>;
  amountDeployed?: Maybe<Scalars['Int']>;
  fundingDate?: Maybe<Scalars['Date']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  closingDate?: Maybe<Scalars['Date']>;
  finalAdvanceRate?: Maybe<Scalars['Date']>;
  facilityHistory?: Maybe<FacilitiesFacilityHistoryManyRelationInput>;
  drawHistory?: Maybe<FacilitiesDrawHistoryManyRelationInput>;
};

/** Facilities relation input */
export type FacilitiesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** Facilities relation input */
export type FacilitiesDrawsManyRelationInput = {
  connect?: Maybe<Array<DrawKeyFilter>>;
};

/** Facilities relation input */
export type FacilitiesFacilityHistoryManyRelationInput = {
  connect?: Maybe<Array<FacilityHistoryKeyFilter>>;
};

/** Facilities relation input */
export type FacilitiesDrawHistoryManyRelationInput = {
  connect?: Maybe<Array<DrawHistoryKeyFilter>>;
};

/** Facilities delete input */
export type FacilityDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** FacilityHistories create input */
export type FacilityHistoryCreateInput = {
  facility?: Maybe<FacilityHistoriesFacilityRelationInput>;
  facilityHistoryValue?: Maybe<FacilityHistoriesFacilityHistoryValueRelationInput>;
};

/** FacilityHistoryManyResponse output */
export type FacilityHistoryManyResponse = {
  __typename?: 'FacilityHistoryManyResponse';
  /** List items */
  items: Array<FacilityHistory>;
  /** List items count */
  count: Scalars['Int'];
};

/** FacilityHistories create many input */
export type FacilityHistoryCreateManyInput = {
  facility: FacilityHistoriesFacilityManyRelationInput;
  facilityHistoryValue?: Maybe<FacilityHistoriesFacilityHistoryValueManyRelationInput>;
};

/** FacilityHistories relation input */
export type FacilityHistoriesFacilityManyRelationInput = {
  connect?: Maybe<FacilityKeyFilter>;
};

/** FacilityHistories relation input */
export type FacilityHistoriesFacilityHistoryValueManyRelationInput = {
  connect?: Maybe<Array<FacilityHistoryValueKeyFilter>>;
};

/** FacilityHistories delete input */
export type FacilityHistoryDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** FacilityHistoryValues create input */
export type FacilityHistoryValueCreateInput = {
  facilityHistory?: Maybe<FacilityHistoryValuesFacilityHistoryRelationInput>;
  active?: Maybe<Scalars['Boolean']>;
  date: Scalars['Date'];
  advanceRate?: Maybe<Scalars['Float']>;
};

/** FacilityHistoryValueManyResponse output */
export type FacilityHistoryValueManyResponse = {
  __typename?: 'FacilityHistoryValueManyResponse';
  /** List items */
  items: Array<FacilityHistoryValue>;
  /** List items count */
  count: Scalars['Int'];
};

/** FacilityHistoryValues create many input */
export type FacilityHistoryValueCreateManyInput = {
  facilityHistory: FacilityHistoryValuesFacilityHistoryManyRelationInput;
  active?: Maybe<Scalars['Boolean']>;
  date: Scalars['Date'];
  advanceRate?: Maybe<Scalars['Float']>;
};

/** FacilityHistoryValues relation input */
export type FacilityHistoryValuesFacilityHistoryManyRelationInput = {
  connect?: Maybe<FacilityHistoryKeyFilter>;
};

/** FacilityHistoryValues delete input */
export type FacilityHistoryValueDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** FacilityHistoryValues update input */
export type FacilityHistoryValueUpdateByFilterInput = {
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  date?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  advanceRate?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
};

/** Facilities update input */
export type FacilityUpdateByFilterInput = {
  structure?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  amountCommitted?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  comments?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  totalAmountDeployed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  nextAvailableDraw?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  closingFee?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  successFee?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  minCashCovenant?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  minCashRunawayCovenant?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  validityGuarantee?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  advanceRate?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  amountDeployed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  fundingDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  closingFeeValue?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  closingDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  finalAdvanceRate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
};

/** Files create input */
export type FileCreateInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsRelationInput>;
};

/** FileManyResponse output */
export type FileManyResponse = {
  __typename?: 'FileManyResponse';
  /** List items */
  items: Array<File>;
  /** List items count */
  count: Scalars['Int'];
};

/** Files create many input */
export type FileCreateManyInput = {
  fileId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  users_avatar?: Maybe<FilesUsers_AvatarManyRelationInput>;
  teamMembers_avatar?: Maybe<FilesTeamMembers_AvatarManyRelationInput>;
  portfolioCompanies_logo?: Maybe<FilesPortfolioCompanies_LogoManyRelationInput>;
  documents_file?: Maybe<FilesDocuments_FileManyRelationInput>;
  notes_attachments?: Maybe<FilesNotes_AttachmentsManyRelationInput>;
};

/** Files relation input */
export type FilesUsers_AvatarManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** Files relation input */
export type FilesTeamMembers_AvatarManyRelationInput = {
  connect?: Maybe<Array<TeamMemberKeyFilter>>;
};

/** Files relation input */
export type FilesPortfolioCompanies_LogoManyRelationInput = {
  connect?: Maybe<Array<CompanyKeyFilter>>;
};

/** Files relation input */
export type FilesDocuments_FileManyRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
};

/** Files relation input */
export type FilesNotes_AttachmentsManyRelationInput = {
  connect?: Maybe<Array<NoteKeyFilter>>;
};

/** Files delete input */
export type FileDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Files update input */
export type FileUpdateByFilterInput = {
  downloadUrl?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  shareUrl?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  provider?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  public?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  uploaded?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  filename?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  uploadUrl?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  fields?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  meta?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  mods?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

/** InboxMessages create input */
export type InboxMessageCreateInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsRelationInput>;
  company?: Maybe<InboxMessagesCompanyRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnRelationInput>;
  replies?: Maybe<InboxMessagesRepliesRelationInput>;
};

/** InboxMessageManyResponse output */
export type InboxMessageManyResponse = {
  __typename?: 'InboxMessageManyResponse';
  /** List items */
  items: Array<InboxMessage>;
  /** List items count */
  count: Scalars['Int'];
};

/** InboxMessages create many input */
export type InboxMessageCreateManyInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<InboxMessagesAttachmentsManyRelationInput>;
  company?: Maybe<InboxMessagesCompanyManyRelationInput>;
  isByAdministrator?: Maybe<Scalars['Boolean']>;
  repliedOn?: Maybe<InboxMessagesRepliedOnManyRelationInput>;
  replies?: Maybe<InboxMessagesRepliesManyRelationInput>;
};

/** InboxMessages relation input */
export type InboxMessagesAttachmentsManyRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
};

/** InboxMessages relation input */
export type InboxMessagesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** InboxMessages relation input */
export type InboxMessagesRepliedOnManyRelationInput = {
  connect?: Maybe<InboxMessageKeyFilter>;
};

/** InboxMessages relation input */
export type InboxMessagesRepliesManyRelationInput = {
  connect?: Maybe<Array<InboxMessageKeyFilter>>;
};

/** InboxMessages delete input */
export type InboxMessageDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** InboxMessages update input */
export type InboxMessageUpdateByFilterInput = {
  subject?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  body?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  isRead?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  isByAdministrator?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** Integrations create input */
export type IntegrationCreateInput = {
  company?: Maybe<IntegrationsCompanyRelationInput>;
  xero?: Maybe<IntegrationsXeroRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksRelationInput>;
  stripe?: Maybe<IntegrationsStripeRelationInput>;
  plaid?: Maybe<IntegrationsPlaidRelationInput>;
};

/** IntegrationManyResponse output */
export type IntegrationManyResponse = {
  __typename?: 'IntegrationManyResponse';
  /** List items */
  items: Array<Integration>;
  /** List items count */
  count: Scalars['Int'];
};

/** Integrations create many input */
export type IntegrationCreateManyInput = {
  company?: Maybe<IntegrationsCompanyManyRelationInput>;
  xero?: Maybe<IntegrationsXeroManyRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksManyRelationInput>;
  stripe?: Maybe<IntegrationsStripeManyRelationInput>;
  plaid?: Maybe<IntegrationsPlaidManyRelationInput>;
};

/** Integrations relation input */
export type IntegrationsCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** Integrations relation input */
export type IntegrationsXeroManyRelationInput = {
  connect?: Maybe<XeroIntegrationKeyFilter>;
};

/** Integrations relation input */
export type IntegrationsQuickBooksManyRelationInput = {
  connect?: Maybe<QuickBooksIntegrationKeyFilter>;
};

/** Integrations relation input */
export type IntegrationsStripeManyRelationInput = {
  connect?: Maybe<StripeIntegrationKeyFilter>;
};

/** Integrations relation input */
export type IntegrationsPlaidManyRelationInput = {
  connect?: Maybe<Array<PlaidIntegrationKeyFilter>>;
};

/** Integrations delete input */
export type IntegrationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Integrations update input */
export type IntegrationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  company?: Maybe<IntegrationsCompanyUpdateRelationInput>;
  xero?: Maybe<IntegrationsXeroUpdateRelationInput>;
  quickBooks?: Maybe<IntegrationsQuickBooksUpdateRelationInput>;
  stripe?: Maybe<IntegrationsStripeUpdateRelationInput>;
  plaid?: Maybe<IntegrationsPlaidUpdateRelationInput>;
};

/** Invitations create input */
export type InvitationCreateInput = {
  invitedUser?: Maybe<InvitationsInvitedUserRelationInput>;
  meta?: Maybe<Scalars['JSON']>;
};

/** InvitationManyResponse output */
export type InvitationManyResponse = {
  __typename?: 'InvitationManyResponse';
  /** List items */
  items: Array<Invitation>;
  /** List items count */
  count: Scalars['Int'];
};

/** Invitations create many input */
export type InvitationCreateManyInput = {
  invitedUser: InvitationsInvitedUserManyRelationInput;
  meta?: Maybe<Scalars['JSON']>;
};

/** Invitations relation input */
export type InvitationsInvitedUserManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** Invitations delete input */
export type InvitationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Invitations update input */
export type InvitationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  invitedUser?: Maybe<InvitationsInvitedUserUpdateRelationInput>;
  meta?: Maybe<Scalars['JSON']>;
};

/** Invitations update input */
export type InvitationUpdateByFilterInput = {
  meta?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

export type InvitationsMutation = {
  __typename?: 'InvitationsMutation';
  invitationSend?: Maybe<InvitationSendResponse>;
  invitationResend?: Maybe<InvitationResendResponse>;
  invitationCancel?: Maybe<InvitationCancelResponse>;
};

export type InvitationsMutationInvitationSendArgs = {
  user: UserCreateInput;
  authProfileId?: Maybe<Scalars['ID']>;
  sendgridTemplateId?: Maybe<Scalars['String']>;
};

export type InvitationsMutationInvitationResendArgs = {
  id: Scalars['ID'];
  sendgridTemplateId?: Maybe<Scalars['String']>;
};

export type InvitationsMutationInvitationCancelArgs = {
  id: Scalars['ID'];
};

/** InvokeFunctionResponse */
export type InvokeFunctionResponse = {
  __typename?: 'InvokeFunctionResponse';
  responseData: Scalars['String'];
};

/** InvokeData */
export type InvokeData = {
  functionName: Scalars['String'];
  inputArgs?: Maybe<Scalars['String']>;
};

/** LoanApplications create input */
export type LoanApplicationCreateInput = {
  company?: Maybe<LoanApplicationsCompanyRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplicationManyResponse output */
export type LoanApplicationManyResponse = {
  __typename?: 'LoanApplicationManyResponse';
  /** List items */
  items: Array<LoanApplication>;
  /** List items count */
  count: Scalars['Int'];
};

/** LoanApplications create many input */
export type LoanApplicationCreateManyInput = {
  company: LoanApplicationsCompanyManyRelationInput;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsManyRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamManyRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications relation input */
export type LoanApplicationsCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** LoanApplications relation input */
export type LoanApplicationsDocumentsManyRelationInput = {
  connect?: Maybe<Array<DocumentKeyFilter>>;
};

/** LoanApplications relation input */
export type LoanApplicationsDealTeamManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** LoanApplications delete input */
export type LoanApplicationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications update input */
export type LoanApplicationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  company?: Maybe<LoanApplicationsCompanyUpdateRelationInput>;
  stage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  applicantTitle?: Maybe<Scalars['String']>;
  amountRequested?: Maybe<Scalars['Int']>;
  amountCommitted?: Maybe<Scalars['Int']>;
  firstDrawProposed?: Maybe<Scalars['Int']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<LoanApplicationsDocumentsUpdateRelationInput>;
  structure?: Maybe<Scalars['String']>;
  dealTeam?: Maybe<LoanApplicationsDealTeamUpdateRelationInput>;
  status?: Maybe<Scalars['Boolean']>;
};

/** LoanApplications update input */
export type LoanApplicationUpdateByFilterInput = {
  stage?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  contactName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  applicantTitle?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  amountRequested?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  amountCommitted?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  firstDrawProposed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  targetCloseDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  structure?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** LoanMonitorings create input */
export type LoanMonitoringCreateInput = {
  merchantDate?: Maybe<Scalars['Date']>;
  companyName?: Maybe<Scalars['String']>;
  loanDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  monitoringStatus?: Maybe<Scalars['String']>;
  bankBalance?: Maybe<Scalars['Int']>;
  bankAccountDate?: Maybe<Scalars['Date']>;
};

/** LoanMonitoringManyResponse output */
export type LoanMonitoringManyResponse = {
  __typename?: 'LoanMonitoringManyResponse';
  /** List items */
  items: Array<LoanMonitoring>;
  /** List items count */
  count: Scalars['Int'];
};

/** LoanMonitorings create many input */
export type LoanMonitoringCreateManyInput = {
  merchantDate?: Maybe<Scalars['Date']>;
  companyName?: Maybe<Scalars['String']>;
  loanDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  monitoringStatus?: Maybe<Scalars['String']>;
  bankBalance?: Maybe<Scalars['Int']>;
  bankAccountDate?: Maybe<Scalars['Date']>;
};

/** LoanMonitorings delete input */
export type LoanMonitoringDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

export type LoanMonitoringKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** LoanMonitorings update input */
export type LoanMonitoringUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  merchantDate?: Maybe<Scalars['Date']>;
  companyName?: Maybe<Scalars['String']>;
  loanDate?: Maybe<Scalars['Date']>;
  accountingDate?: Maybe<Scalars['Date']>;
  monitoringStatus?: Maybe<Scalars['String']>;
  bankBalance?: Maybe<Scalars['Int']>;
  bankAccountDate?: Maybe<Scalars['Date']>;
};

/** LoanMonitorings update input */
export type LoanMonitoringUpdateByFilterInput = {
  merchantDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  companyName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  loanDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  accountingDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  monitoringStatus?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  bankBalance?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  bankAccountDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
};

/** Metrics create input */
export type MetricCreateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  values?: Maybe<MetricsValuesRelationInput>;
  format?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
};

/** MetricManyResponse output */
export type MetricManyResponse = {
  __typename?: 'MetricManyResponse';
  /** List items */
  items: Array<Metric>;
  /** List items count */
  count: Scalars['Int'];
};

/** Metrics create many input */
export type MetricCreateManyInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  values?: Maybe<MetricsValuesManyRelationInput>;
  format?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
};

/** Metrics relation input */
export type MetricsValuesManyRelationInput = {
  connect?: Maybe<Array<MetricValueKeyFilter>>;
};

/** Metrics delete input */
export type MetricDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Metrics update input */
export type MetricUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  values?: Maybe<MetricsValuesUpdateRelationInput>;
  format?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
};

/** Metrics update input */
export type MetricUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  format?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  type?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  saaSScoreWeight?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  saaSScoreWeightEnterprise?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  tierBands?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  metadata?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

/** MetricValues create input */
export type MetricValueCreateInput = {
  metric?: Maybe<MetricValuesMetricRelationInput>;
  company?: Maybe<MetricValuesCompanyRelationInput>;
  source: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  period?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

/** MetricValueManyResponse output */
export type MetricValueManyResponse = {
  __typename?: 'MetricValueManyResponse';
  /** List items */
  items: Array<MetricValue>;
  /** List items count */
  count: Scalars['Int'];
};

/** MetricValues create many input */
export type MetricValueCreateManyInput = {
  metric: MetricValuesMetricManyRelationInput;
  company: MetricValuesCompanyManyRelationInput;
  source: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  period?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

/** MetricValues relation input */
export type MetricValuesMetricManyRelationInput = {
  connect?: Maybe<MetricKeyFilter>;
};

/** MetricValues relation input */
export type MetricValuesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** MetricValues delete input */
export type MetricValueDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** MetricValues update input */
export type MetricValueUpdateByFilterInput = {
  source?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  value?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  adjustedValue?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  comment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  tier?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  date?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  period?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** Notes create input */
export type NoteCreateInput = {
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<NotesAttachmentsRelationInput>;
  company?: Maybe<NotesCompanyRelationInput>;
};

/** NoteManyResponse output */
export type NoteManyResponse = {
  __typename?: 'NoteManyResponse';
  /** List items */
  items: Array<Note>;
  /** List items count */
  count: Scalars['Int'];
};

/** Notes create many input */
export type NoteCreateManyInput = {
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<NotesAttachmentsManyRelationInput>;
  company?: Maybe<NotesCompanyManyRelationInput>;
};

/** Notes relation input */
export type NotesAttachmentsManyRelationInput = {
  connect?: Maybe<Array<FileKeyFilter>>;
};

/** Notes relation input */
export type NotesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** Notes delete input */
export type NoteDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Notes update input */
export type NoteUpdateByFilterInput = {
  title?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  body?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** PlaidIntegrationAccounts create input */
export type PlaidIntegrationAccountCreateInput = {
  plaidIntegration?: Maybe<PlaidIntegrationAccountsPlaidIntegrationRelationInput>;
  accountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
};

/** PlaidIntegrationAccountManyResponse output */
export type PlaidIntegrationAccountManyResponse = {
  __typename?: 'PlaidIntegrationAccountManyResponse';
  /** List items */
  items: Array<PlaidIntegrationAccount>;
  /** List items count */
  count: Scalars['Int'];
};

/** PlaidIntegrationAccounts create many input */
export type PlaidIntegrationAccountCreateManyInput = {
  plaidIntegration?: Maybe<PlaidIntegrationAccountsPlaidIntegrationManyRelationInput>;
  accountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
};

/** PlaidIntegrationAccounts relation input */
export type PlaidIntegrationAccountsPlaidIntegrationManyRelationInput = {
  connect?: Maybe<PlaidIntegrationKeyFilter>;
};

/** PlaidIntegrationAccounts delete input */
export type PlaidIntegrationAccountDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** PlaidIntegrationAccounts update input */
export type PlaidIntegrationAccountUpdateByFilterInput = {
  accountId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  mask?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** PlaidIntegration create input */
export type PlaidIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<PlaidIntegrationIntegrationsRelationInput>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountsRelationInput>;
  institutionId?: Maybe<Scalars['String']>;
};

/** PlaidIntegrationManyResponse output */
export type PlaidIntegrationManyResponse = {
  __typename?: 'PlaidIntegrationManyResponse';
  /** List items */
  items: Array<PlaidIntegration>;
  /** List items count */
  count: Scalars['Int'];
};

/** PlaidIntegration create many input */
export type PlaidIntegrationCreateManyInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<PlaidIntegrationIntegrationsManyRelationInput>;
  institutionMetadata?: Maybe<Scalars['JSON']>;
  accounts?: Maybe<PlaidIntegrationAccountsManyRelationInput>;
  institutionId?: Maybe<Scalars['String']>;
};

/** PlaidIntegration relation input */
export type PlaidIntegrationIntegrationsManyRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
};

/** PlaidIntegration relation input */
export type PlaidIntegrationAccountsManyRelationInput = {
  connect?: Maybe<Array<PlaidIntegrationAccountKeyFilter>>;
};

/** PlaidIntegration delete input */
export type PlaidIntegrationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** PlaidIntegration update input */
export type PlaidIntegrationUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  accessToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  institutionMetadata?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  institutionId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** PortfolioCompanies create input */
export type PortfolioCompanyCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<PortfolioCompaniesCompanyRelationInput>;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsRelationInput>;
};

/** PortfolioCompanyManyResponse output */
export type PortfolioCompanyManyResponse = {
  __typename?: 'PortfolioCompanyManyResponse';
  /** List items */
  items: Array<PortfolioCompany>;
  /** List items count */
  count: Scalars['Int'];
};

/** PortfolioCompanies create many input */
export type PortfolioCompanyCreateManyInput = {
  active?: Maybe<Scalars['Boolean']>;
  company: PortfolioCompaniesCompanyManyRelationInput;
  monitoringStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealTeam?: Maybe<PortfolioCompaniesDealTeamManyRelationInput>;
  cashMonitoringRecords?: Maybe<PortfolioCompaniesCashMonitoringRecordsManyRelationInput>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesDealTeamManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** PortfolioCompanies relation input */
export type PortfolioCompaniesCashMonitoringRecordsManyRelationInput = {
  connect?: Maybe<Array<CashMonitoringRecordKeyFilter>>;
};

/** PortfolioCompanies delete input */
export type PortfolioCompanyDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** PortfolioCompanies update input */
export type PortfolioCompanyUpdateByFilterInput = {
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  monitoringStatus?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
};

/** DeployDataResponse */
export type DeployDataResponse = {
  __typename?: 'DeployDataResponse';
  uploadBuildUrl: Scalars['String'];
  uploadMetaDataUrl: Scalars['String'];
  buildName: Scalars['String'];
};

/** ProgressCompanySteps create input */
export type ProgressCompanyStepCreateInput = {
  stage?: Maybe<ProgressCompanyStepsStageRelationInput>;
  company?: Maybe<ProgressCompanyStepsCompanyRelationInput>;
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressCompanyStepManyResponse output */
export type ProgressCompanyStepManyResponse = {
  __typename?: 'ProgressCompanyStepManyResponse';
  /** List items */
  items: Array<ProgressCompanyStep>;
  /** List items count */
  count: Scalars['Int'];
};

/** ProgressCompanySteps create many input */
export type ProgressCompanyStepCreateManyInput = {
  stage: ProgressCompanyStepsStageManyRelationInput;
  company: ProgressCompanyStepsCompanyManyRelationInput;
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressCompanySteps relation input */
export type ProgressCompanyStepsStageManyRelationInput = {
  connect?: Maybe<ProgressStageKeyFilter>;
};

/** ProgressCompanySteps relation input */
export type ProgressCompanyStepsCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** ProgressCompanySteps delete input */
export type ProgressCompanyStepDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** ProgressCompanySteps update input */
export type ProgressCompanyStepUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  comment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  bigfootTeamComment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  applicantComment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  isVisible?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** ProgressStages create input */
export type ProgressStageCreateInput = {
  name: Scalars['String'];
  steps?: Maybe<ProgressStagesStepsRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsRelationInput>;
};

/** ProgressStageManyResponse output */
export type ProgressStageManyResponse = {
  __typename?: 'ProgressStageManyResponse';
  /** List items */
  items: Array<ProgressStage>;
  /** List items count */
  count: Scalars['Int'];
};

/** ProgressStages create many input */
export type ProgressStageCreateManyInput = {
  name: Scalars['String'];
  steps?: Maybe<ProgressStagesStepsManyRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsManyRelationInput>;
};

/** ProgressStages relation input */
export type ProgressStagesStepsManyRelationInput = {
  connect?: Maybe<Array<ProgressStepKeyFilter>>;
};

/** ProgressStages relation input */
export type ProgressStagesCompanyStepsManyRelationInput = {
  connect?: Maybe<Array<ProgressCompanyStepKeyFilter>>;
};

/** ProgressStages delete input */
export type ProgressStageDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** ProgressStages update input */
export type ProgressStageUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  steps?: Maybe<ProgressStagesStepsUpdateRelationInput>;
  companySteps?: Maybe<ProgressStagesCompanyStepsUpdateRelationInput>;
};

/** ProgressStages update input */
export type ProgressStageUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** ProgressSteps create input */
export type ProgressStepCreateInput = {
  stage?: Maybe<ProgressStepsStageRelationInput>;
  stepValue?: Maybe<ProgressStepsStepValueRelationInput>;
  name: Scalars['String'];
  code: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
};

/** ProgressStepManyResponse output */
export type ProgressStepManyResponse = {
  __typename?: 'ProgressStepManyResponse';
  /** List items */
  items: Array<ProgressStep>;
  /** List items count */
  count: Scalars['Int'];
};

/** ProgressSteps create many input */
export type ProgressStepCreateManyInput = {
  stage: ProgressStepsStageManyRelationInput;
  stepValue?: Maybe<ProgressStepsStepValueManyRelationInput>;
  name: Scalars['String'];
  code: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
};

/** ProgressSteps relation input */
export type ProgressStepsStageManyRelationInput = {
  connect?: Maybe<ProgressStageKeyFilter>;
};

/** ProgressSteps relation input */
export type ProgressStepsStepValueManyRelationInput = {
  connect?: Maybe<Array<ProgressStepValueKeyFilter>>;
};

/** ProgressSteps delete input */
export type ProgressStepDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** ProgressSteps update input */
export type ProgressStepUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  order?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  comment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  helperText?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** ProgressStepValues create input */
export type ProgressStepValueCreateInput = {
  company?: Maybe<ProgressStepValuesCompanyRelationInput>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<ProgressStepValuesStepRelationInput>;
  customStepName?: Maybe<Scalars['String']>;
  customStepComment?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressStepValueManyResponse output */
export type ProgressStepValueManyResponse = {
  __typename?: 'ProgressStepValueManyResponse';
  /** List items */
  items: Array<ProgressStepValue>;
  /** List items count */
  count: Scalars['Int'];
};

/** ProgressStepValues create many input */
export type ProgressStepValueCreateManyInput = {
  company: ProgressStepValuesCompanyManyRelationInput;
  status?: Maybe<Scalars['String']>;
  step: ProgressStepValuesStepManyRelationInput;
  customStepName?: Maybe<Scalars['String']>;
  customStepComment?: Maybe<Scalars['String']>;
  bigfootTeamComment?: Maybe<Scalars['String']>;
  applicantComment?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** ProgressStepValues relation input */
export type ProgressStepValuesCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** ProgressStepValues relation input */
export type ProgressStepValuesStepManyRelationInput = {
  connect?: Maybe<ProgressStepKeyFilter>;
};

/** ProgressStepValues delete input */
export type ProgressStepValueDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** ProgressStepValues update input */
export type ProgressStepValueUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  customStepName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  customStepComment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  bigfootTeamComment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  applicantComment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  isVisible?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** QuickBooksIntegration create input */
export type QuickBooksIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<QuickBooksIntegrationIntegrationsRelationInput>;
  realmId?: Maybe<Scalars['String']>;
};

/** QuickBooksIntegrationManyResponse output */
export type QuickBooksIntegrationManyResponse = {
  __typename?: 'QuickBooksIntegrationManyResponse';
  /** List items */
  items: Array<QuickBooksIntegration>;
  /** List items count */
  count: Scalars['Int'];
};

/** QuickBooksIntegration create many input */
export type QuickBooksIntegrationCreateManyInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<QuickBooksIntegrationIntegrationsManyRelationInput>;
  realmId?: Maybe<Scalars['String']>;
};

/** QuickBooksIntegration relation input */
export type QuickBooksIntegrationIntegrationsManyRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
};

/** QuickBooksIntegration delete input */
export type QuickBooksIntegrationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** QuickBooksIntegration update input */
export type QuickBooksIntegrationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<QuickBooksIntegrationIntegrationsUpdateRelationInput>;
  realmId?: Maybe<Scalars['String']>;
};

/** QuickBooksIntegration update input */
export type QuickBooksIntegrationUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  accessToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  refreshToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  expiresAt?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  realmId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Roles create input */
export type RoleCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersRelationInput>;
  permissions?: Maybe<PermissionsInput>;
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersRelationInput>;
};

/** RoleManyResponse output */
export type RoleManyResponse = {
  __typename?: 'RoleManyResponse';
  /** List items */
  items: Array<Role>;
  /** List items count */
  count: Scalars['Int'];
};

/** Roles create many input */
export type RoleCreateManyInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  users?: Maybe<RolesUsersManyRelationInput>;
  apiTokens?: Maybe<RolesApiTokensManyRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesManyRelationInput>;
  teamMembers?: Maybe<RolesTeamMembersManyRelationInput>;
};

/** Roles relation input */
export type RolesUsersManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** Roles relation input */
export type RolesApiTokensManyRelationInput = {
  connect?: Maybe<Array<ApiTokenKeyFilter>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesManyRelationInput = {
  connect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
};

/** Roles relation input */
export type RolesTeamMembersManyRelationInput = {
  connect?: Maybe<Array<TeamMemberKeyFilter>>;
};

/** Roles delete input */
export type RoleDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** Roles update input */
export type RoleUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  systemType?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Settings update input */
export type SettingUpdateInput = {
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequireNumbers?: Maybe<Scalars['Boolean']>;
  passwordRequireSpecial?: Maybe<Scalars['Boolean']>;
  passwordRequireUppercase?: Maybe<Scalars['Boolean']>;
  passwordRequireLowercase?: Maybe<Scalars['Boolean']>;
  passwordUpdateInterval?: Maybe<Scalars['Int']>;
  rememberDevice?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  vanityUrl?: Maybe<Scalars['String']>;
  buttonLinkColor?: Maybe<Scalars['String']>;
  userInterfaceStyle?: Maybe<Scalars['String']>;
  menuBarBGColor?: Maybe<Scalars['String']>;
  menuBarIconsColor?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  containerColor?: Maybe<Scalars['String']>;
  leftNavColor?: Maybe<Scalars['String']>;
};

/** SortableLoanApplicationManyResponse output */
export type SortableLoanApplicationManyResponse = {
  __typename?: 'SortableLoanApplicationManyResponse';
  /** List items */
  items: Array<SortableLoanApplication>;
  /** List items count */
  count: Scalars['Int'];
};

/** SortableLoanApplications update input */
export type SortableLoanApplicationUpdateByFilterInput = {
  stage?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  applicantTitle?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  targetCloseDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  amountRequested?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  amountCommitted?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  firstDrawProposed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  structure?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  companyId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  companyName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactFirstName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactLastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  ownerFirstName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  ownerLastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  ownerTitle?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  ownerId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  age?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  committed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  saasScore?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
};

/** SortablePortfolioCompanyManyResponse output */
export type SortablePortfolioCompanyManyResponse = {
  __typename?: 'SortablePortfolioCompanyManyResponse';
  /** List items */
  items: Array<SortablePortfolioCompany>;
  /** List items count */
  count: Scalars['Int'];
};

/** SortablePortfolioCompanies update input */
export type SortablePortfolioCompanyUpdateByFilterInput = {
  active?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  monitoringStatus?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactFirstName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  contactLastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  fundedDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  structure?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  deployed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  committed?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  term?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  finalAdvanceRate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  nextAvailableDraw?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
};

/** StripeIntegration create input */
export type StripeIntegrationCreateInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<StripeIntegrationIntegrationsRelationInput>;
  userId?: Maybe<Scalars['String']>;
};

/** StripeIntegrationManyResponse output */
export type StripeIntegrationManyResponse = {
  __typename?: 'StripeIntegrationManyResponse';
  /** List items */
  items: Array<StripeIntegration>;
  /** List items count */
  count: Scalars['Int'];
};

/** StripeIntegration create many input */
export type StripeIntegrationCreateManyInput = {
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<StripeIntegrationIntegrationsManyRelationInput>;
  userId?: Maybe<Scalars['String']>;
};

/** StripeIntegration relation input */
export type StripeIntegrationIntegrationsManyRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
};

/** StripeIntegration delete input */
export type StripeIntegrationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** StripeIntegration update input */
export type StripeIntegrationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  integrations?: Maybe<StripeIntegrationIntegrationsUpdateRelationInput>;
  userId?: Maybe<Scalars['String']>;
};

/** StripeIntegration update input */
export type StripeIntegrationUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  accessToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  refreshToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  userId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

export type SystemMutation = {
  __typename?: 'SystemMutation';
  applicationDelete?: Maybe<SuccessResponse>;
  applicationInstall?: Maybe<SystemApplication>;
  applicationUpdate?: Maybe<SystemApplication>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetailsUpdate, system.organizationPaymentDetailsUpdate or system.workspacePaymentDetailsUpdate` instead. */
  billingDetailsUpdate?: Maybe<SystemBillingDetailsResponse>;
  billingPlanUpdate?: Maybe<SystemBillingCurrentPlanResponse>;
  ciCommit?: Maybe<AsyncSession>;
  ciInstall?: Maybe<SuccessResponse>;
  deploy?: Maybe<Scalars['Boolean']>;
  environmentBackup?: Maybe<AsyncSession>;
  environmentBranch?: Maybe<AsyncSession>;
  environmentDelete?: Maybe<SuccessResponse>;
  environmentDeleteAsync?: Maybe<AsyncSession>;
  environmentMemberDelete: SuccessResponse;
  environmentMemberUpdate?: Maybe<SystemEnvironmentMember>;
  environmentRestore?: Maybe<AsyncSession>;
  environmentSetup?: Maybe<SuccessResponse>;
  fieldCreate: SystemTableField;
  fieldDelete: SuccessResponse;
  fieldUpdate: SystemTableField;
  fieldUpdatePosition: SuccessResponse;
  indexCreate: SystemTableIndex;
  indexDelete?: Maybe<SuccessResponse>;
  indexUpdate: SystemTableIndex;
  inviteMembers: Array<Maybe<SystemTeamInvitationDetails>>;
  invoke?: Maybe<SystemInvokeFunctionResponse>;
  memberAccountUpsert?: Maybe<SystemMemberAccountInfo>;
  memberInvitationAccept: SystemMemberInvitationAcceptResponse;
  memberInvitationCancel?: Maybe<SuccessResponse>;
  memberInvitationResend?: Maybe<SuccessResponse>;
  memberPaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  notificationUpdate?: Maybe<SuccessResponse>;
  organizationInviteUser?: Maybe<OrganizationUserInvitationResponse>;
  organizationInviteUserAccept?: Maybe<SuccessResponse>;
  organizationInviteUserCancel?: Maybe<SuccessResponse>;
  organizationPaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  organizationUpdate?: Maybe<SystemOrganizationItem>;
  organizationUserRemove?: Maybe<SuccessResponse>;
  organizationWorkspaceAdd?: Maybe<SuccessResponse>;
  organizationWorkspaceAddAccept?: Maybe<SuccessResponse>;
  organizationWorkspaceAddCancel?: Maybe<SuccessResponse>;
  organizationWorkspaceRemove?: Maybe<SuccessResponse>;
  organizationWorkspaceUserRemove?: Maybe<SuccessResponse>;
  organizationWorkspaceUserShare?: Maybe<SuccessResponse>;
  prepareDeploy: SystemDeployDataResponse;
  tableCreate: SystemTable;
  tableDelete: SuccessResponse;
  tableUpdate: SystemTable;
  viewCreate: SystemTable;
  viewUpdate: SystemTable;
  workspaceCreate?: Maybe<SystemWorkspaceCreateResponse>;
  workspaceCreateAsync?: Maybe<SystemWorkspaceCreateResponse>;
  workspaceDelete?: Maybe<SuccessResponse>;
  workspaceLeave?: Maybe<SuccessResponse>;
  workspacePaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  workspaceTransferAccept?: Maybe<SuccessResponse>;
  workspaceTransferCancel?: Maybe<SuccessResponse>;
  workspaceTransferRegister?: Maybe<SuccessResponse>;
  workspaceUpdate?: Maybe<SystemWorkspaceUpdateResponse>;
};

export type SystemMutationApplicationDeleteArgs = {
  data: SystemApplicationDeleteMutationInput;
};

export type SystemMutationApplicationInstallArgs = {
  data: SystemApplicationInstallInput;
};

export type SystemMutationApplicationUpdateArgs = {
  data: SystemApplicationUpdateInput;
};

export type SystemMutationBillingDetailsUpdateArgs = {
  data: SystemBillingDetailsUpdateMutationInput;
};

export type SystemMutationBillingPlanUpdateArgs = {
  data: SystemBillingPlanUpdateMutationInput;
};

export type SystemMutationCiCommitArgs = {
  mode?: Maybe<SystemCiCommitMode>;
  build?: Maybe<Scalars['String']>;
  migrationNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SystemMutationDeployArgs = {
  data?: Maybe<SystemDeployingBuildInput>;
};

export type SystemMutationEnvironmentBackupArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentBranchArgs = {
  name: Scalars['String'];
  mode?: Maybe<SystemBranchEnvironmentMode>;
};

export type SystemMutationEnvironmentDeleteArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentDeleteAsyncArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentMemberDeleteArgs = {
  filter?: Maybe<SystemEnvironmentMembersFilter>;
};

export type SystemMutationEnvironmentMemberUpdateArgs = {
  filter?: Maybe<SystemEnvironmentMembersFilter>;
  data?: Maybe<SystemEnvironmentMemberUpdateData>;
};

export type SystemMutationEnvironmentRestoreArgs = {
  environmentName: Scalars['String'];
  backup: Scalars['String'];
};

export type SystemMutationEnvironmentSetupArgs = {
  data?: Maybe<EnvironmentSetupInput>;
};

export type SystemMutationFieldCreateArgs = {
  data: SystemTableFieldCreateInput;
};

export type SystemMutationFieldDeleteArgs = {
  data: SystemTableFieldDeleteInput;
};

export type SystemMutationFieldUpdateArgs = {
  data: SystemTableFieldUpdateInput;
};

export type SystemMutationFieldUpdatePositionArgs = {
  data: SystemTableFieldPositionUpdateInput;
};

export type SystemMutationIndexCreateArgs = {
  data: SystemIndexCreateInput;
};

export type SystemMutationIndexDeleteArgs = {
  data: SystemIndexDeleteInput;
};

export type SystemMutationIndexUpdateArgs = {
  data: SystemIndexUpdateInput;
};

export type SystemMutationInviteMembersArgs = {
  data: SystemInviteMembersInput;
  force?: Maybe<Scalars['Boolean']>;
};

export type SystemMutationInvokeArgs = {
  data?: Maybe<SystemInvokeData>;
};

export type SystemMutationMemberAccountUpsertArgs = {
  data?: Maybe<SystemMemberAccountUpsertDataInput>;
};

export type SystemMutationMemberInvitationAcceptArgs = {
  data: SystemMemberInvitationAcceptInput;
};

export type SystemMutationMemberInvitationCancelArgs = {
  data: SystemMemberInvitationCancelInput;
};

export type SystemMutationMemberInvitationResendArgs = {
  data: SystemMemberInvitationResendInput;
};

export type SystemMutationMemberPaymentDetailsUpdateArgs = {
  data: SystemMemberPaymentDetailsUpdateMutationInput;
};

export type SystemMutationNotificationUpdateArgs = {
  id: Scalars['String'];
  status: NotificationStatusType;
};

export type SystemMutationOrganizationInviteUserArgs = {
  organizationId: Scalars['String'];
  email: Scalars['String'];
  role: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SystemMutationOrganizationInviteUserAcceptArgs = {
  invitationId: Scalars['String'];
};

export type SystemMutationOrganizationInviteUserCancelArgs = {
  invitationId: Scalars['String'];
};

export type SystemMutationOrganizationPaymentDetailsUpdateArgs = {
  data: SystemOrganizationPaymentDetailsUpdateMutationInput;
};

export type SystemMutationOrganizationUpdateArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<SystemOrganizationTypeEnum>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlCreateFileItemInput>;
};

export type SystemMutationOrganizationUserRemoveArgs = {
  organizationId: Scalars['String'];
  email: Scalars['String'];
};

export type SystemMutationOrganizationWorkspaceAddArgs = {
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SystemMutationOrganizationWorkspaceAddAcceptArgs = {
  sessionId: Scalars['String'];
};

export type SystemMutationOrganizationWorkspaceAddCancelArgs = {
  sessionId: Scalars['String'];
};

export type SystemMutationOrganizationWorkspaceRemoveArgs = {
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SystemMutationOrganizationWorkspaceUserRemoveArgs = {
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
  email: Scalars['String'];
};

export type SystemMutationOrganizationWorkspaceUserShareArgs = {
  environments?: Maybe<Array<Maybe<SystemOrganizationWorkspaceUserShareInfo>>>;
  email: Scalars['String'];
};

export type SystemMutationTableCreateArgs = {
  data: SystemTableCreateInput;
};

export type SystemMutationTableDeleteArgs = {
  data: SystemTableDeleteInput;
};

export type SystemMutationTableUpdateArgs = {
  data: SystemTableUpdateInput;
};

export type SystemMutationViewCreateArgs = {
  data: SystemViewCreateInput;
};

export type SystemMutationViewUpdateArgs = {
  data: SystemViewUpdateInput;
};

export type SystemMutationWorkspaceCreateArgs = {
  data: SystemWorkspaceCreateMutationInput;
};

export type SystemMutationWorkspaceCreateAsyncArgs = {
  data: SystemWorkspaceCreateMutationInput;
};

export type SystemMutationWorkspaceDeleteArgs = {
  data: SystemWorkspaceDeleteMutationInput;
};

export type SystemMutationWorkspaceLeaveArgs = {
  force?: Maybe<Scalars['Boolean']>;
};

export type SystemMutationWorkspacePaymentDetailsUpdateArgs = {
  data: SystemWorkspacePaymentDetailsUpdateMutationInput;
};

export type SystemMutationWorkspaceTransferAcceptArgs = {
  workspaceId: Scalars['String'];
};

export type SystemMutationWorkspaceTransferCancelArgs = {
  workspaceId: Scalars['String'];
};

export type SystemMutationWorkspaceTransferRegisterArgs = {
  owner: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SystemMutationWorkspaceUpdateArgs = {
  data: SystemWorkspaceUpdateMutationInput;
};

/** ApplicationDeleteMutationInput */
export type SystemApplicationDeleteMutationInput = {
  id: Scalars['String'];
  force?: Maybe<Scalars['Boolean']>;
};

/** Application install input */
export type SystemApplicationInstallInput = {
  appType: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<SystemApplicationStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** Application update input */
export type SystemApplicationUpdateInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<SystemApplicationStatusEnum>;
};

/** BillingDetailsUpdateMutationInput */
export type SystemBillingDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
};

/** BillingPlanUpdateMutationInput */
export type SystemBillingPlanUpdateMutationInput = {
  planId: Scalars['ID'];
};

/** Ci Commit Mode */
export enum SystemCiCommitMode {
  Full = 'FULL',
  OnlyMigrations = 'ONLY_MIGRATIONS',
  OnlyProject = 'ONLY_PROJECT',
}

/** DeployingBuildInput */
export type SystemDeployingBuildInput = {
  buildName: Scalars['String'];
  options?: Maybe<SystemDeployOptions>;
};

/** DeployOptions */
export type SystemDeployOptions = {
  mode?: Maybe<SystemDeployModeEnum>;
  pluginNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  extensionNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SystemDeployModeEnum {
  Full = 'FULL',
  OnlyPlugins = 'ONLY_PLUGINS',
  OnlyProject = 'ONLY_PROJECT',
  Functions = 'FUNCTIONS',
  Migrations = 'MIGRATIONS',
}

export enum SystemBranchEnvironmentMode {
  Full = 'FULL',
  System = 'SYSTEM',
}

export type SystemEnvironmentMembersFilter = {
  email: Scalars['String'];
};

export type SystemEnvironmentMemberUpdateData = {
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** EnvironmentSetupInput */
export type EnvironmentSetupInput = {
  deleteLock?: Maybe<Scalars['Boolean']>;
};

/** Team Invitation Details */
export type SystemTeamInvitationDetails = {
  __typename?: 'SystemTeamInvitationDetails';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  resentOn?: Maybe<Scalars['DateTime']>;
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
};

/** Invite members input */
export type SystemInviteMembersInput = {
  recipients: Array<SystemInviteRecipientInput>;
};

/** Invite recipient input */
export type SystemInviteRecipientInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
};

/** InvokeFunctionResponse */
export type SystemInvokeFunctionResponse = {
  __typename?: 'SystemInvokeFunctionResponse';
  responseData: Scalars['String'];
};

/** InvokeData */
export type SystemInvokeData = {
  functionName: Scalars['String'];
  inputArgs?: Maybe<Scalars['String']>;
};

export type SystemMemberAccountUpsertDataInput = {
  avatar?: Maybe<GraphQlCreateFileItemInput>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  learningMode?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  isDeveloper?: Maybe<Scalars['Boolean']>;
  aboutMe?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  linkedInUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  type?: Maybe<SystemUserType>;
  companyName?: Maybe<Scalars['String']>;
  projectDescription?: Maybe<Scalars['String']>;
};

/** GraphQLCreateFileItemInput */
export type GraphQlCreateFileItemInput = {
  create?: Maybe<GraphQlCreateFileCustomInput>;
};

/** GraphQLCreateFileCustomInput */
export type GraphQlCreateFileCustomInput = {
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
};

/** Member Invitation Accept Response */
export type SystemMemberInvitationAcceptResponse = {
  __typename?: 'SystemMemberInvitationAcceptResponse';
  success: Scalars['Boolean'];
};

/** Member invitation accept input */
export type SystemMemberInvitationAcceptInput = {
  id: Scalars['String'];
  accepted: Scalars['Boolean'];
};

/** Cancel members invitations input */
export type SystemMemberInvitationCancelInput = {
  email: Scalars['String'];
};

/** Resend member invitation input */
export type SystemMemberInvitationResendInput = {
  email: Scalars['ID'];
};

/** MemberPaymentDetailsUpdateMutationInput */
export type SystemMemberPaymentDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
};

export enum NotificationStatusType {
  Done = 'done',
}

export type OrganizationUserInvitationResponse = {
  __typename?: 'OrganizationUserInvitationResponse';
  invitationId: Scalars['String'];
};

/** OrganizationPaymentDetailsUpdateMutationInput */
export type SystemOrganizationPaymentDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type SystemOrganizationWorkspaceUserShareInfo = {
  environmentId: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** DeployDataResponse */
export type SystemDeployDataResponse = {
  __typename?: 'SystemDeployDataResponse';
  uploadBuildUrl: Scalars['String'];
  uploadMetaDataUrl: Scalars['String'];
  buildName: Scalars['String'];
};

export type SystemWorkspaceCreateResponse = {
  __typename?: 'SystemWorkspaceCreateResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** WorkspaceCreateMutationInput */
export type SystemWorkspaceCreateMutationInput = {
  name: Scalars['String'];
  image?: Maybe<GraphQlCreateFileItemInput>;
  billingPlanId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  kind?: Maybe<SystemWorkspaceKind>;
  description?: Maybe<Scalars['String']>;
};

/** Workspace Kind */
export enum SystemWorkspaceKind {
  Frontend = 'frontend',
  General = 'general',
}

/** WorkspaceDeleteMutationInput */
export type SystemWorkspaceDeleteMutationInput = {
  id: Scalars['ID'];
};

/** WorkspacePaymentDetailsUpdateMutationInput */
export type SystemWorkspacePaymentDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
};

export type SystemWorkspaceUpdateResponse = {
  __typename?: 'SystemWorkspaceUpdateResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlFileItemResponse>;
  description?: Maybe<Scalars['String']>;
};

/** WorkspaceUpdateMutationInput */
export type SystemWorkspaceUpdateMutationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlCreateFileItemInput>;
  description?: Maybe<Scalars['String']>;
};

/** TeamInvitations delete input */
export type TeamInvitationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** TeamMembers delete input */
export type TeamMemberDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** TeamMemberManyResponse output */
export type TeamMemberManyResponse = {
  __typename?: 'TeamMemberManyResponse';
  /** List items */
  items: Array<TeamMember>;
  /** List items count */
  count: Scalars['Int'];
};

/** TeamMembers update input */
export type TeamMemberUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  isOwner?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** ChangePasswordInput */
export type ChangePasswordInput = {
  refreshToken: Scalars['String'];
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  authProfileId: Scalars['ID'];
};

/** Users create input */
export type UserCreateInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  roles?: Maybe<UsersRolesRelationInput>;
  invitation?: Maybe<UsersInvitationRelationInput>;
  company?: Maybe<UsersCompanyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesRelationInput>;
};

/** UserManyResponse output */
export type UserManyResponse = {
  __typename?: 'UserManyResponse';
  /** List items */
  items: Array<User>;
  /** List items count */
  count: Scalars['Int'];
};

/** Users create many input */
export type UserCreateManyInput = {
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarManyRelationInput>;
  roles?: Maybe<UsersRolesManyRelationInput>;
  invitation?: Maybe<UsersInvitationManyRelationInput>;
  company?: Maybe<UsersCompanyManyRelationInput>;
  title?: Maybe<Scalars['String']>;
  employer?: Maybe<UsersEmployerManyRelationInput>;
  loanApplications?: Maybe<UsersLoanApplicationsManyRelationInput>;
  portfolioCompanies?: Maybe<UsersPortfolioCompaniesManyRelationInput>;
};

/** Users relation input */
export type UsersAvatarManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** Users relation input */
export type UsersRolesManyRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
};

/** Users relation input */
export type UsersInvitationManyRelationInput = {
  connect?: Maybe<InvitationKeyFilter>;
};

/** Users relation input */
export type UsersCompanyManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** Users relation input */
export type UsersEmployerManyRelationInput = {
  connect?: Maybe<CompanyKeyFilter>;
};

/** Users relation input */
export type UsersLoanApplicationsManyRelationInput = {
  connect?: Maybe<LoanApplicationKeyFilter>;
};

/** Users relation input */
export type UsersPortfolioCompaniesManyRelationInput = {
  connect?: Maybe<Array<PortfolioCompanyKeyFilter>>;
};

/** Users delete input */
export type UserDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** UserLoginInput */
export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  fromInvitation?: Maybe<Scalars['String']>;
  authProfileId?: Maybe<Scalars['ID']>;
};

/** PasswordForgotInput */
export type PasswordForgotInput = {
  email: Scalars['String'];
  authProfileId: Scalars['ID'];
};

/** PasswordForgotConfirmInput */
export type PasswordForgotConfirmInput = {
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
  authProfileId: Scalars['ID'];
};

/** RefreshTokenInput */
export type RefreshTokenInput = {
  email?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  authProfileId?: Maybe<Scalars['ID']>;
};

/** SignUpResendInput */
export type SignUpResendInput = {
  email: Scalars['String'];
};

/** Users update input */
export type UserUpdateByFilterInput = {
  email?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  origin?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  isOwner?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  is8base?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  firstName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  lastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  timezone?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  title?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** VerificationEmailResendInput */
export type VerificationEmailResendInput = {
  email: Scalars['String'];
};

export type WorkspaceCreateResponse = {
  __typename?: 'WorkspaceCreateResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** WorkspaceCreateMutationInput */
export type WorkspaceCreateMutationInput = {
  name: Scalars['String'];
  image?: Maybe<GraphQlCreateFileItemInput>;
  billingPlanId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  kind?: Maybe<WorkspaceKind>;
  description?: Maybe<Scalars['String']>;
};

/** Workspace Kind */
export enum WorkspaceKind {
  Frontend = 'frontend',
  General = 'general',
}

/** WorkspaceDeleteMutationInput */
export type WorkspaceDeleteMutationInput = {
  id: Scalars['ID'];
};

export type WorkspaceUpdateResponse = {
  __typename?: 'WorkspaceUpdateResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlFileItemResponse>;
  description?: Maybe<Scalars['String']>;
};

/** WorkspaceUpdateMutationInput */
export type WorkspaceUpdateMutationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlCreateFileItemInput>;
  description?: Maybe<Scalars['String']>;
};

/** XeroIntegrations create input */
export type XeroIntegrationCreateInput = {
  status: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<XeroIntegrationsIntegrationsRelationInput>;
  tenantId?: Maybe<Scalars['String']>;
};

/** XeroIntegrationManyResponse output */
export type XeroIntegrationManyResponse = {
  __typename?: 'XeroIntegrationManyResponse';
  /** List items */
  items: Array<XeroIntegration>;
  /** List items count */
  count: Scalars['Int'];
};

/** XeroIntegrations create many input */
export type XeroIntegrationCreateManyInput = {
  status: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<XeroIntegrationsIntegrationsManyRelationInput>;
  tenantId?: Maybe<Scalars['String']>;
};

/** XeroIntegrations relation input */
export type XeroIntegrationsIntegrationsManyRelationInput = {
  connect?: Maybe<IntegrationKeyFilter>;
};

/** XeroIntegrations delete input */
export type XeroIntegrationDeleteInput = {
  id?: Maybe<Scalars['ID']>;
  force?: Maybe<Scalars['Boolean']>;
};

/** XeroIntegrations update input */
export type XeroIntegrationUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Int']>;
  integrations?: Maybe<XeroIntegrationsIntegrationsUpdateRelationInput>;
  tenantId?: Maybe<Scalars['String']>;
};

/** XeroIntegrations update input */
export type XeroIntegrationUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  accessToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  refreshToken?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  expiresAt?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  tenantId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  ApiTokens?: Maybe<ApiTokenPayload>;
  AsyncTasks?: Maybe<AsyncTaskPayload>;
  AuthenticationProfiles?: Maybe<AuthenticationProfilePayload>;
  AuthenticationSettings?: Maybe<AuthenticationSettingPayload>;
  CashMonitoringRecords?: Maybe<CashMonitoringRecordPayload>;
  CiCdMigrations?: Maybe<CiCdMigrationPayload>;
  Companies?: Maybe<CompanyPayload>;
  CompanyMonitoring?: Maybe<CompanyMonitoringPayload>;
  CovenantValues?: Maybe<CovenantValuePayload>;
  Covenants?: Maybe<CovenantPayload>;
  CustomCovenantValues?: Maybe<CustomCovenantValuePayload>;
  Documents?: Maybe<DocumentPayload>;
  DrawHistories?: Maybe<DrawHistoryPayload>;
  DrawHistoryValues?: Maybe<DrawHistoryValuePayload>;
  Draws?: Maybe<DrawPayload>;
  EnvironmentVariables?: Maybe<EnvironmentVariablePayload>;
  Facilities?: Maybe<FacilityPayload>;
  FacilityHistories?: Maybe<FacilityHistoryPayload>;
  FacilityHistoryValues?: Maybe<FacilityHistoryValuePayload>;
  Files?: Maybe<FilePayload>;
  InboxMessages?: Maybe<InboxMessagePayload>;
  Integrations?: Maybe<IntegrationPayload>;
  Invitations?: Maybe<InvitationPayload>;
  LoanApplications?: Maybe<LoanApplicationPayload>;
  LoanMonitorings?: Maybe<LoanMonitoringPayload>;
  MetricValues?: Maybe<MetricValuePayload>;
  Metrics?: Maybe<MetricPayload>;
  Notes?: Maybe<NotePayload>;
  Permissions?: Maybe<PermissionPayload>;
  PlaidIntegration?: Maybe<PlaidIntegrationPayload>;
  PlaidIntegrationAccounts?: Maybe<PlaidIntegrationAccountPayload>;
  PortfolioCompanies?: Maybe<PortfolioCompanyPayload>;
  ProgressCompanySteps?: Maybe<ProgressCompanyStepPayload>;
  ProgressStages?: Maybe<ProgressStagePayload>;
  ProgressStepValues?: Maybe<ProgressStepValuePayload>;
  ProgressSteps?: Maybe<ProgressStepPayload>;
  QuickBooksIntegration?: Maybe<QuickBooksIntegrationPayload>;
  Roles?: Maybe<RolePayload>;
  Settings?: Maybe<SettingPayload>;
  SortableLoanApplications?: Maybe<SortableLoanApplicationPayload>;
  SortablePortfolioCompanies?: Maybe<SortablePortfolioCompanyPayload>;
  StripeIntegration?: Maybe<StripeIntegrationPayload>;
  TeamInvitations?: Maybe<TeamInvitationPayload>;
  TeamMembers?: Maybe<TeamMemberPayload>;
  Users?: Maybe<UserPayload>;
  XeroIntegrations?: Maybe<XeroIntegrationPayload>;
};

export type SubscriptionApiTokensArgs = {
  filter?: Maybe<ApiTokenSubscriptionFilter>;
};

export type SubscriptionAsyncTasksArgs = {
  filter?: Maybe<AsyncTaskSubscriptionFilter>;
};

export type SubscriptionAuthenticationProfilesArgs = {
  filter?: Maybe<AuthenticationProfileSubscriptionFilter>;
};

export type SubscriptionAuthenticationSettingsArgs = {
  filter?: Maybe<AuthenticationSettingSubscriptionFilter>;
};

export type SubscriptionCashMonitoringRecordsArgs = {
  filter?: Maybe<CashMonitoringRecordSubscriptionFilter>;
};

export type SubscriptionCiCdMigrationsArgs = {
  filter?: Maybe<CiCdMigrationSubscriptionFilter>;
};

export type SubscriptionCompaniesArgs = {
  filter?: Maybe<CompanySubscriptionFilter>;
};

export type SubscriptionCompanyMonitoringArgs = {
  filter?: Maybe<CompanyMonitoringSubscriptionFilter>;
};

export type SubscriptionCovenantValuesArgs = {
  filter?: Maybe<CovenantValueSubscriptionFilter>;
};

export type SubscriptionCovenantsArgs = {
  filter?: Maybe<CovenantSubscriptionFilter>;
};

export type SubscriptionCustomCovenantValuesArgs = {
  filter?: Maybe<CustomCovenantValueSubscriptionFilter>;
};

export type SubscriptionDocumentsArgs = {
  filter?: Maybe<DocumentSubscriptionFilter>;
};

export type SubscriptionDrawHistoriesArgs = {
  filter?: Maybe<DrawHistorySubscriptionFilter>;
};

export type SubscriptionDrawHistoryValuesArgs = {
  filter?: Maybe<DrawHistoryValueSubscriptionFilter>;
};

export type SubscriptionDrawsArgs = {
  filter?: Maybe<DrawSubscriptionFilter>;
};

export type SubscriptionEnvironmentVariablesArgs = {
  filter?: Maybe<EnvironmentVariableSubscriptionFilter>;
};

export type SubscriptionFacilitiesArgs = {
  filter?: Maybe<FacilitySubscriptionFilter>;
};

export type SubscriptionFacilityHistoriesArgs = {
  filter?: Maybe<FacilityHistorySubscriptionFilter>;
};

export type SubscriptionFacilityHistoryValuesArgs = {
  filter?: Maybe<FacilityHistoryValueSubscriptionFilter>;
};

export type SubscriptionFilesArgs = {
  filter?: Maybe<FileSubscriptionFilter>;
};

export type SubscriptionInboxMessagesArgs = {
  filter?: Maybe<InboxMessageSubscriptionFilter>;
};

export type SubscriptionIntegrationsArgs = {
  filter?: Maybe<IntegrationSubscriptionFilter>;
};

export type SubscriptionInvitationsArgs = {
  filter?: Maybe<InvitationSubscriptionFilter>;
};

export type SubscriptionLoanApplicationsArgs = {
  filter?: Maybe<LoanApplicationSubscriptionFilter>;
};

export type SubscriptionLoanMonitoringsArgs = {
  filter?: Maybe<LoanMonitoringSubscriptionFilter>;
};

export type SubscriptionMetricValuesArgs = {
  filter?: Maybe<MetricValueSubscriptionFilter>;
};

export type SubscriptionMetricsArgs = {
  filter?: Maybe<MetricSubscriptionFilter>;
};

export type SubscriptionNotesArgs = {
  filter?: Maybe<NoteSubscriptionFilter>;
};

export type SubscriptionPermissionsArgs = {
  filter?: Maybe<PermissionSubscriptionFilter>;
};

export type SubscriptionPlaidIntegrationArgs = {
  filter?: Maybe<PlaidIntegrationSubscriptionFilter>;
};

export type SubscriptionPlaidIntegrationAccountsArgs = {
  filter?: Maybe<PlaidIntegrationAccountSubscriptionFilter>;
};

export type SubscriptionPortfolioCompaniesArgs = {
  filter?: Maybe<PortfolioCompanySubscriptionFilter>;
};

export type SubscriptionProgressCompanyStepsArgs = {
  filter?: Maybe<ProgressCompanyStepSubscriptionFilter>;
};

export type SubscriptionProgressStagesArgs = {
  filter?: Maybe<ProgressStageSubscriptionFilter>;
};

export type SubscriptionProgressStepValuesArgs = {
  filter?: Maybe<ProgressStepValueSubscriptionFilter>;
};

export type SubscriptionProgressStepsArgs = {
  filter?: Maybe<ProgressStepSubscriptionFilter>;
};

export type SubscriptionQuickBooksIntegrationArgs = {
  filter?: Maybe<QuickBooksIntegrationSubscriptionFilter>;
};

export type SubscriptionRolesArgs = {
  filter?: Maybe<RoleSubscriptionFilter>;
};

export type SubscriptionSettingsArgs = {
  filter?: Maybe<SettingSubscriptionFilter>;
};

export type SubscriptionSortableLoanApplicationsArgs = {
  filter?: Maybe<SortableLoanApplicationSubscriptionFilter>;
};

export type SubscriptionSortablePortfolioCompaniesArgs = {
  filter?: Maybe<SortablePortfolioCompanySubscriptionFilter>;
};

export type SubscriptionStripeIntegrationArgs = {
  filter?: Maybe<StripeIntegrationSubscriptionFilter>;
};

export type SubscriptionTeamInvitationsArgs = {
  filter?: Maybe<TeamInvitationSubscriptionFilter>;
};

export type SubscriptionTeamMembersArgs = {
  filter?: Maybe<TeamMemberSubscriptionFilter>;
};

export type SubscriptionUsersArgs = {
  filter?: Maybe<UserSubscriptionFilter>;
};

export type SubscriptionXeroIntegrationsArgs = {
  filter?: Maybe<XeroIntegrationSubscriptionFilter>;
};

/** ApiTokens subscription payload */
export type ApiTokenPayload = {
  __typename?: 'ApiTokenPayload';
  mutation: MutationType;
  node?: Maybe<ApiToken>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<ApiToken>;
};

export enum MutationType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Destroy = 'destroy',
}

/** ApiTokens subscription filter */
export type ApiTokenSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ApiTokenFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** UpdatedFieldsFilter */
export type UpdatedFieldsFilter = {
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  every?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AsyncTasks subscription payload */
export type AsyncTaskPayload = {
  __typename?: 'AsyncTaskPayload';
  mutation: MutationType;
  node?: Maybe<AsyncTask>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<AsyncTask>;
};

/** AsyncTasks subscription filter */
export type AsyncTaskSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AsyncTaskFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** AuthenticationProfiles subscription payload */
export type AuthenticationProfilePayload = {
  __typename?: 'AuthenticationProfilePayload';
  mutation: MutationType;
  node?: Maybe<AuthenticationProfile>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<AuthenticationProfile>;
};

/** AuthenticationProfiles subscription filter */
export type AuthenticationProfileSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AuthenticationProfileFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** AuthenticationSettings subscription payload */
export type AuthenticationSettingPayload = {
  __typename?: 'AuthenticationSettingPayload';
  mutation: MutationType;
  node?: Maybe<AuthenticationSetting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<AuthenticationSetting>;
};

/** AuthenticationSettings subscription filter */
export type AuthenticationSettingSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AuthenticationSettingFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

export type AuthenticationSettingFilter = {
  createdAt?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  _fullText?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserFilter>;
  AND?: Maybe<Array<AuthenticationSettingFilter>>;
  OR?: Maybe<Array<AuthenticationSettingFilter>>;
};

/** CashMonitoringRecords subscription payload */
export type CashMonitoringRecordPayload = {
  __typename?: 'CashMonitoringRecordPayload';
  mutation: MutationType;
  node?: Maybe<CashMonitoringRecord>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<CashMonitoringRecord>;
};

/** CashMonitoringRecords subscription filter */
export type CashMonitoringRecordSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CashMonitoringRecordFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CiCdMigrations subscription payload */
export type CiCdMigrationPayload = {
  __typename?: 'CiCdMigrationPayload';
  mutation: MutationType;
  node?: Maybe<CiCdMigration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<CiCdMigration>;
};

/** CiCdMigrations subscription filter */
export type CiCdMigrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CiCdMigrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Companies subscription payload */
export type CompanyPayload = {
  __typename?: 'CompanyPayload';
  mutation: MutationType;
  node?: Maybe<Company>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Company>;
};

/** Companies subscription filter */
export type CompanySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CompanyFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CompanyMonitoring subscription payload */
export type CompanyMonitoringPayload = {
  __typename?: 'CompanyMonitoringPayload';
  mutation: MutationType;
  node?: Maybe<CompanyMonitoring>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<CompanyMonitoring>;
};

/** CompanyMonitoring subscription filter */
export type CompanyMonitoringSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CompanyMonitoringFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CovenantValues subscription payload */
export type CovenantValuePayload = {
  __typename?: 'CovenantValuePayload';
  mutation: MutationType;
  node?: Maybe<CovenantValue>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<CovenantValue>;
};

/** CovenantValues subscription filter */
export type CovenantValueSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CovenantValueFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Covenants subscription payload */
export type CovenantPayload = {
  __typename?: 'CovenantPayload';
  mutation: MutationType;
  node?: Maybe<Covenant>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Covenant>;
};

/** Covenants subscription filter */
export type CovenantSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CovenantFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CustomCovenantValues subscription payload */
export type CustomCovenantValuePayload = {
  __typename?: 'CustomCovenantValuePayload';
  mutation: MutationType;
  node?: Maybe<CustomCovenantValue>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<CustomCovenantValue>;
};

/** CustomCovenantValues subscription filter */
export type CustomCovenantValueSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CustomCovenantValueFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Documents subscription payload */
export type DocumentPayload = {
  __typename?: 'DocumentPayload';
  mutation: MutationType;
  node?: Maybe<Document>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Document>;
};

/** Documents subscription filter */
export type DocumentSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DocumentFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** DrawHistories subscription payload */
export type DrawHistoryPayload = {
  __typename?: 'DrawHistoryPayload';
  mutation: MutationType;
  node?: Maybe<DrawHistory>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<DrawHistory>;
};

/** DrawHistories subscription filter */
export type DrawHistorySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DrawHistoryFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** DrawHistoryValues subscription payload */
export type DrawHistoryValuePayload = {
  __typename?: 'DrawHistoryValuePayload';
  mutation: MutationType;
  node?: Maybe<DrawHistoryValue>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<DrawHistoryValue>;
};

/** DrawHistoryValues subscription filter */
export type DrawHistoryValueSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DrawHistoryValueFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Draws subscription payload */
export type DrawPayload = {
  __typename?: 'DrawPayload';
  mutation: MutationType;
  node?: Maybe<Draw>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Draw>;
};

/** Draws subscription filter */
export type DrawSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DrawFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** EnvironmentVariables subscription payload */
export type EnvironmentVariablePayload = {
  __typename?: 'EnvironmentVariablePayload';
  mutation: MutationType;
  node?: Maybe<EnvironmentVariable>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<EnvironmentVariable>;
};

/** EnvironmentVariables subscription filter */
export type EnvironmentVariableSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<EnvironmentVariableFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Facilities subscription payload */
export type FacilityPayload = {
  __typename?: 'FacilityPayload';
  mutation: MutationType;
  node?: Maybe<Facility>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Facility>;
};

/** Facilities subscription filter */
export type FacilitySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FacilityFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** FacilityHistories subscription payload */
export type FacilityHistoryPayload = {
  __typename?: 'FacilityHistoryPayload';
  mutation: MutationType;
  node?: Maybe<FacilityHistory>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<FacilityHistory>;
};

/** FacilityHistories subscription filter */
export type FacilityHistorySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FacilityHistoryFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** FacilityHistoryValues subscription payload */
export type FacilityHistoryValuePayload = {
  __typename?: 'FacilityHistoryValuePayload';
  mutation: MutationType;
  node?: Maybe<FacilityHistoryValue>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<FacilityHistoryValue>;
};

/** FacilityHistoryValues subscription filter */
export type FacilityHistoryValueSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FacilityHistoryValueFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Files subscription payload */
export type FilePayload = {
  __typename?: 'FilePayload';
  mutation: MutationType;
  node?: Maybe<File>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<File>;
};

/** Files subscription filter */
export type FileSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FileFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** InboxMessages subscription payload */
export type InboxMessagePayload = {
  __typename?: 'InboxMessagePayload';
  mutation: MutationType;
  node?: Maybe<InboxMessage>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<InboxMessage>;
};

/** InboxMessages subscription filter */
export type InboxMessageSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<InboxMessageFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Integrations subscription payload */
export type IntegrationPayload = {
  __typename?: 'IntegrationPayload';
  mutation: MutationType;
  node?: Maybe<Integration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Integration>;
};

/** Integrations subscription filter */
export type IntegrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<IntegrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Invitations subscription payload */
export type InvitationPayload = {
  __typename?: 'InvitationPayload';
  mutation: MutationType;
  node?: Maybe<Invitation>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Invitation>;
};

/** Invitations subscription filter */
export type InvitationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<InvitationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** LoanApplications subscription payload */
export type LoanApplicationPayload = {
  __typename?: 'LoanApplicationPayload';
  mutation: MutationType;
  node?: Maybe<LoanApplication>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<LoanApplication>;
};

/** LoanApplications subscription filter */
export type LoanApplicationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<LoanApplicationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** LoanMonitorings subscription payload */
export type LoanMonitoringPayload = {
  __typename?: 'LoanMonitoringPayload';
  mutation: MutationType;
  node?: Maybe<LoanMonitoring>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<LoanMonitoring>;
};

/** LoanMonitorings subscription filter */
export type LoanMonitoringSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<LoanMonitoringFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** MetricValues subscription payload */
export type MetricValuePayload = {
  __typename?: 'MetricValuePayload';
  mutation: MutationType;
  node?: Maybe<MetricValue>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<MetricValue>;
};

/** MetricValues subscription filter */
export type MetricValueSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<MetricValueFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Metrics subscription payload */
export type MetricPayload = {
  __typename?: 'MetricPayload';
  mutation: MutationType;
  node?: Maybe<Metric>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Metric>;
};

/** Metrics subscription filter */
export type MetricSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<MetricFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Notes subscription payload */
export type NotePayload = {
  __typename?: 'NotePayload';
  mutation: MutationType;
  node?: Maybe<Note>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Note>;
};

/** Notes subscription filter */
export type NoteSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<NoteFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Permissions subscription payload */
export type PermissionPayload = {
  __typename?: 'PermissionPayload';
  mutation: MutationType;
  node?: Maybe<Permission>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Permission>;
};

/** Permissions subscription filter */
export type PermissionSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<PermissionFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** PlaidIntegration subscription payload */
export type PlaidIntegrationPayload = {
  __typename?: 'PlaidIntegrationPayload';
  mutation: MutationType;
  node?: Maybe<PlaidIntegration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<PlaidIntegration>;
};

/** PlaidIntegration subscription filter */
export type PlaidIntegrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<PlaidIntegrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** PlaidIntegrationAccounts subscription payload */
export type PlaidIntegrationAccountPayload = {
  __typename?: 'PlaidIntegrationAccountPayload';
  mutation: MutationType;
  node?: Maybe<PlaidIntegrationAccount>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<PlaidIntegrationAccount>;
};

/** PlaidIntegrationAccounts subscription filter */
export type PlaidIntegrationAccountSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<PlaidIntegrationAccountFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** PortfolioCompanies subscription payload */
export type PortfolioCompanyPayload = {
  __typename?: 'PortfolioCompanyPayload';
  mutation: MutationType;
  node?: Maybe<PortfolioCompany>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<PortfolioCompany>;
};

/** PortfolioCompanies subscription filter */
export type PortfolioCompanySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<PortfolioCompanyFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** ProgressCompanySteps subscription payload */
export type ProgressCompanyStepPayload = {
  __typename?: 'ProgressCompanyStepPayload';
  mutation: MutationType;
  node?: Maybe<ProgressCompanyStep>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<ProgressCompanyStep>;
};

/** ProgressCompanySteps subscription filter */
export type ProgressCompanyStepSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ProgressCompanyStepFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** ProgressStages subscription payload */
export type ProgressStagePayload = {
  __typename?: 'ProgressStagePayload';
  mutation: MutationType;
  node?: Maybe<ProgressStage>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<ProgressStage>;
};

/** ProgressStages subscription filter */
export type ProgressStageSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ProgressStageFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** ProgressStepValues subscription payload */
export type ProgressStepValuePayload = {
  __typename?: 'ProgressStepValuePayload';
  mutation: MutationType;
  node?: Maybe<ProgressStepValue>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<ProgressStepValue>;
};

/** ProgressStepValues subscription filter */
export type ProgressStepValueSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ProgressStepValueFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** ProgressSteps subscription payload */
export type ProgressStepPayload = {
  __typename?: 'ProgressStepPayload';
  mutation: MutationType;
  node?: Maybe<ProgressStep>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<ProgressStep>;
};

/** ProgressSteps subscription filter */
export type ProgressStepSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ProgressStepFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** QuickBooksIntegration subscription payload */
export type QuickBooksIntegrationPayload = {
  __typename?: 'QuickBooksIntegrationPayload';
  mutation: MutationType;
  node?: Maybe<QuickBooksIntegration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<QuickBooksIntegration>;
};

/** QuickBooksIntegration subscription filter */
export type QuickBooksIntegrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<QuickBooksIntegrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Roles subscription payload */
export type RolePayload = {
  __typename?: 'RolePayload';
  mutation: MutationType;
  node?: Maybe<Role>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Role>;
};

/** Roles subscription filter */
export type RoleSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<RoleFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Settings subscription payload */
export type SettingPayload = {
  __typename?: 'SettingPayload';
  mutation: MutationType;
  node?: Maybe<Setting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<Setting>;
};

/** Settings subscription filter */
export type SettingSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<SettingFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** SortableLoanApplications subscription payload */
export type SortableLoanApplicationPayload = {
  __typename?: 'SortableLoanApplicationPayload';
  mutation: MutationType;
  node?: Maybe<SortableLoanApplication>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<SortableLoanApplication>;
};

/** SortableLoanApplications subscription filter */
export type SortableLoanApplicationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<SortableLoanApplicationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** SortablePortfolioCompanies subscription payload */
export type SortablePortfolioCompanyPayload = {
  __typename?: 'SortablePortfolioCompanyPayload';
  mutation: MutationType;
  node?: Maybe<SortablePortfolioCompany>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<SortablePortfolioCompany>;
};

/** SortablePortfolioCompanies subscription filter */
export type SortablePortfolioCompanySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<SortablePortfolioCompanyFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** StripeIntegration subscription payload */
export type StripeIntegrationPayload = {
  __typename?: 'StripeIntegrationPayload';
  mutation: MutationType;
  node?: Maybe<StripeIntegration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<StripeIntegration>;
};

/** StripeIntegration subscription filter */
export type StripeIntegrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<StripeIntegrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** TeamInvitations subscription payload */
export type TeamInvitationPayload = {
  __typename?: 'TeamInvitationPayload';
  mutation: MutationType;
  node?: Maybe<TeamInvitation>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<TeamInvitation>;
};

/** TeamInvitations subscription filter */
export type TeamInvitationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<TeamInvitationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** TeamMembers subscription payload */
export type TeamMemberPayload = {
  __typename?: 'TeamMemberPayload';
  mutation: MutationType;
  node?: Maybe<TeamMember>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<TeamMember>;
};

/** TeamMembers subscription filter */
export type TeamMemberSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<TeamMemberFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Users subscription payload */
export type UserPayload = {
  __typename?: 'UserPayload';
  mutation: MutationType;
  node?: Maybe<User>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<User>;
};

/** Users subscription filter */
export type UserSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<UserFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** XeroIntegrations subscription payload */
export type XeroIntegrationPayload = {
  __typename?: 'XeroIntegrationPayload';
  mutation: MutationType;
  node?: Maybe<XeroIntegration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  previousValues?: Maybe<XeroIntegration>;
};

/** XeroIntegrations subscription filter */
export type XeroIntegrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<XeroIntegrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

export enum EventType {
  BigfootEmployeesDelete = 'BigfootEmployeesDelete',
  BigfootEmployeeEdit = 'BigfootEmployeeEdit',
  ApplicantInvitationSend = 'ApplicantInvitationSend',
  BigfootEmployeeInvitationSend = 'BigfootEmployeeInvitationSend',
  CompanyTeammateInvitationSend = 'CompanyTeammateInvitationSend',
  CompanyTeammateEdit = 'CompanyTeammateEdit',
  CompanyTeammateDelete = 'CompanyTeammateDelete',
  PortfolioCompanyCreate = 'PortfolioCompanyCreate',
  PortfolioCompanyUpdateActive = 'PortfolioCompanyUpdateActive',
  UserEmailVerify = 'UserEmailVerify',
  UserVerificationEmailSend = 'UserVerificationEmailSend',
  GetInvitationInfo = 'GetInvitationInfo',
  InboxMessageCreate = 'InboxMessageCreate',
  GetSaaSScoreResults = 'GetSaaSScoreResults',
  ResetMasterDataByDefault = 'ResetMasterDataByDefault',
}

export type EventResolverInput = {
  type: EventType;
  payload?: Maybe<Scalars['JSON']>;
};

export type EventResolverResponse = {
  __typename?: 'EventResolverResponse';
  type: EventType;
  response?: Maybe<Scalars['JSON']>;
};

export enum DownloadPdfType {
  Note = 'Note',
  AllNotes = 'AllNotes',
}

export type DownloadPdfInput = {
  type: DownloadPdfType;
  payload?: Maybe<Scalars['JSON']>;
};

export type PdfResponse = {
  __typename?: 'PdfResponse';
  fileName: Scalars['String'];
  pdf: Scalars['String'];
};

export type DownloadPdfResponse = {
  __typename?: 'DownloadPdfResponse';
  type: DownloadPdfType;
  response: PdfResponse;
};

export type SaaSLoanOption = {
  __typename?: 'SaaSLoanOption';
  term?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  revenueMultiplier?: Maybe<Scalars['Float']>;
  repaymentMultiplier?: Maybe<Scalars['Float']>;
  capitalAvailable?: Maybe<Scalars['Float']>;
  totalRepayment?: Maybe<Scalars['Float']>;
  monthlyPayment?: Maybe<Scalars['Float']>;
};

export type SaaSLoanOptionsResult = {
  __typename?: 'SaaSLoanOptionsResult';
  items?: Maybe<Array<Maybe<SaaSLoanOption>>>;
};

export type PortfolioMonitoringValues = {
  __typename?: 'PortfolioMonitoringValues';
  date: Scalars['String'];
  deployed?: Maybe<Scalars['Float']>;
  committed?: Maybe<Scalars['Float']>;
  advanceRate?: Maybe<Scalars['Float']>;
};

export type MonitoringCompany = {
  __typename?: 'MonitoringCompany';
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
  portfolioCompanyId: Scalars['ID'];
  monitoringValues: Array<PortfolioMonitoringValues>;
  monitoringMetrics: Array<MetricValue>;
};

export type PortfolioMonitoringData = {
  __typename?: 'PortfolioMonitoringData';
  monitoringData: Array<MonitoringCompany>;
};

export type ClosingDate = {
  __typename?: 'ClosingDate';
  closingDate?: Maybe<Scalars['String']>;
};

export type FundingDate = {
  __typename?: 'FundingDate';
  fundingDate?: Maybe<Scalars['String']>;
};

export type TotalAmountDeployed = {
  __typename?: 'TotalAmountDeployed';
  totalAmountDeployed?: Maybe<Scalars['Float']>;
};

export type AmountCommitted = {
  __typename?: 'AmountCommitted';
  amountCommitted?: Maybe<Scalars['Float']>;
};

export type PortfolioOverviewMetrics = {
  __typename?: 'PortfolioOverviewMetrics';
  closingDate?: Maybe<Scalars['Date']>;
  fundingDate?: Maybe<Scalars['Date']>;
  committed?: Maybe<Scalars['Float']>;
  deployed?: Maybe<Scalars['Float']>;
  structure?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  iOPeriod?: Maybe<Scalars['Int']>;
  prepayment?: Maybe<Scalars['String']>;
  prepaymentMonths?: Maybe<Scalars['Int']>;
  advancedRate?: Maybe<Scalars['Float']>;
  interestRate?: Maybe<Scalars['Float']>;
  returnCap?: Maybe<Scalars['Float']>;
  closingFee?: Maybe<Scalars['String']>;
  closingFeeValue?: Maybe<Scalars['Float']>;
  successFee?: Maybe<Scalars['Float']>;
  exitFee?: Maybe<Scalars['Float']>;
  iRR?: Maybe<Scalars['Float']>;
  mOIC?: Maybe<Scalars['Float']>;
};

export type PortfolioOverviewCompany = {
  __typename?: 'PortfolioOverviewCompany';
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
  portfolioCompanyId: Scalars['String'];
  metrics: Array<MetricValue>;
  portfolioOverviewMetrics: PortfolioOverviewMetrics;
};

export type PortfolioOverviewData = {
  __typename?: 'PortfolioOverviewData';
  count: Scalars['Int'];
  companies: Array<PortfolioOverviewCompany>;
};

export type MetricValueUpsert = {
  __typename?: 'MetricValueUpsert';
  id: Scalars['ID'];
};

export type PlaidGetLinkTokenResult = {
  __typename?: 'plaidGetLinkTokenResult';
  token?: Maybe<Scalars['String']>;
};

export type PlaidRequestMetricsUpdateResult = {
  __typename?: 'plaidRequestMetricsUpdateResult';
  asyncTaskId: Scalars['ID'];
};

export type StripeGetAuthUrlResult = {
  __typename?: 'StripeGetAuthUrlResult';
  url: Scalars['String'];
};

export type QuickBooksGetAuthUrlResult = {
  __typename?: 'quickBooksGetAuthUrlResult';
  url: Scalars['String'];
};

export type QuickBooksRequestMetricsUpdateResult = {
  __typename?: 'quickBooksRequestMetricsUpdateResult';
  asyncTaskId: Scalars['ID'];
};

export type TotalAmountDeployedResult = {
  __typename?: 'TotalAmountDeployedResult';
  totalAmountDeployed?: Maybe<Scalars['Float']>;
};

export type XeroGetAuthUrlResult = {
  __typename?: 'xeroGetAuthUrlResult';
  url: Scalars['String'];
};

export type XeroRequestMetricsUpdateResult = {
  __typename?: 'xeroRequestMetricsUpdateResult';
  asyncTaskId: Scalars['ID'];
};

export enum TableExportType {
  PortfolioCompanies = 'PortfolioCompanies',
  ScoreAndMetrics = 'ScoreAndMetrics',
  UnderwritingPackage = 'UnderwritingPackage',
  PortfolioMonitoring = 'PortfolioMonitoring',
  Statements = 'Statements',
  LoanApplications = 'LoanApplications',
  CashMonitoring = 'CashMonitoring',
  PortfolioOverview = 'PortfolioOverview',
  CovenantTrackingPortfolio = 'CovenantTrackingPortfolio',
  CovenantTracking = 'CovenantTracking',
}

export type TableExportInput = {
  exportParams?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
};

export type TableExportResponse = {
  __typename?: 'TableExportResponse';
  base64data?: Maybe<Scalars['String']>;
  asyncTaskId?: Maybe<Scalars['String']>;
};

export enum StatementsReportType {
  IncomeStatement = 'IncomeStatement',
  BalanceSheet = 'BalanceSheet',
  StatementOfCashFlows = 'StatementOfCashFlows',
  Banking = 'Banking',
}

export type StatementsMetricsInput = {
  integrationType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  reportType: StatementsReportType;
  period?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StatementsMetricsResponse = {
  __typename?: 'StatementsMetricsResponse';
  taskId?: Maybe<Scalars['String']>;
};

export type StatementsMetricsQueryResponse = {
  __typename?: 'StatementsMetricsQueryResponse';
  report?: Maybe<Scalars['JSON']>;
};

export type TierBands = {
  direction?: Maybe<Scalars['String']>;
  bounds?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type MetricSettingsTierBands = {
  smb?: Maybe<TierBands>;
  enterprise?: Maybe<TierBands>;
};

export type MetricSettingsInput = {
  code?: Maybe<Scalars['String']>;
  saaSScoreWeight?: Maybe<Scalars['Float']>;
  saaSScoreWeightEnterprise?: Maybe<Scalars['Float']>;
  tierBands?: Maybe<MetricSettingsTierBands>;
  metadata?: Maybe<Scalars['JSON']>;
};

export type InvitationSendResponse = {
  __typename?: 'InvitationSendResponse';
  invitationId: Scalars['ID'];
};

export type InvitationResendResponse = {
  __typename?: 'InvitationResendResponse';
  success: Scalars['Boolean'];
};

export type InvitationCancelResponse = {
  __typename?: 'InvitationCancelResponse';
  success: Scalars['Boolean'];
};

export type FinishedAsyncTasksSubscriptionVariables = Exact<{ [key: string]: never }>;

export type FinishedAsyncTasksSubscription = { __typename?: 'Subscription' } & {
  AsyncTasks?: Maybe<
    { __typename?: 'AsyncTaskPayload' } & Pick<AsyncTaskPayload, 'mutation'> & {
        node?: Maybe<
          { __typename?: 'AsyncTask' } & Pick<AsyncTask, 'id' | 'name' | 'status' | 'result'>
        >;
      }
  >;
};

export type FinishedAsyncTasksForExportSubscriptionVariables = Exact<{ [key: string]: never }>;

export type FinishedAsyncTasksForExportSubscription = { __typename?: 'Subscription' } & {
  AsyncTasks?: Maybe<
    { __typename?: 'AsyncTaskPayload' } & Pick<AsyncTaskPayload, 'mutation'> & {
        node?: Maybe<
          { __typename?: 'AsyncTask' } & Pick<AsyncTask, 'id' | 'name' | 'result' | 'status'>
        >;
      }
  >;
};

export type AsyncTaskResultQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AsyncTaskResultQuery = { __typename?: 'Query' } & {
  asyncTask?: Maybe<{ __typename?: 'AsyncTask' } & Pick<AsyncTask, 'id' | 'result'>>;
};

export type CashMonitoringPortfolioCompanyFragmentFragment = {
  __typename?: 'PortfolioCompany';
} & Pick<PortfolioCompany, 'id'> & {
    company?: Maybe<
      { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'primaryCustomerBase'> & {
          metrics?: Maybe<
            { __typename?: 'MetricValueListResponse' } & {
              items: Array<
                { __typename?: 'MetricValue' } & Pick<
                  MetricValue,
                  'id' | 'date' | 'period' | 'tier' | 'value' | 'adjustedValue'
                > & {
                    metric?: Maybe<
                      { __typename?: 'Metric' } & Pick<Metric, 'id' | 'name' | 'code'>
                    >;
                  }
              >;
            }
          >;
          covenantValue?: Maybe<
            { __typename?: 'CovenantValueListResponse' } & {
              items: Array<
                { __typename?: 'CovenantValue' } & Pick<
                  CovenantValue,
                  'id' | 'value' | 'isEnabled'
                > & {
                    covenant?: Maybe<{ __typename?: 'Covenant' } & Pick<Covenant, 'id' | 'name'>>;
                  }
              >;
            }
          >;
        }
    >;
    cashMonitoringRecords?: Maybe<
      { __typename?: 'CashMonitoringRecordListResponse' } & {
        items: Array<
          { __typename?: 'CashMonitoringRecord' } & Pick<
            CashMonitoringRecord,
            'id' | 'date' | 'note' | 'action'
          > & {
              portfolioCompany?: Maybe<
                { __typename?: 'PortfolioCompany' } & Pick<PortfolioCompany, 'id'>
              >;
            }
        >;
      }
    >;
  };

export type CashMonitoringQueryVariables = Exact<{
  metricsFilter: MetricValueFilter;
  companyFilter?: Maybe<PortfolioCompanyFilter>;
  recordFilter?: Maybe<CashMonitoringRecordFilter>;
  sort?: Maybe<Array<PortfolioCompanySort> | PortfolioCompanySort>;
}>;

export type CashMonitoringQuery = { __typename?: 'Query' } & {
  portfolioCompaniesList: { __typename?: 'PortfolioCompanyListResponse' } & Pick<
    PortfolioCompanyListResponse,
    'count'
  > & {
      items: Array<
        { __typename?: 'PortfolioCompany' } & CashMonitoringPortfolioCompanyFragmentFragment
      >;
    };
};

export type CashMonitoringRecordCreateMutationVariables = Exact<{
  data: CashMonitoringRecordCreateInput;
}>;

export type CashMonitoringRecordCreateMutation = { __typename?: 'Mutation' } & {
  cashMonitoringRecordCreate: { __typename?: 'CashMonitoringRecord' } & Pick<
    CashMonitoringRecord,
    'id'
  >;
};

export type CashMonitoringRecordUpdateMutationVariables = Exact<{
  filter: CashMonitoringRecordKeyFilter;
  data: CashMonitoringRecordUpdateInput;
}>;

export type CashMonitoringRecordUpdateMutation = { __typename?: 'Mutation' } & {
  cashMonitoringRecordUpdate: { __typename?: 'CashMonitoringRecord' } & Pick<
    CashMonitoringRecord,
    'id'
  >;
};

export type CompanyFragmentFragment = { __typename?: 'Company' } & Pick<
  Company,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'name'
  | 'active'
  | 'monitoringStatus'
  | 'primaryCustomerBase'
  | 'primaryBusinessModel'
  | 'borrower'
  | 'borrowedDebt'
  | 'idealRaiseAmount'
  | 'foundedYear'
  | 'companyStory'
  | 'founderStory'
  | 'productDescription'
  | 'marketDescription'
  | 'customersStrategy'
  | 'capital'
  | 'capitalType'
  | 'capitalStrategy'
  | 'equityCapitalAmount'
  | 'equityCapitalOwner'
  | 'monthsToNextRound'
  | 'securedDebt'
  | 'structure'
  | 'status'
  | 'contactTitle'
  | 'contactFirstName'
  | 'contactLastName'
  | 'contactEmail'
  | '_description'
> & {
    createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
    loanApplication?: Maybe<
      { __typename?: 'LoanApplication' } & Pick<LoanApplication, 'id' | 'stage'>
    >;
    portfolioCompany?: Maybe<{ __typename?: 'PortfolioCompany' } & Pick<PortfolioCompany, 'id'>>;
    facilities?: Maybe<
      { __typename?: 'FacilityListResponse' } & {
        items: Array<
          { __typename?: 'Facility' } & Pick<
            Facility,
            'id' | 'structure' | 'fundingDate' | 'amountDeployed'
          >
        >;
      }
    >;
    address?: Maybe<
      { __typename?: 'SmartAddress' } & Pick<
        SmartAddress,
        'country' | 'street1' | 'street2' | 'zip' | 'city' | 'state'
      >
    >;
    team?: Maybe<
      { __typename?: 'UserListResponse' } & {
        items: Array<
          { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'id' | 'email'>
        >;
      }
    >;
    owner?: Maybe<{ __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'id' | 'email'>>;
  };

export type CompaniesEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompaniesEntityQuery = { __typename?: 'Query' } & {
  company?: Maybe<{ __typename?: 'Company' } & CompanyFragmentFragment>;
};

export type CompaniesCreateMutationVariables = Exact<{
  data: CompanyCreateInput;
}>;

export type CompaniesCreateMutation = { __typename?: 'Mutation' } & {
  companyCreate: { __typename?: 'Company' } & CompanyFragmentFragment;
};

export type CompaniesDeleteMutationVariables = Exact<{
  filter: CompanyKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type CompaniesDeleteMutation = { __typename?: 'Mutation' } & {
  companyDelete?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type CompaniesUpdateMutationVariables = Exact<{
  data: CompanyUpdateInput;
  filter?: Maybe<CompanyKeyFilter>;
}>;

export type CompaniesUpdateMutation = { __typename?: 'Mutation' } & {
  companyUpdate: { __typename?: 'Company' } & CompanyFragmentFragment;
};

export type FundingDateByCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type FundingDateByCompanyQuery = { __typename?: 'Query' } & {
  getFundingDate?: Maybe<{ __typename?: 'FundingDate' } & Pick<FundingDate, 'fundingDate'>>;
};

export type AmountCommittedQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type AmountCommittedQuery = { __typename?: 'Query' } & {
  getAmountCommitted?: Maybe<
    { __typename?: 'AmountCommitted' } & Pick<AmountCommitted, 'amountCommitted'>
  >;
};

export type TotalAmountDeployedQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type TotalAmountDeployedQuery = { __typename?: 'Query' } & {
  getTotalAmountDeployed?: Maybe<
    { __typename?: 'TotalAmountDeployed' } & Pick<TotalAmountDeployed, 'totalAmountDeployed'>
  >;
};

export type CovenantTrackingPortfolioCompanyFragmentFragment = {
  __typename?: 'PortfolioCompany';
} & Pick<PortfolioCompany, 'id'> & {
    company?: Maybe<
      { __typename?: 'Company' } & Pick<Company, 'id' | 'name'> & {
          metrics?: Maybe<
            { __typename?: 'MetricValueListResponse' } & {
              items: Array<
                { __typename?: 'MetricValue' } & Pick<
                  MetricValue,
                  'id' | 'date' | 'period' | 'tier' | 'value' | 'adjustedValue'
                > & {
                    metric?: Maybe<
                      { __typename?: 'Metric' } & Pick<Metric, 'id' | 'name' | 'code'>
                    >;
                  }
              >;
            }
          >;
          covenantValue?: Maybe<
            { __typename?: 'CovenantValueListResponse' } & {
              items: Array<
                { __typename?: 'CovenantValue' } & Pick<
                  CovenantValue,
                  'id' | 'value' | 'isEnabled'
                > & {
                    covenant?: Maybe<{ __typename?: 'Covenant' } & Pick<Covenant, 'id' | 'name'>>;
                  }
              >;
            }
          >;
          customCovenant?: Maybe<
            { __typename?: 'CustomCovenantValueListResponse' } & {
              items: Array<
                { __typename?: 'CustomCovenantValue' } & Pick<
                  CustomCovenantValue,
                  'id' | 'date' | 'actual' | 'difference' | 'inCompliance'
                > & {
                    covenant?: Maybe<{ __typename?: 'Covenant' } & Pick<Covenant, 'id' | 'name'>>;
                  }
              >;
            }
          >;
        }
    >;
  };

export type CovenantTrackingPortfolioQueryVariables = Exact<{
  metricsFilter: MetricValueFilter;
  companyFilter: PortfolioCompanyFilter;
  customCovenantsFilter: CustomCovenantValueFilter;
}>;

export type CovenantTrackingPortfolioQuery = { __typename?: 'Query' } & {
  portfolioCompaniesList: { __typename?: 'PortfolioCompanyListResponse' } & Pick<
    PortfolioCompanyListResponse,
    'count'
  > & {
      items: Array<
        { __typename?: 'PortfolioCompany' } & CovenantTrackingPortfolioCompanyFragmentFragment
      >;
    };
};

export type CustomCovenantValueFragmentFragment = { __typename?: 'CustomCovenantValue' } & Pick<
  CustomCovenantValue,
  'id' | 'date' | 'actual' | 'difference' | 'inCompliance'
> & {
    company?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'id'>>;
    covenant?: Maybe<{ __typename?: 'Covenant' } & Pick<Covenant, 'id' | 'name'>>;
  };

export type CovenantListQueryVariables = Exact<{ [key: string]: never }>;

export type CovenantListQuery = { __typename?: 'Query' } & {
  covenantsList: { __typename?: 'CovenantListResponse' } & {
    items: Array<{ __typename?: 'Covenant' } & Pick<Covenant, 'id' | 'name'>>;
  };
};

export type CovenantValuesListQueryVariables = Exact<{
  filter?: Maybe<CovenantValueFilter>;
}>;

export type CovenantValuesListQuery = { __typename?: 'Query' } & {
  covenantValuesList: { __typename?: 'CovenantValueListResponse' } & {
    items: Array<
      { __typename?: 'CovenantValue' } & Pick<CovenantValue, 'id' | 'value' | 'isEnabled'> & {
          covenant?: Maybe<{ __typename?: 'Covenant' } & Pick<Covenant, 'id' | 'name'>>;
          company?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'id'>>;
        }
    >;
  };
};

export type CovenantValueCreateManyMutationVariables = Exact<{
  data: Array<Maybe<CovenantValueCreateManyInput>> | Maybe<CovenantValueCreateManyInput>;
}>;

export type CovenantValueCreateManyMutation = { __typename?: 'Mutation' } & {
  covenantValueCreateMany: { __typename?: 'CovenantValueManyResponse' } & {
    items: Array<
      { __typename?: 'CovenantValue' } & Pick<CovenantValue, 'id' | 'value' | 'isEnabled'>
    >;
  };
};

export type CovenantValueCreateMutationVariables = Exact<{
  data: CovenantValueCreateInput;
}>;

export type CovenantValueCreateMutation = { __typename?: 'Mutation' } & {
  covenantValueCreate: { __typename?: 'CovenantValue' } & Pick<CovenantValue, 'id' | 'isEnabled'>;
};

export type CovenantValueDeleteMutationVariables = Exact<{
  filter?: Maybe<CovenantValueKeyFilter>;
}>;

export type CovenantValueDeleteMutation = { __typename?: 'Mutation' } & {
  covenantValueDelete?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type CovenantValueUpdateMutationVariables = Exact<{
  data: CovenantValueUpdateInput;
  filter?: Maybe<CovenantValueKeyFilter>;
}>;

export type CovenantValueUpdateMutation = { __typename?: 'Mutation' } & {
  covenantValueUpdate: { __typename?: 'CovenantValue' } & Pick<
    CovenantValue,
    'value' | 'isEnabled'
  >;
};

export type CustomCovenantValuesListQueryVariables = Exact<{
  filter?: Maybe<CustomCovenantValueFilter>;
}>;

export type CustomCovenantValuesListQuery = { __typename?: 'Query' } & {
  customCovenantValuesList: { __typename?: 'CustomCovenantValueListResponse' } & {
    items: Array<{ __typename?: 'CustomCovenantValue' } & CustomCovenantValueFragmentFragment>;
  };
};

export type CustomCovenantValueCreateMutationVariables = Exact<{
  data: CustomCovenantValueCreateInput;
}>;

export type CustomCovenantValueCreateMutation = { __typename?: 'Mutation' } & {
  customCovenantValueCreate: {
    __typename?: 'CustomCovenantValue';
  } & CustomCovenantValueFragmentFragment;
};

export type CustomCovenantValueUpdateMutationVariables = Exact<{
  data: CustomCovenantValueUpdateInput;
  filter?: Maybe<CustomCovenantValueKeyFilter>;
}>;

export type CustomCovenantValueUpdateMutation = { __typename?: 'Mutation' } & {
  customCovenantValueUpdate: {
    __typename?: 'CustomCovenantValue';
  } & CustomCovenantValueFragmentFragment;
};

export type CustomCovenantValueDeleteMutationVariables = Exact<{
  customCovenantValueId: Scalars['ID'];
}>;

export type CustomCovenantValueDeleteMutation = { __typename?: 'Mutation' } & {
  customCovenantValueDelete?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type DocumentsEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DocumentsEntityQuery = { __typename?: 'Query' } & {
  document?: Maybe<
    { __typename?: 'Document' } & Pick<
      Document,
      'id' | 'createdAt' | 'updatedAt' | 'private' | 'description' | 'type' | '_description'
    > & {
        createdBy?: Maybe<
          { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | '_description'>
        >;
        file?: Maybe<
          { __typename?: 'File' } & Pick<
            File,
            'id' | 'fileId' | 'filename' | 'downloadUrl' | 'shareUrl' | 'meta'
          >
        >;
      }
  >;
};

export type DocumentsUpdateMutationVariables = Exact<{
  data: DocumentUpdateInput;
  filter?: Maybe<DocumentKeyFilter>;
}>;

export type DocumentsUpdateMutation = { __typename?: 'Mutation' } & {
  documentUpdate: { __typename?: 'Document' } & Pick<
    Document,
    'id' | 'createdAt' | 'updatedAt' | 'private' | 'description' | 'type' | '_description'
  > & {
      createdBy?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | '_description'>
      >;
      file?: Maybe<
        { __typename?: 'File' } & Pick<
          File,
          'id' | 'fileId' | 'filename' | 'downloadUrl' | 'shareUrl' | 'meta'
        >
      >;
    };
};

export type DocumentsDeleteMutationVariables = Exact<{
  filter: DocumentKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type DocumentsDeleteMutation = { __typename?: 'Mutation' } & {
  documentDelete?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type DocumentsCreateManyMutationVariables = Exact<{
  data: Array<Maybe<DocumentCreateManyInput>> | Maybe<DocumentCreateManyInput>;
}>;

export type DocumentsCreateManyMutation = { __typename?: 'Mutation' } & {
  documentCreateMany: { __typename?: 'DocumentManyResponse' } & Pick<DocumentManyResponse, 'count'>;
};

export type DocumentsTableContentQueryVariables = Exact<{
  filter?: Maybe<DocumentFilter>;
  orderBy?: Maybe<Array<Maybe<DocumentOrderBy>> | Maybe<DocumentOrderBy>>;
  sort?: Maybe<Array<DocumentSort> | DocumentSort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type DocumentsTableContentQuery = { __typename?: 'Query' } & {
  tableData: { __typename?: 'DocumentListResponse' } & Pick<DocumentListResponse, 'count'> & {
      items: Array<
        { __typename?: 'Document' } & Pick<
          Document,
          'id' | 'createdAt' | 'updatedAt' | 'private' | 'description' | '_description'
        > & {
            createdBy?: Maybe<
              { __typename?: 'User' } & Pick<
                User,
                'id' | 'firstName' | 'lastName' | '_description'
              > & {
                  roles?: Maybe<
                    { __typename?: 'RoleListResponse' } & {
                      items: Array<{ __typename?: 'Role' } & Pick<Role, 'id' | 'name'>>;
                    }
                  >;
                }
            >;
            file?: Maybe<
              { __typename?: 'File' } & Pick<
                File,
                'id' | 'fileId' | 'filename' | 'downloadUrl' | 'shareUrl' | 'meta'
              >
            >;
          }
      >;
    };
};

export type DrawFragmentFragment = { __typename?: 'Draw' } & Pick<
  Draw,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'active'
  | 'fundedDate'
  | 'maturityDate'
  | 'amountDeployed'
  | 'interestRate'
  | 'cashShare'
  | 'returnCap'
  | 'term'
  | 'iOPeriod'
  | 'amortizationPeriod'
  | 'iOPeriodAmortizationPeriod'
  | 'exitFee'
  | 'prepayment'
  | 'prepaymentMonths'
  | 'iRR'
  | 'mOIC'
  | 'comments'
  | '_description'
>;

export type DrawEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DrawEntityQuery = { __typename?: 'Query' } & {
  draw?: Maybe<{ __typename?: 'Draw' } & DrawFragmentFragment>;
};

export type DrawUpdateMutationVariables = Exact<{
  data: DrawUpdateInput;
  filter?: Maybe<DrawKeyFilter>;
}>;

export type DrawUpdateMutation = { __typename?: 'Mutation' } & {
  drawUpdate: { __typename?: 'Draw' } & DrawFragmentFragment;
};

export type DrawCreateMutationVariables = Exact<{
  data: DrawCreateInput;
}>;

export type DrawCreateMutation = { __typename?: 'Mutation' } & {
  drawCreate: { __typename?: 'Draw' } & DrawFragmentFragment;
};

export type DrawDeleteMutationVariables = Exact<{
  filter: DrawKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type DrawDeleteMutation = { __typename?: 'Mutation' } & {
  drawDelete?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type DrawsTableContentQueryVariables = Exact<{
  filter?: Maybe<DrawFilter>;
  orderBy?: Maybe<Array<Maybe<DrawOrderBy>> | Maybe<DrawOrderBy>>;
  sort?: Maybe<Array<DrawSort> | DrawSort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type DrawsTableContentQuery = { __typename?: 'Query' } & {
  drawsList: { __typename?: 'DrawListResponse' } & Pick<DrawListResponse, 'count'> & {
      items: Array<{ __typename?: 'Draw' } & DrawFragmentFragment>;
    };
};

export type EventResolverMutationMutationVariables = Exact<{
  event: EventResolverInput;
}>;

export type EventResolverMutationMutation = { __typename?: 'Mutation' } & {
  eventResolver?: Maybe<
    { __typename?: 'EventResolverResponse' } & Pick<EventResolverResponse, 'type' | 'response'>
  >;
};

export type EventResolverQueryQueryVariables = Exact<{
  event: EventResolverInput;
}>;

export type EventResolverQueryQuery = { __typename?: 'Query' } & {
  eventResolverQuery?: Maybe<
    { __typename?: 'EventResolverResponse' } & Pick<EventResolverResponse, 'type' | 'response'>
  >;
};

export type FacilityFragmentFragment = { __typename?: 'Facility' } & Pick<
  Facility,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'structure'
  | 'amountCommitted'
  | 'totalAmountDeployed'
  | 'advanceRate'
  | 'finalAdvanceRate'
  | 'nextAvailableDraw'
  | 'closingFee'
  | 'closingFeeValue'
  | 'closingDate'
  | 'successFee'
  | 'minCashCovenant'
  | 'minCashRunawayCovenant'
  | 'validityGuarantee'
  | 'comments'
  | 'active'
  | '_description'
>;

export type FacilityEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FacilityEntityQuery = { __typename?: 'Query' } & {
  facility?: Maybe<{ __typename?: 'Facility' } & FacilityFragmentFragment>;
};

export type FacilityUpdateMutationVariables = Exact<{
  data: FacilityUpdateInput;
  filter?: Maybe<FacilityKeyFilter>;
}>;

export type FacilityUpdateMutation = { __typename?: 'Mutation' } & {
  facilityUpdate: { __typename?: 'Facility' } & FacilityFragmentFragment;
};

export type FacilityCreateMutationVariables = Exact<{
  data: FacilityCreateInput;
}>;

export type FacilityCreateMutation = { __typename?: 'Mutation' } & {
  facilityCreate: { __typename?: 'Facility' } & FacilityFragmentFragment;
};

export type FacilityDeleteMutationVariables = Exact<{
  filter: FacilityKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type FacilityDeleteMutation = { __typename?: 'Mutation' } & {
  facilityDelete?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type FacilitiesTableContentQueryVariables = Exact<{
  filter?: Maybe<FacilityFilter>;
  orderBy?: Maybe<Array<Maybe<FacilityOrderBy>> | Maybe<FacilityOrderBy>>;
  sort?: Maybe<Array<FacilitySort> | FacilitySort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type FacilitiesTableContentQuery = { __typename?: 'Query' } & {
  facilitiesList: { __typename?: 'FacilityListResponse' } & Pick<FacilityListResponse, 'count'> & {
      items: Array<
        { __typename?: 'Facility' } & {
          draws?: Maybe<
            { __typename?: 'DrawListResponse' } & {
              items: Array<{ __typename?: 'Draw' } & DrawFragmentFragment>;
            }
          >;
        } & FacilityFragmentFragment
      >;
    };
};

export type RecalculateTotalAmountDeployedMutationVariables = Exact<{
  facilityId: Scalars['ID'];
}>;

export type RecalculateTotalAmountDeployedMutation = { __typename?: 'Mutation' } & {
  recalculateTotalAmountDeployedFacility?: Maybe<
    { __typename?: 'TotalAmountDeployedResult' } & Pick<
      TotalAmountDeployedResult,
      'totalAmountDeployed'
    >
  >;
};

export type FilesCreateMutationVariables = Exact<{
  data: FileCreateInput;
}>;

export type FilesCreateMutation = { __typename?: 'Mutation' } & {
  fileCreate: { __typename?: 'File' } & Pick<
    File,
    'id' | 'fileId' | 'filename' | 'downloadUrl' | 'shareUrl' | 'meta'
  >;
};

export type InboxMessageFragmentFragment = { __typename?: 'InboxMessage' } & Pick<
  InboxMessage,
  'id' | 'subject' | 'body' | 'createdAt' | 'isByAdministrator' | 'isRead'
> & {
    repliedOn?: Maybe<{ __typename?: 'InboxMessage' } & Pick<InboxMessage, 'id' | 'subject'>>;
    replies?: Maybe<
      { __typename?: 'InboxMessageListResponse' } & {
        items: Array<{ __typename?: 'InboxMessage' } & Pick<InboxMessage, 'id' | 'subject'>>;
      }
    >;
    attachments?: Maybe<
      { __typename?: 'DocumentListResponse' } & {
        items: Array<
          { __typename?: 'Document' } & {
            file?: Maybe<
              { __typename?: 'File' } & Pick<
                File,
                'filename' | 'id' | 'downloadUrl' | 'previewUrl' | 'meta'
              >
            >;
          }
        >;
      }
    >;
  };

export type InboxMessagesCountQueryVariables = Exact<{
  filter: InboxMessageFilter;
}>;

export type InboxMessagesCountQuery = { __typename?: 'Query' } & {
  inboxMessagesList: { __typename?: 'InboxMessageListResponse' } & Pick<
    InboxMessageListResponse,
    'count'
  >;
};

export type InboxMessageMarkAsReadMutationVariables = Exact<{
  messageId: Scalars['ID'];
  data: InboxMessageUpdateInput;
}>;

export type InboxMessageMarkAsReadMutation = { __typename?: 'Mutation' } & {
  inboxMessageUpdate: { __typename?: 'InboxMessage' } & InboxMessageFragmentFragment;
};

export type InboxMessageQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InboxMessageQueryQuery = { __typename?: 'Query' } & {
  inboxMessage?: Maybe<{ __typename?: 'InboxMessage' } & InboxMessageFragmentFragment>;
};

export type InboxMessagesListQueryVariables = Exact<{
  filter: InboxMessageFilter;
  sort?: Maybe<Array<InboxMessageSort> | InboxMessageSort>;
}>;

export type InboxMessagesListQuery = { __typename?: 'Query' } & {
  inboxMessagesList: { __typename?: 'InboxMessageListResponse' } & {
    items: Array<{ __typename?: 'InboxMessage' } & InboxMessageFragmentFragment>;
  };
};

export type InboxMessageOnCreateSubscriptionSubscriptionVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type InboxMessageOnCreateSubscriptionSubscription = { __typename?: 'Subscription' } & {
  InboxMessages?: Maybe<
    { __typename?: 'InboxMessagePayload' } & Pick<InboxMessagePayload, 'mutation'> & {
        node?: Maybe<{ __typename?: 'InboxMessage' } & InboxMessageFragmentFragment>;
      }
  >;
};

export type UserIntegrationsEntityQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserIntegrationsEntityQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        company?: Maybe<
          { __typename?: 'Company' } & Pick<Company, 'id'> & {
              integration?: Maybe<
                { __typename?: 'Integration' } & Pick<Integration, 'id'> & {
                    xero?: Maybe<
                      { __typename?: 'XeroIntegration' } & Pick<XeroIntegration, 'id' | 'status'>
                    >;
                    quickBooks?: Maybe<
                      { __typename?: 'QuickBooksIntegration' } & Pick<
                        QuickBooksIntegration,
                        'id' | 'status'
                      >
                    >;
                    stripe?: Maybe<
                      { __typename?: 'StripeIntegration' } & Pick<
                        StripeIntegration,
                        'id' | 'status'
                      >
                    >;
                    plaid?: Maybe<
                      { __typename?: 'PlaidIntegrationListResponse' } & {
                        items: Array<
                          { __typename?: 'PlaidIntegration' } & Pick<
                            PlaidIntegration,
                            'id' | 'status' | 'institutionMetadata'
                          > & {
                              accounts?: Maybe<
                                { __typename?: 'PlaidIntegrationAccountListResponse' } & {
                                  items: Array<
                                    { __typename?: 'PlaidIntegrationAccount' } & Pick<
                                      PlaidIntegrationAccount,
                                      'id' | 'name' | 'mask'
                                    >
                                  >;
                                }
                              >;
                            }
                        >;
                      }
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type CompanyIntegrationsEntityQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CompanyIntegrationsEntityQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        integration?: Maybe<
          { __typename?: 'Integration' } & Pick<Integration, 'id'> & {
              xero?: Maybe<
                { __typename?: 'XeroIntegration' } & Pick<XeroIntegration, 'id' | 'status'>
              >;
              quickBooks?: Maybe<
                { __typename?: 'QuickBooksIntegration' } & Pick<
                  QuickBooksIntegration,
                  'id' | 'status'
                >
              >;
              stripe?: Maybe<
                { __typename?: 'StripeIntegration' } & Pick<StripeIntegration, 'id' | 'status'>
              >;
              plaid?: Maybe<
                { __typename?: 'PlaidIntegrationListResponse' } & {
                  items: Array<
                    { __typename?: 'PlaidIntegration' } & Pick<
                      PlaidIntegration,
                      'id' | 'status' | 'institutionMetadata'
                    > & {
                        accounts?: Maybe<
                          { __typename?: 'PlaidIntegrationAccountListResponse' } & {
                            items: Array<
                              { __typename?: 'PlaidIntegrationAccount' } & Pick<
                                PlaidIntegrationAccount,
                                'id' | 'name' | 'mask'
                              >
                            >;
                          }
                        >;
                      }
                  >;
                }
              >;
            }
        >;
      }
  >;
};

export type InvitationResendMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InvitationResendMutation = { __typename?: 'Mutation' } & {
  invitations?: Maybe<
    { __typename?: 'InvitationsMutation' } & {
      invitationResend?: Maybe<
        { __typename?: 'InvitationResendResponse' } & Pick<InvitationResendResponse, 'success'>
      >;
    }
  >;
};

export type InvitationCancelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InvitationCancelMutation = { __typename?: 'Mutation' } & {
  invitations?: Maybe<
    { __typename?: 'InvitationsMutation' } & {
      invitationCancel?: Maybe<
        { __typename?: 'InvitationCancelResponse' } & Pick<InvitationCancelResponse, 'success'>
      >;
    }
  >;
};

export type InvitationSendMutationVariables = Exact<{
  authProfileId?: Maybe<Scalars['ID']>;
  user: UserCreateInput;
}>;

export type InvitationSendMutation = { __typename?: 'Mutation' } & {
  invitations?: Maybe<
    { __typename?: 'InvitationsMutation' } & {
      invitationSend?: Maybe<
        { __typename?: 'InvitationSendResponse' } & Pick<InvitationSendResponse, 'invitationId'>
      >;
    }
  >;
};

export type LoanApplicationFragmentFragment = { __typename?: 'LoanApplication' } & Pick<
  LoanApplication,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'structure'
  | 'stage'
  | 'contactName'
  | 'applicantTitle'
  | 'firstDrawProposed'
  | 'targetCloseDate'
  | '_description'
> & {
    createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | '_description'>>;
    company?: Maybe<
      { __typename?: 'Company' } & Pick<
        Company,
        | 'id'
        | 'createdAt'
        | 'updatedAt'
        | 'name'
        | 'active'
        | 'monitoringStatus'
        | 'primaryCustomerBase'
        | 'primaryBusinessModel'
        | 'borrower'
        | 'borrowedDebt'
        | 'idealRaiseAmount'
        | 'foundedYear'
        | 'companyStory'
        | 'founderStory'
        | 'productDescription'
        | 'marketDescription'
        | 'customersStrategy'
        | 'capital'
        | 'capitalType'
        | 'capitalStrategy'
        | 'equityCapitalAmount'
        | 'equityCapitalOwner'
        | 'monthsToNextRound'
        | 'securedDebt'
        | 'structure'
        | 'status'
        | 'contactTitle'
        | 'contactFirstName'
        | 'contactLastName'
        | 'contactEmail'
        | '_description'
      > & {
          createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
          address?: Maybe<
            { __typename?: 'SmartAddress' } & Pick<
              SmartAddress,
              'country' | 'street1' | 'street2' | 'zip' | 'city' | 'state'
            >
          >;
          owner?: Maybe<
            { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName' | 'title' | 'email'
            >
          >;
        }
    >;
    dealTeam?: Maybe<
      { __typename?: 'UserListResponse' } & {
        items: Array<
          { __typename?: 'User' } & Pick<User, 'id' | 'lastName' | 'firstName'> & {
              avatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'downloadUrl'>>;
            }
        >;
      }
    >;
  };

export type LoanApplicationsMetricsQueryVariables = Exact<{
  accountCreatedFilter?: Maybe<LoanApplicationFilter>;
  saasScoredFilter?: Maybe<LoanApplicationFilter>;
  appCompletedFilter?: Maybe<LoanApplicationFilter>;
  termSheetFilter?: Maybe<LoanApplicationFilter>;
  structurePresentedFilter?: Maybe<LoanApplicationFilter>;
  underwritingFilter?: Maybe<LoanApplicationFilter>;
  closingFilter?: Maybe<LoanApplicationFilter>;
  fundedFilter?: Maybe<LoanApplicationFilter>;
}>;

export type LoanApplicationsMetricsQuery = { __typename?: 'Query' } & {
  accountCreated: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  saaSScored: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  appCompleted: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  termSheet: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  structurePresented: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  underwriting: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  closing: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
  funded: { __typename?: 'LoanApplicationListResponse' } & Pick<
    LoanApplicationListResponse,
    'count'
  >;
};

export type LoanApplicationsEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LoanApplicationsEntityQuery = { __typename?: 'Query' } & {
  loanApplication?: Maybe<{ __typename?: 'LoanApplication' } & LoanApplicationFragmentFragment>;
};

export type LoanApplicationsUpdateMutationVariables = Exact<{
  data: LoanApplicationUpdateInput;
  filter?: Maybe<LoanApplicationKeyFilter>;
}>;

export type LoanApplicationsUpdateMutation = { __typename?: 'Mutation' } & {
  loanApplicationUpdate: { __typename?: 'LoanApplication' } & LoanApplicationFragmentFragment;
};

export type LoanApplicationsDeleteMutationVariables = Exact<{
  filter: LoanApplicationKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type LoanApplicationsDeleteMutation = { __typename?: 'Mutation' } & {
  loanApplicationDelete?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type SortableLoanApplicationsListQueryVariables = Exact<{
  filter?: Maybe<SortableLoanApplicationFilter>;
  orderBy?: Maybe<
    Array<Maybe<SortableLoanApplicationOrderBy>> | Maybe<SortableLoanApplicationOrderBy>
  >;
  sort?: Maybe<Array<SortableLoanApplicationSort> | SortableLoanApplicationSort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type SortableLoanApplicationsListQuery = { __typename?: 'Query' } & {
  tableData: { __typename?: 'SortableLoanApplicationListResponse' } & Pick<
    SortableLoanApplicationListResponse,
    'count'
  > & {
      items: Array<
        { __typename?: 'SortableLoanApplication' } & Pick<
          SortableLoanApplication,
          | 'id'
          | 'companyId'
          | 'createdAt'
          | 'age'
          | 'stage'
          | 'companyName'
          | 'ownerFirstName'
          | 'ownerLastName'
          | 'contactFirstName'
          | 'contactLastName'
          | 'ownerTitle'
          | 'amountRequested'
          | 'firstDrawProposed'
          | 'targetCloseDate'
          | 'committed'
          | 'saasScore'
          | 'status'
        >
      >;
    };
};

export type UsersListQueryVariables = Exact<{
  filter?: Maybe<UserFilter>;
}>;

export type UsersListQuery = { __typename?: 'Query' } & {
  tableData: { __typename?: 'UserListResponse' } & {
    items: Array<
      { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName'> & {
          loanApplications?: Maybe<
            { __typename?: 'LoanApplication' } & Pick<LoanApplication, 'id'>
          >;
          avatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
        }
    >;
  };
};

export type MetricsListQueryVariables = Exact<{
  filter?: Maybe<MetricFilter>;
}>;

export type MetricsListQuery = { __typename?: 'Query' } & {
  metricsList: { __typename?: 'MetricListResponse' } & {
    items: Array<
      { __typename?: 'Metric' } & Pick<
        Metric,
        | 'id'
        | 'name'
        | 'code'
        | 'type'
        | 'format'
        | 'saaSScoreWeight'
        | 'saaSScoreWeightEnterprise'
        | 'tierBands'
        | 'metadata'
      >
    >;
  };
};

export type MetricValuesListQueryVariables = Exact<{
  filter: MetricValueFilter;
}>;

export type MetricValuesListQuery = { __typename?: 'Query' } & {
  metricValuesList: { __typename?: 'MetricValueListResponse' } & {
    groups: Array<
      { __typename?: 'GroupByResponse' } & { date: GroupByResponse['Date'] } & {
        metrics: { __typename?: 'MetricValueListResponse' } & {
          items: Array<
            { __typename?: 'MetricValue' } & Pick<
              MetricValue,
              | 'id'
              | 'value'
              | 'comment'
              | 'adjustedValue'
              | 'source'
              | 'date'
              | 'tier'
              | 'period'
              | 'active'
            > & {
                company?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'id'>>;
                metric?: Maybe<
                  { __typename?: 'Metric' } & Pick<Metric, 'id' | 'name' | 'format' | 'code'>
                >;
                createdBy?: Maybe<
                  { __typename?: 'User' } & Pick<
                    User,
                    'title' | 'id' | 'lastName' | 'firstName' | 'updatedAt'
                  > & {
                      avatar?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'id' | 'previewUrl' | 'downloadUrl'>
                      >;
                    }
                >;
              }
          >;
        };
      }
    >;
  };
};

export type MetricValuesListByCodeQueryVariables = Exact<{
  filter: MetricValueFilter;
}>;

export type MetricValuesListByCodeQuery = { __typename?: 'Query' } & {
  metricValuesList: { __typename?: 'MetricValueListResponse' } & {
    groups: Array<
      { __typename?: 'GroupByResponse' } & { code: GroupByResponse['String'] } & {
        metrics: { __typename?: 'MetricValueListResponse' } & {
          items: Array<
            { __typename?: 'MetricValue' } & Pick<
              MetricValue,
              'id' | 'value' | 'adjustedValue' | 'tier' | 'date'
            > & {
                metric?: Maybe<
                  { __typename?: 'Metric' } & Pick<Metric, 'id' | 'name' | 'format' | 'code'>
                >;
              }
          >;
        };
      }
    >;
  };
};

export type MetricsCompanyHeaderQueryVariables = Exact<{
  companyId: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type MetricsCompanyHeaderQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'name'> & {
        metrics?: Maybe<
          { __typename?: 'MetricValueListResponse' } & {
            groups: Array<
              { __typename?: 'GroupByResponse' } & { code: GroupByResponse['String'] } & {
                metrics: { __typename?: 'MetricValueListResponse' } & {
                  items: Array<
                    { __typename?: 'MetricValue' } & Pick<
                      MetricValue,
                      'id' | 'value' | 'adjustedValue' | 'date' | 'tier'
                    >
                  >;
                };
              }
            >;
          }
        >;
      }
  >;
  getFundingDate?: Maybe<{ __typename?: 'FundingDate' } & Pick<FundingDate, 'fundingDate'>>;
  getAmountCommitted?: Maybe<
    { __typename?: 'AmountCommitted' } & Pick<AmountCommitted, 'amountCommitted'>
  >;
  getTotalAmountDeployed?: Maybe<
    { __typename?: 'TotalAmountDeployed' } & Pick<TotalAmountDeployed, 'totalAmountDeployed'>
  >;
};

export type CalculateMetricMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CalculateMetricMutation = { __typename?: 'Mutation' } & {
  calculateMetrics?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type CalculateSaaSScoreComponentsMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CalculateSaaSScoreComponentsMutation = { __typename?: 'Mutation' } & {
  calculateSaaSScoreComponents?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type CalculateSaaSScoreMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CalculateSaaSScoreMutation = { __typename?: 'Mutation' } & {
  calculateSaaSScore?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type MetricValueUpsertManyMutationVariables = Exact<{
  data: Array<Maybe<MetricValueCreateManyInput>> | Maybe<MetricValueCreateManyInput>;
}>;

export type MetricValueUpsertManyMutation = { __typename?: 'Mutation' } & {
  metricValueUpsertMany?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type MetricValueUpsertMutationVariables = Exact<{
  companyId: Scalars['ID'];
  date: Scalars['Date'];
  metricCode: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
  adjustedValue?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  period: Scalars['String'];
}>;

export type MetricValueUpsertMutation = { __typename?: 'Mutation' } & {
  metricValueUpsert?: Maybe<{ __typename?: 'MetricValueUpsert' } & Pick<MetricValueUpsert, 'id'>>;
};

export type UpdateMetricSettingsMutationVariables = Exact<{
  settings?: Maybe<Array<Maybe<MetricSettingsInput>> | Maybe<MetricSettingsInput>>;
}>;

export type UpdateMetricSettingsMutation = { __typename?: 'Mutation' } & {
  updateMetricSettings?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type CalculateSaaSLoanOptionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CalculateSaaSLoanOptionsQuery = { __typename?: 'Query' } & {
  calculateSaaSLoanOptions?: Maybe<
    { __typename?: 'SaaSLoanOptionsResult' } & {
      items?: Maybe<
        Array<
          Maybe<
            { __typename?: 'SaaSLoanOption' } & Pick<
              SaaSLoanOption,
              | 'term'
              | 'type'
              | 'revenueMultiplier'
              | 'repaymentMultiplier'
              | 'capitalAvailable'
              | 'totalRepayment'
              | 'monthlyPayment'
            >
          >
        >
      >;
    }
  >;
};

export type NotesEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NotesEntityQuery = { __typename?: 'Query' } & {
  note?: Maybe<
    { __typename?: 'Note' } & Pick<Note, 'id' | 'title' | 'body' | 'createdAt'> & {
        createdBy?: Maybe<
          { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'id' | 'email'>
        >;
        attachments?: Maybe<
          { __typename?: 'FileListResponse' } & {
            items: Array<
              { __typename?: 'File' } & Pick<
                File,
                'filename' | 'id' | 'downloadUrl' | 'previewUrl' | 'meta'
              >
            >;
          }
        >;
      }
  >;
};

export type NotesListQueryVariables = Exact<{
  filter: NoteFilter;
  sort?: Maybe<Array<NoteSort> | NoteSort>;
}>;

export type NotesListQuery = { __typename?: 'Query' } & {
  notesList: { __typename?: 'NoteListResponse' } & {
    items: Array<
      { __typename?: 'Note' } & Pick<Note, 'id' | 'title' | 'body' | 'createdAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'id' | 'email'>
          >;
          attachments?: Maybe<
            { __typename?: 'FileListResponse' } & {
              items: Array<
                { __typename?: 'File' } & Pick<
                  File,
                  'filename' | 'id' | 'downloadUrl' | 'previewUrl' | 'meta'
                >
              >;
            }
          >;
        }
    >;
  };
};

export type NotesCreateMutationVariables = Exact<{
  data: NoteCreateInput;
}>;

export type NotesCreateMutation = { __typename?: 'Mutation' } & {
  noteCreate: { __typename?: 'Note' } & Pick<Note, 'id' | 'title' | 'body' | 'createdAt'> & {
      createdBy?: Maybe<
        { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'id' | 'email'>
      >;
      attachments?: Maybe<
        { __typename?: 'FileListResponse' } & {
          items: Array<
            { __typename?: 'File' } & Pick<
              File,
              'filename' | 'id' | 'downloadUrl' | 'previewUrl' | 'meta'
            >
          >;
        }
      >;
    };
};

export type NoteUpdateMutationVariables = Exact<{
  data: NoteUpdateInput;
  filter?: Maybe<NoteKeyFilter>;
}>;

export type NoteUpdateMutation = { __typename?: 'Mutation' } & {
  noteUpdate: { __typename?: 'Note' } & Pick<Note, 'id' | 'title' | 'body' | 'createdAt'> & {
      createdBy?: Maybe<
        { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'id' | 'email'>
      >;
      attachments?: Maybe<
        { __typename?: 'FileListResponse' } & {
          items: Array<
            { __typename?: 'File' } & Pick<
              File,
              'filename' | 'id' | 'downloadUrl' | 'previewUrl' | 'meta'
            >
          >;
        }
      >;
    };
};

export type NotesDeleteMutationVariables = Exact<{
  filter: NoteKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type NotesDeleteMutation = { __typename?: 'Mutation' } & {
  noteDelete?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type DownloadPdfMutationVariables = Exact<{
  event: DownloadPdfInput;
}>;

export type DownloadPdfMutation = { __typename?: 'Mutation' } & {
  downloadPdf?: Maybe<
    { __typename?: 'DownloadPdfResponse' } & Pick<DownloadPdfResponse, 'type'> & {
        response: { __typename?: 'PdfResponse' } & Pick<PdfResponse, 'fileName' | 'pdf'>;
      }
  >;
};

export type PlaidGetLinkTokenMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type PlaidGetLinkTokenMutation = { __typename?: 'Mutation' } & {
  plaidGetLinkToken?: Maybe<
    { __typename?: 'plaidGetLinkTokenResult' } & Pick<PlaidGetLinkTokenResult, 'token'>
  >;
};

export type PlaidSaveTokenMutationVariables = Exact<{
  companyId: Scalars['ID'];
  publicToken: Scalars['String'];
  accountIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type PlaidSaveTokenMutation = { __typename?: 'Mutation' } & {
  plaidSaveToken?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type PlaidDeleteTokenMutationVariables = Exact<{
  plaidIntegrationId: Scalars['ID'];
}>;

export type PlaidDeleteTokenMutation = { __typename?: 'Mutation' } & {
  plaidDeleteToken?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type PlaidUpdateTokenMutationVariables = Exact<{
  plaidIntegrationId: Scalars['ID'];
  companyId: Scalars['ID'];
  publicToken: Scalars['String'];
  accountIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type PlaidUpdateTokenMutation = { __typename?: 'Mutation' } & {
  plaidUpdateToken?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type PlaidRequestMetricsUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type PlaidRequestMetricsUpdateMutation = { __typename?: 'Mutation' } & {
  plaidRequestMetricsUpdate?: Maybe<
    { __typename?: 'plaidRequestMetricsUpdateResult' } & Pick<
      PlaidRequestMetricsUpdateResult,
      'asyncTaskId'
    >
  >;
};

export type PortfolioCompanyFragmentFragment = { __typename?: 'PortfolioCompany' } & Pick<
  PortfolioCompany,
  'id' | 'active' | 'monitoringStatus' | '_description'
> & {
    company?: Maybe<
      { __typename?: 'Company' } & Pick<
        Company,
        | 'id'
        | 'createdAt'
        | 'updatedAt'
        | 'name'
        | 'active'
        | 'monitoringStatus'
        | 'primaryCustomerBase'
        | 'primaryBusinessModel'
        | 'borrower'
        | 'borrowedDebt'
        | 'idealRaiseAmount'
        | 'foundedYear'
        | 'companyStory'
        | 'founderStory'
        | 'productDescription'
        | 'marketDescription'
        | 'customersStrategy'
        | 'capital'
        | 'capitalType'
        | 'capitalStrategy'
        | 'equityCapitalAmount'
        | 'equityCapitalOwner'
        | 'monthsToNextRound'
        | 'securedDebt'
        | 'structure'
        | 'status'
        | 'contactTitle'
        | 'contactFirstName'
        | 'contactLastName'
        | 'contactEmail'
        | '_description'
      > & {
          createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
          address?: Maybe<
            { __typename?: 'SmartAddress' } & Pick<
              SmartAddress,
              'country' | 'street1' | 'street2' | 'zip' | 'city' | 'state'
            >
          >;
          owner?: Maybe<
            { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName' | 'title' | 'email'
            >
          >;
        }
    >;
  };

export type PortfolioCompanyMainInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PortfolioCompanyMainInfoQuery = { __typename?: 'Query' } & {
  portfolioCompany?: Maybe<
    { __typename?: 'PortfolioCompany' } & Pick<
      PortfolioCompany,
      'id' | 'active' | 'monitoringStatus'
    > & {
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            | 'id'
            | 'name'
            | 'contactEmail'
            | 'contactFirstName'
            | 'contactLastName'
            | 'primaryCustomerBase'
            | 'status'
            | 'structure'
          >
        >;
      }
  >;
};

export type PortfolioCompaniesEntityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PortfolioCompaniesEntityQuery = { __typename?: 'Query' } & {
  portfolioCompany?: Maybe<{ __typename?: 'PortfolioCompany' } & PortfolioCompanyFragmentFragment>;
};

export type PortfolioCompaniesDeleteMutationVariables = Exact<{
  filter: PortfolioCompanyKeyFilter;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type PortfolioCompaniesDeleteMutation = { __typename?: 'Mutation' } & {
  portfolioCompanyDelete?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type PortfolioCompaniesUpdateMutationVariables = Exact<{
  data: PortfolioCompanyUpdateInput;
  filter?: Maybe<PortfolioCompanyKeyFilter>;
}>;

export type PortfolioCompaniesUpdateMutation = { __typename?: 'Mutation' } & {
  portfolioCompanyUpdate: { __typename?: 'PortfolioCompany' } & PortfolioCompanyFragmentFragment;
};

export type SortablePortfolioCompaniesListQueryVariables = Exact<{
  filter?: Maybe<SortablePortfolioCompanyFilter>;
  orderBy?: Maybe<
    Array<Maybe<SortablePortfolioCompanyOrderBy>> | Maybe<SortablePortfolioCompanyOrderBy>
  >;
  sort?: Maybe<Array<SortablePortfolioCompanySort> | SortablePortfolioCompanySort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type SortablePortfolioCompaniesListQuery = { __typename?: 'Query' } & {
  tableData: { __typename?: 'SortablePortfolioCompanyListResponse' } & Pick<
    SortablePortfolioCompanyListResponse,
    'count'
  > & {
      items: Array<
        { __typename?: 'SortablePortfolioCompany' } & Pick<
          SortablePortfolioCompany,
          | 'id'
          | 'name'
          | 'structure'
          | 'active'
          | 'monitoringStatus'
          | 'fundedDate'
          | 'deployed'
          | 'committed'
          | 'term'
          | 'finalAdvanceRate'
          | 'nextAvailableDraw'
          | 'contactFirstName'
          | 'contactLastName'
        >
      >;
    };
};

export type ExportMutationVariables = Exact<{
  event: TableExportInput;
}>;

export type ExportMutation = { __typename?: 'Mutation' } & {
  tableExport?: Maybe<
    { __typename?: 'TableExportResponse' } & Pick<TableExportResponse, 'base64data'>
  >;
};

export type PortfolioCompaniesFacilityListQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioCompaniesFacilityListQuery = { __typename?: 'Query' } & {
  companiesList: { __typename?: 'CompanyListResponse' } & {
    items: Array<
      { __typename?: 'Company' } & {
        portfolioCompany?: Maybe<
          { __typename?: 'PortfolioCompany' } & Pick<PortfolioCompany, 'id'>
        >;
        facilities?: Maybe<
          { __typename?: 'FacilityListResponse' } & {
            items: Array<
              { __typename?: 'Facility' } & Pick<
                Facility,
                | 'closingDate'
                | 'amountCommitted'
                | 'amountDeployed'
                | 'totalAmountDeployed'
                | 'finalAdvanceRate'
                | 'structure'
                | 'nextAvailableDraw'
                | 'createdAt'
              > & {
                  draws?: Maybe<
                    { __typename?: 'DrawListResponse' } & {
                      items: Array<
                        { __typename?: 'Draw' } & Pick<
                          Draw,
                          'fundedDate' | 'term' | 'amountDeployed'
                        >
                      >;
                    }
                  >;
                }
            >;
          }
        >;
      }
    >;
  };
};

export type PortfolioMonitoringCompaniesListQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioMonitoringCompaniesListQuery = { __typename?: 'Query' } & {
  portfolioCompaniesList: { __typename?: 'PortfolioCompanyListResponse' } & {
    items: Array<
      { __typename?: 'PortfolioCompany' } & {
        company?: Maybe<
          { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'structure'> & {
              portfolioCompany?: Maybe<
                { __typename?: 'PortfolioCompany' } & Pick<PortfolioCompany, 'id'>
              >;
            }
        >;
      }
    >;
  };
};

export type CalculatePortfolioMonitoringQueryVariables = Exact<{
  companiesList: Array<Scalars['ID']> | Scalars['ID'];
  dates: Array<Scalars['String']> | Scalars['String'];
  period: Scalars['String'];
}>;

export type CalculatePortfolioMonitoringQuery = { __typename?: 'Query' } & {
  calculatePortfolioMonitoring?: Maybe<
    { __typename?: 'PortfolioMonitoringData' } & {
      monitoringData: Array<
        { __typename?: 'MonitoringCompany' } & Pick<
          MonitoringCompany,
          'companyId' | 'companyName' | 'portfolioCompanyId'
        > & {
            monitoringValues: Array<
              { __typename?: 'PortfolioMonitoringValues' } & Pick<
                PortfolioMonitoringValues,
                'date' | 'committed' | 'deployed' | 'advanceRate'
              >
            >;
            monitoringMetrics: Array<
              { __typename?: 'MetricValue' } & Pick<
                MetricValue,
                'id' | 'value' | 'adjustedValue' | 'date' | 'period' | 'comment'
              > & {
                  metric?: Maybe<
                    { __typename?: 'Metric' } & Pick<Metric, 'code' | 'format' | 'id' | 'name'>
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type MetricFragmentFragment = { __typename?: 'Metric' } & Pick<
  Metric,
  | 'id'
  | 'code'
  | 'name'
  | 'format'
  | 'metadata'
  | 'saaSScoreWeight'
  | 'saaSScoreWeightEnterprise'
  | 'tierBands'
  | 'type'
>;

export type PortfolioOverviewQueryVariables = Exact<{
  date: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;

export type PortfolioOverviewQuery = { __typename?: 'Query' } & {
  getPortfolioOverviewReport?: Maybe<
    { __typename?: 'PortfolioOverviewData' } & Pick<PortfolioOverviewData, 'count'> & {
        companies: Array<
          { __typename?: 'PortfolioOverviewCompany' } & Pick<
            PortfolioOverviewCompany,
            'companyId' | 'companyName' | 'portfolioCompanyId'
          > & {
              portfolioOverviewMetrics: { __typename?: 'PortfolioOverviewMetrics' } & Pick<
                PortfolioOverviewMetrics,
                | 'closingDate'
                | 'fundingDate'
                | 'committed'
                | 'deployed'
                | 'advancedRate'
                | 'closingFee'
                | 'closingFeeValue'
                | 'exitFee'
                | 'iOPeriod'
                | 'iRR'
                | 'interestRate'
                | 'mOIC'
                | 'prepayment'
                | 'prepaymentMonths'
                | 'returnCap'
                | 'structure'
                | 'successFee'
                | 'term'
              >;
              metrics: Array<
                { __typename?: 'MetricValue' } & Pick<
                  MetricValue,
                  | 'id'
                  | 'value'
                  | 'adjustedValue'
                  | 'date'
                  | 'period'
                  | 'active'
                  | 'comment'
                  | 'source'
                  | 'tier'
                > & { metric?: Maybe<{ __typename?: 'Metric' } & MetricFragmentFragment> }
              >;
            }
        >;
      }
  >;
};

export type ProgressStagesQueryQueryVariables = Exact<{
  progressStagesFilter: ProgressStageFilter;
  progressStepValuesFilter: ProgressStepValueFilter;
  progressCompanyStepFilter: ProgressCompanyStepFilter;
}>;

export type ProgressStagesQueryQuery = { __typename?: 'Query' } & {
  progressStagesList: { __typename?: 'ProgressStageListResponse' } & {
    items: Array<
      { __typename?: 'ProgressStage' } & Pick<ProgressStage, 'id' | 'name'> & {
          steps?: Maybe<
            { __typename?: 'ProgressStepListResponse' } & {
              items: Array<
                { __typename?: 'ProgressStep' } & Pick<
                  ProgressStep,
                  'id' | 'name' | 'code' | 'comment' | 'helperText' | 'order'
                > & {
                    stepValue?: Maybe<
                      { __typename?: 'ProgressStepValueListResponse' } & {
                        items: Array<
                          { __typename?: 'ProgressStepValue' } & Pick<
                            ProgressStepValue,
                            | 'id'
                            | 'status'
                            | 'customStepName'
                            | 'customStepComment'
                            | 'isVisible'
                            | 'bigfootTeamComment'
                            | 'applicantComment'
                            | 'updatedAt'
                          >
                        >;
                      }
                    >;
                  }
              >;
            }
          >;
          companySteps?: Maybe<
            { __typename?: 'ProgressCompanyStepListResponse' } & {
              items: Array<
                { __typename?: 'ProgressCompanyStep' } & Pick<
                  ProgressCompanyStep,
                  | 'id'
                  | 'name'
                  | 'status'
                  | 'code'
                  | 'comment'
                  | 'isVisible'
                  | 'bigfootTeamComment'
                  | 'applicantComment'
                  | 'updatedAt'
                >
              >;
            }
          >;
        }
    >;
  };
};

export type ProgressStepValueCreateMutationVariables = Exact<{
  data: ProgressStepValueCreateInput;
}>;

export type ProgressStepValueCreateMutation = { __typename?: 'Mutation' } & {
  progressStepValueCreate: { __typename?: 'ProgressStepValue' } & Pick<
    ProgressStepValue,
    'id' | 'status'
  >;
};

export type ProgressCompanyStepCreateMutationVariables = Exact<{
  data: ProgressCompanyStepCreateInput;
}>;

export type ProgressCompanyStepCreateMutation = { __typename?: 'Mutation' } & {
  progressCompanyStepCreate: { __typename?: 'ProgressCompanyStep' } & Pick<
    ProgressCompanyStep,
    'id' | 'name' | 'comment' | 'code' | 'status'
  >;
};

export type ProgressStepValueUpdateMutationVariables = Exact<{
  data: ProgressStepValueUpdateInput;
  filter: ProgressStepValueKeyFilter;
}>;

export type ProgressStepValueUpdateMutation = { __typename?: 'Mutation' } & {
  progressStepValueUpdate: { __typename?: 'ProgressStepValue' } & Pick<
    ProgressStepValue,
    | 'id'
    | 'status'
    | 'customStepName'
    | 'customStepComment'
    | 'isVisible'
    | 'bigfootTeamComment'
    | 'applicantComment'
  >;
};

export type ProgressCompanyStepUpdateMutationVariables = Exact<{
  data: ProgressCompanyStepUpdateInput;
  filter: ProgressCompanyStepKeyFilter;
}>;

export type ProgressCompanyStepUpdateMutation = { __typename?: 'Mutation' } & {
  progressCompanyStepUpdate: { __typename?: 'ProgressCompanyStep' } & Pick<
    ProgressCompanyStep,
    'id' | 'status' | 'name' | 'comment' | 'isVisible' | 'bigfootTeamComment' | 'applicantComment'
  >;
};

export type QuickBooksGetAuthUrlMutationVariables = Exact<{
  companyId: Scalars['ID'];
  redirectUrl?: Maybe<Scalars['String']>;
}>;

export type QuickBooksGetAuthUrlMutation = { __typename?: 'Mutation' } & {
  quickBooksGetAuthUrl?: Maybe<
    { __typename?: 'quickBooksGetAuthUrlResult' } & Pick<QuickBooksGetAuthUrlResult, 'url'>
  >;
};

export type QuickBooksDeleteTokenMutationVariables = Exact<{
  quickBooksIntegrationId: Scalars['ID'];
}>;

export type QuickBooksDeleteTokenMutation = { __typename?: 'Mutation' } & {
  quickBooksDeleteToken?: Maybe<
    { __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>
  >;
};

export type QuickBooksRequestMetricsUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type QuickBooksRequestMetricsUpdateMutation = { __typename?: 'Mutation' } & {
  quickBooksRequestMetricsUpdate?: Maybe<
    { __typename?: 'quickBooksRequestMetricsUpdateResult' } & Pick<
      QuickBooksRequestMetricsUpdateResult,
      'asyncTaskId'
    >
  >;
};

export type GetStatementsMetricsMutationMutationVariables = Exact<{
  event: StatementsMetricsInput;
}>;

export type GetStatementsMetricsMutationMutation = { __typename?: 'Mutation' } & {
  getStatementsMetricsMutation?: Maybe<
    { __typename?: 'StatementsMetricsResponse' } & Pick<StatementsMetricsResponse, 'taskId'>
  >;
};

export type StatementsExportMutationMutationVariables = Exact<{
  event: TableExportInput;
}>;

export type StatementsExportMutationMutation = { __typename?: 'Mutation' } & {
  tableExport?: Maybe<
    { __typename?: 'TableExportResponse' } & Pick<TableExportResponse, 'asyncTaskId'>
  >;
};

export type StripeGetAuthUrlMutationVariables = Exact<{
  companyId: Scalars['ID'];
  redirectUrl?: Maybe<Scalars['String']>;
}>;

export type StripeGetAuthUrlMutation = { __typename?: 'Mutation' } & {
  stripeGetAuthUrl?: Maybe<
    { __typename?: 'StripeGetAuthUrlResult' } & Pick<StripeGetAuthUrlResult, 'url'>
  >;
};

export type StripeDeleteTokenMutationVariables = Exact<{
  stripeIntegrationId: Scalars['ID'];
}>;

export type StripeDeleteTokenMutation = { __typename?: 'Mutation' } & {
  stripeDeleteToken?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type UserFragmentFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'title'
  | 'status'
  | '_description'
> & {
    avatar?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'downloadUrl'>>;
    invitation?: Maybe<{ __typename?: 'Invitation' } & Pick<Invitation, 'id'>>;
    roles?: Maybe<
      { __typename?: 'RoleListResponse' } & {
        items: Array<{ __typename?: 'Role' } & Pick<Role, 'id' | 'name'>>;
      }
    >;
  };

export type UserCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type UserCompanyQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            'id' | 'primaryCustomerBase' | 'applicationFinalized'
          > & { owner?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
        >;
        employer?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            'id' | 'primaryCustomerBase' | 'applicationFinalized'
          >
        >;
      }
  >;
};

export type UserDeleteMutationVariables = Exact<{
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
}>;

export type UserDeleteMutation = { __typename?: 'Mutation' } & {
  userDelete?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type TeammateQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TeammateQueryQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'title'> & {
        roles?: Maybe<
          { __typename?: 'RoleListResponse' } & {
            items: Array<{ __typename?: 'Role' } & Pick<Role, 'id' | 'name'>>;
          }
        >;
      }
  >;
};

export type EmployeeQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EmployeeQueryQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'> & {
        roles?: Maybe<
          { __typename?: 'RoleListResponse' } & {
            items: Array<{ __typename?: 'Role' } & Pick<Role, 'id' | 'name'>>;
          }
        >;
      }
  >;
};

export type UsersTableContentQueryVariables = Exact<{
  filter?: Maybe<UserFilter>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>> | Maybe<UserOrderBy>>;
  sort?: Maybe<Array<UserSort> | UserSort>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;

export type UsersTableContentQuery = { __typename?: 'Query' } & {
  tableData: { __typename?: 'UserListResponse' } & Pick<UserListResponse, 'count'> & {
      items: Array<{ __typename?: 'User' } & UserFragmentFragment>;
    };
};

export type XeroGetAuthUrlMutationVariables = Exact<{
  companyId: Scalars['ID'];
  redirectUrl?: Maybe<Scalars['String']>;
}>;

export type XeroGetAuthUrlMutation = { __typename?: 'Mutation' } & {
  xeroGetAuthUrl?: Maybe<
    { __typename?: 'xeroGetAuthUrlResult' } & Pick<XeroGetAuthUrlResult, 'url'>
  >;
};

export type XeroDeleteTokenMutationVariables = Exact<{
  xeroIntegrationId: Scalars['ID'];
}>;

export type XeroDeleteTokenMutation = { __typename?: 'Mutation' } & {
  xeroDeleteToken?: Maybe<{ __typename?: 'SuccessResponse' } & Pick<SuccessResponse, 'success'>>;
};

export type XeroRequestMetricsUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type XeroRequestMetricsUpdateMutation = { __typename?: 'Mutation' } & {
  xeroRequestMetricsUpdate?: Maybe<
    { __typename?: 'xeroRequestMetricsUpdateResult' } & Pick<
      XeroRequestMetricsUpdateResult,
      'asyncTaskId'
    >
  >;
};
