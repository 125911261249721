import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ScaleByTierTable } from './ScaleByTierTable';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
  },
}));

export const ScaleByTier: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography gutterBottom variant="h6" display="block">
          Scaling by tier SMB
        </Typography>
        <ScaleByTierTable isEnterprise={false} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography gutterBottom variant="h6" display="block">
          Scaling by tier Enterprise
        </Typography>
        <ScaleByTierTable isEnterprise={true} />
      </Grid>
    </Grid>
  );
};
