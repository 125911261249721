import React from 'react';

export const usePopover = (): {
  isOpen: boolean;
  el: HTMLElement | null;
  openPopover: (event: React.MouseEvent<any, MouseEvent>) => void;
  closePopover: () => void;
} => {
  const [el, setEl] = React.useState<HTMLElement | null>(null);

  const openPopover = React.useCallback(
    (event: React.MouseEvent<any, MouseEvent>): void => {
      setEl(event.currentTarget);
    },
    [setEl],
  );

  const closePopover = React.useCallback(() => {
    setEl(null);
  }, [setEl]);

  return { isOpen: Boolean(el), el, openPopover, closePopover };
};
