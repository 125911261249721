import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { LoanApplicationsMetricsQuery } from 'src/graphql';
import { MetricCard } from 'src/shared';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
  },
  card: {
    flex: '1 1 0',
  },
}));

interface MetricsProps {
  metrics: LoanApplicationsMetricsQuery;
}

export const Metrics: React.FC<MetricsProps> = ({ metrics }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      justify="space-between"
      alignItems="center"
      direction="row"
      spacing={4}
    >
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.accountCreated?.count} name="Account Created" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.saaSScored?.count} name="SaaSScored" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.appCompleted?.count} name="App. Completed" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.structurePresented?.count} name="Struct. Presented" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.termSheet?.count} name="Term Sheet" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.underwriting?.count} name="Underwriting" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.closing?.count} name="Closing" />
      </Grid>
      <Grid item className={classes.card}>
        <MetricCard value={metrics?.funded?.count} name="Funded" />
      </Grid>
    </Grid>
  );
};
