import { gql, useAuth } from '8base-react-sdk';
import { useQuery } from 'react-apollo';
import { User } from 'src/graphql';

const CURRENT_USER_QUERY = gql`
  query UsersEntity($id: ID) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      createdBy {
        id
        _description
      }
      email
      status
      origin
      is8base
      firstName
      lastName
      timezone
      title
      avatar {
        id
        fileId
        filename
        downloadUrl
        shareUrl
        meta
      }
      roles {
        items {
          id
          name
          _description
        }
        count
      }
      invitation {
        id
        _description
      }
      company {
        id
        applicationFinalized
        portfolioCompany {
          id
        }
      }
      employer {
        id
        applicationFinalized
      }
      _description
    }
  }
`;

export enum UserStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITATION_PENDING = 'invitationPending',
}

export const useCurrentUser = (): { user: User | null; loading: boolean } => {
  const { isAuthorized } = useAuth();

  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    skip: !isAuthorized,
  });

  return { user: data?.user || null, loading: isAuthorized ? loading : false };
};
