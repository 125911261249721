import React, { FC } from 'react';
import { List, Theme } from '@material-ui/core';

import { Note } from './notesList/Note';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    overflow: 'auto',
    padding: 0,
  },
}));

interface NotesListProps {
  path: string;
  notes: Array<any>;
}

export const NotesList: FC<NotesListProps> = props => {
  const { path, notes } = props;

  const classes = useStyles();

  return (
    <List className={classes.list}>
      {notes.map((note: any) => (
        <Note key={note.id} path={path} note={note} />
      ))}
    </List>
  );
};
