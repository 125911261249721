import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import * as icons from '@material-ui/icons';

import { EmptyScreen } from 'src/components';
import { actionsContext } from 'src/shared/team/ActionsProvider';

const EmptyScreenContainer = (): JSX.Element => {
  const { openTeamInviteDialog, permissions } = useContext(actionsContext);

  const emptyScreenProps = React.useMemo(() => {
    const actionText = 'Invite Teammate';
    const onClick = openTeamInviteDialog;

    return permissions.canInvite ? { actionText, onClick } : null;
  }, [permissions, openTeamInviteDialog]);

  return (
    <Box margin={2}>
      <EmptyScreen text="We couldn't find any teammates" icon={icons.Group} {...emptyScreenProps} />
    </Box>
  );
};

export default EmptyScreenContainer;
