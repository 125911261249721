import React, { memo } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { TableShared } from 'src/shared/table/TableShared';
import { ColumnsType } from 'rc-table/es/interface';

const Table = memo(
  ({
    rows,
    columns,
    classes,
  }: {
    rows: any;
    columns: ColumnsType<any>;
    classes: Record<any, any>;
  }) => {
    return (
      <Grid container item>
        <Paper style={{ width: '100%' }}>
          <TableShared
            rowKey={(row: any) => row.id}
            className={classes.table}
            rowClassName={classes.row}
            scroll={{ x: 2500 }}
            columns={columns}
            data={rows}
          />
        </Paper>
      </Grid>
    );
  },
);

Table.displayName = 'Table';

export { Table };
