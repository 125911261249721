const DOLLAR_OR_PERCENT_OR_COMMA = /[%,$\s]/g;
const BRACKETS = /[\])}[{(]/g;
const CURLY_BRACKETS = /{(.*)}/;
// eslint-disable-next-line no-useless-escape
const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
const TEMPLATE_PARSER_BRACKETS = /\{\{[ ]{0,}([\w\_-]{1,})[ ]{0,}\}\}/gi;
const TEMPLATE_PARSER_FROM_TITLE_TO_CODE = /[^0-9A-Za-z]+/g;
const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/;

export const REG_EXP = {
  DOLLAR_OR_PERCENT_OR_COMMA,
  TEMPLATE_PARSER_BRACKETS,
  BRACKETS,
  CURLY_BRACKETS,
  EMAIL,
  TEMPLATE_PARSER_FROM_TITLE_TO_CODE,
  PASSWORD_VALIDATION,
};
