import * as R from 'ramda';

import { MetricValueCreateManyInput } from 'src/graphql';

import { CompaniesWithMetricChange } from 'src/types/localStorage';
import { ChangedMetric } from 'src/types';
import { Format } from 'src/constants';

export const hasMetricChange = (
  companiesWithChange: CompaniesWithMetricChange | null = {},
  companyId: string,
): boolean => {
  if (!companiesWithChange) {
    companiesWithChange = {};
  }

  return R.pathOr(false, [companyId], companiesWithChange);
};

export const addMetricChange = (
  companiesWithChange: CompaniesWithMetricChange | null = {},
  companyId: string,
  metric: ChangedMetric,
): CompaniesWithMetricChange => {
  if (!companiesWithChange) {
    companiesWithChange = {};
  }

  const updatedCompanies = { ...companiesWithChange };

  const clearedMetric = R.omit(['companyId', 'date'], metric);

  const metricKey = getMetricLocalValuesKey(metric.metricCode, metric.period);

  const levelMetrics = R.pathOr({}, [companyId], companiesWithChange);
  const levelDates = R.pathOr({}, [metricKey], levelMetrics);

  // create hashes TableUnderwritingReport
  updatedCompanies[companyId] = {
    ...levelMetrics,
    [metricKey]: {
      ...levelDates,
      [metric.date]: clearedMetric,
    },
  };

  return updatedCompanies;
};

export const removeMetricChange = (
  companiesWithChange: CompaniesWithMetricChange | null = {},
  companyId: string,
): CompaniesWithMetricChange => {
  if (!companiesWithChange) {
    companiesWithChange = {};
  }

  const updatedCompanies = { ...companiesWithChange };

  delete updatedCompanies[companyId];

  return updatedCompanies;
};

export const getAllMetricsByCompanyId = (
  companiesWithChange: CompaniesWithMetricChange | null = {},
  companyId: string,
): ChangedMetric[] => {
  const metricsCompany = R.pathOr({}, [companyId], companiesWithChange);

  const metrics: ChangedMetric[] = [];

  for (const metricKey in metricsCompany) {
    const dates = R.pathOr({}, [metricKey], metricsCompany);

    for (const date in dates) {
      const metricByDate = R.pathOr({}, [date], dates);

      const metric = {
        ...metricByDate,
        date,
      };
      metrics.push(metric as ChangedMetric);
    }
  }

  return metrics;
};

export const getLocalChangedMetric = (
  companiesWithChange: CompaniesWithMetricChange | null = {},
  companyId: string,
  key: string,
  date: string,
  format?: Format,
): ChangedMetric | null => {
  const path = [companyId, key, date];
  const metric = R.pathOr<ChangedMetric | null>(null, path, companiesWithChange);

  if (metric !== null && R.equals(format, Format.Percent)) {
    return {
      ...metric,
      adjustedValue: metric.adjustedValue,
    };
  }

  return metric;
};

export const generateLocalMetricsAsQueryData = (
  localMetrics: ChangedMetric[],
  companyId: string,
): MetricValueCreateManyInput[] => {
  const data: MetricValueCreateManyInput[] = localMetrics.map(
    ({ date, source, period, active, adjustedValue, comment, metricCode }: ChangedMetric) => {
      return {
        company: {
          connect: { id: companyId },
        },
        metric: {
          connect: {
            code: metricCode,
          },
        },
        source,
        active,
        period,
        date,
        adjustedValue,
        comment,
      };
    },
  );

  return data;
};

export const getMetricLocalValuesKey = (code: string, period: string): string => {
  return `${code}_${period}`;
};
