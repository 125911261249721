import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import { useCurrentUser } from 'src/app-builder';

import { OnboardingStep } from 'src/constants';
import { OnboardingStepper } from 'src/components';
import { CustomerHomeIntegrationsPage, CustomerHomeSaaSScorePage } from './index';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    textAlign: 'center',
  },
  headerContainer: {
    marginBottom: theme.spacing(8),
  },
}));

const ROUTE_TO_STEP_MAP = {
  '/customer/home': OnboardingStep.Second,
  '/customer/home/saas-score': OnboardingStep.Third,
};

type CustomerHomeRoute = '/customer/home' | '/customer/home/saas-score';

interface CustomerHomePageProps {}

export const CustomerHomePage: React.FC<CustomerHomePageProps> = () => {
  const classes = useStyles();
  const location = useLocation();

  const { user } = useCurrentUser();
  const company = user?.company || null;
  const hasPortfolioCompany = Boolean(company?.portfolioCompany);

  return (
    <Grid className={classes.root}>
      {!hasPortfolioCompany && (
        <Grid className={classes.headerContainer} container justify="center">
          <OnboardingStepper step={ROUTE_TO_STEP_MAP[location.pathname as CustomerHomeRoute]} />
        </Grid>
      )}

      <Switch>
        <Route exact path="/customer/home" component={CustomerHomeIntegrationsPage} />
        <Route path="/customer/home/saas-score" component={CustomerHomeSaaSScorePage} />
      </Switch>
    </Grid>
  );
};
