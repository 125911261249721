import React from 'react';
import { Paper, Grid, List, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { SecondaryNavItem } from 'src/app-builder';

import { useLoanApplication, useInboxMessagesCount } from 'src/hooks';
import { LoanApplicationProvider } from 'src/providers/LoanApplicationProvider';
import { BackNavItem } from 'src/components';
import { InboxMessagesIcon } from 'src/shared/inboxMessages';
import {
  LoanApplicationHeader,
  LoanApplicationInformationPage,
  LoanApplicationSaaSStory,
  LoanApplicationScoreAndMetricsPage,
  LoanApplicationSaaSLoanPage,
  LoanApplicationDocumentsPage,
  LoanApplicationNotesPage,
  LoanApplicationStatusPage,
  LoanApplicationTeamPage,
  LoanApplicationUnderwritingPackagePage,
  LoanApplicationFacilityAndDrawsPage,
  LoanApplicationIntegrationsPage,
  LoanApplicationStatementsPage,
  LoanApplicationInboxPage,
  LoanApplicationDashboardPage,
} from './index';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    height: '100%',
  },

  content: {
    flex: 1,
    display: 'flex',
    height: '100%',
    overflow: 'auto',
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar ': {
      display: 'none',
    },
  },
  fullHeight: {
    height: '100%',
    overflow: 'auto',
  },
  main: {
    height: 'calc(100% - 122px)',
  },
}));

interface LoanApplicationContentProps {
  loanApplicationId: string;
}

const LoanApplicationPageContent: React.FC<LoanApplicationContentProps> = ({
  loanApplicationId,
}) => {
  const classes = useStyles();

  const { data: loanApplication } = useLoanApplication();
  const { messagesCount, setCompanyId } = useInboxMessagesCount();

  setCompanyId(loanApplication?.company?.id as string);

  return (
    <Grid container spacing={2} className={classes.fullHeight} direction="column" wrap="nowrap">
      <Grid container item>
        <LoanApplicationHeader />
      </Grid>

      <Grid className={classes.main} container item>
        <Paper className={classes.paper}>
          <Grid container direction="row" className={classes.fullHeight} wrap="nowrap">
            <Grid item className={classes.sidebar}>
              <List>
                <BackNavItem to="/loan-applications" />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/dashboard`}
                  icon="ViewQuilt"
                  title="Dashboard"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/information`}
                  icon="Info"
                  title="Loan Information"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/saas-story`}
                  icon="AccountCircle"
                  title="SaaSStory"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/saas-score-and-metrics`}
                  icon="MyLocation"
                  title="SaaSScore &amp; Key Metrics"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/underwriting-package`}
                  icon="MonetizationOn"
                  title="Underwriting Package"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/statements`}
                  icon="InsertChart"
                  title="Statements"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/facility-and-draws`}
                  icon="Assignment"
                  title="Facility &amp; Draws"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/saas-loan`}
                  icon="Info"
                  title="SaaSLoan"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/documents`}
                  icon="FileCopy"
                  title="Documents"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/notes`}
                  icon="Description"
                  title="Notes"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/status`}
                  icon="Done"
                  title="Funding Progress"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/integrations`}
                  icon="OfflineBoltRounded"
                  title="Integrations"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/team`}
                  icon="Group"
                  title="Team"
                />
                <SecondaryNavItem
                  to={`/loan-applications/${loanApplicationId}/inbox`}
                  icon={<InboxMessagesIcon messagesCount={messagesCount} />}
                  title="Inbox"
                />
              </List>
            </Grid>

            <Grid className={classes.content} item>
              <Switch>
                <Route
                  path={`/loan-applications/:id/dashboard`}
                  component={LoanApplicationDashboardPage}
                />
                <Route
                  path={`/loan-applications/:id/information`}
                  component={LoanApplicationInformationPage}
                />
                <Route
                  path={`/loan-applications/:id/saas-story`}
                  component={LoanApplicationSaaSStory}
                />
                <Route
                  path={`/loan-applications/:id/saas-score-and-metrics`}
                  component={LoanApplicationScoreAndMetricsPage}
                />
                <Route
                  path={`/loan-applications/:id/underwriting-package`}
                  component={LoanApplicationUnderwritingPackagePage}
                />
                <Route
                  path={`/loan-applications/:id/statements`}
                  component={LoanApplicationStatementsPage}
                />
                <Route
                  path={`/loan-applications/:id/facility-and-draws`}
                  component={LoanApplicationFacilityAndDrawsPage}
                />
                <Route
                  path={`/loan-applications/:id/saas-loan`}
                  component={LoanApplicationSaaSLoanPage}
                />
                <Route
                  path={`/loan-applications/:id/documents`}
                  component={LoanApplicationDocumentsPage}
                />
                <Route
                  path={[`/loan-applications/:id/notes/:noteId`, `/loan-applications/:id/notes`]}
                  component={LoanApplicationNotesPage}
                />
                <Route
                  path={'/loan-applications/:id/status'}
                  component={LoanApplicationStatusPage}
                />
                <Route path={`/loan-applications/:id/team`} component={LoanApplicationTeamPage} />
                <Route
                  path={`/loan-applications/:id/integrations`}
                  component={LoanApplicationIntegrationsPage}
                />
                <Route
                  path={[
                    `/loan-applications/:id/inbox/:folder/:messageId`,
                    `/loan-applications/:id/inbox/:folder`,
                  ]}
                  component={LoanApplicationInboxPage}
                />

                <Redirect
                  from={`/loan-applications/:loanId/inbox`}
                  to={`/loan-applications/:loanId/inbox/received`}
                />

                <Redirect to={`/loan-applications/${loanApplicationId}/dashboard`} />
              </Switch>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const LoanApplicationPage: React.FC = () => {
  const { id: loanApplicationId }: { id: string } = useParams();

  return (
    <LoanApplicationProvider loanApplicationId={loanApplicationId}>
      <LoanApplicationPageContent loanApplicationId={loanApplicationId} />
    </LoanApplicationProvider>
  );
};
