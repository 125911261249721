import React from 'react';
import { Popover, MenuList, PopoverProps } from '@material-ui/core';

interface EntitiesTableActionsPopoverProps extends PopoverProps {
  createActions?: (closeActions: () => void, id: string) => React.ReactNode;
  activeId: string | null;
  onClose: () => void;
}

export const EntitiesTableActionsPopover: React.FC<EntitiesTableActionsPopoverProps> = ({
  anchorEl,
  open,
  onClose,
  createActions,
  activeId,
}) => {
  const extraActions = React.useMemo(
    () => (createActions && activeId ? createActions(onClose, activeId) : []),
    [createActions, activeId, onClose],
  );

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuList>{extraActions}</MenuList>
    </Popover>
  );
};
