import React, { useContext } from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';

import { selectedRowsContext } from 'src/providers/SelectedRowsProvider';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
}));

export const SelectionColumnRenderer = (row: Record<any, any>): React.ReactNode => {
  const selected = useContext(selectedRowsContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Checkbox
        checked={selected?.selectedRows[row.id] || false}
        onChange={() => selected?.toggleRowSelection(row.id)}
      />
    </div>
  );
};
