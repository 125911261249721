import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Theme,
} from '@material-ui/core';
import { Form, Field } from '8base-react-sdk';
import { useSnackbar } from 'notistack';

import { useModal, useClientRoles } from '../providers';
import { SelectField } from '../forms';

import { DIALOGS } from './constants';
import { APP_ROLES } from 'src/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),
  },
}));

interface SwitchRoleDialogProps {}

export const SwitchRoleDialog: React.FC<SwitchRoleDialogProps> = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { currentRole, roles, setRoleName } = useClientRoles();

  const { open, closeModal } = useModal(DIALOGS.SWITCH_ROLE_DIALOG);

  const onSubmit = React.useCallback(
    async (data, form) => {
      setRoleName(data.role);

      enqueueSnackbar(`Role successfully switched`, { variant: 'success' });

      closeModal();

      setTimeout(form.reset);
    },
    [closeModal, enqueueSnackbar, setRoleName],
  );

  const onClose = React.useCallback(
    form => {
      closeModal();

      setTimeout(form.reset);
    },
    [closeModal],
  );

  const roleOptions = React.useMemo(
    () =>
      roles.map(({ name }) => ({
        value: name,
        label: APP_ROLES[name]?.title,
      })),
    [roles],
  );

  if (!open) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ role: currentRole?.name }}>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={onClose.bind(null, form)}
          PaperProps={{
            className: classes.root,
            component: 'form' as any,
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle>Switch Role</DialogTitle>

          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Field
                  component={SelectField}
                  name="role"
                  label="Role"
                  fullWidth
                  options={roleOptions}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" disabled={submitting} onClick={onClose.bind(null, form)}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={submitting || pristine}
            >
              Switch
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
