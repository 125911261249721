import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TableShared } from 'src/shared/table/TableShared';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      padding: '0 !important',
    },
    '& td': {
      padding: '0 !important',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td:first-of-type': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: '8px !important',
    },
    '& tr': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        height: 41,
      },
    },
    '& .rc-table-container': {
      height: '100%',
    },
    '& .rc-table-body': {
      height: 'calc(100% - 41px)',
      maxHeight: 'calc(100vh - 448px)',
    },
    // eslint-disable-next-line no-multi-str
    '& .rc-table-body .rc-table-row:nth-of-type(5n+4) .rc-table-cell:first-of-type, \
     & .rc-table-body .rc-table-row:nth-of-type(2) .rc-table-cell:first-of-type': {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

const getScrollX = (months: number): number => {
  const COVENANT_COL_WIDTH = 275;
  const OTHER_COLS_WIDTH = 185;

  return COVENANT_COL_WIDTH + months * OTHER_COLS_WIDTH;
};

interface CovenantTrackingReportProps {
  metrics: any;
  rows: any;
}

export const CovenantTrackingReport: React.FC<CovenantTrackingReportProps> = ({
  metrics,
  rows,
}) => {
  const classes = useStyles();
  const scroll = {
    x: getScrollX(metrics.length - 1),
    y: 'auto',
  };

  return (
    metrics && (
      <TableShared className={classes.table} scroll={scroll} columns={metrics} data={rows} />
    )
  );
};
