import React from 'react';
import { Typography, Paper, Grid, List, Theme, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as ChangeCase from 'change-case';

import { SecondaryNavItem } from '../../shared';
import { useAllowed } from '../../providers';

import { APP_SETTING } from 'src/constants/app';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'auto',
    flex: 1,
  },
  content: {
    width: '100%',
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

interface SettingsPageProps {
  routes: Array<{
    path: string;
    exact: true;
    component: React.ComponentType<any>;
    location: 'main' | 'reports' | 'settings' | 'custom';
  }>;
}

export const SettingsPage: React.FC<SettingsPageProps> = ({ routes }) => {
  const classes = useStyles();

  const isAllowed = useAllowed();

  const areas = APP_SETTING.areas.items.filter(
    ({ name, location }) =>
      location === 'settings' && isAllowed(ChangeCase.constantCase(name + 'Area')),
  );

  const settingsRoutes = routes.filter(({ location }) => location === 'settings');

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Settings</Typography>
      </Grid>

      <Grid container item>
        <Paper className={classes.paper}>
          <Grid container direction="row" wrap="nowrap">
            <Grid item className={classes.sidebar}>
              <List>
                {areas
                  .filter(({ isSystem }) => isSystem)
                  .sort(({ position: a }, { position: b }) => a - b)
                  .map(area => (
                    <SecondaryNavItem
                      key={area.path}
                      to={area.path}
                      icon={area.icon}
                      title={area.title as string}
                    />
                  ))}

                {areas.filter(({ isSystem }) => isSystem).length > 0 && (
                  <Divider style={{ marginBottom: 8 }} />
                )}

                {areas
                  .filter(({ isSystem }) => !isSystem)
                  .sort(({ position: a }, { position: b }) => a - b)
                  .map(area => (
                    <SecondaryNavItem
                      key={area.path}
                      to={area.path}
                      icon={area.icon}
                      title={area.title as string}
                    />
                  ))}
              </List>
            </Grid>

            <Grid item className={classes.contentWrapper}>
              <Box padding={0} className={classes.content}>
                <Switch>
                  {settingsRoutes.map(({ location, ...route }) => (
                    <Route key={route.path} {...route} />
                  ))}

                  <Redirect to={areas[0]?.path || ''} />
                </Switch>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
