import React, { useMemo } from 'react';
import { Grid, Typography, FormHelperText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Field, Form } from 'src/app-builder';

import { useRouterQuery } from 'src/hooks';
import { SubmitButton } from 'src/components';

import { validateDataByYup } from 'src/utils/validators';
import { getWorkspaceInfo } from 'src/utils';
import { trimString } from 'src/utils/parsers';
import { UserInvitationSignUpSchema } from 'src/utils/schemas';
import { LOGIN_CONNECTION } from 'src/constants';

const { REACT_APP_ENDPOINT = '' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: theme.spacing(80),
  },
}));

type InvitationSignUpProps = {
  onSubmit: (
    data: any,
  ) => Promise<
    | {
        [key: string]: string;
      }
    | undefined
  >;
};

export const InvitationSignUpForm: React.FC<InvitationSignUpProps> = ({ onSubmit }) => {
  const query = useRouterQuery();

  const { env } = useMemo(() => {
    return getWorkspaceInfo(REACT_APP_ENDPOINT);
  }, []);

  const initialValues = useMemo(
    () => ({
      connection: LOGIN_CONNECTION,
      email: query.email,
      user_metadata: {
        workspace_env: env,
        invitation_id: query.id,
        first_name: query.firstName,
        last_name: query.lastName,
      },
    }),
    [query, env],
  );

  const classes = useStyles();

  return (
    <Form
      subscription={{ submitting: true, pristine: true }}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => validateDataByYup(UserInvitationSignUpSchema, values)}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <Grid
          className={classes.form}
          component="form"
          onSubmit={handleSubmit}
          container
          item
          spacing={2}
        >
          <Grid xs={12} item>
            <Typography variant="subtitle1">Login Information</Typography>
          </Grid>

          <Grid xs={6} item>
            <Field
              fullWidth
              name="email"
              label="Business Email"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
              disabled={true}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="password"
              label="Password"
              type="password"
              component={TextField}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.first_name"
              label="First Name"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
            />
          </Grid>
          <Grid xs={6} item>
            <Field
              fullWidth
              name="user_metadata.last_name"
              label="Last Name"
              component={TextField}
              format={trimString}
              formatOnBlur={true}
            />
          </Grid>

          {submitError && (
            <Grid xs={12} item>
              <FormHelperText error>{submitError}</FormHelperText>
            </Grid>
          )}
          <Grid xs={12} item>
            <SubmitButton
              fullWidth
              size="large"
              type="submit"
              color="secondary"
              variant="contained"
              loading={submitting}
            >
              Create Account
            </SubmitButton>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};
