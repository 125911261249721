import React from 'react';
import * as R from 'ramda';
import { Link, useParams } from 'react-router-dom';
import { Box, ListItem, ListItemText, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DateFormatPatterns, formatDate } from 'src/utils';
import { InboxMessage } from 'src/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  createdByTitle: {
    color: theme.palette.text.secondary,
  },
  createdDate: {
    color: theme.palette.text.secondary,
    marginLeft: 5,
    whiteSpace: 'nowrap',
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
    width: '100%',
  },
  listItem: {
    height: 'auto',
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: (props: { isActive: boolean }) =>
      props.isActive ? theme.customPalette.background.activeCard : theme.palette.background.paper,
  },
  title: {
    fontSize: 16,
    marginRight: 10,
  },
}));

type RouteParams = {
  folder: string;
  messageId: string;
};

interface MessageProps {
  message: InboxMessage;
  path: string;
}

export const ShortViewMessage: React.FC<MessageProps> = props => {
  const { message, path } = props;

  const { messageId }: RouteParams = useParams();

  const classes = useStyles({
    isActive: R.equals(messageId, message?.id),
  });

  const createdAt = formatDate(
    message.createdAt as string,
    DateFormatPatterns.smallDateWithLocalTime,
  );

  return (
    <ListItem className={classes.listItem}>
      <Link key={message.id} to={`${path}/${message.id}`} className={classes.link}>
        <ListItemText
          primary={
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography className={classes.title} component="h6" variant="subtitle2" noWrap>
                {message.subject}
              </Typography>
              <Typography className={classes.createdDate} variant="body2">
                {createdAt}
              </Typography>
            </Box>
          }
          secondary={
            <Box component="span" display="flex" flexDirection="column">
              <Typography component="span" variant="body2" color="textPrimary" noWrap>
                {message.body}
              </Typography>
            </Box>
          }
        />
      </Link>
    </ListItem>
  );
};
