import React from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { FieldSchema } from '@8base/utils';
import { Theme, Chip, Grid } from '@material-ui/core';

import { AppAreaField } from 'src/constants/app';

const useStyles = makeStyles((theme: Theme) => ({
  chip: ({ color }: { color: string }): CreateCSSProperties<{ color: string }> => {
    let backgroundColor = theme.palette.primary.main;
    let textColor = theme.palette.primary.contrastText;

    if (['primary', 'secondary', 'info', 'error', 'warning', 'success'].indexOf(color) !== -1) {
      backgroundColor = R.pathOr(theme.palette.primary.main, [color, 'main'], theme.palette);
      textColor = R.pathOr(
        theme.palette.primary.contrastText,
        [color, 'contrastText'],
        theme.palette,
      );
    } else if (/#([\da-f]{3}){1,2}/i.test(color)) {
      backgroundColor = color;
      textColor = '#ffffff';
    }

    return {
      backgroundColor: backgroundColor,
      color: textColor,
    };
  },
}));

interface FieldChipItemProps {
  value: any;
  areaField: AppAreaField;
}

export const FieldChilItem: React.FC<FieldChipItemProps> = ({ value, areaField }) => {
  const color: string = R.propOr('primary', String(value), areaField?.formatOptions.colors || {});
  const label: string = R.propOr(
    String(value),
    String(value),
    areaField?.formatOptions.labels || {},
  );

  const classes = useStyles({ color });

  return <Chip className={classes.chip} label={label} size="small" />;
};

interface FieldChipProps {
  field: FieldSchema;
  value: any;
  areaField: AppAreaField;
}

export const FieldChip: React.FC<FieldChipProps> = ({ value, areaField, field }) => {
  if (R.isNil(value) || R.isEmpty(value)) {
    return null;
  }

  if (field.isList) {
    if (Array.isArray(value)) {
      return (
        <Grid container spacing={1}>
          {value.map(item => (
            <Grid key={item} item>
              <FieldChilItem value={item} areaField={areaField} />
            </Grid>
          ))}
        </Grid>
      );
    }
  }

  return <FieldChilItem value={value} areaField={areaField} />;
};
