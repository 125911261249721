import { useIntegrations } from 'src/hooks/useIntegrations';
import { Source } from 'src/types';

type StatementsIntegrationsResponse = {
  integrationsLoading: boolean;
  integrationType: Source.Xero | Source.QBO | null;
  isIntegrationConnected: boolean;
  isPlaidIntegrationConnected: boolean;
  isQuickBooksIntegrationConnected: boolean;
  isXeroIntegrationConnected: boolean;
};

export const useStatementsIntegrations = (
  companyId: string,
  companyInfoLoading: boolean,
): StatementsIntegrationsResponse => {
  const {
    loading: integrationsDataLoading,
    isPlaidIntegrationConnected,
    isQuickBooksIntegrationConnected,
    isXeroIntegrationConnected,
  } = useIntegrations(companyId);

  const integrationsLoading = integrationsDataLoading || companyInfoLoading;
  const isIntegrationConnected = isXeroIntegrationConnected || isQuickBooksIntegrationConnected;

  const getIntegrationType = (
    integrationsLoading: boolean,
    isQuickBooksIntegrationConnected: boolean,
    isXeroIntegrationConnected: boolean,
  ) => {
    if (integrationsLoading) {
      return null;
    }

    if (isXeroIntegrationConnected) {
      return Source.Xero;
    }

    if (isQuickBooksIntegrationConnected) {
      return Source.QBO;
    }

    return null;
  };

  const integrationType = getIntegrationType(
    integrationsLoading,
    isQuickBooksIntegrationConnected,
    isXeroIntegrationConnected,
  );

  return {
    integrationsLoading,
    isIntegrationConnected,
    integrationType,
    isPlaidIntegrationConnected,
    isQuickBooksIntegrationConnected,
    isXeroIntegrationConnected,
  };
};
