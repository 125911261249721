import React from 'react';
import { Grid, Link, Typography, FormHelperText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import { TextField, useAuth, Field, Form, FORM_ERROR } from 'src/app-builder';
import { SubmitButton } from 'src/components';
import { required, email, composeValidators } from 'src/utils/validators';

import bfc from 'src/assets/logos/bfc.png';
import background from 'src/assets/bg.jpg';
import { LOGIN_CONNECTION, AuthRoutes } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    background: theme.palette.secondary.dark,
  },
  wrapper: {
    background: theme.palette.common.white,
    margin: 0,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  form: {
    maxWidth: theme.spacing(80),
  },
  logo: {
    width: theme.spacing(22),
  },
}));

export const AuthLoginPage: React.FC = () => {
  const { authClient, isAuthorized } = useAuth();

  const classes = useStyles();

  const login = React.useCallback(
    data =>
      new Promise<void>((resolve, reject) => {
        authClient.auth0.login(data, (err: any) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      }),
    [authClient],
  );

  const onSubmit = React.useCallback(
    async data => {
      try {
        await login({
          connection: LOGIN_CONNECTION,
          email: data.email,
          password: data.password,
        });
      } catch (e) {
        return { [FORM_ERROR]: e?.description || 'Something went wrong during login' };
      }
    },
    [login],
  );

  // if the user already logged in we redirect them to the root route
  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return (
    <Grid className={classes.root} container>
      <Grid
        className={classes.wrapper}
        xs={6}
        item
        container
        alignItems="center"
        justify="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <img className={classes.logo} src={bfc} alt="" />
        </Grid>
        <Grid item>
          <Typography variant="h3">Welcome Back</Typography>
        </Grid>
        <Grid item>
          <Typography>
            Don’t have an account?{' '}
            <Link color="secondary" component={RouterLink} to={AuthRoutes.SignUp}>
              Register here
            </Link>
          </Typography>
        </Grid>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitError, submitting }) => (
            <Grid
              className={classes.form}
              component="form"
              onSubmit={handleSubmit}
              container
              item
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Field
                  fullWidth
                  name="email"
                  label="Email"
                  component={TextField}
                  validate={composeValidators([required, email])}
                />
              </Grid>
              <Grid item>
                <Field
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  component={TextField}
                  validate={composeValidators([required])}
                />
              </Grid>

              {submitError && (
                <Grid item>
                  <FormHelperText error>{submitError}</FormHelperText>
                </Grid>
              )}
              <Grid item>
                <SubmitButton
                  fullWidth
                  size="large"
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={submitting}
                >
                  Login
                </SubmitButton>
              </Grid>
            </Grid>
          )}
        </Form>
        <Grid item>
          <Typography>
            Forgot your password?{' '}
            <Link color="secondary" component={RouterLink} to={AuthRoutes.ChangePassword}>
              Click here
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={6}
        item
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
      />
    </Grid>
  );
};
