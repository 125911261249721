import React from 'react';
import { Grid, LinearProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import {
  useLoanApplication,
  useStatementsTable,
  useStatementsExport,
  useStatementsReport,
  useStatementsIntegrations,
} from 'src/hooks';
import { EmptyScreen } from 'src/components';
import { StatementsReport } from 'src/shared';
import { StatementsReportHeader } from 'src/shared/statements/StatementsReportHeader';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(2),
    height: '100%',
  },
  reportTable: {
    marginBottom: 20,
  },
}));

export const LoanApplicationStatementsPage: React.FC = () => {
  const classes = useStyles();

  const { data: loanApplication, loading: loanLoading } = useLoanApplication();

  const companyId = loanApplication?.company?.id as string;

  const {
    integrationType,
    integrationsLoading,
    isIntegrationConnected,
    isPlaidIntegrationConnected,
  } = useStatementsIntegrations(companyId, loanLoading);

  const [
    onChangeActiveReport,
    onRefetch,
    {
      data: { statementsReports, reportType },
      loading: statementsLoading,
      filter: { from, till },
    },
  ] = useStatementsReport({ companyId, integrationType, integrationsLoading });

  const { metrics, exportData, dates } = useStatementsTable(statementsReports);

  const exportParams = {
    companyId,
    period: [from, till],
    integrationType,
    isPlaidIntegrationConnected,
  };

  const isLoading = integrationsLoading || loanLoading || statementsLoading;

  const [onExport, { loading: isExporting, disabled }] = useStatementsExport(exportParams);

  if (isIntegrationConnected || isLoading) {
    return (
      <Grid container direction="column" wrap="nowrap">
        <StatementsReportHeader
          onExport={onExport}
          isExporting={isExporting}
          isDisabled={disabled || isLoading}
        />

        {isLoading ? (
          <LinearProgress />
        ) : (
          <Grid item className={classes.content}>
            <StatementsReport
              from={from}
              till={till}
              reportType={reportType}
              onChangeActiveReport={onChangeActiveReport}
              onRefetch={onRefetch}
              metrics={metrics}
              columns={exportData.columns}
              dates={dates}
              integrationType={integrationType}
              isPlaidIntegrationConnected={isPlaidIntegrationConnected}
              className={classes.reportTable}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  return <EmptyScreen text="Integrations are not connected" icon={icons.InsertChart} />;
};
