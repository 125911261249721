import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SubmitButton } from 'src/components';
import * as icons from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  button: {
    padding: theme.spacing(1),

    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

interface StatementsReportHeaderProps {
  onExport: () => void;
  isExporting: boolean;
  isDisabled: boolean;
}

export const StatementsReportHeader: React.FC<StatementsReportHeaderProps> = ({
  onExport,
  isExporting,
  isDisabled,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} item>
      <Typography variant="subtitle1">Statements</Typography>

      {/* todo: refactor export for new reports */}
      <Grid item className={clsx([classes.right, classes.button])}>
        <SubmitButton
          onClick={onExport}
          color="secondary"
          variant="outlined"
          size="large"
          startIcon={<icons.GetApp />}
          loading={isExporting}
          disabled={isDisabled}
        >
          Export
        </SubmitButton>
      </Grid>
    </Grid>
  );
};
