import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '8px 16px',
    height: '37px',
  },
}));

export const StatementsMetricNameRenderer = (name: string): React.ReactNode => {
  const classes = useStyles();
  return <div className={classes.root}>{name}</div>;
};
