import { CovenantTableType, MetricCode } from 'src/types';

export const DEFAULT_COVENANT_REPORT_RANGE = 12;

export const METRICS_FOR_COVENANTS: Record<CovenantTableType, Array<MetricCode>> = {
  [CovenantTableType.AdjustedNetCashBurnL3MCovenant]: [MetricCode.AdjustedNetCashBurnL3M],
  [CovenantTableType.MinCashPositionMonthly]: [MetricCode.CashPositionPlaid],
  [CovenantTableType.MinCashRunwayMonthly]: [
    MetricCode.CashPositionPlaid,
    MetricCode.AdjustedNetCashBurnL3M,
  ],
  [CovenantTableType.MinCashPositionWeekly]: [MetricCode.CashPositionPlaid],
  [CovenantTableType.MinCashRunwayWeekly]: [
    MetricCode.CashPositionPlaid,
    MetricCode.AdjustedNetCashBurnL3M,
  ],
  [CovenantTableType.CumulativeCashReceipts]: [MetricCode.CashInflows],
  [CovenantTableType.MinCashAsPercentOfDrawsTaken]: [MetricCode.CashPositionPlaid],
  [CovenantTableType.CumulativeAdvanceRate]: [],
  [CovenantTableType.ActualVsExpectedRevenue]: [],
  [CovenantTableType.AdditionalCovenant]: [],
};
