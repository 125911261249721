import { gql } from '8base-react-sdk';

export const INVITATION_RESEND_MUTATION = gql`
  mutation InvitationResend($id: ID!) {
    invitations {
      invitationResend(id: $id) {
        success
      }
    }
  }
`;

export const INVITATION_CANCEL_MUTATION = gql`
  mutation InvitationCancel($id: ID!) {
    invitations {
      invitationCancel(id: $id) {
        success
      }
    }
  }
`;

export const INVITATION_SEND_MUTATION = gql`
  mutation invitationSend($authProfileId: ID, $user: UserCreateInput!) {
    invitations {
      invitationSend(authProfileId: $authProfileId, user: $user) {
        invitationId
      }
    }
  }
`;
