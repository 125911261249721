import gql from 'graphql-tag';

export const DOWNLOAD_PDF = gql`
  mutation DownloadPdf($event: DownloadPdfInput!) {
    downloadPdf(event: $event) {
      type
      response {
        fileName
        pdf
      }
    }
  }
`;
