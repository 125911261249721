import { TableExportType } from 'src/graphql';
import { ExportParams, TableColumnType } from 'src/types';
import { UNDERWRITING_PACKAGES_REPORT, WEEKLY_BANK_ACTIVITY_REPORT } from 'src/constants';
import { formatExportData, getPortfolioMonitoringColumns } from 'src/utils/export';

export const getExportParams = (
  exportType: TableExportType,
  initialData: ExportParams,
): ExportParams | null => {
  switch (exportType) {
    case TableExportType.PortfolioMonitoring: {
      const { columns } = getPortfolioMonitoringColumns(initialData.columns);

      return {
        columns,
        rows: initialData.rows,
        reportType: initialData.reportType,
      };
    }

    case TableExportType.ScoreAndMetrics: {
      const { columns } = formatExportData({
        columns: initialData.columns,
      });

      return {
        columns,
        rows: initialData.rows,
      };
    }

    case TableExportType.UnderwritingPackage: {
      const underwritingColumns = formatExportData({
        columns: initialData.columns.underwritingColumns,
      });

      const weeklyColumns = formatExportData({
        columns: initialData.columns.weeklyColumns,
      });

      return {
        metrics: {
          underwritingMetricsList: UNDERWRITING_PACKAGES_REPORT,
          weeklyMetricsList: WEEKLY_BANK_ACTIVITY_REPORT,
        },
        columns: { underwritingColumns, weeklyColumns },
        rows: initialData.rows,
      };
    }

    case TableExportType.LoanApplications:
    case TableExportType.PortfolioCompanies: {
      const { columns } = formatExportData({
        columns: initialData.columns,
      });

      return {
        columns,
        dataEntry: initialData.dataEntry,
      };
    }

    case TableExportType.CashMonitoring:
    case TableExportType.CovenantTracking:
    case TableExportType.CovenantTrackingPortfolio: {
      const columns = initialData.columns.map((col: TableColumnType) => col?.exportData);

      return {
        columns,
        rows: initialData.rows,
      };
    }

    case TableExportType.PortfolioOverview: {
      return {
        columns: initialData.columns,
        rows: initialData.rows,
      };
    }

    default: {
      return null;
    }
  }
};
