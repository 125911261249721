import React from 'react';
import { getColumns } from 'src/shared/team/getColumns';
import { TableShared } from 'src/shared/table/TableShared';
import { makeStyles } from '@material-ui/core';

interface TeamTableProps {
  columnKeys: string[];
  data: any;
  actionsContext: any;
}

const useStyles = makeStyles(theme => ({
  table: {
    '& table': {
      width: '100%',
    },
    '& th, & td': {
      padding: '8px 16px',
      height: '1px',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td:first-child, & td:last-child, & th:first-child, & td:last-child': {
      padding: 0,
    },
    '& thead > tr': {
      height: 45,
    },
    '& tr': {
      height: 65,
    },
    '& th:last-child, & td:last-child': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export const TeamTable: React.FC<TeamTableProps> = ({ columnKeys, data, actionsContext }) => {
  const classes = useStyles();
  const columns = getColumns(columnKeys, {}, true, actionsContext);

  return <TableShared className={classes.table} columns={columns} data={data} emptyText="" />;
};
