import React from 'react';

import { MetricCellTier, MetricCellHeader } from 'src/components';
import { MetricValue } from 'src/graphql';

export const renderTierColumn = (row: MetricValue, _: unknown, idx: number): Record<any, any> => {
  const settings: Record<any, any> = {
    children: row,
    props: {},
  };
  switch (idx) {
    case 0: {
      settings.props.colSpan = 0;
      break;
    }
    case 1: {
      settings.children = <MetricCellHeader type="tier" />;
      settings.props.align = 'center';
      break;
    }
    default: {
      settings.children = <MetricCellTier value={row} />;
    }
  }
  return settings;
};
