import React from 'react';
import { CircularProgress, Grid, InputAdornment, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, Form } from 'react-final-form';
import { baseNumberFormat } from 'src/utils';
import { NumberField, TextField } from 'src/app-builder';
import { SubmitButton } from '.';
import { useCovenantTrackingValues, usePortfolioCompany } from 'src/hooks';
import { CovenantType } from 'src/types';

const useStyles = makeStyles(() => ({
  covenantItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px',
  },
  loading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '200px',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '25px',
  },
  thumb: {
    '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
      border: '1px grey solid',
    },
  },
}));

export const Covenants = (): JSX.Element => {
  const classes = useStyles();
  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();
  const companyId = portfolioCompany?.company?.id;
  const {
    upsertCovenantValue,
    upsertCovenantStatus,
    covenants,
    upsertLoading,
    dataLoading: covenantsLoading,
  } = useCovenantTrackingValues(companyId);

  const onUpdateCovenantStatus = async (conenantType: CovenantType) => {
    await upsertCovenantStatus(conenantType);
  };

  const loading = covenantsLoading || portfolioCompanyLoading;
  const onSubmit = async (data: any) => {
    await upsertCovenantValue(data);
  };

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid className={classes.loading}>
          <CircularProgress />
        </Grid>
      ) : (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, pristine }): React.ReactNode => (
            <Grid xs={6} item>
              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Min. Cash Position</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.MinCashPosition}
                    component={NumberField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.MinCashPosition]?.value}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.MinCashPosition]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.MinCashPosition);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Min. Cash Runway</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.MinCashRunway}
                    component={NumberField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.MinCashRunway]?.value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.MinCashRunway]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.MinCashRunway);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Cumulative Cash Receipts</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.CumulativeCashReceipts}
                    component={NumberField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.CumulativeCashReceipts]?.value}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.CumulativeCashReceipts]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.CumulativeCashReceipts);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Min. Cash as a % of Draws Taken</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.MinCashAsPercentOfDrawsTaken}
                    component={NumberField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.MinCashAsPercentOfDrawsTaken]?.value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.MinCashAsPercentOfDrawsTaken]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.MinCashAsPercentOfDrawsTaken);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Cumulative Advance Rate</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.CumulativeAdvanceRate}
                    component={NumberField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.CumulativeAdvanceRate]?.value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">x</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.CumulativeAdvanceRate]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.CumulativeAdvanceRate);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Actual vs. Expected Revenue</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.ActualVsExpectedRevenue}
                    component={NumberField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.ActualVsExpectedRevenue]?.value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    numberFormat={baseNumberFormat}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.ActualVsExpectedRevenue]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.ActualVsExpectedRevenue);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} item className={classes.covenantItem}>
                <Grid item xs={5}>
                  <Typography>Additional Covenant</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={CovenantType.AdditionalCovenant}
                    component={TextField}
                    fullWidth
                    disabled={submitting || upsertLoading}
                    initialValue={covenants?.[CovenantType.AdditionalCovenant]?.value}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    className={classes.thumb}
                    checked={covenants?.[CovenantType.AdditionalCovenant]?.isEnabled}
                    onChange={async () => {
                      onUpdateCovenantStatus(CovenantType.AdditionalCovenant);
                    }}
                    disabled={submitting || upsertLoading}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.submitButton}>
                <SubmitButton
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={pristine}
                  loading={submitting || upsertLoading}
                >
                  Set Covenants
                </SubmitButton>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Grid>
  );
};
