import React from 'react';

import { MetricValue } from 'src/graphql';

interface TierRendererProps {
  value?: MetricValue | null;
}

export const TierRenderer: React.FC<TierRendererProps> = ({ value }) => {
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
      {value?.tier || '-'}
    </div>
  );
};
