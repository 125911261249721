import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { makeStyles, Theme } from '@material-ui/core';

import { toSortVariables } from 'src/providers/SortProvider';
import { selectedRowsContext } from 'src/providers/SelectedRowsProvider';
import { selectedColumnsContext } from 'src/providers/SelectedColumnsProvider';
import { useAllowed } from 'src/app-builder';
import { usePagination, useSearch, useSort } from 'src/hooks';
import { Permission } from 'src/constants/permissions';
import {
  PORTFOLIO_COMPANIES_TABLE_QUERY,
  SortablePortfolioCompaniesListQuery,
  SortablePortfolioCompaniesListQueryVariables,
} from 'src/graphql';
import { PaginationContainer } from 'src/shared/table/PaginationContainer';
import { ToolbarContainer } from 'src/shared/table/ToolbarContainer';
import { Layout, Table, HeaderContainer, getColumns } from './index';
import { DIALOGS } from 'src/dialogs';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& th:not(:last-child), & td:not(:last-child)': {
      padding: '8px 16px',
    },
    '& td:first-child, & td:last-child, & th:first-child': {
      height: '1px',
    },
    '& th:first-child': {
      zIndex: 101,
    },
    '& th:last-child, & td:last-child': {
      padding: '0!important',
    },
    '& th': {
      height: '1px',
      zIndex: 100,
    },
    '& td': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  structureColumn: {
    whiteSpace: 'nowrap',
  },
  selectionColumn: {
    padding: '0!important',
  },
  row: {
    height: 65,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const Main: React.FC = () => {
  const classes = useStyles();

  const selectedColumns = React.useContext(selectedColumnsContext);
  const pagination = usePagination();
  const sort = useSort();
  const search = useSearch();
  const isAllowed = useAllowed();
  const { initRows } = React.useContext(selectedRowsContext);

  const canDelete = isAllowed(Permission.PortfolioCompaniesDelete);

  const variables = React.useMemo(
    () => ({
      skip: pagination.page * pagination.perPage,
      first: pagination.perPage,
      sort:
        sort?.field && sort?.order
          ? toSortVariables([{ field: sort.field, order: sort?.order }])
          : null,
      filter: {
        name: {
          contains: search?.search,
        },
      },
    }),
    [pagination, search, sort],
  );

  const { data, loading } = useQuery<
    SortablePortfolioCompaniesListQuery,
    SortablePortfolioCompaniesListQueryVariables
  >(PORTFOLIO_COMPANIES_TABLE_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const rows = React.useMemo(() => data?.tableData?.items || [], [data]);
  const count = React.useMemo(() => data?.tableData?.count || 0, [data]);
  const columns = React.useMemo(() => getColumns(selectedColumns.keys, classes, canDelete), [
    selectedColumns.keys,
    classes,
    canDelete,
  ]);

  const isFirstLoading = !data && loading;
  const areCompaniesEmpty = !loading && R.isEmpty(rows) && !search;
  const areNotSearchedCompanies = !loading && R.isEmpty(rows) && !!search?.search;

  React.useEffect(() => {
    initRows(data?.tableData?.items || [], 'id');
  }, [data, initRows]);

  const tableExportData = {
    columns,
    dataEntry: {
      query: PORTFOLIO_COMPANIES_TABLE_QUERY,
    },
  };

  return (
    <Layout
      isFirstLoading={isFirstLoading}
      loading={loading}
      areCompaniesEmpty={areCompaniesEmpty}
      areNotSearchedCompanies={areNotSearchedCompanies}
      renderHeader={<HeaderContainer tableExportData={tableExportData} />}
      renderToolbar={
        <ToolbarContainer dialog={DIALOGS.PORTFOLIO_COMPANY_DELETE_DIALOG} canDelete={canDelete} />
      }
      renderTable={<Table rows={rows} columns={columns} classes={classes} />}
      renderPagination={<PaginationContainer count={count} />}
    />
  );
};
