import React from 'react';
import * as R from 'ramda';
import { Button, Grid, LinearProgress, Typography, Theme, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from 'react-apollo';
import {
  Form,
  Field,
  TextField,
  NumberField,
  SelectField,
  FormSpy,
  useDataLoss,
} from 'src/app-builder';
import { useAllowed } from 'src/app-builder/providers';

import { useLoanApplication, useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import { COMPANIES_QUERY, COMPANIES_EDIT_MUTATION } from 'src/graphql';
import { baseNumberFormat, yearFormat, t, companyStoryLabel } from 'src/utils';
import {
  Permission,
  COUNTRIES_OPTIONS,
  STATE_OPTIONS_BY_COUNTRY,
  CAPITAL_SPEND_ON_OPTIONS,
  CAPITAL_TYPES_OPTIONS,
  FROM_WHOM_CAPITAL_RAISED_OPTIONS,
  FROM_WHOM_BORROWED,
  PRIMARY_BUSINESS_MODEL_OPTIONS,
  PRIMARY_CUSTOMER_BASE_OPTIONS,
} from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'auto',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(2),
    overflow: 'auto',
    flex: 1,
  },
}));

export const LoanApplicationSaaSStory: React.FC<any> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { setWhen } = useDataLoss();

  const isAllowed = useAllowed();

  const canChangeSaaSStory = isAllowed(Permission.LoanApplicationsEditSaaSStory);

  const { data: loanApplication } = useLoanApplication();

  const companyId = loanApplication?.company?.id as string;

  const { data, loading } = useQuery(COMPANIES_QUERY, {
    variables: {
      id: companyId,
    },
    skip: !Boolean(companyId),
  });

  const initialValues = React.useMemo(() => (loading ? {} : data?.company || {}), [data, loading]);

  const [updateCompany] = useMutation(COMPANIES_EDIT_MUTATION, {
    refetchQueries: [
      'UserCompany',
      'SortableLoanApplicationsList',
      'SortablePortfolioCompaniesList',
    ],
    awaitRefetchQueries: true,
    onCompleted: () => notification.success(t('company_update_success')),
    onError: () => notification.error(t('company_update_error')),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      const dataToUpdate = R.omit(['owner', 'facilities', 'team'], data);
      await updateCompany({ variables: { data: dataToUpdate } });

      setTimeout(form.reset);
    },
    [updateCompany],
  );

  const onCancel = React.useCallback(form => {
    setTimeout(form.reset);
  }, []);

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid className={classes.header} item alignItems="center">
        <Typography variant="subtitle1">SaaSStory</Typography>
      </Grid>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.content} item container direction="column">
          {R.isEmpty(initialValues) ? null : (
            <Form
              tableSchemaName="Companies"
              type="UPDATE"
              onSubmit={onSubmit}
              initialValues={initialValues as any}
              formatRelationToIds
            >
              {({ handleSubmit, form, submitting, pristine, values }): React.ReactNode => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1">I. Company Information</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="name"
                          label="Company Name"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="foundedYear"
                          label="Year Founded"
                          component={NumberField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          numberFormat={yearFormat}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="primaryBusinessModel"
                          label="Primary Business Model"
                          component={SelectField}
                          options={PRIMARY_BUSINESS_MODEL_OPTIONS}
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="primaryCustomerBase"
                          label="Primary Customer Base"
                          component={SelectField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          options={PRIMARY_CUSTOMER_BASE_OPTIONS}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={3}>
                        <Field
                          name="address.street1"
                          label="Street Address"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="address.city"
                          label="City"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="address.zip"
                          label="Zip Code"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name="address.country"
                          label="Country"
                          component={SelectField}
                          options={COUNTRIES_OPTIONS}
                          fullWidth
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="address.state"
                          label="State / Province"
                          component={SelectField}
                          options={R.propOr([], values?.address?.country, STATE_OPTIONS_BY_COUNTRY)}
                          fullWidth
                          disabled={
                            submitting || !canChangeSaaSStory || !Boolean(values?.address?.country)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="contactFirstName"
                          label="Contact First Name"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="contactLastName"
                          label="Contact Last Name"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="contactTitle"
                          label="Contact Title"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="contactEmail"
                          label="Contact Email"
                          component={TextField}
                          fullWidth
                          disabled={submitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">II. Company and Founder(s) Story</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="founderStory"
                          label="What is the Founder(s) story?"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                          rows={8}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="companyStory"
                          label={companyStoryLabel(values.name)}
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                          rows={8}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">III. Product/Market</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="productDescription"
                          label="Product Description"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="marketDescription"
                          label="Market Description"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="customersStrategy"
                          label="How do you get customers?"
                          component={TextField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          multiline
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">IV. Capital</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          name="capital"
                          label="How much capital are you seeking?"
                          component={NumberField}
                          fullWidth
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="capitalType"
                            label="What type of capital are you seeking?"
                            component={SelectField}
                            options={CAPITAL_TYPES_OPTIONS}
                            disabled={submitting || !canChangeSaaSStory}
                            fullWidth
                            multiple
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="capitalStrategy"
                            label="What are you planning to spend it on?"
                            component={SelectField}
                            disabled={submitting || !canChangeSaaSStory}
                            options={CAPITAL_SPEND_ON_OPTIONS}
                            fullWidth
                            multiple
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="equityCapitalAmount"
                          label="How much equity (including convertible debt) have you raised?"
                          component={NumberField}
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="equityCapitalOwner"
                          label="From whom?"
                          component={SelectField}
                          disabled={submitting || !canChangeSaaSStory}
                          options={FROM_WHOM_CAPITAL_RAISED_OPTIONS}
                          multiple
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="monthsToNextRound"
                          label="How many months to your next equity round (ideally)?"
                          component={NumberField}
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Months</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item>
                          <Field
                            name="idealRaiseAmount"
                            label="Ideal raise amount"
                            component={NumberField}
                            disabled={submitting || !canChangeSaaSStory}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            numberFormat={baseNumberFormat}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          name="borrowedDebt"
                          label="How much have you borrowed (excluding convertible debt)?"
                          component={NumberField}
                          disabled={submitting || !canChangeSaaSStory}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          numberFormat={baseNumberFormat}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="borrower"
                            label="From whom?"
                            component={SelectField}
                            disabled={submitting || !canChangeSaaSStory}
                            options={FROM_WHOM_BORROWED}
                            multiple
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item>
                          <Field
                            name="securedDebt"
                            label="How much of your debt is secured?"
                            component={NumberField}
                            disabled={submitting || !canChangeSaaSStory}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            numberFormat={baseNumberFormat}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item justify="flex-end" container spacing={2}>
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="text"
                          onClick={onCancel.bind(null, form)}
                          disabled={submitting}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <SubmitButton
                          color="secondary"
                          variant="contained"
                          disableElevation
                          type="submit"
                          disabled={pristine}
                          loading={submitting}
                        >
                          Save Changes
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <FormSpy
                    subscription={{ pristine: true }}
                    onChange={({ pristine }) => {
                      setWhen(!pristine);
                    }}
                  />
                </form>
              )}
            </Form>
          )}
        </Grid>
      )}
    </Grid>
  );
};
