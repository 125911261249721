import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { Grid, Theme, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentUser } from 'src/app-builder';
import { useClientRoles } from 'src/app-builder/providers';

import { IntegrationsList } from 'src/shared/integrations';
import { useNotification } from 'src/hooks';
import {
  COMPANIES_EDIT_MUTATION,
  CompanyIntegrationsEntityQuery,
  CompanyIntegrationsEntityQueryVariables,
  COMPANY_INTEGRATIONS_QUERY,
} from 'src/graphql';
import { AppRole } from 'src/types';
import { extractUserCompanyId, getCurrentUserRole, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  welcome: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingBottom: `${theme.spacing(6)}px !important`,
  },
}));

interface CustomerHomeIntegrationsPageProps {}

export const CustomerHomeIntegrationsPage: React.FC<CustomerHomeIntegrationsPageProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const notification = useNotification();

  const { user, loading: userLoading } = useCurrentUser();

  const companyId = extractUserCompanyId(user) as string;

  const { currentRole } = useClientRoles();
  const { isAppCustomerOwner } = getCurrentUserRole(currentRole as AppRole);

  const [updateCompany] = useMutation(COMPANIES_EDIT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UsersEntity'],
    onError: () => notification.error(t('company_update_error')),
  });

  const {
    data: integrations,
    loading: integrationsLoading,
    refetch: refetchIntegrations,
  } = useQuery<CompanyIntegrationsEntityQuery, CompanyIntegrationsEntityQueryVariables>(
    COMPANY_INTEGRATIONS_QUERY,
    {
      variables: { companyId },
      onError: () => notification.error(t('integrations_load_error')),
      skip: userLoading,
    },
  );

  const company = user?.company || null;

  const isCompanyFinalized = Boolean(company?.applicationFinalized);
  const hasPortfolioCompany = Boolean(company?.portfolioCompany);
  const showClientPortal = isAppCustomerOwner && hasPortfolioCompany && !isCompanyFinalized;

  const onNextPage = async () => {
    if (showClientPortal) {
      await updateCompany({
        variables: {
          data: {
            id: company?.id,
            applicationFinalized: true,
          },
        },
      });

      history.push('/client-portal/dashboard');
      return;
    }

    history.push('/customer/home/saas-score');
  };

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} className={classes.welcome}>
        <Typography variant="h3">Connect Your Accounts</Typography>
      </Grid>
      <IntegrationsList
        integrations={integrations}
        integrationsLoading={userLoading || integrationsLoading}
        refetchIntegrations={refetchIntegrations}
      />
      <Grid item xs={12}>
        <Link component="button" color="secondary" onClick={onNextPage}>
          <Typography style={{ textTransform: 'uppercase' }}>
            {showClientPortal ? 'Go to client portal' : 'Next Step'}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
