import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  FormControlLabel,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-apollo';
import { useModal, Form, Field, FileField, CheckboxField } from 'src/app-builder';

import { useNotification } from 'src/hooks';
import { DOCUMENT_QUERY, DOCUMENTS_EDIT_MUTATION } from 'src/graphql';
import { DIALOGS } from './constants';
import { useClientRoles } from 'src/app-builder/providers';
import { APP_ROLES } from 'src/types';
import { t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),

    '& .MuiFormControl-root:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  privateField: {
    marginTop: theme.spacing(2),
  },
}));

interface DocumentEditDialogProps {}

export const DocumentEditDialog: React.FC<DocumentEditDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, args, closeModal, openModal } = useModal(DIALOGS.DOCUMENT_EDIT_DIALOG);

  const { data, loading } = useQuery(DOCUMENT_QUERY, {
    variables: {
      id: args?.id,
    },
    skip: !open || !Boolean(args?.id),
  });

  const { currentRole } = useClientRoles();

  const isBigfootEmployee =
    currentRole?.name === APP_ROLES.appAdministrator.name ||
    currentRole?.name === APP_ROLES.appAnalyst.name;

  const initialValues = React.useMemo(() => (loading ? {} : data?.document || {}), [data, loading]);

  const [updateDocument] = useMutation(DOCUMENTS_EDIT_MUTATION, {
    onCompleted: () => notification.success(t('document_update_success')),
    onError: () => notification.error(t('document_update_error')),
  });

  const onSubmit = React.useCallback(
    async (data, form) => {
      await updateDocument({ variables: { data } });

      closeModal();

      setTimeout(form.reset);
    },
    [closeModal, updateDocument],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  if (!open || loading) {
    return null;
  }

  return (
    <Form
      tableSchemaName="Documents"
      type="UPDATE"
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle>Edit Document</DialogTitle>

          <DialogContent>
            <Field
              name="file"
              label="File"
              component={FileField}
              fullWidth
              disabled={submitting}
              sessionCache
            />

            {isBigfootEmployee && (
              <FormControlLabel
                className={classes.privateField}
                control={<Field name="private" component={CheckboxField} disabled={submitting} />}
                label="Make Private"
              />
            )}
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={() => onClose(form, pristine)} disabled={submitting}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
