import React from 'react';
import * as R from 'ramda';
import { Avatar, Box, Grid, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { User as UserType } from 'src/graphql';
import { getNameInitials, getFullName, formatDate, DateFormatPatterns } from 'src/utils';

interface UserCommentProps {
  user: UserType;
  comment: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  lessThanZero: {
    '& .MuiInputBase-input': {
      color: '#eb4336',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  info: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  date: {
    color: theme.palette.grey[500],
  },
}));

export const UserComment: React.FC<UserCommentProps> = (props: UserCommentProps) => {
  const { user, comment } = props;

  const classes = useStyles();

  if (R.isNil(user) || R.isEmpty(user) || R.isEmpty(comment)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Box className={classes.avatarContainer}>
        <Avatar src={user.avatar?.downloadUrl ?? ''}>{getNameInitials(user)}</Avatar>
        <Box className={classes.info}>
          <Typography variant="subtitle1">{getFullName(user)}</Typography>
          <Typography variant="body2" className={classes.date}>
            {formatDate(user.updatedAt, DateFormatPatterns.fullDateStartsFromMonth)}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1">{comment}</Typography>
    </Grid>
  );
};
