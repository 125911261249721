export function bytesToSize(bytes: number): string | null {
  const sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return null;
  }

  const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));

  if (i === 0) {
    return `${bytes}${sizes[i]}`;
  }

  return `${(bytes / 1024 ** i).toFixed(1)}${sizes[i]}`;
}

export function getFileExtension(type: string, isUpperCase?: boolean): string | null {
  if (!type) {
    return null;
  }

  const parts = type.split('/');
  const extension = parts[parts.length - 1];

  if (isUpperCase) {
    return `.${extension.toUpperCase()}`;
  }

  return `.${extension}`;
}

export async function downloadDataUrl(filename: string, url: string): Promise<void> {
  const data = await fetch(url);
  const blob = await data.blob();
  const a = document.createElement('a');

  a.href = URL.createObjectURL(blob);
  a.setAttribute('download', filename);
  a.click();
}

export const downloadDataBase64 = (fileName: string, base64String: string): void => {
  const downloadLink = document.createElement('a');

  downloadLink.href = base64String;
  downloadLink.download = fileName;

  downloadLink.click();
  downloadLink.remove();
};
