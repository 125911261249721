import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

import { FacilityValueCell, TotalRow } from './index';
import { Format } from 'src/constants';
import { MonitoringCompanyData, MonitoringTableRows, MonitoringTotalCells } from 'src/types';
import { DateFormatPatterns, formatDate, getMonitoringValueCellKey } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
    marginBottom: theme.spacing(4),
    ...(loading ? { opacity: 0.3 } : {}),
  }),
  monitoringTable: {
    borderCollapse: 'initial',
  },
  companyNameCell: {
    backgroundColor: '#FFF',
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '20%',
  },
  cell: {
    position: 'relative',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  alignRight: {
    textAlign: 'right',
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      right: 0,
    },
  },
  totalRow: {
    background: theme.palette.grey[200],
  },
  lessThanZero: {
    color: '#eb4336',
  },
  adjustedValueMarker: {
    position: 'absolute',
    right: 0,
    top: 0,
    border: '5px solid transparent',
    borderTop: '5px solid #295F7B',
    borderRight: '5px solid #295F7B',
  },
}));

interface MonitoringTableProps {
  tableName: string;
  dates: string[];
  reportCompanies: Record<string, MonitoringCompanyData>;
  reportValues: MonitoringTableRows;
  metricFormat?: Format;
  loading?: boolean;
}

export const MonitoringTable: React.FC<MonitoringTableProps> = ({
  tableName,
  dates,
  reportCompanies,
  reportValues,
  metricFormat = Format.Money,
  loading = false,
}) => {
  const classes = useStyles({ loading });

  const hasTotalsRow = !R.isNil(reportValues?.totals);
  const companies = R.keys(reportCompanies);

  const renderCell = (value: number | null, key: string) => (
    <FacilityValueCell metricFormat={metricFormat} value={value} key={key} />
  );

  return (
    <React.Fragment>
      <Box marginBottom={1}>
        <Typography variant="subtitle1">{tableName}</Typography>
      </Box>
      <Grid className={classes.tableWrapper} item>
        <Table className={classes.monitoringTable}>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.companyNameCell, classes.stickyLeft)}>
                Company
              </TableCell>
              {dates.map((date: string) => (
                <TableCell key={date} className={classes.alignRight}>
                  {formatDate(date, DateFormatPatterns.shortDateWithSlash)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {hasTotalsRow && (
              <TotalRow
                dates={dates}
                totals={reportValues.totals as MonitoringTotalCells}
                metricFormat={metricFormat}
              />
            )}
            {companies.map((id: string) => {
              const { companyId, companyName, portfolioCompanyId } = reportCompanies[id];

              return (
                <TableRow key={companyId}>
                  <TableCell className={clsx(classes.companyNameCell, classes.stickyLeft)}>
                    <Link
                      color="secondary"
                      component={RouterLink}
                      to={`/portfolio-companies/${portfolioCompanyId}/underwriting-package`}
                    >
                      {companyName}
                    </Link>
                  </TableCell>
                  {dates.map((date: string) => {
                    const monitoringCellKey = getMonitoringValueCellKey(companyId, date);
                    const monitoringCellValue = R.pathOr(null, [companyId, date], reportValues);

                    return renderCell(monitoringCellValue, monitoringCellKey);
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </React.Fragment>
  );
};
