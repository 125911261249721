import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { makeStyles, TextField } from '@material-ui/core';
import { fixNumberAndTruncateZeros, getFractionCount } from 'src/utils';
import { LoanMultiplierSettings } from 'src/types';

const useStyles = makeStyles(() => ({
  input: {
    display: 'block',
    height: 33,
    '& > div': {
      height: '100%',
    },
  },
  valueContainer: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface MultiplierRendererProps {
  isRevenue: boolean;
  path: Array<string | number>;
  loanMultiplierSettings: LoanMultiplierSettings;
  setIsDirty: (isDirty: boolean) => void;
  update: (isRevenue: boolean) => (path: Array<string | number>) => (value: number) => void;
}

export const MultiplierRenderer: React.FC<MultiplierRendererProps> = ({
  isRevenue,
  path,
  loanMultiplierSettings,
  setIsDirty,
  update,
}) => {
  const classes = useStyles();

  const [isActive, setActive] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!R.isNil(isActive) && !R.isNil(inputRef.current)) inputRef.current.focus();
  }, [isActive, inputRef]);

  useEffect(() => {
    const fullPath = [isRevenue ? 'revenue' : 'repayment', ...path];
    setValue(R.pathOr(null, fullPath, loanMultiplierSettings));
  }, [loanMultiplierSettings, path, isRevenue]);

  if (!loanMultiplierSettings) return null;

  const handleClick = () => setActive(true);

  const handleBlur = () => {
    setActive(false);
    if (R.isNil(value)) return;

    const numberValue = parseFloat(value) || 0;
    const valueToSet =
      getFractionCount(numberValue) > 4 ? fixNumberAndTruncateZeros(numberValue, 4) : numberValue;

    update(isRevenue)(path)(valueToSet);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^\d.-]/g, '');
    setValue(newValue);
    setIsDirty(true);
  };

  return isActive ? (
    <TextField
      value={value}
      size="small"
      variant="outlined"
      className={classes.input}
      inputRef={inputRef}
      onBlur={handleBlur}
      onChange={onChange}
    />
  ) : (
    <div className={classes.valueContainer} onClick={handleClick}>
      {value}
    </div>
  );
};
