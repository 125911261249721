import React from 'react';
import { Popover, PopoverActions } from '@material-ui/core';

import { useScoreContext } from 'src/hooks';
import { MetricCellEditForm } from 'src/components';
import { ChangedMetric } from 'src/types';
import { Metric, MetricValue } from 'src/graphql';
import { delay } from 'src/utils';

export type ActiveMetricCell = {
  el: HTMLElement | null;
  activeCell: MetricValue | null;
  activeDate: string | null;
  activeMetric: Metric | null;
};

export interface MetricCellContext {
  openEditMetricForm: (
    event: React.MouseEvent<HTMLTableCellElement>,
    cell: MetricValue | null,
    date: string | null,
    metric: Metric | null,
  ) => void;
  closeEditMetricForm: () => void;
  getActiveMetricCell: () => MetricValue | null;
  activeMetricCell: ActiveMetricCell;
}

export const MetricCellContext = React.createContext({
  openEditMetricForm: () => {},
  closeEditMetricForm: () => {},
  getActiveMetricCell: () => null,
  activeMetricCell: {
    el: null,
    activeCell: null,
    activeDate: null,
    activeMetric: null,
  },
} as MetricCellContext);

interface MetricCellProviderProps {
  children: React.ReactNode;
  companyId?: string | null;
}

export const MetricCellProvider: React.FC<MetricCellProviderProps> = ({ companyId, children }) => {
  const { updateMetric, companiesWithChange } = useScoreContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popoverAction, setPopoverAction] = React.useState<PopoverActions>();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [activeMetricCell, setActiveMetricCell] = React.useState<ActiveMetricCell>({
    el: null,
    activeCell: null,
    activeDate: null,
    activeMetric: null,
  });

  const openEditMetricForm = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    cell: MetricValue | null,
    date: string | null,
    metric: Metric | null,
  ): void => {
    setIsPopoverOpen(true);

    setActiveMetricCell({
      el: event.currentTarget,
      activeCell: cell,
      activeDate: date,
      activeMetric: metric,
    });
  };

  const closeEditMetricForm = React.useCallback(() => {
    setIsPopoverOpen(false);

    delay(300).then(() =>
      setActiveMetricCell({
        el: null,
        activeCell: null,
        activeDate: null,
        activeMetric: null,
      }),
    );
  }, [setActiveMetricCell]);

  const getActiveMetricCell = React.useCallback(() => {
    return activeMetricCell?.activeCell ?? null;
  }, [activeMetricCell]);

  const onSubmit = React.useCallback(
    (metric: ChangedMetric) => {
      return updateMetric(metric);
    },
    [updateMetric],
  );

  return (
    <MetricCellContext.Provider
      value={{
        openEditMetricForm,
        closeEditMetricForm,
        getActiveMetricCell,
        activeMetricCell,
      }}
    >
      {children}
      <Popover
        disableRestoreFocus
        open={isPopoverOpen}
        onClose={closeEditMetricForm}
        anchorEl={activeMetricCell.el}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        action={setPopoverAction as (instance: PopoverActions | null) => void}
      >
        <MetricCellEditForm
          companyId={companyId}
          cell={activeMetricCell.activeCell}
          metric={activeMetricCell.activeMetric}
          date={activeMetricCell.activeDate}
          companiesWithChange={companiesWithChange}
          onSubmit={onSubmit}
          onClose={closeEditMetricForm}
        />
      </Popover>
    </MetricCellContext.Provider>
  );
};
