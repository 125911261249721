import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TableShared } from 'src/shared/table/TableShared';
import { Merge } from 'src/types';
import { Metric } from 'src/graphql';
import { ColumnsType } from 'rc-table/es/interface';

import { ExpandIcon } from 'src/shared/metrics/TableUnderwritingReport/ExpandIcon';

interface TableProps {
  metrics: Array<Merge<Metric, { code: string; name: string }> | string>;
  columns: ColumnsType<any>;
  dates: string[];
  defaultExpandAllRows?: boolean;
}

const useStyles = makeStyles(theme => ({
  table: {
    height: 'calc(100% - 72px)',
    overflow: 'auto',
    '& th': {
      padding: 0,
    },
    '& td': {
      padding: '0!important',
      height: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& tr': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        height: 41,
      },
    },
    '& th:first-child, & td:first-child': {
      padding: 0,
      zIndex: 100,
    },
    '& .rc-table-container': {
      height: '100%',
    },
    '& .rc-table-body': {
      height: 'calc(100% - 41px)',
    },
  },
}));

export const TableUnderwritingReport: React.FC<TableProps> = React.memo(
  ({ metrics, columns, dates, defaultExpandAllRows = false }) => {
    const classes = useStyles();
    return (
      <TableShared
        className={classes.table}
        columns={columns}
        data={metrics}
        scroll={{ x: dates.length * 106 + 340, y: 'auto' }}
        expandIcon={ExpandIcon}
        defaultExpandAllRows={defaultExpandAllRows}
      />
    );
  },
);

TableUnderwritingReport.displayName = 'TableUnderwritingReport';
