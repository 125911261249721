import { gql } from '8base-react-sdk';

export const EVENT_RESOLVER_MUTATION = gql`
  mutation EventResolverMutation($event: EventResolverInput!) {
    eventResolver(event: $event) {
      type
      response
    }
  }
`;

export const EVENT_RESOLVER_QUERY = gql`
  query EventResolverQuery($event: EventResolverInput!) {
    eventResolverQuery(event: $event) {
      type
      response
    }
  }
`;
