import React from 'react';
import * as R from 'ramda';
import * as icons from '@material-ui/icons';

import { EmptyScreen } from 'src/components';
import { FiltersContainer } from './Filters/FiltersContainer';
import { TableStatesment } from 'src/shared/metrics/TableStatesment';
import { Source } from 'src/types';
import { StatementsReportType } from 'src/graphql';

type StatementsReportProps = {
  from: string | null;
  till: string | null;
  reportType: StatementsReportType;
  onChangeActiveReport: (reportType: StatementsReportType, period: string[]) => void;
  onRefetch: () => void;
  metrics: any;
  columns: any;
  dates: Array<string>;
  integrationType: Source.Xero | Source.QBO | null;
  isPlaidIntegrationConnected: boolean;
  className?: string;
};

export const StatementsReport: React.FC<StatementsReportProps> = ({
  from,
  till,
  reportType,
  onChangeActiveReport,
  onRefetch,
  metrics,
  columns,
  dates,
  integrationType,
  isPlaidIntegrationConnected,
}) => {
  return (
    <React.Fragment>
      <FiltersContainer
        from={from}
        till={till}
        activeReport={reportType}
        onChangeActiveReport={onChangeActiveReport}
        onRefetch={onRefetch}
        integrationType={integrationType}
        isPlaidIntegrationConnected={isPlaidIntegrationConnected}
      />
      {R.isEmpty(metrics) ? (
        <div style={{ margin: 'auto' }}>
          <EmptyScreen text="We couldn't find any Statements" icon={icons.InsertChart} />
        </div>
      ) : (
        <TableStatesment metrics={metrics} columns={columns} dates={dates} defaultExpandAllRows />
      )}
    </React.Fragment>
  );
};
