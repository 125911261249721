export enum KeyCode {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Delete = 'Delete',
  Backspace = 'Backspace',
  Minus = 'Minus',
  Period = 'Period',
}
