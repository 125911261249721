import React, { useContext } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';

import * as icons from '@material-ui/icons';
import { actionsContext } from './ActionsProvider';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:after': {
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.customPalette.border.table,
      content: '""',
      top: 0,
      left: 0,
    },
  },
}));

interface ActionsRendererProps {
  id?: string | null;
}

export const ActionsRenderer: React.FC<ActionsRendererProps> = ({ id }) => {
  const classes = useStyles();
  const { openActions } = useContext(actionsContext);

  return (
    <div className={classes.root}>
      <IconButton onClick={openActions(id as string)}>
        <icons.MoreVert />
      </IconButton>
    </div>
  );
};
