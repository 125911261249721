import React from 'react';
import * as R from 'ramda';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { Field, Form, SelectField } from 'src/app-builder';

import { useMetricsUpdate, useUnderwritingContext } from 'src/hooks';
import { DateRangeField, SubmitButton } from 'src/components';
import { RecalculateMetricsButton } from 'src/shared/pageScoreAndMetrics';
import { DateFormatPatterns } from 'src/utils';
import { REPORTS_OPTIONS } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  right: {
    marginLeft: 'auto',
  },
}));

interface FiltersProps {
  companyId: string;
  initialValues: { selectedReport: null; period: (string | null)[] };
  onSubmit: (values: any) => void;
}

export const Filters: React.FC<FiltersProps> = ({ companyId, onSubmit, initialValues }) => {
  const classes = useStyles();

  const {
    recalculateMetrics,
    companiesWithChange,
    hasMetricChanges,
    recalculating,
  } = useUnderwritingContext();

  const [
    updateMetrics,
    { loading: isMetricsUpdating, disabled: isUpdateDisabled },
  ] = useMetricsUpdate(companyId, recalculateMetrics);

  return (
    <Box className={classes.root}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, pristine, submitting }) => (
          <Grid
            component="form"
            onSubmit={handleSubmit}
            alignItems="center"
            spacing={2}
            container
            item
          >
            <Grid item xs={2}>
              <Field
                label="Report"
                name="selectedReport"
                component={SelectField}
                options={REPORTS_OPTIONS}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <Field
                name="period"
                label="Period"
                component={DateRangeField}
                isEqual={R.equals}
                dateFormat={DateFormatPatterns.shortDateWithSlash}
                fullWidth
              />
            </Grid>

            <Grid item>
              <SubmitButton
                size="large"
                color="secondary"
                variant="contained"
                disabled={pristine}
                loading={submitting}
              >
                Go
              </SubmitButton>
            </Grid>

            <Grid item>
              <RecalculateMetricsButton
                companyId={companyId}
                hasMetricChanges={hasMetricChanges}
                companiesWithChange={companiesWithChange}
                recalculating={recalculating}
                recalculateMetrics={recalculateMetrics}
              />
            </Grid>

            <Grid item>
              <SubmitButton
                size="large"
                color="secondary"
                variant="contained"
                disabled={isUpdateDisabled}
                loading={isMetricsUpdating}
                onClick={updateMetrics}
              >
                Update metrics
              </SubmitButton>
            </Grid>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
