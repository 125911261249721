type NotificationMessages = {
  [key: string]: string;
};

export default {
  calculate_metrics_success: 'Metrics successfully calculated',
  calculate_saas_score_components_success: 'SaaSScore components successfully calculated',
  calculate_saas_score_success: 'SaaSScore successfully calculated',
  calculate_saas_loan_options_success: 'SaaSLoan Options successfully calculated',
  calculate_metrics_error: "We couldn't calculate metrics",
  calculate_saas_score_components_error: "We couldn't calculate score components",
  calculate_saas_score_error: "We couldn't calculate SaaSScore",
  calculate_saas_loan_options_error: "We couldn't calculate SaaSLoan Options: {{errorMessage}}",
  update_metrics_from_plaid_success: 'Metrics from Plaid successfully updated',
  update_metrics_from_quick_books_success: 'Metrics from QuickBooks successfully updated',
  update_metrics_from_xero_success: 'Metrics from Xero successfully updated',
  update_metrics_from_plaid_error: "We couldn't update metrics from Plaid",
  update_metrics_from_quick_books_error: "We couldn't update metrics from QuickBooks",
  update_metrics_from_xero_error: "We couldn't update metrics from Xero",
  update_metric_settings_success: 'Metric settings successfully updated',
  update_metric_settings_error: "We couldn't update metric settings",
  upload_metrics_success: 'Metrics successfully uploaded',
  upload_metrics_error: "We couldn't upload metrics",
  integrations_load_error: "We couldn't load integrations",
  integration_connect_plaid_success: 'Plaid integration successfully connected',
  integration_connect_plaid_error: "We couldn't connect Plaid integration",
  integration_update_plaid_success: 'Plaid integration account successfully updated',
  integration_update_plaid_error: "We couldn't update Plaid integration account",
  integration_connect_quick_books_success: 'QuickBooks integration successfully connected',
  integration_connect_quick_books_error: "We couldn't connect QuickBooks integration",
  integration_connect_xero_success: 'Xero integration successfully connected',
  integration_connect_xero_error: "We couldn't connect Xero integration",
  integration_disconnect_plaid_success: 'Plaid integration successfully disconnected',
  integration_disconnect_plaid_error: "We couldn't to disconnect Plaid integration",
  integration_disconnect_quick_books_success: 'QuickBooks integration successfully disconnected',
  integration_disconnect_quick_books_error: "We couldn't to disconnect QuickBooks integration",
  integration_disconnect_xero_success: 'Xero integration successfully disconnected',
  integration_disconnect_xero_error: "We couldn't to disconnect Xero integration",
  invitation_resend_success: 'Invitation was successfully resent',
  invitation_resend_error: "We couldn't resend invitations",
  invitation_cancel_success: 'Invitation successfully canceled',
  invitation_cancel_error: "We couldn't resend invitations",
  upload_metrics_from_csv_success: 'Metrics successfully uploaded from CSV file',
  upload_metrics_from_csv_error: "We couldn't upload metrics from CSV file",
  parse_csv_success: 'CSV file successfully parsed',
  parse_csv_error: "We couldn't parse CSV file",
  reset_metric_settings_by_default_success: 'Metric settings was successfully reset by default',
  reset_metric_settings_by_default_error: "We couldn't reset metric settings by default",
  account_information_update_success: 'Account information was successfully updated',
  account_information_update_error: "We couldn't update account information",
  draw_create_success: 'Draw successfully created',
  draw_update_success: 'Draw successfully updated',
  draw_delete_success: 'Draw successfully deleted',
  draw_create_error: "We couldn't create draw",
  draw_update_error: "We couldn't update draw",
  draw_delete_error: "We couldn't delete draw",
  facility_create_success: 'Facility successfully created',
  facility_create_error: "We couldn't create facility",
  facility_update_success: 'Facility successfully updated',
  facility_update_error: "We couldn't update facility",
  facility_delete_success: 'Facility successfully deleted',
  facility_delete_error: "We couldn't delete facility",
  file_create_error: "Couldn't upload file {{filename}}",
  note_create_success: 'Note successfully created',
  note_update_success: 'Note successfully updated',
  note_delete_success: 'Note successfully deleted',
  note_create_error: "We couldn't create note",
  note_update_error: "We couldn't update note",
  note_delete_error: "We couldn't delete note",
  document_create_success: 'Document successfully created',
  document_update_success: 'Document successfully updated',
  document_delete_success: '{{formattedDocuments}} successfully deleted',
  document_create_error: "We couldn't create document",
  document_update_error: "We couldn't update document",
  document_delete_error: "We couldn't delete {{formattedDocuments}}",
  teammate_invite_success: 'Teammate successfully invited',
  teammate_update_success: 'Teammate successfully updated',
  applicant_invite_sent_success: 'Invitation has been sent',
  inbox_message_sent_success: 'Message successfully sent',
  company_update_success: 'Company successfully updated',
  company_update_error: "We couldn't update company",
  loan_application_update_success: 'Loan Application successfully updated',
  loan_application_update_error: "We couldn't update Loan Application",
  loan_application_delete_success: '{{formattedLoanApplications}} successfully deleted',
  loan_application_delete_error: "We couldn't delete {{formattedLoanApplications}}",
  loan_application_deal_team_update_success: 'Deal team was successfully updated',
  loan_application_deal_team_update_error: "We couldn't update deal team",
  loan_application_stage_update_error: "We couldn't update stage",
  loan_application_status_update_success: 'Loan Application status successfully updated',
  loan_application_status_update_error: "We couldn't update status",
  portfolio_company_create_success: 'Portfolio company was successfully created',
  portfolio_company_delete_success: '{{formattedPortfolioCompanies}} was successfully deleted',
  portfolio_company_delete_error: "We couldn't delete {{formattedPortfolioCompanies}}",
  user_delete_success: '{{formattedUsers}} was successfully deleted',
  user_update_success: 'User successfully updated',
  metric_delete_success: '{{metricName}} was successfully deleted',
  metric_delete_error: "We couldn't delete {{metricName}}",
  progress_step_update_status_error: "We couldn't update status",
  cash_monitoring_record_update_success: 'Cash Monitoring Record successfully updated',
  cash_monitoring_record_update_error: "We couldn't update Cash Monitoring Record",
  covenant_tracking_update_error: "We couldn't update Covenant Values",
  covenant_tracking_update_success: 'Covenants successfully updated',
  portfolio_overview_data_fetch_error: "We couldn't upload data of Portfolio Overview Report",
  portfolio_overview_export_data_fetch_error:
    "We couldn't upload export data of Portfolio Overview Report",
  custom_covenant_metric_create_success: 'Covenant metric successfully created',
  custom_covenant_metric_create_error: "We couldn't create covenant metric",
  custom_covenant_metric_update_success: 'Covenant metrics successfully updated',
  custom_covenant_metric_update_error: "We couldn't update covenant metric",
  custom_covenant_metric_delete_success: 'Covenant metrics successfully deleted',
  custom_covenant_metric_delete_error: "We couldn't delete covenant metric",
} as NotificationMessages;
