import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { useAllowed } from 'src/app-builder';

import { ActionsProvider, getColumns, Layout, Metrics, structureUsers, Table } from './index';
import {
  LOAN_APPLICATIONS_METRICS_QUERY,
  LOAN_APPLICATIONS_TABLE_QUERY,
  LOAN_APPLICATIONS_TABLE_USERS_QUERY,
  LoanApplicationFilter,
  SortOrder,
  TableExportType,
} from 'src/graphql';
import { selectedColumnsContext, selectedRowsContext, toSortVariables } from 'src/providers';
import { ToolbarContainer } from 'src/shared/table/ToolbarContainer';
import { PaginationContainer } from 'src/shared/table/PaginationContainer';
import { DIALOGS } from 'src/dialogs';
import { LoanApplicationStage, Permission } from 'src/constants';
import { usePagination, useSearch, useTableExport, useSort } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  table: {
    '& th:not(:last-child), & td:not(:last-child)': {
      padding: '8px 16px',
    },
    '& td:first-child, & td:last-child, & th:first-child': {
      height: '1px',
    },
    '& th:first-child': {
      zIndex: 101,
    },
    '& th:last-child, & td:last-child': {
      padding: '0!important',
    },
    '& th': {
      height: '1px',
      zIndex: 100,
    },
    '& td': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  selectionColumn: {
    padding: '0!important',
  },
  row: {
    height: 65,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actions: {
    zIndex: 100,
  },
}));

const getActivitySort = (order: SortOrder) => toSortVariables([{ field: 'status', order }]);

const buildFilterByStage = (
  stage: LoanApplicationStage,
  isOnlyActive: boolean,
): LoanApplicationFilter => {
  const stageFilter = { stage: { equals: stage } };

  if (isOnlyActive) {
    return { ...stageFilter, status: { equals: true } };
  }

  return { ...stageFilter };
};

export const Main: React.FC = () => {
  const classes = useStyles();

  const selectedColumns = React.useContext(selectedColumnsContext);
  const pagination = usePagination();
  const sort = useSort();
  const search = useSearch();
  const isAllowed = useAllowed();
  const { initRows } = React.useContext(selectedRowsContext);
  const [isOnlyActiveCompaniesCount, setIsOnlyActiveCompaniesCount] = React.useState(false);

  const canDelete = isAllowed(Permission.LoanApplicationsDelete);

  const loanApplicationMetricsVariables = {
    accountCreatedFilter: buildFilterByStage(
      LoanApplicationStage.AccountCreated,
      isOnlyActiveCompaniesCount,
    ),
    saasScoredFilter: buildFilterByStage(
      LoanApplicationStage.SaaSScored,
      isOnlyActiveCompaniesCount,
    ),
    appCompletedFilter: buildFilterByStage(
      LoanApplicationStage.AppCompleted,
      isOnlyActiveCompaniesCount,
    ),
    structurePresentedFilter: buildFilterByStage(
      LoanApplicationStage.StructurePresented,
      isOnlyActiveCompaniesCount,
    ),
    termSheetFilter: buildFilterByStage(LoanApplicationStage.TermSheet, isOnlyActiveCompaniesCount),
    underwritingFilter: buildFilterByStage(
      LoanApplicationStage.Underwriting,
      isOnlyActiveCompaniesCount,
    ),
    closingFilter: buildFilterByStage(LoanApplicationStage.Closing, isOnlyActiveCompaniesCount),
    fundedFilter: buildFilterByStage(LoanApplicationStage.Funded, isOnlyActiveCompaniesCount),
  };

  const { data: metrics, loading: metricsLoading, refetch } = useQuery(
    LOAN_APPLICATIONS_METRICS_QUERY,
    {
      variables: loanApplicationMetricsVariables,
    },
  );

  const toggleActiveFilterAndRefetch = () => {
    setIsOnlyActiveCompaniesCount(prev => !prev);
    refetch();
  };

  const variables = React.useMemo(
    () => ({
      skip: pagination.page * pagination.perPage,
      first: pagination.perPage,
      sort:
        sort?.field && sort?.order
          ? sort.field === 'status'
            ? getActivitySort(sort.order as SortOrder)
            : toSortVariables([{ field: sort.field, order: sort?.order }])
          : null,
      filter: {
        companyName: {
          contains: search?.search,
        },
      },
    }),
    [pagination, search, sort],
  );

  const { data, loading: tableLoading } = useQuery(LOAN_APPLICATIONS_TABLE_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const rows = React.useMemo(() => data?.tableData?.items || [], [data]);
  const count = React.useMemo(() => data?.tableData?.count || 0, [data]);
  const columns = React.useMemo(() => getColumns(selectedColumns.keys, classes, true), [
    selectedColumns,
    classes,
  ]);

  const tableExportData = {
    columns,
    dataEntry: {
      query: LOAN_APPLICATIONS_TABLE_QUERY,
    },
  };

  const [onExport, { loading: isExporting }] = useTableExport(
    TableExportType.LoanApplications,
    tableExportData,
  );

  const loanApplicationId = React.useMemo(() => rows.map((r: any) => r.id), [rows]);

  const { data: usersData } = useQuery(LOAN_APPLICATIONS_TABLE_USERS_QUERY, {
    skip: !data,
    variables: {
      filter: {
        loanApplications: {
          id: {
            in: loanApplicationId,
          },
        },
      },
    },
  });

  const users = React.useMemo(() => structureUsers(usersData?.tableData?.items), [usersData]);

  React.useEffect(() => {
    initRows(rows, 'id');
  }, [initRows, rows]);

  return (
    <ActionsProvider users={users} rows={rows}>
      <Layout
        onExport={onExport}
        isExporting={isExporting}
        metricsLoading={metricsLoading}
        tableLoading={tableLoading}
        isOnlyActiveCompaniesCount={isOnlyActiveCompaniesCount}
        toggleActiveFilterAndRefetch={toggleActiveFilterAndRefetch}
        toolbar={
          <ToolbarContainer canDelete={canDelete} dialog={DIALOGS.LOAN_APPLICATION_DELETE_DIALOG} />
        }
        table={<Table data={rows} columns={columns} classes={classes} />}
        metrics={<Metrics metrics={metrics} />}
        pagination={<PaginationContainer count={count} />}
      />
    </ActionsProvider>
  );
};
