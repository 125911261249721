import React from 'react';

import { Role, User } from 'src/graphql';
import { AppRoleName, APP_ROLES, APP_ROLE_NAMES_LIST } from 'src/types';

export const RolesRenderer = (item: User): JSX.Element => {
  const roles = React.useMemo(() => item?.roles?.items || [], [item]);

  const appRoles = React.useMemo(
    () => roles.filter(({ name }) => APP_ROLE_NAMES_LIST.some(appRoleName => appRoleName === name)),
    [roles],
  ) as Array<Role & { name: AppRoleName }>;

  const roleTitles = React.useMemo(
    () => appRoles.map(({ name }) => APP_ROLES[name as AppRoleName].title).join(', '),
    [appRoles],
  );

  return <div>{roleTitles}</div>;
};
