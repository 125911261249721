import * as R from 'ramda';
import { Maybe } from 'src/graphql';

export const getFullName = (
  user?: Maybe<{
    firstName?: string | null;
    lastName?: string | null;
  }>,
): string => `${user?.firstName || ''}${user?.firstName ? ' ' : ''}${user?.lastName || ''}`;

export const getNameInitials = (user: {
  firstName?: string | null;
  lastName?: string | null;
}): string => {
  return [user?.firstName, user?.lastName]
    .map(value => (!R.isNil(value) ? value.charAt(0).toUpperCase() : ''))
    .join('');
};
