import React, { memo } from 'react';
import { Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { EmptyCompaniesScreen } from './';

interface LayoutProps {
  loading: boolean;
  isFirstLoading: boolean;
  hasCompanies: boolean;
  renderHeader: React.ReactNode;
  renderTable: React.ReactNode;
  renderPagination: React.ReactNode;
  renderToolbar: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
  },
  root: {
    padding: 16,
  },
  tableWrapper: ({ loading }: { loading: boolean }): CreateCSSProperties<{ loading: boolean }> => ({
    ...(loading ? { opacity: 0.3, pointerEvents: 'none' } : {}),
  }),
}));

const Layout = memo(
  ({
    loading,
    isFirstLoading,
    hasCompanies,
    renderHeader,
    renderTable,
    renderPagination,
    renderToolbar,
  }: LayoutProps) => {
    const classes = useStyles({ loading });

    return (
      <Grid className={classes.root} container spacing={4}>
        {renderHeader}
        <Grid className={classes.main}>
          <Paper>
            {renderToolbar}
            {isFirstLoading ? (
              <LinearProgress />
            ) : (
              <React.Fragment>
                {hasCompanies ? (
                  <React.Fragment>
                    <div className={classes.tableWrapper}>{renderTable}</div>

                    {renderPagination}
                  </React.Fragment>
                ) : (
                  <EmptyCompaniesScreen />
                )}
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  },
);

Layout.displayName = 'Layout';

export { Layout };
