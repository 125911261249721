import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { convertToQueryParams } from 'src/utils';
import { Filters } from '.';

interface FiltersContainerProps {
  selectedPeriod: string[];
}

const FiltersContainer: React.FC<FiltersContainerProps> = memo(({ selectedPeriod }) => {
  const history = useHistory();

  const onChangeSelectedPeriod = async (values: any) => {
    const { period } = values;

    history.replace({
      search: convertToQueryParams({ from: period[0], till: period[1] }),
    });
  };

  return (
    <Filters selectedPeriod={selectedPeriod} onChangeSelectedPeriod={onChangeSelectedPeriod} />
  );
});

export { FiltersContainer };
