import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import { useRouterQuery, useTableExport } from 'src/hooks';
import { MonitoringFilters, MonitoringMetricTable, MonitoringTable } from './components';
import { EmptyScreen, SubmitButton } from 'src/components';
import {
  MetricCode,
  MetricPeriod,
  MonitoringCompanyData,
  MonitoringMetricData,
  MonitoringTableRows,
  MonitoringValueName,
} from 'src/types';
import {
  CalculatePortfolioMonitoringQueryVariables,
  CalculatePortfolioMonitoringQuery,
  TableExportType,
  Company,
  PORTFOLIO_MONITORING_COMPANIES_LIST,
  PORTFOLIO_MONITORING_VALUES,
} from 'src/graphql';
import { DEFAULT_COUNT_PERIOD, DEFAULT_COUNT_SELECTED_COMPANIES, Format } from 'src/constants';
import {
  convertToQueryParams,
  DateFormatPatterns,
  formatDate,
  getDateRange,
  getDateRangeBetweenTwoDates,
  getPortfolioMonitoringTableData,
  getTableNameByMetricCode,
  isValidDate,
} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  root: {
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    overflow: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: theme.spacing(2),
  },
  loader: {
    color: theme.palette.grey[400],
    marginBottom: 20,
  },
  loaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `200px 0`,
  },
}));

export const PortfolioMonitoringPage: React.FC = () => {
  const classes = useStyles();

  const query = useRouterQuery();
  const history = useHistory();

  const [hasInvalidFilters, setInvalidFilters] = React.useState<boolean>(false);

  const from = R.pathOr(null, ['from'], query);
  const till = R.pathOr(null, ['till'], query);

  const view = R.pathOr(MetricPeriod.Month, ['view'], query);

  const dates = React.useMemo(() => {
    const defaultPeriod =
      view !== MetricPeriod.Month ? DEFAULT_COUNT_PERIOD * 4 : DEFAULT_COUNT_PERIOD;

    const defaultDateRange = getDateRange(defaultPeriod, view);
    const datesRange = getDateRangeBetweenTwoDates(from || '', till || '', view);

    return datesRange.length ? datesRange : defaultDateRange;
  }, [from, till, view]);

  const { data, loading: loadingCompanyList } = useQuery(PORTFOLIO_MONITORING_COMPANIES_LIST);

  const allCompanies = React.useMemo(() => {
    const getItems = R.pathOr([], ['portfolioCompaniesList', 'items']);
    const getCompany = R.map((item: Company) => R.pathOr({}, ['company'], item));

    return R.pipe(getItems, getCompany)(data);
  }, [data]);

  const selectedCompanies = R.pathOr<string[]>([], ['selectedCompanies'], query);

  const selectedCompaniesAsArray: string[] = React.useMemo(() => {
    return Array.isArray(selectedCompanies) ? selectedCompanies : [selectedCompanies];
  }, [selectedCompanies]);

  const defaultSelectedCompanies: Company[] = React.useMemo(() => {
    return R.take(DEFAULT_COUNT_SELECTED_COMPANIES, allCompanies);
  }, [allCompanies]);

  const [isChangedCompanies, setIsChangedCompanies] = React.useState(false);

  React.useEffect(() => {
    if (
      R.isEmpty(selectedCompanies) &&
      !isChangedCompanies &&
      !R.isEmpty(defaultSelectedCompanies)
    ) {
      history.replace({
        search: convertToQueryParams({
          ...query,
          selectedCompanies: R.map(
            (company: Company) => company?.portfolioCompany?.id,
            defaultSelectedCompanies,
          ),
        }),
      });
    }
  }, [query, history, selectedCompanies, defaultSelectedCompanies, isChangedCompanies]);

  const variables = React.useMemo(
    () => ({
      companiesList: selectedCompanies,
      dates,
      period: view,
    }),
    [dates, selectedCompanies, view],
  );

  const { data: monitoringValues, loading: loadingTableData } = useQuery<
    CalculatePortfolioMonitoringQuery,
    CalculatePortfolioMonitoringQueryVariables
  >(PORTFOLIO_MONITORING_VALUES, {
    variables,
    skip: !selectedCompanies.length || hasInvalidFilters,
    fetchPolicy: 'no-cache',
  });

  const monitoringData = getPortfolioMonitoringTableData(
    R.pathOr(null, ['calculatePortfolioMonitoring', 'monitoringData'], monitoringValues),
  );

  const companies = monitoringData?.companies as Record<string, MonitoringCompanyData>;
  const values = monitoringData?.values as Record<string, MonitoringTableRows>;
  const metrics = monitoringData?.metrics as Record<string, MonitoringMetricData>;

  const visibleMonitoringTables = React.useMemo(() => view === MetricPeriod.Month, [view]);

  const areEmptyCompanies = !loadingCompanyList && !loadingTableData && R.isNil(monitoringData);

  const emptyScreen = React.useMemo(() => {
    const emptyScreenText = R.isEmpty(selectedCompaniesAsArray)
      ? 'Select Companies'
      : 'We could not find any companies';

    return <EmptyScreen icon={icons.Group} text={emptyScreenText} />;
  }, [selectedCompaniesAsArray]);

  const onChangePeriod = React.useCallback(
    (date: string | null | undefined, period: string, limitDate: string) => {
      const dateISO = formatDate(
        date,
        DateFormatPatterns.shortDateWithDash,
        DateFormatPatterns.shortDateWithSlash,
      );

      if (isValidDate(dateISO)) {
        history.replace({
          search: convertToQueryParams({
            ...query,
            [period]: dateISO,
          }),
        });
      }
    },
    [history, query],
  );

  const onSelectCompanies = React.useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: string | unknown;
      }>,
    ) => {
      const {
        target: { value: options },
      } = event;

      setIsChangedCompanies(true);
      history.replace({
        search: convertToQueryParams({
          ...query,
          selectedCompanies: options as string,
        }),
      });
    },
    [history, query, setIsChangedCompanies],
  );

  const onChangeView = React.useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: string | unknown;
      }>,
    ) => {
      const {
        target: { value: view },
      } = event;

      history.replace({
        search: convertToQueryParams({
          ...query,
          view,
        }),
      });
    },
    [history, query],
  );

  const [onExport, { loading: isExporting }] = useTableExport(TableExportType.PortfolioMonitoring, {
    columns: dates,
    rows: monitoringData,
    reportType: view,
  });

  const isExportDisabled = loadingCompanyList || areEmptyCompanies;

  if (loadingCompanyList) {
    return <LinearProgress />;
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <Grid container item justify="space-between" alignItems="center" direction="row" spacing={0}>
        <Typography variant="h4">Portfolio Monitoring</Typography>
      </Grid>

      <Grid container item>
        <Paper className={classes.paper}>
          <Grid className={classes.header} justify="space-between" container item>
            <Grid item>
              <Typography variant="subtitle1">Portfolio Monitoring</Typography>
            </Grid>

            <Grid item>
              <SubmitButton
                onClick={onExport}
                color="secondary"
                variant="outlined"
                size="medium"
                startIcon={<icons.GetApp />}
                loading={isExporting}
                disabled={isExportDisabled}
              >
                Export
              </SubmitButton>
            </Grid>
          </Grid>

          <MonitoringFilters
            selectedCompanies={selectedCompaniesAsArray}
            view={view}
            companyList={allCompanies}
            from={from}
            till={till}
            onSelectCompanies={onSelectCompanies}
            onChangePeriod={onChangePeriod}
            onChangeView={onChangeView}
            setInvalidFilters={setInvalidFilters}
          />

          {loadingTableData ? (
            <Box className={classes.loaderWrapper}>
              <CircularProgress className={classes.loader} />
              <Typography color="textSecondary" variant="body1" align="center">
                Loading...
              </Typography>
            </Box>
          ) : areEmptyCompanies ? (
            <Box padding={8}>{emptyScreen}</Box>
          ) : (
            <Box padding={2}>
              {visibleMonitoringTables && (
                <React.Fragment>
                  <MonitoringTable
                    tableName="$ Committed"
                    dates={dates}
                    reportCompanies={companies}
                    reportValues={values[MonitoringValueName.Committed]}
                    loading={loadingTableData}
                  />

                  <MonitoringTable
                    tableName="$ Deployed"
                    dates={dates}
                    reportCompanies={companies}
                    reportValues={values[MonitoringValueName.Deployed]}
                    loading={loadingTableData}
                  />

                  <MonitoringTable
                    tableName="Advance Rate"
                    dates={dates}
                    reportCompanies={companies}
                    reportValues={values[MonitoringValueName.AdvanceRate]}
                    metricFormat={Format.Ratio}
                    loading={loadingTableData}
                  />
                </React.Fragment>
              )}

              {R.keys(metrics).map(code => (
                <MonitoringMetricTable
                  key={code}
                  tableName={getTableNameByMetricCode(code as MetricCode)}
                  dates={dates}
                  metricCode={code}
                  reportMetrics={metrics}
                  reportValues={values[code]}
                  reportCompanies={companies}
                  loading={loadingTableData}
                />
              ))}
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
