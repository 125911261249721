import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as TierBadge } from 'src/assets/icons/tier-badge.svg';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    fill: theme.palette.secondary.light,
    width: 60,
    height: 60,
  },
  tier: {
    position: 'relative',
  },
  tierInner: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '45%',
    textAlign: 'center',
  },
  tierValue: {
    fontSize: 32,
    fontWeight: 100,
    lineHeight: 1,
  },
  tierSubtitle: {
    fontSize: 8,
    fontWeight: 600,
    lineHeight: 1,
    textTransform: 'uppercase',
  },
}));

interface TierProps {
  value: number | string;
}

export const Tier: React.FC<TierProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.tier}>
      <TierBadge className={classes.badge} />
      <div className={classes.tierInner}>
        <Typography className={classes.tierValue}>{props.value}</Typography>
        <Typography className={classes.tierSubtitle}>Tier</Typography>
      </div>
    </div>
  );
};
