import React from 'react';
import { useQuery } from 'react-apollo';
import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IntegrationsList } from 'src/shared';
import { useCurrentUser } from 'src/app-builder';
import { useNotification } from 'src/hooks';
import {
  COMPANY_INTEGRATIONS_QUERY,
  CompanyIntegrationsEntityQuery,
  CompanyIntegrationsEntityQueryVariables,
} from 'src/graphql';
import { extractUserCompanyId, t } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.spacing(2),
    textAlign: 'center',
    overflow: 'hidden',
    gap: theme.spacing(3),
  },
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface ClientPortalSettingsIntegrationsPageProps {}

export const ClientPortalSettingsIntegrationsPage: React.FC<ClientPortalSettingsIntegrationsPageProps> = () => {
  const classes = useStyles();
  const notification = useNotification();

  const { user, loading: userLoading } = useCurrentUser();

  const companyId = extractUserCompanyId(user) as string;

  const {
    data: integrations,
    loading: integrationsLoading,
    refetch: refetchIntegrations,
  } = useQuery<CompanyIntegrationsEntityQuery, CompanyIntegrationsEntityQueryVariables>(
    COMPANY_INTEGRATIONS_QUERY,
    {
      variables: { companyId },
      onError: () => notification.error(t('integrations_load_error')),
      skip: userLoading,
    },
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Paper className={classes.paper}>
        <Grid className={classes.header} item>
          <Typography variant="subtitle1">Integrations</Typography>
        </Grid>
        <Grid className={classes.content} item container justify="center">
          <IntegrationsList
            integrations={integrations}
            integrationsLoading={userLoading || integrationsLoading}
            refetchIntegrations={refetchIntegrations}
            darkShadow
          />
        </Grid>
      </Paper>
    </Grid>
  );
};
