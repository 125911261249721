import { SYSTEM_ROLES, AppRole } from 'src/types';

type GetUserRolesResult = {
  isAppAdministrator: boolean;
  isAppAnalyst: boolean;
  isAppCustomer: boolean;
  isAppCustomerOwner: boolean;
  isBigfootTeam: boolean;
};

export const getCurrentUserRole = (currentAppRole: AppRole): GetUserRolesResult => {
  const roleName = currentAppRole?.name;

  const isAppAdministrator = roleName === SYSTEM_ROLES.appAdministrator;

  const isAppAnalyst = roleName === SYSTEM_ROLES.appAnalyst;

  const isAppCustomer = roleName === SYSTEM_ROLES.appCustomer;

  const isAppCustomerOwner = roleName === SYSTEM_ROLES.appCustomerOwner;

  const isBigfootTeam = isAppAdministrator || isAppAnalyst;

  return {
    isAppAdministrator,
    isAppAnalyst,
    isAppCustomer,
    isAppCustomerOwner,
    isBigfootTeam,
  };
};
