import React from 'react';
import { Popover, PopoverActions } from '@material-ui/core';

import { useScoreContext } from 'src/hooks';
import { MetricCellEditForm } from 'src/components';
import { ChangedMetric } from 'src/types';
import { Metric, MetricValue } from 'src/graphql';
import { delay } from 'src/utils';

type Actions = {
  el: HTMLElement | null;
  activeCell: MetricValue | null;
  activeDate: string | null;
  activeMetric: Metric | null;
};

interface ActionsContext {
  openActions: (
    cell: MetricValue | null,
    date: string | null,
    metric: Metric | null,
  ) => (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  actions: Actions;
  closeActions: () => void;
}

export const actionsContext = React.createContext({
  openActions: () => (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {},
  actions: {
    el: null,
    activeCell: null,
    activeDate: null,
    activeMetric: null,
  },
  closeActions: () => {},
} as ActionsContext);

interface ActionsProviderProps {
  children: React.ReactNode;
  companyId?: string | null;
}

export const ActionsProvider: React.FC<ActionsProviderProps> = ({ companyId, children }) => {
  const { updateMetric, companiesWithChange } = useScoreContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popoverAction, setPopoverAction] = React.useState<PopoverActions>();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [actions, setActions] = React.useState<Actions>({
    el: null,
    activeCell: null,
    activeDate: null,
    activeMetric: null,
  });

  const openActions = (cell: MetricValue | null, date: string | null, metric: Metric | null) => (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  ): void => {
    setIsPopoverOpen(true);
    setActions({
      el: event.currentTarget,
      activeCell: cell,
      activeDate: date,
      activeMetric: metric,
    });
  };

  const closeActions = React.useCallback(() => {
    setIsPopoverOpen(false);
    delay(300).then(() =>
      setActions({
        el: null,
        activeCell: null,
        activeDate: null,
        activeMetric: null,
      }),
    );
  }, [setActions]);

  const onSubmit = (metric: ChangedMetric) => {
    updateMetric(metric);
  };
return (
    <actionsContext.Provider
      value={{
        openActions,
        actions,
        closeActions,
      }}
    >
      {children}
      <Popover
        disableRestoreFocus
        open={isPopoverOpen}
        onClose={closeActions}
        anchorEl={actions.el}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        action={setPopoverAction as (instance: PopoverActions | null) => void}
      >
        <MetricCellEditForm
          companyId={companyId}
          cell={actions.activeCell}
          metric={actions.activeMetric}
          date={actions.activeDate}
          onClose={closeActions}
          onSubmit={onSubmit}
          companiesWithChange={companiesWithChange}
        />
      </Popover>
    </actionsContext.Provider>
  );
};
