import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { usePortfolioCompany } from 'src/hooks';
import { Dashboard } from 'src/components';
import { Company } from 'src/graphql';
import { DEFAULT_COUNT_MONTHS } from 'src/constants';
import { getDateRange } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const PortfolioCompanyDashboardPage: React.FC = () => {
  const classes = useStyles();

  const { data: portfolioCompany, loading: portfolioCompanyLoading } = usePortfolioCompany();

  const company = portfolioCompany?.company;
  const period = getDateRange(DEFAULT_COUNT_MONTHS);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid className={classes.header} item alignItems="center">
        <Typography variant="subtitle1">Dashboard</Typography>
      </Grid>
      <Dashboard
        company={company as Company}
        period={period}
        oneColumn={false}
        loading={portfolioCompanyLoading}
      />
    </Grid>
  );
};
