import _ from 'lodash';
import * as R from 'ramda';
import { COMPLIANCE_NO, COMPLIANCE_YES, Format } from 'src/constants';
import { METRICS_FOR_COVENANTS } from 'src/constants/covenants';

import {
  MetricValue,
  Covenant,
  CovenantListQuery,
  CovenantValue,
  CovenantValuesListQuery,
  PortfolioMonitoringValues,
} from 'src/graphql';
import { CustomCovenantData } from 'src/hooks/useCustomCovenants';
import {
  CovenantRowType,
  CovenantTableType,
  CovenantTitle,
  MetricCode,
  TotalDeployedMapByDate,
} from 'src/types';
import {
  getAdjustedOrOriginalMetricValue,
  getFormattedMetricValue,
  getMonth,
  getYear,
  replaceIfNil,
} from 'src/utils';
import { convertToPercentage, subtractNumbers } from '../number';
import {
  calculateActualValue,
  calculateDifferenceValue,
  calculateInCompliance,
} from './calculations';

export type CovenantForFormType = {
  [key: string]: {
    value: string | undefined | null;
    covenantId: string | undefined | null;
    covenantValueId: string | undefined | null;
    isEnabled: boolean | undefined | null;
  };
};

export const convertCovenantDataForForm = (
  covenantsData: CovenantListQuery | undefined,
  covenantValuesData: CovenantValuesListQuery,
): CovenantForFormType => {
  const covenantValues: Array<CovenantValue> = covenantValuesData?.covenantValuesList?.items;
  const covenants: Array<Covenant> = covenantsData?.covenantsList?.items || [];

  const convertedCovenants = covenants.reduce<CovenantForFormType>(
    (previousCovenant, currentCovenant) => {
      const covenantValue = covenantValues.find(covenantValue => {
        return covenantValue?.covenant?.name === currentCovenant.name;
      });

      return {
        ...previousCovenant,
        [currentCovenant.name as string]: {
          value: covenantValue?.value,
          covenantId: currentCovenant.id,
          covenantValueId: covenantValue?.id,
          isEnabled: covenantValue?.isEnabled,
        },
      };
    },
    {},
  );

  return convertedCovenants;
};

export const formatCovenantValue = (
  covenantTableType: CovenantTableType,
  covenantType: CovenantRowType,
  value: any,
  format: Format,
) => {
  switch (covenantType) {
    case CovenantRowType.InCompliance: {
      if (R.isNil(value)) {
        return '-';
      } else if (_.isBoolean(value)) {
        return value ? COMPLIANCE_YES : COMPLIANCE_NO;
      }

      return value;
    }

    default: {
      if (covenantTableType === CovenantTableType.AdditionalCovenant) {
        return replaceIfNil(value, '-');
      }

      if (format === Format.Percent && !R.isNil(value)) {
        value = value / 100;
      }

      return !R.isNil(value) ? getFormattedMetricValue(value, format) : '–';
    }
  }
};

export const mapTotalDeployedByDate = (
  monitoringValues: PortfolioMonitoringValues[],
): TotalDeployedMapByDate => {
  return monitoringValues.reduce(
    (deployedMap, monitoringValue) => ({
      ...deployedMap,
      [monitoringValue.date]: monitoringValue?.deployed ?? null,
    }),
    {} as TotalDeployedMapByDate,
  );
};

const buildCovenantRow = (
  covenantName: CovenantTableType,
  underwritingMetricValues: any[],
  covenantValue: any,
) => {
  const covenantRow = underwritingMetricValues.reduce((accumulator: any, currentValue: any) => {
    const metricValue = currentValue.metrics.items.find((metricValue: any) => {
      return METRICS_FOR_COVENANTS[covenantName]?.includes(metricValue.metric.code);
    });

    const actualValue = getAdjustedOrOriginalMetricValue(metricValue);
    const difference = calculateDifferenceValue(actualValue, covenantValue?.value);
    const inCompliance = calculateInCompliance(actualValue, covenantValue?.value);

    return {
      ...accumulator,
      [currentValue.date]: {
        value: actualValue,
        difference,
        inCompliance,
      },
    };
  }, {});

  return covenantRow;
};

const buildWeeklyCovenantRow = (
  covenantName: CovenantTableType,
  weeklyMetricValues: any[],
  underwritingMetricValues: any[],
  covenantValue: any,
) => {
  const covenantRow = underwritingMetricValues.reduce((accumulator: any, currentValue: any) => {
    const filteredWeeklyMetricValues = weeklyMetricValues?.filter(weeklyMetric => {
      const isSameMonth =
        getMonth(weeklyMetric.date, 'number') === getMonth(currentValue.date, 'number');
      const isSameYear = getYear(weeklyMetric.date) === getYear(currentValue.date);

      return isSameMonth && isSameYear;
    });

    const unsortedMetricValues = filteredWeeklyMetricValues?.map(filteredWeeklyMetricValue => {
      const metricValue = filteredWeeklyMetricValue.metrics.items.find(
        (filteredWeeklyMetric: any) => {
          return METRICS_FOR_COVENANTS[covenantName]?.includes(filteredWeeklyMetric.metric.code);
        },
      );
      return getAdjustedOrOriginalMetricValue(metricValue);
    });

    const sortedMetricValues = unsortedMetricValues?.sort((metricValue1, metricValue2) => {
      if (R.isNil(metricValue1) && R.isNil(metricValue2)) {
        return 0;
      }
      return (metricValue1 as number) - (metricValue2 as number);
    });

    const actualValue = sortedMetricValues?.[0];
    const difference = calculateDifferenceValue(actualValue, covenantValue?.value);
    const inCompliance = calculateInCompliance(actualValue, covenantValue?.value);

    return {
      ...accumulator,
      [currentValue.date]: {
        value: actualValue,
        difference,
        inCompliance,
      },
    };
  }, {});
  return covenantRow;
};

const buildCustomCovenantRow = (customCovenantValues: any, covenantValue: any) => {
  const covenantRow = customCovenantValues.reduce((accumulator: any, customCovenantValue: any) => {
    if (customCovenantValue.covenant.id !== covenantValue.covenant.id) {
      return { ...accumulator };
    }

    const actualValue = customCovenantValue.actual;
    const difference = calculateDifferenceValue(actualValue, covenantValue?.value);
    const inCompliance = difference ? difference >= 0 : null;
    return {
      ...accumulator,
      [customCovenantValue.date]: { value: actualValue, difference, inCompliance },
    };
  }, {});
  return covenantRow;
};

export const getCovenantRow = (
  covenantName: string,
  covenantValues: any,
  underwritingMetricValues: any[],
  weeklyMetricValues: any,
  customCovenantValues: any,
  totalDeployedMap?: TotalDeployedMapByDate,
): Array<Record<string, any> | null> => {
  const covenantValue = covenantValues.find((covenantValue: any) => {
    return covenantName.includes(covenantValue.covenant.name);
  });
  const covenantId = covenantValue?.covenant?.id;

  if (!covenantValue && covenantName !== CovenantTableType.AdjustedNetCashBurnL3MCovenant) {
    return [];
  }

  let covenantRow = null;
  let name = null;
  let covenant = null;
  let actual = null;
  let difference = null;
  let inCompliance = null;

  switch (covenantName) {
    case CovenantTableType.AdjustedNetCashBurnL3MCovenant:
      covenantRow = buildCovenantRow(covenantName, underwritingMetricValues, covenantValue);
      name = {
        covenantTableType: CovenantTableType.AdjustedNetCashBurnL3MCovenant,
        name: CovenantTitle.AdjustedNetCashBurnL3MCovenant,
      };
      actual = {
        covenantTableType: CovenantTableType.AdjustedNetCashBurnL3MCovenant,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Money,
      };
      return [name, actual];

    case CovenantTableType.MinCashPositionMonthly:
      covenantRow = buildCovenantRow(covenantName, underwritingMetricValues, covenantValue);
      name = {
        covenantTableType: CovenantTableType.MinCashPositionMonthly,
        name: CovenantTitle.MinCashPositionMonthly,
      };
      covenant = {
        covenantTableType: CovenantTableType.MinCashPositionMonthly,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Money,
      };
      actual = {
        covenantTableType: CovenantTableType.MinCashPositionMonthly,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Money,
      };
      difference = {
        covenantTableType: CovenantTableType.MinCashPositionMonthly,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Money,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.MinCashPositionMonthly,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.MinCashRunwayMonthly:
      covenantRow = underwritingMetricValues.reduce((accumulator: any, currentValue: any) => {
        const metricValues = currentValue.metrics.items.reduce(
          (acc: Record<string, MetricValue>, metricValue: MetricValue) => {
            const metricCode = metricValue?.metric?.code;

            if (!R.isNil(metricCode)) {
              const isCashRunwayMetric = METRICS_FOR_COVENANTS[covenantName]?.includes(
                metricCode as MetricCode,
              );

              return {
                ...acc,
                ...(isCashRunwayMetric ? { [metricCode]: metricValue } : null),
              };
            }

            return acc;
          },
          {} as Record<string, MetricValue>,
        );

        const {
          [MetricCode.CashPositionPlaid]: cashPositionBankingMetric,
          [MetricCode.AdjustedNetCashBurnL3M]: adjustedNetCashBurnL3MMetric,
        } = metricValues;

        const adjustedNetCashBurnL3MValue = getAdjustedOrOriginalMetricValue(
          adjustedNetCashBurnL3MMetric,
        );
        const cashPositionBankingValue = getAdjustedOrOriginalMetricValue(
          cashPositionBankingMetric,
        );

        const actualValue = calculateActualValue(
          cashPositionBankingValue,
          adjustedNetCashBurnL3MValue,
        );
        const actualValueNegated = !R.isNil(actualValue) ? -actualValue : null;

        const difference = calculateDifferenceValue(actualValueNegated, covenantValue?.value);

        let inCompliance = null;

        if (!R.isNil(actualValueNegated) && !R.isNil(covenantValue?.value)) {
          inCompliance = R.gte(actualValueNegated, covenantValue?.value) || actualValueNegated < 0;
        }

        return {
          ...accumulator,
          [currentValue.date]: {
            value: actualValueNegated,
            difference,
            inCompliance,
          },
        };
      }, {});
      name = {
        covenantTableType: CovenantTableType.MinCashRunwayMonthly,
        name: CovenantTitle.MinCashRunwayMonthly,
      };
      covenant = {
        covenantTableType: CovenantTableType.MinCashRunwayMonthly,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Month,
      };
      actual = {
        covenantTableType: CovenantTableType.MinCashRunwayMonthly,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Month,
      };
      difference = {
        covenantTableType: CovenantTableType.MinCashRunwayMonthly,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Month,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.MinCashRunwayMonthly,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.MinCashPositionWeekly:
      covenantRow = buildWeeklyCovenantRow(
        covenantName,
        weeklyMetricValues,
        underwritingMetricValues,
        covenantValue,
      );
      name = {
        covenantTableType: CovenantTableType.MinCashPositionWeekly,
        name: CovenantTitle.MinCashPositionWeekly,
      };
      covenant = {
        covenantTableType: CovenantTableType.MinCashPositionWeekly,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Money,
      };
      actual = {
        covenantTableType: CovenantTableType.MinCashPositionWeekly,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Money,
      };
      difference = {
        covenantTableType: CovenantTableType.MinCashPositionWeekly,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Money,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.MinCashPositionWeekly,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.MinCashRunwayWeekly:
      covenantRow = underwritingMetricValues.reduce((accumulator: any, currentValue: any) => {
        const filteredWeeklyMetricValues = weeklyMetricValues.filter((weeklyMetric: any) => {
          const isSameMonth =
            getMonth(weeklyMetric.date, 'number') === getMonth(currentValue.date, 'number');
          const isSameYear = getYear(weeklyMetric.date) === getYear(currentValue.date);

          return isSameMonth && isSameYear;
        });

        const unsortedMetricValues = filteredWeeklyMetricValues.map(
          (filteredWeeklyMetricValue: any) => {
            const metricValue = filteredWeeklyMetricValue.metrics.items.find(
              (filteredWeeklyMetric: any) => {
                return METRICS_FOR_COVENANTS[covenantName]?.includes(
                  filteredWeeklyMetric.metric.code,
                );
              },
            );
            return getAdjustedOrOriginalMetricValue(metricValue);
          },
        );

        const sortedMetricValues = unsortedMetricValues.sort(
          (metricValue1: number | null | undefined, metricValue2: number | null | undefined) => {
            if (R.isNil(metricValue1) && R.isNil(metricValue2)) {
              return 0;
            }
            return (metricValue1 as number) - (metricValue2 as number);
          },
        );

        const adjustedNetCashBurnL3MMetric = currentValue.metrics.items.find((metricValue: any) => {
          return metricValue.metric.code === MetricCode.AdjustedNetCashBurnL3M;
        });

        const adjustedNetCashBurnL3MValue = getAdjustedOrOriginalMetricValue(
          adjustedNetCashBurnL3MMetric,
        );

        const weeklyCashPosition = sortedMetricValues?.[0];

        const actualValue = calculateActualValue(weeklyCashPosition, adjustedNetCashBurnL3MValue);
        const actualValueNegated = !R.isNil(actualValue) ? -actualValue : null;

        const difference = calculateDifferenceValue(actualValueNegated, covenantValue?.value);

        let inCompliance = null;

        if (!R.isNil(actualValueNegated) && !R.isNil(covenantValue?.value)) {
          inCompliance = R.gte(actualValueNegated, covenantValue?.value) || actualValueNegated < 0;
        }

        return {
          ...accumulator,
          [currentValue.date]: {
            value: actualValueNegated,
            difference,
            inCompliance,
          },
        };
      }, {});
      name = {
        covenantTableType: CovenantTableType.MinCashRunwayWeekly,
        name: CovenantTitle.MinCashRunwayWeekly,
      };
      covenant = {
        covenantTableType: CovenantTableType.MinCashRunwayWeekly,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Month,
      };
      actual = {
        covenantTableType: CovenantTableType.MinCashRunwayWeekly,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Month,
      };
      difference = {
        covenantTableType: CovenantTableType.MinCashRunwayWeekly,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Month,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.MinCashRunwayWeekly,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.CumulativeCashReceipts:
      covenantRow = buildCovenantRow(covenantName, underwritingMetricValues, covenantValue);
      name = {
        covenantTableType: CovenantTableType.CumulativeCashReceipts,
        name: CovenantTitle.CumulativeCashReceipts,
      };
      covenant = {
        covenantTableType: CovenantTableType.CumulativeCashReceipts,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Money,
      };
      actual = {
        covenantTableType: CovenantTableType.CumulativeCashReceipts,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Money,
      };
      difference = {
        covenantTableType: CovenantTableType.CumulativeCashReceipts,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Money,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.CumulativeCashReceipts,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.MinCashAsPercentOfDrawsTaken:
      covenantRow = underwritingMetricValues.reduce((accumulator: any, currentValue: any) => {
        const date = currentValue?.date;

        const metricValue = currentValue.metrics.items.find((metricValue: any) => {
          return METRICS_FOR_COVENANTS[covenantName]?.includes(metricValue.metric.code);
        });

        const cashPositionBankingValue = getAdjustedOrOriginalMetricValue(metricValue);
        const totalAmountDeployed = totalDeployedMap?.[date];

        const actualValue = calculateActualValue(cashPositionBankingValue, totalAmountDeployed, 3);
        const actualValuePercentage = convertToPercentage(actualValue);

        const difference = calculateDifferenceValue(
          actualValuePercentage,
          covenantValue?.value,
          (actualValue, covenantValue) =>
            subtractNumbers(actualValue as number, covenantValue as number, 1),
        );
        const inCompliance = calculateInCompliance(actualValuePercentage, covenantValue?.value);

        return {
          ...accumulator,
          [date]: {
            value: actualValuePercentage,
            difference,
            inCompliance,
          },
        };
      }, {});
      name = {
        covenantTableType: CovenantTableType.MinCashAsPercentOfDrawsTaken,
        name: CovenantTitle.MinCashAsPercentOfDrawsTaken,
      };
      covenant = {
        covenantTableType: CovenantTableType.MinCashAsPercentOfDrawsTaken,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Percent,
      };
      actual = {
        covenantTableType: CovenantTableType.MinCashAsPercentOfDrawsTaken,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Percent,
      };
      difference = {
        covenantTableType: CovenantTableType.MinCashAsPercentOfDrawsTaken,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Percent,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.MinCashAsPercentOfDrawsTaken,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.CumulativeAdvanceRate:
      covenantRow = customCovenantValues.reduce((accumulator: any, customCovenantValue: any) => {
        if (customCovenantValue.covenant.id !== covenantValue.covenant.id) {
          return { ...accumulator };
        }

        const actualValue = customCovenantValue.actual;
        const difference = calculateDifferenceValue(covenantValue?.value, actualValue);
        const inCompliance = calculateInCompliance(covenantValue?.value, actualValue);
        return {
          ...accumulator,
          [customCovenantValue.date]: { value: actualValue, difference, inCompliance },
        };
      }, {});
      name = {
        covenantTableType: CovenantTableType.CumulativeAdvanceRate,
        name: CovenantTitle.CumulativeAdvanceRate,
      };
      covenant = {
        covenantTableType: CovenantTableType.CumulativeAdvanceRate,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Ratio,
      };
      actual = {
        covenantTableType: CovenantTableType.CumulativeAdvanceRate,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Ratio,
        covenantId,
        isEditable: true,
      };
      difference = {
        covenantTableType: CovenantTableType.CumulativeAdvanceRate,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Ratio,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.CumulativeAdvanceRate,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.ActualVsExpectedRevenue:
      covenantRow = buildCustomCovenantRow(customCovenantValues, covenantValue);
      name = {
        covenantTableType: CovenantTableType.ActualVsExpectedRevenue,
        name: CovenantTitle.ActualVsExpectedRevenue,
      };
      covenant = {
        covenantTableType: CovenantTableType.ActualVsExpectedRevenue,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
        format: Format.Percent,
      };
      actual = {
        covenantTableType: CovenantTableType.ActualVsExpectedRevenue,
        name: CovenantRowType.Actual,
        value: covenantRow,
        format: Format.Percent,
        covenantId,
        isEditable: true,
      };
      difference = {
        covenantTableType: CovenantTableType.ActualVsExpectedRevenue,
        name: CovenantRowType.Difference,
        value: covenantRow,
        format: Format.Percent,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.ActualVsExpectedRevenue,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
      };
      break;

    case CovenantTableType.AdditionalCovenant:
      covenantRow = customCovenantValues.reduce((accumulator: any, customCovenantValue: any) => {
        if (customCovenantValue.covenant.id !== covenantValue.covenant.id) {
          return { ...accumulator };
        }

        const actualValue = customCovenantValue.actual;
        const difference = customCovenantValue.difference;
        const inCompliance = customCovenantValue.inCompliance;
        return {
          ...accumulator,
          [customCovenantValue.date]: { value: actualValue, difference, inCompliance },
        };
      }, {});
      name = {
        covenantTableType: CovenantTableType.AdditionalCovenant,
        name: CovenantTitle.AdditionalCovenant,
      };
      covenant = {
        covenantTableType: CovenantTableType.AdditionalCovenant,
        name: CovenantRowType.Covenant,
        value: covenantValue?.value,
      };
      actual = {
        covenantTableType: CovenantTableType.AdditionalCovenant,
        name: CovenantRowType.Actual,
        value: covenantRow,
        covenantId,
        isEditable: true,
      };
      difference = {
        covenantTableType: CovenantTableType.AdditionalCovenant,
        name: CovenantRowType.Difference,
        value: covenantRow,
        covenantId,
        isEditable: true,
      };
      inCompliance = {
        covenantTableType: CovenantTableType.AdditionalCovenant,
        name: CovenantRowType.InCompliance,
        value: covenantRow,
        covenantId,
        isEditable: true,
      };
      break;
  }

  return [name, covenant, actual, difference, inCompliance];
};

export const getDataForCustomCovenant = (
  covenantType: CovenantRowType,
  value: number | string,
): CustomCovenantData => {
  switch (covenantType) {
    case CovenantRowType.Covenant:
      return { covenant: value || null };
    case CovenantRowType.Actual:
      return { actual: value || null };
    case CovenantRowType.Difference:
      return { difference: value || null };
    case CovenantRowType.InCompliance:
      return { inCompliance: _.capitalize(value as string) || null };
  }
};

export const getCovenantValueByType = (
  covenantType: CovenantRowType,
  covenantItem: Record<string, any>,
  date: string,
): string | number | null | undefined => {
  switch (covenantType) {
    case CovenantRowType.Covenant:
      return covenantItem.value;
    case CovenantRowType.Actual:
      return covenantItem?.value?.[date]?.value;
    case CovenantRowType.Difference:
      return covenantItem?.value?.[date]?.difference;
    case CovenantRowType.InCompliance:
      return covenantItem?.value?.[date]?.inCompliance;
  }
};

export const isMatchingComplianceStatus = (
  covenantType: CovenantRowType,
  compareTo: boolean,
  covenantValue?: string | number | boolean | null,
): boolean => {
  const isInComplianceType = covenantType === CovenantRowType.InCompliance;

  if (!isInComplianceType || R.isNil(covenantValue)) return false;

  switch (compareTo) {
    case true: {
      return covenantValue === true || covenantValue === COMPLIANCE_YES;
    }

    case false: {
      return covenantValue === false || covenantValue === COMPLIANCE_NO;
    }
  }
};
